import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import GlobalRescueConfirmationModal from "./globalRescueConfirmationModal";

const GlobalRescueContainer = () => {

  const globalRescueText = "Rockefeller clients can enroll with Global Rescue for medical and security evacuation and crisis management services. Comprehensive coverage packages available at preferred pricing as well as short-term coverage for specific travel itineraries";
  const globalRescueURL = useSelector((store) => store?.env?.globalRescueURL)
  const [isGlobalRescueModalOpen, setGlobalRescueModalOpen] = useState(false);

  const handleViewGlobalRescueClick = useCallback(() => {
    setGlobalRescueModalOpen(true);
  }, []);

  const handleGlobalRescueConfirmClick = useCallback(() => {
    window.open(globalRescueURL)
    setGlobalRescueModalOpen(false);
  }, [globalRescueURL]);

  const toogleGlobalRescueModal = useCallback(() => {
    setGlobalRescueModalOpen((prev) => !prev);
  }, []);

  return (
    <div className="container">
      <img
        id="globalrescue_image"
        src="./../images/Global_Rescue.png"
        alt="Global Rescue"
        width="100%"
        className="RCM_health_base_image"
      />
      <div>
        <div className="row RCM_health_container">
          <div className="col col-sm-12 mt-1">
            <div
              className="RCM_Toast RCM_health_toast"
              id="RCM_cyberwa_details"
            >
              <div className="RCM_lifestyle_homeThrive"></div>
              <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
                GLOBAL RESCUE
              </div>
              <div className="mt-2 RCM_aviation_toast_content_container">
                {globalRescueText}
              </div>
              <div className="mt-4 RCM_lifestyle_btns" align="center">
                <button
                  className="btn btn-primary RCM_button_aviation_page"
                  onClick={handleViewGlobalRescueClick}
                >
                  CLICK HERE TO VIEW GLOBAL RESCUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isGlobalRescueModalOpen && (
        <GlobalRescueConfirmationModal
          isOpen={isGlobalRescueModalOpen}
          onContinueClick={handleGlobalRescueConfirmClick}
          toogleModal={toogleGlobalRescueModal}
        />
      )}
    </div>
  );
};

export default GlobalRescueContainer;
