import React from 'react';
import { roundvalue, formatMoney, roundUnitValue, getAccountDisplayName, IndLongShort } from '../../../../common/constants.jsx';
export const defaultSorted = [{ dataField: 'dtsettle', order: 'desc' }];
export function getActivitySummaryColumns(selectedaccounts) {
    const columns = [
        {
            dataField: 'dttrade',
            text: 'Trade Date',
            sort: false,
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text RCM_noWrap',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'dtsettle',
            text: 'Settlement Date',
            sort: true,
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'acct',
            text: 'Account',
            sort: false,
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell,row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts,cell, row.key);
            }
        },
        {
            dataField: 'trndesc',
            text: 'Description',
            sort: false,
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'trntype',
            text: 'Type',
            sort: false,
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'secid',
            text: 'Symbol/Cusip',
            sort: false,
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'units',
            text: 'Quantity',
            sort: false,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>0</span>;
                else return <span>{roundUnitValue(cell)}</span>;
            }
        },
        {
            dataField: 'unitprice',
            text: 'Price',
            sort: false,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(cell, 2))} </span>;
            }
        },
        {
            dataField: 'total',
            text: 'Amount',
            sort: false,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else{
                    let modifiedCell = parseFloat(String(cell).replace("*", ""));
                    if (modifiedCell < 0) return <span className="RCM_negative">{String(cell).includes("*")
                        ? formatMoney(roundvalue(modifiedCell, 2)) + "*"
                        : formatMoney(roundvalue(modifiedCell, 2))}</span>;
                    else if (modifiedCell > 0) return <span className="RCM_positive">{String(cell).includes("*")
                        ? formatMoney(roundvalue(modifiedCell, 2)) + "*"
                        : formatMoney(roundvalue(modifiedCell, 2))}</span>;
                    else return <span>$0</span>;
                }
            }
        }
    ]
    return columns;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function acctFormatter(accountlist, cell,key) {
    return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}</div>)
}