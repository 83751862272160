import React from 'react'

function onMFAChallangesAnsChange(event, mfaChallengeId, mfaChallengesAnswers, selectedOption){
    if (mfaChallengeId) {
        //check if user has param id
        if (checkChallangesExists(mfaChallengesAnswers, mfaChallengeId)) {
            //update the paramValue here
            for (let i = 0; i < mfaChallengesAnswers.length; i++) {
                if (mfaChallengesAnswers[i].mfaChallengeId === mfaChallengeId) {

                    if(event.target.type === 'radio'){
                        mfaChallengesAnswers[i].mfaChallengeAnswer = selectedOption;
                    }else{
                        mfaChallengesAnswers[i].mfaChallengeAnswer.value = event.target.value;
                    }
                    break;
                }
            }
        } else {
            //create a new object and push to object
            if(event.target.type === 'radio'){
                mfaChallengesAnswers.push({ mfaChallengeId:  mfaChallengeId, mfaChallengeAnswer: selectedOption });
            }else{
                mfaChallengesAnswers.push({ mfaChallengeId:  mfaChallengeId, mfaChallengeAnswer: {value: event.target.value} });
            }
            
        }
    }
}


function checkChallangesExists(mfaChallengesAnswers, id) {
    let isParamExist = false;

    if (mfaChallengesAnswers && Array.isArray(mfaChallengesAnswers) && mfaChallengesAnswers.length > 0) {
        for (let i = 0; i < mfaChallengesAnswers.length; i++) {
            if (mfaChallengesAnswers[i].mfaChallengeId === id) {
                isParamExist = true;
                break;
            }
        }
    }

    return isParamExist;
}


function NinthWaveLoginMFA(props) {

    let mfaChallanegsAnswers = [];

    return (
        <div>
            {
               ( props.mfaChallanegs && props.mfaChallanegs.mfaChallengeQuestions && Array.isArray(props.mfaChallanegs.mfaChallengeQuestions) && props.mfaChallanegs.mfaChallengeQuestions.length > 0  ) 
                    && 
               <>
               {
                  props.mfaChallanegs.mfaChallengeQuestions.map((mfa, index) => {
                      return (
                            <div className="mt-4" key={index} align="left">
                                <div style={props.fontStyle}>
                                    {mfa.mfaChallengeQuestion}
                                </div>

                                {
                                    renderMfaData(mfa, mfaChallanegsAnswers, props.fontStyle)
                                }

                            </div>
                      )
                  })
                }
                <div className="mt-3" align="center">
                                        <button
                                            onClick={() => props.handleExit()}
                                            className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size RCM_overlapping_btn">
                                            {
                                                props.passwordResetFiid ? 'GO BACK' : 'EXIT'
                                            } 
                                        </button>
                                        <button 
                                            className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size RCM_overlapping_btn" 
                                                onClick={() => props.loginUserMfaChallenge(props.mfaChallanegs, mfaChallanegsAnswers)}
                                            >
                                            {props.passwordResetFiid ? 'SUBMIT' : 'CONTINUE' }
                                        </button>
                </div>
               </>

            }
        </div>
    )
}


function renderMfaData(mfa, mfaChallanegsAnswers, fontStyle){
    if(mfa.mfaChallengeOptions && Array.isArray(mfa.mfaChallengeOptions ) && mfa.mfaChallengeOptions.length > 0 ){
        return(
            <div className="mt-2">
                {
                    mfa.mfaChallengeOptions.map((option, index) => {
                        return(
                            <div key={index}>
                                <input type="radio" name={mfa.mfaChallengeId} id={option.choiceTypeValue} 
                                    className="RCM_Aggr_Login_Radio" 
                                    onClick={(event) => onMFAChallangesAnsChange(event, mfa.mfaChallengeId, mfaChallanegsAnswers, option)}
                                    />
                                <label htmlFor={option.choiceTypeValue} style={fontStyle}>&nbsp;{option.choiceTypeValue ? option.choiceTypeValue : option.content}</label>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    else{
        return(
            <div className="mt-2">
                <input type='text' 
                    className="RCM_form_control RCM_Aggr_Login_Controls" 
                    placeholder="Answer" 
                    onChange={(event) => onMFAChallangesAnsChange(event, mfa.mfaChallengeId, mfaChallanegsAnswers)} />
            </div>
        )
    }
}

export default React.memo(NinthWaveLoginMFA)