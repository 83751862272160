import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import PdfViewer from './../pdfviewer'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getTaxDocumentColumns } from './taxdocumentcolumns.jsx';
import { getTaxDocumentModalColumns } from './taxdocumentModalColumns';
import Messages from './../../../common/messages.jsx';
import {DownloadPrintallModal} from '../../documents/accountstatement/downloadPrintAllModal.jsx'
import { spinner, MsgForFailedData, MsgDocumentLoading, MsgForNoData, MsgSelectDocument, MsgSelectRockoActs } from './../../../common/constants.jsx';
import Watermark from './../../../common/watermark';

export function TaxDocumentView(
    props
) {
    const selectRow = {
        mode: 'radio',
        selected: props && props.state && props.state.selected,
        onSelect: props && props.state && props.selectDocument,
        bgColor: 'var(--color-light-blue)',
        clickToSelect: true
    };

    let taxdocumentview = (
        <div className="container-fluid">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row RCM_Document_Message_Margin">
                <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                    <Messages messages={props && props.state && props.state.messages} />
                </div>
            </div>
            <div className="row RCM_Document_Fields">
                {/*<div className="col-sm-2" id="RCM_tax_docaccdropdown">
                    <label className="RCM_Accounts_lbl">ACCOUNTS</label>
                    <div className="RCM_Inline_Div">
                        <Dropdown isOpen={props && props.state && props.state.dropdownOpen} toggle={props.toggle} className="RCM_select_box RCM_mm_subselect_box RCM_Document_Dropdown">
                            <DropdownToggle caret>
                                {
                                    props && props.state && props.state.dropdownDispValue
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {props && props.state && props.state.accounts.map((account, index) =>
                                    <DropdownItem key={index} onClick={() => props.changeAccount(account)}>
                                        <div >{account.accountdisplayname}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>*/}

                <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3" id="RCM_taxdoc_Date">
                    <label className="RCM_Date_lbl">DATE</label>
                    <div className="RCM_Document_Date_Inline_Div" >
                        <Dropdown isOpen={props && props.state && props.state.dateRangeDropdownOpen} toggle={props.dateRangeDropdownToggle} className="RCM_select_box RCM_mm_subselect_box RCM_Document_Dropdown">
                            <DropdownToggle caret disabled={props.state.isProspectClient}>
                                {
                                    props && props.state && props.state.dateRangeDropdownValue
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {props && props.dateRangeDropDownItems && props.dateRangeDropDownItems.map((item, index) =>
                                    <DropdownItem key={index} onClick={props.changeDateRange} disabled={props.state.isProspectClient}>
                                        <div >{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>               
               {/*  <div className="col-sm-12 col-xs-12 col-md-2 col-lg-2 pull-right RCM_TaxDocument_PrintAllBtn_Padding">
                    <label onClick={props.toggleModel} class="custom-file-upload">Print All</label>
                </div> */}
            </div>
            <div style={{ textAlign: 'right' }} className={props.state.isProspectClient ? "RCM_Prospect_Disabled" : ''}>
                <span id="print" className="RCM_add_asset_link RCM_add_asset_link_color" onClick={props.toggleModel}><a id="print" disabled={props.state.isProspectClient}>PRINT</a></span>
                &nbsp;
                &nbsp;
                <span id="download" className="RCM_add_asset_link RCM_add_asset_link_color" onClick={props.toggleModel}><a id="download" disabled={props.state.isProspectClient}>DOWNLOAD</a></span>
            </div>
            <div className={
                props && props.state && props.state.filteredDocumentList && props.state.filteredDocumentList.length === 0
                    ? 'row RCM_Document_Details_Row'
                    : 'row'
            }>
                {
                    props && props.state && props.state.areDocumentsRetrieved
                        ?
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5" id="RCM_tax_docview">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    props && props.state && props.state.dropdownValue !== 'All Accounts'
                                        ? !props.state.filteredDocumentList.find(doc => doc.primaryValue === props.state.dropdownValue)
                                            ? <p>{MsgForNoData}</p>
                                            :
                                            <div>
                                                <h6>{props && props.state && props.state.dropdownValue}</h6>
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={props && props.state && props.state.filteredDocumentList.filter(doc => doc.primaryValue === props.state.dropdownValue)
                                                    }
                                                    columns={getTaxDocumentColumns(props.authprops, props.selectedaccounts, props.role)}
                                                    bordered={false}
                                                    selectRow={selectRow}
                                                    classes="RCM_two_level_table1"
                                                >
                                                    </BootstrapTable>
                                            </div>
                                        :
                                        props && props.state && props.state.accounts.map((acct, index) =>
                                            (acct.number !== 'All Accounts' && props && props.state && props.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number).length > 0
                                                ? <div key={index}>
                                                    <h6>{acct.number}</h6>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        data={props && props.state && props.state.filteredDocumentList.filter(doc =>  (doc.primaryValue === acct.number && doc.description !== undefined && !(doc.description.includes("HH"))))
                                                        }
                                                        columns={getTaxDocumentColumns(props.authprops, props.selectedaccounts, props.role)}
                                                        bordered={false}
                                                        selectRow={selectRow}
                                                        classes="RCM_two_level_table1"
                                                    >
                                                        </BootstrapTable>
                                                    </div>
                                                : <div key={index}></div>
                                            )
                                        )
                                }
                                 {
                                 
                                 (props.state.filteredDocumentList.filter(doc => doc.description !== undefined && doc.description.includes("HH")).length > 0
                                     ? <div key={"HH"}>
                                           <h6>HOUSEHOLD</h6>
                                         <BootstrapTable
                                             keyField="id"
                                             data={props.state.filteredDocumentList.filter(doc => (doc.description !== undefined  &&  doc.description.includes("HH")))
                                             }
                                             columns={getTaxDocumentColumns(props.authprops, props.selectedaccounts, props.role)}
                                             bordered={false}
                                             selectRow={selectRow}
                                             classes="RCM_two_level_table1"
                                         >
                                         </BootstrapTable>
                                     </div>
                                     : <div key={"HH"}></div>
                                 )
                          
                             }
                            </div>

                        </div>
                        : <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5" id="RCM_tax_docview">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    props && props.state && props.state.isNonRockActSelected
                                        ? <div>
                                            <p>{MsgSelectRockoActs}</p>
                                        </div>
                                        : props && props.state && props.state.isDocumentListResponseWithNoData
                                            ? <div>
                                                <p>{MsgForNoData}</p>
                                            </div>
                                            : <div>
                                                {
                                                    props && props.state && props.state.isDocumentListResponseWithError
                                                        ? <div>
                                                            <p> {MsgForFailedData}</p>
                                                        </div>
                                                        : <div className="RCM_spinner">{MsgDocumentLoading}{spinner()}</div>
                                                }
                                            </div>

                                }
                            </div>
                        </div>

                }
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7">
                    <div className="RCM_Toast RCM_Document_Toast" id="RCM_tax_docpreview">
                        {
                            props && props.state && props.state.pdfData
                                ? <PdfViewer pdfData={props && props.state && props.state.pdfData} />
                                : props && props.state && props.state.isDocumentSelected
                                    ? props && props.state && props.state.isDocumentResponseWithNoData || props && props.state && props.state.isDocumentResponseWithError
                                        ? props && props.state && props.state.isDocumentResponseWithNoData
                                            ? <p> {MsgForNoData}</p>
                                            : <p> {MsgForFailedData} </p>
                                        : <div className="RCM_spinner">Loading Selected Document{spinner()}</div>
                                    : props && props.state && props.state.areDocumentsRetrieved
                                        ? props && props.state && props.state.dropdownValue === 'All Accounts' || props && props.state && props.state.filteredDocumentList.find(doc => doc.primaryValue === props.state.dropdownValue)
                                            ? <p>{MsgSelectDocument}</p>
                                            : <p></p>
                                        : <p></p>
                        }
                    </div>
                </div>
            </div>
            <DownloadPrintallModal props={props} columns={getTaxDocumentModalColumns(props.authprops, props.selectedaccounts, props.role)}  screenName="Tax Documents"/>
        </div>
    )
    return taxdocumentview
}