import React from 'react';
import { printDocument } from './../../../common/printdocument.jsx';
import { downloadDocument } from './../../../common/downloaddocument.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { DocumentURL, FileType, getAccountDisplayName, IndLongShort } from './../../../common/constants.jsx';
import axios from 'axios';
import pdfData from './../mockpdfresponse.js'
import csvData from './../mockcsvresponse.js'
export function getTaxDocumentColumns(authprops, selectedaccounts, role) {
    const taxdocumentcolumns = [
        {
            dataField: "objectId",
            text: "",
            sort: true,
            hidden: true
        },
        {
            dataField: "primaryValue",
            text: "Account",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell);
            }
        },
        {
            dataField: "description",
            text: "Document Type",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "corrected",
            text: "Corrected",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "date",
            text: "Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {
            dataField: "streamid",
            text: "",
            sort: true,
            hidden: true
        },
        
        {
            dataField: '',
            text: '',
            formatter: (cellContent, row) => (
                < div className="row " >
                    <a className="RCM_Cursor_Pointer" onClick={() => printDocuments(row, authprops, role)} title="Print">
                        <div className={"RCM_Documents_btn_img RCM_userMenu_Print"}></div>
                    </a>
                    <a className="RCM_Cursor_Pointer" onClick={() => downloadPDFDocument(row, authprops, role)} title="Download PDF" >
                        <div className={"RCM_Documents_btn_img RCM_Download"}></div>
                    </a>
                    {
                        (row.mimetype && row.mimetype === 'text/csv')
                        && (
                            <a className="RCM_Cursor_Pointer" onClick={() => downloadCSVDocument(row, authprops, role)} title="Download CSV" >
                                <div className={"RCM_Documents_btn_img RCM_Document_CSV_Download"}></div>
                            </a>
                        )
                    }
                    

                </div >
            )
        }

    ];
    return taxdocumentcolumns;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function printDocuments(row, props, role) {
    if (role === 'Prospect') {
        printDocument(pdfData)
    } else {
        let requestBody = JSON.stringify({
            'VirtualTable': 'NF_TAX_DOCUMENTS',
            'PrimaryType': 'Account',
            'PrimaryValue': row.primaryValue,
            'ObjectId': row.objectId,

        });

        let url = DocumentURL;
        let documentCanellationToken = null;
        if (documentCanellationToken) {
            documentCanellationToken.cancel('Operation canceled due to new request.')
        }
        documentCanellationToken = axios.CancelToken.source();
        Post(url, requestBody, props.token, props.contextjson, props.profilejson, documentCanellationToken)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData) {
                    printDocument(selectedDocPdfData)
                } else {
                    console.log("didn't receive document from webapi")
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log(error);
                }
            });
    }
}

function downloadPDFDocument(row, props, role) {
    if (role === 'Prospect') {
        let fileName = row.primaryValue.trim() + "_" + row.description.trim() + "_" + row.date.trim();
        downloadDocument(pdfData, null, fileName);
    } else {
        let fileName = row.primaryValue.trim() + "_" + row.description.trim() + "_" + row.date.trim();
        let requestBody = JSON.stringify({
            'VirtualTable': 'NF_TAX_DOCUMENTS',
            'PrimaryType': 'Account',
            'PrimaryValue': row.primaryValue,
            'ObjectId': row.objectId,

        });

        let url = DocumentURL;
        let documentCanellationToken = null;
        if (documentCanellationToken) {
            documentCanellationToken.cancel('Operation canceled due to new request.')
        }
        documentCanellationToken = axios.CancelToken.source();
        Post(url, requestBody, props.token, props.contextjson, props.profilejson, documentCanellationToken)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData) {
                    downloadDocument(selectedDocPdfData, null, fileName);
                } else {
                    console.log("didn't receive document from webapi")
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log(error);
                }
            });
    }  
}
function downloadCSVDocument(row, props, role) {
    if (role === 'Prospect') {
        let fileName = row.primaryValue.trim() + "_" + row.description.trim() + "_" + row.date.trim();
        downloadDocument(csvData, FileType.CSV, fileName, role);
    } else {
        let fileName = row.primaryValue.trim() + "_" + row.description.trim() + "_" + row.date.trim();
        let requestBody = JSON.stringify({
            'VirtualTable': 'NF_TAX_DOCUMENTS',
            'PrimaryType': 'Account',
            'PrimaryValue': row.primaryValue,
            'ObjectId': row.objectId,
            'StreamId': row.streamid,

        });

        let url = DocumentURL;
        let documentCanellationToken = null;
        if (documentCanellationToken) {
            documentCanellationToken.cancel('Operation canceled due to new request.')
        }
        documentCanellationToken = axios.CancelToken.source();
        Post(url, requestBody, props.token, props.contextjson, props.profilejson, documentCanellationToken)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData) {
                    downloadDocument(selectedDocPdfData, FileType.CSV, fileName);
                } else {
                    console.log("didn't receive document from webapi")
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log(error);
                }
            });
    }  
}

function acctFormatter(accounts, cell) {
    return (<div title={getAccountDisplayName(accounts, cell, IndLongShort.LONG)}>{getAccountDisplayName(accounts, cell, IndLongShort.SINGLEVIEW)}</div>)
}