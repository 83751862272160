import { FETCH_PAST_TRANSFERS, 
    FETCH_SCHEDULED_TRANSFERS, 
    ADD_TRANSFER, UPDATE_ACCOUNT_INFORMATION, REMOVE_RECENTCONF } from "./actiontypes.jsx";


export function PastTransfersActivity(pastTransferList)
{
    return {
        type: FETCH_PAST_TRANSFERS,
        payload: pastTransferList
    }
}

export function ScheduleTransfersActivity(scheduleTransfersList)
{
    return {
        type:FETCH_SCHEDULED_TRANSFERS,
        payload: scheduleTransfersList
    }
}

export function Add_Transfer(scheduleTransfersList, recentConf)
{
    return{
        type:ADD_TRANSFER,
        payload: {
            list: scheduleTransfersList,
            recentConf: recentConf
        }
    }
}

export function Remove_LatestConf()
{
    return {
        type: REMOVE_RECENTCONF
    }
}

export function UpdateAccountInformation(accountList) {
    return {
        type: UPDATE_ACCOUNT_INFORMATION,
        payload: accountList
    }
}