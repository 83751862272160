import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultSorted } from "./scheduleTransfersColumn.js";
import { getScheduleTransfersList, cancelScheduleTransfer, getScheduleTransfersCancellationToken, cancelScheduleTransferCancellationToken } from "./moneymovementService.jsx";
import { PAST5DAYS } from './data/filterDateRange.js';
import { formatMoney, spinner, MsgForNoData, MsgForFailedData, MsgSelectFromTop, getAccountDisplayName, IndLongShort } from './../../common/constants.jsx';
import { getFormattedDate, filterAggregatedAccounts } from './common/utilty.js';
import RCM_Modal from "./common/modal.jsx";
import OverlayProcessing from "./common/overlayProcessing.jsx";
import { noDataIndicationMessage } from "./common/gridCommon.jsx";

class ScheduleTransfers extends React.Component {
    constructor(props) {
        super(props);

        this.contextAccounts = [];
        if (this.props.accounts !== null
            && this.props.accounts !== undefined
            && Array.isArray(this.props.accounts)
            && this.props.accounts.length > 0) {
            this.contextAccounts = this.props.accounts;
        }


        const scheduledTransfersColumns = [
            {
                dataField: "dttrade",
                text: "Date",
                sort: true,
                formatter: this.dateFormatter,
                sortCaret: (order, column) => this.customCaretSort(order, column),
                headerStyle: (column, colIndex) => this.columnHeaderStyle(column, colIndex)
            },
            {
                dataField: "acct",
                text: "Account",
                sort: true,
                formatter: (cell) => this.acctFormatter(this.contextAccounts, cell),
                sortCaret: (order, column) => this.customCaretSort(order, column),
                headerStyle: (column, colIndex) => this.columnHeaderStyle(column, colIndex)
            },
            {
                dataField: "trntype",
                text: "Type",
                sort: true,
                sortCaret: (order, column) => this.customCaretSort(order, column),
                headerStyle: (column, colIndex) => this.columnHeaderStyle(column, colIndex)
            },
            {
                dataField: "trndesc",
                text: "Description",
                sort: true,
                sortCaret: (order, column) => this.customCaretSort(order, column),
                headerStyle: (column, colIndex) => this.columnHeaderStyle(column, colIndex)
            },
            {
                dataField: "total",
                text: "Amount",
                sort: true,
                formatter: this.amountFormatter,
                sortCaret: (order, column) => this.customCaretSort(order, column),
                headerStyle: (column, colIndex) => { return { 'textAlign': 'right', 'whiteSpace': 'nowrap' } }
            },
            {
                dataField: "allowcancel",
                text: "",
                formatter: (this.actionFormatter = this.actionFormatter.bind(this))
            }
        ];

        this.topLevelAccounts = [];
        if (this.props.selectedaccount !== null
            && this.props.selectedaccount !== undefined
            && Array.isArray(this.props.selectedaccount)
            && this.props.selectedaccount.length > 0) {
            this.topLevelAccounts = filterAggregatedAccounts(this.props.selectedaccount);
        }

        this.token = "";
        if(this.props.token !== null && this.props.token !== undefined)
        {
            this.token = this.props.token;
        }

        this.contextjson = null;
        if(this.props.contextjson !== null && this.props.contextjson !== undefined)
        {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if(this.props.profilejson !== null && this.props.profilejson !== undefined)
        {
            this.profilejson = this.props.profilejson;
        }

        this.state = {
            columns: scheduledTransfersColumns,
            //scheduleTransfers: [],
            isDataLoaded: false,
            isLoading: false,
            render: this.props.render,
            gridMessage: MsgForNoData,
            showCancelConfirmModal: false,
            cancelVerbiage: "This action will cancel your transfer, Do you want to continue?",
            recentCancelRow: null,
            dateRange: PAST5DAYS,
            errorMsg: ''
        };

        this.rowClassForNewTransfer = this.rowClassForNewTransfer.bind(this);
        this.amountFormatter = this.amountFormatter.bind(this);
        this.dateFormatter = this.dateFormatter.bind(this);
        this.customCaretSort = this.customCaretSort.bind(this);
        this.columnHeaderStyle = this.columnHeaderStyle.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.cancelTransferConfirm = this.cancelTransferConfirm.bind(this);
    }

    toggleCancelModal(row)
    {
        this.setState(prevState => ({
            showCancelConfirmModal: !prevState.showCancelConfirmModal,
            recentCancelRow: row,
            isLoading: false
        }));
    }

    cancelTransferConfirm()
    {
        this.setState(prevState => ({
            showCancelConfirmModal: !prevState.showCancelConfirmModal
        }), ()=> {
            this.cancelTransfer(this.state.recentCancelRow);
        });
    }

    componentDidMount() {

        if(this.topLevelAccounts !== null 
            && this.topLevelAccounts !== undefined 
            && Array.isArray(this.topLevelAccounts)
            && this.topLevelAccounts.length > 0 ){
            getScheduleTransfersList(this.topLevelAccounts, this.state.dateRange, this.token, this.contextjson, this.profilejson)
                .then(data => {
                    if(Array.isArray(data)) 
                        this.props.updateScheduleTransferList(data);
                    let clonedState = Object.assign({}, this.state);
                    clonedState.gridMessage = MsgForNoData;
                    clonedState.isDataLoaded = true;
                    this.setState(clonedState);
                })
                .catch(error => {
                    //do something with error code.
                    this.props.updateScheduleTransferList([]);
                    let clonedState = Object.assign({}, this.state);
                    clonedState.gridMessage = MsgForFailedData;
                    clonedState.isDataLoaded = true;
                    this.setState(clonedState);
                });
        }else{
            this.props.updateScheduleTransferList([]);
            let clonedState = Object.assign({}, this.state);
            clonedState.isDataLoaded = true;
            clonedState.gridMessage = MsgSelectFromTop;
            this.setState(clonedState);
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.render !== prevProps.render) {
            if (this.props.selectedaccount !== null
                && this.props.selectedaccount !== undefined
                && Array.isArray(this.props.selectedaccount)
                && this.props.selectedaccount.length > 0) {
                let clonedState = Object.assign({}, this.state);
                clonedState.isDataLoaded = false;
                clonedState.gridMessage = MsgForNoData;
                this.setState(clonedState, () => {
                    getScheduleTransfersList(filterAggregatedAccounts(this.props.selectedaccount), this.state.dateRange, this.token, this.contextjson, this.profilejson)
                        .then(data => {
                            if(Array.isArray(data)) 
                                this.props.updateScheduleTransferList(data);
                            let clonedState = Object.assign({}, this.state);
                            clonedState.isDataLoaded = true;
                            this.setState(clonedState);
                        })
                        .catch(error => {
                            //do something with error code.
                            this.props.updateScheduleTransferList([]);
                            let clonedState = Object.assign({}, this.state);
                            clonedState.gridMessage = MsgForFailedData;
                            clonedState.isDataLoaded = true;
                            this.setState(clonedState);
                        });
                });
            }
            else {
                this.props.updateScheduleTransferList([]);
                let clonedState = Object.assign({}, this.state);
                clonedState.isDataLoaded = true;
                clonedState.gridMessage = MsgSelectFromTop;
                this.setState(clonedState);
            }

        }

        //performe the service call with date range if somebody changes the date
        // if (this.props.filterData !== null
        //     && this.props.filterData
        //     && this.props.filterData !== SELECTRANGE
        //     && this.state.dateRange !== this.props.filterData) {
        //     let clonedState = Object.assign({}, this.state);
        //     clonedState.isDataLoaded = false;
        //     clonedState.dateRange = this.props.filterData;
        //     clonedState.gridMessage = MsgForNoData;
        //     this.setState(clonedState, () => {
        //         getScheduleTransfersList(this.props.selectedaccount, this.state.dateRange, this.token)
        //             .then(data => {
        //                 if(Array.isArray(data)) 
        //                     this.props.updateScheduleTransferList(data);
        //                 let clonedState = Object.assign({}, this.state);
        //                 clonedState.isDataLoaded = true;
        //                 this.setState(clonedState);
        //             })
        //             .catch(error => {
        //                 //do something with error code.
        //                 this.props.updateScheduleTransferList([]);
        //                 let clonedState = Object.assign({}, this.state);
        //                 clonedState.gridMessage = MsgForFailedData;
        //                 clonedState.isDataLoaded = true;
        //                 this.setState(clonedState);
        //             });
        //     });
        // }
    }

    componentWillUnmount()
    {
        this.props.clearRecentTransferConf();

        if(getScheduleTransfersCancellationToken !== null && getScheduleTransfersCancellationToken !== undefined)
        {
            getScheduleTransfersCancellationToken.cancel("Cancelling schedule transfers request on unmount to prevent memory leak.");
        }

        if(cancelScheduleTransferCancellationToken !== null && cancelScheduleTransferCancellationToken !== undefined)
        {
            cancelScheduleTransferCancellationToken.cancel("Cancelling cancel schedule transfer request on unmount to prevent memory leak.");
        }
    }

    amountFormatter(cell, row, rowIndex) {
        if (cell !== undefined && cell !== null) {
            return (
            <div align="right">
                <span>
                    {formatMoney(cell)}
                </span>
            </div>);
        }
        else return ("");
    }

    dateFormatter(cell, row, rowIndex) {
        let date = "-";
        try {
            if (cell !== null && cell !== undefined && Date.parse(cell)) {
                date = getFormattedDate(cell);
            }
        } catch (error) {
            console.error(error);
        }

        return date;
    }

    
    acctFormatter(accountlist, cell)
    {
        return (<div title={getAccountDisplayName(accountlist, cell, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, cell, IndLongShort.SINGLEVIEW)}</div>)
    }

    cancelTransfer(row) {
        console.log(row);
        // do the api call here
        this.setState({ 'isLoading': true, 'errorMsg': ''}, () => {
            if (row !== null
                && row !== undefined
                && row.confirmationnumber !== null
                && row.confirmationnumber !== undefined) {
                //call cancel api
                cancelScheduleTransfer(row, this.token, this.contextjson, this.profilejson).then(data => {
                    this.setState({ 'isLoading': false, 'errorMsg': ''}, () => {
                        let updatedscheduleTransferList = this.props.scheduleTransfersList.filter(record => {
                            if (record.confirmationnumber !== row.confirmationnumber) {
                                return record;
                            }
                        });
                        this.props.updateScheduleTransferList(updatedscheduleTransferList);
                        document.dispatchEvent(new CustomEvent('refreshJournalEntry', {bubbles: true}));
                    })
                    
                }).catch(error => {
                        this.setState({ 'isLoading': false, 'errorMsg': error});
                });
            }
        })

        
    }

    actionFormatter(cell, row) {
        if (cell !== null && cell !== undefined && cell === true) {
            return (<div className="RCM_CM_delete-icon" onClick={() => this.toggleCancelModal(row)}></div>);
        }
    }

    customCaretSort(order, column)
    {
      if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
      else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
      else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
      return null;
    }
    
    columnHeaderStyle(column, colIndex)
    {
      return { 'textAlign': 'left', 'whiteSpace': 'nowrap' };
    }

    rowClassForNewTransfer = (row) => {
        let cssClass = null;
        if (row !== null && row !== undefined && row.confirmationnumber !== null
            && row.confirmationnumber !== undefined
            && this.props.recentConf != null
            && this.props.recentConf !== undefined
            && this.props.recentConf === row.confirmationnumber) {
            console.log("New confirmation number matched");
            cssClass = "RCM_CM_new_transfer";
        }

        return cssClass;
    }



    render() {
        let scheduledPayment;

        if (this.state.isDataLoaded) {
            scheduledPayment = (
                <div>
                    {
                        this.state.isLoading === true ? (
                            <OverlayProcessing />
                        ) : ""
                    }

                    {
                        (this.state.errorMsg !== "") &&
                            (<div className="RCM_validation_error_container" >
                                <div className="RCM_msg_text">{this.state.errorMsg}</div>
                            </div>)
                    }

                    <BootstrapTable
                        keyField="id"
                        data={this.props.scheduleTransfersList}
                        columns={this.state.columns}
                        bordered={false}
                        classes="RCM_two_level_table1"
                        wrapperClasses="RCM_CM_no_hand"
                        noDataIndication={() => noDataIndicationMessage(this.state.gridMessage)}
                        defaultSorted={defaultSorted}
                        rowClasses={this.rowClassForNewTransfer}
                    />

                    <RCM_Modal
                        modalState={this.state.showCancelConfirmModal}
                        toggleModal={this.toggleCancelModal}
                        modalTitle="Delete Account Confirmation"
                        modalContent={this.state.cancelVerbiage}
                        approvedAction={this.cancelTransferConfirm}
                    ></RCM_Modal>
                </div>
            );
        } else {
            scheduledPayment = (
                <div align="center">
                    <div className="RCM_spinner">Loading Pending Transfers Details{spinner()}</div>
                </div>
            );
        }

        return <div>{scheduledPayment}</div>;
    }
}

export default ScheduleTransfers;
