

import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import { getAssetGroupViewParentColumns, getAssetGroupViewChildColumns, renderChildRowsForGroupView} from './assetviewbygroupColumn';
import { MsgForNoData } from '../../../../common/constants';

export default function Assetviewbygroup(props) {

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
           //console.log(row);
           props.redirecttoholdings(row.acctkey);
        }
      };


    const expandRowAssetGroupView = {
        onlyOneExpanding: true,
        renderer: (row) => (
                <BootstrapTable
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={renderChildRowsForGroupView(row && row.accounts && Array.isArray(row.accounts) && row.accounts.length > 0 ? row.accounts : [], props.accounts, props.childColumnToSort, props.childColumnSortOrder)}
                    columns={getAssetGroupViewChildColumns(props.accounts, props.rowsortevent)}
                    noDataIndication={MsgForNoData}
                    rowEvents={rowEvents}
                    rowClasses= "RCM_Table_pointer"
                    bordered={false}
                />
        ),
        showExpandColumn: true,
        expanded: props && props.data && props.data.length === 1 ? [props.data[0].groupkey]:[],
        expandColumnPosition: 'right',
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return <b></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<div className="RCM_icon_hand RCM_down_arrow">
                </div>);
            }
            return (<div className="RCM_icon_hand RCM_right_arrow">
            </div>);
        }
    };

    return (
    
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="RCM_Asset_GroupView_Container">
                    <BootstrapTable
                        keyField="groupkey"
                        data={props.data ? props.data : []}
                        classes="RCM_two_level_table1"
                        columns={getAssetGroupViewParentColumns(props.rowsortevent)}
                        bordered={false}
                        noDataIndication={MsgForNoData}
                        expandRow={expandRowAssetGroupView}
                    />
                </div>
            </div>
        </div>

    )
}



