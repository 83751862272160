import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DatePicker from 'react-datepicker'
import { getAccountActivityDetailsColumns } from './activitydetailscolumns.js'
import Messages from '../../../../common/messages.jsx';
import { spinner, MsgForFailedData, MsgForNoData } from '../../../../common/constants.jsx';
import { defaultSorted } from './activitydetailscolumns.js'
import CheckDepositImageModal from '../../../../common/checkDepositImageModal.jsx';
import Watermark from '../../../../common/watermark';
export function AccountActivityDetailsView(
    props
) {
    let accountactivitydetailsview = (
        <div>
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="conatiner-fluid RCM_Toast">
                <div className="row RCM_AccountActivityDetails_Message_Div" >
                    <div className="col-sm-7">
                        <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                    </div>
                </div>
                <div className="row RCM_text_hd RCM_Display_Flex">
                    <div id="RCM_activitydetailstypefilter">
                        <div className="RCM_text RCM_CM_form_label RCM_text_textdropdown RCM_Inline_Div">
                            <label>TYPE :</label>
                        </div>

                        <div className="RCM_Inline_Div">
                            <Dropdown
                                isOpen={props.state.typeDropdownOpen}
                                toggle={props.toggleType}
                                className="RCM_select_box RCM_mm_subselect_box dropdown RCM_Activity_Dropdown"
                                key="addropdown1">
                                <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                    {
                                        props.state.dropdownTypeValue
                                    }
                                </DropdownToggle>
                                <DropdownMenu >
                                    {props && props.typeDropDownItems && props.typeDropDownItems.map((item, index) =>
                                        <DropdownItem key={index} onClick={props.changeType} disabled={props.state.isProspectClient}>
                                            <div>{item.label}</div>
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className=" RCM_AccountActivityDetail_Date_Div" id="RCM_activitydetailsdatefilter">
                        <label className="RCM_Date_lbl">DATE</label>
                        <div className="RCM_Document_Date_Inline_Div">
                            <Dropdown isOpen={props && props.state && props.state.dateRangeDropdownOpen} toggle={props.dateRangeDropdownToggle} className="RCM_select_box RCM_mm_subselect_box ">
                                <DropdownToggle caret disabled={props.state.isProspectClient}>
                                    {
                                        props && props.state && props.state.dateRangeDropdownValue
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    {props && props.dateRangeDropDownItems && props.dateRangeDropDownItems.map((item, index) =>
                                        <DropdownItem key={index} onClick={props.changeDateRange} disabled={props.state.isProspectClient}>
                                            <div>{item.label}</div>
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    {
                        props && props.state && props.state.isCustomDateRangeSelected
                            ?
                            <div className="row col-sm-6" >
                                <div className=" col-sm-5" >
                                    <label className="RCM_Accounts_lbl RCM_FromDate_Margin" >FROM DATE</label>
                                    <DatePicker
                                        className="RCM_Document_Datepicker RCM_AccountAcitivityDetail_Datepicker RCM_Document_FromDate_Margin"
                                        selected={props && props.state && props.state.startDate}
                                        onChange={props.startDateChange}
                                        maxDate={new Date()}
                                        //minDate={props.minDate}
                                        disabled={props.state.isProspectClient}
                                    ></DatePicker>
                                    <label className="RCM_bottom-label RCM_Stmt_FromDate_Bottom_lbl" >(mm/dd/yyyy)</label>
                                </div>

                                <div className=" col-sm-5 RCM_Stmt_ToDate_Div">
                                    <label className="RCM_Accounts_lbl RCM_ToDate_Lbl">TO DATE</label>
                                    <DatePicker
                                        className="RCM_Document_Datepicker RCM_AccountAcitivityDetail_Datepicker"
                                        selected={props && props.state && props.state.endDate}
                                        onChange={props.endDateChange}
                                        maxDate={new Date()}
                                        //minDate={props.minDate}
                                        disabled={props.state.isProspectClient}
                                    />
                                    <label className="RCM_bottom-label RCM_Stmt_ToDate_Bottom_lbl">(mm/dd/yyyy)</label>
                                </div>
                                <div className={props.role === 'Prospect' ? "col-sm-1 RCM_AccountActivityDetail_DateRange_Search_Btn_Margin RCM_Prospect_Disable_Button" : "col-sm-1 RCM_AccountActivityDetail_DateRange_Search_Btn_Margin"}>
                                    <a className="RCM_Cursor_Pointer" onClick={props.search} title="Search" >
                                        <div className={"RCM_TradeConfirm_Search"}></div>
                                    </a>
                                </div>
                            </div>
                            : <div></div>

                    }
                    <div className="col-sm-5"></div>
                </div>
                {
                    (() => {
                        if (props.state.requestFailed) {
                            return (
                                <div className="row RCM_activityDetailspage">
                                    <div className="col-sm-12 RCM_Tables RCM_balancetable" id="RCM_activitydetailstable">
                                        <span className="row col-sm-6"><label>(*) - Indicates a pending activity.</label></span>
                                        <BootstrapTable key='btactivity'
                                            keyField='id'
                                            data={[]}
                                            columns={getAccountActivityDetailsColumns(props.accounts, props.checkDepImageClick)}
                                            headerClasses="row-bg"
                                            bordered={false}
                                            classes="RCM_two_level_table1"
                                            noDataIndication={MsgForFailedData} />
                                    </div></div>)

                        }
                        else if (props.state.isloading) {
                            return <div className="RCM_spinner">Loading Account Activity Details{spinner()}</div>
                        }
                        else {
                            if (props.state.areDataRetrieved && !props.state.isloading && props.rows.length > 0) {
                                return (
                                    <div className="row RCM_activityDetailspage">

                                        <div className="col-sm-12 RCM_Tables RCM_balancetable" id="RCM_activitydetailstable">
                                            <span className="row col-sm-6"><label>(*) - Indicates a pending activity.</label></span>
                                            <BootstrapTable key='btactivity'
                                                keyField='id'
                                                data={props.rows}
                                                columns={getAccountActivityDetailsColumns(props.accounts, props.checkDepImageClick)}
                                                headerClasses="row-bg"
                                                bordered={false}
                                                classes="RCM_two_level_table1"
                                                noDataIndication={MsgForNoData}
                                                defaultSorted={defaultSorted} />
                                        </div></div>
                                )
                            }
                            else {
                                return (
                                    <div className="row RCM_activityDetailspage">
                                        <div className="col-sm-12 RCM_Tables RCM_balancetable" id="RCM_activitydetailstable">
                                            <span className="row col-sm-6"><label>(*) - Indicates a pending activity.</label></span>
                                            <BootstrapTable key='btactivity'
                                                keyField='id'
                                                data={props.rows}
                                                columns={getAccountActivityDetailsColumns(props.accounts, props.checkDepImageClick)}
                                                headerClasses="row-bg"
                                                bordered={false}
                                                classes="RCM_two_level_table1"
                                                noDataIndication={MsgForNoData}
                                                defaultSorted={defaultSorted} />
                                        </div></div>
                                )
                            }
                        }
                    })()

                }
            </div>
                <CheckDepositImageModal 
                    token = {props.token} 
                    contextjson = {props.contextjson}
                    profilejson = {props.profilejson}
                    modalState={props.showCheckDepModal} 
                    toggleModal={props.toggleCheckDepModal} 
                    checkDepImgData={props.checkDepImgData} />
        </div>
    )
    return accountactivitydetailsview
}