import { ninthwavesGet, ninthwavesPost } from "../../common/servicecalls";
import axios from "axios";
import { assetagUrl } from "../../common/constants";
export var fetchFiListCancellationToken = null;
export var fetchAllFiListCancellationToken = null;
export var fetchFiProfileCancellationToken = null;
export var fetchCustAcctsCancellationToken = null;
export var customerConsentCancellationToken = null;
export var customerAcctEnrollCancellationToken = null;
export var resetPasswordCancellationToken = null;
export var fetchCommercialWidgetDetailsCancellationToken = null;

export const fetchFiList = (nwtoken, appid, authToken) => {
   
    let fetchFiListUrl = `${assetagUrl}/api/Ninthwave/FiList`;

    if (fetchFiListCancellationToken !== null && fetchFiListCancellationToken !== undefined) {
        fetchFiListCancellationToken.cancel("Cancel existing get fetch fi list call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchFiListCancellationToken = cancelToken.source();

    let fetchFiListPromise = new Promise(function (resolve, reject){
        ninthwavesGet(fetchFiListUrl, appid, nwtoken, fetchFiListCancellationToken, authToken).then(
            fiListResponse => {
                resolve(fiListResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return fetchFiListPromise;

}

export const fetchFiProfile = (fiid, nwtoken, appid, authToken) => {

    let fetchFiProfileUrl = `${assetagUrl}/api/Ninthwave/Profile?fiid=${fiid}`;

    if (fetchFiProfileCancellationToken !== null && fetchFiProfileCancellationToken !== undefined) {
        fetchFiProfileCancellationToken.cancel("Cancel existing get fetch fi list call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchFiProfileCancellationToken = cancelToken.source();

    let fetchFiProfilePromise = new Promise(function (resolve, reject){
        ninthwavesGet(fetchFiProfileUrl, appid, nwtoken, fetchFiProfileCancellationToken, authToken).then(
            fiListResponse => {
                resolve(fiListResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return fetchFiProfilePromise;

}

export const fetchAllFiList = (searchKey, nwtoken, appid, authToken) => {

    let fetchAllFiListUrl = `${assetagUrl}/api/Ninthwave/FiList?name=${searchKey}`;

    if (fetchAllFiListCancellationToken !== null && fetchAllFiListCancellationToken !== undefined) {
        fetchAllFiListCancellationToken.cancel("Cancel existing get fetch fi list call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchAllFiListCancellationToken = cancelToken.source();

    let fetchFiListPromise = new Promise(function (resolve, reject){
        ninthwavesGet(fetchAllFiListUrl, appid, nwtoken, fetchAllFiListCancellationToken, authToken).then(
            fiListResponse => {
                resolve(fiListResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });
    return fetchFiListPromise;
}

export const fetchCustomerAccounts = (data, fiid, nwtoken, appid, authToken, version) => {
    const apiversion =  version ? version : ''
    let fetchCustAcctsUrl = `${assetagUrl}/api/Ninthwave/Accounts?fiid=${fiid}&version=${apiversion}`;

    if (fetchCustAcctsCancellationToken !== null && fetchCustAcctsCancellationToken !== undefined) {
        fetchCustAcctsCancellationToken.cancel("Cancel existing get fetch fi list call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchCustAcctsCancellationToken = cancelToken.source();

    let fetchCustAcctsPromise = new Promise(function (resolve, reject){
        ninthwavesPost(fetchCustAcctsUrl, data, appid, nwtoken, fetchAllFiListCancellationToken, authToken).then(
            fiListResponse => {
                resolve(fiListResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return fetchCustAcctsPromise;

}

export const resetPasswordService = (data, fiid, nwtoken, appid, authToken) => {
    let resetPasswordUrl = `${assetagUrl}/api/Ninthwave/ResetPassword?fiid=${fiid}`;

    if (resetPasswordCancellationToken !== null && resetPasswordCancellationToken !== undefined) {
        resetPasswordCancellationToken.cancel("Cancel existing get fetch fi list call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    resetPasswordCancellationToken = cancelToken.source();

    let resetPasswordPromise = new Promise(function (resolve, reject){
        ninthwavesPost(resetPasswordUrl, data, appid, nwtoken, fetchAllFiListCancellationToken, authToken).then(
            fiListResponse => {
                resolve(fiListResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return resetPasswordPromise;
}

export const provideCustomerConsent = (data, nwtoken, appid, authToken) => {
    
    let fiid = (data && data.fiDirId) ? data.fiDirId : '';
    let customerid = (data && data.customerKey) ? data.customerKey : '';
    let custConsentUrl = `${assetagUrl}/api/Ninthwave/Consent?fiid=${fiid}&customerid=${customerid}`;

    if (customerConsentCancellationToken !== null && customerConsentCancellationToken !== undefined) {
        customerConsentCancellationToken.cancel("Cancel existing customer concent call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    customerConsentCancellationToken = cancelToken.source();

    let custConsentPromise = new Promise(function (resolve, reject){
        ninthwavesPost(custConsentUrl, data, appid, nwtoken, customerConsentCancellationToken, authToken).then(
            custConcentRes => {
                resolve(custConcentRes);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return custConsentPromise;

}

export const enrollCustomerAccounts = (data, nwtoken, appid, authToken) => {

    let enrollmentUrl = `${assetagUrl}/api/Ninthwave/Enroll`;

    if (customerAcctEnrollCancellationToken !== null && customerAcctEnrollCancellationToken !== undefined) {
        customerAcctEnrollCancellationToken.cancel("Cancel existing customer concent call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    customerAcctEnrollCancellationToken = cancelToken.source();

    let custAcctEnrollPromise = new Promise(function (resolve, reject){
        ninthwavesPost(enrollmentUrl, data, appid, nwtoken, customerAcctEnrollCancellationToken, authToken).then(
            enrollRes => {
                resolve(enrollRes);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return custAcctEnrollPromise;

}


export const fetchCommercialWidgetDetails = (data, fiid, nwtoken, appid, authToken) => {
    let fetchCommercialWidgetDetailsUrl = `${assetagUrl}/api/Ninthwave/Widget?fiid=${fiid}`;

    if (fetchCommercialWidgetDetailsCancellationToken !== null && fetchCommercialWidgetDetailsCancellationToken !== undefined) {
        fetchCommercialWidgetDetailsCancellationToken.cancel("Cancel existing get fetch commercial widget details call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchCommercialWidgetDetailsCancellationToken = cancelToken.source();

    let fetchCommercialWidgetDetailsPromise = new Promise(function (resolve, reject){
        ninthwavesPost(fetchCommercialWidgetDetailsUrl, data, appid, nwtoken, fetchCommercialWidgetDetailsCancellationToken, authToken).then(
            widgetDetailsResponse => {
                resolve(widgetDetailsResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });


    return fetchCommercialWidgetDetailsPromise;

}