export const introData =
{
    "data": [
        {
            "step": "Accounts",
            "altStep": "Accounts",
            "steps": [
                {
                    "element": "#RCM_header_logo_img",
                    "intro": "Allows you to go back to the Dashboard page."
                },
                {
                    "element": "#RCM_alert_icon",
                    "intro": "Get notifications for alerts."
                },
                {
                    "element": ".RCM_settingsWrapper",
                    "intro": "Allows you to Logout, provide feedback or update your default settings."
                },
                {
                    "element": "#themeToggle",
                    "intro": "Allows you to switch between Dark & Light theme."
                },
                {
                    "element": "#RCM_headerbal",
                    "intro": "Allows you to view balance summary of all accounts selected."
                },
                {
                    "element": "#FADetails",
                    "intro": "Your FA contact information is shown in this section."
                },
                {
                    "element": "#FontSizing",
                    "intro": "Click on 'A-' to decrease, 'A' to default and 'A+' to increase the font size."
                },
                {
                    "element": ".RCM_sticky",
                    "intro": "Allows you to move across different screens."
                },

                {
                    "element": "#AssetAllocationSummary",
                    "intro": "Asset Allocation summary view, this gives you graphical and tabular view of all your assets."
                },
                {
                    "element": "#TooltipAssetallocationdetailToggle",
                    "intro": "Allows you to switch views from Chart to Table and vice versa."
                },
                {
                    "element": "#TooltipAssetallocationdetail",
                    "intro": "Allows you to have detailed view."
                },
                {
                    "element": "#AssetSchemaDropdown",
                    "intro": "Allows you to select different Asset schema."
                },
                {
                    "element": "#Account_overview",
                    "intro": "Account Overview summary view, this gives you the tabular representation of your accounts."
                },
                {
                    "element": "#topten_holdings",
                    "intro": "Top 10 Holdings summary view, shows you your top ten holdings at given time."
                },
                {
                    "element": "#historicalasset",
                    "intro": "Historical Asset summary view, this gives you graphical representation of historical values of assets."
                },
                {
                    "element": "#RCM_Performance_Summary",
                    "intro": "Performance summary view, this gives you graphical and tabular view of performance of your accounts."
                },
                {
                    "element": "#Account_Activity",
                    "intro": "Account Activity summary view, gives you tabular representation of all accounts activities."
                }
            ]
        },

        {
            "step": "Transfer",
            "altStep": "Transfers",
            "steps": [
                {
                    "element": "#RCM_transfertab",
                    "intro": "Transfer Information."
                },
                {
                    "element": "#RCM_billpaytab",
                    "intro": "Rockefeller Bill Payments provided by National Financial Services LLC."
                },
                {
                    "element": "#RCM_transferfund",
                    "intro": "Transfer Funds within account."
                },
                {
                    "element": "#RCM_transferfundhistory",
                    "intro": "You can check Transfer history."
                },
                {
                    "element": "#RCM_transferhistoryDate",
                    "intro": "Select the Date Range."
                },
                {
                    "element": "#RCM_tranferPendingData",
                    "intro": "Pending Transfer record."
                },
                {
                    "element": "#RCM_transferPastData",
                    "intro": "Past Transfer record."
                },
            ]
        },
        {
            "step": "manageExtAccount",
            "altStep": "manageExtAccount",
            "steps": [
                {
                    "element": "#RCM_manageEXtAccounttab",
                    "intro": "Manage Account Information."
                },
                {
                    "element": "#RCM_manageExtAddAccount",
                    "intro": "Add External Account."
                },
                {
                    "element": "#RCM_manageExtAccountData",
                    "intro": "External Account Data."
                }
            ]
        },
        {
            "step": "BillPay",
            "altStep": "BillPay",
            "steps": [
                {
                    "element": "#RCM_billpaytab",
                    "intro": "Rockefeller Bill Payments provided by National Financial Services LLC."
                }
            ]
        },
        {
            "step": "Assetallocationdetail",
            "altStep": "Assetallocationdetail",
            "steps": [
                {
                    "element": "#assetallocation-detail",
                    "intro": "Asset Allocation tab for Current."
                },
                {
                    "element": "#RCM_aadetailsTable",
                    "intro": "Asset allocation Details table."
                }
            ]
        },
        {
            "step": "Assetallocationhistory",
            "altStep": "Assetallocationhistory",
            "steps": [
                {
                    "element": "#assetallocation-history",
                    "intro": "Asset Allocation tab for History."
                },
                {
                    "element": "#RCM_Historical_dateRange_filter",
                    "intro": "Date filter."
                },
                {
                    "element": "#RCM_historical_chartTable_icon",
                    "intro": "Allows you to switch views from chart to table and vice versa."

                }, {
                    "element": "#RCM_historical_chartview",
                    "intro": "Asset allocation historical chart view."
                },
                {
                    "element": "#RCM_historical_tableview",
                    "intro": "Asset allocation historical table view."
                }
            ]
        },
        {
            "step": "Balancesdetail",
            "altStep": "Balancesdetail",
            "steps": [
                {
                    "element": "#RCM_bal_details_heading",
                    "intro": "Account Balance Details."
                },
                {
                    "element": "#balances-detail",
                    "intro": "Current Balance details tab."
                },
                {
                    "element": "#RCM_balancedetailsTable",
                    "intro": "Balance Details table."
                }
            ]
        },
        {
            "step": "Balanceshistory",
            "altStep": "Balanceshistory",
            "steps": [
                {
                    "element": "#RCM_bal_details_heading",
                    "intro": "Account Balance Details."
                },
                {
                    "element": "#balances-history",
                    "intro": "Balances history details tab."
                },
                {
                    "element": "#RCM_Historical_dateRange_filter",
                    "intro": "Date range filter"
                },
                {
                    "element": "#RCM_historical_chartTable_icon",
                    "intro": "Toggle to see table/chart view of historical balance details"
                },
                {
                    "element": "#RCM_historical_chartview",
                    "intro": "Historical balance details chart view."
                },
                {
                    "element": "#RCM_historical_tableview",
                    "intro": "Historical balance details table view."
                }
            ]
        },
        {
            "step": "Activitydetail",
            "altStep": "Activitydetail",
            "steps": [
                {
                    "element": "#RCM_activitydetailstypefilter",
                    "intro": "Type filter."
                },
                {
                    "element": "#RCM_activitydetailsdatefilter",
                    "intro": "Date filter."
                },
                {
                    "element": "#RCM_activitydetailstable",
                    "intro": "Activity Details Table."
                },
            ]
        },
        {
            "step": "RealizedAccountHoldingdetails",
            "altStep": "RealizedAccountHoldingdetails",
            "steps": [
                {
                    "element": "#RCM_RealizedAccountHoldingdetails",
                    "intro": "Realized Gain Loss Account tab."
                },
                {
                    "element": "#RCM_realizedgainlossdetailsfilter",
                    "intro": "Select Year."
                },
                {
                    "element": "#RCM_RealizedgainlossAccountHoldingTable",
                    "intro": "Realized Gain loss by Account Table."
                },
            ]
        },
        {
            "step": "RealizedSecurityHoldingdetails",
            "altStep": "RealizedGainLossDetails",
            "steps": [
                {
                    "element": "#RCM_RealizedSecurityHoldingdetails",
                    "intro": "Realized Gain Loss Security tab."
                },
                {
                    "element": "#RCM_realizedgainlossdetailsfilter",
                    "intro": "Select Year."
                },
                {
                    "element": "#RCM_RealizedGainLossSecurityHoldingTable",
                    "intro": "Realized Gain loss by Security Table."
                },
            ]
        },

        {
            "step": "EstimatedIncomeSummary",
            "altStep": "EstimatedIncomeDetails",
            "steps": [

                {
                    "element": "#RCM_projectedIncome_toggle",
                    "intro": "Allows you to switch views from Historical Income to Projected Income and vice versa."

                },
                {
                    "element": "#RCM_EstimatedIncomeForSummary",
                    "intro": "Estimated Income tab for Summary."

                },
                {
                    "element": "#RCM_estimatedincomedetailsfilter",
                    "intro": "Select year filter."
                },
                {
                    "element": "#RCM_toggleview",
                    "intro": "Allows you to switch views from Chart to Table and vice versa."
                },
                {
                    "element": "#RCM_estimatedincomesummaryview",
                    "intro": "Estimated Income Summary view."
                },
                {
                    "element": "#RCM_SecurityAccount_toggle_btn",
                    "intro": "Allows you to switch views from Security to Account and vice versa."
                },
                {
                    "element": "#RCM_EstimatedIncomeSummaryTable",
                    "intro": "Estimated Income Summary information table."
                },

            ]
        },
        {
            "step": "EstimatedIncomeSecurity",
            "altStep": "EstimatedIncomeSecurity",
            "steps": [
                {
                    "element": "#RCM_projectedIncome_toggle",
                    "intro": "Allows you to switch views from Historical Income to Projected Income and vice versa."

                },
                {
                    "element": "#RCM_EstimatedIncomeForSecurity",
                    "intro": "Estimated Income tab for Security."

                },
                {
                    "element": "#RCM_estimatedincomedetailsfilter",
                    "intro": "Select year filter."
                },
                {
                    "element": "#RCM_estimatedincomesecurityview",
                    "intro": "Estimated Income by Security view"
                }
            ]
        },
        {
            "step": "EstimatedIncomeAccount",
            "altStep": "EstimatedIncomeAccount",
            "steps": [
                {
                    "element": "#RCM_projectedIncome_toggle",
                    "intro": "Allows you to switch views from Historical Income to Projected Income and vice versa."

                },
                {
                    "element": "#RCM_EstimatedIncomeForAccount",
                    "intro": "Estimated Income tab for Account."

                },
                {
                    "element": "#RCM_estimatedincomedetailsfilter",
                    "intro": "Select year filter."
                },
                {
                    "element": "#RCM_estimatedincomeaccountview",
                    "intro": "Estimated Income by Account view"
                }
            ]
        },
        {
            "step": "ProjectedIncomeSummary",
            "altStep": "ProjectedIncomeSummary",
            "steps": [

                {
                    "element": "#RCM_projectedIncome_toggle",
                    "intro": "Allows you to switch views from Historical Income to Projected Income and vice versa."

                },
                {
                    "element": "#RCM_ProjectedIncomeForSummary",
                    "intro": "Projected Income tab for Summary."

                },
                {
                    "element": "#RCM_projectedincomedetailsfilter",
                    "intro": "Select year filter."
                },
                {
                    "element": "#RCM_toggleview",
                    "intro": "Allows you to switch views from Chart to Table and vice versa."
                },
                {
                    "element": "#RCM_projectedincomesummaryview",
                    "intro": "Projected Income Summary view."
                },
                {
                    "element": "#RCM_SecurityAccount_toggle_btn",
                    "intro": "Allows you to switch views from Security to Account and vice versa."
                },
                {
                    "element": "#RCM_ProjectedIncomeSummaryTable",
                    "intro": "Projected Income Summary information table."
                },

            ]
        },
        {
            "step": "ProjectedIncomeSecurity",
            "altStep": "ProjectedIncomeSecurity",
            "steps": [
                {
                    "element": "#RCM_projectedIncome_toggle",
                    "intro": "Allows you to switch views from Historical Income to Projected Income and vice versa."

                },
                {
                    "element": "#RCM_ProjectedIncomeForSecurity",
                    "intro": "Projected Income tab for Security."

                },
                {
                    "element": "#RCM_projectedincomedetailsfilter",
                    "intro": "Select year filter."
                },
                {
                    "element": "#RCM_projectedincomesecurityview",
                    "intro": "Projected Income by Security view"
                }
            ]
        },
        {
            "step": "ProjectedIncomeAccount",
            "altStep": "ProjectedIncomeAccount",
            "steps": [
                {
                    "element": "#RCM_projectedIncome_toggle",
                    "intro": "Allows you to switch views from Historical Income to Projected Income and vice versa."

                },
                {
                    "element": "#RCM_ProjectedIncomeForAccount",
                    "intro": "Projected Income tab for Account."

                },
                {
                    "element": "#RCM_projectedincomedetailsfilter",
                    "intro": "Select year filter."
                },
                {
                    "element": "#RCM_projectedincomeaccountview",
                    "intro": "Projected Income by Account view"
                }
            ]
        },
        {
            "step": "AssetAggregation",
            "altStep": "AssetAggregation",
            "steps": [
                {
                    "element": "#RCM_aaggregationAddAccount",
                    "intro": "Add new account to Aggregate from the institutions where they are held away."
                },
                {
                    "element": ".search_wrpr",
                    "intro": "You can search for your Financial Institution where you have accounts held away."
                },
                {
                    "element": "#RCM_click_here",
                    "intro": "Click on this link if you are unable to find your institution or to report an issue."
                },
                {
                    "element": "#toogleGridAndList",
                    "intro": "Allows you to switch views from list view to table view and vice versa."
                },
                {
                    "element": ".RCM_Aggr_Fi_As_list:first-child",
                    "intro": "Click on this to pick your Financial Institution, once you select institution you will be prompted to login to that financial institution and select an account."
                },
                {
                    "element": "#RCM_aaggregationtable",
                    "intro": "These are the list of held away accounts that you have aggregated."
                },
                {
                    "element": "#RCM_manual_assetLink",
                    "intro": "Link to add Specialty Assets."
                },
                {
                    "element": ".RCM_CM_delete-icon:first-child",
                    "intro": "Click here to delete and aggregated account."
                },
            ]
        },
        {
            "step": "SecurityHoldingdetails",
            "altStep": "Toptendetail",
            "steps": [
                {
                    "element": "#RCM_SecurityHoldingdetails",
                    "intro": "Security Holdings Information."
                },
                {
                    "element": "#RCM_SecurityHoldingFilter",
                    "intro": "Account filter."
                },
                {
                    "element": "#RCM_SecurityHoldingTable",
                    "intro": "Security Holding Details Table."
                },
            ]
        },
        {
            "step": "AccountHoldingdetails",
            "altStep": "AccountHoldingdetails",
            "steps": [
                {
                    "element": "#RCM_AccountHoldingdetails",
                    "intro": "Account Holdings Information."
                },
                {
                    "element": "#RCM_AccountHoldingFilter",
                    "intro": "Account filter."
                },
                {
                    "element": "#RCM_AccountHoldingTable",
                    "intro": "Account Holding Details Table."
                },
            ]
        },
        {
            "step": "AssetHoldingdetails",
            "altStep": "AssetHoldingdetails",
            "steps": [
                {
                    "element": "#RCM_AssetHoldingdetails",
                    "intro": "Asset Holdings Information."
                },
                {
                    "element": "#RCM_AssetHoldingTable",
                    "intro": "Asset Holding Details Table."
                },
            ]
        },
        {
            "step": "AccountStatements",
            "altStep": "Statements",
            "steps": [
                {
                    "element": "#RCM_AccountStatementstab",
                    "intro": "Account Statements Information."
                },
                {
                    "element": "#RCM_accstmtDropdownacc",
                    "intro": "Account Filter."
                },
                {
                    "element": "#RCM_accstmtDatefilter",
                    "intro": "Date filter."
                },
                {
                    "element": "#RCM_fromDateFilter",
                    "intro": "From date filter."
                },
                {
                    "element": "#RCM_fromToFilter",
                    "intro": "To date filter."
                },
                {
                    "element": "#print",
                    "intro": "Prints the selected statement."
                },
                {
                    "element": "#download",
                    "intro": "Download the selected statement."
                },
                {
                    "element": "#RCM_stmtaccdetails",
                    "intro": "Statement Details."
                },
                {
                    "element": "#RCM_accstmtpreview",
                    "intro": "Statement Preview."
                }

            ]
        },
        {
            "step": "Correspondence",
            "altStep": "Correspondence",
            "steps": [
                {
                    "element": "#RCM_Correspondencetab",
                    "intro": "Correspondence Information."
                },
                {
                    "element": "#RCM_correspondenceaccdropdown",
                    "intro": "Account Filter."
                },
                {
                    "element": "#RCM_correspondencefromdate",
                    "intro": "From Date filter."
                },
                {
                    "element": "#RCM_correspondencetodate",
                    "intro": "To Date Filter."
                },
                {
                    "element": "#RCM_correspondencesearch",
                    "intro": "Correspondence Search."
                },
                {
                    "element": "#RCM_correspondenceaccview",
                    "intro": "Correspondence Details."
                },
                {
                    "element": "#RCM_correspondencepreview",
                    "intro": "Correspondence Preview."
                }
            ]
        },
        {
            "step": "TradeConfirms",
            "altStep": "TradeConfirms",
            "steps": [
                {
                    "element": "#RCM_TradeConfirmstab",
                    "intro": "Trade Confirm Information."
                },
                {
                    "element": "#RCM_tradeconfirmaccdropdown",
                    "intro": "Account Filter."
                },
                {
                    "element": "#RCM_TradeConfirm_Search",
                    "intro": "Search."
                },
                {
                    "element": "#RCM_TradeConfirm_Date",
                    "intro": "Date Filter."
                },
                {
                    "element": "#RCM_TradeConfirm_FromDate",
                    "intro": "From Date Filter."
                },
                {
                    "element": "#RCM_TradeConfirm_ToDate",
                    "intro": "To Date Filter."
                },
                {
                    "element": "#RCM_TradeConfirm_Search_date",
                    "intro": "Search functionality for filtering trade confirm."
                },
                {
                    "element": "#RCM_TradeConfirmview",
                    "intro": "Trade Details."
                },
                {
                    "element": "#RCM_TradeConfirmpreview",
                    "intro": "Trade Preview."
                },

            ]
        },
        {
            "step": "TaxDocuments",
            "altStep": "TaxDocuments",
            "steps": [
                {
                    "element": "#RCM_TaxDocumentstab",
                    "intro": "Tax Documents Information."
                },
                {
                    "element": "#RCM_tax_docaccdropdown",
                    "intro": "Account Filter."
                },
                {
                    "element": "#RCM_taxdoc_Date",
                    "intro": "To Date Filter."
                },
                {
                    "element": "#print",
                    "intro": "Prints the selected document."
                },
                {
                    "element": "#download",
                    "intro": "Download the selected document."
                },
                {
                    "element": "#RCM_tax_docview",
                    "intro": "Tax Document Details."
                },
                {
                    "element": "#RCM_tax_docpreview",
                    "intro": "TAx Document Preview."
                }
            ]
        },
        {
            "step": "Collaboration",
            "altStep": "Collaboration",
            "steps": [
                {
                    "element": "#RCM_Collabrationlist",
                    "intro": "Document List."
                },
                {
                    "element": "#RCM_Collabrationuploadbtn",
                    "intro": "Upload Document."
                },
                {
                    "element": "#RCM_Collabrationpreview",
                    "intro": "Document Preview."
                }
            ]
        },
        {
            "step": "Help",
            "altStep": "CaseManager",
            "steps": [
                {
                    "element": "#RCM_CaseManagertab",
                    "intro": "You can report your issue here."
                },
                {
                    "element": "#RCM_Case_manager",
                    "intro": "Case manager form."
                },
                {
                    "element": "#RCM_issue_section",
                    "intro": "Select the issue area here."
                },
                {
                    "element": "#RCM_Feedback_TextArea",
                    "intro": "Provide a brief summary of the issue(s) you are experiencing."
                },
                {
                    "element": "#RCM_feedback_file_upload",
                    "intro": "You can upload files related to the issue(s) you are experiencing."
                },

                {
                    "element": "#RCM_Feedback_Submit",
                    "intro": "Submit issue."
                }
            ]
        },
        {
            "step": "Feedback",
            "altStep": "Feedback",
            "steps": [
                {
                    "element": "#RCM_Feedbacktab",
                    "intro": "Feedback."
                },
                {
                    "element": "#RCM_Feedback_Form",
                    "intro": "Provide your feedback here."
                }
            ]
        },
        {
            "step": "ProfileComponent",
            "altStep": "Profile",
            "steps": [
                {
                    "element": "#RCM_Profile",
                    "intro": "Profile Settings."
                },
                {
                    "element": "#RCM_ProfileSettings",
                    "intro": "You can update your profile here."
                },
                {
                    "element": "#RCM_OneTimePasscode",
                    "intro": "You can get One time Passcode for tax download."
                }
            ]
        },
        {
            "step": "ViewPreferences",
            "altStep": "Preferences",
            "steps": [
                {
                    "element": "#RCM_ViewPreferences",
                    "intro": "Preferences Settings."
                },
                {
                    "element": "#RCM_accounts_only",
                    "intro": "Toggle button to show Rockefeller accounts only."
                },
                {
                    "element": "#RCM_view_preferences",
                    "intro": "You can set your preference for menu view Normal/Tabs."
                },
                {
                    "element": "#RCM_top_tile_selection",
                    "intro": "You can chose which components to view on the home page for top section from here. And you can select maximun of three."
                },
                {
                    "element": "#RCM_bottom_tile_selection",
                    "intro": "You can chose which components to view on the home page for bottom section from here."
                }
            ]
        },
        {
            "step": "AccountsNickname",
            "altStep": "AccountsNickname",
            "steps": [
                {
                    "element": "#RCM_AccountsNickname",
                    "intro": "Accounts Nickname Settings."
                },
                {
                    "element": "#RCM_AccountSettings",
                    "intro": "You can update your account's nickname here."
                }
            ]
        },
        {
            "step": "aviation",
            "altStep": "aviation",
            "steps": [
                {
                    "element": "#lifestyle_image",
                    "intro": "Plane Image."
                },
                {
                    "element": "#aviation_individual",
                    "intro": "Click the button below to access a comprehensive overview of Wheels Up for Rockefeller Clients."
                },
                {
                    "element": "#aviation_plane",
                    "intro": "See real-time pricing and inventory for approved aircraft that can be booked without having to join."
                },
                {
                    "element": "#aviation_clipboard",
                    "intro": "If you are currently a Wheels Up Member. click the button below to log in to your Member Account."
                },
                {
                    "element": "#aviation_mail",
                    "intro": "Speak directly with a Wheels Up Executive, who is exclusively dedicated to Rockefeller Clients."
                }
            ]
        },
        {
            "step": "ManageGroups",
            "altStep": "ManageGroups",
            "steps": [
                {
                    "element": "#group_table",
                    "intro": "You can edit and delete groups, you can also remove account for selected group and sort groups this section."
                },
                {
                    "element": "#RCM_group_add",
                    "intro": "You can add new group."

                },
                {
                    "element": ".RCM_group_edit_icon:first-child",
                    "intro": "You can edit group name."

                },
                {
                    "element": ".RCM_Sort_arrow_down_icon:first-child",
                    "intro": "You can sort groups."

                },
                {
                    "element": ".RCM_manage_grp_delete_icon:first-child",
                    "intro": "You can delete group."

                },
                {
                    "element": "#RCM_accountSearch",
                    "intro": "You can search for specific account."

                },
                {
                    "element": "#account_table",
                    "intro": "You can view, add and delete accounts to the selected group from this section."
                },
                {
                    "element": ".RCM_nickname_edit_icon:first-child",
                    "intro": "You can add/edit account nickname."

                },
            ]
        },
        {
            "step": "ManageAssets",
            "altStep": "ManageAssets",
            "steps": [
                {
                    "element": "#RCM_ManageAsset_Table",
                    "intro": "Table to show all the specialty assets."
                },
                {
                    "element": "#RCM_addAsset_link",
                    "intro": "Link to add new assets form."
                },
                {
                    "element": ".RCM_edit_icon:first-child",
                    "intro": "Link to edit asset details."
                },
                {
                    "element": ".RCM_manage_grp_delete_icon.RCM_sp_details:first-child",
                    "intro": "Link to delete asset."
                },
                {
                    "element": "#RCM_addmanageAsset_back_link",
                    "intro": "Link to go back to specialty assets detail screen."
                },
                {
                    "element": "#RCM_manageAsset_select_type",
                    "intro": "Select the type of Asset."
                },
                {
                    "element": "#RCM_manual_assetLink",
                    "intro": "Link to add specialty assets."
                },
                //forms
                {
                    "element": "#RCM_AssetAccounts_form",
                    "intro": "Form to capture all the information for Asset Account. This form contains sub types such as: Cash, Investment, Retirement, Education and Savings, Trust, Other and Fund of Hedge Funds."
                },
                {
                    "element": "#RCM_Creditcard_form",
                    "intro": "Form to capture all the information for Credit card."
                },
                {
                    "element": "#RCM_exchangefunds_form",
                    "intro": "Form to capture all the information for Exchange funds."
                },
                {
                    "element": "#RCM_hedgefunds_form",
                    "intro": "Form to capture all the information for Hedge funds."
                },
                {
                    "element": "#RCM_mortgage_form",
                    "intro": "Form to capture all the information for Mortgage."
                },
                {
                    "element": "#RCM_loan_form",
                    "intro": "Form to capture all the information for Loan Account."
                },
                {
                    "element": "#RCM_PrivateEquity_form",
                    "intro": "Form to capture all the information for Private Equity."
                },
                {
                    "element": "#RCM_promissorynote_form",
                    "intro": "Form to capture all the information for Promissory Note."
                },
                {
                    "element": "#RCM_promissoryloannote_form",
                    "intro": "Form to capture all the information for Promissory Note Loan."
                },
                {
                    "element": "#RCM_realestate_form",
                    "intro": "Form to capture all the information for Real Estate."
                },
                {
                    "element": "#RCM_tangibles_form",
                    "intro": "Form to capture all the information for Real Estate."
                },
                {
                    "element": "#RCM_transportation_form",
                    "intro": "Form to capture all the information for Real Estate."
                },

                //tabs of forms
                {
                    "element": "#RCM_basic_info",
                    "intro": "Tab to capture the basic information of an asset."
                },
                {
                    "element": "#RCM_propertyAddress_info",
                    "intro": "Tab to capture the property address details."
                },

                {
                    "element": "#RCM_propertydetails_info",
                    "intro": "Tab to capture the property details of asset."
                },
                {
                    "element": "#RCM_collateral_info",
                    "intro": "Tab to capture the collateral information of asset."
                },

                {
                    "element": "#RCM_loan_info",
                    "intro": "Tab to capture the loan information of liability."
                },
                {
                    "element": "#RCM_history_info",
                    "intro": "Tab to show the balance history of asset/liability."
                },
                {
                    "element": "#RCM_investment_info",
                    "intro": "Tab to capture the investment details of asset."
                },
                {
                    "element": "#RCM_transactions_info",
                    "intro": "Tab to capture the transactions for private equity."
                },
                {
                    "element": "#RCM_note_info",
                    "intro": "Tab to capture the note information."
                },

                //form specific common elements
                {
                    "element": "#RCM_Type_Of_Account",
                    "intro": "Select the type of asset."
                },
                {
                    "element": "#RCM_assetClass",
                    "intro": "Select the asset class."
                },
                {
                    "element": "#RCM_assetClassCategory",
                    "intro": "Select the asset class category."
                },
                {
                    "element": "#RCM_custodianname",
                    "intro": "Specify the custodian name to which the asset belongs."
                },
                {
                    "element": "#RCM_issuername",
                    "intro": "Specify the issuer name of the credit card."
                },
                {
                    "element": "#RCM_accountNumber",
                    "intro": "Specify the account number of the asset."
                },
                {
                    "element": "#RCM_name",
                    "intro": "Specify the legal name of the asset."
                },
                {
                    "element": "#RCM_loannumber",
                    "intro": "Specify the loan account number of the liability."

                },
                {
                    "element": "#RCM_nickname",
                    "intro": "Specify the nickname for the asset for your refrence."
                },
                {
                    "element": "#RCM_initialprice",
                    "intro": "Specify the initial price/value of the asset."
                },
                {
                    "element": "#RCM_initialpricedate",
                    "intro": "Specify the initial price date in (mm/dd/yyyy) format."
                },
                {
                    "element": "#RCM_sourceofinitialvaluation",
                    "intro": "Specify the source from where the asset has been initially valuated."
                },
                {
                    "element": "#RCM_bankname",
                    "intro": "Specify the Bank Name to which the liability belong."

                },
                {
                    "element": "#RCM_outstandingbalance",
                    "intro": "Specify the total outstanding balance of the asset."
                },
                {
                    "element": "#RCM_totalvalue",
                    "intro": "Specify the total value of the asset."
                },
                {
                    "element": "#RCM_valueasof",
                    "intro": "Specify the value as of date in (mm/dd/yyyy) format."
                },
                {
                    "element": "#RCM_sourceofvaluation",
                    "intro": "Specify the source from where the asset has been valuated."
                },
                {
                    "element": "#RCM_outstandingbalance_noteLoan",
                    "intro": "Specify the total outstanding balance of the asset."
                },
                {
                    "element": "#RCM_decs",
                    "intro": "Specify some description for the asset."
                },



            ]
        },
        {
            "step": "EDelivery",
            "altStep": "EDelivery",
            "steps": [
                {
                    "element": "#RCM_eDelivery",
                    "intro": "Manage E-Delivery options."
                },
                {
                    "element": "#RCM_Edelivery_email",
                    "intro": "Select your E-Delivery email address to view e-delivery settings from here."
                },
                {
                    "element": "#RCM_edelivery_selectAll",
                    "intro": "Select/UnSelect all documents for E-Delivery."
                }
            ]
        },
        {
            "step": "SubscribeAlerts",
            "altStep": "SubscribeAlerts",
            "steps": [
                {
                    "element": "#RCM_Subscribe_Alerts",
                    "intro": "Subscribe Alerts."
                },
                {
                    "element": "#RCM_Edelivery_email_wrapper",
                    "intro": "You can view your email, where you will recieve alerts notification."
                },
                {
                    "element": "#RCM_Edelivery_mobileNo_wrapper",
                    "intro": "You can set your mobile no to get alerts notification."
                },
                {
                    "element": "#RMC_Subscribe_alert_account_list",
                    "intro": "You can view your accounts and alert type subscribed information."
                },
                {
                    "element": ".manage_alert_btn:first-child",
                    "intro": "You can subscribe to alerts by clicking on this link. If you want to apply the same selections to all accounts you can click on 'Apply changes to All accounts' instead of 'Save' and that will subscribe all accounts to those alerts."
                },

            ]
        },
        {
            "step": "AlertDetails",
            "altStep": "AlertDetails",
            "steps": [
                {
                    "element": "#RCM_alert_notification",
                    "intro": "Alert notification details."
                },
                {
                    "element": "#RCM_alert_table",
                    "intro": "You can view all alert notifications summary in this section. To view more details click on the alert row."
                },
                {
                    "element": "#lnkmarkallasread",
                    "intro": "Link to mark all unread notifications as read."
                },
                {
                    "element": "#RCM_alert_preview",
                    "intro": "Alert notification details section. Click the alert row from left table to view the alert details in this section."
                }
            ]
        },
        {
            "step": "philanthropy",
            "altStep": "philanthropy",
            "steps": [
                {
                    "element": "#RCM_philanthropytab",
                    "intro": "Philanthropy."
                },
                {
                    "element": "#RCM_myfavoriteCharity",
                    "intro": "You can view your favorite charities in this section."
                },
                {
                    "element": "#RCM_myGrants",
                    "intro": "You can view/edit your grants in this section."
                },
                {
                    "element": "#RCM_Grants_Drafts",
                    "intro": "You can view/edit your drafted grants in this tab."
                },
                {
                    "element": "#RCM_Grants_Pending",
                    "intro": "You can view/edit your pending grants in this tab."
                },
                {
                    "element": "#RCM_Grants_Completed",
                    "intro": "You can view your completed grants in this tab."
                },
                {
                    "element": "#RCM_charitysearch",
                    "intro": "Charity search section."
                },
                {
                    "element": "#RCM_charitysearchbox",
                    "intro": "Charity search box."
                },
                {
                    "element": "#RCM_CharitySearchList",
                    "intro": "You can view charity search results and initiate grants in this section."
                },
            ]
        },
        {
            "step": "health",
            "altStep": "health",
            "steps": [
                {
                    "element": "#health_image",
                    "intro": "Health image."
                },
                {
                    "element": "#health_learnmore",
                    "intro": "Click the button below to access a comprehensive overview of PinnacleCare information for Rockefeller Clients."
                },
                {
                    "element": "#health_member",
                    "intro": "If you are currently a PinnacleCare Member and activated your Electronic Medical Records, please click the button below to log in to your Member Portal."
                },
                {
                    "element": "#health_contact",
                    "intro": "Speak directly with a PinnacleCare Executive, who is exclusively dedicated to Rockefeller Clients."
                },

            ]
        },
        {
            "step": "Collaboration",
            "altStep": "Collaboration",
            "steps": [
                {
                    "element": "#grpContainer",
                    "intro": "Showing Group name which user belongs to."
                },
                {
                    "element": "#grpList",
                    "intro": "Click the folders to see documents available in it with this user can navigate through documents Also User can add folder,upload document and browse the files in shareponit site"
                },
                {
                    "element": "#mnuRefresh",
                    "intro": "Link to refresh the screen."
                },
                {
                    "element": "#mnuNewFolder",
                    "intro": "Link to Add new folder in Sharepoint site."
                },
                {
                    "element": "#mnuUpload",
                    "intro": "Link to Upload documents."
                },
                {
                    "element": "#mnuLaunchPortal",
                    "intro": "Link to open Portal in the browser."
                },
                {
                    "element": ".RCM_Teamwork_sharepoint:first-child",
                    "intro": "Link to show folder/document in Sharepoint site"
                },
                {
                    "element": ".RCM_Teamwork_preview",
                    "intro": "Link to see document preview in right side container."
                },
                {
                    "element": "#previewDocument",
                    "intro": "Click the file name to preview the file in this container"
                },
            ]
        },
        {
            "step": "Performance",
            "altStep": "PerformanceSummary",
            "steps": [
                {
                    "element": "#RCM_PerformanceSummary",
                    "intro": "This tab shows the performance af all accounts for the last 6 months."
                },
                {
                    "element": "#RCM_Performance_Summary_chart",
                    "intro": "Chart to represent the performance of the accounts for the last 6 months."
                }
            ]
        },
        {
            "step": "AccountPerformance",
            "altStep": "AccountPerformance",
            "steps": [
                {
                    "element": "#RCM_AccountPerformance",
                    "intro": "This tab shows the account wise performance."
                },
                {
                    "element": "#RCM_AccountPerformance_table",
                    "intro": "Account performance table to show account wise data for Last makert close, Month to date etc."
                }
            ]
        },
        {
            "step": "Performance",
            "altStep": "PerformanceSummary",
            "steps": [
                {
                    "element": "#RCM_PerformanceSummary",
                    "intro": "This tab shows the performance af all accounts for the last 6 months."
                },
                {
                    "element": "#RCM_Performance_Summary_chart",
                    "intro": "Chart to represent the performance of the accounts for the last 6 months."
                }
            ]
        },
        {
            "step": "AccountPerformance",
            "altStep": "AccountPerformance",
            "steps": [
                {
                    "element": "#RCM_AccountPerformance",
                    "intro": "This tab shows the account wise performance."
                },
                {
                    "element": "#RCM_AccountPerformance_table",
                    "intro": "Account performance table to show account wise data for Last makert close, Month to date etc."
                }
            ]
        },
        {
            "step": "Liabilities",
            "altStep": "Liabilities",
            "steps": [
                {
                    "element": "#RCM_Liabilities_Table",
                    "intro": "Liabilities details of the user."
                },
            ]
        },
        {
            "step": "AccountInfo",
            "altStep": "AccountInfo",
            "steps": [
                {
                    "element": "#RCM_AccountInfotab",
                    "intro": "Accounts Information tab."
                },
                {
                    "element": "#RCM_Account_Info_Table",
                    "intro": "Account information table which contains information such as Brokerage Account, ABA no and Checking account  no."
                },
                {
                    "element": "#RCM_ach_info_section",
                    "intro": "Section which contains ACH instructions information."
                },
                {
                    "element": "#RCM_wire_info_section",
                    "intro": "Section which contains WIRE instructions information."
                }
            ]
        }
    ]
};
