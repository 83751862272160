import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
//import DatePicker from 'react-datepicker'
import { TabContent, TabPane, Row, Col } from "reactstrap";
import { spinner, MsgDocumentLoading, MsgForFailedData, MsgForNoData } from './../../../../common/constants.jsx';
import { getHeaderColumnsByCategory, getColumnsForExpandedRow } from './assetallocationdetailscolumns.jsx';
import Watermark from '../../../../common/watermark';
export function AssetAllocationDetailsView(
    props
) {
   

    let assetallocationdetailsview = (
        <div>
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="conatiner-fluid RCM_Toast" key="aadetails1">
                {props && props.state.showAssetClassOption ?
                    <form>
                        <div className="d-flex flex-row align-content-start" id="RCM_asset_schema_filter">
                            <div className="form-group RCM_displayflex RCM_form_group_inline">
                                
                                <Dropdown
                                    isOpen={props.state.classDropdownOpen}
                                    toggle={props.toggleClass}
                                    className="RCM_as_dropdown RCM_select_box RCM_mm_subselect_box dropdown"
                                    key="addropdown1">
                                    <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                        {
                                            props.state.dropdownClassValue
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu >
                                        {props && props.classDropDownItems && props.classDropDownItems.map((item, index) =>
                                            <DropdownItem key={index} onClick={props.changeClass} disabled={props.state.isProspectClient}>
                                                <div>{item.label}</div>
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                </form>: ''}
                <div className="row" key="aadetails10">
                    <div className="col-sm-12 RCM_NavItem_container" key="aadetails11">
                        <TabContent activeTab={props.state.activeTab} key="aadetailstab">
                            <TabPane tabId="1" key="aadetailstab1">
                                <Row key="aadetailstab2">
                                    <Col sm="12" key="aadetailstab3">
                                        {
                                            (() => {
                                                if (props.state.requestFailed) {
                                                    return (<div className="RCM_aadtablewrapper" id="RCM_aadetailsTable">
                                                        <BootstrapTable
                                                            keyField='assetcat'
                                                            data={[]}
                                                            columns={getHeaderColumnsByCategory('', props.selectedaccounts, props.rowsortevent)}
                                                            headerClasses="row-bg"
                                                            bordered={false}
                                                            classes="RCM_two_level_table1"
                                                            noDataIndication={MsgForFailedData}
                                                            expandRow={props.expandRowByCategory}
                                                        />
                                                    </div>)

                                                }
                                                else if (props.state.isloading || props.state.isLoadingAssetColor) {
                                                    return <div className="RCM_spinner">Loading Asset Allocation Details{spinner()}</div>
                                                }
                                                else if (props.state.requestFailedAssetColor) { return <p className="RCM_data_error">Unable to fetch Asset schema configuration</p> }
                                                else {
                                                    if (props.state.areDataRetrieved && !props.state.isloading) {
                                                        return (
                                                            <div className="RCM_aadtablewrapper" id="RCM_aadetailsTable">
                                                                <BootstrapTable
                                                                    keyField='assetcat'
                                                                    data={props.assetallocationData}
                                                                    columns={getHeaderColumnsByCategory(props, props.selectedaccounts, props.rowsortevent)}
                                                                    headerClasses="row-bg"
                                                                    bordered={false}
                                                                    classes="RCM_two_level_table1"
                                                                    noDataIndication={MsgForNoData}
                                                                    expandRow={props.expandRowByCategory}
                                                                />
                                                            </div>
                                                        )

                                                    }
                                                    else {
                                                        return (<div className="RCM_aadtablewrapper" id="RCM_aadetailsTable">
                                                            <BootstrapTable
                                                                keyField='assetcat'
                                                                data={props.assetallocationData}
                                                                columns={getHeaderColumnsByCategory(props, props.selectedaccounts, props.rowsortevent)}
                                                                headerClasses="row-bg"
                                                                bordered={false}
                                                                classes="RCM_two_level_table1"
                                                                noDataIndication={MsgForNoData}
                                                                expandRow={props.expandRowByCategory}
                                                            />
                                                        </div>)
                                                    }
                                                }
                                            })()

                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                    </div>

                </div>
            </div>

        </div>
    )
    return assetallocationdetailsview;
}