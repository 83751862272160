import React from 'react';
import { Link } from 'react-router-dom';
import { roundvalue, formatMoney, getAccountDisplayName, IndLongShort, toDate, CheckIfExternalAcct } from '../../../../common/constants.jsx';
import Tooltip from '../../../../common/Tooltipcomponent';

export function GetBalanceDetailsColumns(pricedinvestments, cashmoneyaccounts, marginbalance, pendingactivity, totalaccountvalue, selectedAccount, totalfixedincomeaccruedinterest, totaloutstandingbalance, insuranceandannuity, BalSummaryV2PilotFlag, totalcreditdebit, totalmarginandoutstandingbalance, redirecttoholdings) {
    const columns = [
        {
            dataField: 'accountnickname', text: 'Account', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'left', footerAlign: 'left', classes: 'RCM_common_table_column_text', footer: (cell) => {
                return <span>Total</span>;
            },
            formatter: (cell, row) => {
                if (row.key === null || row.key === '') return <span>-</span>;
                else return <span>
                    {acctFormatter(selectedAccount, row.key)}
                    <div>
                        <span className="RCM_atw" >
                            {(row.availablewithdraw === null || row.availablewithdraw === '') ? 
                        '': 'Available to Withdraw: '}
                        {(row.availablewithdraw === null || row.availablewithdraw === '') ? '' : (row.availablewithdraw === 0) ? '$0' : formatMoney(row.availablewithdraw)}
                        </span >
                    </div>
                    
                    { row.asofdate && row.asofdate !== null && row.asofdate !== '' && CheckIfExternalAcct(row.key, selectedAccount) ?
                        <div>
                            <span className="RCM_asofdate" >{'As of:' }
                            {(row.asofdate === null || row.asofdate === '') ? '' : (new Date(toDate(row.asofdate)).toISOString()).split('T')[0]}
                        </span > 
                        </div>
                        : ''
                    }
                </span >

            }
        },
        {
            dataField: 'priced', text: 'Priced Investments', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell,row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span class="RCM_link" onClick={() => redirecttoholdings(row.key)}>{formatMoney(cell)}</span>;
            }, footer: (cell) => {
                if (pricedinvestments === undefined || pricedinvestments === null || pricedinvestments === '') return <span>-</span>;
                else if (pricedinvestments === 0) return <span>$0</span>;
                else return <span>{formatMoney(pricedinvestments)}</span>;
            }, footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';    
            }

        },
        ...(BalSummaryV2PilotFlag ?
            [{
                dataField: 'cash', text: 'Cash/Money Accounts', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', 
                formatter: (cell, row, index) => {
                    let moneyaccountvalue = (row.moneyaccountvalue === undefined || row.moneyaccountvalue === null || row.moneyaccountvalue === '') ? "-" : row.moneyaccountvalue === 0 ? "$0" : formatMoney(row.moneyaccountvalue)
                    let cashbalance = (row.cashbalance === undefined || row.cashbalance === null || row.cashbalance === '') ? "-" : row.cashbalance === 0 ? "$0" : formatMoney(row.cashbalance)
                    
                        return (
                            <Tooltip id={`Tooltip${index}Cash`} placement="right" value={cell ? cell === 0 ? "$0" : formatMoney(cell) : '-'} >
                                <table width="100%">
                                    <tr>
                                        <td><div className="leftTextAlign">Money Account</div></td>
                                        <td align="right"><div className="rightTextAlign">{moneyaccountvalue}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="leftTextAlign">Cash Credit/Debit</div></td>
                                        <td align="right"><div className="rightTextAlign">{cashbalance}</div></td>
                                    </tr>
                                    
                                </table>
                            </Tooltip>)
                   
                },
                footer: (cell) => {
                    if (cashmoneyaccounts === undefined || cashmoneyaccounts === null || cashmoneyaccounts === '') return <span>-</span>;
                    else if (cashmoneyaccounts === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cashmoneyaccounts)}</span>;
                }, footerClasses: (column, colIndex) => {
                    return 'RCM_common_table_column_number';
                }
            }
            ] :
            [
        {
            dataField: 'cash', text: 'Cash/Money Accounts', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            },
            footer: (cell) => {
                if (cashmoneyaccounts === undefined || cashmoneyaccounts === null || cashmoneyaccounts === '') return <span>-</span>;
                else if (cashmoneyaccounts === 0) return <span>$0</span>;
                else return <span>{formatMoney(cashmoneyaccounts)}</span>;
            }, footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
                }
            ]),
        ...(BalSummaryV2PilotFlag ? [
            {
                dataField: 'othercreditdebit', text: 'Other Credit/Debit', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number',
                formatter: (cell, row) => {
                    if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                },
                footer: (cell) => {
                    if (totalcreditdebit === undefined || totalcreditdebit === null || totalcreditdebit === '') return <span>-</span>;
                    else if (totalcreditdebit === 0) return <span>$0</span>;
                    else return <span>{formatMoney(totalcreditdebit)}</span>;
                }, footerClasses: (column, colIndex) => {
                    return 'RCM_common_table_column_number';
                }
            }
        ] : [
            {
                dataField: 'margin', text: 'Margin Balance', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number',
                formatter: (cell, row, index) => {
                    let marginintrate = (row.marginintrate === undefined || row.marginintrate === null || row.marginintrate === '') ? "-" : row.marginintrate === 0 ? "0%" : `${roundvalue(row.marginintrate,2)}%`
                    let cashmarginbuyingpower = (row.cashmarginbuyingpower === undefined || row.cashmarginbuyingpower === null || row.cashmarginbuyingpower === '') ? "-" : row.cashmarginbuyingpower === 0 ? "$0" : formatMoney(row.cashmarginbuyingpower)
                    let nonmarginbuyingpower = (row.nonmarginbuyingpower === undefined || row.nonmarginbuyingpower === null || row.nonmarginbuyingpower === '') ? "-" : row.nonmarginbuyingpower === 0 ? "$0" : formatMoney(row.nonmarginbuyingpower)
                    let cashmarginavailabletowithdraw = (row.cashmarginavailabletowithdraw === undefined || row.cashmarginavailabletowithdraw === null || row.cashmarginavailabletowithdraw === '') ? "-" : row.cashmarginavailabletowithdraw === 0 ? "$0" : formatMoney(row.cashmarginavailabletowithdraw)
                    if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                    else
                        return (
                            <Tooltip id={`Tooltip${index}`} placement="right" value={(cell === 0) ? "$0" : formatMoney(cell)} >
                                <table width="100%">
                                    <tr>
                                        <td colspan="2"><div className="leftTextAlign">Margin Balance</div></td>
                                        <td align="right"><div className="rightTextAlign">{(cell === 0) ? "$0" : formatMoney(cell)}</div></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td><div className="leftTextAlign">Margin Interest Rate</div></td>
                                        <td align="right"><div className="rightTextAlign">{marginintrate}</div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><div className="leftTextAlign" >Cash &amp; Margin Available to Withdraw</div></td>
                                        <td align="right"><div className="rightTextAlign" >{cashmarginavailabletowithdraw}</div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><div className="leftTextAlign" >Cash & Margin Available to Trade</div></td>
                                        <td align="right">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td><div className="leftTextAlign">Fully Margin Eligible Securities</div></td>
                                        <td align="right"><div className="rightTextAlign">{cashmarginbuyingpower}</div></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td><div className="leftTextAlign">Non-Margin Eligible Securities</div></td>
                                        <td align="right"><div className="rightTextAlign">{nonmarginbuyingpower}</div></td>
                                    </tr>
                                </table>
                        </Tooltip>)
                },
                footer: (cell) => {
                    if (marginbalance === undefined || marginbalance === null || marginbalance === '') return <span>-</span>;
                    else if (marginbalance === 0) return <span>$0</span>;
                    else return <span>{formatMoney(marginbalance)}</span>;
                }, footerClasses: (column, colIndex) => {
                    return 'RCM_common_table_column_number';
                }
    
            }
        ]),
        {
            dataField: 'fixedincomeaccruedinterest', text: 'Accrued Interest', sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            },
            footer: (cell) => {
                if (totalfixedincomeaccruedinterest === undefined || totalfixedincomeaccruedinterest === null || totalfixedincomeaccruedinterest === '') return <span>-</span>;
                else if (totalfixedincomeaccruedinterest === 0) return <span>$0</span>;
                else return <span>{formatMoney(totalfixedincomeaccruedinterest)}</span>;
            }, footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
        },
        {
            dataField: 'pending', text: 'Pending Activity (Cash/Securities)', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            },
            footer: (cell) => {
                if (pendingactivity === undefined || pendingactivity === null || pendingactivity === '') return <span>-</span>;
                else if (pendingactivity === 0) return <span>$0</span>;
                else return <span>{formatMoney(pendingactivity)}</span>;
            }, footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
        },
        ...(BalSummaryV2PilotFlag? 
            [{
                dataField: 'marginandoutstandingbalance', text: 'Margin/Outstanding Balance', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', 
                formatter: (cell, row, index) => {
                    let marginintrate = (row.marginintrate === undefined || row.marginintrate === null || row.marginintrate === '') ? "-" : row.marginintrate === 0 ? "0%" : `${roundvalue(row.marginintrate,2)}%`
                    let cashmarginbuyingpower = (row.cashmarginbuyingpower === undefined || row.cashmarginbuyingpower === null || row.cashmarginbuyingpower === '') ? "-" : row.cashmarginbuyingpower === 0 ? "$0" : formatMoney(row.cashmarginbuyingpower)
                    let nonmarginbuyingpower = (row.nonmarginbuyingpower === undefined || row.nonmarginbuyingpower === null || row.nonmarginbuyingpower === '') ? "-" : row.nonmarginbuyingpower === 0 ? "$0" : formatMoney(row.nonmarginbuyingpower)
                    let cashmarginavailabletowithdraw = (row.cashmarginavailabletowithdraw === undefined || row.cashmarginavailabletowithdraw === null || row.cashmarginavailabletowithdraw === '') ? "-" : row.cashmarginavailabletowithdraw === 0 ? "$0" : formatMoney(row.cashmarginavailabletowithdraw)

                    if(row.isMargin){
                    return (
                        <Tooltip id={`Tooltip${index}`} placement="right" value={cell ? cell === 0 ? "$0" : formatMoney(cell) : '-'} >
                            <table width="100%">
                                <tr>
                                    <td colspan="2"><div className="leftTextAlign">Margin Balance</div></td>
                                    <td align="right"><div className="rightTextAlign">{(cell === 0) ? "$0" : formatMoney(cell)}</div></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><div className="leftTextAlign">Margin Interest Rate</div></td>
                                    <td align="right"><div className="rightTextAlign">{marginintrate}</div></td>
                                </tr>
                                <tr>
                                    <td colspan="2"><div className="leftTextAlign" >Cash &amp; Margin Available to Withdraw</div></td>
                                    <td align="right"><div className="rightTextAlign" >{cashmarginavailabletowithdraw}</div></td>
                                </tr>
                                <tr>
                                    <td colspan="2"><div className="leftTextAlign" >Cash & Margin Available to Trade</div></td>
                                    <td align="right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><div className="leftTextAlign">Fully Margin Eligible Securities</div></td>
                                    <td align="right"><div className="rightTextAlign">{cashmarginbuyingpower}</div></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><div className="leftTextAlign">Non-Margin Eligible Securities</div></td>
                                    <td align="right"><div className="rightTextAlign">{nonmarginbuyingpower}</div></td>
                                </tr>
                            </table>
                    </Tooltip>)
                    }else{
                        return <div>
                            {cell ? cell === 0 ? "$0" : formatMoney(cell) : '-'}
                        </div>
                    }
                },
                footer: (cell) => {
                    if (totalmarginandoutstandingbalance === undefined || totalmarginandoutstandingbalance === null || totalmarginandoutstandingbalance === '') return <span>-</span>;
                    else if (totalmarginandoutstandingbalance === 0) return <span>$0</span>;
                    else return <span>{formatMoney(totalmarginandoutstandingbalance)}</span>;
                }, footerClasses: (column, colIndex) => {
                    return 'RCM_common_table_column_number';
                }
            }
        ]: 
            [{
                dataField: 'outstandingbalance', text: 'Outstanding Balance', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                },
                footer: (cell) => {
                    if (totaloutstandingbalance === undefined || totaloutstandingbalance === null || totaloutstandingbalance === '') return <span>-</span>;
                    else if (totaloutstandingbalance === 0) return <span>$0</span>;
                    else return <span>{formatMoney(totaloutstandingbalance)}</span>;
                }, footerClasses: (column, colIndex) => {
                    return 'RCM_common_table_column_number';
                }
            }
        ]),
        {
            dataField: 'annuity', text: 'Insurance/Annuity', sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            },
            footer: (cell) => {
                if (insuranceandannuity === undefined || insuranceandannuity === null || insuranceandannuity === '') return <span>-</span>;
                else if (insuranceandannuity === 0) return <span>$0</span>;
                else return <span>{formatMoney(insuranceandannuity)}</span>;
            }, footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
        },
        {
            dataField: 'value',
            text: 'Value',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>0</span>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;

            },
            footer: (cell) => {
                if (totalaccountvalue === undefined || totalaccountvalue === null || totalaccountvalue === '') return <span>-</span>;
                else if (totalaccountvalue === 0) return <span>$0</span>;
                else return <span>{formatMoney(totalaccountvalue)}</span>;

            }, footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
        }
    ]
    return columns;

}


function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function acctFormatter(accountlist, key) {
    return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}</div>)
}