export const CURRENT_YEAR = "Current_Year";
export const PREVIOUS_YEAR = "Previous_Year";
export const contributionYear = [
    {
        "label": "Current Year Contribution",
        "value": CURRENT_YEAR
    },
    {
        "label": "Previous Year Contribution",
        "value": PREVIOUS_YEAR
    }
];

export const currentYearContribution =
{
    "label": "Current Year Contribution",
    "value": CURRENT_YEAR
}


export const previousYearContribution =
{
    "label": "Previous Year Contribution",
    "value": PREVIOUS_YEAR
}

export const genericErrorMsg = "We could not process your request at this time. Please try again or contact your Advisor for assistance.";
export const noEligiblePairExist = "You need to have at least 2 eligible accounts to do a transfer. You may add an external account or contact your Financial Advisor for Assistance."
export const noEligilblePairExistForMangAcct = "You need to have at least 1 eligible account to add external account";
export const thirdPartyErrorMsg = "Adding 3rd Party Accounts is currently not available. Please contact your Advisor for assistance."

export const errorObjExternalAcct = {
    acctNickName: {
        type: "ERROR",
        text: 'Please enter account nick name',
        class: "RCM_validation_error_text"
    },
    acctNumber: {
        type: "ERROR",
        text: 'Please enter account number',
        class: "RCM_validation_error_text"
    },
    abaNumber: {
        type: "ERROR",
        text: 'Please enter ABA routing number',
        class: "RCM_validation_error_text"
    }, 
    acctType: {
        type: "ERROR",
        text: "Please enter account type",
        class: "RCM_validation_error_text"
    },   
    thirdparty: {
        type: "ERROR",
        text: "Please select account ownership",
        class: "RCM_validation_error_text"
    },
    fromaccount:{
        type: "ERROR",
        text: "Please select transfer allowed from",
        class: "RCM_validation_error_text"
    },
    bankAcctName:{
        type: "ERROR",
        text: "Please enter account holder name",
        class: "RCM_validation_error_text"
    },
    fileSelected:{
        type: "ERROR",
        text: "Please select file to upload",
        class: "RCM_validation_error_text"
    }   
};

export const thirdpartyTypes = [
    {"value": "self",  "description" : "My Account"}, {"value": "other",  "description" : "Someone Else's Account"}
];

export const acctTypes = [
    {"value": "C", "text" : "Checking Account"},
    {"value": "S", "text" : "Savings Account"}
];