import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { getReportsColumns } from './reportscolumns.jsx'
import Messages from './../../../common/messages.jsx';
import { spinner, MsgForFailedData, MessageType, MessageClass, MsgForNoData } from './../../../common/constants.jsx';
import Watermark from './../../../common/watermark';
import OverlayProcessing from './../../MoneyMovement/common/overlayProcessing';
export const defaultSorted = [{ dataField: 'date', order: 'desc' }];
export function ReportsView(
    props
) {
    const selectRow = {
        mode: 'radio',
        selected: props && props.state && props.state.selectedReport,
        onSelect: props && props.state && props.previewDocument,
        clickToSelect: true
    };

    const downloadFailedMessage = [{
        type: MessageType.INFO,
        text: "Report download is failed.Please try again later",
        class: MessageClass.ERROR
    }]

    const printFailedMessage = [{
        type: MessageType.INFO,
        text: "Print report is failed.Please try again later",
        class: MessageClass.ERROR
    }]

    let reportview = (
        <div className="container-fluid">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            {props && props.state.isPrintReport || props && props.state.isDownloadingReport ? <OverlayProcessing /> : ''}
            {
                props && props.state.isDownloadingReportFailed ?
                    <div className="row RCM_Document_Message_Margin">
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                            <Messages messages={downloadFailedMessage} containerClass="RCM_validation_error_container" />
                        </div>
                    </div> : ''
            }
            {
                props && props.state.isPrintReportFailed ?
                    <div className="row RCM_Document_Message_Margin">
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                            <Messages messages={printFailedMessage} containerClass="RCM_validation_error_container" />
                        </div>
                    </div> : ''
            }
            {
                    props && props.state.isDocumentListResponseWithError && (
                        <div className="row RCM_Document_Message_Margin">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                <Messages messages={[{text: 'We are unable to load report list', class: MessageClass.ERROR}]} containerClass="RCM_validation_error_container" />
                            </div>
                      </div>
                    )
            }
            <div className={
                props && props.state && props.state.reportResponse && props.state.reportResponse.length === 0
                    ? 'row RCM_Document_Details_Row'
                    : 'row'
            }>
                {
                    props && props.state && props.state.areDocumentsRetrieved
                        ?
                        <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5" id="RCM_stmtaccdetails">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    <div key="reports_div">
                                        <div key="reports_div">
                                            {
                                                props.data.length !== 0
                                                    ?
                                                    props.data.map(folder =>
                                                        <>
                                                            <h6>{folder.name && folder.name !== 'Reports' ? folder.name : ''}</h6>
                                                            <BootstrapTable
                                                                keyField="id"
                                                                data={folder.reportlist}
                                                                columns={getReportsColumns(props.printReport, props.downloadReport)}
                                                                bordered={false}
                                                                classes="RCM_two_level_table1"
                                                                selectRow={selectRow}
                                                                defaultSorted={defaultSorted}
                                                            >
                                                            </BootstrapTable>
                                                        </>

                                                    )
                                                    : <><div>{MsgForNoData}</div></>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                        :
                        <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5" id="RCM_stmtaccdetails">
                            <div className="RCM_Toast RCM_Document_Toast">
                                <div>
                                    {
                                        props && props.state && props.state.isDocumentListResponseWithError
                                            ? <div>
                                                <p> {MsgForFailedData}</p>
                                            </div>
                                            : <div className="RCM_spinner">Loading Report List{spinner()}</div>

                                    }
                                </div>
                            </div>
                        </div>

                }
                <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                    {props && props.state && props.state.isloadingReport ?
                        <div className="RCM_spinner">Loading Report Preview {spinner()}</div>
                        : props && props.state && props.state.isloadingReportFailed ?
                            <div>
                                <p> {MsgForFailedData}</p>
                            </div>
                            : <div className="RCM_Toast RCM_Document_Toast" id="RCM_accstmtpreview">
                                {
                                    props && props.state && props.state.pdfUrl ?

                                        <div className="RCM_PdfViewer_Border">
                                            <iframe
                                                type="application/pdf"
                                                src={props.state.pdfUrl}
                                                width="100%"
                                                height="700px"
                                            />
                                        </div> : props && props.state.areDocumentsRetrieved && props.state.reportResponse && props.state.reportResponse.length > 0 ? <p>Please Select an item to preview the report</p> : ''
                                }
                            </div>
                    }


                </div>
            </div>

        </div>



    )
    return reportview
}

