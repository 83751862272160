import React, { Component } from 'react';

import { Post } from './servicecalls.jsx';
import { GetPreferenceValue, PreferencesUrl} from './constants.jsx';
import { connect } from 'react-redux';
import axios from 'axios';
import ErrorBoundary from './errorboundary.jsx';
import { updatePreference } from "../actions/accountsactions.jsx";
import { Tooltip } from 'reactstrap';

class FontSizing extends Component {
    constructor(props) {
        super(props);
        this.increaseFontSize = this.increaseFontSize.bind(this);
        this.decreaseFontSize = this.decreaseFontSize.bind(this);
        this.resetFontSize = this.resetFontSize.bind(this);
        this.toggleDecreaseTooltip = this.toggleDecreaseTooltip.bind(this);
        this.toggleResetTooltip = this.toggleResetTooltip.bind(this);
        this.toggleIncreaseTooltip = this.toggleIncreaseTooltip.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = {
            fontSize: 16,
            decreaseTooltipOpen: false,
            resetTooltipOpen: false,
            increaseTooltipOpen: false
        }

    }
    updateDimensions() {
        var appliedFontSize = 0;
        if (window.innerWidth >= 1920) {
            appliedFontSize = this.state.fontSize;
        } else if (window.innerWidth < 1920 && window.innerWidth >= 1600) {
            appliedFontSize = this.state.fontSize - 1;
        } else if (window.innerWidth < 1600 && window.innerWidth >= 1366) {
            appliedFontSize = this.state.fontSize - 2;
        } else if (window.innerWidth < 1366 && window.innerWidth >= 1024) {
            appliedFontSize = this.state.fontSize - 3;
        } else if (window.innerWidth < 1024 && window.innerWidth >= 640) {
            appliedFontSize = this.state.fontSize - 4;
        } else {
            appliedFontSize = this.state.fontSize - 5;
        }
        appliedFontSize = appliedFontSize + "px";
        var root = document.documentElement;
        root.style.setProperty('font-size', appliedFontSize);
        
    }

    componentDidMount() {
        if (this.props.isFontScaleRequired) {
            var fontSize = GetPreferenceValue(this.props.acct, "FontScale", "16");
            this.setState({ fontSize: parseInt(fontSize) }, () => {
                this.updateDimensions();
            })
        }
        else {
            this.updateDimensions();
        }
    }
    
    increaseFontSize() {
        var fontSize = this.state.fontSize + 1;
        this.setState({ fontSize: fontSize }, () => {
            this.updatePreferenceCallBack();
            this.updateDimensions();
            document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
        })
    }
    
    decreaseFontSize() {
        var fontSize = this.state.fontSize - 1;
        this.setState({ fontSize: fontSize }, () => {
            this.updatePreferenceCallBack();
            this.updateDimensions();
            document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
        })
    }
    
    resetFontSize() {
        var fontSize = 16;
        this.setState({ fontSize: fontSize }, () => {
            this.updatePreferenceCallBack();
            this.updateDimensions();
            document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
        })
    }
    toggleDecreaseTooltip() {
        this.setState({
            decreaseTooltipOpen: !this.state.decreaseTooltipOpen
        });
    }
    toggleResetTooltip() {
        this.setState({
            resetTooltipOpen: !this.state.resetTooltipOpen
        });
    }
    toggleIncreaseTooltip() {
        this.setState({
            increaseTooltipOpen: !this.state.increaseTooltipOpen
        });
    }
    fontScalecanellationToken = null;
    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.fontScalecanellationToken = axios.CancelToken.source();
        var fontsize = this.state.fontSize + "";
        let componentjson = [];
        componentjson.push({ "key": "FontScale", "value": fontsize})
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.acct.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            let fontpreferencevalue = reqObj1.component.filter(component => component.key === "FontScale");
            if (fontpreferencevalue && fontpreferencevalue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "FontScale")].value = fontsize;
            }
            else {
                reqObj1.component.push({ "key": "FontScale", "value": fontsize});
            }
        }

        if (this.props.role !== "Prospect") {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.fontScalecanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        this.props.updatePreference(reqObj1);
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                });
        }
        else {
            this.props.updatePreference(reqObj1);
        }
    }

    render() {
        return (
           
            <ErrorBoundary >
                    <div className={"RCM_font_sizing_container " + (this.props.isFontScaleRequired ? ' ' : ' RCM_font_sizing_container_hidden')}>
                        <Tooltip placement="left" isOpen={this.state.decreaseTooltipOpen} target="decreaseTooltip" toggle={this.toggleDecreaseTooltip} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                            Decrease font size.
                        </Tooltip>
                        <span className={"RCM_font_sizing RCM_font_sizing_small " + (this.state.fontSize < 14 ? 'RCM_font_sizing_disabled' : ' ')} onClick={this.decreaseFontSize.bind()} id="decreaseTooltip">A-&nbsp;</span>

                        <Tooltip placement="left" isOpen={this.state.resetTooltipOpen} target="resetTooltip" toggle={this.toggleResetTooltip} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                            Reset font size to default.
                        </Tooltip>
                        <span className="RCM_font_sizing RCM_font_sizing_normal" onClick={this.resetFontSize.bind()} id="resetTooltip">A&nbsp;</span>
                        <Tooltip placement="left" isOpen={this.state.increaseTooltipOpen} target="increaseTooltip" toggle={this.toggleIncreaseTooltip} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                            Increase font size.
                        </Tooltip>
                        <span className={"RCM_font_sizing RCM_font_sizing_big " + (this.state.fontSize > 18 ? 'RCM_font_sizing_disabled' : ' ')} onClick={this.increaseFontSize.bind()} id="increaseTooltip">A+</span>
                    </div>
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        token: state.acct.token,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        preferences: state.acct.preferences,
        role: state.acct.role,
        acct: state.acct,
        profileId: state.acct.profileId,
        role:state.acct.role
    };
};

const mapDispatchToProps = {
    updatePreference
}

export default connect(mapStateToProps, mapDispatchToProps)(FontSizing);