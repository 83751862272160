import React from 'react';
import { MsgForNoData, addIndexToList, spinner, MessageClass } from '../../../common/constants.jsx';
import Messages from '../../../common/messages.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from "react-bootstrap-table2-editor";
import { DeleteModalPopup } from './deletemodalpopup.jsx';
import { AddEditGroupModalPopup } from './addeditgroupmodalpopup.jsx';
import UpdateNicknameModalPopup from './updatenicknamemodalpopup.jsx';
import Watermark from '../../../common/watermark'; 
export function ManageGroupsView(
    props
) {
    let managegroupsview = (
        <div>
            {
                (props && props.state && !props.state.isManageGroupsLoading)
                    ? props && props.state && props.state.messages && props.state.messages.length > 0
                        ?
                        <div className="row RCM_Feedback_Message_Margin" key="alerts03">
                            <div className="col-sm-12 col-xs-12 col-md-11 col-lg-9" key="alerts04">
                                <Messages messages={props && props.state && props.state.messages}
                                    containerClass={
                                        props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                            ? "RCM_validation_error_container"
                                            : ""
                                    } />


                            </div>

                        </div>
                        : ""
                    : ""
            }
            <div className="row RCM_ManageGroup_heading_container RCM_alert_wrapper" key="alerts05">
                <div className="RCM_Toast_heading col-sm-12 col-xs-12 col-md-12 col-lg-12" key="alerts06">MANAGE GROUPS</div>
            </div>
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            {props && props.state && props.state.isManageGroupsLoading
                ? <div className="RCM_spinner" key="alerts07">Loading Manage Groups{spinner()}</div>
                :
                <div className="RCM_Toast" id="RCM_ManageGroups">
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6">

                            <div id="group_table" className="RCM_ManageGroup_Tables RCM_CM_scroll">
                                <BootstrapTable
                                    keyField='groupid'
                                    data={props.state.groupsdata}
                                    columns={props.columns}
                                    headerClasses="row-bg"
                                    bordered={false}
                                    rowClasses={props.grpTableRowClasses}
                                    classes="RCM_two_level_table1 RCM_grp_table"
                                    noDataIndication="There is no group. Please add new group."
                                    //cellEdit={props.cellEditFactory({ mode: 'dbclick', blurToSave: true, afterSaveCell: props.handleChange })}
                                    expandRow={props.expandRow}
                                    selectRow={props.selectGroupRow}

                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6">

                            <div className="row">
                                <div className="col-sm-5 col-xs-5 col-md-5 col-lg-5 RCM_ManageGroup_heading_container" style={{ height: '2.5rem', marginBottom: '0.5rem' }}>
                                    {(props.state.activeGroup ? <div>{props.state.activeGroup.groupname ? `Accounts in ${props.state.activeGroup.groupname}` : `Accounts`}</div> : <div className="RCM_Toast_heading col-sm-6">Accounts</div>)}
                                </div>
                                <div className="col-sm-7 col-xs-7 col-md-7 col-lg-7" id="RCM_accountSearch">
                                    <input 
                                        type="text" 
                                        className="RCM_form_control" style={{marginTop: 0}}
                                        placeholder="Search for your account" 
                                        onChange={(e) => props.handleAccountFilter(props.state.accounts, e)}
                                        value={props.filterKey}
                                        disabled={props.state.isProspectClient}
                                    />
                                    <button className="RCM_Textbox_close-icon"
                                        type="reset"
                                        onClick={() => props.resetFilter(props.state.accounts)}
                                        disabled={props.state.isProspectClient}
                                    ></button>
                                </div>
                            </div>

                            {(props.state.activeGroup ?
                                <div>
                                    <div id="account_table" className="RCM_ManageGroup_Tables RCM_CM_scroll">
                                        <BootstrapTable
                                            keyField='UniqueIdx'
                                            data={addIndexToList(props.state.filteredAccountList)}
                                            columns={props.accountsGridColumns}
                                            //selectRow={props.selectRow}
                                            bordered={false}
                                            rowClasses={props.rowClasses}
                                            classes="RCM_two_level_table1 RCM_manage_group_account_table"
                                            cellEdit={
                                                cellEditFactory(
                                                    {
                                                        mode: 'dbclick',
                                                        blurToSave: true,
                                                        beforeSaveCell: (oldValue, newValue, row, column, done) => props.beforeSaveCell(oldValue, newValue, row, column, done),
                                                        //afterSaveCell: (oldValue, newValue, row, column) => props.afterSaveCell(oldValue, newValue, row, column)
                                                    })
                                            }
                                        />
                                    </div>
                                    <div key="Dropdown14" className="RCM_manage_group_saveBtn_container">
                                        <button key="Dropdown16" type="button" className="btn btn-primary RCM_button_primary"
                                            disabled={props.state.isProspectClient}
                                            onClick={props.save}>SAVE</button>
                                    </div>
                                </div>
                                :
                                <div>No group selected. Please select a group.</div>
                            )}

                        </div>

                    </div>

                    <AddEditGroupModalPopup
                        state={props.state}
                        toggleModal={props.toggleModal}
                        handleFormChanges={props.handleFormChanges}
                        addGroup={props.addGroup}
                        editGroupName={props.editGroupName}
                    />

                    <DeleteModalPopup
                        state={props.state}
                        toggleDeleteModal={props.toggleDeleteModal}
                        deleteGroup={props.deleteGroup}
                        deleteAccount={props.deleteAccount}
                    />

                    <UpdateNicknameModalPopup
                        state={props.state}
                        toggleNicknameModal={props.toggleNicknameModal}
                        updateNickName={props.updateNickName}
                    />

                </div>

            }
        </div>
    )
    return managegroupsview
}



