import React, { Component } from 'react';
import { compose } from 'redux';
import { withPageView } from '../../hoc/withPageView.jsx';

class Feedback extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        //Put Page view
       /*  let pageViewData =  new PageView();
        pageViewData.name = "Feedback";
        pageViewData.url = "/feedback"; 
        _logPageView(pageViewData); */

        console.log("inside feedback did mount, portal id: " + this.props.name);
    }


    render() {
        console.log("Inside render")

        return (
            <div id="RCM_Feedback_Form">
                <iframe width="100%" height="680px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=pSo1dIPYSk6EI3his0JwjqlM0g0ZbpdLneNLHcPxOv5UQ0w2MDRZQTFBMVNBUVZSVkxGT1lIUlNPSCQlQCN0PWcu&embed=true" frameBorder="0" marginWidth="0" marginHeight="0" allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" msallowfullscreen="true"> </iframe>
            </div>
                );
    }
}


const enhancement = compose(
    withPageView({pageName: 'Feedback'})
)

export default enhancement(Feedback);
