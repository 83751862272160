import React, { Component } from 'react';
import Chart from './chart.jsx';
import { MsgForNoData } from './constants.jsx';
class TopTenChartDetails extends Component {


    getClassName(val) {
        if (val == '') {
            return ''
        } else if (val >= 0) {
            return "arrow-up";
        }
        else {
            return "arrow-down";
        }
    }

    render() {
        var rows = this.props.data;
        function isRowCheck(dataname) {
            let val = (dataname && (dataname !== '-')) ? dataname.replace(/[$,%]/g, '') : "";
            if (val !== '')
                return parseFloat(val);
            else return val;
        }
        return (
            <div >
                {this.props.datachart && this.props.datachart.length !== 0 ?
                    <div className="row">
                        <div className='col-sm-8 RCM_barChart'>
                            <Chart data="bar" chartdata={this.props.datachart} />
                        </div>
                        <div className='col-sm-4 RCM_holdinfsection'>
                            <ul className="RCM_holdingWrapper">
                                {rows.map((holding, index) => {
                                    return <li key={index} className="RCM_li_topten">
                                        <div className="RCM_holdingdiv">
                                            <div className="RCM_holdingname"> <span>{holding.name}</span></div>
                                            <div className="RCM_value_arrow_wrapper">
                                                <div className="RCM_holdingvalue">{holding.value}</div>
                                                <div className={this.getClassName(isRowCheck(holding.value))}></div>
                                            </div>
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div> </div> : <div>{MsgForNoData}</div>}
            </div>
        );
    }
}
export default TopTenChartDetails;