import React, { PureComponent } from "react";
import groupBy from "lodash.groupby";
import BootstrapTable from "react-bootstrap-table-next";
import {
    getAssetGroups, getTotalRowsforAssetGroup, getMappedData, GetUniqueTimedata
} from './HistoricalAssetAllocationService';
import { getAssetColorConfig } from './../../../../common/assetschemautilities.jsx'
import {
    formatMoney,
    MsgForNoData,
    getAccountDisplayName,
    IndLongShort
} from "../../../../common/constants";

export class HistoricalAssetAllocationTableComponent extends PureComponent {
    render() {
        const { data, accounts, chartType } = this.props;

        if (!data.length) {
            return (
                <div>
                    <p className="RCM_data_error">{MsgForNoData}</p>
                </div>
            );
        }

        const mappedData = getMappedData(data);

        const allTimes = GetUniqueTimedata(mappedData);

        const GetBarTableData = () => {
            const assetGroups = getAssetGroups(mappedData, accounts);
            const assetTotalRows = getTotalRowsforAssetGroup(assetGroups, allTimes);
            for (var i = 0; i < assetTotalRows.length; i++) {
                if (assetTotalRows[i].assetType !== null) {
                    let assetObj = getAssetColorConfig(assetTotalRows[i].assetType, assetTotalRows[i].assetcatcd, this.props.assetschemacolorsconfig, this.props.assetSchema)
                    assetTotalRows[i].colorCd = assetObj.colorcd;
                    assetTotalRows[i].displaySeq = assetObj.displayseq;
                }

            }
            if (assetTotalRows && assetTotalRows.length > 0) {
                assetTotalRows.sort(function (a, b) { return a.displaySeq - b.displaySeq; });
            }
            return assetTotalRows;
        }

        const GetAreaTableData = (mappedData, allTimes) => {
            const groupedByAccount = groupBy(mappedData, item => item.accountNumber);
            const rows = [].concat(
                ...Object.keys(groupedByAccount)
                    .map(accountNumber => {
                        const accountItems = groupedByAccount[accountNumber];
                        const groupedByAssetType = groupBy(
                            accountItems,
                            accountItem => accountItem.assetType
                        );
                        return Object.keys(groupedByAssetType).map(assetType => {
                            const assetItems = groupedByAssetType[assetType];
                            return {
                                key: `${accountNumber}${assetType}`,
                                accountNumber,
                                assetType: assetItems[0].assetType,
                                ...assetItems.reduce((a, assetItem) => {
                                    const marketValue = parseFloat(assetItem.marketValue || 0);
                                    const existingValue = a[assetItem.time];
                                    // TODO - figure out why there's dupe entries for the same thing
                                    if (existingValue) {
                                        a[assetItem.time] = existingValue + marketValue;
                                    } else {
                                        a[assetItem.time] = marketValue;
                                    }
                                    return a;
                                }, {})
                            };
                        });
                    })
                    .sort((a, b) => (a.assetType > b.assetType ? 0 : 1))
            );

            const accountGroups = groupBy(rows, x => x.accountNumber);
            const accountTotalRows = Object.keys(accountGroups).map(accountNumber => {
                const assetItems = accountGroups[accountNumber];
                return {
                    key: `${accountNumber}`,
                    accountNumber,
                    assetType: assetItems.assetType,
                    meta: { expanded: false },
                    accountnickname: getAccountDisplayName(accounts, `${accountNumber}`, IndLongShort.SINGLEVIEW),

                    ...allTimes.reduce((a, time) => {
                        const value = assetItems
                            .map(x => x[time])
                            .reduce((a, x) => a + (x || 0), 0);

                        a[time] = value;
                        return a;
                    }, {})
                };
            });
            return accountTotalRows;
        }

        const getlevel1ChildRowData = (row) => {
            let data = getAssetGroups(mappedData, accounts)[row.assetType];
            return data;
        }

        const moneyFormatter = value => {
            if (value === null || value === "" || value === undefined) {
                return <span>-</span>;
            } else if (value === 0) {
                return <span>$0</span>;
            } else {
                return <span>{formatMoney(value)}</span>;
            }
        };

        const textFormatter = value => {
            return <span>{value}</span>;
        };

        const dateColumnStyles = { width: "10%" };
        const dateColumns = allTimes.map(dateItem => {
            return {
                dataField: "" + dateItem,
                text: new Date(dateItem).toLocaleString("default", {
                    month: "short",
                    year: "numeric"
                }),
                sort: true,
                headerAlign: "right",
                classes: "RCM_common_table_column_number",
                headerStyle: dateColumnStyles,
                style: dateColumnStyles,
                formatter: moneyFormatter
            };
        });

        const headerColumnStyle = { width: "15%" };
        const headerColumn = {

            dataField: "accountnickname",
            text: "Account Number",
            sort: true,
            headerStyle: headerColumnStyle,
            style: headerColumnStyle,
            formatter: (value,row) => {
                return (
                    <div title={getAccountDisplayName(accounts, row.accountNumber, IndLongShort.LONG)}>{getAccountDisplayName(accounts, row.accountNumber, IndLongShort.SINGLEVIEW)}</div>
                );
            }
        };
        const customCaretSort = (order) => {
            if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
            else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
            else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
            return null;
        }
        const totalColumnsBarChart = [
            {
                dataField: "assetType",
                text: "Asset Class",
                sort: true,
                headerStyle: headerColumnStyle,
                style: headerColumnStyle,
                formatter: value => {
                    return (
                        <span>{value}</span>
                    );
                },
                footer: () => <span>Total</span>,
                footerStyle: headerColumnStyle,
                sortCaret: (order, column) => customCaretSort(order, column),
            },
            ...dateColumns.map(x => ({
                ...x,
                footer: columnData => (
                    <span>{moneyFormatter(columnData.reduce((a, x) => a + x, 0))}</span>
                ),
                footerSytle: dateColumnStyles,
                footerClasses: "RCM_common_table_column_number",
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
            }))
        ];

        const totalColumnsAreaChart = [
            {
                ...headerColumn,
                footer: () => <span>Total</span>,
                footerStyle: headerColumnStyle,
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
            },
            ...dateColumns.map(x => ({
                ...x,
                footer: columnData => (
                    <span>{moneyFormatter(columnData.reduce((a, x) => a + x, 0))}</span>
                ),
                footerSytle: dateColumnStyles,
                footerClasses: "RCM_common_table_column_number",
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
            }))
        ];
        const detailColumns = [
            {
                ...headerColumn,
                formatter: textFormatter,
                style: {
                    ...headerColumn.style,
                    paddingLeft: "15px"
                }
            },
            ...dateColumns,
            {
                sort: false,
                headerStyle: { width: "30px" },
                style: { width: "30px" },
            }
        ];

        const expandrow = {
            renderer: row => (
               
                <BootstrapTable
                    keyField="key"
                    classes="RCM_two_level_table2 fixed-table RCM_Headerless_table"
                    data={getlevel1ChildRowData(row) }
                    columns={detailColumns}
                    bordered={false}
                    noDataIndication={MsgForNoData}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            onExpand: (row, isexpanded) => {
                row.meta.expanded = isexpanded;
            },
            expandHeaderColumnRenderer: () => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }

                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
        };

        return (
            <div style={{ minWidth: "1000px" }}>
                <BootstrapTable
                    keyField="key"
                    classes="RCM_two_level_table1 fixed-table"
                    data={chartType === 'area' ? GetAreaTableData(mappedData, allTimes) : GetBarTableData(mappedData, allTimes)}
                    columns={chartType === 'area' ? totalColumnsAreaChart : totalColumnsBarChart}
                    bordered={false}
                    expandRow={chartType === 'area' ? { } : expandrow}
                    noDataIndication={MsgForNoData}
                />
            </div>
        );
    }
}
