import axios from './servicecallsInterceptor';
import { authProvider } from '../../App';
import { apiVersion } from './constants';

export async function Get(url, token, context, profile, cancellationSource) {
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "contextjson": `${context}`,
        "profilejson": `${profile}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600
    };
    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;

   return axios({
        method: 'get',
        url: url,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    }); 
}

export async function Post(url, requestBody, token, context, profile, cancellationSource) {
    
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "contextjson": `${context}`,
        "profilejson": `${profile}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600
    };
    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;

    return axios({
        method: 'post',
        url: url,
        data: requestBody,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    })
    
}

export function PostMultiPart(url, requestBody, token, context, profile, cancellationSource, onUploadProgress){
    
    let headers = {
        //Note:  Ask apim to have allowed header * to upload multipart files
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "contextjson": `${context}`,
        "profilejson": `${profile}`,
    }
    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;

    return axios({
        method: 'post',
        url: url,
        data: requestBody,
        headers: headers,
        onUploadProgress: onUploadProgress? (progressEvent) => { onUploadProgress(progressEvent) }:null,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    })
}

export async function Put(url, requestBody, token, context, profile, cancellationSource) {
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "contextjson": `${context}`,
        "profilejson": `${profile}`,
        "Cache-Control": "No-Store"
    };
    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;
    return axios({
        method: 'put',
        url: url,
        data: requestBody,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    })
}

export async function getAccessToken(){
    //try silient login and get the refreshed token
    
    let getAccessTokenPromise = new Promise(function(resolve, reject){
        if(authProvider != null && authProvider !== undefined 
            && authProvider._userAgentApplication !== null && authProvider._userAgentApplication !== undefined)
        {
            authProvider.getAccessToken().then(token => {
                resolve(token);
            }).catch(err => {
                console.log("Error while getting the access token from cache ", err);
                reject(err);
            })
        }
        else{
            //
            reject("No valid authproivder exist.");
        }
    });
    
    return await getAccessTokenPromise;
}


export function ninthwavesGet(url, appId, nwtoken, cancellationSource, token){

    let headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "ApplicationId": `${appId}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "nwauth": `${nwtoken}`
    };

    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;

   return axios({
        method: 'get',
        url: url,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    }); 

}

export function ninthwavesPost(url, requestBody, appId, nwtoken, cancellationSource, token){

    let headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "ApplicationId": `${appId}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "nwauth": `${nwtoken}`
    };

    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;

   return axios({
        method: 'post',
        url: url,
        headers: headers,
        data: requestBody,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    }); 

}

export async function GetPhilanthropy(url, token, context, profile, cancellationSource) {//it's added only for testing purpose and should be removed when Philanthropy is working with contextjson
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "contextjson": `${context}`,
        "profilejson": `${profile}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "partyid":12345
    };
    if (apiVersion && apiVersion.length > 0)
        headers.version = `${apiVersion}`;

    return axios({
        method: 'get',
        url: url,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token) ? cancellationSource.token : null
    });
}

export async function PostGraph(url, requestBody, token) {
    
    let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "Cache-Control": "No-Store"
    };

    return axios({
        method: 'post',
        url: url,
        data: requestBody,
        headers: headers
    })
    
}

export async function DeleteGraph(url, token, cancellationSource) {
    
    let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`,
        "Cache-Control": "No-Store",
        cancelToken: (cancellationSource && cancellationSource.token) ? cancellationSource.token : null
    };

    return axios({
        method: 'delete',
        url: url,
        headers: headers
    })
    
}
export function Guid() {

    return (([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    ))
}