import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import VirtualSpecialityCareConfirmationModal from "./virtualSpecialityCareConfirmationModal";

const VirtualSpecialityCare = () => {
  const virtualSpecialityCareText =
    "Summus, providing virtual personalized medical advice and direct video access to leading physicians around the world with concierge service for clients and their extended family members.";
 const summasURL = useSelector((state) => state?.env?.summusURL)
  const [isVirtualSpecialityCareModalOpen, setVirtualSpecialityCareModalOpen] =
    useState(false);

  const handleViewVirtualSpecialityCareClick = useCallback(() => {
    setVirtualSpecialityCareModalOpen(true);
  }, []);

  const handleVirtualSpecialityCareConfirmClick = useCallback(() => {
    window.open(summasURL)
    setVirtualSpecialityCareModalOpen(false);
  }, [summasURL]);

  const toogleVirtualSpecialityCareModal = useCallback(() => {
    setVirtualSpecialityCareModalOpen((prev) => !prev);
  }, []);

  return (
    <div className="container">
      <img
        id="virtual_specialoty_care_image"
        src="./../images/summas_lending.png"
        alt="Virtual Speciality Care"
        width="100%"
        className="RCM_health_base_image"
      />
      <div>
        <div className="row RCM_health_container">
          <div className="col col-sm-12 mt-1">
            <div
              className="RCM_Toast RCM_health_toast"
              id="RCM_cyberwa_details"
            >
              <div className="RCM_lifestyle_homeThrive"></div>
              <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
                VIRTUAL SPECIALITY CARE
              </div>
              <div className="mt-2 RCM_aviation_toast_content_container">
                {virtualSpecialityCareText}
              </div>
              <div className="mt-4 RCM_lifestyle_btns" align="center">
                <button
                  className="btn btn-primary RCM_button_aviation_page"
                  onClick={handleViewVirtualSpecialityCareClick}
                >
                  CLICK HERE TO VIEW SUMMUS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVirtualSpecialityCareModalOpen && (
        <VirtualSpecialityCareConfirmationModal
          isOpen={isVirtualSpecialityCareModalOpen}
          toogleModal={toogleVirtualSpecialityCareModal}
          onContinueClick={handleVirtualSpecialityCareConfirmClick}
        />
      )}
    </div>
  );
};

export default VirtualSpecialityCare;
