import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { spinner, nwScriptUrl, nwDbUrl, ninthwavesAppId, aggregatedAcctAddMsg, getUpdatedAccountList, GetPreferencePilotValue, addIndexToList, balanceActicityAggreationDisclaimer } from '../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { assetAggrGridColumns, defaultSorted } from "./assetaggregationcolumns";
import AssetAggregationModal from "./assetaggregationmodal.jsx";
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { getAALoginToken, updateExternalAccountsList, getAALoginCancellationToken, updateExternalAccountsListCancellationToken, getExternalAccountsList, deleteExternalAccount } from "./assetaggregationservice";
import { loadExternalJS, getNestedObject } from "../../../MoneyMovement/common/utilty";
import OverlayProcessing from '../../../MoneyMovement/common/overlayProcessing.jsx';
import { updateAggregatedAccounts, addAggregatedAccounts } from '../../../../actions/accountsactions';
import { getUserProfile } from '../../../../services/home/userServices.jsx';
import { getNwAggregatedAccounts } from './models/assetAggregationInfo.js';
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { compose } from 'redux';
import { HelpAndSupportModal } from '../../../CaseManager/helpandsupportmodal.jsx';
import { Link } from 'react-router-dom';
import NinthWaveContainer from '../../../Ninthwaves/ninthWaveContainer.jsx';
class AssetAggregationDetails extends Component {


    constructor(props) {
        super(props);

        this.token = null;
        this.token = this.props.token ? this.props.token : null;
        this.contextjson = null;
        this.contextjson = this.props.contextjson ? this.props.contextjson : null;
        this.profilejson = null;
        this.profilejson = this.props.profilejson ? this.props.profilejson : null;

        this.state = {
            requestFailed: false,
            error: null,
            render: this.props.render,
            isloading: false,
            isNwCompLoading: false,
            areDataRetrieved: false,
            assetAggrModalState: false,
            userDetails: {
                firstName: getNestedObject(props.profileResp, ["firstname"]),
                lastName: getNestedObject(props.profileResp, ["lastname"]),
                aadUserId: (props.aaduseroid) ? props.aaduseroid : "",
                emailId: (props.userEmailId) ? props.userEmailId : ""
            },
            nwtoken: '',
            extassetaggregationid: '',
            showNinthWavesWebCompnent: false,
            aggregatedAccountList: [],
            showAddAcctMsg: false,
            aboutToDeleteAccount: {},
            theme: sessionStorage.getItem('theme') === 'Dark' ? 'dark' : 'light',
            openHelpAndSupportModal: false,
            showAddAssetLink: false,
            nwErrorMsg:'',
            showRCMNinthWaveComp: this.ninthwaveCompPreference(this.props),
            resetPasswordFi: undefined,
            selectedAcctkey: undefined
        }

        this.onReteriveAccountEvents = this.onReteriveAccountEvents.bind(this);
        this.onDeleteAggregatedModal = this.onDeleteAggregatedModal.bind(this);
        this.fetchExternalAccountList = this.fetchExternalAccountList.bind(this);
        this.refreshAccountSelector = this.refreshAccountSelector.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
        this.toggleHelpAndSupportModal = this.toggleHelpAndSupportModal.bind(this);
        this.nwscripturl = nwScriptUrl;
        this.nwDbUrl = nwDbUrl;
        //this.nwAppId = "7EFF0631-78EF-415E-9BCE-57ED8E74ABFB";
        this.nwAppId = ninthwavesAppId;
        this.showNewAcctMessage = this.showNewAcctMessage.bind(this);
        this.resetPassword = this.resetPassword.bind(this)
        
    }


    componentDidUpdate(prevProps) {

            if (this.props.render !== prevProps.render) {
                this.setState({
                    aggregatedAccountList: []
                }, () => {
                    if(!this.state.showRCMNinthWaveComp){
                        this.loadData();
                    }
                    this.fetchExternalAccountList();
                });
                
            }
    }
    
    componentDidMount() {
        window.addEventListener("deleteAggregatedAccount", this.onDeleteAggregatedModal);
        window.addEventListener("showNewAcctMessage", this.showNewAcctMessage);
        window.addEventListener("resetPasswordAccount", this.resetPassword)
        window.addEventListener("refreshAggregatedAcctList", this.fetchExternalAccountList)
        this.props && this.props.preferences && this.props.preferences.pilotfeatures.map(feature => {
            if (feature.componentname === "Manage Assets" && feature.active === "true") {
                this.setState({ showAddAssetLink: true });
            }
        })

        //Show loading message while we load the nw token and etc...
        if(!this.state.showRCMNinthWaveComp){
            window.addEventListener("consent-completed", this.onReteriveAccountEvents);
            window.addEventListener("themeToggle", this.onThemeChange);
            this.loadData();
        }

        this.fetchExternalAccountList();
        
    }

    showNewAcctMessage(event){
        this.setState({showAddAcctMsg: event.detail});
    }
    

    ninthwaveCompPreference(preference){
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'RCMNinthWaveComponent', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }

    loadData() {
        this.setState({ isNwCompLoading: true }, () => {
            getAALoginToken(this.state.userDetails.aadUserId, this.token, this.contextjson, this.profilejson).then(res => {

                this.setState(() => ({
                    nwtoken: getNestedObject(res, ['nwtoken']),
                    extassetaggregationid: getNestedObject(res, ['extassetaggregationid'])
                }), () => {
                    loadExternalJS(
                        this.nwscripturl, "NWConnectScript").then(res => {
                            console.log("NW file has been loaded", res);
                            this.setState({ isNwCompLoading: false });
                        })
                        .catch(err => {
                            console.log("NW file was not loaded...", err);
                            this.setState({ isNwCompLoading: false });
                        });
                });
            })
                .catch(() => {
                    //Show Error message here...
                    this.setState({ isNwCompLoading: false });
                })
            //this.fetchExternalAccountList();
        })
    }
    fetchExternalAccountList() {
        getExternalAccountsList(this.token, this.contextjson, this.profilejson).then(res => {
            if (res && res.data && res.data.value && Array.isArray(res.data.value)) {
                const aggregatedaccts = res.data.value.filter(acc => parseInt(acc.fiid)> 1000);
                this.setState({
                    aggregatedAccountList: aggregatedaccts
                });
                this.props.addAggregatedAccounts(aggregatedaccts);
            }
        })
            .catch(() => {
                //Show Error message here...
            })
    }

    componentWillUnmount() {
        window.removeEventListener("consent-completed", this.onReteriveAccountEvents);
        window.removeEventListener("deleteAggregatedAccount", this.onDeleteAggregatedModal);
        window.removeEventListener("themeToggle", this.onThemeChange);
        window.removeEventListener("showNewAcctMessage", this.showNewAcctMessage);
        window.removeEventListener("resetPasswordAccount", this.resetPassword)
        window.removeEventListener("refreshAggregatedAcctList", this.fetchExternalAccountList)

        if (getAALoginCancellationToken !== null && getAALoginCancellationToken !== undefined) {
            getAALoginCancellationToken.cancel("Cancelling AALogin token call to prevent memory leak");
        }

        if (updateExternalAccountsListCancellationToken !== null && updateExternalAccountsListCancellationToken !== undefined) {
            updateExternalAccountsListCancellationToken.cancel("Cancelling update/add aggregarted account call to prevent memory leak");
        }
    }

    onThemeChange(){
        console.log("Do something on theme change");
        this.setState({theme: sessionStorage.getItem('theme') === 'Dark' ? 'dark' : 'light'}, () => {
            loadExternalJS(
                this.nwscripturl, "NWConnectScript").then(res => {
                    console.log("NW file has been loaded", res);
                    this.setState({ isNwCompLoading: false });
                })
                .catch(err => {
                    console.log("NW file was not loaded...", err);
                    this.setState({ isNwCompLoading: false });
                });
        });
    }

    onReteriveAccountEvents(event) {
        console.log("Event received -", event);
        //Remove asset aggregated account message 
        this.setState({ showAddAcctMsg: false }, () => {
            if (event.data && event.data.nwConnect && event.data.nwConnect === "TRUE") {
                //Call api to insert into the database
                updateExternalAccountsList(event.data, getNestedObject(this.props.profileResp, ["_id"]), this.token, this.contextjson, this.profilejson)
                    .then(() => {
                        //Process after adding the external account
                        getExternalAccountsList(this.token, this.contextjson, this.profilejson).then(res => {
                            if (res && res.data && res.data.value && Array.isArray(res.data.value)) {
                                const aggregatedaccts = res.data.value.filter(acc => parseInt(acc.fiid) > 1000);
                                this.setState({
                                    aggregatedAccountList: aggregatedaccts, 
                                    showAddAcctMsg: true
                                });
                                this.props.addAggregatedAccounts(aggregatedaccts);
                            }
                        })
                        .catch(() => {
                            //Show Error message here...
                        })

                        this.refreshAccountSelector(event, "add");
                    })
                    .catch(() => {
                        //Show error message and close the ninth waves 
                    })

            } else {
                //hide the ninth wave component here
                this.setState({ showNinthWavesWebCompnent: false })
            }
        });
    }

    resetPassword(event){
        const fiid = event && event.detail ? event.detail.extfiid : ''
        const accountKey =  event && event.detail ? event.detail.key : ''
        if(this.state.resetPasswordFi === fiid) {
            this.setState({resetPasswordFi: undefined, selectedAcctkey: undefined}, () => {
                this.setState(prevState => ({...prevState, resetPasswordFi: fiid, selectedAcctkey: accountKey}))
            })
        } else {
          this.setState(prevState => ({...prevState, resetPasswordFi: fiid, selectedAcctkey: accountKey}))
        }
    }

    refreshAccountSelector(event, modifiedType){
        //Call profile api to get the group external accounts 
        getUserProfile(this.token).then(profileRes => {
            const profileData = (profileRes.data && Array.isArray(profileRes.data)
                && profileRes.data[0] && profileRes.data[0].profile) ? profileRes.data[0].profile : null;
            let groupedaccounts = [];
            if (profileData !== null && profileData.groupedexternalaccounts && profileData.groupedaccounts
                && this.props.accountList) {
                let externalAccountsList = profileData.groupedexternalaccounts;
                if (profileData.groupedaccounts && profileData.groupedaccounts!==null) {
                    groupedaccounts = profileData.groupedaccounts;
                }
                if (externalAccountsList !== null && externalAccountsList !== undefined && Array.isArray(externalAccountsList)) {
                    
                    let acctList = this.props.accountList;
                    acctList.externalAccounts = profileData.groupedexternalaccounts;
                    acctList.accounts = profileData.accounts;
                    this.props.updateAggregatedAccounts(
                        {
                            acctList: acctList,
                            profileResp: profileData,
                            accounts: getUpdatedAccountList(profileData),
                            groupedaccounts: groupedaccounts,
                            aggregatedAcctModifiedList: this.getModifiedAggregatedAcctList(modifiedType, event),
                            profilejson: profileRes.headers.profilejson
                        });
                }
            }
        }).catch(err => {
            //log the error message here...
            console.error(err);
        })
    }

    getModifiedAggregatedAcctList(modifiedType, event){
        if(modifiedType === "add"){
            return {
                isExternalAccountAdded: (this.props.aggregatedAcctModifiedList) ? !(this.props.aggregatedAcctModifiedList.isExternalAccountAdded) : false,
                modifiedType: modifiedType,
                modifiedAccounts: getNwAggregatedAccounts(event.data)
            }
        }else if(modifiedType === "delete"){
            return {
                isExternalAccountAdded: false,
                modifiedType: modifiedType,
                modifiedAccounts: [event.data]
            }
        }
    }

    onDeleteAggregatedModal(event) {
        this.setState(prevState => ({
            assetAggrModalState: !prevState.assetAggrModalState,
            aboutToDeleteAccount: event.detail,
            showAddAcctMsg: false
        }));
    }

    showNinthWavesComponent() {
        this.setState(prevState => ({
            showNinthWavesWebCompnent: !prevState.showNinthWavesWebCompnent
        }), () => {

            loadExternalJS(
                this.nwscripturl, "NWConnectScript").then(res => {
                    console.log("NW file has been loaded", res);
                })
                .catch(err => {
                    console.log("NW file was not loaded...", err);
                });
        })

    }

    onDeleteAccept() {
        this.setState(prevState => ({
            assetAggrModalState: !prevState.assetAggrModalState
        }), () => {
            //call api to delete 
            this.deleteAggregatedAccountConfirm(this.state.aboutToDeleteAccount)
        })
    }

    deleteAggregatedAccountConfirm(deleteAccount){
        deleteExternalAccount(deleteAccount, this.token, this.contextjson, this.profilejson).then(() => {
            //if delete successfull , call get external account list
            this.fetchExternalAccountList();
            this.refreshAccountSelector({ 'data': deleteAccount }, 'delete');
        }).catch(() => {
            console.log("Error Occured while deleting the external account");
        })
    }

    toggleAssetAggrModal() {
        if (this.state.showNinthWavesWebCompnent) {
            this.setState(prevState => ({
                showNinthWavesWebCompnent: !prevState.showNinthWavesWebCompnent
            }));
        } else {
            this.setState(prevState => ({
                assetAggrModalState: !prevState.assetAggrModalState
            }));
        }
    }
    toggleHelpAndSupportModal() {
        var errorMsg = "";
        var obj1 = null/*, obj2 = null*/;
        //if (this.rockcoDemo && this.rockcoDemo !== null && (obj1 = this.rockcoDemo.getElementsByClassName('main_logo_wrpr')) !== undefined && obj1[0] !== undefined && obj1[0].parentNode !== undefined && (obj2 = obj1[0].parentNode.getElementsByTagName('h1')) !== undefined && obj2[0] !== undefined) {
        //    errorMsg += "FI: " + obj2[0].innerHTML + ". ";
        //}
        if (this.rockcoDemo && this.rockcoDemo !== null && (obj1 = this.rockcoDemo.getElementsByClassName('ffab fs12 ls14 ttuc tac')) !== undefined && obj1[0] !== undefined ) {
            errorMsg += "FI: " + obj1[0].innerHTML + ". ";
        }
        if (this.rockcoDemo && this.rockcoDemo !== null && this.rockcoDemo.getElementsByClassName('error_msg') !== undefined && this.rockcoDemo.getElementsByClassName('error_msg')[0] !== undefined) {
            errorMsg += this.rockcoDemo.getElementsByClassName('error_msg')[0].innerHTML;
        }
        this.setState(prevState => ({
            openHelpAndSupportModal: !prevState.openHelpAndSupportModal, nwErrorMsg: errorMsg
        }));
    }
    render() {
        
        return (
            <div>
                <ErrorBoundary>

                    <div className="conatiner-fluid RCM_Toast" >
                        {
                            !this.state.isloading
                                ?
                                <div className="row RCM_balanceDetails">
                                    <div className="col-md-5" id="RCM_aaggregationAddAccount">
                                        <div>
                                            {
                                                this.state.isNwCompLoading === true ? (
                                                    <OverlayProcessing />
                                                ) : ""
                                            }
                                            <div className="row">
                                                <div className="RCM_Toast_heading">
                                                    ADD NEW ACCOUNT TO AGGREGATE
                                                </div>
                                                {/*<div className="RCM_Toast_sub_heading row mt-2">
                                                    Find the Institution where your account is held from the list below.
                                                </div>*/}
                                            </div>
                                            <div className="row">
                                                <div className="RCM_caseManager_modal_link_container">

                                                    <span onClick={this.toggleHelpAndSupportModal} className="RCM_caseManager_modal_link" id="RCM_click_here">Click here</span> if you are unable to find your institution or to report an issue.
                                                </div>
                                            
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 mt-2">
                                                    {
                                                        this.state.showRCMNinthWaveComp ? 
                                                            <NinthWaveContainer  passwordResetFiid={this.state.resetPasswordFi} selectedAcctkey={this.state.selectedAcctkey}/> 
                                                            :
                                                            (
                                                                <nw-consent-app
                                                                    data-token={this.state.nwtoken}
                                                                    data-showbutton="false"
                                                                    data-appId={this.nwAppId}
                                                                    data-appName="Rockefeller"
                                                                    data-appLogo="/"
                                                                    data-usecases="[wealthMgmt]"
                                                                    //data-devenv="true"
                                                                    data-theme={this.state.theme}
                                                                    data-firstName={this.state.userDetails.firstName}
                                                                    data-lastName={this.state.userDetails.lastName}
                                                                    data-email={this.state.userDetails.emailId}
                                                                    data-customerReferenceId={this.state.userDetails.aadUserId}
                                                                    ref={ele => { this.rockcoDemo = ele }}
                                                                    data-featuredfionly="true"
                                                                    data-widgettype="page"
                                                                    data-baseURL={this.nwDbUrl}
                                                                >
                                                                </nw-consent-app>
                                                        )
                                                    }
                                                    
                                                    
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-7 RCM_Tables" id="RCM_aaggregationtable">
                                        <div className="row">
                                            <div className={"RCM_Toast_heading " + (this.state.showAddAssetLink ? 'col-sm-6 ' : 'col-sm-12 ')}>
                                                AGGREGATED ACCOUNTS
                                            </div>
                                            {this.state.showAddAssetLink ? <div className="col-sm-6 RCM_add_asset_link">
                                                <Link key={"Add_Assets_Manually"} to='ManageAssets' id="RCM_manual_assetLink">
                                                    ADD ASSETS MANUALLY
                                                </Link>

                                            </div> : <div></div>}
                                        </div>
                                        {
                                            (this.state.showAddAcctMsg) && (
                                                <div className="row">
                                                    <div className="col-sm-12 RCM_success_msg">{aggregatedAcctAddMsg}</div>
                                                </div>
                                            )
                                        }
                                        <div className="row">
                                            <div className="col-sm-12 mt-2">
                                                <BootstrapTable
                                                    keyField='UniqueIdx'
                                                    data={addIndexToList(this.state.aggregatedAccountList)}
                                                    columns={assetAggrGridColumns(this.props)}
                                                    headerClasses="row-bg"
                                                    bordered={false}
                                                    classes="RCM_two_level_table1"
                                                    noDataIndication="Please add accounts from your Institution to see them here"
                                                    defaultSorted={defaultSorted} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="RCM_spinner">Loading Account Details{spinner()}</div>
                        }
                    </div >
                    <AssetAggregationModal
                        modalState={this.state.assetAggrModalState}
                        toggleModal={() => this.toggleAssetAggrModal()}
                        deleteAccept={() => this.onDeleteAccept()}
                        aboutToDeleteAccount={this.state.aboutToDeleteAccount}
                    >
                    </AssetAggregationModal>
                    <HelpAndSupportModal
                        source="AssetAggregation"
                        toggleHelpAndSupportModal={() => this.toggleHelpAndSupportModal()}
                        additionalInfo={this.state.nwErrorMsg}
                        state={this.state}
                    >
                    </HelpAndSupportModal>

                </ErrorBoundary>
                <div className="mt-2 RCM_footer_text">
                    <p>{balanceActicityAggreationDisclaimer}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        aaduseroid: state.acct.aaduseroid,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        profileResp: state.acct.profileResp,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        userEmailId: state.acct.userName,
        accountList: state.acct.accountList,
        accounts: state.acct.accounts,
        aggregatedAcctModifiedList: state.acct.aggregatedAcctModifiedList,
        preferences: state.acct.preferences,
    };
};

const mapDispatchToProps = {
    updateAggregatedAccounts,
    addAggregatedAccounts
};
const AssetAggregationDetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Asset Aggregation' })
)

export default AssetAggregationDetailscomp(AssetAggregationDetails);

