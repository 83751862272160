import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AccountPerformanceView from './accountperformanceview';
import { getNestedObject } from '../../../../MoneyMovement/common/utilty';
import { getPerformanceDataService, getPerformanceDataServiceCancellationToken } from '../performanceservice';
import { spinner, CheckIfProspectClient, getAccountDisplayName, IndLongShort } from '../../../../../common/constants';
import Watermark from '../../../../../common/watermark';
import { accountPerformanceMockData } from './../performancemockdata';
import { UpdatePerformanceAccountsDetails } from './../../../../../actions/accountsactions';

const AccountPerformance = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [accounts, setAccounts] = useState([]);
   /* const [isrowexpanded, setIsrowexpanded] = useState(false);
    const [expanded, setExpanded] = useState([]);*/
    const dispatch = useDispatch();
    const contextJson = useSelector(state => getNestedObject(state, ['acct', 'contextjson']));
    const authToken = useSelector(state => getNestedObject(state, ['acct', 'token']));
    const profilejson = useSelector(state => getNestedObject(state, ['acct', 'profilejson']));
    const render = useSelector(state => getNestedObject(state, ['acct', 'render']));
    const accountlist = useSelector(state => getNestedObject(state, ['acct', 'accounts']));
    const role = useSelector(state => getNestedObject(state, ['acct', 'role']));

    const isProspectClient = CheckIfProspectClient(role);

    useEffect(() => {
        return () => {
            if (getPerformanceDataServiceCancellationToken !== null && getPerformanceDataServiceCancellationToken !== undefined) {
                getPerformanceDataServiceCancellationToken.cancel("Cancelling get performance data request on unmount to prevent memory leak.");
            }

        }
    }, []);
    useEffect(() => {
        setShowOverlay(prevShowOverlay => true);
        if (isProspectClient) {
            getAccountPerformanceMockData();
        }else
            getAccountPerformanceData();
    }, [render]);

    const updatePerformanceAccount = useCallback(
        (accounts) => dispatch(UpdatePerformanceAccountsDetails(accounts)),
        [dispatch]
    );

    const getAccountPerformanceData = () => {

        getPerformanceDataService(authToken, contextJson, profilejson, 'account')
            .then(performanceData => {
                if (performanceData) {
                    let data = performanceData.accountlist && performanceData.accountlist !== null ? performanceData.accountlist : [];
                    setAccountData(data);
                    
                    setShowOverlay(prevShowOverlay => false);
                } else {
                    setAccountData([]);
                    updatePerformanceAccount([]);
                    setShowOverlay(prevShowOverlay => false);
                }

            })
            .catch(error => {
                console.error(error);
                setAccountData([]);
                updatePerformanceAccount([]);
                setShowOverlay(prevShowOverlay => false);
            })

    }
    const setAccountData = data => {
        if (data && data.length > 0) {
            let mappedData = data.map(x => ({
                ...x,
                displayname: getAccountDisplayName(accountlist, x.key, IndLongShort.SINGLEVIEW)

            }));
            updatePerformanceAccount(mappedData);
            setAccounts(mappedData);
        } else {
            updatePerformanceAccount([]);
            setAccounts([]);
        }
    }
    const getAccountPerformanceMockData = () => {
        let data = accountPerformanceMockData.accountlist && accountPerformanceMockData.accountlist !== null ? accountPerformanceMockData.accountlist : [];

        setAccounts(data);
        setShowOverlay(prevShowOverlay => false);
    }
    /*const handleOnExpand = (row, isExpand) => {
        if (isExpand) {
            setExpanded([...expanded, row.key]);
            setIsrowexpanded(true);
           
        } else {
            let isRowExpanded = expanded.length > 1 ? true : false;
            setExpanded(expanded.filter(item => item !== row.key));
            setIsrowexpanded(isRowExpanded);  
        }
    }

    //expand row function for account table
    const expandRowByAccount = {
        renderer: (row) => (
            <div className="row" key="dvexpandaccountrow">
                <div className="col-sm-12" key="dvexpandaccountcol">
                    
                    <BootstrapTable key="btaccountchild"
                        keyField='secinfo.secid'
                        classes="RCM_two_level_table2 RCM_Headerless_table"
                        data={row.securitylist && row.securitylist !== null ? row.securitylist:[]}
                        columns={AccountPerformanceChildColumns()}
                        bordered={false}
                        noDataIndication={MsgForNoData}
                    />
                </div>
            </div>
        ),
        showExpandColumn: true,
        expandColumnPosition: 'right',
        expanded: expanded,
        onExpand: (row, isExpand) => handleOnExpand(row, isExpand),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return <b></b>
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<div className="RCM_icon_hand RCM_down_arrow">
                </div>);
            }

            return (<div className="RCM_icon_hand RCM_right_arrow" >
            </div>);
        },
        onExpandAll: (isExpandAll, results, e) => {

            if (isExpandAll) {
                let expandedrows = [];
                for (const [index, account] of results.entries()) {
                    expandedrows.push(account.key);
                }
                setExpanded(expandedrows);
                setIsrowexpanded(true);
               
            } else {
                setExpanded([]);
                setIsrowexpanded(false);
            }
        }

    };*/
    
    return (
        <>
            
            {showOverlay
                ? <div className="RCM_spinner mt-4" key="accountPerf01">Loading Account Performance {spinner()}</div>
                : <>
                    {isProspectClient ? <Watermark /> : ''}
                    < AccountPerformanceView accounts={accounts} accountlist={accountlist} key="accountPerf02"/>
                </>
               
            }

        </>
    )

};

export default AccountPerformance;