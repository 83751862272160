import React, { useEffect, useState, useContext, memo } from "react";
import { Get } from "../../../common/servicecalls";
import { TeamworkContext } from "../TeamworkContainer";
import Group from "./Group";

const GroupListContainer = ({ onGroupSelection, selectedGroup }) => {
  const { token } = useContext(TeamworkContext);
  const [error, setError] = useState();
  const [url] = useState(
    `https://graph.microsoft.com/v1.0/me/memberof/$/microsoft.graph.group?$filter=groupTypes/any(a:a eq 'unified')`
  );
  const [groups, setGroups] = useState();
  useEffect(() => {
    //Call graph client to check
    setError();
    Get(url, token)
      .then((groupResponse) => {
        setGroups(groupResponse.data.value);
        if (
          groupResponse.data.value &&
          Array.isArray(groupResponse.data.value)
        ) {
          onGroupSelection(groupResponse.data.value[0]);
        }
      })
      .catch((err) => {
        setError(
          err && err.response && err.response.status === 403
            ? "Not authorized"
            : "Unable to pull group information"
        );
      });
  }, [url, token, onGroupSelection]);

  return (
    <>
      {error && <div className="RCM_Teamwork_error">{error}</div>}
      <div
        className="RCM_Teamwork_Grouplist"
        style={{
          display: `${groups && groups.length > 1 ? "" : "none"}`,
        }}
      >
        {groups &&
          groups.map((group) => {
            return (
              <Group
                details={group}
                key={group.id}
                selectedGroup={selectedGroup}
                onGroupSelection={onGroupSelection}
              />
            );
          })}
      </div>
    </>
  );
};

export default memo(GroupListContainer);
