import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GetPreferencePilotValue, GetPreferenceValue, CheckIfProspectClient } from './../../../common/constants.jsx';
import { _logPageView } from "./../../../common/_logging";
import { compose } from 'redux';
import { withPageView } from './../../../hoc/withPageView.jsx';
import { connect } from 'react-redux';
import ErrorBoundary from './../../../common/errorboundary.jsx';
import { alertsSummaryService } from './alertdetails/alertdetailservices.jsx';
class ShowAlert extends Component {

    constructor(props) {
        super(props);

        this.execute = this.execute.bind(this);
        this.state = {
            alertCount: 0,
            isShowNotification: this.getPilotFlagValue(),
            isProspectClient: CheckIfProspectClient(this.props.role)

        };
        this.descreaseCount = this.descreaseCount.bind(this);
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
    }

    getPilotFlagValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isAlertPreferenceRequired = GetPreferencePilotValue(obj, "Alerts", "false") === "true" ? true : false;
        return isAlertPreferenceRequired;
    }

    descreaseCount() {
        if (this.state.isProspectClient) {
            this.setState({ alertCount: 0 });
        }
        else {
            this.execute();
        }
    }


    componentDidMount() {
        window.addEventListener('descreaseCount', this.descreaseCount);
        if (this.state.isProspectClient) {
            this.setState({ alertCount: 0 });
        }
        else {
            this.execute();
        }

    }

    execute() {
        alertsSummaryService(this.authToken, this.contextjson, this.profilejson)
            .then(response => {
                if (response && response.data && response.data.alertunreadcount) {
                    this.setState({ alertCount: response.data.alertunreadcount });
                }
                else {
                    this.setState({ alertCount: 0 });
                }

            })
            .catch(error => {
                console.error(error);
                this.setState({ alertCount: 0 });

            })
    }


    componentWillMount() {
        window.removeEventListener('descreaseCount', this.descreaseCount);
    }
    render() {
        const alert = (
            <div key="dvDocument">
                {
                    this.state.isShowNotification ?
                        <Link key={"show_alert"} to='/AlertDetails' id="RCM_alert_icon_Link">
                            {this.state.alertCount > 0 ?
                                <div className="dots" id="RCM_alert_icon"><span className="RCM_menu_img RCM_Bell_Icon"><mark>{this.state.alertCount}</mark></span></div>
                                :
                                <div className="dots" id="RCM_alert_icon"><span className="RCM_menu_img RCM_Bell_Icon"></span></div>
                            }
                        </Link>
                        : ''
                }

            </div>
        );
        return (
            <div>
                <ErrorBoundary>
                    {alert}
                </ErrorBoundary>
            </div>
        );
    }
}

const enhancement = compose(
    withPageView({ pageName: 'alerts' })
)
const mapStateToProps = (store) => {
    return {
        preferences: store.acct.preferences,
        token: store.acct.token,
        portalid: store.acct.portalid,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        role: store.acct.role
    };
}
export default enhancement(connect(mapStateToProps)(ShowAlert));