import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { EstimatedIncomeURL, spinner, GetAccountsList, MsgForNoData, CheckIfProspectClient} from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddEstimatedIncomeBySecurity, SetAccountOverviewFlag, ChangeYearEstimatedIncome, RefreshEstimatedSummaryAccount } from '../../../../../actions/toptenactions.jsx';
import { securityColumns, securitychildColumns, renderRowsBySecurity, renderRowsBySecurityChild, getDisplayName, GetAccountColumns } from '../estimatedincomecolumns.jsx';
import axios from "axios";
import { ProjectedIncomeSecurityView } from './estimatedincomesecurityview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { estimatedincomesecurity, RRR_123456_estimatedincomesecurity, RRR_234567_estimatedincomesecurity } from './../../../accountsmockresponse';

class EstimatedIncomeForSecurity extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            estimatedincomebysecurity: this.props.estimatedincomebysecurity,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            expanded: [],
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
            dropdownyearValue: this.props.estimatedIncomeYear ? this.props.estimatedIncomeYear :(new Date().getMonth() > 0) ? new Date().getFullYear() : new Date().getFullYear() - 1,
            showTable: false,
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        this.collapserow = this.collapserow.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
        this.toggleShowTable = this.toggleShowTable.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);

    }
    getSecurityHoldingsCancellationToken = null;
    getSecurityHoldingsAccountChangeCancellationToken = null;

    //method to execute api calls
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];
        var accounts = '';
        if (this.state.accountOverviewFlag) {
            this.props.selectedaccount.map(x => {
                if (x.number !== 'All Accounts') {
                    accounts += x.number + ',';
                }

            })
            accounts = accounts.substr(0, accounts.length - 1);
            this.props.SetAccountOverviewFlag(false);
        }
        if (this.getSecurityHoldingsCancellationToken) {
            //Cancel the existing call
            this.getSecurityHoldingsCancellationToken.cancel('Cancelling the existing Security Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getSecurityHoldingsCancellationToken = cancelToken.source();
        let selectorparam = 'S';
        const baseUrlSecurity = EstimatedIncomeURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getSecurityHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data != "" ? result.data.value : datarows;
                this.props.AddEstimatedIncomeBySecurity(data);
                this.props.ChangeYearEstimatedIncome(this.state.dropdownyearValue);
                this.setState(() => ({ estimatedincomebysecurity: data, isloading: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                }
            })
    };

    componentDidMount() {
        //if props has data then take it or make api call
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.estimatedincomebysecurity === null)
                this.execute();
            else this.setState({ isloading: false, requestFailed: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }
        }
    }
    componentWillUnmount() {
        if (this.getSecurityHoldingsCancellationToken !== null && this.getSecurityHoldingsCancellationToken !== undefined) {
            this.getSecurityHoldingsCancellationToken.cancel('Cancelling the Security Holdings during unmount to prevent memory leak.');
        }
        if (this.getSecurityHoldingsAccountChangeCancellationToken !== null && this.getSecurityHoldingsAccountChangeCancellationToken !== undefined) {
            this.getSecurityHoldingsAccountChangeCancellationToken.cancel('Cancelling the Security Holdings Account Change during unmount to prevent memory leak.');
        }

    }
    collapserow(row) {
        this.setState(() => ({
            expanded: []
        }));
    }
   
   
    toggleShowTable() {
        this.setState(prevState => ({ showTable: !prevState.showTable }));
    }

   
    //toggleDropdown() {
    //    this.setState(prevState => ({
    //        dropdownOpen: !prevState.dropdownOpen
    //    }));
    //}
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.UNQID]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.UNQID),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
    }

    changeYear(selectedyear) {
        if (this.state.dropdownyearValue !== selectedyear.label)
            this.setState({ dropdownyearValue: selectedyear.label },
                () => this.execute(),
                this.props.RefreshEstimatedSummaryAccount());
    }


    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }

    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.estimatedincomebysecurity === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ estimatedincomebysecurity: (RRR_123456_estimatedincomesecurity && RRR_123456_estimatedincomesecurity.value ? RRR_123456_estimatedincomesecurity.value : []), isloading: false, areDataRetrieved: true, expanded: ["115065YH1", "FDIC66606"] }), () => { this.props.AddEstimatedIncomeBySecurity((RRR_123456_estimatedincomesecurity && RRR_123456_estimatedincomesecurity.value ? RRR_123456_estimatedincomesecurity.value : [])); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ estimatedincomebysecurity: (RRR_234567_estimatedincomesecurity && RRR_234567_estimatedincomesecurity.value ? RRR_234567_estimatedincomesecurity.value : []), isloading: false, areDataRetrieved: true, expanded: ["FDIC66606"] }), () => { this.props.AddEstimatedIncomeBySecurity((RRR_234567_estimatedincomesecurity && RRR_234567_estimatedincomesecurity.value ? RRR_234567_estimatedincomesecurity.value : [])); });
                }
            } else if (this.props.selectedaccount.length == 2) {
                console.log(estimatedincomesecurity.value);
                this.setState(() => ({ estimatedincomebysecurity: (estimatedincomesecurity && estimatedincomesecurity.value ? estimatedincomesecurity.value : []), isloading: false, areDataRetrieved: true, expanded: ["115065YH1", "FDIC66606", "002535300"] }), () => { this.props.AddEstimatedIncomeBySecurity((estimatedincomesecurity && estimatedincomesecurity.value ? estimatedincomesecurity.value : [])); });
            }
        }
        else {
            this.setState({ isloading: false, dropdownValue: this.state.accounts[0].number, expanded: ["115065YH1", "FDIC66606", "002535300"], dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }
    }
    //render method
    render() {
        var securityData = [];
        var totalordinarydividend;
        var totalqualifieddividend
        var totaltaxableinterest;
        var totalnontaxableinterest;
        var totalnetincome;
        var totalcapitalgainloss;
        if (this.state.estimatedincomebysecurity && this.state.estimatedincomebysecurity.length > 0) {
            if (this.state.estimatedincomebysecurity[0].itemSummary) {
                securityData = renderRowsBySecurity(this.state.estimatedincomebysecurity[0].itemSummary);
                totalordinarydividend = this.state.estimatedincomebysecurity[0].ODNDividend;
                totalqualifieddividend = this.state.estimatedincomebysecurity[0].QlfDividend;
                totaltaxableinterest = this.state.estimatedincomebysecurity[0].TaxableInterest;
                totalnontaxableinterest = this.state.estimatedincomebysecurity[0].NonTaxaableInterest;
                totalnetincome = this.state.estimatedincomebysecurity[0].NetIncome;
                totalcapitalgainloss = this.state.estimatedincomebysecurity[0].TOTCAPGAIN;
            }

        }
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for security table
        const expandRowBySecurity = {
            renderer: (row) => (
                <BootstrapTable
                    key="btsecchild1"
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={renderRowsBySecurityChild(row, this.props.selectedaccount, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                    columns={securitychildColumns(this.props.selectedaccount)}
                    noDataIndication={MsgForNoData}
                    rowEvents={rowEvents}
                    bordered={false}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.UNQID);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };
        
        return (

            <ErrorBoundary>
                <ProjectedIncomeSecurityView
                    state={this.state}
                    showTable={this.state.showTable}
                    securityData={securityData}
                    toggle={this.toggleDropdown}
                    changeAccount={this.changeAccount}
                    accounts={this.props.selectedaccount}
                    totalordinarydividend={totalordinarydividend}
                    totalqualifieddividend={totalqualifieddividend}
                    totaltaxableinterest={totaltaxableinterest} 
                    totalnontaxableinterest={totalnontaxableinterest}
                    totalnetincome={totalnetincome}
                    totalcapitalgainloss={totalcapitalgainloss}
                    selectedAccount={this.props.selectedaccount}
                    expandRowBySecurity={expandRowBySecurity}
                    changeYear={this.changeYear}
                    toggleYear={this.toggleYear}
                    toggleShowTable={this.toggleShowTable}
                    toggle1={this.toggle1}
                    accounts={this.props.selectedaccount}
                    rowsortevent={this.rowsortevent}
                />
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        accountOverviewFlag: state.acct.accountOverviewFlag,
        estimatedincomebysecurity: state.topten.estimatedincomebysecurity,
        estimatedIncomeYear: state.topten.estimatedIncomeYear,
        role: state.acct.role
    };
};

const mapDispatchToProps = {
    AddEstimatedIncomeBySecurity,
    ChangeYearEstimatedIncome,
    SetAccountOverviewFlag,
    RefreshEstimatedSummaryAccount
};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Security Holdings' })
)
export default securityholdingdetailscomp(EstimatedIncomeForSecurity);