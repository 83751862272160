import React, { Component } from "react";
import Assetallocationdetails from "./assetallocationdetailservicecalls.jsx";
import ErrorBoundary from "../../../../common/errorboundary.jsx";
import { connect } from "react-redux";
import HistoricalAssetAllocationContainer from "../historicalassetallocation/index.jsx";
import { Switch, Route, BrowserRouter, NavLink } from "react-router-dom";
import { showHistoricalAssetAllocation } from "../historicalassetallocation/HistoricalAssetAllocationService";
import { historicalAssetAllocationFooterMsg } from './../../../../common/constants';

class Assetallocationdetail extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        const { match } = this.props;
        this.tabLinks = [
            {
                id: "assetallocation-detail",
                active: false,
                displayName: "CURRENT",
                tabUrl: "/Assetallocationdetail"
            },
            {
                id: "assetallocation-history",
                active: false,
                displayName: "HISTORY",
                tabUrl: "/Assetallocationhistory"
            }
        ].map(x => ({ ...x, active: x.tabUrl === match.path }));
        this.state = {
            tooltipOpen: false,
            activeTab: this.tabLinks.findIndex(x => x.active)
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        const { activeTab } = this.state;
        const { preferences } = this.props;
        return (
            <div className="RCM_detailsScreeen">
                <div className="row">
                    <div className="col-sm-12" key="aadrow">
                        <label
                            className="RCM_tile_heading RCM_details_heading RCM_Toast_heading"
                            key="lblholdingheading"
                        >
                            ASSET ALLOCATION
            </label>
                    </div>
                </div>
                <ErrorBoundary>
                    {
                        showHistoricalAssetAllocation(
                            preferences ? preferences.pilotfeatures : []
                        ) &&
                        (
                            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding">
                                <ul className="nav nav-tabs">
                                    {this.tabLinks.map((tabLink, i) => {
                                        return (
                                            <li key={tabLink.id} className="nav-item active" id={tabLink.id}>
                                                <NavLink
                                                    exact={true}
                                                    to={tabLink.tabUrl}
                                                    className={
                                                        "nav-link " + (i === activeTab ? "linkactive " : " ")
                                                    }
                                                    onClick={() => this.setState({ activeTab: i })}
                                                >
                                                    {tabLink.displayName}
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )
                    }
                    <BrowserRouter>
                        <Switch>
                            <Route
                                key="assetallocationdetail"
                                exact
                                path="/Assetallocationdetail"
                                component={Assetallocationdetails}
                            />
                            <Route
                                key="assetallocationshistory"
                                exact
                                path="/Assetallocationhistory"
                                component={(props) => (<HistoricalAssetAllocationContainer {...props} chartType={"Bar"} />)}
                            />
                        </Switch>
                    </BrowserRouter>
                    {this.tabLinks && this.tabLinks.length >= (activeTab + 1) && this.tabLinks[activeTab] && this.tabLinks[activeTab].displayName === 'HISTORY'
                        ? <div className="mt-2 RCM_footer_text" key="footerText">
                            {historicalAssetAllocationFooterMsg}
                        </div>
                        : <></>
                    }
                    
                </ErrorBoundary>
            </div>
        );
    }


}
const mapStateToProps = state => {
    return {
        preferences: state.acct.preferences
    };
};

export default connect(mapStateToProps)(Assetallocationdetail);
