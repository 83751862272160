import { wheelsUpUrl, wheelsUpPdfContentUrl } from "../../../common/constants";
import { Get, Post } from "../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../common/_logging";
import { errorMsg} from "../../../common/constants.jsx";

export var wheelsUPApiCancellationToken = null;
export var wheelsUPpdfCancellationToken = null;

export const getWheelsUpApiUrl = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (wheelsUPApiCancellationToken !== null && wheelsUPApiCancellationToken !== undefined) {
        //Cancel the existing call
        wheelsUPApiCancellationToken.cancel('Cancelling the existing wheels up api url to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    wheelsUPApiCancellationToken = cancelToken.source();

    let getWheelsUPApiUrl = new Promise(
        function (resolve, reject) {
           
            let url = `${wheelsUpUrl}?vendor=WU`;
            Get(url, token, contextJson, profileJson, wheelsUPApiCancellationToken).then(res => {
                resolve(res);
                
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log("Wheels UP Service :: getWheelsUp API ", error);
                    _logException("Wheels UP Service :: getWheelsUp API " + (error && error.response) ? error.response : "");
                    reject(errorMsg);
                }
            });
        });
    return getWheelsUPApiUrl;
}


export const getWheelsUpPdfContent = (token, requestBody, contextJson, profileJson) => {
    
    //Check if cancellation token has value;
    if (wheelsUPpdfCancellationToken !== null && wheelsUPpdfCancellationToken !== undefined) {
        //Cancel the existing call
        wheelsUPpdfCancellationToken.cancel('Cancelling the existing wheels up pdf content to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    wheelsUPpdfCancellationToken = cancelToken.source();

    let getWheelsUPPdfContentApi = new Promise(
        function (resolve, reject) {
           
            let url = wheelsUpPdfContentUrl;
            Post(url, requestBody, token, contextJson, profileJson, wheelsUPpdfCancellationToken).then(res => {
                resolve(res);
                
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log("Wheels UP Service :: get pdf content", error);
                    _logException("Wheels UP Service :: get pdf content" + (error && error.response) ? error.response : "");
                    reject(errorMsg);
                }
            });
        });
    return getWheelsUPPdfContentApi;

}