import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import PdfViewer from './../pdfviewer'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DatePicker from 'react-datepicker'
import { getAccountStatementsColumns } from './accountstatementscolumns.jsx'
import { getAccountStatementsModalColumns } from './accountstatementModalcolumn.jsx'
import {DownloadPrintallModal} from './downloadPrintAllModal.jsx'
import Messages from './../../../common/messages.jsx';
import { spinner, MsgDocumentLoading, MsgForFailedData, MsgForNoData, MsgSelectDocument, MsgSelectRockoActs } from './../../../common/constants.jsx';
import Watermark from './../../../common/watermark';
export function AccountStatementsView(
    props
) {
    const selectRow = {
        mode: 'radio',
        selected: props && props.state && props.state.selected,
        onSelect: props && props.selectDocument,
        bgColor: 'var(--color-light-blue)',
        clickToSelect: true
    };

    let accountstatementview = (
        <div className="container-fluid">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row RCM_Document_Message_Margin">
                <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                    <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                </div>
            </div>

            <div className="row RCM_Document_Fields">
                <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3 RCM_Stmt_Date_Div" id="RCM_accstmtDatefilter">
                    <label className="RCM_Date_lbl">DATE</label>
                    <div className="RCM_Document_Date_Inline_Div">
                        <Dropdown 
                            isOpen={props && props.state && props.state.dateRangeDropdownOpen} 
                            toggle={props.dateRangeDropdownToggle} 
                            className="RCM_select_box RCM_mm_subselect_box RCM_Document_Dropdown">
                            <DropdownToggle caret disabled={props.state.isProspectClient}>
                                {
                                    props && props.state && props.state.dateRangeDropdownValue
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {props && props.dateRangeDropDownItems && props.dateRangeDropDownItems.map((item, index) =>
                                    <DropdownItem key={index} 
                                        onClick={props.changeDateRange} 
                                        disabled={props.state.isProspectClient}>
                                        <div >{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                {
                    props && props.state && props.state.isCustomDateRangeSelected
                        ?
                        <div className="row col-sm-12 col-xs-12 col-md-7 col-lg-7" >
                            <div className="col-sm-5" id="RCM_fromDateFilter">
                                <label className="RCM_Accounts_lbl RCM_FromDate_Margin" >FROM DATE</label>
                                <DatePicker
                                    className="RCM_Document_Datepicker RCM_Document_FromDate_Margin"
                                    selected={props && props.state && props.state.startDate}
                                    onChange={props.startDateChange}
                                    maxDate={new Date()}
                                    disabled={props.state.isProspectClient}
                                ></DatePicker>
                                <label className="RCM_bottom-label RCM_Stmt_FromDate_Bottom_lbl" >(mm/dd/yyyy)</label>
                            </div>

                            <div className=" col-sm-12 col-xs-12 col-md-4 col-lg-4 RCM_Stmt_ToDate_Div" id="RCM_fromToFilter">
                                <label className="RCM_Accounts_lbl RCM_ToDate_Lbl">TO DATE</label>
                                <DatePicker
                                    className="RCM_Document_Datepicker"
                                    selected={props && props.state && props.state.endDate}
                                    onChange={props.endDateChange}
                                    maxDate={new Date()}
                                    disabled={props.state.isProspectClient}
                                />
                                <label className="RCM_bottom-label RCM_Stmt_ToDate_Bottom_lbl">(mm/dd/yyyy)</label>
                            </div>
                            <div className={props.role === 'Prospect' ? "col-sm-1 RCM_TradeConfirm_DateRange_Search_Btn_Margin  RCM_Prospect_Disable_Button" : "col-sm-1 RCM_TradeConfirm_DateRange_Search_Btn_Margin"} id="RCM_stmt_search">
                                <a className="RCM_Cursor_Pointer" onClick={props.SearchDocuments} title="Search">
                                    <div className={"RCM_TradeConfirm_Search"}></div>
                                </a>
                            </div>
                        </div>
                        : <div></div>

                }
                {/* <div className="col-sm-12 col-xs-12 col-md-2 col-lg-2 pull-right">
                    <label onClick={props.toggleModel} class="RCM_add_asset_link">Print All</label>
                </div> */}
            </div>
            <div style={{ textAlign: 'right' }} className={props.state.isProspectClient ? "RCM_Prospect_Disabled" : ''}>
                <span id="print" className="RCM_add_asset_link RCM_add_asset_link_color" onClick={props.toggleModel} ><a id="print" disabled={props.state.isProspectClient}>PRINT</a></span>
                &nbsp;
                &nbsp;
                <span id="download" className="RCM_add_asset_link RCM_add_asset_link_color" onClick={props.toggleModel} ><a id="download" disabled={props.state.isProspectClient}>DOWNLOAD</a></span>
            </div>

            <div className={
                props && props.state && props.state.filteredDocumentList && props.state.filteredDocumentList.length === 0
                    ? 'row RCM_Document_Details_Row'
                    : 'row'
            }>
                {
                    props && props.state && props.state.areDocumentsRetrieved
                        ?
                        <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5" id="RCM_stmtaccdetails">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    props && props.state && props.state.dropdownValue !== 'All Accounts'
                                        ? !props.state.filteredDocumentList.find(doc => doc.primaryValue === props.state.dropdownValue)
                                            ? <p>{MsgForNoData}</p>
                                            :
                                            <div>
                                                <h6>{props && props.state && props.state.dropdownValue}</h6>
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={props && props.state && props.state.filteredDocumentList.filter(doc => doc.primaryValue === props.state.dropdownValue)
                                                    }
                                                    columns={getAccountStatementsColumns(props.authprops, props.selectedaccounts, props.role)}
                                                    bordered={false}
                                                    selectRow={selectRow}
                                                    classes="RCM_two_level_table1"
                                                >
                                                </BootstrapTable>
                                            </div>
                                        :
                                        props && props.state && props.state.accounts.map((acct, index) =>
                                            (acct.number !== 'All Accounts' && props.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number).length > 0
                                                ? <div key={index}>
                                                    <h6>{acct.number}</h6>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        data={props.state.filteredDocumentList.filter(doc => (doc.primaryValue === acct.number && doc.description !== undefined && !(doc.description.includes("HH"))))
                                                        }
                                                        columns={getAccountStatementsColumns(props.authprops, props.selectedaccounts, props.role)}
                                                        bordered={false}
                                                        selectRow={selectRow}
                                                        classes="RCM_two_level_table1"
                                                    >
                                                    </BootstrapTable>
                                                   
                                                </div>
                                                : <div key={index}></div>
                                            )
                                        )

                                }
                                {
                                 
                                    (props.state.filteredDocumentList.filter(doc => doc.description !== undefined && doc.description.includes("HH")).length > 0
                                        ? <div key={"HH"}>
                                              <h6>HOUSEHOLD</h6>
                                            <BootstrapTable
                                                keyField="id"
                                                data={props.state.filteredDocumentList.filter(doc => (doc.description !== undefined  &&  doc.description.includes("HH")))
                                                }
                                                columns={getAccountStatementsColumns(props.authprops, props.selectedaccounts, props.role)}
                                                bordered={false}
                                                selectRow={selectRow}
                                                classes="RCM_two_level_table1"
                                            >
                                            </BootstrapTable>
                                        </div>
                                        : <div key={"HH"}></div>
                                    )
                             
                                }

                            </div>
                        </div>
                        :
                        <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5" id="RCM_stmtaccdetails">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    props && props.state && props.state.isNonRockActSelected
                                        ? <div>
                                            <p>{MsgSelectRockoActs}</p>
                                        </div>
                                        : props && props.state && props.state.isDocumentListResponseWithNoData
                                            ? <div>
                                                <p>{MsgForNoData}</p>
                                            </div>
                                            : <div>
                                                {
                                                    props && props.state && props.state.isDocumentListResponseWithError
                                                        ? <div>
                                                            <p> {MsgForFailedData}</p>
                                                        </div>
                                                        : <div className="RCM_spinner">{MsgDocumentLoading}{spinner()}</div>

                                                }
                                            </div>

                                }
                            </div>
                        </div>

                }
                <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                    <div className="RCM_Toast RCM_Document_Toast" id="RCM_accstmtpreview">
                        {
                            props && props.state && props.state.pdfData
                                ? <PdfViewer pdfData={props && props.state && props.state.pdfData} />
                                : props && props.state && props.state.isDocumentSelected
                                    ? props && props.state && props.state.isDocumentResponseWithNoData || props && props.state && props.state.isDocumentResponseWithError
                                        ? props && props.state && props.state.isDocumentResponseWithNoData
                                            ? <p> {MsgForNoData}</p>
                                            : <p> {MsgForFailedData} </p>
                                        : <div className="RCM_spinner">Loading Selected Document{spinner()}</div>
                                    : props && props.state && props.state.areDocumentsRetrieved
                                        ? props.state.dropdownValue === 'All Accounts' || props.state.filteredDocumentList.find(doc => doc.primaryValue === props.state.dropdownValue)
                                            ? <p>{MsgSelectDocument}</p>
                                            : <p></p>
                                        : <p></p>
                        }
                    </div>
                </div>
            </div>
            <DownloadPrintallModal props={props} columns={getAccountStatementsModalColumns(props.authprops, props.selectedaccounts, props.role)} screenName="Statements"/>
        </div>

    )
    return accountstatementview
}

