import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { HoldingsbysecurityURL, spinner, GetAccountsList, MsgForNoData, CheckIfProspectClient, GetPreferencePilotValue} from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddHoldingsBySecurity, SetAccountOverviewFlag } from '../../../../../actions/toptenactions.jsx';
import { securityColumns, securitychildColumns, renderRowsBySecurity, renderRowsBySecurityChild, getDisplayName } from '../holdingdetailscolumns.jsx';
import TASHoldingdetails from '../tasholding.jsx';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from "axios";
import { SecurityHoldingsView } from './securityholdingview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { securityholdingdetailsDummyResponse, RRR_123456_securityholdingdetailsDummyResponse, RRR_234567_securityholdingdetailsDummyResponse } from '../../../accountsmockresponse.js';
import Watermark from '../../../../../common/watermark.jsx';

class SecurityHoldingdetails extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            holdingsresponsebysecurity: this.props.holdingsresponsebysecurity,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            expanded: [],
            
            accountOverviewFlag: this.props.accountOverviewFlag,
            isrowexpanded: false,
            expanded: [],
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role),
            balSummaryV2PilotFlag: this.setBalanceSummaryV2Flag(),
            viewYield: this.setViewYieldFlag()
        }
        this.execute = this.execute.bind(this);
        this.collapserow = this.collapserow.bind(this);
      
        this.toggle = this.toggle.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);

    }
    getSecurityHoldingsCancellationToken = null;
    getSecurityHoldingsAccountChangeCancellationToken = null;
    setBalanceSummaryV2Flag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'BalSummaryV2', 'false') === 'true' ? true : false;
    }
    setViewYieldFlag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
    }
    //method to execute api calls
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];
        var accounts = '';
        if (this.state.accountOverviewFlag) {
            this.props.selectedaccount.map(x => {
                if (x.number !== 'All Accounts') {
                    accounts += x.number + ',';
                }

            })
            accounts = accounts.substr(0, accounts.length - 1);
            this.props.SetAccountOverviewFlag(false);
        }
        if (this.getSecurityHoldingsCancellationToken) {
            //Cancel the existing call
            this.getSecurityHoldingsCancellationToken.cancel('Cancelling the existing Security Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getSecurityHoldingsCancellationToken = cancelToken.source();
        const baseUrlSecurity = HoldingsbysecurityURL + '\'\',' + null + ')';
        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getSecurityHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data != "" ? result.data.value : datarows;
                this.props.AddHoldingsBySecurity(data);
                this.setState(() => ({ holdingsresponsebysecurity: data, isloading: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                }
            })
    };

    componentDidMount() {
        //if props has data then take it or make api call
        //
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.holdingsresponsebysecurity === null) {
                this.execute();
                this.setState({ dropdownValue: this.state.accounts[0] && this.state.accounts[0].number, dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname });
            }
            else {

                this.setState({ isloading: false, dropdownValue: this.state.accounts[0] && this.state.accounts[0].number, dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname });
            }
        }
        

    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }
           
        }
    }
    componentWillUnmount() {
        if (this.getSecurityHoldingsCancellationToken !== null && this.getSecurityHoldingsCancellationToken !== undefined) {
            this.getSecurityHoldingsCancellationToken.cancel('Cancelling the Security Holdings during unmount to prevent memory leak.');
        }
        if (this.getSecurityHoldingsAccountChangeCancellationToken !== null && this.getSecurityHoldingsAccountChangeCancellationToken !== undefined) {
            this.getSecurityHoldingsAccountChangeCancellationToken.cancel('Cancelling the Security Holdings Account Change during unmount to prevent memory leak.');
        }

    }
    collapserow(row) {
        this.setState(() => ({
            expanded: []
        }));
    }
  
    GetHeaderName() {
        if (this.state.rowdata) {
            let header = this.state.rowdata.SECNAME ? this.state.rowdata.SECNAME : ""
            header += this.state.rowdata.SECID ? (" - " + this.state.rowdata.SECID) : "";
            return header;
        }
        return "";
    }
    toggle(row) {
        this.setState(prevState => ({
            modal: !prevState.modal,

        }));
        this.state.rowdata = row;
    }
    
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.UNQID]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.UNQID),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.holdingsresponsebysecurity === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ holdingsresponsebysecurity: (RRR_123456_securityholdingdetailsDummyResponse && RRR_123456_securityholdingdetailsDummyResponse.value ? RRR_123456_securityholdingdetailsDummyResponse.value : []), isloading: false, areDataRetrieved: true, expanded: ["315994103", "316062108"] }), () => { this.props.AddHoldingsBySecurity((RRR_123456_securityholdingdetailsDummyResponse && RRR_123456_securityholdingdetailsDummyResponse.value ? RRR_123456_securityholdingdetailsDummyResponse.value : [])); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ holdingsresponsebysecurity: (RRR_234567_securityholdingdetailsDummyResponse && RRR_234567_securityholdingdetailsDummyResponse.value ? RRR_234567_securityholdingdetailsDummyResponse.value : []), isloading: false, areDataRetrieved: true, expanded: ["461070AF1"] }), () => { this.props.AddHoldingsBySecurity((RRR_234567_securityholdingdetailsDummyResponse && RRR_234567_securityholdingdetailsDummyResponse.value ? RRR_234567_securityholdingdetailsDummyResponse.value : [])); });
                }
            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ holdingsresponsebysecurity: (securityholdingdetailsDummyResponse && securityholdingdetailsDummyResponse.value ? securityholdingdetailsDummyResponse.value : []), isloading: false, areDataRetrieved: true, expanded: ["315994103", "316062108"] }), () => { this.props.AddHoldingsBySecurity((securityholdingdetailsDummyResponse && securityholdingdetailsDummyResponse.value ? securityholdingdetailsDummyResponse.value : [])); });
            }
        }
        else {
            this.setState({ isloading: false, dropdownValue: this.state.accounts[0].number, expanded: ["315994103", "316062108"], dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }
    }
    gotobalance = () => {
        document.dispatchEvent(new CustomEvent('goToBalanceDetails', { bubbles: true }));
    }
    //render method
    render() {
        var securityData = [];
        var totalvalue;
        var totalglamount;
        var totalglpct;
        var totalcostbasis;
        var totaltodayschange;
        var totaltodayschangepct;
        if (this.state.holdingsresponsebysecurity && this.state.holdingsresponsebysecurity.length > 0) {
            if (this.state.holdingsresponsebysecurity[0].DetailPOSLIST) {
                securityData = renderRowsBySecurity(this.state.holdingsresponsebysecurity[0].DetailPOSLIST);
                if (this.props.location.state && this.props.location.state.secid && this.props.location.state.secid !== '') {
                    let filterrows = securityData.filter(acc => (acc.SECID === this.props.location.state.secid));
                    securityData = filterrows;
                    if (filterrows.length > 0) {
                        totalvalue = this.state.holdingsresponsebysecurity[0].totalvalue
                        totalglamount = this.state.holdingsresponsebysecurity[0].unrealizedvalue
                        totalglpct = this.state.holdingsresponsebysecurity[0].unrealizedpct
                        totalcostbasis = this.state.holdingsresponsebysecurity[0].costbasis
                        if (this.state.holdingsresponsebysecurity[0].MKTVALS) {
                            totaltodayschange = this.state.holdingsresponsebysecurity[0].MKTVALS.MKTVALCHANGE;
                            totaltodayschangepct = this.state.holdingsresponsebysecurity[0].MKTVALS.MKTVALCHANGEPCT;
                            totalvalue = this.state.holdingsresponsebysecurity[0].MKTVALS.RCNTMKTVAL
                        }
                    }
                    else {
                        totalvalue = 0;
                        totalglamount = 0;
                        totalglpct = 0;
                        totalcostbasis = 0;
                        totaltodayschange = 0;
                        totaltodayschangepct=0;
                    }
                   
                }
                else {
                    totalvalue = this.state.holdingsresponsebysecurity[0].totalvalue
                    totalglamount = this.state.holdingsresponsebysecurity[0].unrealizedvalue
                    totalglpct = this.state.holdingsresponsebysecurity[0].unrealizedpct
                    totalcostbasis = this.state.holdingsresponsebysecurity[0].costbasis
                    if (this.state.holdingsresponsebysecurity[0].MKTVALS) {
                        totaltodayschange = this.state.holdingsresponsebysecurity[0].MKTVALS.MKTVALCHANGE;
                        totaltodayschangepct = this.state.holdingsresponsebysecurity[0].MKTVALS.MKTVALCHANGEPCT;
                        totalvalue = this.state.holdingsresponsebysecurity[0].MKTVALS.RCNTMKTVAL
                    }
                }
              
                this.optionsSecurity = [];
                this.optionsSecurity.push({
                    custom: true,
                    totalSize: this.state.holdingsresponsebysecurity && this.state.holdingsresponsebysecurity.length < 11 ? 0 : this.state.holdingsresponsebysecurity.length
                });
            }

        }



        
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for security table
        const expandRowBySecurity = {
            renderer: (row) => (
                <BootstrapTable
                    key="btsecchild1"
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={renderRowsBySecurityChild(row, this.state.childColumnToSort, this.state.childColumnSortOrder, this.props.selectedaccount)}
                    columns={securitychildColumns(this.props.selectedaccount, this.state.viewYield)}
                    noDataIndication={MsgForNoData}
                    rowEvents={rowEvents}
                    bordered={false}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.UNQID);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };
        return (

            <ErrorBoundary>
                <SecurityHoldingsView
                    state={this.state}
                    securityData={securityData}
                    accounts={this.props.selectedaccount}
                    totalvalue={totalvalue}
                    totalglamount={totalglamount}
                    totalglpct={totalglpct}
                    totaltodayschange={totaltodayschange}
                    totaltodayschangepct={totaltodayschangepct}
                    totalcostbasis={totalcostbasis}
                    selectedAccount={this.props.selectedaccount}
                    expandRowBySecurity={expandRowBySecurity}
                    rowsortevent={this.rowsortevent}
                    gotobalance={this.gotobalance}
                />
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="RCM_holding_details_modal_content"
                    zIndex='9999'
                    modalClassName="RCM_CM_modal_class"
                    centered key='modaltas1'>
                    <ModalHeader toggle={this.toggle} className="RCM_holding_details_modal" key='modaltas2'>
                        <label className="RCM_tile_heading" key='lblholdingheading'>{this.GetHeaderName()}</label>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.isProspectClient ? <Watermark/> : ''}
                        <TASHoldingdetails row={this.state.rowdata}></TASHoldingdetails>
                    </ModalBody>

                </Modal>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        holdingsresponsebysecurity: state.topten.holdingsbysecurity,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        accountOverviewFlag: state.topten.accountOverviewFlag,
        role: state.acct.role,
        preferences: state.acct.preferences
    };
};

const mapDispatchToProps = {
    AddHoldingsBySecurity,
    SetAccountOverviewFlag
};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Security Holdings' })
)
export default securityholdingdetailscomp(SecurityHoldingdetails);