export const ADD_ACCOUNTSTATEMENTS = "AccountStatement:addAccountStatementList";
export const ADD_CORRESPONDENCEDOCUMENTS = "Correspondence:addCorrespondenceDocumentList";
export const ADD_TRADECONFIRMS = "TradeConfirms:addTradeConfirmDocumentList";
export const ADD_TRADECONFIRMSUMMARY = "TradeConfirmSummary:addTradeConfirmSummaryDocumentList";
export const ADD_TAXDOCUMENTS = "TaxDocuments:addTaxDocumentList";
export const UPDATE_DOCUMENTS = "Documents:UpdateDocuments";
export function AddAccountStatements(newAccountStatementsList, newFilteredDocumentList) {
    return {
        type: ADD_ACCOUNTSTATEMENTS,
        accountStatementsList: newAccountStatementsList,
        filteredAccountStatementsList: newFilteredDocumentList
    }
}
export function AddCorrespondenceDocuments(newCorrespondenceDocumentsList, newFilteredDocumentList, startDate, endDate) {
    return {
        type: ADD_CORRESPONDENCEDOCUMENTS,
        correspondenceDocumentList: newCorrespondenceDocumentsList,
        filteredCorrespondenceDocumentList: newFilteredDocumentList,
        startDate: startDate,
        endDate: endDate
    }
}
export function AddTradeConfirmDocuments(newTradeConfirmDocumentsList, newFilteredDocumentList, isSearchByTradeConfirmSummary) {
    return {
        type: ADD_TRADECONFIRMS,
        tradeConfirmDocumentList: newTradeConfirmDocumentsList,
        filteredTradeConfirmDocumentList: newFilteredDocumentList,
        isSearchByTradeConfirmSummary: isSearchByTradeConfirmSummary
    }
}
export function AddTradeConfirmSummaryDocuments(newTradeConfirmSummaryDocumentsList, newFilteredDocumentList, isSearchByTradeConfirmSummary) {
    return {
        type: ADD_TRADECONFIRMSUMMARY,
        tradeConfirmSummaryDocumentList: newTradeConfirmSummaryDocumentsList,
        filteredTradeConfirmSummaryDocumentList: newFilteredDocumentList,
        isSearchByTradeConfirmSummary: isSearchByTradeConfirmSummary
    }
}
export function AddTaxDocuments(newTaxDocumentsList, newFilteredDocumentList) {
    return {
        type: ADD_TAXDOCUMENTS,
        taxDocumentList: newTaxDocumentsList,
        filteredTaxDocumentList: newFilteredDocumentList
    }
}
export function UpdateDocuments() {
    return {
        type: UPDATE_DOCUMENTS,
        accountStatementsList: null,
        filteredAccountStatementsList: null,
        correspondenceDocumentList: null,
        filteredCorrespondenceDocumentList: null,
        tradeConfirmDocumentList: null,
        filteredTradeConfirmDocumentList: null,
        tradeConfirmSummaryDocumentList: null,
        filteredTradeConfirmSummaryDocumentList: null,
        taxDocumentList: null,
        filteredTaxDocumentList: null
    }
}
