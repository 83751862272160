import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MsgForNoData, getAccountToolTip, IndLongShort, isObjectEmpty } from '../../../../common/constants.jsx';

export function AlertsConfirmationPopup(
    props
) {
    let alertsConfirmationPopup = (
        <Modal isOpen={props.state.isAlertsConfirmationPopupOpen} toggle={props.toggleAlertsConfirmationPopup} key="confirmAlerts01"
            backdrop={false}
            size='lg'
            zIndex='9999'
            className="RCM_CM_modal"
            wrapClassName="RCM_CM_modal_wrapper"
            modalClassName="RCM_CM_modal_class"
            contentClassName="RCM_CM_modal_content"
            centered>
            {props && props.state ?
                <div key= "confirmAlerts02">
                    <ModalHeader toggle={props.toggleAlertsConfirmationPopup} charCode="X" key="confirmAlerts02">
                        <label className="RCM_tile_heading" key='DisclaimerModelheader' key="confirmAlerts03">SUBSCRIBE ALERTS CONFIRMATION</label>
                </ModalHeader>
                    <ModalBody className="RCM_Manage_alert_popup" key="confirmAlerts04">
                        <div className="RCM_manage_alert_popup_subheading" key="confirmAlerts05">Are you sure you want to apply these selections to all accounts?</div>
                           
                        { props.state.selectedAccount &&  props.state.selectedAccount !== null &&  props.state.selectedAccount !== isObjectEmpty ?
                            <div key="confirmAlerts06">
                                <div className="react-bootstrap-table" key="confirmAlerts07">
                                    <table className="table RCM_two_level_table1" key="confirmAlerts08">
                                        <thead key="confirmAlerts09">
                                            <tr key="confirmAlerts10">
                                                <th colSpan={props.state.notificationOptions.length + 1} key="confirmAlerts11">Alert Type</th>

                                            </tr>
                                        </thead>
                                        <tbody key="confirmAlerts12">

                                            { props.state.selectedAccount &&  props.state.selectedAccount !== null &&  props.state.selectedAccount.alertTypes !== undefined
                                                &&  props.state.selectedAccount.alertTypes.length &&
                                                 props.state.selectedAccount.alertTypes.map((data, index) => {
                                                    return (
                                                        < tr className="RCM_disabled" key={"confirmAlerts13" + index + "01"}>
                                                            <td key={"confirmAlerts13" + index + "02"}>{data.alertname}</td>
                                                            {data.subscribedDevices.map((device, index) => {
                                                                return (
                                                                    <td key={"confirmAlerts13" + index + "0201"}>
                                                                        <span className={device.value ? "RCM_check_mark RCM_devices" : "RCM_devices"} key={"confirmAlerts13" + index + "0202"}>{device.deviceDisplayName}</span>
                                                                       
                                                                    </td>
                                                                )
                                                            })

                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                
                            </div> : { MsgForNoData }
                        }
                        <div className="row row-margin" key="confirmAlerts14">
                            <div className="col-sm-12 RCM_alert_cnf_popup_buttons" key="confirmAlerts15">
                                <input className="btn RCM_button_secondary RCM_select_btn" type="submit" value="CANCEL" onClick={props.toggleAlertsConfirmationPopup} key="confirmAlerts16"/>
                                <input className="btn RCM_button_primary RCM_select_btn" type="submit" value="CONFIRM" onClick={props.saveAlerts} key="confirmAlerts17"/>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>

                    </ModalFooter>
                    </div>
          
                : <div key="confirmAlerts19">{MsgForNoData}</div>

            }
        </Modal>
    )
    return alertsConfirmationPopup
}



