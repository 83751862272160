import React from 'react';
import { roundvalue, formatMoney, GetNumericValue, roundvalueForNumbers, getAccountDisplayName, IndLongShort} from '../../../../../common/constants.jsx';
export function AccountPerformanceColumns(accountlist) {
    var columns = [
            {
                dataField: 'displayname', text: "Account", sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                headerAlign: 'left',
                headerClasses: 'RCM_noWrap',
                classes: 'RCM_common_table_column_text',
                formatter: (cell, row) => {
                    return <div title={getAccountDisplayName(accountlist, row.key, IndLongShort.LONG)}>{cell}</div>;
            },
            footer: () => (
                <span>Total </span>
            )
        }, 
        {
            dataField: 'lastmarketclose', text: "Last Market Close",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSortForAmountPercent(a, b, order),
            headerAlign: 'center',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            footer: columnData => (
                <div className="RCM_displayflex">
                    <div className="RCM_Performance_LCM_col RCM_Spanwidthone">

                        <span>
                            {formatMoney(roundvalueForNumbers(columnData.reduce((acclerator, currentValue) => {
                                if (currentValue && currentValue !== null && currentValue !== '') {
                                    let value = isNaN(currentValue.value) ? 0 : parseFloat(currentValue.value);
                                    return acclerator + value;
                                } else
                                    return acclerator + 0
                            }, 0)))}
                        </span>
                    </div>
                            &nbsp;/&nbsp;
                    <div className="RCM_Performance_LCM_col RCM_Spanwidth">
                        <span>
                            {roundvalue(columnData.reduce((acclerator, currentValue) => {
                                if (currentValue && currentValue !== null && currentValue !== '') {
                                    let value = isNaN(currentValue.percent) ? 0 : parseFloat(currentValue.percent);
                                    return acclerator + value;
                                } else
                                    return acclerator + 0
                            },0),2) + '%'}
                        </span>
                    </div>
                </div>
                
            ),
            footerClasses: "RCM_common_table_column_number",
            formatter: (cell, row) => {
                if (row.lastmarketclose && row.lastmarketclose !== null) {
                    let value = row.lastmarketclose.value !== undefined && row.lastmarketclose.value !== null && row.lastmarketclose.value !== 0 ? formatMoney(roundvalue(row.lastmarketclose.value, 2)) : GetNumericValue(row.lastmarketclose.value);
                    let valuePerc = row.lastmarketclose.percent !== undefined && row.lastmarketclose.percent !== null && row.lastmarketclose.percent !== 0 ? roundvalue(row.lastmarketclose.percent, 2) + '%' : GetNumericValue(row.lastmarketclose.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Performance_LCM_col RCM_Spanwidthone">
                            <span className={GetClassName(row.lastmarketclose.increase)}>
                                {value}</span></div>
                            &nbsp;/&nbsp;
                        <div className="RCM_Performance_LCM_col RCM_Spanwidth">
                            <span className={GetClassName(row.lastmarketclose.increase)}>
                                {valuePerc}</span>
                        </div>
                    </div>
                }else
                    return <div>-&nbsp;/&nbsp;-</div>;
            }
        },
        /*{
            dataField: 'lastmonthend', text: "Last Month End",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (row.lastmonthend && row.lastmonthend !== null) {
                    let value = row.lastmonthend.value !== undefined && row.lastmonthend.value !== null && row.lastmonthend.value !== 0 ? formatMoney(roundvalue(row.lastmonthend.value, 2)) : GetNumericValue(row.lastmonthend.value);
                    let valuePerc = row.lastmonthend.percent !== undefined && row.lastmonthend.percent !== null && row.lastmonthend.percent !== 0 ? roundvalue(row.lastmonthend.percent, 2) + '%' : GetNumericValue(row.lastmonthend.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Spanwidthone">
                            <span className={GetClassName(row.lastmonthend.increase)}>
                                {value}</span></div>
                        &nbsp;/&nbsp;
                        <div className="RCM_Spanwidth">
                            <span className={GetClassName(row.lastmonthend.increase)}>
                                {valuePerc}</span>
                        </div>
                    </div>
                } else
                    return <div>-&nbsp;/&nbsp;-</div>;
            },
            footer: columnData => (
                <span>{formatMoney(roundvalueForNumbers(columnData.reduce((acclerator, currentValue) => {
                    if (currentValue && currentValue !== null) {
                        let value = isNaN(currentValue.value) ? 0: parseInt(currentValue.value);
                        return acclerator + value;
                    } else
                        return acclerator + 0
                }, 0)))}</span>
            ),
            footerClasses: "RCM_common_table_column_number",
        },
        {
            dataField: 'lastquarterend.value', text: "Last Quarter End",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (row.lastquarterend && row.lastquarterend !== null) {
                    let value = row.lastquarterend.value !== undefined && row.lastquarterend.value !== null && row.lastquarterend.value !== 0 ? formatMoney(roundvalue(row.lastquarterend.value, 2)) : GetNumericValue(row.lastquarterend.value);
                    let valuePerc = row.lastquarterend.percent !== undefined && row.lastquarterend.percent !== null && row.lastquarterend.percent !== 0 ? roundvalue(row.lastquarterend.percent, 2) + '%' : GetNumericValue(row.lastquarterend.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Spanwidthone">
                            <span className={GetClassName(row.lastquarterend.increase)}>
                                {value}</span></div>
                            &nbsp;/&nbsp;
                        <div className="RCM_Spanwidth">
                            <span className={GetClassName(row.lastquarterend.increase)}>
                                {valuePerc}</span>
                        </div>
                    </div>
                } else
                    return <div>-&nbsp;/&nbsp;-</div>;
            },
            footer: columnData => (
                <span>{formatMoney(roundvalueForNumbers(columnData.reduce((acclerator, currentValue) => {
                    if (currentValue && currentValue !== null) {
                        let value = isNaN(currentValue.value) ? 0 : parseInt(currentValue.value);
                        return acclerator + value;
                    } else
                        return acclerator + 0
                }, 0)))}</span>
            ),
            footerClasses: "RCM_common_table_column_number",
        },*/
        {
            dataField: 'monthtodate', text: "Month To Date (%)",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSort(a, b, order),
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            footer: () => (
                <span> </span>
            ),
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            }
        },
        {
            dataField: 'quartertodate', text: "Quarter To Date (%)",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSort(a, b, order),
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            },
            footer: () => (
                <span> </span>
            )
        },
        {
            dataField: 'yeartodate', text: "Year To Date (%)",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSort(a, b, order),
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            },
            footer: () => (
                <span> </span>
            )
        },
        
        ];

    return columns;
}

export function AccountPerformanceChildColumns() {
    var columns = [
        {
            dataField: 'secinfo.secid', text: "",
            //sort: true,
            //sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                //return <div title={getAccountToolTip(row, IndLongShort.LONG)}>{getDisplayName(row)}</div>;
                return <div>{cell}</div>
            }
        },
        {
            dataField: 'secinfo.secname', text: "",
            //sort: true,
            //sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                //return <div title={getAccountToolTip(row, IndLongShort.LONG)}>{getDisplayName(row)}</div>;
                return <div>{cell}</div>
            }
        },
        {
            dataField: 'lastmarketclose.value', text: "Last Market Close",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',

            formatter: (cell, row) => {
                if (row.lastmarketclose && row.lastmarketclose !== null) {
                    let value = row.lastmarketclose.value !== undefined && row.lastmarketclose.value !== null && row.lastmarketclose.value !== 0 ? formatMoney(roundvalue(row.lastmarketclose.value, 2)) : GetNumericValue(row.lastmarketclose.value);
                    let valuePerc = row.lastmarketclose.percent !== undefined && row.lastmarketclose.percent !== null && row.lastmarketclose.percent !== 0 ? roundvalue(row.lastmarketclose.percent, 2) + '%' : GetNumericValue(row.lastmarketclose.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Spanwidthone">
                            <span className={GetClassName(row.lastmarketclose.increase)}>
                                {value}</span></div>
                            &nbsp;/&nbsp;
                        <div className="RCM_Spanwidth">
                            <span className={GetClassName(row.lastmarketclose.increase)}>
                                {valuePerc}</span>
                        </div>
                    </div>
                } else
                    return <div>-&nbsp;/&nbsp;-</div>;
            }
        },
        {
            dataField: 'lastmonthend.value', text: "Last Month End",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (row.lastmonthend && row.lastmonthend !== null) {
                    let value = row.lastmonthend.value !== undefined && row.lastmonthend.value !== null && row.lastmonthend.value !== 0 ? formatMoney(roundvalue(row.lastmonthend.value, 2)) : GetNumericValue(row.lastmonthend.value);
                    let valuePerc = row.lastmonthend.percent !== undefined && row.lastmonthend.percent !== null && row.lastmonthend.percent !== 0 ? roundvalue(row.lastmonthend.percent, 2) + '%' : GetNumericValue(row.lastmonthend.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Spanwidthone">
                            <span className={GetClassName(row.lastmonthend.increase)}>
                                {value}</span></div>
                        &nbsp;/&nbsp;
                        <div className="RCM_Spanwidth">
                            <span className={GetClassName(row.lastmonthend.increase)}>
                                {valuePerc}</span>
                        </div>
                    </div>
                } else
                    return <div>-&nbsp;/&nbsp;-</div>;
            }
        },
        {
            dataField: 'lastquarterend.value', text: "Last Quarter End",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (row.lastquarterend && row.lastquarterend !== null) {
                    let value = row.lastquarterend.value !== undefined && row.lastquarterend.value !== null && row.lastquarterend.value !== 0 ? formatMoney(roundvalue(row.lastquarterend.value, 2)) : GetNumericValue(row.lastquarterend.value);
                    let valuePerc = row.lastquarterend.percent !== undefined && row.lastquarterend.percent !== null && row.lastquarterend.percent !== 0 ? roundvalue(row.lastquarterend.percent, 2) + '%' : GetNumericValue(row.lastquarterend.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Spanwidthone">
                            <span className={GetClassName(row.lastquarterend.increase)}>
                                {value}</span></div>
                            &nbsp;/&nbsp;
                        <div className="RCM_Spanwidth">
                            <span className={GetClassName(row.lastquarterend.increase)}>
                                {valuePerc}</span>
                        </div>
                    </div>
                } else
                    return <div>-&nbsp;/&nbsp;-</div>;
            }
        },
        {
            dataField: 'monthtodate.percent', text: "Month To Date (%)",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (row.monthtodate && row.monthtodate !== null && row.monthtodate.percent !== undefined && row.monthtodate.percent !== null) {
                    let valuePerc = row.monthtodate.percent !== 0 ? roundvalue(row.monthtodate.percent, 2) + '%' : GetNumericValue(row.monthtodate.percent, true);
                    return <div className={GetClassName(row.monthtodate.increase)}>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            }
        },
        {
            dataField: 'quartertodate', text: "Quarter To Date (%)",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            }
        },
        {
            dataField: 'yeartodate.percent', text: "Year To Date (%)",
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (row.yeartodate && row.yeartodate !== null && row.yeartodate.percent !== undefined && row.yeartodate.percent !== null) {
                    let valuePerc = row.yeartodate.percent !== 0 ? roundvalue(row.yeartodate.percent, 2) + '%' : GetNumericValue(row.yeartodate.percent, true);
                    return <div className={GetClassName(row.yeartodate.increase)}>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            }
        },
       
        {
            dataField: '', text: "",
            //sort: true,
            //sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            formatter: () => {
               
                return <div></div>
            }
        }
    ];

    return columns;
}

function customCaretSort(order) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
function GetClassName(value) {
    if (value === undefined) {
        return '';
    }
    else if (value) {
        return "RCM_positiveval";
    }
    else {
        return "RCM_negativeval";
    }
}
function customSort(a, b, order) {
    let num1 = a && a !== null ? parseFloat(a) : 0;
    let num2 = b && b !== null ? parseFloat(b) : 0;
    if (order === 'asc') {
        return num2 - num1;
    }
    return num1 - num2 // desc
}
function customSortForAmountPercent(a, b, order) {
    let num1 = a && a !== null && a.value !== undefined && a.value !== null ? parseFloat(a.value) : 0;
    let num2 = b && b !== null && b.value !== undefined && b.value !== null ? parseFloat(b.value) : 0;
    if (order === 'asc') {
        return num2 - num1;
    }
    return num1 - num2 // desc
}