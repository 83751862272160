import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetPreferenceValue, GetPreferencePilotValue, tabSelection } from './../common/constants';
import * as $ from 'jquery';
class TabView extends React.Component {


    constructor(props) {
        super(props);

        this.handleTabSelections = this.handleTabSelections.bind(this);

        this.state = {
            navigate: this.props.navigate,
            preferences: this.props.preferences,
            isCustomView: this.getPreferenceValue(),
            tabsLinks: this.getNavigationLinks(),
            isAccountTabSelected: this.getAccountTabSelectedFlag(),
            prevRoute: this.props.location.pathname
        };

        this.getPreferenceValue = this.getPreferenceValue.bind(this);
        this.AccountTabSelected = this.AccountTabSelected.bind(this);
        this.AccountTabDeselected = this.AccountTabDeselected.bind(this);
        this.handleTabSelectionDeselection = this.handleTabSelectionDeselection.bind(this);
    }

    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }

    componentDidUpdate() {
        if (this.props.preferences && this.state.preferences) {
            if (this.props.preferences !== this.state.preferences) {
                let iscustomview = this.getPreferenceValue();
                this.setState({ isCustomView: iscustomview, preferences: this.props.preferences });
            }
        }
        const currentRoute = this.props.location.pathname;
        if (currentRoute !== this.state.prevRoute) {
            this.handleTabSelectionDeselection();
        }
    }

    handleTabSelectionDeselection() {
     
            const currentRoute = this.props.history.location.pathname;
            if (this.state.isCustomView) {
                let tablinks = this.state.tabsLinks;
                for (const [index, component] of tablinks.entries()) {
                    var route = "/" + component.displayName;
                    if (component.displayName === 'HOME' && (route === currentRoute || currentRoute === '/Accounts' || currentRoute === '/')) {
                        component.active = true;
                    } else if (component.displayName === 'HOLDINGS' && (currentRoute === '/Toptendetail' || currentRoute === '/SecurityHoldingdetails' || currentRoute === '/AccountHoldingdetails' || currentRoute === ' /AssetHoldingdetails')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'ASSET ALLOCATION' && (currentRoute === '/Assetallocationdetail' || currentRoute === '/Assetallocationhistory')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'BALANCES' && (currentRoute === '/Balancesdetail' || currentRoute === '/Balanceshistory')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'ACTIVITY' && (currentRoute === '/Activitydetail')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'REALIZED GAIN LOSS' && (currentRoute === '/RealizedSecurityHoldingdetails' || currentRoute === '/RealizedAccountHoldingdetails' || currentRoute === '/RealizedGainLossDetails')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'INCOME' && (currentRoute === '/EstimatedProjectedIncomeDetails' || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity' || currentRoute === '/EstimatedIncomeAccount' || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/ProjectedIncomeSummary' || currentRoute === '/ProjectedIncomeSecurity' || currentRoute === '/ProjectedIncomeAccount' || currentRoute === '/ProjectedIncomeDetails')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'LIABILITIES' && (currentRoute === '/Liabilities')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'ACCOUNT AGGREGATION' && (currentRoute === '/AssetAggregation' )) {
                        component.active = true;
                    }
                    else if (component.displayName === 'SPECIALTY ASSETS' && (currentRoute === '/ManageAssets')) {
                        component.active = true;
                    }
                    else if (component.displayName === 'PERFORMANCE' && (currentRoute === '/PerformanceSummary' || currentRoute === '/AccountPerformance' )) {
                        component.active = true;
                    }
                    else {
                        component.active = false;
                    }
                }
                this.setState({ tabLinks: tablinks, isAccountTabSelected: this.getAccountTabSelectedFlag(), prevRoute: currentRoute });
            }
    }

    AccountTabSelected = (event) => {
        if (event !== null && event !== undefined) {
            let tablinks = this.getNavigationLinks();
            tablinks[0].active = true;
            this.setState({ isAccountTabSelected: true, tabsLinks: tablinks });
        }
        else {
            console.log("Invalid account tab selection received");
        }
    };
    AccountTabDeselected = (event) => {
        if (event !== null && event !== undefined) {
            this.setState({ isAccountTabSelected: false, tabsLinks: [] });
        }
        else {
            console.log("Invalid account tab Deselection received");
        }
    };

    componentDidMount() {
        window.addEventListener('AccountTabSelected', this.AccountTabSelected);
        window.addEventListener('AccountTabDeselected', this.AccountTabDeselected);
        //document.dispatchEvent(new CustomEvent('removeSelectionUsersetting', { bubbles: true }));
        const currentRoute = this.props.location.pathname;
        if (this.state.isCustomView) {
            let tablinks = this.state.tabsLinks;
            for (const [index, component] of tablinks.entries()) {
                var route = "/" + component.displayName;
                if (component.displayName === 'HOME' && (route === currentRoute || currentRoute === '/Accounts' || currentRoute === '/')) {
                    component.active = true;
                } else if (component.displayName === 'HOLDINGS' && (currentRoute === '/Toptendetail' || currentRoute === '/SecurityHoldingdetails' || currentRoute === '/AccountHoldingdetails' || currentRoute === '/AssetHoldingdetails')) {
                    component.active = true;
                }
                else if (component.displayName === 'ASSET ALLOCATION' && (currentRoute === '/Assetallocationdetail' || currentRoute === '/Assetallocationhistory')) {
                    component.active = true;
                }
                else if (component.displayName === 'BALANCES' && (currentRoute === '/Balancesdetail' || currentRoute === '/Balanceshistory')) {
                    component.active = true;
                }
                else if (component.displayName === 'ACTIVITY' && (currentRoute === '/Activitydetail')) {
                    component.active = true;
                }
                else if (component.displayName === 'REALIZED GAIN LOSS' && (currentRoute === '/RealizedSecurityHoldingdetails' || currentRoute === '/RealizedAccountHoldingdetails' || currentRoute === '/RealizedGainLossDetails')) {
                    component.active = true;
                }
                else if (component.displayName === 'INCOME' && (currentRoute === '/EstimatedProjectedIncomeDetails' || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity' || currentRoute === '/EstimatedIncomeAccount' || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/ProjectedIncomeSummary' || currentRoute === '/ProjectedIncomeSecurity' || currentRoute === '/ProjectedIncomeAccount' || currentRoute === '/ProjectedIncomeDetails')) {
                    component.active = true;
                }
                else if (component.displayName === 'LIABILITIES' && (currentRoute === '/Liabilities')) {
                    component.active = true;
                }
                else if (component.displayName === 'ACCOUNT AGGREGATION' && (currentRoute === '/AssetAggregation' )) {
                    component.active = true;
                }
                else if (component.displayName === 'SPECIALTY ASSETS' && (currentRoute === '/ManageAssets')) {
                    component.active = true;
                }
                else if (component.displayName === 'PERFORMANCE' && (currentRoute === '/PerformanceSummary' || currentRoute === '/AccountPerformance')) {
                    component.active = true;
                }
            }
            this.setState({ tabLinks: tablinks, isAccountTabSelected: this.getAccountTabSelectedFlag(), prevRoute: currentRoute });
        }
    }
    componentWillUnmount() {
        // de registering the event here
        window.removeEventListener('AccountTabSelected', this.AccountTabSelected);
        window.removeEventListener('AccountTabDeselected', this.AccountTabDeselected);

    }

    getAccountTabSelectedFlag() {
        const currentRoute = this.props && this.props.location && this.props.location.pathname ? this.props.location.pathname : '';
        if (currentRoute === "/" || currentRoute === '/Accounts' || currentRoute === '/Toptendetail' || currentRoute === '/SecurityHoldingdetails'
            || currentRoute === '/AccountHoldingdetails' || currentRoute === '/AssetHoldingdetails' || currentRoute === '/Activitydetail' || currentRoute === '/Balancesdetail'
            || currentRoute === '/Assetallocationdetail' || currentRoute === '/AssetAggregation' || currentRoute === '/ManageAssets' || currentRoute === '/EstimatedProjectedIncomeDetails'
            || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity' || currentRoute === '/EstimatedIncomeAccount'
            || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/RealizedSecurityHoldingdetails' || currentRoute === '/RealizedAccountHoldingdetails'
            || currentRoute === '/RealizedGainLossDetails' || currentRoute === '/Liabilities' || currentRoute === '/ProjectedIncomeSummary' || currentRoute === '/ProjectedIncomeSecurity'
            || currentRoute === '/ProjectedIncomeAccount' || currentRoute === '/ProjectedIncomeDetails' || currentRoute === '/HistoricalAssetAllocation' || currentRoute === '/Balanceshistory'
            || currentRoute === '/Assetallocationhistory' || currentRoute === '/PerformanceSummary' || currentRoute === '/AccountPerformance') {
            return true;
        }
        else {
            return false;
        }
    }
    getNavigationLinks() {
        let accountNavigate = this.props.navigate ? this.props.navigate.filter(navlink => navlink.Name === 'Accounts') : []
        let tablinks = [];

        // if (this.props && this.props.location && this.props.location.pathname === '/') {
        tablinks.push({
            tabUrl: '/Accounts', active: false, displayName: 'HOME', id: 'RCM_AccountHome'
        });
        //  }
        accountNavigate && accountNavigate[0] && accountNavigate[0].Components && accountNavigate[0].Components.map((component) => {
            if (component.ComponentName === 'Allocation' && component.Active === true)
                tablinks.push({ tabUrl: 'Assetallocationdetail', active: false, displayName: 'ASSET ALLOCATION', id: 'RCM_AccountAssetAllocationDetails' })
            else if (component.ComponentName === 'TopTen' && component.Active === true)
                tablinks.push({ tabUrl: 'Toptendetail', active: false, displayName: 'HOLDINGS', id: 'RCM_AccountToptenDetails' })
            else if (component.ComponentName === 'Balances' && component.Active === true)
                tablinks.push({ tabUrl: 'Balancesdetail', active: false, displayName: 'BALANCES', id: 'RCM_AccountBalanceDetails' })
            else if (component.ComponentName === 'Activity' && component.Active === true)
                tablinks.push({ tabUrl: 'Activitydetail', active: false, displayName: 'ACTIVITY', id: 'RCM_AccountActivityDetails' })
            else if (component.ComponentName === 'RealizedGainLoss' && component.Active === true)
                tablinks.push({ tabUrl: 'RealizedGainLossDetails', active: false, displayName: 'REALIZED GAIN LOSS', id: 'RCM_AccountRealizedgainlossDetails' })
            else if (component.ComponentName === 'Income' && component.Active === true)
                tablinks.push({
                    tabUrl: {
                        pathname: '/EstimatedProjectedIncomeDetails',
                        view: {
                            viewBy: 'E'
                        }
                    }, active: false, displayName: 'INCOME', id: 'RCM_AccountIncomeDetails'
                })
            else if (component.ComponentName === 'Liabilities' && component.Active === true)
                tablinks.push({
                    tabUrl: {
                        pathname: '/Liabilities',
                        view: {
                            viewBy: 'L'
                        }
                    }, active: false, displayName: 'LIABILITIES', id: 'RCM_AccountLiabilitiesDetails'
                })
            else if (component.ComponentName === "Asset Aggregation" && component.Active === true)
                tablinks.push({
                    tabUrl: "/AssetAggregation", active: false, displayName: 'ACCOUNT AGGREGATION', id: 'RCM_AccountAggregationDetails'
                })
            else if (component.ComponentName === "Specialty Assets" && component.Active === true)
                tablinks.push({
                    tabUrl: "/ManageAssets", active: false, displayName: 'SPECIALTY ASSETS', id: 'RCM_ManageAssetsDetails'
                })
            else if (component.ComponentName === "Performance" && component.Active === true && this.isRequired(component.ComponentName))
                tablinks.push({
                    tabUrl: "/AccountPerformance", active: false, displayName: 'PERFORMANCE', id: 'RCM_Performance'
                })
        });

        return tablinks;
    }
    handleTabSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
        document.dispatchEvent(new CustomEvent('removePreferenceMesage', { bubbles: true }));
    }
    isRequired(source) {
        let obj = {
            preferences: this.props.preferences
        }
        let isRequired = ((source === 'PlanManager' || source === 'Services') && planManagerUrl && planManagerUrl !== null && planManagerUrl !== '')
            ? (GetPreferencePilotValue(obj, source, "false") === "true" ? true : false)
            : (source !== 'PlanManager' && source !== 'Services') ? (GetPreferencePilotValue(obj, source, "false") === "true" ? true : false)
                : false;

        return isRequired;
    }
    render() {
        return (
            this.state.isAccountTabSelected && this.state.isCustomView ?
                <div className="RCM_Tab_container">
                    <ul className="nav nav-tabs">
                        {this.state.tabsLinks && this.state.tabsLinks.map((tabLink, index) => {
                            return <li className="nav-item active" id={tabLink.id} key={index}>
                                <NavLink exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                    onClick={this.handleTabSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                            </li>
                        })}
                    </ul>
                </div>
                : ''
        );
    }
}

const mapStateToProps = state => {
    return {
        preferences: state.acct.preferences,

    };
};

export default withRouter(connect(mapStateToProps)(TabView));