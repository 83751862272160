import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as Highcharts from "highcharts";
import { formatMoney } from "../constants.jsx";
import { useHistory } from "react-router-dom";

const AssetAllocationHighChart = ({ data, fontsize }) => {
  const history = useHistory();
  const chartContainerReference = useRef();
  const seriesData = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x) => ({
        name: x.name,
        y: x.percentage,
        color: x.colorCd,
        value: formatMoney(x.value),
      }));
    } else {
      return [];
    }
  }, [data]);

  const handlePieChartPlotClick = useCallback(() => {
    history.push("/Assetallocationdetail");
  }, [history]);

  useEffect(() => {
    Highcharts.chart(chartContainerReference.current, {
      title: {
        text: "",
      },
      chart: {
        type: "pie",
        backgroundColor: "transparent",
        height: fontsize.replace('px', '') * 25
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          events: {
            click: () => handlePieChartPlotClick(),
          },
          borderColor: 'transparant',
        },
      },
      tooltip: {
        pointFormat: `<b>{point.value} ({point.percentage:.2f}%)</b>`,
        backgroundColor: '#000',
        style: {
          color: '#fff',
          fontFamily: 'Avenir'
        },
        outside: false
      },
      series: [
        {
          name: "Asset Allocations",
          colorByPoint: true,
          data: seriesData,
          cursor: "pointer",
        },
      ],
    });
  }, [fontsize, handlePieChartPlotClick, seriesData]);

  return <div ref={chartContainerReference}></div>;
};

export default React.memo(AssetAllocationHighChart, (prevProps, nextProps) => {
  return JSON.stringify(prevProps || {}) === JSON.stringify(nextProps || {})
    ? true
    : false;
});
