import React from 'react'

const disClaimerHeadingStyle = {
    color: "#16a5b0",
    marginBottom: '0.3rem'
  };

const DisclaimerText = () => {
    return (
        <div>
            <section>
              <div style={disClaimerHeadingStyle}>
                Electronic Delivery Agreement
              </div>
              <p>
                To receive electronic notification that documents are available
                for you to view online in an electronic format rather than
                receiving paper documents through the U.S. Mail, you must
                confirm your consent by reviewing and agreeing to the terms and
                conditions of this Agreement and by indicating your selection(s)
                on the Web site page provided to you.
              </p>
              <p>
                Please be sure to read this Agreement in its entirety as it
                contains important information that is required by law to be
                provided to you. Currently, certain documents are not included
                in the electronic notification program and will continue to be
                delivered to you via U.S. Mail. However, in the future some or
                all of these documents may be made available for you to view
                online in accordance with this Agreement.
              </p>
              <p>
                If at any time after consenting to the electronic notification
                program you wish to receive a paper copy of a document made
                available to you for online viewing, you will need to request
                such paper copy from our broker-dealer and/or their agents, who
                may charge you a fee for such copy.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>
                Notification of Availability of Documents
              </div>
              <p>
                Your broker-dealer, its clearing firm National Financial
                Services LLC (NFS), or their respective agents will notify you
                by e-mail or other electronic means when an account statement,
                possibly with related inserts, trade confirmation and related
                prospectuses or other documents are available for online
                viewing. If you select to receive shareholder reports (including
                prospectuses) and other documents, documents may include
                communications from issuers and other third parties. These
                documents include, but are not limited to, prospectuses (except
                prospectuses related to trade confirmations which must be
                individually selected), periodic shareholder reports, proxy
                statements, and other legal and regulatory notices and
                documents. If you select to receive Third Party Quarterly
                Performance Reports, documents may include performance reports
                and regulatory notices, such as privacy policies and other
                required disclosures, and other documents from third parties. If
                any documents are not made available electronically, you will
                receive the printed documents. If you select to receive
                statements and related inserts, inserts may include, but are not
                limited to, marketing documentation and documentation required
                to be provided to you pursuant to regulatory rules, such as
                privacy policies and other important information regarding your
                account. Your broker-dealer and NFS are not responsible for the
                content or accuracy of any documents created or prepared by
                third parties.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>Accessing Documents</div>
              <p>
                The electronic notification you receive will include a link or
                Internet address (URL) where the document(s) can be accessed,
                viewed and printed, and you will be provided with instructions
                on how to access the documents.
              </p>
              <p>
                In the future, you will receive instructions on how to access
                these documents or other documents if they become available in a
                different location.
              </p>
              <p>
                If you have difficulty viewing documents electronically, you
                will need to contact your broker-dealer and/or their agents to
                have them address any such issues.
              </p>
              <p>
                If you have provided a correct e-mail address of record and you
                experience difficulty in receiving the e-mail notification
                electronically, it may be necessary for you to contact your
                internet service provider for further assistance.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>
                Changing Selections or Revoking Consent
              </div>
              <p>
                You may revoke your consent to receive electronic notification
                or change your document delivery preferences at any time. Please
                note that there may be a short delay before your revocation is
                implemented.. Unless revoked by you, your consent to receive
                electronic notification is effective until further notice by
                your broker-dealer, its clearing firm NFS, or their respective
                agents. Your brokerdealer, its clearing firm NFS, and/or their
                respective agents reserve the right to revert any documents you
                have chosen to view online back to paper delivery at any time.
              </p>
              <p>
                You may revoke your consent, change or verify your preferences
                and update your email address of record by visiting the Web site
                page provided to you. Please note that if you revoke your
                election(s), this will result in such document(s) being mailed
                to you in paper form through the U.S. Mail and you will no
                longer receive electronic notification when new documents are
                available to be viewed online.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>Accounts in a Household</div>
              <p>
                If you, as part of a household, have consented to electronic
                notification, such documents will be available online only and
                will no longer be included within the envelope you currently
                receive through U.S. Mail containing any other householded
                documents.
              </p>
              <p>
                However, the account values for all household accounts will
                continue to be provided on the Consolidated Summary of Accounts
                statement which will continue to be provided via U.S. Mail and
                online. In the event that all accounts in the household choose
                electronic notification, then documents will be provided
                electronically, including the Consolidated Summary of Accounts
                statement.
              </p>
              <p>
                Please note that if you are part of a household that has
                consented to electronic delivery of some or all documents and at
                some point in the future an account or transaction that is
                excluded from electronic delivery is associated with the
                household, this may cause your entire electronic delivery
                election to be revoked.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>Combined Statement</div>
              <p>
                If you have elected to receive a combined statement, including
                both your brokerage account information and your bank account
                information (whether through your broker-dealer, investment
                representative or bank representative), by consenting to
                electronic notification, herein, you are consenting to view your
                combined statement through the Internet at the Internet address
                (URL) provided by your broker-dealer. You will no longer receive
                paper copies of your bank or brokerage account statement by U.S.
                mail.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>
                E-mail Address of Record for Accounts with Joint and/or Multiple
                Owners
              </div>
              <p>
                We have this email address on file as the email address of
                record on the account(s) associated with an account owner. Only
                one account owner&apos;s authorization is required to enroll in
                or initiate electronic notification. The e-mail address of
                record will be the only e-mail address that electronic
                notification will be sent to when documents are available for
                viewing. The e-mail address of record must be the email address
                of an account owner who has authority to make transactions and
                act on behalf of the account.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>Interested Parties</div>
              <p>
                If you have designated one or more interested parties to receive
                copies of your account statements, trade confirmations or other
                documents, they will continue to receive copies of such
                documents via U.S. Mail.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>
                Information on Systems Requirements
              </div>
              <p>
                To participate in the electronic notification program you must
                have a valid e-mail address on record and Internet access via a
                browser that is JavaScript-enabled. Your internet service
                provider may apply a charge. Documents are currently provided in
                Portable Document Format (PDF). In order to access PDF
                documents, you must have Adobe® Acrobat Reader® software. This
                software is available for download at no cost at www.adobe.com.
                Downloading time may be slow.
              </p>
              <p>
                In the event that any documents exceed the maximum data capacity
                for online viewing and are unable to be provided to you in an
                electronic format, your brokerdealer, its clearing firm NFS,
                and/or their respective agents will revert such documents you
                have chosen to view online back to paper delivery. You will
                continue to be enrolled in the electronic notification program
                and, when possible, future documents will continue to be
                provided to you electronically. In the event, however, that it
                is not feasible or possible to make future documents available
                for you to view online, your broker-dealer, its clearing firm
                NFS, and/or their respective agents reserve the right to
                unenroll you from the electronic notification program at any
                time and revert any documents you have chosen to view online
                back to paper delivery.
              </p>
              <p>
                If you do not have the ability to access, print and/or retain
                PDF documents, do not consent to this Electronic Notification
                Agreement. By electing to participate in the electronic
                notification program, you confirm that your personal computer is
                equipped with the Adobe Acrobat Reader and that you have the
                ability to access, print and/or retain PDF documents.
              </p>
            </section>
            <section>
              <div style={disClaimerHeadingStyle}>Acceptance and Consent</div>
              <p>
                To consent to the terms of electronic notification program as
                described above, please read the following statement carefully
                before acceptance:
              </p>
              <p>
                I have read, understand and agree to be bound by the terms and
                conditions described above. By clicking the “I Agree” button
                below I consent to receive electronic documents according to the
                process described above. I understand that I may incur costs,
                including but not limited to online time and other charges from
                my internet service provider, in accessing and/or viewing such
                document(s). I understand and agree that: (i) certain documents
                will continue to be delivered to me via U.S. Mail that are not
                included in the electronic notification program and that in the
                future some or all of these documents may be made available for
                me to view online in accordance with this Agreement; (ii) my
                consent to view documents electronically does not automatically
                expire and is not limited as to duration; (iii) my
                broker-dealer, its clearing firm NFS, and/or their respective
                designee or agents may revoke my participation in the electronic
                notification program at any time at their discretion; (iv)
                neither my broker-dealer, its clearing firm NFS, nor their
                respective agents will be liable for any loss, liability, cost,
                expense, or claim for acting upon this authorization or arising
                from my use of the product or services provided pursuant to this
                Agreement; (v) by selecting electronic delivery of shareholder
                reports (including prospectuses) and other documents I
                understand that my selection constitutes a global consent for
                all future account documents (not including trade confirms and
                confirming prospectuses) and revocation of my consent will
                result in revocation of electronic delivery for all documents
                covered by the global consent, and (vi) inserts that may be
                provided along with my account statements contain important
                information or disclosures concerning my account and I agree to
                review such inserts in a timely manner.
              </p>
            </section>
            <section style={{fontSize: '0.75rem'}}>
              <div>
                Third party products referenced herein are the property of their
                respective owners.
              </div>
              <div>
                Third party marks are the property of their respective owners;
                all other marks are the property of FMR LLC.
              </div>
              <div>
                Your Broker Dealer is independent and unaffiliated with National
                Financial Services LLC.
              </div>
              <div>
                Clearing, custody or other brokerage services may be provided by
                National Financial Services LLC.
              </div>
              <div>Member NYSE, SIPC.</div>
              <div>617198.6.0</div>
            </section>
        </div>
    )
}

export default DisclaimerText
