import React from 'react'
import Watermark from '../../../common/watermark';
import ConfirmationModal from '../confirmationModal';

export default function artView(props) {
    const fineArtGroupSectionText = 'A comprehensive, global services group for Art, Jewelry, and other valuable collections. Click the button below to get an overview for Rockefeller Clients.';
    const christiesSectionText = 'A world-leading art and luxury business, renowned and trusted for its live and online auctions, as well as its bespoke private sales. Click the button below to access an overview for Rockefeller Clients.';

    
    return (
        <div className="container">
            {props.isProspectClient ? <Watermark /> : ''}
            <img id="health_image" src="./../images/art_landing_image.png" alt="Health" width="100%" className="RCM_health_base_image" />
            <div>
                <div className="row RCM_health_container">
                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast RCM_health_toast" id="RCM_art_fineArtGroup">
                            <div className="RCM_lifestyle_theFineArtGroup"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">FINE ART GROUP</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">{fineArtGroupSectionText}</div>
                            <div className="mt-4" align="center" className="RCM_lifestyle_btns">
                                <button className="btn btn-primary RCM_button_aviation_page"
                                    onClick={() => props.openFineArtGroup()}
                                    disabled={props.isProspectClient} >
                                    CLICK HERE TO VIEW FINE ART GROUP
                                    </button>
                            </div>
                        </div>

                    </div>

                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast RCM_health_toast" id="art_christies">
                            <div className="RCM_lifestyle_christie"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">CHRISTIE'S</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">{christiesSectionText}</div>
                            <div className="mt-4" align="center" className="RCM_lifestyle_btns">
                                <button className="btn btn-primary RCM_button_aviation_page"
                                    disabled={props.isProspectClient}
                                    onClick={() => props.openChristies()}
                                >CLICK HERE TO VIEW CHRISTIE'S</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ConfirmationModal
                confirmationModalToggle={props.confirmationModalToggle}
                confirmationModalState={props.confirmationModalState}
                openSelectedSectionWindow={props.openSelectedSectionWindow}
                selectedSection={props.selectedSection}
            />
           
        </div>
    )
}
