import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AccountPerformance from './accountperformance/accountperformance';
//import PerformanceSummary from './summaryperformance/summaryperformance';
import { tabSelection, tabSelectionOnLoad } from './../../../../common/constants.jsx';
import { _logPageView } from '../../../../common/_logging';
import { withPageView } from '../../../../hoc/withPageView.jsx';

class PerformanceComponent extends Component {
    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);

        this.state = {
            tabsLinks: this.getTabLinks()
        };
    }

    getTabLinks() {
        let tablinks = [];
       // tablinks.push({ tabUrl: '/PerformanceSummary', active: false, displayName: 'SUMMARY', id: 'RCM_PerformanceSummary' });
        tablinks.push({ tabUrl: '/AccountPerformance', active: true, displayName: 'ACCOUNT', id: 'RCM_AccountPerformance' });
        //tablinks.push({ tabUrl: '/SecurityPerformance', active: false, displayName: 'SECURITY', id: 'RCM_SecurityPerformance' });
        //tablinks.push({ tabUrl: '/AssetPerformance', active: false, displayName: 'ASSET TYPE', id: 'RCM_AssetPerformance' });
        
        return tablinks;
    }

    handleSelections = (param, e)=> {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }

    componentDidMount() {  
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/AccountPerformance', '/Performance');
        this.setState({ tabsLinks: tabsLinks});
    }
    
    render() {
        const performanceComponent = (
          <div>
            <div className="row">
              <div className="col-sm-12" key="lblholdingheadingrow">
                <label
                  className="RCM_tile_heading RCM_details_heading RCM_Toast_heading"
                  key="lblholdingheading"
                >
                  PERFORMANCE
                </label>
              </div>
            </div>
            <div key="dvholdingdetailsview" className="row">
              <div className="col-sm-12" key="lbholdingdetailviewby">
                <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding">
                  <ul className="nav nav-tabs">
                    {this.state.tabsLinks &&
                      this.state.tabsLinks.map((tabLink) => {
                        return (
                          <li className="nav-item active" id={tabLink.id}>
                            <NavLink
                              className="nav-link"
                              exact
                              to={tabLink.tabUrl}
                              className={
                                "nav-link " +
                                (tabLink.active ? "linkactive " : " ")
                              }
                              onClick={this.handleSelections.bind(
                                this,
                                tabLink
                              )}
                            >
                              {tabLink.displayName}
                            </NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                <div>
                  <Switch>
                                <Route
                                    key="root"
                                    exact
                                    path="/Performance"
                                    render={() => <Redirect to="/AccountPerformance" />}
                                />
                                <Route
                                    key="accountperformance"
                                    exact
                                    path="/AccountPerformance"
                                    component={AccountPerformance}
                                />
                                {/* <Route
                                    key="performancesummary"
                                    exact
                                    path="/PerformanceSummary"
                                    component={PerformanceSummary}
                                />
                                
                               
                                 * <Route
                                    key="assetperformance"
                                    exact
                                    path="/AssetPerformance"
                                    component={AssetPerformance}
                                />
                                <Route
                                    key="securityperformance"
                                    exact
                                    path="/SecurityPerformance"
                                    component={SecurityPerformance}
                                />
                                */}
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        );
        return (
            <BrowserRouter>{performanceComponent}</ BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        
    };
};

const enhancement = compose(
    withPageView({ pageName: 'Account Performance'})
)
export default enhancement(withRouter(connect(mapStateToProps)(PerformanceComponent)));