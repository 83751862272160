import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Chart from './../../../../common/chart.jsx';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { getAssetAllocationSummaryColumns } from './assetallocationcolumns.jsx'
import { MsgForNoData } from './../../../../common/constants.jsx';
import Watermark from '../../../../common/watermark.jsx';
export function AssetAllocationSummaryView(
    props
) {
    let assetallocationsummaryview = (
        <div>
            <div className="row RCM_headingwrapper">
                <div className="col-sm-8">
                    <div>
                        <label className="RCM_tile_heading">ASSET ALLOCATION</label></div>
                    {props && props.state.showAssetClassOption ?
                        <div className="RCM_Display_Flex">
                            <Dropdown id="AssetSchemaDropdown"
                                isOpen={props.state.classDropdownOpen}
                                toggle={props.toggleClass}
                                className="RCM_select_box RCM_mm_subselect_box RCM_as_dropdown dropdown"
                                key="addropdown1">
                                <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                    {
                                        props.state.dropdownClassValue
                                    }
                                </DropdownToggle>
                                <DropdownMenu >
                                    {props && props.classDropDownItems && props.classDropDownItems.map((item, index) =>
                                        <DropdownItem key={index} onClick={props.changeClass} disabled={props.state.isProspectClient}>
                                            <div>{item.label}</div>
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        
                     : ''}
                </div>
                {props && props.state.isProspectClient ? <Watermark /> : ''}
                <div className="col-sm-4 RCM_expandviewright">
                    <div className="RCM_expandviewrightWrapper">
                        <div>
                            <button id="TooltipAssetallocationdetailToggle" onClick={props.toggleShowTable}
                                className={(props.showTable ? 'RCM_left_toggle' : 'RCM_left_bar_toggle')}></button>
                            <Tooltip placement="left" isOpen={props.state.tooltipOpen1} target="TooltipAssetallocationdetailToggle"
                                toggle={props.toggle1} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                {props.showTable ? 'Toggle to Chart' : 'Toggle to Table'}
                            </Tooltip>
                            <label className="RCM_togglelabeltext" onClick={props.toggleShowTable}>{props.showTable ? 'Chart' : 'Table'}</label>
                        </div>
                        <div>
                            <Link to='Assetallocationdetail' className="RCM_expandview" id="TooltipAssetallocationdetail"></Link>
                            <Tooltip placement="left" isOpen={props.state.tooltipOpen} target="TooltipAssetallocationdetail"
                                toggle={props.toggle} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                Asset Allocation Detail
                        </Tooltip>
                            <Link to='Assetallocationdetail'> <label className="RCM_togglelabeltext RCM_detailsLabel">Detail</label></Link>

                        </div>
                    </div>
                </div>
            </div>
           
        
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    {props.showTable
                        ? <div className="RCM_Dashboard_Tables RCM_Asset_GroupView_Container RCM_CM_scroll ">
                            <BootstrapTable
                                keyField='id'
                                data={props.rows}
                                columns={getAssetAllocationSummaryColumns()}
                                headerClasses="row-bg"
                                bordered={false}
                                rowClasses={props.rowClasses}
                                classes="RCM_two_level_table1"
                                noDataIndication={MsgForNoData} />
                        </div>
                        : props.showChart
                            ? <Chart data="pie" chartdata={props.rows} />
                            : <div className="RCM_Dashboard_Tables RCM_Asset_GroupView_Container RCM_CM_scroll">
                                <BootstrapTable
                                    keyField='id'
                                    data={props.rows}
                                    columns={getAssetAllocationSummaryColumns()}
                                    headerClasses="row-bg"
                                    rowClasses={props.rowClasses}
                                    classes="RCM_two_level_table1"
                                    noDataIndication={MsgForNoData} />
                            </div>
                    }
                </div>
            </div>
        </div>
    );
    return assetallocationsummaryview
}