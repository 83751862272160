import React from  "react";

const OverlayProcessing = (props) => {
    return (
        <div className="RCM_CM_Overlay">
            <div className={props.documents === true?"RCM_CM_Overlay_body_documents":"RCM_CM_Overlay_body"}>
                <div className="RCM_CM_Overlay_spinner"></div>
                <div>Please Wait, Loading...</div>
            </div>
        </div>
    );
}

export default OverlayProcessing;