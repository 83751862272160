import React from 'react';
import {relasevariables} from '../../index';

export function FooterView(
    props
) {
    let footerview = (
        <div key='footerRow' className="container-fluid" id="footer">
            <footer key='footer' className="RCM_footer_container row">
                <div>
                    <div className="RCM_footer_text_container"><a key='footer1' href="" className="RCM_footer_text" onClick={(e) => donothing(e)}>Version : {relasevariables ? relasevariables.version : ''}</a></div>
                </div>
                <div className="RCM_footer_link_container">
                    {props.crspdfPilotFlag === 'true' && <div className="RCM_footer_text_container"><a key='footer5' href={props.formCrsPdf} className="RCM_footer_text" target="_blank">Form CRS</a></div> }
                    <div className="RCM_footer_text_container"><a key='footer1' href="https://www.rockco.com/cookiepolicy" className="RCM_footer_text" target="_blank">Cookie Consent</a></div>
                    <div className="RCM_footer_text_container"><a key='footer2' href="https://www.rockco.com/privacypolicy" className="RCM_footer_text" target="_blank">Privacy Policy</a></div>
                    <div className="RCM_footer_text_container"><a key='footer3' href="https://www.rockco.com/termsofserviceonline" className="RCM_footer_text" target="_blank">Terms of use</a></div>
                    <div className="RCM_footer_text_container"><a key='footer4' href="https://www.rockco.com/termsofuseaabp" className="RCM_footer_text" target="_blank">End-user license agreement</a></div>
                </div>


                {/* <div className="RCM_footer_text_container"><div key='footer4' className="RCM_footer_text RCM_footer_mail_text">Mail to</div></div>*/}
            </footer>
        </div>
    )
    return footerview;
}

function donothing(event){
    event.preventDefault();
}



