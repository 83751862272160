import React from "react";
import {
  roundvalue,
  formatMoney,
  roundUnitValue,
  GetNumericValue,
  getAccountDisplayName,
  IndLongShort,
  roundUnitValueWithPrecision,
  toDate,
  CheckIfExternalAcct,
} from "./../../../../common/constants.jsx";
import { printTasHoldingExcelReport } from "../../../../common/printreport.jsx";
import { generateTasHoldingExcelReport } from "../../../../common/excelreport.jsx";
import orderBy from "lodash/orderBy";
import { getAssetColorConfig } from "../../../../common/assetschemautilities";
//columns for child table shown on expanding specific row
export function securityColumns(
  totalmkVal,
  totalgainloss,
  totalglpct,
  totalcostbasis,
  rowcol,
  isrowexpanded,
  totaltodayschange,
  totaltodayschangepct,
  rowsortevent,
  viewYield
) {
  var columns = [];
  if (isrowexpanded || rowcol.length === 0) {
    columns = [
      {
        dataField: "SECID",
        text: "Symbol",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "Total:",
        footerAlign: () => "left",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      },
      {
        dataField: "SECNAME",
        text: "Description",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      },
      {
        dataField: "UNIT",
        text: "Quantity",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "AVGCOSTPERSHARE",
        text: "Unit Cost",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(cell, 4)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: () => "right",
      },
      {
        dataField: "RCNTPRICE",
        text: "Price",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "RCNTMKTVAL",
        text: "Value",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalmkVal === null ||
            totalmkVal === "" ||
            totalmkVal === undefined
          )
            return <span>-</span>;
          else if (totalmkVal === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalmkVal, 2))}</span>;
        },
        footerAlign: () => "right",
      },
      {
        dataField: "TODAYSCHANGE",
        text: "Today's Change",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.TODAYSCHANGE !== null &&
            row.TODAYSCHANGE !== undefined &&
            row.TODAYSCHANGE !== 0
              ? formatMoney(roundvalue(row.TODAYSCHANGE, 2))
              : GetNumericValue(row.TODAYSCHANGE);
          var marketValuePerc =
            row.TODAYSCHANGEPCT !== null &&
            row.TODAYSCHANGEPCT !== undefined &&
            row.TODAYSCHANGEPCT !== 0
              ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
              : GetNumericValue(row.TODAYSCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.TODAYSCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totaltodayschange !== null &&
            totaltodayschange !== undefined &&
            totaltodayschange !== 0
              ? formatMoney(totaltodayschange)
              : GetNumericValue(totaltodayschange);
          var marketValuePerc =
            totaltodayschangepct !== null &&
            totaltodayschangepct !== undefined &&
            totaltodayschangepct !== 0
              ? roundvalue(totaltodayschangepct, 2) + "%"
              : GetNumericValue(totaltodayschangepct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totaltodayschange)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totaltodayschangepct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      },

      {
        dataField: "MKTVALCHANGE",
        text: "Unrealized Gain/loss",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.MKTVALCHANGE !== null &&
            row.MKTVALCHANGE !== undefined &&
            row.MKTVALCHANGE !== 0
              ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
              : GetNumericValue(row.MKTVALCHANGE);
          var marketValuePerc =
            row.MKTVALCHANGEPCT !== null &&
            row.MKTVALCHANGEPCT !== undefined &&
            row.MKTVALCHANGEPCT !== 0
              ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
              : GetNumericValue(row.MKTVALCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.MKTVALCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== 0
              ? formatMoney(totalgainloss)
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== 0
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      },
    ];
    if (viewYield) {
      columns.push({
        dataField: "YIELDPCT",
        text: "Yield %",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        formatter: (cell) => {
          var yieldPerc =
            cell && cell !== null && cell !== 0
              ? roundvalue(cell, 2) + "%"
              : GetNumericValue(cell, true);
          if (cell === undefined || cell === null || cell === "")
            return <span>-</span>;
          else if (cell === 0) return <span>0%</span>;
          else return <span>{yieldPerc}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }
  } else {
    columns.push({
      dataField: "SECID",
      text: "Symbol",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: "Total:",
      footerAlign: () => "left",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    });

    if (checkIfFieldEmpty(rowcol, "SECNAME")) {
      columns.push({
        dataField: "df1",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "SECNAME",
        text: "Description",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      });
    }

    if (checkIfFieldEmpty(rowcol, "UNIT")) {
      columns.push({
        dataField: "df2",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "UNIT",
        text: "Quantity",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "AVGCOSTPERSHARE")) {
      columns.push({
        dataField: "df3",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "AVGCOSTPERSHARE",
        text: "Unit Cost",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(cell, 4)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "COSTBASIS")) {
      columns.push({
        dataField: "df4",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: () => "right",
      });
    }

    if (checkIfFieldEmpty(rowcol, "RCNTPRICE")) {
      columns.push({
        dataField: "df5",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "RCNTPRICE",
        text: "Price",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "RCNTMKTVAL")) {
      columns.push({
        dataField: "df6",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "RCNTMKTVAL",
        text: "Value",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalmkVal === null ||
            totalmkVal === "" ||
            totalmkVal === undefined
          )
            return <span>-</span>;
          else if (totalmkVal === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalmkVal, 2))}</span>;
        },
        footerAlign: () => "right",
      });
    }

    if (
      checkIfFieldEmpty(rowcol, "TODAYSCHANGE") &&
      checkIfFieldEmpty(rowcol, "TODAYSCHANGEPCT")
    ) {
      columns.push({
        dataField: "df7",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "TODAYSCHANGE",
        text: "Today's Change",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.TODAYSCHANGE !== null &&
            row.TODAYSCHANGE !== undefined &&
            row.TODAYSCHANGE !== 0
              ? formatMoney(roundvalue(row.TODAYSCHANGE, 2))
              : GetNumericValue(row.TODAYSCHANGE);
          var marketValuePerc =
            row.TODAYSCHANGEPCT !== null &&
            row.TODAYSCHANGEPCT !== undefined &&
            row.TODAYSCHANGEPCT !== 0
              ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
              : GetNumericValue(row.TODAYSCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.TODAYSCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totaltodayschange !== null &&
            totaltodayschange !== undefined &&
            totaltodayschange !== 0
              ? formatMoney(totaltodayschange)
              : GetNumericValue(totaltodayschange);
          var marketValuePerc =
            totaltodayschangepct !== null &&
            totaltodayschangepct !== undefined &&
            totaltodayschangepct !== 0
              ? roundvalue(totaltodayschangepct, 2) + "%"
              : GetNumericValue(totaltodayschangepct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totaltodayschange)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totaltodayschangepct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      });
    }

    if (
      checkIfFieldEmpty(rowcol, "MKTVALCHANGE") &&
      checkIfFieldEmpty(rowcol, "MKTVALCHANGEPCT")
    ) {
      columns.push({
        dataField: "df8",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "MKTVALCHANGE",
        text: "Unrealized Gain/loss",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.MKTVALCHANGE !== null &&
            row.MKTVALCHANGE !== undefined &&
            row.MKTVALCHANGE !== 0
              ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
              : GetNumericValue(row.MKTVALCHANGE);
          var marketValuePerc =
            row.MKTVALCHANGEPCT !== null &&
            row.MKTVALCHANGEPCT !== undefined &&
            row.MKTVALCHANGEPCT !== 0
              ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
              : GetNumericValue(row.MKTVALCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.MKTVALCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== 0
              ? formatMoney(totalgainloss)
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== 0
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      });
    }
    if (viewYield) {
      if (checkIfFieldEmpty(rowcol, "YIELDPCT")) {
        columns.push({
          dataField: "df9",
          isDummyField: true,
          text: "",
          footer: "",
        });
      } else {
        columns.push({
          dataField: "YIELDPCT",
          text: "Yield %",
          headerAlign: "right",
          classes: "RCM_common_table_column_number",
          sort: true,
          sortCaret: (order, column) => customCaretSort(order, column),
          formatter: (cell) => {
            var yieldPerc =
              cell && cell !== null && cell !== 0
                ? roundvalue(cell, 2) + "%"
                : GetNumericValue(cell, true);
            if (cell === undefined || cell === null || cell === "")
              return <span>-</span>;
            else if (cell === 0) return <span>0%</span>;
            else return <span>{yieldPerc}</span>;
          },
          onSort: (field, order) => {
            rowsortevent(field, order);
          },
          footer: "",
        });
      }
    }
  }
  return columns;
}
export function accountColumns(
  totalmkVal,
  totalgainloss,
  totalglpct,
  accounts,
  rowcol,
  isrowexpanded,
  totaltodayschange,
  totaltodayschangepct,
  rowsortevent,
  viewYield
) {
  var headercolumnsbyaccount = [];
  if (isrowexpanded === true || rowcol.length === 0) {
    headercolumnsbyaccount = [
      {
        dataField: "ACCOUNTNICKNAME",
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        text: "Account",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "Total:",
        footerAlign: () => "left",
        formatter: (cell, row) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else
            return (
              <span>
                {acctFormatter(accounts, cell, row.ACCTKEY)}
                {row.ASOFDATE &&
                row.ASOFDATE !== null &&
                row.ASOFDATE !== "" &&
                CheckIfExternalAcct(row.ACCTKEY, accounts) ? (
                  <span className="RCM_asofdate">
                    {"As of: "}
                    {row.ASOFDATE === null || row.ASOFDATE === ""
                      ? "-"
                      : new Date(toDate(row.ASOFDATE))
                          .toISOString()
                          .split("T")[0]}
                  </span>
                ) : (
                  ""
                )}
              </span>
            );
        },
      },
      {
        dataField: "ACCTYPE",
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        text: "Description",
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
        footerAlign: () => "left",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>&nbsp;</span>;
          else return <span>&nbsp;</span>;
        },
      },

      {
        dataField: "UNIT",
        text: "Quantity",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },

      {
        dataField: "AVGCOSTPERSHARE",
        text: "Unit Cost",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(cell, 4)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },

      {
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },

      {
        dataField: "RCNTPRICE",
        text: "Price",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },

      {
        dataField: "MKTVAL",
        text: "Value",
        headerAlign: "right",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalmkVal === null ||
            totalmkVal === "" ||
            totalmkVal === undefined
          )
            return <span>-</span>;
          else if (totalmkVal === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalmkVal, 2))}</span>;
        },
        footerAlign: () => "right",
      },

      {
        dataField: "MKTVALCHANGE",
        text: "Today's Change",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.MKTVALCHANGE !== null &&
            row.MKTVALCHANGE !== undefined &&
            row.MKTVALCHANGE !== 0
              ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
              : GetNumericValue(row.MKTVALCHANGE);
          var marketValuePerc =
            row.MKTVALCHANGEPCT !== null &&
            row.MKTVALCHANGEPCT !== undefined &&
            row.MKTVALCHANGEPCT !== 0
              ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
              : GetNumericValue(row.MKTVALCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.MKTVALCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totaltodayschange !== null &&
            totaltodayschange !== undefined &&
            totaltodayschange !== 0
              ? formatMoney(totaltodayschange)
              : GetNumericValue(totaltodayschange);
          var marketValuePerc =
            totaltodayschangepct !== null &&
            totaltodayschangepct !== undefined &&
            totaltodayschangepct !== 0
              ? roundvalue(totaltodayschangepct, 2) + "%"
              : GetNumericValue(totaltodayschangepct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totaltodayschange)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totaltodayschangepct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      },

      {
        dataField: "UNGLVAL",
        text: "Unrealized Gain/Loss",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        formatter: (cell, row) => {
          var marketValue =
            row.UNGLVAL !== null &&
            row.UNGLVAL !== undefined &&
            row.UNGLVAL !== 0
              ? formatMoney(roundvalue(row.UNGLVAL, 2))
              : GetNumericValue(row.UNGLVAL);
          var marketValuePerc =
            row.UNGLPCT !== null &&
            row.UNGLPCT !== undefined &&
            row.UNGLPCT !== 0
              ? roundvalue(row.UNGLPCT, 2) + "%"
              : GetNumericValue(row.UNGLPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.UNGLVAL)}>{marketValue}</span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.UNGLPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== ""
              ? formatMoney(roundvalue(totalgainloss, 2))
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== ""
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      },
    ];
    if (viewYield) {
      headercolumnsbyaccount.push({
        dataField: "YIELDPCT",
        text: "Yield %",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        formatter: (cell) => {
          var yieldPerc =
            cell && cell !== null && cell !== 0
              ? roundvalue(cell, 2) + "%"
              : GetNumericValue(cell, true);
          if (cell === undefined || cell === null || cell === "")
            return <span>-</span>;
          else if (cell === 0) return <span>0%</span>;
          else return <span>{yieldPerc}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }
  } else {
    //acct num is mandatoty field so not adding check here
    headercolumnsbyaccount.push({
      dataField: "ACCOUNTNICKNAME",
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      text: "Account",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: "Total:",
      footerAlign: () => "left",
      formatter: (cell, row) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else
          return (
            <span>
              {acctFormatter(accounts, cell, row.ACCTKEY)}
              {row.ASOFDATE &&
              row.ASOFDATE !== null &&
              row.ASOFDATE !== "" &&
              CheckIfExternalAcct(row.ACCTKEY, accounts) ? (
                <span className="RCM_asofdate">
                  {"As of: "}
                  {row.ASOFDATE === null || row.ASOFDATE === ""
                    ? "-"
                    : new Date(toDate(row.ASOFDATE))
                        .toISOString()
                        .split("T")[0]}
                </span>
              ) : (
                ""
              )}
            </span>
          );
      },
    });
    headercolumnsbyaccount.push({
      dataField: "df1",
      isDummyField: true,
      text: "",
      footer: "",
    }); //this field is for accttype as we explicitly making it blank adding dummy column here

    if (checkIfFieldEmpty(rowcol, "UNIT")) {
      headercolumnsbyaccount.push({
        dataField: "df2",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "UNIT",
        text: "Quantity",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "AVGCOSTPERSHARE")) {
      headercolumnsbyaccount.push({
        dataField: "df3",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "AVGCOSTPERSHARE",
        text: "Unit Cost",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(cell, 4)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "COSTBASIS")) {
      headercolumnsbyaccount.push({
        dataField: "df4",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "RCNTPRICE")) {
      headercolumnsbyaccount.push({
        dataField: "df5",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "RCNTPRICE",
        text: "Price",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "MKTVAL")) {
      headercolumnsbyaccount.push({
        dataField: "df6",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "MKTVAL",
        text: "Value",
        headerAlign: "right",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalmkVal === null ||
            totalmkVal === "" ||
            totalmkVal === undefined
          )
            return <span>-</span>;
          else if (totalmkVal === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalmkVal, 2))}</span>;
        },
        footerAlign: () => "right",
      });
    }

    if (checkIfFieldEmpty(rowcol, "MKTVALCHANGE")) {
      headercolumnsbyaccount.push({
        dataField: "df7",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "MKTVALCHANGE",
        text: "Today's Change",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.MKTVALCHANGE !== null &&
            row.MKTVALCHANGE !== undefined &&
            row.MKTVALCHANGE !== 0
              ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
              : GetNumericValue(row.MKTVALCHANGE);
          var marketValuePerc =
            row.MKTVALCHANGEPCT !== null &&
            row.MKTVALCHANGEPCT !== undefined &&
            row.MKTVALCHANGEPCT !== 0
              ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
              : GetNumericValue(row.MKTVALCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.MKTVALCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totaltodayschange !== null &&
            totaltodayschange !== undefined &&
            totaltodayschange !== 0
              ? formatMoney(totaltodayschange)
              : GetNumericValue(totaltodayschange);
          var marketValuePerc =
            totaltodayschangepct !== null &&
            totaltodayschangepct !== undefined &&
            totaltodayschangepct !== 0
              ? roundvalue(totaltodayschangepct, 2) + "%"
              : GetNumericValue(totaltodayschangepct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totaltodayschange)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totaltodayschangepct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      });
    }

    if (
      checkIfFieldEmpty(rowcol, "UNGLVAL") &&
      checkIfFieldEmpty(rowcol, "UNGLPCT")
    ) {
      headercolumnsbyaccount.push({
        dataField: "df8",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "UNGLVAL",
        text: "Unrealized Gain/Loss",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        formatter: (cell, row) => {
          var marketValue =
            row.UNGLVAL !== null &&
            row.UNGLVAL !== undefined &&
            row.UNGLVAL !== 0
              ? formatMoney(roundvalue(row.UNGLVAL, 2))
              : GetNumericValue(row.UNGLVAL);
          var marketValuePerc =
            row.UNGLPCT !== null &&
            row.UNGLPCT !== undefined &&
            row.UNGLPCT !== 0
              ? roundvalue(row.UNGLPCT, 2) + "%"
              : GetNumericValue(row.UNGLPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.UNGLVAL)}>{marketValue}</span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.UNGLPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== ""
              ? formatMoney(roundvalue(totalgainloss, 2))
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== ""
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      });
    }
    if (viewYield) {
      if (checkIfFieldEmpty(rowcol, "YIELDPCT")) {
        headercolumnsbyaccount.push({
          dataField: "df9",
          isDummyField: true,
          text: "",
          footer: "",
        });
      } else {
        headercolumnsbyaccount.push({
          dataField: "YIELDPCT",
          text: "Yield %",
          headerAlign: "right",
          classes: "RCM_common_table_column_number",
          sort: true,
          sortCaret: (order, column) => customCaretSort(order, column),
          formatter: (cell) => {
            var yieldPerc =
              cell && cell !== null && cell !== 0
                ? roundvalue(cell, 2) + "%"
                : GetNumericValue(cell, true);
            if (cell === undefined || cell === null || cell === "")
              return <span>-</span>;
            else if (cell === 0) return <span>0%</span>;
            else return <span>{yieldPerc}</span>;
          },
          onSort: (field, order) => {
            rowsortevent(field, order);
          },
          footer: "",
        });
      }
    }
  }
  return headercolumnsbyaccount;
}

//child columns for account expand view
export function accountchildcolumns(viewYield) {
  var accountchildcolumns = [
    {
      dataField: "SECID",
      text: "",
      headerAlign: "left",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      classes: "RCM_common_table_column_text",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "SECNAME",
      text: "",
      headerAlign: "left",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      classes: "RCM_common_table_column_text",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "UNIT",
      text: "",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValue(cell)}</span>;
      },
    },
    {
      dataField: "AVGCOSTPERSHARE",
      text: "",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell, 4)}</span>;
      },
    },
    {
      dataField: "COSTBASIS",
      text: "",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTPRICE",
      text: "",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTMKTVAL",
      text: "",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "TODAYSCHANGE",
      text: "Today's Change",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.TODAYSCHANGE !== null &&
          row.TODAYSCHANGE !== undefined &&
          row.TODAYSCHANGE !== 0
            ? formatMoney(row.TODAYSCHANGE)
            : GetNumericValue(row.TODAYSCHANGE);
        var marketValuePerc =
          row.TODAYSCHANGEPCT !== null &&
          row.TODAYSCHANGEPCT !== undefined &&
          row.TODAYSCHANGEPCT !== 0
            ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
            : GetNumericValue(row.TODAYSCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.TODAYSCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "MKTVALCHANGE",
      text: "Unrealized Gain/loss",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.MKTVALCHANGE !== null &&
          row.MKTVALCHANGE !== undefined &&
          row.MKTVALCHANGE !== 0
            ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
            : GetNumericValue(row.MKTVALCHANGE);
        var marketValuePerc =
          row.MKTVALCHANGEPCT !== null &&
          row.MKTVALCHANGEPCT !== undefined &&
          row.MKTVALCHANGEPCT !== 0
            ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
            : GetNumericValue(row.MKTVALCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.MKTVALCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  if (viewYield) {
    accountchildcolumns.push({
      dataField: "YIELDPCT",
      text: "Yield %",
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        var yieldPerc =
          cell && cell !== null && cell !== 0
            ? roundvalue(cell, 2) + "%"
            : GetNumericValue(cell, true);
        if (cell === undefined || cell === null || cell === "")
          return <span>-</span>;
        else if (cell === 0) return <span>0%</span>;
        else return <span>{yieldPerc}</span>;
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
    });
  }

  accountchildcolumns.push({
    dataField: "dummy",
    isDummyField: true,
    text: "...",
    classes: "RCM_common_table_column_number",
    formatter: () => {
      return (
        <div className="RCM_table_open_popup_button">
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
        </div>
      );
    },
  });
  return accountchildcolumns;
}

function customCaretSort(order) {
  if (!order) return <span className="RCM_Sort_arrow_icon"></span>;
  else if (order === "asc")
    return <span className="RCM_Sort_arrow_down_icon"></span>;
  else if (order === "desc")
    return <span className="RCM_Sort_arrow_up_icon"></span>;
  return null;
}

export function securitychildColumns(accounts, viewYield) {
  //child columns for third level drill down in account tab
  var childColumns = [
    {
      dataField: "ACCOUNTNICKNAME",
      text: "",
      classes: "RCM_common_table_column_text",
      formatter: (cell, row) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return acctFormatter(accounts, cell, row.ACCTKEY);
      },
    },
    {
      dataField: "ACCTYPE",
      text: "",
      classes: "RCM_common_table_column_text",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>&nbsp;</span>;
        else return <span>&nbsp;</span>;
      }, //account type
    },
    {
      dataField: "UNIT",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValue(cell)}</span>;
      },
    },
    {
      dataField: "AVGCOSTPERSHARE",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell, 4)}</span>;
      },
    },
    {
      dataField: "COSTBASIS",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTPRICE",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTMKTVAL",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "TODAYSCHANGE",
      text: "Today's Change",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.TODAYSCHANGE !== null &&
          row.TODAYSCHANGE !== undefined &&
          row.TODAYSCHANGE !== 0
            ? formatMoney(row.TODAYSCHANGE)
            : GetNumericValue(row.TODAYSCHANGE);
        var marketValuePerc =
          row.TODAYSCHANGEPCT !== null &&
          row.TODAYSCHANGEPCT !== undefined &&
          row.TODAYSCHANGEPCT !== 0
            ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
            : GetNumericValue(row.TODAYSCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.TODAYSCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "MKTVALCHANGE",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.MKTVALCHANGE !== null &&
          row.MKTVALCHANGE !== undefined &&
          row.MKTVALCHANGE !== 0
            ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
            : GetNumericValue(row.MKTVALCHANGE);
        var marketValuePerc =
          row.MKTVALCHANGEPCT !== null &&
          row.MKTVALCHANGEPCT !== undefined &&
          row.MKTVALCHANGEPCT !== 0
            ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
            : GetNumericValue(row.MKTVALCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.MKTVALCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  if (viewYield) {
    childColumns.push({
      dataField: "YIELDPCT",
      text: "Yield %",
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        var yieldPerc =
          cell && cell !== null && cell !== 0
            ? roundvalue(cell, 2) + "%"
            : GetNumericValue(cell, true);
        if (cell === undefined || cell === null || cell === "")
          return <span>-</span>;
        else if (cell === 0) return <span>0%</span>;
        else return <span>{yieldPerc}</span>;
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
    });
  }
  childColumns.push({
    dataField: "dummy",
    isDummyField: true,
    text: "...",
    classes: "RCM_common_table_column_number",
    formatter: () => {
      return (
        <div className="RCM_table_open_popup_button">
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
        </div>
      );
    },
  });

  return childColumns;
}

export function taschildcolumns(totalgainloss, totalglpct) {
  //child columns for third level drill down in account tab
  const childColumns = [
    {
      dataField: "AcquisitionDate",
      text: "Acquisition Date",
      sort: true,
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      sortCaret: (order, column) => customCaretSort(order, column),
      footer: "",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "HoldingPeriod",
      text: "Holding Period",
      sort: true,
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      sortCaret: (order, column) => customCaretSort(order, column),
      footer: "",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "Quantity",
      text: "Quantity",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValueWithPrecision(cell, 4)}</span>;
      },
      footer: "",
    },
    {
      dataField: "AvgUnitCost",
      text: "Unit Cost",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 4), 4)}</span>;
      },
      footer: "",
    },
    {
      dataField: "CostBasis",
      text: "Cost Basis",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
      footer: "",
    },
    {
      dataField: "RecentPrice",
      text: "Price",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
      footer: "",
    },
    {
      dataField: "RecentMktVal",
      text: "Value",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
      footer: "",
    },
    {
      dataField: "abc",
      text: "",
      isDummyField: true,
      footer: "Total:",
      footerAlign: () => "right",
    },
    {
      dataField: "UnrealizedGainLossAmt",
      text: "Unrealized Gain/loss",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.UnrealizedGainLossAmt !== null &&
          row.UnrealizedGainLossAmt !== undefined &&
          row.UnrealizedGainLossAmt !== "" &&
          row.UnrealizedGainLossAmt !== 0
            ? formatMoney(row.UnrealizedGainLossAmt)
            : GetNumericValue(row.UnrealizedGainLossAmt);
        var marketValuePerc =
          row.UnrealizedGainLossPct !== null &&
          row.UnrealizedGainLossPct !== undefined &&
          row.UnrealizedGainLossPct !== "" &&
          row.UnrealizedGainLossPct !== 0
            ? roundvalue(row.UnrealizedGainLossPct, 2) + "%"
            : GetNumericValue(row.UnrealizedGainLossPct, true);

        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.UnrealizedGainLossAmt)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.UnrealizedGainLossPct)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
      footer: () => {
        var marketValue =
          totalgainloss !== null &&
          totalgainloss !== undefined &&
          totalgainloss !== "" &&
          totalgainloss !== 0
            ? formatMoney(roundvalue(totalgainloss, 2))
            : GetNumericValue(totalgainloss);
        var marketValuePerc =
          totalglpct !== null &&
          totalglpct !== undefined &&
          totalglpct !== "" &&
          totalglpct !== 0
            ? roundvalue(totalglpct, 2) + "%"
            : GetNumericValue(totalglpct, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(totalgainloss)}>{marketValue}</span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidthfooter">
              <span className={GetClassName(totalglpct)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
      footerAlign: () => "right",
    },
  ];

  return childColumns;
}

//method to get child table rows on expand used for Account tab
export function renderRowsByAccount(
  rowdata,
  childColumnToSort,
  childColumnSortOrder
) {
  if (childColumnToSort === "ACCOUNTNICKNAME") childColumnToSort = "SECID";
  if (childColumnToSort === "MKTVAL") childColumnToSort = "RCNTMKTVAL";
  if (childColumnToSort === "MKTVALCHANGE") childColumnToSort = "TODAYSCHANGE";
  if (childColumnToSort === "UNGLVAL") childColumnToSort = "MKTVALCHANGE";
  let data = [];
  let datarows = [];
  data = rowdata.INVPOSLIST;
  if (data) {
    data.map((d, i) =>
      datarows.push({
        id: i,
        CUSIP: d.CUSIP,
        ACCTNUM: d.ACCTNUM,
        ACCTKEY: d.ACCTKEY,
        ACCTYPE: d.ACCTYPE,
        DATASRC: d.DATASRC,
        ASSETTOTALPCT: d.ASSETTOTALPCT,
        ASSETCAT: d.SECINFO ? d.SECINFO.ASSETCAT : "",
        SECID: d.SECINFO ? d.SECINFO.SECID : "",
        SECNAME: d.SECINFO ? d.SECINFO.SECNAME : "",
        SYMBOL_DESC: {
          SECID: d.SECINFO.SECID,
          SECNAME: d.SECINFO.SECNAME,
        },
        TICKER: d.SECINFO.TICKER,
        RCNTMKTVAL: d.MKTVALS ? d.MKTVALS.RCNTMKTVAL : null,
        MKTVALCHANGE: d.UnrealizedValue,
        MKTVALCHANGEPCT: d.UnrealizedPct,
        DTASOF: d.SHRQNTS ? d.SHRQNTS.DTASOF : null,
        UNIT: d.SHRQNTS ? d.SHRQNTS.RecentUNIT : null,
        RCNTPRICE: d.PRICING ? d.PRICING.RCNTPRICE : null,
        PRICECHG: d.PRICING ? d.PRICING.PRICECHG : null,
        PRICECHGPCT: d.PRICING ? d.PRICING.PRICECHGPCT : null,
        AVGCOSTPERSHARE: d.COSTBASIS ? d.COSTBASIS.AVGCOSTPERSHARE : null,
        COSTBASIS: d.COSTBASIS ? d.COSTBASIS.COSTBASIS : null,
        DESCRIPTION: "",
        TODAYSCHANGE: d.MKTVALS ? d.MKTVALS.MKTVALCHANGE : null,
        TODAYSCHANGEPCT: d.MKTVALS ? d.MKTVALS.MKTVALCHANGEPCT : null,
        YIELDPCT: d.SECINFO ? d.SECINFO.YieldPct : "",
      })
    );
  }
  if (childColumnToSort !== "" && childColumnSortOrder !== "") {
    if (childColumnToSort === "SECID") {
      datarows = orderBy(
        datarows,
        [(row) => row[childColumnToSort].toLowerCase()],
        [childColumnSortOrder]
      );
    } else {
      datarows = orderBy(datarows, [childColumnToSort], [childColumnSortOrder]);
    }
  }

  return datarows;
}

export function renderRowsForAccount(rowdata, accountlist) {
  let datarows = [];
  if (rowdata) {
    rowdata.map((d, i) =>
      datarows.push({
        id: i,
        ACCTNUM: d.ACCTNUM,
        ACCTKEY: d.ACCTKEY,
        MKTVALCHANGE: d.MKTVALS ? d.MKTVALS.MKTVALCHANGE : null,
        MKTVALCHANGEPCT: d.MKTVALS ? d.MKTVALS.MKTVALCHANGEPCT : null,
        MKTVAL: d.MKTVALS ? d.MKTVALS.RCNTMKTVAL : null,
        INVPOSLIST: d.INVPOSLIST,
        UNGLVAL: d.UNGLVAL,
        UNGLPCT: d.UNGLPCT,
        MKTVAL: d.MKTVAL,
        COSTBASIS: d.COSTBASIS,
        ACCOUNTNICKNAME: getAccountDisplayName(
          accountlist,
          d.ACCTKEY,
          IndLongShort.SINGLEVIEW
        ),
        ASOFDATE: d.ASOFDATE,
      })
    );
  }
  return datarows;
}

const GetAsOfDate = (key, balancedetails) => {
  if (
    balancedetails &&
    balancedetails.value &&
    balancedetails.value.length > 0 &&
    balancedetails.value[0] &&
    balancedetails.value[0].accountbalances
  ) {
    const filterrow = balancedetails.value[0].accountbalances.filter(
      (d) => d.key === key
    );
    if (filterrow && filterrow.length > 0) {
      return filterrow[0].asofdate;
    }
  } else {
    return "";
  }
};

//method to get child table rows on expand used for security and Account tab
export function renderRowsBySecurity(rowdata) {
  let datarows = [];
  for (var i = 0; i < rowdata.length; i++) {
    let d = rowdata[i];
    if (d.INVPOSLIST && d.INVPOSLIST.length > 0) {
      datarows.push({
        UNQID: d.SECINFO ? d.SECINFO.UNQID : null,
        SECID: d.SECINFO ? d.SECINFO.SECID : null,
        SECNAME: d.SECINFO ? d.SECINFO.SECNAME : null,
        RCNTMKTVAL: d.MKTVALS ? d.MKTVALS.RCNTMKTVAL : null,
        MKTVALCHANGE: d.UnrealizedValue,
        MKTVALCHANGEPCT: d.UnrealizedPct,
        DTASOF: d.SHRQNTS ? d.SHRQNTS.DTASOF : null,
        UNIT: d.SHRQNTS ? d.SHRQNTS.RecentUNIT : null,
        RCNTPRICE: d.PRICING ? d.PRICING.RCNTPRICE : null,
        AVGCOSTPERSHARE: d.COSTBASIS ? d.COSTBASIS.AVGCOSTPERSHARE : null,
        COSTBASIS: d.COSTBASIS ? d.COSTBASIS.COSTBASIS : null,
        INVPOSLIST: d.INVPOSLIST,
        DESCRIPTION: "",
        TODAYSCHANGE: d.MKTVALS ? d.MKTVALS.MKTVALCHANGE : null,
        TODAYSCHANGEPCT: d.MKTVALS ? d.MKTVALS.MKTVALCHANGEPCT : null,
        YIELDPCT: d.SECINFO ? d.SECINFO.YieldPct : "",
      });
    }
  }
  return datarows;
}

//method to get child table rows on expand used for security and Account tab
export function renderRowsBySecurityChild(
  rowdata,
  childColumnToSort,
  childColumnSortOrder,
  accountlist
) {
  let datarows = [];
  if (childColumnToSort === "SECID") childColumnToSort = "ACCOUNTNICKNAME";
  if (childColumnToSort === "SECNAME") {
    childColumnToSort = "ACCTYPE";
  }
  if (rowdata.INVPOSLIST && rowdata.INVPOSLIST.length > 0) {
    for (var i = 0; i < rowdata.INVPOSLIST.length; i++) {
      let d = rowdata.INVPOSLIST[i];
      datarows.push({
        CUSIP: d.CUSIP,
        ACCTNUM: d.ACCTNUM,
        ACCTNUM: d.ACCTKEY,
        ACCTYPE: d.ACCTYPE,
        ACCTKEY: d.ACCTKEY,
        MKTVALCHANGE: d.MKTVALS ? d.MKTVALS.MKTVALCHANGE : null,
        RCNTMKTVAL: d.MKTVALS ? d.MKTVALS.RCNTMKTVAL : null,
        MKTVALCHANGE: d.UnrealizedValue,
        MKTVALCHANGEPCT: d.UnrealizedPct,
        UNIT: d.SHRQNTS ? d.SHRQNTS.RecentUNIT : null,
        RCNTPRICE: d.PRICING ? d.PRICING.RCNTPRICE : null,
        AVGCOSTPERSHARE: d.COSTBASIS ? d.COSTBASIS.AVGCOSTPERSHARE : null,
        COSTBASIS: d.COSTBASIS ? d.COSTBASIS.COSTBASIS : null,
        SECID: d.SECINFO ? d.SECINFO.SECID : null,
        SECNAME: d.SECINFO ? d.SECINFO.SECNAME : null,
        id: i,
        TODAYSCHANGE: d.MKTVALS ? d.MKTVALS.MKTVALCHANGE : null,
        TODAYSCHANGEPCT: d.MKTVALS ? d.MKTVALS.MKTVALCHANGEPCT : null,
        ACCOUNTNICKNAME: getAccountDisplayName(
          accountlist,
          d.ACCTKEY,
          IndLongShort.SINGLEVIEW
        ),
        YIELDPCT: d.SECINFO ? d.SECINFO.YieldPct : "",
      });
    }
    if (childColumnToSort !== "" && childColumnSortOrder !== "") {
      if (
        childColumnToSort === "ACCOUNTNICKNAME" ||
        childColumnToSort === "ACCTYPE"
      ) {
        datarows = orderBy(
          datarows,
          [(row) => row[childColumnToSort].toLowerCase()],
          [childColumnSortOrder]
        );
      } else {
        datarows = orderBy(
          datarows,
          [childColumnToSort],
          [childColumnSortOrder]
        );
      }
    }

    return datarows;
  }
  return datarows;
}
export function AssetColumns(
  totalmkVal,
  totalgainloss,
  totalglpct,
  totalcostbasis,
  rowcol,
  isrowexpanded,
  totaltodayschange,
  totaltodayschangepct,
  rowsortevent,
  totalassetpct,
  headerColumnStyles,
  otherColumnStyles,
  smallColumnStyles,
  viewYield
) {
  var secondColStyle = viewYield ? { width: "11%" } : { width: "13%" };
  var columns = [];
  if (isrowexpanded || rowcol.length === 0) {
    columns = [
      {
        dataField: "ASSET",
        text: "Asset class",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        headerStyle: otherColumnStyles,
        style: otherColumnStyles,
        classes: "RCM_common_table_column_text",
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "Total:",
        footerAlign: () => "left",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      },
      {
        dataField: "df1",
        text: "Description",
        headerStyle: secondColStyle,
        style: secondColStyle,
        isDummyField: true,
        footer: "",
      },
      {
        dataField: "UNIT",
        text: "Quantity",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "AVGCOSTPERSHARE",
        text: "Unit Cost",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(cell, 4)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: () => "right",
      },
      {
        dataField: "RCNTPRICE",
        text: "Price",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "RCNTMKTVAL",
        text: "Value",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: otherColumnStyles,
        style: otherColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalmkVal === null ||
            totalmkVal === "" ||
            totalmkVal === undefined
          )
            return <span>-</span>;
          else if (totalmkVal === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalmkVal, 2))}</span>;
        },
        footerAlign: () => "right",
      },

      {
        dataField: "TODAYSCHANGE",
        text: "Today's Change",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: { width: "12%" },
        style: { width: "12%" },
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.TODAYSCHANGE !== null &&
            row.TODAYSCHANGE !== undefined &&
            row.TODAYSCHANGE !== 0
              ? formatMoney(roundvalue(row.TODAYSCHANGE, 2))
              : GetNumericValue(row.TODAYSCHANGE);
          var marketValuePerc =
            row.TODAYSCHANGEPCT !== null &&
            row.TODAYSCHANGEPCT !== undefined &&
            row.TODAYSCHANGEPCT !== 0
              ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
              : GetNumericValue(row.TODAYSCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.TODAYSCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totaltodayschange !== null &&
            totaltodayschange !== undefined &&
            totaltodayschange !== 0
              ? formatMoney(totaltodayschange)
              : GetNumericValue(totaltodayschange);
          var marketValuePerc =
            totaltodayschangepct !== null &&
            totaltodayschangepct !== undefined &&
            totaltodayschangepct !== 0
              ? roundvalue(totaltodayschangepct, 2) + "%"
              : GetNumericValue(totaltodayschangepct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totaltodayschange)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totaltodayschangepct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      },
      {
        dataField: "MKTVALCHANGE",
        text: "Unrealized Gain/loss",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: headerColumnStyles,
        style: headerColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.MKTVALCHANGE !== null &&
            row.MKTVALCHANGE !== undefined &&
            row.MKTVALCHANGE !== 0
              ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
              : GetNumericValue(row.MKTVALCHANGE);
          var marketValuePerc =
            row.MKTVALCHANGEPCT !== null &&
            row.MKTVALCHANGEPCT !== undefined &&
            row.MKTVALCHANGEPCT !== 0
              ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
              : GetNumericValue(row.MKTVALCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.MKTVALCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== 0
              ? formatMoney(totalgainloss)
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== 0
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      },
      {
        dataField: "ASSETTOTALPCT",
        text: "% of Portfolio",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0%</span>;
          else return <span>{roundvalue(cell, 2) + "%"}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalassetpct === null ||
            totalassetpct === "" ||
            totalassetpct === undefined
          )
            return <span>-</span>;
          else if (totalassetpct === 0) return <span>0%</span>;
          else return <span>{roundvalue(totalassetpct, 2) + "%"}</span>;
        },
        footerAlign: () => "right",
      },
    ];
    if (viewYield) {
      columns.push({
        dataField: "YIELDPCT",
        text: "Yield %",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        formatter: (cell) => {
          var yieldPerc =
            cell && cell !== null && cell !== 0
              ? roundvalue(cell, 2) + "%"
              : GetNumericValue(cell, true);
          if (cell === undefined || cell === null || cell === "")
            return <span>-</span>;
          else if (cell === 0) return <span>0%</span>;
          else return <span>{yieldPerc}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }
  } else {
    columns.push({
      dataField: "ASSET",
      text: "Asset class",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      headerStyle: otherColumnStyles,
      style: otherColumnStyles,
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: "Total:",
      footerAlign: () => "left",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    });

    // if (checkIfFieldEmpty(rowcol, 'ASSET')) {
    columns.push({
      dataField: "df1",
      text: "",
      isDummyField: true,
      footer: "",
      headerStyle: secondColStyle,
      style: secondColStyle,
    });

    if (checkIfFieldEmpty(rowcol, "UNIT")) {
      columns.push({
        dataField: "df2",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
      });
    } else {
      columns.push({
        dataField: "UNIT",
        text: "Quantity",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "AVGCOSTPERSHARE")) {
      columns.push({
        dataField: "df3",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
      });
    } else {
      columns.push({
        dataField: "AVGCOSTPERSHARE",
        text: "Unit Cost",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(cell, 4)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "COSTBASIS")) {
      columns.push({
        dataField: "df4",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
      });
    } else {
      columns.push({
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: () => "right",
      });
    }

    if (checkIfFieldEmpty(rowcol, "RCNTPRICE")) {
      columns.push({
        dataField: "df5",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
      });
    } else {
      columns.push({
        dataField: "RCNTPRICE",
        text: "Price",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "RCNTMKTVAL")) {
      columns.push({
        dataField: "df6",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: otherColumnStyles,
        style: otherColumnStyles,
      });
    } else {
      columns.push({
        dataField: "RCNTMKTVAL",
        text: "Value",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: otherColumnStyles,
        style: otherColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalmkVal === null ||
            totalmkVal === "" ||
            totalmkVal === undefined
          )
            return <span>-</span>;
          else if (totalmkVal === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalmkVal, 2))}</span>;
        },
        footerAlign: () => "right",
      });
    }

    if (
      checkIfFieldEmpty(rowcol, "TODAYSCHANGE") &&
      checkIfFieldEmpty(rowcol, "TODAYSCHANGEPCT")
    ) {
      columns.push({
        dataField: "df7",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: headerColumnStyles,
        style: headerColumnStyles,
      });
    } else {
      columns.push({
        dataField: "TODAYSCHANGE",
        text: "Today's Change",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: headerColumnStyles,
        style: headerColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.TODAYSCHANGE !== null &&
            row.TODAYSCHANGE !== undefined &&
            row.TODAYSCHANGE !== 0
              ? formatMoney(roundvalue(row.TODAYSCHANGE, 2))
              : GetNumericValue(row.TODAYSCHANGE);
          var marketValuePerc =
            row.TODAYSCHANGEPCT !== null &&
            row.TODAYSCHANGEPCT !== undefined &&
            row.TODAYSCHANGEPCT !== 0
              ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
              : GetNumericValue(row.TODAYSCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.TODAYSCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totaltodayschange !== null &&
            totaltodayschange !== undefined &&
            totaltodayschange !== 0
              ? formatMoney(totaltodayschange)
              : GetNumericValue(totaltodayschange);
          var marketValuePerc =
            totaltodayschangepct !== null &&
            totaltodayschangepct !== undefined &&
            totaltodayschangepct !== 0
              ? roundvalue(totaltodayschangepct, 2) + "%"
              : GetNumericValue(totaltodayschangepct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totaltodayschange)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totaltodayschangepct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      });
    }

    if (
      checkIfFieldEmpty(rowcol, "MKTVALCHANGE") &&
      checkIfFieldEmpty(rowcol, "MKTVALCHANGEPCT")
    ) {
      columns.push({
        dataField: "df8",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: headerColumnStyles,
        style: headerColumnStyles,
      });
    } else {
      columns.push({
        dataField: "MKTVALCHANGE",
        text: "Unrealized Gain/loss",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: headerColumnStyles,
        style: headerColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.MKTVALCHANGE !== null &&
            row.MKTVALCHANGE !== undefined &&
            row.MKTVALCHANGE !== 0
              ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
              : GetNumericValue(row.MKTVALCHANGE);
          var marketValuePerc =
            row.MKTVALCHANGEPCT !== null &&
            row.MKTVALCHANGEPCT !== undefined &&
            row.MKTVALCHANGEPCT !== 0
              ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
              : GetNumericValue(row.MKTVALCHANGEPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.MKTVALCHANGE)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== 0
              ? formatMoney(totalgainloss)
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== 0
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: () => "right",
      });
    }

    if (checkIfFieldEmpty(rowcol, "ASSETTOTALPCT")) {
      columns.push({
        dataField: "df9",
        text: "",
        isDummyField: true,
        footer: "",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
      });
    } else {
      columns.push({
        dataField: "ASSETTOTALPCT",
        text: "% of Portfolio",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: smallColumnStyles,
        style: smallColumnStyles,
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0%</span>;
          else return <span>{roundvalue(cell, 2) + "%"}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: () => {
          if (
            totalassetpct === null ||
            totalassetpct === "" ||
            totalassetpct === undefined
          )
            return <span>-</span>;
          else if (totalassetpct === 0) return <span>0%</span>;
          else return <span>{roundvalue(totalassetpct, 2) + "%"}</span>;
        },
        footerAlign: () => "right",
      });
    }
    if (viewYield) {
      if (checkIfFieldEmpty(rowcol, "YIELDPCT")) {
        columns.push({
          dataField: "df10",
          text: "",
          isDummyField: true,
          footer: "",
          headerStyle: smallColumnStyles,
          style: smallColumnStyles,
        });
      } else {
        columns.push({
          dataField: "YIELDPCT",
          text: "Yield %",
          headerAlign: "right",
          classes: "RCM_common_table_column_number",
          sort: true,
          sortCaret: (order, column) => customCaretSort(order, column),
          headerStyle: smallColumnStyles,
          style: smallColumnStyles,
          formatter: (cell) => {
            var yieldPerc =
              cell && cell !== null && cell !== 0
                ? roundvalue(cell, 2) + "%"
                : GetNumericValue(cell, true);
            if (cell === undefined || cell === null || cell === "")
              return <span>-</span>;
            else if (cell === 0) return <span>0%</span>;
            else return <span>{yieldPerc}</span>;
          },
          onSort: (field, order) => {
            rowsortevent(field, order);
          },
          footer: "",
        });
      }
    }
  }
  return columns;
}

export const renderRowsByAsset = (
  rowdata,
  assetColorRes,
  dropdownClassValue
) => {
  let datarows = [];
  for (var i = 0; i < rowdata.length; i++) {
    let d = rowdata[i];
    let assetObj = getAssetColorConfig(
      d.assetcat,
      d.assetcatcd,
      assetColorRes,
      dropdownClassValue
    );
    if (d.invposacct && d.invposacct.length > 0) {
      datarows.push({
        UNQID: d.assetcat ? d.assetcat : null,
        ASSET: d.assetcat ? d.assetcat : null,
        RCNTMKTVAL: d.mktvals ? d.mktvals.rcntmktval : null,
        MKTVALCHANGE: d.unrealizedValue,
        MKTVALCHANGEPCT: d.unrealizedPct,
        UNIT: d.SHRQNTS ? d.SHRQNTS.RecentUNIT : null,
        RCNTPRICE: d.PRICING ? d.PRICING.RCNTPRICE : null,
        AVGCOSTPERSHARE: d.COSTBASIS ? d.COSTBASIS.AVGCOSTPERSHARE : null,
        DTASOF: d.SHRQNTS ? d.SHRQNTS.DTASOF : null,
        COSTBASIS: d.costbasis ? d.costbasis : null,
        INVPOSLIST: d.invposacct,
        DESCRIPTION: "",
        TODAYSCHANGE: d.mktvals ? d.mktvals.mktvalchange : null,
        TODAYSCHANGEPCT: d.mktvals ? d.mktvals.mktvalchangepct : null,
        ASSETTOTALPCT: d.mktvalpct ? d.mktvalpct : null,
        colorCd: assetObj.colorcd,
        displaySeq: assetObj.displayseq,
      });

      if (datarows && datarows.length > 0) {
        datarows.sort(function (a, b) {
          return a.displaySeq - b.displaySeq;
        });
      }
    }
  }
  return datarows;
};

//method to get child table rows on expand used for security and Account tab
export function renderRowsByAssetSecurity(
  rowdata,
  childColumnToSort,
  childColumnSortOrder
) {
  let datarows = [];
  if (childColumnToSort === "ASSET") childColumnToSort = "SECID";

  if (rowdata.INVPOSLIST && rowdata.INVPOSLIST.length > 0) {
    for (var i = 0; i < rowdata.INVPOSLIST.length; i++) {
      let d = rowdata.INVPOSLIST[i];
      datarows.push({
        UNIQID: d.secinfo ? d.secinfo.secid : "",
        SECID: d.secinfo ? d.secinfo.secid : null,
        SECNAME: d.secinfo ? d.secinfo.secname : null,
        RCNTMKTVAL: d.mktvals ? d.mktvals.rcntmktval : null,
        MKTVALCHANGE: d.unrealizedValue,
        MKTVALCHANGEPCT: d.unrealizedPct,
        DTASOF: d.SHRQNTS ? d.SHRQNTS.DTASOF : null,
        UNIT: d.shrqnts ? d.shrqnts : null,
        RCNTPRICE: d.pricing ? d.pricing.rcntprice : null,
        AVGCOSTPERSHARE: d.COSTBASIS ? d.COSTBASIS.AVGCOSTPERSHARE : null,
        COSTBASIS: d.costbasis ? d.costbasis : null,
        INVPOSLIST: d.invposlist,
        //DESCRIPTION: "",
        TODAYSCHANGE: d.mktvals ? d.mktvals.mktvalchange : null,
        TODAYSCHANGEPCT: d.mktvals ? d.mktvals.mktvalchangepct : null,
        ASSETTOTALPCT: d.mktvalpct ? d.mktvalpct : null,
        UNIQUEIDX:
          (d.assetcat ? d.secinfo.assetcat : "") +
          (d.secinfo ? d.secinfo.secid : ""),
        YIELDPCT: d.secinfo ? d.secinfo.yieldPct : "",
      });
    }
  }
  if (childColumnToSort !== "" && childColumnSortOrder !== "") {
    if (childColumnToSort === "SECID") {
      datarows = orderBy(
        datarows,
        [(row) => row[childColumnToSort].toLowerCase()],
        [childColumnSortOrder]
      );
    } else {
      datarows = orderBy(datarows, [childColumnToSort], [childColumnSortOrder]);
    }
  }
  return datarows;
}

//method to get child table rows on expand used for security and Account tab
export function renderRowsByAssetSecurityAccount(
  rowdata,
  accountlist,
  childColumnToSort,
  childColumnSortOrder
) {
  let datarows = [];
  if (childColumnToSort === "ASSET") childColumnToSort = "ACCOUNTNICKNAME";
  if (rowdata.INVPOSLIST && rowdata.INVPOSLIST.length > 0) {
    for (var i = 0; i < rowdata.INVPOSLIST.length; i++) {
      let d = rowdata.INVPOSLIST[i];
      datarows.push({
        ACCTNUM: d.acctnum,
        ACCTKEY: d.acctkey,
        RCNTMKTVAL: d.mktvals ? d.mktvals.rcntmktval : null,
        MKTVALCHANGE: d.unrealizedValue,
        MKTVALCHANGEPCT: d.unrealizedPct,
        DTASOF: d.SHRQNTS ? d.SHRQNTS.DTASOF : null,
        UNIT: d.shrqnts ? d.shrqnts.recentUNIT : null,
        RCNTPRICE: d.pricing ? d.pricing.rcntprice : null,
        AVGCOSTPERSHARE: d.costbasis ? d.costbasis.avgcostpershare : null,
        COSTBASIS: d.costbasis ? d.costbasis.costbasis : null,
        INVPOSLIST: d.invposlist,
        //DESCRIPTION: "",
        TODAYSCHANGE: d.mktvals ? d.mktvals.mktvalchange : null,
        TODAYSCHANGEPCT: d.mktvals ? d.mktvals.mktvalchangepct : null,
        ACCOUNTNICKNAME: getAccountDisplayName(
          accountlist,
          d.acctkey,
          IndLongShort.SINGLEVIEW
        ),
        ASSETTOTALPCT: d.mktvalpct ? d.mktvalpct : null,
        CUSIP: d.cusip ? d.cusip : null,
        SECNAME: d.secinfo && d.secinfo.secname ? d.secinfo.secname : null,
        SECID: d.secinfo && d.secinfo.secid ? d.secinfo.secid : null,
        UNIQID:
          (d.assetcat ? d.secinfo.assetcat : "") +
          (d.secinfo ? d.secinfo.secid : "") +
          (d.acctkey ? d.acctkey : ""),
        YIELDPCT: d.secinfo ? d.secinfo.yieldPct : "",
      });
    }
    if (childColumnToSort !== "" && childColumnSortOrder !== "") {
      if (
        childColumnToSort === "ACCOUNTNICKNAME" ||
        childColumnToSort === "ACCTYPE"
      ) {
        datarows = orderBy(
          datarows,
          [(row) => row[childColumnToSort].toLowerCase()],
          [childColumnSortOrder]
        );
      } else {
        datarows = orderBy(
          datarows,
          [childColumnToSort],
          [childColumnSortOrder]
        );
      }
    }
  }
  return datarows;
}

//child columns for account expand view
export function assetsecuritycolumns(
  headerColumnStyles,
  otherColumnStyles,
  smallColumnStyles,
  viewYield
) {
  var secondColStyle = viewYield ? { width: "9.5%" } : { width: "11%" };
  var columns = [
    {
      dataField: "SECID",
      text: "SECID",
      headerAlign: "left",
      sort: true,
      headerStyle: otherColumnStyles,
      style: otherColumnStyles,
      sortCaret: (order, column) => customCaretSort(order, column),
      classes: "RCM_common_table_column_text",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "SECNAME",
      text: "SECNAME",
      headerAlign: "left",
      sort: true,
      headerStyle: secondColStyle,
      style: secondColStyle,
      sortCaret: (order, column) => customCaretSort(order, column),
      classes: "RCM_common_table_column_text",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "UNIT",
      text: "UNIT",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValue(cell)}</span>;
      },
    },
    {
      dataField: "AVGCOSTPERSHARE",
      text: "AVGCOSTPERSHARE",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell, 4)}</span>;
      },
    },
    {
      dataField: "COSTBASIS",
      text: "COSTBASIS",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTPRICE",
      text: "RCNTPRICE",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTMKTVAL",
      text: "RCNTMKTVAL",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: otherColumnStyles,
      style: otherColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "TODAYSCHANGE",
      text: "Today's Change",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: { width: "13%" },
      style: { width: "13%" },
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.TODAYSCHANGE !== null &&
          row.TODAYSCHANGE !== undefined &&
          row.TODAYSCHANGE !== 0
            ? formatMoney(row.TODAYSCHANGE)
            : GetNumericValue(row.TODAYSCHANGE);
        var marketValuePerc =
          row.TODAYSCHANGEPCT !== null &&
          row.TODAYSCHANGEPCT !== undefined &&
          row.TODAYSCHANGEPCT !== 0
            ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
            : GetNumericValue(row.TODAYSCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.TODAYSCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "MKTVALCHANGE",
      text: "Unrealized Gain/loss",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: headerColumnStyles,
      style: headerColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.MKTVALCHANGE !== null &&
          row.MKTVALCHANGE !== undefined &&
          row.MKTVALCHANGE !== 0
            ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
            : GetNumericValue(row.MKTVALCHANGE);
        var marketValuePerc =
          row.MKTVALCHANGEPCT !== null &&
          row.MKTVALCHANGEPCT !== undefined &&
          row.MKTVALCHANGEPCT !== 0
            ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
            : GetNumericValue(row.MKTVALCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.MKTVALCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "ASSETTOTALPCT",
      text: "% of Portfolio",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0%</span>;
        else return <span>{roundvalue(cell, 2) + "%"}</span>;
      },
    },
  ];
  if (viewYield) {
    columns.push({
      dataField: "YIELDPCT",
      text: "Yield %",
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      formatter: (cell) => {
        var yieldPerc =
          cell && cell !== null && cell !== 0
            ? roundvalue(cell, 2) + "%"
            : GetNumericValue(cell, true);
        if (cell === undefined || cell === null || cell === "")
          return <span>-</span>;
        else if (cell === 0) return <span>0%</span>;
        else return <span>{yieldPerc}</span>;
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
    });
  }
  return columns;
}
export function assetsecuritychildColumns(
  accounts,
  headerColumnStyles,
  otherColumnStyles,
  smallColumnStyles,
  viewYield
) {
  var secondColStyle = viewYield ? { width: "9.5%" } : { width: "11%" };
  //child columns for third level drill down in asset type tab
  var childColumns = [
    {
      dataField: "ACCOUNTNICKNAME",
      text: "",
      classes: "RCM_common_table_column_text",
      headerStyle: otherColumnStyles,
      style: otherColumnStyles,
      formatter: (cell, row) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return acctFormatter(accounts, cell, row.ACCTKEY);
      },
    },
    {
      dataField: "ACCTYPE",
      text: "",
      classes: "RCM_common_table_column_text",
      headerStyle: secondColStyle,
      style: secondColStyle,
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>&nbsp;</span>;
        else return <span>&nbsp;</span>;
      }, //account type
    },
    {
      dataField: "UNIT",
      text: "",
      classes: "RCM_common_table_column_number",
      hheaderStyle: smallColumnStyles,
      style: smallColumnStyles,
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValue(cell)}</span>;
      },
    },
    {
      dataField: "AVGCOSTPERSHARE",
      text: "",
      classes: "RCM_common_table_column_number",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell, 4)}</span>;
      },
    },
    {
      dataField: "COSTBASIS",
      text: "",
      classes: "RCM_common_table_column_number",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTPRICE",
      text: "",
      classes: "RCM_common_table_column_number",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "RCNTMKTVAL",
      text: "",
      classes: "RCM_common_table_column_number",
      headerStyle: otherColumnStyles,
      style: otherColumnStyles,
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },

    {
      dataField: "TODAYSCHANGE",
      text: "Today's Change",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: { width: "13%" },
      style: { width: "13%" },
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.TODAYSCHANGE !== null &&
          row.TODAYSCHANGE !== undefined &&
          row.TODAYSCHANGE !== 0
            ? formatMoney(row.TODAYSCHANGE)
            : GetNumericValue(row.TODAYSCHANGE);
        var marketValuePerc =
          row.TODAYSCHANGEPCT !== null &&
          row.TODAYSCHANGEPCT !== undefined &&
          row.TODAYSCHANGEPCT !== 0
            ? roundvalue(row.TODAYSCHANGEPCT, 2) + "%"
            : GetNumericValue(row.TODAYSCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.TODAYSCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.TODAYSCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "MKTVALCHANGE",
      text: "",
      classes: "RCM_common_table_column_number",
      headerStyle: headerColumnStyles,
      style: headerColumnStyles,
      formatter: (cell, row) => {
        var marketValue =
          row.MKTVALCHANGE !== null &&
          row.MKTVALCHANGE !== undefined &&
          row.MKTVALCHANGE !== 0
            ? formatMoney(roundvalue(row.MKTVALCHANGE, 2))
            : GetNumericValue(row.MKTVALCHANGE);
        var marketValuePerc =
          row.MKTVALCHANGEPCT !== null &&
          row.MKTVALCHANGEPCT !== undefined &&
          row.MKTVALCHANGEPCT !== 0
            ? roundvalue(row.MKTVALCHANGEPCT, 2) + "%"
            : GetNumericValue(row.MKTVALCHANGEPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.MKTVALCHANGE)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.MKTVALCHANGEPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "ASSETTOTALPCT",
      text: "% of Portfolio",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0%</span>;
        else return <span>{roundvalue(cell, 2) + "%"}</span>;
      },
    },
  ];
  if (viewYield) {
    childColumns.push({
      dataField: "YIELDPCT",
      text: "Yield %",
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerStyle: smallColumnStyles,
      style: smallColumnStyles,
      formatter: (cell) => {
        var yieldPerc =
          cell && cell !== null && cell !== 0
            ? roundvalue(cell, 2) + "%"
            : GetNumericValue(cell, true);
        if (cell === undefined || cell === null || cell === "")
          return <span>-</span>;
        else if (cell === 0) return <span>0%</span>;
        else return <span>{yieldPerc}</span>;
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
    });
  }

  childColumns.push({
    dataField: "dummy",
    isDummyField: true,
    text: "...",
    classes: "RCM_common_table_column_number",
    formatter: () => {
      return (
        <div className="RCM_table_open_popup_button">
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
        </div>
      );
    },
  });
  return childColumns;
}

export function getDisplayName(account) {
  let accountNo =
    account.number === undefined ||
    account.number === null ||
    account.number === ""
      ? ""
      : account.number;
  let nickname =
    account.nickname === undefined ||
    account.nickname === null ||
    account.nickname === ""
      ? ""
      : account.nickname;
  let regtypecode =
    account.regtypecode === undefined ||
    account.regtypecode === null ||
    account.regtypecode === ""
      ? ""
      : " (" + account.regtypecode + ") ";

  if (accountNo === "All Accounts") {
    return accountNo;
  } else {
    return accountNo + " " + nickname + " " + regtypecode;
  }
}
export function generateExcel(props, totalgainlossamt, totalgainlosspct) {
  generateTasHoldingExcelReport(props, totalgainlossamt, totalgainlosspct);
}

//method to print data
export function printCustomWindow(table, props) {
  printTasHoldingExcelReport(table, props);
}
function GetClassName(value) {
  if (value === "" || value === null || value === undefined) {
    return "";
  } else if (value >= 0) {
    return "RCM_positiveval";
  } else if (value < 0) {
    return "RCM_negativeval";
  }
}

function acctFormatter(accountlist, cell, key) {
  return (
    <div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>
      {getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}
    </div>
  );
}

function checkIfFieldEmpty(rowcol, fieldname) {
  let filterrows = rowcol.filter(
    (acc) =>
      acc[fieldname] === null ||
      acc[fieldname] === "" ||
      acc[fieldname] === undefined
  );

  if (filterrows && filterrows.length === rowcol.length) {
    return true;
  }
  return false;
}
