import { useState, useCallback } from "react";
import axios from "../../../common/servicecallsInterceptor";

const sliceFile = (file, range) => {
  var frag = "";
  const reader = new FileReader();
  var blob = file.slice(range.min, range.max + 1);
  reader.readAsArrayBuffer(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = (event) => {
      if (reader.readyState === reader.DONE) {
        frag = reader.result;
        resolve(frag);
      }
    };
  });
};

const parsePosition = (file, position) => {
  const rangeStr = position[0];
  if (typeof position === "undefined" || rangeStr === "") {
    return undefined;
  }
  const firstRange = rangeStr.split("-");
  const minVal = parseInt(firstRange[0], 10);
  let maxVal = parseInt(firstRange[1], 10);
  if (Number.isNaN(maxVal)) {
    maxVal = file.size - 1;
  }
  return { min: minVal, max: maxVal };
};

export const useUploadFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress] = useState(0);

  const execute = useCallback(
    ({ chunk, uploadURL, position, totalLength, token }) => {
      return new Promise((resolve, reject) => {
        try {
          axios({
            method: "put",
            url: uploadURL,
            data: chunk,
            onUploadProgress: (progressEvent) =>
              setProgress((progressEvent.loaded * 100) / totalLength),
            headers: {
              "Content-Type": "application/octet-stream",
              "Content-Range": `bytes ${position.min}-${position.max}/${totalLength}`,
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } catch (e) {
          console.log("exception inside uploadChunk::  " + e);
          reject(e);
        }
      });
    },
    []
  );

  const uploadFile = async (file, uploadUrl, token) => {
    setError();
    setProgress(0);
    setLoading(true);
    setCompleted(false);
    let chunkSize = 3276;
    const fileSize = file.size;
    if(chunkSize >= fileSize) {
      chunkSize = fileSize
    }
    var nextRange = { min: 0, max: chunkSize - 1 };
    try {
      while (true) {
        const fileSlice = await sliceFile(file, nextRange);
        const res = await execute({
          chunk: fileSlice,
          uploadURL: uploadUrl,
          position: nextRange,
          totalLength: fileSize,
          token,
        });
        if (res.status === 202) {
          nextRange = parsePosition(file, res.data.nextExpectedRanges);
        } else if (res.status === 200 || res.status === 201) {
          setLoading(false);
          setCompleted(true);
          setProgress(100);
          setError();
          break;
        }
      }
    } catch (e) {
      setLoading(false);
      setProgress(0);
      setCompleted(false);
      setError(e);
    }
  };

  return { loading, error, completed, uploadFile, progress };
};
