import { getFormattedDate } from "../common/utilty.js";
import{IndLongShort,getAccountDisplayName} from '../../../common/constants.jsx'
import { Array } from "core-js";
import { currentYearContribution, previousYearContribution, CURRENT_YEAR, PREVIOUS_YEAR } from "../common/constants.js";
import { TransfersErrors, errorObjTransfers } from "./transfersError.js";
import moment from "moment";
import "moment-timezone";
import { addDays } from "date-fns";


export class AcctInfo {
    acctNum;
    acctType;
    acctCategory;
}

export class Transfers {
    moneymovementid;
    amount;
    iseft;
    isjnl;
    isira;
    memo;
    transactiondate;
    fromaccount;
    toaccount;
    isfuturedated;

    //adding the properties for retirement disburstment 
    //ismodeone;
    statewithholdpct;
    fedwithholdpct;
    istrnamtincludetaxwithhold;
}

export class Account {
    accountnumber;
    accounttype;
    isexternal;
    abanumber;
    siid;
    isira;
    isprioryear;
    bankname;
    isthirdparty;
}

export function getAcctInfosJson(accountInfos, requesterId) {

    let acctList = {
        //AcctInfos: [],
        //RequestorId: requesterId,
        IncludingBalance: "N"
    };
    // accountInfos.map(acct => {
    //     var acctInfo = new AcctInfo;
    //     acctInfo.acctNum = acct.number;
    //     //acctInfo.acctCategory = acct.RegTypeCode;
    //     acctInfo.acctType = acct.regtypecode;
    //     acctList.AcctInfos.push(acctInfo);
    // });

    return JSON.stringify(acctList);

}

export function getFromAccounts(eligibleAccounts,accounts)
{
    var fromAccountsList = [];

    if (eligibleAccounts !== null
        && eligibleAccounts !== undefined
        && Array.isArray(eligibleAccounts)
        && eligibleAccounts.length > 0) {

        //Pass the from accounts

        eligibleAccounts.map(acct => {
            if (acct.FromAcct !== null && acct.FromAcct !== undefined) {
                var fromAcct = acct.FromAcct;
                if (fromAcct.AcctNum !== null
                    && fromAcct.AcctNum !== undefined
                    && fromAcct.AcctNum !== ""
                    && fromAcct.DisplayName !== null
                    && fromAcct.DisplayName !== undefined
                ) {
                   return fromAccountsList.push({ 
                       "accountNumber": fromAcct.AcctNum, 
                       "displayName": isExternal(fromAcct) ? fromAcct.DisplayName : getAccountDisplayName(accounts,fromAcct.AcctNum,IndLongShort.SINGLEVIEW),
                       "isExternal": isExternal(fromAcct),
                       "accountType": fromAcct.AcctType
                    });
                }
            }
        });
    }
    return fromAccountsList;
}

function isExternal(Acct){
    return (Acct.IsJNL !== null && Acct.IsJNL !== undefined && Acct.IsJNL) ? false : (Acct.isExternalAcct !== null && Acct.isExternalAcct !== undefined) ? Acct.isExternalAcct : false
}

export function getInternalExternalAccts(fromAccounts, isExternalRequired)
{
    let AcctList = [];
    if(fromAccounts !== null && fromAccounts !== undefined && Array.isArray(fromAccounts))
    {
       AcctList = fromAccounts.filter(acct => {
            if(acct.isExternal === isExternalRequired)
             return acct;
        })
    }

    return AcctList;
}


export function getEligibleToAccounts(fromAccount, eligibleAccountsList, accounts) {
    let toAccountsList = [];
    if (fromAccount !== null
        && fromAccount !== undefined
        && eligibleAccountsList !== null
        && eligibleAccountsList !== undefined
        && Array.isArray(eligibleAccountsList)
        && eligibleAccountsList.length > 0) {
        //Pull the list of to account
        
       for(let i=0; i<eligibleAccountsList.length; i++)
       {
           let eligibleAcct = eligibleAccountsList[i];
            if(eligibleAcct.FromAcct !== null 
                && eligibleAcct.FromAcct !== undefined
                && eligibleAcct.FromAcct.AcctNum !== null
                && eligibleAcct.FromAcct.AcctNum !== undefined
                && eligibleAcct.FromAcct.AcctNum === fromAccount)
                {
                    //return to account list
                    if (eligibleAcct.ToAccts !== null
                        && eligibleAcct.ToAccts !== undefined
                        && Array.isArray(eligibleAcct.ToAccts)
                        && eligibleAcct.ToAccts.length > 0) {
        
                            eligibleAcct.ToAccts.map(toAcct => {
                            if (toAcct.AcctNum !== null
                                && toAcct.AcctNum !== undefined
                                && toAcct.AcctNum !== ""
                                && toAcct.DisplayName !== null
                                && toAcct.DisplayName !== undefined) {
                                toAccountsList.push(
                                    { 
                                    "accountNumber": toAcct.AcctNum, 
                                    "displayName": isExternal(toAcct) ? toAcct.DisplayName : getAccountDisplayName(accounts, toAcct.AcctNum, IndLongShort.SINGLEVIEW),
                                    "isRetirement": (toAcct.IsIRA !==null && toAcct.IsIRA !== undefined) ? toAcct.IsIRA : false,
                                    "iraTransferDirection": toAcct.IRAxferDirection,
                                    "limits": toAcct.Limits,
                                    "isExternal": isExternal(toAcct),
                                    "cutoff": getTransferCutoff(eligibleAcct.FromAcct, toAcct)
                                });
                            }
                        });
        
                    }

                    break;
                }
       }
    }

    return toAccountsList;
}


export function getTransferCutoff(fromAcct, toAcct){
    let cutoff = ""
    if(fromAcct !== null && fromAcct !== undefined && toAcct !== null && toAcct !== undefined
        && fromAcct.isExternalAcct !== null && fromAcct.isExternalAcct !== undefined
        && toAcct.isExternalAcct !== null && toAcct.isExternalAcct !== undefined
        && toAcct.cutofftime !== null && toAcct.cutofftime !== undefined
        && (fromAcct.isExternalAcct === true || toAcct.isExternalAcct === true))
        {
            cutoff = toAcct.cutofftime
        }

    return cutoff;
}

export function calculateCutOffVarbiage(cutofftime){
    let cutoffvarbiage = "";
    //Check if cutoff time is a valid date format
    if(Date.parse(cutofftime))
    {
        //Convert the date to new york time zone date time
        let cutoffDate = moment(cutofftime).tz("America/New_York").format("YYYY-MM-DD");
        let currentDate = moment().tz("America/New_York").format("YYYY-MM-DD");

        let cutoffDateTime = moment(cutofftime).tz("America/New_York").format();
        let currentDateTime = moment().tz("America/New_York").format();

        if(cutoffDate === currentDate){
            //Check if cutoff has been passed
            if(moment(cutoffDateTime) > moment(currentDateTime)){
                // return the cutoff time received from api
                cutoffvarbiage = `Transaction cutoff ${moment(cutofftime).tz("America/New_York").format("MM/DD/YYYY hh:mm a")} ET`
            }else{
                //Cutoff time has been passed
                cutoffvarbiage = 'Transaction received after 4:00 pm ET will be processed next business day';
            }
        }else{
            //check if cut off date is grater 
            if(moment(cutoffDate) > moment(currentDate)){
                //Cutoff date is higer than record will be process on the cutoff date
                cutoffvarbiage = `Transaction will be processed on ${moment(cutofftime).tz("America/New_York").format("L")}`;
            }
        }

    }

    return cutoffvarbiage;
}

export function identifyRetirementContribution(toAccount, toAcctList)
{
    let isRetirement = false;
    if(toAccount !== null 
        && toAccount !== undefined 
        && toAcctList !== null 
        && toAcctList !== undefined && Array.isArray(toAcctList) && toAcctList.length > 0)
        {
            for(let key in toAcctList)
            {
                if (toAcctList[key].accountNumber === toAccount 
                    && toAcctList[key].iraTransferDirection && toAcctList[key].iraTransferDirection === 'C')
                {
                    isRetirement = toAcctList[key].isRetirement;
                    break;
                }
            }
        }

    return isRetirement;
}

export function identifyAchTransfer(toAccount, fromAcctNumber, eligiblePairAcct){
    let isAchTransfer = false;
    if(toAccount && toAccount.isExternal){
       if(toAccount.isExternal){
           isAchTransfer = true
       }else {
           if(eligiblePairAcct && Array.isArray(eligiblePairAcct) && fromAcctNumber){
               eligiblePairAcct.map(acct => {
                   if(acct.FromAcct && acct.FromAcct.AcctNum && acct.FromAcct.AcctNum === fromAcctNumber && acct.FromAcct.isExternalAcct){
                        isAchTransfer = acct.FromAcct.isExternalAcct
                   }
               })
           }     
       }
    }
    return isAchTransfer;
}

export function identifyAchHolidays(holidays, cutoff){
    let res = [];
    if(holidays &&  Array.isArray(holidays) && holidays.length > 0){
        for (var i = 0; i < holidays.length; i++) {
            if(holidays[i].isachallowed === false){
                res.push(holidays[i].date);
            }
          }
    }

    if(!removeCurrentDateAfterCutoff(cutoff)){
        res.push(addDays(new Date(moment().tz('America/New_York').format('YYYY-MM-DD')), 1));
    }
    return res;
}

export function identifyJournalHolidays(holidays, cutoff){
    let res = [];

    if(holidays &&  Array.isArray(holidays) && holidays.length > 0){
        for (var i = 0; i < holidays.length; i++) {
            if(holidays[i].isjournalallowed === false){
                res.push(holidays[i].date);
            }
          }
    }

    if(!removeCurrentDateAfterCutoff(cutoff)){
        res.push(addDays(new Date(moment().tz('America/New_York').format('YYYY-MM-DD')), 1));
    }

    return res;
}

function removeCurrentDateAfterCutoff(cutoff){
    let isCurrentDateReqRuired = true;

    if(Date.parse(cutoff)){
        
        //Convert the date to new york time zone date time
        let cutoffDate = moment(cutoff).tz("America/New_York").format("YYYY-MM-DD");
        let currentDate = moment().tz("America/New_York").format("YYYY-MM-DD");

        let cutoffDateTime = moment(cutoff).tz("America/New_York").format();
        let currentDateTime = moment().tz("America/New_York").format();

        if(moment(cutoffDate) > moment(currentDate)){
            isCurrentDateReqRuired = false;
        }else if(cutoffDate === currentDate && moment(currentDateTime) > moment(cutoffDateTime)){
            isCurrentDateReqRuired = false;
        }
        
    }
    return isCurrentDateReqRuired;
}

export function identifyLimits(toAccount, toAcctList)
{
    let limits = {};
    if(toAccount !== null 
        && toAccount !== undefined 
        && toAcctList !== null 
        && toAcctList !== undefined && Array.isArray(toAcctList) && toAcctList.length > 0)
        {
            for(let key in toAcctList)
            {
                if (toAcctList[key].accountNumber === toAccount)
                {
                    limits = toAcctList[key].limits
                    break;
                }
            }
        }

    return limits;
}


export function getTransferLimitAndDate(fromAccount, toAccount, eligibleAccountsList, isRetirement, contributionYr) {

    console.log("Limit function has been called");

    let limitAndDate = {
        limit: "",
        transferDate:""
    };

    if (fromAccount !== null
        && fromAccount !== undefined
        && toAccount !== null
        && toAccount !== undefined
        && eligibleAccountsList !== null
        && eligibleAccountsList !== undefined
        && Array.isArray(eligibleAccountsList)
        && eligibleAccountsList.length > 0) {

        eligibleAccountsList.map(eligibleAcct => {
            if (eligibleAcct.FromAcct !== null
                && eligibleAcct.FromAcct !== undefined
                && eligibleAcct.FromAcct.AcctNum !== null
                && eligibleAcct.FromAcct.AcctNum !== undefined
                && eligibleAcct.FromAcct.AcctNum === fromAccount) {

                //find the to account 

                if (eligibleAcct.ToAccts !== null
                    && eligibleAcct.ToAccts !== undefined
                    && Array.isArray(eligibleAcct.ToAccts)
                    && eligibleAcct.ToAccts.length > 0 ) {

                    eligibleAcct.ToAccts.map(to => {
                        if (to.AcctNum !== null
                            && to.AcctNum !== undefined
                            && to.AcctNum === toAccount
                            && to.Limits !== null
                            && to.Limits !== undefined) {
                            
                            if(isRetirement)
                            {
                                if(contributionYr === CURRENT_YEAR){
                                   limitAndDate.limit = to.Limits.IRACurrYrLimit !== null && to.Limits.IRACurrYrLimit !== undefined ? to.Limits.IRACurrYrLimit : "";
                                }

                                if(contributionYr === PREVIOUS_YEAR)
                                {
                                  limitAndDate.limit = to.Limits.IRAPrevYrLimit !== null && to.Limits.IRAPrevYrLimit !== undefined ? to.Limits.IRAPrevYrLimit : "";
                                }
                            }
                            else{
                               limitAndDate.limit = (to.Limits.TransLimit !== null && to.Limits.TransLimit !== undefined) ? to.Limits.TransLimit : "";
                            }
                        }
                    });

                    eligibleAcct.ToAccts.map(to => {
                        if (to.AcctNum !== null
                            && to.AcctNum !== undefined
                            && to.AcctNum === toAccount
                            && to.XferDate !== null
                            && to.XferDate !== undefined) {

                            if (Date.parse(to.XferDate)) {
                               limitAndDate.transferDate = getFormattedDate(to.XferDate);
                            }
                        }
                    });

                }
            }
        });

    }


    return limitAndDate;
}

export function getTransferJson(transferObj, eligiblePairs, ismodeone) {
    let transfer = new Transfers();
    transfer.moneymovementid = "1";
    transfer.amount = parseFloat(transferObj.transferAmount);
    transfer.memo = '';
    transfer.transactiondate = formatDate(transferObj.transferDate);
    transfer.isfuturedated = transferObj.isfuturedated;
    
    
    //Below fields are required only for retirement disburtment 

    if(ismodeone !== null && ismodeone !== undefined && ismodeone !== false){
        transfer.ismodeone = ismodeone;
    }

    if(transferObj.statewithholdpct !== null && transferObj.statewithholdpct != undefined){
        transfer.statewithholdpct = transferObj.statewithholdpct;
    }

    if(transferObj.fedwithholdpct !== null && transferObj.fedwithholdpct !== undefined){
        transfer.fedwithholdpct = transferObj.fedwithholdpct;
    }

    if(transferObj.istrnamtincludetaxwithhold !== null && transferObj.istrnamtincludetaxwithhold !== undefined){
        transfer.istrnamtincludetaxwithhold = transferObj.istrnamtincludetaxwithhold;
    }

    if(transferObj.retdistreason !==null &&  transferObj.retdistreason !== undefined){
        transfer.retdistreason = [transferObj.retdistreason];
    }

    //End of retirement disburstment fields.

    if (transferObj.fromAccount !== null
        && transferObj.fromAccount !== undefined
        && transferObj.toAccount !== null
        && transferObj.toAccount !== undefined
        && eligiblePairs !== null
        && eligiblePairs !== undefined
        && Array.isArray(eligiblePairs)
        && eligiblePairs.length > 0) {

        eligiblePairs.map(eligibleAcct => {
            if (eligibleAcct.FromAcct !== null
                && eligibleAcct.FromAcct !== undefined
                && eligibleAcct.FromAcct.AcctNum !== null
                && eligibleAcct.FromAcct.AcctNum !== undefined
                && eligibleAcct.FromAcct.AcctNum === transferObj.fromAccount) {

                transfer.fromaccount = new Account();
                transfer.fromaccount.accountnumber = transferObj.fromAccount;
                transfer.fromaccount.isira =  transferObj.isRetirementDisbursment;
                (eligibleAcct.FromAcct.AcctType !== null
                    && eligibleAcct.FromAcct.AcctType !== undefined) ?
                    transfer.fromaccount.accounttype = eligibleAcct.FromAcct.AcctType
                    : transfer.fromaccount.accounttype = "" ;

                

                if(eligibleAcct.FromAcct.ABANumber !== null
                    && eligibleAcct.FromAcct.ABANumber !== undefined
                    && eligibleAcct.FromAcct.ABANumber !== "") {
                    transfer.fromaccount.abanumber = eligibleAcct.FromAcct.ABANumber;
                    transfer.fromaccount.isexternal = true;

                    //FROM  account SIID code
                    eligibleAcct.ToAccts.map(to => {
                        if (to.AcctNum !== null && to.AcctNum !== undefined && to.AcctNum === transferObj.toAccount)
                            transfer.fromaccount.siid = to.SIID;
                    });
                        
                    }else{
                        transfer.fromaccount.abanumber = "";
                        transfer.fromaccount.isexternal = false;
                    }
                   

                //siid
                //(transfer.fromaccount.siid == undefined && transfer.fromaccount.siid == null && eligibleAcct.FromAcct.SIID !== null
                //    && eligibleAcct.FromAcct.SIID !== undefined) ?
                //    transfer.fromaccount.siid = eligibleAcct.FromAcct.SIID : transfer.fromaccount.siid = "";

                //bankname
                (eligibleAcct.FromAcct.BankName !== null
                    && eligibleAcct.FromAcct.BankName !== undefined) ?
                    transfer.fromaccount.bankname = eligibleAcct.FromAcct.BankName : transfer.fromaccount.bankname ="";

                if (eligibleAcct.ToAccts !== null
                    && eligibleAcct.ToAccts !== undefined
                    && Array.isArray(eligibleAcct.ToAccts)
                    && eligibleAcct.ToAccts.length > 0) {

                    eligibleAcct.ToAccts.map(to => {
                        if (to.AcctNum !== null
                            && to.AcctNum !== undefined
                            && to.AcctNum === transferObj.toAccount) {

                            (to.IsEFT !== null && to.IsEFT !== undefined) ? transfer.iseft = to.IsEFT : transfer.iseft = false;
                            (to.IsJNL !== null && to.IsJNL !== undefined) ? transfer.isjnl = to.IsJNL : transfer.isjnl = false;
                            (to.IsIRA !== null && to.IsIRA !== undefined) ? transfer.isira = to.IsIRA : transfer.isira = false;

                            transfer.toaccount = new Account();
                            transfer.toaccount.accountnumber = to.AcctNum;
                            (to.AcctType !== null && to.AcctType !== undefined) ? transfer.toaccount.accounttype = to.AcctType : transfer.toaccount.accounttype ="";


                            //check contribution or distribution
                            let isContribution = false;
                            if(to.IRAxferDirection !== null && to.IRAxferDirection !== undefined && to.IRAxferDirection === 'C'){
                                isContribution = true;
                            }

                            if(isContribution){
                                (to.IsIRA !== null && to.IsIRA !== undefined ) ? transfer.toaccount.isira = to.IsIRA : transfer.toaccount.isira = false;

                                if(to.IsIRA !== null  && to.IsIRA !== undefined && to.IsIRA === true)
                                {
                                    transfer.toaccount.isprioryear = (transferObj.contributionType === previousYearContribution.value ) ?  true : false;
                                }
                            }else{
                                transfer.toaccount.isira = false;
                            }
                            

                            if(to.ABANumber !== null && to.ABANumber !== undefined && to.ABANumber !== "") {
                                transfer.toaccount.abanumber = to.ABANumber
                                transfer.toaccount.isexternal = true;
                                (to.SIID !== null && to.SIID !== undefined) ? transfer.toaccount.siid = to.SIID : transfer.toaccount.siid = "";
                                (to.BankName !== null && to.BankName !== undefined) ? transfer.toaccount.bankname = to.BankName : transfer.toaccount.bankname = "";
                                (to.thirdpartyIndicator !== null && to.thirdpartyIndicator !== undefined) ? transfer.toaccount.isthirdparty = (to.thirdpartyIndicator == "Y") : transfer.toaccount.isthirdparty = false;
                             }else{ 
                                 transfer.toaccount.abanumber = "";
                                 transfer.toaccount.isexternal = false;
                            }
                            
                        }
                    });

                }
            }
        });
        return JSON.stringify(transfer);
    }
}

export function getAccountString(accountsList) {
    let accountStr = "";

    try {
        if (accountsList !== null
            && accountsList !== undefined
            && Array.isArray(accountsList) && accountsList.length > 0) {

            let acctNumber = accountsList.map(account => {
                return account.number;
            });

            accountStr = acctNumber.join(',');
        }
    } catch (error) {
        console.error(error);
    }
    

    return accountStr;
}

export function getNewTransferActivity(data)
{
    return {
        acct: data.fromaccount.accountnumber,
        allowcancel: true,
        allowdelete: false,
        catseq: "",
        confirmationnumber: data.confirmno,
        displayname: "Individual - New",
        dtentry: data.transactiondate,
        dtsettle: data.transactiondate,
        dttrade: data.transactiondate,
        incomeordistributionactivity: false,
        isbillpay: false,
        iscashtransaction: true,
        isdebitcardoratm: false,
        isdeposit: false,
        ispending: true,
        istradeactivity: false,
        iswithdraw: false,
        secid: "",
        total: data.amount,
        trncode: "dp",
        trndesc: `Transferred to ${data.toaccount.accountnumber}`,
        trntype: "Exchange/Transfer",
        unitprice: 0,
        units: 0
    };
}

export function getContributionYears(Limits)
{
    let contributionYr = [];
    contributionYr.push(currentYearContribution);
    if(Limits !== null && Limits !== undefined)
    {
        if(Limits.IRAPrevYrLimit !== null && Limits.IRAPrevYrLimit !== undefined && Limits.IRAPrevYrLimit > 0)
        {
            contributionYr.push(previousYearContribution);
        }
    }
    
    return contributionYr;
}

export function validateTransferInput(transfers)
{
    let response = {
        "isError" : false,
        "errorList": [],
        "transfersError": new TransfersErrors()
    }

    if(!(transfers.fromAccount !== null 
        && transfers.fromAccount !== undefined 
        && transfers.fromAccount !== ""))
        {
            response.isError = true;
            response.transfersError.fromAccountErrorMsg = errorObjTransfers.fromAccount.text;
            response.errorList.push(errorObjTransfers.fromAccount.text);
        }
    
    if(!(transfers.toAccount !== null 
        && transfers.toAccount !== undefined 
        && transfers.toAccount !== ""))
        {
            response.isError = true;
            response.transfersError.toAccountErrorMsg = errorObjTransfers.toAccount.text;
            response.errorList.push(errorObjTransfers.toAccount.text);
        }

    if(transfers.isRetirement)
    {
        if(!(transfers.contributionYear !== null 
            && transfers.contributionYear !== undefined 
            && transfers.contributionYear !== ""))
            {
                response.isError = true;
                response.transfersError.contributionYearErrorMsg = errorObjTransfers.contributionYear.text;
                response.errorList.push(errorObjTransfers.contributionYear.text);
            }
    }
    
    if(!(transfers.transferDate !== null 
        && transfers.transferDate !== undefined
        && transfers.transferDate !== "" ))
        {
            response.isError = true;
            response.transfersError.transferDateErrorMsg = errorObjTransfers.transferDate.text;
            response.errorList.push(errorObjTransfers.transferDate.text);
        }

        if(!(transfers.transferAmount !== null 
            && transfers.transferAmount !== undefined
            && transfers.transferAmount !== ""))
            {
                response.isError = true;
                response.transfersError.transferAmountErrorMsg = errorObjTransfers.transferAmount.text;
                response.errorList.push(errorObjTransfers.transferAmount.text);
            }

    return response;

}

export function getCancelTransferReq(data)
{
    if(data !== null 
        && data !== undefined 
        && data.confirmationnumber !== null 
        && data.confirmationnumber !== undefined)
    {
        return { 
            "moneymovementid": "1", 
            "iscancel": true, 
            "trancode": (data.trncode !== null && data.trncode !== undefined) ? data.trncode : "", 
            "confirmno": (data.confirmationnumber != null && data.confirmationnumber !== undefined) ? data.confirmationnumber : "", 
            "memo": "",
            "iseft": (data.trncode !== null && data.trncode !== undefined && (data.trncode == "AD" || data.trncode == "ED" || data.trncode == "ER")),
            "isjnl": (data.trncode !== null && data.trncode !== undefined && (data.trncode == "JD" || data.trncode == "JJ"))
        }
        
    }
}

function formatDate(dateStr)
{
    if(Date.parse(dateStr))
    {
        let date = new Date(dateStr);
        let year = date.getUTCFullYear();
    
        var month = (1 + date.getUTCMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
    
        var day = date.getUTCDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return year + '-' + month + '-' + day;
    }else{
        return ""
    } 
}

export function identifyRetirementDisbursment(toAccount){
    let response = false;

    //change below line 
    if(toAccount && toAccount.iraTransferDirection && toAccount.iraTransferDirection === 'D'){
       response = true;
    }

    return response;
}

export function identifyRetirementDisbursmentPilot(preferences){
    let retirementDisAllowed = false;
    if(preferences !== null && preferences !==undefined 
        && preferences.pilotfeatures !== null && preferences.pilotfeatures !== undefined 
        && Array.isArray(preferences.pilotfeatures) && preferences.pilotfeatures.length > 0) {
            for(let i=0; i< preferences.pilotfeatures.length; i++){
                let feature = preferences.pilotfeatures[i];
                if(feature["componentname"] === "Retirement Disbursement"){
                     feature["active"] === "true" ? retirementDisAllowed = true : retirementDisAllowed = false;
                    break;
                }
            }
    }

    return retirementDisAllowed;
}