import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DocumentListURL, DocumentURL, CheckIfProspectClient } from './../../../common/constants.jsx';
import { AddCorrespondenceDocuments, AddAccountStatements, AddTaxDocuments, AddTradeConfirmDocuments } from './../../../actions/documentactions.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { CorrespondenceView } from './correspondenceview.jsx';
import { MessageType, MessageClass, GetAccountsList } from './../../../common/constants.jsx';
import axios from 'axios';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import { _logException, _logInformation } from "../../../common/_logging.js";
import { withPageView } from '../../../hoc/withPageView.jsx';
import correspondencemockresponse from './prospectcorrespondencemockresponse.js';
import pdfData from './../mockpdfresponse.js';
import dateFormat from "dateformat";
class Correspondence extends Component {
    constructor(props) {
        super(props);

        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            documentResponse: null,
            pdfData: null,
            objectIds: [],
            documentList: [],
            filteredDocumentList: [],
            documentListColumns: this.documentListColumns,
            accounts: this.accounts,
            selectedAccount: '',
            dropdownOpen: false,
            dateRangeDropdownOpen: false,
            dropdownValue: '',
            dropdownDispValue: '',
            dateRangeDropdownValue: '',
            dateRangeValue: '',
            isCustomDateRangeSelected: false,
            areDocumentsRetrieved: false,
            selected: [],
            isDocumentSelected: false,
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
            isDocumentResponseWithNoData: false,
            isDocumentResponseWithError: false,
            render: this.props.render,
            messages: [],
            fileName: '',
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.changeAccount = this.changeAccount.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.SearchDocuments = this.SearchDocuments.bind(this);
    }
    documentList = [];
    //dateRangeDropDownItems = [];
    documentCanellationToken = null;
    correspondenceToken = null;
    accountStatementCancellationToken = null;
    taxDocumentCancellationToken = null;
    tradeConfirmCancellationToken = null;

    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.accounts = [];
                this.accounts = GetAccountsList(this.props.selectedaccount);
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                //As per business requirement, default date range should be of 90 days
                var startDate = new Date();
                startDate.setDate(startDate.getDate() - 89);
                this.setState({
                    startDate: startDate,
                    endDate: new Date()
                });

                if (accountNumbers.length > 0) {
                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_CORRESPONDENCE',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': dateFormat(startDate, "yyyy-mm-dd"),
                        'SearchDateEnd': dateFormat(new Date(), "yyyy-mm-dd"),
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody);
                    this.setState({
                        dropdownValue: this.accounts[0].number,
                        dropdownDispValue: this.accounts[0].accountdisplayname,
                        areDocumentsRetrieved: false,
                        accounts: this.accounts,
                        pdfData: null,
                        isDocumentSelected: false,
                        isDocumentResponseWithNoData: false,
                        isDocumentResponseWithError: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: false,
                        isNonRockActSelected: false
                    });
                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true,
                        documentList: [],
                        filteredDocumentList: [],
                        isDocumentListResponseWithNoData: true,
                        isDocumentListResponseWithError: false,
                        areDocumentsRetrieved: false
                    });
                }

            }
            }
            
    }

    execute = (requestBody) => {
        let documentList = []
        let url = DocumentListURL;
        let i = 1;
        if (this.correspondenceToken) {
            this.correspondenceToken.cancel('Operation canceled due to new request.')
        }
        this.correspondenceToken = axios.CancelToken.source();
        Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.correspondenceToken)
            .then(res => {
                if (res.status === 200) {
                    const documentResponse = res.data;
                    if (documentResponse) {
                        documentResponse.value[0].DocumentList.map(document => {
                            var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
                            var correspondenceDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                            documentList.push({
                                "id": i,
                                "primaryValue": document.PrimaryValue,
                                "objectId": document.ObjectId,
                                "description": document.DisplayName,
                                "date": correspondenceDate
                            });
                            i++;
                        });
                        let correspondenceDocumentList = documentList;
                        if (correspondenceDocumentList && correspondenceDocumentList.length > 0) {
                            this.props.AddCorrespondenceDocuments(correspondenceDocumentList, correspondenceDocumentList, this.state.startDate, this.state.endDate);
                            this.setState({
                                documentList: correspondenceDocumentList,
                                filteredDocumentList: correspondenceDocumentList,
                                areDocumentsRetrieved: true,
                                isDocumentListResponseWithError: false,
                            });
                        } else {
                            this.props.AddCorrespondenceDocuments([], null, this.state.startDate, this.state.endDate);
                            this.setState({
                                documentList: [],
                                filteredDocumentList: [],
                                isDocumentListResponseWithNoData: true,
                                isDocumentListResponseWithError: false,
                                areDocumentsRetrieved: false
                            });
                        }
                        _logInformation("Correspondence: Documentlist fetched")
                    } else {
                        this.props.AddCorrespondenceDocuments(null, null, this.state.startDate, this.state.endDate);
                        this.setState({
                            documentList: [],
                            filteredDocumentList: [],
                            isDocumentListResponseWithNoData: true,
                            isDocumentListResponseWithError: false,
                            areDocumentsRetrieved: false
                        });
                    }
                } else {
                    this.props.AddCorrespondenceDocuments(null, null, this.state.startDate, this.state.endDate);
                    this.setState({
                        isDocumentListResponseWithNoData: true,
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithError: false,
                    });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    _logException("Correspondence : Fetching documentlist: " + (error && error.response) ? error.response : "");
                    console.log(error);
                    this.setState({
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: true
                    });
                }
            });
    }

    componentWillUnmount() {
        if (this.correspondenceToken) {
            this.correspondenceToken.cancel('Operation canceled due to new request.')
        }
        if (this.accountStatementCancellationToken) {
            this.accountStatementCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.taxDocumentCancellationToken) {
            this.taxDocumentCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.tradeConfirmCancellationToken) {
            this.tradeConfirmCancellationToken.cancel('Operation canceled due to new request.')
        }
    }
    componentDidMount() {
        //Put Page view
        /*   let pageViewData = new PageView();
          pageViewData.name = "Correspondence";
          pageViewData.url = "/Correspondence";
          _logPageView(pageViewData); */
        if (this.state.isProspectClient) {
            this.SetDummyResponseForProspectClient();
        }
        else {
            //if statements are already available in global store, use them
            if (this.props.correspondenceDocumentList) {
                this.setState({
                    documentList: this.props.correspondenceDocumentList,
                    filteredDocumentList: this.props.correspondenceDocumentList,
                    areDocumentsRetrieved: true,
                    startDate: this.props.correspondenceDocStartDate,
                    endDate: this.props.correspondenceDocEndDate
                });
            } else {    //if statements are not available, make a service call and store them in global store  
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });

                //As per business requirement, default date range should be of 90 days
                var startDate = new Date();
                startDate.setDate(startDate.getDate() - 89);
                this.setState({
                    startDate: startDate,
                    endDate: new Date()
                });

                if (accountNumbers.length > 0) {

                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_CORRESPONDENCE',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': dateFormat(startDate, "yyyy-mm-dd"),
                        'SearchDateEnd': dateFormat(this.state.endDate, "yyyy-mm-dd"),
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody)
                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true
                    });
                }

            }
            this.setState({
                dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
                dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
                selectedAccount: 'All Accounts',
            });
        }
    }
    SearchDocuments() {
        this.setState({
            pdfData: null,
            isDocumentSelected: false,
            selected: [],
            areDocumentsRetrieved: false,
            messages: []
        });
        let messages = [];
        if (new Date(dateFormat(this.state.startDate, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
            return;
        } else {
            this.setState({
                messages: messages
            });
        }
        let accountNumbers = [];
        this.state.accounts.map(acct => {
            if (acct.number !== 'All Accounts') {
                if (acct.isInternal) {
                    accountNumbers.push(acct.number);
                }
            }
        });
        this.setState({
            pdfData: null,
            isDocumentSelected: false,
            selected: [],
            areDocumentsRetrieved: false,
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
            documentList: [],
            filteredDocumentList: []
        });
        let requestBody = JSON.stringify({
            'VirtualTable': 'NF_CORRESPONDENCE',
            'PrimaryType': 'Account',
            'PrimaryValue': accountNumbers,
            'SearchDateComparison': 'R',
            'SearchDateBegin': this.state.startDate ? dateFormat(this.state.startDate, "yyyy-mm-dd") : '',
            'SearchDateEnd': this.state.endDate ? dateFormat(this.state.endDate, "yyyy-mm-dd") : '',
            'SearchDateEndSpecified': true,
            'SearchAllVersions': true,
            'IncludeRelationships': false,
            'RenditionFilter': '*',
            'DateRange': '',
        });
        this.execute(requestBody);
    }


    startDateChange(date) {
        let messages = [];
        this.setState({
            startDate: date
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("start Date: " + this.state.startDate);
    }
    endDateChange(date) {
        let messages = [];
        this.setState({
            endDate: date
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) < new Date(dateFormat(this.state.startDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("end Date: " + this.state.endDate);
    }
    changeAccount(selectedAccount) {
        this.setState({
            pdfData: null,
            dropdownValue: selectedAccount.number,
            isDocumentSelected: false,
            selected: []
        });
        let accountNumber = selectedAccount.number;
        let docList = this.state.documentList.filter(doc => doc.primaryValue === accountNumber);
        this.props.AddCorrespondenceDocuments(this.props.correspondenceDocumentList, docList);
        if (accountNumber !== 'All Accounts') {
            this.setState({
                filteredDocumentList: docList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        } else {
            this.setState({
                filteredDocumentList: this.state.documentList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    selectDocument(row, isSelect) {
        if (this.state.isProspectClient) {
            this.setState({
                pdfData: pdfData,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
        }
        else {
            this.setState({
                pdfData: null,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
            let requestBody = JSON.stringify({
                'VirtualTable': 'NF_CORRESPONDENCE',
                'PrimaryType': 'Account',
                'PrimaryValue': row.primaryValue,
                'ObjectId': row.objectId,

            });

            let url = DocumentURL;
            if (this.documentCanellationToken) {
                this.documentCanellationToken.cancel('Operation canceled due to new request.')
            }
            this.documentCanellationToken = axios.CancelToken.source();
            Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.documentCanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        let selectedDocPdfData = res && res.data && res.data.value[0].ContentStream;
                        if (selectedDocPdfData !== "") {
                            this.setState({
                                pdfData: selectedDocPdfData
                            })
                        } else {
                            this.setState({
                                pdfData: null,
                                isDocumentResponseWithNoData: true
                            })
                        }

                    } else {
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithNoData: true
                        })
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //_logException("Correspondence : Fetching document: " + (error && error.response) ? error.response : "");
                        console.log(error);
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithError: true
                        })
                    }
                });
        }
       
    }

    SetDummyResponseForProspectClient() {
        let documentList = [];
        let i = 1;
        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        if (correspondencemockresponse) {
            correspondencemockresponse.value[0].DocumentList.map(document => {
                var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
                var correspondenceDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                documentList.push({
                    "id": i,
                    "primaryValue": document.PrimaryValue,
                    "objectId": document.ObjectId,
                    "description": document.DisplayName,
                    "date": correspondenceDate
                });
                i++;
            });
            let correspondencestmtdoclist = [];
            documentList.forEach(doc => {
                this.accounts.forEach(act => {
                    if (act.number === doc.primaryValue) {
                        correspondencestmtdoclist.push(doc);
                    }
                })
            });
            let correspondenceDocumentList = documentList;
            if (correspondenceDocumentList && correspondenceDocumentList.length > 0) {
                this.props.AddCorrespondenceDocuments(correspondenceDocumentList, correspondenceDocumentList, this.state.startDate, this.state.endDate);
                this.setState({
                    documentList: correspondenceDocumentList,
                    filteredDocumentList: correspondencestmtdoclist,
                    areDocumentsRetrieved: true,
                    isDocumentListResponseWithError: false,
                });
            } else {
                this.setState({
                    documentList: [],
                    filteredDocumentList: [],
                    isDocumentListResponseWithNoData: true,
                    isDocumentListResponseWithError: false,
                    areDocumentsRetrieved: false
                });
            }
            _logInformation("Correspondence: Documentlist fetched")
        }

        //As per business requirement, default date range should be of 90 days
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 89);
        this.setState({
            startDate: startDate,
            endDate: new Date(),
            dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
            dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
            selectedAccount: 'All Accounts',
        });
    }

    options = [];
    render() {
        console.log("Inside render")
        let documents = [];
        this.state.accounts.map(acct => {
            documents = this.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number);
            this.options.push({
                custom: true,
                totalSize: documents.length < 11 ? 0 : documents.length
            })
        });
        let authprops = {
            token: this.props.token,
            contextjson: this.props.contextjson,
            profilejson: this.props.profilejson,
            portalid: this.props.portalid
        }
        return (
            <ErrorBoundary>
                <CorrespondenceView
                    state={this.state}
                    options={this.options}
                    selectDocument={this.selectDocument}
                    toggle={this.toggle}
                    changeAccount={this.changeAccount}
                    SearchDocuments={this.SearchDocuments}
                    startDateChange={this.startDateChange}
                    endDateChange={this.endDateChange}
                    authprops={authprops}
                    selectedaccounts={this.props.selectedaccount}
                    role={this.props.role}
                />
            </ErrorBoundary>
        )
    }
}
const mapStateToProps = state => {
    return {
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        correspondenceDocumentList: state.doc.correspondenceDocumentList,
        filteredCorrespondenceDocumentList: state.doc.filteredCorrespondenceDocumentList,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        portalid: state.acct.portalid,
        correspondenceDocStartDate: state.doc.correspondenceDocStartDate,
        correspondenceDocEndDate: state.doc.correspondenceDocEndDate,
        role: state.acct.role
    };
};
const mapDispatchToProps = {
    AddCorrespondenceDocuments,
    AddAccountStatements,
    AddTaxDocuments,
    AddTradeConfirmDocuments
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Correspondence' })
)
export default enhancement(Correspondence);