import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { HoldingsbyaccountURL, spinner, GetAccountsList, MsgForNoData, CheckIfProspectClient, GetPreferencePilotValue } from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddHoldingsByAccount } from '../../../../../actions/toptenactions.jsx';
import { compose } from 'redux';
import { accountchildcolumns, renderRowsByAccount, renderRowsForAccount } from '../holdingdetailscolumns.jsx';
import axios from "axios";
import { AccountHoldingsView } from './accountholdingview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import TASHoldingdetails from '../tasholding.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { accountholdingdetailsDummyResponse, RRR_123456_accountholdingdetailsDummyResponse, RRR_234567_accountholdingdetailsDummyResponse } from '../../../accountsmockresponse.js';
import Watermark from '../../../../../common/watermark.jsx';
import { SetHoldingAccount } from './../../../../../actions/accountsactions';

class AccountHoldingdetails extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            holdingsresponsebyaccount: this.props.holdingsresponsebyaccount,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            isrowexpanded: false,
            expanded: [],
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role),
            balSummaryV2PilotFlag: this.setBalanceSummaryV2Flag(),
            viewYield: this.setViewYieldFlag()
        }
        this.execute = this.execute.bind(this);
        this.toggle = this.toggle.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
        this.setBalanceAccountHolding = this.setBalanceAccountHolding.bind(this);
    }
    getAccountHoldingsCancellationToken = null;
    getAccountHoldingsAccountChangeCancellationToken = null;
    setBalanceSummaryV2Flag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'BalSummaryV2', 'false') === 'true' ? true : false;
    }
    setViewYieldFlag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
    }
    //method to execute api calls
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];
        const baseUrlAccount = HoldingsbyaccountURL + '\'' + "" + '\',\'' + null + '\')';
        if (this.getAccountHoldingsCancellationToken) {
            //Cancel the existing call
            this.getAccountHoldingsCancellationToken.cancel('Cancelling the existing Account Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getAccountHoldingsCancellationToken = cancelToken.source();
        Get(baseUrlAccount, this.props.token, this.props.contextjson, this.props.profilejson, this.getAccountHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data != "" ? result.data.value : datarows;
                this.props.AddHoldingsByAccount(data);
                this.setState(() => ({ holdingsresponsebyaccount: data, isloading: false }), () => {
                    this.setBalanceAccountHolding();
                })

            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message }));
                }
            })
    };

    setBalanceAccountHolding = () => {
        if (this.state.holdingsresponsebyaccount && this.state.holdingsresponsebyaccount.length > 0 && this.props.newholdingaccount && this.props.newholdingaccount.length > 0) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [this.props.newholdingaccount[0].key]
            }), () => {
                this.props.SetHoldingAccount([]);
            });

        }
    }

    componentDidMount() {
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.holdingsresponsebyaccount === null) {
                this.execute();
            }
            else {
                this.setState({
                    isloading: false,
                }, () => { this.setBalanceAccountHolding() });
            }
            
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute(this.accounts);
            }

        }
    }

    componentWillUnmount() {
        if (this.getAccountHoldingsCancellationToken !== null && this.getAccountHoldingsCancellationToken !== undefined) {
            this.getAccountHoldingsCancellationToken.cancel('Cancelling the Account Holdings during unmount to prevent memory leak.');
        }
        if (this.getAccountHoldingsAccountChangeCancellationToken !== null && this.getAccountHoldingsAccountChangeCancellationToken !== undefined) {
            this.getAccountHoldingsAccountChangeCancellationToken.cancel('Cancelling the Account Holdings during unmount to prevent memory leak.');
        }
    }
    collapserow(row) {
        this.setState(() => ({
            expanded: []
        }));
    }

    GetHeaderName() {
        if (this.state.rowdata) {
            let header = this.state.rowdata.SECNAME ? this.state.rowdata.SECNAME : ""
            header += this.state.rowdata.SECID ? (" - " + this.state.rowdata.SECID) : "";
            return header;
        }
        return "";
    }
    toggle(row) {
        this.setState(prevState => ({
            modal: !prevState.modal,

        }));
        this.state.rowdata = row;
    }
    gotobalance = () => {
        document.dispatchEvent(new CustomEvent('goToBalanceDetails', { bubbles: true }));
    }
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.ACCTKEY]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.ACCTKEY),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
    }

    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }

    SetDummayResponseForProspectClient() {
        if (this.props.holdingsresponsebyaccount === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ expanded: ["RRR-123456"], holdingsresponsebyaccount: (RRR_123456_accountholdingdetailsDummyResponse && RRR_123456_accountholdingdetailsDummyResponse.value ? RRR_123456_accountholdingdetailsDummyResponse.value : []), isloading: false, areDataRetrieved: true }), () => { this.props.AddHoldingsByAccount((RRR_123456_accountholdingdetailsDummyResponse && RRR_123456_accountholdingdetailsDummyResponse.value ? RRR_123456_accountholdingdetailsDummyResponse.value : [])); }, () => { this.setBalanceAccountHolding });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ expanded: ["RRR-234567"], holdingsresponsebyaccount: (RRR_234567_accountholdingdetailsDummyResponse && RRR_234567_accountholdingdetailsDummyResponse.value ? RRR_234567_accountholdingdetailsDummyResponse.value : []), isloading: false, areDataRetrieved: true }), () => { this.props.AddHoldingsByAccount((RRR_234567_accountholdingdetailsDummyResponse && RRR_234567_accountholdingdetailsDummyResponse.value ? RRR_234567_accountholdingdetailsDummyResponse.value : [])); }, () => { this.setBalanceAccountHolding });
                }
            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ expanded: ["RRR-123456"], holdingsresponsebyaccount: (accountholdingdetailsDummyResponse && accountholdingdetailsDummyResponse.value ? accountholdingdetailsDummyResponse.value : []), isloading: false, areDataRetrieved: true }), () => { this.props.AddHoldingsByAccount((accountholdingdetailsDummyResponse && accountholdingdetailsDummyResponse.value ? accountholdingdetailsDummyResponse.value : [])); }, () => { this.setBalanceAccountHolding });
            }
        }
        else {
            
            this.setState({
                isloading: false, expanded: ["RRR-123456"]
            }, () => { this.setBalanceAccountHolding() });
        }
    }
    //render method
    render() {

        var accountData = [];
        var totalmkVal;
        var totalPct;
        var totalglamount, totalglpct, totaltodayschange, totaltodayschangepct;

        if (this.state.holdingsresponsebyaccount && this.state.holdingsresponsebyaccount.length > 0) {
            if (this.state.holdingsresponsebyaccount[0].AcctPosLst) {
                for (var i = 0; i < this.state.holdingsresponsebyaccount[0].AcctPosLst.length; i++) {
                    if (this.state.holdingsresponsebyaccount[0].AcctPosLst[i].ACCTNUM === null) {
                        accountData = [];
                    }
                    else {
                        accountData = this.state.holdingsresponsebyaccount[0].AcctPosLst;
                    }
                }
                accountData = renderRowsForAccount(accountData, this.props.selectedaccount, this.props.balancedetails);
            }



            totalPct = this.state.holdingsresponsebyaccount[0].TotalMktPct;
            totalglamount = this.state.holdingsresponsebyaccount[0].unrealizedvalue
            totalglpct = this.state.holdingsresponsebyaccount[0].unrealizedpct
            if (this.state.holdingsresponsebyaccount[0].MKTVALS) {
                totaltodayschange = this.state.holdingsresponsebyaccount[0].MKTVALS.MKTVALCHANGE;
                totaltodayschangepct = this.state.holdingsresponsebyaccount[0].MKTVALS.MKTVALCHANGEPCT;
                totalmkVal = this.state.holdingsresponsebyaccount[0].MKTVALS.RCNTMKTVAL;
            }
            this.optionsAccount = [];
            this.optionsAccount.push({
                custom: true,
                totalSize: this.state.holdingsresponsebyaccount && this.state.holdingsresponsebyaccount.length < 5 ? 0 : this.state.holdingsresponsebyaccount.length
            });

        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for account table
        const expandRowByAccount = {
            renderer: (row) => (
                <div className="row" key="dvexpandaccountrow">
                    <div className="col-sm-12" key="dvexpandaccountcol">
                        <BootstrapTable key="btaccountchild"
                            keyField='SECID'
                            classes="RCM_two_level_table2 RCM_Headerless_table"
                            data={renderRowsByAccount(row, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                            columns={accountchildcolumns(this.state.viewYield)}
                            bordered={false}
                            rowEvents={rowEvents}
                            noDataIndication={MsgForNoData}
                        />
                    </div>
                </div>
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }

                return (<div className="RCM_icon_hand RCM_right_arrow" >
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.ACCTKEY);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };
        return (
            <ErrorBoundary>
                <AccountHoldingsView
                    state={this.state}
                    accountData={accountData}
                    accounts={this.props.selectedaccount}
                    totalmkVal={totalmkVal}
                    totalglamount={totalglamount}
                    totalglpct={totalglpct}
                    totaltodayschange={totaltodayschange}
                    totaltodayschangepct={totaltodayschangepct}
                    selectedAccount={this.props.selectedaccount}
                    expandRowByAccount={expandRowByAccount}
                    rowsortevent={this.rowsortevent}
                    gotobalance={this.gotobalance}
                />
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="RCM_holding_details_modal_content"
                    zIndex='9999'
                    modalClassName="RCM_CM_modal_class"
                    centered key='modaltas1'>
                    <ModalHeader toggle={this.toggle} className="RCM_holding_details_modal" key='modaltas2'>
                        <label className="RCM_tile_heading" key='lblholdingheading'>{this.GetHeaderName()}</label>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.isProspectClient ? <Watermark /> : ''}
                        <TASHoldingdetails row={this.state.rowdata}></TASHoldingdetails>
                    </ModalBody>

                </Modal>
            </ErrorBoundary>

        )

    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        holdingsresponsebyaccount: state.topten.holdingsbyaccount,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        balancedetails: state.acct.balancedetails,
        role: state.acct.role,
        preferences: state.acct.preferences,
        newholdingaccount: state.acct.newHoldingAccount
    };
};

const mapDispatchToProps = {
    AddHoldingsByAccount,
    SetHoldingAccount
};
const accountholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Account Holdings' })
)
export default accountholdingdetailscomp(AccountHoldingdetails);