import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    GetAccountsList, MsgForNoData, ProjectedIncomeSummaryURL, addIndexToList, getAccountDisplayName, IndLongShort, CheckIfProspectClient,
    GetPreferencePilotValue} from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddProjectedIncomeSummaryAccount, AddProjectedIncomeSummarySecurity, AddProjectedIncomeSummaryChart, UpdateProjectedIncomeSummaryToggleView, ChangeYearProjectedIncome, RefreshProjectedIncomeAccountSecurity } from '../../../../../actions/toptenactions.jsx';
import { GetSecuritySummaryChildColumns, accountSummaryChildColumns, securitySummaryChildColumns, renderRowsBySecuritySummaryChild, renderRowsByAccountSummaryChild, yearoptions, getYearOptionsText } from '../projectedincomecolumns.jsx';
//import TASHoldingdetails from '../realizedtasholding.jsx';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from "axios";
import { ProjectedIncomeSummaryView } from './projectedincomesummaryview';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { projectedincomesummarychart, RRR_123456_projectedincomesummarychart, RRR_234567_projectedincomesummarychart, projectedincomesummarysecurity, RRR_123456_projectedincomesummarysecurity, projectedincomesummaryaccount, RRR_123456_projectedincomesummaryaccount, RRR_234567_projectedincomesummaryaccount, RRR_234567_projectedincomesummarysecurity } from './../../../accountsmockresponse'

class ProjectedIncomeSummary extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        let obj = { preferences: this.props.preference }
        this.state = {
            modal: false,
            chartData: this.props.projectedIncomeSummaryChartData,
            securityViewData: this.props.projectedIncomeSummarySecurityData,
            accountViewData: this.props.projectedIncomeSummaryAccountData,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            requestFailed: false,
            isloadingSecuritySummary: true,
            requestFailedSecuritySummary: false,
            isloadingAccountSummary: true,
            requestFailedAccountSummary: false,
            render: this.props.render,
            expanded: [],
            //dropdownValue: '',
            //dropdownDispValue: '',
            //accountOverviewFlag: this.props.accountOverviewFlag,
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
            //monthDropdownValue: 'All',
            dropdownyearValue: this.props.projectedIncomeYear ? this.props.projectedIncomeYear : '2',
            dropdownText: this.props.projectedIncomeYear ? getYearOptionsText(this.props.projectedIncomeYear) : getYearOptionsText('2'),
            showTable: false,
            //tooltipOpen1: false,
            viewToggleValue: true,
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role),
            isEstimatedPayDate: GetPreferencePilotValue(obj, 'EstimatedPayDate', 'false') === 'true' ? true : false
        }
        this.execute = this.execute.bind(this);
       // this.collapserow = this.collapserow.bind(this);
        //this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        //this.toggle = this.toggle.bind(this);
        //this.toggle1 = this.toggle1.bind(this);
        this.toggleShowTable = this.toggleShowTable.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.fetchTableSecurityViewData = this.fetchTableSecurityViewData.bind(this);
        this.fetchTableAccountViewData = this.fetchTableAccountViewData.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
        this.changeYear = this.changeYear.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
    }
    getEstimatedIncomeSummaryChartCancellationToken = null;
    getEstimatedIncomeSummarySecurityCancellationToken = null;
    getEstimatedIncomeSummaryAccountCancellationToken = null;

    //method to execute api calls
    execute = (indUpdateToggleView) => {

        this.setState(() => ({ isloading: true, requestFailed: false, chartData: [] }), () => {
            if (this.getEstimatedIncomeSummaryChartCancellationToken) {
                //Cancel the existing call
                this.getEstimatedIncomeSummaryChartCancellationToken.cancel('Cancelling the existing estimated income request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getEstimatedIncomeSummaryChartCancellationToken = cancelToken.source();
            let selectorparam = 'C';
            const baseUrlSummary = ProjectedIncomeSummaryURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
            Get(baseUrlSummary, this.props.token, this.props.contextjson, this.props.profilejson, this.getEstimatedIncomeSummaryChartCancellationToken)
                .then(result => {
                    const data = result && result.data && result.data != "" ? result.data : null;
                    this.setState(() => ({ chartData: data, isloading: false }), () => {
                        this.props.AddProjectedIncomeSummaryChart(data);
                        if (indUpdateToggleView)
                            this.props.UpdateProjectedIncomeSummaryToggleView('C');
                        this.props.ChangeYearProjectedIncome(this.state.dropdownyearValue);
                    });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                    }
                })

        });
    };

    fetchTableSecurityViewData = (indUpdateToggleView) => {

        this.setState(() => ({ isloadingSecuritySummary: true, requestFailedSecuritySummary: false, securityViewData: [] }), () => {

            if (this.getEstimatedIncomeSummarySecurityCancellationToken) {
                //Cancel the existing call
                this.getEstimatedIncomeSummarySecurityCancellationToken.cancel('Cancelling the existing estimated income request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getEstimatedIncomeSummarySecurityCancellationToken = cancelToken.source();
            let selectorparam = 'S';
            const baseUrlSummary = ProjectedIncomeSummaryURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
            Get(baseUrlSummary, this.props.token, this.props.contextjson, this.props.profilejson, this.getEstimatedIncomeSummarySecurityCancellationToken)
                .then(result => {
                    const data = result && result.data && result.data != "" ? result.data : null;
                    this.setState(() => ({ securityViewData: data, isloadingSecuritySummary: false }), () => {
                        this.props.AddProjectedIncomeSummarySecurity(data);
                        if (indUpdateToggleView)
                            this.props.UpdateProjectedIncomeSummaryToggleView('S');
                        this.props.ChangeYearProjectedIncome(this.state.dropdownyearValue);
                    });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailedSecuritySummary: true, error: error.message, isloadingSecuritySummary: false }));
                    }
                })
        });
    }

    fetchTableAccountViewData = (indUpdateToggleView) => {

        this.setState(() => ({ isloadingAccountSummary: true, requestFailedAccountSummary: false, accountViewData: [] }), () => {

            if (this.getEstimatedIncomeSummaryAccountCancellationToken) {
                //Cancel the existing call
                this.getEstimatedIncomeSummaryAccountCancellationToken.cancel('Cancelling the existing estimated income request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getEstimatedIncomeSummaryAccountCancellationToken = cancelToken.source();
            let selectorparam = 'A';
            const baseUrlSummary = ProjectedIncomeSummaryURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
            Get(baseUrlSummary, this.props.token, this.props.contextjson, this.props.profilejson, this.getEstimatedIncomeSummaryAccountCancellationToken)
                .then(result => {
                    const data = result && result.data && result.data != "" ? result.data : null;
                    this.setState(() => ({ accountViewData: data, isloadingAccountSummary: false }), () => {
                        this.props.AddProjectedIncomeSummaryAccount(data);
                        if (indUpdateToggleView)
                            this.props.UpdateProjectedIncomeSummaryToggleView('A');
                        this.props.ChangeYearProjectedIncome(this.state.dropdownyearValue);
                    });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailedAccountSummary: true, error: error.message, isloadingAccountSummary: false }));
                    }
                });
        });
    }

    componentDidMount() {
        //if props has data then take it or make api call.
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.projectedIncomeSummaryChartData == null)
                this.execute(true);
            else {
                this.setState({ isloading: false, requestFailed: false });
                this.props.UpdateProjectedIncomeSummaryToggleView('C');
            }
            if (this.props.projectedIncomeSummarySecurityData == null)
                this.fetchTableSecurityViewData(false);
            else this.setState({ isloadingSecuritySummary: false, requestFailedSecuritySummary: false });
            if (this.props.projectedIncomeSummaryAccountData == null)
                this.fetchTableAccountViewData(false);
            else this.setState({ isloadingAccountSummary: false, requestFailedAccountSummary: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.refreshScreens();
            }
        }
    }

    refreshScreens() {
        if (!this.state.showTable) {
            this.execute(true);
            this.fetchTableSecurityViewData(false);
            this.fetchTableAccountViewData(false);
        } else {
            if (this.state.viewToggleValue) {
                //Call security api
                this.fetchTableSecurityViewData(true);
                this.fetchTableAccountViewData(false);
                this.execute(false);
            } else {
                //call account api
                this.fetchTableAccountViewData(true);
                this.fetchTableSecurityViewData(false);
                this.execute(false);
            }
        }
    }

    componentWillUnmount() {
        if (this.getEstimatedIncomeSummaryChartCancellationToken !== null && this.getEstimatedIncomeSummaryChartCancellationToken !== undefined) {
            this.getEstimatedIncomeSummaryChartCancellationToken.cancel('Cancelling the Estimated Income Summary Chart during unmount to prevent memory leak.');
        }
        if (this.getEstimatedIncomeSummarySecurityCancellationToken !== null && this.getEstimatedIncomeSummarySecurityCancellationToken !== undefined) {
            this.getEstimatedIncomeSummarySecurityCancellationToken.cancel('Cancelling the Estimated Income Summary By Security during unmount to prevent memory leak.');
        }
        if (this.getEstimatedIncomeSummaryAccountCancellationToken !== null && this.getEstimatedIncomeSummaryAccountCancellationToken !== undefined) {
            this.getEstimatedIncomeSummaryAccountCancellationToken.cancel('Cancelling the Estimated Income Summary By Account during unmount to prevent memory leak.');
        }

    }

    //collapserow(row) {
    //    this.setState(() => ({
    //        expanded: []
    //    }));
    //}

    //toggle1() {
    //    this.setState({ tooltipOpen1: !this.state.tooltipOpen1 });
    //}

    toggleShowTable() {
        this.setState(prevState => ({ showTable: !prevState.showTable,expanded:[] }), () => {
            if (this.state.showTable) {
                if (this.props.projectedIncomeSummarySecurityData == null) {
                    this.fetchTableSecurityViewData(true);
                }
                else {
                    this.props.UpdateProjectedIncomeSummaryToggleView('S');
                    this.setState(() => ({ isloadingSecuritySummary: false, requestFailedSecuritySummary: false }));
                }
               
            } else {
                if (this.props.projectedIncomeSummaryChartData == null) {
                    this.execute(true);
                }
                else {
                    this.props.UpdateProjectedIncomeSummaryToggleView('C');
                    this.setState(() => ({ isloading: false, requestFailed: false }));
                }
            }
        });
    }

    //toggle(row) {
    //    this.setState(prevState => ({
    //        modal: !prevState.modal,

    //    }));
    //    this.state.rowdata = row;
    //}
    //toggleDropdown() {
    //    this.setState(prevState => ({
    //        dropdownOpen: !prevState.dropdownOpen
    //    }));
    //}

     handleOnExpand = (row, isExpand, rowIndex, e) => {
         if (isExpand) {
             this.setState(() => ({
                 isrowexpanded: true,
                 expanded: [...this.state.expanded, row.UniqueIdx]
             }));
         } else {
             this.setState(() => ({
                 expanded: this.state.expanded.filter(x => x !== row.UniqueIdx),
                 isrowexpanded: this.state.expanded.length > 1 ? true : false
             }));
         }
     }

    changeYear(selectedyear) {
        if (this.state.dropdownText !== selectedyear.label)
            this.setState({ dropdownText: selectedyear.label, dropdownyearValue: selectedyear.value, childColumnToSort: '', childColumnSortOrder: '' },
                () => this.refreshScreens(),
                this.props.RefreshProjectedIncomeAccountSecurity);
    }

    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }

    toggleView() {
        this.setState({ viewToggleValue: !this.state.viewToggleValue, expanded: [] }, () => {
            if (this.state.viewToggleValue) {
                //Call security api
                if (this.props.projectedIncomeSummarySecurityData == null) {
                    this.fetchTableSecurityViewData(true);
                }
                else {
                    this.props.UpdateProjectedIncomeSummaryToggleView('S');
                    this.setState(() => ({ isloadingSecuritySummary: false, requestFailedSecuritySummary: false }));
                }

            } else {
                //call account api
                if (this.props.projectedIncomeSummaryAccountData == null) {
                    this.fetchTableAccountViewData(true);
                }
                else {
                    this.props.UpdateProjectedIncomeSummaryToggleView('A');
                    this.setState(() => ({ isloadingAccountSummary: false, requestFailedAccountSummary: false }));
                }

            }
        });
    }

    getChartInput(data) {
        let chartrows = [];
        if (data !== null && Array.isArray(data) && data.length > 0 && data[0] && Array.isArray(data[0].incomeSubTotal) && data[0].incomeSubTotal.length > 0) {
            let currentmonth = new Date().getMonth() + 1;
            let futuremonths = data[0].incomeSubTotal.filter(c => c.monseq < (currentmonth));
            futuremonths = orderBy(futuremonths, ['monseq'], ['asc'])
            for (var i = 0; i < futuremonths.length; i++) {
                futuremonths[i].monseq = 13 + i;
            }
            chartrows = cloneDeep(data[0].incomeSubTotal);
            chartrows = orderBy(chartrows, ['monseq'], ['asc']);
            let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            for (var i = 0; i < chartrows.length; i++) {
                chartrows[i].month = textfieldarr[(chartrows[i].monseq > 12 ? (chartrows[i].monseq - 13) : (chartrows[i].monseq - 1))] + ' ' + (chartrows[i].monseq > 12 ? (new Date().getFullYear() + 1).toString() : (new Date().getFullYear()).toString())
            }
        }

        return chartrows;
    }

    getChartTotal(data) {

        let response = {};
        if (data !== null && Array.isArray(data) && data.length > 0 && data[0] && Array.isArray(data[0].incomeSubTotal) && data[0].incomeSubTotal.length > 0) {
            response = data[0];
        }

        return response;
    }

    getSecurityInput(data) {
        let securityrows = [];
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            securityrows = data.value[0].IncomeSubTotal ? addIndexToList(data.value[0].IncomeSubTotal) : [];
        }
        return securityrows;
    }

    getSecurityInputTotal(data) {

        let resp = {};
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            let localData = JSON.parse(JSON.stringify(data));
            delete localData.value[0].IncomeSubTotal;
            delete localData.value[0]['@odata.type'];

            resp = localData.value[0];
        }
        return resp;
    }

    getAccountInputTotal(data) {

        let resp = {};
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            let localData = JSON.parse(JSON.stringify(data));
            delete localData.value[0].IncomeSubTotal;
            delete localData.value[0]['@odata.type'];

            resp = localData.value[0];
        }
        return resp;
    }

    getAccountViewInput(data) {
        let accountrows = [];
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            accountrows = data.value[0].IncomeSubTotal ? addIndexToList(data.value[0].IncomeSubTotal) : [];
            for (var i = 0; i < accountrows.length; i++) {
                accountrows[i].ACCOUNTNICKNAME = getAccountDisplayName(this.props.selectedaccount, accountrows[i].ACCT, IndLongShort.SINGLEVIEW);

            }
        }
        return accountrows;
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.selectedaccount.length == 1) {
            if (this.props.selectedaccount[0].key === "RRR-123456") {
                this.setState(() => ({
                    chartData: RRR_123456_projectedincomesummarychart,
                    accountViewData: RRR_123456_projectedincomesummaryaccount,
                    securityViewData: RRR_123456_projectedincomesummarysecurity, isloading: false
                }), () => {
                    this.props.AddProjectedIncomeSummaryChart(RRR_123456_projectedincomesummarychart);
                    this.props.AddProjectedIncomeSummaryAccount(RRR_123456_projectedincomesummaryaccount);
                    this.props.AddProjectedIncomeSummarySecurity(RRR_123456_projectedincomesummarysecurity);
                });
            }
            else if (this.props.selectedaccount[0].key === "RRR-234567") {
                this.setState(() => ({
                    chartData: RRR_234567_projectedincomesummarychart,
                    accountViewData: RRR_234567_projectedincomesummaryaccount,
                    securityViewData: RRR_234567_projectedincomesummarysecurity, isloading: false
                }), () => {
                    this.props.AddProjectedIncomeSummaryChart(RRR_234567_projectedincomesummarychart);
                    this.props.AddProjectedIncomeSummaryAccount(RRR_234567_projectedincomesummaryaccount);
                    this.props.AddProjectedIncomeSummarySecurity(RRR_234567_projectedincomesummarysecurity);
                });
            }
        } else if (this.props.selectedaccount.length == 2) {

            this.setState(() => ({ chartData: projectedincomesummarychart, accountViewData: projectedincomesummaryaccount, securityViewData: projectedincomesummarysecurity, isloading: false }), () => {
                this.props.AddProjectedIncomeSummaryChart(projectedincomesummarychart);
                this.props.AddProjectedIncomeSummaryAccount(projectedincomesummaryaccount);
                this.props.AddProjectedIncomeSummarySecurity(projectedincomesummarysecurity);
            });
        }
    }

    //render method
    render() {

        const expandRowBySecurity = {
            renderer: (row) => (
                <BootstrapTable
                    key="btsecchild1"
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={this.state.viewToggleValue ? renderRowsBySecuritySummaryChild(row.IncomeDetail ? row.IncomeDetail : [], this.props.selectedaccount, this.state.childColumnToSort, this.state.childColumnSortOrder) : renderRowsByAccountSummaryChild(row.IncomeDetail ? row.IncomeDetail : [], this.props.selectedaccount, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                    columns={this.state.viewToggleValue ? securitySummaryChildColumns(this.props.selectedaccount, this.state.dropdownyearValue) : accountSummaryChildColumns(this.state.dropdownyearValue)}
                    noDataIndication={MsgForNoData}
                    bordered={false}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.UniqueIdx);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };

        let chartrows = this.getChartInput(this.state.chartData);
        let chartTotal = this.getChartTotal(this.state.chartData);
        let securityrows = this.getSecurityInput(this.state.securityViewData);
        let securityrowsTotal = this.getSecurityInputTotal(this.state.securityViewData);
        let accountrows = this.getAccountViewInput(this.state.accountViewData);
        let acountrowsTotal = this.getAccountInputTotal(this.state.accountViewData);


        return (

            <ErrorBoundary>
                <ProjectedIncomeSummaryView
                    state={this.state}
                    showTable={this.state.showTable}
                    securityrows={securityrows}
                    securityrowsTotal={securityrowsTotal}
                    acountrowsTotal={acountrowsTotal}
                    accountrows={accountrows}
                    chartrows={chartrows}
                    chartTotal={chartTotal}
                    toggle={this.toggleDropdown}
                    changeAccount={this.changeAccount}
                    accounts={this.props.selectedaccount}
                    selectedAccount={this.props.selectedaccount}
                    expandRowBySecurity={expandRowBySecurity}
                    changeYear={this.changeYear}
                    toggleYear={this.toggleYear}
                    toggleShowTable={this.toggleShowTable}
                    toggle1={this.toggle1}
                    toggleView={this.toggleView}
                    rowsortevent={this.rowsortevent}
                    yearoptions={yearoptions}
                />

            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        projectedIncomeSummaryChartData: state.topten.projectedIncomeSummaryChartData,
        projectedIncomeSummarySecurityData: state.topten.projectedIncomeSummarySecurityData,
        projectedIncomeSummaryAccountData: state.topten.projectedIncomeSummaryAccountData,
        projectedIncomeSummarytoggleview: state.topten.projectedIncomeSummarytoggleview,
        projectedIncomeYear: state.topten.projectedIncomeYear,
        role: state.acct.role,
        preference: state.acct.preferences
    };
};

const mapDispatchToProps = {
    AddProjectedIncomeSummaryAccount,
    AddProjectedIncomeSummarySecurity,
    AddProjectedIncomeSummaryChart,
    UpdateProjectedIncomeSummaryToggleView,
    ChangeYearProjectedIncome,
    RefreshProjectedIncomeAccountSecurity
};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Estimated Income Summary View' })
)
export default securityholdingdetailscomp(ProjectedIncomeSummary);