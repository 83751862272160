import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AccountHoldingdetails from './realizedaccountholdings/realizedaccountholding.jsx';
import SecurityHoldingdetails from './realizedsecurityholdings/realizedsecurityholding.jsx';
import { tabSelection, tabSelectionOnLoad } from './../../../../common/constants.jsx';
import { _logPageView } from '../../../../common/_logging';
import { withPageView } from '../../../../hoc/withPageView.jsx';
class RealizedGainlossDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);

        this.state = {
            tabsLinks:
                [
                    { tabUrl: '/RealizedAccountHoldingdetails', active: false, displayName: 'ACCOUNT', id:'RCM_RealizedAccountHoldingdetails' },
                    { tabUrl: '/RealizedSecurityHoldingdetails', active: true, displayName: 'SECURITY', id:'RCM_RealizedSecurityHoldingdetails' }
                ],
        };
    }
    handleSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }

    componentDidMount() {     
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/RealizedAccountHoldingdetails', '/RealizedGainLossDetails');
        this.setState({ tabsLinks: tabsLinks });
        //Put Page view
       /*  let pageViewData = new PageView();
        pageViewData.name = "Security Holdings";
        pageViewData.url = "/Toptendetail";
        _logPageView(pageViewData); */
    }
    render() {
        const realizedgainlossdetailsview = (
            <div>
                <div key="dvholdingdetailsview" className="row" >
                    <div className="col-sm-12" key="lbholdingdetailviewby">
                        <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding" >

                            <ul className="nav nav-tabs">
                                {this.state.tabsLinks && this.state.tabsLinks.map((tabLink) => {
                                    return <li className="nav-item active" id={tabLink.id} >
                                        <NavLink className="nav-link" exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                            onClick={this.handleSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                                    </li>
                                })}
                            </ul>
                        </div>

                        <div>
                            <Switch>
                                <Route key='root' exact path="/RealizedGainLossDetails" render={() => <Redirect to="/RealizedAccountHoldingdetails" />} />
                                <Route key='realizedaccountholdings' exact path="/RealizedAccountHoldingdetails" component={AccountHoldingdetails} />
                                <Route key='realizedsecurityholdings' exact path='/RealizedSecurityHoldingdetails' component={SecurityHoldingdetails} />
                            </Switch>
                        </div>

                    </div>
                </div>


            </div>);
        return (
            <BrowserRouter>{realizedgainlossdetailsview}</ BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        acct: state.acct,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};

const enhancement = compose(
    withPageView({pageName: 'Security Holdings'})
)
export default enhancement(withRouter(connect(mapStateToProps)(RealizedGainlossDetails)));