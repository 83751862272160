import React, { memo } from "react";

const Breadcrum = ({ breadcrums, onBreadCrumClick }) => {
  return (
    <>
      {breadcrums &&
        Array.isArray(breadcrums) &&
        breadcrums.length > 0 &&
        breadcrums.map((breadcrum, index) => {
          return (
            <div
              key={index}
              className="RCM_Teamwork_link RCM_Teamwork_DriveItem_Center"
              style={{ color: "inherit" }}
              onClick={() => onBreadCrumClick(breadcrum, index)}
            >{`${breadcrum.name} >`}</div>
          );
        })}
    </>
  );
};

export default memo(Breadcrum);
