import React, { memo } from "react";

const GetExtn = (dd) => {
  let retvalue = "folder";
  if (dd && dd.name != null) {
    let str1 = dd.name.split(".");
    if (str1.length > 1) {
      retvalue = str1[1];
      if (retvalue === "txt") {
        retvalue = "txt";
      } else if (retvalue === "docx") {
        retvalue = "word";
      } else if (retvalue === "xlsx") {
        retvalue = "excel";
      } else if (retvalue === "pdf") {
        retvalue = "pdf";
      } else {
        retvalue = "unknown";
      }
    }
  }

  return retvalue;
};

const DriveItemIcon = ({ drive }) => {
  const extension = GetExtn(drive);
  return (
    <div
      className={`${
        drive && drive.folder ? `RCM_icon_hand` : ""
      } RCM_Collabaration_${extension}`}
    ></div>
  );
};

export default memo(DriveItemIcon);
