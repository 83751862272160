import { Array } from 'core-js';
import { errorMsg, manageGroupsURL, updateNickNameURL, sortGroupAccountURL} from "../../../common/constants.jsx";
//import {  } from "../../common/constants.jsx";
import { Get, Post } from "../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../common/_logging";

export var getGroupListCancellationToken = null;
export var addGroupCancellationToken = null;
export var deleteGroupCancellationToken = null;
export var editGroupCancellationToken = null;
export var addDeleteAccountCancellationToken = null;
export var updateNickNameCancellationToken = null;
export var sortGroupCancellationToken = null;

export const getGroupList = (token, contextJson, profileJson, profileId) => {

    //Check if cancellation token has value;
    if (getGroupListCancellationToken !== null && getGroupListCancellationToken !== undefined) {
        //Cancel the existing call
        getGroupListCancellationToken.cancel('Cancelling the existing group list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getGroupListCancellationToken = cancelToken.source();

    let getGroupListPromise = new Promise(
        function (resolve, reject) {
            let groupList = [];
           
            let groupListUrl = `${manageGroupsURL}('${profileId}')`;
            Get(groupListUrl, token, contextJson, profileJson, getGroupListCancellationToken).then(res => {
                
                //get the valid response 
                console.log("Manage Group Service : Group List::", res);

                if (res &&  res.data && res.data.value !== null
                    && res.data.value != undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length >= 0) {
                    let response = res.data.value;
                    resolve(response);  
                } else {
                    resolve([]);
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log("Manage Group Service :: getGroupList ", error);
                    _logException("Manage Group Service :: getGroupList " + (error && error.response) ? error.response : "");
                    reject(errorMsg);
                }
            });
        });
    return getGroupListPromise;
}

export const addGroupService = (groupDetails, token, contextJson, profileJson, profileId) => {

    //Check if cancellation token has value;
    if (addGroupCancellationToken !== null && addGroupCancellationToken !== undefined) {
        //Cancel the existing call
        addGroupCancellationToken.cancel('Cancelling the add group request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addGroupCancellationToken = cancelToken.source();

    let addGroupPromise = new Promise(
        function (resolve, reject) {
            let addGroupurl = manageGroupsURL;
            Post(addGroupurl, groupDetails, token, contextJson, profileJson, addGroupCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Group Service : Add Group ::", res);
                    //get the valid response 
                        getGroupList(token, contextJson, profileJson, profileId).then(res => {
                            console.log(res);
                            resolve(res);
                        }).catch(error => {
                            if (axios.isCancel(error)) {
                                console.log(error);
                            } else {
                                //Log the error on console incase of failure. 
                                console.log("Manage Group Service : Get Group List:: ", error);
                                _logException("Manage Group Service : Get Group List :: " + (error && error.response) ? error.response : "");
                                reject(errorMsg);
                            }
                        })
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Group Service : Add Group :: ", error);
                        _logException("Manage Group Service : Add Group :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return addGroupPromise;
}

export const deleteGroupService = (token, contextJson, profileJson, profileId, requestBody) => {

    //Check if cancellation token has value;
    if (deleteGroupCancellationToken !== null && deleteGroupCancellationToken !== undefined) {
        //Cancel the existing call
        deleteGroupCancellationToken.cancel('Cancelling the existing delete group request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    deleteGroupCancellationToken = cancelToken.source();

    let deleteGroupPromise = new Promise(
        function (resolve, reject) {
            
            let deleteGroupUrl = manageGroupsURL;
            Post(deleteGroupUrl, requestBody, token, contextJson, profileJson, deleteGroupCancellationToken).then(res => {
                console.log("Manage Group Service : Delete Group ::", res);
                //get the valid response 
                getGroupList(token, contextJson, profileJson, profileId).then(res => {
                    console.log(res);
                    resolve(res);
                }).catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Group Service : Get Group List:: ", error);
                        _logException("Manage Group Service : Get Group List :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                })

            })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Manage Group Service :: deleteGroup ", error);
                        _logException("Manage Group Service :: deleteGroup " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });

    return deleteGroupPromise;
}

export const editGroupService = (token, contextJson, profileJson, profileId, requestBody) => {

    //Check if cancellation token has value;
    if (editGroupCancellationToken !== null && editGroupCancellationToken !== undefined) {
        //Cancel the existing call
        editGroupCancellationToken.cancel('Cancelling the existing edit group request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    editGroupCancellationToken = cancelToken.source();

    let editGroupPromise = new Promise(
        function (resolve, reject) {

            let editGroupUrl = manageGroupsURL;
            Post(editGroupUrl, requestBody, token, contextJson, profileJson, editGroupCancellationToken).then(res => {
                console.log("Manage Group Service : edit Group ::", res);
                //get the valid response 
                getGroupList(token, contextJson, profileJson, profileId).then(res => {
                    console.log(res);
                    resolve(res);
                }).catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Group Service : get group:: ", error);
                        _logException("Manage Group Service : get group :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                })

            })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Manage Group Service :: edit group ", error);
                        _logException("Manage Group Service :: edit group" + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });

    return editGroupPromise;
}

export const addDeleteAccountService = (token, contextJson, profileJson, profileId, requestBody) => {

    //Check if cancellation token has value;
    if (addDeleteAccountCancellationToken !== null && addDeleteAccountCancellationToken !== undefined) {
        //Cancel the existing call
        addDeleteAccountCancellationToken.cancel('Cancelling the existing delete group request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addDeleteAccountCancellationToken = cancelToken.source();

    let addDeleteAccountPromise = new Promise(
        function (resolve, reject) {

            let addDeleteAccountUrl = manageGroupsURL;
            Post(addDeleteAccountUrl, requestBody, token, contextJson, profileJson, addDeleteAccountCancellationToken).then(res => {
                console.log("Manage Group Service : Add Group ::", res);
                //get the valid response 
                getGroupList(token, contextJson, profileJson, profileId).then(res => {
                    console.log(res);
                    resolve(res);
                }).catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Group Service : Get Group List:: ", error);
                        _logException("Manage Group Service : Get Group List :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                })

            })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Manage Group Service :: addDeleteAccountService ", error);
                        _logException("Manage Group Service :: addDeleteAccountService " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });

    return addDeleteAccountPromise;
}

export const updateNickNameService = (token, contextJson, profileJson, requestBody) => {

    //Check if cancellation token has value;
    if (updateNickNameCancellationToken !== null && updateNickNameCancellationToken !== undefined) {
        //Cancel the existing call
        updateNickNameCancellationToken.cancel('Cancelling the existing update nick name request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    updateNickNameCancellationToken = cancelToken.source();

    let updateNickNamePromise = new Promise(
        function (resolve, reject) {
            Post(updateNickNameURL, requestBody, token, contextJson, profileJson, updateNickNameCancellationToken).then(res => {
                console.log("Manage Group Service : Update Nick Name::", res);
                //get the valid response 
                resolve();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log("Manage Group Service :: updateNickName ", error);
                    _logException("Manage Group Service :: updateNickName " + (error && error.response) ? error.response : "");
                    reject(errorMsg);
                }
            });
        });

    return updateNickNamePromise;

}

export const sortGroup = (token, contextJson, profileJson, requestBody) => {

    //Check if cancellation token has value;
    if (sortGroupCancellationToken !== null && sortGroupCancellationToken !== undefined) {
        //Cancel the existing call
        sortGroupCancellationToken.cancel('Cancelling the existing group sort request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    sortGroupCancellationToken = cancelToken.source();

    let sortGroupPromise = new Promise(
        function (resolve, reject) {
            Post(sortGroupAccountURL, requestBody, token, contextJson, profileJson, sortGroupCancellationToken).then(res => {
                console.log("Manage Group Service : Sort Group::", res);
                //get the valid response 
                resolve();
            })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Manage Group Service :: Sort Group", error);
                        _logException("Manage Group Service :: Sort Group " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });

    return sortGroupPromise;

}