import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Please do not change the order we need these ployfills for Edge
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { disableConsoleLogs } from './content/common/_logging.js';
import {unregister} from "./registerServiceWorker.js";

export var relasevariables = null;

const isWindowTop = window === window.top;

let simulationRequest
try {
  simulationRequest = JSON.parse(
    sessionStorage.getItem('simulation') || 'null'
  );
} catch(e) {
  sessionStorage.clear()
}

if (!simulationRequest && isWindowTop && window.location.search) {
  try {
    // TODO - this doesn't work in IE
    const params = new URLSearchParams(
      window.location.search.substr(1, window.location.search.length)
    );

    const isSimulation = params.get('simulate') === 'true';

    if (isSimulation) {
      simulationRequest = {
          simulationUser: params.get('simulationUser'),
          loginId: params.get('loginId'),
          pid: params.get('pid'),
          simulationAccount: params.get('sAcc')
      };

      sessionStorage.setItem('simulation', JSON.stringify(simulationRequest));
    }
  } catch (e) {
    // do nothing
  }
}


//fetch the public file 
var headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');

var fetchConfig = {
  method: 'GET',
  headers
};

//fetch the public file 
  let url = './releasevariables.json';
  fetch(url, fetchConfig)
  .then(res => res.json())
  .then(variables => {
    relasevariables = variables;
    console.log('Release Variables', relasevariables);
    if(relasevariables && relasevariables.consolelog === "false"){
      //this function will disable the logs in non development environment
      //including dev server.
      disableConsoleLogs();
    }

    //call getConfig fro index so we will always have environment variable avaliable.
    fetch("/Config/GetLoginConfig")
    .then(res => res.json())
    .then(config => {
      ReactDOM.render(
        <App key="mainapp" simulationRequest={simulationRequest} config={config} />,
        document.getElementById('root')
      );
    })
    .catch(err => {
      console.error('Failed to load environment variables', err);
      alert("We are expirencing some difficulty to load our application, please try after sometime.");
    })
  })
  .catch(err => {
    console.error('failed to pull release variables', err);
    alert("We are expirencing some difficulty to load our application, please try after sometime.");
  })


//registerServiceWorker();
unregister();
