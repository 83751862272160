import { GET_PERSONALISATION_DATA, TOGGLE_COMPONENT,SET_OTP_EXPIRY, SET_DASHBOARD_PREFERENCE } from  "../actions/actiontypes";

export function PersonalisationReducer(state = {}, action)
{
    switch(action.type)
    {
        case GET_PERSONALISATION_DATA:
        {
            return{
                ...state,
                personalisationData : action.payload
            }
        }
        case TOGGLE_COMPONENT:
        {
            return{
                ...state,
                toggleComponent: action.payload
            }
        }
        case SET_DASHBOARD_PREFERENCE:
            {
                return {
                    ...state,
                    dashboardPreference: action.payload
                }
            }
        case SET_OTP_EXPIRY:
            {
                return {
                    ...state,
                    otpToken: action.payload.token,
                    otpExpiry: action.payload.expiry
                }
            }
        default :
        return state;
    }
}