import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as Highcharts from "highcharts";
import { useHistory } from "react-router-dom";

const ToptenHoldingsHighChart = ({ data }) => {
  const history = useHistory();
  const chartContainer = useRef();
  const colors = useMemo(
    () => [
      "#13A5B0",
      "#B3E3F4",
      "#7BC46C",
      "#C4E1B2",
      "#B71F3A",
      "#EF4264",
      "#653C93",
      "#B02C91",
      "#F89938",
      "#F4B93F",
      "#45bda9",
    ],
    []
  );
  const seriesData = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x, i) => ({
        name: x.symbol,
        y: x.todaytotalpct,
        color: colors[i],
      }));
    } else {
      return [];
    }
  }, [colors, data]);

  const categories = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x) => x.symbol);
    } else {
      return [];
    }
  }, [data]);

  const handleToptenBarClicked = useCallback(
    (event) => {
      if (event && event.point && event.point.options) {
        const { name } = event.point.options;
        if (name !== "Other") {
          history.push("/SecurityHoldingdetails", { secid: name });
        }
      }
    },
    [history]
  );

  useEffect(() => {
    Highcharts.chart(chartContainer.current, {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        style: { height: "25rem" },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [...categories],
        crosshair: true,
        type: "category",
        labels: {
          formatter: function () {
            return `${
              this.value?.length > 9
                ? `${this.value.substring(0, 9)}...`
                : this.value
            }`;
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "% of portfolio",
        },
        labels: {
          format: "{value}%",
        },
      },
      plotOptions: {
        series: {
          cursor: "pointer",
        },
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          groupPadding: 0,
          shadow: false,
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: "#000",
        style: {
          color: "#fff",
          fontFamily: "Avenir",
        },
        formatter: function () {
          return `${
            this.point.name?.length > 9
              ? `${this.point.name.substring(0, 9)}...`
              : this.point.name
          }<br/><b>${Highcharts.numberFormat(this.point.y, 2)}%</b>`;
        },
        outside: false,
      },
      series: [
        {
          data: seriesData,
          events: {
            click: handleToptenBarClicked,
          },
        },
      ],
    });
  }, [categories, handleToptenBarClicked, seriesData]);

  return <div ref={chartContainer}></div>;
};

export default React.memo(ToptenHoldingsHighChart, (prevProps, nextProps) => {
  return JSON.stringify(prevProps || {}) === JSON.stringify(nextProps || {})
    ? true
    : false;
});
