
import { createStore, combineReducers } from 'redux';

import { AccountsReducer } from './../reducers/accountsreducer';
import { MoneyMovementReducer } from './../reducers/moneymovementreducer';
import { ManageExternalAccountReducer } from './../reducers/manageexternalacctreducer';
import { PersonalisationReducer } from './../reducers/personalisationreducer'
import { DocumentReducer } from './../reducers/documentreducer'
import { TopTenReducer } from './../reducers/toptenreducer'
import { EnvironmentReducer } from '../reducers/environmentreducer';


function configureStore(config) {
    return createStore(rootReducer, {
        acct: {
            accounts: [],
            selectedaccount: [],
            externalAccounts: [],
            activitysummary: null,
            activitydetails: null,
            assetallocationsummary: null,
            balancedetails: null,
            balancessummary: null,
            assetallocationdetails: null,
            accountOverviewFlag: null,
            render: true,
            isAccountContextChanged: false,
            groupedAccounts: [],
            aggregatedAcctModifiedList: {
                isExternalAccountAdded: false,
                modifiedType: "",
                modifiedAccounts: []
            },
            subscribeAlerts: [],
            isphilanthropy: null,
            alertList: null,
            manualAssetList: null,
            aggregatedAccountList:null,
           
            assetSchema: "Asset Allocation",
           
            assetschemacolorsconfig: null,
            perfromanceAccounts: null,
            perfromanceSummaryDetails: null
        },
        doc: {
            accountStatementsList: null,
            filteredAccountStatementsList: null,
            correspondenceDocumentList: null,
            filteredCorrespondenceDocumentList: null,
            correspondenceDocStartDate: null,
            correspondenceDocEndDate: null,
            taxDocumentList: null,
            filteredTaxDocumentList: null,
            tradeConfirmDocumentList: null,
            tradeConfirmSummaryDocumentList: null,
            isSearchByTradeConfirmSummary: false,
            filteredTradeConfirmSummaryDocumentList: null,
            filteredTradeConfirmDocumentList: null,
        },
        topten: {
            toptensummary: null,
            holdingsbyaccount: null,
            holdingsbysecurity: null,
            holdingsbycategory:null,
            accountOverviewFlag: false,
            realizedgainlossbyaccount: null,
            realizedgainlossbysecurity: null,
            estimatedincomebyaccount: null,
            estimatedincomebysecurity: null,
            estimatedIncomeSummaryChartData:null,
            estimatedIncomeSummarySecurityData:null,
            estimatedIncomeSummaryAccountData: null,
            estimatedIncomeSummarytoggleview: 'C',
            projectedIncomeSummarytoggleview: 'C',
            projectedIncomeSummarySecurityData: null,
            projectedIncomeSummaryAccountData: null,
            projectedIncomeSummaryChartData: null,
            projectedincomebyaccount: null,
            projectedincomebysecurity: null,
            estimatedIncomeYear: null,
            projectedIncomeYear: null
           
        },
        env: {...config}
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}

export default configureStore;

const rootReducer = combineReducers({
    env: EnvironmentReducer,
    acct: AccountsReducer,
    moneymovement: MoneyMovementReducer,
    manageextacct: ManageExternalAccountReducer,
    personalisationData: PersonalisationReducer,
    doc: DocumentReducer,
    topten: TopTenReducer
});
