import gsap from "gsap"

export const animateFinancialInstitutionList = (showFiAsList) => {

    //Do something before animation start

    gsap.to('#toogleGridAndList',  {duration: 0, css: {disabled: true}});

    if(showFiAsList){
        //Animate filist
       let tween = gsap.from('.RCM_Aggr_Fi_As_list', {duration: 0.8, opacity: 0, stagger: 0.05, onComplete: animateFinancialInstitutionListComplete});
        setTimeout(() => {
            tween.totalProgress(1);
        }, 1500);
    }else{
        //Animate filist
        let tween = gsap.from('.RCM_Aggr_Fi', {duration: 0.8, opacity: 0, stagger: 0.05, onComplete: animateFinancialInstitutionListComplete});
        setTimeout(() => {
            tween.totalProgress(1);
        }, 1500);
    }
}


const animateFinancialInstitutionListComplete = () => {
    // Reenable toggle button
    gsap.to('#toogleGridAndList',  {duration: 0, css: {disabled: false}});
}
