import React, { Component } from 'react'
import ElderlyCareView from './elderlyCareView';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { CheckIfProspectClient, elderlyCareUrl } from '../../../common/constants';
import OverlayProcessing from '../../MoneyMovement/common/overlayProcessing';
import { _logInformation } from '../../../common/_logging';

class ElderlyCare extends Component {


    constructor(props) {
        super(props);
        this.state = {
            confirmationModalState: false,
            showOverlay: false,
            selectedSection: {
                url: '',
                headerData: '',
                contentData: ''
            },
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.confirmationModalToggle = this.confirmationModalToggle.bind(this);
        this.openElderlyCare = this.openElderlyCare.bind(this);
    }
    confirmationModalToggle() {
        this.setState(prevState => ({ confirmationModalState: !prevState.confirmationModalState }));
    }
    openElderlyCare() {
        _logInformation('Elder Care Clicked');
        const selectedSection = {
            url: elderlyCareUrl,
            headerData: 'Elder Care provided by Homethrive',
            contentData: 'By clicking on the \"Continue\" button below, you will be transferred to a third party website which is owned, operated and maintained by Homethrive. If you have any questions, please contact your Financial Advisor.'
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    
    openSelectedSectionWindow = () => {
        _logInformation(this.state.selectedSection.url);
        window.open(this.state.selectedSection.url);
        const selectedSection = {
            url: '',
            headerData: '',
            contentData: ''
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    render() {
        return (
            <>
                <ElderlyCareView
                    confirmationModalState={this.state.confirmationModalState}
                    confirmationModalToggle={this.confirmationModalToggle}
                    openElderlyCare={this.openElderlyCare}
                    openSelectedSectionWindow={this.openSelectedSectionWindow}
                    selectedSection={this.state.selectedSection}
                />
                {this.state.showOverlay && (<OverlayProcessing />)}
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
       
    };
}

const mapDispatchToProps = {

}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'ElderlyCare' })
)

export default enhancement(ElderlyCare);
