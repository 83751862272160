import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Collapse, Button } from 'reactstrap';
import { MsgForNoData, getAccountToolTip, IndLongShort } from './constants';

export function SelectWithCheckboxView(
    props
) {
    let selectwithcheckboxview = (
        <div>
            {props && props.state ?
                < Dropdown key="Dropdown1" isOpen={props.state.dropdownOpen} direction='down'
                    toggle={props.toggle}
                    className="RCM_select_box RCM_headre_select_box" >
                    <DropdownToggle caret key="Dropdown2">
                        <div key="Nav21" className=""></div> {props.state.dropDownValue}
                    </DropdownToggle>
                    <DropdownMenu key="Dropdown3">

                        {props && props.state && props.state.accounts && props.state.accounts.length !== 0 ?
                            <div>
                                <div className="RCM_selectBox_groupingContainer">
                                    <div></div><div onClick={props.clearAll.bind()} className="RCM_clear_all">Clear All</div>
                                </div>
                                <div className="RCM_CM_scroll RCM_selectBox_scrollArea">
                                    <ul key="Dropdown13" className="RCM_select_ul RCM_CM_scroll RCM_all_Accounts">
                                        <li className={"RCM_select_li " + (props.state.allAccount.isChecked ? 'RCM_li_selected' : '')}><label className="RCM_selectLabel"><input className="RCM_select_checkbox" type="checkbox" name="selectedAccounts" value={props.state.allAccount.value} checked={props.state.allAccount.isChecked}
                                            onChange={props.handleSelectChange.bind(props, props.state.allAccount.value)} key="allSelected" /><span className="RCM_accounts_span">{props.state.allAccount.value}</span></label></li>
                                    </ul>
                                    {props && props.state && props.state.accounts.map(accountList =>
                                        <div key="dvAccounts">
                                            <Button onClick={props.toggleAccordion.bind(props, accountList.fiId)} className="btn btn-primary RCM_button_primary RCM_accordian_btn">
                                                <label><input className="RCM_select_checkbox" type="checkbox" name="selectedAccounts" value={accountList.allAccount.value} checked={accountList.allAccount.isChecked}
                                                    onChange={props.handleSelectChange.bind(props, accountList.allAccount)} key="allSelected" />
                                                    <span className="RCM_accounts_span RCM_select_all"></span></label><span className="RCM_Bank_Name">{accountList.fiName}</span>
                                                {(accountList.isOpen ? <div className="RCM_arrow_down"></div> : <div className="RCM_arrow_right"></div>)}
                                            </Button>
                                            <Collapse isOpen={accountList.isOpen}>
                                                {(accountList.fiId === 'rockcoid' ?
                                                    <ul key="Dropdown13" className="RCM_select_ul RCM_CM_scroll rockcoid">
                                                        {props && props.state && accountList.accounts.map(account =>
                                                            <li key={account.number} className={"RCM_select_li " + (account.isChecked ? 'RCM_li_selected' : '')} title={getAccountToolTip(account, IndLongShort.LONG)}><label className="RCM_selectLabel"><input type="checkbox" className="RCM_select_checkbox" name="selectedAccounts" key={account.number} checked={account.isChecked}
                                                                value={account.number} onChange={props.handleSelectChange.bind(props, account)} />
                                                                <span className="RCM_accounts_span">{props.getDisplayName(account)}</span></label></li>
                                                        )}
                                                    </ul>
                                                    :
                                                    <ul key="Dropdown13" className="RCM_select_ul RCM_CM_scroll externalAccounts">
                                                        {props && props.state && accountList.accounts.map(account =>
                                                            <li key={account.key} className={"RCM_select_li " + (account.isChecked ? 'RCM_li_selected' : '')} title={getAccountToolTip(account, IndLongShort.LONG)}><label className="RCM_selectLabel"><input type="checkbox" className="RCM_select_checkbox" name="selectedAccounts" key={account.key} checked={account.isChecked}
                                                                value={account.key} onChange={props.handleSelectChange.bind(props, account)} />
                                                                <span className="RCM_accounts_span">{props.getDisplayName(account)}</span></label></li>
                                                        )}
                                                    </ul>
                                                )}
                                            </Collapse>
                                        </div>
                                    )}
                                </div>
                            </div>
                            : <div>{MsgForNoData}</div>}
                        <div className="RCM_select_btn_container">
                            <div key="Dropdown14" className="RCM_applyBtn_container">
                                <button key="Dropdown16" type="button" className="btn btn-primary RCM_button_primary" disabled={props.state.disableApplyFlag} onClick={props.apply}>APPLY</button>
                            </div>
                            <div key="Dropdown17">
                                <button key="Dropdown15" type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.cancel}>CANCEL</button>

                            </div>
                        </div>
                    </DropdownMenu>
                </Dropdown >
                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return selectwithcheckboxview
}



