import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Collapse, Button } from 'reactstrap';
import { MsgForNoData, getAccountToolTip, IndLongShort } from './constants';
import ToggleButton from 'react-toggle-button';
import RCMToggleButton from './togglebutton.jsx';
export function AccountSelectorWithGroupView(
    props
) {
    const thumbStyle = { width: 16, height: 16 }
    const trackStyle = { width: 100, height: 16 }
    let accountselectorwithgroupview = (
        <div>
            {props && props.state ?
                < Dropdown key="ASDropdown1" isOpen={props.state.dropdownOpen} direction='down'
                    toggle={props.toggle}
                    className="RCM_select_box RCM_headre_select_box" >
                    <DropdownToggle caret key="ASDropdown2">
                        <div key="ASDropdown3" className=""></div>
                        {props.state.groupToggleValue
                            ? props.state.dropDownValue
                            : (props.state.selectedGroupName === "" || props.state.selectedGroupName === "Not Grouped")
                                ? props.state.dropDownValue
                                : props.state.selectedGroupName}
                    </DropdownToggle>
                    <DropdownMenu key="ASDropdown4">

                        {props && props.state && props.state.accounts && props.state.accounts.length !== 0 ?
                            <div key="ASDropdown5">
                                <div className="RCM_selectBox_groupingContainer" key="ASDropdown6">
                                    <div key="ASDropdown7" className="RCM_selectBox_smalltoggle">
                                    {/*<ToggleButton key="ASDropdown8"

                                        inactiveLabel="Institutions&nbsp;&nbsp;"
                                        activeLabel="&nbsp;&nbsp;My Groups"
                                        value={props.state.groupToggleValue}
                                        onToggle={() => props.toggleGroupView()}
                                        colors={{
                                            inactive: {
                                                base: '#16a5b0',
                                            },
                                            active: {
                                                base: '#16a5b0',
                                            },
                                        }}
                                        thumbAnimateRange={[0, 83]}
                                        thumbStyle={thumbStyle}
                                        trackStyle={trackStyle}
                                        activeLabelStyle={{
                                            color: '#fff',
                                            height: '10px',
                                            width: '50px'
                                        }}
                                        inactiveLabelStyle={{
                                            color: '#fff',
                                            height: '10px',
                                            width: '50px'
                                        }}
                                        />*/}
                                        <RCMToggleButton
                                            selectedOption={props.state.groupToggleValue ? "Institutions" : "My Groups"}
                                            leftOption="Institutions"
                                            rightOption="My Groups"
                                            onToggle={() => props.toggleGroupView()}
                                        />
                                    </div>
                                    <div onClick={props.clearAll.bind()} className="RCM_clear_all" key="ASDropdown9">Clear All</div>
                                </div>

                                <div className="RCM_CM_scroll RCM_selectBox_scrollArea" key="ASDropdown10">
                                    <ul key="ASDropdown11" className="RCM_select_ul RCM_CM_scroll RCM_all_Accounts">
                                        <li className={"RCM_select_li " + (props.state.allAccount.isChecked ? 'RCM_li_selected' : '')}><label className="RCM_selectLabel"><input className="RCM_select_checkbox" type="checkbox" name="selectedAccounts" value={props.state.allAccount.value} checked={props.state.allAccount.isChecked}
                                            onChange={props.handleSelectChange.bind(props, props.state.allAccount.value)} key="ASallSelected" /><span className="RCM_accounts_span">{props.state.allAccount.value}</span></label></li>
                                    </ul>
                                    {props && props.state && props.state.accounts !== null && props.state.accounts.map(accountList =>
                                        (accountList.accounts === null || accountList.accounts.length === 0) && accountList.groupid === "-1" ? <div></div>
                                            :
                                            <div key="dvAccounts" key={"ASDropdown12_" + accountList.groupid}>
                                                <Button key={"ASDropdownbtn_" + accountList.groupid} onClick={props.toggleAccordion.bind(props, accountList.groupid)}
                                                    className={"btn btn-primary RCM_button_primary RCM_accordian_btn " + (accountList.accounts === null || accountList.accounts.length === 0 ? 'RCM_accordian_btn_noAccount' : '')} >
                                                    {accountList.accounts !== null && accountList.accounts.length !== 0 ? <label key={"ASDropdownlabel_" + accountList.groupid}><input key={"ASDropdowninput_" + accountList.groupid} className="RCM_select_checkbox" type="checkbox" name="selectedAccounts" value={accountList.allAccount.value} checked={accountList.allAccount.isChecked}
                                                        onChange={props.handleSelectChange.bind(props, accountList.allAccount)} key="allSelected" />
                                                        <span className="RCM_accounts_span RCM_select_all" key={"ASDropdownspan_" + accountList.groupid}></span></label> : <label className="RCM_no_account_label" key={"ASDropdownlabel_" + accountList.groupid}></label>}
                                                    <span key={"ASDropdownspan_" + accountList.groupid} className="RCM_Bank_Name">{accountList.groupname}</span>
                                                    {(accountList.isOpen ? <div className="RCM_arrow_down" key={"ASDropdownDownArrow_" + accountList.groupid}></div> : <div className="RCM_arrow_right" key={"ASDropdownRightArrow_" + accountList.groupid}></div>)}
                                                </Button>
                                                <Collapse isOpen={accountList.isOpen} key={"ASDropdownCollapse_" + accountList.groupid}>
                                                    {(accountList.groupid === 'rockcoid' ?
                                                        <ul key={"ASDropdownUL_" + accountList.groupid} className="RCM_select_ul RCM_CM_scroll rockcoid">
                                                            {accountList.accounts !== null && accountList.accounts.map(account =>
                                                                <li key={"ASDropdown_" + accountList.groupid + account.key} className={"RCM_select_li " + (account.isChecked ? 'RCM_li_selected' : '')} title={getAccountToolTip(account, IndLongShort.LONG)}>
                                                                    <label key={"ASDropdownlabel_" + accountList.groupid + account.key} className="RCM_selectLabel"><input key={"ASDropdowninput_" + accountList.groupid + account.key} type="checkbox" className="RCM_select_checkbox" name="selectedAccounts" key={account.number} checked={account.isChecked}
                                                                    value={account.key} onChange={props.handleSelectChange.bind(props, accountList)} />
                                                                    <span key={"ASDropdown_span" + accountList.groupid + account.key} className="RCM_accounts_span">{props.getDisplayName(account)}</span></label></li>

                                                            )}
                                                        </ul>
                                                        :
                                                        props && props.state && accountList.accounts !== null && accountList.accounts.length !== 0 ?
                                                            <ul key={"ASDropdownUl_" + accountList.groupid} className="RCM_select_ul RCM_CM_scroll externalAccounts">
                                                                {accountList.accounts.map(account =>
                                                                    <li key={account.key} className={"RCM_select_li " + (account.isChecked ? 'RCM_li_selected' : '')} title={getAccountToolTip(account, IndLongShort.LONG)}>
                                                                        <label className="RCM_selectLabel" key={"ASDropdownlabel_" + account.key}>
                                                                            <input type="checkbox" className="RCM_select_checkbox" name="selectedAccounts" key={"ASDropdowninput_" + account.key} checked={account.isChecked}
                                                                        value={account.key} onChange={props.handleSelectChange.bind(props, accountList)} />
                                                                            <span key={"ASDropdownspan_" + account.key} className="RCM_accounts_span">{props.getDisplayName(account)}</span></label></li>
                                                                )}
                                                            </ul> :
                                                            <div key="ASDropdown15" className="RCM_select_no_account_msg">No accounts.</div>

                                                    )}
                                                </Collapse>
                                            </div>
                                    )}
                                </div>
                            </div>
                            : <div key="ASDropdown16">{MsgForNoData}</div>}
                        <div className="RCM_select_btn_container" key="ASDropdown17">
                            {/*<div className="RCM_applyBtn_container" key="ASDropdown18">*/}
                                <button key="ASDropdown19" type="button" className="btn btn-primary RCM_button_primary RCM_AS_Buttons" disabled={props.state.disableApplyFlag} onClick={props.apply}>APPLY</button>
                            {/*</div>
                            <div key="ASDropdown20">*/}
                            <button key="ASDropdown21" type="button" className="btn btn-secondary RCM_button_secondary RCM_AS_Buttons" onClick={props.cancel}>CANCEL</button>

                            {/*</div>*/}
                        </div>
                    </DropdownMenu>
                </Dropdown >
                : <div key="ASDropdown22">{MsgForNoData}</div>
            }
        </div>
    )
    return accountselectorwithgroupview
}



