import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { HoldingsbycategoryURL, MsgForNoData, CheckIfProspectClient, GetPreferencePilotValue, assetSchemaColorsConfigUrl } from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddHoldingsByAsset } from '../../../../../actions/toptenactions.jsx';
import { UpdateAssetSchemaAssetDetails, UpdateAssetSchemaAssetSummary, UpdateHistoricalAssetSchema, AddAssetColor } from '../../../../../actions/accountsactions.jsx';
import {  assetsecuritychildColumns, assetsecuritycolumns, renderRowsByAsset, renderRowsByAssetSecurity, renderRowsByAssetSecurityAccount } from '../holdingdetailscolumns.jsx';
import TASHoldingdetails from '../tasholding.jsx';
import axios from "axios";
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { categoryholdingdetailsDummyResponse, RRR_123456_categoryholdingdetailsDummyResponse, RRR_234567_categoryholdingdetailsDummyResponse, assetAllocationColorRes } from '../../../accountsmockresponse.js';
import Watermark from '../../../../../common/watermark.jsx';
import { AssetHoldingsView } from './assetholdingview.jsx';
import { getSchemaValue, assetClassDropDownItems } from '../../../../../common/assetschemautilities';

class AssetHoldingdetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            requestFailed: false,
            holdingsbycategory: this.props.holdingsbycategory,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            expanded: [],
			isrowexpanded: false,
			ischildrowexpanded:false,
			expanded: [],
			expandedChild:[],
            childColumnToSort: "",
            childColumnSortOrder: "",
            isLoadingAssetColor: false,
            requestFailedAssetColor: false,
            classDropdownOpen: false,
            dropdownClassValue: this.props.assetSchema,
            isProspectClient:CheckIfProspectClient(this.props.role),
            balSummaryV2PilotFlag: this.setBalanceSummaryV2Flag(),
            showAssetClassOption: this.assetClassViewPreference(this.props),
            viewYield: this.setViewYieldFlag(),
            assetcolorRes: this.props.assetschemacolorsconfig
        }
        this.execute = this.execute.bind(this);
        this.collapserow = this.collapserow.bind(this);
        this.toggle = this.toggle.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
		this.handleOnExpandLevel2 = this.handleOnExpandLevel2.bind(this);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.changeClass = this.changeClass.bind(this);
       
       
    }
    setViewYieldFlag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
    }
    toggleClass() {
        this.setState(prevState => ({
            classDropdownOpen: !prevState.classDropdownOpen
        }));
    }
    changeClass(selectedClass) {
        //let class = this.classDropDownItems.find(item => item.label === selectedType.currentTarget.textContent.trim()).value;

        this.setState({
            dropdownClassValue: selectedClass.currentTarget.textContent.trim(),
            //classValue: class
        }, () => {
            this.execute();
                this.props.UpdateAssetSchemaAssetDetails(this.state.dropdownClassValue);
                this.props.UpdateAssetSchemaAssetSummary(this.state.dropdownClassValue);
                this.props.UpdateHistoricalAssetSchema(this.state.dropdownClassValue);
        });
    }
    assetClassViewPreference(preference) {
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'AssetSchemaV2', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }
   
    getAssetHoldingsCancellationToken = null;
    setBalanceSummaryV2Flag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'BalSummaryV2', 'false') === 'true' ? true : false;
    }
    gotobalance = () => {
        document.dispatchEvent(new CustomEvent('goToBalanceDetails', { bubbles: true }));
    }
    //method to execute api calls
    execute = () => {
       this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];

        if (this.getAssetHoldingsCancellationToken) {
            //Cancel the existing call
            this.getAssetHoldingsCancellationToken.cancel('Cancelling the existing Asset Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getAssetHoldingsCancellationToken = cancelToken.source();
        let baseUrlSecurity = HoldingsbycategoryURL + '\'\',\'\')';
        baseUrlSecurity = this.state.showAssetClassOption ? (HoldingsbycategoryURL + '\'' + "" + '\',' + null + ',' + (getSchemaValue(this.state.dropdownClassValue)) + ')') : baseUrlSecurity;
        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getAssetHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data != "" ? result.data : datarows;
                this.props.AddHoldingsByAsset(data);
                this.setState(() => ({ holdingsbycategory: data, isloading: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                }
            })
		
    };
    assetColorCancellationToken = null;
    executeAssetColor = () => {
        this.setState(() => ({ isLoadingAssetColor: true, requestFailedAssetColor: false }));

        let baseUrl = assetSchemaColorsConfigUrl;

        if (this.assetColorCancellationToken) {
            this.assetColorCancellationToken.cancel('Operation canceled due to new request.')
        }
        this.assetColorCancellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.assetColorCancellationToken)
            .then(result => {
                this.setState(() => ({ assetcolorRes: result.data, isLoadingAssetColor: false, requestFailedAssetColor: false }))
                this.props.AddAssetColor(result.data)
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailedAssetColor: true, error: error.message }))
                }
            })
    };
    componentDidMount() {
        //if props has data then take it or make api call
        //
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.holdingsbycategory === null) {
                this.execute();
            }
            else {

                this.setState({ isloading: false, requestFailed: false });
            }

            if (this.props.assetschemacolorsconfig === null) {
                this.props.AddAssetColor(assetAllocationColorRes)
                this.setState(() => ({
                    isLoadingAssetColor: false, requestFailedAssetColor: false, assetcolorRes: assetAllocationColorRes
                }))
            }
            else {
                this.setState({
                    isLoadingAssetColor: false, requestFailedAssetColor: false
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }

        }
    }
    componentWillUnmount() {
        if (this.getAssetHoldingsCancellationToken !== null && this.getAssetHoldingsCancellationToken !== undefined) {
            this.getAssetHoldingsCancellationToken.cancel('Cancelling the asset Holdings during unmount to prevent memory leak.');
        }
        if (this.assetColorCancellationToken !== null && this.assetColorCancellationToken !== undefined) {
            this.assetColorCancellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }
    collapserow(row) {
        this.setState(() => ({
            expanded: []
        }));
    }

    GetHeaderName() {
        if (this.state.rowdata) {
            let header = this.state.rowdata.SECNAME ? this.state.rowdata.SECNAME : ""
            header += this.state.rowdata.SECID ? (" - " + this.state.rowdata.SECID) : "";
            return header;
        }
        return "";
    }
    toggle(row) {
        this.setState(prevState => ({
            modal: !prevState.modal,

        }));
        this.state.rowdata = row;
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
				isrowexpanded: true,
                expanded: [...this.state.expanded, row.UNQID]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.UNQID),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
	}

	handleOnExpandLevel2 = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			this.setState(() => ({
				ischildrowexpanded: true,
				expandedChild: [...this.state.expandedChild, row.UNIQUEIDX]
			}));
		} else {
			this.setState(() => ({
				expandedChild: this.state.expandedChild.filter(x => x !== row.UNIQUEIDX),
				ischildrowexpanded: this.state.expandedChild.length > 1 ? true : false
			}));
		}
	}
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.holdingsbycategory === null) {
            if (this.props.selectedaccount.length == 1) {
				if (this.props.selectedaccount[0].key === "RRR-123456") {
					this.setState(() => ({ holdingsbycategory: RRR_123456_categoryholdingdetailsDummyResponse ? RRR_123456_categoryholdingdetailsDummyResponse : [], isloading: false, areDataRetrieved: true, expanded: ["Common Stock"], expandedChild: ["ZNGA"], isrowexpanded:true}), () => { this.props.AddHoldingsByAsset(RRR_123456_categoryholdingdetailsDummyResponse); });
                }
				else if (this.props.selectedaccount[0].key === "RRR-234567") {
					this.setState(() => ({ holdingsbycategory: RRR_234567_categoryholdingdetailsDummyResponse ? RRR_234567_categoryholdingdetailsDummyResponse : [], isloading: false, areDataRetrieved: true, expanded: ["Common Stock"], expandedChild: ["ZNGA"], isrowexpanded: true }), () => { this.props.AddHoldingsByAsset(RRR_234567_categoryholdingdetailsDummyResponse); });
                }
            } else if (this.props.selectedaccount.length == 2) {
				this.setState(() => ({ holdingsbycategory: categoryholdingdetailsDummyResponse ? categoryholdingdetailsDummyResponse : [], isloading: false, areDataRetrieved: true, expanded: ["Common Stock"], expandedChild: ["ZNGA"], isrowexpanded: true}), () => { this.props.AddHoldingsByAsset(categoryholdingdetailsDummyResponse); });
            }
        }
        else {
			this.setState({ isloading: false, dropdownValue: this.state.accounts[0].number, expanded: ["Common Stock"], expandedChild: ["ZNGA"], isrowexpanded: true, dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }

        if (this.props.assetschemacolorsconfig === null) {
            this.props.AddAssetColor(assetAllocationColorRes)
            this.setState(() => ({
                isLoadingAssetColor: false, requestFailedAssetColor: false, assetcolorRes: assetAllocationColorRes
            }))
        }
        else {
            this.setState({
                isLoadingAssetColor: false, requestFailedAssetColor: false
            });
        }
    }

   

    //render method
    render() {
        var assetData = [];
        var totalvalue;
        var totalglamount;
        var totalglpct;
        var totalcostbasis;
        var totaltodayschange;
        var totaltodayschangepct;
        var totalpct;
        
		if (this.state.holdingsbycategory ) {
            if (this.state.holdingsbycategory[0] && this.state.holdingsbycategory[0].value && this.state.holdingsbycategory[0].value.length > 0)
                assetData = renderRowsByAsset(this.state.holdingsbycategory[0].value, this.state.assetcolorRes, this.state.dropdownClassValue);
			totalvalue = this.state.holdingsbycategory[0].totalRcntVal;
            totalglamount = this.state.holdingsbycategory[0].unrealizedValue;
            totalglpct = this.state.holdingsbycategory[0].unrealizedPct;
            totalcostbasis = this.state.holdingsbycategory[0].costbasis;
			totalpct = this.state.holdingsbycategory[0].totalPct;
            if (this.state.holdingsbycategory[0].mktvals) {
				totaltodayschange = this.state.holdingsbycategory[0].mktvals.mktvalchange;
				totaltodayschangepct = this.state.holdingsbycategory[0].mktvals.mktvalchangepct;
				totalvalue = this.state.holdingsbycategory[0].mktvals.rcntmktval
            }
        }




        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
		};

		//const columns = 
		const expandRowBySecurity = {
			 renderer: (row) => ( 
				<BootstrapTable
					key="btsecchild2"
					keyField='UNIQID'
                    classes="RCM_two_level_table2 RCM_Headerless_table RCM_Headerless_table_secondChild"
                    columns={assetsecuritychildColumns(this.props.selectedaccount, headerColumnStyles, otherColumnStyles, smallColumnStyles, this.state.viewYield)}
					data={renderRowsByAssetSecurityAccount(row, this.props.selectedaccount, this.state.childColumnToSort, this.state.childColumnSortOrder)}
					noDataIndication={MsgForNoData}
					rowEvents={rowEvents}
					bordered={false}
				/>
			),
			showExpandColumn: true,
			expandColumnPosition: 'right',
			//expandByColumnOnly: true,
			expanded: this.state.expandedChild,
			onExpand: this.handleOnExpandLevel2,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return null;
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (<div className="RCM_icon_hand RCM_down_arrow">
					</div>);
				}
				return (<div className="RCM_icon_hand RCM_right_arrow">
				</div>);
			},
			
		}
        //expand row function for security table
        const expandRowByAsset = {
			renderer: (row) => (
				<BootstrapTable
					key={row.UNQID + " btsecchild1"}
					keyField='UNIQUEIDX'
					classes="RCM_two_level_table2 RCM_Headerless_table"
					data={renderRowsByAssetSecurity(row, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                    columns={assetsecuritycolumns(headerColumnStyles, otherColumnStyles, smallColumnStyles, this.state.viewYield)}
					noDataIndication={MsgForNoData}
					//rowEvents={rowEvents}
					bordered={false}
					expandRow={expandRowBySecurity}
                />
            ),
			showExpandColumn: true,
			expandColumnPosition: 'right',
            expanded: this.state.expanded,
			onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
		};
		const otherColumnStyles = { width: "10%" };
		const smallColumnStyles = { width: "8%" };
		const headerColumnStyles = { width: "11%" };
        return (

            <ErrorBoundary>
                <AssetHoldingsView
                    state={this.state}
                    assetData={assetData}
                    accounts={this.props.selectedaccount}
                    totalvalue={totalvalue}
                    totalglamount={totalglamount}
                    totalglpct={totalglpct}
                    totaltodayschange={totaltodayschange}
                    totaltodayschangepct={totaltodayschangepct}
                    totalcostbasis={totalcostbasis}
                    selectedAccount={this.props.selectedaccount}
                    expandRowByAsset={expandRowByAsset}
                    rowsortevent={this.rowsortevent}
                    totalassetpct={totalpct}
                    otherColumnStyles={otherColumnStyles}
                    headerColumnStyles={headerColumnStyles}
                    smallColumnStyles={smallColumnStyles}
                    gotobalance={this.gotobalance}
                    changeClass={this.changeClass}
                    toggleClass={this.toggleClass}
                    classDropDownItems={assetClassDropDownItems}
                    getAssetColorConfig={this.getAssetColorConfig}
					
                />
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="RCM_holding_details_modal_content"
                    zIndex='9999'
                    modalClassName="RCM_CM_modal_class"
                    centered key='modaltas1'>
                    <ModalHeader toggle={this.toggle} className="RCM_holding_details_modal" key='modaltas2'>
                        <label className="RCM_tile_heading" key='lblholdingheading'>{this.GetHeaderName()}</label>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.isProspectClient ? <Watermark /> : ''}
                        <TASHoldingdetails row={this.state.rowdata}></TASHoldingdetails>
                    </ModalBody>

                </Modal>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        holdingsbycategory: state.topten.holdingsbycategory,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        role: state.acct.role,
        preferences: state.acct.preferences,
        assetSchema: state.acct.assetSchema,
        assetschemacolorsconfig: state.acct.assetschemacolorsconfig
    };
};

const mapDispatchToProps = {
    AddHoldingsByAsset,
    UpdateAssetSchemaAssetDetails,
    UpdateAssetSchemaAssetSummary,
    AddAssetColor,
    UpdateHistoricalAssetSchema

};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Asset Holdings' })
)
export default securityholdingdetailscomp(AssetHoldingdetails);