import React from 'react';
import { formatMoney, roundvalue } from '../../../../common/constants.jsx';
export function getTopTenSummaryColumns(datarows) {
    const columns = [{
        dataField: 'name',
        text: "Symbol",
        sort: false,
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else return symbolFormatter(datarows,cell);
        }
    },
    {
        dataField: 'value',
        text: 'Value',
        sort: false,
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else if (cell < 0) return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            else if (cell > 0) return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            else return <span>$0</span>;
        }
    },
    {
        dataField: 'glvalue',
        text: "$ Gain/Loss",
        sort: false,
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else if (cell < 0) return <span className="RCM_negative">{formatMoney(roundvalue(cell, 2))}</span>;
            else if (cell > 0) return <span className="RCM_positive">{formatMoney(roundvalue(cell, 2))}</span>;
            else return <span>0</span>;
        }
    },
    {
        dataField: 'glpercent',
        text: "% Gain/Loss",
        sort: false,
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else if (cell < 0) return <span className="RCM_negative">{roundvalue(cell, 2)}%</span>;
            else if (cell > 0) return <span className="RCM_positive">{roundvalue(cell, 2)}%</span>;
            else return <span>0%</span>;
        }
    }]
    return columns;
}
function symbolFormatter(datarows, cell) {
    return (<div title={getSecurityDescription(datarows,cell)}><span>{cell}</span></div>)
}
function getSecurityDescription(datarows, cell) {
    var securitydescription = datarows.filter(dt => dt.name === cell)
    return securitydescription[0].description;
}