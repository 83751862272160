import React from 'react';

export function FADetailsView(
    props
) {
    let email = props.fadata && props.fadata.email;    
    let fadetailsview = (
        <div key="FDContainer" className="row RCM_FADetails_Div" >
            {
                props.showFADetails !== false
                    ? 
                    <div key="FDWrapper" className="col-xs-12 col-sm-12 col-md-12 col-lg-12" id='FADetails'>
                        <div key="FD1" className="row">
                            <label key="FD2" className="RCM_AdvisorTeam_Label">Your advisor team</label>
                            </div>
                        
                
                {
                            props.fadata && props.fadata.faname && props.fadata.website
                                ? <div key="FD3" className="row">
                                    <a href={props.fadata.website} target='_blank' className="RCM_Cursor_Pointer RCM_FAInfo" ><label key="FD4" className="RCM_FAName" style={{ 'cursor': 'pointer' }}>{props.fadata && props.fadata.faname}</label></a>
                                </div>
                                : props.fadata && props.fadata.faname ? <div key="FD3" className="row">
                                    <label key="FD4" className="RCM_FAName">{props.fadata && props.fadata.faname}</label>
                                 </div> : ''
                }
                {
                    props.fadata && props.fadata.contactnumber && props.fadata.contactnumber.length >= 1
                        ?
                        <div key="FD5" className="row">
                            <div key="FD6" className="col-xs-3">
                                <span key="FD7">
                                    <div key="FD8" className={"RCM_menu_img  RCM_FA_Phone"}></div>
                                </span>
                            </div>
                            <div key="FD9" className="col-xs-9">
                                {
                                    props.fadata && props.fadata.contactnumber && props.fadata.contactnumber.length > 1
                                        ? props.fadata && props.fadata.contactnumber.map((contact, index) => {
                                            if (index !== props.fadata.contactnumber.length - 1) {
                                                return (<a key={"FD10" + contact} className="RCM_Cursor_Pointer RCM_FAInfo" href={"tel:" + contact}>
                                                    {contact}/
                                        </a>)
                                            } else {
                                                return (<a key={"FD11" + contact} className="RCM_Cursor_Pointer RCM_FAInfo" href={"tel:" + contact}>
                                                    {contact}
                                                </a>)
                                            }

                                        })
                                        : props.fadata && props.fadata.contactnumber.map(contact => {
                                            return (<a key={"FD12" + contact} className="RCM_Cursor_Pointer RCM_FAInfo" href={"tel:" + contact}>
                                                {contact}
                                            </a>)
                                        })

                                }

                            </div>
                        </div>
                        : ''
                }
                {
                    email
                        ?
                        <div key="FD13" className="row">
                            <div key="FD14" className="col-xs-3">
                                <span key="FD15">
                                    <div key="FD16" className={"RCM_menu_img  RCM_FA_Email"}></div>
                                </span>
                            </div>
                            <div key="FD17" className="col-xs-9">
                                <a key="FD18" className="RCM_Cursor_Pointer RCM_FAInfo" href={"mailto:" + email + "?Subject=Online inquiry"} target="_top">
                                    {email}
                                </a>
                            </div>
                        </div>
                        : ''
                }

            </div>
            :''
            }
        </div>
    )
    return fadetailsview
}


