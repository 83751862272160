import { errorMsg, alertsURL } from "../../../../common/constants.jsx";
import { Post, Get } from "../../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../../common/_logging";

export var subscribeAlertsCancellationToken = null;
export var getAlertSubscribeSummaryCancellationToken = null;

export const subscribeAlertsService = (alertsDetails, token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (subscribeAlertsCancellationToken !== null && subscribeAlertsCancellationToken !== undefined) {
        //Cancel the existing call
        subscribeAlertsCancellationToken.cancel('Cancelling the subscribe Alerts request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    subscribeAlertsCancellationToken = cancelToken.source();

    let subscribeAlertsPromise = new Promise(
        function (resolve, reject) {
            let subscribeAlertsurl = alertsURL + "/AlertSubscribeSubmit";
            Post(subscribeAlertsurl, alertsDetails, token, contextJson, profileJson, subscribeAlertsCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Alerts service: subscribe Alerts ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Alerts service: subscribe Alerts :: ", error);
                        //_logException("Alerts service: subscribe Alerts :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return subscribeAlertsPromise;
}

export const getAlertSubscribeSummary = (token, contextJson, profileJson) => {
    //Check if cancellation token has value;
    if (getAlertSubscribeSummaryCancellationToken !== null && getAlertSubscribeSummaryCancellationToken !== undefined) {
        //Cancel the existing call
        getAlertSubscribeSummaryCancellationToken.cancel('Cancelling the get Alert Subscribe Summary request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAlertSubscribeSummaryCancellationToken = cancelToken.source();

    let getAlertSubscribeSummaryPromise = new Promise(
        function (resolve, reject) {
            let getAlertSubscribeSummaryurl = alertsURL + '/AlertSubscribeSummary';
            Get(getAlertSubscribeSummaryurl, token, contextJson, profileJson, getAlertSubscribeSummaryCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Alert Service : get Alert Subscribe Summary ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Alert Service : get Alert Subscribe Summary :: ", error);
                        //_logException("Alert Service : get Alert Subscribe Summary :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return getAlertSubscribeSummaryPromise;
}