import React, { useRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Get } from './servicecalls';
import { mmSummaryUrl, formatMoney, toDate } from './constants';
import moment from 'moment';
import OverlayProcessing from '../components/MoneyMovement/common/overlayProcessing';



function CheckDepositImageModal({checkDepImgData, modalState, toggleModal, token, contextjson, profilejson}){

    const [checkImageSrc, setCheckImageSrc] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [unavaliableCheckMsg, setUnavaliableCheckMsg] =  useState(false);

   const onModalOpen = () => {
       setCheckImageSrc(null);
       setShowOverlay(true);
       setUnavaliableCheckMsg(false);
       const acct = checkDepImgData ? checkDepImgData.acct : '';
       const amount = checkDepImgData ? checkDepImgData.amount : '';
       const fromDt = checkDepImgData ? checkDepImgData.dttrade : '';
       const toDt = checkDepImgData ? moment(checkDepImgData.dttrade, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') : ''
       const checknumber = checkDepImgData ? checkDepImgData.checknumber:'';
       let url = `${mmSummaryUrl}?AccountList=${acct}&DateRange=FromTo,${fromDt},${toDt}&SearchBy=Image,Check,CKR&FilterBy=CheckAmount,${amount},CheckNumber,${checknumber}`
       Get(url, token, contextjson, profilejson, null)
       .then(res => {
          if(res && res.data && res.data.value 
            && Array.isArray(res.data.value) && res.data.value.length > 0 
            && res.data.value[0].activities && Array.isArray(res.data.value[0].activities) && res.data.value[0].activities.length > 0){
                const checkImg  = res.data.value[0].activities[0].checkimage;
                if(checkImg){
                    let src = `data:application/pdf;base64,${checkImg}`;
                    setCheckImageSrc(src);
                }
                
          }
          else{
              setUnavaliableCheckMsg(true);
          }

          setShowOverlay(false);
       })
       .catch(err => {
           setShowOverlay(false);
           setUnavaliableCheckMsg(true);
           console.error(err);
       })
   }


    return (
            
        <div>
             <Modal
                isOpen={modalState}
                toggle={toggleModal}
                backdrop={false}
                onOpened={() => onModalOpen()}
                size='lg'
                zIndex='9999'
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                centered>
                <ModalHeader toggle={toggleModal} charCode="X" ></ModalHeader>
                <ModalBody>
                        <div className="row">
                            <div className="col-sm-4 col-md-4 col-lg-4">Account: {checkDepImgData ? checkDepImgData.acct : ''}</div>
                            <div className="col-sm-4 col-md-4 col-lg-4">Amount : {checkDepImgData ? formatMoney(checkDepImgData.amount) : '-'}</div>
                            <div className="col-sm-4 col-md-4 col-lg-4">Date   : {checkDepImgData ?  toDate(checkDepImgData.dttrade) : ''}</div>
                        </div>

                        {
                            unavaliableCheckMsg &&
                            <div align="center">
                                Check image is currently not available or not yet cleared for this transaction. 
                            </div>
                        }
                        
                        
                        {
                            showOverlay ? <div style={{width: '100%', height:500}}><OverlayProcessing /></div> : <iframe src={checkImageSrc} width="100%" height="500" title="check image"></iframe>
                        }

                        
                      
                </ModalBody>
            </Modal>
        </div>
    )

}


export default CheckDepositImageModal;