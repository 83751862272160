export function printDocument(data) {
    if (data) {
        var base64str = data;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        var blob = new Blob([view], { type: "application/pdf" });
        //below if block is for IE compatibility
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        }
        else {            
            var url = URL.createObjectURL(blob);
            window.open(url);
        }
        return "document printed";
    } else {
        console.log("didn't receive document from webapi")
        return null;
    }     
}