import { LOAD_ENV, CLEAR_ENV } from "../actions/actiontypes";

export function EnvironmentReducer(state = {}, action) {
    

    switch(action.type){
        case LOAD_ENV: {
            return {...action.config}
        }

        case CLEAR_ENV: {
            return {}
        }

        default: 
            return state;

    }

}