import React from "react";
import { GetPreferencePilotValue, MsgPasswordStatus } from '../../../../common/constants.jsx';

export const defaultSorted = [{ dataField: 'finame', order: 'asc' }];

export const assetAggrGridColumns = (props) => { 
    return [
    {
        dataField: 'finame',
        text: 'Bank Name',
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: 'left',
        formatter: (cell,row) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else if (props && GetPreferencePilotValue(props, 'NWResetPassword', false) === 'true' && row.passwordstatus !== undefined && row.passwordstatus !== null && row.passwordstatus === 'Y') return <div ><span>{cell}&nbsp;<div className="RCM_Inline_Div RCM_icon_hand" title={MsgPasswordStatus}>&#x1F6C8;</div></span></div>;
            else return <span>{cell}</span>;
        }
    },
    {
        dataField: 'number', 
        text: 'Account', 
        sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column), 
        headerAlign: 'left', 
        formatter: (cell) => {
            if (cell === null || cell === '') return <span>-</span>;
            else return <span>{cell}</span>;
        }
    },
    {
        dataField: 'nickname',
        text: 'NickName',
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: 'left',
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else return <span>{cell}</span>;
        }

    },
    {
        dataField: 'accttype', 
        text: 'Account Type', 
        sort: true, sortCaret: (order, column) => customCaretSort(order, column), 
        headerAlign: 'left',
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else return <span>{cell}</span>;
        }

    },
    {
        dataField: 'status', 
        text: 'Status', 
        sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column), 
        headerAlign: 'left', 
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else return <span>{cell}</span>;
        }

    },
    {
        dataField: 'updatedon',
        text: 'Last Updated Date',
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: 'left',
        formatter: (cell) => {
            if (cell === undefined || cell === null || cell === '') return <span>-</span>;
            else return <span>{cell}</span>;
        }

    },
    { dataField: "isdelete", text: "", formatter: actionFormatter },
    props && GetPreferencePilotValue(props, 'NWResetPassword', false) === 'true' && { dataField: "passwordstatus", text: "", formatter: resetPasswordFormatter}
]}

function customCaretSort(order) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function deleteAggregatedAccount(row)
{
    document.dispatchEvent(new CustomEvent('deleteAggregatedAccount', {bubbles: true, 'detail': row}));
}


function actionFormatter(cell, row)
{
  return(<div title="Delete Account" className="RCM_CM_delete-icon" onClick={() =>deleteAggregatedAccount(row)}></div>);
}

function resetPasswordAccount(row)
{
    document.dispatchEvent(new CustomEvent('resetPasswordAccount', {bubbles: true, 'detail': row}));
}

function resetPasswordFormatter(cell, row) {
   return cell &&  cell === 'Y' ? 
    (<div title="Reset Password" style={{cursor: 'pointer'}}
            className="RCM_menu_img RCM_userMenu_Refresh" 
            onClick={() => resetPasswordAccount(row)}></div>) : <></>
    
}