import { Get } from "../../../common/servicecalls.jsx";
import { authProvider } from "../../../../App";
import { _logException, _logInformation } from "../../../common/_logging";

export const getToken = () => {
    let getTokenPromise = new Promise(function (resolve, reject) {
        try {
            const accounts = authProvider.getAllAccounts();
            if (accounts && Array.isArray(accounts) && accounts.length > 0) {
                //fetching silent token for collaboration scope

                const tokenRequest = {
                    account: accounts[0],
                    scopes: ["Files.ReadWrite.All", "user.read"],
                    forceRefresh: true,
                };
                authProvider
                    .acquireTokenSilent(tokenRequest)
                    .then((res) => {
                        _logInformation(`Reports token received for scopes = 
                            ${res && res.scopes && Array.isArray(res.scopes)
                                ? res.scopes.map((s) => s).join(",")
                                : "Scope not found"
                            }`);
                        resolve(res.accessToken);
                    })
                    .catch((err) => {
                        _logException(err);
                        resolve(null);
                    });

            } else {
                _logException("Account not found");
                resolve(null);
            }
        } catch (e) {
            _logException(e);
            resolve(null);
        }
    });
    return getTokenPromise;
}

const getDrive = (token, group) => {
    const url = `https://graph.microsoft.com/v1.0/groups/${group.id}/drive/root/children`;
    let drives = [];
    let drive = null;
    let getDrivePromise = new Promise(function (resolve, reject) {
        Get(url, token)
            .then((res) => {
                drives = res && res.data && res.data.value ? [...res.data.value] : undefined
                if (
                    res &&
                    res.data &&
                    res.data.value &&
                    Array.isArray(res.data.value) &&
                    res.data.value.length > 0
                ) {
                    const generalDrives = res.data.value.filter(
                        (drive) => drive.name === "General"
                    );
                    if (generalDrives && generalDrives.length > 0) {
                        drive = generalDrives[0];
                    }
                }
                resolve(drive);
            })
            .catch((err) => {
                _logException(err);
                resolve(drive);
            });
    });
    return getDrivePromise;
}

const getDriveItems = (token, drive) => {
    const url = `https://graph.microsoft.com/v1.0/drives/${drive.parentReference.driveId}/items/${drive.id}/children`;
    let folder = null;
    let getDriveItemsPromise = new Promise(function (resolve, reject) {
        Get(url, token)
            .then((res) => {
                if (
                    res &&
                    res.data &&
                    res.data.value &&
                    Array.isArray(res.data.value) &&
                    res.data.value.length > 0

                ) {
                    const reportFolder = res.data.value.filter(
                        (folder) => folder.name === "Reports"
                    );
                    if (reportFolder && reportFolder.length > 0) {
                        folder = reportFolder[0];
                    }
                    resolve(folder);
                }

            })
            .catch((err) => {
                _logException(err);
                resolve(folder);
            });
    });
    return getDriveItemsPromise;
}

const getReportFolderItems = (token, drive, reportfolder) => {
    const url = `https://graph.microsoft.com/v1.0/drives/${drive.parentReference.driveId}/items/${reportfolder.id}/children`;
    let folderItems = null;
    let getReportFolderItemsPromise = new Promise(function (resolve, reject) {
        Get(url, token)
            .then((res) => {
                if (
                    res &&
                    res.data &&
                    res.data.value &&
                    Array.isArray(res.data.value) &&
                    res.data.value.length > 0
                ) {
                    folderItems = res.data.value;
                    resolve(folderItems);
                }

            })
            .catch((err) => {
                _logException(err);
                resolve(folderItems);
            });
    });
    return getReportFolderItemsPromise;
}

export const getReportFolderSubFolderItems = (reportsubfolder) => {
    const getSubFolderItemsServicePromise = new Promise(function (resolve, reject) {
        getToken().then((token) => {
            if (token) {
                const url = `https://graph.microsoft.com/v1.0/drives/${reportsubfolder.parentReference.driveId}/items/${reportsubfolder.id}/children`;
                let folderItems = null;

                Get(url, token)
                    .then((res) => {
                        if (
                            res &&
                            res.data &&
                            res.data.value &&
                            Array.isArray(res.data.value) &&
                            res.data.value.length > 0
                        ) {
                            folderItems = res.data.value;
                            resolve(folderItems);
                        }

                    })
                    .catch((err) => {
                        _logException(err);
                        resolve(folderItems);
                    });
            }

            else {
                reject("Unable to load Report List")
            }
        }).catch((err) => {
            reject(err);
        });
    });
    return getSubFolderItemsServicePromise;

}

export const getReportItemsService = (aadId) => {
    const objectIdentifier = aadId ? `users/${aadId}` : 'me'
    //create a promise
    const getReportItemsServicePromise = new Promise(function (resolve, reject) {
        getToken().then((token) => {
            if (token) {
                const url = `https://graph.microsoft.com/v1.0/${objectIdentifier}/memberof/$/microsoft.graph.group?$filter=groupTypes/any(a:a eq 'unified')`
                Get(url, token)
                    .then((groupResponse) => {
                        let groups = groupResponse.data.value;
                        if (groupResponse.data.value && Array.isArray(groupResponse.data.value)) {
                            let group = groupResponse.data.value.filter(grp => grp.classification === 'Client Collaboration');
                            if (group && group.length > 0) {
                                getDrive(token, group[0]).then((driveFolderResponse) => {
                                    if (driveFolderResponse) {
                                        getDriveItems(token, driveFolderResponse).then((driveItemsResponse) => {
                                            if (driveItemsResponse) {
                                                getReportFolderItems(token, driveFolderResponse, driveItemsResponse).then((reportFolderItemsResponse) => {
                                                    if (reportFolderItemsResponse) {

                                                        resolve(reportFolderItemsResponse);
                                                    }
                                                    else {
                                                        reject("Unable to load Report List")
                                                    }
                                                }).catch((err) => {
                                                    reject(err);
                                                });
                                            }
                                            else {
                                                reject("Unable to load Report List")
                                            }
                                        }).catch((err) => {
                                            reject(err);
                                        });
                                    }
                                    else {
                                        reject("Unable to load Report List")
                                    }
                                }).catch((err) => {
                                    reject(err);
                                });
                            }
                            else {
                                reject("Unable to load Report List")
                            }
                        }
                        else {
                            reject("Unable to load Report List")
                        }
                    }).catch((err) => {
                        reject(err);
                    });
            }
            else {
                reject("Unable to load Report List")
            }
        }).catch((err) => {
            reject(err);
        });
    });
    return getReportItemsServicePromise;
}
