import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import {
  setBaseUrl,
  setBillPayUIUrl,
  UserLogOut,
  setNinthWavesConfig,
  setWheelsUpConfig,
  setCaseManagerConfig,
  setTransfersConfig,
  setApiConfig,
    setPinnacleCareConfig,
    setPlanManagerUrl,
    setEnvConfig,
    setAvationPortfolioConfig
} from "./content/common/constants";
import HomeComponent from "./content/components/home";
import {
  _logInformation,
  _logEvent,
  _logException,
} from "./content/common/_logging";
import { Provider } from "react-redux";
import configureStore from "./content/store/store";
import UserLogon from "./content/common/userLogon";
import { relasevariables } from "./index";
import ClientOnlineMsalProvider, {
  getLoggedInUser,
  isTokenRefreshRequired,
} from "./content/system/MsalUtilities";
import { Spinner } from "reactstrap";
import ErrorBoundary from "./content/common/errorboundary";
import CheckUserStatus from "./content/components/CustomLogin/CheckUserStatus";
import {getUserFirstTimeLoginDetails, checkRedirectVersionService} from './content/services/home/userServices'

export var authProvider = null;
export var userinfo = null;
export var userTypedEmail = null;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      loginFailed: true,
      user: null,
      baseurl: null,
      idletimeout: null,
      msalObj: null,
      isTokenRefreshed: false,
      displayUserLoginForm: false,
      displayCustomLoginForm: false,
      userEmail: "",
      crspdf: "",
      rcmcpEnv:'',
      showTermsAndConditions: ''
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.silentLogin = this.silentLogin.bind(this);
    this.setShowHomePageState = this.setShowHomePageState.bind(this);

    this.clientOnlineProvider = null;

    if (process.env.NODE_ENV === "development") {
      let themeStyle = document.getElementById("themecss");
      themeStyle.href = `RCM.dark.style.css?ver=${
        relasevariables ? relasevariables.version : ""
      }`;
    }
  }
  store = null;

  getAuthProvider(response) {
    this.clientOnlineProvider = new ClientOnlineMsalProvider();
    authProvider = this.clientOnlineProvider.getMsalInstance(response);
    return authProvider;
  }

  setSessionItems(response) {
    sessionStorage.setItem("clientid", response.clientid);
    sessionStorage.setItem("authority", response.authority);
    sessionStorage.setItem("instrumentationKey", response.instrumentationKey);
    sessionStorage.setItem("idletimeout", response.idletimeout);
  }

  setConstants(serverConfig) {
    setBaseUrl(serverConfig.baseurl);
    setBillPayUIUrl(serverConfig.billPayUrl);
    setNinthWavesConfig(serverConfig);
    setWheelsUpConfig(serverConfig);
    setCaseManagerConfig(serverConfig);
    setTransfersConfig(serverConfig);
    setApiConfig(serverConfig);
    setPinnacleCareConfig(serverConfig);
    setPlanManagerUrl(serverConfig.planManagerUrl);
    setEnvConfig(serverConfig);
    setAvationPortfolioConfig(serverConfig);
  }

  async componentDidMount() {
    this.store = configureStore(this.props.config);
    this.serverConfig = this.props.config;

    ////Add QA config to point to QA
    //this.serverConfig.baseurl = 'https://api.qa.rockco.com/';
    //this.serverConfig.ninthwavesDbUrl = 'https://qa-services.1fsapi.com/nwplatform-stg/trustednetwork';
    //this.serverConfig.ninthwavesScriptUrl = "https://consent.ninth-wave.com/nw-connect-rco.js";

    this.setSessionItems(this.serverConfig);
    this.setState({
      crspdf: this.serverConfig ? this.serverConfig.crspdf : "",
      rcmcpEnv: this.serverConfig ? this.serverConfig.rcmcpEnv:'',
      showTermsAndConditions: this.serverConfig ? this.serverConfig.showTermsAndConditions: ''
    });
    this.setConstants(this.serverConfig);

    this.setTokenRefreshListner();
    this.authProvider = authProvider = this.getAuthProvider(this.serverConfig);
    this.authProvider
      .handleRedirectPromise()
      .then((res) => {
        if (res) {
          _logInformation('Handle Redirect response received, token should be received')
          this.checkTimeOut();
          this.processAfterAcquiringToken(res); 
        } else {
          const accounts = this.authProvider.getAllAccounts();
          if (accounts && Array.isArray(accounts) && accounts.length > 0) {
            _logInformation('Fetching token silent')
            this.clientOnlineProvider
              .getTokenSilent(
                accounts[0],
                this.apiTokenScope(this.serverConfig)
              )
              .then((silentres) => {
                _logInformation('Fetched token silently')
                this.processAfterAcquiringToken(silentres);
              })
              .catch(() => {
                _logException('Silent Token Failed, trying SSO')
                this.silentLogin(sessionStorage.getItem('userTypeEmail'));
              });
          } else {
            //user is coming first time
            console.log("User is coming first time");
            _logInformation('User is coming first time, show login page')
            // check for redirect client 
            const params = new URLSearchParams(
              window.location.search.substr(1, window.location.search.length)
            );
            const userid = params.get('uid')

            const { simulationRequest } = this.props;
            if (simulationRequest) {
              this.silentLogin(simulationRequest.simulationUser);
            } else if(userid) {
              const scopes = this.apiTokenScope(this.props.config);
              this.setState(
                { userEmail: userid },
                this.clientOnlineProvider.interactionBasedLogin(userid, scopes)
              );
            }
            else {
              this.setState({ displayUserLoginForm: true });
            }
          }
        }
      })
      .catch((err) => {
        //show error page
        console.error(err);
        _logException(err);
        alert(
          "We are having trouble loading your settings, please retry after some time. If the problem persists, please contact your advisor team."
        );
      });
  }

  processAfterAcquiringToken(silentRes, tokenRefreshed) {
    let user = getLoggedInUser(silentRes.accessToken);
    userinfo = JSON.stringify(user);
    this.setUserTypedEmail(user.userName);
    const {simulationRequest}  =  this.props || {}
    if(simulationRequest) {
      _logInformation("Simulation User, we will not check first time login details");
      this.setShowHomePageState(silentRes.accessToken, user, tokenRefreshed, false, null)
    } else {
      getUserFirstTimeLoginDetails(silentRes.accessToken)
      .then(firstimeloginDetailsResponse => {
        if(firstimeloginDetailsResponse 
            && firstimeloginDetailsResponse.data 
            && firstimeloginDetailsResponse.data.enablecustomlogin) {
          this.setShowHomePageState(silentRes.accessToken, user, tokenRefreshed, true, firstimeloginDetailsResponse.data)
        } else {
          this.setShowHomePageState(silentRes.accessToken, user, tokenRefreshed, false, null)
        }
      })
      .catch(err => {
        //Do something when error occured while calling api
        console.error(err)
        this.setShowHomePageState(silentRes.accessToken, user, tokenRefreshed, false, null)
      })
    }
  }

  setShowHomePageState(token, user, tokenRefreshed, displayCustomLoginForm, firstimeloginresonse) {
    this.setState({
      loginFailed: false,
      token: token,
      userEmail: user.userName,
      user: userinfo,
      displayCustomLoginForm: displayCustomLoginForm || false,
      firstimeloginresonse: firstimeloginresonse,
      maintenancewindowjson: JSON.parse(
        this.serverConfig.maintenancewindowjson
      ),
      isTokenRefreshed: tokenRefreshed ? 
                         !this.state.isTokenRefreshed 
                        : this.state.isTokenRefreshed
    });
  }

  silentLogin(loginHint, tokenRefreshed) {
    this.clientOnlineProvider
      .ssoLogin(loginHint, this.apiTokenScope(this.serverConfig))
      .then((silentRes) => {
        this.processAfterAcquiringToken(silentRes, tokenRefreshed);
      })
      .catch((err) => {
        console.log("Silent login Error", err);
        _logException(err);
        this.clientOnlineProvider.interactionBasedLogin(
          loginHint,
          this.apiTokenScope(this.serverConfig)
        );
      });
  }

  lastEventTime = null;
  checkTimeOut() {
    let ideltimeout = sessionStorage.getItem("idletimeout");
    setInterval(() => {
      if (ideltimeout) {
        let currentTime = new Date().valueOf();
        //console.log('Current Time: ' + currentTime + ', LastEventTime: ' + this.lastEventTime + ', idleTimeout: ' + ideltimeout);
        if (this.lastEventTime !== null) {
          if (currentTime - this.lastEventTime >= ideltimeout) {
            _logEvent(`User Session Expired !!`, this.state.userEmail);
            this.logout();
          } else {
            this.lastEventTime = currentTime;
          }
        }
      }
    }, 2000);
  }

  setUserTypedEmail(email) {
    sessionStorage.setItem("userTypeEmail", email);
  }

  logout() {
    UserLogOut(this.authProvider, this.state.userEmail);
  }

  apiTokenScope(response) {
    return {
      scopes: [response.scopes],
    };
  }

  setTokenRefreshListner() {
    let intervalTime = 1 * 60 * 1000;
    setInterval(() => {
      if (isTokenRefreshRequired(this.state.token, intervalTime)) {
        _logInformation("Token is about to expire, renewing the token");
        this.silentLogin(this.state.userEmail, true);
      }
    }, intervalTime);
  }

  onLoginClick = (email) => {
    _logEvent("0. User Login Hint", email);
    userTypedEmail = email;
    this.setUserTypedEmail(userTypedEmail);
    let scopes = this.apiTokenScope(this.serverConfig);
    sessionStorage.setItem('redirecturl', window.location.href)

    checkRedirectVersionService(email)
    .then((response) => {
       if(response && response.data && response.data.appversion === '20230200'){
        const clientv2url = this.props?.config?.clientV2Url
        window.location.replace(`${clientv2url}?uid=${email}`)
       } else {
        this.setState(
          { userEmail: email },
          this.clientOnlineProvider.interactionBasedLogin(email, scopes)
        );
       }
    })
    .catch(() => {
      _logException("Check Redirect version service failed")
      this.setState(
        { userEmail: email },
        this.clientOnlineProvider.interactionBasedLogin(email, scopes)
      );
    })

    
  };

  onSkipUserStatus = () => {
    this.setState({
      displayCustomLoginForm: false
    })
  }

  onOTPVerification = () => {
    this.setState({
      displayCustomLoginForm: false
    })
  }

  render() {
    let userKey = this.state.user;
    let idletimeout = sessionStorage.getItem("idletimeout");

    let user = [];
    if (userKey) {
      user = JSON.parse(userKey);
    }

    return (
      <div className="App" key="App01">
        {!this.state.loginFailed ? (
          <div key="App02">
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={idletimeout ? parseFloat(idletimeout) : parseFloat(this.state.idletimeout)}
            />

            <Provider store={this.store} key="App03">
              {
                this.state.displayCustomLoginForm ? (
                  <ErrorBoundary>
                    <CheckUserStatus 
                      logout={() => this.logout()} 
                      token={this.state.token}
                      onSkip={() => this.onSkipUserStatus()}
                      afterVerification={() => this.onOTPVerification()}
                      firstimeloginresonse={this.state.firstimeloginresonse}
                      />
                  </ErrorBoundary>
                ) : (
                  <HomeComponent
                    user={user ? user : this.state.user}
                    token={this.state.token}
                    isTokenRefreshed={this.state.isTokenRefreshed}
                    baseurl={this.state.baseurl}
                    maintenancewindowjson={this.state.maintenancewindowjson}
                    key="App04"
                    crspdf={this.state.crspdf}
                    rcmcpEnv={this.state.rcmcpEnv}
                    simulationRequest={this.props.simulationRequest}
                    showTermsAndConditions={this.state.showTermsAndConditions}
                    sneekpeekpdf={this.props.config?.clientV2SneekPdf}
                  />
                )
              }
            </Provider>
          </div>
        ) : this.state.displayUserLoginForm === true ? (
          <UserLogon 
            onLoginClick={this.onLoginClick} 
            key="App05" 
            config={this.props.config}
          />
        ) : (
          <div
            className="RCM_spinner"
            align="center"
            style={{ height: "100vh", backgroundColor: "#191f28" }}
          >
            Loading RCM Client Portal
            <div>
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="primary" />
            </div>
          </div>
        )}
      </div>
    );
  }

  _onAction() {
    //console.log('user did something', e)
  }

  _onActive(e) {
    console.log("user is active", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  _onIdle() {
    this.logout();
  }
}

export default App;
