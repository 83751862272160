import React, {
  memo,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import OverlayProcessing from "../../MoneyMovement/common/overlayProcessing";
import { Post } from "../../../common/servicecalls";
import { TeamworkContext } from "../TeamworkContainer";
import { _logException } from "../../../common/_logging";

const FilePreviewContainer = ({ previewUrl }) => {
  const { token } = useContext(TeamworkContext);
  const [apiUrl, setApiUrl] = useState(previewUrl);
  const [url, setUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (apiUrl) {
      setIsLoading(true);
      Post(apiUrl, {}, token)
        .then((res) => {
          setUrl(
            res && res.data && res.data.getUrl ? res.data.getUrl : undefined
          );
          setIsLoading(false);
        })
        .catch((err) => {
          _logException(err);
          setIsLoading(false);
        });
    }
  }, [apiUrl, token]);

  useEffect(() => {
    setApiUrl(previewUrl);
  }, [previewUrl]);

  return (
    <>
      {isLoading && <OverlayProcessing />}
      {url && (
        <iframe
          height={window.innerHeight * 0.75}
          width="100%"
          src={url}
          title="Preview File"
        ></iframe>
      )}
    </>
  );
};

export default memo(FilePreviewContainer);
