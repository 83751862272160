import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from './errorboundary.jsx';
import { Button, ButtonGroup } from 'reactstrap';

class RCMToggleButton extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            selectedOption: "On",
            leftOption: "On",
            rightOption: "Off",
            isDisabled:false
        }

    }
   
    componentDidMount() {
        if (this.props) {
            this.setState({
                leftOption: this.props.leftOption && this.props.leftOption !== "" ? this.props.leftOption : "On",
                rightOption: this.props.rightOption && this.props.rightOption !== "" ? this.props.rightOption : "Off",
                selectedOption: this.props.selectedOption && this.props.selectedOption !== "" ? this.props.selectedOption
                    : this.props.leftOption && this.props.leftOption !== "" ? this.props.leftOption : "On",
                isDisabled: this.props.isDisabled
            }); 
        }
    }

    toggle = (selectedOption) => {
        this.setState({
            selectedOption: selectedOption
        }, () => {
            if (this.props.onToggle !== undefined && this.props.onToggle !== null && typeof this.props.onToggle === "function") {
                    this.props.onToggle();
                }
        });
    }

    render() {
        return (

            <ErrorBoundary >
                <div className="" >
                    <ButtonGroup>
                        <Button className={"btn RCM_toggle_btns left_btn " + (this.state.selectedOption === this.state.leftOption ? "btn-primary RCM_button_primary" : "btn-secondary RCM_button_secondary")}
                            onClick={() => this.toggle(this.state.leftOption)} disabled={this.state.isDisabled}>
                            {this.state.leftOption}
                        </Button>
                        <Button className={"btn RCM_toggle_btns right_btn " + (this.state.selectedOption === this.state.rightOption ? " btn-primary RCM_button_primary" : " btn-secondary RCM_button_secondary")}
                            onClick={() => this.toggle(this.state.rightOption)} disabled={this.state.isDisabled}>
                            {this.state.rightOption} 
                        </Button>


                    </ButtonGroup>
                </div>

            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        
    };
};

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(RCMToggleButton);