import React, { Component } from 'react';

import { connect } from 'react-redux';
import { LiabilitiesDetailsView } from './liablitiesdetailsview.jsx';
import { deleteExternalAccount } from '../assetaggregation/assetaggregationservice.jsx';
import { spinner, BalancesdetailURL, GetNumericValue } from '../../../../common/constants.jsx';
import { formatMoney, roundvalue, toDate, GetPreferencePilotValue, GetPreferenceValue,getDisplayName } from '../../../../common/constants';
import { Get } from '../../../../common/servicecalls.jsx';
import axios from 'axios';
import { AddBalanceDetails, AddLiabilites } from '../../../../actions/accountsactions.jsx';
import ManageAssets from '../manageassets/manageassets.jsx';
import { getAssetListService } from '../manageassets/manageassetsservice.jsx';
import { balancedetailsDummyResponse, RRR_123456_balance_details, RRR_234567_balance_details } from '../../accountsmockresponse.js';
import { accountresponse } from './../../../profilemockresponse';
class LiabilitiesDetails extends Component {

    constructor(props) {
        super(props);
        this.toggleDeletePopup = this.toggleDeletePopup.bind(this);
        this.getAssetList = this.getAssetList.bind(this);
        this.openAssetDeletePopup = this.openAssetDeletePopup.bind(this);
        this.requesterId = "";

        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {

            typeOfAssetOpen: false,
            typeOfAsset: {},
            formComponent: "",
            isAddModalOpen: false,
            viewformcomponent: "",
            selectedAsset: [],
            openDeletePopup: false,
            selectedRow: {},
            profileId: props.profileId,
            manageAssetData: {},
            manageAssetList: [],
            assetToDelete: {},
            loadingManageAsset: true,
            balancestileresponse: this.props.balancedetails,
            showAssetDetails: false,
            accountDetailsForEdit: {},
            isShowMarginAccounts: this.getPilotFlagValue("Margin Accounts"),
            balSummaryV2Flag : this.getPilotFlagValue("BalSummaryV2"),
            isProspectClient:this.props.role && this.props.role==='Prospect'?true:false
        };

        this.typeOfAssetToggle = this.typeOfAssetToggle.bind(this);
    }

    getPilotFlagValue(flagName) {
        let obj = {
            preferences: this.props.preferences
        }
        let isPreferenceRequired = GetPreferencePilotValue(obj, flagName, "false") === "true" ? true : false;
      
        if (isPreferenceRequired)
            return true;
        else
            return false;

    }
    balDetailscanellationToken = null;

    execute = () => {
        this.setState({ loadingManageAsset: true, requestFailed: false, areDataRetrieved: false, showAssetDetails: false, accountDetailsForEdit: {} }, () => {
            //callback function to load data


            const baseUrl = BalancesdetailURL + "";
            if (this.balDetailscanellationToken) {
                this.balDetailscanellationToken.cancel('Operation canceled due to new request.')
            }
            this.balDetailscanellationToken = axios.CancelToken.source();

            Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.balDetailscanellationToken)
                .then(result => {
                    //We should not update the store from this page, need testing 
                    //this.props.AddBalanceDetails(result.data);
                    this.setState({ balancestileresponse: result.data, loadingManageAsset: false, areDataRetrieved: true });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        this.setState({ requestFailed: true, error: error.message });
                    }
                })
        });
    };
    componentDidMount() {
        if (this.props.balancedetails === null) {
            if (this.state.isProspectClient) {
                this.setState({ loadingManageAsset: false, areDataRetrieved: true}); 
            }
            else
            this.execute();
        }
        else {
            this.setState({ loadingManageAsset: false, areDataRetrieved: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.setState({ loadingManageAsset: false, areDataRetrieved: true });
            }
            else {
                this.execute();
            }
           
        }
    }

    getAssetList() {
        this.setState({ loadingManageAsset: false }, () => {
        });
    }

    handleOnAssetSelection = (row, e) => {
        if (row) {
            var formComponent = this.getAssetFormComponent("cashaccount", "view");
            this.setState({ viewformcomponent: formComponent, selectedRow: row });
        }

    }
    toggleDeletePopup() {
        this.setState(prevState => ({
            openDeletePopup: !prevState.openDeletePopup
        }))
    }
    openAssetDeletePopup(row, e) {
        this.setState(prevState => ({
            openDeletePopup: !prevState.openDeletePopup, assetToDelete: row
        }))
    }
    typeOfAssetToggle() {
        this.setState(prevState => ({
            typeOfAssetOpen: !prevState.typeOfAssetOpen
        }));
    }

    getValueFromBalanceResponse(accountnumber) {
        let data = [];
        if (this.state.balancestileresponse && this.state.balancestileresponse.value && this.state.balancestileresponse.value.length > 0 && this.state.balancestileresponse.value[0].accountbalances && this.state.balancestileresponse.value[0].accountbalances.length > 0) {
            data = this.state.balancestileresponse.value[0].accountbalances.filter(d => d.accountaumber === accountnumber);
        }
        return data;
    }

    closeAddEdittoggle() {
        this.setState({ showAssetDetails: false, accountDetailsForEdit: {} });
    }

    showManageAssetDetails(row) {
        console.log(row);
        //call manage asset api here... and filter the account 
        let accountDataToEdit = {};
        getAssetListService(this.authToken, this.contextjson, this.profilejson, this.state.profileId)
            .then(manageAssetData => {
                if (manageAssetData) {

                    if (manageAssetData && manageAssetData.manualassetdetails
                        && manageAssetData.manualassetdetails.accounts
                        && Array.isArray(manageAssetData.manualassetdetails.accounts)
                        && manageAssetData.manualassetdetails.accounts.length > 0
                        && row.key && row.key !== '') {
                        let acctList = manageAssetData.manualassetdetails.accounts;
                        for (let i = 0; i < acctList.length; i++) {
                            if (acctList[i].account && acctList[i].account.accountkey && acctList[i].account.accountkey === row.key) {
                                accountDataToEdit = acctList[i];
                                this.setState({ showAssetDetails: true, accountDetailsForEdit: { type: row.type, ...accountDataToEdit } });
                                break;
                            }
                        }
                    }
                    //Filter the account and update the property addeditaccount

                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    render() {
        const selectAssetRow = {
            mode: 'radio',
            clickToSelect: true,
            clickToExpand: false,
            hideSelectColumn: true,
            classes: 'RCM_grp_selection_row',
            selected: ["Cash Account"],
            onSelect: (row, isSelect, rowIndex, e) => this.handleOnAssetSelection(row, e)
        }
        var rowdata = [];


        if (this.props.selectedaccount && this.props.selectedaccount.length > 0) {
            for (let i = 0; i < this.props.selectedaccount.length; i++) {
                // this.props.selectedaccount.map((d, i) =>
                let d = this.props.selectedaccount[i];
                const data = this.getValueFromBalanceResponse(d.number);
                rowdata.push(
                    {
                        key: (d.key === undefined || d.key === null || d.key == 0) ? '-' : d.key,
                        accountnumber: (d.number === undefined || d.number === null || d.number == 0) ? '-' : d.number,
                        nickname: getDisplayName(d),
                        accounttype: (d.isInternal ? 'Rockefeller' : (d.accountsourcetypecode ? d.accountsourcetypecode : '')),//rockefeller,external,manualadd
                        type: d.isInternal ? (d.ismargin ? "Margin" : d.regtypecode) : (d.accttype === null || d.accttype === '') ? d.accountsourcetypecode : d.accttype,//type like checking ,IRA,manual asset
                        assettype: d.accountclassification,
                        pricedinvestment: data && data.length > 0 ? data[0].pricedinvestments : null,
                        cashaccount: data && data.length > 0 ? data[0].cashmoneyaccounts : null,
                        asofdate: data && data.length > 0 ? data[0].asofdate : null,
                        totalvalue: data && data.length > 0 ? data[0].totalaccountvalue : null,
                        outstandingbalance: (this.state.balSummaryV2Flag) ? (data && data.length > 0 ? data[0].marginandoutstandingbalance : null) :
                            ((d.ismargin) ? (data && data.length > 0 ? data[0].marginbalance : null) : (data && data.length > 0 ? data[0].outstandingbalance : null)),
                        interestrate: d.ismargin ? (data && data.length > 0
                            ? (data[0].marginintrate) : null) : (data && data.length > 0 ? data[0].interestrate : null),
                        availablecredit: data && data.length > 0 ? data[0].availablecredit : null,
                        pledgedaccounts: '',
                        ratetype: '',
                        //margin: data && data.length > 0 ? (data[0].marginbalance) : null,
                       // marginintrate: data && data.length > 0 ? (data[0].marginintrate) : null,
                        //cashmarginavailabletowithdraw: data && data.length > 0 ? (data[0].cashmarginavailabletowithdraw) : null,
                       // cashmarginbuyingpower: data && data.length > 0 ? data[0].cashmarginbuyingpower : null,
                        //nonmarginbuyingpower: data && data.length > 0 ? data[0].nonmarginbuyingpower : null,
                        customername: data && data.length > 0 ? data[0].customername : null,
                        purposeFlag: data && data.length > 0 ? data[0].loanpurpose : null,
                        commitment: data && data.length > 0 ? data[0].totalcredit : null,
                        grosscollval: data && data.length > 0 ? data[0].grosscollateralvalue : null,
                        collateralaccounts: data && data.length > 0 ? data[0].collateralaccounts : null,
                        rateindex: data && data.length > 0 ? data[0].rateindex : null,
                        billedamount: data && data.length > 0 ? data[0].billedamount : null,
                        dayofmonthpmtdue: data && data.length > 0 ? data[0].dayofmonthpaymentdue : null,
                        paymentmethod: data && data.length > 0 ? data[0].paymentmethod : null,
                        interestpaidamountytd: data && data.length > 0 ? data[0].interestpaidamountytd : null,
                        nextratechangedate: data && data.length > 0 ? data[0].nextratechangedate : null,
                        loanopendate: data && data.length > 0 ? data[0].loanopendate : null,
                        relationshipmanager: data && data.length > 0 ? data[0].relationshipmanager : null,
                        servicingphone: data && data.length > 0 ? data[0].servicingphone : null,
                        nextpaymentdate: data && data.length > 0 ? data[0].nextpaymentdate : null,
                        spread: data && data.length > 0 ? data[0].spread : null,
                        margin: (this.state.balSummaryV2Flag) ? (data && data.length > 0 ? data[0].marginandoutstandingbalance : null) : (data && data.length > 0 ? (data[0].marginbalance) : null),
                        marginintrate: data && data.length > 0 ? (data[0].marginintrate) : null,
                        cashmarginbuyingpower: data && data.length > 0 ? (data[0].cashmarginbuyingpower) : null,
                        nonmarginbuyingpower: data && data.length > 0 ? (data[0].nonmarginbuyingpower) : null,
                        cashmarginavailabletowithdraw: data && data.length > 0 ? (data[0].cashmarginavailabletowithdraw) : null,
                        notes: data && data.length > 0 ? (data[0].notes) : null,
                        nextpayment: data && data.length > 0 ? (data[0].nextpayment) : null
                    })
            }

            var filterdata = [];
            if (this.state.isProspectClient) {
                filterdata = rowdata;
            }
            else {
                if (this.props && this.props.viewBy) {
                    if (this.props.viewBy === "L") {
                        if (this.state.isShowMarginAccounts) {
                            filterdata = rowdata.filter(asset => ((asset.assettype === "LIABILITY" && asset.accounttype === "SBL") || asset.type === "Margin"));
                        }
                        else {
                            filterdata = rowdata.filter(asset => (asset.assettype === "LIABILITY" && asset.accounttype === "SBL"));
                        }

                    }
                    else if (this.props.viewBy === "AL") {
                        filterdata = rowdata;
                    }
                    else {
                        filterdata = rowdata.filter(asset => asset.assettype === "ASSET")
                    }
                }
                if (filterdata.length > 0) {
                    this.props.AddLiabilites(filterdata)
                }
            }
           


        }
        return (

            this.state.loadingManageAsset ? (
                <div align="center">
                    <div className="RCM_spinner">Loading Liabilities{spinner()}</div>
                </div>
            ) :
                (
                    this.state.showAssetDetails ?
                        (
                            <ManageAssets
                                addEditEnabled={true}
                                accountDetailsForEdit={this.state.accountDetailsForEdit}
                                mode="edit"
                                hideLabel={true}
                                closeAddEdittoggle={() => this.closeAddEdittoggle()} />
                        )
                        :
                        (<LiabilitiesDetailsView
                            state={this.state}
                            data={filterdata}
                            showManageAssetDetails={(row) => this.showManageAssetDetails(row)}
                            viewBy={this.props.viewBy}
                            selectedaccount={this.props.selectedaccount}
                           
                        />)
                )
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
        selectedaccount: store.acct.role && store.acct.role === 'Prospect' ? accountresponse : store.acct.selectedaccount,
        balancedetails: store.acct.role && store.acct.role === 'Prospect' ?  balancedetailsDummyResponse:store.acct.balancedetails,
        render: store.acct.render,
        preferences: store.acct.preferences,
        role:store.acct.role
    };
};

const mapDispatchToProps = {
    AddBalanceDetails,
    AddLiabilites
};
export default connect(mapStateToProps, mapDispatchToProps)(LiabilitiesDetails);