import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Post } from '../../common/servicecalls.jsx';
import { CaseManagerView } from './casemanagerview.jsx'
import { FeedbackURL, MailURL, MessageType, MessageClass, caseManagementEmail } from '../../common/constants.jsx';
import axios from 'axios';
import ErrorBoundary from '../../common/errorboundary.jsx';
import { _logException, _logInformation, _logPageView } from '../../common/_logging.js';
import { withPageView } from '../../hoc/withPageView.jsx';
import { userinfo, authProvider } from '../../../App.js'
class CaseManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            renderProp: "",
            PopoverOpen: false,
            renderFeedBack: false,
            feedbackDetails: '',
            messages: [],
            isCaseSubmitted: false,
            validateFeedForm: false,
            name: '',
            emailid: '',
            sectionsWithIssue: '',
            selectedfile: null,
            source: '',
            additionalInfo :''

        }
        this.submit = this.submit.bind(this);
        this.changeFeedBack = this.changeFeedBack.bind(this);
        this.selectSection = this.selectSection.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    cancellationToken = null;

    componentWillUnmount() {
        if (this.cancellationToken) {
            this.cancellationToken.cancel('Operation canceled due to new request.')
        }
    }
    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {

        }
    }

    componentDidMount() {
        console.log("inside feedback did mount, portal id: " + this.props.name);
        //let userKey = sessionStorage.getItem('user');
        let userKey = userinfo;
        let user = [];
        if (userKey) {
            user = JSON.parse(userKey);
        }
        var accountIdentifier = "";
        var environment = "";
        var homeAccountIdentifier = "";
        var name = "";
        var userName = "";
        if (user) {
            accountIdentifier = user.accountIdentifier;
            environment = user.environment;
            homeAccountIdentifier = user.homeAccountIdentifier;
            name = this.props.profileResp && this.props.profileResp.fullname ? this.props.profileResp.fullname:  user.name;
            userName = user.userName;
        }
        var source = "";
        var additionalInfo = "";
        if (this.props && this.props.source !== undefined && this.props.source !== null) {
            source = this.props.source;
            additionalInfo = this.props.additionalInfo;
            if (source === "AssetAggregation") {
                let sectionsWithIssue = 'assetaggregation';
                this.setState({ isassetaggregationchecked: true, sectionsWithIssue: sectionsWithIssue })
            }
            if(source === "login"){
                this.setState({isloginchecked: true, sectionsWithIssue: source});
            }
        }
        this.setState({
            name: name.trim(),
            emailid: userName.trim(),
            source: source,
            additionalInfo :additionalInfo

        })
    }
    changeFeedBack(event) {
        let messages = [];
        this.setState({
            feedbackDetails: event.target.value,
            messages: messages,
            validateFeedForm: false
        });
    }
    selectSection(event) {
        let messages = [];
        if (event.target.checked) {
            let sectionsWithIssue = ''
            if (this.state.sectionsWithIssue === '') {
                sectionsWithIssue = event.target.value;
            } else {
                sectionsWithIssue = this.state.sectionsWithIssue + ", " + event.target.value;
            }
            if (event.target.value === "login") {
                this.setState({ isloginchecked: true })
            }
            if (event.target.value === "logout") {
                this.setState({ islogoutchecked: true })
            }
            if (event.target.value === "accounts") {
                this.setState({ isaccountschecked: true })
            }
            if (event.target.value === "transfers") {
                this.setState({ istransferschecked: true })
            }
            if (event.target.value === "documents") {
                this.setState({ isdocumentschecked: true })
            }
            if (event.target.value === "general") {
                this.setState({ isgeneralchecked: true })
            }
            if (event.target.value === "collaboration") {
                this.setState({ iscollaborationchecked: true })
            }
            if (event.target.value === "helpandsupport") {
                this.setState({ ishelpandsupportchecked: true })
            }
            if (event.target.value === "billpay") {
                this.setState({ isbillpaychecked: true })
            }
            if (event.target.value === "preferences") {
                this.setState({ ispreferenceschecked: true })
            }
            if (event.target.value === "printandexport") {
                this.setState({ isprintandexportchecked: true })
            }
            if (event.target.value === "assetaggregation") {
                this.setState({ isassetaggregationchecked: true })
            }   
            this.setState({
                sectionsWithIssue: sectionsWithIssue,
                validateFeedForm: false,
                messages: messages
            });
        } else {
            if (event.target.value === "login") {
                this.setState({ isloginchecked: false })
            }
            if (event.target.value === "logout") {
                this.setState({ islogoutchecked: false })
            }
            if (event.target.value === "accounts") {
                this.setState({ isaccountschecked: false })
            }
            if (event.target.value === "transfers") {
                this.setState({ istransferschecked: false })
            }
            if (event.target.value === "documents") {
                this.setState({ isdocumentschecked: false })
            }
            if (event.target.value === "general") {
                this.setState({ isgeneralchecked: false })
            }
            if (event.target.value === "collaboration") {
                this.setState({ iscollaborationchecked: false })
            }
            if (event.target.value === "helpandsupport") {
                this.setState({ ishelpandsupportchecked: false })
            }
            if (event.target.value === "billpay") {
                this.setState({ isbillpaychecked: false })
            }
            if (event.target.value === "preferences") {
                this.setState({ ispreferenceschecked: false })
            }
            if (event.target.value === "printandexport") {
                this.setState({ isprintandexportchecked: false })
            }
            if (event.target.value === "assetaggregation") {
                this.setState({ isassetaggregationchecked: false })
            }
            var sectionsWithIssue = this.state.sectionsWithIssue.split(',');
            if (sectionsWithIssue.length > 0) {
                sectionsWithIssue.pop(event.target.value);
                this.setState({
                    sectionsWithIssue: sectionsWithIssue.toString(),
                    messages: messages,
                    validateFeedForm: false
                })
            }
        }
    }

    // convert to base 64

    toUTF8Array = (unicodeString) => {

        return btoa(unicodeString);

    }

    getTechnicalInformation(){
        return document.cookie;
    }


    // end convert to base 64
    async submit(e) {
        e.preventDefault();
        let messages = [];
        if (this.state.feedbackDetails.trim() === "" && this.state.feedbackDetails.trim().length === 0) {
            messages.push({
                type: MessageType.ERROR,
                text: 'Please enter case description',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages, validateFeedForm: false, isCaseSubmitted: false });
            return;
        } else {
            this.setState({
                messages: messages,
                validateFeedForm: true
            });
        }
        if (this.state.sectionsWithIssue.trim() === "") {
            messages.push({
                type: MessageType.ERROR,
                text: 'Please select at least one section',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages, validateFeedForm: false, isCaseSubmitted: false });
            return;
        } else {
            this.setState({
                messages: messages,
                validateFeedForm: true
            });
        }

        if (this.state.selectedfile && this.state.selectedfile.ContentBytes && !this.validateFile(this.state.selectedfile.ContentBytes)) {//this condition is for wrong file extension upload
            messages.push({
                type: MessageType.ERROR,
                text: 'Please Select Correct file format.',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages, validateFeedForm: false, isCaseSubmitted: false });
            return;
        } else {
            this.setState({
                messages: messages,
                validateFeedForm: true
            });
        }
        messages = [];
        //let userKey = sessionStorage.getItem('user');
        let userKey = userinfo;
        let user = [];
        if (userKey) {
            user = JSON.parse(userKey);
        }
        var accountIdentifier = "";
        var environment = "";
        var homeAccountIdentifier = "";
        var name = "";
        var userName = "";
        if (user) {
            accountIdentifier = user.accountIdentifier;
            environment = user.environment;
            homeAccountIdentifier = user.homeAccountIdentifier;
            name = user.name;
            userName = user.userName;
        }

        var caseDescription = this.state.feedbackDetails.trim();
        var body = '';
        if (this.state.source === 'AssetAggregation' && this.state.additionalInfo !== '') {
            body =
                "Client details: " + "\n" +
                "Client Name:" + name + "\n" +
                "Client Id:" + this.props.portalid + "\n" +
                "Client Email Id :" + userName + "\n" +
                "Sections with Issue:" + this.state.sectionsWithIssue + "\n" +
                "Case Description :" + caseDescription + "\n" +
                "Additional Information : " + this.state.additionalInfo + "\n" +
                "Technical Information : " +  this.getTechnicalInformation();
        }
        else {
            body =
                "Client details: " + "\n" +
                "Client Name:" + name + "\n" +
                "Client Id:" + this.props.portalid + "\n" +
                "Client Email Id :" + userName + "\n" +
                "Sections with Issue:" + this.state.sectionsWithIssue + "\n" +
                "Case Description :" + caseDescription + "\n" +
                "Technical Information : " +  this.getTechnicalInformation();
        }
        let requestBody = {
            'Subject': 'New Case submitted',
            'Body': body,
            'ToRecipients':  caseManagementEmail && caseManagementEmail === '' ?  'casemanagement@rockco.com' : caseManagementEmail,
        }
        //
        if (this.cancellationToken) {
            this.cancellationToken.cancel('Operation canceled due to new request.')
        }
        this.cancellationToken = axios.CancelToken.source();
        let url = MailURL;

        var body = "Unable to Encrypt the message";
        try {
            body = this.toUTF8Array(JSON.stringify(requestBody));
        }
        catch (e) {
            body = "Unable to Encrypt the message";
        }
        // Encrypt the message
        var bodyFormData = new FormData();
        bodyFormData.append('EmailMessageEncoded', body);
        if (this.state.selectedfile && this.state.selectedfile.ContentBytes && this.state.selectedfile.ContentType && this.state.selectedfile.Name) {
            bodyFormData.append('Attachments.ContentBytes', this.state.selectedfile.ContentBytes);
            bodyFormData.append('Attachments.ContentType', this.state.selectedfile.ContentType);
            bodyFormData.append('Attachments.Name', this.state.selectedfile.Name);
        }
        // Done Encrypting
        Post(url, bodyFormData, this.props.token, this.props.contextjson, this.props.profilejson, this.cancellationToken)
            
            .then(res => {
                if (res.status === 200) {
                    messages.push({
                        type: MessageType.SUCCESS,
                        text: 'Case submitted.',
                        class: MessageClass.SUCCESS
                    })
                    this.setState({
                        messages: messages,
                        isloginchecked: false,
                        islogoutchecked: false,
                        isaccountschecked: false,
                        istransferschecked: false,
                        isdocumentschecked: false,
                        isgeneralchecked: false,
                        iscollaborationchecked: false,
                        ishelpandsupportchecked: false,
                        isbillpaychecked: false,
                        ispreferenceschecked: false,
                        isprintandexportchecked: false,
                        isassetaggregationchecked:false,
                        feedbackDetails: '',
                        sectionsWithIssue:'',
                        selectedfile: null,
                        isCaseSubmitted: true,
                        additionalInfo:''
                    });
                    document.getElementById('file-upload').value = null;
                    _logInformation("Case Manager: Case Submitted");
                    if (this.props && this.props.source !== undefined && this.props.source !== null && this.props.source !== '') {
                        this.props.toggleHelpAndSupportModal();
                    }
                    
                } else {
                    messages.push({
                        type: MessageType.INFO,
                        text: 'Submit Failed.',
                        class: MessageClass.INFO
                    })
                    this.setState({
                        messages: messages,
                        isloginchecked: false,
                        islogoutchecked: false,
                        isaccountschecked: false,
                        istransferschecked: false,
                        isdocumentschecked: false,
                        isgeneralchecked: false,
                        iscollaborationchecked: false,
                        ishelpandsupportchecked: false,
                        isbillpaychecked: false,
                        ispreferenceschecked: false,
                        isprintandexportchecked: false,
                        isassetaggregationchecked: false,
                        feedbackDetails: '',
                        sectionsWithIssue: '',                        
                        selectedfile: null,
                        isCaseSubmitted: true,
                        additionalInfo: ''
                    });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    _logException("Case Manager : Submitting Case : " + (error && error.response) ? error.response : "");
                    console.log(error);
                    messages.push({
                        type: MessageType.ERROR,
                        text: 'Submit Failed.',
                        class: MessageClass.ERROR
                    })
                    this.setState({
                        messages: messages,
                        isCaseSubmitted: true
                    });
                }
            });
        this.setState({ isCaseSubmitted: true })
    }
    validateFile(file) {
        var checkimg = file.name.toLowerCase();
        if (!checkimg.match(/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.mp4|\.MP4|\.bmp|\.BMP|\.doc|\.DOC|\.docx|\.DOCX|\.xls|\.xlsx|\.ppt|\.PPT|\.txt|\.TXT|\.pptx|\.PPTX|\.csv|\.CSV|\.pdf|\.PDF)$/)) {
            return false;
        }
        return true;
    }

    onChangeHandler = event => {
        let files = event.target.files;
        let messages = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            var selectedFile = {
                ContentBytes: file,
                ContentType: file.type,
                Name: file.name
            }
            if (this.validateFile(file)) {
                this.setState({
                    selectedfile: selectedFile,
                    messages:[]
                })
            }
            else {
                messages.push({
                    type: MessageType.ERROR,
                    text: 'Please Select Correct file format.',
                    class: MessageClass.ERROR
                })
                this.setState({ messages: messages, validateFeedForm: false, selectedfile: selectedFile,});
                return;
            }

        }


    }
    render() {
        console.log("Inside render")

        return (
            <ErrorBoundary>
                <CaseManagerView
                    state={this.state}
                    name={this.props.name}
                    selectSection={this.selectSection}
                    changeFeedBack={this.changeFeedBack}
                    submit={this.submit}
                    selectfile={this.onChangeHandler}
                    toggleHelpAndSupportModal={this.props.toggleHelpAndSupportModal }
                />
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state && state.acct && state.acct.token,
        contextjson: state && state.acct && state.acct.contextjson,
        profilejson: state && state.acct && state.acct.profilejson,
        portalid: state && state.acct && state.acct.portalid,
        name: state && state.acct && state.acct.name,
        aaduseroid: state && state.acct && state.acct.aaduseroid,
        profileResp: state && state.acct && state.acct.profileResp
    };
};
const mapDispatchToProps = {

}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({pageName: 'Case Manager'})
)

export default enhancement(CaseManager);
//export default connect(mapStateToProps, mapDispatchToProps)(CaseManager);