import React from 'react'
import { useSelector } from 'react-redux';

const PersonalSecurityContainer = () => {
    const personalSecurityText = 'In an exclusive partnership with Clark International, our clients achieve discreet and trusted world-class personal security, including executive protection, residential and corporate site assessments, travel protection for clients and family members, threat management, and personal security training, among other services. '
    const personalSecurityPdfUrl = useSelector(store => store.env?.personalSecurityPdfURL)
    
    const handlePersonalSecurityPdfClick = () => {
      window.open(personalSecurityPdfUrl)
    }
    
    return (
    <div className="container">
    <img
      id="personal_security_image"
      src="./../images/Clark_International.png"
      alt="Personal Security"
      width="100%"
      className="RCM_health_base_image"
    />
    <div>
      <div className="row RCM_health_container">
        <div className="col col-sm-12 mt-1">
          <div
            className="RCM_Toast RCM_health_toast"
            id="RCM_cyberwa_details"
          >
            <div className="RCM_lifestyle_homeThrive"></div>
            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
              PERSONAL SECURITY
            </div>
            <div className="mt-2 RCM_aviation_toast_content_container">
              {personalSecurityText}
            </div>
            <div className="mt-4 RCM_lifestyle_btns" align="center">
              <button className="btn btn-primary RCM_button_aviation_page" onClick={handlePersonalSecurityPdfClick}>
                CLICK HERE TO VIEW CLARK INTERNATIONAL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
}

export default PersonalSecurityContainer