import React, {
  memo,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";
import { Get } from "../../../common/servicecalls";
import { TeamworkContext } from "../TeamworkContainer";
import { _logException } from "../../../common/_logging";
import DriveItem from "./DriveItem";
import OverlayProcessing from "../../MoneyMovement/common/overlayProcessing";
import CommandBar from "./CommandBar";

const GroupItemList = ({ group, onPreviewClick }) => {
  const { token } = useContext(TeamworkContext);
  const [drives, setDrives] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedDriveItem, setSelectedDriveItem] = useState();
  const [breadcrums, setBreadcrums] = useState([]);
  const [refreshGroup, setRefreshGroup] = useState(false);
  const [selectDefaultGroup, setSelectDefaultGroup] = useState(true);

  const onDriveItemClick = useCallback((driveItem) => {
    if (driveItem && driveItem.folder) {
      setSelectedDriveItem(driveItem);
    }
  }, []);

  const onBreadCrumClick = useCallback((breadcrum, breadcrumIndex) => {
    if (breadcrum && breadcrum.type !== "Group") {
      setBreadcrums((prevState) => {
        if (prevState[prevState.length - 1].name !== breadcrum.drive.name) {
          return prevState.splice(0, breadcrumIndex);
        } else {
          return prevState;
        }
      });
      setSelectedDriveItem(breadcrum.drive);
    } else if (breadcrum && breadcrum.type === "Group") {
      setSelectedDriveItem();
      setRefreshGroup((prevState) => !prevState);
    }
  }, []);

  const onRefresh = useCallback(() => {
    const url = selectedDriveItem
      ? `https://graph.microsoft.com/v1.0/drives/${selectedDriveItem.parentReference.driveId}/items/${selectedDriveItem.id}/children`
      : `https://graph.microsoft.com/v1.0/groups/${group.id}/drive/root/children`;
    setError();
    setIsLoading(true);
    Get(url, token)
      .then((res) => {
        setDrives(
          res && res.data && res.data.value ? [...res.data.value] : undefined
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(`We are unable to pull documents`);
        _logException(err);
      });
  }, [group.id, selectedDriveItem, token]);

  useEffect(() => {
    const url = `https://graph.microsoft.com/v1.0/groups/${group.id}/drive/root/children`;
    setError();
    setIsLoading(true);
    setSelectedDriveItem();
    setBreadcrums([]);
    Get(url, token)
      .then((res) => {
        setDrives(
          res && res.data && res.data.value ? [...res.data.value] : undefined
        );
        setBreadcrums((prevState) => [
          ...prevState,
          { name: "Documents", type: "Group", drive: group },
        ]);
        setIsLoading(false);

        //select general group
        setSelectDefaultGroup((prevState) => {
          if (prevState) {
            // Check if group conatins "General drive"
            if (
              res &&
              res.data &&
              res.data.value &&
              Array.isArray(res.data.value) &&
              res.data.value.length > 0
            ) {
              const generalDrives = res.data.value.filter(
                (drive) => drive.name === "General"
              );
              if (generalDrives && generalDrives.length > 0) {
                setSelectedDriveItem(generalDrives[0]);
              }
            }
            return false;
          } else {
            return prevState;
          }
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setDrives();
        setError(`We are unable to pull documents`);
        _logException(err);
      });
  }, [group, token, refreshGroup]);

  useEffect(() => {
    if (selectedDriveItem && selectedDriveItem.folder) {
      const url = `https://graph.microsoft.com/v1.0/drives/${selectedDriveItem.parentReference.driveId}/items/${selectedDriveItem.id}/children`;
      setError();
      setIsLoading(true);
      Get(url, token)
        .then((res) => {
          setDrives(
            res && res.data && res.data.value ? [...res.data.value] : undefined
          );
          setBreadcrums((prevState) => [
            ...prevState,
            {
              name: selectedDriveItem.name,
              type: "Drive",
              drive: selectedDriveItem,
            },
          ]);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(`We are unable to pull items for ${selectedDriveItem.name}`);
          _logException(err);
        });
    }
  }, [selectedDriveItem, token]);

  return (
    <>
      <div>
        {error && <div>{error}</div>}
        {isLoading && <OverlayProcessing />}
        <CommandBar
          breadcrums={breadcrums}
          onBreadCrumClick={onBreadCrumClick}
          selectedDriveItem={selectedDriveItem}
          group={group}
          onRefresh={onRefresh}
        />
        {drives && Array.isArray(drives) && drives.length > 0 && (
          <>
            {drives.map((drive, index) => {
              return (
                <DriveItem
                  key={index}
                  drive={drive}
                  selectDriveItem={onDriveItemClick}
                  onPreviewClick={onPreviewClick}
                />
              );
            })}
          </>
        )}
        {drives && Array.isArray(drives) && drives.length === 0 && (
          <div className="RCM_Teamwork_NoItem">No items exist</div>
        )}
      </div>
    </>
  );
};

export default memo(GroupItemList);
