import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Post } from '../../common/servicecalls.jsx';
import { FADetailsView } from './fadetailsview'
import { FeedbackURL, MailURL, MessageType, MessageClass } from '../../common/constants.jsx';
import axios from 'axios';
import ErrorBoundary from '../../common/errorboundary.jsx';
import { _logException, _logInformation, _logPageView } from '../../common/_logging.js';
import { withPageView } from '../../hoc/withPageView.jsx';
import { userinfo } from '../../../App.js'
class FADetails extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            showFADetails: false
        }
    }
    

    fadata = {}    

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
        }
    }

    componentDidMount() {
        let contactnumbers = [];
        
        if (this.props.fadata) {
            if (this.props.fadata.contactnumber && this.props.fadata.contactnumber.length >= 1)
            {
                this.props.fadata.contactnumber.map((contact, index) => {
                    if (index < 2) {    // showing max two contact numbers
                        if (contact !== null && contact !== undefined && contact !== '')
                            contactnumbers.push(contact);
                    }
                });
            }
        } 
        this.fadata.faname = this.props.fadata && this.props.fadata.faname;
        this.fadata.contactnumber = contactnumbers;
        this.fadata.email = this.props.fadata && this.props.fadata.email;
        this.fadata.website = this.props.fadata && this.props.fadata.website;
        if (this.fadata.faname !== undefined && this.fadata.faname !== null && this.fadata.faname !== '') {
            this.setState({ fadata: this.fadata, showFADetails: true });
        }
        if (this.fadata.contactnumber && this.fadata.contactnumber !== undefined && this.fadata.contactnumber !== null && this.fadata.contactnumber.length >= 1) {
            this.setState({ fadata: this.fadata, showFADetails: true });
        }
        if (this.fadata.email !== undefined && this.fadata.email !== null && this.fadata.email !== '') {
            this.setState({ fadata: this.fadata, showFADetails: true });
        }
        this.setState({ fadata: this.fadata });
    }
    
    render() {
        console.log("Inside render")
        return (
            <ErrorBoundary>
                <FADetailsView
                    fadata={this.state.fadata}
                    showFADetails = {this.state.showFADetails}
                    call={this.call}
                    sendemail={this.sendemail}
                />
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        fadata: state.acct.fadata,
    };
};
const mapDispatchToProps = {

}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhancement(FADetails);