import React, { useEffect, useMemo, useRef } from "react";
import * as Highcharts from "highcharts";
import { formatMoney } from "../constants";

const EstimatedIncomeHighChart = ({ data }) => {
  const chartContainer = useRef();
  const categories = useMemo(() => ["dividend", "interest", "capGain"], []);
  const colors = useMemo(() => ["#13A5B0", "#C4E1B2", "#B3E3F4"], []);
  const xAxisCategories = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x) => x.month);
    } else {
      return [];
    }
  }, [data]);
  const seriesData = useMemo(() => {
    if (data && Array.isArray(data)) {
      return categories.map((x, i) => ({
        name: x,
        data: data.map((y) => y[x] || 0),
        color: colors[i],
      }));
    } else {
      return [];
    }
  }, [data, categories, colors]);

  const getCategoryLabel = (key) => {
    switch (key) {
      case "interest": {
        return "Interest";
      }
      case "dividend": {
        return "Dividend";
      }
      case "capGain": {
        return "Capital Gain Dist.";
      }
      default: {
        return "";
      }
    }
  };

  useEffect(() => {
    Highcharts.chart(chartContainer.current, {
      chart: {
        type: "column",
        backgroundColor: "transparent",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: xAxisCategories,
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return `<span style="color: #666; font-size: 0.75rem; font-weight: normal;">${formatMoney(
              this.total
            )}</span>`;
          },
          useHTML: true,
        },
        gridLineWidth: 0,
        plotLines: [
          {
            color: "#666",
            width: 1,
            value: 0,
            dashStyle: "Dash",
          },
        ],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return `<b>${getCategoryLabel(this.key)}</b><br/>${
            this.series.name
          } <b>${formatMoney(this.point.y)}</b> <br/> Total: <b>${formatMoney(
            this.total
          )}</b>`;
        },
        backgroundColor: "#000",
        style: {
          color: "#fff",
          fontFamily: "Avenir",
        },
        outside: true
      },
      plotOptions: {
        column: {
          stacking: "normal",
          borderWidth: 0,
        },
      },
      series: seriesData,
    });
  }, [seriesData, xAxisCategories]);

  return (
    <div ref={chartContainer}>this will be used for estimated income chart</div>
  );
};

export default React.memo(EstimatedIncomeHighChart, (prevProps, nextProps) => {
  return JSON.stringify(prevProps || {}) === JSON.stringify(nextProps || {})
    ? true
    : false;
});
