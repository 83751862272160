export class TransfersErrors {
    fromAccountErrorMsg;
    toAccountErrorMsg;
    transferDateErrorMsg;
    transferAmountErrorMsg;
    contributionYearErrorMsg;
    constructor() {
        this.fromAccountErrorMsg = "";
        this.toAccountErrorMsg = "";
        this.transferDateErrorMsg = "";
        this.transferAmountErrorMsg = "";
        this.contributionYearErrorMsg = "";
    }
}


export const errorObjTransfers = {
    fromAccount: {
        type: "ERROR",
        text: 'Please select from account',
        class: "RCM_validation_error_text"
    },
    toAccount: {
        type: "ERROR",
        text: 'Please select to account',
        class: "RCM_validation_error_text"
    },
    transferDate: {
        type: "ERROR",
        text: 'Please provide transfer date',
        class: "RCM_validation_error_text"
    }, 
    transferAmount: {
        type: "ERROR",
        text: "Please enter transfer amount",
        class: "RCM_validation_error_text"
    },   
    contributionYear: {
        type: "ERROR",
        text: "Please select contribution year",
        class: "RCM_validation_error_text"
    }   
};
