import React, { Component } from 'react';
import { Get, Post } from '../../../../common/servicecalls.jsx';
import { formatMoney, HoldingssummaryURL, roundvalue, TopTenHoldingPct, MsgForFailedData, GetNumericValue, GetPreferenceValue, PreferencesUrl } from './../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddTopten } from '../../../../actions/toptenactions.jsx';
import { spinner, MsgForNoData, CheckIfProspectClient } from '../../../../common/constants.jsx';
import axios from 'axios';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { TopTenSummaryView } from './toptenview.jsx';
import { updatePreference } from "../../../../actions/accountsactions.jsx";
import { toptenholdingsummaryDummyResponse, RRR_123456_toptenholdingsummaryDummyResponse, RRR_234567_toptenholdingsummaryDummyResponse } from './../../accountsmockresponse.js';
class Holdingssummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false,
            holdingsresponse: this.props.toptensummary,
            accounts: this.props.selectedaccount,
            portalid: this.props.portalid,
            isloading: true,
            showTable: false,
            showChart: true,
            tooltipOpen: false,
            tooltipOpen1: false,
            isProspectClient: CheckIfProspectClient(this.props && this.props.role)
        }
        this.execute = this.execute.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggleShowTable = this.toggleShowTable.bind(this);
    }
    tthcanellationToken = null;
    execute = (showtable, acct) => {
        this.setState(() => ({ isloading: true, requestFailed: false }));

        const baseUrl = HoldingssummaryURL + '\'' + "" + '\',' + null + ')';
        if (this.tthcanellationToken) {
            this.tthcanellationToken.cancel('Operation canceled due to new request.')
        }
        this.tthcanellationToken = axios.CancelToken.source();
        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.tthcanellationToken)
            .then(result => {
                this.setState(() => ({ holdingsresponse: result.data, isloading: false, requestFailed: false, showTable: showtable }))
                this.props.AddTopten(result.data)
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailed: true, error: error.message, showTable: showtable }))
                }
            })
    };

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            let showtable = GetPreferenceValue(this.props.acct, "Top10Holdings", "chart") === "table" ? true : false;
            if (this.state.isProspectClient) {
                this.setDataForProspectClient(showtable)
            }
            else {
                this.execute(showtable, this.props.selectedaccount);
            }
           
        }

    }
    componentWillUnmount() {
        if (this.tthcanellationToken !== null && this.tthcanellationToken !== undefined) {
            this.tthcanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }
    componentDidMount() {
        let showtable = GetPreferenceValue(this.props.acct, "Top10Holdings", "chart") === "table" ? true : false
        if (this.state.isProspectClient) {
            this.setDataForProspectClient(showtable)
        }
        else {
            if (this.state.holdingsresponse === null)
                this.execute(showtable, this.state.accounts);
            else
                this.setState(() => ({ isloading: false, showTable: showtable }))
        }
       
    }
    toggle() {
        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen
        }));
    }
    toggle1() {
        this.setState(prevState => ({
            tooltipOpen1: !prevState.tooltipOpen1
        }));
    }
    toggleShowTable() {
        this.setState(prevState => ({ showTable: !prevState.showTable }),
            () => {
                this.updatePreferenceCallBack();
            }
        )
    }
    setDataForProspectClient(showtable) {
        if (this.props.toptensummary === null) {
            setTimeout(() => {
                if (this.props.selectedaccount.length == 1) {
                    if (this.props.selectedaccount[0].key === "RRR-123456") {
                        this.setState(() => ({ holdingsresponse: RRR_123456_toptenholdingsummaryDummyResponse, isloading: false, areDataRetrieved: true }), () => { this.props.AddTopten(RRR_123456_toptenholdingsummaryDummyResponse); });
                    }
                    else if (this.props.selectedaccount[0].key === "RRR-234567") {
                        this.setState(() => ({ holdingsresponse: RRR_234567_toptenholdingsummaryDummyResponse, isloading: false, areDataRetrieved: true }), () => { this.props.AddTopten(RRR_234567_toptenholdingsummaryDummyResponse); });
                    }
                } else if (this.props.selectedaccount.length == 2) {
                    this.setState(() => ({ holdingsresponse: toptenholdingsummaryDummyResponse, isloading: false, areDataRetrieved: true }), () => { this.props.AddTopten(toptenholdingsummaryDummyResponse); });
                }
                document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
            }, 100);
        }
        else {
            this.setState({
                isloading: false, showtable: showtable
            });
        }
    }
    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.tthcanellationToken = axios.CancelToken.source();
        let componentjson = [];
        componentjson.push({ "key": "Top10Holdings", "value": this.state.showTable ? "table" : "chart" })
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.acct.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            let toptenpreferencevalue = reqObj1.component.filter(component => component.key === "Top10Holdings");
            if (toptenpreferencevalue && toptenpreferencevalue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "Top10Holdings")].value = this.state.showTable ? "table" : "chart";
            } else {
                reqObj1.component.push({ "key": "Top10Holdings", "value": this.state.showTable ? "table" : "chart" });
            }

        }
        if (this.props.role !== "Prospect") {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.tthcanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        this.props.updatePreference(reqObj1);
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                });
        }
    }
    render() {
        if (this.state.requestFailed) return <p className="RCM_data_error">{MsgForFailedData}</p>

        if (this.state.isloading) return <div className="RCM_spinner">Loading Top 10 Holdings{spinner()}</div>

        //if (!this.state.holdingsresponse || !this.state.holdingsresponse.value || !this.state.holdingsresponse.value[0] || !this.state.holdingsresponse.value[0].holdingdetails)
        //    return <p className="RCM_data_error">{MsgForNoData}</p>

        const { showTable } = this.state;
        const rowClasses = (row, rowIndex) => {
            if (rowIndex % 2 == 0)
                return 'RCM_Table_row_odd';
            else
                return 'RCM_Table_row_even';
        };


        var chartrows = [];
        var datarows = [];
        var datatth = [];
        if (this.state.holdingsresponse && this.state.holdingsresponse.value) {
            this.state.holdingsresponse && this.state.holdingsresponse.value.map((d, i) => chartrows.push(
                { name: 'Recent Market Value', value: d.recentmktval && d.recentmktval > 0 ? formatMoney(roundvalue(d.recentmktval, 2)) : GetNumericValue(d.recentmktval) },
                { name: 'Cost basis', value: d.costbasis && d.costbasis > 0 ? formatMoney(roundvalue(d.costbasis, 2)) : GetNumericValue(d.costbasis) },
                { name: 'Gain Loss Amount', value: d.gainlossamt && d.gainlossamt > 0 ? formatMoney(roundvalue(d.gainlossamt, 2)) : GetNumericValue(d.gainlossamt) },
                { name: 'Gain Loss Percentage', value: d.gainlosspct && d.gainlosspct > 0 ? roundvalue(d.gainlosspct, 2) + '%' : GetNumericValue(d.gainlosspct, true) },
                { name: 'Today change', value: d.todaychangeamt ? formatMoney(roundvalue(d.todaychangeamt, 2)) : GetNumericValue(d.todaychangeamt) },
                { name: 'Today change percent', value: d.todaychangepct ? roundvalue(d.todaychangepct, 2) + '%' : GetNumericValue(d.todaychangepct, true) }
            )
            )
       
        if (this.state.holdingsresponse && this.state.holdingsresponse.value[0] && this.state.holdingsresponse.value[0].holdingdetails) {
            this.state.holdingsresponse && this.state.holdingsresponse.value[0].holdingdetails.map((d, i) => datarows.push({
                name: d.symbol,
                description: d.SECNAME,
                value: d.recentmktval,
                glvalue: d.gainlossamt,
                glpercent: d.gainlosspct
            }
            )
            );
        }
        if (this.state.holdingsresponse && this.state.holdingsresponse.value[0] && this.state.holdingsresponse.value[0].holdingdetails) {
            this.state.holdingsresponse && this.state.holdingsresponse.value[0].holdingdetails.map((d, i) => datatth.push({
                symbol: d.symbol,
                todaytotalpct: (d.todaytotalpct * 100)
            }
            ));
            }
        }
        var grandtotal = 0;
        if (datatth) {
            datatth.map((grandtot, index) => {
                grandtotal = parseFloat(grandtotal) + parseFloat(grandtot.todaytotalpct);
            })
        }
        if (datatth.length !== 0) {
            var remaindertodaytotalpct = TopTenHoldingPct - parseFloat(grandtotal);
            datatth.push(
                {
                    "symbol": "Other",
                    "todaytotalpct": (remaindertodaytotalpct)
                }
            );
        }
        return (
            <ErrorBoundary>
                <TopTenSummaryView
                    state={this.state}
                    datarows={datarows}
                    chartrows={chartrows}
                    datatth={datatth}
                    rowClasses={rowClasses}
                    showTable={showTable}
                    toggleShowTable={this.toggleShowTable}
                    toggle={this.toggle}
                    toggle1={this.toggle1}
                >
                </TopTenSummaryView>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        toptensummary: state.topten.toptensummary,
        portalid: state.acct.portalid,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        acct: state.acct,
        preferences: state.acct.preferences,
        profileId: state.acct.profileId,
        role:state.acct.role
    };
};


const mapDispatchToProps = {
    AddTopten,
    updatePreference
};

export default connect(mapStateToProps, mapDispatchToProps)(Holdingssummary);