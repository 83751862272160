import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, MessageType, MessageClass, basicAlphaNumeric, discriptionFormat, viewOnlyText } from '../../../../../common/constants.jsx';
import Messages from '../../../../../common/messages.jsx';
import { HistoryManualAsset } from './history';
import cloneDeep from 'lodash/cloneDeep';
class Tangibles extends Component {

    constructor(props) {
        super(props);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleInitialValueAsOfChange = this.handleInitialValueAsOfChange.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.typesOfAccountsToggle = this.typesOfAccountsToggle.bind(this);
        this.typesOfAccountsChange = this.typesOfAccountsChange.bind(this);
        this.pricingFrequencyToggle = this.pricingFrequencyToggle.bind(this);
        this.pricingFrequencyChange = this.pricingFrequencyChange.bind(this);
        this.deleteHistory = this.deleteHistory.bind(this);
        this.toggle = this.toggle.bind(this);
        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            displaytotalvalue: '',
            displayinitialvalue: '',
            activeTab: '1',
            intialAccount: {},
            formStateChange: false,
            formValueChange: false,
            messages: [],
            typesOfAccounts: [
                {
                    "assetName": " ",
                    "assetDisplayName": " "
                },
                {
                    "assetName": "Gold",
                    "assetDisplayName": "Gold"
                },
                {
                    "assetName": "Wine",
                    "assetDisplayName": "Wine"
                },
                {
                    "assetName": "Horses",
                    "assetDisplayName": "Horses"
                },
                {
                    "assetName": "Watches",
                    "assetDisplayName": "Watches"
                },
                {
                    "assetName": "Art",
                    "assetDisplayName": "Art"
                },
                {
                    "assetName": "Jewelry",
                    "assetDisplayName": "Jewelry"
                },
                {
                    "assetName": "Collectibles",
                    "assetDisplayName": "Collectibles"
                },
                {
                    "assetName": "Other",
                    "assetDisplayName": "Other"
                }


            ],
            PricingFrequencyList:
                ['Semi – Annually', 'Annually', 'Other'],
            isTypesOfAccountsOpen: false,
            isPricingFrequencyOpen: false,

            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: 'Tangibles',
                    description: '',
                    name: '',
                    subassettype: ''
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date(),
                    sourceofvaluation: '',
                    pricingfrequency: '',
                    pricingcurrency: '',
                    initialprice: '',
                    initialpricedate: new Date(),
                    sourceofinitialvaluation: '',
                    otherpricingfrequency: ''
                },
                history: [

                ]
            }


        };

    }
    getDateString = date => {
        if (date instanceof Date) {
            var dd = date.getDate();
            var mm = date.getMonth() + 1;

            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            var returnString = mm + '/' + dd + '/' + yyyy;
            return returnString;
        } else
            return date;
    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displaytotalvalue = "";
            var displayinitialvalue = "";

            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            if (this.props.account.value.initialprice !== undefined && this.props.account.value.initialprice !== null && this.props.account.value.initialprice != '')
                displayinitialvalue = parseFloat(this.props.account.value.initialprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({ account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue, displayinitialvalue: displayinitialvalue });
        } else if (this.props.mode === 'add') {
            var account = this.state.account;
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount, account: account });
        }
    }
    componentDidUpdate(prevProps) {

        if (prevProps.source !== this.props.source) {
            if (this.props.mode === 'edit' && this.props.account) {
                var displaytotalvalue = "";
                var displayinitialvalue = "";

                if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                    displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (this.props.account.value.initialprice !== undefined && this.props.account.value.initialprice !== null && this.props.account.value.initialprice != '')
                    displayinitialvalue = parseFloat(this.props.account.value.initialprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var initailAccount = JSON.parse(JSON.stringify(this.props.account));
                this.setState({ account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue, displayinitialvalue: displayinitialvalue });
            } else if (this.props.mode === 'add') {
                var initailAccount = JSON.parse(JSON.stringify(this.state.account));
                this.setState({ intialAccount: initailAccount });
            }
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    typesOfAccountsToggle() {
        this.setState(prevState => ({
            isTypesOfAccountsOpen: !prevState.isTypesOfAccountsOpen
        }))
    }
    typesOfAccountsChange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.info.subassettype = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }
    pricingFrequencyToggle() {
        this.setState(prevState => ({
            ispricingFrequencyOpen: !prevState.ispricingFrequencyOpen
        }))
    }
    pricingFrequencyChange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        if (clonedState.account.value.pricingfrequency === "Other" && statevalue !== "Other") {
            clonedState.account.value.otherpricingfrequency = "";
        }
        clonedState.account.value.pricingfrequency = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }

    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleInitialValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.initialpricedate = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleFormChanges(evt) {

        if (evt.target.name === "custodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.custodianname = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.nickname = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "name" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.accountnumber = evt.target.value;
            clonedState.account.info.name = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            let clonedState = Object.assign({}, this.state);
            clonedState.displaytotalvalue = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "initialvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            let clonedState = Object.assign({}, this.state);
            clonedState.displayinitialvalue = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.description = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === 'pricingcurrency') {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.pricingcurrency = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "otherpricingfrequency" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.otherpricingfrequency = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "totalvaluesource" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.sourceofvaluation = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "initialvaluesource" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.sourceofinitialvaluation = evt.target.value;
            this.setState(clonedState);
        }

        this.checkStateChange();
    }

    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "totalvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.setState(clonedState);
            }
            else if (evt.target.name === "initialvalue") {
                clonedState.account.value.initialprice = evt.target.value;
                clonedState.displayinitialvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.setState(clonedState);
            }
        } else {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "totalvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
                this.setState(clonedState);
            }
            else if (evt.target.name === "initialvalue") {
                clonedState.account.value.initialprice = evt.target.value;
                this.setState(clonedState);
            }
        }
        this.checkStateChange();
    }

    handleAmmountOnFocus(evt) {
        const clonedState = Object.assign({}, this.state);
        if (evt.target.name === "totalvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displaytotalvalue = ""
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
            }
        } else if (evt.target.name === "initialvalue") {
            if (clonedState.account.value.initialprice === "") {
                clonedState.displayinitialvalue = "";
                clonedState.account.value.initialprice = "";
            } else {
                clonedState.displayinitialvalue = clonedState.account.value.initialprice;
            }

        }
        this.setState(clonedState);
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }


    deleteHistory(valueasof) {
        let arr = cloneDeep(this.state.account.history);
        var indexOfHistory = -1;
        arr.findIndex(function (obj, i) {
            if (obj.valueasof === valueasof) {
                indexOfHistory = i;
            }
        });
        if (indexOfHistory >= 0) {
            arr.splice(indexOfHistory, 1);
        }
        let clonedState = Object.assign({}, this.state);
        clonedState.account.history = arr;
        this.setState(clonedState)
    }

    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
        let messages = [];
        if (this.state.account.value.totalvalue !== this.state.intialAccount.value.totalvalue || this.state.account.value.valueasof !== this.state.intialAccount.value.valueasof
            || this.state.account.value.initialprice !== this.state.intialAccount.value.initialprice || this.state.account.value.initialpricedate !== this.state.intialAccount.value.initialpricedate) {

            messages.push({
                type: MessageType.INFO,
                text: 'Value changes will be shown here after Submit',
                class: MessageClass.INFO
            })
            this.setState({
                formValueChange: true, messages: messages
            });
        }
        else {
            this.setState({
                formValueChange: false, messages: messages
            });
        }
    }

    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_tangibles_form">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                        >
                            STEP 1 : BASIC INFORMATION
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('2'); }} id="RCM_history_info"
                        >
                            STEP 2 : HISTORY
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                    <TabPane tabId="1">
                        <div>

                            <Form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_assetClass">
                                                <Label for="type">Asset Class</Label>
                                                <Dropdown name="type" required
                                                    isOpen={this.state.isTypesOfAccountsOpen}
                                                    toggle={this.typesOfAccountsToggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.info.subassettype}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.typesOfAccounts.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.typesOfAccountsChange(item.assetName)}>
                                                                        <div>{item.assetDisplayName}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridAccount" id="RCM_name">
                                                <Label for="name">Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="name"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.accountnumber}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                                <Label for="nickname">Nickname</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="nickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.nickname}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_initialprice">
                                                <Label for="initialvalue">Initial Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="initialvalue"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displayinitialvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_initialpricedate">
                                                <div><Label for="initialvalueasof">Initial Value Date (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="initialvalueasof" id="initialvalueasof"
                                                    value={new Date(this.state.account.value.initialpricedate)}
                                                    onChange={this.handleInitialValueAsOfChange}
                                                    selected={(this.state.account.value.initialpricedate !== undefined && this.state.account.value.initialpricedate !== null
                                                        && this.state.account.value.initialpricedate !== '') ? new Date(this.state.account.value.initialpricedate) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridValueSource" id="RCM_sourceofinitialvaluation">
                                                <Label for="initialvaluesource">Source of Initial Valuation</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="initialvaluesource"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.sourceofinitialvaluation}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                                <Label for="totalvalue">Total Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="totalvalue"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displaytotalvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="valueasof" id="valueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    onChange={this.handleValueAsOfChange}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridValueSource" id="RCM_sourceofvaluation">
                                                <Label for="valuesource">Source of Valuation</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="totalvaluesource"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.sourceofvaluation}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="pricingfrequency">Pricing Frequency</Label>
                                                <Dropdown name="pricingfrequency" required
                                                    isOpen={this.state.ispricingFrequencyOpen}
                                                    toggle={this.pricingFrequencyToggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.value.pricingfrequency}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.PricingFrequencyList.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.pricingFrequencyChange(item)}>
                                                                        <div>{item}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        {this.state.account.value.pricingfrequency === 'Other'
                                            ? <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="otherpricingfrequency">Specify Other Pricing Frequency</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="otherpricingfrequency"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.otherpricingfrequency}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            : ''
                                        }
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridpricingcurrency">
                                                <Label for="pricingcurrency">Pricing Currency</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="pricingcurrency"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.pricingcurrency}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="description"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.info.description}
                                                onChange={this.handleFormChanges}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                    </div>

                                </div>

                            </Form>
                        </div>

                    </TabPane>
                    <TabPane tabId="2">
                        <div>
                            <Form>

                                <div className="row">
                                    {this.state.formValueChange &&
                                        this.state.messages.length > 0 ?
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <Messages messages={this.state.messages} containerClass="RCM_validation_error_container" />
                                        </div>
                                        : ''
                                    }

                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <HistoryManualAsset
                                            source='tangibles'
                                            data={this.state.account.history}
                                            deleteHistory={this.deleteHistory}
                                            canedit={this.state.account.canedit !== undefined ? this.state.account.canedit : ''} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                    </div>
                                </div>

                            </Form>

                        </div>

                    </TabPane>
                    {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                        ?
                        < div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                {viewOnlyText}
                            </div>
                        </div>
                        : ""
                    }
                </TabContent>
            </div>


        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Tangibles);