import React, { Component } from 'react';
import { roundvalue, formatMoney, BalancesdetailURL, spinner, GetAccountsList, MsgForFailedData, getAccountDisplayName, IndLongShort } from '../../../../common/constants.jsx';
import Liabilities from '../../detail/liabilities/liabilities.jsx';
export function AssetView(
    props
) {



    let assetview = (
        <div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                <li className="RCM_asset_li RCM_asset_li_header">
                                        <div className="row">
                                            <div className="col-sm-3 RCM_removepadding_right">
                                                <label className="RCM_asset_label RCM_asset_accountCol">Account</label>
                                               {
                                                    
                                                    props.state.columnToSort ==="Account"
                                                        ?props.state.sortOrder ==="asc"
                                                            ?<span id="Account_desc"  className="RCM_Sort_arrow_down_icon"  onClick = {props.sortColumnType}></span>
                                                                :<span id = "Account_asc"  className="RCM_Sort_arrow_up_icon"  onClick = {props.sortColumnType}></span>
                                                        : <span className="RCM_Sort_arrow_icon" id = "Account_asc" onClick = {props.sortColumnType}></span>
                                                }
                                               
                                               {/*  <span className="RCM_Sort_arrow_up_icon"></span>
                                                <span className="RCM_Sort_arrow_down_icon"></span> */}
                                            </div>
                                            <div className="col-sm-5 RCM_removepadding_right">
                                                <div className="RCM_asset_lidivright RCM_assetdiv2">
                                                    <label className="RCM_asset_label">Total Value</label>
                                                    {
                                                    
                                                    props.state.columnToSort ==="TotalVal"
                                                        ?props.state.sortOrder ==="asc"
                                                            ?<span id="TotalVal_desc"  className="RCM_Sort_arrow_down_icon"  onClick = {props.sortColumnType}></span>
                                                                :<span id = "TotalVal_asc"  className="RCM_Sort_arrow_up_icon"  onClick = {props.sortColumnType}></span>
                                                        : <span className="RCM_Sort_arrow_icon" id = "TotalVal_asc" onClick = {props.sortColumnType}></span>
                                                }
                                                </div>
                                            </div>
                                            <div className="col-sm-4  RCM_removepadding_right">
                                                <div className="RCM_asset_lidivright RCM_assetdiv3">
                                                    <label className="RCM_asset_label">Today's Change</label>
                                                    {
                                                    
                                                    props.state.columnToSort ==="TodaysChange"
                                                        ?props.state.sortOrder ==="asc"
                                                            ?<span id="TodaysChange_desc"  className="RCM_Sort_arrow_down_icon"  onClick = {props.sortColumnType}></span>
                                                                :<span id = "TodaysChange_asc"  className="RCM_Sort_arrow_up_icon"  onClick = {props.sortColumnType}></span>
                                                        : <span className="RCM_Sort_arrow_icon" id = "TodaysChange_asc" onClick = {props.sortColumnType}></span>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
               
                    <div className="RCM_Dashboard_Tables RCM_CM_scroll RCM_assetwrapper" key="bs5">
                        {
                            props.state.areDataRetrieved && !props.state.isloading
                                ?
                                <ul className="RCM_asset_ul">
                                   

                                    {
                                        props.rows && props.rows.length !== 0 ?
                                            props.rows.map((rows, index) =>
                                                <li className="RCM_asset_li" key={"asset_" + index} onClick={() => props.redirecttoholdings(rows.key)}>
                                                    <div className="row">
                                                        <div className="col-sm-4 RCM_removepadding_right">

                                                            <div className="RCM_assetdiv1" >
                                                                <div className="RCM_assetacc">{props.acctFormatter(props.selectedaccount, rows.accountaumber, rows.key)}</div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-4 RCM_removepadding_right">
                                                            <div className="RCM_asset_lidivright RCM_assetdiv2">
                                                                <div className="RCM_asset_innerli">
                                                                    <span className="RCM_assetval">{rows.totalaccountvalue}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 RCM_removepadding_right">
                                                            <div className="RCM_asset_lidivright RCM_assetdiv3">
                                                                <div className="RCM_assetspantot">
                                                                    <span className={"RCM_positiveval " + props.getClassName((rows.totalaccountvaluechange))}>
                                                                        {(rows.totalaccountvaluechange === undefined || rows.totalaccountvaluechange === null || rows.totalaccountvaluechange == 0) ? '$0' :
                                                                            formatMoney(roundvalue(rows.totalaccountvaluechange, 2))}</span>&nbsp;/&nbsp;
                                                                <span className={"RCM_positiveval " + props.getClassName((rows.totalaccountvaluepercentchange))}>
                                                                        {(rows.totalaccountvaluepercentchange === undefined || rows.totalaccountvaluepercentchange === null || rows.totalaccountvaluepercentchange == 0) ? '0%' :
                                                                            `${roundvalue(rows.totalaccountvaluepercentchange, 2)}%`}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ) : <div>No data available</div>}
                                </ul> : <div className="RCM_spinner">Loading Account Overview{spinner()}</div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )

    return assetview
}
