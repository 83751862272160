import React from 'react';
import { MsgForNoData } from '../../../common/constants.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';

export function DeleteModalPopup(props) {

    let deletemodalpopup = (
        <div>
            {props && props.state ?
                    <Modal isOpen={props.state.deleteModal} toggle={props.toggleDeleteModal} 
                    className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                        zIndex='9999'
                        centered >
                        <ModalHeader toggle={props.toggleDeleteModal} className="RCM_externalAssetModel_header RCM_manage_group_header">
                            {(props.state.deleteRow && props.state.deleteRow.isAccountRemove !== true  ? <label className="RCM_tile_heading">REMOVE GROUP</label> :
                                <label className="RCM_tile_heading">REMOVE ACCOUNT</label>)}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                            {(props.state.deleteRow && props.state.deleteRow.isAccountRemove !== true ? ((props.state.deleteRow.accounts && props.state.deleteRow.accounts.length) ?
                                <Label for="groupname">Are you sure you want to remove group: {props.state.deleteRow.groupname}, it has {props.state.deleteRow.accounts.length} accounts?</Label>
                                : <Label for="groupname">Are you sure you want to remove group: {props.state.deleteRow.groupname}?</Label>)
                                : <Label for="groupname">Are you sure you want to remove account: {props.state.deleteRow.number} from group: {props.state.deleteRow.groupname}?</Label>
                            )}
                            </FormGroup>
                        </ModalBody>
                    <ModalFooter className="RCM_manage_group_footer">
                        <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.toggleDeleteModal}>CANCEL</button> {' '}
                            {(props.state.deleteRow && props.state.deleteRow.isAccountRemove !== true ?
                                <button type="button" className="btn btn-primary RCM_button_primary" onClick={props.deleteGroup}>REMOVE GROUP</button>
                                : <button type="button" className="btn btn-primary RCM_button_primary" onClick={props.deleteAccount}>REMOVE ACCOUNT</button>
                            )}
                        </ModalFooter>
                    </Modal>
            
                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return deletemodalpopup
}



