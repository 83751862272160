import React, { Component } from 'react';
import { Get, Post } from '../../../../common/servicecalls.jsx';
import { AssetallocationURL, spinner, MsgForFailedData, PreferencesUrl, GetPreferenceValue, CheckIfProspectClient, GetPreferencePilotValue, assetSchemaColorsConfigUrl } from './../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddAA, UpdateAssetSchemaAssetSummary, AddAssetColor, UpdateHistoricalAssetSchema } from '../../../../actions/accountsactions.jsx';
import { UpdateAssetSchema } from '../../../../actions/toptenactions.jsx';
import axios from 'axios';
import { AssetAllocationSummaryView } from './assetallocationview.jsx'
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { updatePreference } from "../../../../actions/accountsactions.jsx";
import { assetallocationDummyResponse, RRR_123456_assetallocationDummyResponse, RRR_234567_assetallocationDummyResponse, assetAllocationColorRes } from '../../accountsmockresponse.js';
import { getSchemaValue, assetClassDropDownItems } from '../../../../common/assetschemautilities';
import { renderRowsByCategory } from './assetallocationcolumns';
class Assetallocation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false,
            assetallocationresponse: this.props.assetallocationsummary,
            accounts: this.props.selectedaccount,
            portalid: this.props.portalid,
            render: this.props.render,
            isloading: true,
            isLoadingAssetColor: false,
            requestFailedAssetColor: false,
            showTable: false,
            showChart: true,
            tooltipOpen: false,
            tooltipOpen1: false,
            isProspectClient:CheckIfProspectClient(this.props && this.props.role),
            classDropdownOpen: false,
            dropdownClassValue: this.props.assetSchema,
            showAssetClassOption: this.assetClassViewPreference(this.props),
            assetcolorRes: this.props.assetschemacolorsconfig
        }
        this.execute = this.execute.bind(this);
        this.executeAssetColor = this.executeAssetColor.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggleShowTable = this.toggleShowTable.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.changeClass = this.changeClass.bind(this);
    }

    toggleClass() {
        this.setState(prevState => ({
            classDropdownOpen: !prevState.classDropdownOpen
        }));
    }
    changeClass(selectedClass) {
        this.setState({
            dropdownClassValue: selectedClass.currentTarget.textContent.trim(),
        }, () => {
            let showtable = GetPreferenceValue(this.props.acct, "AssetAllocation", "chart") === "table" ? true : false
            this.execute(showtable, this.state.dropdownClassValue);
            this.props.UpdateAssetSchemaAssetSummary(this.state.dropdownClassValue);
                this.props.UpdateAssetSchema();
                this.props.UpdateHistoricalAssetSchema(this.state.dropdownClassValue);
        });
    }

    assetClassViewPreference(preference) {
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'AssetSchemaV2', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }
   
    aacanellationToken = null;

    execute = (showtable, dropdownClassValue) => {
        this.setState(() => ({ isloading: true, requestFailed: false }));

        let baseUrl = AssetallocationURL + '\'' + "" + '\',' + null + ')';
        baseUrl = this.state.showAssetClassOption ? (AssetallocationURL + '\'' + "" + '\',' + null + ',' + (getSchemaValue(this.state.dropdownClassValue)) + ')') : baseUrl;
        if (this.aacanellationToken) {
            this.aacanellationToken.cancel('Operation canceled due to new request.')
        }
        this.aacanellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.aacanellationToken)
            .then(result => {
                this.setState(() => ({ assetallocationresponse: result.data, isloading: false, requestFailed: false, showTable: showtable }))
                this.props.AddAA(result.data)
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailed: true, error: error.message, showTable: showtable }))
                }
            })
    };

    assetColorCancellationToken = null;
    executeAssetColor = () => {
        this.setState(() => ({ isLoadingAssetColor: true, requestFailedAssetColor: false }));

        let baseUrl = assetSchemaColorsConfigUrl;

        if (this.assetColorCancellationToken) {
            this.assetColorCancellationToken.cancel('Operation canceled due to new request.')
        }
        this.assetColorCancellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.assetColorCancellationToken)
            .then(result => {
                this.setState(() => ({ assetcolorRes: result.data, isLoadingAssetColor: false, requestFailedAssetColor: false }))
                this.props.AddAssetColor(result.data)
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailedAssetColor: true, error: error.message }))
                }
            })
    };

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render || this.props.assetSchema !== prevProps.assetSchema) {
            let showtable = GetPreferenceValue(this.props.acct, "AssetAllocation", "chart") === "table" ? true : false
            if (this.state.isProspectClient) {
                this.setDataForProspectClient(showtable);
            }
            else {
                this.execute(showtable, this.state.dropdownClassValue);
            }

        }

    }

    componentDidMount() {
        let showtable = GetPreferenceValue(this.props.acct, "AssetAllocation", "chart") === "table" ? true : false
        if (this.state.isProspectClient) {
            this.setDataForProspectClient(showtable);
        }
        else {
            if (this.state.assetallocationresponse === null)
                this.execute(showtable, this.state.dropdownClassValue);
            else
                this.setState(() => ({ isloading: false, showTable: showtable }))

            if (this.state.assetcolorRes === null) {
                this.executeAssetColor()
            }
            else {
                this.setState(() => ({ isLoadingAssetColor: false }))
            }


        }

    }

    componentWillUnmount() {
        if (this.aacanellationToken !== null && this.aacanellationToken !== undefined) {
            this.aacanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }

        if (this.assetColorCancellationToken !== null && this.assetColorCancellationToken !== undefined) {
            this.assetColorCancellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }

    }
    toggle() {
        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen
        }));
    }
    toggle1() {
        this.setState(prevState => ({
            tooltipOpen1: !prevState.tooltipOpen1
        }));
    }

    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.aacanellationToken = axios.CancelToken.source();
        let componentjson = [];
        componentjson.push({ "key": "AssetAllocation", "value": this.state.showTable ? "table" : "chart" })
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.acct.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            let asstallocationpreferencevalue = reqObj1.component.filter(component => component.key === "AssetAllocation");
            if (asstallocationpreferencevalue && asstallocationpreferencevalue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "AssetAllocation")].value = this.state.showTable ? "table" : "chart";
            } else {
                reqObj1.component.push({ "key": "AssetAllocation", "value": this.state.showTable ? "table" : "chart" });
            }

        }

        if (this.props.role !== "Prospect") {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.aacanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        this.props.updatePreference(reqObj1);
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                });
        }
    }

    toggleShowTable() {
        this.setState(prevState => ({ showTable: !prevState.showTable }),
            () => {
                this.updatePreferenceCallBack();
            }
        )
    }

    setDataForProspectClient(showtable) {
        if (this.props.assetallocationsummary === null) {
            setTimeout(() => {
                if (this.props.selectedaccount.length == 1) {
                    if (this.props.selectedaccount[0].key === "RRR-123456") {
                        this.setState(() => ({ isloading: false, requestFailed: false, showtable: showtable, assetallocationresponse: RRR_123456_assetallocationDummyResponse, areDataRetrieved: false }));
                        this.props.AddAA(RRR_123456_assetallocationDummyResponse);
                    }
                    else if (this.props.selectedaccount[0].key === "RRR-234567") {
                        this.setState(() => ({ isloading: false, requestFailed: false, showtable: showtable, assetallocationresponse: RRR_234567_assetallocationDummyResponse, areDataRetrieved: false }));
                        this.props.AddAA(RRR_234567_assetallocationDummyResponse);
                    }

                } else if (this.props.selectedaccount.length == 2) {
                    this.setState(() => ({ isloading: false, requestFailed: false, showtable: showtable, assetallocationresponse: assetallocationDummyResponse, areDataRetrieved: false }));
                    this.props.AddAA(assetallocationDummyResponse);
                }
                document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
            }, 100);
        }
        else {
            this.setState({
                isloading: false, showtable: showtable
            });
        }

        if (this.props.assetschemacolorsconfig === null) {
            this.props.AddAssetColor(assetAllocationColorRes)
            this.setState(() => ({
                isLoadingAssetColor: false, requestFailedAssetColor: false, assetcolorRes: assetAllocationColorRes
            }))
        }
        else {
            this.setState({
                isLoadingAssetColor: false, requestFailedAssetColor: false
            });
        }
    }
   
   
    render() {

        if (this.state.requestFailed) return <p className="RCM_data_error">{MsgForFailedData}</p>

        if (this.state.requestFailedAssetColor) return <p className="RCM_data_error">Unable to fetch Asset schema configuration</p>

        if (this.state.isloading || this.state.isLoadingAssetColor) return <div className="RCM_spinner">Loading Asset Allocation{spinner()}</div>

        const { showTable } = this.state;
        const { showChart } = this.state;

        const rowClasses = (row, rowIndex) => {
            if (rowIndex % 2 == 0)
                return 'RCM_Table_row_odd';
            else
                return 'RCM_Table_row_even';
        };

        var rows = [];
        if (this.state.assetallocationresponse && this.state.assetallocationresponse[0] && this.state.assetallocationresponse[0].value) {
            rows = renderRowsByCategory(this.state.assetallocationresponse[0].value, this.state.assetcolorRes, this.state.dropdownClassValue);
        }
        return (
            <ErrorBoundary>
                <AssetAllocationSummaryView
                    state={this.state}
                    rows={rows}
                    rowClasses={rowClasses}
                    showTable={showTable}
                    showChart={showChart}
                    toggleShowTable={this.toggleShowTable}
                    toggle={this.toggle}
                    toggle1={this.toggle1}
                    changeClass={this.changeClass}
                    toggleClass={this.toggleClass}
                    classDropDownItems={assetClassDropDownItems}
                >
                </AssetAllocationSummaryView>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        assetallocationsummary: state.acct.assetallocationsummary,
        render: state.acct.render,
        portalid: state.acct.portalid,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        preferences: state.acct.preferences,
        role: state.acct.role,
        acct: state.acct,
        profileId: state.acct.profileId,
        assetSchema: state.acct.assetSchema,
        assetschemacolorsconfig: state.acct.assetschemacolorsconfig
    };
};

const mapDispatchToProps = {
    AddAA,
    updatePreference,
    UpdateAssetSchemaAssetSummary,
    UpdateAssetSchema,
    AddAssetColor,
    UpdateHistoricalAssetSchema
}

export default connect(mapStateToProps, mapDispatchToProps)(Assetallocation);