import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Post, Get, Guid } from '../../../../common/servicecalls.jsx';
import { roundvalue, formatMoney, BalancesdetailURL, GetPreferenceValue, spinner, GetAccountsList, MsgForFailedData, getAccountDisplayName, IndLongShort, ContextURL, PreferencesUrl, GetPreferencePilotValue, CheckIfProspectClient } from '../../../../common/constants.jsx';
import { connect } from 'react-redux';
import axios from 'axios';
import { AddBalanceDetails, UpdateAccount, SetAccountOverviewFlag } from '../../../../actions/accountsactions.jsx';
import { UpdateTopten } from '../../../../actions/toptenactions.jsx';
import { UpdateDocuments } from '../../../../actions/documentactions.jsx';
import { updatePreference } from "../../../../actions/accountsactions.jsx";
import { AssetView } from './assetview.jsx';
import { setContextInCache } from '../../../../services/home/userServices.jsx';
import Assetviewbygroup from './assetviewbygroup.jsx';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import Assetviewbyinstitution from './assetviewbyInstitution.jsx';
import { balancedetailsDummyResponse, RRR_123456_balance_details, RRR_234567_balance_details } from './../../accountsmockresponse.js'

class Assetsummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false,
            balancestileresponse: this.props.balancedetails,
            isloading: true,
            areDataRetrieved: false,
            sortOrder:"",
            columnToSort:"",
            showAssetInstitutionView: this.assetInstitutionViewPreference(this.props),
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.redirecttoholdings = this.redirecttoholdings.bind(this);
        this.acctFormatter = this.acctFormatter.bind(this);
        this.compareValues = this.compareValues.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
    }

    cancellationToken = null;
    execute = (accountlist) => {
        this.setState(() => ({ isloading: true, requestFailed: false, areDataRetrieved: false }));

        const baseUrl = BalancesdetailURL + "&key3=" + Guid();
         if (this.balDetailscanellationToken) {
            this.balDetailscanellationToken.cancel('Operation canceled due to new request.')
        }
        this.balDetailscanellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.balDetailscanellationToken)
            .then(result => {
                this.props.AddBalanceDetails(result.data);
                this.setState(() => ({ balancestileresponse: result.data, isloading: false, areDataRetrieved: true }))
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailed: true, error: error.message }))
                }
            })

    };

    assetInstitutionViewPreference(preference){
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'RCMAssetInstitutionView', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }

    componentDidMount() {
        if (this.state.isProspectClient) {
            this.setDataForProspectClient()
        }
        else {
            if (this.props.balancedetails === null) {
                this.execute(this.accounts);
            }
            else {
                this.setState({ isloading: false, areDataRetrieved: true });
            }
            let columnValue = GetPreferenceValue(this.props.acct, "accountoverviewsortorder", "");
            if (columnValue !== "") {
                let columnOrder = columnValue.split('|');
                this.setState({ columnToSort: columnOrder[0], sortOrder: columnOrder[1] });
            }
        }
    }
    componentWillUnmount() {
        if (this.balDetailscanellationToken !== null && this.balDetailscanellationToken !== undefined) {
            this.balDetailscanellationToken.cancel('Cancelling the Account Details during unmount to prevent memory leak.');
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.setDataForProspectClient()
            }
            else {
                this.accounts = GetAccountsList(this.props.selectedaccount);
                this.execute(this.accounts);
                this.setState({
                    accounts: this.accounts,
                    areDataRetrieved: true
                });
                let columnValue = GetPreferenceValue(this.props.acct, "accountoverviewsortorder", "");
                if (columnValue !== "") {
                    let columnOrder = columnValue.split('|');
                    this.setState({ columnToSort: columnOrder[0], sortOrder: columnOrder[1] });
                }
            }
           
        }
    }
    setSortedColumn = () => {
        const preferenceUrl = PreferencesUrl;
        this.cancellationToken = axios.CancelToken.source();
        let componentjson = [];
        componentjson.push({ "key": "accountoverviewsortorder", "value": this.state.columnToSort + "|" + this.state.sortOrder })
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.acct.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            let accountoverviewsortordervalue = reqObj1.component.filter(component => component.key === "accountoverviewsortorder");
            if (accountoverviewsortordervalue && accountoverviewsortordervalue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "accountoverviewsortorder")].value = this.state.columnToSort + "|" + this.state.sortOrder;
            }
            else {
                reqObj1.component.push({ "key": "accountoverviewsortorder", "value": this.state.columnToSort + "|" + this.state.sortOrder });
            }
        }

        Post(preferenceUrl, requestBody, this.props.token,
            this.props.contextjson, this.props.profilejson, this.cancellationToken)
            .then(res => {
                if (res.status === 200) {
                    this.props.updatePreference(reqObj1);
                    console.log("updated successfully");
                }
            }).catch(error => {
                console.log('making false - profile');
                console.log(error);
            });
    }
    getClassName(val) {
        if (val == '') {
            return ''
        } else if (val >= 0) {
            return "RCM_positiveval";
        }
        else {
            return "RCM_negativeval";
        }
    }
    
    acctFormatter(accountlist, cell, key) {
        return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}</div>)
    }
    redirecttoholdings(accountnumber) {

        let filteraccount = this.props.selectedaccount.find(acct => acct.key === accountnumber)
        let arr = [];
        arr.push(filteraccount);
        // Make Asyc call to profile set context
        // Post(ContextURL,arr, this.props.token, this.props.contextjson, this.props.profilejson).then(result => {
        //     this.props.UpdateAccount(arr, this.props.render, true);
        //     this.props.SetAccountOverviewFlag(true);
        //     this.props.history.push("/Toptendetail");
        // }).catch(er => {
        //     console.log(er);
        // });
        if (this.state.isProspectClient) {
            this.props.UpdateAccount(arr, this.props.render, true);
            this.props.UpdateTopten();
            this.props.SetAccountOverviewFlag(true);
            this.props.UpdateDocuments();
            if (arr && Array.isArray(arr) && arr.length > 0 && (arr[0].accountsourcetypecode === 'UserManualAdded' || arr[0].accountsourcetypecode === 'SBL' || arr[0].accountsourcetypecode === 'Aggregated')) {
                this.props.history.push("/Balancesdetail");

            } else {
                this.props.history.push("/Toptendetail");
            }
        }
        else {

            setContextInCache(arr, this.props.token, this.props.contextjson, this.props.profilejson)
                .then(res => {
                    this.props.UpdateAccount(arr, this.props.render, true);
                    this.props.UpdateTopten();
                    this.props.SetAccountOverviewFlag(true);
                    this.props.UpdateDocuments();
                    if (arr && Array.isArray(arr) && arr.length > 0 && (arr[0].accountsourcetypecode === 'UserManualAdded' || arr[0].accountsourcetypecode === 'SBL' || arr[0].accountsourcetypecode === 'Aggregated')) {
                        this.props.history.push("/Balancesdetail");

                    } else {
                        this.props.history.push("/Toptendetail");
                    }



                    console.log("Account details :: ", arr);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    compareValues(key, order = 'asc') {
        let selectedAccount = this.props.selectedaccount;
        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }
            let varA = 1;
            let varB = 1;

            varA = (typeof a[key] === 'string') ?
                a[key === "accountaumber"?"key":key] : a[key];

            varB = (typeof b[key] === 'string') ?
                b[key === "accountaumber" ? "key" : key] : b[key];

            if(key === "accountaumber"){
                varA =  getAccountDisplayName(selectedAccount, varA, IndLongShort.SINGLEVIEW).toUpperCase()
                varB =  getAccountDisplayName(selectedAccount, varB, IndLongShort.SINGLEVIEW).toUpperCase()
            }
   
            if (key === "totalaccountvalue") {
                varA = (a === undefined || a === null || a === '')
                    ? 0.00 : parseFloat(a[key].replace(/,/g, '').replace('$', ''));
                varB = (b === undefined || b === null || b === '')
                    ? 0.00 : parseFloat(b[key].replace(/,/g, '').replace('$', ''));
            }   

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };
    }


    sortColumnType = (event)=>{
        this.setState({
            columnToSort : event.target.id.split('_')[0],
            sortOrder: event.target.id.split('_')[1]
        },()=>{this.setSortedColumn() })
    }

    getAssetGroupViewRows(balancestileresponse){
        let assetGroupRows = [];

        if(balancestileresponse && balancestileresponse.value && Array.isArray(balancestileresponse.value) 
            && balancestileresponse.value.length > 0 && balancestileresponse.value[0].groupbalances
            && Array.isArray(balancestileresponse.value[0].groupbalances) && balancestileresponse.value[0].groupbalances.length > 0){

                    balancestileresponse.value[0].groupbalances.map((group, index) => {
                        assetGroupRows.push({
                            "groupkey": group && group.groupkey ? group.groupkey : index,
                            "groupname": group && group.groupname ? group.groupname : '',
                            "groupid": group && group.groupid ? group.groupid : '',
                            "grouptotalvalue": (group.totalaccountvalue === undefined || group.totalaccountvalue === null || group.totalaccountvalue == 0) ? 0
                                        : group.totalaccountvalue,
                            "totalgroupvaluechange": group.totalaccountvaluechange,
                            "totalgroupvaluepercentchange": group.totalaccountvaluepercentchange, 
                            "accounts": this.getGroupAcctAssetForGroup(group.groupid, balancestileresponse.value[0].accountbalances)
                        });
                    })
        }

        //console.log(assetGroupRows);

        return assetGroupRows;
    }

    getGroupAcctAssetForGroup(groupid, accountbalances){
        let acctList = [];

        if(this.props.groupedAccounts && Array.isArray(this.props.groupedAccounts) && this.props.groupedAccounts.length > 0){
            for(let i=0; i < this.props.groupedAccounts.length; i++){
                if(this.props.groupedAccounts[i].groupid === groupid && this.props.groupedAccounts[i].accounts 
                    && Array.isArray(this.props.groupedAccounts[i].accounts) 
                    && this.props.groupedAccounts[i].accounts.length > 0 ){
                        let accounts = this.props.groupedAccounts[i].accounts;
                        accounts.map((acct, index) => {
                            if(accountbalances && Array.isArray(accountbalances) && accountbalances.length > 0){
                                 for(let j =0; j < accountbalances.length; j++){
                                     if(accountbalances[j].key === acct.key){
                                         acctList.push({
                                            "acctnum" : acct.rockaccounttype === 'Internal' ?  acct.number : acct.key,
                                            "acctkey" : acct.key,
                                             "totalaccountvalue": accountbalances[j].totalaccountvalue,
                                                 //(accountbalances[j].totalaccountvalue === undefined || accountbalances[j].totalaccountvalue === null || accountbalances[j].totalaccountvalue == 0) ? '$0' : formatMoney(roundvalue(accountbalances[j].totalaccountvalue, 2)),
                                            "totalaccountvaluechange": accountbalances[j].totalaccountvaluechange,
                                            "totalaccountvaluepercentchange": accountbalances[j].totalaccountvaluepercentchange
                                         });
                                         break;
                                     }
                                 }
                            }else{
                                acctList.push(
                                    {
                                        "acctnum" : acct.number,
                                        "acctkey" : acct.key
                                    }
                                );
                            }
                        })
                    break;
                }
            }
        }
        
        return acctList;
    }


    getAssetInstitutionGroupViewRows(balancestileresponse){
        let assetGroupInstitutionRows = [];

        if(balancestileresponse && balancestileresponse.value && Array.isArray(balancestileresponse.value) 
            && balancestileresponse.value.length > 0 && balancestileresponse.value[0].institutionbalances
            && Array.isArray(balancestileresponse.value[0].institutionbalances) && balancestileresponse.value[0].institutionbalances.length > 0){

                    balancestileresponse.value[0].institutionbalances.map((group, index) => {
                        assetGroupInstitutionRows.push({
                            "fikey": group && group.fiid ? group.fiid : index,
                            "finame": group && group.finame ? group.finame : '',
                            "fiid": group && group.fiid ? group.fiid : '',
                            "fitotalvalue": (group.totalaccountvalue === undefined || group.totalaccountvalue === null || group.totalaccountvalue == 0) ? 0
                                        : group.totalaccountvalue,
                            "figroupvaluechange": group.totalaccountvaluechange,
                            "totalgroupfivaluepercentchange": group.totalaccountvaluepercentchange, 
                            "accounts": group && group.accountbalances ? group.accountbalances : []
                        });
                    })
        }

        console.log(assetGroupInstitutionRows);

        return assetGroupInstitutionRows;
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    setDataForProspectClient() {
        if (this.props.balancedetails === null) {
            if (this.props.selectedaccount.length == 1) {
                let account = this.props.selectedaccount[0].number + "_balance_details";
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ balancestileresponse: RRR_123456_balance_details, isloading: false, areDataRetrieved: true }), () => { this.props.AddBalanceDetails(RRR_123456_balance_details); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ balancestileresponse: RRR_234567_balance_details, isloading: false, areDataRetrieved: true }), () => { this.props.AddBalanceDetails(RRR_234567_balance_details); });
                }
            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ balancestileresponse: balancedetailsDummyResponse, isloading: false, areDataRetrieved: true }), () => { this.props.AddBalanceDetails(balancedetailsDummyResponse); });
            }
        }
        else {
            this.setState(() => ({ isloading: false, areDataRetrieved: true }));
        }
    }
    render() {
        if (this.state.requestFailed) return <p className="RCM_data_error">{MsgForFailedData}</p>

        if (this.state.isloading) return <div className="RCM_spinner">Loading Account Overview{spinner()}</div>

        const rowClasses = (row, rowIndex) => {
            if (rowIndex % 2 == 0)
                return 'RCM_Table_row_odd';
            else
                return 'RCM_Table_row_even';
        };

        var rows = [];

        //  console.log(this.props.balancessummary);

        if (this.state.balancestileresponse && this.state.balancestileresponse.value && this.state.balancestileresponse.value.length > 0 && this.state.balancestileresponse.value[0] && this.state.balancestileresponse.value[0].accountbalances) {
            this.state.balancestileresponse.value[0].accountbalances.map((d, i) => rows.push(
                {
                    key: (d.key === undefined || d.key === null || d.key == 0) ? '-' : d.key,
                    accountaumber: (d.accountaumber === undefined || d.accountaumber === null || d.accountaumber == 0) ? '-' : d.accountaumber,
                    totalaccountvalue: ((d.totalaccountvalue === undefined || d.totalaccountvalue === null || d.totalaccountvalue == 0) ? '$0'
                        : formatMoney(roundvalue(d.totalaccountvalue, 2))),
                    totalaccountvaluechange: d.totalaccountvaluechange,
                    totalaccountvaluepercentchange: d.totalaccountvaluepercentchange,
                }
            ));
        }
        if(this.state.columnToSort === "Account"){
            rows.sort(this.compareValues('accountaumber',this.state.sortOrder));
        }else if(this.state.columnToSort === "TotalVal"){
            rows.sort(this.compareValues('totalaccountvalue',this.state.sortOrder));
        }else if(this.state.columnToSort === "TodaysChange"){
            rows.sort(this.compareValues('totalaccountvaluechange',this.state.sortOrder));
        }
      
        function isRowCheck(dataname) {
            let val = dataname.replace(/[$,%,-]/g, '');
            if (val !== '')
                return parseFloat(val);
            else return val;
        }

        return (

            <>
                <div className="row RCM_headingwrapper">
                <div className="col-sm-8">
                    <label className="RCM_tile_heading">ACCOUNT OVERVIEW</label></div>
                <div className="col-sm-4 RCM_expandviewright">
                    <div className="RCM_expandviewrightWrapper">
                        <div>
                            <Link to={{
                                pathname: '/Balancesdetail',
                                view: {
                                    viewBy:'AL'
                                }
                            }} className="RCM_expandview" id="TooltipAssetallocationdetail"></Link>
                            <Tooltip placement="left" isOpen={this.state.tooltipOpen} target="TooltipAssetallocationdetail"
                                toggle={this.toggle} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                               Account Details
                        </Tooltip>
                            <Link to={{
                                pathname: '/Balancesdetail',
                                view: {
                                    viewBy: 'AL'
                                }
                            }} ><label className="RCM_togglelabeltext RCM_detailsLabel">Detail</label></Link>
                        </div>
                    </div>
                </div>
            </div>
                
                {
                    this.props.view && this.props.view === 'group' ? (
                        <Assetviewbygroup
                            data={this.getAssetGroupViewRows(this.state.balancestileresponse)}
                            redirecttoholdings={this.redirecttoholdings}
                            accounts={this.props.selectedaccount}
                            rowsortevent={this.rowsortevent}
                            childColumnToSort={this.state.childColumnToSort}
                            childColumnSortOrder={this.state.childColumnSortOrder} />
                    ) : (

                         this.state.showAssetInstitutionView ? 
                            <Assetviewbyinstitution 
                                data={this.getAssetInstitutionGroupViewRows(this.state.balancestileresponse)} 
                                    redirecttoholdings={this.redirecttoholdings}
                                    accounts={this.props.selectedaccount}
                                    rowsortevent={this.rowsortevent}
                                    childColumnToSort={this.state.childColumnToSort}
                                    childColumnSortOrder={this.state.childColumnSortOrder} 
                             />
                        :

                        <AssetView
                                state={this.state}
                                rows={rows}
                                rowClasses={rowClasses}
                                redirecttoholdings={this.redirecttoholdings}
                                acctFormatter={this.acctFormatter}
                                selectedaccount={this.props.selectedaccount}
                                getClassName={this.getClassName}
                                sortColumnType={this.sortColumnType}
                            />

                        )
                }    
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        balancedetails: state.acct.balancedetails,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        accounts: state.acct.accounts,
        render: state.acct.render,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        profileId: state.acct.profileId,
        acct: state.acct,
        preferences: state.acct.preferences,
        groupedAccounts: state.acct && state.acct.role === 'Prospect' ? state.acct.groupedAccounts:state.acct.profileResp && state.acct.profileResp.groupedaccounts ? state.acct.profileResp.groupedaccounts : [],
        view: state.acct.selectorview,
        role: state.acct.role
    };
};
const mapDispatchToProps = {
    AddBalanceDetails,
    UpdateAccount,
    SetAccountOverviewFlag,
    updatePreference,
    UpdateTopten,
    UpdateDocuments
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assetsummary));

