import { errorMsg, performanceSummaryURL, performanceDataURL } from "../../../../common/constants.jsx";
import { Get } from "../../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../../common/_logging";

export var getPerformanceSummaryCancellationToken = null;

export const getPerformanceSummaryService = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (getPerformanceSummaryCancellationToken !== null && getPerformanceSummaryCancellationToken !== undefined) {
        //Cancel the existing call
        getPerformanceSummaryCancellationToken.cancel('Cancelling the get asset list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getPerformanceSummaryCancellationToken = cancelToken.source();

    let getPerformanceSummaryPromise = new Promise(
        function (resolve, reject) {
      
            Get(performanceSummaryURL, token, contextJson, profileJson, getPerformanceSummaryCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Performance Summary Service : get performance summary ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Performance Summary Service : get performance summary :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return getPerformanceSummaryPromise;
}

export var getPerformanceDataServiceCancellationToken = null;

export const getPerformanceDataService = (token, contextJson, profileJson, source) => {

    //Check if cancellation token has value;
    if (getPerformanceDataServiceCancellationToken !== null && getPerformanceDataServiceCancellationToken !== undefined) {
        //Cancel the existing call
        getPerformanceDataServiceCancellationToken.cancel('Cancelling the get asset list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getPerformanceDataServiceCancellationToken = cancelToken.source();
    let url = `${performanceDataURL}(${source})`;
    let getPerformanceDataPromise = new Promise(
        function (resolve, reject) {

            Get(url, token, contextJson, profileJson, getPerformanceDataServiceCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Performance Summary Service : get performance data ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Performance Summary Service : get performance data :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return getPerformanceDataPromise;
}