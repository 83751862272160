import { errorMsg, alertSummaryURL, alertDetailsURL, alertMarkAsReadURL} from "../../../../common/constants.jsx";
import { Get,Post} from "../../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "./../../../../common/_logging";

export var alertsSummaryCancellationToken = null;
export var alertsDetailsCancellationToken = null;
export var alertReadCancellationToken = null;

export const alertsSummaryService = (token, contextJson,profileJson) => {

    //Check if cancellation token has value;
    if (alertsSummaryCancellationToken !== null && alertsSummaryCancellationToken !== undefined) {
        //Cancel the existing call
        alertsSummaryCancellationToken.cancel('Cancelling the alert summary request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    alertsSummaryCancellationToken = cancelToken.source();

    let alertsSummaryPromise = new Promise(
        function (resolve, reject) {
            let AlertsSummaryurl = alertSummaryURL;
            Get(AlertsSummaryurl, token, contextJson, profileJson, alertsSummaryCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Alerts service: Alerts Summary ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Alerts service: Alerts Summary :: ", error);
                        _logException("Alerts service: Alerts Summary :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return alertsSummaryPromise;
}

export const alertsDetailsService = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (alertsDetailsCancellationToken !== null && alertsDetailsCancellationToken !== undefined) {
        //Cancel the existing call
        alertsDetailsCancellationToken.cancel('Cancelling the alert summary request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    alertsDetailsCancellationToken = cancelToken.source();

    let alertsDetailsPromise = new Promise(
        function (resolve, reject) {
            let AlertsDetailsurl = alertDetailsURL;
            Get(AlertsDetailsurl, token, contextJson,profileJson, alertsDetailsCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Alerts service: Alerts Details ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Alerts service: Alerts Details :: ", error);
                        _logException("Alerts service: Alerts Details :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return alertsDetailsPromise;
}

export const alertReadService = (alertRequest, token, contextJson, profileJson) => {
    //Check if cancellation token has value;
    if (alertReadCancellationToken !== null && alertReadCancellationToken !== undefined) {
        //Cancel the existing call
        alertReadCancellationToken.cancel('Cancelling the alert read request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    alertReadCancellationToken = cancelToken.source();

    let alertsReadPromise = new Promise(
        function (resolve, reject) {
            let AlertsReadUrl = alertMarkAsReadURL;
            Post(AlertsReadUrl, alertRequest, token, contextJson, profileJson, alertReadCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Alerts service: Alerts Read ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Alerts service: Alerts Read :: ", error);
                        _logException("Alerts service: Alerts Read :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return alertsReadPromise;
}