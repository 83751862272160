import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetAllocation from './summary/assetallocation/assetallocation.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Asset from './summary/asset/asset.jsx';
import TopTen from './summary/topten/topten.jsx'
import ErrorBoundary from './../../common/errorboundary';
import { Post, Get } from './../../common/servicecalls.jsx';
import { customizationComponentURL, CheckIfProspectClient, GetPreferencePilotValue} from './../../common/constants';
import axios from 'axios';
import Activity from './summary/activity/activity.jsx';
import Performance from './summary/performance/performance.jsx';
import { toggleComponent, setDashboardPreference } from '../../actions/personalisationAction';
import Messages from '../../common/messages.jsx';
import { MessageType, MessageClass } from '../../common/constants.jsx';
import HistoricalAssetAllocationSummaryContainer from "./detail/historicalassetallocation/HistoricalAssetAllocationSummaryContainer.jsx";
//import { showHistoricalAssetAllocation } from './detail/historicalassetallocation/HistoricalAssetAllocationService';
import { CustomizationView } from './customizationview.jsx';
import { savePreferenceService, getHomePagePreference } from './customizationviewservices.jsx';
class Accounts extends Component {

    constructor(props) {
        let expreimentArray = [1, 2, 0, 3];
        super(props);
        let dragableObj = this.props.preference !== undefined ? this.props.preference.pilotfeatures.filter(data => data.componentname === "OnlinePortalCustomization") : []
        let obj = { preferences: this.props.preference }
        this.state = {
            positionOfComponent: expreimentArray,
            assetallocation: expreimentArray.includes(0) ? true : false,
            asset: expreimentArray.includes(1) ? true : false,
            topten: expreimentArray.includes(2) ? true : false,
            activity: expreimentArray.includes(3) ? true : false,
            historicalasset: expreimentArray.includes(4) ? true : false,
            performance: expreimentArray.includes(5) ? true : false,
            positionOfDiv: "default",
            modal: false,
            draggable: (dragableObj[0] !== undefined) && (dragableObj[0].active === "true") ? true : false,
            componentArray: [],
            isTokenRefreshed: this.props.isTokenRefreshed,
            topSectionsSelected: [],
            messages: [],
            isProspectClient: CheckIfProspectClient(this.props.role),
            //isPilotForHistoricalAssetAllocation:false
            isPerformance: GetPreferencePilotValue(obj, "Performance", "false") === "true" ? true : false,
        }
        this.validateTopSection = this.validateTopSection.bind(this);
    }
    SetDummyResponseForProspectClient() {
        if (this.props.personalistaionData && this.props.personalistaionData.dashboardPreference) {
            let preference = this.props.personalistaionData.dashboardPreference;
            let positionOfComponent = [];
            for (var i = 0; i < preference.length; i++) {
                if (preference[i] === 'assetallocation') {
                    positionOfComponent.push(0);
                }
                if (preference[i] === 'asset') {
                    positionOfComponent.push(1);
                }
                if (preference[i] === 'topten') {
                    positionOfComponent.push(2);
                }
                if (preference[i] === 'historicalasset') {
                    positionOfComponent.push(4);
                }
                if (preference[i] === 'performance') {
                    positionOfComponent.push(5);
                }
                if (preference[i] === 'activity') {
                    positionOfComponent.push(3);
                }
                
            }
            this.setPositionOfComponents(positionOfComponent);
           
        }
        else {
            let positionOfComponent = [0, 1, 4, 3];
            this.setPositionOfComponents(positionOfComponent);
        }
    }
    setPositionOfComponents(positionOfComponent) {
        this.setState({
            positionOfComponent: positionOfComponent,
            assetallocation: positionOfComponent.includes(0) ? true : false,
            asset: positionOfComponent.includes(1) ? true : false,
            topten: positionOfComponent.includes(2) ? true : false,
            activity: positionOfComponent.includes(3) ? true : false,
            historicalasset: positionOfComponent.includes(4) ? true : false,
            performance: positionOfComponent.includes(5) ? true : false,
            // isPilotForHistoricalAssetAllocation: isPilotForHistoricalAssetAllocation
        }, () => {
            let positionOfComponent = this.state.positionOfComponent;
            let componentsArray = this.makeArrayOfComponentToBeShown().slice();
            let componentToBeShown = [];
            let dashboardPreference = [];
            positionOfComponent.map((data) => {
                componentsArray.map((component) => {
                    if (component.props.children.key === 'assetallocation' && data === 0) {
                        componentToBeShown.push(component);
                        dashboardPreference.push(component.props.children.key);
                    } else if (component.props.children.key === 'asset' && data === 1) {
                        componentToBeShown.push(component)
                        dashboardPreference.push(component.props.children.key);
                    } else if (component.props.children.key === 'topten' && data === 2) {
                        componentToBeShown.push(component)
                        dashboardPreference.push(component.props.children.key);
                    } 
                    else if (component.props.children.key === 'historicalasset' && data === 4) {
                        componentToBeShown.push(component)
                        dashboardPreference.push(component.props.children.key);
                    }
                    else if (component.props.children.key === 'performance' && data === 5) {
                        componentToBeShown.push(component)
                        dashboardPreference.push(component.props.children.key);
                    } else if (component.props.children.key === 'activity' && data === 3) {
                        componentToBeShown.push(component)
                        dashboardPreference.push(component.props.children.key);
                    }
                })
            })
            this.setState({
                componentArray: componentToBeShown
            }, () => { this.props.setDashboardPreference(dashboardPreference) });

        })
    }

    execute = () => {
        getHomePagePreference(this.props.token, this.props.contextjson, this.props.profilejson)
            .then(result => {
                console.log(result.data.value);
                let obj = result.data.value;
                let pageOrder = obj.pageorder
                let positionOfComponent = [];
                if (pageOrder !== null) {
                    for (let i = 0; i < pageOrder.length; i++) {
                        if (pageOrder[i].pagename === "/Accounts" || pageOrder[i].pagename === "/") {
                            let pagesequence = pageOrder[i].pagesequence
                            for (let j = 0; j < pagesequence.length; j++) {
                                let id = pagesequence[j].id
                                positionOfComponent.push(id)
                            }
                        }
                    }
                    //positionOfComponent.push(5)
                } else {
                    positionOfComponent = [0, 1, 4, 3]
                }
                this.setPositionOfComponents(positionOfComponent);
                
            })
            .catch(error => {
                console.log(error)
            })

    }

    toggleModel = () => {
        let data = this.props.personalistaionData.toggleComponent;
        this.props.toggleComponent(!data);
    }


    updateScreenpreference = (type) => {

        let pageOrder = [];
        let dashboardPreference = [];
        this.state.positionOfComponent.map((data) => {
            if (data === 0) {
                pageOrder.push(
                    {
                        "tilename": "AssetAllocation",
                        "id": 0
                    }
                )
                dashboardPreference.push("assetallocation");
            } else if (data === 1) {
                pageOrder.push(
                    {
                        "tilename": "Asset",
                        "id": 1
                    }
                )
                dashboardPreference.push("asset");
            } else if (data === 2) {
                pageOrder.push(
                    {
                        "tilename": "Top10",
                        "id": 2
                    }
                )
                dashboardPreference.push("topten");
            } else if (data === 4) {
                pageOrder.push(
                    {
                        "tilename": "HistoricalAsset",
                        "id": 4
                    }
                )
                dashboardPreference.push("historicalasset");
            } else if (data === 5) {
                pageOrder.push(
                    {
                        "tilename": "Performance",
                        "id": 5
                    }
                )
                dashboardPreference.push("performance");
            }else if (data === 3) {
                pageOrder.push(
                    {
                        "tilename": "Activity",
                        "id": 3
                    }
                )
                dashboardPreference.push("activity");
            }
           
        })
        let requestBody = {
            "profileid": this.props.profileId,
            "pageorder": [{
                "pagename": window.location.pathname,
                "pagesequence": pageOrder
            }]
        }
        let messages = [];
        if (this.state.isProspectClient) {
            this.props.setDashboardPreference(dashboardPreference);
        }
        else {
            savePreferenceService(requestBody, this.props.token,
                this.props.contextjson)
                .then(res => {
                    if (res.status === 200) {
                        console.log("updated");
                        this.props.setDashboardPreference(dashboardPreference);
                    }
                })
                .catch(error => {
                    console.log(error)
                    messages.push({
                        type: MessageType.INFO,
                        text: "Components to view is not saved",
                        class: MessageClass.ERROR
                    });
                    this.setState({
                        messages: messages
                    });
                })
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isTokenRefreshed !== undefined && this.props.isTokenRefreshed !== prevProps.isTokenRefreshed && this.props.token !== undefined) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.execute();
            }
            
        }
    }
    componentDidMount() {
        if (this.props.token !== undefined)
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.execute();
            }
            
    }

    components = {
        assetallocation: AssetAllocation,
        topten: TopTen,
        asset: Asset,
        performance: Performance,
        activity: Activity
    }
    validateTopSection() {
        let selectedComponents = 0;
        if (this.state.asset) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.assetallocation) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.topten) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.historicalasset) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.performance) {
            selectedComponents = selectedComponents + 1;
        }
        if (selectedComponents > 3) {
            return false;
        }
        else {
            return true;
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let messages = [];
        this.setState({
            [name]: value, messages: messages
        }, () => {
                if (this.validateTopSection()) {
                    if (value || (!value && (this.state.asset || this.state.assetallocation || this.state.topten || this.state.historicalasset || this.state.activity || this.state.performance))) {
                        this.componentToBeRendered();
                    } else {
                        messages.push({
                            type: MessageType.INFO,
                            text: "Atleast 1 component should be selected for the dashboard",
                            class: MessageClass.ERROR
                        });
                        this.setState({
                            messages: messages, [name]: !value
                        });
                    }
                }
                else {
                    messages.push({
                        type: MessageType.INFO,
                        text: "Maximum 3 component's can be selected for the top section",
                        class: MessageClass.ERROR
                    });
                    this.setState({
                        messages: messages, [name]: !value
                    });
                }
        });
    }

    makeArrayOfComponentToBeShown = () => {
        var array = [];
        let bsClass = "col-lg-4";
         if (this.state.positionOfComponent.length === 1)
             bsClass = "col-lg-12";
         else if (this.state.positionOfComponent.length === 2)
            bsClass = "col-lg-6";
        
        if (this.state.assetallocation) {
            array.push(<ErrorBoundary><AssetAllocation bsClass={bsClass} key="assetallocation" draggable={this.state.draggable} onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} /></ErrorBoundary>);

        }

        if (this.state.asset) {
            array.push(<ErrorBoundary><Asset bsClass={bsClass} key="asset" draggable={this.state.draggable} onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} /></ErrorBoundary>);
        }

        if (this.state.topten) {
            array.push(<ErrorBoundary><TopTen bsClass={bsClass} key="topten" draggable={this.state.draggable} onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} /></ErrorBoundary>);

        }

        if (this.state.historicalasset) {
           
            array.push(<ErrorBoundary>
                <div
                    key="historicalasset"
                    id="historicalasset"
                    data-intro="Historical Asset Allocation Information"
                    className={[
                        "RCM_summary_section col-sm-12 col-md-5 col-xs-12",
                        bsClass
                    ].join(" ")}
                    draggable={this.state.draggable}
                    onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver}
                    onDrop={this.handleDrop}
                >
                    <div className="RCM_Toast">
                        <HistoricalAssetAllocationSummaryContainer />
                    </div>
                </div>
            </ErrorBoundary>);
        }
        if (this.state.performance && this.state.isPerformance) {
            array.push(<ErrorBoundary><Performance bsClass={bsClass} key="performance" draggable={this.state.draggable} onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} /></ErrorBoundary>);
        }

        if (this.state.activity)
            array.push(<ErrorBoundary><Activity key="activity" draggable={this.state.draggable} onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} /></ErrorBoundary>)
         return array
    }

    componentToBeRendered = () => {

            let array = this.makeArrayOfComponentToBeShown();
        let positionofComponents = this.getPositionOfElements(array, ["assetallocation", "asset", "topten", "activity", "historicalasset", "performance"])
            this.setState({
                componentArray: array,
                positionOfComponent: positionofComponents
            }, () => {
                    this.updateScreenpreference("");
                    document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
            })
    }

    getPositionOfElements = (array, components) => {
        let positionofComponents = [];
        array.map((arraydata) => {
            components.map((componentData, innerIndex) => {
                if (arraydata.props.children.key === componentData) {
                    positionofComponents.push(innerIndex)
                }
            })

        })
        return positionofComponents;
    }

    swapBoxes = (fromBox, toBox) => {

        let array = this.state.componentArray.slice();
        let fromId = fromBox.id;
        let toId = toBox.id
        let temp;
        let fromIndex = "";
        let toIndex = "";
        array.map((data, index) => {
            if (data.props.children.key === fromId) {
                fromIndex = index
            }
            if (data.props.children.key === toId) {
                toIndex = index
            }
        })
        temp = array[toIndex];
        array[toIndex] = array[fromIndex];
        array[fromIndex] = temp;
        let positionofComponents = this.getPositionOfElements(array, ["assetallocation", "asset", "topten", "activity", "historicalasset", "performance"]);
        this.setState({
            componentArray: array,
            positionOfComponent: positionofComponents
        }, () => {
            this.updateScreenpreference("onSwap")
        })

    }

    handleDragStart = event => {
        let fromBox = JSON.stringify({ id: event.target.id });
        event.dataTransfer.setData("dragContent", fromBox);

    };

    handleDragOver = event => {
        event.preventDefault(); // Necessary. Allows us to drop.
        return false;
    };

    handleDrop = data => event => {
        event.preventDefault();
        let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
        let toBox = { id: data };
        this.swapBoxes(fromBox, toBox);
        return false;
    };
    
    render() {
        return (
            <div className='container-fluid'>
                <Modal isOpen={this.props.personalistaionData.toggleComponent} toggle={this.toggleModel} backdrop={false}
                    zIndex='9999'
                    className="RCM_CM_modal RCM_Customization_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content "
                    centered>
                    <ModalHeader toggle={this.toggleModel} className="RCM_customization_details_modal" charCode="X" >
                        <label className="RCM_tile_heading">PLEASE SELECT COMPONENTS TO VIEW</label>
                        <div className="row RCM_Customization_Message_No_Margin">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                {
                                    this.state.messages && this.state.messages.length > 0
                                        ? <Messages messages={this.state && this.state.messages}
                                            containerClass={
                                                this.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                                    ? "RCM_validation_error_container"
                                                    : ""
                                            } />

                                        : ""
                                }
                            </div>

                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <CustomizationView
                            state={this.state}
                            handleInputChange={this.handleInputChange}
                        />
                        <div className="row mt-4 RCM_Customization_Note">
                            <label className="RCM_Customization_text RCM_Customization_text_small_font">*&nbsp; Selection will save immediately.</label>
                        </div>
                    </ModalBody>

                </Modal>
                <div className='row'>
                    {this.state.componentArray}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        isTokenRefreshed: state.acct.isTokenRefreshed,
        profileId: state.acct.profileId,
        preference: state.acct.preferences,
        token: state.acct.token,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        personalistaionData: state.personalisationData,
        role: state.acct.role,
        
    };
};
const mapDispatchToProps = {
    toggleComponent,
    setDashboardPreference
};
export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
