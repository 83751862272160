import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { externalAcctColums, defaultSorted } from "./externalAccountColumns.js";
import { getStandingInstructions, cancelStandingInstruction, getStandingInstructionsCancellationToken, cancelStandingInstructionCancellationToken } from "./manageExternalAccountService.jsx";
import { fetchExternalAccounts, Remove_LatestExtAcct} from "../../../actions/manageexternalacctactions";
import OverlayProcessing from "../common/overlayProcessing.jsx";
import { spinner, MsgForNoData, MsgForFailedData, MsgSelectFromTop } from "../../../common/constants.jsx";
import RCM_Modal from "../common/modal.jsx";
import { noDataIndicationMessage } from "../common/gridCommon.jsx";
import { filterAggregatedAccounts } from "../common/utilty.js";


class ExternalAccountsList extends React.Component {
  constructor(props) {
    super(props);

    
    this.contextAccounts = [];
    if (this.props.accounts !== null
        && this.props.accounts !== undefined
        && Array.isArray(this.props.accounts)
        && this.props.accounts.length > 0) {
        this.contextAccounts = this.props.accounts;
    }

    this.topLevelAccounts = [];
      if (this.props.accounts !== null
          && this.props.accounts !== undefined
          && Array.isArray(this.props.accounts)
          && this.props.accounts.length > 0) {
        this.topLevelAccounts = filterAggregatedAccounts(this.props.accounts);
    }

    this.requesterId = "";
    if (this.props.portalid !== null && this.props.portalid !== undefined) {
      this.requesterId = this.props.portalid;
    }

    this.token ="";
    if(this.props.token !== null && this.props.token !== undefined)
    {
      this.token = this.props.token;
    }

    this.contextjson = null;
        if(this.props.contextjson !== null && this.props.contextjson !== undefined)
        {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if(this.props.profilejson !== null && this.props.profilejson !== undefined)
        {
            this.profilejson = this.props.profilejson;
        }


    this.state = {
      isDataLoaded: false,
      isGridRefreshed: true,
      //externalAccountsList: [],
      externalAccountColumns: externalAcctColums(this.contextAccounts),
        gridMessage: MsgForNoData,
      showCancelConfirmModal: false,
      cancelVerbiage: "",
        currentDeleting: {},
        errorMsg: ''
    };

    this.onDeleteExternalAccount = this.onDeleteExternalAccount.bind(this);
    this.toggleCancelModal = this.toggleCancelModal.bind(this);
    this.cancelExternalAccountConfirm = this.cancelExternalAccountConfirm.bind(this);
    this.rowClassForNewExtAcct = this.rowClassForNewExtAcct.bind(this);
  }

  toggleCancelModal()
  {
      this.setState(prevState => ({
        showCancelConfirmModal: !prevState.showCancelConfirmModal
    }));
  }

  rowClassForNewExtAcct = (row) => {
    let cssClass = null;
    if (row !== null && row !== undefined && row.standinginstructionid !== null
        && row.standinginstructionid !== undefined
        && this.props.recentExternalAcct != null
        && this.props.recentExternalAcct !== undefined
        && this.props.recentExternalAcct === row.standinginstructionid) {
        console.log("New confirmation number matched");
        cssClass = "RCM_CM_new_transfer";
    }

    return cssClass;
}

  

  componentDidMount() {

    //register event here
    window.addEventListener('deleteExternalAccount', this.onDeleteExternalAccount);

    if (this.topLevelAccounts.length > 0 && this.requesterId !== "") {
      getStandingInstructions(this.topLevelAccounts, this.requesterId, this.token, this.contextjson, this.profilejson).then(data => {
        let clonedState = Object.assign({}, this.state);
        clonedState.isDataLoaded = true;
          clonedState.gridMessage = MsgForNoData;
        this.setState(clonedState, () => {
            if (Array.isArray(data)) this.props.fetchExternalAccounts(data);
        });
      }).catch(error => {
        let clonedState = Object.assign({}, this.state);
        clonedState.isDataLoaded = true;
        clonedState.gridMessage = MsgForFailedData;
        this.setState(clonedState, () =>{
          this.props.fetchExternalAccounts([]);
        });
      });
    }else{
      let clonedState = Object.assign({}, this.state);
        clonedState.isDataLoaded = true;
        clonedState.gridMessage = MsgSelectFromTop;
        this.setState(clonedState, () => {
          this.props.fetchExternalAccounts([]);
        });
    }

  }

  componentWillUnmount()
  {
    // de registering the event here
     window.removeEventListener('deleteExternalAccount', this.onDeleteExternalAccount);
     this.props.Remove_LatestExtAcct();

     if(getStandingInstructionsCancellationToken !==null && getStandingInstructionsCancellationToken !== undefined)
     {
       getStandingInstructionsCancellationToken.cancel("Cancelling get standing instructions call during unmount to prevent memory leak.");
     }

     if(cancelStandingInstructionCancellationToken !== null && cancelStandingInstructionCancellationToken !== undefined)
     {
        cancelStandingInstructionCancellationToken.cancel("Cancelling cancel standing instruction call during unmount to prevent memory leak.");
     }

  }

  cancelExternalAccountConfirm()
  {
      let clonedState = Object.assign({}, this.state);
      clonedState.isGridRefreshed = false;
      clonedState.showCancelConfirmModal = false;
      clonedState.errorMsg = '';
      this.setState(clonedState, () => {
        cancelStandingInstruction(this.state.currentDeleting, this.token, this.contextjson, this.profilejson).then(data => {
          let newExternalAcctList = this.props.externalAccountsList.filter(record => {
            if (record.standinginstructionid !== data.standinginstructionid) {
              return record;
            }
          });

          if (Array.isArray(newExternalAcctList)) this.props.fetchExternalAccounts(newExternalAcctList);
          let clonedState = Object.assign({}, this.state);
          clonedState.isGridRefreshed = true;
            clonedState.currentDeleting = {};
            clonedState.errorMsg = '';
          this.setState(clonedState);

        }).catch(error => {
          let clonedState = Object.assign({}, this.state);
          clonedState.isGridRefreshed = true;
            clonedState.currentDeleting = {};
            clonedState.errorMsg = error;
          this.setState(clonedState);
        })
      })
    }

  onDeleteExternalAccount(event)
  {
    if (event !== null && event !== undefined && event.detail !== null && event.detail !== undefined)
    {
      let clonedState = Object.assign({}, this.setState);
      clonedState.cancelVerbiage = "This action will delete your external account, do you want to proceed ?";
      clonedState.currentDeleting = event.detail;
      this.setState(clonedState, this.toggleCancelModal);
    }
    else {
      console.log("Invalid Event received");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //console.log("Past Transfers component did update :", prevProps);
        if (this.props.accounts !== null
            && this.props.accounts !== undefined
            && Array.isArray(this.props.accounts)
            && this.props.accounts.length > 0) {
        let clonedState = Object.assign({}, this.state);
        clonedState.isDataLoaded = false;
          clonedState.gridMessage = MsgForNoData;
        this.setState(clonedState, () => {
            getStandingInstructions(filterAggregatedAccounts(this.props.accounts), this.requesterId, this.token, this.contextjson, this.profilejson)
            .then(data => {
              let clonedState = Object.assign({}, this.state);
              clonedState.isDataLoaded = true;
              this.setState(clonedState, () => {
                  if (Array.isArray(data)) this.props.fetchExternalAccounts(data);
              });
            })
            .catch(error => {
              //do something with error code.
              let clonedState = Object.assign({}, this.state);
              clonedState.isDataLoaded = true;
              clonedState.gridMessage = MsgForFailedData;
              this.setState(clonedState, () => {
                this.props.fetchExternalAccounts([]);
              });
            });
        });

      } else {
        let clonedState = Object.assign({}, this.state);
        clonedState.isDataLoaded = true;
        clonedState.gridMessage = MsgSelectFromTop;
        this.setState(clonedState, () => {
          this.props.fetchExternalAccounts([]);
        });
      }
    }
  }

  render() {
    let externalAccounts;
    if (this.state.isDataLoaded) {
      externalAccounts = (
        <div>
          { this.state.isGridRefreshed ? "" : (<OverlayProcessing />) }

            {
                (this.state.errorMsg !== "") &&
                (<div className="RCM_validation_error_container" >
                    <div className="RCM_msg_text">{this.state.errorMsg}</div>
                </div>)
            }

          <BootstrapTable
            keyField="seqid"
            data={this.props.externalAccountsList}
            columns={this.state.externalAccountColumns}
            bordered={false}
                  classes="RCM_two_level_table1"
                  wrapperClasses="RCM_CM_no_hand"
            defaultSorted={defaultSorted}
            rowClasses={this.rowClassForNewExtAcct}
            bootstrap4 = {true}
            noDataIndication={() => noDataIndicationMessage(this.state.gridMessage)}
          />
          <RCM_Modal 
              modalState={this.state.showCancelConfirmModal}
              toggleModal={this.toggleCancelModal}
              modalTitle="Delete Account Confirmation"
              modalContent = {this.state.cancelVerbiage}
              approvedAction = {this.cancelExternalAccountConfirm}
            ></RCM_Modal>
        </div>
      );
    } else {
      externalAccounts = (
        <div align="Center">
          <div className="RCM_spinner">Loading External Account Details{spinner()}</div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="RCM_Toast_heading mt-3">EXTERNAL ACCOUNTS</div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12">{externalAccounts}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accounts: state.acct.accounts,
    selectedaccount: state.acct.selectedaccount,
    portalid: state.acct.portalid,
    token: state.acct.token,
    render: state.acct.render,
    externalAccountsList: state.manageextacct.externalAcctList,
    recentExternalAcct: state.manageextacct.recentExternalAcctId,
    contextjson: state.acct.contextjson,
    profilejson: state.acct.profilejson
  };
};

const mapDispatchToProps ={
   fetchExternalAccounts,
   Remove_LatestExtAcct
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalAccountsList);
