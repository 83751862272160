import React, { Component } from "react";
import {
  deleteCookie,
  setCookie,
  validateEmailId,
  getUserEmailFromCookie,
} from "./constants";
import axios from "axios";
import { encrypt } from "./CryptoUtil";

export default class UserLogon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: getUserEmailFromCookie(props.config.cookieSalt),
      rememberUser: true,
      isValidEmail: true,
      errorMessage: "",
    };
    this.textInput = React.createRef();
  }

  async componentDidMount() {
    this.textInput.current.autofocus = true;
    this.textInput.current.focus();
  }

  onUserEmailChange(event) {
    this.setState({ userEmail: event.target.value });
  }

  onKeyUp(event) {
    if (event.keyCode === 13) {
      this.onLogin();
    }
  }

  onRememberUserChange(event) {
    if (event.target.checked === false) {
      deleteCookie("rcmLoggedInUser");
    }
    this.setState({ rememberUser: event.target.checked });
  }

  onClear = () => {
    this.setState({ userEmail: "" });
  };

  fallbackToStore = function () {};

  onLogin() {
    this.setState({ isValidEmail: true, errorMessage: "" }, () => {
      if (this.state.userEmail !== "") {
        let isValidEmail = validateEmailId(this.state.userEmail);

        if (isValidEmail) {
          if (this.state.rememberUser && this.state.rememberUser === true) {
            const encryptedUserEmail = encrypt(
              this.state.userEmail,
              this.props.config.cookieSalt
            );
            setCookie("rcmLoggedInUser", encryptedUserEmail, 365);
          }
          this.props.onLoginClick(this.state.userEmail);
        } else {
          this.setState({
            isValidEmail: false,
            errorMessage: "Invalid Email Address.",
          });
        }
      }
    });
  }

  getConfig = () => {
    try {
      return axios.get("/Config/GetLoginConfig");
    } catch (error) {
      console.log(error);
    }
  };

  handleClickIntroductionToSite = () => {
    if (this.props.config && this.props.config.loginIntroPdfURL) {
      window.open(this.props.config.loginIntroPdfURL, "introduction");
    }
  };

  render() {
    return (
      <>
        <div>
          <div className="RCM_background"></div>
          <div className="RCM_background_overlay"></div>
        </div>
        <div className="RCM_login_outer">
          <div className="RCM_login_middle">
            <div className="RCM_login_inner">
              <img
                className="RCM_Logon_Form_Logo"
                role="presentation"
                src="../images/logo.svg"
                alt="Rockefeller logo"
              />

              <div>
                <label htmlFor="uname" className="RCM_Logon_Form2">
                  Sign in
                </label>
                <div>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    name="uname"
                    className={
                      this.state.isValidEmail
                        ? "RCM_Form_textfield"
                        : "RCM_Form_textfield RCM_Form_textfield_error"
                    }
                    required
                    onChange={(event) => this.onUserEmailChange(event)}
                    onKeyUp={(event) => this.onKeyUp(event)}
                    value={this.state.userEmail}
                    ref={this.textInput}
                    spellCheck={false}
                  />
                </div>
                <div>
                  <label className="RCM_Logon_Form">
                    <input
                      type="checkbox"
                      className="RCM_Logon_Form_CheckBox mt-3"
                      checked={this.state.rememberUser}
                      name="remember"
                      onChange={(event) => this.onRememberUserChange(event)}
                    />{" "}
                    Remember me
                  </label>
                </div>
                <div className="mt-3" align="right">
                  <button
                    className="RCM_Form_Sec_Button"
                    onClick={() => this.onClear()}
                  >
                    Clear
                  </button>{" "}
                  <button
                    className="RCM_Form_Primary_Button"
                    onClick={() => this.onLogin()}
                  >
                    Login
                  </button>
                </div>
              </div>

              {this.props.config && this.props.config.loginIntroPdfURL && (
                <div
                  onClick={this.handleClickIntroductionToSite}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: "0.75rem",
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  View Login and Site Overview
                </div>
              )}

              {this.state.isValidEmail || (
                <div className="RCM_Logon_Form_Error mt-2">
                  {this.state.errorMessage}
                </div>
              )}

              <div className="RCM_Logon_Form_Footer">
                <div>
                  Welcome to Rockefeller Capital Management. Please login with
                  your credentials.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
