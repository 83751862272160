import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import { getAssetGroupInstitutionViewParentColumns, getAssetGroupInstitutionViewChildColumns, renderChildRowsForInstitutionView} from './assetviewbyInstitutionColumn';
import { MsgForNoData } from '../../../../common/constants';

export default function Assetviewbyinstitution(props) {

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
           //console.log(row);
           props.redirecttoholdings(row.key);
        }
      };

    const expandRowAssetInstitutionView = {
        onlyOneExpanding: true,
        renderer: (row) => (
                <BootstrapTable
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                data={renderChildRowsForInstitutionView(row && row.accounts && Array.isArray(row.accounts) && row.accounts.length > 0 ? row.accounts : [], props.accounts, props.childColumnToSort, props.childColumnSortOrder)}
                    columns={getAssetGroupInstitutionViewChildColumns(props.accounts)}
                    noDataIndication={MsgForNoData}
                    rowEvents={rowEvents}
                    rowClasses="RCM_Table_pointer"
                    bordered={false}
                />
        ),
        showExpandColumn: true,
        expandColumnPosition: 'right',
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return <b></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<div className="RCM_icon_hand RCM_down_arrow">
                </div>);
            }
            return (<div className="RCM_icon_hand RCM_right_arrow">
            </div>);
        }
    };

    return (

        <div className="RCM_Asset_GroupView_Container">
            <BootstrapTable 
                keyField="fiid"
                data={props.data ? props.data : []}
                classes="RCM_two_level_table1"
                columns={getAssetGroupInstitutionViewParentColumns(props.rowsortevent)}
                bordered={false}
                noDataIndication={MsgForNoData}
                expandRow={expandRowAssetInstitutionView}
            /> 
        </div>
    )
}



