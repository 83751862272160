import React, { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Post } from "../../../../common/servicecalls";
import {
  Button,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import VerifyPhoneMethod from "./VerifyPhoneMethod";
import { MaskPhoneNumber, rectifyPhoneNumber } from "../common/constants";

const AddPhoneMethod = ({ handleCloseAddAuthenticationPopup }) => {
  const { token, profileResp } = useSelector((store) => store.acct) || {};
  const { baseurl } = useSelector((store) => store.env) || {};
  const [openMobileDropDown, setOpenMobileDropdown] = useState(false);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const [submitPhoneProcessState, setSubmitPhoneProcessState] = useState({
    isPhoneSubmitInProgress: false,
    phoneSubmitError: undefined,
    phoneSubmitResult: undefined,
  });
  const [userDetailsForm, setUserDetailsForm] = useState({
    mode: 1,
  });

  const { phone: phoneNumber, phones: phoneNumbers } = profileResp || {};
  const userPhoneList = useMemo(() => {
    var phoneList = [];
    if (phoneNumbers && Array.isArray(phoneNumbers)) {
      phoneList = phoneNumbers.map((contact) => ({
        displayPhone: MaskPhoneNumber(contact.phone),
        value: contact.phone || "",
      }));
    }
    if (!phoneList || (Array.isArray(phoneList) && phoneList.length <= 0)) {
      phoneList = [
        { value: phoneNumber, displayPhone: MaskPhoneNumber(phoneNumber) },
      ];
    }

    return phoneList;
  }, [phoneNumber, phoneNumbers]);
  //const pattern = /^\d*$/;

  const handleMobileDropdownToogle = useCallback(() => {
    setOpenMobileDropdown((prev) => !prev);
  }, []);

  const handleVerificationModeChange = (mode) => {
    setUserDetailsForm((prevState) => ({
      ...prevState,
      mode: mode,
    }));
  };

  const handleMobileDropdownClick = useCallback((phone) => {
    setUserDetailsForm((prevState) => ({
      ...prevState,
      phoneno: phone ? phone.value : "",
      displayPhone: phone ? phone.displayPhone : "",
    }));
  }, []);

  const handlePhoneNumberSubmit = useCallback(() => {
    setSubmitPhoneProcessState((prevState) => ({
      ...prevState,
      isPhoneSubmitInProgress: true,
      phoneSubmitError: undefined,
      phoneSubmitResult: undefined,
    }));

    //Rectify phone number
    var rectifiedPhone = userDetailsForm.phoneno
      ? rectifyPhoneNumber(userDetailsForm.phoneno)
      : "";
    if (rectifiedPhone === "") {
      setSubmitPhoneProcessState((prevState) => ({
        ...prevState,
        isPhoneSubmitInProgress: false,
        phoneSubmitError:
          "Invalid Phone number, Please contact technical support.",
        phoneSubmitResult: undefined,
      }));
    }

    if (rectifiedPhone) {
      //Post data
      const cancellationToken = axios.CancelToken.source();
      const apiurl = `${baseurl}multifactorauthapi/api/PhoneMethods/add`;
      const phoneRequest = {
        phoneNumber: `${rectifiedPhone}`,
        phoneType: "mobile",
        mode: userDetailsForm.mode,
      };
      Post(apiurl, phoneRequest, token, null, null, cancellationToken)
        .then((apiRes) => {
          setSubmitPhoneProcessState((prevState) => ({
            ...prevState,
            isPhoneSubmitInProgress: false,
            phoneSubmitError: undefined,
            phoneSubmitResult: apiRes.data,
          }));
          setShowPhoneVerification(true);
        })
        .catch(() => {
          setSubmitPhoneProcessState((prevState) => ({
            ...prevState,
            isPhoneSubmitInProgress: false,
            phoneSubmitError:
              "We are unable to add phone number, Please try after sometime.",
            phoneSubmitResult: undefined,
          }));
        });
    }
  }, [baseurl, token, userDetailsForm]);

  if (showPhoneVerification) {
    return (
      <div>
        <VerifyPhoneMethod
          phoneSubmitResult={submitPhoneProcessState.phoneSubmitResult}
          handleCloseAddAuthenticationPopup={handleCloseAddAuthenticationPopup}
        />
      </div>
    );
  }

  return (
    <div>
      {submitPhoneProcessState.phoneSubmitError && (
        <div className="RCM_Teamwork_error">
          {submitPhoneProcessState.phoneSubmitError}
        </div>
      )}
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="RCM_CM_form_label">
            <label>PHONE NUMBER</label>
          </div>
          <div style={{ fontSize: "0.8rem", letterSpacing: "0.1rem" }}>
            Below phone numbers we have in your profile. Please use call mode if your number does not support SMS.
          </div>
          <Dropdown
            isOpen={openMobileDropDown}
            toggle={handleMobileDropdownToogle}
            className="RCM_form_control RCM_select_box RCM_mm_select_box mt-2"
          >
            <DropdownToggle caret={true}>
              {userDetailsForm.displayPhone ||
                "Select an Existing Phone Number"}
            </DropdownToggle>
            <DropdownMenu>
              {userPhoneList.map((phone, index) => {
                return (
                  <DropdownItem
                    key={index}
                    onClick={() => handleMobileDropdownClick(phone)}
                  >
                    <div>{phone.displayPhone}</div>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <div style={{ fontSize: "0.8rem", marginTop: "0.5rem" }}>
            Choose a mode to verify your phone number
          </div>
          <div style={{ display: "flex", gap: "15px", marginTop: "0.3rem" }}>
            <div className="radio">
              <label className="RCM_selectLabel">
                <input
                  type="radio"
                  id="mode_text"
                  name="mode"
                  value="1"
                  onChange={() => handleVerificationModeChange(1)}
                  checked={userDetailsForm.mode === 1}
                />
                <span className="profile-font-color RCM_preference_label">
                  Text
                </span>
              </label>
            </div>
            <div className="radio">
              <label className="RCM_selectLabel">
                <input
                  type="radio"
                  id="mode_call"
                  name="mode"
                  value="0"
                  onChange={() => handleVerificationModeChange(0)}
                  checked={userDetailsForm.mode === 0}
                  className="radio"
                />
                <span className="profile-font-color RCM_preference_label">
                  Call
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col col-sm-12"
          style={{
            display: "flex",
            gap: "0.75rem",
            marginTop: "1.5rem",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            className="btn RCM_button_primary RCM_select_btn"
            onClick={handlePhoneNumberSubmit}
            disabled={submitPhoneProcessState.isPhoneSubmitInProgress}
          >
            <>
              {submitPhoneProcessState.isPhoneSubmitInProgress && (
                <Spinner size="sm" style={{ marginRight: "0.5rem" }} />
              )}{" "}
              CONFIRM
            </>
          </Button>
          <input
            className="btn RCM_button_secondary RCM_select_btn"
            type="submit"
            value="CANCEL"
            onClick={() => handleCloseAddAuthenticationPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPhoneMethod;
