import React, { Component } from 'react';
import { _logPageView } from '../common/_logging';
import { PageView } from '@microsoft/applicationinsights-web';

export const withPageView = (properties) => (OriginalComponent) => {

    class WrappedComponent extends Component {

        constructor(props){
            super(props);
        }

        componentDidMount(){

            //show for page view
            let pageViewData =  new PageView();
            pageViewData.url = (properties.pageName && properties.pageName === 'Home') ?  window.location.origin : window.location;
            pageViewData.name = (properties.pageName) ? properties.pageName : ''
            _logPageView(pageViewData);
            //console.log(pageViewData);
        }

        render() {
            return (
                <OriginalComponent {...this.props} key="OriginalComponent"></OriginalComponent>
            )
        }
    }

    return WrappedComponent;
}


