import React from  "react";
import { formatMoney, getAccountDisplayName, IndLongShort } from './../../common/constants.jsx';
import { getFormattedDate } from './common/utilty.js';
import XLSX from "xlsx";


function amountFormatter(cell, row, rowIndex) {
    if (cell !== undefined && cell !== null) {
        return (<div align="right">
            <span>{formatMoney(cell)}</span>
        </div>);
    }
    else return ("-");
}

function dateFormatter(cell, row, rowIndex) {
    let date = "";
    if (cell !== null && cell !== undefined && Date.parse(cell)) {
        date = getFormattedDate(cell);
    }

    return date;
}

function customCaretSort(order, column)
{
  if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
  else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
  else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
  return null;
}

function columnHeaderStyle(column, colIndex)
{
  return { 'textAlign': 'left', 'whiteSpace': 'nowrap' };
}


function acctFormatter(accountlist, cell)
    {
        return (<div title={getAccountDisplayName(accountlist, cell, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, cell, IndLongShort.SINGLEVIEW)}</div>)
    }

export const defaultSorted = [{ dataField: 'dttrade', order: 'desc' }];

export function pastTransferColums(accountList){
    return [{
        dataField: "dttrade",
        text: "Date",
        sort: true,
        formatter: dateFormatter,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
    },
    {
        dataField: "acct",
        text: "Account",
        sort: true,
        formatter: (cell) => acctFormatter(accountList, cell),
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
    },
    {
        dataField: "trntype",
        text: "Type",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
    },
    {
        dataField: "trndesc",
        text: "Description",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
    },
    {
        dataField: "total",
        text: "Amount",
        sort: true,
        formatter: amountFormatter,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => { return { 'textAlign': 'right', 'whiteSpace': 'nowrap' } }
    }
]}


export function generatePastTransfersExcelWs(props,pastTransfersList)
{
    let xlsHeader = ["Transaction Date", "Account", "Amount", "Type", "Description"];
    let xlsRows = []
    if (pastTransfersList !== null && pastTransfersList !== undefined && Array.isArray(pastTransfersList)) {
         pastTransfersList.forEach(transfer => {
            var xlRow = {
                "Transaction Date": getFormattedDate(transfer.dttrade), "Account": getAccountDisplayName( props.selectedaccount, transfer.acct, IndLongShort.SINGLEVIEW),
                "Amount": formatMoney(transfer.total), "Type": transfer.trntype,
                "Description": transfer.trndesc
            }
            xlsRows.push(xlRow);
        })
    }
    let ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });

    ws['!cols'] = [{ 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 120 }];

    return ws;
}

export function printPastTransferReport(pastTransfers) {
    let printWindow = window.open('', 'RCM Print', '');
    let table = "<table cellspacing='0'><tr><td><div><b>ROCKEFELLER</b></div><div>CAPITAL MANAGEMENT</div></td >";
    table += pastTransfersPrintTable(pastTransfers);
    
    printWindow.document.write(table);
    printWindow.print()
    printWindow.close();
}

export function pastTransfersPrintTable( props,pastTransfers) {
    let table = "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='6'>Past Transfers Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'>Date </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Account </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Type </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:30%;'> Description </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:10%;'> Amount </td>"
    table += "</tr>"
    if (pastTransfers !== null && pastTransfers !== undefined && Array.isArray(pastTransfers)) {
        pastTransfers.forEach(transfer => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:15%;'>" + getFormattedDate(transfer.dttrade) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + getAccountDisplayName( props.selectedaccount, transfer.acct, IndLongShort.SINGLEVIEW)   + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:15%;'>" + transfer.trntype + "</td>"
            table += "<td style='text-align:right; border: 1px solid black;width:30%;'>" + transfer.trndesc + "</td>"
            table += "<td style='text-align:right; border: 1px solid black; width:10%;'>" + formatMoney(transfer.total) + "</td>"
           
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }
    table += "</table>"

    return table;
}

