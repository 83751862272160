import XLSX from "xlsx";
import download from 'downloadjs'
import {
    roundvalue, formatMoney, GetNumericValue, GetTextValue, roundUnitValue, getFormattedTime, getAccountDisplayName, IndLongShort, toDate, getDisplayName,
    getDateTimeVal, GetPreferencePilotValue, getDateString, getManualAssetBalance, GetPreferenceValue
} from './../common/constants.jsx';
import { generateExtAcctExcelWs } from "../components/MoneyMovement/ManageExternalAccounts/externalAccountColumns.js";
import { generatePastTransfersExcelWs } from "../components/MoneyMovement/pastTransfersColumns.js";
import { generateScheduleTransfersExcelWs } from "../components/MoneyMovement/scheduleTransfersColumn.js";
import { getMappedData, GetUniqueTimedata, getAssetGroups } from './../components/accounts/detail/historicalassetallocation/HistoricalAssetAllocationService';
import groupBy from "lodash.groupby";
import uniq from "lodash.uniq";
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import { getAssetColorConfig } from './assetschemautilities';
import { renderRowsByAsset } from './../components/accounts/detail/topten/holdingdetailscolumns';
import { renderTemplateRows } from './../components/accounts/detail/assetallocation/assetallocationdetailscolumns';
import { renderRowsByCategory } from './../components/accounts/summary/assetallocation/assetallocationcolumns';
export function generateExcelReport(props, path) {
    switch (path) {
        case "/Balancesdetail": {
            if (props && props.acct && props.acct)
                generateBalanceDetailsExcelReport(props.acct)
            break;
        }
        case "/Assetallocationdetail": {
            if (props && props.acct && props)
                generateAssetallocationDetailsExcelReport(props.acct);
            break;
        }

        case "/AccountHoldingdetails": {
            if (props && props.topten) {
                generateHoldingDetailsExcelReport(props.acct, props.topten, true, false, false, props.preferences)
            }
            break;
        }
        case "/RealizedAccountHoldingdetails": {
            if (props && props.acct && props.topten) {
                generateRealizedGainLossDetailsReport(props.acct, props.topten, true, false)
            }
            break;
        }
        case "/AssetHoldingdetails": {
            if (props && props.acct && props)
                generateHoldingDetailsExcelReport(props.acct, props.topten, false, false, true, props.preferences)
            break;
        }
        case "/SecurityHoldingdetails": {
            if (props && props.topten) {
                generateHoldingDetailsExcelReport(props.acct, props.topten, false, true, false, props.preferences)
            }
            break;
        }
        case "/RealizedSecurityHoldingdetails": {
            if (props && props.acct && props.topten) {
                generateRealizedGainLossDetailsReport(props.acct, props.topten, false, true)
            }
            break;
        }
        case "/Activitydetail": {
            if (props && props.acct)
                generateActivityDetailsExcelReport(props.acct)
            break;
        }
        case "/Transfers": {
            generateTransfersExcelReport(props);
            break;
        }
        case "/Documents": {
            generateDocumentsExcelReport(props);
            break;
        }
        case "/AccountStatements": {
            generateAccountStatementExcelReport(props);
            break;
        }
        case "/Correspondence": {
            generateCorrespondenceExcelReport(props);
            break;
        }
        case "/TradeConfirms": {
            generateTradeConfirmsExcelReport(props);
            break;
        }
        case "/TaxDocuments": {
            generateTaxDocumentsExcelReport(props);
            break;
        }
        case "/manageExtAccount": {
            generateExternalAcctExcelReport(props);
            break;
        }
        case "/AssetAggregation": {
            if (props && props.acct) {
                generateAssetAggreagtionExcelReport(props)
            }
            break;
        }
        case "/EstimatedIncomeSecurity": {
            getEstimatedIncomeSecurityExcelReport(props);
            break;
        }
        case "/EstimatedIncomeAccount": {
            getEstimatedIncomeAccountExcelReport(props);
            break;
        }
        case "/EstimatedIncomeSummary": {
            generateEstimatedIncomeSummaryExcelReport(props);
            break;
        }
        case "/ProjectedIncomeSecurity": {
            getProjectedIncomeSecurityExcelReport(props);
            break;
        }
        case "/ProjectedIncomeAccount": {
            getProjectedIncomeAccountExcelReport(props);
            break;
        }
        case "/ProjectedIncomeSummary": {
            generateProjectedIncomeSummaryExcelReport(props);
            break;
        }
        case "/Liabilities":
            {
                generateLiabilitiesExcelReport(props);
                break;
            }
        case "/EDelivery":
            {
                generateEDeliveryExcelReport(props);
                break;
            }
        case "/Balanceshistory":
            {
                generateHistoricalAccountDetailsExcelReport(props);
                break;
            }
        case "/SubscribeAlerts":
            {
                generateSubscribeAlertsExcelReport(props);
                break;
            }
        case "/AlertDetails":
            {
                generateAlertNotificationDetailsExcelReport(props);
                break;
            }
        case "/Assetallocationhistory": {
            if (props && props.acct && props)
                generateAssetallocationHistoryExcelReport(props);
            break;
        }
        case "/ManageAssets": {
            if (props && props.acct && props)
                generateManualAssetsReport(props);
            break;
        }
        case "/AccountPerformance": {
            if (props && props.acct)
                generateAccountPerformanceReport(props);
            break;
        }
        case "/PerformanceSummary": {
            if (props && props.acct)
                generatePerformanceSummaryReport(props);
            break;
        }
       /* case "/AccountInfo": {
            if (props && props.acct && props.moneymovement)
                generateAccountInfoReport(props);
            break;
        }*/
        case "/AccountInfo":
        case "/Collaboration":
        case "/Help":
        case "/Feedback":
        case "/Support":
        case "/ProfileComponent":
        case "/Profile":
        case "/Preferences":
        case "/AccountsNickname":
        case "/ManageGroups":
        case "/aviation":
        case "/philanthropy":
        case "/CaseManager":
        case "/Reports":
        case "/PlanManager":
        case "/Services":{
            break;
        }
        default: {
            if (props)
                generateDashboardExcelReport(props);
        }


    }
}

export function generateDashboardExcelReport(props) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "Account Summary Report",
        Subject: "Account Summary Report",
        Author: "Copyright@Rockefeller"
    };
    //step 3: create Balance work sheet
    wb.SheetNames.push("Balances");
    var balancesWs = getBalancesWorkSheetData(props.acct);
    wb.Sheets["Balances"] = balancesWs;
    if (props.personalistaionData.dashboardPreference) {
        let dashboardPreferences = props.personalistaionData.dashboardPreference;
        dashboardPreferences.forEach(dashboardPreference => {
            if (dashboardPreference === 'assetallocation') {
                //step 4: create AssetAllocation work sheet
                wb.SheetNames.push("AssetAllocation");
                var assetAllocationWs = getAssetAllocationWorkSheetData(props.acct);
                wb.Sheets["AssetAllocation"] = assetAllocationWs;
            } else if (dashboardPreference === 'asset') {
                //step 5: create Account overview work sheet
                wb.SheetNames.push("Accountoverview");
                var accountoverviewWs = getAccountOverviewExcelReport(props.acct);
                wb.Sheets["Accountoverview"] = accountoverviewWs;
            } else if (dashboardPreference === 'topten') {
                //step 6: create Top 10 Holdings work sheet
                wb.SheetNames.push("Top10Holdings");
                var top10HoldingsWs = getTop10HoldingsWorkSheetData(props.acct);
                wb.Sheets["Top10Holdings"] = top10HoldingsWs;
            } else if (dashboardPreference === 'historicalasset') {
                //step 7: create Activity work sheet
                wb.SheetNames.push("HistoricalValue");
                if (props.acct && props.acct.historicalAssetAllocationSummaryDetails) {
                    var historicalAssetWs = GenerateHistoricalAssetallocationsheet(props.acct.historicalAssetAllocationSummaryDetails, props.selectedaccount);
                    wb.Sheets["HistoricalValue"] = historicalAssetWs;
                }
            } else if (dashboardPreference === 'performance') {
                //create Account Performance work sheet
                wb.SheetNames.push("AccountPerformance");
                var accountPerformancetWs = GenerateAccountPerformancetWssheet(props);
                wb.Sheets["AccountPerformance"] = accountPerformancetWs;
            } else if (dashboardPreference === 'activity') {
                //step 7: create Activity work sheet
                wb.SheetNames.push("Activity");
                var activityWs = getActivityWorkSheetData(props.acct);
                wb.Sheets["Activity"] = activityWs;
            }
        })
    }


    //step 8: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 9: user file saver to save the file
    var filename = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "AccountSummary-" + filename + ".xlsx", "application/octet-stream");
    return;
}
export function getAccountOverviewExcelReport(props) {
    var xlsHeader = ["Account", "Total Value", "$ Todays Change", "% Todays Change"];
    var xlsRows = []
    if (props && props.balancedetails && props.balancedetails.value && props.balancedetails.value.length > 0 && props.balancedetails.value[0] && props.balancedetails.value[0].accountbalances) {
        props.balancedetails.value[0].accountbalances.forEach(account => {
            if (account) {
                var totalval = (account.totalaccountvaluechange && (account.totalaccountvaluechange > 0 || account.totalaccountvaluechange < 0)) ? formatMoney(account.totalaccountvaluechange) : GetNumericValue(account.totalaccountvaluechange);
                var totalpct = (account.totalaccountvaluepercentchange && (account.totalaccountvaluepercentchange > 0 || account.totalaccountvaluepercentchange < 0)) ? roundvalue(account.totalaccountvaluepercentchange, 2) + "%" : GetNumericValue(account.totalaccountvaluepercentchange, true);
                var xlRow = {
                    "Account": (getAccountDisplayName(props.selectedaccount, account.accountaumber, IndLongShort.SINGLEVIEW)),
                    "Total Value": (account.totalaccountvalue ? account.totalaccountvalue : 0),
                    "$ Todays Change": account.totalaccountvaluechange ? account.totalaccountvaluechange : 0,
                    "% Todays Change": account.totalaccountvaluepercentchange ? (account.totalaccountvaluepercentchange / 100) : 0
                }
                xlsRows.push(xlRow);
            }
        });
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
    });
    ws = formatColumnToCurrency(ws, 'B');
    ws = formatColumnToCurrency(ws, 'C');
    ws = formatColumnToPercentage(ws, 'D');
    return ws;
}
export function generateAssetallocationDetailsExcelReport(props) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "Asset Allocation Details Report",
        Subject: "Asset Allocation Details Summary Report",
        Author: "Copyright@Rockefeller"
    };

    //step 3: create AssetAllocation work sheet
    wb.SheetNames.push("AssetAllocationDetails");
    var AssetAllocationDetailsWs = getAssetAllocationDetailsWorkSheetData(props);
    wb.Sheets["AssetAllocationDetails"] = AssetAllocationDetailsWs;

    //step 4: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 5: user file saver to save the file
    var filename = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "AssetAllocationDetailsReport-" + filename + ".xlsx", "application/octet-stream");
    return;
}
//get buffer array
export function getDataArrayBuffer(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
}
//Get Top 10 Holdings worksheet
function getTop10HoldingsWorkSheetData(props) {
    /* XLS Head Columns */
    var xlsHeader = ["Symbol", "Value", "$ Gain/Loss", "% Gain/Loss"];
    var xlsRows = []
    if (props && props.toptensummary && props.toptensummary.value[0] && props.toptensummary.value[0].holdingdetails &&
        props.toptensummary.value[0].holdingdetails.length > 0) {
        props.toptensummary.value[0].holdingdetails.forEach(holding => {
            if (holding) {
                var xlRow = {
                    "Symbol": holding.symbol,
                    "Value": holding.recentmktval ? formatMoney(holding.recentmktval) : GetNumericValue(holding.recentmktval),
                    "$ Gain/Loss": holding.gainlossamt ? holding.gainlossamt : 0,
                    "% Gain/Loss": holding.gainlosspct ? holding.gainlosspct / 100 : 0
                }
                xlsRows.push(xlRow);
            }
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'C');
    ws = formatColumnToPercentage(ws, 'D');
    return ws;
}
//Get Balances worksheet
function getBalancesWorkSheetData(props) {
    /* XLS Head Columns */
    var xlsHeader = ["Name", "Value"];
    var xlsRows = []
    if (props && props.balancessummary && props.balancessummary.value[0]) {
        var balanceSummaryDetails = props.balancessummary.value[0]
        if (balanceSummaryDetails) {
            balanceSummaryDetails.marginbalancehange = balanceSummaryDetails.marginbalancehange ? roundvalue(balanceSummaryDetails.marginbalancehange, 2) : 0
            var xlRow = {
                Name: "Total Account Value", Value: balanceSummaryDetails.totalaccountvalue ? balanceSummaryDetails.totalaccountvalue : 0,
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Outstanding Balance", Value: balanceSummaryDetails.outstandingbalance ? balanceSummaryDetails.outstandingbalance : 0,
            }
            xlsRows.push(xlRow);

            xlRow = {
                Name: "Today's Change", Value: balanceSummaryDetails.totalaccountvaluechange ? balanceSummaryDetails.totalaccountvaluechange : 0,
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Insurance & Annuities", Value: balanceSummaryDetails.annuity ? balanceSummaryDetails.annuity : 0,
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Priced Investments", Value: balanceSummaryDetails.pricedinvestments ? balanceSummaryDetails.pricedinvestments : 0,
                //"Change in %": balanceSummaryDetails.pricedinvestmentschange? roundvalue(balanceSummaryDetails.pricedinvestmentschange, 2):"-"
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Cash/Money Accounts", Value: balanceSummaryDetails.cashmoneyaccounts ? balanceSummaryDetails.cashmoneyaccounts : 0,
                //"Change in %": balanceSummaryDetails.cashmoneyaccountschange?roundvalue(balanceSummaryDetails.cashmoneyaccountschange, 2):"-"
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Money Account Value", Value: balanceSummaryDetails.moneyaccountvalue ? balanceSummaryDetails.moneyaccountvalue : 0,
                //"Change in %": balanceSummaryDetails.moneyaccountvaluechange?roundvalue(balanceSummaryDetails.moneyaccountvaluechange, 2):"-"
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Cash Balance", Value: balanceSummaryDetails.cashbalance ? balanceSummaryDetails.cashbalance : 0,
                //"Change in %": "-"
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Pending Activity", Value: balanceSummaryDetails.pendingactivity ? balanceSummaryDetails.pendingactivity : 0,
                //"Change in %": "-"
            }
            xlsRows.push(xlRow);
            xlRow = {
                Name: "Margin Balance", Value: balanceSummaryDetails.marginbalance ? balanceSummaryDetails.marginbalance : 0,
                //"Change in %": balanceSummaryDetails.marginbalancehange? roundvalue(balanceSummaryDetails.marginbalancehange, 2):"-"
            }
            xlsRows.push(xlRow);
        }
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'B');
    return ws;
}
//Get Asset Allocations worksheet
function getAssetAllocationWorkSheetData(props) {

    var xlsHeader = ["Asset Class", "Allocation", "Value"];
    var xlsRows = []
    if (props && props.assetallocationsummary && props.assetallocationsummary[0].value) {
        props.assetallocationsummary[0].value.forEach(asset => {
            var xlRow = {
                "Asset Class": asset.assetcat,
                "Allocation": asset.mktvalpct ? (asset.mktvalpct / 100) : '0',
                "Value": asset.mktval ? asset.mktval : 0
            }
            xlsRows.push(xlRow);
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToPercentage(ws, 'B');
    ws = formatColumnToCurrency(ws, 'C');
    return ws;
}
//Get Activity worksheet
function getActivityWorkSheetData(props) {
    /* XLS Head Columns */
    var xlsHeader = ["Trade Date", "Settlement Date", "Account", "Description", "Type", "Symbol/CUSIP", "Quantity", "Price", "Amount"];
    var xlsRows = []
    if (props && props.activitysummary && props.activitysummary.value && props.activitysummary.value[0] && props.activitysummary.value[0].activities) {
        let activities = props.activitysummary.value[0].activities;
        if (activities && activities.length > 15) {
            activities = activities.slice(0, 14)
        }
        activities.sort(function (a, b) {
            if (b['dtsettle']) return a['dtsettle'] ? b['dtsettle'].localeCompare(a['dtsettle']) : 1;
            else if (a['dtsettle']) return (b['dtsettle']) ? a['dtsettle'].localeCompare(b['dtsettle']) : -1;
        });
        activities.forEach(activity => {
            var xlRow = {
                "Trade Date": (activity.dttrade ? activity.dttrade.replace(/T.+/, '') : GetTextValue(activity.dttrade)),
                "Settlement Date": (activity.dtsettle ? activity.dtsettle.replace(/T.+/, '') : GetTextValue(activity.dtsettle)),
                "Account": (activity.acct ? (getAccountDisplayName(props.selectedaccount, activity.acct, IndLongShort.SINGLEVIEW)) : GetTextValue(activity.acct)),
                "Description": (activity.trndesc ? activity.trndesc : GetTextValue(activity.trndesc)),
                "Type": (activity.trntype ? activity.trntype : GetTextValue(activity.trntype)),
                "Symbol/CUSIP": (activity.secid ? activity.secid : GetTextValue(activity.secid)),
                "Quantity": (activity.units ? activity.units : GetNumericValue(activity.units, false, true)),
                "Price": (activity.unitprice ? activity.unitprice : GetNumericValue(activity.unitprice)),
                "Amount": (activity.total ? activity.total : 0)
            }
            xlsRows.push(xlRow);
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        raw: false,
        dateNF: 'yyyy-mm-dd'
    });
    ws = formatColumnToNumber(ws, "G");
    ws = formatColumnToCurrency(ws, "H");
    ws = formatColumnToCurrency(ws, "I");
    return ws;
}

export function generateTasHoldingExcelReport(props, UnrealizedValue, UnrealizedPct) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    var timestamp = getFormattedTime();
    var fileName = "TasHoldingDetails-" + timestamp + ".xlsx";
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "TAS Holding Details Report",
        Subject: "TAS Holding Details Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("TASHoldingDetails");
    var tasDetailsWs = getTASHoldingDetailsWorkSheetData(props, UnrealizedValue, UnrealizedPct);
    wb.Sheets["TASHoldingDetails"] = tasDetailsWs;
    timestamp = getFormattedTime();
    fileName = "TasHoldingDetails-" + timestamp + ".xlsx";
    //step 4: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 5: user file saver to save the file
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), fileName, "application/octet-stream");
    return;
}

function getTASHoldingDetailsWorkSheetData(props, UnrealizedValue, UnrealizedPct) {

    var xlsHeader = ["Acquisition Date", "Holding Period", "Quantity", "Unit Cost", "Cost Basis", "Price", "Value", "Unrealized Gain/loss $", "Unrealized Gain/loss %"];
    var xlsRows = []
    if (props) {
        props.forEach(account => {
            if (account) {
                var unglVal = (account.UnrealizedGainLossAmt && (account.UnrealizedGainLossAmt > 0 || account.UnrealizedGainLossAmt < 0)) ? (account.UnrealizedGainLossAmt) : 0;
                var unglPct = (account.UnrealizedGainLossPct && (account.UnrealizedGainLossPct > 0 || account.UnrealizedGainLossPct < 0)) ? (account.UnrealizedGainLossPct / 100) : 0;
                var xlRow = {
                    "Acquisition Date": (account.AcquisitionDate ? new Date(account.AcquisitionDate.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                    "Holding Period": account.HoldingPeriod ? account.HoldingPeriod : (account.LongShort === "L" ? "Long Term" : "Short Term"),
                    "Quantity": (account.Quantity ? (account.Quantity) : 0),
                    "Unit Cost": (account.AvgUnitCost ? (account.AvgUnitCost) : 0),
                    "Cost Basis": (account.CostBasis ? (account.CostBasis) : 0),
                    "Price": (account.RecentPrice ? (account.RecentPrice) : 0),
                    "Value": (account.RecentMktVal ? (account.RecentMktVal) : 0),
                    "Unrealized Gain/loss $": unglVal,
                    "Unrealized Gain/loss %": unglPct
                }
                xlsRows.push(xlRow);


            }
        })
        var unglVal = (UnrealizedValue && UnrealizedValue > 0 || UnrealizedValue && UnrealizedValue < 0) ? (UnrealizedValue) : 0;
        var unglPct = (UnrealizedPct && UnrealizedPct > 0 || UnrealizedPct && UnrealizedPct < 0) ? (UnrealizedPct / 100) : 0;
        var xlRow = {
            "Acquisition Date": "Total",
            "Holding Period": '',
            "Quantity": '',
            "Unit Cost": '',
            "Cost Basis": '',
            "Price": '',
            "Value": '',
            "Unrealized Gain/loss $": unglVal,
            "Unrealized Gain/loss %": unglPct
        }
        xlsRows.push(xlRow);

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });
    ws = formatColumnToNumber(ws, 'C', true);
    ws = formatColumnToCurrency(ws, 'D', true);
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToPercentage(ws, 'I');
    return ws;
}

export function generateRealizedGainLossDetailsReport(acctDetails, hldDetails, isAccount, isSecurity) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    var timestamp = getFormattedTime();
    var fileName = "AccountHoldingDetails-" + timestamp + ".xlsx";
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "Realized Gain loss Details Report",
        Subject: "Realized Gain loss Details Report",
        Author: "Copyright@Rockefeller"
    };
    if (isAccount) {
        wb.SheetNames.push("AccountDetails");
        var accountDetailsWs = getAccountRealizedGainLossDetailsWorkSheetData(acctDetails, hldDetails);
        wb.Sheets["AccountDetails"] = accountDetailsWs;
        timestamp = getFormattedTime();
        fileName = "RealizedGainLossAccount-" + timestamp + ".xlsx";
    }

    if( isSecurity) {
        //step 3: create Account details workbook
        wb.SheetNames.push("SecurityDetails");
        var securityDetailsWs = getSecurityRealizedGainLossDetailsWorkSheetData(acctDetails, hldDetails);
        wb.Sheets["SecurityDetails"] = securityDetailsWs;
        timestamp = getFormattedTime();
        fileName = "RealizedGainLossSecurity-" + timestamp + ".xlsx";
    }
    //step 4: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 5: user file saver to save the file
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), fileName, "application/octet-stream");
    return;
}

function getAccountRealizedGainLossDetailsWorkSheetData(acctDetails, topTen) {

    var xlsHeader = ["Account", "Symbol", "Description", "Acquisition Date", "Sale Date", "Holding Period", "Quantity", "Cost Basis", "Net Proceeds", "Wash Sale", "Net Gain/loss $", "Net Gain/loss %"];
    var xlsRows = []
    if (topTen && topTen.realizedgainlossbyaccount && topTen.realizedgainlossbyaccount.length > 0 && topTen.realizedgainlossbyaccount[0].ITEMSUMMARY) {
        topTen.realizedgainlossbyaccount[0].ITEMSUMMARY.forEach(account => {
            if (account) {
                //xlsRows.push(xlRow);
                if (account.DETAILSUMMARY && account.DETAILSUMMARY.length > 0) {
                    account.DETAILSUMMARY.forEach(invitem => {
                        //Add tax lot here
                        if (invitem.DETAIL && Array.isArray(invitem.DETAIL) && invitem.DETAIL.length > 0) {
                            invitem.DETAIL.forEach(tasdetail => {
                                var unglVal = (tasdetail.PROFIT && (tasdetail.PROFIT > 0 || tasdetail.PROFIT < 0)) ? (tasdetail.PROFIT) : 0;
                                var unglPct = (tasdetail.PROFITPCT && (tasdetail.PROFITPCT > 0 || tasdetail.PROFITPCT < 0)) ? (tasdetail.PROFITPCT) / 100 : 0;
                                var xlRow = {

                                    "Account": getAccountDisplayName(acctDetails.selectedaccount, account.ACCT, IndLongShort.SINGLEVIEW),
                                    "Symbol": (GetTextValue(invitem.SECNAME)),
                                    "Description": (GetTextValue(invitem.SECDESCRIPTION)),
                                    "Acquisition Date": (tasdetail.DTAQD ? new Date(tasdetail.DTAQD.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                                    "Sale Date": (tasdetail.DTSALE ? new Date(tasdetail.DTSALE.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                                    "Holding Period": tasdetail.LONGSHORT,
                                    "Quantity": (tasdetail.NUMSHRS ? (tasdetail.NUMSHRS) : 0),
                                    "Cost Basis": (tasdetail.COSTBASIS ? (tasdetail.COSTBASIS) : 0),
                                    "Net Proceeds": (tasdetail.STKBNDAMT ? (tasdetail.STKBNDAMT) : 0),
                                    "Wash Sale": tasdetail.WASHSALE,
                                    "Net Gain/loss $": unglVal,
                                    "Net Gain/loss %": unglPct
                                }
                                xlsRows.push(xlRow);
                            })
                        }

                    }
                    )

                }
            }
        })
        if (topTen && topTen.realizedgainlossbyaccount && topTen.realizedgainlossbyaccount.length > 0) {
            var unglVal = (topTen.realizedgainlossbyaccount[0].GRANDPROFIT && topTen.realizedgainlossbyaccount[0].GRANDPROFIT > 0 || topTen.realizedgainlossbyaccount[0] && topTen.realizedgainlossbyaccount[0].GRANDPROFIT < 0) ? (topTen.realizedgainlossbyaccount[0].GRANDPROFIT) : 0;
            var unglPct = (topTen.realizedgainlossbyaccount[0].GRANDPROFITPCT && topTen.realizedgainlossbyaccount[0].GRANDPROFITPCT > 0 || topTen.realizedgainlossbyaccount[0] && topTen.realizedgainlossbyaccount[0].GRANDPROFITPCT < 0) ? (topTen.realizedgainlossbyaccount[0].GRANDPROFITPCT / 100) : 0;
            var totalProceeds = (topTen.realizedgainlossbyaccount[0].GRANDSTKBNDAMT && topTen.realizedgainlossbyaccount[0].GRANDSTKBNDAMT > 0 || topTen.realizedgainlossbyaccount[0] && topTen.realizedgainlossbyaccount[0].GRANDSTKBNDAMT < 0) ? (topTen.realizedgainlossbyaccount[0].GRANDSTKBNDAMT) : 0;
            var totalcostbasis = (topTen.realizedgainlossbyaccount[0].GRANDCOSTBASIS && topTen.realizedgainlossbyaccount[0].GRANDCOSTBASIS > 0 || topTen.realizedgainlossbyaccount[0] && topTen.realizedgainlossbyaccount[0].GRANDCOSTBASIS < 0) ? (topTen.realizedgainlossbyaccount[0].GRANDCOSTBASIS) : 0;

            var xlRow = {
                "Account": "Total:",
                "Symbol": "",
                "Description": "",
                "Acquisition Date": "",
                "Holding Period": "",
                "Sale Date": "",
                "Quantity": "",
                "Cost Basis": totalcostbasis,
                "Net Proceeds": totalProceeds,
                "Wash Sale": "",
                "Net Gain/loss $": unglVal,
                "Net Gain/loss %": unglPct
            }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });
    ws = formatColumnToNumber(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToPercentage(ws, 'L');
    return ws;
}

function getSecurityRealizedGainLossDetailsWorkSheetData(acctDetails, topTen) {

    var xlsHeader = ["Symbol", "Description", "Account", "Acquisition Date", "Sale Date", "Holding Period", "Quantity", "Cost Basis", "Net Proceeds", "Wash Sale", "Net Gain/loss $", "Net Gain/loss %"];
    var xlsRows = []
    if (topTen && topTen.realizedgainlossbysecurity && topTen.realizedgainlossbysecurity.length > 0 && topTen.realizedgainlossbysecurity[0].ITEMSUMMARY) {
        topTen.realizedgainlossbysecurity[0].ITEMSUMMARY.forEach(security => {

            if (security.DETAILSUMMARY && security.DETAILSUMMARY.length > 0) {
                security.DETAILSUMMARY.forEach(invitem => {


                    //Add tax lot here
                    if (invitem.DETAIL && Array.isArray(invitem.DETAIL) && invitem.DETAIL.length > 0) {
                        invitem.DETAIL.forEach(tasdetail => {
                            var unglVal = (tasdetail.PROFIT && (tasdetail.PROFIT > 0 || tasdetail.PROFIT < 0)) ? (tasdetail.PROFIT) : 0;
                            var unglPct = (tasdetail.PROFITPCT && (tasdetail.PROFITPCT > 0 || tasdetail.PROFITPCT < 0)) ? (tasdetail.PROFITPCT / 100) : 0;
                            var xlRow = {
                                // "Account": (account.ACCTNUM ? getAccountDisplayName(acctDetails.selectedaccount, account.ACCTNUM, IndLongShort.SINGLEVIEW) : GetTextValue(account.ACCTNUM)),
                                "Symbol": (GetTextValue(security.SECNAME)),
                                "Description": GetTextValue(security.SECDESCRIPTION),
                                "Account": getAccountDisplayName(acctDetails.selectedaccount, invitem.ACCT, IndLongShort.SINGLEVIEW),
                                "Acquisition Date": (tasdetail.DTAQD ? new Date(tasdetail.DTAQD.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                                "Sale Date": (tasdetail.DTSALE ? new Date(tasdetail.DTSALE.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                                "Holding Period": tasdetail.LONGSHORT,
                                "Quantity": (tasdetail.NUMSHRS ? (tasdetail.NUMSHRS) : 0),
                                "Cost Basis": (tasdetail.COSTBASIS ? (tasdetail.COSTBASIS) : 0),
                                "Net Proceeds": (tasdetail.STKBNDAMT ? (tasdetail.STKBNDAMT) : 0),
                                "Wash Sale": tasdetail.WASHSALE,
                                "Net Gain/loss $": unglVal,
                                "Net Gain/loss %": unglPct
                            }
                            xlsRows.push(xlRow);
                        })
                    }

                }
                )

            }

        })

        if (topTen && topTen.realizedgainlossbysecurity && topTen.realizedgainlossbysecurity.length > 0) {
            var unglVal = (topTen.realizedgainlossbysecurity[0].GRANDPROFIT && topTen.realizedgainlossbysecurity[0].GRANDPROFIT > 0 || topTen.realizedgainlossbysecurity[0] && topTen.realizedgainlossbysecurity[0].GRANDPROFIT < 0) ? (topTen.realizedgainlossbysecurity[0].GRANDPROFIT) : 0;
            var unglPct = (topTen.realizedgainlossbysecurity[0].GRANDPROFITPCT && topTen.realizedgainlossbysecurity[0].GRANDPROFITPCT > 0 || topTen.realizedgainlossbysecurity[0] && topTen.realizedgainlossbysecurity[0].GRANDPROFITPCT < 0) ? (topTen.realizedgainlossbysecurity[0].GRANDPROFITPCT / 100) : 0;
            var totalProceeds = (topTen.realizedgainlossbysecurity[0].GRANDSTKBNDAMT && topTen.realizedgainlossbysecurity[0].GRANDSTKBNDAMT > 0 || topTen.realizedgainlossbysecurity[0] && topTen.realizedgainlossbysecurity[0].GRANDSTKBNDAMT < 0) ? (topTen.realizedgainlossbysecurity[0].GRANDSTKBNDAMT) : 0;
            var totalcostbasis = (topTen.realizedgainlossbysecurity[0].GRANDCOSTBASIS && topTen.realizedgainlossbysecurity[0].GRANDCOSTBASIS > 0 || topTen.realizedgainlossbysecurity[0] && topTen.realizedgainlossbysecurity[0].GRANDCOSTBASIS < 0) ? (topTen.realizedgainlossbysecurity[0].GRANDCOSTBASIS) : 0;

            var xlRow = {
                "Symbol": "Total:",
                "Description": "",
                "Account": "",
                "Acquisition Date": "",
                "Sale Date": "",
                "Holding Period": "",
                "Quantity": "",
                "Cost Basis": totalcostbasis,
                "Net Proceeds": totalProceeds,
                "Wash Sale": "",
                "Net Gain/loss $": unglVal,
                "Net Gain/loss %": unglPct
            }
            xlsRows.push(xlRow);
        }
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });
    ws = formatColumnToNumber(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToPercentage(ws, 'L');
    return ws;
}


export function generateHoldingDetailsExcelReport(acctDetails, hldDetails, isAccount, isSecurity, isCategory, preferences) {
    let obj = {
        preferences: preferences
    }
    const viewYield = GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    var timestamp = getFormattedTime();
    var fileName = "AccountHoldingDetails-" + timestamp + ".xlsx";
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "Account Holding Details Report",
        Subject: "Account Holding Details Report",
        Author: "Copyright@Rockefeller"
    };
    if (isAccount) {
        wb.SheetNames.push("AccountDetails");
        var accountDetailsWs = getAccountHoldingDetailsWorkSheetData(acctDetails, hldDetails, viewYield);
        wb.Sheets["AccountDetails"] = accountDetailsWs;
        timestamp = getFormattedTime();
        fileName = "AccountHoldingDetails-" + timestamp + ".xlsx";
    }

    if(isSecurity) {
        //step 3: create Account details workbook
        wb.SheetNames.push("SecurityDetails");
        var securityDetailsWs = getSecurityHoldingDetailsWorkSheetData(acctDetails, hldDetails, viewYield);
        wb.Sheets["SecurityDetails"] = securityDetailsWs;
        timestamp = getFormattedTime();
        fileName = "SecurityHoldingDetails-" + timestamp + ".xlsx";
    }

    if (isCategory) {
        wb.SheetNames.push("AssetCategoryDetails");
        var securityDetailsWs = getAssetHoldingDetailsWorkSheetData(acctDetails, hldDetails, viewYield);
        wb.Sheets["AssetCategoryDetails"] = securityDetailsWs;
        timestamp = getFormattedTime();
        fileName = "AssetTypeHoldingDetails-" + timestamp + ".xlsx";
    }
    //step 4: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 5: user file saver to save the file
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), fileName, "application/octet-stream");
    return;
}

function getAccountHoldingDetailsWorkSheetData(acctDetails, props, viewYield) {

    var xlsHeader = ["Account", "Symbol", "Description", "Quantity", "Unit Cost", "Cost Basis", "Price", "Value", "Today's Change $",
        "Today's Change %", "Unrealized Gain/loss $", "Unrealized Gain/loss %"];
    if (viewYield) {
        xlsHeader.push("Yield %");
    }
    var xlsRows = []
    if (props && props.holdingsbyaccount && props.holdingsbyaccount.length > 0 && props.holdingsbyaccount[0].AcctPosLst) {
        props.holdingsbyaccount[0].AcctPosLst.forEach(account => {
            if (account) {

                if (account.INVPOSLIST && account.INVPOSLIST.length > 0) {
                    account.INVPOSLIST.forEach(invitem => {
                        var unglVal = (invitem.UnrealizedValue && invitem.UnrealizedValue > 0 || invitem.UnrealizedValue && invitem.UnrealizedValue < 0) ? (invitem.UnrealizedValue) : 0;
                        var unglPct = (invitem.UnrealizedPct && invitem.UnrealizedPct > 0 || invitem.UnrealizedPct && invitem.UnrealizedPct < 0) ? (invitem.UnrealizedPct / 100) : 0;
                        var mktVal = (invitem.MKTVALS.MKTVALCHANGE && (invitem.MKTVALS.MKTVALCHANGE > 0 || invitem.MKTVALS.MKTVALCHANGE < 0)) ? (invitem.MKTVALS.MKTVALCHANGE) : 0;
                        var mktPct = (invitem.MKTVALS.MKTVALCHANGEPCT && (invitem.MKTVALS.MKTVALCHANGEPCT > 0 || invitem.MKTVALS.MKTVALCHANGEPCT < 0)) ? (invitem.MKTVALS.MKTVALCHANGEPCT / 100) : 0;
                        var yieldPct = (invitem.SECINFO.YieldPct && invitem.SECINFO.YieldPct > 0 || invitem.SECINFO.YieldPct && invitem.SECINFO.YieldPct < 0) ? roundvalue(invitem.SECINFO.YieldPct, 2) + "%" : GetNumericValue(invitem.SECINFO.YieldPct, true, false);
                        xlRow = {
                            "Account": (account.ACCTNUM ? getAccountDisplayName(acctDetails.selectedaccount, account.ACCTNUM, IndLongShort.SINGLEVIEW) : GetTextValue(account.ACCTNUM)),
                            "Symbol": (invitem.SECINFO && invitem.SECINFO.SECID ? invitem.SECINFO.SECID : GetTextValue(invitem.SECINFO.SECID)),
                            "Description": (invitem.SECINFO && invitem.SECINFO.SECNAME ? invitem.SECINFO.SECNAME : GetTextValue(invitem.SECINFO.SECNAME)),
                            "Quantity": (invitem.SHRQNTS && invitem.SHRQNTS.UNIT ? (invitem.SHRQNTS.UNIT) : 0),
                            "Unit Cost": (invitem.COSTBASIS && invitem.COSTBASIS.AVGCOSTPERSHARE ? (invitem.COSTBASIS.AVGCOSTPERSHARE) : 0),
                            "Cost Basis": (invitem.COSTBASIS && invitem.COSTBASIS.COSTBASIS ? (invitem.COSTBASIS.COSTBASIS) : 0),
                            "Price": (invitem.PRICING && invitem.PRICING.RCNTPRICE ? (invitem.PRICING.RCNTPRICE) : 0),
                            "Value": (invitem.MKTVALS && invitem.MKTVALS.RCNTMKTVAL ? (invitem.MKTVALS.RCNTMKTVAL) : 0),
                            "Today's Change $": mktVal,
                            "Today's Change %": mktPct,
                            "Unrealized Gain/loss $": unglVal,
                            "Unrealized Gain/loss %": unglPct
                        }
                        if (viewYield)
                            xlRow = { ...xlRow, "Yield %": yieldPct }
                        xlsRows.push(xlRow);
                    }
                    )

                }
                else {
                    var unglVal = (account.UNGLVAL && (account.UNGLVAL > 0 || account.UNGLVAL < 0)) ? (account.UNGLVAL) : 0;
                    var unglPct = (account.UNGLPCT && (account.UNGLPCT > 0 || account.UNGLPCT < 0)) ? roundvalue(account.UNGLPCT / 100) : 0;
                    var mktVal = (account.MKTVALCHANGE && (account.MKTVALCHANGE > 0 || account.MKTVALCHANGE < 0)) ? (account.MKTVALCHANGE) : 0;
                    var mktPct = (account.MKTVALCHANGEPCT && (account.MKTVALCHANGEPCT > 0 || account.MKTVALCHANGEPCT < 0)) ? (account.MKTVALCHANGEPCT / 100) : 0;

                    var xlRow = {
                        "Account": (account.ACCTNUM ? getAccountDisplayName(acctDetails.selectedaccount, account.ACCTNUM, IndLongShort.SINGLEVIEW) : GetTextValue(account.ACCTNUM)),
                        "Symbol": "",
                        "Description": (account.ACCTYPE ? account.ACCTYPE : GetTextValue(account.ACCTYPE)),
                        "Quantity": (account.UNIT ? (account.UNIT) : 0),
                        "Unit Cost": (account.AVGCOSTPERSHARE ? (account.AVGCOSTPERSHARE) : 0),
                        "Cost Basis": (account.COSTBASIS && account.COSTBASIS ? (account.COSTBASIS) : 0),
                        "Price": (account.RCNTPRICE ? (account.RCNTPRICE) : 0),
                        "Value": (account.MKTVALS && account.MKTVALS.RCNTMKTVAL ? (account.MKTVALS.RCNTMKTVAL) : 0),
                        "Today's Change $": mktVal,
                        "Today's Change %": mktPct,
                        "Unrealized Gain/loss $": unglVal,
                        "Unrealized Gain/loss %": unglPct

                    }
                    if (viewYield)
                        xlRow = { ...xlRow, "Yield %": '' }
                    xlsRows.push(xlRow);
                }
            }
        })
        if (props && props.holdingsbyaccount && props.holdingsbyaccount.length > 0) {
            var unglVal = (props.holdingsbyaccount[0].unrealizedvalue && props.holdingsbyaccount[0].unrealizedvalue > 0 || props.holdingsbyaccount[0] && props.holdingsbyaccount[0].unrealizedvalue < 0) ? (props.holdingsbyaccount[0].unrealizedvalue) : 0;
            var unglPct = (props.holdingsbyaccount[0].unrealizedpct && props.holdingsbyaccount[0].unrealizedpct > 0 || props.holdingsbyaccount[0] && props.holdingsbyaccount[0].unrealizedpct < 0) ? (props.holdingsbyaccount[0].unrealizedpct / 100) : 0;
            var totalvalue = (props.holdingsbyaccount[0].TotalMktVal && props.holdingsbyaccount[0].TotalMktVal > 0 || props.holdingsbyaccount[0] && props.holdingsbyaccount[0].TotalMktVal < 0) ? (props.holdingsbyaccount[0].TotalMktVal) : 0;
            var xlRow = {
                "Account": "Total:",
                "Symbol": "",
                "Description": "",
                "Quantity": "",
                "Unit Cost": "",
                "Cost Basis": "",
                "Price": "",
                "Value": totalvalue,
                "Today's Change $": "",
                "Today's Change %": "",
                "Unrealized Gain/loss $": unglVal,
                "Unrealized Gain/loss %": unglPct
            }
            if (viewYield)
                xlRow = { ...xlRow, "Yield %": '' }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToNumber(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E',true);
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToPercentage(ws, 'J');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToPercentage(ws, 'L');
    if (viewYield)
        ws = formatColumnToPercentage(ws, 'M');
    return ws;
}

function getSecurityHoldingDetailsWorkSheetData(acctDetails, props, viewYield) {

    var xlsHeader = ["Symbol", "Description", "Account", "Quantity", "Unit Cost", "Cost Basis", "Price", "Value", "Today's Change $",
        "Today's Change %", "Unrealized Gain/loss $", "Unrealized Gain/loss %"];
    if (viewYield) {
        xlsHeader.push("Yield %");
    }
    var xlsRows = []
    if (props && props.holdingsbysecurity && props.holdingsbysecurity[0] && props.holdingsbysecurity[0].DetailPOSLIST) {
        props.holdingsbysecurity[0].DetailPOSLIST.forEach(security => {

            if (security.INVPOSLIST && security.INVPOSLIST.length > 0) {
                security.INVPOSLIST.forEach(invitem => {
                    var unglVal = (invitem.UnrealizedValue && invitem.UnrealizedValue > 0 || invitem.UnrealizedValue && invitem.UnrealizedValue < 0) ? (invitem.UnrealizedValue) : 0;
                    var unglPct = (invitem.UnrealizedPct && invitem.UnrealizedPct > 0 || invitem.UnrealizedPct && invitem.UnrealizedPct < 0) ? (invitem.UnrealizedPct / 100) : 0;
                    var mktVal = (invitem.MKTVALS.MKTVALCHANGE && invitem.MKTVALS.MKTVALCHANGE > 0 || invitem.MKTVALS.MKTVALCHANGE && invitem.MKTVALS.MKTVALCHANGE < 0) ? (invitem.MKTVALS.MKTVALCHANGE) : 0;
                    var mktPct = (invitem.MKTVALS.MKTVALCHANGEPCT && invitem.MKTVALS.MKTVALCHANGEPCT > 0 || invitem.MKTVALS.MKTVALCHANGEPCT && invitem.MKTVALS.MKTVALCHANGEPCT < 0) ? (invitem.MKTVALS.MKTVALCHANGEPCT / 100) : 0;
                    var yieldPct = (invitem.SECINFO.YieldPct && invitem.SECINFO.YieldPct > 0 || invitem.SECINFO.YieldPct && invitem.SECINFO.YieldPct < 0) ? roundvalue(invitem.SECINFO.YieldPct, 2) + "%" : GetNumericValue(invitem.SECINFO.YieldPct, true, false);
                    xlRow = {
                        "Symbol": (invitem.SECINFO && invitem.SECINFO.SECID ? invitem.SECINFO.SECID : GetTextValue(invitem.SECINFO.SECID)),
                        "Description": (invitem.SECINFO && invitem.SECINFO.SECNAME ? invitem.SECINFO.SECNAME : GetTextValue(invitem.SECINFO.SECNAME)),
                        "Account": (invitem.ACCTNUM ? getAccountDisplayName(acctDetails.selectedaccount, invitem.ACCTNUM, IndLongShort.SINGLEVIEW) : GetTextValue(invitem.ACCTNUM)),
                        "Quantity": (invitem.SHRQNTS && invitem.SHRQNTS.UNIT ? (invitem.SHRQNTS.UNIT) : 0),
                        "Unit Cost": (invitem.COSTBASIS && invitem.COSTBASIS.AVGCOSTPERSHARE ? (invitem.COSTBASIS.AVGCOSTPERSHARE) : 0),
                        "Cost Basis": (invitem.COSTBASIS && invitem.COSTBASIS.COSTBASIS ? (invitem.COSTBASIS.COSTBASIS) : 0),
                        "Price": (invitem.PRICING && invitem.PRICING.RCNTPRICE ? (invitem.PRICING.RCNTPRICE) : 0),
                        "Value": (invitem.MKTVALS && invitem.MKTVALS.RCNTMKTVAL ? (invitem.MKTVALS.RCNTMKTVAL) : 0),
                        "Today's Change $": mktVal,
                        "Today's Change %": mktPct,
                        "Unrealized Gain/loss $": unglVal,
                        "Unrealized Gain/loss %": unglPct
                    }
                    if (viewYield)
                        xlRow = { ...xlRow, "Yield %": yieldPct }

                    xlsRows.push(xlRow);
                }
                )

            }
            else {
                var unglVal = (security.UnrealizedValue && security.UnrealizedValue > 0 || security.UnrealizedValue && security.UnrealizedValue < 0) ? (security.UnrealizedValue) : 0;
                var unglPct = (security.UnrealizedPct && security.UnrealizedPct > 0 || security.UnrealizedPct && security.UnrealizedPct < 0) ? (security.UnrealizedPct / 100) : 0;
                var mktVal = (security.MKTVALS.MKTVALCHANGE && security.MKTVALS.MKTVALCHANGE > 0 || security.MKTVALS.MKTVALCHANGE && security.MKTVALS.MKTVALCHANGE < 0) ? (security.MKTVALS.MKTVALCHANGE) : 0;
                var mktPct = (security.MKTVALS.MKTVALCHANGEPCT && security.MKTVALS.MKTVALCHANGEPCT > 0 || security.MKTVALS.MKTVALCHANGEPCT && security.MKTVALS.MKTVALCHANGEPCT < 0) ? (security.MKTVALS.MKTVALCHANGEPCT / 100) : 0;
                var yieldPct = (security.SECINFO.YieldPct && security.SECINFO.YieldPct > 0 || security.SECINFO.YieldPct && security.SECINFO.YieldPct < 0) ? roundvalue(security.SECINFO.YieldPct, 2) + "%" : GetNumericValue(security.SECINFO.YieldPct, true, false);
                var xlRow = {
                    "Symbol": (security.SECINFO && security.SECINFO.SECID ? security.SECINFO.SECID : GetTextValue(security.SECINFO.SECID)),
                    "Description": (security.SECINFO && security.SECINFO.SECNAME ? security.SECINFO.SECNAME : GetTextValue(security.SECINFO.SECNAME)),
                    "Account": "",
                    "Quantity": (security.SHRQNTS && security.SHRQNTS.UNIT ? (security.SHRQNTS.UNIT) : 0),
                    "Unit Cost": (security.COSTBASIS && security.COSTBASIS.AVGCOSTPERSHARE ? (security.COSTBASIS.AVGCOSTPERSHARE) : 0),
                    "Cost Basis": (security.COSTBASIS && security.COSTBASIS.COSTBASIS ? (security.COSTBASIS.COSTBASIS) : 0),
                    "Price": (security.PRICING && security.PRICING.RCNTPRICE ? (security.PRICING.RCNTPRICE) : 0),
                    "Value": (security.MKTVALS && security.MKTVALS.RCNTMKTVAL ? (security.MKTVALS.RCNTMKTVAL) : 0),
                    "Today's Change $": mktVal,
                    "Today's Change %": mktPct,
                    "Unrealized Gain/loss $": unglVal,
                    "Unrealized Gain/loss %": unglPct
                }
                if (viewYield)
                    xlRow = { ...xlRow, "Yield %": yieldPct }
                xlsRows.push(xlRow);
            }

        })

        if (props && props.holdingsbysecurity && props.holdingsbysecurity[0] && props.holdingsbysecurity.length > 0) {
            var unglVal = (props.holdingsbysecurity[0].unrealizedvalue && props.holdingsbysecurity[0].unrealizedvalue > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].unrealizedvalue < 0) ? (props.holdingsbysecurity[0].unrealizedvalue) : 0;
            var unglPct = (props.holdingsbysecurity[0].unrealizedpct && props.holdingsbysecurity[0].unrealizedpct > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].unrealizedpct < 0) ? (props.holdingsbysecurity[0].unrealizedpct / 100) : 0;
            var totalvalue = (props.holdingsbysecurity[0].totalvalue && props.holdingsbysecurity[0].totalvalue > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].totalvalue < 0) ? (props.holdingsbysecurity[0].totalvalue) : 0;
            var costbasis = (props.holdingsbysecurity[0].costbasis && props.holdingsbysecurity[0].costbasis > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].costbasis < 0) ? (props.holdingsbysecurity[0].costbasis) : 0;
            var xlRow = {
                "Symbol": "Total:",
                "Description": "",
                "Account": "",
                "Quantity": "",
                "Unit Cost": "",
                "Cost Basis": costbasis,
                "Price": "",
                "Value": totalvalue,
                "Today's Change $": "",
                "Today's Change %": "",
                "Unrealized Gain/loss $": unglVal,
                "Unrealized Gain/loss %": unglPct
            }
            if (viewYield)
                xlRow = { ...xlRow, "Yield %": '' }
            xlsRows.push(xlRow);
        }
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToNumber(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E',true);
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToPercentage(ws, 'J');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToPercentage(ws, 'L');
    if (viewYield) {
        ws = formatColumnToPercentage(ws, 'M');
    }

    return ws;
}

function getAssetHoldingDetailsWorkSheetData(acctDetails, props, viewYield) {

    var xlsHeader = ["Asset class", "Symbol", "Description", "Account", "Quantity", "Unit Cost", "Cost Basis", "Price", "Value",
        "Today's Change $", "Today's Change %", "Unrealized Gain/loss $", "Unrealized Gain/loss %", "% of Portfolio"];
    if (viewYield) {
        xlsHeader.push("Yield %")
    }
    var xlsRows = []
    if (props && props.holdingsbycategory && props.holdingsbycategory.length > 0 && props.holdingsbycategory[0].value) {
        const assetData = renderRowsByAsset(props.holdingsbycategory[0].value, props.assetschemacolorsconfig, props.assetSchema);
        assetData.forEach(asset => {
            if (asset.INVPOSLIST && asset.INVPOSLIST.length > 0) {
                asset.INVPOSLIST.forEach(security => {
                    if (security.invposlist && security.invposlist.length > 0) {
                        security.invposlist.forEach(account => {
                            var unglVal = (account.unrealizedValue && account.unrealizedValue > 0 || account.unrealizedValue && account.unrealizedValue < 0) ? (account.unrealizedValue) : 0;
                            var unglPct = (account.unrealizedValue && account.unrealizedPct > 0 || account.unrealizedPct && account.unrealizedPct < 0) ? (account.unrealizedPct / 100) : 0;
                            var mktVal = (account.mktvals.mktvalchange && account.mktvals.mktvalchange > 0 || account.mktvals.mktvalchange && account.mktvals.mktvalchange < 0) ? (account.mktvals.mktvalchange) : 0;
                            var mktPct = (account.mktvals.mktvalchangepct && account.mktvals.mktvalchangepct > 0 || account.mktvals.mktvalchangepct && account.mktvals.mktvalchangepct < 0) ? (account.mktvals.mktvalchangepct / 100) : 0;
                            var yieldPct = (account.secinfo.yieldPct && account.secinfo.yieldPct > 0 || account.secinfo.yieldPct && account.secinfo.yieldPct < 0) ? roundvalue(account.secinfo.yieldPct, 2) + "%" : GetNumericValue(account.secinfo.yieldPct, true, false);
                            xlRow = {
                                "Asset class": (account.secinfo && account.secinfo.assetcat ? GetTextValue(account.secinfo.assetcat) : '-'),
                                "Symbol": (account.secinfo && account.secinfo.secid ? GetTextValue(account.secinfo.secid) : '-'),
                                "Description": (account.secinfo && account.secinfo.secname ? GetTextValue(account.secinfo.secname) : '-'),
                                "Account": getAccountDisplayName(acctDetails.selectedaccount, account.acctnum, IndLongShort.SINGLEVIEW),
                                "Quantity": (account.shrqnts && account.shrqnts.recentUNIT ? (account.shrqnts.recentUNIT) : 0),
                                "Unit Cost": (account.costbasis && account.costbasis.avgcostpershare ? (account.costbasis.avgcostpershare) : 0),
                                "Cost Basis": (account.costbasis && account.costbasis.costbasis ? (account.costbasis.costbasis) : 0),
                                "Price": (account.pricing && account.pricing.rcntprice ? (account.pricing.rcntprice) : 0),
                                "Value": (account.mktvals && account.mktvals.rcntmktval ? (account.mktvals.rcntmktval) : 0),
                                "Today's Change $": mktVal,
                                "Today's Change %": mktPct,
                                "Unrealized Gain/loss $": unglVal,
                                "Unrealized Gain/loss %": unglPct,
                                "% of Portfolio": (account.mktvalpct ? (account.mktvalpct / 100) : 0)
                            }
                            if (viewYield)
                                xlRow = { ...xlRow, "Yield %": yieldPct }
                            xlsRows.push(xlRow);
                        })
                    }
                    else {
                        var unglVal = (security.unrealizedValue && security.unrealizedValue > 0 || security.unrealizedValue && security.unrealizedValue < 0) ? formatMoney(security.unrealizedValue) : 0;
                        var unglPct = (security.unrealizedValue && security.unrealizedPct > 0 || security.unrealizedPct && security.unrealizedPct < 0) ? roundvalue(security.unrealizedPct, 2) + "%" : 0;
                        var mktVal = (security.mktvals.mktvalchange && security.mktvals.mktvalchange > 0 || security.mktvals.mktvalchange && security.mktvals.mktvalchange < 0) ? formatMoney(security.mktvals.mktvalchange) : 0;
                        var mktPct = (security.mktvals.mktvalchangepct && security.mktvals.mktvalchangepct > 0 || security.mktvals.mktvalchangepct && security.mktvals.mktvalchangepct < 0) ? roundvalue(security.mktvals.mktvalchangepct, 2) + "%" : 0;
                        var yieldPct = (security.secinfo.yieldPct && security.secinfo.yieldPct > 0 || security.secinfo.yieldPct && security.secinfo.yieldPct < 0) ? roundvalue(security.secinfo.yieldPct, 2) + "%" : GetNumericValue(security.secinfo.yieldPct, true, false);
                        var xlRow = {
                            "Asset class": (security.secinfo && security.secinfo.assetcat ? GetTextValue(security.secinfo.assetcat) : '-'),
                            "Symbol": (security.secinfo && security.secinfo.secid ? GetTextValue(security.secinfo.secid) : '-'),
                            "Description": (security.secinfo && security.secinfo.secname ? GetTextValue(security.secinfo.secname) : '-'),
                            "Account": "",
                            "Quantity": (security.shrqnts ? security.shrqnts : 0),
                            "Unit Cost": 0,
                            "Cost Basis": (security.costbasis ? security.costbasis : 0),
                            "Price": (security.pricing && security.pricing.rcntprice ? (security.pricing.rcntprice) : 0),
                            "Value": (security.mktvals && security.mktvals.rcntmktval ? (security.mktvals && security.mktvals.rcntmktval) : 0),
                            "Today's Change $": mktVal,
                            "Today's Change %": mktPct,
                            "Unrealized Gain/loss $": unglVal,
                            "Unrealized Gain/loss %": unglPct,
                            "% of Portfolio": ""
                        }
                        if (viewYield)
                            xlRow = { ...xlRow, "Yield %": yieldPct }
                        xlsRows.push(xlRow);
                    }
                })
            }
            else {
                var unglVal = (asset.MKTVALCHANGE && asset.MKTVALCHANGE > 0 || asset.MKTVALCHANGE && asset.MKTVALCHANGE < 0) ? formatMoney(asset.MKTVALCHANGE) : MKTVALCHANGE(asset.MKTVALCHANGE);
                var unglPct = (asset.MKTVALCHANGEPCT && asset.MKTVALCHANGEPCT > 0 || asset.MKTVALCHANGEPCT && asset.MKTVALCHANGEPCT < 0) ? roundvalue(asset.MKTVALCHANGEPCT, 2) + "%" : GetNumericValue(asset.MKTVALCHANGEPCT, true, false);
                var mktVal = (asset.TODAYSCHANGE && asset.TODAYSCHANGE > 0 || asset.TODAYSCHANGE && asset.TODAYSCHANGE < 0) ? formatMoney(asset.TODAYSCHANGE) : GetNumericValue(asset.TODAYSCHANGE);
                var mktPct = (asset.TODAYSCHANGEPCT && asset.TODAYSCHANGEPCT > 0 || asset.TODAYSCHANGEPCT && asset.TODAYSCHANGEPCT < 0) ? roundvalue(asset.TODAYSCHANGEPCT, 2) + "%" : GetNumericValue(asset.TODAYSCHANGEPCT, true, false);
                var xlRow = {
                    "Asset class": GetTextValue(asset.ASSET),
                    "Symbol": "-",
                    "Description": "-",
                    "Account": "",
                    "Quantity": 0,
                    "Unit Cost": 0,
                    "Cost Basis": (asset.COSTBASIS ? asset.COSTBASIS : 0),
                    "Price": 0,
                    "Value": (asset.RCNTMKTVAL ? asset.RCNTMKTVAL : 0),
                    "Today's Change $": mktVal,
                    "Today's Change %": mktPct,
                    "Unrealized Gain/loss $": unglVal,
                    "Unrealized Gain/loss %": unglPct,
                    "% of Portfolio": (asset.ASSETTOTALPCT ? asset.ASSETTOTALPCT : 0)
                }
                if (viewYield)
                    xlRow = { ...xlRow, "Yield %": '' }
                xlsRows.push(xlRow);

            }

        })

        //if (props && props.holdingsbysecurity && props.holdingsbysecurity[0] && props.holdingsbysecurity.length > 0) {
        //    var unglVal = (props.holdingsbysecurity[0].unrealizedvalue && props.holdingsbysecurity[0].unrealizedvalue > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].unrealizedvalue < 0) ? (props.holdingsbysecurity[0].unrealizedvalue) : 0;
        //    var unglPct = (props.holdingsbysecurity[0].unrealizedpct && props.holdingsbysecurity[0].unrealizedpct > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].unrealizedpct < 0) ? (props.holdingsbysecurity[0].unrealizedpct / 100) : 0;
        //    var totalvalue = (props.holdingsbysecurity[0].totalvalue && props.holdingsbysecurity[0].totalvalue > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].totalvalue < 0) ? (props.holdingsbysecurity[0].totalvalue) : 0;
        //    var costbasis = (props.holdingsbysecurity[0].costbasis && props.holdingsbysecurity[0].costbasis > 0 || props.holdingsbysecurity[0] && props.holdingsbysecurity[0].costbasis < 0) ? (props.holdingsbysecurity[0].costbasis) : 0;
        //    var xlRow = {
        //        "Asset class": "Total",
        //        "Symbol": "Total:",
        //        "Description": "",
        //        "Account": "",
        //        "Quantity": "",
        //        "Unit Cost": "",
        //        "Cost Basis": costbasis,
        //        "Price": "",
        //        "Value": totalvalue,
        //        "Today's Change $": "",
        //        "Today's Change %": "",
        //        "Unrealized Gain/loss $": unglVal,
        //        "Unrealized Gain/loss %": unglPct
        //    }
        //    xlsRows.push(xlRow);
        //}
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });

    ws = formatColumnToNumber(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F', true);
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToCurrency(ws, 'J');
    ws = formatColumnToPercentage(ws, 'K');
    ws = formatColumnToCurrency(ws, 'L');
    ws = formatColumnToPercentage(ws, 'M');
    ws = formatColumnToPercentage(ws, 'N');
    if (viewYield)
        ws = formatColumnToPercentage(ws, 'O');
    return ws;
}
function getAssetAllocationDetailsWorkSheetData(props) {

    var xlsHeader = ["Asset Class", "Symbol", "Description", "Account", "Quantity", "Price", "Value"];
    var xlsRows = []
    if (props !== null && props.assetallocationdetails && props.assetallocationdetails[0].value) {
        const assetallocationData = renderTemplateRows(props.assetallocationdetails[0], props.assetschemacolorsconfig, props.assetSchema);
        assetallocationData.forEach(asset => {
            if (asset && asset.invpossummary.length > 0) {
                asset.invpossummary.forEach(invpossummary => {
                    if (invpossummary.invposlist && invpossummary.invposlist.length > 0) {
                        invpossummary.invposlist.forEach(invposlist => {
                            var xlRow = {
                                "Asset Class": GetTextValue(asset.assetcat),
                                "Symbol": (GetTextValue(invposlist.secinfo.ticker ? invposlist.secinfo.ticker : invposlist.secinfo.unqid)),
                                "Description": (invposlist.secinfo && invposlist.secinfo.secname ? invposlist.secinfo.secname : GetTextValue(invposlist.secinfo.secname)),
                                "Account": GetTextValue(invposlist.acctnum),
                                "Quantity": invposlist.shrqnts && invposlist.shrqnts.unit ? invposlist.shrqnts.unit : 0,
                                "Price": invposlist.pricing && invposlist.pricing.rcntprice ? (invposlist.pricing.rcntprice) : 0,
                                //"Percentage": invposlist.assettotalpct && invposlist.assettotalpct > 0 ? (invposlist.assettotalpct / 100) : 0,
                                "Value": invposlist.mktvals && invposlist.mktvals.rcntmktval ? (invposlist.mktvals.rcntmktval) : 0
                            }
                            xlsRows.push(xlRow);
                        })
                    }

                })
            }
            else {
                var xlRow = {
                    "Asset Class": GetTextValue(asset.assetcat),
                    "Symbol": "",
                    "Description": "",
                    "Account": "",
                    "Quantity": "",
                    "Price": "",
                    //"Percentage": asset.mktvalpct && asset.mktvalpct > 0 ? (asset.mktvalpct / 100) : 0,
                    "Value": asset.mktval ? (asset.mktval) : 0
                }
                xlsRows.push(xlRow);
            }

        })
    }

    if (props !== null && props && props.assetallocationdetails[0]) {
        var totalvalue = (props.assetallocationdetails[0].totalMktVal && props.assetallocationdetails[0].totalMktVal > 0 || props.assetallocationdetails[0] && props.assetallocationdetails[0].totalMktVal < 0) ? (props.assetallocationdetails[0].totalMktVal) : 0;
        var totalpct = (props.assetallocationdetails[0].totalPct && props.assetallocationdetails[0].totalPct > 0 || props.assetallocationdetails[0] && props.assetallocationdetails[0].totalPct < 0) ? (props.assetallocationdetails[0].totalPct / 100) : 0;
        var xlRow = {
            "Asset Class": "Total",
            "Symbol": "",
            "Description": "",
            "Account": "",
            "Quantity": "",
            "Price": "",
            //"Percentage": totalpct,
            "Value": totalvalue
        }
        xlsRows.push(xlRow);
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToNumber(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    //ws = formatColumnToPercentage(ws, 'G');
    ws = formatColumnToCurrency(ws, 'G');
    return ws;
}

export function generateBalanceDetailsExcelReport(props) {
    let obj = {
        preferences: props.preferences
    }
    const isBalSummaryV2 = GetPreferencePilotValue(obj, 'BalSummaryV2', 'false') === 'true' ? true : false;;
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Financial Details Report",
        Subject: "Financial Details Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("FinancialDetails");
    var xlsHeader = ["Account", "Priced Investments", "Cash/Money Accounts",
        isBalSummaryV2 ? "Other Credit/Debit" : "Margin Balance",
        "Accrued Interest", "Pending Activity(Cash/Securities)",
        isBalSummaryV2 ? "Margin/Outstanding Balance" : "Outstanding Balance",
        "Insurance/Annuity", "Value"];
    var xlsRows = []
    if (props && props.balancedetails.value[0] && props.balancedetails.value[0].accountbalances) {
        var accountbalances = props.balancedetails.value[0].accountbalances
        if (accountbalances) {
            accountbalances.forEach((row) => {
                if (row) {
                    var xlRow = {
                        "Account": getAccountDisplayName(props.selectedaccount, row.accountaumber, IndLongShort.SINGLEVIEW),
                        "Priced Investments": row.pricedinvestments ? (row.pricedinvestments) : 0,
                        "Cash/Money Accounts": row.cashmoneyaccounts ? (row.cashmoneyaccounts) : 0,
                        //"Margin Balance" : row.marginbalance ? (row.marginbalance) : 0,
                        "Accrued Interest": row.fixedincomeaccruedinterest ? (row.fixedincomeaccruedinterest) : 0,
                        "Pending Activity(Cash/Securities)": row.pendingactivity ? (row.pendingactivity) : 0,
                        //"Outstanding Balance": row.outstandingbalance ? (row.outstandingbalance) : 0,
                        "Insurance/Annuity": row.annuity ? (row.annuity) : 0,
                        "Value": row.totalaccountvalue ? (row.totalaccountvalue) : 0,
                    }
                    if (isBalSummaryV2) {
                        xlRow["Other Credit/Debit"] = row.othercreditdebit ? (row.othercreditdebit) : 0
                        xlRow["Margin/Outstanding Balance"] = row.marginandoutstandingbalance ? (row.marginandoutstandingbalance) : 0
                    } else {
                        xlRow["Margin Balance"] = row.marginbalance ? (row.marginbalance) : 0
                        xlRow["Outstanding Balance"] = row.outstandingbalance ? (row.outstandingbalance) : 0
                    }

                    xlsRows.push(xlRow);
                }
            })
        }

        if (props.balancedetails && props.balancedetails.value && props.balancedetails.value.length > 0) {
            var xlRow = {
                "Account": "Total",
                "Priced Investments": (props.balancedetails.value[0].pricedinvestments ? props.balancedetails.value[0].pricedinvestments : 0),
                "Cash/Money Accounts": (props.balancedetails.value[0].cashmoneyaccounts ? props.balancedetails.value[0].cashmoneyaccounts : 0),
                //"Margin Balance": (props.balancedetails.value[0].marginbalance ? props.balancedetails.value[0].marginbalance : 0),
                "Accrued Interest": (props.balancedetails.value[0].fixedincomeaccruedinterest ? props.balancedetails.value[0].fixedincomeaccruedinterest : 0),
                "Pending Activity(Cash/Securities)": (props.balancedetails.value[0].pendingactivity ? props.balancedetails.value[0].pendingactivity : 0),
                //"Outstanding Balance": (props.balancedetails.value[0].outstandingbalance ? props.balancedetails.value[0].outstandingbalance : 0),
                "Insurance/Annuity": (props.balancedetails.value[0].annuity ? props.balancedetails.value[0].annuity : 0),
                "Value": (props.balancedetails.value[0].totalaccountvalue ? props.balancedetails.value[0].totalaccountvalue : 0)
            }
            if (isBalSummaryV2) {
                xlRow["Other Credit/Debit"] = (props.balancedetails.value[0].othercreditdebit ? props.balancedetails.value[0].othercreditdebit : 0)
                xlRow["Margin/Outstanding Balance"] = (props.balancedetails.value[0].marginandoutstandingbalance ? props.balancedetails.value[0].marginandoutstandingbalance : 0)
            } else {
                xlRow["Margin Balance"] = (props.balancedetails.value[0].marginbalance ? props.balancedetails.value[0].marginbalance : 0)
                xlRow["Outstanding Balance"] = (props.balancedetails.value[0].outstandingbalance ? props.balancedetails.value[0].outstandingbalance : 0)
            }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });

    ws = formatColumnToCurrency(ws, 'B');
    ws = formatColumnToCurrency(ws, 'C');
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    wb.Sheets["FinancialDetails"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "FinancialDetails-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

function generateActivityDetailsExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Activity Details Report",
        Subject: "Activity Details Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("AccountActivityDetails");
    var xlsHeader = ["Trade Date", "Settlement Date", "Account", "Description", "Type", "Symbol/CUSIP", "Quantity", "Price", "Amount"];
    var xlsRows = []
    if (props && props.activitydetails && props.activitydetails.value[0] && props.activitydetails.value[0].activities) {
        var activities = props.activitydetails.value[0].activities;
        activities.sort(function (a, b) {
            if (b['dtsettle']) return a['dtsettle'] ? b['dtsettle'].localeCompare(a['dtsettle']) : 1;
            else if (a['dtsettle']) return (b['dtsettle']) ? a['dtsettle'].localeCompare(b['dtsettle']) : -1;
        });
        props.activitydetails.value[0].activities.forEach(activity => {
            if (activity) {
                var xlRow = {
                    "Trade Date": (activity.dttrade ? activity.dttrade.replace(/T.+/, '') : GetTextValue(activity.dttrade)),
                    "Settlement Date": (activity.dtsettle ? activity.dtsettle.replace(/T.+/, '') : GetTextValue(activity.dtsettle)),
                    "Account": (activity.acct ? (getAccountDisplayName(props.selectedaccount, activity.acct, IndLongShort.SINGLEVIEW)) : GetTextValue(activity.acct)),
                    "Description": (activity.trndesc ? activity.trndesc : GetTextValue(activity.trndesc)),
                    "Type": (activity.trntype ? activity.trntype : GetTextValue(activity.trntype)),
                    "Symbol/CUSIP": (activity.secid ? activity.secid : GetTextValue(activity.secid)),
                    "Quantity": (activity.units ? activity.units : GetNumericValue(activity.units, false, true)),
                    "Price": (activity.unitprice ? activity.unitprice : GetNumericValue(activity.unitprice)),
                    "Amount": (activity.total ? activity.total : 0)
                }
                xlsRows.push(xlRow);
            }



        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });



    ws = formatColumnToNumber(ws, "G");
    ws = formatColumnToCurrency(ws, "H");
    ws = formatColumnToCurrency(ws, "I");
    wb.Sheets["AccountActivityDetails"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "AccountActivityDetails-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}


function generateTransfersExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Transfers Report",
        Subject: "Transfers Report",
        Author: "Copyright@Rockefeller"
    };



    wb.SheetNames.push("PastTransfers");
    let pastTransfersList = props.moneymovement.pastTransfersList;
    pastTransfersList.sort(function (a, b) {
        if (b['dttrade']) return a['dttrade'] ? b['dttrade'].localeCompare(a['dttrade']) : 1;
        else if (a['dttrade']) return (b['dttrade']) ? a['dttrade'].localeCompare(b['dttrade']) : -1;
    });
    wb.Sheets["PastTransfers"] = generatePastTransfersExcelWs(props, pastTransfersList);



    wb.SheetNames.push("PendingTransfers");
    let scheduledTransfersList = props.moneymovement.scheduledTransfersList;
    scheduledTransfersList.sort(function (a, b) {
        if (b['dttrade']) return a['dttrade'] ? b['dttrade'].localeCompare(a['dttrade']) : 1;
        else if (a['dttrade']) return (b['dttrade']) ? a['dttrade'].localeCompare(b['dttrade']) : -1;
    });
    wb.Sheets["PendingTransfers"] = generateScheduleTransfersExcelWs(props, scheduledTransfersList);
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Transfers-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}


function generateDocumentsExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Documents Report",
        Subject: "Documents Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Documents");
    var xlsHeader = ["Date", "Document Type", "Account", "Description",];
    var xlsRows = []
    if (props && props.acct && props.acct.filteredStatementsList) {
        props.acct.filteredStatementsList.forEach(document => {
            var xlRow = {
                "Date": (document.date ? new Date(document.date.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                "Document Type": GetTextValue(document.documentType),
                "Account": getAccountDisplayName(props.selectedaccount, document.primaryValue, IndLongShort.SINGLEVIEW),
                "Description": GetTextValue(document.description)
            }
            xlsRows.push(xlRow);
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });

    wb.Sheets["Documents"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Documents-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

function generateAccountStatementExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Documents Report",
        Subject: "Documents Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Documents");
    var xlsHeader = ["Account", "Document Name", "Date",];
    var xlsRows = []
    if (props && props.doc && props.doc.filteredAccountStatementsList) {
        props.doc.filteredAccountStatementsList.forEach(document => {
            var xlRow = {
                "Account": getAccountDisplayName(props.selectedaccount, document.primaryValue, IndLongShort.SINGLEVIEW),
                "Document Name": document.description,
                "Date": (document.date ? new Date(document.date.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
            }
            xlsRows.push(xlRow);
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });

    wb.Sheets["Documents"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Documents-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

function generateCorrespondenceExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Correspondence Report",
        Subject: "Correspondence Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Correspondence");
    var xlsHeader = ["Account", "Document Name", "Date"];
    var xlsRows = []
    if (props && props.doc && props.doc.filteredCorrespondenceDocumentList) {
        props.doc.filteredCorrespondenceDocumentList.forEach(document => {
            var xlRow = {
                "Account": document.primaryValue,
                "Document Name": document.description,
                "Date": (document.date ? new Date(document.date.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
            }
            xlsRows.push(xlRow);
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });

    wb.Sheets["Correspondence"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Correspondence" + timestamp + ".xlsx", "application / octet - stream");
    return;
}

function generateTaxDocumentsExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Tax Documents Report",
        Subject: "Tax Documents Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("TaxDocuments");
    var xlsHeader = ["Account", "Document Type", "Corrected", "Date"];
    var xlsRows = []
    if (props && props.doc && props.doc.filteredTaxDocumentList) {
        props.doc.filteredTaxDocumentList.forEach(document => {
            var xlRow = {
                "Account": document.primaryValue,
                "Document Type": document.description,
                "Corrected": document.corrected,
                "Date": (document.date ? new Date(document.date.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
            }
            xlsRows.push(xlRow);
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });

    wb.Sheets["TaxDocuments"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "TaxDocuments-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

function generateTradeConfirmsExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Trade Confirms Report",
        Subject: "Trade Confirms Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("TradeConfirms");
    var xlsHeader = [];
    var xlsRows = []
    if (props && props.doc && props.doc.isSearchByTradeConfirmSummary) {
        xlsHeader = ["Account", "Summary Date", "Description"];
        if (props && props.doc && props.doc.filteredTradeConfirmSummaryDocumentList) {
            props.doc.filteredTradeConfirmSummaryDocumentList.forEach(document => {
                var xlRow = {
                    "Account": GetTextValue(document.primaryValue),
                    "Summary Date": (document.summaryDate ? new Date(document.summaryDate.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                    "Description": GetTextValue(document.description)
                }
                xlsRows.push(xlRow);
            })
        }
    }
    if (props && props.doc && !props.doc.isSearchByTradeConfirmSummary) {
        xlsHeader = ["Account", "Trade Date", "Description", "Security Type", "Action", "Symbol", "Cusip", "Quantity", "Price"];
        if (props && props.doc && props.doc.filteredTradeConfirmDocumentList) {
            props.doc.filteredTradeConfirmDocumentList.forEach(document => {
                var xlRow = {
                    "Account": GetTextValue(document.primaryValue),
                    "Trade Date": (document.tradeDate ? new Date(document.tradeDate.replace(/-/g, '\/').replace(/T.+/, '')) : "-"),
                    "Description": GetTextValue(document.description),
                    "Security Type": GetTextValue(document.securityType),
                    "Action": GetTextValue(document.action),
                    "Symbol": GetTextValue(document.symbol),
                    "Cusip": GetTextValue(document.cusip),
                    "Quantity": document.quantity ? parseFloat(document.quantity) : 0,
                    "Price": document.price ? parseFloat(document.price) : 0
                }
                xlsRows.push(xlRow);
            })
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });
    ws = formatColumnToNumber(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    wb.Sheets["TradeConfirms"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "TradeConfirms-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}


function generateExternalAcctExcelReport(props) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    var fileName = "";
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "External Account Report",
        Subject: "External Account Report",
        Author: "Copyright@Rockefeller"
    };

    wb.SheetNames.push("External Accounts");
    var extAcctWs = generateExtAcctExcelWs(props.manageextacct.externalAcctList);
    wb.Sheets["External Accounts"] = extAcctWs;
    var timestamp = getFormattedTime();
    fileName = "ExternalAccounts-" + timestamp + ".xlsx";

    //step 4: Write wotksheet into binary
    let wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 5: user file saver to save the file
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), fileName, "application/octet-stream");
    return;
}


export function getActivityWorkExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Account Activity Details Report",
        Subject: "Account Activity Details Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("AccountActivityDetails");
    var activityWs = getActivityWorkSheetData(props);
    wb.Sheets["AccountActivityDetails"] = activityWs;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "AccountActivityDetails-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

function generateAssetAggreagtionExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Asset Aggregation Details Report",
        Subject: "Asset Aggregation Details Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("AggregatedAccounts");
    var xlsHeader = ["Bank Name", "Account", "Nickname", "Account Type", "Status", "Last Updated Date"];
    var xlsRows = []
    if (props && props.acct && props.acct.aggregatedAccountList) {
        props.acct.aggregatedAccountList.forEach(account => {
            if (account) {
                var xlRow = {
                    "Bank Name": (account.finame ? account.finame : GetTextValue(account.finame)),
                    "Account": (GetTextValue(account.number)),
                    "Nickname": (account.nickname ? account.nickname : ''),
                    "Account Type": (account.accttype ? account.accttype : GetTextValue(account.accttype)),
                    "Status": (account.status ? account.status : GetTextValue(account.status)),
                    "Last Updated Date": (account.updatedon ? account.updatedon : '')
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    wb.Sheets["AggregatedAccounts"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "AggregatedAccountDetails-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function getEstimatedIncomeAccountExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Historical Income Account Report",
        Subject: "Historical Income Account Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("HistoricalIncomeAccount");
    var xlsHeader = ["Account", "Symbol", "Description", "Dividend", "Interest", "Capital Gain Disctribution", "Net Income"];
    var xlsRows = []
    if (props && props.topten && props.topten.estimatedincomebyaccount) {
        var estimatedIncomeByAcct = props.topten.estimatedincomebyaccount;
        if (estimatedIncomeByAcct && estimatedIncomeByAcct[0] && estimatedIncomeByAcct[0].itemSummary && Array.isArray(estimatedIncomeByAcct[0].itemSummary)) {
            estimatedIncomeByAcct[0].itemSummary.forEach((row) => {

                //sub rows
                if (row.ItemDetail && Array.isArray(row.ItemDetail) && row.ItemDetail.length > 0) {
                    row.ItemDetail.forEach(subrow => {
                        if (subrow) {
                            var xlRow = {
                                "Account": (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)),
                                "Symbol": subrow.SECNAME ? subrow.SECNAME : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-"),
                                "Description": subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-",
                                "Dividend": subrow.ORDDIV ? (subrow.ORDDIV) : 0,
                                "Interest": subrow.INTINCOME ? (subrow.INTINCOME) : 0,
                                "Capital Gain Disctribution": subrow.TOTCAPGAIN ? (subrow.TOTCAPGAIN) : 0,
                                "Net Income": subrow.TOTAL ? (subrow.TOTAL) : 0,
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
            })
        }

        // This is for footer 

        if (props && props.topten && props.topten.estimatedincomebyaccount && props.topten.estimatedincomebyaccount[0]) {
            var xlRow = {
                "Account": "Total",
                "Symbol": '',
                "Description": '',
                "Dividend": (props.topten.estimatedincomebyaccount[0].ODNDividend ? (props.topten.estimatedincomebyaccount[0].ODNDividend) : 0),
                "Interest": (props.topten.estimatedincomebyaccount[0].TotalInt ? (props.topten.estimatedincomebyaccount[0].TotalInt) : 0),
                "Capital Gain Disctribution": (props.topten.estimatedincomebyaccount[0].TOTCAPGAIN ? (props.topten.estimatedincomebyaccount[0].TOTCAPGAIN) : 0),
                "Net Income": (props.topten.estimatedincomebyaccount[0].NetIncome ? (props.topten.estimatedincomebyaccount[0].NetIncome) : 0)
            }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    wb.Sheets["HistoricalIncomeAccount"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "HistoricalIncomeAccount-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function getEstimatedIncomeSecurityExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Historical Income Security Report",
        Subject: "Historical Income Security Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("HistoricalIncomeSecurity");
    var xlsHeader = ["Symbol", "Description", "Account", "Dividend", "Interest", "Capital Gain Disctribution", "Net Income"];
    var xlsRows = []
    if (props && props.topten && props.topten.estimatedincomebysecurity) {
        var estimatedIncomeBySecurity = props.topten.estimatedincomebysecurity;
        if (estimatedIncomeBySecurity && estimatedIncomeBySecurity[0] && estimatedIncomeBySecurity[0].itemSummary && Array.isArray(estimatedIncomeBySecurity[0].itemSummary)) {
            estimatedIncomeBySecurity[0].itemSummary.forEach((row) => {


                //sub rows
                if (row.ItemDetail && Array.isArray(row.ItemDetail) && row.ItemDetail.length > 0) {
                    row.ItemDetail.forEach(subrow => {
                        if (subrow) {
                            var xlRow = {
                                "Symbol": row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-"),
                                "Description": row.SECDESCRIPTION ? row.SECDESCRIPTION : "-",
                                "Account": (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)),
                                "Dividend": subrow.ORDDIV ? (subrow.ORDDIV) : 0,
                                "Interest": subrow.INTINCOME ? (subrow.INTINCOME) : 0,
                                "Capital Gain Disctribution": subrow.TOTCAPGAIN ? (subrow.TOTCAPGAIN) : 0,
                                "Net Income": subrow.TOTAL ? (subrow.TOTAL) : 0
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
                else {
                    var xlRow = {
                        "Symbol": row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-"),
                        "Description": row.SECDESCRIPTION ? row.SECDESCRIPTION : "-",
                        "Account": "",
                        "Dividend": row.ORDDIV ? (row.ORDDIV) : 0,
                        "Interest": row.INTINCOME ? (row.INTINCOME) : 0,
                        "Capital Gain Disctribution": row.TOTCAPGAIN ? (row.TOTCAPGAIN) : 0,
                        "Net Income": row.TOTAL ? (row.TOTAL) : 0
                    }
                    xlsRows.push(xlRow);
                }
            })
        }

        // This is for footer 

        if (props && props.topten && props.topten.estimatedincomebysecurity && props.topten.estimatedincomebysecurity[0]) {
            var xlRow = {
                "Symbol": "Total",
                "Description": '',
                "Account": '',
                "Dividend": (props.topten.estimatedincomebysecurity[0].ODNDividend ? (props.topten.estimatedincomebysecurity[0].ODNDividend) : 0),
                "Interest": (props.topten.estimatedincomebysecurity[0].TotalInt ? (props.topten.estimatedincomebysecurity[0].TotalInt) : 0),
                "Capital Gain Disctribution": (props.topten.estimatedincomebysecurity[0].TOTCAPGAIN ? (props.topten.estimatedincomebysecurity[0].TOTCAPGAIN) : 0),
                "Net Income": (props.topten.estimatedincomebysecurity[0].NetIncome ? (props.topten.estimatedincomebysecurity[0].NetIncome) : 0)
            }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    wb.Sheets["HistoricalIncomeSecurity"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "HistoricalIncomeSecurity-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function generateEstimatedIncomeSummaryExcelReport(props) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "Historical Income Summary Report",
        Subject: "Historical Income Summary Report",
        Author: "Copyright@Rockefeller"
    };

    //step 3: create Balance work sheet

    //check if store data is present before adding the worksheet

    if (props && props.topten && props.topten.estimatedIncomeSummaryChartData) {
        wb.SheetNames.push("Hst. Income Summary Chart");
        var estimatedSummaryChart = getEstimatedIncomeSummaryChartWS(props.topten.estimatedIncomeSummaryChartData, props.topten.estimatedIncomeYear);
        wb.Sheets["Hst. Income Summary Chart"] = estimatedSummaryChart;
    } else {
        wb.SheetNames.push("Hst. Income Summary Chart");
        wb.Sheets["Hst. Income Summary Chart"] = null;
    }


    if (props && props.topten && props.topten.estimatedIncomeSummarySecurityData
        && props.topten.estimatedIncomeSummarySecurityData.value
        && Array.isArray(props.topten.estimatedIncomeSummarySecurityData.value)
        && props.topten.estimatedIncomeSummarySecurityData.value.length > 0) {
        wb.SheetNames.push("Hst. Income Summary By Security");
        var estimatedSummarySecurity = getEstimatedIncomeSummarySecurityWS(props.topten.estimatedIncomeSummarySecurityData.value[0], props);
        wb.Sheets["Hst. Income Summary By Security"] = estimatedSummarySecurity;
    } else {
        wb.SheetNames.push("Est. Income Summary By Security");
        wb.Sheets["Hst. Income Summary By Security"] = null;
    }


    if (props && props.topten && props.topten.estimatedIncomeSummaryAccountData
        && props.topten.estimatedIncomeSummaryAccountData.value
        && Array.isArray(props.topten.estimatedIncomeSummaryAccountData.value)
        && props.topten.estimatedIncomeSummaryAccountData.value.length > 0) {
        wb.SheetNames.push("Hst. Income Summary By Account");
        var estimatedSummaryAccount = getEstimatedIncomeSummaryAccountWS(props.topten.estimatedIncomeSummaryAccountData.value[0], props);
        wb.Sheets["Hst. Income Summary By Account"] = estimatedSummaryAccount;
    } else {
        wb.SheetNames.push("Est. Income Summary By Account");
        wb.Sheets["Hst. Income Summary By Account"] = null;
    }

    //step 8: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 9: user file saver to save the file
    var filename = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "HistoricalIncomeSummary-" + filename + ".xlsx", "application/octet-stream");
    return;

}

function getEstimatedIncomeSummaryChartWS(data, estimatedIncomeYear) {
    var xlsHeader = ["Month", "Interest", "Dividend", "Capital Gain", "Total"];
    let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var xlsRows = [];
    if (data && data[0] && data[0].incomeSubTotal && Array.isArray(data[0].incomeSubTotal) && data[0].incomeSubTotal.length > 0) {
        data[0].incomeSubTotal.forEach((row) => {
            if (row) {
                var xlRow = {
                    "Month": row.monseq ? (textfieldarr[row.monseq - 1] + ' ' + estimatedIncomeYear.toString().substr(-2)) : '-',
                    "Interest": row.interest ? row.interest : 0,
                    "Dividend": row.dividend ? row.dividend : 0,
                    "Capital Gain": row.capGain ? row.capGain : 0,
                    "Total": row.totalIncome ? row.totalIncome : 0
                }
                xlsRows.push(xlRow);
            }
        });

        // //footer value
        if (data && data[0]) {
            var xlRow = {
                "Month": "Total",
                "Interest": data[0].interest ? data[0].interest : 0,
                "Dividend": data[0].dividend ? data[0].dividend : 0,
                "Capital Gain": data[0].capGain ? data[0].capGain : 0,
                "Total": data[0].total ? data[0].total : 0,
            }
            xlsRows.push(xlRow);
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToNumber(ws, 'B');
    ws = formatColumnToNumber(ws, 'C');
    ws = formatColumnToNumber(ws, 'D');
    ws = formatColumnToNumber(ws, 'E');
    return ws;
}

function getEstimatedIncomeSummarySecurityWS(data, props) {
    let year = props.topten.estimatedIncomeYear ? (' ' + props.topten.estimatedIncomeYear.toString().substr(-2)) : '';
    var xlsHeader = ["Symbol", "Description", "Account", "Jan" + year, "Feb" + year, "Mar" + year, "Apr" + year, "May" + year, "Jun" + year, "Jul" + year, "Aug" + year, "Sep" + year, "Oct" + year, "Nov" + year, "Dec" + year, "Net Income"];
    var xlsRows = [];

    if (data && data.IncomeSubTotal && Array.isArray(data.IncomeSubTotal) && data.IncomeSubTotal.length > 0) {
        data.IncomeSubTotal.forEach((row) => {
            if (row) {
                //Add Sub rows data
                if (row.IncomeDetail && Array.isArray(row.IncomeDetail) && row.IncomeDetail.length > 0) {
                    row.IncomeDetail.forEach((subrow) => {
                        if (subrow) {
                            var xlRow = {
                                "Symbol": row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-"),
                                "Description": row.SECDESCRIPTION ? row.SECDESCRIPTION : "-",
                                "Account": (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)),
                                ["Jan" + year]: (subrow.JanuaryTotal ? (subrow.JanuaryTotal) : 0),
                                ["Feb" + year]: subrow.FebruaryTotal ? (subrow.FebruaryTotal) : 0,
                                ["Mar" + year]: subrow.MarchTotal ? (subrow.MarchTotal) : 0,
                                ["Apr" + year]: subrow.AprilTotal ? (subrow.AprilTotal) : 0,
                                ["May" + year]: subrow.MayTotal ? (subrow.MayTotal) : 0,
                                ["Jun" + year]: subrow.JuneTotal ? (subrow.JuneTotal) : 0,
                                ["Jul" + year]: subrow.JulyTotal ? (subrow.JulyTotal) : 0,
                                ["Aug" + year]: subrow.AugustTotal ? (subrow.AugustTotal) : 0,
                                ["Sep" + year]: subrow.SeptemberTotal ? (subrow.SeptemberTotal) : 0,
                                ["Oct" + year]: subrow.OctoberTotal ? (subrow.OctoberTotal) : 0,
                                ["Nov" + year]: subrow.NovemberTotal ? (subrow.NovemberTotal) : 0,
                                ["Dec" + year]: subrow.DecemberTotal ? (subrow.DecemberTotal) : 0,
                                "Net Income": subrow.Total ? (subrow.Total) : 0
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
                else {
                    var xlRow = {
                        "Symbol": row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-"),
                        "Description": row.SECDESCRIPTION ? row.SECDESCRIPTION : "-",
                        "Account": '',
                        ["Jan" + year]: row.JanuaryTotal ? (row.JanuaryTotal) : 0,
                        ["Feb" + year]: row.FebruaryTotal ? (row.FebruaryTotal) : 0,
                        ["Mar" + year]: row.MarchTotal ? (row.MarchTotal) : 0,
                        ["Apr" + year]: row.AprilTotal ? (row.AprilTotal) : 0,
                        ["May" + year]: row.MayTotal ? (row.MayTotal) : 0,
                        ["Jun" + year]: row.JuneTotal ? (row.JuneTotal) : 0,
                        ["Jul" + year]: row.JulyTotal ? (row.JulyTotal) : 0,
                        ["Aug" + year]: row.AugustTotal ? (row.AugustTotal) : 0,
                        ["Sep" + year]: row.SeptemberTotal ? (row.SeptemberTotal) : 0,
                        ["Oct" + year]: row.OctoberTotal ? (row.OctoberTotal) : 0,
                        ["Nov" + year]: row.NovemberTotal ? (row.NovemberTotal) : 0,
                        ["Dec" + year]: row.DecemberTotal ? (row.DecemberTotal) : 0,
                        "Net Income": row.Total ? (row.Total) : 0
                    }
                    xlsRows.push(xlRow);
                }
            }
        });
        //footer value
        if (data) {
            var xlRow = {
                "Symbol": "Total",
                "Description": '',
                "Account": '',
                ["Jan" + year]: data.JanuaryTotal ? (data.JanuaryTotal) : 0,
                ["Feb" + year]: data.FebruaryTotal ? (data.FebruaryTotal) : 0,
                ["Mar" + year]: data.MarchTotal ? (data.MarchTotal) : 0,
                ["Apr" + year]: data.AprilTotal ? (data.AprilTotal) : 0,
                ["May" + year]: data.MayTotal ? (data.MayTotal) : 0,
                ["Jun" + year]: data.JuneTotal ? (data.JuneTotal) : 0,
                ["Jul" + year]: data.JulyTotal ? (data.JulyTotal) : 0,
                ["Aug" + year]: data.AugustTotal ? (data.AugustTotal) : 0,
                ["Sep" + year]: data.SeptemberTotal ? (data.SeptemberTotal) : 0,
                ["Oct" + year]: data.OctoberTotal ? (data.OctoberTotal) : 0,
                ["Nov" + year]: data.NovemberTotal ? (data.NovemberTotal) : 0,
                ["Dec" + year]: data.DecemberTotal ? (data.DecemberTotal) : 0,
                "Net Income": data.Total ? (data.Total) : 0
            }
            xlsRows.push(xlRow);
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToCurrency(ws, 'J');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToCurrency(ws, 'L');
    ws = formatColumnToCurrency(ws, 'M');
    ws = formatColumnToCurrency(ws, 'N');
    ws = formatColumnToCurrency(ws, 'O');
    ws = formatColumnToCurrency(ws, 'P');

    return ws;
}

function getEstimatedIncomeSummaryAccountWS(data, props) {
    let year = props.topten.estimatedIncomeYear ? (' ' + props.topten.estimatedIncomeYear.toString().substr(-2)) : '';
    var xlsHeader = ["Account", "Symbol", "Description", "Jan" + year, "Feb" + year, "Mar" + year, "Apr" + year, "May" + year, "Jun" + year, "Jul" + year, "Aug" + year, "Sep" + year, "Oct" + year, "Nov" + year, "Dec" + year, "Net Income"];
    var xlsRows = [];

    if (data && data.IncomeSubTotal && Array.isArray(data.IncomeSubTotal) && data.IncomeSubTotal.length > 0) {
        data.IncomeSubTotal.forEach((row) => {
            if (row) {
                //Add Sub rows data
                if (row.IncomeDetail && Array.isArray(row.IncomeDetail) && row.IncomeDetail.length > 0) {
                    row.IncomeDetail.forEach((subrow) => {
                        if (subrow) {
                            var xlRow = {
                                "Account": (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)),
                                "Symbol": subrow.SECNAME ? subrow.SECNAME : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-"),
                                "Description": subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-",
                                ["Jan" + year]: (subrow.JanuaryTotal ? (subrow.JanuaryTotal) : 0),
                                ["Feb" + year]: subrow.FebruaryTotal ? (subrow.FebruaryTotal) : 0,
                                ["Mar" + year]: subrow.MarchTotal ? (subrow.MarchTotal) : 0,
                                ["Apr" + year]: subrow.AprilTotal ? (subrow.AprilTotal) : 0,
                                ["May" + year]: subrow.MayTotal ? (subrow.MayTotal) : 0,
                                ["Jun" + year]: subrow.JuneTotal ? (subrow.JuneTotal) : 0,
                                ["Jul" + year]: subrow.JulyTotal ? (subrow.JulyTotal) : 0,
                                ["Aug" + year]: subrow.AugustTotal ? (subrow.AugustTotal) : 0,
                                ["Sep" + year]: subrow.SeptemberTotal ? (subrow.SeptemberTotal) : 0,
                                ["Oct" + year]: subrow.OctoberTotal ? (subrow.OctoberTotal) : 0,
                                ["Nov" + year]: subrow.NovemberTotal ? (subrow.NovemberTotal) : 0,
                                ["Dec" + year]: subrow.DecemberTotal ? (subrow.DecemberTotal) : 0,
                                "Net Income": subrow.Total ? (subrow.Total) : 0
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
                else {
                    var xlRow = {
                        "Account": row.ACCT ? row.ACCT : '',
                        "Symbol": '',
                        "Description": '',
                        ["Jan" + year]: row.JanuaryTotal ? (row.JanuaryTotal) : 0,
                        ["Feb" + year]: row.FebruaryTotal ? (row.FebruaryTotal) : 0,
                        ["Mar" + year]: row.MarchTotal ? (row.MarchTotal) : 0,
                        ["Apr" + year]: row.AprilTotal ? (row.AprilTotal) : 0,
                        ["May" + year]: row.MayTotal ? (row.MayTotal) : 0,
                        ["Jun" + year]: row.JuneTotal ? (row.JuneTotal) : 0,
                        ["Jul" + year]: row.JulyTotal ? (row.JulyTotal) : 0,
                        ["Aug" + year]: row.AugustTotal ? (row.AugustTotal) : 0,
                        ["Sep" + year]: row.SeptemberTotal ? (row.SeptemberTotal) : 0,
                        ["Oct" + year]: row.OctoberTotal ? (row.OctoberTotal) : 0,
                        ["Nov" + year]: row.NovemberTotal ? (row.NovemberTotal) : 0,
                        ["Dec" + year]: row.DecemberTotal ? (row.DecemberTotal) : 0,
                        "Net Income": row.Total ? (row.Total) : 0
                    }
                    xlsRows.push(xlRow);
                }
            }

        });

        //footer value
        if (data) {
            var xlRow = {
                "Account": "Total",
                "Symbol": '',
                "Description": '',
                ["Jan" + year]: data.JanuaryTotal ? (data.JanuaryTotal) : 0,
                ["Feb" + year]: data.FebruaryTotal ? (data.FebruaryTotal) : 0,
                ["Mar" + year]: data.MarchTotal ? (data.MarchTotal) : 0,
                ["Apr" + year]: data.AprilTotal ? (data.AprilTotal) : 0,
                ["May" + year]: data.MayTotal ? (data.MayTotal) : 0,
                ["Jun" + year]: data.JuneTotal ? (data.JuneTotal) : 0,
                ["Jul" + year]: data.JulyTotal ? (data.JulyTotal) : 0,
                ["Aug" + year]: data.AugustTotal ? (data.AugustTotal) : 0,
                ["Sep" + year]: data.SeptemberTotal ? (data.SeptemberTotal) : 0,
                ["Oct" + year]: data.OctoberTotal ? (data.OctoberTotal) : 0,
                ["Nov" + year]: data.NovemberTotal ? (data.NovemberTotal) : 0,
                ["Dec" + year]: data.DecemberTotal ? (data.DecemberTotal) : 0,
                "Net Income": data.Total ? (data.Total) : 0
            }
            xlsRows.push(xlRow);
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToCurrency(ws, 'J');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToCurrency(ws, 'L');
    ws = formatColumnToCurrency(ws, 'M');
    ws = formatColumnToCurrency(ws, 'N');
    ws = formatColumnToCurrency(ws, 'O');
    ws = formatColumnToCurrency(ws, 'P');
    return ws;
}

export function getProjectedIncomeAccountExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Projected Income Account Report",
        Subject: "Projected Income Account Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("ProjectedIncomeAccount");
    var xlsHeader = ["Account", "Symbol", "Description", "Dividend", "Interest", "Net Income"];
    var xlsRows = []
    if (props && props.topten && props.topten.projectedincomebyaccount) {
        var projectedIncomeByAcct = props.topten.projectedincomebyaccount;
        if (projectedIncomeByAcct && projectedIncomeByAcct[0] && projectedIncomeByAcct[0].itemSummary && Array.isArray(projectedIncomeByAcct[0].itemSummary)) {
            projectedIncomeByAcct[0].itemSummary.forEach((row) => {

                //sub rows
                if (row && row.ItemDetail && Array.isArray(row.ItemDetail) && row.ItemDetail.length > 0) {
                    row.ItemDetail.forEach(subrow => {
                        if (subrow) {
                            var xlRow = {
                                "Account": (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)),
                                "Symbol": subrow.SECNAME ? subrow.SECNAME : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-"),
                                "Description": subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-",
                                "Dividend": subrow.ORDDIV ? (subrow.ORDDIV) : 0,
                                "Interest": subrow.INTINCOME ? (subrow.INTINCOME) : 0,
                                "Net Income": subrow.TOTAL ? (subrow.TOTAL) : 0,
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
            })
        }

        // This is for footer 

        if (props && props.topten && props.topten.projectedincomebyaccount && props.topten.projectedincomebyaccount[0]) {
            var xlRow = {
                "Account": "Total",
                "Symbol": '',
                "Description": '',
                "Dividend": (props.topten.projectedincomebyaccount[0].ODNDividend ? (props.topten.projectedincomebyaccount[0].ODNDividend) : 0),
                "Interest": (props.topten.projectedincomebyaccount[0].TotalInt ? (props.topten.projectedincomebyaccount[0].TotalInt) : 0),
                "Net Income": (props.topten.projectedincomebyaccount[0].NetIncome ? (props.topten.projectedincomebyaccount[0].NetIncome) : 0)
            }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    wb.Sheets["ProjectedIncomeAccount"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "ProjectedIncomeAccount-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function getProjectedIncomeSecurityExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Projected Income Security Report",
        Subject: "Projected Income Security Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("ProjectedIncomeSecurity");
    var xlsHeader = ["Symbol", "Description", "Account", "Dividend", "Interest", "Net Income"];
    var xlsRows = []
    if (props && props.topten && props.topten.projectedincomebysecurity) {
        var projectedincomebysecurity = props.topten.projectedincomebysecurity;
        if (projectedincomebysecurity && projectedincomebysecurity[0] && projectedincomebysecurity[0].itemSummary && Array.isArray(projectedincomebysecurity[0].itemSummary)) {
            projectedincomebysecurity[0].itemSummary.forEach((row) => {


                //sub rows
                if (row.ItemDetail && Array.isArray(row.ItemDetail) && row.ItemDetail.length > 0) {
                    row.ItemDetail.forEach(subrow => {
                        if (subrow) {
                            var xlRow = {
                                "Symbol": row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-"),
                                "Description": row.SECDESCRIPTION ? row.SECDESCRIPTION : "-",
                                "Account": (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)),
                                "Dividend": subrow.ORDDIV ? (subrow.ORDDIV) : 0,
                                "Interest": subrow.INTINCOME ? (subrow.INTINCOME) : 0,
                                "Net Income": subrow.TOTAL ? (subrow.TOTAL) : 0,
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
                else {
                    var xlRow = {
                        "Symbol": row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-"),
                        "Description": row.SECDESCRIPTION ? row.SECDESCRIPTION : "-",
                        "Account": "",
                        "Dividend": row.ORDDIV ? (row.ORDDIV) : 0,
                        "Interest": row.INTINCOME ? (row.INTINCOME) : 0,
                        "Net Income": row.TOTAL ? (row.TOTAL) : 0,
                    }
                    xlsRows.push(xlRow);
                }
            })
        }

        // This is for footer 

        if (props && props.topten && props.topten.projectedincomebysecurity && props.topten.projectedincomebysecurity[0]) {
            var xlRow = {
                "Symbol": "Total",
                "Description": '',
                "Account": '',
                "Dividend": (props.topten.projectedincomebysecurity[0].ODNDividend ? (props.topten.projectedincomebysecurity[0].ODNDividend) : 0),
                "Interest": (props.topten.projectedincomebysecurity[0].TotalInt ? (props.topten.projectedincomebysecurity[0].TotalInt) : 0),
                "Net Income": (props.topten.projectedincomebysecurity[0].NetIncome ? (props.topten.projectedincomebysecurity[0].NetIncome) : 0)
            }
            xlsRows.push(xlRow);
        }

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');

    wb.Sheets["ProjectedIncomeSecurity"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "ProjectedIncomeSecurity-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function generateProjectedIncomeSummaryExcelReport(props) {
    //Step1 : Create a workbook
    var wb = XLSX.utils.book_new();
    //Step2 : add properties in your excel file its optional
    wb.Props = {
        Title: "Proj. Income Summary Report",
        Subject: "Proj. Income Summary Report",
        Author: "Copyright@Rockefeller"
    };

    //step 3: create Balance work sheet

    //check if store data is present before adding the worksheet

    if (props && props.topten && props.topten.projectedIncomeSummaryChartData) {
        wb.SheetNames.push("Projected Income Summary");
        var projectedSummaryChart = getProjectedIncomeSummaryChartWS(props.topten.projectedIncomeSummaryChartData);
        wb.Sheets["Projected Income Summary"] = projectedSummaryChart;
    } else {
        wb.SheetNames.push("Projected Income Summary");
        wb.Sheets["Projected Income Summary"] = null;
    }


    if (props && props.topten && props.topten.projectedIncomeSummarySecurityData
        && props.topten.projectedIncomeSummarySecurityData.value
        && Array.isArray(props.topten.projectedIncomeSummarySecurityData.value)
        && props.topten.projectedIncomeSummarySecurityData.value.length > 0) {
        wb.SheetNames.push("Income by Security");
        var projectedSummarySecurity = getProjectedIncomeSummarySecurityWS(props.topten.projectedIncomeSummarySecurityData.value[0], props);
        wb.Sheets["Income by Security"] = projectedSummarySecurity;
    } else {
        wb.SheetNames.push("Income by Security");
        wb.Sheets["Income by Security"] = null;
    }


    if (props && props.topten && props.topten.projectedIncomeSummaryAccountData
        && props.topten.projectedIncomeSummaryAccountData.value
        && Array.isArray(props.topten.projectedIncomeSummaryAccountData.value)
        && props.topten.projectedIncomeSummaryAccountData.value.length > 0) {
        wb.SheetNames.push("Income by Account");
        var projectedSummaryAccount = getProjectedIncomeSummaryAccountWS(props.topten.projectedIncomeSummaryAccountData.value[0], props);
        wb.Sheets["Income by Account"] = projectedSummaryAccount;
    } else {
        wb.SheetNames.push("Income by Account");
        wb.Sheets["Income by Account"] = null;
    }

    //step 8: Write wotksheet into binary
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //step 9: user file saver to save the file
    var filename = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "ProjectedIncomeSummary-" + filename + ".xlsx", "application/octet-stream");
    return;

}

function getProjectedIncomeSummaryChartWS(data) {
    var xlsHeader = ["Month", "Interest", "Dividend", "Capital Gain", "Total"];
    let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var xlsRows = [];
    if (data && data[0] && data[0].incomeSubTotal && Array.isArray(data[0].incomeSubTotal) && data[0].incomeSubTotal.length > 0) {
        let currentmonth = new Date().getMonth() + 1;
        let futuremonths = data[0].incomeSubTotal.filter(c => c.monseq < currentmonth);
        futuremonths = orderBy(futuremonths, ['monseq'], ['asc'])
        for (var i = 0; i < futuremonths.length; i++) {
            futuremonths[i].monseq = 13 + i;
        }
        let chartrows = [];
        chartrows = cloneDeep(data[0].incomeSubTotal);
        chartrows = orderBy(chartrows, ['monseq'], ['asc'])
        chartrows.forEach((row) => {
            if (row) {
                var xlRow = {
                    "Month": (textfieldarr[(row.monseq > 12 ? (row.monseq - 13) : (row.monseq - 1))] + ' ' + (row.monseq > 12 ? (new Date().getFullYear() + 1).toString().substr(-2) : (new Date().getFullYear()).toString().substr(-2))),
                    "Interest": row.interest ? row.interest : 0,
                    "Dividend": row.dividend ? row.dividend : 0,
                    "Capital Gain": row.capGain ? row.capGain : 0,
                    "Total": row.totalIncome ? row.totalIncome : 0
                }
                xlsRows.push(xlRow);
            }
        });

        // //footer value
        if (data && data[0]) {
            var xlRow = {
                "Month": "Total",
                "Interest": data[0].interest ? data[0].interest : 0,
                "Dividend": data[0].dividend ? data[0].dividend : 0,
                "Capital Gain": data[0].capGain ? data[0].capGain : 0,
                "Total": data[0].total ? data[0].total : 0,
            }
            xlsRows.push(xlRow);
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToNumber(ws, 'B');
    ws = formatColumnToNumber(ws, 'C');
    ws = formatColumnToNumber(ws, 'D');
    ws = formatColumnToNumber(ws, 'E');
    return ws;
}
function GetColumnsForInputMonth(start, end, xlsHeader, iscurrentyear, isSecurity = false, isEstimatedPayDate = false) {
   
    
    let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    for (var i = start; i < end; i++) {
        xlsHeader.push(
            (textfieldarr[i]) + ' ' + (iscurrentyear ? new Date().getFullYear().toString().substr(-2) : (new Date().getFullYear() + 1).toString().substr(-2))
        );
        if (isEstimatedPayDate && isSecurity) {
            xlsHeader.push("EstPayDate " + (textfieldarr[i]) + ' ' + (iscurrentyear ? new Date().getFullYear().toString().substr(-2) : (new Date().getFullYear() + 1).toString().substr(-2)));
        }
    }
    return xlsHeader;
}
function GetColumnsDataFieldsForInputMonth(start, end, xlsDataFieldHeader, isSecurity = false, isEstimatedPayDate = false) {
    let datafieldarr = ["JanuaryTotal", "FebruaryTotal", "MarchTotal", "AprilTotal", "MayTotal", "JuneTotal", "JulyTotal", "AugustTotal", "SeptemberTotal", "OctoberTotal", "NovemberTotal", "DecemberTotal"]
    let datefieldarr = ["JanuaryPayDate", "FebruaryPayDate", "MarchPayDate", "AprilPayDate", "MayPayDate", "JunePayDate", "JulyPayDate", "AugustPayDate", "SeptemberPayDate", "OctoberPayDate", "NovemberPayDate", "DecemberPayDate"]
    for (var i = start; i < end; i++) {
        xlsDataFieldHeader.push(
            datafieldarr[i]
        );
        if (isEstimatedPayDate && isSecurity) {
            xlsDataFieldHeader.push(
                datefieldarr[i]
              
            );
        }
    }
    return xlsDataFieldHeader;
}
function getProjectedIncomeSummarySecurityWS(data, props) {
    var xlsHeader = ["Symbol", "Description", "Account"];
    let obj = {
        preferences: props.preferences
    }
    const isEstimatedPayDate =GetPreferencePilotValue(obj, 'EstimatedPayDate', 'false') === 'true' ? true : false;
    let currentmonth = new Date().getMonth();
    xlsHeader = GetColumnsForInputMonth(currentmonth, 12, xlsHeader, true, true, isEstimatedPayDate);
    if (props && props.topten && props.topten.projectedIncomeYear === '2')
        xlsHeader = GetColumnsForInputMonth(0, currentmonth, xlsHeader, false, true, isEstimatedPayDate);
    xlsHeader.push("Net Income");

    var xlsDataFieldHeader = ["SECNAME", "SECDESCRIPTION", "ACCT"];

    xlsDataFieldHeader = GetColumnsDataFieldsForInputMonth(currentmonth, 12, xlsDataFieldHeader, true, isEstimatedPayDate);
    if (props && props.topten && props.topten.projectedIncomeYear === '2')
        xlsDataFieldHeader = GetColumnsDataFieldsForInputMonth(0, currentmonth, xlsDataFieldHeader, true, isEstimatedPayDate);
    xlsDataFieldHeader.push("Total");
    var xlsRows = [];

    if (data && data.IncomeSubTotal && Array.isArray(data.IncomeSubTotal) && data.IncomeSubTotal.length > 0) {
        data.IncomeSubTotal.forEach((row) => {
            if (row) {
                //Add Sub rows data
                if (row.IncomeDetail && Array.isArray(row.IncomeDetail) && row.IncomeDetail.length > 0) {
                    row.IncomeDetail.forEach((subrow) => {
                        if (subrow) {
                            var xlRow = {};
                            for (var i = 0; i < xlsHeader.length; i++) {
                                if (xlsHeader[i] === "Symbol")
                                    xlRow[xlsHeader[i]] = subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-")
                                else if (xlsHeader[i] === "Account")
                                    xlRow[xlsHeader[i]] = (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : '-'), IndLongShort.SINGLEVIEW))
                                else if (xlsHeader[i] === "Description")
                                    xlRow[xlsHeader[i]] = subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : '-'
                                else if (xlsHeader[i].startsWith("EstPayDate")) {
                                    const paydate = row[xlsDataFieldHeader[i]];
                                    xlRow[xlsHeader[i]] = paydate ? paydate.split('T')[0] : '-'
                                }
                                else
                                    xlRow[xlsHeader[i]] = subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : 0
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
                else {
                    var xlRow = {}
                    for (var i = 0; i < xlsHeader.length; i++) {
                        if (xlsHeader[i] === "Symbol")
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : (row.CUSIPNUM ? row.CUSIPNUM : "-")
                        else if (xlsHeader[i] === "Account")
                            xlRow[xlsHeader[i]] = (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : '-'), IndLongShort.SINGLEVIEW))
                        else if (xlsHeader[i] === "Description")
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : '-'
                        else if (xlsHeader[i].startsWith("EstPayDate"))
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]].split('T')[0] : '-'
                        else
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : 0
                    }
                    xlsRows.push(xlRow);
                }
            }
        });

        //footer value
        if (data) {
            var xlRow = {};
            for (var i = 0; i < xlsHeader.length; i++) {
                if (xlsHeader[i] === "Symbol")
                    xlRow[xlsHeader[i]] = "Total";
                else if (xlsHeader[i] === "Account" || xlsHeader[i] === "Description" || xlsHeader[i].startsWith("EstPayDate"))
                    xlRow[xlsHeader[i]] = '';
                else
                    xlRow[xlsHeader[i]] = data[xlsDataFieldHeader[i]] ? data[xlsDataFieldHeader[i]] : 0
            }
            xlsRows.push(xlRow);
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });
    if (isEstimatedPayDate) {
        ws = formatColumnToCurrency(ws, 'D');
        ws = formatColumnToCurrency(ws, 'F');
        ws = formatColumnToCurrency(ws, 'H');
        ws = formatColumnToCurrency(ws, 'J');
        ws = formatColumnToCurrency(ws, 'L');
        ws = formatColumnToCurrency(ws, 'N');
        ws = formatColumnToCurrency(ws, 'P');
        ws = formatColumnToCurrency(ws, 'R');
        ws = formatColumnToCurrency(ws, 'T');
        ws = formatColumnToCurrency(ws, 'V');
        ws = formatColumnToCurrency(ws, 'X');
        ws = formatColumnToCurrency(ws, 'Z');
        ws = formatColumnToCurrency(ws, 'AB');
    }
    else {
        ws = formatColumnToCurrency(ws, 'D');
        ws = formatColumnToCurrency(ws, 'E');
        ws = formatColumnToCurrency(ws, 'F');
        ws = formatColumnToCurrency(ws, 'G');
        ws = formatColumnToCurrency(ws, 'H');
        ws = formatColumnToCurrency(ws, 'I');
        ws = formatColumnToCurrency(ws, 'J');
        ws = formatColumnToCurrency(ws, 'K');
        ws = formatColumnToCurrency(ws, 'L');
        ws = formatColumnToCurrency(ws, 'M');
        ws = formatColumnToCurrency(ws, 'N');
        ws = formatColumnToCurrency(ws, 'O');
        ws = formatColumnToCurrency(ws, 'P');
    }
 
    return ws;
}

function getProjectedIncomeSummaryAccountWS(data, props) {
    var xlsHeader = ["Account", "Symbol", "Description"];
    //, "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Net Income"];
    let currentmonth = new Date().getMonth();
    xlsHeader = GetColumnsForInputMonth(currentmonth, 12, xlsHeader, true);
    if (props && props.topten && props.topten.projectedIncomeYear === '2')
        xlsHeader = GetColumnsForInputMonth(0, currentmonth, xlsHeader, false);
    xlsHeader.push("Net Income");
    var xlsDataFieldHeader = ["ACCT", "SECNAME", "SECDESCRIPTION"];
    xlsDataFieldHeader = GetColumnsDataFieldsForInputMonth(currentmonth, 12, xlsDataFieldHeader);
    if (props && props.topten && props.topten.projectedIncomeYear === '2')
        xlsDataFieldHeader = GetColumnsDataFieldsForInputMonth(0, currentmonth, xlsDataFieldHeader);

    xlsDataFieldHeader.push("Total");
    var xlsRows = [];

    if (data && data.IncomeSubTotal && Array.isArray(data.IncomeSubTotal) && data.IncomeSubTotal.length > 0) {
        data.IncomeSubTotal.forEach((row) => {
            if (row) {
                //Add Sub rows data
                if (row.IncomeDetail && Array.isArray(row.IncomeDetail) && row.IncomeDetail.length > 0) {
                    row.IncomeDetail.forEach((subrow) => {
                        if (subrow) {
                            var xlRow = {};
                            for (var i = 0; i < xlsHeader.length; i++) {
                                if (xlsHeader[i] === "Symbol")
                                    xlRow[xlsHeader[i]] = subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-")
                                else if (xlsHeader[i] === "Account")
                                    xlRow[xlsHeader[i]] = (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : '-'), IndLongShort.SINGLEVIEW))
                                else if (xlsHeader[i] === "SECDESCRIPTION")
                                    xlRow[xlsHeader[i]] = subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : '-'
                                else
                                    xlRow[xlsHeader[i]] = subrow[xlsDataFieldHeader[i]] ? subrow[xlsDataFieldHeader[i]] : 0
                            }
                            xlsRows.push(xlRow);
                        }
                    })
                }
                else {
                    var xlRow = {};

                    for (var i = 0; i < xlsHeader.length; i++) {
                        if (xlsHeader[i] === "Symbol")
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : (row.CUSIPNUM ? row.CUSIPNUM : "-")
                        else if (xlsHeader[i] === "Account")
                            xlRow[xlsHeader[i]] = (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : '-'), IndLongShort.SINGLEVIEW))
                        else if (xlsHeader[i] === "SECDESCRIPTION")
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : '-'
                        else
                            xlRow[xlsHeader[i]] = row[xlsDataFieldHeader[i]] ? row[xlsDataFieldHeader[i]] : 0
                    }
                    xlsRows.push(xlRow);
                }
            }

        });

        //footer value
        if (data) {
            var xlRow = {};
            for (var i = 0; i < xlsHeader.length; i++) {
                if (xlsHeader[i] === "Account")
                    xlRow[xlsHeader[i]] = "Total";
                else if (xlsHeader[i] === "Symbol" || xlsHeader[i] === "Description")
                    xlRow[xlsHeader[i]] = '';
                else
                    xlRow[xlsHeader[i]] = data[xlsDataFieldHeader[i]] ? data[xlsDataFieldHeader[i]] : 0
            }
            xlsRows.push(xlRow);
        }
    }

    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'D');
    ws = formatColumnToCurrency(ws, 'E');
    ws = formatColumnToCurrency(ws, 'F');
    ws = formatColumnToCurrency(ws, 'G');
    ws = formatColumnToCurrency(ws, 'H');
    ws = formatColumnToCurrency(ws, 'I');
    ws = formatColumnToCurrency(ws, 'J');
    ws = formatColumnToCurrency(ws, 'K');
    ws = formatColumnToCurrency(ws, 'L');
    ws = formatColumnToCurrency(ws, 'M');
    ws = formatColumnToCurrency(ws, 'N');
    ws = formatColumnToCurrency(ws, 'O');
    ws = formatColumnToCurrency(ws, 'P');
    return ws;
}

export function generateLiabilitiesExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Liabilities Report",
        Subject: "Liabilities Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Liabilities");
    var xlsHeader = ["As Of Date", "Customer Name", "Account", "Loan Purpose", "Commitment", "Outstanding", "Available Credit", "Gross Collateral Value",
        "Interest Rate",
        "Rate Index", "Spread", "Billed Amount", "Type"];
    var xlsRows = [];
    if (props && props.acct && props.acct.liabilities && props.acct.liabilities.length > 0) {
        props.acct.liabilities.forEach(row => {
            if (row) {
                var xlRow = {
                    "As Of Date": (row.asofdate ? new Date(toDate(row.asofdate)) : GetTextValue(row.asofdate)),
                    "Customer Name": (row.customername ? row.customername : '-'),
                    "Account": (getAccountDisplayName(props.selectedaccount, (row.accountnumber ? row.accountnumber : '-'), IndLongShort.SINGLEVIEW)),
                    "Loan Purpose": (row.purposeFlag ? row.purposeFlag : '-'),
                    "Commitment": (row.commitment ? row.commitment : '-'),
                    "Outstanding": (row.outstandingbalance ? row.outstandingbalance : 0),
                    "Available Credit": (row.availablecredit ? row.availablecredit : 0),
                    "Gross Collateral Value": (row.grosscollval ? row.grosscollval : 0),
                    "Interest Rate": (row.interestrate ? (row.interestrate / 100) : 0),
                    "Rate Index": row.rateindex ? row.rateindex : '-',
                    "Spread": (row.spread ? (row.spread / 100) : 0),
                    "Billed Amount": (row.billedamount ? row.billedamount : 0),
                    "Type": row.type ? row.type : '-',

                }
                xlsRows.push(xlRow);
            }
        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false,
        dateNF: 'yyyy-mm-dd'
    });
    ws = formatColumnToCurrency(ws, 'F')
    ws = formatColumnToCurrency(ws, 'G')
    ws = formatColumnToCurrency(ws, 'H')
    ws = formatColumnToPercentage(ws, 'I')
    ws = formatColumnToCurrency(ws, 'J')
    ws = formatColumnToPercentage(ws, 'K')
    ws = formatColumnToCurrency(ws, 'L')
    wb.Sheets["Liabilities"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Liabilities-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}
export function generateEDeliveryExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "E-Delivery Report",
        Subject: "E-Delivery Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("E-Delivery");
    var xlsHeader = ["Account", "Enrolled", "Confirms/Confirming Prospectuses", "Statements and Regulatory Inserts", "Eligible Customer Correspondence", "Tax Forms and Related Documents", "Shareholder Reports & Other Documents"];
    var xlsRows = []
    if (props && props.personalistaionData && props.personalistaionData.personalisationData && props.personalistaionData.personalisationData.accounts
        && props.personalistaionData.personalisationData.accounts.length > 0) {
        props.personalistaionData.personalisationData.accounts.forEach(row => {
            if (row) {
                var xlRow = {
                    "Account": (row.number),
                    "Enrolled": row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].edeliverystatus : "",
                    "Confirms/Confirming Prospectuses": row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].cfrmsSuprnCd : "",
                    "Statements and Regulatory Inserts": row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].stmtsSuprnCd : "",
                    "Eligible Customer Correspondence": row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].custCorrSuprnCd : "",
                    "Tax Forms and Related Documents": row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].taxFormSuprnCd : "",
                    "Shareholder Reports & Other Documents": row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].rptSuprnCd : ""
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    wb.Sheets["E-Delivery"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "E-Delivery-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function generateHistoricalAccountDetailsExcelReport(props) {

    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Historical Account Value Report",
        Subject: "Historical Account Value Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Historical Account Value");
    if (props && props.acct && props.acct.historicalBalanceDetails) {
        var ws = GenerateHistoricalAssetallocationsheet(props.acct.historicalBalanceDetails, props.selectedaccount);
        wb.Sheets["Historical Account Value"] = ws;
    }
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Historical Account Value-" + timestamp + ".xlsx", "application/octet-stream");
    return;

}

function GenerateHistoricalAssetallocationsheet(props, accountlist) {
    var xlsHeader = [];
    var xlsRows = []
    if (props && props.data
        && props.data.length > 0) {


        const mappedData = props.data.map(x => ({
            accountNumber: x.accountNumber,
            marketValue: parseFloat(x.marketValue) || 0,
            assetType: x.assetType || "UNCATEGORIZED",
            time: new Date(x.calendarYear, x.month - 1).getTime(),
            monthYear: new Date(x.calendarYear, x.month - 1),
            asOfDate: new Date(x.asOfDate),
        }));

        const allTimes = uniq(mappedData.map(x => x.time).filter(x => x)).sort(
            (a, b) => {
                return a - b;
            }
        );
        //for (var i = 0; i < allTimes.length - 1; i++) {
        //    let monthcol = allTimes.toLocaleString("default", {
        //        month: "short",
        //        year: "2-digit"
        //    });
        //    //let monthcol = months[i].columnName;
        //   // xlsHeader.push(monthcol);
        //}
        const groupedByAccount = groupBy(mappedData, item => item.accountNumber);
        const rows = [].concat(
            ...Object.keys(groupedByAccount)
                .map(accountNumber => {
                    const accountItems = groupedByAccount[accountNumber];
                    const groupedByAssetType = groupBy(
                        accountItems,
                        accountItem => accountItem.assetType
                    );
                    return Object.keys(groupedByAssetType).map(assetType => {
                        const assetItems = groupedByAssetType[assetType];
                        return {
                            //  key: `${accountNumber}${assetType}`,
                            Account: accountNumber,
                            // assetType: assetItems[0].assetType,
                            ...assetItems.reduce((a, assetItem) => {
                                const marketValue = parseFloat(assetItem.marketValue || 0);
                                const existingValue = a[assetItem.time];
                                // TODO - figure out why there's dupe entries for the same thing
                                if (existingValue) {

                                    a[assetItem.time] = existingValue + marketValue;
                                } else {

                                    a[assetItem.time] = marketValue;
                                }
                                return a;
                            }, {})
                        };
                    });
                })
                .sort((a, b) => (a.assetType > b.assetType ? 0 : 1))
        );

        const accountGroups = groupBy(rows, x => x.Account);
        const accountTotalRows = Object.keys(accountGroups).map(accountNumber => {
            const assetItems = accountGroups[accountNumber];
            return {
                Account: accountNumber,
                ...allTimes.reduce((a, time) => {
                    const value = assetItems
                        .map(x => x[time])
                        .reduce((a, x) => a + (x || 0), 0);

                    a[time] = value;
                    return a;
                }, {})
            };
        });
        var key;
        const dateColumns = allTimes.map(dateItem => {
            return {
                text: new Date(dateItem).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                }),
                value: dateItem
            };
        });

        xlsHeader.push("Account");
        for (var i = 0; i < dateColumns.length; i++) {
            xlsHeader.push(dateColumns[i].text);
        }
        accountTotalRows.forEach(row => {
            var xlRow = {};
            if (row) {
                for (key in row) {

                    if (row.hasOwnProperty(key)) {
                        if (key === "Account") {
                            xlRow[key] = getAccountDisplayName(accountlist, row[key], IndLongShort.SINGLEVIEW);
                        }
                        else {
                            xlRow[GetDateText(dateColumns, key)] = row[key]
                        }

                    }
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrencyForHistoricalView(ws);
    return ws;
}
export function generateAssetallocationHistoryExcelReport(props) {

    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Historical Asset Allocation Report",
        Subject: "Historical Asset Allocation Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Historical Asset Allocation");
    if (props && props.acct && props.acct.historicalAssetAllocationDetails) {
        var ws = GenerateAssetallocationHistorySheet(props.acct.historicalAssetAllocationDetails, props.selectedaccount, props.acct.assetschemacolorsconfig, props.acct.assetSchema);
        wb.Sheets["Historical Asset Allocation"] = ws;
    }
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Historical Asset Allocation-" + timestamp + ".xlsx", "application/octet-stream");
    return;

}

function GenerateAssetallocationHistorySheet(props, accountlist, assetschemacolorsconfig, assetSchema) {
    var xlsHeader = [];
    var xlsRows = []
    if (props && props.data
        && props.data.length > 0) {

        const mappedData = getMappedData(props.data)
        for (var i = 0; i < mappedData.length; i++) {
            if (mappedData[i].category !== null) {
                let assetObj = getAssetColorConfig(mappedData[i].assetType, mappedData[i].assetcatcd, assetschemacolorsconfig, assetSchema)
                mappedData[i].colorCd = assetObj.colorcd;
                mappedData[i].displaySeq = assetObj.displayseq;
            }

        }
        if (mappedData && mappedData.length > 0) {
            mappedData.sort(function (a, b) { return a.displaySeq - b.displaySeq; });
        }
        const allTimes = GetUniqueTimedata(mappedData);

        var key;
        const dateColumns = allTimes.map(dateItem => {
            return {
                text: new Date(dateItem).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                }),
                value: dateItem
            };
        });

        xlsHeader.push("AssetType");
        xlsHeader.push("Account");
        for (var i = 0; i < dateColumns.length; i++) {
            xlsHeader.push(dateColumns[i].text);
        }
        const assetGroups = getAssetGroups(mappedData, accountlist);
        //for (var i = 0; i < assetGroups.length; i++) {
        Object.keys(assetGroups).forEach(function (key) {
            console.log(assetGroups[key]);
            assetGroups[key].forEach(row => {
                var xlRow = {};
                if (row) {
                    for (key in row) {
                        if (row.hasOwnProperty(key)) {
                            if (key === "key" || key === "accountnickname") continue;
                            if (key === "assetType") {
                                xlRow["AssetType"] = row[key];
                            }
                            else if (key === "accountNumber") {
                                xlRow["Account"] = row["accountnickname"];
                            }
                            else {
                                xlRow[GetDateText(dateColumns, key)] = (row[key] ? row[key] : 0);
                            }

                        }
                    }
                    xlsRows.push(xlRow);
                }

            })
            //}
        });

        ////for (var asset in assetGroups) {

        //}

    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrencyForHistoricalView(ws);
    return ws;
}
function GetDateText(dateColumns, key) {
    var res = dateColumns.filter(x => x.value.toString() === key.toString());
    if (res && res.length > 0) {
        return res[0].text;
    }
    return '';
}

function formatColumnToCurrencyForHistoricalView(ws) {
    //var colNum = XLSX.utils.decode_col(columnname); //decode_col converts Excel col name to an integer for col #
    // var fmt = '$0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
    var fmt = '"$"#,##0.00';
    /* get worksheet range */
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        for (var j = range.s.c + 1; j <= range.e.c; j++) {//1st column is account so started from 2nd column
            var ref = XLSX.utils.encode_cell({ r: i, c: j });
            /* if the particular row did not contain data for the column, the cell will not be generated */
            if (!ws[ref]) continue;
            /* `.t == "n"` for number cells */
            if (ws[ref].t != 'n') continue;
            /* assign the `.z` number format */
            ws[ref].z = fmt;
        }

    }
    return ws;
}

export function formatColumnToCurrency(ws, columnname, precision = false) {
    var colNum = XLSX.utils.decode_col(columnname); //decode_col converts Excel col name to an integer for col #
    // var fmt = '$0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
    if (precision)
        var fmt = '"$"#,##0.0000';
    else
    var fmt = '"$"#,##0.00';
    /* get worksheet range */
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) continue;
        /* `.t == "n"` for number cells */
        if (ws[ref].t != 'n') continue;
        /* assign the `.z` number format */
        ws[ref].z = fmt;
    }
    return ws;
}

export function formatColumnToNumber(ws, columnname, precision = false) {
    var colNum = XLSX.utils.decode_col(columnname); //decode_col converts Excel col name to an integer for col #
    // var fmt = '$0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
     var fmt = precision ? '#,##0.0000' : '#,##0.00';
    /* get worksheet range */
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) continue;
        /* `.t == "n"` for number cells */
        if (ws[ref].t != 'n') continue;
        /* assign the `.z` number format */
        ws[ref].z = fmt;
    }
    return ws;
}

export function formatColumnToPercentage(ws, columnname) {
    var colNum = XLSX.utils.decode_col(columnname); //decode_col converts Excel col name to an integer for col #
    // var fmt = '$0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
    var fmt = '0.00%';
    /* get worksheet range */
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) continue;
        /* `.t == "n"` for number cells */
        if (ws[ref].t != 'n') continue;
        /* assign the `.z` number format */
        ws[ref].z = fmt;
    }
    return ws;
}

export function generateSubscribeAlertsExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Subscribe Alerts Report",
        Subject: "Subscribe Alerts Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Subscribe Alerts");
    var xlsHeader = ["Account", "Subscribed Alerts"];
    var xlsRows = []
    if (props && props.acct && props.acct.subscribeAlerts && props.acct.subscribeAlerts.length > 0) {
        props.acct.subscribeAlerts.forEach(row => {
            if (row) {
                var xlRow = {
                    "Account": (getDisplayName(row)),
                    "Subscribed Alerts": row.alertList
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    wb.Sheets["Subscribe Alerts"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Subscribe-Alerts-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}
export function generateAlertNotificationDetailsExcelReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Alerts Notifications Report",
        Subject: "Alerts Notifications Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Alert Notifications");
    var xlsHeader = ["Account", "Date", "Type", "Alert", "Alert desc"];
    var xlsRows = []
    if (props && props.acct && props.acct.alertList && props.acct.alertList.length > 0) {
        props.acct.alertList.forEach(row => {
            if (row) {
                var xlRow = {
                    "Account": (getAccountDisplayName(props.selectedaccount, row.accountnumber, IndLongShort.SINGLEVIEW)),
                    "Date": getDateTimeVal(row.alertdate),
                    "Type": (row.alerttype),
                    "Alert": (row.alertname),
                    "Alert desc": (row.isread ? row.alertdesc : ''),
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    wb.Sheets["Alert Notifications"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "AlertNotifications-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}
export function generateManualAssetsReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Manual Asset Report",
        Subject: "Manual Asset Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Manage Assets");
    var xlsHeader = ["Custodian/Name", "Type", "Asset Class", "Account", "Nickname", "Value", "Value as of"];
    var xlsRows = []
    if (props && props.acct && props.acct.manualAssetList && props.acct.manualAssetList.length > 0) {
        props.acct.manualAssetList.forEach(row => {
            if (row) {
                var xlRow = {
                    "Custodian/Name": (row.account.info.custodianname ? row.account.info.custodianname : ''),
                    "Type": (row.account.info.type ? row.account.info.type : ''),
                    "Asset Class": (row.account.info.subassettype ? row.account.info.subassettype : ''),
                    "Account": (row.account.info.accountnumber ? row.account.info.accountnumber : ''),
                    "Nickname": (row.account.info.nickname ? row.account.info.nickname : ''),
                    "Value": (getManualAssetBalance(row)),
                    "Value as of": (row.account.value.valueasof ? getDateString(row.account.value.valueasof) : '')
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'F')
    wb.Sheets["Manage Assets"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Manage Assets-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}
export function generateAccountPerformanceReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Account Performance Report",
        Subject: "Account Performance Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Account Performance");
    var xlsHeader = ["Account", "Last Market Close", "Month To Date (%)", "Quarter To Date (%)", "Year To Date (%)"];
    var xlsRows = []
    if (props && props.acct && props.acct.perfromanceAccounts && props.acct.perfromanceAccounts.length > 0){
        props.acct.perfromanceAccounts.forEach(row => {
            if (row) {
                let displayName = row.key;
                if (props.acct.accounts && props.acct.accounts !== null) {
                    displayName = getAccountDisplayName(props.acct.accounts, row.key, IndLongShort.SINGLEVIEW);
                }
                let value = '';
                let valuePerc = '';
                if (row.lastmarketclose && row.lastmarketclose !== null) {
                    value = row.lastmarketclose.value !== undefined && row.lastmarketclose.value !== null && row.lastmarketclose.value !== 0 ? formatMoney(roundvalue(row.lastmarketclose.value, 2)) : GetNumericValue(row.lastmarketclose.value);
                    valuePerc = row.lastmarketclose.percent !== undefined && row.lastmarketclose.percent !== null && row.lastmarketclose.percent !== 0 ? roundvalue(row.lastmarketclose.percent, 2) + '%' : GetNumericValue(row.lastmarketclose.percent, true);
                } 
                var xlRow = {
                    "Account": displayName,
                    "Last Market Close": value + ' / ' + valuePerc,
                    "Month To Date (%)": (row.monthtodate && row.monthtodate !== null ? (row.monthtodate !== 0 ? roundvalue(row.monthtodate, 2) + '%' : GetNumericValue(row.monthtodate, true)) : ''),
                    "Quarter To Date (%)": (row.quartertodate && row.quartertodate !== null ? (row.quartertodate !== 0 ? roundvalue(row.quartertodate, 2) + '%' : GetNumericValue(row.quartertodate, true)) : ''),
                    "Year To Date (%)": (row.yeartodate && row.yeartodate !== null ? (row.yeartodate !== 0 ? roundvalue(row.yeartodate, 2) + '%' : GetNumericValue(row.yeartodate, true)) : ''),
                }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'F')
    wb.Sheets["Account Performance"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Account Performance-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}
export function generatePerformanceSummaryReport(props) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Performance Summary Report",
        Subject: "Performance Summary Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Performance Summary");
    var xlsHeader = [];
    if (props && props.acct && props.acct.perfromanceSummaryDetails && props.acct.perfromanceSummaryDetails.length > 0) {
        props.acct.perfromanceSummaryDetails.forEach(row => {
            let keyString = new Date(row.month).toLocaleString("default", {
                month: "short",
                year: "2-digit"
            })
            xlsHeader.push(keyString);
        });
    }
    var xlsRows = [];
    var xlRow = {};
    if (props && props.acct && props.acct.perfromanceSummaryDetails && props.acct.perfromanceSummaryDetails.length > 0) {
        props.acct.perfromanceSummaryDetails.forEach(row => {
            let keyString = new Date(row.month).toLocaleString("default", {
                month: "short",
                year: "2-digit"
            });
            let value = row.value !== undefined && row.value !== null && row.value !== 0 ? formatMoney(roundvalue(row.value, 2)) : GetNumericValue(row.value);
            xlRow = { ...xlRow, [keyString]: value };
           

        });
        xlsRows.push(xlRow);
    }
   
    
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'F')
    wb.Sheets["Performance Summary"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Performance Summary-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}

export function GenerateAccountPerformancetWssheet(props) {
    var xlsHeader = [];
    var xlRows = [];
    let showtablechart = GetPreferenceValue(props.acct, "Performance", "chart");
    if (showtablechart === 'table') {
        xlsHeader.push("Account");
        if (props && props.acct && props.acct.perfromanceSummaryTable && props.acct.perfromanceSummaryTable.columns && props.acct.perfromanceSummaryTable.columns.length > 0) {
            props.acct.perfromanceSummaryTable.columns.forEach(column => {
                xlsHeader.push(column);
            });
        }
        if (props && props.acct && props.acct.perfromanceSummaryTable && props.acct.perfromanceSummaryTable.accounts && props.acct.perfromanceSummaryTable.accounts.length > 0) {
            props.acct.perfromanceSummaryTable.accounts.forEach(account => {
                let xlRow = {};
                let accountname = getAccountDisplayName(props.acct.accounts, account.key, IndLongShort.SINGLEVIEW);
                xlRow["Account"] = accountname;

                if (props && props.acct && props.acct.perfromanceSummaryTable && props.acct.perfromanceSummaryTable.columns && props.acct.perfromanceSummaryTable.columns.length > 0) {
                    props.acct.perfromanceSummaryTable.columns.forEach(column => {
                        let value = account[column] !== undefined && account[column] !== null ? (account[column] !== 0 ? formatMoney(roundvalue(account[column], 2)) : GetNumericValue(account[column])) : '-';
                        xlRow[column] = value;
                    });

                }
                xlRows.push(xlRow);
            });

        }
    } else {
        if (props && props.acct && props.acct.perfromanceSummaryChart && props.acct.perfromanceSummaryChart.length > 0) {
            props.acct.perfromanceSummaryChart.forEach(row => {
                let keyString = new Date(row.month).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                })
                xlsHeader.push(keyString);
            });
        }
        let xlRow;
        if (props && props.acct && props.acct.perfromanceSummaryChart && props.acct.perfromanceSummaryChart.length > 0) {
            props.acct.perfromanceSummaryChart.forEach(row => {
                let keyString = new Date(row.month).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                });
                let value = row.value !== undefined && row.value !== null && row.value !== 0 ? formatMoney(roundvalue(row.value, 2)) : GetNumericValue(row.value);

                xlRow = { ...xlRow, [keyString]:value }

            });
        }
        xlRows.push(xlRow);
    }
    
    var ws = XLSX.utils.json_to_sheet(xlRows, {
        header: xlsHeader,
        skipHeader: false
    });
    return ws;
}

export const generateAccountInfoReport = (props) => {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Account Information Report",
        Subject: "Account Information Report",
        Author: "Copyright@Rockefeller"
    };
    wb.SheetNames.push("Account Information");
    var xlsHeader = ["Account #", "ABA Number", "Checking Account Number"];
    var xlsRows = []
    if (props && props.moneymovement && props.moneymovement.accountList && props.moneymovement.accountList.length > 0) {
        props.moneymovement.accountList.forEach(row => {
            if (row) {
                var xlRow = {
                    "Account #": (row.singleViewDisplayName && row.singleViewDisplayName !== null ? row.singleViewDisplayName : ''),
                    "ABA Number": (row.abanumber && row.abanumber !== null ? row.abanumber : ''),
                    "Checking Account Number": (row.maskedCheckingAccount && row.maskedCheckingAccount !== null ? row.maskedCheckingAccount: ''),
                   }
                xlsRows.push(xlRow);
            }

        })
    }
    var ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });
    ws = formatColumnToCurrency(ws, 'F')
    wb.Sheets["Account Information"] = ws;
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    var timestamp = getFormattedTime();
    download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Account Information-" + timestamp + ".xlsx", "application/octet-stream");
    return;
}