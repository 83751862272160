import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Profile from "./profileservice";
import Preference from "../../personalisationandsettings/preference/preference";
import { fetchPersonalisationData } from "../../../actions/personalisationAction";
import ManageGroups from "../../personalisationandsettings/managegroups/managegroups.jsx";
import { tabSelection, tabSelectionOnLoad, GetPreferencePilotValue, GetPreferenceValue } from './../../../common/constants.jsx';
import { withPageView } from '../../../hoc/withPageView.jsx';
import ViewPreference from './../viewpreference/preference.jsx';
import SubscribeAlerts from '../alerts/subscribealerts/subscribealerts.jsx';

export class ProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);

        this.state = {
            tabsLinks: this.getTabsLinks(),
            preferences: this.props.preferences,
            isCustomView: this.getPreferenceValue(),
            firstName: ""
        };
    }
    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }


    getTabsLinks() {

        let defaultView = [
            { tabUrl: '/Profile', active: true, displayName: 'PROFILE', id: 'RCM_Profile' },
            { tabUrl: '/ViewPreferences', active: false, displayName: 'PREFERENCES', id: 'RCM_ViewPreferences' },
            { tabUrl: '/EDelivery', active: false, displayName: 'E-DELIVERY', id: 'RCM_eDelivery' }
            //{ tabUrl: '/AccountsNickname', active: false, displayName: 'NICKNAMES', id: 'RCM_AccountsNickname' }
    ];
        if (this.getPilotFlagValue()) {
            defaultView.push({ tabUrl: '/SubscribeAlerts', active: false, displayName: 'ALERTS', id: 'RCM_Subscribe_Alerts' });
        }
        if(this.props.preferences 
            && this.props.preferences.pilotfeatures 
            && Array.isArray(this.props.preferences.pilotfeatures)
            && this.props.preferences.pilotfeatures.length > 0) {
            //do something if we have preferences
            let pilotfeatures = this.props.preferences.pilotfeatures;
            let isManageGroupRequired = false;
            for (let i = 0; i < pilotfeatures.length; i++) {
                if (pilotfeatures[i] && pilotfeatures[i].componentname
                    && pilotfeatures[i].componentname === 'Manage Groups'
                    && pilotfeatures[i].active
                    && pilotfeatures[i].active === "true") {
                    isManageGroupRequired = true;
                    break;
                }
            }

            if(isManageGroupRequired){
                defaultView.push({ tabUrl: '/ManageGroups', active: false, displayName: 'GROUPS/NICKNAMES', id: 'RCM_ManageGroups' });
            }



        }

        return defaultView;
    }

    handleSelections(param, e) {
        document.dispatchEvent(new CustomEvent('removePreferenceMesage', { bubbles: true }));
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }

    componentDidUpdate() {
        if (this.props.preferences && this.state.preferences) {
            if (this.props.preferences !== this.state.preferences) {
                let iscustomview = this.getPreferenceValue();
                this.setState({ isCustomView: iscustomview, preferences: this.props.preferences });
            }
        }


    }

    componentDidMount() {
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/Profile', '/ProfileComponent');
        this.setState({ tabsLinks: tabsLinks });
    }

    cancellationToken = null;

    getPilotFlagValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isAlertPreferenceRequired = GetPreferencePilotValue(obj, "Alerts", "false") === "true" ? true : false;
        return isAlertPreferenceRequired;
    }
    render() {

        let userName = this.props.userName;
        let token = this.props.token;
        let baseUrl = this.props.baseUrl;


        const profileMenu = (
            <div>
                <div className={this.state.isCustomView ? "RCM_Tab_container" : "RCM_NavTab_container"}>
                    <ul className="nav nav-tabs">
                        {this.state.tabsLinks && this.state.tabsLinks.map((tabLink, index) => {
                            return (
                                <li className="nav-item active" key={index}>
                                    <NavLink exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                        onClick={this.handleSelections.bind(this, tabLink)} id={tabLink.id}>{tabLink.displayName}</NavLink>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div>
                    {
                        <Switch>
                            <Route key='ProfileComp' exact path="/ProfileComponent" render={() => <Redirect to="/Profile" />} />
                            <Route key='Profile' exact path='/Profile' component={() => (<Profile userName={userName}
                                token={token}
                                baseUrl={baseUrl} />)}
                            />
                            <Route key='Preferences' exact path='/EDelivery' component={() => (<Preference token={token}
                                baseUrl={baseUrl} />)} />

                            {/*<Route key='Account' exact path='/AccountsNickname' component={() => (<Account token={token}
                                baseUrl={baseUrl} />)} />*/}

                            <Route key='viewPreferences' exact path='/ViewPreferences' component={() => (<ViewPreference token={token}
                                baseUrl={baseUrl} />)} />

                            <Route key='ManageGroups' exact path='/ManageGroups' component={() => (<ManageGroups token={token}
                                baseUrl={baseUrl} />)} />
                            <Route key='SubscribeAlerts' exact path='/SubscribeAlerts' component={() => (<SubscribeAlerts token={token}
                                baseUrl={baseUrl} />)} />
                            </Switch>
                    }
                </div>
            </div>
        );

        return (
            <div className="RCM_sub_container">
                <BrowserRouter>{profileMenu}</BrowserRouter>
            </div>
        );

    }

}
const mapStateToProps = (store) => {
    return {
        token: store.acct.token,
        userName: store.acct.userName,
        baseUrl: store.acct.baseurl,
        preferences: store.acct.preferences
    };
}

const mapDispatchToProps = {
    fetchPersonalisationData
}


const enhancement = compose(
    withPageView({ pageName: 'Profile' })
)

export default enhancement(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileComponent)));
