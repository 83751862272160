import { Get, Post } from "../../common/servicecalls";
import { ProfileURL, PreferencesUrl, TemplateUrl, ContextURL, defaultPreference, ProfileTncUrl, 
    FirstTimeUserDetailsUrl, VerifyTimeUserDetailsUrl, 
    UpdateFirstTimeLoginDetailsUrl, GetInternationalCallingCodesUrl, UpdateMfaSkipCounterUrl, CheckRedirectVersionUrl } from "../../common/constants";
import axios from "axios";
import { _logException } from "../../common/_logging";
import { uniqBy } from "lodash";



//this service fetches the data from profile api and send the success and failure 
//promise object to caller. 

export var userProfileCancellationToken = null;
export var getUserPreferncesCancellationToken = null;
export var getUserTemplateCancellationToken = null;
export var setContextCancellationToken = null;
export var getEDeliveryTnCCancellationToken = null;
export var getFirstTimeLoginDetailsCancellationToken = null;
export var verifyFirstTimeLoginDetailsCancellationToken = null;
export var updateFirstTimeLoginDetailsCancellationToken = null;
export var getInternationalCallingCodeCancellationToken = null;
export var updateMfaSkipCounterCancellationToken =null;
export var checkRedirectVersionCancellationToken = null;

export const getLoginId = () => {
    let simulationRequest;
    try {
        simulationRequest = JSON.parse(
            sessionStorage.getItem("simulation") || "null"
        );
    } catch(e) {
        _logException('Failed to parse simulation requeset ' + (e && e.message ? e.message : ''))
    }
    const partyId = simulationRequest && simulationRequest.pid ? `|${simulationRequest.pid}` : ''
    return simulationRequest ? `${simulationRequest.loginId}${partyId}`   : ' '
}

export const getUserProfile = (token, email) =>
{
    if (userProfileCancellationToken !== null && userProfileCancellationToken !== undefined) {
        userProfileCancellationToken.cancel("Cancel existing get user profile call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    userProfileCancellationToken = cancelToken.source();

    let getUserProfilePromise = new Promise(function (resolve, reject){
        const profileApiUrl = `${ProfileURL}${email || getLoginId()}')`;
        Get(profileApiUrl, token, undefined, undefined, userProfileCancellationToken).then(
            profileResponse => {
                resolve(profileResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching profile data", err);
                reject(err);
            }
        })
    });


    return getUserProfilePromise;

}

//Below function is used for fetching the user preferences data 
// It takes profile id of the user to pull the information 

export const getUserPreferences = (profileId, token, contextJson, profileJson) =>{

    if (getUserPreferncesCancellationToken !== null && getUserPreferncesCancellationToken !== undefined) {
        getUserPreferncesCancellationToken.cancel("Cancel existing get user profile call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getUserPreferncesCancellationToken = cancelToken.source();

    let getUserPreferencesPromise = new Promise(function (resolve){
        const preferencesUrl = `${PreferencesUrl}(${profileId})`;
        Get(preferencesUrl, token, contextJson, profileJson, getUserPreferncesCancellationToken).then(
            preferencesResponse => {
               let res = (preferencesResponse && preferencesResponse.data && preferencesResponse.data.preferencejson) ? 
                            preferencesResponse.data.preferencejson : null;
               resolve(res);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.error("Error Occured While Fetching preferences data", err);
                //reject(err);
                _logException({"Error" : "Preferences Api Failed", "Details" : err });
                resolve(defaultPreference); // Pass default preference in case of preferences failure...
            }
        })
    });


    return getUserPreferencesPromise;
}

//Below function is used for fetching the user template
//template api takes profile id and role as input


export const getUserTeamplate = (profileId, userrole, token, contextJson, profileJson) => {


    if (getUserTemplateCancellationToken !== null && getUserTemplateCancellationToken !== undefined) {
        getUserTemplateCancellationToken.cancel("Cancel existing get user template call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getUserTemplateCancellationToken = cancelToken.source();


    let getUserTemplatePromise = new Promise(function (resolve, reject){
        const templateUrl = `${TemplateUrl}('${userrole},${profileId}')`;
        Get(templateUrl, token, contextJson, profileJson, getUserTemplateCancellationToken).then(
            templateResponse => {
                if(templateResponse && templateResponse.data && templateResponse.data.value && Array.isArray(templateResponse.data.value) && templateResponse.data.value[0]){
                    let res = templateResponse.data.value[0];
                    resolve(res);
                }else{
                    reject("Invalid template api response");
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching user template data", err);
                reject(err);
            }
        })
    });


    return getUserTemplatePromise;
    

}


export const getEntireUserData = (token, email, simulationRequest) => {

    //create a promise

    const getEntireUserDataPromise = new Promise(function(resolve, reject){

        getUserProfile(token, email)
            .then(profileRes => {
                //Check the profile response
                console.log('Profile Response');
                console.log(profileRes);
                if (profileRes && profileRes.data && profileRes.data.length > 0 && Array.isArray(profileRes.data) && profileRes.data[0] && profileRes.data[0].profile) {
                    //Call template and preferences in paralle
                    const profileData = profileRes.data[0].profile;
                    const profilejson = profileRes.headers.profilejson;
                    const contextjson = profileRes.headers.contextjson;
                    let templatePromise = getUserTeamplate(profileData._id, profileData.role, token);
                    let preferencesPromise  = getUserPreferences(profileData._id, token);

                    Promise.all([templatePromise, preferencesPromise]).then(responses => {
                        //prefernce is group view then setcontext
                        const preferences  = responses[1]
                        if(preferences?.component && Array.isArray(preferences.component)) {
                            const viewMode = preferences.component.filter(x => x.key === 'AllAccountSelectorView')?.[0]?.value
                            if(viewMode === 'group') {
                                const defaultGroups = profileData?.groupedaccounts?.filter(x => x.isdefault)
                                if(defaultGroups && defaultGroups.length > 0) {
                                   const req = []
                                    defaultGroups.forEach(group => {
                                        group.accounts?.forEach(acct => {
                                            req.push({key: acct.key, isInternal: acct.isInternal})
                                        })
                                    })
                                    const uniqueAccts = uniqBy(req, 'key')
                                    setContextInCache(uniqueAccts, token, contextjson, profilejson)
                                    .then(() => {
                                        resolve({
                                            profile: profileData,
                                            profilejson: profilejson,
                                            contextjson: contextjson,
                                            template: filterTemplate(responses[0], simulationRequest, responses[1]),   //Used for template 
                                            preferences: responses[1] //Used for preferences 
                                        });
                                    })
                                    .catch(() => {
                                        resolve({
                                            profile: profileData,
                                            profilejson: profilejson,
                                            contextjson: contextjson,
                                            template: filterTemplate(responses[0], simulationRequest, responses[1]),   //Used for template 
                                            preferences: responses[1] //Used for preferences 
                                        });
                                    })

                                } else {
                                    resolve({
                                        profile: profileData,
                                        profilejson: profilejson,
                                        contextjson: contextjson,
                                        template: filterTemplate(responses[0], simulationRequest, responses[1]),   //Used for template 
                                        preferences: responses[1] //Used for preferences 
                                    });
                                }
                            }  else {
                                resolve({
                                    profile: profileData,
                                    profilejson: profilejson,
                                    contextjson: contextjson,
                                    template: filterTemplate(responses[0], simulationRequest, responses[1]),   //Used for template 
                                    preferences: responses[1] //Used for preferences 
                                });
                            }
                        } else {
                        resolve({
                            profile: profileData,
                            profilejson: profilejson,
                            contextjson: contextjson,
                            template: filterTemplate(responses[0], simulationRequest, responses[1]),   //Used for template 
                            preferences: responses[1] //Used for preferences 
                        });
                    }
                    }).catch(err => {
                        console.log("Template and Preferences Error :-", err)
                        reject(err);
                    })

                }
                else{
                    reject("Profile Response is invalid");
                } 
            })
            .catch(err => {
                    reject(err);
            })

    })
    return getEntireUserDataPromise;
}


const filterTemplate = (response, simulationRequest, preference) => {
    let navigations = [];
    if( simulationRequest &&
        response && response.TemplateJson 
        && response.TemplateJson.Navigation 
        && response.TemplateJson.Navigation.length > 0){
        //update template for simulation request 
        navigations  =  response.TemplateJson.Navigation;
        navigations =  navigations.filter(n => n.Name && n.Name !== 'Collaboration');
        response.TemplateJson.Navigation = navigations;
    }

    const isTransferAllowed = checkTrnasfersAllwed(simulationRequest, preference)
    if(!isTransferAllowed && response && response.TemplateJson 
        && response.TemplateJson.Navigation 
        && response.TemplateJson.Navigation.length > 0) {
        navigations  =  response.TemplateJson.Navigation;
        navigations =  navigations.filter(n => n.Name && n.Name !== 'Transfer');
        response.TemplateJson.Navigation = navigations;
    }

    return response;
}

const checkTrnasfersAllwed = (simulationRequest, preference) => {
    let isAllowed = simulationRequest ? false : true
     if(preference && preference.pilotfeatures && preference.pilotfeatures.length > 0 && simulationRequest) {
       const transferFromSimulation =  preference.pilotfeatures.filter(p => p.componentname === 'TransfersFromSimulation')
       if(transferFromSimulation && transferFromSimulation.length > 0 && transferFromSimulation[0].active === "true") {
          isAllowed = true
       }
     }
    return isAllowed
}

//Below Api call will set context on Redis cache for api's to use
//What ever the accounts present on cache api return data for same
//accounts

export const setContextInCache = (selectedAccount, token, contextJson, profileJson) => {

        if (setContextCancellationToken !== null && setContextCancellationToken !== undefined) {
            setContextCancellationToken.cancel("Cancel existing set context call to prevent memory leak.");
        }

        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        setContextCancellationToken = cancelToken.source();

        let setContextPromise = new Promise(function(resolve, reject){
        let contextUrl = ContextURL;
        let sanitizedAccountList = [];
        //Make sure we remove the **** from the external account numbers.
        if(selectedAccount && Array.isArray(selectedAccount)){
            let clonedSelectedAccount = JSON.parse(JSON.stringify(selectedAccount));
            //let clonedSelectedAccount = selectedAccount.slice();
                sanitizedAccountList =  clonedSelectedAccount.map(acct => {
                // acct.number = (acct.number) ? window.btoa(acct.number) : null;
                // acct.nickname = (acct.nickname) ? window.btoa(acct.nickname) : null;
                // if(acct.displaynickname && Array.isArray(acct.displaynickname) && acct.displaynickname.length > 0 && acct.displaynickname[0])
                // {
                //     acct.displaynickname[0].displongname = (acct.displaynickname[0].displongname) ? window.btoa(acct.displaynickname[0].displongname) : null;
                //     acct.displaynickname[0].dispshortname = (acct.displaynickname[0].dispshortname) ? window.btoa(acct.displaynickname[0].dispshortname) : null;
                //     acct.displaynickname[0].dispname = (acct.displaynickname[0].dispname) ? window.btoa(acct.displaynickname[0].dispname) : null;
                // }

                let account = {
                    "key": acct.key ? acct.key : null,
                    "isInternal": acct.isInternal
                }

                return account;
            });
        }


        Post(contextUrl, sanitizedAccountList, token, contextJson, profileJson, setContextCancellationToken)
        .then(result => {
            resolve(result);
        }).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While setting context on server", err);
                reject(err);
            }
        });

    })

    return setContextPromise;
}

export const checkTermsAndConditionsService = (token, contextJson, profileJson) => {
    //Call a new api to see any terms and condition required
    if (getEDeliveryTnCCancellationToken !== null && getEDeliveryTnCCancellationToken !== undefined) {
        getEDeliveryTnCCancellationToken.cancel("Cancel existing set context call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getEDeliveryTnCCancellationToken = cancelToken.source();


    let getTnCPromise = new Promise(function (resolve, reject){
        const tncUrl = ProfileTncUrl;
        Get(tncUrl, token, contextJson, profileJson, getEDeliveryTnCCancellationToken).then(
            tncResponse => {
                //After getting response from getTnC api
                resolve(tncResponse)
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });
    
    return getTnCPromise;
}


export const getUserFirstTimeLoginDetails = (token) => {
    //Call a new api to see first time user login details
    if (getFirstTimeLoginDetailsCancellationToken !== null && getFirstTimeLoginDetailsCancellationToken !== undefined) {
       getFirstTimeLoginDetailsCancellationToken.cancel("Cancel existing set context call to prevent memory leak.");
   }

   //Create a new cancellation token
   const cancelToken = axios.CancelToken;
   getFirstTimeLoginDetailsCancellationToken = cancelToken.source();


   let getFirstTimeDetailsPromise = new Promise(function (resolve, reject){
       const url = FirstTimeUserDetailsUrl;
       Get(url, token, null, null, getFirstTimeLoginDetailsCancellationToken).then(
           Response => {
               resolve(Response)
           }
       ).catch(err => {
           if (axios.isCancel(err)) {
               console.log(err);
           } else {
               reject(err);
           }
       })
   });
   
   return getFirstTimeDetailsPromise;
}

export const verifyFirstTimeUserDetails = (userform, token) => {
   if (verifyFirstTimeLoginDetailsCancellationToken !== null && verifyFirstTimeLoginDetailsCancellationToken !== undefined) {
       verifyFirstTimeLoginDetailsCancellationToken.cancel("Cancel existing set context call to prevent memory leak.");
   }

   //Create a new cancellation token
   const cancelToken = axios.CancelToken;
   verifyFirstTimeLoginDetailsCancellationToken = cancelToken.source();

   const data =  {"accountno": userform.accountno,"phoneno": userform.phoneno,"ssn": userform.ssn}

   let verifyFirstTimeDetailsPromise = new Promise(function (resolve, reject){
       const url = VerifyTimeUserDetailsUrl;
       Post(url, data, token,  null, null, verifyFirstTimeLoginDetailsCancellationToken).then(
           Response => {
               resolve(Response)
           }
       ).catch(err => {
           if (axios.isCancel(err)) {
               console.log(err);
           } else {
               reject(err);
           }
       })
   });
   
   return verifyFirstTimeDetailsPromise;
}

export const updateFirstTimeUserDetails = (userform, otpkey, otp, token) => {
   if (updateFirstTimeLoginDetailsCancellationToken !== null && updateFirstTimeLoginDetailsCancellationToken !== undefined) {
       updateFirstTimeLoginDetailsCancellationToken.cancel("Cancel existing set context call to prevent memory leak.");
   }

   //Create a new cancellation token
   const cancelToken = axios.CancelToken;
   updateFirstTimeLoginDetailsCancellationToken = cancelToken.source();

   const data =  {"accountno": userform.accountno,"phoneno": userform.phoneno,"ssn": userform.ssn, "key": otpkey, "otp": otp}

   let updateFirstTimeDetailsPromise = new Promise(function (resolve, reject){
       const url = UpdateFirstTimeLoginDetailsUrl;
       Post(url, data, token,  null, null, updateFirstTimeLoginDetailsCancellationToken).then(
           Response => {
               resolve(Response)
           }
       ).catch(err => {
           if (axios.isCancel(err)) {
               console.log(err);
           } else {
               reject(err);
           }
       })
   });
   
   return updateFirstTimeDetailsPromise;
}

export const getInternationalCallingCodes = (token) => {
    if (getInternationalCallingCodeCancellationToken !== null && getInternationalCallingCodeCancellationToken !== undefined) {
        getInternationalCallingCodeCancellationToken.cancel("Cancel existing set context call to prevent memory leak.");
    }
 
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getInternationalCallingCodeCancellationToken = cancelToken.source();
 
    let getInternationalCallingCodePromise = new Promise(function (resolve, reject){
        const url = GetInternationalCallingCodesUrl;
        Get(url, token,  null, null, getInternationalCallingCodeCancellationToken).then(
            Response => {
                resolve(Response)
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });

    return getInternationalCallingCodePromise;
}

export const updateMfaSkipCounterService = (id, token) => {
    if (updateMfaSkipCounterCancellationToken !== null && updateMfaSkipCounterCancellationToken !== undefined) {
        updateMfaSkipCounterCancellationToken.cancel("Cancel existing update mfa skip counter to prevent memory leak");
    }
 
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    updateMfaSkipCounterCancellationToken = cancelToken.source();

 
    let updatemfaSkipCounterPromise = new Promise(function (resolve, reject){
        const url = `${UpdateMfaSkipCounterUrl}?id=${id}`;
        Post(url, null, token,  null, null, updateMfaSkipCounterCancellationToken).then(
            Response => {
                resolve(Response)
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });
    
    return updatemfaSkipCounterPromise;
 }


 export const checkRedirectVersionService = (id) => {
    if (checkRedirectVersionCancellationToken !== null && checkRedirectVersionCancellationToken !== undefined) {
        checkRedirectVersionCancellationToken.cancel("Cancel existing check redirect version cancellation token");
    }
 
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    checkRedirectVersionCancellationToken = cancelToken.source();

 
    let checkRedirectVersionPromise = new Promise(function (resolve, reject){
        const url = `${CheckRedirectVersionUrl}?email=${id}`;
        Get(url, null, null, null, checkRedirectVersionCancellationToken).then(
            Response => {
                resolve(Response)
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });
    
    return checkRedirectVersionPromise;
 }