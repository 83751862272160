import React, { Component } from 'react';
import TransferHistoryFilter from "./transferHistoryFilter.jsx";
//import TransferFund from "./transferFund.jsx";
import ScheduleTransfers from "./scheduleTransfers.jsx";
import PastTransfers from "./pastTransfers.jsx";
import JournalEntry from './journalEntry.jsx';
import { SELECTRANGE } from "./data/filterDateRange.js";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ScheduleTransfersActivity, Remove_LatestConf } from "../../actions/moneymovementactions";
import ErrorBoundary from '../../common/errorboundary.jsx';
import XLSX from "xlsx";
import download from 'downloadjs';
import { generatePastTransfersExcelWs, printPastTransferReport } from "./pastTransfersColumns.js";
import { generateScheduleTransfersExcelWs, printScheduleTransferReport } from "./scheduleTransfersColumn.js";
import { getDataArrayBuffer } from "../../common/excelreport.jsx";
import { withPageView } from '../../hoc/withPageView.jsx';

class Transfers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateRange: SELECTRANGE,
            transferData: null
        }
        this.changeFilterHistory = this.changeFilterHistory.bind(this);
        //this.newTransferEvent = this.newTransferEvent.bind(this);
        this.updateScheduleTransferList = this.updateScheduleTransferList.bind(this);
        this.exportPastTransfers = this.exportPastTransfers.bind(this);
        this.exportScheduleTransfers = this.exportScheduleTransfers.bind(this);
        this.printScheduleTransfers = this.printScheduleTransfers.bind(this);
        this.printPastTransfers = this.printPastTransfers.bind(this);
        this.clearRecentTransferConf = this.clearRecentTransferConf.bind(this);
    }

    changeFilterHistory(newFilter) {
        this.setState(() => ({
            dateRange: newFilter
        }));
    }

    exportPastTransfers() {
        //Step1 : Create a workbook
        var wb = XLSX.utils.book_new();
        var fileName = "";
        //Step2 : add properties in your excel file its optional
        wb.Props = {
            Title: "Transfers Report",
            Subject: "Transfers Report",
            Author: "Copyright@Rockefeller"
        };

        wb.SheetNames.push("Past Transfers");
        var PastTransfersWs = generatePastTransfersExcelWs(this.props.pastTransfersList);
        wb.Sheets["Past Transfers"] = PastTransfersWs;
        fileName = "PastTransfers.xlsx";
        
        //step 4: Write wotksheet into binary
        let wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //step 5: user file saver to save the file
        download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), fileName, "application/octet-stream");
        return;

    }

    printPastTransfers() {
        printPastTransferReport(this.props.pastTransfersList);
    }

    exportScheduleTransfers() {
        //Step1 : Create a workbook
        let wb = XLSX.utils.book_new();
        let fileName = "";
        //Step2 : add properties in your excel file its optional
        wb.Props = {
            Title: "Transfers Report",
            Subject: "Transfers Report",
            Author: "Copyright@Rockefeller"
        };

        wb.SheetNames.push("Pending Transfers");
        let scheduleTransfersWs = generateScheduleTransfersExcelWs(this.props.scheduleTransfersList);
        wb.Sheets["Pending Transfers"] = scheduleTransfersWs;
        fileName = "PendingTransfers.xlsx";

        //step 4: Write wotksheet into binary
        var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //step 5: user file saver to save the file
        download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), fileName, "application/octet-stream");
        return;
    }

    printScheduleTransfers() {
        printScheduleTransferReport(this.props.scheduleTransfersList);
    }


    updateScheduleTransferList(data) {
        this.props.ScheduleTransfersActivity(data);
    }

    clearRecentTransferConf()
    {
        this.props.Remove_LatestConf();
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="RCM_Toast" id="RCM_transferfund">
                            {/*<TransferFund />*/}
                            <div className="row">
                                <div className="col-sm-12">
                                    
                                    <ErrorBoundary>
                                        <JournalEntry newTransferEvent={this.newTransferEvent} />
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="RCM_Toast" id="RCM_transferfundhistory">
                        <div className="row">
                            <div className="col-sm-12 mt-3">
                                <TransferHistoryFilter changeFilterHistory={this.changeFilterHistory} />
                            </div>
                            
                        </div>
                        <div><hr className="RCM_divider_horizontal"></hr></div>
                        <div className="row" id="RCM_tranferPendingData">
                            <div className="col-sm-12 mt-3">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                                <div className="RCM_Toast_sub_heading RCM_heading_light">PENDING TRANSFERS</div>
                                        </div>
                                        <div className="col-sm-6" align="right">
                                            {/* <button className="btn RCM_Toptendetail_img RCM_userMenu_Print pull-right" onClick={this.printScheduleTransfers}></button>
                                            <button className="btn RCM_Toptendetail_img RCM_Download pull-right" onClick={this.exportScheduleTransfers}></button>
                                            */}
                                        </div>
                                    </div>
                                                                              
                                            
                                        
                                    <div className="mt-2">
                                        <ErrorBoundary>
                                            <ScheduleTransfers
                                                //filterData={this.state.dateRange}
                                                accounts={this.props.accounts}
                                                selectedaccount={this.props.selectedaccount}
                                                render={this.props.render}
                                                token={this.props.token}
                                                recentConf={this.props.recentConf}
                                                profilejson={this.props.profilejson}
                                                contextjson={this.props.contextjson}
                                                scheduleTransfersList={this.props.scheduleTransfersList}
                                                updateScheduleTransferList={this.updateScheduleTransferList}
                                                clearRecentTransferConf = {this.clearRecentTransferConf} />
                                        </ErrorBoundary>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                            <div className="row" id="RCM_transferPastData">
                            <div className="col-sm-12">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                                <div className="RCM_Toast_sub_heading RCM_heading_light">PAST TRANSFERS</div>
                                        </div>
                                        <div className="col-sm-6" align="right">
                                            {/*
                                            <button className="btn RCM_Toptendetail_img RCM_userMenu_Print" onClick={this.printPastTransfers}></button>
                                            <button className="btn RCM_Toptendetail_img RCM_Download" onClick={this.exportPastTransfers}></button> */}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <ErrorBoundary>
                                            <PastTransfers filterData={this.state.dateRange} />
                                        </ErrorBoundary>
                                    </div>
                                </div>
                            </div>
                            </div>
                      </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accounts: state.acct.accounts,
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        scheduleTransfersList: state.moneymovement.scheduledTransfersList,
        pastTransfersList: state.moneymovement.pastTransfersList,
        recentConf: state.moneymovement.recentTransferConf,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
}

const mapDispatchToProps = {
    ScheduleTransfersActivity,
    Remove_LatestConf
}


const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({pageName: 'Transfers'})
)

//export default connect(mapStateToProps, mapDispatchToProps)(withPageView(Transfers));

export default enhancement(Transfers);
