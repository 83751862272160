import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { getActivitySummaryColumns } from './activitycolumns.jsx'
import { defaultSorted } from './activitycolumns.jsx'
export function AccountActivitySummaryView(
    props
) {
    let accountactivitysummaryview = (
        <div key="activity01" className="row">

            <div key="activity02" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_activity_table RCM_CM_scroll">
                <span key="activity03" className="row col-sm-6"><label key="activity05">(*) - Indicates a pending activity.</label></span>
                <BootstrapTable
                    keyField='id'
                    data={props.rows}
                    columns={getActivitySummaryColumns(props.selectedaccounts)}
                    headerClasses="row-bg"
                    bordered={false}
                    rowClasses={props.rowClasses}
                    classes="RCM_two_level_table1"
                    noDataIndication="No data available"
                    defaultSorted={defaultSorted}
                    />
            </div>
        </div>)
    return accountactivitysummaryview
}