import React, { Component } from "react";
import { connect } from "react-redux";
import Toptendetails from "./toptendetailsservicecalls.jsx";
import ErrorBoundary from "../../../../common/errorboundary.jsx";
import {balanceActicityAggreationDisclaimer} from '../../../../common/constants'
class Toptendetail extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  render() {
    const toptendetails = (
      <div key="dvholding" className="RCM_detailsScreeen">
        <ErrorBoundary>
          <Toptendetails />
        </ErrorBoundary>
        <div className="mt-2 RCM_footer_text">
          <p>{balanceActicityAggreationDisclaimer}</p>
        </div>
      </div>
    );

    return toptendetails;
  }
}
const mapStateToProps = (state) => {
  return {
    holdingsbyaccount: state.topten.holdingsbyaccount,
    holdingsbysecurity: state.topten.holdingsbysecurity,
    holdingsbycategory: state.topten.holdingsbycategory,
    render: state.acct.render,
    acct: state.acct,
    contextjson: state.acct.contextjson,
    profilejson: state.acct.profilejson,
  };
};

export default connect(mapStateToProps)(Toptendetail);
