import React from 'react';
export const getSupportdocColumns = (navigateToSite) => {
    const columns = [
        {
            dataField: "id",
            text: "",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            hidden: true
        }, {
            dataField: 'name',
            text: "Name",
            classes: 'RCM_common_table_column_text',
            sort: false,
            
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }

        }, {
            dataField: 'url',
            text: "",
            classes: 'RCM_common_table_column_number',
            headerAlign:'right',
            formatter: (cell) => {
                return (<div
                    className="RCM_icon_hand RCM_Teamwork_sharepoint"
                    title="View in browser"
                    onClick={() => navigateToSite(cell)}
                ></div>)
            }
        }
        
    ];

    return columns;
}