import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { LiabilitiesColumns, LiabilitiesNotesColumns } from './liabilitiescolumns.jsx';
import { MsgForNoData, formatMoney, GetNumericValue, roundvalue, GetTextValue } from '../../../../common/constants.jsx';
import { toDate } from 'date-fns/esm';
import Watermark from '../../../../common/watermark'; 
export function LiabilitiesDetailsView(
    props
) {

    const expandRow = {
        className: (isExpanded, row, rowIndex) => {
            return 'RCM_CM_Table_Expand_bar'; 
          },
        renderer: row => (
            <div>
                {row.type === 'Margin' ?
                    <table className="table RCM_two_level_table2 RCM_Headerless_table">
                        <thead>
                            <tr>
                                <th width="12%" />
                                <th />
                                <th width="12%" />
                                <th />
                                <th width="12%" />
                                <th />
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Margin Balance:</td>
                                <td>{row.margin ? formatMoney(row.margin) : GetNumericValue(row.margin)}</td>
                                <td>Margin Interest Rate:</td>
                                <td>{row.marginintrate ? roundvalue(row.marginintrate, 2) + '%' : GetNumericValue(row.marginintrate,true,false)}</td>
                                <td>Cash &amp; Margin Available to Withdraw:</td>
                                <td>{row.cashmarginavailabletowithdraw ? formatMoney(row.cashmarginavailabletowithdraw) : GetNumericValue(row.cashmarginavailabletowithdraw)}</td>
                            </tr>
                            <tr>
                                <td>Cash & Margin Available to Trade:</td>
                                <td></td>
                                <td>Fully Margin Eligible Securities:</td>
                                <td>{row.cashmarginbuyingpower ? formatMoney(row.cashmarginbuyingpower) : GetNumericValue(row.cashmarginbuyingpower)}</td>
                                <td>Non-Margin Eligible Securities:</td>
                                <td>{row.nonmarginbuyingpower ? formatMoney(row.nonmarginbuyingpower) : GetNumericValue(row.nonmarginbuyingpower)}</td>
                            </tr>
                        </tbody>

                    </table> : <table className="table RCM_two_level_table2 RCM_Headerless_table">
                        <thead>
                            <tr>
                                <th width="12%" />
                                <th />
                                <th width="12%" />
                                <th />
                                <th width="12%" />
                                <th />
                                <th/>
                                <th width="12%"/>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Loan open date:</td>
                                <td>{row.loanopendate ? (new Date(row.loanopendate).toLocaleDateString("default", { month: "2-digit", day: "2-digit", year: "numeric" })): GetTextValue(row.loanopendate)}</td>
                                <td>Payment due</td>
                                <td>{row.nextpayment ? formatMoney(row.nextpayment) : "-"}</td>
                                <td>Next payment due:</td>
                                <td>{row.nextpaymentdate ? (new Date(row.nextpaymentdate).toLocaleDateString("default", { month: "2-digit", day: "2-digit", year: "numeric" })) : GetTextValue(row.nextpaymentdate)}</td>
                                <td>Next rate change date:</td>
                                <td>{row.nextratechangedate ? (new Date(row.nextratechangedate).toLocaleDateString("default", { month: "2-digit", day: "2-digit", year: "numeric" }))  : GetTextValue(row.nextratechangedate)}</td>
                            </tr>
                            <tr>
                                <td>Payment method:</td>
                                <td>{row.paymentmethod ? row.paymentmethod:'-'}</td>
                                <td>Interest paid amount (YTD):</td>
                                <td>{row.interestpaidamountytd ? formatMoney(row.interestpaidamountytd) : GetNumericValue(row.interestpaidamountytd)}</td>
                                <td></td>
                                <td></td>
                                <td>Collateral accounts:</td>
                                <td>{row.collateralaccounts ? row.collateralaccounts:'-'}</td>
                            </tr>
                            {
                                row.notes && row.notes.length > 0 && (
                                    <tr>
                                        <td colSpan="8">
                                            <BootstrapTable 
                                                keyField="loanaccount"
                                                data={row.notes}
                                                columns={LiabilitiesNotesColumns()}
                                                bordered={false}
                                                classes="RCM_two_level_table1"
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>

                    </table>
                }
            </div>
        ),
        showExpandColumn: true,
        expandColumnPosition: 'right',
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return <b></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<div className="RCM_icon_hand RCM_down_arrow">
                </div>);
            }

            return (<div className="RCM_icon_hand RCM_right_arrow" >
            </div>);
        }
      };

    let liabilitiesdetailsview = (
        <div>
            {
                props && props.state.isProspectClient ? <Watermark /> : ''}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="RCM_Toast RCM_Document_Toast" id="RCM_Liabilities_Table">
                            
                            {props && props.data && props.data.length !== 0 ?
                                     <div>
                                        <BootstrapTable
                                            keyField="key"
                                            data={props.data}
                                            columns={LiabilitiesColumns(props.showManageAssetDetails, props.selectedaccount)}
                                            bordered={false}
                                            classes="RCM_two_level_table1"
                                            wrapperClasses="RCM_CM_no_hand"
                                            //noDataIndication={() => noDataIndicationMessage(this.state.gridMessage)}
                                            //defaultSorted={defaultSorted}
                                            expandRow={ expandRow }
                                        />
                                    </div>
                                : <div>{MsgForNoData}</div>}
                        </div>
                    </div>
                </div>
            
        </div>
    )
    return liabilitiesdetailsview
}



