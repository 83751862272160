import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectWithCheckbox from './../../../common/SelectWithCheckbox.jsx';
import AccountSelectorWithGroup from './../../../common/accountselectorwithgroup.jsx';
import ProspectAccountSelectorWithGroup from './../../../common/prospectaccountselectorwithgroup.jsx';
class Accountlist extends Component{
   
    constructor(props) {
        super(props);
        this.state = {
            showGroupedView: false
        }
        
    }
    componentDidMount() {
        if (this.props.preferences
            && this.props.preferences.pilotfeatures
            && Array.isArray(this.props.preferences.pilotfeatures)
            && this.props.preferences.pilotfeatures.length > 0) {

            let pilotfeatures = this.props.preferences.pilotfeatures;
            let showGroupedView = false;
            for (let i = 0; i < pilotfeatures.length; i++) {
                if (pilotfeatures[i] && pilotfeatures[i].componentname
                    && pilotfeatures[i].componentname === 'Manage Groups'
                    && pilotfeatures[i].active
                    && pilotfeatures[i].active === "true") {
                    showGroupedView = true;
                    break;
                }
            }
            this.setState({ showGroupedView: showGroupedView })
        }
    }
    render() {
        return (
            <div>
                {
                    this.props.clientRole === "Prospect" ? <ProspectAccountSelectorWithGroup />
                        : (this.state.showGroupedView && !(this.props.clientRole === "Prospect")) ? <AccountSelectorWithGroup />
                    : <SelectWithCheckbox />}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        preferences: state.acct.preferences,
        clientRole:state.acct.role
    };
};

export default connect(mapStateToProps)(Accountlist);
