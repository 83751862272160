import React from 'react'
import { HelpAndSupportModal } from './CaseManager/helpandsupportmodal'

export default function UnEntitled(props) {

    return (
        <>
            <div>
                <div className="RCM_background"></div>
                <div className="RCM_background_overlay"></div>
            </div>
            <div className="RCM_login_outer">
                <div className="RCM_login_middle">
                    <div className="RCM_login_inner">
                        <img
                            className="RCM_Logon_Form_Logo"
                            role="presentation"
                            src="../images/logo.svg"
                        />

                        <div>
                            <label htmlFor="uname" className="RCM_Logon_Form2">
                                Sign Out
                            </label>
                            <div className="mt-3 RCM_Logon_Form_Error">
                                    We are unable to verify your access at this time.  Please contact your Advisor team for assistance or 
                                        <span className="RCM_caseManager_modal_link" onClick={() => props.toggleHelpAndSupportModal()}> click here </span>    
                                    to report this issue to our technical support team.
                                {/* {props.user.name} with logged in email {props.user.userName} is not entitled to Access the site. */}
                            </div>
                            <div className="mt-3" align="right">
                                <button className="RCM_Form_Primary_Button" onClick={() => props.logout()}>Sign Out</button>
                            </div>
                        </div>

                        <div className="RCM_Logon_Form_Footer">
                            <div>
                                Welcome to Rockefeller Capital Management. Please login with your credentials.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HelpAndSupportModal
                        source="login"
                        toggleHelpAndSupportModal={() => props.toggleHelpAndSupportModal()}
                        additionalInfo={{}}
                        state={props.state}
                    >
            </HelpAndSupportModal>
        </>
    )
}
