import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { RealizedGainLossURL, spinner, GetAccountsList, MsgForNoData, CheckIfProspectClient} from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddRealizedgainlossBySecurity, SetAccountOverviewFlag } from '../../../../../actions/toptenactions.jsx';
import { securityColumns, securitychildColumns, renderRowsBySecurity, renderRowsBySecurityChild, getDisplayName } from '../realizedgainlosscolumns.jsx';
import TASHoldingdetails from '../realizedtasholding.jsx';
import axios from "axios";
import { SecurityHoldingsView } from './realizedsecurityholdingview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { realizedgainlosssecuritydummyresponse, RRR_123456_realizedgainlosssecurityresponse, RRR_234567_realizedgainlosssecurityresponse } from '../../../accountsmockresponse.js';
import Watermark from '../../../../../common/watermark.jsx';

class SecurityHoldingdetails extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            realizedgainlossbysecurity: this.props.realizedgainlossbysecurity,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            expanded: [],
            accountOverviewFlag: this.props.accountOverviewFlag,
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
            dropdownyearValue: new Date().getFullYear(),
            totalgainloss: '',
            totalgainlosspct: '',
            rowdata: [],
            headername: '',
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        this.collapserow = this.collapserow.bind(this);
        this.toggle = this.toggle.bind(this);
        this.changeYear = this.changeYear.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
    }
    getSecurityGainLossCancellationToken = null;
    getSecurityHoldingsAccountChangeCancellationToken = null;

    //method to execute api calls
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];
        var accounts = '';
        if (this.state.accountOverviewFlag) {
            this.props.selectedaccount.map(x => {
                if (x.number !== 'All Accounts') {
                    accounts += x.number + ',';
                }

            })
            accounts = accounts.substr(0, accounts.length - 1);
            this.props.SetAccountOverviewFlag(false);
        }
        if (this.getSecurityGainLossCancellationToken) {
            //Cancel the existing call
            this.getSecurityGainLossCancellationToken.cancel('Cancelling the existing Security Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getSecurityGainLossCancellationToken = cancelToken.source();
        let selectorparam = 'S';
        const baseUrlSecurity = RealizedGainLossURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ', \'Y\', \'\',\'\' )';
        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getSecurityGainLossCancellationToken)
            .then(result => {
                const data = result && result.data && result.data.value && Array.isArray(result.data.value)  ? result.data.value : [];
                this.props.AddRealizedgainlossBySecurity(data);
                this.setState(() => ({ realizedgainlossbysecurity: data, isloading: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                }
            })
    };

    componentDidMount() {
        //if props has data then take it or make api call
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.realizedgainlossbysecurity == null) {
                this.execute();
            }
            else {
                this.setState({ isloading: false });
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }
            
           
        }
    }
    componentWillUnmount() {
        if (this.getSecurityGainLossCancellationToken !== null && this.getSecurityGainLossCancellationToken !== undefined) {
            this.getSecurityGainLossCancellationToken.cancel('Cancelling the Security realized gain loss during unmount to prevent memory leak.');
        }

    }
    collapserow(row) {
        this.setState(() => ({
            expanded: []
        }));
    }
   
   
    toggle(row) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            rowdata : row.LOTS,
            totalgainloss: row.GAINLOSS,
            totalgainlosspct: row.GAINLOSSPCT,
            headername: (row.SECNAME ? row.SECNAME : "" )+ (row.SECID ? (" - " + row.SECID) : "")
        }));
        
    }
    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.UNQID]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.UNQID),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
    }
   
    changeYear(selectedyear) {
        this.setState({ dropdownyearValue: selectedyear.label }, () => { this.execute(this.accounts) });
    }
    

    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.realizedgainlossbysecurity === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ realizedgainlossbysecurity: (RRR_123456_realizedgainlosssecurityresponse && RRR_123456_realizedgainlosssecurityresponse.value ? RRR_123456_realizedgainlosssecurityresponse.value : []), isloading: false, areDataRetrieved: true, expanded: ["11133T103", "437076102"] }), () => { this.props.AddRealizedgainlossBySecurity((RRR_123456_realizedgainlosssecurityresponse && RRR_123456_realizedgainlosssecurityresponse.value ? RRR_123456_realizedgainlosssecurityresponse.value : [])); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ realizedgainlossbysecurity: (RRR_234567_realizedgainlosssecurityresponse && RRR_234567_realizedgainlosssecurityresponse.value ? RRR_234567_realizedgainlosssecurityresponse.value : []), isloading: false, areDataRetrieved: true, expanded: ["30212P303"] }), () => { this.props.AddRealizedgainlossBySecurity((RRR_234567_realizedgainlosssecurityresponse && RRR_234567_realizedgainlosssecurityresponse.value ? RRR_234567_realizedgainlosssecurityresponse.value : [])); });
                }
            } else if (this.props.selectedaccount.length == 2) {
                console.log(realizedgainlosssecuritydummyresponse.value);
                this.setState(() => ({ realizedgainlossbysecurity: (realizedgainlosssecuritydummyresponse && realizedgainlosssecuritydummyresponse.value ? realizedgainlosssecuritydummyresponse.value : []), isloading: false, areDataRetrieved: true, expanded: ["278865100", "824348106"] }), () => { this.props.AddRealizedgainlossBySecurity((realizedgainlosssecuritydummyresponse && realizedgainlosssecuritydummyresponse.value ? realizedgainlosssecuritydummyresponse.value : [])); });
            }
        }
        else {
            this.setState({ isloading: false, dropdownValue: this.state.accounts[0].number, expanded: ["278865100", "824348106"], dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }
    }
    //render method
    render() {
        var securityData = [];
       
        var totalglamount, totalglpct, totalProceeds, totalcostbasis,totalST, totalLT;
        if (this.state.realizedgainlossbysecurity && this.state.realizedgainlossbysecurity.length > 0) {
            if (this.state.realizedgainlossbysecurity[0].ITEMSUMMARY) {
                for (var i = 0; i < this.state.realizedgainlossbysecurity[0].ITEMSUMMARY.length; i++) {
                    if (this.state.realizedgainlossbysecurity[0].ITEMSUMMARY[i].SECNAME === null) {
                        securityData = [];
                    }
                    else {
                        securityData = renderRowsBySecurity(this.state.realizedgainlossbysecurity[0].ITEMSUMMARY);
                    }
                }

            }
            totalST = this.state.realizedgainlossbysecurity[0].SHRTGAINLOSS
            totalLT = this.state.realizedgainlossbysecurity[0].LNGGAINLOSS
            totalProceeds = this.state.realizedgainlossbysecurity[0].GRANDSTKBNDAMT;
            totalglamount = this.state.realizedgainlossbysecurity[0].GRANDPROFIT;
            totalglpct = this.state.realizedgainlossbysecurity[0].GRANDPROFITPCT;
            totalcostbasis = this.state.realizedgainlossbysecurity[0].GRANDCOSTBASIS;

        }
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for security table
        const expandRowBySecurity = {
            renderer: (row) => (
                <BootstrapTable
                    key="btsecchild1"
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={renderRowsBySecurityChild(row, this.state.childColumnToSort, this.state.childColumnSortOrder, this.props.selectedaccount)}
                    columns={securitychildColumns(this.props.selectedaccount)}
                    noDataIndication={MsgForNoData}
                    rowEvents={rowEvents}
                    bordered={false}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.UNQID);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };
        return (

            <ErrorBoundary>
                <SecurityHoldingsView
                    state={this.state}
                    securityData={securityData}
                    accounts={this.props.selectedaccount}
                    totalProceeds={totalProceeds}
                    totalST={totalST}
                    totalLT={totalLT}
                    totalglamount={totalglamount}
                    totalglpct={totalglpct}
                    totalcostbasis={totalcostbasis}
                    selectedAccount={this.props.selectedaccount}
                    expandRowBySecurity={expandRowBySecurity}
                    changeMonth={this.changeMonth}
                    changeYear={this.changeYear}
                    toggleYear={this.toggleYear}
                    rowsortevent={this.rowsortevent}
                />
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="RCM_holding_details_modal_content"
                    zIndex='9999'
                    modalClassName="RCM_CM_modal_class"
                    centered key='modaltas1'>
                    <ModalHeader toggle={this.toggle} className="RCM_holding_details_modal" key='modaltas2'>
                        <label className="RCM_tile_heading" key='lblholdingheading'>{this.state.headername}</label>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.isProspectClient ? <Watermark/>:''}
                        <TASHoldingdetails row={this.state.rowdata} totalgainloss={this.state.totalgainloss} totalgainlosspct={this.state.totalgainlosspct}></TASHoldingdetails>
                    </ModalBody>

                </Modal>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        realizedgainlossbysecurity: state.topten.realizedgainlossbysecurity,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        accountOverviewFlag: state.acct.accountOverviewFlag,
        role:state.acct.role
    };
};

const mapDispatchToProps = {
    AddRealizedgainlossBySecurity,
    SetAccountOverviewFlag
};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Security Holdings' })
)
export default securityholdingdetailscomp(SecurityHoldingdetails);