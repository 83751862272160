import React, { Component } from 'react';
import PlanManagerConfirmationModal from './planManagerconfirmationModal';

export default class PlanManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planManagerModalState: false
        }
        this.togglePlanManagerModal = this.togglePlanManagerModal.bind(this);
    }

    togglePlanManagerModal() {

        this.setState(prevState => ({
            planManagerModalState: !prevState.planManagerModalState
        }));
    }

    render() {
        return (
            <div>
                <div className="RCM_Toast" id="RCM_ManageGroups">
                    <div className="row">

                        <a onClick={this.togglePlanManagerModal}>Click here to launch Plan Manager</a>
                    </div>
                </div>
                <PlanManagerConfirmationModal planManagerModalState={this.state.planManagerModalState} togglePlanManagerModal={this.togglePlanManagerModal}></PlanManagerConfirmationModal>
            </div>
        );
    }
}
