
export function getFormattedDate(dateStr) {
    if(Date.parse(dateStr)){
    let date = new Date(dateStr);
    var year = date.getUTCFullYear();
    var month = (1 + date.getUTCMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getUTCDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
    }else {
        return ""
    }
    //return year + '-' + month + '-' + day; 
}

export function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getNestedObject(nestedObj, pathArr) {

    if(nestedObj !== null && nestedObj !== undefined 
        && pathArr !== null && pathArr !== undefined && Array.isArray(pathArr)){
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== undefined && obj[key] !== null ) ? obj[key] : "", nestedObj);
    }
}

export function loadExternalJS(_url, _id) {
    return new Promise(function(resolve, reject) {
        let isScriptPresent = document.getElementById(_id);
        if(isScriptPresent){
            //Remove the script tag
            isScriptPresent.parentNode.removeChild(isScriptPresent);
        }
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = _id
        script.async = true;
        script.src = _url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
        
    })
  }


export function loadExternalCss(_url, _id)
{
    return new Promise(function(resolve, reject){
        let isCssExist = document.getElementById(_id);
        if(!isCssExist)
        {
            var lnk = document.createElement("link");
            lnk.href=_url;
            lnk.rel='stylesheet';
            lnk.type='text/css';
            lnk.onload = resolve;
            lnk.onerror = reject;
            document.head.appendChild(lnk);

        }else{
            console.log(`${_id} already in head`);
            resolve();
        }
    })
}

export function filterAggregatedAccounts(accounts)
{
    let filteredAccount = [];
    if(accounts && Array.isArray(accounts) && accounts.length > 0)
    {
      filteredAccount =  accounts.filter(account => account.isInternal === true);
    }

    return filteredAccount;
}



