import React, { useContext, useRef, useEffect, useCallback } from "react";
import Breadcrum from "./Breadcrum";
import { Get, Post, PostGraph } from "../../../common/servicecalls";
import { TeamworkContext } from "../TeamworkContainer";
import { useUploadFile } from "../Hooks/useUploadFile";
import { useBoolean } from "../Hooks/useBoolean";
import NewFolderModal from "./NewFolderModal";
import { Progress } from "reactstrap";
import { _logException } from "../../../common/_logging";

const CommandBar = ({
  breadcrums,
  onBreadCrumClick,
  selectedDriveItem,
  group,
  onRefresh,
}) => {
  const { token, setError } = useContext(TeamworkContext);
  const fileSelectorRef = useRef(null);
  const { value: isAddFolderOpen, toogle: toogleAddFolder } = useBoolean(false);
  const {
    loading: isFileUploading,
    error: fileUploadError,
    completed,
    uploadFile,
    progress: uploadProgress,
  } = useUploadFile();

  const handleUploadClick = () => {
    //create upload session
    if (fileSelectorRef) {
      fileSelectorRef.current.click();
    }
  };

  const onFileSelect = (event) => {
    setError();
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      event.target.value = "";
      const url = selectedDriveItem
        ? `https://graph.microsoft.com/v1.0/drives/${selectedDriveItem.parentReference.driveId}/items/${selectedDriveItem.id}:/${file.name}:/createUploadSession`
        : `https://graph.microsoft.com/v1.0//groups/${group.id}/drive/root:/${file.name}:/createUploadSession`;
      Post(
        url,
        {
          item: {
            "@microsoft.graph.conflictBehavior": "rename",
          },
        },
        token
      )
        .then((res) => {
          uploadFile(file, res.data.uploadUrl, token);
        })
        .catch((err) => {
          _logException(err);
          setError("We are unable to create upload file session");
        });
    }
  };

  const handleLaunchSharePoint = useCallback(() => {
    if (selectedDriveItem) {
      window.open(`${selectedDriveItem.webUrl}`);
    } else {
      //call graph api to get the root url 
      const url = `https://graph.microsoft.com/v1.0/groups/${group.id}/drive/root?$select=webUrl`
      Get(url, token)
      .then(res => {
        if(res && res.data && res.data.webUrl) {
          window.open(res.data.webUrl)
        } else {
          alert('We are unable to open collaboration portal, please try after sometime.')
        }
      })
      .catch(() => {
        alert('We are unable to open collaboration portal, please try after sometime.')
      })
    }
  }, [group.id, selectedDriveItem, token]);

  const handleOnNewFolderClick = useCallback(() => {
    toogleAddFolder();
  }, [toogleAddFolder]);

  const handleOnNewFolderSubmit = useCallback(
    (foldername) => {
      setError();
      toogleAddFolder();
      const url = selectedDriveItem
        ? `https://graph.microsoft.com/v1.0/drives/${selectedDriveItem.parentReference.driveId}/items/${selectedDriveItem.id}/children`
        : `https://graph.microsoft.com/v1.0/groups/${group.id}/drive/root/children`;

      PostGraph(
        url,
        {
          name: foldername,
          folder: {},
          "@microsoft.graph.conflictBehavior": "rename",
        },
        token
      )
        .then((res) => {
          onRefresh();
        })
        .catch((err) => {
          _logException(err);
          setError("We are unable to create folder, please try again");
        });
    },
    [group.id, onRefresh, selectedDriveItem, setError, token, toogleAddFolder]
  );

  const handleRefreshClick = useCallback(() => {
     onRefresh()
  },[onRefresh])

  useEffect(() => {
    if (completed) {
      onRefresh();
    }
  }, [completed, onRefresh]);

  useEffect(() => {
    if (fileUploadError) {
      setError("We are unable to upload file, please try again.");
    }
    //clear upload session here...
  }, [fileUploadError, setError]);

  return (
    <>
      <div className="RCM_Teamwork_CommandBar">
        {breadcrums && (
          <Breadcrum
            breadcrums={breadcrums}
            onBreadCrumClick={onBreadCrumClick}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "flex-end",
            marginLeft: "auto",
          }}
        >
          <div id="mnuRefresh"
            className="RCM_Teamwork_link RCM_Teamwork_DriveItem_Center"
            onClick={handleRefreshClick}
          >
            Refresh
          </div>
          <div>|</div>
          <div id="mnuNewFolder"
            className="RCM_Teamwork_link RCM_Teamwork_DriveItem_Center"
            onClick={handleOnNewFolderClick}
          >
            +New Folder
          </div>
          <div>|</div>
          <div id="mnuUpload"
            className="RCM_Teamwork_link RCM_Teamwork_DriveItem_Center"
            onClick={handleUploadClick}
          >
            Upload
          </div>
          <input
            id="fileSelector"
            type="file"
            name="fileSelector"
            ref={fileSelectorRef}
            style={{ display: "none" }}
            onChange={onFileSelect}
          />
        </div>
        <div>|</div>
        <div id="mnuLaunchPortal"
          className="RCM_Teamwork_link RCM_Teamwork_DriveItem_Center"
          onClick={handleLaunchSharePoint}
        >
          Launch Collaboration Portal
        </div>
        <NewFolderModal
          isOpen={isAddFolderOpen}
          toogle={toogleAddFolder}
          onAddNewFolderClick={handleOnNewFolderSubmit}
        />
      </div>
      {isFileUploading && (
        <Progress color="success" value={Math.round(uploadProgress)}>
          {Math.round(uploadProgress)}
        </Progress>
      )}
    </>
  );
};

export default CommandBar;
