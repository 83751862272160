import React from 'react';
import { DocumentURL, getAccountDisplayName, IndLongShort } from './../../../common/constants.jsx';
export function getAccountStatementsModalColumns(authprops, selectedaccounts, role) {
    const accountstatementscolumns = [
        {
            dataField: "objectId",
            text: "",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            hidden: true
        },
        {
            dataField: "primaryValue",
            text: "Account",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            sortFunc: (a, b, order, dataField) => {
                let varA = getAccountDisplayName(selectedaccounts, a, IndLongShort.SINGLEVIEW).toUpperCase();
                let varB = getAccountDisplayName(selectedaccounts, b, IndLongShort.SINGLEVIEW).toUpperCase();
                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order == 'desc') ? (comparison * -1) : comparison
                );// desc
              },
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell);
            }
        },
        {
            dataField: "description",
            text: "Document Name",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "date",
            text: "Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
       

    ];
    return accountstatementscolumns;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function acctFormatter(accounts, cell) {    
    return (<div title={getAccountDisplayName(accounts, cell, IndLongShort.LONG)}>{getAccountDisplayName(accounts, cell, IndLongShort.SINGLEVIEW)}</div>)
}