import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { spinner, MsgForNoData, MsgForFailedData, getYearList } from './../../../../../common/constants.jsx';
import { yearDropDownItems, GetAccountColumns } from '../estimatedincomecolumns';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Messages from '../../../../../common/messages';
import Watermark from '../../../../../common/watermark';
export function EstimatedIncomeAccountView(
    props
) {
    let estimatedIncomeAccountView =  (
        <div className="conatiner-fluid RCM_Toast" key="aadetails1">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row RCM_AccountActivityDetails_Message_Div" >
                <div className="col-sm-7">
                    <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                </div>
            </div>
            <div className="row RCM_text_hd">
                <div className="col-sm-4" id="RCM_estimatedincomedetailsfilter">
                    <div className="RCM_text RCM_CM_form_label RCM_text_textdropdown RCM_Inline_Div">
                        <label>YEAR :</label>
                    </div>
                    <div className="RCM_Inline_Div">
                        <Dropdown
                            isOpen={props.state.yearDropdownOpen}
                            toggle={props.toggleYear}
                            className="RCM_select_box RCM_mm_subselect_box dropdown"
                            key="addropdown1">
                            <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                {
                                    props.state.dropdownyearValue
                                }
                            </DropdownToggle>
                            <DropdownMenu >
                                {getYearList('2018').map((item, index) =>
                                    <DropdownItem key={index} onClick={() => props.changeYear(item)} disabled={props.state.isProspectClient}>
                                        <div>{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-sm-8"></div>
            </div>
            <div className="row" id="RCM_estimatedincomeaccountview">
                <div className="col-sm-12 col-sm-12 col-md-12 col-xs-12 col-lg-12">
            {
                (() => {
                    if (props.state.requestFailed) {
                        return (<div className="row" id="RCM_AccountHoldingTable">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_Realized_SecurityHolding_Wrapper">
                            <BootstrapTable key='btaccount'
                                keyField='ACCTKEY'
                                data={[]}
                                classes="RCM_two_level_table1"
                                    columns={GetAccountColumns()}
                                expandRow={props.expandRowByAccount}
                                noDataIndication={MsgForFailedData}
                                bordered={false}
                        />
                    </div></div>)

                    }
                    else if (props.state.isloading) {
                        return <div className="RCM_spinner">Loading Historical Income By Account{spinner()}</div>
                    }
                    else {
                        if (props.state.estimatedincomebyaccount && Array.isArray(props.state.estimatedincomebyaccount) && props.state.estimatedincomebyaccount.length > 0 && !props.state.isloading) {
                            return (
                                <div className="row" id="RCM_AccountHoldingTable">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_Realized_SecurityHolding_Wrapper">
                                        <BootstrapTable key='btaccount'
                                            keyField='ACCTKEY'
                                            data={props.accountData}
                                            classes="RCM_two_level_table1"
                                            columns={GetAccountColumns(props.totalordinarydividend, props.totaltaxableincome, props.totalnetincome, props.totalcapitalgainloss, props.accountData, props.state.isrowexpanded, props.accounts, props.rowsortevent)}
                                            expandRow={props.expandRowByAccount}
                                            noDataIndication={MsgForNoData}
                                            bordered={false}
                                        />
                                    </div></div>
                                )
                          
                        }
                        else {
                            return(
                                <div className="row" id="RCM_AccountHoldingTable">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_Realized_SecurityHolding_Wrapper">
                                    <BootstrapTable key='btaccount'
                                        keyField='ACCTKEY'
                                        data={props.accountData}
                                        classes="RCM_two_level_table1"
                                            columns={GetAccountColumns(props.totalordinarydividend, props.totaltaxableincome, props.totalnetincome, props.totalcapitalgainloss, props.accountData, props.state.isrowexpanded, props.accounts,props.rowsortevent)}
                                        expandRow={props.expandRowByAccount}
                                        noDataIndication={MsgForNoData}
                                bordered={false}
                            />
                           </div></div>)
                        }
                    }
                        })()}
                </div>
                </div>
        </div>

    )
    return estimatedIncomeAccountView;
}