import React, { Component } from 'react';
import {billPayUIUrl} from "../../common/constants";
//import AuthService from '../../../services/auth.service';
//import { Get } from '../../common/servicecalls';

export default class BillPay extends Component {
    constructor(props) {
        super(props);
    }

    getBPUrl() {
        //let url = @Environment.GetEnvironmentVariable("rcmcpbillpayapi");
        let url = billPayUIUrl;
        console.log('url: ' + url);
        return url;
    }

    popBillPay() {
        window.open(billPayUIUrl, 'billpay', 'alwaysOnTop=1,titlebar=0,location=0,toolbar=0,status=0,width=1000,height=700')
    }

    componentDidMount()
    {
        /*Get('https://myapps.microsoft.com/signin/FidelityWealthScapeBillPay/31c97283-c205-402d-ba89-cc427f1bfd5f?tenantId=74352aa5-d883-4e4a-8423-7862b342708e')
        .then(res => {
            console.log(res);
            //Ge the response here
        }).catch(err => {
            console.log(err);
            //console log the error
        })

        this.ifr.onload = () => {
            this.ifr.contentWindow.postMessage('put post data object here', "*");
        }
        */

    }

    render() {
        console.log("Inside Billpay render")
        return (<div>
                <br />

            <div className="RCM_PdfViewer_Border">
                <div>
                    {/*<iframe id='RcmBpShim' width="100%" height="100%" style='position: "absolute", left: "0px", top: "0px", zIndex: "100", backgroundColor: "#fff", opacity: "0.5"'></iframe>*/}
                    <a onClick={this.popBillPay}>Click to Open BillPay</a>
                </div>
                <iframe
                        type="text/html"
                        src= {billPayUIUrl}
                        width="100%"
                        height="700px"
                        sandbox="allow-scripts allow-forms"
                    />
                {//ref={(f) => this.ifr = f }

                }
                </div>
            </div>
        );
    }
}
