import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, basicAlphaNumeric, discriptionFormat, viewOnlyText } from '../../../../../common/constants.jsx';
class AssetAccount extends Component {

    constructor(props) {
        super(props);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.typesOfAccountsToggle = this.typesOfAccountsToggle.bind(this);
        this.typesOfAccountsChange = this.typesOfAccountsChange.bind(this);

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            displaytotalvalue: '',
            activeTab: '1',
            intialAccount: {},
            formStateChange: false,
            typesOfAccounts: [
                {
                    "assetName": "Cash Account",
                    "assetDisplayName": "Cash Account"
                },
                {
                    "assetName": "Investment Account",
                    "assetDisplayName": "Investment Account"
                },
                {
                    "assetName": "Retirement Account",
                    "assetDisplayName": "Retirement Account"
                },
                {
                    "assetName": "Education and Savings Account",
                    "assetDisplayName": "Education and Savings Account"
                },
                {
                    "assetName": "Trust Account",
                    "assetDisplayName": "Trust Account"
                },
                {
                    "assetName": "Other Asset",
                    "assetDisplayName": "Other Asset"
                },
                {
                    "assetName": "Fund of Hedge Funds",
                    "assetDisplayName": "Fund of Hedge Funds"
                }

            ],
            isTypesOfAccountsOpen: false,
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: '',
                    description: ''
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date()
                }
            }

        };

    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displaytotalvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({ account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue });
        } else if (this.props.mode === 'add') {
            var account = this.state.account;
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            if (this.props.source === "Other Asset" || this.props.source === 'Fund of Hedge Funds') {
                account.info.type = this.props.source;
                initailAccount.info.type = this.props.source;
                this.setState({ intialAccount: initailAccount, account: account });
            } else {
                this.setState({ intialAccount: initailAccount });
            }

        }
    }
    componentDidUpdate(prevProps) {

        if (prevProps.source !== this.props.source) {


            if (this.props.mode === 'edit' && this.props.account) {
                var displaytotalvalue = "";
                if (this.props.account.value.totalvalue !== "")
                    displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var initailAccount = JSON.parse(JSON.stringify(this.props.account));
                this.setState({ account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue });
            } else if (this.props.mode === 'add') {
                var account = {
                    profid: this.props.profileId,
                    accountkey: '',
                    info: {
                        custodianname: '',
                        accountnumber: '',
                        nickname: '',
                        type: '',
                        description: ''
                    },
                    value: {
                        totalvalue: '',
                        valueasof: new Date()
                    }
                };
                var initailAccount = JSON.parse(JSON.stringify(account));
                if (this.props.source === "Other Asset" || this.props.source === 'Hedge Funds' || this.props.source === 'Fund of Hedge Funds') {
                    account.info.type = this.props.source;
                    initailAccount.info.type = this.props.source;
                }
                this.setState({ intialAccount: initailAccount, account: account, displaytotalvalue: '' });

            }
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    typesOfAccountsToggle() {
        this.setState(prevState => ({
            isTypesOfAccountsOpen: !prevState.isTypesOfAccountsOpen
        }))
    }
    typesOfAccountsChange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.info.type = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleFormChanges(evt) {

        if (evt.target.name === "custodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.custodianname = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.nickname = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "accountnumber" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.accountnumber = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            let clonedState = Object.assign({}, this.state);
            clonedState.displaytotalvalue = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.description = evt.target.value;
            this.setState(clonedState);
        }
        this.checkStateChange();
    }

    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.totalvalue = evt.target.value;
            clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.totalvalue = evt.target.value;
            this.setState(clonedState);
        }
        this.checkStateChange();
    }

    handleAmmountOnFocus(event) {
        const clonedState = Object.assign({}, this.state);
        if (clonedState.account.value.totalvalue === "") {
            clonedState.displaytotalvalue = "";
            clonedState.account.value.totalvalue = "";
        } else {
            clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
        }
        this.setState(clonedState);
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }

    render() {
        return (
            this.props.mode && this.props.mode !== "view" ?
                <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container RCM_asset_form_container" id="RCM_AssetAccounts_form">
                    <div>
                        <Form>
                            <fieldset>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                        <FormGroup controlId="formGridTotal Value" id="RCM_Type_Of_Account">
                                            <Label for="type">Type Of Account</Label>
                                            <Dropdown name="type" required
                                                isOpen={this.state.isTypesOfAccountsOpen}
                                                toggle={this.typesOfAccountsToggle}
                                                className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                <DropdownToggle caret>{this.state.account.info.type}</DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        <div>
                                                            {this.state.typesOfAccounts.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.typesOfAccountsChange(item.assetName)}>
                                                                    <div>{item.assetDisplayName}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    }
                                                </DropdownMenu>
                                            </Dropdown>
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                        <FormGroup controlId="formGridTotal Value" id="RCM_custodianname">
                                            <Label for="custodianname">Custodian Name</Label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="custodianname"
                                                className="RCM_form_control"
                                                required
                                                value={this.state.account.info.custodianname}
                                                onChange={this.handleFormChanges}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                        <FormGroup controlId="formGridAccount" id="RCM_accountNumber">
                                            <Label for="accountnumber">Account Number</Label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="accountnumber"
                                                className="RCM_form_control"
                                                required
                                                value={this.state.account.info.accountnumber}
                                                onChange={this.handleFormChanges}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                        <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                            <Label for="nickname">Nickname</Label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="nickname"
                                                className="RCM_form_control"
                                                required
                                                value={this.state.account.info.nickname}
                                                onChange={this.handleFormChanges}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                        <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                            <Label for="totalvalue">Total Value</Label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder="$"
                                                name="totalvalue"
                                                className="RCM_form_control"
                                                required
                                                value={this.state.displaytotalvalue}
                                                onChange={this.handleFormChanges}
                                                onBlur={this.handleAmountFormatting}
                                                onFocus={this.handleAmmountOnFocus}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                        <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                            <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                            <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                name="valueasof" id="valueasof"
                                                value={new Date(this.state.account.value.valueasof)}
                                                onChange={this.handleValueAsOfChange}
                                                selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                    && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                maxDate={new Date()}
                                                placeholderText="Click to select a date"
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                        <div><Label for="desc" >Description</Label></div>
                                        <textarea
                                            name="description"
                                            placeholder=""
                                            className="RCM_form_control RCM_Feedback_TextArea"
                                            value={this.state.account.info.description}
                                            onChange={this.handleFormChanges}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <div className="row">

                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                    <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                    <button type="button"
                                        className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                        onClick={this.saveAsset}
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                            {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                                ?
                                < div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                        {viewOnlyText}
                                    </div>
                                </div>
                                : ""
                            }
                        </Form>

                    </div>

                </div>

                :
                <div>

                    <div className="row">
                        <div className="col-sm-4">
                            <Label for="custodianname">Custodian Name : </Label>&nbsp;&nbsp;
                                    <Label>abcd</Label>
                        </div>
                        <div className="col-sm-4">
                            <Label for="nickname">Nick Name : </Label>&nbsp;&nbsp;
                                <Label>name123</Label>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <Label for="totalvalue">Total Value : </Label> &nbsp;  &nbsp;
                            <Label for="totalvalue">$300.00</Label>
                        </div>
                        <div className="col-sm-4">
                            <Label for="valueasof">Value as of : </Label> &nbsp; &nbsp;
                            <Label for="totalvalue">10/21/2019</Label>
                        </div>

                    </div>

                </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(AssetAccount);