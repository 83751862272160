import React, { Component } from 'react';

export default class PdfViewer extends Component {

    constructor(props) {
        super(props);        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.pdfData === nextProps.pdfData) {
            return false;
        } else {
            return true;
        }
    }

    getPdfUrl() {
        //below one will be the actual data received from webapi
        if (this.props.pdfData) {
            var base64str = this.props.pdfData;
            // decode base64 string, remove space for IE compatibility
            var binary = atob(base64str.replace(/\s/g, ""));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }            
            // create the blob object with content-type "application/pdf"
            var blob = new Blob([view], { type: "application/pdf" });           
            var url = URL.createObjectURL(blob);
            console.log('url: ' + url);
            return url;
        }
    }

    render() {        
        return (           
            <div className="RCM_PdfViewer_Border">               
                <iframe
                    type="application/pdf"
                    src={this.getPdfUrl()}
                    width="100%"
                    height="700px"                                    
                />
            </div>

        );
    }
}

