import React, { Component } from 'react';
import { Switch, Route, withRouter, BrowserRouter } from 'react-router-dom';
import { BalancesdetailURL, GetAccountsList, getAccountDisplayName, IndLongShort, CheckIfProspectClient, GetPreferencePilotValue, identifyMarginAccount } from '../../../../common/constants.jsx';
import { Get, Guid } from '../../../../common/servicecalls.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddBalanceDetails } from '../../../../actions/accountsactions.jsx';
import { Balancesdetailsview } from './balancesdetailsview.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import axios from 'axios';
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { SetHoldingAccount } from './../../../../actions/accountsactions';
import { balancedetailsDummyResponse, RRR_123456_balance_details, RRR_234567_balance_details } from './../../accountsmockresponse.js';
import Toptendetail from './../topten/toptendetails.jsx';
class Balancesdetails extends Component {

    constructor(props) {
        super(props);
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            requestFailed: false,
            error: null,
            balancestileresponse: this.props.balancedetails,
            accounts: this.accounts,
            render: this.props.render,
            isloading: true,
            name: this.props.name,
            areDataRetrieved: false,
            isProspectClient: CheckIfProspectClient(this.props.role),
            BalSummaryV2PilotFlag: this.SetBalanceSummaryV2Flag()
        }
        this.execute = this.execute.bind(this);
        this.acctFormatter = this.acctFormatter.bind(this);
        this.redirecttoholdings = this.redirecttoholdings.bind(this);

    }

    SetBalanceSummaryV2Flag() {
        let obj = {
            preferences: this.props.preferences
        }
        return GetPreferencePilotValue(obj, 'BalSummaryV2', 'false') === 'true' ? true : false;
    }

    balDetailscanellationToken = null;
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false, areDataRetrieved: false }));

        var baseUrl = BalancesdetailURL + "&k2=" + Guid();
        if (this.balDetailscanellationToken) {
            this.balDetailscanellationToken.cancel('Operation canceled due to new request.')
        }
        this.balDetailscanellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.balDetailscanellationToken)
            .then(result => {
                this.props.AddBalanceDetails(result.data);
                this.setState(() => ({ balancestileresponse: result.data, isloading: false, areDataRetrieved: true }))
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailed: true, error: error.message }))
                }
            })

    };


    componentDidMount() {

        if (this.props.balancedetails === null) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            } else {
                this.execute();
            }
        }
        else {
            this.setState({ isloading: false, areDataRetrieved: true });
        }
    }
    componentWillUnmount() {
        if (this.balDetailscanellationToken !== null && this.balDetailscanellationToken !== undefined) {
            this.balDetailscanellationToken.cancel('Cancelling the Account Details during unmount to prevent memory leak.');
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            } else {
                this.execute();
            }
            //this.setState({
            //   isloading: false, areDataRetrieved: true
            //});
        }
    }
    customCaretSort(order, column) {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }

    acctFormatter(accountlist, cell) {
        return (<div title={getAccountDisplayName(accountlist, cell, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, cell, IndLongShort.SINGLEVIEW)}</div>)
    }
    SetDummayResponseForProspectClient() {
        if (this.props.selectedaccount.length === 1) {
            if (this.props.selectedaccount[0].key === "RRR-123456") {
                this.setState(() => ({ balancestileresponse: RRR_123456_balance_details, isloading: false, areDataRetrieved: true }), () => { this.props.AddBalanceDetails(RRR_123456_balance_details); });
            }
            else if (this.props.selectedaccount[0].key === "RRR-234567") {
                this.setState(() => ({ balancestileresponse: RRR_234567_balance_details, isloading: false, areDataRetrieved: true }), () => { this.props.AddBalanceDetails(RRR_234567_balance_details); });
            }
        } else if (this.props.selectedaccount.length === 2) {
            this.setState(() => ({ balancestileresponse: balancedetailsDummyResponse, isloading: false, areDataRetrieved: true }))
        }

    }
    redirecttoholdings(accountnumber) {

        let filteraccount = this.props.selectedaccount.find(acct => acct.key === accountnumber)
        let arr = [];
        arr.push(filteraccount);
        if (this.state.isProspectClient) {
            document.dispatchEvent(new CustomEvent('goToAccountHoldingDetails', { bubbles: true }));
            this.props.SetHoldingAccount(arr);
        }
        else {
            document.dispatchEvent(new CustomEvent('goToAccountHoldingDetails', { bubbles: true }));
            this.props.SetHoldingAccount(arr);
        }
    }
   
    render() {

        let pricedinvestments = null;
        let cashmoneyaccounts = null;
        let pendingactivity = null;
        let totalaccountvalue = null;
        let marginbalance = null;
        let totalfixedincomeaccruedinterest = null;
        let outstandingbalance = null;
        let totaloutstandingbalance = null;
        let insuranceandannuity = null;
        let totalcreditdebit = null;
        let totalmarginandoutstandingbalance = null;
        var rows = [];
        if (this.state.balancestileresponse && this.state.balancestileresponse.value && this.state.balancestileresponse.value.length > 0 && this.state.balancestileresponse.value[0] && this.state.balancestileresponse.value[0].accountbalances) {
            this.state.balancestileresponse.value[0].accountbalances.map((d, i) => rows.push(
                {
                    name: d.accountaumber,
                    availablewithdraw: (d.availablewithdraw),
                    key: d.key,
                    priced: (d.pricedinvestments),
                    cash: (d.cashmoneyaccounts),
                    margin: (d.marginbalance),
                    pending: (d.pendingactivity),
                    value: (d.totalaccountvalue),
                    fixedincomeaccruedinterest: (d.fixedincomeaccruedinterest),
                    outstandingbalance: (d.outstandingbalance),
                    annuity: (d.annuity),
                    marginintrate: d.marginintrate,
                    cashmarginavailabletowithdraw: d.cashmarginavailabletowithdraw,
                    cashmarginbuyingpower: d.cashmarginbuyingpower,
                    nonmarginbuyingpower: d.nonmarginbuyingpower,
                    accountnickname: getAccountDisplayName(this.props.selectedaccount, d.key, IndLongShort.SINGLEVIEW),
                    asofdate: d.asofdate,
                    othercreditdebit: d.othercreditdebit,
                    marginandoutstandingbalance: d.marginandoutstandingbalance,
                    isMargin: identifyMarginAccount(this.props.selectedaccount, d.key),
                    moneyaccountvalue: (d.moneyaccountvalue),
                    cashbalance: (d.cashbalance)
                }
            ));
            insuranceandannuity = this.state.balancestileresponse.value[0].annuity;
            pricedinvestments = this.state.balancestileresponse.value[0].pricedinvestments;
            cashmoneyaccounts = this.state.balancestileresponse.value[0].cashmoneyaccounts;
            marginbalance = this.state.balancestileresponse.value[0].marginbalance;
            pendingactivity = this.state.balancestileresponse.value[0].pendingactivity;
            totalaccountvalue = this.state.balancestileresponse.value[0].totalaccountvalue;
            totalfixedincomeaccruedinterest = this.state.balancestileresponse.value[0].fixedincomeaccruedinterest;
            totaloutstandingbalance = this.state.balancestileresponse.value[0].outstandingbalance;
            totalcreditdebit = this.state.balancestileresponse.value[0].othercreditdebit;
            totalmarginandoutstandingbalance = this.state.balancestileresponse.value[0].marginandoutstandingbalance;
        }

        return (
            <ErrorBoundary>
                <Balancesdetailsview
                    state={this.state}
                    rows={rows}
                    toggleDropdown={this.toggleDropdown}
                    changeAccount={this.changeAccount}
                    accounts={this.props.selectedaccount}
                    selectedAccount={this.props.selectedaccount}
                    insuranceandannuity={insuranceandannuity}
                    pricedinvestments={pricedinvestments}
                    cashmoneyaccounts={cashmoneyaccounts}
                    marginbalance={marginbalance}
                    pendingactivity={pendingactivity}
                    totalaccountvalue={totalaccountvalue}
                    totalfixedincomeaccruedinterest={totalfixedincomeaccruedinterest}
                    outstandingbalance={outstandingbalance}
                    totaloutstandingbalance={totaloutstandingbalance}
                    totalcreditdebit={totalcreditdebit}
                    totalmarginandoutstandingbalance={totalmarginandoutstandingbalance}
                    redirecttoholdings={this.redirecttoholdings}
                />

                <BrowserRouter>
                    <Switch>
                        <Route
                            key="Toptendetail"
                            exact
                            path="/Toptendetail"
                            component={Toptendetail}
                        />
                       

                    </Switch>
                </BrowserRouter>
            </ErrorBoundary>
        )
    }
}


const mapStateToProps = state => {
    return {
        balancessummary: state.acct.balancessummary,
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        balancedetails: state.acct.balancedetails,
        name: state.acct.name,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        role: state.acct.role,
        preferences: state.acct.preferences
    };
};

const mapDispatchToProps = {
    AddBalanceDetails,
    SetHoldingAccount
};

const balancedetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Balances Detail' })
)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Balancesdetails));
