export const LAST30DAYS = "Last30Days";
export const LAST90DAYS = "Last90Days";
export const LAST5DAYS = "Last5Days";
export const PAST5DAYS = "Prior5Days";
export const SELECTRANGE = "Select Range";

export const dateFilterDateRange = [
    {
        "label": "Select Range",
        "value": SELECTRANGE,
        "number" : 30
    },
    {
        "label": "Last 5 Days",
        "value": LAST5DAYS,
        "number": 5
    },
    {
        "label": "Last 30 Days",
        "value": LAST30DAYS,
        "number": 30
    },
    {
        "label": "Last 90 Days",
        "value": LAST90DAYS,
        "number": 90
    }
];


export function getNumericDate(dateValue) {

    let dateRange = 30;

    if (dateValue === PAST5DAYS) {
        dateRange = 5;
    } else {

        for (let item of dateFilterDateRange) {
            if (item.value === dateValue) {
                dateRange = item.number;
                break;
            }
        }
    }
    return dateRange;
}