import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { RealizedGainLossURL, spinner, GetAccountsList, MsgForNoData, CheckIfProspectClient } from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddRealizedgainlossByAccount } from '../../../../../actions/toptenactions.jsx';
import { compose } from 'redux';
import { accountchildcolumns, renderRowsByAccount, renderRowsForAccount } from '../realizedgainlosscolumns.jsx';
import axios from "axios";
import { AccountHoldingsView } from './realizedaccountholdingview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import TASHoldingdetails from '../realizedtasholding.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { realizedgainlossaccountdummyresponse, RRR_123456_realizedgainlossaccountresponse, RRR_234567_realizedgainlossaccountresponse } from '../../../accountsmockresponse.js';
import Watermark from '../../../../../common/watermark.jsx';
class AccountHoldingdetails extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            realizedgainlossbyaccount: this.props.realizedgainlossbyaccount,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            dropdownOpen: false,
            dropdownValue: 'All Accounts',
            dropdownDispValue: 'All Accounts',
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
            monthDropdownOpen: false,
            monthDropdownValue: 'All',
            dropdownyearValue: new Date().getFullYear(),
            totalgainloss: '',
            totalgainlosspct: '',
            rowdata: [],
            headername: '',
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggle = this.toggle.bind(this);
        this.changeYear = this.changeYear.bind(this);
      //  this.monthDropdownToggle = this.monthDropdownToggle.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
    }
    getAccountHoldingsCancellationToken = null;
    getAccountHoldingsAccountChangeCancellationToken = null;
    //method to execute api calls
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];
        //const baseUrlAccount = HoldingsbyaccountURL + '\'' + "" + '\',\'' + null + '\')';
        if (this.getAccountHoldingsCancellationToken) {
            //Cancel the existing call
            this.getAccountHoldingsCancellationToken.cancel('Cancelling the existing Account Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        let selectorparam = 'A';
        const baseUrlSecurity = RealizedGainLossURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ', \'Y\', \'\',\'\' )';
        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getAccountHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data.value && Array.isArray(result.data.value) ? result.data.value : [];
                this.props.AddRealizedgainlossByAccount(data);
                this.setState(() => ({ realizedgainlossbyaccount: data, isloading: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                }
            })
    };

    componentDidMount() {
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.realizedgainlossbyaccount == null) {
                this.execute();

            }
            else {
                this.setState({ isloading: false });
            }
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }
        }
    }
    //changeMonth(selectedmonth) {
    //    this.setState({ monthDropdownValue: selectedmonth.label });
    //    this.execute(this.accounts);
    //}
    changeYear(selectedyear) {
        this.setState({ dropdownyearValue: selectedyear.label }, () => { this.execute()});
       
    }
    //monthDropdownToggle() {
    //    this.setState(prevState => ({
    //        monthDropdownOpen: !prevState.monthDropdownOpen
    //    }));
    //}

    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }
    componentWillUnmount() {
        if (this.getAccountHoldingsCancellationToken !== null && this.getAccountHoldingsCancellationToken !== undefined) {
            this.getAccountHoldingsCancellationToken.cancel('Cancelling the Account Holdings during unmount to prevent memory leak.');
        }
        if (this.getAccountHoldingsAccountChangeCancellationToken !== null && this.getAccountHoldingsAccountChangeCancellationToken !== undefined) {
            this.getAccountHoldingsAccountChangeCancellationToken.cancel('Cancelling the Account Holdings during unmount to prevent memory leak.');
        }
    }
    collapserow(row) {
        this.setState(() => ({
            expanded: []
        }));
    }
    //changeAccount(selectedAccount) {

    //    let accountNumber;
    //    if (selectedAccount.number === "All Accounts") {
    //        accountNumber = 'All Accounts';
    //    }
    //    else { accountNumber = selectedAccount.number; }

    //    this.setState(() => ({ isloading: true, requestFailed: false, dropdownValue: accountNumber, dropdownDispValue: selectedAccount.accountdisplayname }));
    //    var accounts = '';
    //    if (accountNumber === 'All Accounts') {

    //        this.setState(() => ({ realizedgainlossbyaccount: this.props.realizedgainlossbyaccount, isloading: false, requestFailed: false }));
    //    }
    //    else {
    //        if (selectedAccount.isInternal === true) {
    //            accounts = accountNumber;
    //        }
    //        else {
    //            accounts = selectedAccount.key;
    //        }
    //        var datarows = [];
    //        if (this.getAccountHoldingsAccountChangeCancellationToken) {
    //            //Cancel the existing call
    //            this.getAccountHoldingsAccountChangeCancellationToken.cancel('Cancelling the existing Account Holdings request to prevent memory leaks.');
    //        }
    //        //Create a new cancellation token
    //        const cancelToken = axios.CancelToken;
    //        this.getAccountHoldingsAccountChangeCancellationToken = cancelToken.source();
    //        const baseUrlAccount = HoldingsbyaccountURL + '\'' + accounts + '\',\'' + null + '\')';
    //        Get(baseUrlAccount, this.props.token, this.props.contextjson, this.props.profilejson, this.getAccountHoldingsAccountChangeCancellationToken)
    //            .then(result => {
    //                const data = result && result.data && result.data != "" ? result.data.value : datarows;
    //                this.setState(() => ({ realizedgainlossbyaccount: data, isloading: false }))
    //            })
    //            .catch(error => {
    //                if (axios.isCancel(error)) {
    //                    console.log(error);
    //                }
    //                else {
    //                    this.setState(() => ({ requestFailed: true, error: error.message }))
    //                }
    //            })

    //    }

    //}
    //GetHeaderName(row) {
    //    if (row) {
    //        let header = row.SECNAME ?row.SECNAME : ""
    //        header += row.SECID ? (" - " + row.SECID) : "";
    //        return header;
    //    }
    //    return "";
    //}
    toggle(row) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            rowdata: row.LOTS,
            totalgainloss: row.GAINLOSS,
            totalgainlosspct: row.GAINLOSSPCT,
            headername:(row.SECNAME ? row.SECNAME : "") + (row.SECID ? (" - " + row.SECID) : "")
        }));
       
    }
    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.ACCTKEY]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.ACCTKEY),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.realizedgainlossbyaccount === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR - 123456") {
                    this.setState(() => ({ expanded: ["RRR-123456"], realizedgainlossbyaccount: (RRR_123456_realizedgainlossaccountresponse && RRR_123456_realizedgainlossaccountresponse.value ? RRR_123456_realizedgainlossaccountresponse.value : []), isloading: false, areDataRetrieved: true }), () => { this.props.AddRealizedgainlossByAccount((RRR_123456_realizedgainlossaccountresponse && RRR_123456_realizedgainlossaccountresponse.value ? RRR_123456_realizedgainlossaccountresponse.value : [])); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ expanded: ["RRR-234567"], realizedgainlossbyaccount: (RRR_234567_realizedgainlossaccountresponse && RRR_234567_realizedgainlossaccountresponse.value ? RRR_234567_realizedgainlossaccountresponse.value : []), isloading: false, areDataRetrieved: true }), () => { this.props.AddRealizedgainlossByAccount((RRR_234567_realizedgainlossaccountresponse && RRR_234567_realizedgainlossaccountresponse.value ? RRR_234567_realizedgainlossaccountresponse.value : [])); });
                }
            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ expanded: ["RRR-123456"], realizedgainlossbyaccount: (realizedgainlossaccountdummyresponse && realizedgainlossaccountdummyresponse.value ? realizedgainlossaccountdummyresponse.value : []), isloading: false, areDataRetrieved: true }), () => { this.props.AddRealizedgainlossByAccount((realizedgainlossaccountdummyresponse && realizedgainlossaccountdummyresponse.value ? realizedgainlossaccountdummyresponse.value : [])); });
            }
        }
        else {
            this.setState({ isloading: false, expanded: ["RRR-123456"], dropdownValue: this.state.accounts[0].number, dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }
    }
    
    //render method
    render() {
        var accountData = [];
        var totalglamount, totalglpct, totalProceeds, totalcostbasis, totalShortTermGL = 0, totalLongTermGL = 0;
        if (this.state.realizedgainlossbyaccount && this.state.realizedgainlossbyaccount.length > 0) {
            if (this.state.realizedgainlossbyaccount[0].ITEMSUMMARY) {
                for (const [, account] of this.state.realizedgainlossbyaccount[0].ITEMSUMMARY.entries()) {
                    let shortAccountAmount = 0;
                    let longAccountAmount = 0;
                   
                    if (account.ACCT !== null) {
                        for (const [, security] of account.DETAILSUMMARY.entries()) {
                            let shortAmount = 0;
                            let longAmount = 0;
                            if (security.DETAIL.length > 0) {
                                for (const [, sales] of security.DETAIL.entries()) {
                                    if (sales.LONGSHORT === "RET-S" || sales.LONGSHORT === "SHORT") {
                                        shortAmount = shortAmount + sales.PROFIT
                                    } else if (sales.LONGSHORT === "RET-L" || sales.LONGSHORT === "LONG") {
                                        longAmount = longAmount + sales.PROFIT
                                    }
                                }
                            }
                            security.SHORTAMOUNT = shortAmount;
                            security.LONGAMOUNT = longAmount;
                            shortAccountAmount = shortAccountAmount + shortAmount;
                            longAccountAmount = longAccountAmount + longAmount;
                        }
                        account.SHORTAMOUNT = shortAccountAmount;
                        account.LONGAMOUNT = longAccountAmount;
                        totalShortTermGL = totalShortTermGL + shortAccountAmount;
                        totalLongTermGL = totalLongTermGL + longAccountAmount;
                    }
                }
                for (var i = 0; i < this.state.realizedgainlossbyaccount[0].ITEMSUMMARY.length; i++) {
                    if (this.state.realizedgainlossbyaccount[0].ITEMSUMMARY[i].ACCT === null) {
                        accountData = [];
                    }
                    else {
                        accountData = renderRowsForAccount(this.state.realizedgainlossbyaccount[0].ITEMSUMMARY, this.props.selectedaccount);
                    }
                }
            }

            totalProceeds = this.state.realizedgainlossbyaccount[0].GRANDSTKBNDAMT;
            totalglamount = this.state.realizedgainlossbyaccount[0].GRANDPROFIT;
            totalglpct = this.state.realizedgainlossbyaccount[0].GRANDPROFITPCT;
            totalcostbasis = this.state.realizedgainlossbyaccount[0].GRANDCOSTBASIS;
        }
        
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for account table
        const expandRowByAccount = {
            renderer: (row) => (
                <div className="row" key="dvexpandaccountrow">
                    <div className="col-sm-12" key="dvexpandaccountcol">
                        <BootstrapTable key="btaccountchild"
                            keyField='SEQID'
                            classes="RCM_two_level_table2 RCM_Headerless_table"
                            data={renderRowsByAccount(row, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                            columns={accountchildcolumns}
                            bordered={false}
                            rowEvents={rowEvents}
                            noDataIndication={MsgForNoData}
                        />
                    </div>
                </div>
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }

                return (<div className="RCM_icon_hand RCM_right_arrow" >
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.ACCTKEY);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };
        return (
            <ErrorBoundary>
                <AccountHoldingsView
                    state={this.state}
                    accountData={accountData}
                    toggle={this.toggleDropdown}
                    changeAccount={this.changeAccount}
                    accounts={this.props.selectedaccount}
                    totalCostbasis={totalcostbasis}
                    totalglamount={totalglamount}
                    totalglpct={totalglpct}
                    totalProceeds={totalProceeds}
                    selectedAccount={this.props.selectedaccount}
                    expandRowByAccount={expandRowByAccount}
                    changeMonth={this.changeMonth}
                    changeYear={this.changeYear}
                    monthDropdownToggle={this.monthDropdownToggle}
                    toggleYear={this.toggleYear}
                    rowsortevent={this.rowsortevent}
                    totalShortTermGL={totalShortTermGL}
                    totalLongTermGL={totalLongTermGL}
                    
                />
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="RCM_holding_details_modal_content"
                    zIndex='9999'
                    modalClassName="RCM_CM_modal_class" centered key='modaltas1'>
                    <ModalHeader toggle={this.toggle} className="RCM_holding_details_modal" key='modaltas2'>
                        <label className="RCM_tile_heading" key='lblholdingheading'>{this.state.headername}</label>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.isProspectClient ? < Watermark /> : ''}
                        <TASHoldingdetails row={this.state.rowdata} totalgainloss={this.state.totalgainloss} totalgainlosspct={this.state.totalgainlosspct}></TASHoldingdetails>
                    </ModalBody>

                </Modal>
            </ErrorBoundary>

        )

    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        realizedgainlossbyaccount: state.topten.realizedgainlossbyaccount,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        role:state.acct.role
    };
};

const mapDispatchToProps = {
    AddRealizedgainlossByAccount
};
const accountholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Account Holdings' })
)
export default accountholdingdetailscomp(AccountHoldingdetails);