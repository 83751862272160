
import React from 'react'
import { getFiImageUrl, isSvgImg } from './ninthWaveUtility'

export default function NinthWaveFiList(props) {
    return (
        <div>
            <div>
                <input type="text" className="RCM_form_control" style={{ width: '95%' }} placeholder="Search for your financial institution" onChange={props.handleSearchFinancialInstitution} value={props.searchKey} />
            </div>
            <div className="RCM_Aggr_Fi_Container mt-2">
                <div className="row">
                    <div className={props.showFiAsList === false ? 'col-sm-11 RCM_Aggr_FiList_Container' : 'col-sm-11'}>
                        {
                            (props.fiList && Array.isArray(props.fiList) && props.fiList.length > 0) ? (
                                props.fiList.map((fi, index) => {
                                    return (
                                        <div className={props.showFiAsList === false ? 'RCM_Aggr_Fi': 'RCM_Aggr_Fi_As_list'} 
                                        onClick={() => props.handleFiSelection(fi)} key={index}
                                        title={fi && fi.uiAssets && fi.uiAssets.loginUrl ? fi.uiAssets.loginUrl : ''}
                                        >
                                           { props.showLogo ? (<div className="">
                                                {
                                                    isSvgImg(fi.uiAssets) ? 
                                                    <div style={{width: '4rem',
                                                        height: '4rem',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '0.2rem',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundImage: `url(${getFiImageUrl(fi.uiAssets)})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundColor: `${fi && fi.uiAssets && fi.uiAssets.backgroundColor ? fi.uiAssets.backgroundColor : '#fff'}`
                                                    }}
                                                    ></div>
                                                    :
                                                    <div className="RCM_Aggr_Fi_Img" 
                                                        style={{backgroundImage: `url(${getFiImageUrl(fi.uiAssets)})`, 
                                                            backgroundColor: `${fi && fi.uiAssets && fi.uiAssets.backgroundColor ? fi.uiAssets.backgroundColor : '#fff'}` 
                                                        }} 
                                                        alt=""></div>
                                                }
                                            </div>) : <div style={{height: '4rem'}}></div>
                                            }
                                            <div className="RCM_Aggr_Fi_Name">
                                                {fi.name}
                                            </div>
                                        </div>
                                    )
                                })
                            ) :
                            ( !props.showOverlay && (
                                <div className="RCM_Aggr_NoFI" align="center">
                                    <div className="RCM_Aggr_Login_logo_wrap">
                                        <img src="https://res.cloudinary.com/ninth-wave/image/upload/f_png,t_wide_0C5460/v1/nwConsentUI/ui_empty" />
                                    </div>
                                    <div className="mt-4">
                                        Your search for <i></i> returned no results, please try a different search.
                                </div>
                                    <div className="mt-4">
                                        <span className="RCM_caseManager_modal_link" onClick={() => props.resetSearchList()}>Back to Institutions List</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="col-sm-1" style={{padding: 0, left: '-2rem'}}>
                         <button id="toogleGridAndList" className={props.showFiAsList === true ? "RCM_left_bar_toggle" : "RCM_expandview" } onClick={() => props.toogleFiGridListView(props.showFiAsList)} title="Change your financial institution view"></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
