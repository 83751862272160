import React, { PureComponent } from "react";
import groupBy from "lodash.groupby";
import { MsgForNoData } from "../../../../common/constants";
import { getAssetColorConfig } from './../../../../common/assetschemautilities.jsx'
import HistoricalAssetAllocationAreaHighChart from "../../../../common/HighCharts/HistoricalAssetAllocationAreaHighChart";
import HistoricalAssetAllocationBarHighChart from "../../../../common/HighCharts/HistoricalAssetAllocationBarHighChart";

export class HistoricalAssetAllocationChartComponent extends PureComponent {
    static parseDataForChart(data, groupByAssetSubType = false, assetschemacolorsconfig, assetSchema) {
        const categoryProperty = groupByAssetSubType ? "assetSubType" : "assetType";
        const mappedData = data.map(x => (
            {
                value: parseFloat(x.marketValue) || 0,
                category: x[categoryProperty] || "UNCATEGORIZED",
                legend: new Date(x.calendarYear, x.month - 1).getTime(),
                asOfDate: new Date(x.asOfDate),
                assetcatcd: x.code
            }));
        for (var i = 0; i < mappedData.length; i++) {
            if (mappedData[i].category !== null) {
                let assetObj = getAssetColorConfig(mappedData[i].category, mappedData[i].assetcatcd, assetschemacolorsconfig, assetSchema)
                mappedData[i].colorCd = assetObj.colorcd;
                mappedData[i].displaySeq = assetObj.displayseq;
            }

        }
        if (mappedData && mappedData.length > 0) {
            mappedData.sort(function (a, b) { return a.displaySeq - b.displaySeq; });
        }
        const groupedByCategory = groupBy(mappedData, item => {
            return item.category;
        });
        const chartCategories = Object.keys(groupedByCategory)
            .sort((a, b) => {
                return a.displaySeq - b.displaySeq;
            })
            // only 11 colors
            // TOOD - lump everything else into OTHER?
            .slice(0, 11);

        const groupedByLegend = groupBy(mappedData, item => item.legend);

        const chartData = Object.keys(groupedByLegend)
            .map(legendGroupKey => {
                const legendGroupItems = groupedByLegend[legendGroupKey];
                const groupedByCategory = groupBy(legendGroupItems, x => x.category);
                const categoriesAsProps = chartCategories.reduce(
                    (a, x) => {
                        const categoryItems = groupedByCategory[x] || [];
                        a[x] = categoryItems.reduce((a, x) => a + x.value, 0);
                        a.TOTAL = a.TOTAL + a[x];
                        return a;
                    },
                    { TOTAL: 0 }
                );
                return {
                    legend: legendGroupKey,
                    date: new Date(parseInt(legendGroupKey)),
                    asOfDate: legendGroupItems[0].asOfDate,
                    ...categoriesAsProps
                };
            })
            .sort((a, b) => {
                return a.legend - b.legend;
            });
        const groupedByColors = groupBy(mappedData, item => {
            return item.colorCd;
        });
        const chartColors = Object.keys(groupedByColors)
            .sort((a, b) => {
                return a.displaySeq - b.displaySeq;
            })
            // only 11 colors
            // TOOD - lump everything else into OTHER?
            .slice(0, 11);
        return {
            chartData,
            chartCategories,
            chartColors
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { data } = props;
        if (!data || !data.length) {
            return {
                chartData: [],
                chartCategories: [],
                chartColors:[]
            };
        }

        return {
            ...HistoricalAssetAllocationChartComponent.parseDataForChart(
                data,
                props.groupByAssetSubType || false,props.assetschemacolorsconfig, props.assetSchema
            )
        };
    }

    colors;
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
            chartCategories: [],
            chartColors:[]
        };

        this.colors = [
            "#13A5B0",
            "#B3E3F4",
            "#7BC46C",
            "#C4E1B2",
            "#B71F3A",
            "#EF4264",
            "#653C93",
            "#B02C91",
            "#F89938",
            "#F4B93F",
            "#45bda9"
        ];
    }

    render() {
        const { chartType } = this.props;
        const { chartData, chartCategories, chartColors} = this.state;

        if (!chartData.length) {
            return (
                <div>
                    <p className="RCM_data_error">{MsgForNoData}</p>
                </div>
            );
        }

        return (
            <div>
                <div>
                    {chartType === "area" ? (
                        <HistoricalAssetAllocationAreaHighChart 
                            data={chartData} 
                            colors={this.colors} 
                            />
                    ) : (
                            <HistoricalAssetAllocationBarHighChart 
                               data={chartData}
                               colors={chartColors}
                               categories={chartCategories}
                            />
                        )}
                </div>
            </div>
        );
    }
}
