import React from 'react';
import { printDocument } from './../../../common/printdocument.jsx';
import { downloadDocument } from './../../../common/downloaddocument.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { DocumentURL, FileType, getAccountDisplayName, IndLongShort } from './../../../common/constants.jsx';
import axios from 'axios';
export function getTaxDocumentModalColumns(authprops, selectedaccounts) {
    const taxdocumentcolumns = [
        {
            dataField: "objectId",
            text: "",
            sort: true,
            hidden: true
        },
        {
            dataField: "primaryValue",
            text: "Account",
            sort: true,
            sortFunc: (a, b, order, dataField) => {
                let varA = getAccountDisplayName(selectedaccounts, a, IndLongShort.SINGLEVIEW).toUpperCase();
                let varB = getAccountDisplayName(selectedaccounts, b, IndLongShort.SINGLEVIEW).toUpperCase();
                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order == 'desc') ? (comparison * -1) : comparison
                );// desc
              },
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell);
            }
        },
        {
            dataField: "description",
            text: "Document Type",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "corrected",
            text: "Corrected",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "date",
            text: "Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {
            dataField: "streamid",
            text: "",
            sort: true,
            hidden: true
        }

    ];
    return taxdocumentcolumns;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}



function acctFormatter(accounts, cell) {
    return (<div title={getAccountDisplayName(accounts, cell, IndLongShort.LONG)}>{getAccountDisplayName(accounts, cell, IndLongShort.SINGLEVIEW)}</div>)
}