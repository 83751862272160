import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReportsView } from './reportsview.jsx';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { getReportsService, downloadReportService } from './reportsService.jsx';
import { MessageType, MessageClass } from '../../../common/constants.jsx';
import { downloadDocument } from '../../../common/downloaddocument.jsx';
import { printDocument } from './../../../common/printdocument.jsx';

class Reports extends Component {

    constructor(props) {
        super(props);
        this.requesterId = "";
        if (this.props.profileId !== null && this.props.profileId !== undefined) {
            this.requesterId = this.props.profileId;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            reportResponse: [],
            isloadingReportFailed: false,
            isloadingReport: false,
            isDownloadingReportFailed: false,
            isDownloadingReport: false,
            isPrintReportFailed: false,
            isPrintReport: false,
            isDocumentListResponseWithError: false,
            areDocumentsRetrieved: false,
            pdfUrl: '',
            selectedReport: [],
            reportData: [],
            errorMsgs: [],
            pdfData: null
        }
        this.previewDocument = this.previewDocument.bind(this);
        this.handlePortalClick - this.handlePortalClick.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
    }

    getReportList = () => {
        this.setState({ areDocumentsRetrieved: false, pdfUrl: '', selectedReport: [] }, () => {
            getReportsService(this.authToken, this.contextjson, this.profilejson, this.requesterId)
                .then(res => {
                    console.log(res)
                    let data = [];
                    data = res && Array.isArray(res) ? res : [];
                    this.setState({ reportResponse: data, areDocumentsRetrieved: true, isDocumentListResponseWithError: false });
                })
                .catch(() => {
                    this.setState({ areDocumentsRetrieved: true, isDocumentListResponseWithError: true })
                })
        })
    }
   
    componentDidMount() {
        this.getReportList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            this.getReportList();
        }
    }

    handlePortalClick(row) {
        window.open(row.webUrl);
    }

    previewDocument(row) {
        if (this.state.selectedReport && this.state.selectedReport.length > 0) {
            if (this.state.selectedReport[0] !== row.id) {
                this.selectDocument(row);
            }
        }
        else {
            this.selectDocument(row);
        }

    }

    downloadReport = (row, source) => {
        this.setState({
            isDownloadingReportFailed: false,
            isDownloadingReport: true,
            isPrintReport: false,
            isPrintReportFailed: false,
        }, () => {
            downloadReportService(this.authToken, this.contextjson, this.profilejson, row.id, row.name)
                .then(res => {
                    let errosMsgs = [];
                    let isDownloadingReportFailed = false;
                    let fileContent = null;
                    if (res.status === 'Failed') {
                        errosMsgs = [{
                            type: MessageType.INFO,
                            text: res.message,
                            class: MessageClass.ERROR
                        }];
                        isDownloadingReportFailed = true;
                    } else if (res.status === 'Success' && res.base64contents && res.base64contents !== null) {
                        fileContent = res.base64contents;
                        if (source === 'download') {
                            downloadDocument(fileContent, null, row.name);
                        }
                        else {
                            printDocument(fileContent);
                        }
                    }

                    this.setState({ isDownloadingReport: false, isDownloadingReportFailed: isDownloadingReportFailed, isloadingReport: false, isloadingReportFailed: isDownloadingReportFailed, errorMsgs: errosMsgs, pdfData: fileContent })
                })
                .catch(() => {
                    this.setState({ isDownloadingReport: false, isDownloadingReportFailed: true })
                })
        });

    }

    selectDocument = (row) => {
        this.setState({
            pdfData: null,
            isloadingReport: true,
            isloadingReportFailed: false,
            selectedReport: [row.id]
        }, () => {
            downloadReportService(this.authToken, this.contextjson, this.profilejson, row.id, row.name)
                .then(res => {
                    let errosMsgs = [];
                    let isloadingReportFailed = false;
                    let pdfData = null;
                    if (res.status === 'Failed') {
                        errosMsgs = [{
                            type: MessageType.INFO,
                            text: res.message,
                            class: MessageClass.ERROR
                        }];
                        isloadingReportFailed = true;
                    } else {
                        pdfData = res.status === 'Success' && res.base64contents && res.base64contents !== null ? res.base64contents : null;
                    }
                    this.setState({ isloadingReport: false, isloadingReportFailed: isloadingReportFailed, errorMsgs: errosMsgs, pdfData: pdfData })
                })
                .catch(() => {
                    this.setState({ isloadingReport: false, isloadingReportFailed: true })
                })
        });
    }

    checkIfReportNameinFormat = (name) => {

        if (name && name != '') {
            if (name.includes('-')) {
                let datestr = name.split('.')[0].substr(name.split('.')[0].length - 10);
                if (datestr.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else return false;
        }
        else return false;

    }
    render() {
        let folderList = [];
        if (this.state.reportResponse && this.state.reportResponse.length > 0) {
            for (const [, folder] of this.state.reportResponse.entries()) {
                let reports = folder.reportlist;
                var reportsData = [];
                var i;
                for (i = 0; i < reports.length; i++) {
                    if (reports.id !== null) {

                        reportsData.push(
                            {
                                id: reports[i].docid,
                                name: reports[i].docname.split('.')[0].substr(0, reports[i].docname.split('.')[0].length - 11),
                                date: reports[i].docname.split('.')[0].substr(reports[i].docname.split('.')[0].length - 10)
                            }
                        )
                    }

                }
                let folderData = { name: folder.foldername, reportlist: reportsData };
                folderList.push(folderData);
            }
        }
        
        return (
            <ErrorBoundary>
                <ReportsView state={this.state}
                    role={this.props.role}
                    previewDocument={this.previewDocument}
                    handlePortalClick={this.handlePortalClick}
                    downloadReport={this.downloadReport}
                    data={folderList}
                />
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        profileResp: state.acct.profileResp,
        render: state.acct.render,
        token: state.acct.token,
        role: state.acct.role,
        profileId: state.acct.profileId,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};
const enhancement = compose(
    connect(mapStateToProps),
    withPageView({ pageName: 'Reports' })
)

export default enhancement(Reports);