import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MsgForNoData} from '../../../common/constants.jsx';

export function ConfirmEmailChangePopup(
    props
) {
    let confirmEmailChangePopup = (
        <Modal isOpen={props.isOpen} toggle={() => props.toggleConfirmEmailChangePopup()} key="confirmAlerts01"
            backdrop={false}
            size='md'
            zIndex='9999'
            className="RCM_CM_modal"
            wrapClassName="RCM_CM_modal_wrapper"
            modalClassName="RCM_CM_modal_class"
            contentClassName="RCM_CM_modal_content"
            centered>
            {props ?
                <div key= "confirmAlerts02">
                    <ModalHeader toggle={() => props.toggleConfirmEmailChangePopup()} charCode="X" key="confirmAlerts02">
                        <label className="RCM_tile_heading" key='DisclaimerModelheader' key="confirmAlerts03">EMAIL CHANGE CONFIRMATION</label>
                    </ModalHeader>
                    <ModalBody className="RCM_Manage_alert_popup" key="confirmAlerts04">
                        <div className="RCM_manage_alert_popup_subheading" key="confirmAlerts05">
                            You have pending changes that will be lost. Do you want to continue?
                        </div>

                           
                        <div className="row row-margin" key="confirmAlerts14">
                            <div className="col-sm-12 RCM_alert_cnf_popup_buttons" key="confirmAlerts15">
                                <input className="btn RCM_button_secondary RCM_select_btn" type="submit" value="CANCEL"
                                    onClick={() => props.toggleConfirmEmailChangePopup()} key="confirmAlerts16" />
                                <input className="btn RCM_button_primary RCM_select_btn" type="submit" value="CONFIRM"
                                    onClick={() => props.changeEmail()} key="confirmAlerts17" />
                            </div>
                        </div>

                </ModalBody>
                <ModalFooter>

                    </ModalFooter>
                    </div>
          
                : <div key="confirmAlerts19">{MsgForNoData}</div>

            }
        </Modal>
    )
    return confirmEmailChangePopup
}



