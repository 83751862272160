import React from "react";
import { spinner } from "../../../../common/constants";
import Messages from "../../../../common/messages";

const Beneficiaries = ({ accountInfoState }) => {
  const { accountInfo, isLoading, error } = accountInfoState || {};
  return (
    <>
      {isLoading && (
        <div className="RCM_spinner">
          Loading Account Beneficiaries{spinner()}
        </div>
      )}
      {error && (
        <Messages
          messages={["Unable to fetch beneficiaries information."]}
          containerClass="RCM_validation_error_container"
        />
      )}
      <div className="mt-4">
        {accountInfo &&
          accountInfo.beneficiaries &&
          accountInfo.beneficiaries.map((bene, index) => {
            return (
              <div key={index} style={{ display: "flex", gap: 5 }}>
                <div>{bene.name}</div>
                {bene.percentage !== null && bene.percentage !== undefined && (
                  <div>{` - ${bene.percentage} %`}</div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Beneficiaries;
