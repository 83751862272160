import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { EstimatedIncomeURL,  GetAccountsList, MsgForNoData, CheckIfProspectClient } from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddEstimatedIncomeByAccount, ChangeYearEstimatedIncome, RefreshEstimatedIncomeSummarySecurity } from '../../../../../actions/toptenactions.jsx';
import { compose } from 'redux';
import { accountchildcolumns, renderRowsByAccount, renderRowsForAccount, GetSecurityColumns } from '../estimatedincomecolumns';
import axios from "axios";
import { EstimatedIncomeAccountView } from './estimatedincomeaccountview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
//import TASHoldingdetails from '../realizedtasholding.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { estimatedincomeaccount, RRR_123456_estimatedincomeaccount, RRR_234567_estimatedincomeaccount } from './../../../accountsmockresponse';

class EstimatedIncomeForAccount extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            estimatedincomebyaccount: this.props.estimatedincomebyaccount,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
            dropdownyearValue: this.props.estimatedIncomeYear ? this.props.estimatedIncomeYear : (new Date().getMonth() > 0) ? new Date().getFullYear() : new Date().getFullYear() - 1,
            showTable: false,
             childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        this.changeYear = this.changeYear.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
        this.toggleShowTable = this.toggleShowTable.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
    }
    getAccountHoldingsCancellationToken = null;
    getAccountHoldingsAccountChangeCancellationToken = null;
    //method to execute api calls
    execute = () => {
        this.setState(() => ({ isloading: true,requestFailed: false }));
        var datarows = [];
        let selectorparam = 'A';
        const baseUrlAccount = EstimatedIncomeURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
        if (this.getAccountHoldingsCancellationToken) {
            //Cancel the existing call
            this.getAccountHoldingsCancellationToken.cancel('Cancelling the existing Account Holdings request to prevent memory leaks.');
        } 
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getAccountHoldingsCancellationToken = cancelToken.source(); 
        Get(baseUrlAccount, this.props.token, this.props.contextjson, this.props.profilejson, this.getAccountHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data != "" ? result.data.value : datarows;
                this.props.AddEstimatedIncomeByAccount(data);
                this.props.ChangeYearEstimatedIncome(this.state.dropdownyearValue);
                this.setState(() => ({ estimatedincomebyaccount: data, isloading: false }))
                
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message }));
                }
            })
    };

    componentDidMount() {
       
        console.log(this.props);
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.estimatedincomebyaccount === null)
                this.execute();
            else this.setState({ isloading: false, requestFailed: false });
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }
        }
    }

    changeYear(selectedyear) {
        if (this.state.dropdownyearValue !== selectedyear.label)
            this.setState({ dropdownyearValue: selectedyear.label }, () =>
                this.execute(),
                this.props.RefreshEstimatedIncomeSummarySecurity())
    }

    //toggle1() {
    //    this.setState(prevState => ({
    //        tooltipOpen1: !prevState.tooltipOpen1
    //    }));
    //}
    toggleShowTable() {
        this.setState(prevState => ({ showTable: !prevState.showTable }));
    }
    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }
    componentWillUnmount() {
        if (this.getAccountHoldingsCancellationToken !== null && this.getAccountHoldingsCancellationToken !== undefined) {
            this.getAccountHoldingsCancellationToken.cancel('Cancelling the Account Holdings during unmount to prevent memory leak.');
        }
        if (this.getAccountHoldingsAccountChangeCancellationToken !== null && this.getAccountHoldingsAccountChangeCancellationToken !== undefined) {
            this.getAccountHoldingsAccountChangeCancellationToken.cancel('Cancelling the Account Holdings during unmount to prevent memory leak.');
        }
    }
    //collapserow(row) {
    //    this.setState(() => ({
    //        expanded: []
    //    }));
    //}

    //GetHeaderName() {
    //    if (this.state.rowdata) {
    //        let header = this.state.rowdata.SECNAME ? this.state.rowdata.SECNAME : ""
    //        header += this.state.rowdata.SECID ? (" - " + this.state.rowdata.SECID) : "";
    //        return header;
    //    }
    //    return "";
    //}
    //toggle(row) {
    //    this.setState(prevState => ({
    //        modal: !prevState.modal,

    //    }));
    //    this.state.rowdata = row;
    //}
    //toggleDropdown() {
    //    this.setState(prevState => ({
    //        dropdownOpen: !prevState.dropdownOpen
    //    }));
    //}
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.ACCTKEY]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.ACCTKEY),
                isrowexpanded: this.state.expanded.length>1?true:false
            }));
        }
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.estimatedincomebyaccount === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ estimatedincomebyaccount: (RRR_123456_estimatedincomeaccount && RRR_123456_estimatedincomeaccount.value ? RRR_123456_estimatedincomeaccount.value : []), isloading: false, areDataRetrieved: true, expanded: ["RRR-123456"] }), () => { this.props.AddEstimatedIncomeByAccount((RRR_123456_estimatedincomeaccount && RRR_123456_estimatedincomeaccount.value ? RRR_123456_estimatedincomeaccount.value : [])); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ estimatedincomebyaccount: (RRR_234567_estimatedincomeaccount && RRR_234567_estimatedincomeaccount.value ? RRR_234567_estimatedincomeaccount.value : []), isloading: false, areDataRetrieved: true, expanded: ["RRR-234567"] }), () => { this.props.AddEstimatedIncomeByAccount((RRR_234567_estimatedincomeaccount && RRR_234567_estimatedincomeaccount.value ? RRR_234567_estimatedincomeaccount.value : [])); });
                }
            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ estimatedincomebyaccount: (estimatedincomeaccount && estimatedincomeaccount.value ? estimatedincomeaccount.value : []), isloading: false, areDataRetrieved: true, expanded: ["RRR-123456"] }), () => { this.props.AddEstimatedIncomeByAccount((estimatedincomeaccount && estimatedincomeaccount.value ? estimatedincomeaccount.value : [])); });
            }
        }
        else {
            this.setState({ isloading: false, dropdownValue: this.state.accounts[0].number, expanded: ["RRR-123456", "RRR-234567"], dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }
    }
   
    //render method
    render() {

        var accountData = [];
        var totalordinarydividend;
        var totalqualifieddividend
        var totaltaxableinterest;
        var totalnontaxableinterest;
        var totalnetincome;
        var totalcapitalgainloss;
        var totaltaxableincome;
        if (this.state.estimatedincomebyaccount && this.state.estimatedincomebyaccount.length > 0) {
            if (this.state.estimatedincomebyaccount[0].itemSummary) {
                accountData = renderRowsForAccount(this.state.estimatedincomebyaccount[0].itemSummary, this.props.selectedaccount);
                totalordinarydividend = this.state.estimatedincomebyaccount[0].ODNDividend;
                totalqualifieddividend = this.state.estimatedincomebyaccount[0].QlfDividend;
                totaltaxableinterest = this.state.estimatedincomebyaccount[0].TaxableInterest;
                totalnontaxableinterest = this.state.estimatedincomebyaccount[0].NonTaxaableInterest;
                totalnetincome = this.state.estimatedincomebyaccount[0].NetIncome;
                totalcapitalgainloss = this.state.estimatedincomebyaccount[0].TOTCAPGAIN;
                totaltaxableincome = this.state.estimatedincomebyaccount[0].TotalInt;
            }
        }
      
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for account table
        const expandRowByAccount = {
            renderer: (row) => (
                <div className="row" key="dvexpandaccountrow">
                    <div className="col-sm-12" key="dvexpandaccountcol">
                        <BootstrapTable key="btaccountchild"
                            keyField='UNQID'
                            classes="RCM_two_level_table2 RCM_Headerless_table"
                            data={renderRowsByAccount(row, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                            columns={accountchildcolumns}
                            bordered={false}
                            rowEvents={rowEvents}
                            noDataIndication={MsgForNoData}
                        />
                    </div>
                </div>
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }

                return (<div className="RCM_icon_hand RCM_right_arrow" >
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {
             
                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index,account] of results.entries()) {
                        expandedrows.push(account.ACCTKEY);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded:expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }
           
        };

        return (
            <ErrorBoundary>
                <EstimatedIncomeAccountView
                    state={this.state}
                    showTable={this.state.showTable}
                    accountData={accountData}
                   // toggle={this.toggleDropdown}
                    //changeAccount={this.changeAccount}
                    accounts={this.props.selectedaccount}
                    totalordinarydividend={totalordinarydividend}
                    totalqualifieddividend={totalqualifieddividend}
                    totaltaxableinterest={totaltaxableinterest}
                    totalnontaxableinterest={totalnontaxableinterest}
                    totalnetincome={totalnetincome}
                    totalcapitalgainloss={totalcapitalgainloss}
                    totaltaxableincome = {totaltaxableincome}
                    selectedAccount={this.props.selectedaccount}
                    expandRowByAccount={expandRowByAccount}
                    changeYear={this.changeYear}
                    toggleYear={this.toggleYear}
                    toggleShowTable={this.toggleShowTable}
                    //toggle1={this.toggle1}
                    //chartrows={chartrows}
                    accounts={this.props.selectedaccount}
                    rowsortevent={this.rowsortevent}
            />
            </ErrorBoundary>

        )
        
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        estimatedincomebyaccount: state.topten.estimatedincomebyaccount,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        estimatedIncomeYear: state.topten.estimatedIncomeYear,
        role:state.acct.role
    };
};

const mapDispatchToProps = {
    AddEstimatedIncomeByAccount,
    ChangeYearEstimatedIncome,
    RefreshEstimatedIncomeSummarySecurity
};
const accountholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Account Holdings' })
)
export default accountholdingdetailscomp(EstimatedIncomeForAccount);