import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Form, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, basicAlphaNumeric, discriptionFormat, viewOnlyText, MessageType, MessageClass } from '../../../../../common/constants.jsx';
import Messages from '../../../../../common/messages.jsx';
import classnames from 'classnames';
import { HistoryManualAsset } from './history';
import cloneDeep from 'lodash/cloneDeep';
class HedgeFunds extends Component {

    constructor(props) {
        super(props);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.dropdownChange = this.dropdownChange.bind(this);
        this.handleInitialPriceDateChange = this.handleInitialPriceDateChange.bind(this);
        this.deleteHistory = this.deleteHistory.bind(this);
        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            displaytotalvalue: '',
            displayinitialprice: '',
            activeTab: '1',
            intialAccount: {},
            formStateChange: false,
            typesOfAssets: [
                {
                    "assetName": " ",
                    "assetDisplayName": " "
                },
                {
                    "assetName": "Global Macro",
                    "assetDisplayName": "Global Macro"
                },
                {
                    "assetName": "Managed Futures",
                    "assetDisplayName": "Managed Futures"
                },
                {
                    "assetName": "Fixed Income Relative Value",
                    "assetDisplayName": "Fixed Income Relative Value"
                },
                {
                    "assetName": "Equity Market Neutral",
                    "assetDisplayName": "Equity Market Neutral"
                },
                {
                    "assetName": "Structural Credit",
                    "assetDisplayName": "Structural Credit"
                },
                {
                    "assetName": "Equity Long/Short",
                    "assetDisplayName": "Equity Long/Short"
                },
                {
                    "assetName": "Other",
                    "assetDisplayName": "Other"
                }
            ],
            isTypesOfAssetsOpen: false,
            isPricingFrequencyOpen: false,
            typesOfPricingFrequency: ['Monthly', 'Quarterly', 'Semi – Annually', 'Annually', 'Other'],
            isVolatilityOpen: false,
            typesOfVolatilityRisk: ['High', 'Moderated', 'Low'],
            isRiskOpen: false,
            typesOfAssetClassCategory: [' ', 'Equity', 'Equity large Capitalization', 'Equity Small Capitalization', 'Energy', 'Equity Multi Capitalization',
                'Fixed Income', 'Hedge', 'Private Investment', 'Multi Asset Class', 'Real Asset', 'Real Estate', 'Venture Capital', 'Other'],
            isTypesOfAssetClassCategoryOpen: false,
            formValueChange: false,
            messages: [],
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: "",
                    accountnumber: "",
                    nickname: "",
                    type: "Hedge Funds",
                    description: "",
                    name: null,
                    originator: null,
                    bankname: null,
                    issuername: null,
                    status: null,
                    volatility: "",
                    risk: "",
                    subassettype: ""
                },
                value: {
                    totalvalue: "",
                    valueasof: new Date(),
                    outstandingbalance: "",
                    sourceofvaluation: "",
                    pricecontact: "",
                    pricingcurrency: "",
                    pricingfrequency: "",
                    initialprice: "",
                    initialpricedate: new Date(),
                    sourceofinitialvaluation: "",
                    otherpricingfrequency: ''
                },
                history: [

                ],
                hedgefunds: {
                    taxable: false,
                    assetclasscategory: ''
                }

            }

        };

    }

    componentDidMount() {

        if (this.props.mode === 'edit' && this.props.account) {
            var displayvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displayinitialprice = "";
            if (this.props.account.value.initialprice !== undefined && this.props.account.value.initialprice !== null && this.props.account.value.initialprice !== "")
                displayinitialprice = parseFloat(this.props.account.value.initialprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            let account = {
                ...this.props.account
            };
            this.setState({
                account: account, intialAccount: initailAccount, displaytotalvalue: displayvalue, displayinitialprice: displayinitialprice
            });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    componentDidUpdate(prevProps) {

        if (prevProps.source !== this.props.source) {


            if (this.props.mode === 'edit' && this.props.account) {
                var displaytotalvalue = "";
                if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                    displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var displayinitialprice = "";
                if (this.props.account.value.initialprice !== undefined && this.props.account.value.initialprice !== null && this.props.account.value.initialprice !== "")
                    displayinitialprice = parseFloat(this.props.account.value.initialprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var initailAccount = JSON.parse(JSON.stringify(this.props.account));
                this.setState({ account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue, displayinitialprice: displayinitialprice });
            } else if (this.props.mode === 'add') {
                var initailAccount = JSON.parse(JSON.stringify(this.state.account));
                this.setState({ intialAccount: initailAccount });
            }
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    dropdownToggle = source => {
        if (source === 'typeofasset') {
            this.setState(prevState => ({
                isTypesOfAssetsOpen: !prevState.isTypesOfAssetsOpen
            }))
        } else if (source === 'pricingfrequency') {
            this.setState(prevState => ({
                isPricingFrequencyOpen: !prevState.isPricingFrequencyOpen
            }))
        } else if (source === 'volatility') {
            this.setState(prevState => ({
                isVolatilityOpen: !prevState.isVolatilityOpen
            }))
        } else if (source === 'risk') {
            this.setState(prevState => ({
                isRiskOpen: !prevState.isRiskOpen
            }))
        } else if (source === 'assetclasscategory') {
            this.setState(prevState => ({
                isTypesOfAssetClassCategoryOpen: !prevState.isTypesOfAssetClassCategoryOpen
            }))
        }
    }
    dropdownChange(source, statevalue) {
        let clonedState = Object.assign({}, this.state);
        if (source === 'typeofasset') {
            clonedState.account.info.subassettype = statevalue;
        } else if (source === 'pricingfrequency') {
            if (clonedState.account.value.pricingfrequency === "Other" && statevalue !== "Other") {
                clonedState.account.value.otherpricingfrequency = "";
            }
            clonedState.account.value.pricingfrequency = statevalue;
        } else if (source === 'volatility') {
            clonedState.account.info.volatility = statevalue;
        } else if (source === 'risk') {
            clonedState.account.info.risk = statevalue;
        } else if (source === 'assetclasscategory') {
            clonedState.account.hedgefunds.assetclasscategory = statevalue;
        }
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleInitialPriceDateChange = (evt) => {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.initialpricedate = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleFormChanges(evt) {
        let clonedState = Object.assign({}, this.state);
        if (evt.target.name === "custodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.custodianname = evt.target.value;
        }
        else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        }
        else if (evt.target.name === "name" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.name = evt.target.value;
        }
        else if (evt.target.name === "accountnumber" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            clonedState.account.info.accountnumber = evt.target.value;
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaytotalvalue = evt.target.value;
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;
        } else if (evt.target.name === "initialprice" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displayinitialprice = evt.target.value;
        } else if (evt.target.name === "pricecontact" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.pricecontact = evt.target.value;
        } else if (evt.target.name === "sourceofvaluation" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.sourceofvaluation = evt.target.value;
        } else if (evt.target.name === "pricingcurrency" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.pricingcurrency = evt.target.value;
        } else if (evt.target.name === "sourceofinitialvaluation" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.sourceofinitialvaluation = evt.target.value;
        } else if (evt.target.name === "otherpricingfrequency" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.otherpricingfrequency = evt.target.value;
        } else if (evt.target.name === "taxable") {
            if (evt.target.value === 'yes') {
                clonedState.account.hedgefunds.taxable = true;
            } else {
                clonedState.account.hedgefunds.taxable = false;
            }

        }
        this.setState(clonedState);
        this.checkStateChange();
    }

    handleAmountFormatting(evt) {
        if (evt.target.name === "totalvalue") {
            if (evt.target.value !== "") {
                let clonedState = Object.assign({}, this.state);
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.setState(clonedState);
            } else {
                let clonedState = Object.assign({}, this.state);
                clonedState.account.value.totalvalue = evt.target.value;
                this.setState(clonedState);
            }
        } else if (evt.target.name === "initialprice") {
            if (evt.target.value !== "") {
                let clonedState = Object.assign({}, this.state);
                clonedState.account.value.initialprice = evt.target.value;
                clonedState.displayinitialprice = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.setState(clonedState);
            } else {
                let clonedState = Object.assign({}, this.state);
                clonedState.account.value.initialprice = evt.target.value;
                this.setState(clonedState);
            }
        }
        this.checkStateChange();
    }

    handleAmmountOnFocus(event) {
        const clonedState = Object.assign({}, this.state);
        if (event.target.name === "totalvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displaytotalvalue = "";
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
            }
        } else if (event.target.name === "initialprice") {
            if (clonedState.account.value.initialprice === "") {
                clonedState.displayinitialprice = "";
                clonedState.account.value.initialprice = "";
            } else {
                clonedState.displayinitialprice = clonedState.account.value.initialprice;
            }
        }
        this.setState(clonedState);
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
        let messages = [];
        if (this.state.account.value.totalvalue !== this.state.intialAccount.value.totalvalue || this.state.account.value.valueasof !== this.state.intialAccount.value.valueasof
            || this.state.account.value.initialprice !== this.state.intialAccount.value.initialprice || this.state.account.value.initialpricedate !== this.state.intialAccount.value.initialpricedate) {

            messages.push({
                type: MessageType.INFO,
                text: 'Value changes will be shown here after Submit',
                class: MessageClass.INFO
            })
            this.setState({
                formValueChange: true, messages: messages
            });
        }
        else {
            this.setState({
                formValueChange: false, messages: messages
            });
        }
    }
    deleteHistory(valueasof) {
        let arr = cloneDeep(this.state.account.history);
        var indexOfHistory = -1;
        arr.findIndex(function (obj, i) {
            if (obj.valueasof === valueasof) {
                indexOfHistory = i;
            }
        });
        if (indexOfHistory >= 0) {
            arr.splice(indexOfHistory, 1);
        }
        let clonedState = Object.assign({}, this.state);
        clonedState.account.history = arr;
        this.setState(clonedState)
    }
    render() {
        return (
            this.props.mode && this.props.mode !== "view" ?

                <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_hedgefunds_form">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                                onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                            >
                                BASIC INFORMATION
          </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                                onClick={() => { this.toggle('2'); }} id="RCM_history_info"
                            >
                                HISTORY
          </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                        <TabPane tabId="1">
                            <div>

                                <Form>
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_assetClass">
                                                    <Label for="type">Asset Class</Label>
                                                    <Dropdown name="type" required
                                                        isOpen={this.state.isTypesOfAssetsOpen}
                                                        toggle={() => this.dropdownToggle("typeofasset")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                        <DropdownToggle caret>{this.state.account.info.subassettype}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfAssets.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('typeofasset', item.assetName)}>
                                                                            <div>{item.assetDisplayName}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_assetClassCategory">
                                                    <Label for="type">Asset Class Category</Label>
                                                    <Dropdown name="type" required
                                                        isOpen={this.state.isTypesOfAssetClassCategoryOpen}
                                                        toggle={() => this.dropdownToggle("assetclasscategory")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                        <DropdownToggle caret>{this.state.account.hedgefunds.assetclasscategory}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfAssetClassCategory.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('assetclasscategory', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_custodianname">
                                                    <Label for="custodianname">Custodian Name</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="custodianname"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.custodianname}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridAccount" id="RCM_accountNumber">
                                                    <Label for="accountnumber">Account Number</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="accountnumber"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.accountnumber}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_name">
                                                    <Label for="name">Name</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="name"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.name}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                                    <Label for="nickname">Nickname</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="nickname"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.nickname}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_initialprice">
                                                    <Label for="initialprice">Initial Price </Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        name="initialprice"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.displayinitialprice}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_initialpricedate">
                                                    <div><Label for="initialpricedate">Initial Price Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="initialpricedate" id="initialpricedate"
                                                        value={new Date(this.state.account.value.initialpricedate)}
                                                        onChange={this.handleInitialPriceDateChange}
                                                        selected={(this.state.account.value.initialpricedate !== undefined && this.state.account.value.initialpricedate !== null
                                                            && this.state.account.value.initialpricedate !== '') ? new Date(this.state.account.value.initialpricedate) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === "N"}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_sourceofinitialvaluation">
                                                    <Label for="sourceofinitialvaluation">Source of Initial Valuation</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="sourceofinitialvaluation"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.sourceofinitialvaluation}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>


                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                                    <Label for="totalvalue">Total Value</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        name="totalvalue"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.displaytotalvalue}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                    <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="valueasof" id="valueasof"
                                                        value={new Date(this.state.account.value.valueasof)}
                                                        onChange={this.handleValueAsOfChange}
                                                        selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                            && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === "N"}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_sourceofvaluation">
                                                    <Label for="sourceofvaluation">Source of Valuation</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="sourceofvaluation"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.sourceofvaluation}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="custodianname">Price Contact</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="pricecontact"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.pricecontact}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-8 col-lg-8">
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="type">Pricing Frequency</Label>
                                                    <Dropdown name="type" required
                                                        isOpen={this.state.isPricingFrequencyOpen}
                                                        toggle={() => this.dropdownToggle("pricingfrequency")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                        <DropdownToggle caret>{this.state.account.value.pricingfrequency}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfPricingFrequency.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('pricingfrequency', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            {this.state.account.value.pricingfrequency === 'Other'
                                                ? <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridTotal Value">
                                                        <Label for="otherpricingfrequency">Specify Other Pricing Frequency</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="otherpricingfrequency"
                                                            className="RCM_form_control"
                                                            required
                                                            value={this.state.account.value.otherpricingfrequency}
                                                            onChange={this.handleFormChanges}
                                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                : ''
                                            }
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="pricingcurrency">Pricing Currency</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="pricingcurrency"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.pricingcurrency}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <div>
                                                        <label className="RCM_selectLabel">
                                                            Taxable
                                                    </label>
                                                    </div>
                                                    <div className="RCM_Taxable_radio_wrapper">
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio" value="yes"
                                                                    checked={this.state.account.hedgefunds.taxable === true}
                                                                    name="taxable"
                                                                    onChange={this.handleFormChanges}
                                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'} />
                                                                <span className="RCM_radio_span_assets">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio" value="no"
                                                                    name="taxable"
                                                                    checked={this.state.account.hedgefunds.taxable === false}
                                                                    onChange={this.handleFormChanges}
                                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'} />
                                                                <span className="RCM_radio_span_assets">No</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="volatility">Volatility </Label>
                                                    <Dropdown name="volatility" required
                                                        isOpen={this.state.isVolatilityOpen}
                                                        toggle={() => this.dropdownToggle("volatility")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                        <DropdownToggle caret>{this.state.account.info.volatility}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfVolatilityRisk.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('volatility', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="risk">Risk </Label>
                                                    <Dropdown name="risk" required
                                                        isOpen={this.state.isRiskOpen}
                                                        toggle={() => this.dropdownToggle("risk")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                        <DropdownToggle caret>{this.state.account.info.risk}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfVolatilityRisk.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('risk', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                                <div><Label for="desc" >Description</Label></div>
                                                <textarea
                                                    name="description"
                                                    placeholder=""
                                                    className="RCM_form_control RCM_Feedback_TextArea"
                                                    value={this.state.account.info.description}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </div>

                                        </div>
                                    </fieldset>
                                    <div className="row">

                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                            <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                            <button type="button"
                                                className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                onClick={this.saveAsset}
                                            >
                                                SUBMIT
                                            </button>
                                            <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </TabPane>
                        <TabPane tabId="2">
                            <div>
                                <Form>
                                    <div className="row">
                                        {this.state.formValueChange &&
                                            this.state.messages.length > 0 ?
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <Messages messages={this.state.messages} containerClass="RCM_validation_error_container" />
                                            </div>
                                            : ''
                                        }
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <HistoryManualAsset source='hedgefunds'
                                                    data={this.state.account.history}
                                                    deleteHistory={this.deleteHistory}
                                                    canedit={this.state.account.canedit !== undefined ? this.state.account.canedit : ''} />
                                            </div>
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                            <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                            <button type="button"
                                                className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                onClick={this.saveAsset}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === "N"}>
                                                SUBMIT
                                        </button>
                                            <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        </div>
                                    </div>

                                </Form>

                            </div>

                        </TabPane>
                        {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                            ?
                            < div className="row">
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                    {viewOnlyText}
                                </div>
                            </div>
                            : ""
                        }

                    </TabContent>
                </div>
                :
                <div>


                </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(HedgeFunds);