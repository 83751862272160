import React from 'react';
import { roundvalue, formatMoney, GetNumericValue, getAccountDisplayName, IndLongShort } from '../../../../common/constants';
export function PerformanceSummaryColumns(accountlist) {
    var columns = [
        {
            dataField: 'displayname', text: "Account", //sort: true,
            //sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            //headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div title={getAccountDisplayName(accountlist, row.key, IndLongShort.LONG)}>{cell}</div>;
            }
        },
        {
            dataField: 'lastmarketclose', text: "Last Market Close",
            /*sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSortForAmountPercent(a, b, order),*/
            headerAlign: 'center',
           // headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',

            formatter: (cell, row) => {
                if (row.lastmarketclose && row.lastmarketclose !== null) {
                    let value = row.lastmarketclose.value !== undefined && row.lastmarketclose.value !== null && row.lastmarketclose.value !== 0 ? formatMoney(roundvalue(row.lastmarketclose.value, 2)) : GetNumericValue(row.lastmarketclose.value);
                    //let valuePerc = row.lastmarketclose.percent !== undefined && row.lastmarketclose.percent !== null && row.lastmarketclose.percent !== 0 ? roundvalue(row.lastmarketclose.percent, 2) + '%' : GetNumericValue(row.lastmarketclose.percent, true);

                    return <div className="RCM_displayflex">
                        <div className="RCM_Performance_LCM_col">
                            <span className={GetClassName(row.lastmarketclose.increase)}>
                                {value}
                            </span>
                        </div>
                    </div>
                } else
                    return <div>-</div>;
            }
        },
        {
            dataField: 'monthtodate', text: "Month To Date(%)",
            /*sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSort(a, b, order),*/
            headerAlign: 'right',
           // headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            }
        },
        {
            dataField: 'quartertodate', text: "Quarter To Date(%)",
           /* sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSort(a, b, order),*/
            headerAlign: 'right',
            //headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            }
        }
        /*,
        {
            dataField: 'yeartodate', text: "Year To Date (%)",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => customSort(a, b, order),
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== null) {
                    let valuePerc = cell !== 0 ? roundvalue(cell, 2) + '%' : GetNumericValue(cell, true);
                    return <div>
                        {valuePerc}</div>;
                } else
                    return <div>-</div>;
            },
            footer: () => (
                <span> </span>
            )
        },*/

    ];

    return columns;
}

function GetClassName(value) {
    if (value === undefined) {
        return '';
    }
    else if (value) {
        return "RCM_positiveval";
    }
    else {
        return "RCM_negativeval";
    }
}
