import React from 'react';
import PerformanceSummary from './performancesummary';
import PerformanceSummaryTable from './performancesummarytable';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';

const PerformanceView = (props) => {

    return (

        <>
            <div className="col-sm-12 col-md-5 col-xs-12  RCM_summary_section col-lg-4">
                <div className="RCM_Toast" id="RCM_Performance_Summary">
                    <ErrorBoundary>
                        <div className="row RCM_headingwrapper">
                            <div className="col-sm-8">
                                <label className="RCM_tile_heading">PERFORMANCE</label></div>
                            
                            <div className="col-sm-4 RCM_expandviewright">
                                <div className="RCM_expandviewrightWrapper">
                                   {/* <div>
                                        <button id="TooltipPerformanceTableToggle" onClick={() => props.updatePreferenceCallBack()}
                                            className={(props.showTable ? 'RCM_left_toggle' : 'RCM_left_bar_toggle')}></button>
                                        <Tooltip placement="left" isOpen={props.tooltipTableChartOpen} target="TooltipPerformanceTableToggle"
                                            
                                            toggle={() => props.setTooltipTableChartOpen(prevTooltipTableChartOpen => !prevTooltipTableChartOpen)}
                                            innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                            {props.showTable ? 'Toggle to Chart' : 'Toggle to Table'}
                                        </Tooltip>
                                        <label className="RCM_togglelabeltext" onClick={() => props.updatePreferenceCallBack()}>{props.showTable ? 'Chart' : 'Table'}</label>
                                    </div>*/}
                                    <div>
                                        <Link to='Performance' className="RCM_expandview" id="TooltipPerformancedetail"></Link>
                                        <Tooltip placement="left" isOpen={props.tooltipDetailsOpen} target="TooltipPerformancedetail"
                                            toggle={() => props.setTooltipDetailsOpen(prevTooltipDetailsOpen => !prevTooltipDetailsOpen)} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                            Performance Detail
                                        </Tooltip>
                                        <Link to='Performance'> <label className="RCM_togglelabeltext RCM_detailsLabel">Detail</label></Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <PerformanceSummaryTable />
                        {/*{props.showTable
                            ? <PerformanceSummaryTable />
                            : <PerformanceSummary />
                        }*/}
                        
                    </ErrorBoundary>
                </div>
            </div>

        </>
    )

};

export default PerformanceView;