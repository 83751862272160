export const ADD_TOPTEN = "Topten:addTopten";
export const ADD_HOLDINGSBYSECURITY = "Holdings:Security"
export const ADD_HOLDINGSBYACCOUNT = "Holdings:Account"
export const ADD_HOLDINGSBYCATEGORY = "Holdings:Asset"
export const SET_ACCOUNTOVERVIEWFLAG = "Security:Flag"
export const UPDATE_TOPTEN = "UPDATE_TOPTEN"
export const ADD_REALIZEDGAINLOSSBYSECURITY = "realizedgainloss:Security"
export const ADD_REALIZEDGAINLOSSBYACCOUNT = "realizedgainloss:Account"
export const ADD_ESTIMATEDINCOMEBYACCOUNT = "estimatedincome:Account";
export const ADD_ESTIMATEDINCOMEBYSECURITY = "estimatedincome:Security";
export const ADD_ESTIMATEDINCOMESUMMARY_CHART = "estimatedincomesummary:chart";
export const ADD_ESTIMATEDINCOMESUMMARY_SECURITY = "estimatedincomesummary:security";
export const ADD_ESTIMATEDINCOMESUMMARY_ACCOUNT = "estimatedincomesummary:account";
export const UPDATE_ESTIMATEDINCOMESUMMARY_TOGGLEVIEW = "estimatedincomesummary:toggleview";
export const ADD_PROJECTEDINCOMEBYACCOUNT = "projectedincome:Account";
export const ADD_PROJECTEDINCOMEBYSECURITY = "projectedincome:Security";
export const ADD_PROJECTEDINCOMESUMMARY_CHART = "projectedincomesummary:chart";
export const ADD_PROJECTEDINCOMESUMMARY_SECURITY = "projectedincomesummary:security";
export const ADD_PROJECTEDINCOMESUMMARY_ACCOUNT = "projectedincomesummary:account";
export const UPDATE_PROJECTEDINCOMESUMMARY_TOGGLEVIEW = "projectedincomesummary:toggleview";
export const CHANGEYEAR_ESTIMATEDINCOME = "changeyear:estimatedincome";
export const CHANGEYEAR_PROJECTEDINCOME = "changeyear:projectedincome";
export const REFRESH_ESTIMATEDINCOME_SUMMARYSECURITY = "update:estimatedincomesummarysecurity";
export const REFRESH_ESTIMATEDINCOME_SUMMARYACCOUNT = "update:estimatedincomesummaryaccount";
export const REFRESH_ESTIMATEDINCOME_ACCOUNTSECURITY = "update:estimatedincomeaccountsecurity";
export const REFRESH_PROJECTEDINCOME_SUMMARYSECURITY = "update:projectedincomesummarysecurity";
export const REFRESH_PROJECTEDINCOME_SUMMARYACCOUNT = "update:projectedincomesummaryaccount";
export const REFRESH_PROJECTEDINCOME_ACCOUNTSECURITY = "update:projectedincomeaccountsecurity";
export const UPDATE_ASSET_SCHEMA = "update:assetschema";

export function UpdateTopten() {
    return {
        type: UPDATE_TOPTEN,
        toptensummary: null,
        holdingsbyaccount: null,
        holdingsbysecurity: null,
        holdingsbycategory: null,
        realizedgainlossbyaccount: null,
        realizedgainlossbysecurity: null,
        estimatedincomebyaccount: null,
        estimatedincomebysecurity: null,
        estimatedIncomeSummarytoggleview: 'C',
        estimatedIncomeSummarySecurityData: null,
        estimatedIncomeSummaryAccountData: null,
        estimatedIncomeSummaryChartData: null,
        projectedincomebyaccount: null,
        projectedincomebysecurity: null,
        projectedIncomeSummarytoggleview: 'C',
        projectedIncomeSummarySecurityData: null,
        projectedIncomeSummaryAccountData: null,
        projectedIncomeSummaryChartData: null,
        estimatedIncomeYear: null,
        projectedIncomeYear:null
    }
}
export function AddTopten(newToptensummary) {
    return {
        type: ADD_TOPTEN,
        toptensummary: newToptensummary
    }
}
export function AddHoldingsBySecurity(newHoldingsBySecurity) {
    return {
        type: ADD_HOLDINGSBYSECURITY,
        holdingsbysecurity: newHoldingsBySecurity
    }
}
export function AddHoldingsByAccount(newHoldingsByAccount) {
    return {
        type: ADD_HOLDINGSBYACCOUNT,
        holdingsbyaccount: newHoldingsByAccount
    }
}
export function AddHoldingsByAsset(newHoldingsByCategory) {
    return {
        type: ADD_HOLDINGSBYCATEGORY,
        holdingsbycategory: newHoldingsByCategory
    }
}
export function SetAccountOverviewFlag(newAccountOverviewFlag) {
    return {
        type: SET_ACCOUNTOVERVIEWFLAG,
        accountOverviewFlag: newAccountOverviewFlag
    }
}
export function AddRealizedgainlossByAccount(newrealizedgainlossbyaccount) {
    return {
        type: ADD_REALIZEDGAINLOSSBYACCOUNT,
        realizedgainlossbyaccount: newrealizedgainlossbyaccount
    }
}
export function AddRealizedgainlossBySecurity(newrealizedgainlossbysecurity) {
    return {
        type: ADD_REALIZEDGAINLOSSBYSECURITY,
        realizedgainlossbysecurity: newrealizedgainlossbysecurity
    }
}

export function AddEstimatedIncomeByAccount(newestimatedincomebyaccount) {
    return {
        type: ADD_ESTIMATEDINCOMEBYACCOUNT,
        estimatedincomebyaccount: newestimatedincomebyaccount
    }
}
export function AddEstimatedIncomeBySecurity(newestimatedincomebysecurity) {
    return {
        type: ADD_ESTIMATEDINCOMEBYSECURITY,
        estimatedincomebysecurity: newestimatedincomebysecurity
    }
}

export function AddEstimatedIncomeSummaryChart(chartData){
    return {
        type: ADD_ESTIMATEDINCOMESUMMARY_CHART,
        estimatedIncomeSummaryChartData :  chartData
    }
}

export function AddEstimatedIncomeSummarySecurity(securityData){
    return {
        type: ADD_ESTIMATEDINCOMESUMMARY_SECURITY,
        estimatedIncomeSummarySecurityData :  securityData
    }
}

export function AddEstimatedIncomeSummaryAccount(accountData){
    return {
        type: ADD_ESTIMATEDINCOMESUMMARY_ACCOUNT,
        estimatedIncomeSummaryAccountData :  accountData
    }
}

export function UpdateEstimateIncomeSummaryToggleView(toggleview) {
    return {
        type: UPDATE_ESTIMATEDINCOMESUMMARY_TOGGLEVIEW,
        estimatedIncomeSummarytoggleview: toggleview
    }
}

export function AddProjectedIncomeByAccount(newprojectedincomebyaccount) {
    return {
        type: ADD_PROJECTEDINCOMEBYACCOUNT,
        projectedincomebyaccount: newprojectedincomebyaccount
    }
}
export function AddProjectedIncomeBySecurity(newprojectedincomebysecurity) {
    return {
        type: ADD_PROJECTEDINCOMEBYSECURITY,
        projectedincomebysecurity: newprojectedincomebysecurity
    }
}

export function AddProjectedIncomeSummaryChart(chartData) {
    return {
        type: ADD_PROJECTEDINCOMESUMMARY_CHART,
        projectedIncomeSummaryChartData: chartData
    }
}

export function AddProjectedIncomeSummarySecurity(securityData) {
    return {
        type: ADD_PROJECTEDINCOMESUMMARY_SECURITY,
        projectedIncomeSummarySecurityData: securityData
    }
}

export function AddProjectedIncomeSummaryAccount(accountData) {
    return {
        type: ADD_PROJECTEDINCOMESUMMARY_ACCOUNT,
        projectedIncomeSummaryAccountData: accountData
    }
}

export function UpdateProjectedIncomeSummaryToggleView(toggleview) {
    return {
        type: UPDATE_PROJECTEDINCOMESUMMARY_TOGGLEVIEW,
        projectedIncomeSummarytoggleview: toggleview
    }
}

export function ChangeYearEstimatedIncome(yearval) {
    return {
        type: CHANGEYEAR_ESTIMATEDINCOME,
        estimatedIncomeYear: yearval,
       
    }
}
export function RefreshEstimatedIncomeSummarySecurity() {
    return {
        type: REFRESH_ESTIMATEDINCOME_SUMMARYSECURITY,
        estimatedincomebysecurity: null,
        estimatedIncomeSummarytoggleview: 'C',
        estimatedIncomeSummarySecurityData: null,
        estimatedIncomeSummaryAccountData: null,
        estimatedIncomeSummaryChartData: null,
    }
}
export function RefreshEstimatedSummaryAccount() {
    return {
        type: REFRESH_ESTIMATEDINCOME_SUMMARYACCOUNT,
        estimatedincomebyaccount: null,
        estimatedIncomeSummarytoggleview: 'C',
        estimatedIncomeSummarySecurityData: null,
        estimatedIncomeSummaryAccountData: null,
        estimatedIncomeSummaryChartData: null,
    }
}
export function RefreshEstimatedIncomeAccountSecurity() {
    return {
        type: REFRESH_ESTIMATEDINCOME_ACCOUNTSECURITY,
        estimatedincomebyaccount: null,
        estimatedincomebysecurity: null,
    }
}


export function ChangeYearProjectedIncome(yearval) {
    return {
        type: CHANGEYEAR_PROJECTEDINCOME,
        projectedIncomeYear: yearval,
    }
}
export function RefreshProjectedIncomeSummarySecurity() {
    return {
        type: REFRESH_PROJECTEDINCOME_SUMMARYSECURITY,
        projectedincomebysecurity: null,
        projectedIncomeSummarytoggleview: 'C',
        projectedIncomeSummarySecurityData: null,
        projectedIncomeSummaryAccountData: null,
        projectedIncomeSummaryChartData: null,
    }
}
export function RefreshProjectedSummaryAccount() {
    return {
        type: REFRESH_PROJECTEDINCOME_SUMMARYACCOUNT,
        projectedincomebyaccount: null,
        projectedIncomeSummarytoggleview: 'C',
        projectedIncomeSummarySecurityData: null,
        projectedIncomeSummaryAccountData: null,
        projectedIncomeSummaryChartData: null,
    }
}
export function RefreshProjectedIncomeAccountSecurity() {
    return {
        type: REFRESH_PROJECTEDINCOME_ACCOUNTSECURITY,
        projectedincomebyaccount: null,
        projectedincomebysecurity: null,
    }
}
export function UpdateAssetSchema() {
    return {
        type: UPDATE_ASSET_SCHEMA,
        holdingsbycategory:null
    }
}



