import React, { Component } from 'react';
import Activitydetails from './activitydetailsservicecalls.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import {balanceActicityAggreationDisclaimer} from '../../../../common/constants'
export default class Activitydetail extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        return (
            <div className="RCM_detailsScreeen">

                <div className="row">
                    <div className="col-sm-12">
                        <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='adkey'>ACCOUNT ACTIVITY DETAILS</label>
                    </div>
                </div>
                <ErrorBoundary>
                    <Activitydetails />
                </ErrorBoundary>
                <div className="mt-2 RCM_footer_text">
                    <p>{balanceActicityAggreationDisclaimer}</p>
                </div>
            </div>
        )
    }
}
