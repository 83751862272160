import React, { useCallback, useMemo, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import {
  MaskPhoneNumber,
  rectifyPhoneNumber,
} from "../personalisationandsettings/multifactorAuthentication/common/constants";
import VerifyUser from "./VerifyUser";
import {
  getInternationalCallingCodes,
  verifyFirstTimeUserDetails,
  updateMfaSkipCounterService,
} from "../../services/home/userServices";
import { _logException, _logInformation } from "../../common/_logging";
import { AddPhoneMethodUrl } from "../../common/constants";
import axios from "axios";
import { Post } from "../../common/servicecalls";
import { useEffect } from "react";
import UserPhoneList from "./UserPhoneList";

const CheckUserStatus = ({
  logout,
  token,
  onSkip,
  firstimeloginresonse,
  afterVerification,
}) => {
  const [userDetailsForm, setUserDetailsForm] = useState({
    phoneno: "",
    accountno: "",
    ssn: "",
    displayPhone: "",
    mode: 1,
  });
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [isCountryDropdownOpen, setCountryDropdownOpenValue] = useState(false);
  const [countryList, setCountryList] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [validateUserProgress, setValidateUserProgress] = useState({
    inProgress: false,
    error: undefined,
    otpkey: undefined,
  });

  const {
    contactinfo,
    isfirstimelogin: isFirstTimeLogin,
    allowSkip,
    role,
    profileid,
    currentSkipCount,
    maxSkipCount,
  } = firstimeloginresonse || {};

  const userPhoneList = useMemo(() => {
    var phoneList = [];
    if (contactinfo && Array.isArray(contactinfo)) {
      return contactinfo.map((contact) => ({
        displayPhone: MaskPhoneNumber(contact.phoneno),
        value: contact.phoneno || "",
      }));
    }
    return phoneList;
  }, [contactinfo]);

  const toogleCountryDropdown = useCallback(() => {
    setCountryDropdownOpenValue((prevValue) => !prevValue);
  }, []);

  const handleOnCountryChange = useCallback(
    (country) => {
      setSelectedCountry(countryList[country]);
    },
    [countryList]
  );

  const handleMobileDropdownClick = useCallback((phone) => {
    setUserDetailsForm((prevState) => ({
      ...prevState,
      phoneno: phone ? phone.value : "",
      displayPhone: phone ? phone.displayPhone : "",
    }));
  }, []);

  const handleAccountNumberChange = (event) => {
    event.persist();
    if (event && event.target) {
      setUserDetailsForm((prevState) => ({
        ...prevState,
        accountno: event.target.value,
      }));
    }
  };

  const handleLast4DigitSSNChange = (event) => {
    event.persist();
    const value = event.target ? event.target.value : "";
    const pattern = /^\d*$/;
    if (value === "" || pattern.test(value)) {
      setUserDetailsForm((prevState) => ({
        ...prevState,
        ssn: event.target.value,
      }));
    }
  };

  const handleVerificationModeChange = (mode) => {
    setUserDetailsForm((prevState) => ({
      ...prevState,
      mode: mode,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    event.persist();
    const value = event.target ? event.target.value : "";
    const pattern = /^\d*$/;
    if (value === "" || pattern.test(value)) {
      setUserDetailsForm((prevState) => ({
        ...prevState,
        phoneno: event.target.value,
      }));
    }
  };

  const handleVerifyUserDetails = (userDetailsForm) => {
    setValidateUserProgress((prevState) => ({
      ...prevState,
      inProgress: true,
      error: undefined,
      otpkey: undefined,
    }));
    var rectifiedPhone =
      role && role.toLowerCase() === "prospect"
        ? `${selectedCountry}${userDetailsForm.phoneno}`
        : rectifyPhoneNumber(userDetailsForm.phoneno);
    var isInputValid = isFirstTimeLogin
      ? userDetailsForm &&
        userDetailsForm.ssn !== "" &&
        userDetailsForm.accountno !== "" &&
        rectifiedPhone !== ""
      : rectifiedPhone !== "";
    if (isInputValid) {
      verifyFirstTimeUserDetails(userDetailsForm, token)
        .then((res) => {
          if (res && res.data === true) {
            //send OTP
            if (rectifiedPhone) {
              //Post data
              const cancellationToken = axios.CancelToken.source();
              const apiurl = AddPhoneMethodUrl;
              const phoneRequest = {
                phoneNumber: `${rectifiedPhone}`,
                phoneType: "mobile",
                mode: userDetailsForm.mode,
              };
              Post(apiurl, phoneRequest, token, null, null, cancellationToken)
                .then((apiRes) => {
                  setValidateUserProgress((prevState) => ({
                    ...prevState,
                    inProgress: false,
                    error: undefined,
                    otpkey: apiRes.data,
                  }));
                  setShowVerifyOtp(true);
                })
                .catch(() => {
                  setValidateUserProgress((prevState) => ({
                    ...prevState,
                    inProgress: false,
                    error:
                      "We are unable to send verification code to you, please try after sometime",
                    otpkey: undefined,
                  }));
                  setShowVerifyOtp(false);
                });
            }
          } else {
            setValidateUserProgress((prevState) => ({
              ...prevState,
              inProgress: false,
              error:
                "We are unable to verify your details, please make sure you have provided correct details.",
              otpkey: undefined,
            }));
          }
        })
        .catch((err) => {
          _logException(err);
          setValidateUserProgress((prevState) => ({
            ...prevState,
            inProgress: false,
            error:
              "We are unable to verify your details at this time, please try after sometime.",
          }));
        });
    } else {
      setValidateUserProgress((prevState) => ({
        ...prevState,
        inProgress: false,
        error: "Please provide all the details.",
      }));
    }
  };

  const handleOnSkipClick = useCallback(() => {
    if (!sessionStorage.getItem("alreadySkip")) {
      sessionStorage.setItem("alreadySkip", true);
      updateMfaSkipCounterService(profileid, token)
        .then(() => {
          _logInformation("Mfa Counter Updated");
        })
        .catch(() => {
          _logException("Failed to update, MFA counter");
        });
    }
    onSkip();
  }, [onSkip, profileid, token]);

  useEffect(() => {
    setCountryList(undefined);
    getInternationalCallingCodes(token)
      .then((apiRes) => {
        setCountryList(apiRes.data);
      })
      .catch((err) => {
        setCountryList(undefined);
        _logException(
          `Get International Calling code failed. ${
            err && err.message ? err.message : ""
          }`
        );
      });
  }, [token]);

  const innerFormHeight = useMemo(() => {
    let minHeight = isFirstTimeLogin ? 600 : 500;
    const minHeightWithError = validateUserProgress.error
      ? minHeight + 40
      : minHeight;
    const minHeightWithMultiplePhone =
      userPhoneList && userPhoneList.length > 1
        ? minHeightWithError + 50
        : minHeightWithError;
    return `${minHeightWithMultiplePhone}px`;
  }, [isFirstTimeLogin, userPhoneList, validateUserProgress.error]);

  //Select the firt one if user have only one phone number
  useEffect(() => {
    if (userPhoneList && userPhoneList.length === 1) {
      const { value, displayPhone } = userPhoneList[0];
      setUserDetailsForm((prevState) => ({
        ...prevState,
        phoneno: value,
        displayPhone: displayPhone,
      }));
    }
  }, [userPhoneList]);

  if (showVerifyOtp) {
    return (
      <VerifyUser
        logout={logout}
        token={token}
        otpkey={validateUserProgress.otpkey}
        userDetailsForm={userDetailsForm}
        afterVerification={afterVerification}
      />
    );
  }

  return (
    <>
      <div>
        <div className="RCM_background"></div>
        <div className="RCM_background_overlay"></div>
      </div>
      <div className="RCM_login_outer">
        <div className="RCM_login_middle">
          <div
            className="RCM_login_inner"
            style={{ minHeight: innerFormHeight }}
          >
            <img
              className="RCM_Logon_Form_Logo"
              role="presentation"
              src="../images/logo.svg"
              alt="Rockefeller logo"
            />
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  marginTop: "1.5rem",
                }}
              >
                <div style={{ color: "black", fontSize: "0.8rem" }}>
                  You are required to register for Multi-factor authentication
                  with Rockefeller Capital Management.
                </div>
                {isFirstTimeLogin && (
                  <>
                    <input
                      type="text"
                      placeholder="Enter an Account Number"
                      className="RCM_Form_textfield"
                      required
                      spellCheck={false}
                      autoComplete="off"
                      value={userDetailsForm.accountno}
                      onChange={handleAccountNumberChange}
                      maxLength={15}
                    />
                    <input
                      type="text"
                      placeholder={
                        role && role.toLowerCase() === "3rdparty"
                          ? "Enter 4 digit code provided by Advisor/CA"
                          : "Enter the Last 4 Digits of your SSN"
                      }
                      className="RCM_Form_textfield"
                      required
                      spellCheck={false}
                      autoComplete="off"
                      value={userDetailsForm.ssn}
                      onChange={handleLast4DigitSSNChange}
                      maxLength={4}
                    />
                  </>
                )}
                {role && role.toLowerCase() === "prospect" ? (
                  <div style={{ display: "flex", gap: "0.3rem" }}>
                    <Dropdown
                      isOpen={isCountryDropdownOpen}
                      toggle={toogleCountryDropdown}
                    >
                      <DropdownToggle
                        caret={false}
                        className="RCM_Form_textfield"
                        style={{
                          textAlign: "left",
                          paddingLeft: 0,
                          backgroundColor: "#fff",
                          color: "gray",
                        }}
                      >
                        {selectedCountry ? selectedCountry : "Country"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{
                          height: "15rem",
                          overflowX: "hidden",
                          overflowY: "auto",
                          zIndex: 999,
                          width: "380px",
                        }}
                      >
                        {Object.keys(countryList || {}).map((item, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => handleOnCountryChange(item)}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    <div
                      style={{
                        color: "gray",
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      -
                    </div>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className="RCM_Form_textfield"
                      required
                      spellCheck={false}
                      autoComplete="off"
                      maxLength={13}
                      value={userDetailsForm.phoneno}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                ) : (
                  <UserPhoneList
                    PhoneList={userPhoneList}
                    onUserPhoneChange={handleMobileDropdownClick}
                    detailsForm={userDetailsForm}
                  />
                )}

                <div style={{ color: "gray", fontSize: "0.8rem" }}>
                  Choose a verification method:
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                  <input
                    type="radio"
                    id="mode_text"
                    name="mode"
                    value="1"
                    style={{
                      position: "relative",
                      left: 0,
                      height: "1.5rem",
                      width: "1rem",
                    }}
                    onChange={() => handleVerificationModeChange(1)}
                    checked={userDetailsForm.mode === 1}
                  />
                  <label htmlFor="mode_text" style={{ color: "gray" }}>
                    Text
                  </label>
                  <input
                    type="radio"
                    id="mode_call"
                    name="mode"
                    value="0"
                    style={{
                      position: "relative",
                      left: 0,
                      height: "1.5rem",
                      width: "1rem",
                    }}
                    onChange={() => handleVerificationModeChange(0)}
                    checked={userDetailsForm.mode === 0}
                  />
                  <label htmlFor="mode_call" style={{ color: "gray" }}>
                    Call
                  </label>
                </div>
              </div>
              {validateUserProgress.error && (
                <div className="RCM_Teamwork_error">
                  {validateUserProgress.error}
                </div>
              )}
              <div
                className="mt-1"
                style={{
                  display: "flex",
                  gap: "2px",
                  justifyContent: "flex-end",
                }}
              >
                {allowSkip && (
                  <button
                    className="RCM_Form_Sec_Button"
                    onClick={() => handleOnSkipClick()}
                  >
                    Skip {currentSkipCount} / {maxSkipCount}
                  </button>
                )}
                <button
                  className="RCM_Form_Sec_Button"
                  onClick={() => logout()}
                >
                  Cancel
                </button>
                <button
                  className="RCM_Form_Primary_Button"
                  onClick={() => handleVerifyUserDetails(userDetailsForm)}
                  disabled={validateUserProgress.inProgress}
                >
                  {validateUserProgress.inProgress && (
                    <Spinner size="sm" style={{ marginRight: "0.5rem" }} />
                  )}
                  Continue
                </button>
              </div>
              <div
                style={{ color: "black", fontSize: "0.8rem", marginTop: "2px" }}
              >
                If you have any questions, please contact your advisor team.
              </div>
            </div>
            <div className="RCM_Logon_Form_Footer">
              <div>Welcome to Rockefeller Capital Management.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckUserStatus;
