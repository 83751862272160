import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const Tooltipcomponent = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
          <span href="#" id={props.id}>{props.value}</span>
      <Tooltip placement={props.placement}  isOpen={tooltipOpen} target={props.id} toggle={toggle}
        className=""
        popperClassName="toolTipPopper"
        innerClassName="toolTip"
        arrowClassName="">
          {props.children}
      </Tooltip>
    </div>
  );
}

export default Tooltipcomponent; 