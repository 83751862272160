import { useState, useCallback } from "react";

export const useBoolean = (initialValue) => {
  const [value, setValue] = useState(initialValue || false);

  const toogle = useCallback(() => {
    setValue((prevState) => !prevState);
  }, []);

  const updateValue = useCallback((val) => {
    setValue(val);
  }, []);

  return { value, toogle, updateValue };
};
