import React, { Component } from 'react';
import { roundvalue, GetNumericValue } from './constants.jsx';
import AssetAllocationHighChart from './HighCharts/AssetAllocationHighChart.jsx';

class AssetAllocationChartDetails extends Component {

    setColor(asset) {
        if (asset) {
            return "linear-gradient(to bottom, transparent 0%,transparent 10%," + asset.colorCd + " 11%, " + asset.colorCd +" 90%, transparent 91%, transparent 100%)"
        }
    }

    render() {
        return (
            <div className="row">
                <div className='col-sm-8'>
                    <AssetAllocationHighChart 
                        data={this.props.data} 
                        fontsize={window.document.documentElement.style.getPropertyValue('font-size')}/>
                </div>
                <div className='col-sm-4 RCM_legent_Wrapper'>
                    <ul className="RCM_legent_height RCM_CM_scroll">
                        {this.props.data.map((asset, index) => {
                            return <li key={index} style={{ borderImageSource: this.setColor(asset) }} className={"RCM_aachartWrapper RCM_aachart"}>
                                <div className="RCM_aseetpct">{(asset.percentage && asset.percentage > 0) ? roundvalue(asset.percentage, 2)+ '%' : GetNumericValue(asset.percentage,true)}</div>
                                <div className="RCM_assetName"> <span>{asset.name}</span></div>
                            </li>
                        })}
                    </ul>
                </div>

            </div>
        );
    }
}
export default AssetAllocationChartDetails;