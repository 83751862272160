import React from "react";
import { Spinner } from "reactstrap";
import { _logEvent } from "./_logging";
import { getNestedObject } from "../components/MoneyMovement/common/utilty";
import { userinfo } from "../../App.js";
import { decrypt } from "./CryptoUtil";
var baseURL = "";
//if (!baseURL) baseURL ="https://api.dev.rockco.com/"

var gmailLogOutUrl = "https://accounts.google.com/logout";
export var billPayUIUrl = "";
export var nwScriptUrl = "";
export var nwDbUrl = "";
export var ninthwavesAppId = "";
export var assetagUrl = "assetag";

export var eligiblityPairServiceUrl =
  "cashmanagement/mm/odata/GetEligiblePairs";
export var mmvalidateUrl = "mm/odata/MMValidate";
export var addTransferUrl = "mm/odata/mmsubmit";
export var activityTransfersUrl = "activityapi/odata/activitysummary";
export var mmSummaryUrl = "mm/odata/MMSummary";

export var validateABAUrl = "cashmanagement/mm/odata/GetABA('ATOCH,ABANumber')";
export var getSIUrl = "cashmanagement/mm/odata/GetSI";
export var getFirstPartyEligibleAcctUrl =
  "/cashmanagement//mm/odata/PostEligibleTos";
export var getThirdPartyEligibleAcctUrl =
  "/cashmanagement/mm/odata/GetXferFrom";
export var validateSIUrl = "mm/odata/MMStandingInstructionValidate";
export var submitSI = "mm/odata/MMStandingInstructionSubmit";
export var uploadToBpm = "acctopngapi/api/BPM/UpdateSIDoc";

export var getAALoginTokenUrl = "assetag/odata/ExtAssetAggregationStartSession";
export var getAALogoutTokenUrl = "assetag/odata/ExtAssetAggregationEndSession";
export var deleteAggrAcctUrl = "assetag/odata/ExtAssetAggregationSubmit";
export var updatePasswordStatusUrl = "assetag/odata/ExtAssetAggregationSubmit";
export var updateAssetAggrAcct = "profileapi/odata/aggregatedaccount";
export var getAssetAggrAcctList = "profileapi/odata/aggregatedaccount('1')";

export var ProfileURL = "profileapi/odata/profile('";
export var ProfileUpdateUrl = "profileapi/odata/profile";
export var ContextURL = "profileapi/odata/context";
export var PreferencesUrl = "profileapi/odata/preferences";
export var TemplateUrl = "templateapi/odata/template";
export var ProfileTncUrl = "profileapi/api/TnCPrompt/GetTnCPrompt";
export var FirstTimeUserDetailsUrl =
  "profileapi/api/LoginInfo/GetFirstTimeLoginDetails";
export var VerifyTimeUserDetailsUrl =
  "profileapi/api/LoginInfo/ValidateLoginDetails";
export var AddPhoneMethodUrl = "multifactorauthapi/api/PhoneMethods/add";
export var UpdateFirstTimeLoginDetailsUrl =
  "profileapi/api/logininfo/UpdateFirstTimeLogin";
export var GetInternationalCallingCodesUrl =
  "multifactorauthapi/api/PhoneMethods/internationalCallingCodes";
export var UpdateMfaSkipCounterUrl =
  "profileapi/api/LoginInfo/UpdateSkipCounter";

export var CheckRedirectVersionUrl = "registration/api/Registration/CheckRoute1"  

export var BalancessummaryURL = "balance/odata/balancesummary?AccountList=";
export var BalancesdetailURL = "balance/odata/balancedetails?AccountList=";

export var HoldingssummaryURL = "holdings/odata/Position/Top10Holding(";
export var HoldingsbysecurityURL = "holdings/odata/Position/SecurityPosition(";
export var HoldingsbyaccountURL = "holdings/odata/Position/AccountPosition(";
export var HoldingsbycategoryURL =
  "holdings/odata/Position/GetPositionByCategorySecurity(";

export var RealizedGainLossURL = "holdings/odata/Position/GetRealizedGainLoss(";
export var EstimatedIncomeSummaryURL =
  "holdings/odata/Position/GetEstimatedIncomeSummary(";
export var EstimatedIncomeURL = "holdings/odata/Position/GetEstimatedIncomeBy(";
export var ProjectedIncomeSummaryURL =
  "holdings/odata/Position/GetProjectedIncomeSummary(";
export var ProjectedIncomeURL = "holdings/odata/Position/GetProjectedIncomeBy(";

export var AssetallocationURL = "holdings/odata/Position/CategoryPosition(";
export var HistoricalAssetAllocationURL = "assetallocapi/api/assetallocation";
export var ActivitysummaryURL =
  "activityapi/odata/activitysummary?AccountList=";
export var TASsummaryURL = "tas/odata/TAS/TaxLotOpenByAcctSec(secId=";

export var DocumentListURL = "documentsapi/odata/document/RetrieveDocumentList";
export var DocumentURL = "documentsapi/odata/document/RetrieveDocument";
export var StichedPdfDocumentURL =
  "documentsapi/odata/document/RetrieveStichedPdfDocument";

export var customizationComponentURL =
  "profileapi/odata/onlineportalcustomization";

export var AccountUpdateUrl = "profileapi/odata/account";

export var FeedbackURL = "profileapi/odata/feedback";
export var MailURL = "mail/api/mailV2";

export var MyProfileURL =
  "https://account.activedirectory.windowsazure.com/r#/profile";
export var MyProfileGoogleURL =
  "https://account.activedirectory.windowsazure.com/r?tenantid=74352aa5-d883-4e4a-8423-7862b342708e&login_hint=";
export var TermsOfUseURL =
  "https://account.activedirectory.windowsazure.com/r#/termsOfUse/myAcceptances";
export var TermsOfUseGoogleURL =
  "https://account.activedirectory.windowsazure.com/r#/termsOfUse/myAcceptances";
export var MyAccountURL =
  "https://account.activedirectory.windowsazure.com/Proofup.aspx";
export var MyAccountGoogleURL = "https://myaccount.google.com";

export var manageGroupsURL = "profileapi/odata/accountgroup";
export var updateNickNameURL = "profileapi/odata/updateaccountnickname";
export var sortGroupAccountURL = "profileapi/odata/accountgroupsort";
export var manageAssetURL = "manualassets/api/ManageAssets";

export var edeliveryURL = "profileapi/odata/edeliveryemail";
export var alertsURL = "alerts/api";
export var alertSummaryURL = "alerts/api/AlertSummary";
export var alertDetailsURL = "alerts/api/AlertDetails";
export var alertMarkAsReadURL = "alerts/api/MarkAlert";

export var charitySearchURL = "grantmaking/charity/search";
export var charityListURL = "grantmaking/charity?grantMaker=?";
//export var grantListURL = 'grantmaking/grant?user=me'
export var grantListURL = "grantmaking/grant?grantMaker=?";
export var createGrantURL = "grantmaking/grant?grantMaker=";
export var donorListURL = "grantmaking/donor?grantMaker=?";
export var accountInfoURL = "mm/odata/MMSummary?";
export var getReportsURL =
  "documentsapi/api/reports/GetClientOnlineReportList?profileid=";
export var downloadReportURL =
  "documentsapi/api/reports/DownloadReport?storagedocid=";

export const TopTenHoldingPct = 100;
export const AssetAllocationHelp =
  "https://wwwxq1.mystreetscape.com/my/rockefeller/jsp/msshelp/default/help.jsp?ContentPage=msshlpaccountpositions.htm&frommenu=Y";

export var performanceSummaryURL =
  "holdings/odata/position/GetPerformanceSummary";
export var performanceDataURL = "holdings/odata/position/GetPerformanceData";

export var taxDocumetOTPURL = "extauth/api/UserAuth/GenerateOTToken";

export var alreadyMemberWUUrl = "";
export var contactWUUrl = "";

export var avationPortfolioUrl = "";
export var contactFlyexclusiveUrl = "";

export var wheelsUpUrl = "extauth/api/ExtAuth/StartSession";
export var wheelsUpPdfContentUrl = "profileapi/api/BlobStorage";

export var wheelsUpDocumentFolder = "";
export var wheelsUpDocumentPdf = "";
export var caseManagementEmail = "";
export var transferWithholdingNoticePdf = "";

export var learnMorePCUrl = "";
export var alreadyMemberPCUrl = "";
export var contactPCUrl = "";
export var nextGenHealthUrl = "";
export var fineArtGroupUrl = "";
export var christiesUrl = "";
export var pinnacleCareUrl = "";
export var projectYouUrl = "";
export var elderlyCareUrl = "";
export var summusUrl = "";

export var apiVersion = "";
export var loginDomainFilters = "";
export var maskAccountTimeout = 0;

export var assetSchemaColorsConfigUrl =
  "templateapi/api/Template/StaticConfig?version=v2";
export var planManagerUrl = "";
export var clientOnlineTutorialUrl =
  "https://rcmbrand.rockco.com/Guide/RCM_Online_Summary.pdf";
export var billPayTutorialUrl =
  "https://rcmbrand.rockco.com/Guide/RCM_Online_Bill_Pay_Guide.pdf";
export const mobileTutorialUrl =
  "https://rcmbrand.rockco.com/RCM_Online_Mobile_Summary.pdf";
export var ourInsightsUrl = "https://rcm.rockco.com/our-ideas/";
export var ourInsightsLabel = "VIEW ROCKEFELLER INSIGHTS";
export var accountInfoDetailServiceurl = "profileapi/api/accountinfo?account=";

export const FileType = {
  PDF: "pdf",
  CSV: "csv",
};
export const MessageType = {
  SUCCESS: "Success",
  ERROR: "Error",
  INFO: "Info",
  WARNING: "Warning",
  LABEL: "Label",
  PREFERENCE_WARNING: "PREFERENCE_WARNING",
};

export const MessageClass = {
  SUCCESS: "alert alert-success alert-dismissible fade show",
  ERROR: "RCM_validation_error_text",
  INFO: "alert alert-info alert-dismissible fade show",
  WARNING: "alert alert-warning alert-dismissible fade show",
  LABEL: "",
  PREFERENCE_WARNING: "",
};

export const DocumentType = {
  ACCOUNTSTATEMENT: "Account Statement",
  CORRESPONDENCE: "Correspondence",
  TAXDOCUMENT: "Tax Docouments",
  TRADECONFIRM: "Trade Confirm",
};

export const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/;
export const alphanumericFormat = /^[0-9a-zA-Z ]+$/;
export const alphanumericnameFormat = /^[a-zA-Z 0-9'/-]+$/;
export const basicAlphaNumeric = /^[a-zA-Z0-9 -]+$/;
export const discriptionFormat = /^[a-zA-Z0-9,\.@%()' -]+$/;
export const zipFormat = /^[0-9 -]*$/;
export const numericFormat = /^[0-9]*$/;
export const alphabitFormat = /^[a-zA-Z ]+$/;
export const yearFormat = /^[0-9]+$/;
export const interestRateFormat = /^[0-9]+(\.)?([0-9]{1,4})?$/;

export const EmailErrorMsg = "Please select a valid email address.";
export const PhoneErrorMsg = "Please enter a valid phone number.";
export const ConfirEmailErrorMsg = "Confirmation email is not same.";
export const MsgDocumentLoading = "Loading Document List.";
export const MsgForFailedData = "Failed to fetch data, please try again.";
export const MsgForNoData = "No data available.";
export const MsgLoadingTaxDocuments = "Loading Documents.";
export const MsgSelectFromTop = "Please select account from top.";
export const MsgSelectDocument =
  "Please select an item to preview the document";
export const MsgSelectRockoActs =
  "Please select a Rockefeller account to view Documents and Statements";
export const errorMsg =
  "We could not process your request at this time. Please try again or contact your Advisor for assistance.";
export const aggregatedAcctAddMsg =
  "Account(s) have been successfully added to aggregate and could take up to 48 hours to show up in your profile.";
export const billPayProspectMsg =
  "Bill Pay is not available under preview mode";
export const MsgPasswordStatus =
  "We are experiencing issues updating this account. Typically, these issues are caused due to changes at the financial institution. These issues generally resolve themselves in a few days. If you have any concerns, please contact your advisor.";
export const viewOnlyText = "This asset is displayed in read only view.";
export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    if (amount) {
      amount = parseFloat(amount);
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimalCount,
      });
    }
  } catch (e) {
    console.log(e);
  }
}
export function formatMoneyForPE(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    if (amount || amount === 0) {
      amount = parseFloat(amount);
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function formatMoneyWithoutCurrency(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    if (amount) {
      amount = parseFloat(amount);
      return amount.toLocaleString("en-US");
    }
  } catch (e) {
    console.log(e);
  }
}

export function roundvalue(value, decimals) {
  if (!value) return "-";
  var floatValue = parseFloat(value);
  return floatValue.toFixed(decimals);
}
export function roundvalueForNumbers(value, decimals) {
  var floatValue = parseFloat(value);
  return floatValue.toFixed(decimals);
}
export function convertDttime(value) {
  let datevalue = new Date(value).toLocaleDateString("en-US");
  let timevalue = new Date(value).toLocaleTimeString("en-US");
  return datevalue + " " + timevalue;
}
export function toDate(value) {
  let datevalue = new Date(value).toLocaleDateString("en-US");
  let timevalue = new Date(value).toLocaleTimeString("en-US");
  return datevalue;
}

export function spinner() {
  return (
    <div>
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="primary" />
    </div>
  );
}

export function setBaseUrl(baseURL) {
  if (eligiblityPairServiceUrl.includes("http")) return;
  //baseURL = window && window.sessionStorage && window.sessionStorage.getItem('baseurl');
  console.log("Base Url : ", baseURL);

  assetagUrl = baseURL + assetagUrl;

  eligiblityPairServiceUrl = baseURL + eligiblityPairServiceUrl;
  mmvalidateUrl = baseURL + mmvalidateUrl;
  addTransferUrl = baseURL + addTransferUrl;
  activityTransfersUrl = baseURL + activityTransfersUrl;
  mmSummaryUrl = baseURL + mmSummaryUrl;
  AccountUpdateUrl = baseURL + AccountUpdateUrl;

  validateABAUrl = baseURL + validateABAUrl;
  getSIUrl = baseURL + getSIUrl;
  getFirstPartyEligibleAcctUrl = baseURL + getFirstPartyEligibleAcctUrl;
  getThirdPartyEligibleAcctUrl = baseURL + getThirdPartyEligibleAcctUrl;
  validateSIUrl = baseURL + validateSIUrl;
  submitSI = baseURL + submitSI;

  uploadToBpm = baseURL + uploadToBpm;

  getAALoginTokenUrl = baseURL + getAALoginTokenUrl;
  getAALogoutTokenUrl = baseURL + getAALogoutTokenUrl;
  updateAssetAggrAcct = baseURL + updateAssetAggrAcct;
  getAssetAggrAcctList = baseURL + getAssetAggrAcctList;
  deleteAggrAcctUrl = baseURL + deleteAggrAcctUrl;
  updatePasswordStatusUrl = baseURL + updatePasswordStatusUrl;

  ProfileURL = baseURL + ProfileURL;
  ProfileUpdateUrl = baseURL + ProfileUpdateUrl;
  ContextURL = baseURL + ContextURL;
  PreferencesUrl = baseURL + PreferencesUrl;
  TemplateUrl = baseURL + TemplateUrl;
  ProfileTncUrl = baseURL + ProfileTncUrl;
  FirstTimeUserDetailsUrl = baseURL + FirstTimeUserDetailsUrl;
  VerifyTimeUserDetailsUrl = baseURL + VerifyTimeUserDetailsUrl;
  AddPhoneMethodUrl = baseURL + AddPhoneMethodUrl;
  UpdateFirstTimeLoginDetailsUrl = baseURL + UpdateFirstTimeLoginDetailsUrl;
  GetInternationalCallingCodesUrl = baseURL + GetInternationalCallingCodesUrl;
  UpdateMfaSkipCounterUrl = baseURL + UpdateMfaSkipCounterUrl;
  CheckRedirectVersionUrl = baseURL + CheckRedirectVersionUrl;
  BalancessummaryURL = baseURL + BalancessummaryURL;
  BalancesdetailURL = baseURL + BalancesdetailURL;

  HoldingssummaryURL = baseURL + HoldingssummaryURL;
  HoldingsbysecurityURL = baseURL + HoldingsbysecurityURL;
  HoldingsbyaccountURL = baseURL + HoldingsbyaccountURL;
  HoldingsbycategoryURL = baseURL + HoldingsbycategoryURL;

  AssetallocationURL = baseURL + AssetallocationURL;
  HistoricalAssetAllocationURL = baseURL + HistoricalAssetAllocationURL;
  ActivitysummaryURL = baseURL + ActivitysummaryURL;
  TASsummaryURL = baseURL + TASsummaryURL;

  DocumentListURL = baseURL + DocumentListURL;
  DocumentURL = baseURL + DocumentURL;
  StichedPdfDocumentURL = baseURL + StichedPdfDocumentURL;
  FeedbackURL = baseURL + FeedbackURL;
  MailURL = baseURL + MailURL;

  manageGroupsURL = baseURL + manageGroupsURL;
  updateNickNameURL = baseURL + updateNickNameURL;
  sortGroupAccountURL = baseURL + sortGroupAccountURL;

  wheelsUpUrl = baseURL + wheelsUpUrl;
  wheelsUpPdfContentUrl = baseURL + wheelsUpPdfContentUrl;

  customizationComponentURL = baseURL + customizationComponentURL;
  manageAssetURL = baseURL + manageAssetURL;
  RealizedGainLossURL = baseURL + RealizedGainLossURL;
  EstimatedIncomeSummaryURL = baseURL + EstimatedIncomeSummaryURL;
  EstimatedIncomeURL = baseURL + EstimatedIncomeURL;

  ProjectedIncomeSummaryURL = baseURL + ProjectedIncomeSummaryURL;
  ProjectedIncomeURL = baseURL + ProjectedIncomeURL;

  alertsURL = baseURL + alertsURL;
  alertSummaryURL = baseURL + alertSummaryURL;
  alertDetailsURL = baseURL + alertDetailsURL;
  alertMarkAsReadURL = baseURL + alertMarkAsReadURL;
  edeliveryURL = baseURL + edeliveryURL;
  charitySearchURL = baseURL + charitySearchURL;
  charityListURL = baseURL + charityListURL;
  grantListURL = baseURL + grantListURL;
  createGrantURL = baseURL + createGrantURL;
  donorListURL = baseURL + donorListURL;
  performanceSummaryURL = baseURL + performanceSummaryURL;
  performanceDataURL = baseURL + performanceDataURL;

  taxDocumetOTPURL = baseURL + taxDocumetOTPURL;
  assetSchemaColorsConfigUrl = baseURL + assetSchemaColorsConfigUrl;
  accountInfoURL = baseURL + accountInfoURL;
  accountInfoDetailServiceurl = baseURL + accountInfoDetailServiceurl;
  getReportsURL = baseURL + getReportsURL;
  downloadReportURL = baseURL + downloadReportURL;
}

export function setBillPayUIUrl(billpayUrl) {
  billPayUIUrl = billpayUrl;
}
export function setPlanManagerUrl(url) {
  planManagerUrl = url;
}
export function setNinthWavesConfig(environmentVariables) {
  nwScriptUrl = environmentVariables.ninthwavesScriptUrl;
  nwDbUrl = environmentVariables.ninthwavesDbUrl;
  ninthwavesAppId = environmentVariables.ninthwavesAppId;
}

export function setWheelsUpConfig(environmentVariables) {
  wheelsUpDocumentFolder = environmentVariables.wheelsUpDocumentFolder;
  wheelsUpDocumentPdf = environmentVariables.wheelsUpDocumentPdf;
  alreadyMemberWUUrl = environmentVariables.wheelsUpAlreadyMemberUrl;
  contactWUUrl = environmentVariables.wheelsUpContactUrl;
}

export function setAvationPortfolioConfig(environmentVariables) {
    avationPortfolioUrl = environmentVariables.avationPortfolioUrl;
    contactFlyexclusiveUrl = environmentVariables.contactFlyexclusiveUrl;
}

export function setPinnacleCareConfig(environmentVariables) {
  learnMorePCUrl = environmentVariables.pinnacleCareLearnMore;
  alreadyMemberPCUrl = environmentVariables.pinnacleCareAlreadyMemberURL;
  contactPCUrl = environmentVariables.pinnacleCareContactURL;
  nextGenHealthUrl = environmentVariables.nextGenHealthURL;
  fineArtGroupUrl = environmentVariables.fineArtGroupURL;
  christiesUrl = environmentVariables.christiesURL;
  projectYouUrl = environmentVariables.projectYouURL;
  elderlyCareUrl = environmentVariables.elderlyCareURL;
  summusUrl = environmentVariables.summusURL;
}

export function setCaseManagerConfig(environmentVariables) {
  caseManagementEmail = environmentVariables.caseManagementEmail;
}

export function setTransfersConfig(environmentVariables) {
  transferWithholdingNoticePdf = environmentVariables.withholdingNoticePdf;
}

export function setApiConfig(environmentVariables) {
  apiVersion = environmentVariables.apiVersion;
}
export function setEnvConfig(environmentVariables) {
  if (
    environmentVariables.ourInsightsUrl &&
    environmentVariables.ourInsightsUrl.length > 0
  )
    ourInsightsUrl = environmentVariables.ourInsightsUrl;
  if (
    environmentVariables.ourInsightsLabel &&
    environmentVariables.ourInsightsLabel.length > 0
  )
    ourInsightsLabel = environmentVariables.ourInsightsLabel;
  if (environmentVariables.maskAccountTimeout)
    maskAccountTimeout = environmentVariables.maskAccountTimeout;
}

export function formatNumberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function roundUnitValue(cell) {
  if (!cell) return "-";
  var result = roundvalue(cell, 2) - Math.floor(cell, 2) !== 0;
  let value;
  if (result) {
    value = roundvalue(cell, 2);
  } else {
    value = Math.floor(cell);
  }
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); //this regex is used for thousand seprator
}

export function roundUnitValueWithPrecision(cell, precisionVal) {
  if (!cell) return "-";
  var result = roundvalue(cell, precisionVal) - Math.floor(cell) !== 0;
  let value;
  if (result) {
    value = roundvalue(cell, precisionVal);
  } else {
    value = Math.floor(cell);
  }
  return formatNumberWithCommas(value); //this regex is used for thousand seprator
}

export function GetNumericValue(
  cell,
  ispercentage = false,
  isunit = false,
  precision = false
) {
  if (cell === null || cell === undefined || cell === "") {
    return "-";
  } else if (cell === 0) {
    if (isunit) {
      return cell;
    } else if (ispercentage) {
      return "0%";
    } else {
      return "$0";
    }
  } else if (cell < 0) {
    if (isunit) {
      return roundUnitValue(cell);
    } else if (ispercentage) {
      return roundvalue(cell, 2) + "%";
    } else {
      if (precision) formatMoney(roundvalue(cell, 4));
      else return formatMoney(cell);
    }
  }
}
export function GetTextValue(cell) {
  if (cell === null || cell === undefined || cell === "") {
    return "-";
  } else {
    return cell;
  }
}
function GetAccountDisplayName(act) {
  if (act) {
    let nickName = "";
    let regTypeCode = "";
    if (act.nickname) nickName = act.nickname;
    if (act.regtypecode) regTypeCode = act.regtypecode;
    let actDisplayName = act.number + " " + nickName + " (" + regTypeCode + ")";
    return actDisplayName;
  } else {
    return "";
  }
}
export function GetAccountsList(selectedaccount) {
  let accounts = [];
  if (selectedaccount && selectedaccount.length > 1) {
    accounts.push({
      number: "All Accounts",
      nickname: "",
      regtypecode: "",
      accountdisplayname: "All Accounts",
      i: null,
      isallaccount: true,
    });
  }
  let j = 0; // this is used for sending options for pagination in bootstrap table
  selectedaccount &&
    selectedaccount.map((act) => {
      accounts.push({
        number: act.number,
        nickname: act.nickname,
        accountdisplayname: act.displaynickname[0].dispshortname,
        regtypecode: act.regtypecode,
        isInternal: act.isInternal,
        key: act.key ? act.key : null,
        i: j,
      });
      j++;
    });

  return accounts;
}
export function tabSelection(tabLinks, param) {
  if (!param.active) {
    for (const [index, value] of tabLinks.entries()) {
      if (value.displayName == param.displayName) {
        value.active = true;
      } else {
        value.active = false;
      }
    }
  }
  return tabLinks;
}
export function tabSelectionOnLoad(
  tabsLinks,
  currentRoute,
  defaultRoute,
  parentRoute
) {
  for (const [index, value] of tabsLinks.entries()) {
    if (
      (value.tabUrl === defaultRoute && currentRoute === parentRoute) ||
      value.tabUrl == currentRoute
    ) {
      value.active = true;
    } else {
      value.active = false;
    }
  }
  return tabsLinks;
}

export const IndLongShort = {
  LONG: 1,
  SHORT: 2,
  SINGLEVIEW: 3,
};

export function getAccountDisplayName(
  accountsList,
  currentAccount,
  indLongShort
) {
  let displayName = currentAccount;
  if (
    accountsList !== null &&
    accountsList !== undefined &&
    Array.isArray(accountsList) &&
    accountsList.length > 0 &&
    currentAccount !== null &&
    currentAccount !== undefined &&
    indLongShort !== null &&
    indLongShort !== undefined
  ) {
    //compare account with current Account
    for (let index = 0; index < accountsList.length; index++) {
      const account = accountsList[index];
      if (
        account &&
        account.key &&
        account.key === currentAccount &&
        account.displaynickname &&
        Array.isArray(account.displaynickname) &&
        account.displaynickname.length > 0
      ) {
        //return long and short name based on need
        let displayNames = account.displaynickname[0];
        let shortDispName =
          displayNames && displayNames.dispshortname
            ? displayNames.dispshortname
            : "";
        let longDispName =
          displayNames && displayNames.displongname
            ? displayNames.displongname
            : "";
        let singleView =
          displayNames && displayNames.dispname ? displayNames.dispname : "";
        if (
          singleView === null ||
          singleView === undefined ||
          singleView.trim().length <= 0
        )
          singleView = singleView; // TODO We need to show FI NAme + Last 4 of Acctnum as default.
        switch (indLongShort) {
          case IndLongShort.LONG: {
            displayName = longDispName;
            break;
          }

          case IndLongShort.SHORT: {
            displayName = shortDispName;
            break;
          }

          case IndLongShort.SINGLEVIEW: {
            displayName = singleView;
            break;
          }

          default: {
            break;
          }
        }
        break;
      }
    }
  }
  return displayName;
}
export function getAccountToolTip(account, indLongShort) {
  let displayName = "";

  if (
    account.displaynickname &&
    Array.isArray(account.displaynickname) &&
    account.displaynickname.length > 0
  ) {
    //return long and short name based on need
    let displayNames = account.displaynickname[0];
    let shortDispName =
      displayNames && displayNames.dispshortname
        ? displayNames.dispshortname
        : "";
    let longDispName =
      displayNames && displayNames.displongname
        ? displayNames.displongname
        : "";
    let singleView =
      displayNames && displayNames.dispname ? displayNames.dispname : "";
    switch (indLongShort) {
      case IndLongShort.LONG: {
        displayName = longDispName;
        break;
      }
      case IndLongShort.SHORT: {
        displayName = shortDispName;
        break;
      }
      case IndLongShort.SINGLEVIEW: {
        displayName = singleView;
        break;
      }
      default: {
        break;
      }
    }
  }
  return displayName;
}

export function decodeJwtToken(token) {
  let response = null;
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    response = JSON.parse(jsonPayload);
  } catch (exception) {
    //
  }
  return response;
}
export function getFormattedTime() {
  var today = new Date();
  var y = today.getFullYear();
  // JavaScript months are 0-based.
  var m = today.getMonth() + 1;
  var d = today.getDate();
  var h = today.getHours();
  var mi = today.getMinutes();
  var s = today.getSeconds();
  return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
}

export function GetHeaderHtmlPrint(props) {
  let balanceSummaryDetails = [];
  let table =
    "<table cellspacing='0' width='100%'><tr><td width='30%'><div><b>ROCKEFELLER</b></div><div>CAPITAL MANAGEMENT</div><div><b>" +
    (props && props.name ? props.name : "") +
    "</b></div></td>";
  if (props && props) {
    table += "<td valign='top' width='35%'><table cellspacing='0' ><tr>";
    //prepare table header columns
    let pricedinvestments = "-";
    let cashmoneyaccounts = "-";
    let totalaccountvalue = "-";
    let totalaccountvaluechange;
    let totalaccountvaluechangepct;
    let annuity = "-";
    let pendingactivity = "-";
    let othercreditdebit = "-";
    let marginandoutstandingbalance = "-";
    if (
      props.balancedetails &&
      props.balancedetails.value &&
      props.balancedetails.value.length > 0 &&
      props.balancedetails.value[0] &&
      props.balancedetails.value[0].accountbalances
    ) {
      pricedinvestments = props.balancedetails.value[0].pricedinvestments
        ? formatMoney(props.balancedetails.value[0].pricedinvestments)
        : GetNumericValue(props.balancedetails.value[0].pricedinvestments);
      cashmoneyaccounts = props.balancedetails.value[0].cashmoneyaccounts
        ? formatMoney(props.balancedetails.value[0].cashmoneyaccounts)
        : GetNumericValue(props.balancedetails.value[0].cashmoneyaccounts);
      totalaccountvalue = props.balancedetails.value[0].totalaccountvalue
        ? formatMoney(props.balancedetails.value[0].totalaccountvalue)
        : GetNumericValue(props.balancedetails.value[0].totalaccountvalue);
      totalaccountvaluechange =
        props.balancedetails.value[0].totalaccountvaluechange; // formatMoney(this.props.balancedetails.value[0].totalaccountvaluechange) : GetNumericValue(this.props.balancedetails.value[0].totalaccountvaluechange);
      totalaccountvaluechangepct =
        props.balancedetails.value[0].totalaccountvaluepercentchange; //?// roundvalue(this.props.balancedetails.value[0].totalaccountvaluepercentchange) : GetNumericValue(this.props.balancedetails.value[0].totalaccountvaluepercentchange,true);
      annuity = props.balancedetails.value[0].annuity
        ? formatMoney(props.balancedetails.value[0].annuity)
        : GetNumericValue(props.balancedetails.value[0].annuity);
      pendingactivity = props.balancedetails.value[0].pendingactivity
        ? formatMoney(props.balancedetails.value[0].pendingactivity)
        : GetNumericValue(props.balancedetails.value[0].pendingactivity);
      othercreditdebit = props.balancedetails.value[0].othercreditdebit
        ? formatMoney(props.balancedetails.value[0].othercreditdebit)
        : GetNumericValue(props.balancedetails.value[0].othercreditdebit);
      marginandoutstandingbalance = props.balancedetails.value[0]
        .marginandoutstandingbalance
        ? formatMoney(props.balancedetails.value[0].marginandoutstandingbalance)
        : GetNumericValue(
            props.balancedetails.value[0].marginandoutstandingbalance
          );
    }
    if (
      props &&
      props &&
      props.balancessummary &&
      props.balancessummary.value &&
      props.balancessummary.value[0]
    ) {
      //prepare table row data

      if (
        props &&
        props &&
        props.balancessummary &&
        props.balancessummary.value[0]
      ) {
        balanceSummaryDetails = props.balancessummary.value[0];
        balanceSummaryDetails.marginbalancehange =
          balanceSummaryDetails.marginbalancehange
            ? balanceSummaryDetails.marginbalancehange
            : 0;
      }
      table +=
        "<td style='text-align:left;font-size: 12px;'>Total Account Value</td>";
      if (totalaccountvalue) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" +
          totalaccountvalue +
          "</td>";
      } else {
        table += "<td style='text-align:left;font-size: 12px;'>&nbsp;</td>";
      }
      table += "</tr>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;padding-left:0.8rem;'>Priced Investments</td>";
      if (pricedinvestments) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" +
          pricedinvestments +
          "</td>";
      } else {
        table += "<td style='text-align:left;font-size: 12px;'>&nbsp;</td>";
      }
      table += "</tr>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;padding-left:0.8rem;'>Cash/Money Accounts</td>";
      if (cashmoneyaccounts) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" +
          cashmoneyaccounts +
          "</td>";
      } else {
        table += "<td style='text-align:left;font-size: 12px;'>&nbsp;</td>";
      }
      table += "</tr>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;padding-left:0.8rem;'>Insurance & Annuities</td>";
      if (annuity) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" + annuity + "</td>";
      } else {
        table += "<td style='text-align:left;font-size: 12px;'>&nbsp;</td>";
      }
      table += "</tr>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;;padding-left:0.8rem;'>Other Credit / Debit</td>";
      if (othercreditdebit) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" +
          othercreditdebit +
          "</td>";
      } else {
        table += "<td style='text-align:left;font-size: 12px;'>&nbsp;</td>";
      }

      table += "</tr></table></td>";
      table += "<td valign='top' width='35%'><table cellspacing='0'>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;padding-top:1.5rem;'>Today's Change</td>";
      if (totalaccountvaluechange && totalaccountvaluechangepct) {
        let val = totalaccountvaluechange
          ? formatMoney(totalaccountvaluechange, 2)
          : GetNumericValue(totalaccountvaluechange);
        let pct = totalaccountvaluechangepct
          ? `${roundvalue(totalaccountvaluechangepct, 2)}%`
          : GetNumericValue(totalaccountvaluechangepct, true);
        table +=
          "<td style='text-align:right;font-size: 12px;;padding-top:1.5rem;'>" +
          (val + "/" + pct) +
          "</td>";
      } else {
        table +=
          "<td style='text-align:left;font-size: 12px;;padding-top:1.5rem;' >&nbsp;</td>";
      }
      table += "</tr>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;'>Pending Activity</td>";
      if (pendingactivity) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" +
          pendingactivity +
          "</td>";
      } else {
        table += "<td style='AZtext-align:left;font-size: 12px;'>&nbsp;</td>";
      }
      table += "</tr>";
      table += "<tr>";
      table +=
        "<td style='text-align:left;font-size: 12px;'>Margin/Outstanding Balance</td>";
      if (marginandoutstandingbalance) {
        table +=
          "<td style='text-align:right;font-size: 12px;'>" +
          marginandoutstandingbalance +
          "</td>";
      } else {
        table += "<td style='text-align:left;font-size: 12px;'>&nbsp;</td>";
      }
    }
    table += "</tr></table></td></tr>";
  }
  return table;
}

export function UserLogOut(authProvider, email) {
  let accounts = [];
  if (authProvider) {
    accounts = authProvider.getAllAccounts();
    console.log("Account for logout", JSON.stringify(accounts[0]));
  }

  let userObject = { name: "unknown" };
  const user = userinfo;
  if (user) {
    userObject = JSON.parse(user);
  }
  let userEmail = email
    ? email
    : getNestedObject(userObject, ["idTokenClaims", "preferred_username"]);
  _logEvent("Initating User Logout", userEmail);
  RemoveSessionItemsDuringLogout();
  accounts.length > 0
    ? authProvider.logoutRedirect({ account: accounts[0] })
    : authProvider.logout();
}

export function RemoveSessionItemsDuringLogout() {
  sessionStorage.removeItem("clientid");
  sessionStorage.removeItem("authority");
  sessionStorage.removeItem("instrumentationKey");
  sessionStorage.removeItem("idletimeout");
  sessionStorage.removeItem("simulation");
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export function detectIE() {
  let a = 0;
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    a = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:");
    a = parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  var edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    a = parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return a > 0 ? true : false;
}

export function GetPreferenceValue(props, key, defaultvalue) {
  let preferencevalue = defaultvalue;
  if (props && props.preferences && props.preferences.component) {
    let filterrow = props.preferences.component.filter((p) => p.key === key);
    if (filterrow && filterrow.length > 0) {
      preferencevalue = filterrow[0].value;
    }
  }
  return preferencevalue;
}
export function GetPreferencePilotValue(props, key, defaultvalue) {
  let preferencevalue = defaultvalue;
  if (props && props.preferences && props.preferences.pilotfeatures) {
    let filterrow = props.preferences.pilotfeatures.filter(
      (p) => p.componentname === key
    );
    if (filterrow && filterrow.length > 0) {
      preferencevalue = filterrow[0].active;
    }
  }
  return preferencevalue;
}

export function getUpdatedAccountList(profileData) {
  let internalAccounts = [];
  if (profileData && profileData.accounts && profileData.accounts !== null) {
    internalAccounts = profileData.accounts;
    for (const [index, value] of internalAccounts.entries()) {
      value.isInternal = true;
    }
  }

  let externalAccountsList = [];
  let externalAccounts = [];
  if (
    profileData &&
    profileData.groupedexternalaccounts &&
    profileData.groupedexternalaccounts !== null
  ) {
    externalAccountsList = profileData.groupedexternalaccounts;
    for (const [index, value] of externalAccountsList.entries()) {
      for (const [index, item] of value.groupby.entries()) {
        item.isInternal = false;
        externalAccounts.push(item);
      }
    }
  }

  let accounts = [...internalAccounts, ...externalAccounts];
  return accounts;
}

//utility function to set the cookies
export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

//utility function to get the cookies
export const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cname) => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const validateEmailId = (emailid) => {
  var isValid = true;
  var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;
  if (emailid && pattern.test(emailid)) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const addIndexToList = (arr) => {
  if (arr && Array.isArray(arr) && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      arr[i]["UniqueIdx"] = i;
    }
  }
  return arr;
};

export const defaultPreference = {
  theme: "Dark",
  color: "Blue",
  showexternalaccount: "true",
  pilotfeatures: [
    { componentname: "Asset Aggregation", active: "true" },
    { componentname: "Mobile Minimum Version", active: "1.1.121231" },
    { componentname: "Lifestyle", active: "false" },
    { componentname: "Manage Groups", active: "true" },
    { componentname: "FontScaleVisiblity", active: "false" },
    { componentname: "Manage Assets", active: "false" },
    { componentname: "Mobile Logging", active: "false" },
    { componentname: "OnlinePortalCustomization", active: "false" },
  ],
  component: [
    { key: "AllAccountSelectorView", value: "group" },
    { key: "FontScale", value: "16" },
    { key: "accountoverviewsortorder", value: "Account|asc" },
    { key: "FontScaleVisiblity", value: "true" },
  ],
};

export const realizedGainLossAndEstIncomeFooterMsg =
  "The information herein is for informational purposes only. For tax reporting or calculations, you should only rely on your annual Tax Reporting Statement consisting of various Forms of 1099 (or any other appropriate tax form). Rockefeller Financial LLC cannot guarantee that the information herein is accurate, complete, or timely. Rockefeller Financial LLC does not provide legal or tax advice. You should consult with a tax or legal professional to address your particular situation.";
export const balancesDetailsFooterMsg =
  "In most instances, Balances for Manual Assets are not verifiable by Rockefeller Financial LLC and may not be covered by SIPC. The values shown in this report for your Balances for Manual Assets are based on information supplied by you and therefore the accuracy of the information contained in this summary has not been verified and cannot be guaranteed. Your account statement is the official record of the holdings, balances and values of portfolios, strategies, securities, and other assets with Rockefeller Financial LLC.";
export const balancesDetailsFooterMsg2 =
  "Historical value includes priced investments and cash and does not include margin and loan balances.";
export const balanceActicityAggreationDisclaimer =
  "Aggregated data is provided for your convenience and contains information with respect to investment positions held outside of Rockefeller Financial LLC.  In certain instances, systems limitations necessitate making adjustments to that transaction data in order to perform this aggregation.  As such, the data may vary from your account statements.  In all instances, your account statements are the true and official records of your positions and transactions.";
export const estimatedIncomeFooterMsgP1 =
  "The Estimated projected income calculations provided herein are hypothetical, not guaranteed and are not intended to replace your official account statements.  These projected calculations are based on current holdings, the most recent regular income distribution for each holding, and may include the estimated monthly cash flow from your current holdings based on an “as of” date and are subject to change.  All data included herein, including all charts, figures and graphs, is dependent upon the quality and accuracy of the information supplied to us from other sources.  While the information is believed to be true and accurate as of the date received, no guarantee is made as to its completeness or accuracy.  There is no guarantee that the projected income calculations will be achieved or that past performance is indicative of future results.  Projected Income calculations are meant to only reflect interest and dividend distributions. Capital gain distributions are not considered income for the purposes of these calculations.";
export const estimatedIncomeFooterMsgP2 =
  "Rockefeller Financial LLC does not provide legal or tax advice.  For tax reporting purposes, you should only rely on your annual Tax Reporting Statement consisting of various 1099 Forms (or any other appropriate tax document) and consult with a tax or legal professional to address your particular situation.";
export const historicalAssetAllocationFooterMsg =
  "Only Rockefeller accounts are shown on this screen.";
export const getYearList = (onwardYear) => {
  let yearsList = [];

  let currentYear = new Date().getFullYear();

  if (currentYear >= onwardYear) {
    for (let i = currentYear; i >= onwardYear; i--) {
      yearsList.push({ label: i, value: i });
    }
  } else {
    yearsList.push({ label: currentYear, value: currentYear });
  }

  return yearsList;
};

export function arrayFilterByValue(array, string) {
  let filteredArr = [];

  if (array && Array.isArray(array) && array.length > 0) {
    filteredArr = array.filter((o) => {
      return Object.keys(o).some((k) => {
        if (o[k] !== null && o[k] !== undefined && typeof o[k] === "string")
          return o[k].toLowerCase().includes(string.toLowerCase());
      });
    });
  }

  return filteredArr;
}
export function getDisplayName(account) {
  var actName =
    account.nickname !== undefined &&
    account.nickname !== null &&
    account.nickname.length > 0 &&
    account.displaynickname[0].dispname !== undefined &&
    account.displaynickname[0].dispname !== null &&
    account.displaynickname[0].dispname.length > 0
      ? account.displaynickname[0].dispname
      : account.displaynickname[0].displongname === undefined ||
        account.displaynickname[0].displongname === null ||
        account.displaynickname[0].displongname === ""
      ? ""
      : account.displaynickname[0].displongname;
  return actName;
}

export function toUTF8Array(str) {
  var utf8 = [];
  for (var i = 0; i < str.length; i++) {
    var charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
    // surrogate pair
    else {
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }
  return utf8;
}

export const CheckIfExternalAcct = (key, selectedAccounts) => {
  const filteracct = selectedAccounts.filter((x) => x.key === key);
  if (filteracct && filteracct.length > 0) {
    if (filteracct[0].isInternal === false) return true;
    else return false;
  }
  return false;
};
export const CheckIfProspectClient = (role) => {
  if (role && role === "Prospect") {
    return true;
  } else return false;
};
export const getDateTimeVal = (val) => {
  let formattedval = val;
  let strdate = "";

  if (val) {
    formattedval = new Date(Date.parse(val));
    var y = formattedval.getFullYear();
    // JavaScript months are 0-based.
    var m = formattedval.getMonth() + 1;
    var d = formattedval.getDate();
    var h = formattedval.getHours();
    var mi =
      formattedval.getMinutes() > 9
        ? formattedval.getMinutes()
        : "0" + formattedval.getMinutes();
    // var s = formattedval.getSeconds() > 9 ? formattedval.getSeconds() : '0' + formattedval.getSeconds();
    var hrstring = h > 12 ? h - 12 : h === 0 ? 12 : h;
    hrstring = hrstring > 9 ? hrstring : "0" + hrstring;
    strdate =
      (m > 9 ? m : "0" + m) +
      "/" +
      (d > 9 ? d : "0" + d) +
      "/" +
      y +
      " " +
      hrstring +
      ":" +
      mi +
      " " +
      (h > 12 ? "PM" : "AM");
  }
  return strdate;
};

export const identifyMarginAccount = (accounts, key) => {
  let isMargin = false;
  if (accounts && accounts.length > 0) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].key === key) {
        isMargin = accounts[i].ismargin ? accounts[i].ismargin : false;
        break;
      }
    }
  }
  return isMargin;
};

export const getDateString = (date, format = "mm/dd/yyyy") => {
  let newDate = date instanceof Date ? date : new Date(date);
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1;

  var yyyy = newDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var returnString = "";
  if (format === "yyyy-mm-dd") {
    returnString = yyyy + "-" + mm + "-" + dd;
  } else {
    returnString = mm + "/" + dd + "/" + yyyy;
  }
  return returnString;
};

export const getManualAssetBalance = (row) => {
  let type = row.account.info.type;
  let assetClass =
    type === "Mortgage" ||
    type === "Credit Card" ||
    type === "Loan" ||
    type === "Promissory Note Loan"
      ? "Liability"
      : "Asset";
  let cell = row.account.value.totalvalue;
  if (assetClass === "Asset") {
    if (cell === null || cell === "" || cell === undefined) return "-";
    else if (cell === 0) return "$0";
    else return formatMoney(roundvalue(cell, 2));
  } else {
    let outstandingbalance = row.account.value.outstandingbalance;
    if (
      outstandingbalance === null ||
      outstandingbalance === "" ||
      outstandingbalance === undefined
    )
      return "-";
    else if (outstandingbalance === 0) return "$0";
    else {
      let outstandingbalancevalue = "-" + outstandingbalance;
      return formatMoney(roundvalue(outstandingbalancevalue, 2));
    }
  }
};

//Optimized method to get nickName, toottip name in single loop.
export function getAccountNames(
  accountsList,
  currentAccount,
  longName = false,
  shortName = false,
  singleViewName = false
) {
  let names = {
    longName: currentAccount,
    shortName: currentAccount,
    singleView: currentAccount,
  };
  if (
    accountsList !== null &&
    accountsList !== undefined &&
    Array.isArray(accountsList) &&
    accountsList.length > 0 &&
    currentAccount !== null &&
    currentAccount !== undefined
  ) {
    let accountarray = accountsList.filter(
      (account) => account.key === currentAccount
    );
    let account = accountarray.length > 0 ? accountarray[0] : {};

    if (
      account &&
      account.displaynickname &&
      Array.isArray(account.displaynickname) &&
      account.displaynickname.length > 0
    ) {
      //return long and short name based on need
      let displayNames = account.displaynickname[0];
      let shortDispName =
        displayNames && displayNames.dispshortname
          ? displayNames.dispshortname
          : "";
      let longDispName =
        displayNames && displayNames.displongname
          ? displayNames.displongname
          : "";
      let singleView =
        displayNames && displayNames.dispname ? displayNames.dispname : "";
      if (
        singleView === null ||
        singleView === undefined ||
        singleView.trim().length <= 0
      )
        singleView = singleView;

      if (longName) {
        names = { ...names, longName: longDispName };
      }
      if (shortName) {
        names = { ...names, shortName: shortDispName };
      }
      if (singleViewName) {
        names = { ...names, singleView: singleView };
      }
    }
  }
  return names;
}

export const getUserEmailFromCookie = (salt) => {
  const encryptedEmail = decrypt(getCookie("rcmLoggedInUser"), salt);
  const isValidEmail = validateEmailId(encryptedEmail);
  return isValidEmail ? encryptedEmail : "";
};
