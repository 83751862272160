import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter} from 'react-router-dom';
import ErrorBoundary from '../../common/errorboundary';
import { tabSelection, tabSelectionOnLoad, GetPreferencePilotValue, GetPreferenceValue } from './../../common/constants.jsx';
import PlanManager from "./planmanager/planManager";
import PlanManagerConfirmationModal from './planmanager/planManagerconfirmationModal';

class Services extends Component {


    constructor(props){
        super(props);

        this.state = {
            tabsLinks: this.getTabsLinks(),
            preferences: this.props.preferences,
            isCustomView: this.getPreferenceValue(),
            planManagerModalState: false
        }

        this.handleSelections = this.handleSelections.bind(this);
        this.togglePlanManagerModal = this.togglePlanManagerModal.bind(this);
    }

    isViewRequired(source) {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewRequired = GetPreferencePilotValue(obj, source, "false") === "true" ? true : false;
        return isViewRequired;
    }
   
    getTabsLinks() {
        let defaultView = [];
        if (this.isViewRequired('PlanManager')) {
            defaultView.push({ tabUrl: '/PlanManager', active: true, displayName: 'PLAN MANAGER', id: 'RCM_planManagertab' });
        }
        return defaultView;
    }
    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }
    componentDidMount() {
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/PlanManager', '/Services');
        this.setState({ tabsLinks: tabsLinks });
    }
    handleSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }
    togglePlanManagerModal = ()=> {

        this.setState(prevState => ({
            planManagerModalState: !prevState.planManagerModalState
        }));
    }
    componentDidUpdate() {
        if (this.props.preferences && this.state.preferences) {
            if (this.props.preferences !== this.state.preferences) {
                let iscustomview = this.getPreferenceValue();
                this.setState({ isCustomView: iscustomview, preferences: this.props.preferences });
            }
        }


    }
    render() {
        const servicesMenu = (
            <div>
                <div className={this.state.isCustomView ? "RCM_Tab_container" : "RCM_NavTab_container"}>
                    <ul className="nav nav-tabs">
                        {this.state.tabsLinks && this.state.tabsLinks.map((tabLink, index) => {
                            return (
                                (tabLink.tabUrl === "/PlanManager") ?
                                    (<li key={index} className="nav-item" id={tabLink.id}>
                                        <button className="btn btn-link nav-link RCM_Transfer_BillPay_btn" onClick={this.togglePlanManagerModal}>{tabLink.displayName}</button>
                                    </li>) :
                                    <li key={index} className="nav-item active" id={tabLink.id}>
                                        <NavLink className="nav-link"
                                            exact to={tabLink.tabUrl}
                                            className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                            onClick={this.handleSelections.bind(this, tabLink)}
                                        >
                                            {tabLink.displayName}
                                        </NavLink>
                                    </li>)
                        })}
                    </ul>
                </div>
                <div>
                    <Switch>
                        <Route key='services' exact path="/Services" render={() => <Redirect to="/PlanManager" />} />
                        <Route key='planManager' exact path="/PlanManager" component={PlanManager} />
                    </Switch>
                </div>
            </div>

                );
        return (
            <>
                <ErrorBoundary>
                    <PlanManagerConfirmationModal planManagerModalState={this.state.planManagerModalState} togglePlanManagerModal={this.togglePlanManagerModal}></PlanManagerConfirmationModal>

                    <BrowserRouter>{servicesMenu}</BrowserRouter>
                </ErrorBoundary>
                
                </>
           
        )
    }
}
const mapStateToProps = (store) => {
    return {
        preferences: store.acct.preferences
    };
}

export default withRouter(connect(mapStateToProps)(Services));
