import React from "react";
import ErrorBoundary from "../../../../common/errorboundary.jsx";
import { AddHistoricalAssetAllocationDetails, UpdateAssetSchemaAssetDetails, UpdateAssetSchemaAssetSummary,AddHistoricalBalanceDetails, AddAssetColor } from "../../../../actions/accountsactions.jsx";
import { UpdateAssetSchema } from './../../../../actions/toptenactions';
import { compose } from "redux";
import { connect } from "react-redux";
import { withPageView } from "../../../../hoc/withPageView.jsx";
import { spinner, MsgForFailedData, CheckIfProspectClient } from "../../../../common/constants.jsx";
import DateRanges from "../../../../common/dateranges.jsx";
import { HistoricalAssetAllocationTableComponent } from "./HistoricalAssetAllocationTable";
import { HistoricalAssetAllocationFiltersComponent } from "./HistoricalAssetAllocationFilters";
import { HistoricalAssetAllocationChartComponent } from "./HistoricalAssetAllocationChart";
import { HistoricalAssetAllocationContainerBase } from "./HistoricalAssetAllocationContainerBase";
import Watermark from "../../../../common/watermark.jsx";

class HistoricalAssetAllocationContainer extends HistoricalAssetAllocationContainerBase {
    constructor(props) {
        super(props);

        this.state = {
            ...super.state,
            groupByAssetSubType: false,
            chartType: this.props.chartType ? this.props.chartType : "area",
            showTable: false,
            isProspectClient: CheckIfProspectClient(this.props.role)

        };
    }

    onFilterChanged = (newFilter,classValue) => {
        this.execute(newFilter, classValue);
    };

    onAssetGroupingChanged = type => {
        this.setState({
            groupByAssetSubType: type === "assetSubType"
        });
    };

    onChartTypeChanged = type => {
        this.setState({
            chartType: type
        });
    };

    componentWillUnmount() {
        if (
            this.aadcanellationToken !== null &&
            this.aadcanellationToken !== undefined
        ) {
            this.aadcanellationToken.cancel(
                "Cancelling the token during unmount to prevent memory leak."
            );
        }
    }

    render() {
        return (
            <div className="RCM_detailsScreeen">
                <div className="conatiner-fluid RCM_Toast">{this.renderContent()}</div>
            </div>
        );
    }

    renderContent() {
        const { historicalAssetAllocationDetails, historicalBalanceDetails } = this.props;
        const { isLoading, error, showTable, isProspectClient } = this.state;

        const initialFilters = ((this.state.chartType === 'area' ?historicalBalanceDetails:historicalAssetAllocationDetails) || {}).filters || {
            range: DateRanges.PRIOR12MONTHS
        };

        const hasData = this.state.chartType === 'area' ? (historicalBalanceDetails &&
            historicalBalanceDetails.data &&
            historicalBalanceDetails.data.length):
            (historicalAssetAllocationDetails &&
            historicalAssetAllocationDetails.data &&
            historicalAssetAllocationDetails.data.length);

        const data = hasData ? (this.state.chartType === 'area' ? historicalBalanceDetails.data: historicalAssetAllocationDetails.data) : [];

        const isLoadingOrHasError = isLoading || !!error;

        const LoadingOrError = () => {
            if (!isLoadingOrHasError) {
                return null;
            }

            return (
                <>
                    {isProspectClient ? <Watermark /> : ''}
                    {isLoading && (
                        <div className="RCM_spinner">
                            Loading Historical Value {spinner()}
                        </div>
                    )}
                    {error && (
                        <p className="RCM_data_error">{MsgForFailedData}</p>
                    )}
                </>
            );
        };

        return (
            <ErrorBoundary>
                <>{}
                    {isProspectClient ? <Watermark /> : ''}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="d-flex">
                                <div className="d-flex pr-3">
                                    <label className="RCM_Date_lbl text-left w-100">
                                        HISTORICAL VALUE
                  </label>
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <HistoricalAssetAllocationFiltersComponent
                                        dateRangeOptions={this.dateRangeOptions}
                                        initialFilters={initialFilters}
                                        onChange={this.onFilterChanged}
                                        onAssetGroupingChanged={this.onAssetGroupingChanged}
                                        onChartTypeChanged={this.onChartTypeChanged}
                                        chartType={this.state.chartType}
                                        assetGrouping={"assetType"}
                                        isProspectClient={CheckIfProspectClient(this.props.role)}
                                        assetSchema={this.props.assetSchema}
                                        UpdateAssetSchemaAssetDetails={this.props.UpdateAssetSchemaAssetDetails}
                                        UpdateAssetSchemaAssetSummary={this.props.UpdateAssetSchemaAssetSummary}
                                        UpdateAssetSchema={this.props.UpdateAssetSchema}
                                    />
                                </div>
                                <div className="RCM_expandviewrightWrapper" id="RCM_historical_chartTable_icon">
                                    <div>
                                        <button
                                            onClick={() => this.setState({ showTable: !showTable })}
                                            className={
                                                showTable
                                                    ? "RCM_left_toggle"
                                                    : "RCM_left_bar_toggle RCM_EstiamtedIncomeSummary_toggle_button_width"
                                            }
                                        ></button>
                                        <label
                                            className="RCM_togglelabeltext"
                                            onClick={() => this.setState({ showTable: !showTable })}
                                        >
                                            {showTable ? "Chart" : "Table"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!showTable ? (
                        <div className="row">
                            <div className="col-sm-12" id="RCM_historical_chartview">
                                {isLoadingOrHasError ? (
                                    <LoadingOrError />
                                ) : (
                                        <HistoricalAssetAllocationChartComponent
                                            groupByAssetSubType={false}
                                            style={{ height: "30rem" }}
                                            chartType={this.state.chartType}
                                            data={data}
                                            assetSchema={this.props.assetSchema}
                                            assetschemacolorsconfig={this.props.assetschemacolorsconfig}
                                        />
                                    )}
                            </div>
                        </div>
                    ) : (
                            <div className="row mt-3">
                                <div className="col-sm-12 overflow-auto" id="RCM_historical_tableview">
                                    {isLoadingOrHasError ? (
                                        <LoadingOrError />
                                    ) : (
                                            <HistoricalAssetAllocationTableComponent
                                                data={data}
                                                accounts={this.props.selectedaccount}
                                                chartType={this.state.chartType}
                                                assetSchema={this.props.assetSchema}
                                                assetschemacolorsconfig={this.props.assetschemacolorsconfig}
                                            />
                                        )}
                                </div>
                            </div>
                        )}
                </>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        portalid: state.acct.portalid,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        name: state.acct.name,
        historicalAssetAllocationDetails: state.acct.historicalAssetAllocationDetails,
        historicalBalanceDetails: state.acct.historicalBalanceDetails,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        role: state.acct.role,
        assetSchema: state.acct.assetSchema,
        assetschemacolorsconfig: state.acct.assetschemacolorsconfig
    };
};
const mapDispatchToProps = {
    AddHistoricalAssetAllocationDetails,
    AddHistoricalBalanceDetails,
    UpdateAssetSchemaAssetDetails,
    UpdateAssetSchemaAssetSummary,
    UpdateAssetSchema,
    AddAssetColor
};

const component = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: "Historical Asset Allocation" })
);

export default component(HistoricalAssetAllocationContainer);
