import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";
import DatePicker from 'react-datepicker';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, interestRateFormat, basicAlphaNumeric, discriptionFormat, viewOnlyText } from './../../../../../common/constants.jsx';

class CreditCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            displayoutstandingbalance: '',
            intialAccount: {},
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: 'Credit Card',
                    description: '',
                    issuername: '',
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date()
                },
                creditcard: {
                    loaninfo: {
                        outstandingbalance: "",
                        interestrate: "",
                        interesttype: "",
                        periodicprincipalpayment: "",
                        periodicinterestpayment: "",
                        paymentfrequency: "",
                        nextpaymentdate: new Date()
                    },
                    //borrowers: [
                    //    {
                    //        firstname: "",
                    //        lastname: ""
                    //    }
                    //]
                }
            },
            formStateChange: false,
            interesttypeoptions: ["Interest Only", "Fixed", "Variable"],
            paymentfrequencyoptions: ["Daily", "Monthly", "Quarterly", "Yearly"],
            isinteresttypeopen: false,
            ispaymentfrequencyopen: false,

        };

        this.interesttypetoggle = this.interesttypetoggle.bind(this);
        this.paymentfrequencytoggle = this.paymentfrequencytoggle.bind(this);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.interesttypechange = this.interesttypechange.bind(this);
        this.paymentfrequencychange = this.paymentfrequencychange.bind(this);
        //this.handleRemoveBorrower = this.handleRemoveBorrower.bind(this);
        //this.handleAddBorrower = this.handleAddBorrower.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {

            var displayoutstandingbalance = "";
            if (this.props.account.creditcard.loaninfo.outstandingbalance !== undefined && this.props.account.creditcard.loaninfo.outstandingbalance !== null
                && this.props.account.creditcard.loaninfo.outstandingbalance !== "")
                displayoutstandingbalance = parseFloat(this.props.account.creditcard.loaninfo.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({ account: this.props.account, intialAccount: initailAccount, displayoutstandingbalance: displayoutstandingbalance });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.initialaccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    //handleRemoveBorrower = id => () => {
    //    var account = this.state.account;
    //    var borrowers = account.creditcard.borrowers.filter((b, bid) => id !== bid);
    //    account.creditcard.borrowers = borrowers;
    //    this.setState({ account: account });
    //};
    //handleAddBorrower = () => {
    //    let clonedState = Object.assign({}, this.state);
    //    clonedState.account.creditcard.borrowers = this.state.account.creditcard.borrowers.concat([{ firstname: "", lastname: "" }]);
    //    this.setState(clonedState);
    //    //this.setState({
    //    //    shareholders: this.state.shareholders.concat([{ name: "" }])
    //    //});
    //};
    //handleBorrowerNameChange = id => evt => {
    //    let clonedState = Object.assign({}, this.state);
    //    const newBorrowers = clonedState.account.creditcard.borrowers.map((borrower, borrowerid) => {
    //        if (id !== borrowerid) return borrower;
    //        if (evt.target.name === 'firstname')
    //            return { ...borrower, firstname: evt.target.value };
    //        return { ...borrower, lastname: evt.target.value };
    //    });
    //    clonedState.account.creditcard.borrowers = newBorrowers;
    //    this.setState(clonedState);
    //    this.checkStateChange();
    //};
    interesttypechange(interesttypeval) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.creditcard.loaninfo.interesttype = interesttypeval;
        this.setState(clonedState);
        this.checkStateChange();
    }
    paymentfrequencychange(paymentfrequencyval) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.creditcard.loaninfo.paymentfrequency = paymentfrequencyval;
        this.setState(clonedState);
        this.checkStateChange();
    }

    interesttypetoggle() {
        this.setState(prevState => ({
            isinteresttypeopen: !prevState.isinteresttypeopen
        }))
    }
    paymentfrequencytoggle() {
        this.setState(prevState => ({
            ispaymentfrequencyopen: !prevState.ispaymentfrequencyopen
        }))
    }
    handleFormChanges(evt) {

        let clonedState = Object.assign({}, this.state);

        if (evt.target.name === "accountnumber") {
            if (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value) && evt.target.value.length <= 20)
                clonedState.account.info.accountnumber = evt.target.value;
        } else if (evt.target.name === "issuername") {
            if (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value) && evt.target.value.length <= 50) {
                clonedState.account.info.issuername = evt.target.value;
                clonedState.account.info.custodianname = evt.target.value;
            }
        }
        else if (evt.target.name === "servicer") {
            clonedState.account.info.servicer = evt.target.value;
        }
        else if (evt.target.name === "outstandingbalance") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayoutstandingbalance = evt.target.value;
        }
        else if (evt.target.name === "interestrate") {
            if (evt.target.value === "" || interestRateFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.creditcard.loaninfo.interestrate = evt.target.value;
        }
        else if (evt.target.name === "periodicprincipalpayment") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.creditcard.loaninfo.periodicprincipalpayment = evt.target.value;
        }
        else if (evt.target.name === "periodicinterestpayment") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.creditcard.loaninfo.periodicinterestpayment = evt.target.value;
        } else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        } else if (evt.target.name === "desc" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.description = evt.target.value;
            this.setState(clonedState);
        }
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.creditcard.loaninfo.outstandingbalance = evt.target.value;
            clonedState.displayoutstandingbalance = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.creditcard.loaninfo.outstandingbalance = evt.target.value;
            this.setState(clonedState);
        }
        this.checkStateChange();
    }

    handleAmmountOnFocus(event) {
        const clonedState = Object.assign({}, this.state);
        if (clonedState.account.creditcard.loaninfo.outstandingbalance === "") {
            clonedState.displayoutstandingbalance = "";
            clonedState.account.creditcard.loaninfo.outstandingbalance = "";
        } else {
            clonedState.displayoutstandingbalance = clonedState.account.creditcard.loaninfo.outstandingbalance;
        }
        this.setState(clonedState);
    }
    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_Creditcard_form">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                        >
                            STEP 1 : BASIC INFORMATION
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('2'); }} id="RCM_loan_info"
                        >
                            STEP 2 : LOAN INFORMATION
                     </NavLink>
                    </NavItem>
                    {/*<NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' }, { linkactive: this.state.activeTab === '3' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('3'); }}
                        >
                            STEP 3 : ADD/REMOVE BORROWER
                     </NavLink>
                    </NavItem>*/}
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                    <TabPane tabId="1">


                        <div>
                            <Form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridloannumber" id="RCM_issuername">
                                                <Label for="issuername">Issuer Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="issuername"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.issuername}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridloannumber" id="RCM_accountNumber">
                                                <Label for="accountnumber">Account Number</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="accountnumber"
                                                    className="RCM_form_control"
                                                    required
                                                    maxLength="20"
                                                    value={this.state.account.info.accountnumber}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>

                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_nickname">
                                                <Label for="nickname">Nick Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="nickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.nickname}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity" id="RCM_outstandingbalance">
                                                <Label>Outstanding Balance</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="outstandingbalance"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displayoutstandingbalance}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                <div>  <Label for="valueasof" >As of Date &nbsp;(mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="valueasof" id="valueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    onChange={this.handleValueAsOfChange}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>

                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div ><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="desc"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.info.description}
                                                onChange={this.handleFormChanges}
                                                required
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                        >
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </TabPane>

                    <TabPane tabId="2">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>Interest Rate</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="interestrate"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.account.creditcard.loaninfo.interestrate}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Interest Type</Label>
                                                <Dropdown name="interesttypedropdown" required
                                                    isOpen={this.state.isinteresttypeopen}
                                                    toggle={this.interesttypetoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.creditcard.loaninfo.interesttype}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.interesttypeoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.interesttypechange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Payment Frequency</Label>
                                                <Dropdown name="paymentfrequencydropdown" required
                                                    isOpen={this.state.ispaymentfrequencyopen}
                                                    toggle={this.paymentfrequencytoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.creditcard.loaninfo.paymentfrequency}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.paymentfrequencyoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.paymentfrequencychange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        {/*<button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>NEXT</button>*/}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    {/*<TabPane tabId="3">
                        <div>
                            <Form>
                                <div className="row">
                                    <div className="col-sm-8 col-xs-8 col-md-8 col-lg-8">
                                        <label className="RCM_form_control_placeholder">Borrowers : </label>
                                    </div>

                                    <div className="col-sm-1 col-xs-1 col-md-1 col-lg-1 RCM_button_add RCM_icon_hand" title="Add Borrower" onClick={this.handleAddBorrower}>
                                        +
                        </div>
                                </div>

                                {this.state.account.creditcard.borrowers.map((borrower, id) => (
                                    <div className="row">
                                        <div className="col-sm-5 col-xs-5 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="firstname" >First Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="firstname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={borrower.firstname}
                                                    onChange={this.handleBorrowerNameChange(id)}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-5 col-xs-5 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="lastname" >Last Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="lastname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={borrower.lastname}
                                                    onChange={this.handleBorrowerNameChange(id)}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-1 col-xs-1 col-md-1 col-lg-1 RCM_button_delete RCM_icon_hand" title="Delete Borrower" onClick={this.handleRemoveBorrower(id)}>
                                            -
                            </div>
                                    </div>

                                ))}

                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={this.saveAsset}>SUBMIT</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>PREVIOUS</button>
                                    </div>
                                    
                                </div>
                            </Form>
                        </div>
                    </TabPane>*/}
                    {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                        ?
                        < div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                {viewOnlyText}
                            </div>
                        </div>
                        : ""
                    }
                </TabContent>



            </div>)
    }
}
function mapStateToProps(store) {
    return {
        accounts: store.acct.accounts,
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);