import React, { Component } from "react";
import { connect } from "react-redux";
import PerformanceComponent from "./performancecomponent";
import ErrorBoundary from "../../../../common/errorboundary.jsx";
class Performance extends Component {
  constructor(props) {
    super(props);
      
    }

  render() {
      const performance = (
          <div key="PerformanceComponent" className="RCM_detailsScreeen">
              <ErrorBoundary>
                  <PerformanceComponent />
              </ErrorBoundary>   
      </div>
    );

      return performance;
  }
}
const mapStateToProps = (state) => {
  return {
    
  };
};

export default connect(mapStateToProps)(Performance);
