import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from "reactstrap";
import DatePicker from 'react-datepicker';
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, basicAlphaNumeric, discriptionFormat, alphanumericFormat, viewOnlyText } from '../../../../../common/constants.jsx';

class ExchangeFund extends Component {

    constructor(props) {
        super(props);

        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.handleInceptionDateChange = this.handleInceptionDateChange.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);

        this.state = {
            activeTab: '1',
            displaytotalvalue: '',
            intialAccount: {},
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: 'Exchange Fund',
                    description: ''
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date()
                },
                exchangefund: {
                    investmentvehiclename: '',
                    inceptiondate: new Date()
                }
            },
            formStateChange: false,

        };

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
    }

    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displaytotalvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({ account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleInceptionDateChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.exchangefund.inceptiondate = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleFormChanges(evt) {
        let clonedState = Object.assign({}, this.state);

        if (evt.target.name === "custodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.custodianname = evt.target.value;
        }
        else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        }
        else if (evt.target.name === "accountnumber" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            clonedState.account.info.accountnumber = evt.target.value;
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaytotalvalue = evt.target.value;
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;
        }
        else if (evt.target.name === "investmentvehiclename") {
            if (evt.target.value === "" || alphanumericFormat.test(evt.target.value) && evt.target.value.length <= 50) {
                clonedState.account.exchangefund.investmentvehiclename = evt.target.value;
            }
        }
        this.setState(clonedState);
        this.checkStateChange();
    }

    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.totalvalue = evt.target.value;
            clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.value.totalvalue = evt.target.value;
            this.setState(clonedState);
        }
        this.checkStateChange();
    }

    handleAmmountOnFocus(event) {
        const clonedState = Object.assign({}, this.state);
        if (clonedState.account.value.totalvalue === "") {
            clonedState.displaytotalvalue = "";
            clonedState.account.value.totalvalue = "";
        } else {
            clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
        }
        this.setState(clonedState);
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }

    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container RCM_asset_form_container" id="RCM_exchangefunds_form">

                <div>

                    <Form>
                        <fieldset>
                            <div className="row">

                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridTotal Value" id="RCM_custodianname">
                                        <Label for="custodianname">Custodian Name</Label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="custodianname"
                                            className="RCM_form_control"
                                            required
                                            value={this.state.account.info.custodianname}
                                            onChange={this.handleFormChanges}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridAccount" id="RCM_accountNumber">
                                        <Label for="accountnumber">Account Number</Label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="accountnumber"
                                            className="RCM_form_control"
                                            required
                                            value={this.state.account.info.accountnumber}
                                            onChange={this.handleFormChanges}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                        <Label for="nickname">Nickname</Label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="nickname"
                                            className="RCM_form_control"
                                            required
                                            value={this.state.account.info.nickname}
                                            onChange={this.handleFormChanges}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                        <Label for="totalvalue">Total Value</Label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder="$"
                                            name="totalvalue"
                                            className="RCM_form_control"
                                            required
                                            value={this.state.displaytotalvalue}
                                            onChange={this.handleFormChanges}
                                            onBlur={this.handleAmountFormatting}
                                            onFocus={this.handleAmmountOnFocus}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                        <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                        <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                            name="valueasof" id="valueasof"
                                            value={new Date(this.state.account.value.valueasof)}
                                            onChange={this.handleValueAsOfChange}
                                            selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                            maxDate={new Date()}
                                            placeholderText="Click to select a date"
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridInvestmentVehicleName">
                                        <Label for="investmentvehiclename">Investment Vehicle Name</Label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="investmentvehiclename"
                                            className="RCM_form_control"
                                            required
                                            value={this.state.account.exchangefund.investmentvehiclename}
                                            onChange={this.handleFormChanges}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridTotal Value">
                                        <div><Label for="inceptiondate">Inception Date (mm/dd/yyyy)</Label></div>
                                        <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                            name="inceptiondate" id="inceptiondate"
                                            value={new Date(this.state.account.exchangefund.inceptiondate)}
                                            onChange={this.handleInceptionDateChange}
                                            selected={(this.state.account.exchangefund.inceptiondate !== undefined && this.state.account.exchangefund.inceptiondate !== null
                                                && this.state.account.exchangefund.inceptiondate !== '') ? new Date(this.state.account.exchangefund.inceptiondate) : ''}
                                            placeholderText="Click to select a date"
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                    <div><Label for="desc" >Description</Label></div>
                                    <textarea
                                        name="description"
                                        placeholder=""
                                        className="RCM_form_control RCM_Feedback_TextArea"
                                        value={this.state.account.info.description}
                                        onChange={this.handleFormChanges}
                                        required
                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                <button type="button"
                                    className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                    onClick={this.saveAsset}
                                >
                                    SUBMIT
                                 </button>
                            </div>
                        </div>
                        {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                            ?
                            < div className="row">
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                    {viewOnlyText}
                                </div>
                            </div>
                            : ""
                        }
                    </Form>
                </div>
            </div>)
    }
}
function mapStateToProps(store) {
    return {
        accounts: store.acct.accounts,
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeFund);