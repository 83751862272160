import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import AddExternalAccountPreConfirm from "./addExternalAccountPreconfirm.jsx";
import { validateABA, validateStandingInstruction, validateStandingInstructionCancellationToken, validateABACancellationToken, getFirstPartyEligibleAcct, getThirdPartyEligibleAcct } from "./manageExternalAccountService.jsx";
import OverlayProcessing from "../common/overlayProcessing.jsx";
import { errorObjExternalAcct, thirdpartyTypes, acctTypes, noEligilblePairExistForMangAcct, thirdPartyErrorMsg } from "../common/constants.js";
import Messages from './../../../common/messages.jsx';
import RCM_Modal from "../common/modal.jsx";
import { getNestedObject, filterAggregatedAccounts } from "../common/utilty.js";
import { getEligiblePair, getEligiblePairCancellationToken } from "../moneymovementService.jsx";
import { getFromAccounts, identifyRetirementDisbursmentPilot } from "../models/AccountInfo.js";
import { isFirstPartyAllowed } from "../models/ExternalAcctInfo.js";
import ShowFilePopOver from "../../../common/showFilePopOver.jsx";

class AddExternalAccount extends React.Component {
    constructor(props) {
        super(props);

        this.topLevelAccounts = [];
        if (this.props.accounts !== null && this.props.accounts !== undefined && Array.isArray(this.props.accounts)) {
            this.topLevelAccounts = filterAggregatedAccounts(this.props.accounts);
        }

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.token = "";
        if(this.props.token !== null && this.props.token !== undefined)
        {
            this.token = this.props.token;
        }

        this.contextjson = null;
        if(this.props.contextjson !== null && this.props.contextjson !== undefined)
        {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if(this.props.profilejson !== null && this.props.profilejson !== undefined)
        {
            this.profilejson = this.props.profilejson;
        }

         //Add the pilot check for retirement distribution
         this.retirementDisAllowed = identifyRetirementDisbursmentPilot(this.props.preferences);
         this.isFirstPartySIAllowed = isFirstPartyAllowed('First Party SI', this.props.preferences);

        this.state = {
            abaList: [],
            externalAcct: {
                acctNickName: "",
                bankName: "",
                acctNumber: "",
                abaNumber: "",
                acctType: "",
                displayAcctType: "",
                thirdparty: "",
                displayThirdParty: "",
                fromaccount: "",
                displayAcctName: "",
                bankAcctName: "",
                memo: "",
                fileSelected: null

            },
            isSubmitDisabled: false, 
            eligibleFromAccounts: [],
            acctNickNameErrorMsg: "",
            abaNumberErrorMsg:"",
            acctNumberErrorMsg: "",
            acctTypeErrorMsg: "",
            fromaccountErrorMsg: "",
            thirdpartyErrorMsg: "",
            bankAcctNameErrorMsg: "",
            fileSelectedErrorMsg: "",

            thirdpartyDropdownOpen: false,
            acctTypeDropdownOpen: false,
            fromAccountDropdownOpen: false,
            filePopOverOpen: false,
            lblTransferFrom: 'TRANSFER FROM',
            isLoading: false,
            errorMsg: "",
            showPreConfirm: false,
            disclouserModal: false,
            extAcctDescription:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            
        };

        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleAddAccount = this.handleAddAccount.bind(this);
        this.backAsEdit = this.backAsEdit.bind(this);
        this.backAsFresh = this.backAsFresh.bind(this);
        this.handleCancelAddExtAcct = this.handleCancelAddExtAcct.bind(this);
        this.validateAbaNumber = this.validateAbaNumber.bind(this);
        this.validateAccount = this.validateAccount.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.toggleDisclouserModal = this.toggleDisclouserModal.bind(this);
        this.makeErrorfieldBlank = this.makeErrorfieldBlank.bind(this);
        this.thirdpartyDropdownToggle = this.thirdpartyDropdownToggle.bind(this);
        this.acctTypeDropdownToggle = this.acctTypeDropdownToggle.bind(this);
        this.fromAccountDropdownToggle = this.fromAccountDropdownToggle.bind(this);
        this.onThirdParyTypeChange = this.onThirdParyTypeChange.bind(this);
        this.onAcctTypeChange = this.onAcctTypeChange.bind(this);
        this.onFromAccountChange = this.onFromAccountChange.bind(this);
        this.filePopOverToggle = this.filePopOverToggle.bind(this);
    }


    filePopOverToggle()
    {
        this.setState({filePopOverOpen: !this.state.filePopOverOpen})
    }


    thirdpartyDropdownToggle()
    {
        this.setState(prevState => ({
            thirdpartyDropdownOpen: !prevState.thirdpartyDropdownOpen
          }));
    }

    acctTypeDropdownToggle()
    {
        this.setState(prevState => ({
            acctTypeDropdownOpen: !prevState.acctTypeDropdownOpen
          }));
    }

    fromAccountDropdownToggle()
    {
        this.setState(prevState => ({
            fromAccountDropdownOpen: !prevState.fromAccountDropdownOpen
          }));
    }

    onThirdParyTypeChange(thirdpartyType)
    {
            let clonedState = Object.assign({}, this.state);
            clonedState.externalAcct.thirdparty = thirdpartyType.value;
            clonedState.externalAcct.displayThirdParty = thirdpartyType.description;
            clonedState.externalAcct.bankAcctName = "";
            clonedState.externalAcct.fileSelected = null;
            clonedState.isLoading = true;
            clonedState.externalAcct.fromaccount = "";
            clonedState.externalAcct.displayAcctName = "";
            clonedState.eligibleFromAccounts = [];
            if(thirdpartyType.value !== "")
            {
                clonedState.thirdpartyErrorMsg = "";
            }

            clonedState.lblTransferFrom = thirdpartyType.value === 'self' ? 'TRANSFER FROM/TO' : 'TRANSFER FROM';

            this.setState({
                ...clonedState, eligibleFromAccounts: [], isSubmitDisabled: false
            }, () => {
                // getEligiblePair(this.topLevelAccounts, this.retirementDisAllowed, this.requesterId, this.token, this.contextjson, this.profilejson).then(data => {
                //     if(data && data.eligiblePairs && Array.isArray(data.eligiblePairs) && data.eligiblePairs.length > 0 )
                //     {
                //         let eligibleFromAccountList = getFromAccounts(data.eligiblePairs, this.topLevelAccounts);
                //         eligibleFromAccountList = eligibleFromAccountList.filter(function (acct) { return (acct.isExternal != null && acct.isExternal === false); })
                //         this.setState({isLoading: false, eligibleFromAccounts: eligibleFromAccountList});
                //     }else{
                //         this.setState({isLoading: false, errorMsg: noEligilblePairExistForMangAcct})
                //     }
                // })
                // .catch(err => {
                //     console.log("Error while getting eligible pair");
                //     this.setState({isLoading: false, errorMsg: err});
                // })

                if(thirdpartyType.value === 'self'){
                    //call api
                    getFirstPartyEligibleAcct(this.requesterId, this.token, this.contextjson, this.profilejson).then(res => {
                        this.setState({isLoading: false, eligibleFromAccounts: res});
                    })
                    .catch(err => {
                        this.setState({isLoading: false, errorMsg: noEligilblePairExistForMangAcct, isSubmitDisabled: true})
                    })

                }else{
                    //Call api
                    getThirdPartyEligibleAcct('Y', this.token, this.contextjson, this.profilejson).then(res => {
                        this.setState({isLoading: false, eligibleFromAccounts: res});
                    })
                    .catch(err => {
                        this.setState({isLoading: false, errorMsg: thirdPartyErrorMsg, isSubmitDisabled: true})
                    })
                }

            })
    }

    onAcctTypeChange(acctType)
    {
        const clonedState = Object.assign({}, this.state);
        clonedState.externalAcct.acctType = acctType.value;
        clonedState.externalAcct.displayAcctType = acctType.text;
        if(acctType.value !== "")
        {
            clonedState.acctTypeErrorMsg = "";
        }
        this.setState(clonedState);
    }

    onFromAccountChange(fromAccount)
    {
        let clonedState = Object.assign({}, this.state);
        clonedState.externalAcct.fromaccount = fromAccount.AccountNo;
        clonedState.externalAcct.displayAcctName = fromAccount.DisplayName;
        if(fromAccount.AccountNo !== "")
        {
            clonedState.fromaccountErrorMsg = "";
        }
        this.setState(clonedState);
    }

    handleAddAccount(evt) {
        
        let clearErrorObj = this.makeErrorfieldBlank(this.state.externalAcct);
        let clonedState = Object.assign({}, this.state);
        clonedState.errorMsg = "";
        clonedState.showPreConfirm = false;
        let clonedStateWithoutError = Object.assign(clonedState, clearErrorObj);
        
        this.setState(clonedStateWithoutError, () => {
            let errorIndividualMsg = {};
            errorIndividualMsg = this.validateAccount(this.state.externalAcct, errorObjExternalAcct);

            if (Object.keys(errorIndividualMsg).length !== 0) {
                let clonedState = Object.assign({}, this.state);
                let clonedStateWithError = Object.assign(clonedState, errorIndividualMsg);
                clonedStateWithError.showPreConfirm = false;
                this.setState(clonedStateWithError);

            } else {

                let clonedState = Object.assign({}, this.state);
                clonedState.isLoading = true;
                this.setState(clonedState, () => {
                    validateStandingInstruction(this.state.externalAcct, this.token, this.contextjson, this.profilejson)
                        .then(data => {
                            let clonedState = Object.assign({}, this.state);
                            clonedState.errorMsg = "";
                            clonedState.showPreConfirm = true;
                            clonedState.isLoading = false;
                            this.setState(clonedState);
                        }).catch(error => {
                            let clonedState = Object.assign({}, this.state);
                            clonedState.errorMsg = error;
                            clonedState.showPreConfirm = false;
                            clonedState.isLoading = false;
                            this.setState(clonedState);
                        });
                })
            }
        });

    }

    validateAccount(obj, errorObj) {
        let errorMsgObj = {};
        let keys = Object.keys(obj);

            keys.map(
                (item) => {
                    if (obj[item] === null || obj[item] === undefined || obj[item] === "") {
                        if (errorObj[item] !== null && errorObj[item] !== undefined) {
                            if (item !== "bankAcctName" && item !== "fileSelected") {
                               return errorMsgObj[item + "ErrorMsg"] = [errorObj[item]];
                            } else {
                                if ((obj.thirdparty === "other" && item === 'bankAcctName') || (obj.thirdparty === "self" && item === 'fileSelected')) {
                                    return  errorMsgObj[item + "ErrorMsg"] = [errorObj[item]];
                                }
                            }

                        }
                    }
                    
                }
            )
        
        return errorMsgObj;
    }

    makeErrorfieldBlank(obj) {
        let keys = Object.keys(obj);
        let errorMsgObj = {};
        keys.map(
            (item) => {
                if(this.state[item + "ErrorMsg"] !== undefined && this.state[item + "ErrorMsg"] !== null) 
                    return errorMsgObj[item + "ErrorMsg"] = [];
            }
        )
        return errorMsgObj;
    }

    handleOnBlur(event, name, value) {
        if (value !== "") {
            this.setState({
                [name + "ErrorMsg"]: []
            });
        }

            
        if(name === "abaNumber")
        {
            this.validateAbaNumber(event);
        }
    }

    toggleDisclouserModal()
    {
        this.setState(prevState => ({
            disclouserModal: !prevState.disclouserModal
        }));
    }

    backAsEdit() {
        const clonedState = Object.assign({}, this.state);
        clonedState.showPreConfirm = false;
        clonedState.isLoading = false;
        this.setState(clonedState);
    }

    backAsFresh() {
        const clonedState = Object.assign({}, this.state);
        clonedState.showPreConfirm = false;
        clonedState.externalAcct.acctNickName = "";
        clonedState.externalAcct.bankName = "";
        clonedState.externalAcct.acctNumber = "";
        clonedState.externalAcct.abaNumber = "";
        clonedState.externalAcct.acctType = "";
        clonedState.externalAcct.displayAcctType = "";
        clonedState.externalAcct.fromaccount = "";
        clonedState.externalAcct.memo = "";
        clonedState.externalAcct.thirdparty = "";
        clonedState.externalAcct.displayThirdParty = "";
        clonedState.externalAcct.bankAcctName = "";
        clonedState.externalAcct.displayAcctName = "";
        this.setState(clonedState);
    }

    handleCancelAddExtAcct(evt) {
        let clearErrorObj = this.makeErrorfieldBlank(this.state.externalAcct);
        let clonedState = Object.assign({}, this.state);
        clonedState.showPreConfirm = false;
        clonedState.errorMsg = "";
        clonedState.externalAcct.acctNickName = "";
        clonedState.externalAcct.bankName = "";
        clonedState.externalAcct.acctNumber = "";
        clonedState.externalAcct.abaNumber = "";
        clonedState.externalAcct.acctType = "";
        clonedState.externalAcct.displayAcctType = "";
        clonedState.externalAcct.fromaccount = "";
        clonedState.externalAcct.memo = "";
        clonedState.externalAcct.thirdparty = "";
        clonedState.externalAcct.displayThirdParty = "";
        clonedState.externalAcct.bankAcctName = "";
        clonedState.externalAcct.displayAcctName = "";
        let clonedStateWithClearErr = Object.assign(clonedState, clearErrorObj);
        this.setState(clonedStateWithClearErr);
    }

    handleFormChanges(evt) {
        const abaFormat = /^\d{0,9}$/;
        const alphaNumericFormat = /^[a-zA-Z0-9]*$/;

        if (evt.target.name === "acctNickName") {
                const clonedState = Object.assign({}, this.state);
                clonedState.externalAcct.acctNickName = evt.target.value;
                this.setState(clonedState);
        }

        if (evt.target.name === "bankName") {
            const clonedState = Object.assign({}, this.state);
            clonedState.externalAcct.bankName = evt.target.value;
            this.setState(clonedState);
        }

        if (evt.target.name === "acctNumber") {
            if(evt.target.value === '' || alphaNumericFormat.test(evt.target.value)){
                const clonedState = Object.assign({}, this.state);
                clonedState.externalAcct.acctNumber = evt.target.value;
                this.setState(clonedState);
            }
        }

        if (evt.target.name === "bankAcctName") {
            const clonedState = Object.assign({}, this.state);
            clonedState.externalAcct.bankAcctName = evt.target.value;
            this.setState(clonedState);
        }

        if (evt.target.name === "abaNumber") {
            if (evt.target.value === "" || abaFormat.test(evt.target.value)) {
                const clonedState = Object.assign({}, this.state);
                clonedState.externalAcct.abaNumber = evt.target.value;
                this.setState(clonedState);
            }
        }


        if (evt.target.name === "memo") {
            let clonedState = Object.assign({}, this.state);
            clonedState.externalAcct.memo = evt.target.value;
            this.setState(clonedState);
        }
    }

    componentDidMount() {
        //Call eligible pair api and load from accounts. 
        
    }

    componentWillUnmount()
    {
        if(getEligiblePairCancellationToken !== null && getEligiblePairCancellationToken !== undefined)
        {
            getEligiblePairCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
        }
        
        if(validateStandingInstructionCancellationToken !== null && validateStandingInstructionCancellationToken !== undefined)
        {
            validateStandingInstructionCancellationToken.cancel("Cancelling validate SI on unmount to prevent memory leak.");
        }

        if(validateABACancellationToken !==null && validateABACancellationToken !== undefined)
        {
            validateABACancellationToken.cancel("Cancelling validate ABA call on unmount to prevent memory leak.");
        }
    }

    validateAbaNumber(evt) {

        if (evt.target.value !== undefined
            && evt.target.value !== "") {
            let inputABA = evt.target.value;
            this.setState({ isLoading: true }, () => {
                validateABA(inputABA, this.token, this.contextjson, this.profilejson)
                    .then(data => {
                        if (data.ABANumbers !== null
                            && data.ABANumbers !== undefined
                            && Array.isArray(data.ABANumbers)
                            && data.ABANumbers.length > 0) {
                            //let clonedState = Object.assign({}, this.state);
                            //clonedState.abaList = data.ABANumbers;
                            //this.setState(clonedState);
                            console.log(data.ABANumbers);

                            let bankname = "";
                            for (let aba of data.ABANumbers) {
                                if (aba.BankCode !== null && aba.BankCode !== undefined && aba.BankCode === inputABA) {
                                    if (aba.BankName !== null && aba.BankName !== undefined) {
                                        bankname = aba.BankName;
                                        let clonedState = Object.assign({}, this.state);
                                        clonedState.externalAcct.bankName = aba.BankName;
                                        clonedState.errorMsg = "";
                                        clonedState.abaNumberErrorMsg = [];
                                        clonedState.isLoading = false;
                                        this.setState(clonedState);
                                    }
                                    break;
                                }
                            }

                            //check if we did not receive any bank name
                            if (bankname === "") {
                                let clonedState = Object.assign({}, this.state);
                                clonedState.errorMsg = "Please provide valid ABA Routing number";
                                clonedState.externalAcct.bankName = "";
                                clonedState.isLoading = false;
                                this.setState(clonedState);
                            }
                        }else{
                            let clonedState = Object.assign({}, this.state);
                                clonedState.errorMsg = "Please provide valid ABA Routing number";
                                clonedState.externalAcct.bankName = "";
                                clonedState.isLoading = false;
                                this.setState(clonedState);
                        }
                    })
                    .catch(error => {
                        //Do nothing
                        let clonedState = Object.assign({}, this.state);
                        clonedState.errorMsg = error;
                        clonedState.externalAcct.bankName = "";
                        clonedState.isLoading = false;
                        this.setState(clonedState);
                    });

            });

        }
    }

    selectfiles(event){
        let files = event.target.files;
        if(files && files.length > 0 ){
            let clonedState = Object.assign({}, this.state);
            clonedState.externalAcct.fileSelected = files[0];
            clonedState.fileSelectedErrorMsg = "";
            this.setState(clonedState);
        }
    }

    showFilePreview(){
        this.setState({filePopOverOpen: true});
    }

    hideFilePreview(){
        this.setState({filePopOverOpen: false});
    }


    render() {
        let addexternalAcctForm;
        if (!this.state.showPreConfirm) {
            addexternalAcctForm = (
                <div>
                    {
                        this.state.isLoading === true ? (
                            <OverlayProcessing />
                        ) : ""
                    }
                    {
                        this.state.errorMsg !== "" ?
                            (
                                <div className="RCM_validation_error_container" >
                                    <div className="RCM_msg_text">{this.state.errorMsg}</div>
                                </div>
                            ) : ""
                    }
                    {
                        (this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? (
                            <div className="RCM_validation_error_container" >
                                    <div className="RCM_msg_text">Please call your Financial Advisor to setup your accounts</div>
                            </div>
                        ) : ""
                    }
                    <div className="mt-2">
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="RCM_Toast_heading">
                                    ADD A NEW EXTERNAL ACCOUNT
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="RCM_CM_form_label">
                                            <label>ACCOUNT OWNERSHIP</label>
                                        </div>
                                    </div>
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.thirdpartyErrorMsg} containerClass="" />
                                    </div>
                                </div>
                                <div>
                                    <Dropdown name="thirdparty" required
                                            isOpen={this.state.thirdpartyDropdownOpen} 
                                            toggle={this.thirdpartyDropdownToggle}
                                            //onBlur= {(event) => this.handleOnBlur(event, "thirdparty", this.state.externalAcct.thirdparty)} 
                                            className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                        <DropdownToggle caret>{this.state.externalAcct.displayThirdParty}</DropdownToggle>
                                        <DropdownMenu>
                                            {thirdpartyTypes.map((item, index) =>
                                                <DropdownItem key={index} onClick={() => this.onThirdParyTypeChange(item)}>
                                                    <div>{item.description}</div>
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="RCM_CM_form_label"><label>ACCOUNT NICKNAME</label></div>
                                    </div>
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.acctNickNameErrorMsg} containerClass="" />
                                    </div>
                                </div>
                                
                                <div>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="acctNickName"
                                        className="RCM_form_control"
                                        maxLength="50"
                                        disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                        required
                                        value={this.state.externalAcct.acctNickName}
                                        onChange={this.handleFormChanges}
                                        onBlur= {(event) => this.handleOnBlur(event, "acctNickName", this.state.externalAcct.acctNickName)} 
                                    />
                                    
                                </div>
                               
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="RCM_CM_form_label"><label>ROUTING NUMBER</label></div>
                                    </div>
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.abaNumberErrorMsg} containerClass="" />
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        className="RCM_form_control"
                                        name="abaNumber"
                                        maxLength="9"
                                        disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                        required
                                        value={this.state.externalAcct.abaNumber}
                                        onChange={this.handleFormChanges}
                                        onBlur={(event) => this.handleOnBlur(event, "abaNumber", this.state.externalAcct.abaNumber)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="RCM_CM_form_label"><label>BANK NAME</label></div>
                                <div className="RCM_form_control_group RCM_CM_extAcct_from_control">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="bankName"
                                        disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                        className="RCM_form_control"
                                        required
                                        value={this.state.externalAcct.bankName}
                                        onChange={this.handleFormChanges}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="RCM_CM_form_label"><label>ACCOUNT NUMBER</label></div>
                                    </div>
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.acctNumberErrorMsg} containerClass="" />
                                    </div>
                                </div>
                            
                                <div className="RCM_form_control_group RCM_CM_extAcct_from_control">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        required
                                        name="acctNumber"
                                        maxLength="17"
                                        disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                        className="RCM_form_control"
                                        value={this.state.externalAcct.acctNumber}
                                        onChange={this.handleFormChanges}
                                        onBlur= {(event) => this.handleOnBlur(event, "acctNumber", this.state.externalAcct.acctNumber)} 
                                    />
                                    
                                </div>
                                
                            </div>
                        </div>
                        {(this.state.externalAcct.thirdparty === "other") ? 
                            (
                                <div className="row RCM_CM_form_row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="RCM_CM_form_label"><label>ACCOUNT HOLDER NAME</label></div>
                                            </div>
                                            <div className="cols-sm-12">
                                                <Messages messages={this.state.bankAcctNameErrorMsg} containerClass="" />
                                            </div>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="bankAcctName"
                                                maxLength="22"
                                                disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                                className="RCM_form_control"
                                                required
                                                value={this.state.externalAcct.bankAcctName}
                                                onChange={this.handleFormChanges}
                                                onBlur={(event) => this.handleOnBlur(event, "bankAcctName", this.state.externalAcct.bankAcctName)}
                                            />
                                        </div>
                                    </div>
                            
                        </div>) : ""
                        }
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="RCM_CM_form_label"><label>ACCOUNT TYPE</label></div>
                                    </div>
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.acctTypeErrorMsg} containerClass="" />
                                    </div>
                                </div>
                                <div>
                                    <Dropdown name="acctType" required
                                        isOpen={this.state.acctTypeDropdownOpen}
                                        toggle={this.acctTypeDropdownToggle}
                                        //onBlur= {(event) => this.handleOnBlur(event, "thirdparty", this.state.externalAcct.thirdparty)} 
                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                        <DropdownToggle caret disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}>{this.state.externalAcct.displayAcctType}</DropdownToggle>
                                        <DropdownMenu>
                                            {acctTypes.map((item, index) =>
                                                <DropdownItem key={index} onClick={() => this.onAcctTypeChange(item)}>
                                                    <div>{item.text}</div>
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                            <div className="RCM_CM_form_label"><label>{this.state.lblTransferFrom}</label></div>
                                    </div>
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.fromaccountErrorMsg} containerClass="" />
                                    </div>
                                </div>
                                <div>
                                    <Dropdown name="fromaccount" required
                                        isOpen={this.state.fromAccountDropdownOpen}
                                        toggle={this.fromAccountDropdownToggle}
                                        //onBlur= {(event) => this.handleOnBlur(event, "thirdparty", this.state.externalAcct.thirdparty)} 
                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                        <DropdownToggle caret disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}>{this.state.externalAcct.displayAcctName}</DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.eligibleFromAccounts.map((item, index) =>
                                                <DropdownItem key={index} onClick={() => this.onFromAccountChange(item)}>
                                                    <div title={item.AccountNo}>{`${getNestedObject(item, ['DisplayName'])}`}</div>
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                            <div className="RCM_CM_form_label"><label>MEMO (OPTIONAL)</label></div>
                                <div className="RCM_form_control_group RCM_CM_extAcct_from_control">
                                    <textarea
                                        className="RCM_form_control"
                                        autoComplete="off"
                                        name="memo"
                                        disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                        rows="1"
                                        maxLength="128"
                                        onChange={this.handleFormChanges}
                                        value={this.state.externalAcct.memo}
                                        required></textarea>
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.externalAcct.thirdparty === 'self' && this.isFirstPartySIAllowed) 
                            && 
                            (
                                <div className="row RCM_CM_form_row">
                                    <div className="cols-sm-12">
                                        <Messages messages={this.state.fileSelectedErrorMsg} containerClass="" />
                                    </div>
                                    <div className="col-sm-12">
                                        <label htmlFor="file-upload" className="RCM_icon_hand  RCM_manage_grp_new_group">
                                            Click to upload void check image  <span title='Please write "VOID" on a Check showing your name, address, bank routing number and account number, scant it and upload the image in PDF format here as a proof of account ownership. The Account will be activated after review and verification of account information.'>&#128712;</span>
                                        </label>
                                        <div>First party accounts require a copy of a void check. Please click the link above to upload an image of a voided check.</div>
                                        <input type="file" name="fileSelected" className="form-control RCM_fileUpload" onChange={(e) => this.selectfiles(e)} id="file-upload" accept="application/pdf,image/jpeg" />
                                       
                                        {
                                            this.state.externalAcct.fileSelected && (
                                                <div>
                                                    <label className="RCM_form_uploadbtn RCM_icon_hand" id='RCM_SI_file_popover' 
                                                     onClick={() => this.showFilePreview()}
                                                    >
                                                        File: {this.state.externalAcct && this.state.externalAcct.fileSelected && this.state.externalAcct.fileSelected.name ? this.state.externalAcct.fileSelected.name : ''}
                                                    </label>
                                                    <ShowFilePopOver 
                                                        target='RCM_SI_file_popover'
                                                        file={this.state.externalAcct.fileSelected}
                                                        filePopOverOpen={this.state.filePopOverOpen} 
                                                        filePopOverToggle={this.filePopOverToggle}/>
                                                </div>
                                            )
                                        }
                                        
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="">
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12" align="center">

                                <button
                                    disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                    className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size RCM_overlapping_btn"
                                    onClick={this.handleCancelAddExtAcct}>
                                    CANCEL</button>

                                <button
                                    disabled={(this.state.externalAcct.thirdparty === 'self' && !this.isFirstPartySIAllowed) ? true : false}
                                    className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size RCM_overlapping_btn"
                                    onClick={this.handleAddAccount}
                                    disabled={this.state.isSubmitDisabled}>
                                    ADD ACCOUNT </button>

                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12" align="center">
                                
                            </div>
                        </div>
                    </div> 
                </div>
            );
        } else {
            addexternalAcctForm = (
                <div>
                    <AddExternalAccountPreConfirm
                        newAcctData={this.state.externalAcct}
                        backAsEdit={this.backAsEdit}
                        backAsFresh={this.backAsFresh}
                    />
                </div>
            );
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">{addexternalAcctForm}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        accounts: store.acct.accounts,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        portalid: store.acct.portalid,
        preferences: store.acct.preferences
    }
}

export default connect(mapStateToProps)(AddExternalAccount);
