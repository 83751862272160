import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import { getPastTransfersList, getPastTransfersCancellationToken } from "./moneymovementService.jsx";
import { pastTransferColums, defaultSorted } from "./pastTransfersColumns.js";
import { SELECTRANGE } from './data/filterDateRange.js';
import {PastTransfersActivity} from '../../actions/moneymovementactions';
import { spinner, MsgForNoData, MsgForFailedData, MsgSelectFromTop } from '../../common/constants.jsx';
import { noDataIndicationMessage } from './common/gridCommon.jsx';

export class PastTransfers extends Component {
    constructor(props) {
        super(props);

        this.contextAccounts = [];
        if (this.props.accounts !== null
            && this.props.accounts !== undefined
            && Array.isArray(this.props.accounts)
            && this.props.accounts.length > 0) {
            this.contextAccounts = this.props.accounts;
        }

        this.topLevelAccounts = [];
        if (this.props.selectedaccount !== null
            && this.props.selectedaccount !== undefined
            && Array.isArray(this.props.selectedaccount)
            && this.props.selectedaccount.length > 0) {
            this.topLevelAccounts = this.props.selectedaccount;
        }

        this.token = "";
        if(this.props.token !== null && this.props.token !== undefined)
        {
            this.token = this.props.token;
        }

        this.contextjson = null;
        if(this.props.contextjson !== null && this.props.contextjson !== undefined)
        {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if(this.props.profilejson !== null && this.props.profilejson !== undefined)
        {
            this.profilejson = this.props.profilejson;
        }

        this.state = {
            columns: pastTransferColums(this.contextAccounts),
            isDataLoaded: false,
            render: this.props.render,
            gridMessage: MsgForNoData,
            dateRange:(this.props.filterData !== null && this.props.filterData !== undefined )? this.props.filterData : SELECTRANGE
        };

    }



    componentDidMount() {

        if(this.topLevelAccounts !==null 
            && this.topLevelAccounts !== undefined 
            && Array.isArray(this.topLevelAccounts) && this.topLevelAccounts.length > 0 )
            {
                getPastTransfersList(this.topLevelAccounts, this.state.dateRange, this.token, this.contextjson, this.profilejson)
                    .then(data => {

                        if (Array.isArray(data)) this.props.PastTransfersActivity(data);
                        let clonedState = Object.assign({}, this.state);
                        clonedState.isDataLoaded = true;
                        clonedState.gridMessage = MsgForNoData;
                        this.setState(clonedState);
                    })
                    .catch(error => {
                        //do something with error code.
                        this.props.PastTransfersActivity([]);
                        let clonedState = Object.assign({}, this.state);
                        clonedState.isDataLoaded = true;
                        clonedState.gridMessage = MsgForFailedData;
                        this.setState(clonedState);
                    });
        }else{
                let clonedState = Object.assign({}, this.state);
                clonedState.isDataLoaded = true;
                this.props.PastTransfersActivity([]);
                clonedState.gridMessage = MsgSelectFromTop;
                this.setState(clonedState);
        }

    }

    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.


    //componentWillReceiveProps(nextState) {
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            //console.log("Past Transfers component did update :", prevProps);
            if (this.props.selectedaccount !== null
                && this.props.selectedaccount !== undefined
                && Array.isArray(this.props.selectedaccount)
                && this.props.selectedaccount.length > 0) {
                let clonedState = Object.assign({}, this.state);
                clonedState.isDataLoaded = false;
                clonedState.gridMessage = MsgForNoData;
                this.setState(clonedState, () => {
                    getPastTransfersList(this.props.selectedaccount, this.state.dateRange, this.token, this.contextjson, this.profilejson)
                        .then(data => {
                            if (Array.isArray(data)) this.props.PastTransfersActivity(data);
                            let clonedState = Object.assign({}, this.state);
                            clonedState.isDataLoaded = true;
                            this.setState(clonedState);
                        })
                        .catch(error => {
                            //do something with error code.
                            this.props.PastTransfersActivity([]);
                            let clonedState = Object.assign({}, this.state);
                            clonedState.isDataLoaded = true;
                            clonedState.gridMessage = MsgForFailedData;
                            this.setState(clonedState);
                        });
                });

            } else {
                let clonedState = Object.assign({}, this.state);
                clonedState.isDataLoaded = true;
                this.props.PastTransfersActivity([]);
                clonedState.gridMessage = MsgSelectFromTop;
                this.setState(clonedState);
            }


        }

        //performe the service call with date range if somebody changes the date
        if(this.props.filterData !== null 
            && this.props.filterData
            && this.props.filterData !== SELECTRANGE
            && this.state.dateRange !== this.props.filterData
            )
            {
                //console.log("component did update past transfer", this.props.filterData)
                let clonedState = Object.assign({}, this.state);
                clonedState.isDataLoaded = false;
                clonedState.dateRange = this.props.filterData;
                clonedState.gridMessage = MsgForNoData;
                this.setState(clonedState, () => {
                    getPastTransfersList(this.props.selectedaccount, this.state.dateRange, this.token, this.contextjson, this.profilejson)
                        .then(data => {
                            if(Array.isArray(data)) 
                                this.props.PastTransfersActivity(data) 
                            else
                             console.log("Wrong response");
                            let clonedState = Object.assign({}, this.state);
                            clonedState.isDataLoaded = true;
                            this.setState(clonedState);
                        })
                        .catch(error => {
                            //do something with error code.
                            this.props.PastTransfersActivity([]);
                            let clonedState = Object.assign({}, this.state);
                            clonedState.isDataLoaded = true;
                            clonedState.gridMessage = MsgForFailedData;
                            this.setState(clonedState);
                        });
                });
            }
    }

    componentWillUnmount()
    {
        if(getPastTransfersCancellationToken !== null && getPastTransfersCancellationToken !== undefined)
        {
            getPastTransfersCancellationToken.cancel('Cancelling the past transfer during unmount to prevent memory leak.');
        }
    }

    render() {
        let pastPayments;

        if (this.state.isDataLoaded) {
            pastPayments = (
                <div>
                    <BootstrapTable
                        keyField="id"
                        data={this.props.pastTransfersList}
                        columns={this.state.columns}
                        bordered={false}
                        classes="RCM_two_level_table1"
                        wrapperClasses="RCM_CM_no_hand"
                        noDataIndication={() => noDataIndicationMessage(this.state.gridMessage)}
                        defaultSorted={defaultSorted}
                    />
                </div>
            );
        } else {
            pastPayments = (
                <div align="center">
                    <div className="RCM_spinner">Loading Past Transfers Details{spinner()}</div>
                </div>
            );
        }

        return <div>{pastPayments}</div>;
    }
}

const mapStateToProps = state => {
    return {
        accounts: state.acct.accounts,
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        pastTransfersList: state.moneymovement.pastTransfersList,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};

const mapDispatchToProps ={
    PastTransfersActivity
}

export default connect(mapStateToProps, mapDispatchToProps)(PastTransfers);
