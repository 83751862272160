import { errorMsg, edeliveryURL} from "../../../common/constants.jsx";
import { Post, Get } from "../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../common/_logging";

export var addRemoveEmailCancellationToken = null;

export const addRemoveEmailServices = (reqPayload, token, contextJson) => {

    //Check if cancellation token has value;
    if (addRemoveEmailCancellationToken !== null && addRemoveEmailCancellationToken !== undefined) {
        //Cancel the existing call
        addRemoveEmailCancellationToken.cancel('Cancelling the addRemoveEmail request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addRemoveEmailCancellationToken = cancelToken.source();

    let addRemoveEmailPromise = new Promise(
        function (resolve, reject) {
            //let addRemoveEmailsurl = edeliveryURL + "/AlertSubscribeSubmit";
            Post(edeliveryURL, reqPayload, token, contextJson, addRemoveEmailCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Edelivery service: addRemoveEmail ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Edelivery service: addRemoveEmail :: ", error);
                        _logException("Edelivery service: addRemoveEmail :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return addRemoveEmailPromise;
}