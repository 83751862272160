import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UpdateAccount } from '../actions/accountsactions.jsx';
import { UpdateTopten } from '../actions/toptenactions.jsx';
import { UpdateDocuments } from '../actions/documentactions.jsx';
import { SelectWithCheckboxView } from './selectwithcheckboxview.jsx';
import { setContextInCache } from '../services/home/userServices.jsx';

class SelectWithCheckbox extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelections = this.handleSelections.bind(this);
        this.selectUnSelectAllOptions = this.selectUnSelectAllOptions.bind(this);
        this.apply = this.apply.bind(this);
        this.cancel = this.cancel.bind(this);
        this.disableApply = this.disableApply.bind(this);
        this.filterList = this.filterList.bind(this);
        this.updateSearchKeyword = this.updateSearchKeyword.bind(this);
        this.getDisplayName = this.getDisplayName.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.checkAllSubAccountSelected = this.checkAllSubAccountSelected.bind(this);
        this.checkAllAccountSelected = this.checkAllAccountSelected.bind(this);
        this.selectUnSelectAllSubAccounts = this.selectUnSelectAllSubAccounts.bind(this);
        this.createAccountList = this.createAccountList.bind(this);
        this.clearAll = this.clearAll.bind(this);

        this.state = {
            dropdownOpen: false,
            allSelected: true,
            dropDownValue: "All Accounts",
            allAccount: { id: 0, value: "All Accounts", isChecked: true },
            accounts: [],
            options: [],
            initialSelected: [],
            initialAllAccountSelected: { id: 0, value: "All Accounts", isChecked: true },
            initialDropDownValue: "All Accounts",
            initialOptions: [],
            render: this.props.render,
            disableApply: "disabled",
            items: [],
            searchKeyword: '',
            activeKey: "1",
            showExternalAccounts: this.props.showExternalAccounts,
            newAccounts: this.props.accountList

        };
    }
    componentDidMount() {
        this.createAccountList("init");
    }
    createAccountList(source) {
        var accordionList = [];
        var options = this.state.options;
        var newAccounts = {};
        newAccounts.accounts = JSON.parse(JSON.stringify(this.props.profileResp.accounts));
        newAccounts.externalAccounts = JSON.parse(JSON.stringify(this.props.profileResp.groupedexternalaccounts));
        this.state.newAccounts = newAccounts;
        var newOptions = [];
        if (this.state.newAccounts && this.state.newAccounts.accounts && this.state.newAccounts.accounts.length !== 0) {
            var internalAccounts = {};
            internalAccounts.fiName = "Rockefeller";
            internalAccounts.fiId = "rockcoid";
            internalAccounts.allAccount = { id: "rockcoid", value: "All sub Accounts RockId", isChecked: true };
            internalAccounts.isOpen = true;
            internalAccounts.accounts = this.state.newAccounts.accounts;
            for (const [, item] of internalAccounts.accounts.entries()) {
                var indexOfAccount = -1;
                item.isInternal = true;
                options.findIndex(function (obj, i) {
                    if (obj.number === item.number) {
                        indexOfAccount = i;
                    }
                })
                if (source === "init") {
                    item.isChecked = true;
                } else if ((source === "add" || source === "delete" || source === "showExternalAccounts" || source === "hideExternalAccounts" || source === "updateNickname"
                    || source === "nicknameUpdatedFromGroupOrManualAsset" || source === "manualAssetsAdded" || source === "manualAssetDeleted") && indexOfAccount >= 0) {
                    item.isChecked = true;
                    if (source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset") {
                        newOptions.push({ ...item });
                    }
                } else {
                    item.isChecked = false;
                }
            }
            accordionList.push(internalAccounts);
        }
        if (this.state.showExternalAccounts && this.state.newAccounts && this.state.newAccounts.externalAccounts && this.state.newAccounts.externalAccounts !== undefined
            && this.state.newAccounts.externalAccounts.length !== 0) {
            var modifiedAccounts = this.props.aggregatedAcctModifiedList.modifiedAccounts;
            for (const [, value] of this.state.newAccounts.externalAccounts.entries()) {
                var account = {};
                account.accounts = JSON.parse(JSON.stringify(value.groupby));
                var allAccountText = "All sub Accounts " + value.groupid;

                account.isOpen = false;

                account.fiName = value.groupname;
                account.fiId = value.groupid;

                for (const [, item] of account.accounts.entries()) {
                    item.isChecked = false;
                    item.isInternal = false;
                    var indexOfAccount = -1;
                    options.findIndex(function (obj, i) {
                        if (obj.key === item.key) {
                            indexOfAccount = i;
                        }
                    })

                    if (source === "init" || source === "showExternalAccounts") {
                        item.isChecked = true;
                        options.push({ ...item });
                    } else if (source === "add" || source === "delete" || source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset" ||
                        source === "manualAssetsAdded" || source === "manualAssetDeleted") {
                        if (indexOfAccount >= 0) {
                            item.isChecked = true;
                            if (source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset") {
                                newOptions.push({ ...item });
                            }
                        } else {
                            item.isChecked = false;
                        }
                        if (source === "add") {
                            for (const [index, addedAccount] of modifiedAccounts.entries()) {
                                if (item.key === addedAccount.key) {
                                    item.isChecked = true;
                                    options.push({ ...item });
                                }
                            }
                        }
                        else if (source === "manualAssetsAdded") {
                            var addedAccountKey = this.props.modifiedManualAccountKey;
                            if (item.key === addedAccountKey) {
                                item.isChecked = true;
                                options.push({ ...item });
                            }
                        }
                    }

                }
                var isAllSubAccountsChecked = this.checkAllSubAccountSelected(account.accounts);
                account.allAccount = { id: value.groupid, value: allAccountText, isChecked: isAllSubAccountsChecked };
                accordionList.push(account);
            }
            //if (source === "delete") {
            //    for (const [index, deletedAccount] of modifiedAccounts.entries()) {
            //        var indexOfAccount = -1;
            //        options.findIndex(function (obj, i) {
            //            if (obj.key === deletedAccount.key) {
            //                indexOfAccount = i;
            //                options.splice(indexOfAccount, 1);
            //            }
            //        })
            //    }
            //}
        }
        if (source === "hideExternalAccounts") {
            if (this.state.newAccounts && this.state.newAccounts.externalAccounts) {
                for (const [, value] of this.state.newAccounts.externalAccounts.entries()) {
                    for (const [, item] of value.groupby.entries()) {
                        var indexOfAccount = -1;
                        options.findIndex(function (obj, i) {
                            if (obj.key === item.key) {
                                indexOfAccount = i;
                            }
                        })
                        if (indexOfAccount >= 0) {
                            options.splice(indexOfAccount, 1);
                        }
                    }
                }
            }
        }
        else if (source === "delete") {
            for (const [, deletedAccount] of modifiedAccounts.entries()) {
                var indexOfAccount = -1;
                options.findIndex(function (obj, i) {
                    if (obj.key === deletedAccount.key) {
                        indexOfAccount = i;
                    }
                })
                options.splice(indexOfAccount, 1);
            }
        }
        else if (source === "manualAssetDeleted" && this.props.modifiedManualAccountKey !== undefined && this.props.modifiedManualAccountKey !== null && this.props.modifiedManualAccountKey !== "") {
            var deletedAccount = this.props.modifiedManualAccountKey;
            var indexOfAccount = -1;
            options.findIndex(function (obj, i) {
                if (obj.key === deletedAccount) {
                    indexOfAccount = i;
                }
            })
            options.splice(indexOfAccount, 1);
        }
        this.state.accounts = accordionList;
        if (source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset") {
            this.state.options = newOptions;
        } else {
            this.state.options = options;
        }
        this.setState({ initialSelected: accordionList });
    }
    toggleAccordion(fiId, e) {
        var accounts = this.state.accounts;
        for (const [, value] of accounts.entries()) {
            if (value.fiId === fiId) {
                value.isOpen = !value.isOpen;
            } else {
                if (value.isOpen) {
                    value.isOpen = false;
                }
            }
        }
        this.setState({ accounts: accounts });
    }

    componentDidUpdate(prevProps) {
        //Handling the account context changed from account overview, showing the account clicked from account overview as selected.
        if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) || ((prevProps.aggregatedAcctModifiedList) && prevProps.aggregatedAcctModifiedList.isExternalAccountAdded !== this.props.aggregatedAcctModifiedList.isExternalAccountAdded)
            || (this.props.updateNickNameFlag !== prevProps.updateNickNameFlag) || (this.props.profileResp !== prevProps.profileResp)) {
            this.state.showExternalAccounts = this.props.showExternalAccounts;

            var allAccount = this.state.allAccount;
            var source = "";
            if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) && !this.props.showExternalAccounts) {
                source = 'hideExternalAccounts';
            } else if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) && this.props.showExternalAccounts) {
                source = 'showExternalAccounts';
            } else if (this.props.updateNickNameFlag !== prevProps.updateNickNameFlag) {
                source = 'updateNickname';
            } else if (this.props.aggregatedAcctModifiedList !== prevProps.aggregatedAcctModifiedList) {
                source = this.props.aggregatedAcctModifiedList.modifiedType;
            } else if (this.props.profileResp !== prevProps.profileResp) {
                 if (this.props.acctSelectorChangeReason === "ADD_ACCOUNTS_IN_MANUAL_ASSET") {
                    source = 'manualAssetsAdded';
                } else if (this.props.acctSelectorChangeReason === "DELETE_ACCOUNT_IN_MANUAL_ASSET") {
                    source = 'manualAssetDeleted';
                } else if (this.props.acctSelectorChangeReason === "EDIT_ACCOUNTS_IN_MANUAL_ASSET") {
                    source = 'nicknameUpdatedFromGroupOrManualAsset';
                }

            }




            //else {
            //    source = this.props.aggregatedAcctModifiedList.modifiedType;
            //}


            //if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) && !this.props.showExternalAccounts) {
            //    source = 'hideExternalAccounts';
            //} else if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) && this.props.showExternalAccounts) {
            //    source = 'showExternalAccounts';
            //} else if (this.props.updateNickNameFlag !== prevProps.updateNickNameFlag) {
            //    source = 'updateNickname';
            //} else if (this.props.aggregatedAcctModifiedList !== prevProps.aggregatedAcctModifiedList) {
            //    source = this.props.aggregatedAcctModifiedList.modifiedType;
            //} else if (this.props.profileResp !== prevProps.profileResp) {
            //    if (this.props.acctSelectorChangeReason === "ADD_GROUP" || this.props.acctSelectorChangeReason === "UPDATE_GROUP_NAME" || this.props.acctSelectorChangeReason === "ADD_ACCOUNTS_IN_GROUP"
            //        || this.props.acctSelectorChangeReason === "DELETE_GROUP" || this.props.acctSelectorChangeReason === "DELETE_ACCOUNT_IN_GROUP" || this.props.acctSelectorChangeReason === "GROUP_SORT") {
            //        source = 'groupUpdated';
            //    } else if (this.props.acctSelectorChangeReason === "ADD_ACCOUNTS_IN_MANUAL_ASSET") {
            //        source = 'manualAssetsAdded';
            //    } else if (this.props.acctSelectorChangeReason === "DELETE_ACCOUNT_IN_MANUAL_ASSET") {
            //        source = 'manualAssetDeleted';
            //    } else if (this.props.acctSelectorChangeReason === "NICKNAME_UPDATE" || this.props.acctSelectorChangeReason === "EDIT_ACCOUNTS_IN_MANUAL_ASSET") {
            //        source = 'nicknameUpdatedFromGroupOrManualAsset';
            //    }

            //}



            this.createAccountList(source);
            this.handleSelections();
            var dropDownValue = this.state.dropDownValue;
            var isAllAccountSelected = this.checkAllAccountSelected();

            if (isAllAccountSelected) {
                dropDownValue = "All Accounts";
                allAccount.isChecked = true;
            } else if (!isAllAccountSelected && (this.state.options.length <= 3) && (this.state.options.length !== 0)) {
                for (const [index, value] of this.state.options.entries()) {
                    if (index == 0) {
                        dropDownValue = value.nickname ? value.nickname : value.number;
                    } else {
                        dropDownValue = dropDownValue + ", " + (value.nickname ? value.nickname : value.number);
                    }
                }
                allAccount.isChecked = false;
            } else if (this.state.options.length > 3) {
                dropDownValue = this.state.options.length + " Selected";
                allAccount.isChecked = false;
            } else if (this.state.options.length == 0) {
                dropDownValue = "Please select an account";
                allAccount.isChecked = false;
            }
            this.state.dropDownValue = dropDownValue;
            this.setState({ allAccount: allAccount });
            setContextInCache(this.state.options, this.props.token, this.props.contextjson, this.props.profilejson)
                .then(res => {
                    this.props.UpdateAccount(this.state.options, this.props.render);
                })
                .catch(err => {
                    console.log(err);
                    this.cancel();
                })
        } else if (this.props.isAccountContextChanged) {
            this.selectUnSelectAllOptions(false);
            var accounts = this.state.accounts;
            for (const [, value] of accounts.entries()) {
                for (const [, account] of value.accounts.entries()) {
                    if ((account.isInternal && account.number === this.props.selectedaccount[0].number) || (!(account.isInternal) && account.key === this.props.selectedaccount[0].key)) {
                        account.isChecked = true;
                    }
                }
            }
            this.setState({ accounts: accounts });
            this.handleSelections();
            this.setState({ dropDownValue: this.state.options[0].nickname ? this.state.options[0].nickname : this.state.options[0].number });
            this.props.UpdateAccount(this.props.selectedaccount, this.props.render, false);
        }
        else if (this.props.updateNickNameFlag !== prevProps.updateNickNameFlag) {
            var accounts = this.props.accounts;
            if (!this.state.allAccount.isChecked) {
                for (const [, value] of accounts.entries()) {
                    var indexOfAccount = -1;
                    this.state.options.findIndex(function (item, i) {
                        if (item.number == value.number) {
                            indexOfAccount = i;
                        }
                    })
                    if (indexOfAccount >= 0) {
                        value.isChecked = true;

                    } else {
                        value.isChecked = false;
                    }
                }
            }
            this.state.accounts = accounts;
            this.handleSelections();
        }
    }
    toggle() {
        if (this.state.dropdownOpen) {
            var initialSelected = JSON.parse(JSON.stringify(this.state.initialSelected));
            var initialAllAccountSelected = JSON.parse(JSON.stringify(this.state.initialAllAccountSelected));
            var initialDropDownValue = this.state.initialDropDownValue;
            var initialOptions = JSON.parse(JSON.stringify(this.state.initialOptions));
            this.setState({ allAccount: initialAllAccountSelected, accounts: initialSelected, dropDownValue: initialDropDownValue, options: initialOptions });

            this.disableApply();
        }
        this.handleSelections();
        this.state.searchKeyword = '';
        this.filterList();
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));

    }
    clearAll() {

        this.selectUnSelectAllOptions(false);
        this.setState({ dropDownValue: "Please select an account" });
        this.disableApply();
    }
    handleSelections() {
        var selectedAccounts = JSON.parse(JSON.stringify(this.state.accounts));
        var selectAll = JSON.parse(JSON.stringify(this.state.allAccount));

        this.setState({ initialAllAccountSelected: selectAll, initialSelected: selectedAccounts });
        const options = this.state.options;
        var accounts = this.state.accounts;
        if (this.state.allAccount.isChecked == true) {
            this.selectUnSelectAllOptions(true);
        } else {
            for (const [, value] of accounts.entries()) {
                for (const [, account] of value.accounts.entries()) {
                    var indexOfAccount = -1;
                    options.findIndex(function (item, i) {
                        if ((item.isInternal && item.number === account.number) || (!(item.isInternal) && item.key === account.key)) {
                            indexOfAccount = i;
                        }
                    })
                    if (account.isChecked && !(indexOfAccount >= 0)) {
                        options.push({ ...account });

                    } else if (!(account.isChecked) && indexOfAccount >= 0) {
                        options.splice(indexOfAccount, 1);
                    }
                }
                value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
            }
        }
        var dropDownValue = this.state.dropDownValue;

        var initialOptions = JSON.parse(JSON.stringify(options));

        this.setState({ accounts: accounts, options: options, initialDropDownValue: dropDownValue });
        this.state.initialOptions = initialOptions;

        this.disableApply();
    }
    checkAllSubAccountSelected(accounts) {
        for (const [, account] of accounts.entries()) {
            if (!account.isChecked) {
                return false;
            }
        }
        return true;
    }
    checkAllAccountSelected() {
        for (const [, value] of this.state.accounts.entries()) {
            for (const [, account] of value.accounts.entries()) {
                if (!account.isChecked) {
                    return false;
                }
            }
        }
        return true;
    }

    selectUnSelectAllSubAccounts(id, flag) {
        var accounts = this.state.accounts;
        const options = this.state.options;

        for (const [, value] of accounts.entries()) {
            if (value.fiId === id) {
                for (const [, account] of value.accounts.entries()) {
                    account.isChecked = flag;
                    var indexOfAccount = -1;
                    options.findIndex(function (item, i) {
                        if ((item.isInternal && item.number === account.number) || (!(item.isInternal) && item.key === account.key)) {
                            indexOfAccount = i;
                        }
                    })
                    if (flag && indexOfAccount < 0) {
                        options.push({ ...account });
                    } else if (!(flag) && indexOfAccount >= 0) {
                        options.splice(indexOfAccount, 1);
                    }
                }
                value.allAccount.isChecked = flag;
            }

        }

        this.setState({ accounts: accounts, options: options });

    }
    selectUnSelectAllOptions(flag) {
        const options = this.state.options;

        var allAccount = this.state.allAccount;
        allAccount.isChecked = flag;
        const accounts = this.state.accounts;

        for (const [, value] of accounts.entries()) {

            value.allAccount.isChecked = flag;
            for (const [, account] of value.accounts.entries()) {
                account.isChecked = flag;
                var indexOfAccount = -1;
                options.findIndex(function (item, i) {
                    if ((item.isInternal && item.number === account.number) || (!(item.isInternal) && item.key === account.key)) {
                        indexOfAccount = i;
                    }
                })
                if (flag && !(indexOfAccount >= 0)) {
                    options.push({ ...account });
                }
            }

        }
        if (!flag) {
            options.length = 0;
        }
        this.setState({ allAccount: allAccount, options: options, accounts: accounts });
    }

    handleSelectChange(param, e) {
        this.state.dropDownValue = "";
        var accounts = this.state.accounts;
        var allAccount = this.state.allAccount;
        var options = this.state.options;

        if (e.target.value === "All Accounts" && e.target.checked) {
            this.selectUnSelectAllOptions(true);
        } else if (e.target.value === "All Accounts" && !e.target.checked) {
            this.selectUnSelectAllOptions(false);
        } else if (e.target.value.includes("All sub Accounts") && e.target.checked) {
            this.selectUnSelectAllSubAccounts(param.id, true);
        } else if (e.target.value.includes("All sub Accounts") && !e.target.checked) {
            this.selectUnSelectAllSubAccounts(param.id, false);
        } else {
            for (const [, value] of accounts.entries()) {
                for (const [, account] of value.accounts.entries()) {
                    if ((account.isInternal && account.number === e.target.value) || (!account.isInternal && account.key === e.target.value)) {
                        var indexOfOptions = -1;
                        options.findIndex(function (item, i) {
                            if ((item.isInternal && item.number === e.target.value) || (!item.isInternal && item.key === e.target.value)) {
                                indexOfOptions = i;
                            }
                        })
                        account.isChecked = e.target.checked;
                        if (e.target.checked && indexOfOptions < 0) {
                            options.push({ ...account });
                        } else if (!(e.target.checked) && indexOfOptions >= 0) {
                            options.splice(indexOfOptions, 1);
                            value.allAccount.isChecked = false;
                        }
                    }
                }
                if (e.target.checked) {
                    value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
                }
            }
        }

        var dropDownValue = this.state.dropDownValue;

        if (this.checkAllAccountSelected()) {
            dropDownValue = "All Accounts";
            allAccount.isChecked = true;
        } else if (!this.checkAllAccountSelected() && (options.length <= 3) && (options.length !== 0)) {
            for (const [index, value] of options.entries()) {
                if (index == 0) {
                    dropDownValue = value.nickname ? value.nickname : value.number;
                } else {
                    dropDownValue = dropDownValue + ", " + (value.nickname ? value.nickname : value.number);
                }
            }
            allAccount.isChecked = false;
        } else if (options.length > 3) {
            dropDownValue = options.length + " Selected";
            allAccount.isChecked = false;
        } else if (options.length == 0) {
            dropDownValue = "Please select an account";
            allAccount.isChecked = false;
        }
        this.setState({ options: options, accounts: accounts, allAccount: allAccount, dropDownValue: dropDownValue });

        this.disableApply();
    }

    apply() {

        // Make Asyc call to profile set context
        if (this.props.clientRole === "Prospect") {
            try {
                this.setState(prevState => ({
                    dropdownOpen: !prevState.dropdownOpen,
                    //render: !prevState.render
                }), () => {
                        this.props.UpdateAccount(this.state.options, this.props.render);
                        this.props.UpdateTopten();
                        this.props.UpdateDocuments();
                });
            }
            catch (err) {
                console.log(err);
                this.cancel();
            }
        }
        else {
            setContextInCache(this.state.options, this.props.token, this.props.contextjson, this.props.profilejson)
                .then(res => {
                    this.setState(prevState => ({
                        dropdownOpen: !prevState.dropdownOpen,
                        //render: !prevState.render
                    }), () => {
                            this.props.UpdateAccount(this.state.options, this.props.render);
                            this.props.UpdateTopten();
                            this.props.UpdateDocuments();
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.cancel();
                })

        }

    }

    cancel() {
        var initialSelected = JSON.parse(JSON.stringify(this.state.initialSelected));
        var initialAllAccountSelected = JSON.parse(JSON.stringify(this.state.initialAllAccountSelected));

        var initialDropDownValue = this.state.initialDropDownValue;
        var initialOptions = JSON.parse(JSON.stringify(this.state.initialOptions));

        this.setState({ accounts: initialSelected, dropDownValue: initialDropDownValue, allAccount: initialAllAccountSelected, options: initialOptions });
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
        this.disableApply();
    }

    disableApply() {
        this.state.options.sort(function (a, b) {
            var x = a.number.toLowerCase();
            var y = b.number.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        this.state.initialOptions.sort(function (a, b) {
            var x = a.number.toLowerCase();
            var y = b.number.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        if ((this.state.options.length === 0) || JSON.stringify(this.state.options) == JSON.stringify(this.state.initialOptions.sort())) {
            this.state.disableApplyFlag = "disabled";
        } else {
            this.state.disableApplyFlag = "";
        }
    }

    updateSearchKeyword(e) {
        //this.state.searchKeyword = e.target.value;
        //this.filterList();
    }
    filterList() {
        //var updatedList = this.state.accounts;
        //var searchKeyword = this.state.searchKeyword;
        //updatedList = updatedList.filter(function (item) {
        //    return item.number.toLowerCase().search(
        //        searchKeyword.toLowerCase()) !== -1;
        //});
        //this.setState({ items: updatedList });
    }

    getDisplayName(account) {
        var actName = (account.nickname !== undefined && account.nickname !== null && account.nickname.length > 0 && account.displaynickname[0].dispname !== undefined && account.displaynickname[0].dispname !== null && account.displaynickname[0].dispname.length > 0) ? account.displaynickname[0].dispname : (account.displaynickname[0].displongname === undefined || account.displaynickname[0].displongname === null || account.displaynickname[0].displongname === '')
            ? ''
            : account.displaynickname[0].displongname;
        return actName;
    }
    render() {
        return (
            <SelectWithCheckboxView
                state={this.state}
                toggle={this.toggle}
                handleSelectChange={this.handleSelectChange}
                getDisplayName={this.getDisplayName}
                apply={this.apply}
                cancel={this.cancel}
                clientRole={this.props.clientRole}
                toggleAccordion={this.toggleAccordion}
                clearAll={this.clearAll} />

        );
    }
}

function mapStateToProps(state) {
    return {
        accounts: state.acct.accounts,
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        isAccountContextChanged: state.acct.isAccountContextChanged,
        showExternalAccounts: (state.acct && state.acct.preferences) ? JSON.parse(state.acct.preferences.showexternalaccount) : false,
        accountList: state.acct.accountList,
        aggregatedAcctModifiedList: state.acct.aggregatedAcctModifiedList,
        updateNickNameFlag: state.acct.updateNickNameFlag,
        clientRole: state.acct.role,
        acctSelectorChangeReason: state.acct.acctSelectorChangeReason,
        modifiedManualAccountKey: state.acct.deletedManualAccountKey,
        profileResp: state.acct.profileResp,
        profileId: state.acct.profileId,
        acct: state.acct
    };
};

const mapDispatchToProps = {
    UpdateAccount, UpdateTopten,UpdateDocuments
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectWithCheckbox);