import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AddPhonemethod from "./phoneMethods/AddPhonemethod";

const AddAuthenticationMethod = ({ isOpen, handleCloseAddAuthenticationPopup }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => handleCloseAddAuthenticationPopup(false)}
        backdrop={false}
        size="lg"
        className="RCM_CM_modal"
        wrapClassName="RCM_CM_modal_wrapper"
        modalClassName="RCM_CM_modal_class"
        contentClassName="RCM_CM_modal_content"
        centered
      >
        <div>
          <ModalHeader toggle={() => handleCloseAddAuthenticationPopup(false)} charCode="X">
            <label className="RCM_tile_heading">
              ADD AUTHENTICATION METHOD
            </label>
          </ModalHeader>
          <ModalBody style={{overflow: 'visible'}}>
            <>
              <AddPhonemethod
                handleCloseAddAuthenticationPopup={handleCloseAddAuthenticationPopup}
              />
            </>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default AddAuthenticationMethod;
