import React from 'react';
import Messages from '../../../common/messages.jsx';
import {  MessageClass } from '../../../common/constants.jsx';
import { CustomizationView } from './../../accounts/customizationview.jsx';
import RCMToggleButton from '../../../common/togglebutton.jsx';

export function PreferenceView(props) {

    let preferenceView = (
        <div>
            <div className="row">
                <div className="col-sm-12" key="lblholdingheadingrow">
                    <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblholdingheading'>PREFERENCES</label>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5">
                    <div className="RCM_Toast" id="RCM_preference_navigation">
                        <div> <label className="RCM_tile_heading">ACCOUNTS AND NAVIGATION STYLE</label></div>
                        <div className="row mt-2">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                {
                                    (props && props.state && props.state.isPreferenceUpdated)
                                        ? props && props.state && props.state.preferenceMessages && props.state.preferenceMessages.length > 0
                                            ? <Messages messages={props && props.state && props.state.preferenceMessages}
                                                containerClass={
                                                    props && props.state && props.state.preferenceMessages && props.state.preferenceMessages.find(msg => msg.class === MessageClass.ERROR)
                                                        ? "RCM_validation_error_container"
                                                        : ""
                                                } />

                                            : ""
                                        : ""
                                }
                            </div>
                        </div>
                        <div>
                            <div className="row mt-2" id="RCM_accounts_only">
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                    <span className="labelSpan"><label className="RCM_label profile-font-color">Show Rockefeller Accounts Only</label></span>
                                    <div className="RCM_Inline_Div RCM_Preference_toggle_Padding">
                                        <RCMToggleButton
                                            selectedOption={props.state.rockcoAcctToggleValue ? "No" : "Yes"}
                                            leftOption="No"
                                            rightOption="Yes"
                                            onToggle={() => props.toggleShowRockcoAcct()}
                                        />
                                        {/*<ToggleButton
                                            inactiveLabel={"Yes"}
                                            activeLabel={"No"}
                                            value={props.state.rockcoAcctToggleValue}
                                            onToggle={() => props.toggleShowRockcoAcct()}
                                            colors={{
                                                inactive: {
                                                    base: '#E2E2E2',
                                                },
                                                active: {
                                                    base: '#E2E2E2',
                                                },
                                            }}
                                            thumbAnimateRange={[0, 40]}
                                            thumbStyle={thumbStyle}
                                            trackStyle={trackStyle}
                                            activeLabelStyle={{
                                                color: '#000',
                                                height: '10px'
                                            }}
                                            inactiveLabelStyle={{
                                                color: '#000',
                                                height: '10px'
                                            }}
                                        />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            props && props.state && props.state.isViewPreference ?
                                <div className="row mt-4" id="RCM_view_preferences">

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">

                                        <div className="row mt-2">
                                            <div className="col-sm-5 col-xs-5 col-md-5 col-lg-5">
                                                <div>
                                                    <span className="RCM_icon_hand">
                                                        <a className="RCM_Cursor_Pointer" title="Normal View">
                                                            <div className={"RCM_Thumbnail_img  RCM_View_Normal"}></div>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div className="radio mt-3">
                                                    <label className="RCM_icon_hand">
                                                        <input type="radio" value="Normal" checked={props && props.state && props.state.preferecevalue === 'Normal' ? true : false} name="viewpreference" onChange={props.handlePreferenceChanges} />
                                                        <span className="RCM_radio_span">Drop-down</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-5 col-xs-5 col-md-5 col-lg-5">
                                                <div> <span className="RCM_icon_hand">
                                                    <a className="RCM_Cursor_Pointer" title="Tab View">
                                                        <div className={"RCM_Thumbnail_img  RCM_View_Tab"}></div>
                                                    </a>
                                                </span></div>
                                                <div className="radio mt-3">
                                                    <label className="RCM_icon_hand">
                                                        <input type="radio" value="Tab" name="viewpreference" checked={props && props.state && props.state.preferecevalue === 'Tab' ? true : false} onChange={props.handlePreferenceChanges} />
                                                        <span className="RCM_radio_span">Tab</span>
                                                    </label>
                                                </div>
                                            </div>

                                        </div></div>

                                </div> : ''}

                    </div>
                </div>
                        <div className="col-sm-7" id="RCM_tile_selection">
                            <div className="RCM_Toast" id="RCM_preference_home">
                                <div> <label className="RCM_tile_heading">HOME PAGE</label></div>
                                <div className="row mt-2">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        {
                                    (props && props.state && props.state.iscustomizationPreferenceUpdated)
                                        ? props && props.state && props.state.preferenceMessages && props.state.preferenceMessages.length > 0
                                            ? <Messages messages={props && props.state && props.state.preferenceMessages}
                                                containerClass={
                                                    props && props.state && props.state.preferenceMessages && props.state.preferenceMessages.find(msg => msg.class === MessageClass.ERROR)
                                                        ? "RCM_validation_error_container"
                                                        : ""
                                                } />

                                            : ""
                                        : ""
                                }
                            </div>
                        </div>


                        <CustomizationView
                            state={props.state}
                            handleInputChange={props.handleInputChange}
                        />



                    </div>
                </div>
            </div>
            <div className="row RCM_Preference_Note">
                <label className="RCM_Customization_text RCM_Customization_text_small_font">*&nbsp; Selection will save immediately.</label>
            </div>

        </div>
    )
    return preferenceView;

}