import React, { Component } from 'react';
import { Get } from '../../../../common/servicecalls.jsx';
import { convertDttime, AssetallocationURL, GetAccountsList, getAccountDisplayName, IndLongShort, GetNumericValue, formatMoney, CheckIfProspectClient, GetPreferencePilotValue, GetPreferenceValue,  assetSchemaColorsConfigUrl} from './../../../../common/constants.jsx';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { compose } from 'redux';
import { AddAssetAllocationDetails, UpdateAssetSchemaAssetDetails, AddAssetColor, UpdateHistoricalAssetSchema } from '../../../../actions/accountsactions.jsx';
import { UpdateAssetSchema } from '../../../../actions/toptenactions.jsx';
import axios from 'axios';
import ErrorBoundary from './../../../../common/errorboundary.jsx';
import { AssetAllocationDetailsView } from './assetallocationdetailsview.jsx';
import { _logException, _logInformation, _logPageView } from '../../../../common/_logging.js';
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { getColumnsForExpandedRow } from './assetallocationdetailscolumns.jsx';
import { assetallocationDummyResponse, RRR_123456_assetallocationDummyResponse, RRR_234567_assetallocationDummyResponse, assetAllocationColorRes } from './../../accountsmockresponse.js';
import orderBy from 'lodash/orderBy';
import { getSchemaValue, assetClassDropDownItems } from './../../../../common/assetschemautilities';
import { renderTemplateRows } from './assetallocationdetailscolumns';

class Assetallocationdetails extends Component {

    constructor(props) {
        super(props);
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            requestFailed: false,
            assetallocationresponse: this.props.assetallocationdetails,
            accounts: this.accounts,
            portalid: this.props.portalid,
            render: this.props.render,
            isloading: true,
            activeTab:'1',
            areDataRetrieved: false,
            name: this.props.name,
            childColumnToSort: "",
            childColumnSortOrder: "",
            isLoadingAssetColor: false,
            requestFailedAssetColor: false,
            expanded:[],
            isProspectClient:CheckIfProspectClient(this.props.role),
            classDropdownOpen: false,
            dropdownClassValue: this.props.assetSchema,
            showAssetClassOption: this.assetClassViewPreference(this.props),
            assetcolorRes: this.props.assetschemacolorsconfig
        }
        this.execute = this.execute.bind(this);
        this.isExpandableRow = this.isExpandableRow.bind(this);
        this.acctFormatter = this.acctFormatter.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.changeClass = this.changeClass.bind(this);

    }
    
    toggleClass() {
        this.setState(prevState => ({
            classDropdownOpen: !prevState.classDropdownOpen
        }));
    }
    changeClass(selectedClass) {
        //let class = this.classDropDownItems.find(item => item.label === selectedType.currentTarget.textContent.trim()).value;

        this.setState({
            dropdownClassValue: selectedClass.currentTarget.textContent.trim(),
            //classValue: class
        }, () => {
            let showtable = GetPreferenceValue(this.props.acct, "AssetAllocation", "chart") === "table" ? true : false
                this.execute(showtable, this.state.dropdownClassValue);
                this.props.UpdateAssetSchemaAssetDetails(this.state.dropdownClassValue);
                this.props.UpdateAssetSchema();
                this.props.UpdateHistoricalAssetSchema(this.state.dropdownClassValue);
                
        });
    }

    assetClassViewPreference(preference) {
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'AssetSchemaV2', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }
    getSchemaValue() {
        if (this.state.dropdownClassValue) {
            let res = assetClassDropDownItems.find(c => c.label === this.state.dropdownClassValue);
            if (res) {
                return res.value;
            }
        }
        else return "NFS";
    }
    aadcanellationToken = null;
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false, areDataRetrieved: false}));
        let baseUrl = AssetallocationURL + '\'' + "" + '\',' + null + ')';
        baseUrl = this.state.showAssetClassOption ? (AssetallocationURL + '\'' + "" + '\',' + null + ',' + (getSchemaValue(this.state.dropdownClassValue)) + ')') : baseUrl;

        if (this.aadcanellationToken) {
            this.aadcanellationToken.cancel('Operation canceled due to new request.')
        }
        this.aadcanellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.aadcanellationToken)
            .then(result => {
                this.props.AddAssetAllocationDetails(result.data);
                this.setState(() => ({ assetallocationresponse: result.data, isloading: false, areDataRetrieved: true }))
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailed: true, error: error.message }))
                }

            })
    };
    assetColorCancellationToken = null;
    executeAssetColor = () => {
        this.setState(() => ({ isLoadingAssetColor: true, requestFailedAssetColor: false }));

        let baseUrl = assetSchemaColorsConfigUrl;

        if (this.assetColorCancellationToken) {
            this.assetColorCancellationToken.cancel('Operation canceled due to new request.')
        }
        this.assetColorCancellationToken = axios.CancelToken.source();

        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.assetColorCancellationToken)
            .then(result => {
                this.setState(() => ({ assetcolorRes: result.data, isLoadingAssetColor: false, requestFailedAssetColor: false }))
                this.props.AddAssetColor(result.data)
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailedAssetColor: true, error: error.message }))
                }
            })
    };

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient()
            }
            else {
                this.execute();
            }
            
        }
    }
    componentDidMount() {
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient()
        }
        else {
            if (this.props.assetallocationdetails === null) {
                this.execute();
            }
            else {
                this.setState({ isloading: false, areDataRetrieved: true, expanded: []});
            }
            if (this.props.assetschemacolorsconfig === null) {
                this.props.AddAssetColor(assetAllocationColorRes)
                this.setState(() => ({
                    isLoadingAssetColor: false, requestFailedAssetColor: false, assetcolorRes: assetAllocationColorRes
                }))
            }
            else {
                this.setState({
                    isLoadingAssetColor: false, requestFailedAssetColor: false
                });
            }
        }

    }

    componentWillUnmount() {
        if (this.aadcanellationToken !== null && this.aadcanellationToken !== undefined) {
            this.aadcanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
        if (this.assetColorCancellationToken !== null && this.assetColorCancellationToken !== undefined) {
            this.assetColorCancellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }

    isExpandableRow(row) {
        if (row.assetcat !== null || row.invpossummary.length > 0 && row.invpossummary !== null || row.invposlist && row.invposlist.length > 0) return true;
        else return false;
    }
    renderTemplateRowsByCategoty(position, childColumnToSort, childColumnSortOrder) {
        let data = []
        let datarows = []
        let j = 0;//this is for calculating distinct id to each row
        if (childColumnToSort === 'assetcat') childColumnToSort = 'ticker';
        if (childColumnToSort === 'mktvalpct') childColumnToSort = 'assettotalpct';
        if (childColumnToSort === 'mktval') childColumnToSort = 'mktvals';
        for (var i = 0; i < position.length; i++) {
            data = position[i].invposlist;

            data.map((d, index) => datarows.push({
                CUSIP: d.cusip,
                mktvalpct: d.mktvalpct,
                mktval: d.mktval,
                ASSETCAT: d.secinfo.assetcat,
                ticker: (d.secinfo.ticker ? d.secinfo.ticker : d.secinfo.unqid),
                secname: d.secinfo.secname,
                shrqnts: d.shrqnts.unit,
                pricing: d.pricing.rcntprice,
                mktvals: d.mktvals.rcntmktval,
                assettotalpct: '',
                acctkey: d.acctkey,
                acctnum: d.acctnum,
                id: j++
            }));

        }
        if (childColumnToSort !== '' && childColumnSortOrder !== '') {
            if (childColumnToSort === 'ticker') {
                datarows = orderBy(datarows, [row => row[childColumnToSort].toLowerCase()], [childColumnSortOrder]);
            } else {
                datarows = orderBy(datarows, [childColumnToSort], [childColumnSortOrder]);
            }
        }

        return datarows;
        //data.unshift({ name: name })
    }

    customCaretSort(order, column) {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }

    acctFormatter(accountlist, cell) {
        return (<div title={getAccountDisplayName(accountlist, cell, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, cell, IndLongShort.SINGLEVIEW)}</div>)
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.assetallocationdetails === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ isloading: false, requestFailed: false, assetallocationresponse: RRR_123456_assetallocationDummyResponse, areDataRetrieved: true, expanded: ["Common Stock"] }), () => this.props.AddAssetAllocationDetails(RRR_123456_assetallocationDummyResponse));
                    

                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ isloading: false, requestFailed: false, assetallocationresponse: RRR_234567_assetallocationDummyResponse, areDataRetrieved: true, expanded: ["Mutual Fund"] }),()=> this.props.AddAssetAllocationDetails(RRR_234567_assetallocationDummyResponse));
                }

            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ isloading: false, requestFailed: false, assetallocationresponse: assetallocationDummyResponse, areDataRetrieved: true, expanded: ["Common Stock", "Mutual Fund"] }),()=>this.props.AddAssetAllocationDetails(assetallocationDummyResponse));
            }
        }
        else {
            this.setState({ isloading: false, areDataRetrieved: true, expanded: ["Common Stock", "Mutual Fund"] });
        }

        if (this.props.assetschemacolorsconfig === null) {
            this.props.AddAssetColor(assetAllocationColorRes)
            this.setState(() => ({
                isLoadingAssetColor: false, requestFailedAssetColor: false, assetcolorRes: assetAllocationColorRes
            }))
        }
        else {
            this.setState({
                isLoadingAssetColor: false, requestFailedAssetColor: false
            });
        }
    }

    render() {
        var assetallocationData = [];
        if (this.state.assetallocationresponse && this.state.assetallocationresponse[0].value) {
           var rowasset = this.state.assetallocationresponse[0];
            assetallocationData = renderTemplateRows(rowasset, this.state.assetcolorRes, this.state.dropdownClassValue);
            var totalmkVal;
            var totalPct = this.state.assetallocationresponse[0].totalPct;
            var asofdate = this.state.assetallocationresponse[0].asOfDate;
            asofdate = convertDttime(asofdate);
            if (this.state.assetallocationresponse[0].mktvals) {
                totalmkVal = this.state.assetallocationresponse[0].mktvals.rcntmktval;
            }

        }
        const expandRowByCategory = {
            renderer: (row) => (
                <BootstrapTable
                    keyField='id'
                    noDataIndication="No Data Available"
                    data={this.renderTemplateRowsByCategoty(row.invpossummary, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                    columns={getColumnsForExpandedRow(this.props, this.props.selectedaccount)}
                    headerClasses="row-bg"
                    bordered={false}
                    classes="RCM_two_level_table2 RCM_Headerless_table" />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }

                return (<div className="RCM_icon_hand RCM_right_arrow" >
                </div>);
            }
        }

        return (
            <ErrorBoundary>
                <AssetAllocationDetailsView
                    state={this.state}
                    assetallocationData={assetallocationData}
                    renderTemplateRowsByCategoty={this.renderTemplateRowsByCategoty}
                    isExpandableRow={this.isExpandableRow}
                    selectedaccounts={this.props.selectedaccount}
                    totalPct={totalPct}
                    totalmkVal={totalmkVal}
                    expandRowByCategory={expandRowByCategory}
                    rowsortevent={this.rowsortevent}
                    changeClass={this.changeClass}
                    toggleClass={this.toggleClass}
                    classDropDownItems={assetClassDropDownItems}
                >
                </AssetAllocationDetailsView>
            </ErrorBoundary>

        )
    }

}
const mapStateToProps = state => {
    return {
        render: state.acct.render,
        portalid: state.acct.portalid,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        name: state.acct.name,
        assetallocationdetails: state.acct.assetallocationdetails,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        role: state.acct.role,
        preferences: state.acct.preferences,
        assetSchema: state.acct.assetSchema,
        assetschemacolorsconfig: state.acct.assetschemacolorsconfig
    };
};
const mapDispatchToProps = {
    AddAssetAllocationDetails,
    UpdateAssetSchemaAssetDetails,
    UpdateAssetSchema,
    AddAssetColor,
    UpdateHistoricalAssetSchema
};


const assetallocationdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Assetallocation detail' })
)



export default assetallocationdetailscomp(Assetallocationdetails);

