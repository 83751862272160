import { errorMsg, customizationComponentURL } from "./../../common/constants.jsx";
import { Post, Get } from "./../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "./../../common/_logging";

export var savepreferenceCancellationToken = null;
export var getHomePagePreferenceCancellationToken = null;

export const savePreferenceService = (componentDetails, token, contextJson) => {

    //Check if cancellation token has value;
    if (savepreferenceCancellationToken !== null && savepreferenceCancellationToken !== undefined) {
        //Cancel the existing call
        savepreferenceCancellationToken.cancel('Cancelling the save customization preference request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    savepreferenceCancellationToken = cancelToken.source();

    let savePreferencePromise = new Promise(
        function (resolve, reject) {
            const baseUrl = customizationComponentURL;
            Post(baseUrl, componentDetails, token, contextJson, savepreferenceCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Customization service: save components ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Customization service: save components :: ", error);
                        _logException("Customization service: save components :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return savePreferencePromise;
}

export const getHomePagePreference = (token, contextJson, profileJson) => {
    //Check if cancellation token has value;
    if (getHomePagePreferenceCancellationToken !== null && getHomePagePreferenceCancellationToken !== undefined) {
        //Cancel the existing call
        getHomePagePreferenceCancellationToken.cancel('Cancelling the get customization components Service request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getHomePagePreferenceCancellationToken = cancelToken.source();

    let getHomePagePreferencePromise = new Promise(
        function (resolve, reject) {
            const baseUrl = customizationComponentURL + "(" + "' " + "'" + ")";
            Get(baseUrl, token, contextJson, profileJson, getHomePagePreferenceCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Customization Service : get customization components Service ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Customization Service : get customization components Service :: ", error);
                        _logException("Customization Service :get customization components Service :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return getHomePagePreferencePromise;
}