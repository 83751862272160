import React from "react";
import AddExternalAccount from "./addExternalAccount.jsx";
import ExternalAccountsList from "./externalAccountsList.jsx";
import ErrorBoundary from "../../../common/errorboundary.jsx";
import { _logPageView } from "../../../common/_logging.js";
import { PageView } from "@microsoft/applicationinsights-web";

class ManageExternalAccounts extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        //show for page view
        let pageViewData =  new PageView();
        pageViewData.name = "Manage External Accounts";
        pageViewData.url = "/manageExtAccount"; 
        _logPageView(pageViewData);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="RCM_Toast" id="RCM_manageExtAddAccount">
                            <ErrorBoundary>
                                <AddExternalAccount />
                            </ErrorBoundary>
                        </div>

                    </div>
                    <div className="col-sm-8">
                        <div className="RCM_Toast" id="RCM_manageExtAccountData">
                            <ErrorBoundary>
                                <ExternalAccountsList />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageExternalAccounts;
