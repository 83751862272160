import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Get } from './../../../common/servicecalls.jsx';
import { ReportsView } from './reportsview.jsx';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import { _logException } from "../../../common/_logging.js";
import { withPageView } from '../../../hoc/withPageView.jsx';
import { getReportItemsService, getReportFolderSubFolderItems } from './reportsService.jsx';
import { getToken } from './reportsService';
import download from 'downloadjs'

class AzureReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportResponse: [],
            isloadingReportFailed: false,
            isloadingReport: false,
            isDownloadingReportFailed: false,
            isDownloadingReport: false,
            isPrintReportFailed: false,
            isPrintReport: false,
            isDocumentListResponseWithError: false,
            areDocumentsRetrieved: false,
            pdfUrl: '',
            selectedReport: [],
            reportData: []
        }
        this.previewDocument = this.previewDocument.bind(this);
        this.handlePortalClick - this.handlePortalClick.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.printReport = this.printReport.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this.getReportsFolderStructure = this.getReportsFolderStructure.bind(this);
    }

    getReportList = () => {
        const isSimulation =  sessionStorage.getItem("simulation") ? true : false
        this.setState({ areDocumentsRetrieved: false, pdfUrl: '', selectedReport:[], isDocumentListResponseWithError: false }, () => {
            const aadId = isSimulation ? 
                           this.props.profileResp ? this.props.profileResp.aaduseroid : undefined
                          : undefined  
            getReportItemsService(aadId)
                .then(res => {
                    let data = [];
                    data = res && Array.isArray(res) ? res : [];
                    this.getReportsFolderStructure(data);
                })
                .catch(error => {
                    console.error(error);
                    this.setState({ areDocumentsRetrieved: true, isDocumentListResponseWithError: true })
                })

        })

    }

    async getReportsFolderStructure(data) {
        try {
            let folderList = [];
            let folder = { name: 'Reports', reportList: [] };
            for (const [, item] of data.entries()) {

                if (item.folder) {
                    let folder = { name: item.name, reportList: [] };

                    let response = await getReportFolderSubFolderItems(item);
                    if (response) {

                        folder.reportList = response;
                        folderList.push(folder);
                    } else {
                        folderList.push(folder);
                    }
                } else {
                    folder.reportList.push(item);
                }

            }
            folderList.unshift(folder);
            this.setState({ reportResponse: folderList, areDocumentsRetrieved: true, isDocumentListResponseWithError: false });


        } catch (error) {
            console.log(error);
            this.setState({ reportResponse: [], areDocumentsRetrieved: true, isDocumentListResponseWithError: false });
        }
    }
    componentDidMount() {
        this.getReportList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            this.getReportList();
        }
    }

    handlePortalClick(row) {
        window.open(row.webUrl);
    }

    previewDocument(row) {
        if (this.state.selectedReport && this.state.selectedReport.length > 0) {
            if (this.state.selectedReport[0] !== row.id) {
                this.selectDocument(row);
            }
        }
        else {
            this.selectDocument(row);
        }

    }

    downloadReport = (row) => {
        this.setState({
            isDownloadingReportFailed: false,
            isDownloadingReport: true,
            isPrintReport: false,
            isPrintReportFailed: false,
        }, () => {
            const printurl = `https://graph.microsoft.com/v1.0/drives/${row.parentReference.driveId}/items/${row.id}`;
            getToken().then((token) => {
                if (token) {
                    Get(printurl
                        , token)
                        .then((res) => {
                            fetch(res.data['@microsoft.graph.downloadUrl'])
                                .then(res => res.blob())
                                .then(fblob => {
                                    download(fblob, res.data.name, res.data.file.mimeType)
                                })
                            this.setState({ isDownloadingReport: false, isDownloadingReportFailed: false })
                        })
                        .catch((err) => {
                            this.setState({ isDownloadingReport: false, isDownloadingReportFailed: true })
                            _logException(err);
                        });
                }
            });
        });

    }

    printReport = (row) => {
        this.setState({
            isPrintReport: true,
            isPrintReportFailed: false,
            isDownloadingReportFailed: false,
            isDownloadingReport: false
        }, () => {
            const printurl = `https://graph.microsoft.com/v1.0/drives/${row.parentReference.driveId}/items/${row.id}`;
            getToken().then((token) => {
                if (token) {
                    Get(printurl
                        , token)
                        .then((res) => {
                            fetch(res.data['@microsoft.graph.downloadUrl'])
                                .then(res => res.blob())
                                .then(fblob => {
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        window.navigator.msSaveOrOpenBlob(fblob);
                                    }
                                    else {
                                        var url = URL.createObjectURL(fblob);
                                        window.open(url);
                                    }
                                })
                            this.setState({ isPrintReport: false, isPrintReportFailed: false })
                        })
                        .catch((err) => {
                            this.setState({ isPrintReport: false, isPrintReportFailed: true })
                            _logException(err);
                        });
                }
            });
        });
    }
    downloadxlsxReport = (row) => {
        this.setState({
            isloadingReport: true,
            isloadingReportFailed: false,
        }, () => {
            const printurl = `https://graph.microsoft.com/v1.0/drives/${row.parentReference.driveId}/items/${row.id}`;
            getToken().then((token) => {
                if (token) {
                    Get(printurl
                        , token)
                        .then((res) => {
                            fetch(res.data['@microsoft.graph.downloadUrl'])
                                .then(res => res.blob())
                                .then(fblob => {
                                    download(fblob, res.data.name, "text/xlsx")
                                })
                        })
                        .catch((err) => {
                            this.setState({ isloadingReport: false, isloadingReportFailed: true })
                            _logException(err);
                        });
                }
            });
        });
    }

    selectDocument(row) {
        this.setState({
            pdfUrl: null,
            isloadingReport: true,
            isloadingReportFailed: false,
            selectedReport: [row.id]
        }, () => {
            const printurl = `https://graph.microsoft.com/v1.0/drives/${row.parentReference.driveId}/items/${row.id}`;
            getToken().then((token) => {
                if (token) {
                    Get(printurl
                        , token)
                        .then((res) => {
                            fetch(res.data['@microsoft.graph.downloadUrl'])
                                .then(res => res.blob())
                                .then(fblob => {
                                    var url = URL.createObjectURL(fblob);
                                    this.setState({ pdfUrl: url, isloadingReport: false, isloadingReportFailed: false });

                                })
                                .catch((err) => {
                                    this.setState({ isloadingReport: false, isloadingReportFailed: true })
                                    _logException(err);
                                });
                        })
                }


            });
        });

    }

    checkIfReportNameinFormat = (name) => {

        if (name && name != '') {
            if (name.includes('-')) {
                let datestr = name.split('.')[0].substr(name.split('.')[0].length - 10);
                if (datestr.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else return false;
        }
        else return false;

    }
    render() {
        let folderList = [];
        if (this.state.reportResponse && this.state.reportResponse.length > 0) {
            for (const [, folder] of this.state.reportResponse.entries()) {
                let reports = folder.reportList.filter(p => this.checkIfReportNameinFormat(p.name) === true);
                var reportsData = [];
                var i;
                for (i = 0; i < reports.length; i++) {
                    if (reports.id !== null) {

                        reportsData.push(
                            {
                                id: reports[i].id,
                                parentReference: reports[i].parentReference,
                                name: reports[i].name.split('.')[0].substr(0, reports[i].name.split('.')[0].length - 11),
                                date: reports[i].name.split('.')[0].substr(reports[i].name.split('.')[0].length - 10)
                            }
                        )
                    }

                }
                let folderData = { name: folder.name, reportlist: reportsData };
                folderList.push(folderData);
            }
        }

        return (
            <ErrorBoundary>
                <ReportsView state={this.state}
                    role={this.props.role}
                    previewDocument={this.previewDocument}
                    handlePortalClick={this.handlePortalClick}
                    printReport={this.printReport}
                    downloadReport={this.downloadReport}
                    data={folderList}
                />
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        render: state.acct.render,
        token: state.acct.token,
        role: state.acct.role,
        profileResp: state.acct.profileResp
    };
};
const enhancement = compose(
    connect(mapStateToProps),
    withPageView({ pageName: 'Reports' })
)

export default enhancement(AzureReports);