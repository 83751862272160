import React, { Component } from 'react';
import { Get } from '../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { TASsummaryURL, spinner, MsgForNoData, MsgForFailedData } from './../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { taschildcolumns, generateExcel, printCustomWindow } from './realizedgainlosscolumns.jsx';
import axios from "axios";
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { compose } from 'redux';
class TASHoldingdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false,
            tasholdingsresponse: [],
            accounts: this.props.selectedaccount,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            rowdata: this.props.row,
            ismodalopen: false

        }
        this.execute = this.execute.bind(this);
        this.toggleCloseModal = this.toggleCloseModal.bind(this);
    }
    getTasHoldingsCancellationToken = null;
    toggleCloseModal() {
        this.setState({
            ismodalopen: false
        });
      //  this.props.onModalUnmount();
    }

    //method to execute api calls
    execute = (accountlist) => {
        this.setState(() => ({ isloading: true, ismodalopen: true }), () => {
            let data = []
            let datarows = []
            var rowaccount = this.state.rowdata && this.state.rowdata.ACCTKEY ? this.state.rowdata.ACCTKEY : "";
            var response;
            const baseUrlSecurity = TASsummaryURL + '\'' + this.state.rowdata.CUSIP + '\'' + ',acct=' + '\'' + rowaccount + '\'' + ',rid=' + '\'' + null + '\')';
            console.log(baseUrlSecurity);
            if (this.getTasHoldingsCancellationToken) {
                //Cancel the existing call
                this.getTasHoldingsCancellationToken.cancel('Cancelling the existing Security tas Holdings request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getTasHoldingsCancellationToken = cancelToken.source();
            Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getTasHoldingsCancellationToken)
                .then(result => {
                    response = result && result.data;
                    data = response && response.value && response.value.length > 0 && response.value[0] != null ?
                        response.value : datarows;
                    this.setState(() => ({ tasholdingsresponse: data, isloading: false }));
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailed: true, error: error.message }));
                    }
                });
        });


    };

    componentDidMount() {
        //if props has data then take it or make api call
        //if(this.state.isProspectClient)
        //    this.setState(() => ({ isloading: false, requestFailed: false, tasholdingsresponse: (taslotsummaryDummyResponse && taslotsummaryDummyResponse.value && taslotsummaryDummyResponse.value.length > 0 ? taslotsummaryDummyResponse.value : []) }));
        //else
            this.setState(() => ({ tasholdingsresponse: this.state.rowdata, isloading: false, ismodalopen: true, requestFailed: false }));


    }

    componentWillUnmount() {
        if (this.getTasHoldingsCancellationToken !== null && this.getTasHoldingsCancellationToken !== undefined) {
            this.getTasHoldingsCancellationToken.cancel('Cancelling the Security tas Holdings during unmount to prevent memory leak.');
        }
       // this.props.onModalUnmount();

    }



    //render method
    render() {

        var totalgainloss = null;
        var totalgainpct = null;

        var TasDetails = []
       // TasDetails.push(this.state.tasholdingsresponse);

        if (this.state.tasholdingsresponse && this.state.tasholdingsresponse.length > 0) {
            this.state.tasholdingsresponse.map((d, i) => TasDetails.push({
                AcquisitionDate: d.DTAQD? d.DTAQD.split('T')[0]:null,
                    SaleDate:d.DTSALE?d.DTSALE.split('T')[0]:null,
                    HoldingPeriod: d.LONGSHORT,
                    Quantity: d.NUMSHRS,
                    GainLossAmt: d.PROFIT,
                    tasGainLossPct: d.PROFITPCT,
                    tasProceeds: d.STKBNDAMT,
                    tasCostBasis: d.COSTBASIS,
                    tasWashSale: d.WASHSALE,
                    tasdisallowed: d.WASHSALELOSSDISALLOWEDAMT,
                    tasWashSaleST: d.WashSaleST,
                    tasWashSaleLT: d.WashSaleLT,
                    tasWashSalePeriod: d.DTWASHSALEPERIOD,
                    id: i
                }));
            //if (this.state.tasholdingsresponse[0].AcctSecTaxLotSummary) {
            //    totalgainloss = this.state.tasholdingsresponse[0].AcctSecTaxLotSummary.UnrealizedGainLossAmt;
            //    totalgainpct = this.state.tasholdingsresponse[0].AcctSecTaxLotSummary.UnrealizedGainLossPct;
            //}

        }
        return (

            <div key='modaltas4'>
                {

                    (() => {
                        if (this.state.requestFailed) {
                            return <p className="RCM_data_error" key='modaltas5'>{MsgForFailedData}</p>
                        }
                        else if (this.state.isloading) {
                            return <div className="RCM_spinner">Loading Details{spinner()}</div>
                        }
                        else {
                            if (this.state.tasholdingsresponse) {
                                return (<div>
                                    {/* <div className="RCM_right RCM_Padding_Bottom">
                                        <span onClick={() => printCustomWindow(TasDetails,this.props)} className="RCM_printexportWrapper RCM_icon_hand">PRINT</span>
                                        <span className="RCM_printexportWrapper RCM_icon_hand">&nbsp;/&nbsp;</span>
                                        <span onClick={() => generateExcel(TasDetails, this.props.totalgainloss, this.props.totalgainlosspct)} className="RCM_printexportWrapper RCM_icon_hand">EXPORT PAGE</span>
                                        <br/>
                                    </div>*/}
                                    <BootstrapTable key="btsecchild12"
                                    keyField='id'
                                    classes="RCM_two_level_table1"
                                    columns={taschildcolumns(this.props.totalgainloss, this.props.totalgainlosspct)}
                                    data={TasDetails}
                                    noDataIndication={MsgForNoData}
                                    bordered={false}
                                /></div> )
                            }
                            else {
                                return <p className="RCM_data_error" key='modaltas6'>{MsgForNoData}</p>
                            }
                        }
                    })()

                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        acct:state.acct,
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};
const tasholdingdetailscomp = compose(
    connect(mapStateToProps),
    withPageView({ pageName: 'Tas Holdings' })
)
export default tasholdingdetailscomp(TASHoldingdetails);

