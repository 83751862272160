import React, { Component } from 'react';
import Assetsummary from './assetservicecall.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { CheckIfProspectClient } from '../../../../common/constants.jsx';
import { connect } from "react-redux";
class Asset extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            isProspectClient: CheckIfProspectClient(this.props.role)
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        let asset = (
            <div className="RCM_Toast" id="Account_overview">
                <ErrorBoundary>
                    <Assetsummary />
                </ErrorBoundary>
            </div>);

        return (<ErrorBoundary>
            <>{}
                {this.state.isProspectClient ? <div className="col-sm-12 col-md-5 col-xs-12  RCM_summary_section col-lg-4" data-intro='Asset Allocation Information'
                    id="asset"
                >
                    {asset}
                </div>
                    : <div className={'col-sm-12 col-md-5 col-xs-12  RCM_summary_section ' + this.props.bsClass} data-intro='Asset Allocation Information'
                        draggable={this.props.draggable}
                        id="asset"
                        onDragStart={this.props.onDragStart} onDragOver={this.props.onDragOver} onDrop={this.props.onDrop("asset")}
                    >
                        {asset}
                    </div>}

            </>
        </ErrorBoundary >)

        //return (
        //    <div className={'col-sm-12 col-md-5 col-xs-12  RCM_summary_section '+this.props.bsClass} data-intro='Asset Allocation Information' draggable={this.props.draggable} id ="asset"
        //    onDragStart={this.props.onDragStart} onDragOver={this.props.onDragOver}  onDrop={this.props.onDrop("asset")}
        //    >
               
        //    </div>
        //)
    }
}
const mapStateToProps = state => {
    return {
        role: state.acct.role,
    };
};

export default connect(mapStateToProps)(Asset);
