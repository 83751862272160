import React from 'react';
import { MsgForNoData } from '../../common/constants.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CaseManager from './casemanager';

export function HelpAndSupportModal(props) {

    let helpandsupportmodal = (
        <div>
            {props && props.state ?
                <Modal isOpen={props.state.openHelpAndSupportModal} toggle={props.toggleDeleteModal}
                    className="RCM_CM_modal RCM_casemanage_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content RCM_casemanage_modalcontent"
                    zIndex='9999'
                    centered >
                    <ModalHeader toggle={props.toggleHelpAndSupportModal} className="RCM_externalAssetModel_header RCM_manage_group_header">
                        <label className="RCM_tile_heading">CASE MANAGER</label>
                    </ModalHeader>
                    <ModalBody className="RCM_casemanage_modalBody">
                        <CaseManager
                            source={props.source}
                            toggleHelpAndSupportModal={() => props.toggleHelpAndSupportModal()}
                            additionalInfo={props.additionalInfo}
                            />
                    </ModalBody>
                </Modal>

                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return helpandsupportmodal
}



