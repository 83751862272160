import React, { Component } from 'react';
import Holdingssummary from './toptenservicecalls.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { CheckIfProspectClient } from '../../../../common/constants.jsx';
import { connect } from "react-redux";
class TopTen extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            isProspectClient: CheckIfProspectClient(this.props.role)
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        let toptenHoldings = (
            <div className="RCM_Toast" id="topten_holdings">
                <ErrorBoundary>
                    <Holdingssummary />
                </ErrorBoundary>
            </div>);

        return (<ErrorBoundary>
            <>{}
                {this.state.isProspectClient ? <div className={'col-sm-12 col-md-7 col-xs-12  RCM_topten_container RCM_summary_section col-lg-4'} data-intro='Holdings top ten Information' id="topten"
                >
                    {toptenHoldings}
                </div>
                    : <div className={'col-sm-12 col-md-7 col-xs-12  RCM_topten_container RCM_summary_section ' + this.props.bsClass} data-intro='Holdings top ten Information'
                        draggable={this.props.draggable} id="topten"
                        onDragStart={this.props.onDragStart} onDragOver={this.props.onDragOver} onDrop={this.props.onDrop("topten")}
                    >
                        {toptenHoldings}
                    </div>}

            </>
        </ErrorBoundary >)
    }
}
const mapStateToProps = state => {
    return {
        role: state.acct.role,
    };
};
export default connect(mapStateToProps)(TopTen);
