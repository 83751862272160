import React, { Component } from 'react';
import { connect } from 'react-redux';
import img from './../images/user.svg';
import { NavLink, withRouter } from 'react-router-dom';
import { authProvider } from '../../App';
import ThemeToggle from '../common/themetoggle';
import { tabSelection, MyProfileGoogleURL, UserLogOut, GetPreferencePilotValue, ourInsightsUrl, ourInsightsLabel} from './../common/constants.jsx';
import { userinfo } from '../../App.js';
import AlertDetails from './../components/personalisationandsettings/alerts/showalerts';
class UserAccountSetting extends Component {

    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);
        this.removeSelectionUsersetting = this.removeSelectionUsersetting.bind(this);
        let obj = { preferences: this.props.preferenceRes }
        this.state = {
            isgoogleaccount: false,
            isFontScaleRequired: false,
            rockcoAcctToggleValue:(this.props.preferenceRes) ? (this.props.preferenceRes.showexternalaccount === 'true' ? true : false)  : false,
            tabsLinks:
                [{ tabUrl: '/ProfileComponent', active: false, displayName: 'Settings' }
                    //    ,
                    //{ tabUrl: '/Feedback', active: false, displayName: 'Feedback' }
                ],
            showOurIdeas: GetPreferencePilotValue(obj, "OurIdeas", "false") === "true" ? true : false,
        };

    }
    profileGoogleURL = '';
    removeSelectionUsersetting = (event) => {
        if (event !== null && event !== undefined) {
            let links = this.state.tabsLinks;
            for (const [, value] of links.entries()) {
                value.active = false;
            }
            this.setState({ tabsLinks: links });

        }
        else {
            console.log("Invalid removeSelectionUsersetting received");
        }

        
    };
    componentDidMount() {
        document.dispatchEvent(new CustomEvent('AccountTabDeselected', { bubbles: true }));
        window.addEventListener('removeSelectionUsersetting', this.removeSelectionUsersetting);

        const tabsLinks = this.state.tabsLinks;
        const currentRoute = this.props.location.pathname;
        for (const [, value] of this.state.tabsLinks.entries()) {
            if (value.tabUrl == '/ProfileComponent' && ((currentRoute == '/Profile') || (currentRoute == '/EDelivery') || (currentRoute == '/AccountsNickname') || (currentRoute == '/ManageGroups') || (currentRoute === "/ViewPreferences") || (currentRoute === "/SubscribeAlerts"))) {
                value.active = true;
            }
            else if (value.tabUrl == '/Feedback' && (currentRoute == '/Feedback')) {
                value.active = true;
            }
            else {
                value.active = false;
            }
        }
        //let isFontScaleRequired = GetPreferenceValue(this.props.acct, "FontScaleVisiblity", "false") === "true" ? true : false;

        //this.setState({ tabsLinks: tabsLinks, isFontScaleRequired: isFontScaleRequired });
        this.setState({ tabsLinks: tabsLinks});
        /*check the user login based on that change the user icon hover dropdown link*/

        //let userKey = sessionStorage.getItem('user');
        let userKey = userinfo;
        let user = [];
        if (userKey) {
            user = JSON.parse(userKey);
        }
        if (user) {
            var useremail = user.userName;
            if (useremail.toString().includes("gmail")) {
                this.setState({ isgoogleaccount: true });
                this.profileGoogleURL = MyProfileGoogleURL + useremail.toString() + '#/profile';
            }

        }
    }
    componentWillUnmount() {
        // de registering the event here
        window.removeEventListener('removeSelectionUsersetting', this.removeSelectionUsersetting);
    }

    toggleShowRockcoAcct = () => {
        this.setState(prevState => ({
            rockcoAcctToggleValue: !prevState.rockcoAcctToggleValue
        }))
    }

 

    handleSelections(param, e) {
        document.dispatchEvent(new CustomEvent('removeSelection', { bubbles: true }));
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
        let Activeele = document.getElementsByClassName('RCM_menu_active');
        //if (Activeele[0] && Activeele[0].classList) {
        //Activeele[0].classList.remove('RCM_menu_active');
        //}

        if (Activeele[0]) {
            Activeele[0].className = "RCM_menu_text ";
        }

    }


    logout = () => {
        UserLogOut(authProvider);
    };

    render() {
        return (
            <div>
                <div className="RCM_accsettingWrapper">
                    <div id="RCM_PopoverUserIcon">
                        <img
                            src={img}
                            className=""
                            alt="profile image"
                            height="49"
                            width="49"
                        />
                        {/* <mgt-person person-query="me"></mgt-person> */}
                    </div>
                    {/* <UncontrolledPopover delay={300} trigger="hover" placement="bottom" target="RCM_PopoverUserIcon" className="RCM_PopoverUserIconWrapper">
                        <PopoverBody>
                            <div>
                                <ul className="RCM_popovermenu">
                                    <li className="RCM_popovermenu_item"><a href={this.state.isgoogleaccount ? this.profileGoogleURL : MyProfileURL}>My Profile</a></li>
                                    <li className="RCM_popovermenu_item"><a href={this.state.isgoogleaccount ? TermsOfUseGoogleURL : TermsOfUseURL}>Terms of Use</a></li>
                                    <li className="RCM_popovermenu_item"><a href={this.state.isgoogleaccount ? MyAccountGoogleURL : MyAccountURL}>My Account</a></li>
                                </ul>
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover> */}
                    <div className="RCM_usersettingwrapper row">
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_userName_container">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12 col-md-9 col-lg-9"><label className="RCM_username">{this.props.data}</label></div>
                                <div className="col-sm-12 col-md-12 col-md-3 col-lg-3"><AlertDetails /></div> 
                            </div>

                        </div>
                        <div className="RCM_settingWrapperUpper">
                            <div className="RCM_settingsWrapper col-sm-12 col-xs-12 col-md-7 col-lg-8">
                                <div onClick={this.logout.bind(this)}>Logout</div>
                                {this.state.tabsLinks && this.state.tabsLinks.map((tabLink) => {
                                    return <div className="" key="dvTabLink">
                                        <NavLink exact to={tabLink.tabUrl} className={"" + (tabLink.active ? 'linkactive ' : ' ')}
                                            onClick={this.handleSelections.bind(this, tabLink)}
                                        >
                                            {tabLink.displayName}
                                        </NavLink>
                                    </div>
                                })}

                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-5 col-lg-4 RCM_toggleWrapper">
                                <div id="themeToggle"> <ThemeToggle inActive={"Dark"} active={"Light"} toggle={true} preferences={this.props.preferences} /></div>
                            </div>
                        </div>
                        <div key='ourideas' className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                            {this.state.showOurIdeas
                                ? <a href={ourInsightsUrl} target="_blank" rel="noreferrer" className="RCM_link RCM_our_ideas">{ourInsightsLabel}</a>
                                : ''
                            }
                               
                        </div>
                    </div>
                </div>
                {/*<div>
                    <label className="RCM_username">{this.props.FAdata}</label>
                </div>*/}               
            </div>
             
        );
    }
}

const mapStateToProps = (store) => {
    return {
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        preferenceRes: store.acct.preferences,
        profileId: store.acct.profileId,
        role: store.acct.role,
        acct: store.acct
    };
}
const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)( withRouter(UserAccountSetting));
