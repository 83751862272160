import React from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PreferenceView } from './preferenceView.jsx'
import axios from 'axios';
import { Post, Get } from '../../../common/servicecalls';
import ErrorBoundary from '../../../common/errorboundary';
import { spinner, MessageType, MessageClass, PreferencesUrl, GetPreferenceValue, GetPreferencePilotValue, CheckIfProspectClient} from '../../../common/constants.jsx';
import { fetchPersonalisationData, setDashboardPreference } from "../../../actions/personalisationAction";
import { updatePreference } from "../../../actions/accountsactions"
import { withPageView } from '../../../hoc/withPageView.jsx';
import { savePreferenceService, getHomePagePreference } from './../../accounts/customizationviewservices';
//import { showHistoricalAssetAllocation } from './../../accounts/detail/historicalassetallocation/HistoricalAssetAllocationService';

export class ViewPreference extends React.Component {
    constructor(props) {
        super(props);
        let expreimentArray = [1, 2, 0, 3];
        let obj = { preferences:this.props.preferences}
        this.state = {
            preferecevalue: GetPreferenceValue(obj, "ViewPreference", "Normal"),
            isPreferenceUpdated: false,
            preferenceMessages: [],
            isViewPreference: GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false,
            isPerformance: GetPreferencePilotValue(obj, "Performance", "false") === "true" ? true : false,
            rockcoAcctToggleValue: this.props.preferences !== null ? (this.props.preferences !== undefined && this.props.preferences.showexternalaccount !== undefined ? (this.props.preferences.showexternalaccount === 'true' ? true : false) : false) : false,
            assetallocation: expreimentArray.includes(0) ? true : false,
            asset: expreimentArray.includes(1) ? true : false,
            topten: expreimentArray.includes(2) ? true : false,
            activity: expreimentArray.includes(3) ? true : false,
            historicalasset: expreimentArray.includes(4) ? true : false,
            performance: expreimentArray.includes(5) ? true : false,
            positionOfComponent: [],
            iscustomizationPreferenceUpdated:false,
           // isPilotForHistoricalAssetAllocation: showHistoricalAssetAllocation(this.props && this.props.preferences && this.props.preferences.pilotfeatures ? this.props.preferences.pilotfeatures:[]),
            toggleCustomizationIcon: GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false,
            isProspectClient: CheckIfProspectClient(this.props.role)
        };
        this.handlePreferenceChanges = this.handlePreferenceChanges.bind(this);
        this.updatePreferenceCallBack = this.updatePreferenceCallBack.bind(this);
        this.showViewPreference = this.showViewPreference.bind(this);
        this.toggleShowRockcoAcct = this.toggleShowRockcoAcct.bind(this);
        this.removePreferenceMesage = this.removePreferenceMesage.bind(this);
        this.validateTopSection = this.validateTopSection.bind(this);
    }
    preferencecanellationToken = null;
    cancellationToken = null;

    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal");
        return val;
    }

    validateTopSection() {
        let selectedComponents = 0;
        if(this.state.asset) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.assetallocation) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.topten) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.historicalasset) {
            selectedComponents = selectedComponents + 1;
        }
        if (this.state.performance) {
            selectedComponents = selectedComponents + 1;
        }
        if (selectedComponents>3) {
            return false;
        }
        else {
            return true;
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let messages = [];
        this.setState({
            [name]: value, preferenceMessages: messages, iscustomizationPreferenceUpdated: false, isPreferenceUpdated: false
        }, () => {
            if (this.validateTopSection()) {
                
                if (value || (!value && (this.state.asset || this.state.assetallocation || this.state.topten || this.state.historicalasset || this.state.activity || this.state.performance))) {
                    this.updateScreenpreference();
                } else {
                    messages.push({
                        type: MessageType.INFO,
                        text: "Atleast 1 component should be selected for the dashboard",
                        class: MessageClass.ERROR
                    });
                    this.setState({
                        preferenceMessages: messages, [name]: !value
                    });
                }
            }
            else {
                messages.push({
                    type: MessageType.INFO,
                    text: "Maximum 3 component's can be selected for the top section",
                    class: MessageClass.ERROR
                });
                this.setState({
                    preferenceMessages: messages, iscustomizationPreferenceUpdated: true, [name]: !value, isPreferenceUpdated:false
                });
            }
        });
    }


    updateScreenpreference = () => {

        let pageOrder = [];
        let dashboardPreference = [];
        if (this.state.assetallocation) {
            pageOrder.push(
                {
                    "tilename": "AssetAllocation",
                    "id": 0
                }

            );
            dashboardPreference.push("assetallocation");
        } if (this.state.asset) {
            pageOrder.push(
                {
                    "tilename": "Asset",
                    "id": 1
                }
            )
            dashboardPreference.push("asset");
        } if (this.state.topten) {
            pageOrder.push(
                {
                    "tilename": "Top10",
                    "id": 2
                }
            )
            dashboardPreference.push("topten");
        } if (this.state.historicalasset) {
            pageOrder.push(
                {
                    "tilename": "HistoricalAsset",
                    "id": 4
                }
            )
            dashboardPreference.push("historicalasset");
        } if (this.state.performance) {
            pageOrder.push(
                {
                    "tilename": "Performance",
                    "id": 5
                }
            )
            dashboardPreference.push("performance");
        }if (this.state.activity) {
                pageOrder.push(
                    {
                        "tilename": "Activity",
                        "id": 3
                    }
                )
            dashboardPreference.push("activity");
        }  
       // })
        let requestBody = {
            "profileid": this.props.profileId,
            "pageorder": [{
                "pagename": "/Accounts",
                "pagesequence": pageOrder
            }]
        }
        let preferenceMessages = [];
        if (this.state.isProspectClient) {
            this.props.setDashboardPreference(dashboardPreference);
        }
        else {
            savePreferenceService(requestBody, this.props.token,
                this.props.contextjson)
                .then(res => {
                    if (res.status === 200) {
                        console.log("updated")
                        preferenceMessages.push({
                            type: MessageType.INFO,
                            text: 'Preference is updated',
                            class: MessageClass.SUCCESS
                        });
                        this.setState({
                            preferenceMessages: preferenceMessages, iscustomizationPreferenceUpdated: true, isPreferenceUpdated: false
                        }, () => { this.props.setDashboardPreference(dashboardPreference) });
                    }

                })
                .catch(error => {
                    console.log(error)
                    preferenceMessages.push({
                        type: MessageType.INFO,
                        text: "Components to view is not saved",
                        class: MessageClass.ERROR
                    });
                    this.setState({
                        preferenceMessages: preferenceMessages, iscustomizationPreferenceUpdated: true, isPreferenceUpdated: false
                    });
                })
        }
        
       

    }

    handlePreferenceChanges(evt) {
        this.setState({ preferecevalue: evt.target.value }, () => { this.updatePreferenceCallBack() });
    }

    removePreferenceMesage = (event) => {
        if (event !== null && event !== undefined) {
            if (this.props && this.props.preferences && this.props.preferences.preferenceMessage && this.props.preferences.preferenceMessage !== '') {
                let reqObj1 = JSON.parse(JSON.stringify(this.props.preferences));//specifically for updating store
                reqObj1.preferenceMessage = '';
                this.props.updatePreference(reqObj1);

            }
        }
        else {
            console.log("Invalid removePreferenceMesage received");
        }
    }

    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.preferencecanellationToken = axios.CancelToken.source();
        let componentjson = [];
        componentjson.push({ "key": "ViewPreference", "value": this.state.preferecevalue })
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            let viewpreferencevalue = reqObj1.component.filter(component => component.key === "ViewPreference");
            if (viewpreferencevalue && viewpreferencevalue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "ViewPreference")].value = this.state.preferecevalue;
            } else {
                reqObj1.component.push({ "key": "ViewPreference", "value": this.state.preferecevalue });
            }

        }
        reqObj1.preferenceMessage = 'Preference is updated';
        let preferenceMessages = [];


        if (this.state.isProspectClient) {
            this.props.updatePreference(reqObj1);
        }
        else {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.preferencecanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        this.props.updatePreference(reqObj1);
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                    preferenceMessages.push({
                        type: MessageType.INFO,
                        text: 'View preference is not saved',
                        class: MessageClass.ERROR
                    });
                    this.setState({
                        preferenceMessages: preferenceMessages, isPreferenceUpdated: true, iscustomizationPreferenceUpdated:false
                    });
                });

        }
    }


    componentDidMount() {
        let preferenceMessages = []
        if (this.props && this.props.preferences && this.props.preferences.preferenceMessage && this.props.preferences.preferenceMessage !== '') {
            preferenceMessages.push({
                type: MessageType.INFO,
                text: this.props.preferences.preferenceMessage,
                class: MessageClass.SUCCESS
            })
        }
        this.setState({ preferecevalue: this.getPreferenceValue(), isViewPreference: this.showViewPreference(), isPreferenceUpdated: (preferenceMessages.length > 0 ? true : false), preferenceMessages: preferenceMessages, iscustomizationPreferenceUpdated: false});
        window.addEventListener('removePreferenceMesage', this.removePreferenceMesage);//this code is specifically for toggling view dropdown or tab as store gets updated state is not reflected
        if (this.props.token !== undefined) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.execute();
            }
           
        }
           
    }
    SetDummyResponseForProspectClient() {
        let positionOfComponent = [0, 1, 4, 3];
        this.setPositionOfComponents(positionOfComponent)
    }
    setPositionOfComponents(positionOfComponent) {
            this.setState({
                assetallocation: positionOfComponent.includes(0) ? true : false,
                asset: positionOfComponent.includes(1) ? true : false,
                topten: positionOfComponent.includes(2) ? true : false,
                activity: positionOfComponent.includes(3) ? true : false,
                historicalasset: positionOfComponent.includes(4) ? true : false,
                performance: positionOfComponent.includes(5) ? true : false,
            }, () => { this.updateScreenpreference()});

    }
    execute = () => {
       
        //const isPilotForHistoricalAssetAllocation = showHistoricalAssetAllocation(
        //    this.props && this.props.preferences && this.props.preferences.pilotfeatures ? this.props.preferences.pilotfeatures : []
        //);
        
        getHomePagePreference(this.props.token, this.props.contextjson, this.props.profilejson)
            .then(result => {
                console.log(result.data.value);
                let obj = result.data.value;
                let pageOrder = obj.pageorder
                let positionOfComponent = [];
                if (pageOrder !== null) {
                    for (let i = 0; i < pageOrder.length; i++) {
                        if (pageOrder[i].pagename === "/Accounts" || pageOrder[i].pagename === "/") {
                            let pagesequence = pageOrder[i].pagesequence
                            for (let j = 0; j < pagesequence.length; j++) {
                                let id = pagesequence[j].id
                                positionOfComponent.push(id)
                            }
                        }
                    }
                } else {
                    positionOfComponent = [0, 1, 4, 3]
                }

                this.setState({
                    assetallocation: positionOfComponent.includes(0) ? true : false,
                    asset: positionOfComponent.includes(1) ? true : false,
                    topten: positionOfComponent.includes(2) ? true : false,
                    activity: positionOfComponent.includes(3) ? true : false,
                    historicalasset: positionOfComponent.includes(4) ? true : false,
                    performance: positionOfComponent.includes(5) ? true : false,
                });
            })
            .catch(error => {
                console.log(error)
            })

    }

    showViewPreference() {
        let obj = {
            preferences: this.props.preferences
        }

        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        if (isViewPreferenceRequired)
            return true;
        else
            return false;
    }
    toggleShowRockcoAcct() {
        this.setState(prevState => ({
            rockcoAcctToggleValue: !prevState.rockcoAcctToggleValue
        }), () => { this.updateShowExtenalAccountCallBack() })
    }

    updateShowExtenalAccountCallBack = () => {
        const profileUrl = PreferencesUrl;
        let preferenceMessages = [];
        let theme = sessionStorage.getItem('theme')
        this.cancellationToken = axios.CancelToken.source();
        let reqObj = {};
        reqObj.showexternalaccount = this.state.rockcoAcctToggleValue ? "true" : "false";
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: reqObj
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.preferences));//specifically for updating store
        reqObj1.showexternalaccount = this.state.rockcoAcctToggleValue ? "true" : "false";
        reqObj1.preferenceMessage = 'Preference is updated';
        if (this.state.isProspectClient) {
            this.props.updatePreference(reqObj1);//specifically for updating store};
        }
        else {
            Post(profileUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.cancellationToken)
                .then(res => {
                    if (res.status === 200) {
                        this.props.updatePreference(reqObj1);//specifically for updating store};
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    preferenceMessages.push({
                        type: MessageType.INFO,
                        text: ' Show rockco account is not updated',
                        class: MessageClass.ERROR
                    })
                    console.log(error);
                });
            this.setState({
                isPreferenceUpdated: true, preferenceMessages: preferenceMessages, iscustomizationPreferenceUpdated: false
            });
        }
       
       

    }
    componentWillUnmount() {
        if (this.cancellationToken !== null && this.cancellationToken !== undefined) {
            this.cancellationToken.cancel('Cancelling the update preference during unmount to prevent memory leak.');
        }
        if (this.preferencecanellationToken !== null && this.preferencecanellationToken !== undefined) {
            this.preferencecanellationToken.cancel('Cancelling the Security Holdings Account Change during unmount to prevent memory leak.');
        }

        window.removeEventListener('removePreferenceMesage', this.removePreferenceMesage);
    }
    
    render() {

        return (
            <div className="container-fluid">
                <ErrorBoundary>
                    <PreferenceView
                        state={this.state}
                        handlePreferenceChanges={this.handlePreferenceChanges}
                        savePreference={this.updatePreferenceCallBack}
                        toggleShowRockcoAcct={this.toggleShowRockcoAcct}
                        handleInputChange={this.handleInputChange}
                    />
                </ErrorBoundary>

            </div>
        );
    }

}
const mapStateToProps = (store) => {
    return {
        accounts: store.personalisationData.personalisationData === undefined ? [] : store.personalisationData.personalisationData.accounts,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        preferences: store.acct.preferences,
        name: store.acct.name,
        profileId: store.acct.profileId,
        profileReq: store.personalisationData.personalisationData === undefined ? "" : store.personalisationData.personalisationData,
        token: store.acct.token,
        preferenceMessage: store.personalisationData && store.personalisationData.preferenceMessage ? store.personalisationData.preferenceMessage:null,
        dashboardPreference: store.personalisationData && store.personalisationData.dashboardPreference ? store.personalisationData.dashboardPreference : [],
        role:store.acct.role
    };
}
const mapDispatchToProps = {
    fetchPersonalisationData,
    updatePreference,
    setDashboardPreference
}
const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Preferences' })
)

export default enhancement(ViewPreference);