import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, InputGroupAddon } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, interestRateFormat, basicAlphaNumeric, discriptionFormat, alphanumericFormat, viewOnlyText } from './../../../../../common/constants.jsx';

class OtherLoan extends Component {

    constructor(props) {
        super(props);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.originatorchange = this.originatorchange.bind(this);
        this.originatortoggle = this.originatortoggle.bind(this);
        this.interesttypetoggle = this.interesttypetoggle.bind(this);
        this.paymentfrequencytoggle = this.paymentfrequencytoggle.bind(this);
        this.handleCollateralValueAsofChange = this.handleCollateralValueAsofChange.bind(this);
        this.interesttypechange = this.interesttypechange.bind(this);
        this.paymentfrequencychange = this.paymentfrequencychange.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            activeTab: '1',
            displayoutstandingbalance: '',
            displayvalue: '',
            formStateChange: false,
            intialAccount: {},
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: 'Loan',
                    bankname: "",
                    description: ''
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date()
                },
                loan: {
                    collateral: {
                        type: "",
                    },
                    loaninfo: {
                        outstandingbalance: "",
                        interestrate: "",
                        interesttype: "",
                        paymentfrequency: ""
                    }
                }

            },
            originatoroptions: ["Bank Of America", "JP Morgan"],
            interesttypeoptions: ["Interest Only", "Fixed", "Variable"],
            paymentfrequencyoptions: ["Daily", "Monthly", "Quarterly", "Yearly"],
            isoriginatoropen: false,
            isinteresttypeopen: false,
            ispaymentfrequencyopen: false,

        };
    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displayvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displayoutstandingbalance = "";
            if (this.props.account.loan.loaninfo.outstandingbalance !== undefined && this.props.account.loan.loaninfo.outstandingbalance !== null && this.props.account.loan.loaninfo.outstandingbalance !== "")
                displayoutstandingbalance = parseFloat(this.props.account.loan.loaninfo.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({ account: this.props.account, intialAccount: initailAccount, displayvalue: displayvalue, displayoutstandingbalance: displayoutstandingbalance });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }

    interesttypechange(interesttypeval) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.loan.loaninfo.interesttype = interesttypeval;
        this.setState(clonedState);
        this.checkStateChange();
    }
    paymentfrequencychange(paymentfrequencyval) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.loan.loaninfo.paymentfrequency = paymentfrequencyval;
        this.setState(clonedState);
        this.checkStateChange();
    }
    originatorchange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.loan.originator = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }
    originatortoggle() {
        this.setState(prevState => ({
            isoriginatoropen: !prevState.isoriginatoropen
        }))
        this.checkStateChange();
    }

    interesttypetoggle() {
        this.setState(prevState => ({
            isinteresttypeopen: !prevState.isinteresttypeopen
        }))
        this.checkStateChange();
    }

    paymentfrequencytoggle() {
        this.setState(prevState => ({
            ispaymentfrequencyopen: !prevState.ispaymentfrequencyopen
        }))
        this.checkStateChange();
    }

    handleCollateralValueAsofChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleFormChanges(evt) {

        let clonedState = Object.assign({}, this.state);

        if (evt.target.name === "accountnumber") {
            if (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value) && evt.target.value.length <= 20)
                clonedState.account.info.accountnumber = evt.target.value;
        }
        else if (evt.target.name === "bankname") {
            if (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.info.bankname = evt.target.value;
            clonedState.account.info.custodianname = evt.target.value;
        }
        else if (evt.target.name === "outstandingbalance") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayoutstandingbalance = evt.target.value;
        }
        else if (evt.target.name === "interestrate") {
            if (evt.target.value === "" || interestRateFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.loan.loaninfo.interestrate = evt.target.value;
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.account.info.description = evt.target.value;
            this.setState(clonedState);
        }
        else if (evt.target.name === "collateralvalue") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayvalue = evt.target.value;
        } else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        } else if (evt.target.name === "collateraltype" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericFormat.test(evt.target.value))) {
            clonedState.account.loan.collateral.type = evt.target.value;
        }
        this.setState(clonedState);
        this.checkStateChange();
    }

    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "collateralvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displayvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "outstandingbalance") {
                clonedState.account.loan.loaninfo.outstandingbalance = evt.target.value;
                clonedState.displayoutstandingbalance = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "collateralvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
            }
            else if (evt.target.name === "outstandingbalance") {
                clonedState.account.loan.loaninfo.outstandingbalance = evt.target.value;
            }

            this.setState(clonedState);
        }
    }

    handleAmmountOnFocus(event) {
        const clonedState = Object.assign({}, this.state);
        if (event.target.name === "outstandingbalance") {
            if (clonedState.account.loan.loaninfo.outstandingbalance === "") {
                clonedState.displayoutstandingbalance = "";
                clonedState.account.loan.loaninfo.outstandingbalance = "";
            } else {
                clonedState.displayoutstandingbalance = clonedState.account.loan.loaninfo.outstandingbalance;
            }
        } else if (event.target.name === "collateralvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displayvalue = "";
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displayvalue = clonedState.account.value.totalvalue;
            }
        }

        this.setState(clonedState);
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    // this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }
    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_loan_form">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                        >
                            STEP 1 : BASIC INFORMATION
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('2'); }} id="RCM_collateral_info"
                        >
                            STEP 2 : COLLATERAL
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' }, { linkactive: this.state.activeTab === '3' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('3'); }} id="RCM_loan_info"
                        >
                            STEP 3 : LOAN INFO
                     </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                    <TabPane tabId="1">


                        <div>
                            <Form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_loannumber">
                                                <Label for="ledgerbalance">Loan No</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="accountnumber"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.accountnumber}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_nickname">
                                                <Label for="nickname">Nickname</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="nickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.nickname}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">

                                            <FormGroup controlId="formGridTotal Value" id="RCM_bankname">
                                                <Label for="bankname">Bank Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="bankname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.bankname}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>

                                        </div>

                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="description"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.info.description}
                                                onChange={this.handleFormChanges}
                                                required
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                        >
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </TabPane>
                    <TabPane tabId="2">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Collateral Type</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="collateraltype"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.account.loan.collateral.type}
                                                    onChange={this.handleFormChanges}
                                                />

                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>Total Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="collateralvalue"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displayvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <div>  <Label for="valueasof" >Value as of (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="collateralvalueasof" id="collateralvalueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    onChange={this.handleCollateralValueAsofChange}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>

                                        </div>


                                    </div>
                                </fieldset>
                                <div className="row">

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>Outstanding Balance</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="outstandingbalance"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displayoutstandingbalance}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>Interest Rate</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="%"
                                                    name="interestrate"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.account.loan.loaninfo.interestrate}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Interest Type</Label>
                                                <Dropdown name="interesttypedropdown" required
                                                    isOpen={this.state.isinteresttypeopen}
                                                    toggle={this.interesttypetoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.loan.loaninfo.interesttype}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.interesttypeoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.interesttypechange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Payment Frequency</Label>
                                                <Dropdown name="paymentfrequencydropdown" required
                                                    isOpen={this.state.ispaymentfrequencyopen}
                                                    toggle={this.paymentfrequencytoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.loan.loaninfo.paymentfrequency}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.paymentfrequencyoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.paymentfrequencychange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>PREVIOUS</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                        ?
                        < div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                {viewOnlyText}
                            </div>
                        </div>
                        : ""
                    }
                </TabContent>

            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,

    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(OtherLoan);