import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Form, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, basicAlphaNumeric, discriptionFormat, interestRateFormat, viewOnlyText } from '../../../../../common/constants.jsx';
import classnames from 'classnames';
class PromissoryNote extends Component {

    constructor(props) {
        super(props);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);


        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            displaytotalvalue: '',
            displayoutstandingbalance: '',
            activeTab: '1',
            intialAccount: {},
            formStateChange: false,
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: "",
                    accountnumber: "",
                    nickname: "",
                    type: "Promissory Note Loan",
                    description: "",
                    name: null,
                    originator: null,
                    bankname: null,
                    issuername: null,
                    status: null,
                    volatility: "",
                    risk: "",
                    subassettype: ""
                },
                value: {
                    totalvalue: "",
                    valueasof: new Date(),
                    outstandingbalance: "",
                    sourceofvaluation: "",
                    pricecontact: "",
                    pricingcurrency: "",
                    pricingfrequency: "",
                    initialprice: "",
                    initialpricedate: new Date(),
                    sourceofinitialvaluation: "",
                    otherpricingfrequency: ''
                },
                promissorynoteloan: {
                    couponrate: '',
                    maturitydate: new Date(),
                    firstcoupondate: new Date(),
                    lastcoupondate: new Date(),
                    nextcoupondate: new Date()
                }
            }

        };

    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displayvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displayoutstandingbalance = "";
            if (this.props.account.value.outstandingbalance !== undefined && this.props.account.value.outstandingbalance !== null && this.props.account.value.outstandingbalance !== "")
                displayoutstandingbalance = parseFloat(this.props.account.value.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({
                account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displayvalue, displayoutstandingbalance: displayoutstandingbalance
            });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    componentDidUpdate(prevProps) {

        if (prevProps.source !== this.props.source) {
            if (this.props.mode === 'edit' && this.props.account) {
                var displayvalue = "";
                if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                    displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var displayoutstandingbalance = "";
                if (this.props.account.value.outstandingbalance !== undefined && this.props.account.value.outstandingbalance !== null && this.props.account.value.outstandingbalance !== "")
                    displayoutstandingbalance = parseFloat(this.props.account.value.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var initailAccount = JSON.parse(JSON.stringify(this.props.account));
                this.setState({
                    account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displayvalue, displayoutstandingbalance: displayoutstandingbalance
                });
            } else if (this.props.mode === 'add') {
                var initailAccount = JSON.parse(JSON.stringify(this.state.account));
                this.setState({ intialAccount: initailAccount });
            }
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }

    handleFormChanges(evt) {
        let clonedState = Object.assign({}, this.state);
        if (evt.target.name === "custodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.custodianname = evt.target.value;
        }
        else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        }
        else if (evt.target.name === "accountnumber" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            clonedState.account.info.accountnumber = evt.target.value;
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaytotalvalue = evt.target.value;
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;
        } else if (evt.target.name === "pricecontact" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.pricecontact = evt.target.value;
        } else if (evt.target.name === "couponrate") {
            if (evt.target.value === "" || interestRateFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.promissorynoteloan.couponrate = evt.target.value;
        } else if (evt.target.name === "outstandingbalance") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayoutstandingbalance = evt.target.value;
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    handleAmountFormatting(evt) {
        let clonedState = Object.assign({}, this.state);
        if (evt.target.name === "totalvalue") {
            if (evt.target.value !== "") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                clonedState.account.value.totalvalue = evt.target.value;
            }
        } else if (evt.target.name === "outstandingbalance") {
            if (evt.target.value !== "") {
                clonedState.account.value.outstandingbalance = evt.target.value;
                clonedState.displayoutstandingbalance = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                clonedState.account.value.outstandingbalance = evt.target.value;
            }
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }


    handleAmmountOnFocus(evt) {
        const clonedState = Object.assign({}, this.state);
        if (evt.target.name === "totalvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displaytotalvalue = "";
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
            }
        } else if (evt.target.name === "outstandingbalance") {
            if (clonedState.displayoutstandingbalance === "") {
                clonedState.account.value.outstandingbalance = "";
                clonedState.displayoutstandingbalance = "";
            } else {
                clonedState.displayoutstandingbalance = clonedState.account.value.outstandingbalance;
            }
        }
        this.setState(clonedState);
    }
    handleDateChange = (value, source) => {
        let clonedState = Object.assign({}, this.state);
        if (source === 'valueasof') {
            clonedState.account.value.valueasof = value;
        } else if (source === 'maturitydate') {
            clonedState.account.promissorynoteloan.maturitydate = value;
        } else if (source === 'firstcoupondate') {
            clonedState.account.promissorynoteloan.firstcoupondate = value;
        } else if (source === 'lastcoupondate') {
            clonedState.account.promissorynoteloan.lastcoupondate = value;
        } else if (source === 'nextcoupondate') {
            clonedState.account.promissorynoteloan.nextcoupondate = value;
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }

    render() {
        return (
            this.props.mode && this.props.mode !== "view" ?

                <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_promissoryloannote_form">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                                onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                            >
                                BASIC INFORMATION
                         </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                                onClick={() => { this.toggle('2'); }} id="RCM_note_info"
                            >
                                NOTE INFORMATION
                            </NavLink>
                        </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                        <TabPane tabId="1">
                            <div>

                                <Form>
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_custodianname">
                                                    <Label for="custodianname">Custodian Name</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="custodianname"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.custodianname}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridAccount" id="RCM_accountNumber">
                                                    <Label for="accountnumber">Account Number</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="accountnumber"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.accountnumber}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                                    <Label for="nickname">Nickname</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="nickname"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.nickname}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                                    <Label for="totalvalue">Total Value</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        name="totalvalue"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.displaytotalvalue}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                    <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="valueasof" id="valueasof"
                                                        value={new Date(this.state.account.value.valueasof)}
                                                        onChange={(value) => this.handleDateChange(value, 'valueasof')}
                                                        selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                            && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_sourceofvaluation">
                                                    <Label for="custodianname">Pricing Source</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="pricecontact"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.pricecontact}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridCity" id="RCM_outstandingbalance_noteLoan">
                                                    <Label>Outstanding Balance</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        name="outstandingbalance"
                                                        className="RCM_form_control"
                                                        maxLength="12"
                                                        required
                                                        value={this.state.displayoutstandingbalance}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                                <div><Label for="desc" >Description</Label></div>
                                                <textarea
                                                    name="description"
                                                    placeholder=""
                                                    className="RCM_form_control RCM_Feedback_TextArea"
                                                    value={this.state.account.info.description}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="row">

                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                            <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                            <button type="button"
                                                className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                onClick={this.saveAsset}
                                            >
                                                SUBMIT
                                            </button>
                                            <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </TabPane>
                        <TabPane tabId="2">
                            <div>

                                <Form>
                                    <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridNickname">
                                                    <Label for="couponrate">Coupon Rate</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="%"
                                                        name="couponrate"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.promissorynoteloan.couponrate}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="valueasof">Maturity Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="maturitydate" id="maturitydate"
                                                        value={new Date(this.state.account.promissorynoteloan.maturitydate)}
                                                        onChange={(value) => this.handleDateChange(value, 'maturitydate')}
                                                        selected={(this.state.account.promissorynoteloan.maturitydate !== undefined && this.state.account.promissorynoteloan.maturitydate !== null
                                                            && this.state.account.promissorynoteloan.maturitydate !== '') ? new Date(this.state.account.promissorynoteloan.maturitydate) : ''}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="firstcoupondate">First Coupon Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="firstcoupondate" id="firstcoupondate"
                                                        value={new Date(this.state.account.promissorynoteloan.firstcoupondate)}
                                                        onChange={(value) => this.handleDateChange(value, 'firstcoupondate')}
                                                        selected={(this.state.account.promissorynoteloan.firstcoupondate !== undefined && this.state.account.promissorynoteloan.firstcoupondate !== null
                                                            && this.state.account.promissorynoteloan.firstcoupondate !== '') ? new Date(this.state.account.promissorynoteloan.firstcoupondate) : ''}
                                                        //maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="lastcoupondate">Last Coupon Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="lastcoupondate" id="lastcoupondate"
                                                        value={new Date(this.state.account.promissorynoteloan.lastcoupondate)}
                                                        onChange={(value) => this.handleDateChange(value, 'lastcoupondate')}
                                                        selected={(this.state.account.promissorynoteloan.lastcoupondate !== undefined && this.state.account.promissorynoteloan.lastcoupondate !== null
                                                            && this.state.account.promissorynoteloan.lastcoupondate !== '') ? new Date(this.state.account.promissorynoteloan.lastcoupondate) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="nextcoupondate">Next Coupon Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="nextcoupondate" id="nextcoupondate"
                                                        value={new Date(this.state.account.promissorynoteloan.nextcoupondate)}
                                                        onChange={(value) => this.handleDateChange(value, 'nextcoupondate')}
                                                        selected={(this.state.account.promissorynoteloan.nextcoupondate !== undefined && this.state.account.promissorynoteloan.nextcoupondate !== null
                                                            && this.state.account.promissorynoteloan.nextcoupondate !== '') ? new Date(this.state.account.promissorynoteloan.nextcoupondate) : ''}
                                                        //maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                        </div>
                                    </fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                            <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                            <button type="button"
                                                className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                onClick={this.saveAsset}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                SUBMIT
                                            </button>
                                            <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </TabPane>
                        {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                            ?
                            < div className="row">
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                    {viewOnlyText}
                                </div>
                            </div>
                            : ""
                        }
                    </TabContent>
                </div>
                :
                <>
                </>
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(PromissoryNote);