import React, { Component } from 'react'
import Healthview from './healthview';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { learnMorePCUrl, alreadyMemberPCUrl, contactPCUrl, CheckIfProspectClient, summusUrl, GetPreferencePilotValue } from '../../../common/constants';
import OverlayProcessing from '../../MoneyMovement/common/overlayProcessing';
import { _logInformation } from '../../../common/_logging';

class Health extends Component {

    constructor(props) {
        super(props);

        this.state = {
            healthModalState: false,
            selectedSectionurl: '',
            showOverlay: false,
            isProspectClient: CheckIfProspectClient(this.props.role),
            confirmationModalState: false,
            selectedSection: {
                url: '',
                headerData: '',
                contentData: ''
            }
        }
        this.healthModalToggle = this.healthModalToggle.bind(this);
        this.confirmationModalToggle = this.confirmationModalToggle.bind(this);
        this.openSummus = this.openSummus.bind(this);
        this.openSelectedSectionWindowNew = this.openSelectedSectionWindowNew.bind(this);
        this.isNewScreensRequired = this.isNewScreensRequired.bind(this);
    }
    confirmationModalToggle() {
        this.setState(prevState => ({ confirmationModalState: !prevState.confirmationModalState }));
    }
    isNewScreensRequired() {
        let obj = {
            preferences: this.props.preferences
        }
        let isNewScreensRequiredFlag = GetPreferencePilotValue(obj, "lifeStyleNewScreens", "false") === "true" ? true : false;
        return isNewScreensRequiredFlag;
    }
    openSummus() {
        _logInformation('Virtual Specilty Care Clicked');
        
        const selectedSection = {
            url: summusUrl,
            headerData: 'Virtual Specialist Platform provided by Summus',
            contentData: 'By clicking on the Continue button below, you will be transferred to a third party website which is owned, operated and maintained by Summus. If you have any questions, please contact your Financial Advisor.'
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    healthModalToggle() {
        this.setState(prevState => ({ healthModalState: !prevState.healthModalState }));
    }

    openPinnacleCareLearnMore() {
        _logInformation('Pinnacle care-Learn More Clicked');
        this.setState({ selectedSectionurl: learnMorePCUrl }, this.healthModalToggle);
    }


    alreadyMemberClick = () => {
        _logInformation('Pinnacle care-Already Member Clicked');
        this.setState({ selectedSectionurl: alreadyMemberPCUrl }, this.healthModalToggle);
    }

    contactPinnacleCare = () => {
        _logInformation('Contact pinnacle care Clicked');
        this.setState({ selectedSectionurl: contactPCUrl }, this.healthModalToggle);
    }

    openSelectedSectionWindow = () => {
        _logInformation(this.state.selectedSectionurl);
        window.open(this.state.selectedSectionurl);
        this.setState({ selectedSectionurl: '' }, this.healthModalToggle);
    }
    openSelectedSectionWindowNew = () => {
        _logInformation(this.state.selectedSection.url);
        window.open(this.state.selectedSection.url);
        const selectedSection = {
            url: '',
            headerData: '',
            contentData: ''
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    toggleShowNextGenHealth = () => {
        this.setState(prevState => ({ showNextGenHealth: !prevState.showNextGenHealth }));
    }
    render() {
        return (
            <>
                <Healthview
                    openPinnacleCareLearnMore={() => this.openPinnacleCareLearnMore()}
                    alreadyMemberClick={() => this.alreadyMemberClick()}
                    contactPinnacleCare={() => this.contactPinnacleCare()}
                    openSelectedSectionWindow={() => { this.openSelectedSectionWindow() }}
                    healthModalToggle={this.healthModalToggle}
                    healthModalState={this.state.healthModalState}
                    isProspectClient={this.state.isProspectClient}
                    showNextGenHealth={this.state.showNextGenHealth}
                    toggleShowNextGenHealth={() => this.toggleShowNextGenHealth()}
                    confirmationModalState={this.state.confirmationModalState}
                    confirmationModalToggle={this.confirmationModalToggle}
                    openSummus={this.openSummus}
                    openSelectedSectionWindowNew={this.openSelectedSectionWindowNew}
                    selectedSection={this.state.selectedSection}
                    isNewScreensRequired={this.isNewScreensRequired}
                />
                {this.state.showOverlay && (<OverlayProcessing />)}
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        token: store.acct.token,
        contextJson: store.acct.contextjson,
        profileJson: store.acct.profilejson,
        role: store.acct.role,
        preferences: store.acct.preferences
    };
}

const mapDispatchToProps = {

}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Health' })
)

export default enhancement(Health);
