import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MsgForNoData, getAccountToolTip, IndLongShort, getDisplayName, isObjectEmpty } from '../../../../common/constants.jsx';

export default class ManageAlertsPopup extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.isManageAlertsPopupOpen} toggle={this.props.toggleManageAlertsPopup} key="manageAlerts1"
                backdrop={false}
                size='lg'
                zIndex='9999'
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                centered>
                <ModalHeader toggle={this.props.toggleManageAlerts} charCode="X" key="manageAlerts2">
                    <label className="RCM_tile_heading" key='DisclaimerModelheader' key="manageAlerts3">MANAGE ALERTS 
                    </label>
                </ModalHeader>
                <ModalBody className="RCM_Manage_alert_popup" key="manageAlerts4">
                    {this.props.state.selectedAccount && this.props.state.selectedAccount !== null && this.props.state.selectedAccount !== isObjectEmpty ?
                        <div key="manageAlerts5">
                            <div className="RCM_manage_alert_popup_subheading" key="manageAlerts6">
                                <div key="manageAlerts7">Alerts for Account: &nbsp; <span title={getAccountToolTip(this.props.state.selectedAccount, IndLongShort.LONG)} key="manageAlerts8">{getAccountToolTip(this.props.state.selectedAccount, IndLongShort.SINGLEVIEW)}</span></div>
                                <div className="RCM_edelivery_link_wrapper " key="manageAlerts9">
                                    <label onClick={() => this.props.applyAllAccounts()} key="manageAlerts10">Apply changes to All Accounts</label>
                                </div>
                            </div>
                            
                            <div className="react-bootstrap-table" key="manageAlerts11">
                                <table className="table RCM_two_level_table1" key="manageAlerts12">
                                    <thead key="manageAlerts13">
                                        <tr key="manageAlerts14">
                                            <th colSpan={this.props.state.notificationOptions.length + 1} key="manageAlerts15">Alert Type</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody key="manageAlerts16">

                                        {this.props.state.selectedAccount && this.props.state.selectedAccount !== null && this.props.state.selectedAccount.alertTypes !== undefined
                                            && this.props.state.selectedAccount.alertTypes.length &&
                                            this.props.state.selectedAccount.alertTypes.map((data, index) => {
                                                return (
                                                    < tr key={"manageAlerts1" + index + "01"}>
                                                        <td key={"manageAlerts1" + index + "02"}>{data.alertType}</td>
                                                        {data.subscribedDevices.map((device, index) => {
                                                            return (
                                                                <td key={"manageAlerts1" + index + "0201"}>
                                                                    <span className="radio" key={"manageAlerts1" + index + "0202"}>
                                                                        <label className="RCM_selectLabel" key={"manageAlerts1" + index + "0203"}>
                                                                            <input type="checkbox" name={data.alertType + device.deviceType}
                                                                                checked={device.value} key={"manageAlerts1" + index + "0204"}
                                                                                onChange={() => this.props.handleAlertChange(data.alertType, device)}
                                                                            />
                                                                            <span className="profile-font-color RCM_preference_label" key={"manageAlerts1" + index + "0205"}>{device.deviceDisplayName}</span>
                                                                        </label>
                                                                    </span>

                                                                </td>
                                                            )
                                                        })

                                                        }
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="row row-margin" key="manageAlerts17">
                                <div className="col-sm-12 RCM_Manage_alert_popup_buttons" key="manageAlerts18">
                                    <input className="btn RCM_button_secondary RCM_select_btn" type="submit" value="CANCEL" onClick={this.props.toggleManageAlerts} key="manageAlerts19"/>

                                    <input className="btn RCM_button_primary RCM_select_btn" type="submit" value="SAVE" onClick={this.props.saveSelectedAccount}
                                        disabled={this.props.state.isSaveDisabled} key="manageAlerts20"/>
                                </div>
                            </div>
                        </div> : { MsgForNoData}
                    }
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        );
    }
}