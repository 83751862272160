import { errorMsg, taxDocumetOTPURL } from "../../../common/constants";
import { Get } from "../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../common/_logging";

export var getOtpCancellationToken = null;

export const getOtpService = (token) => {

    //Check if cancellation token has value;
    if (getOtpCancellationToken !== null && getOtpCancellationToken !== undefined) {
        //Cancel the existing call
        getOtpCancellationToken.cancel('Cancelling the get otp request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getOtpCancellationToken = cancelToken.source();
    const Url = taxDocumetOTPURL;

    let getOtpSummaryPromise = new Promise(
        function (resolve, reject) {

            Get(Url, token, getOtpCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Otp Service : get otp ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Otp Service Service : get otp :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return getOtpSummaryPromise;
}