import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import DatePicker from 'react-datepicker';
import classnames from 'classnames'
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import {
    roundUnitValue, amountFormat, alphanumericnameFormat, alphabitFormat, discriptionFormat, zipFormat,
    numericFormat, MessageType, MessageClass, viewOnlyText
} from './../../../../../common/constants.jsx';
import Messages from '../../../../../common/messages.jsx';
import { HistoryManualAsset } from './history';
import cloneDeep from 'lodash/cloneDeep';
class RealEstateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            displayvalue: '',
            displayoutstandingbalance: '',
            displayliveablearea: '',
            displayinitialvalue: '',
            intialAccount: {},
            PricingFrequencyList:
                ['Semi – Annually', 'Annually', 'Other'],
            isPricingFrequencyOpen: false,
            typesOfAccounts: [
                {
                    "assetName": " ",
                    "assetDisplayName": " ",
                },
                {
                    "assetName": "Personal Property",
                    "assetDisplayName": "Personal Property",
                },
                {
                    "assetName": "Investment Property",
                    "assetDisplayName": "Investment Property"
                },
                {
                    "assetName": "Other",
                    "assetDisplayName": "Other"
                },
            ],
            isTypesOfAccountsOpen: false,
            isPricingFrequencyOpen: false,
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: 'Real Estate',
                    description: '',
                    subassettype: '',
                    otherpricingfrequency: '',
                    name: ''
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date(),
                    sourceofvaluation: '',
                    pricingfrequency: '',
                    pricingcurrency: '',
                    initialprice: '',
                    initialpricedate: new Date(),
                    sourceofinitialvaluation: '',
                    otherpricingfrequency: ''
                },
                history: [
                ],
                realestate: {
                    propertyaddress: {
                        line1: "",
                        line2: "",
                        city: "",
                        state: "",
                        zip: "",
                        county: "",
                        country: ""
                    },
                    propertydetails: {
                        liveablearea: "",
                        floors: "",
                        bedrooms: "",
                        bathrooms: "",
                        yearbuilt: "",
                        type: "",
                        isprimaryresidence: "no",
                        isinvestment: "yes",
                        yearpurchased: "",
                        sizeofland: "",
                    },
                    mortgageinformation: {
                        loanno: '',
                        nickname: '',
                        custodianname: '',
                        outstandingbalance: '',
                        mortgageaccountkey: '',
                        description: ''
                    }
                }
            },
            formStateChange: false,
            typeoptions: ["Single Family", "Multiple Family", "Condo", "TownHome", "Land", "Commercial"],
            istypeopen: false,
            totalval: 0

        };
        this.typetoggle = this.typetoggle.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleInitialValueAsOfChange = this.handleInitialValueAsOfChange.bind(this);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.typechange = this.typechange.bind(this);
        this.dropdowntoggle = this.dropdowntoggle.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.typesOfAccountsToggle = this.typesOfAccountsToggle.bind(this);
        this.typesOfAccountsChange = this.typesOfAccountsChange.bind(this);
        this.pricingFrequencyToggle = this.pricingFrequencyToggle.bind(this);
        this.pricingFrequencyChange = this.pricingFrequencyChange.bind(this);
        this.deleteHistory = this.deleteHistory.bind(this);
        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displayvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displayliveablearea = "";
            if (this.props.account.realestate.propertydetails.liveablearea !== undefined && this.props.account.realestate.propertydetails.liveablearea !== null && this.props.account.realestate.propertydetails.liveablearea !== "")
                displayliveablearea = roundUnitValue(parseFloat(this.props.account.realestate.propertydetails.liveablearea), false, true);

            var displayinitialvalue = "";
            if (this.props.account.value.initialprice !== undefined && this.props.account.value.initialprice !== null && this.props.account.value.initialprice != '')
                displayinitialvalue = parseFloat(this.props.account.value.initialprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));

            var displayoutstandingbalance = '';
            if (this.props.account.realestate.mortgageinformation.outstandingbalance !== undefined &&
                this.props.account.realestate.mortgageinformation.outstandingbalance !== null &&
                this.props.account.realestate.mortgageinformation.outstandingbalance != '')
                displayoutstandingbalance = parseFloat(this.props.account.realestate.mortgageinformation.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            this.setState({
                account: this.props.account,
                intialAccount: initailAccount,
                displayvalue: displayvalue,
                displayliveablearea: displayliveablearea,
                displayinitialvalue: displayinitialvalue,
                displayoutstandingbalance: displayoutstandingbalance
            });

        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    componentDidUpdate(prevProps) {

        if (prevProps.source !== this.props.source) {
            if (this.props.mode === 'edit' && this.props.account) {
                var displaytotalvalue = "";
                if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                    displaytotalvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var displayliveablearea = "";
                if (this.props.account.realestate.propertydetails.liveablearea !== undefined && this.props.account.realestate.propertydetails.liveablearea !== null && this.props.account.realestate.propertydetails.liveablearea !== "")
                    displayliveablearea = roundUnitValue(parseFloat(this.props.account.realestate.propertydetails.liveablearea), false, true);

                var displayinitialvalue = "";
                if (this.props.account.value.initialprice !== undefined && this.props.account.value.initialprice !== null && this.props.account.value.initialprice != '')
                    displayinitialvalue = parseFloat(this.props.account.value.initialprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                var displayoutstandingbalance = '';
                if (this.props.account.realestate.mortgageinformation.outstandingbalance !== undefined &&
                    this.props.account.realestate.mortgageinformation.outstandingbalance !== null &&
                    this.props.account.realestate.mortgageinformation.outstandingbalance != '')
                    displayoutstandingbalance = parseFloat(this.props.account.realestate.mortgageinformation.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var initailAccount = JSON.parse(JSON.stringify(this.props.account));
                this.setState({
                    account: this.props.account,
                    intialAccount: initailAccount,
                    displaytotalvalue: displaytotalvalue,
                    displayliveablearea: displayliveablearea,
                    displayinitialvalue: displayinitialvalue,
                    displayoutstandingbalance: displayoutstandingbalance
                });
            } else if (this.props.mode === 'add') {
                var initailAccount = JSON.parse(JSON.stringify(this.state.account));
                this.setState({ intialAccount: initailAccount });
            }
        }
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    // this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }

    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.initialaccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
        let messages = [];
        if (this.state.account.value.totalvalue !== this.state.intialAccount.value.totalvalue || this.state.account.value.valueasof !== this.state.intialAccount.value.valueasof
            || this.state.account.value.initialprice !== this.state.intialAccount.value.initialprice || this.state.account.value.initialpricedate !== this.state.intialAccount.value.initialpricedate) {

            messages.push({
                type: MessageType.INFO,
                text: 'Value changes will be shown here after Submit',
                class: MessageClass.INFO
            })
            this.setState({
                formValueChange: true, messages: messages
            });
        }
        else {
            this.setState({
                formValueChange: false, messages: messages
            });
        }
    }
    deleteHistory(valueasof) {
        let arr = cloneDeep(this.state.account.history);
        var indexOfHistory = -1;
        arr.findIndex(function (obj, i) {
            if (obj.valueasof === valueasof) {
                indexOfHistory = i;
            }
        });
        if (indexOfHistory >= 0) {
            arr.splice(indexOfHistory, 1);
        }
        let clonedState = Object.assign({}, this.state);
        clonedState.account.history = arr;
        this.setState(clonedState)
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    typesOfAccountsToggle() {
        this.setState(prevState => ({
            isTypesOfAccountsOpen: !prevState.isTypesOfAccountsOpen
        }))
    }
    typesOfAccountsChange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.info.subassettype = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }
    pricingFrequencyToggle() {
        this.setState(prevState => ({
            ispricingFrequencyOpen: !prevState.ispricingFrequencyOpen
        }))
    }
    pricingFrequencyChange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        if (clonedState.account.value.pricingfrequency === "Other" && statevalue !== "Other") {
            clonedState.account.value.otherpricingfrequency = "";
        }
        clonedState.account.value.pricingfrequency = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }
    typechange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.realestate.propertydetails = {
            liveablearea: "",
            floors: "",
            bedrooms: "",
            bathrooms: "",
            yearbuilt: "",
            type: statevalue,
            isprimaryresidence: "no",
            isinvestment: "yes",
            yearpurchased: "",
            sizeofland: "",
        }
        this.setState({ clonedState });
        this.checkStateChange();
    }

    dropdowntoggle(evt) {
        if (evt.target.name === "citydropdown") {
            this.setState(prevState => ({
                iscityopen: !prevState.iscityopen
            }))
        }
        else if (evt.target.name === "statedropdown") {
            this.setState(prevState => ({
                istateopen: !prevState.istateopen
            }))
        }
        else if (evt.target.name === "countrydropdown") {
            this.setState(prevState => ({
                iscountryopen: !prevState.iscountryopen
            }))
        }
        else if (evt.target.name === "noOfBedrooms") {
            this.setState(prevState => ({
                isbedroomopen: !prevState.isbedroomopen
            }))
        }
        else if (evt.target.name === "bathroomdropdown") {
            this.setState(prevState => ({
                isbathroomopen: !prevState.isbathroomopen
            }))
        } else if (evt.target.name === "typedropdown") {
            this.setState(prevState => ({
                istypeopen: !prevState.istypeopen
            }))
        }
    }

    typetoggle() {
        this.setState(prevState => ({
            istypeopen: !prevState.istypeopen
        }))
    }

    handleValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.valueasof = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleInitialValueAsOfChange(evt) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.value.initialpricedate = evt;
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleFormChanges(evt) {

        let clonedState = Object.assign({}, this.state);

        if (evt.target.name === "totalvalue") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayvalue = evt.target.value;
        }
        else if (evt.target.name === "initialvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {

            clonedState.displayinitialvalue = evt.target.value;
        }
        else if (evt.target.name === "address1") {

            clonedState.account.realestate.propertyaddress.line1 = evt.target.value;
        }
        else if (evt.target.name === "address2") {
            clonedState.account.realestate.propertyaddress.line2 = evt.target.value;
        }
        else if (evt.target.name === "county") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.realestate.propertyaddress.county = evt.target.value;
        }
        else if (evt.target.name === "city") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.realestate.propertyaddress.city = evt.target.value;
        }
        else if (evt.target.name === "state") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.realestate.propertyaddress.state = evt.target.value;
        }
        else if (evt.target.name === "country") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.realestate.propertyaddress.country = evt.target.value;
        }
        else if (evt.target.name === "bedrooms") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 2)
                clonedState.account.realestate.propertydetails.bedrooms = evt.target.value;
        } else if (evt.target.name === "bathrooms") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 2)
                clonedState.account.realestate.propertydetails.bathrooms = evt.target.value;
        }
        else if (evt.target.name === "zip") {
            if (evt.target.value === "" || zipFormat.test(evt.target.value) && evt.target.value.length <= 10)
                clonedState.account.realestate.propertyaddress.zip = evt.target.value;
        }
        else if (evt.target.name === "livablearea") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.realestate.propertydetails.liveablearea = evt.target.value;
        }
        else if (evt.target.name === "isprimaryresidence") {
            //if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
            clonedState.account.realestate.propertydetails.isprimaryresidence = evt.target.value;
        }
        else if (evt.target.name === "isinvestment") {
            //if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
            clonedState.account.realestate.propertydetails.isinvestment = evt.target.value;
        }
        else if (evt.target.name === "yearbuilt") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 4)
                clonedState.account.realestate.propertydetails.yearbuilt = evt.target.value;
        }
        else if (evt.target.name === "yearpurchased") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 4)
                clonedState.account.realestate.propertydetails.yearpurchased = evt.target.value;
        }
        else if (evt.target.name === "floors") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 3)
                clonedState.account.realestate.propertydetails.floors = evt.target.value;
        } else if (evt.target.name === "name" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.name = evt.target.value;
        } else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        } else if (evt.target.name === "sizeofland" && evt.target.value.length <= 10 && (evt.target.value === "" || numericFormat.test(evt.target.value))) {
            clonedState.account.realestate.propertydetails.sizeofland = evt.target.value;

        } else if (evt.target.name === "desc" && (evt.target.value == "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;
        }
        else if (evt.target.name === "liveablearea") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayliveablearea = evt.target.value;
        }
        else if (evt.target.name === 'pricingcurrency') {
            clonedState.account.value.pricingcurrency = evt.target.value;
        }
        else if (evt.target.name === "otherpricingfrequency" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.otherpricingfrequency = evt.target.value;
        }
        else if (evt.target.name === "totalvaluesource" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.sourceofvaluation = evt.target.value;
        }
        else if (evt.target.name === "initialvaluesource" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.sourceofinitialvaluation = evt.target.value;
        }
        else if (evt.target.name === "mortgageinformationloanno" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.realestate.mortgageinformation.loanno = evt.target.value;
        }
        else if (evt.target.name === "mortgageinformationnickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.realestate.mortgageinformation.nickname = evt.target.value;
        }
        else if (evt.target.name === "mortgageinformationcustodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.realestate.mortgageinformation.custodianname = evt.target.value;
        }
        else if (evt.target.name === "outstandingbalance") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayoutstandingbalance = evt.target.value;
        }
        else if (evt.target.name === "mortgageinformationdesc" && (evt.target.value == "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.realestate.mortgageinformation.description = evt.target.value;
        }
        this.setState(clonedState);
        this.checkStateChange();
    }
    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "totalvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displayvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "liveablearea") {

                clonedState.account.realestate.propertydetails.liveablearea = evt.target.value;
                clonedState.displayliveablearea = roundUnitValue(parseFloat(evt.target.value), false, true);
            }
            else if (evt.target.name === "initialvalue") {

                clonedState.account.value.initialprice = evt.target.value;
                clonedState.displayinitialvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "outstandingbalance") {
                clonedState.account.realestate.mortgageinformation.outstandingbalance = evt.target.value;
                clonedState.displayoutstandingbalance = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "totalvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
            }
            else if (evt.target.name === "liveablearea") {
                clonedState.account.realestate.propertydetails.liveablearea = evt.target.value;
            }
            else if (evt.target.name === "initialvalue") {
                clonedState.account.value.initialprice = evt.target.value;
            }
            else if (evt.target.name === "outstandingbalance") {
                clonedState.account.realestate.mortgageinformation.outstandingbalance = evt.target.value;
            }
            this.setState(clonedState);
        }
        this.checkStateChange();
    }

    handleAmmountOnFocus(evt) {
        const clonedState = Object.assign({}, this.state);
        if (evt.target.name === "totalvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displayvalue = ""
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displayvalue = clonedState.account.value.totalvalue;
            }
        } else if (evt.target.name === "liveablearea") {
            if (clonedState.account.realestate.propertydetails.liveablearea === "") {
                clonedState.displayliveablearea = "";
                clonedState.account.realestate.propertydetails.liveablearea = "";
            } else {
                clonedState.displayliveablearea = clonedState.account.realestate.propertydetails.liveablearea;
            }

        }
        else if (evt.target.name === "initialvalue") {
            if (clonedState.account.value.initialprice === "") {
                clonedState.displayinitialvalue = ""
                clonedState.account.value.initialprice = "";
            } else {
                clonedState.displayinitialvalue = clonedState.account.value.initialprice;
            }

        }
        if (evt.target.name === "outstandingbalance") {
            if (clonedState.account.realestate.mortgageinformation.outstandingbalance === "") {
                clonedState.displayoutstandingbalance = ""
                clonedState.account.realestate.mortgageinformation.outstandingbalance = "";
            } else {
                clonedState.displayoutstandingbalance = clonedState.account.realestate.mortgageinformation.outstandingbalance;
            }
        }
        this.setState(clonedState);
    }
    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_realestate_form">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                        >
                            STEP 1 : BASIC INFORMATION
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('2'); }} id="RCM_propertyAddress_info"
                        >
                            STEP 2 : PROPERTY ADDRESS
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' }, { linkactive: this.state.activeTab === '3' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('3'); }} id="RCM_propertydetails_info"
                        >
                            STEP 3 : PROPERTY DETAILS
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '4' }, { linkactive: this.state.activeTab === '4' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('4'); }} id="RCM_mortgage_info"
                        >
                            STEP 4 : MORTGAGE INFORMATION
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '5' }, { linkactive: this.state.activeTab === '5' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('5'); }} id="RCM_history_info"
                        >
                            STEP 5 : HISTORY
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                    <TabPane tabId="1">


                        <div>
                            <Form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_assetClass">
                                                <Label for="type">Asset Class</Label>
                                                <Dropdown name="type" required
                                                    isOpen={this.state.isTypesOfAccountsOpen}
                                                    toggle={this.typesOfAccountsToggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.info.subassettype}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.typesOfAccounts.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.typesOfAccountsChange(item.assetName)}>
                                                                        <div>{item.assetDisplayName}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_name">
                                                <Label for="nickname">Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="name"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.name}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_nickname">
                                                <Label for="nickname">Nickname</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="nickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.nickname}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_initialprice">
                                                <Label for="initialvalue">Initial Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="initialvalue"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displayinitialvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_initialpricedate">
                                                <div><Label for="initialvalueasof">Initial Value Date (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="initialvalueasof" id="initialvalueasof"
                                                    value={new Date(this.state.account.value.initialpricedate)}
                                                    onChange={this.handleInitialValueAsOfChange}
                                                    selected={(this.state.account.value.initialpricedate !== undefined && this.state.account.value.initialpricedate !== null
                                                        && this.state.account.value.initialpricedate !== '') ? new Date(this.state.account.value.initialpricedate) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridValueSource" id="RCM_sourceofinitialvaluation">
                                                <Label for="initialvaluesource">Source of Initial Valuation</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="initialvaluesource"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.sourceofinitialvaluation}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity" id="RCM_totalvalue">
                                                <Label>Total Value </Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="totalvalue"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.displayvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                <div>  <Label for="valueasof" >Value as of (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="valueasof" id="valueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    onChange={this.handleValueAsOfChange}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>

                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridValueSource" id="RCM_sourceofvaluation">
                                                <Label for="valuesource">Source of Valuation</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="totalvaluesource"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.sourceofvaluation}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="pricingfrequency">Pricing Frequency</Label>
                                                <Dropdown name="pricingfrequency" required
                                                    isOpen={this.state.ispricingFrequencyOpen}
                                                    toggle={this.pricingFrequencyToggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.value.pricingfrequency}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.PricingFrequencyList.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.pricingFrequencyChange(item)}>
                                                                        <div>{item}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        {this.state.account.value.pricingfrequency === 'Other'
                                            ? <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="otherpricingfrequency">Specify Other Pricing Frequency</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="otherpricingfrequency"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.otherpricingfrequency}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            : ''
                                        }
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridpricingcurrency">
                                                <Label for="pricingcurrency">Pricing Currency</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="pricingcurrency"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.pricingcurrency}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="desc"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.info.description}
                                                onChange={this.handleFormChanges}
                                                required
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </div>
                                        {
                                            this.state.account.realestate.mortgageinformation.mortgageaccountkey
                                                && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== null
                                                && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== '' ?
                                                <div className="row">
                                                    <div className="RCM_icon_hand  RCM_manage_grp_new_group"
                                                        onClick={() => { this.props.openLinkedAccount(this.state.account.realestate.mortgageinformation.mortgageaccountkey) }}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                        Click here to edit the linked mortgage account.
                                            </div>
                                                </div>
                                                : ''
                                        }

                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                        >
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </TabPane>
                    <TabPane tabId="2">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                            <FormGroup controlId="formGridAddress1">
                                                <Label for="address1">Address Line 1</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="address1"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.line1}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                            <FormGroup controlId="formGridAddress2">
                                                <Label>Address Line 2</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="address2"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.line2}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>City</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="city"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.city}
                                                    onChange={this.handleFormChanges}
                                                />

                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>State</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="state"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.state}
                                                    onChange={this.handleFormChanges}
                                                />

                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Zip</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="zip"
                                                    className="RCM_form_control"
                                                    maxLength="10"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.zip}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>County</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="county"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.county}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>Country</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="country"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.realestate.propertyaddress.country}
                                                    onChange={this.handleFormChanges}
                                                />

                                            </FormGroup>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>Property Type</Label>
                                                <Dropdown name="typedropdown" required
                                                    isOpen={this.state.istypeopen}
                                                    toggle={this.typetoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.realestate.propertydetails.type}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.typeoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.typechange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    {this.state.account.realestate.propertydetails.type !== 'Land' && this.state.account.realestate.propertydetails.type !== '' ?
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridCity">
                                                    <Label>Square footage</Label>

                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="liveablearea"
                                                        className="RCM_form_control"
                                                        maxLength="12"
                                                        required
                                                        value={this.state.displayliveablearea}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <Label>No. Of Floors</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="floors"
                                                        className="RCM_form_control"
                                                        maxLength="3"
                                                        required
                                                        value={this.state.account.realestate.propertydetails.floors}
                                                        onChange={this.handleFormChanges}
                                                    //ref={ele => { groupNameInput = ele }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {this.state.account.realestate.propertydetails.type !== 'Commercial' ?

                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridZip">
                                                        <Label>No. Of Bedrooms</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="bedrooms"
                                                            className="RCM_form_control"
                                                            maxLength="2"
                                                            required
                                                            value={this.state.account.realestate.propertydetails.bedrooms}
                                                            onChange={this.handleFormChanges}
                                                        />

                                                    </FormGroup>
                                                </div> : <div></div>}
                                            {this.state.account.realestate.propertydetails.type !== 'Commercial' ?
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridCity">
                                                        <Label>No. Of Bathrooms</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="bathrooms"
                                                            className="RCM_form_control"
                                                            maxLength="2"
                                                            required
                                                            value={this.state.account.realestate.propertydetails.bathrooms}
                                                            onChange={this.handleFormChanges}
                                                        />

                                                    </FormGroup>

                                                </div> : <div></div>}
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <Label>Year Built</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="yearbuilt"
                                                        className="RCM_form_control"
                                                        maxLength="4"
                                                        required
                                                        value={this.state.account.realestate.propertydetails.yearbuilt}
                                                        onChange={this.handleFormChanges}
                                                    // ref={ele => { groupNameInput = ele }}
                                                    />
                                                </FormGroup>
                                            </div>


                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <Label>Year Purchased</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="yearpurchased"
                                                        className="RCM_form_control"
                                                        maxLength="4"
                                                        required
                                                        value={this.state.account.realestate.propertydetails.yearpurchased}
                                                        onChange={this.handleFormChanges}
                                                    // ref={ele => { groupNameInput = ele }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {this.state.account.realestate.propertydetails.type !== 'Commercial' ?
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridState">
                                                        <div><label className="RCM_selectLabel">
                                                            Is Primary Residence
                                    </label></div>
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio" value="yes" checked={this.state.account.realestate.propertydetails.isprimaryresidence === 'yes'} name="isprimaryresidence" onChange={this.handleFormChanges} />
                                                                <span className="RCM_radio_span">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio" value="no" name="isprimaryresidence" checked={this.state.account.realestate.propertydetails.isprimaryresidence === 'no'} onChange={this.handleFormChanges} />
                                                                <span className="RCM_radio_span">No</span>
                                                            </label>
                                                        </div>
                                                    </FormGroup>
                                                </div> : <div></div>}
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <div><label className="RCM_selectLabel">
                                                        Is Investment
                                    </label></div>
                                                    <div>
                                                        <label className="RCM_icon_hand RCM_Radio_Button">
                                                            <input type="radio" value="yes" name="isinvestment" checked={this.state.account.realestate.propertydetails.isinvestment === 'yes'} onChange={this.handleFormChanges} />
                                                            <span className="RCM_radio_span">Yes</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="RCM_icon_hand RCM_Radio_Button">
                                                            <input type="radio" value="no" name="isinvestment" checked={this.state.account.realestate.propertydetails.isinvestment === 'no'} onChange={this.handleFormChanges} />
                                                            <span className="RCM_radio_span">No</span>
                                                        </label>
                                                    </div>
                                                </FormGroup>
                                            </div>

                                        </div>
                                        : this.state.account.realestate.propertydetails.type === 'Land' ?
                                            <div className="row">
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridState">
                                                        <Label>Size of Land</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="sizeofland"
                                                            className="RCM_form_control"
                                                            maxLength="10"
                                                            required
                                                            value={this.state.account.realestate.propertydetails.sizeofland}
                                                            onChange={this.handleFormChanges}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridState">
                                                        <Label>Year Purchased</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="yearpurchased"
                                                            className="RCM_form_control"
                                                            maxLength="4"
                                                            required
                                                            value={this.state.account.realestate.propertydetails.yearpurchased}
                                                            onChange={this.handleFormChanges}
                                                        // ref={ele => { groupNameInput = ele }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                            </div> : <div></div>

                                    }
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('4'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridloannumber" id="RCM_loannumber">
                                                <Label for="loannumber">Loan No.</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="mortgageinformationloanno"
                                                    className="RCM_form_control"
                                                    required
                                                    maxLength="20"
                                                    value={this.state.account.realestate.mortgageinformation
                                                        && this.state.account.realestate.mortgageinformation !== null
                                                        ? this.state.account.realestate.mortgageinformation.loanno
                                                        : ''}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_mortgage_nickname">
                                                <Label for="nickname">Nickname</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="mortgageinformationnickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.realestate.mortgageinformation
                                                        && this.state.account.realestate.mortgageinformation !== null
                                                        ? this.state.account.realestate.mortgageinformation.nickname
                                                        : ''}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_bankname">
                                                <Label for="bankname">Bank Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="mortgageinformationcustodianname"
                                                    className="RCM_form_control"
                                                    required
                                                    maxLength="50"
                                                    value={this.state.account.realestate.mortgageinformation
                                                        && this.state.account.realestate.mortgageinformation !== null
                                                        ? this.state.account.realestate.mortgageinformation.custodianname
                                                        : ''}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity" id="RCM_outstandingbalance">
                                                <Label>
                                                    Outstanding Balance
                                                {this.props.mode === 'edit'
                                                        && this.state.account.realestate.mortgageinformation.mortgageaccountkey
                                                        && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== null
                                                        && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== ''
                                                        ? <span>&nbsp;*</span>
                                                        : ''
                                                    }
                                                </Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="outstandingbalance"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displayoutstandingbalance}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.props.mode === 'edit' && this.state.account.realestate.mortgageinformation.mortgageaccountkey
                                                        && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== null
                                                        && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity" id="RCM_totalvalue">
                                                <Label>Property Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="value"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.displayvalue}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                <div>  <Label for="valueasof" >Value as of (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="valueasof" id="valueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    placeholderText="Click to select a date"
                                                    disabled
                                                />
                                            </FormGroup>

                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="mortgageinformationdesc"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.realestate.mortgageinformation.description}
                                                onChange={this.handleFormChanges}
                                                required
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('5'); }}>NEXT</button>
                                    </div>
                                </div>
                                {this.props.mode === 'edit'
                                    && this.state.account.realestate.mortgageinformation.mortgageaccountkey
                                    && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== null
                                    && this.state.account.realestate.mortgageinformation.mortgageaccountkey !== ''
                                    ? <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                            <label className="RCM_Customization_text RCM_Customization_text_small_font"><span>*&nbsp;&nbsp;</span> These values can be updated from linked mortgage account.</label>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </Form>
                        </div>

                    </TabPane>

                    <TabPane tabId="5">
                        <div>
                            <Form>
                                <div className="row">
                                    {this.state.formValueChange &&
                                        this.state.messages.length > 0 ?
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <Messages messages={this.state.messages} containerClass="RCM_validation_error_container" />
                                        </div>
                                        : ''
                                    }
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <HistoryManualAsset
                                                source='realestate'
                                                data={this.state.account.history}
                                                deleteHistory={this.deleteHistory}
                                                canedit={this.state.account.canedit !== undefined ? this.state.account.canedit : ''} />
                                        </div>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('4'); }}>PREVIOUS</button>
                                    </div>
                                </div>

                            </Form>

                        </div>

                    </TabPane>
                    {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                        ?
                        < div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                {viewOnlyText}
                            </div>
                        </div>
                        : ""
                    }
                </TabContent>



            </div>)
    }
}
function mapStateToProps(store) {
    return {
        accounts: store.acct.accounts,
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(RealEstateForm);




