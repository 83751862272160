import React, { useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { getOtpService, getOtpCancellationToken } from './otpService'
import OtpView from './otpView.jsx';
import { getNestedObject } from '../../MoneyMovement/common/utilty';
import { setOtpExpiry } from "../../../actions/personalisationAction";
import { MessageType, MessageClass } from '../../../common/constants.jsx';
import * as $ from 'jquery';
const OtpComponent = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [token, setToken] = useState('');
    const [expTime, setExpTime] = useState();
    const [messages, setMessages] = useState([]);
    const authToken = useSelector(state => getNestedObject(state, ['acct', 'token']));
    const otpToken = useSelector(state => getNestedObject(state, ['personalisationData', 'otpToken']));
    const otpExpiry = useSelector(state => getNestedObject(state, ['personalisationData', 'otpExpiry']));
    const dispatch = useDispatch();

    useEffect(() => {
        if (otpToken && otpToken !== '' && otpExpiry && otpExpiry !== '') {
            setToken(otpToken);
            setExpTime(otpExpiry);
            setMessages([]);
            
        }
    }, [otpToken, otpExpiry])

    const getOTP = useCallback(() => {
        setToken('');
        setExpTime('');
        setShowOverlay(prevShowOverlay => true);
        let messages = [];
       
        getOtpService(authToken, null, null)
            .then(res => {
                if (res) {
                    let data = res.token ? res.token : ''
                    let exptime = res.expirationtime ? res.expirationtime:''
                    setToken(data);
                    setExpTime(res.expirationtime)
                    dispatch(setOtpExpiry({ token: res.token, expiry: res.expirationtime }))
                    setShowOverlay(prevShowOverlay => false);
                    setMessages([]);
                } else {
                    setShowOverlay(prevShowOverlay => false);
                    setMessages([]);
                }

            })
            .catch(error => {
                console.error(error);
                messages.push({
                    type: MessageType.INFO,
                    text: "Failed to Fetch One Time Passcode",
                    class: MessageClass.ERROR
                });
                setMessages(messages);
                setShowOverlay(prevShowOverlay => false);
            })

    }, [token]);

    useEffect(() => {
        const interval = 
            setInterval(function () {
                if (token === '') return;
                let date_future = new Date(expTime);
                let date_now = new Date();

                let seconds = Math.floor((date_future - (date_now)) / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);

                hours = hours - (days * 24);
                minutes = minutes - (days * 24 * 60) - (hours * 60);
                seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

                if (minutes <= 0 && seconds <= 0) {
                    $("#expTime").text("OTP is Expired");
                    let dateexp = new Date();
                    setExpTime(dateexp);
                    setToken('');
                    dispatch(setOtpExpiry({ token: '', expiry: new Date() }));
                }
                else {
                    $("#expTime").text("Expires In:\n" + minutes + " Minutes " + seconds + " Seconds");
                }

            }, 10);
        return () => clearInterval(interval);
    }, [token,expTime]);
  
    return (
        <OtpView token={token} expTime={expTime} getOtpToken={getOTP} showOverlay={showOverlay} messages={messages}/>
    )
}

export default OtpComponent