import React, { Component } from 'react'
import NextGenAdvisoryView from './nextGenAdvisoryView';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { CheckIfProspectClient, projectYouUrl, nextGenHealthUrl } from '../../../common/constants';
import OverlayProcessing from '../../MoneyMovement/common/overlayProcessing';
import { _logInformation } from '../../../common/_logging';

class NextGenAdvisory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmationModalState: false,
            showOverlay: false,
            selectedSection: {
                url: '',
                headerData: '',
                contentData: ''
            },
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.confirmationModalToggle = this.confirmationModalToggle.bind(this);
        this.openProjectYou = this.openProjectYou.bind(this);
        this.openPinnacleCare = this.openPinnacleCare.bind(this);
    }
    confirmationModalToggle() {
        this.setState(prevState => ({ confirmationModalState: !prevState.confirmationModalState }));
    }
    openPinnacleCare() {
        _logInformation('PinnacleCare Clicked');
        const selectedSection = {
            url: nextGenHealthUrl,
            headerData: 'Health and Wellness Resources for Young Adults provided by PinnacleCare',
            contentData: 'By clicking on the \"Continue\" button below, you will be transferred to a third party website which is owned, operated and maintained by PinacleCare. If you have any questions, please contact your Financial Advisor.'
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    openProjectYou() {
        _logInformation('Project You Clicked');
        const selectedSection = {
            url: projectYouUrl,
            headerData: 'Career and Life Coaching for Emerging Professionals provided by ProjectYou',
            contentData: 'By clicking on the \"Continue\" button below, you will be transferred to a third party website which is owned, operated and maintained by ProjectYou. If you have any questions, please contact your Financial Advisor.'
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    openSelectedSectionWindow = () => {
        _logInformation(this.state.selectedSection.url);
        window.open(this.state.selectedSection.url);
        const selectedSection = {
            url: '',
            headerData: '',
            contentData: ''
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    render() {
        return (
            <>
                <NextGenAdvisoryView
                    confirmationModalState={this.state.confirmationModalState}
                    confirmationModalToggle={this.confirmationModalToggle}
                    openPinnacleCare={this.openPinnacleCare}
                    openProjectYou={this.openProjectYou}
                    openSelectedSectionWindow={this.openSelectedSectionWindow}
                    selectedSection={this.state.selectedSection}
                />

                {this.state.showOverlay && (<OverlayProcessing />)}
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
       
    };
}

const mapDispatchToProps = {

}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'NextGenAdvisory' })
)

export default enhancement(NextGenAdvisory);
