import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getAccountToolTip, IndLongShort } from "../../../common/constants.jsx";
import DisclaimerText from './disclaimerText'

export default class Disclaimer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggleDesModel}
        key="disclaimer1"
        backdrop={false}
        size="lg"
        zIndex="9999"
        className="RCM_CM_modal"
        wrapClassName="RCM_CM_modal_wrapper"
        modalClassName="RCM_CM_modal_class"
        contentClassName="RCM_CM_modal_content"
        centered
      >
        <ModalHeader
          toggle={this.props.toggleDesModel}
          charCode="X"
          key="disclaimer2"
        >
          <label className="RCM_tile_heading" key="DisclaimerModelheader">
            ELECTRONIC DELIVERY AGREEMENT
          </label>
        </ModalHeader>
        <ModalBody key="disclaimer4">
          <div
            className="RCM_eDelivery_summary_table RCM_table_scorllable_body"
            key="disclaimer5"
          >
            <table className="table RCM_two_level_table1" key="disclaimer6">
              <thead key="disclaimer7">
                <tr key="disclaimer8">
                  <th key="disclaimer9">Account</th>
                  <th key="disclaimer10">Confirms/ Confirming Prospectuses</th>
                  <th key="disclaimer11">Statements and Regulatory Inserts</th>
                  <th key="disclaimer12">Eligible Customer Correspondence</th>
                  <th key="disclaimer13">Tax Forms and Related Documents</th>
                  <th key="disclaimer14">
                    Shareholder Reports & Other Documents
                  </th>
                </tr>
              </thead>
              <tbody key="disclaimer15">
                {this.props.updatedAccounts &&
                  this.props.updatedAccounts.map((account, index) => {
                    return (
                      <tr key={"disclaimer16" + index + "01"}>
                        <td
                          title={getAccountToolTip(account, IndLongShort.LONG)}
                          key={"disclaimer16" + index + "02"}
                        >
                          {account.displayName}
                        </td>
                        <td key={"disclaimer16" + index + "03"}>
                          {account.enrolled === "Pending" ||
                          account.enrolled === "Not Available" ? (
                            <span key={"disclaimer16" + index + "04"}>
                              {account.enrolled}
                            </span>
                          ) : (
                            <span key={"disclaimer16" + index + "05"}>
                              {account.confirmsProspectuses ? "Y" : "N"}
                            </span>
                          )}
                        </td>
                        <td key={"disclaimer16" + index + "06"}>
                          {account.enrolled === "Pending" ||
                          account.enrolled === "Not Available" ? (
                            <span key={"disclaimer16" + index + "07"}>
                              {account.enrolled}
                            </span>
                          ) : (
                            <span key={"disclaimer16" + index + "08"}>
                              {account.statementsAndRegulatoryInserts
                                ? "Y"
                                : "N"}
                            </span>
                          )}
                        </td>
                        <td key={"disclaimer16" + index + "09"}>
                          {account.enrolled === "Pending" ||
                          account.enrolled === "Not Available" ? (
                            <span key={"disclaimer16" + index + "10"}>
                              {account.enrolled}
                            </span>
                          ) : (
                            <span key={"disclaimer16" + index + "11"}>
                              {account.eligibleCustomerCorrespondence
                                ? "Y"
                                : "N"}
                            </span>
                          )}
                        </td>

                        <td key={"disclaimer16" + index + "12"}>
                          {account.enrolled === "Pending" ||
                          account.enrolled === "Not Available" ? (
                            <span key={"disclaimer16" + index + "13"}>
                              {account.enrolled}
                            </span>
                          ) : (
                            <span key={"disclaimer16" + index + "14"}>
                              {account.taxFormsAndRelatedDocuments ? "Y" : "N"}
                            </span>
                          )}
                        </td>
                        <td key={"disclaimer16" + index + "15"}>
                          {account.enrolled === "Pending" ||
                          account.enrolled === "Not Available" ? (
                            <span key={"disclaimer16" + index + "16"}>
                              {account.enrolled}
                            </span>
                          ) : (
                            <span key={"disclaimer16" + index + "17"}>
                              {account.shareholderReportsAndOtherDocuments
                                ? "Y"
                                : "N"}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div
            className="RCM_CM_scroll RCM_profile_editmail"
            key="disclaimer18"
            style={{ marginBottom: "0.3rem" }}
          >
            <DisclaimerText />
          </div>
          <div className="row row-margin" style={{ marginBottom: "0.5rem" }}>
            <div className="col-sm-12">
              <a
                href={this.props.eDeliveryDisclaimerPdf}
                target="_blank"
                rel="noreferrer"
                className="RCM_link RCM_our_ideas"
              >
                Click here to print or download a copy of the agreement
              </a>
            </div>
          </div>
          <div className="row row-margin" key="disclaimer19">
            <div
              className="col-sm-12 RCM_edelivery_popup_buttons"
              key="disclaimer20"
            >
              <input
                className="btn RCM_button_secondary RCM_select_btn"
                type="submit"
                value="I DO NOT AGREE"
                onClick={this.props.toggleDesModel}
                key="disclaimer21"
              />

              <input
                className="btn RCM_button_primary RCM_select_btn"
                type="submit"
                value="I AGREE"
                onClick={this.props.clickOnAgree}
                key="disclaimer22"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}
