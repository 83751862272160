import React, { Component } from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
    StaticRouter,
} from 'react-router-dom';

import StickyNavbar from './stickynavbar.jsx';
import Accounts from './accounts/accounts';
import Toptendetail from './accounts/detail/topten/toptendetails.jsx';
import Activitydetail from './accounts/detail/activity/activitydetails.jsx';
import Balancesdetail from './accounts/detail/balances/balancesdetails.jsx';
import Balancessummary from './accounts/summary/balances/balancesservicecall';
import Assetallocationdetail from './accounts/detail/assetallocation/assetallocationdetails.jsx';
import DocumentCenter from './documents/documents.jsx';
import MoneyMovement from './MoneyMovement/MoneyMovement.jsx';
import AssetAggregationDetails from "./accounts/detail/assetaggregation/assetaggregation.jsx";
import { Spinner } from 'reactstrap';
import Logo from './../common/logo.jsx';
import UserAccountSetting from './useraccountsetting';
import Sticky from 'react-sticky-el';
import ProfileComponent from '../components/personalisationandsettings/profile/profileComponent';
import { authProvider } from '../../App';
import HelpandSupport from './HelpandSupport.jsx';
import Footer from './footer.jsx';
import { getEntireUserData, checkTermsAndConditionsService } from '../services/home/userServices';
import { withPageView } from '../hoc/withPageView.jsx';
import { _logEvent, _logException } from '../common/_logging';
import { UserLogOut, MessageType, MessageClass, GetPreferencePilotValue, getDateTimeVal, planManagerUrl } from '../common/constants';
import { getNestedObject } from './MoneyMovement/common/utilty';
import { compose } from 'redux';
import moment from "moment";
import "moment-timezone";
import Messages from '../common/messages.jsx';
import { accountresponse, famockresponse, groupedaccountselectormockresponse, groupedexternalacctmockresponse } from './profilemockresponse.jsx';
import { userinfo } from '../../App.js'
import FADetails from './FADetails/fadetails';
import Lifestyles from './lifestyles/lifestyles.jsx';
import FontSizing from '../common/fontsizing.jsx';
import { connect } from "react-redux";
import { UpdateAccount, UpdateStoreWithRequiredAppData, AddToken } from './../actions/accountsactions.jsx'
import ManageAssets from './accounts/detail/manageassets/manageassets.jsx';
import RealizedGainlossDetails from './accounts/detail/realizedgainloss/realizedgainloss';
import Liabilities from './accounts/detail/liabilities/liabilities';
import UnEntitled from './unentitled.jsx';
import EstimatedProjectedIncomeDetails from './accounts/detail/estimatedincome/estimatedprojectedincome';
import TabView from './../common/tabview.jsx';
import AlertDetails from '../components/personalisationandsettings/alerts/alertdetails/alertdetails';
import TeamworkContainer from './Teamwork/TeamworkContainer.jsx';
import Performance from './accounts/detail/performance/performance.jsx';
import Services from './services/servicescomponent';
import TermsAndConditionsModal from '../components/TermsAndConditions/TermsAndConditionsModal';
import {relasevariables} from "../../index";
import ClientV2Toast from './clientV2Toast.jsx'

class HomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageInverse: true,
            error: null,
            istempsuccess: false,
            isprofsuccess: false,
            role: null,
            contextjson: null,
            profilejson: null,
            showerrortouser: false,
            isFontScaleRequired: false,
            isTokenRefreshed: this.props.isTokenRefreshed,
            openHelpAndSupportModal: false,
            openTermsAndConditionModal: false,
            showHomePageComponents: false,
            termsData: []
        }

        // Add Window Event Listener for click on close of browser.
        this.handleWindowClose = this.handleWindowClose.bind(this);
        this.loadUserData = this.loadUserData.bind(this);
        this.checkTermsAndConditions = this.checkTermsAndConditions.bind(this);
        this.toogleTermsAndConditionModal = this.toogleTermsAndConditionModal.bind(this);
        this.logout = this.logout.bind(this);
    }

    //Handle window close event
    handleWindowClose = () => {
        this.logout();
        return true;
    }

    logout = () => {
        let userObject = { "name": "unknown" };
        //const user = sessionStorage.getItem('user');
        const user = userinfo;
        if (user) {
            userObject = JSON.parse(user);
        }
        _logEvent('User not entitled - logging out', getNestedObject(userObject, ['name']));
        UserLogOut(authProvider);
    };

    loadUserData() {
        getEntireUserData(this.props.token, null, this.props.simulationRequest, this.props.contextJson, this.props.profileJson).then(res => {
            var splitedName = this.props.user.name.split(",");
            const profilename = (res.profile && res.profile.fullname ? res.profile.fullname : null);
            const name = profilename ? profilename : (splitedName[1] ? splitedName[1] : '') + " " + (splitedName[0] ? splitedName[0] : '');
            //const name = (splitedName[1] ? splitedName[1] : '') + " " + (splitedName[0] ? splitedName[0] : '');
            var accountList = {};
            let internalAccounts = [];

            if (res.profile && res.profile.role === 'Prospect') {
                res.profile.groupedaccounts = groupedaccountselectormockresponse;
                res.profile.accounts = accountresponse;
                res.profile.groupedexternalaccounts = groupedexternalacctmockresponse;
            }
               
           
               
            if (res.profile && res.profile.accounts && res.profile.accounts !== null) {
                accountList.accounts = res.profile.accounts;

                internalAccounts = res.profile.accounts;
                for (const [, value] of internalAccounts.entries()) {
                    value.isInternal = true;
                }
            }
            let externalAccountsList = [];
            let externalAccounts = [];

            if (res.profile && res.profile.groupedexternalaccounts && res.profile.groupedexternalaccounts !== null && res.profile.role !== 'Prospect') {
                accountList.externalAccounts = res.profile.groupedexternalaccounts;

                externalAccountsList = res.profile.groupedexternalaccounts;
                for (const [, value] of externalAccountsList.entries()) {
                    for (const [, item] of value.groupby.entries()) {
                        item.isInternal = false;
                        externalAccounts.push(item);
                    }
                }
            }

            var accounts = [];
            var groupedAccounts = res.profile.groupedaccounts;

            if (res.profile && res.profile.role === 'Prospect') {
                accounts = [...internalAccounts];
            }
            else {
                const showexternalaccount = (res && res.preferences && res.preferences.showexternalaccount) ?
                    (res.preferences.showexternalaccount === 'true' ? true : false) : false;
                if (showexternalaccount) {
                    accounts = [...internalAccounts, ...externalAccounts];
                } else {
                    accounts = [...internalAccounts];
                }
            }


            if (res.profile.role === 'Prospect') {
                if (res.profile.repcode === undefined || res.profile.repcode === null || res.profile.repcode === '') {
                    res.profile.fadata = {};
                    res.profile.fadata = famockresponse;
                } else if (res.profile.fadata === undefined || res.profile.fadata === null) {
                    res.profile.fadata = {};
                    res.profile.fadata = famockresponse;
                }
            }

            let fadata = res.profile && res.profile.fadata;
            let showFADetails = false;
            if (fadata) {
                if (fadata.faname !== null && fadata.faname !== '') {
                    showFADetails = true;
                }
                if (fadata.contactnumber && fadata.contactnumber !== null && fadata.contactnumber.length >= 1) {
                    showFADetails = true;
                }
                if (fadata.email !== null && fadata.email !== '') {
                    showFADetails = true;
                }
            }
            let isFontScaleRequired = GetPreferencePilotValue(res, "FontScaleVisiblity", "false") === "true" ? true : false;
            let simulationAccount = this.props.simulationRequest !== undefined && this.props.simulationRequest !== null && this.props.simulationRequest.simulationAccount
                ? this.props.simulationRequest.simulationAccount
                : ''
            this.props.UpdateStoreWithRequiredAppData(
                {
                    accounts: accounts,
                    groupedaccounts: (res.profile && res.profile.groupedaccounts) ? res.profile.groupedaccounts : [],
                    externalAccounts: res.profile && res.profile.role === 'Prospect' ? [] : externalAccounts,
                    profileResp: res.profile,
                    portalid: (res.profile && res.profile.wsportaluserid) ? res.profile.wsportaluserid : '',
                    profileId: (res.profile && res.profile._id) ? res.profile._id : '',
                    name: name,
                    fadata: {
                        faname: (res.profile && res.profile.fadata && res.profile.fadata.faname) ? res.profile.fadata.faname : '',
                        contactnumber: (res.profile && res.profile.fadata && res.profile.fadata.contactnumber) ? res.profile.fadata.contactnumber : [],
                        email: (res.profile && res.profile.fadata && res.profile.fadata.email) ? res.profile.fadata.email : '',
                        website: (res.profile && res.profile.fadata && res.profile.fadata.website) ? res.profile.fadata.website : '',  
                    },
                    token: this.props.token,
                    role: (res.profile && res.profile.role) ? res.profile.role : '',
                    baseurl: this.props.baseurl,
                    contextjson: res.contextjson,
                    profilejson: res.profilejson,
                    aaduseroid: (res.profile && res.profile.aaduseroid) ? res.profile.aaduseroid : '',
                    preferences: res.preferences,
                    isTokenRefreshed: this.props.isTokenRefreshed,
                    user: { userName: this.props.user.userName },
                    accountList: accountList,
                    isphilanthropy: res.profile.isphilanthropy,
                    simulationAccount: simulationAccount
                }
            );
            this.setState({
                profileResp: res.profile,
                portalid: (res.profile && res.profile.wsportaluserid) ? res.profile.wsportaluserid : '',
                profileId: (res.profile && res.profile._id) ? res.profile._id : '',
                name: name,
                fadata: {
                    faname: (res.profile && res.profile.fadata && res.profile.fadata.faname) ? res.profile.fadata.faname : '',
                    contactnumber: (res.profile && res.profile.fadata && res.profile.fadata.contactnumber) ? res.profile.fadata.contactnumber : [],
                    email: (res.profile && res.profile.fadata && res.profile.fadata.email) ? res.profile.fadata.email : '',
                    website: (res.profile && res.profile.fadata && res.profile.fadata.website) ? res.profile.fadata.website : '',
                },
                isprofsuccess: true,
                role: (res.profile && res.profile.role) ? res.profile.role : '',
                contextjson: res.contextjson,
                profilejson: res.profilejson,
                aaduseroid: (res.profile && res.profile.aaduseroid) ? res.profile.aaduseroid : '',
                externalAccounts: res.profile && res.profile.role === 'Prospect' ? [] : externalAccounts,
                internalAccounts: internalAccounts,
                accountList: accountList,
                preferences: res.preferences,
                accounts: accounts,
                header: (res.template && res.template.TemplateJson) ? res.template.TemplateJson.Header : null,
                navigate: (res.template && res.template.TemplateJson) ? res.template.TemplateJson.Navigation : null,
                istempsuccess: true,
                groupedAccounts: groupedAccounts,
                showFADetails: showFADetails,
                isFontScaleRequired: isFontScaleRequired,
                isTokenRefreshed: this.props.isTokenRefreshed
            }, () => {
                //load css based 
                this.loadTheme(res);
                this.checkTermsAndConditions(this.props.token, res.contextjson, res.profilejson);
            });
        })
            .catch(err => {
                console.log(err);
                this.setState({ showerrortouser: true });
                sessionStorage.clear();
            })
    }

    //Load Theme
    loadTheme = (res) => {
        var darkCss = (process.env.NODE_ENV === 'development') ? "RCM.dark.style.css" : "RCM.dark.style.min.css";
        var lightCss = (process.env.NODE_ENV === 'development') ? "RCM.light.style.css" : "RCM.light.style.min.css";
        darkCss = `${darkCss}?ver=${relasevariables ? relasevariables.version :''}`;
        lightCss = `${lightCss}?ver=${relasevariables ? relasevariables.version : ''}`;
        let theme = sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : getNestedObject(res, ["preferences", "theme"]);
        if (!(theme !== null && theme !== undefined && theme !== "")) {
            theme = 'Dark';
            sessionStorage.setItem('theme', theme);
        } else {
            sessionStorage.setItem('theme', theme);
            let themeStyle = document.getElementById('themecss');
            themeStyle.href = theme === "Dark" ? darkCss : lightCss;
        }
    }

    //Check any terms and condition, we need to show to user after login
    checkTermsAndConditions(token, contextjson, profilejson) {
        if(this.props.showTermsAndConditions && this.props.showTermsAndConditions === "Y") {
            //Call api to fetch any terms and conditions
            checkTermsAndConditionsService(token, contextjson, profilejson)
            .then(res => {
                if(res && res.data && Array.isArray(res.data)) {
                   const eDeliveryTnc = res.data.filter(x => x.type === 'EDelivery' && x.acceptstatus === 'P')
                   if(eDeliveryTnc.length >  0) {
                       this.setState({openTermsAndConditionModal: true, termsData: eDeliveryTnc})
                   } else {
                    this.setState({openTermsAndConditionModal: false, showHomePageComponents: true})
                   }
                } else {
                    this.setState({openTermsAndConditionModal: false, showHomePageComponents: true})
                }
            })
            .catch(err => {
                _logException(err)
                this.setState({openTermsAndConditionModal: false, showHomePageComponents: true})
            })
        } else {
            this.setState({openTermsAndConditionModal: false, showHomePageComponents: true})
        }
    }

    toogleTermsAndConditionModal(loadHomeComponent) {
        this.setState((prevState) => ({...prevState, openTermsAndConditionModal: !prevState.openTermsAndConditionModal}), () => {
            if(loadHomeComponent){
                this.setState({showHomePageComponents: true})
            }
        })
    }

    checkMaintenanceWindow() {
        //Check if scheduled/unscheduled maintenance window is on or unscheduled outage is on.
        //If so, then display the message
        let currentDate = new Date(moment().tz("America/New_York"));
        let currentDateTime = moment().tz("America/New_York");
        let dayOfTheWeek = currentDateTime.day();
        let currentESTTime = currentDateTime.format("HH:mm:sss")
        var regex = new RegExp(':', 'g')
        // commenting below code since maintenancewindowjson should not be fetched from session storage
        let maintenancewindow = null;
        if (this.props.maintenancewindowjson !== "undefined" && this.props.maintenancewindowjson !== "" && this.props.maintenancewindowjson !== null) {
            maintenancewindow = this.props.maintenancewindowjson;
        }
        if (maintenancewindow !== undefined && maintenancewindow !== null && maintenancewindow !== '') {
            let sch1 = maintenancewindow.MaintanenceWindow.Schedule[0];
            let sch2 = maintenancewindow.MaintanenceWindow.Schedule[1];
            let maintenancewindowmessage = maintenancewindow.MaintanenceWindow.Message;
            let maintenancewindowmessagetype = this.getmsgTypeClass(maintenancewindow.MaintanenceWindow.MessageType, 'type');
            let maintenancewindowmessageclass = this.getmsgTypeClass(maintenancewindow.MaintanenceWindow.MessageType, 'class');

            let unschedulemaintanence = maintenancewindow.UnscheduleMaintanence;
            let unschStartDate = new Date(moment(unschedulemaintanence.StartDateTime).tz("America/New_York"));
            let unschEndDate = new Date(moment(unschedulemaintanence.EndDateTime).tz("America/New_York"));
            let unscheduleMaintenanceMessage = unschedulemaintanence.Message;
            let unscheduleMaintenanceMessagetype = this.getmsgTypeClass(unschedulemaintanence.MessageType, 'type');
            let unscheduleMaintenanceMessageclass = this.getmsgTypeClass(unschedulemaintanence.MessageType, 'class');

            let unscheduleoutage = maintenancewindow.UnscheduleOutage;
            let unschOutageStartDate = new Date(moment(unscheduleoutage.StartDateTime).tz("America/New_York"));
            let unschOutageEndDate = new Date(moment(unscheduleoutage.EndDateTime).tz("America/New_York"));
            let unscheduleoutageMessage = unscheduleoutage.Message;
            let unscheduleoutageMessagetype = this.getmsgTypeClass(unscheduleoutage.MessageType, 'type');
            let unscheduleoutageMessageclass = this.getmsgTypeClass(unscheduleoutage.MessageType, 'class');

            let messages = [];

            if (dayOfTheWeek === sch1.StartDayOfWeek && dayOfTheWeek === sch1.EndDayOfWeek) {
                if ((parseInt(currentESTTime.replace(regex, ''), 10) >= parseInt(sch1.StartTime.replace(regex, ''), 10)) &&
                    (parseInt(currentESTTime.replace(regex, ''), 10) <= parseInt(sch1.EndTime.replace(regex, ''), 10))) {
                    messages.push({
                        type: maintenancewindowmessagetype,
                        text: maintenancewindowmessage,
                        class: maintenancewindowmessageclass
                    })
                    this.setState({ messages: messages })
                }
            } else if (dayOfTheWeek === sch2.StartDayOfWeek) {
                if (parseInt(currentESTTime.replace(regex, ''), 10) >= parseInt(sch2.StartTime.replace(regex, ''), 10)) {
                    messages.push({
                        type: maintenancewindowmessagetype,
                        text: maintenancewindowmessage,
                        class: maintenancewindowmessageclass
                    })
                    this.setState({ messages: messages })
                }
            } else if (dayOfTheWeek === sch2.EndDayOfWeek) {
                if (parseInt(currentESTTime.replace(regex, ''), 10) <= parseInt(sch2.EndTime.replace(regex, ''), 10)) {
                    messages.push({
                        type: maintenancewindowmessagetype,
                        text: maintenancewindowmessage,
                        class: maintenancewindowmessageclass
                    })
                    this.setState({ messages: messages })
                }
            }
            //Unscheduled Maitenance
            if (currentDate >= unschStartDate && currentDate <= unschEndDate) {
                messages.push({
                    type: unscheduleMaintenanceMessagetype,
                    text: unscheduleMaintenanceMessage,
                    class: unscheduleMaintenanceMessageclass
                })
                this.setState({ messages: messages })
            }
            //Unscheduled Outage
            if (currentDate >= unschOutageStartDate && currentDate <= unschOutageEndDate) {
                messages.push({
                    type: unscheduleoutageMessagetype,
                    text: unscheduleoutageMessage,
                    class: unscheduleoutageMessageclass
                })
                this.setState({ messages: messages })
            }
        }
    }
    getmsgTypeClass(type, source) {
        if (source === 'type') {
            return type === 'Warning' ? MessageType.WARNING : type === 'Info' ? MessageType.INFO
                : type === 'Error' ? MessageType.ERROR : type === 'Success' ? MessageType.SUCCESS : '';
        } else {
            return type === 'Warning' ? MessageClass.WARNING : type === 'Info' ? MessageClass.INFO
                : type === 'Error' ? MessageClass.ERROR : type === 'Success' ? MessageClass.SUCCESS : '';
        }
    }
    componentDidUpdate() {
        if (this.props.isTokenRefreshed !== this.state.isTokenRefreshed) {
            this.setState({ isTokenRefreshed: this.props.isTokenRefreshed }, () => {
                this.props.UpdateStoreWithRequiredAppData(
                    {
                        accounts: this.state.accounts,
                        groupedaccounts: this.state.groupedAccounts,
                        externalAccounts: this.state.externalAccounts,
                        profileResp: this.state.profileResp,
                        portalid: this.state.portalid,
                        profileId: this.state.profileId,
                        name: this.state.name,
                        fadata: this.state.fadata,
                        token: this.props.token,
                        role: this.state.role,
                        baseurl: this.props.baseurl,
                        contextjson: this.state.contextjson,
                        profilejson: this.state.profilejson,
                        aaduseroid: this.state.aaduseroid,
                        preferences: this.state.preferences,
                        isTokenRefreshed: this.props.isTokenRefreshed,
                        user: { userName: this.props.user.userName },
                        accountList: this.state.accountList,
                        isphilanthropy: this.state.isphilanthropy
                    }
                );
            });
        }
    }
    componentDidMount() {
        this.props.AddToken(this.props.token);
        this.checkMaintenanceWindow();
        this.loadUserData();
    }

    toggleHelpAndSupportModal() {
        this.setState(prevState => ({
            openHelpAndSupportModal: !prevState.openHelpAndSupportModal
        }));
    }
    
    isRequired(source) {
        let obj = {
            preferences: this.state.preferences
        }
        let isRequired = ((source === 'PlanManager' || source === 'Services') && planManagerUrl && planManagerUrl !== null && planManagerUrl !== '')
            ? (GetPreferencePilotValue(obj, source, "false") === "true" ? true : false)
            : (source !== 'PlanManager' && source !== 'Services') ? (GetPreferencePilotValue(obj, source, "false") === "true" ? true : false)
                : false;

        return isRequired;
    }

    render() {
        if (this.state.showerrortouser) return (
            <UnEntitled user={this.props.user} logout={this.logout} toggleHelpAndSupportModal={() => this.toggleHelpAndSupportModal()} state={this.state} />
        )
        if (!this.state.isprofsuccess || !this.state.istempsuccess ) return (
            <>
                <div className="RCM_spinner">Loading RCM Client Portal<div><Spinner type="grow" color="primary" /><Spinner type="grow" color="primary" /><Spinner type="grow" color="primary" /></div></div>
                <div align="center" className="mt-1 RCM_spinner">
                    <a href="#" onClick={() => window.location.reload(true)} className="RCM_manage_grp_new_group">Click here &nbsp;</a> if your home page is not displayed immediately.
                </div>
            </>
        )

        if(this.state.openTermsAndConditionModal) {
             return (
                <TermsAndConditionsModal 
                    isOpen={this.state.openTermsAndConditionModal} 
                    toogleModal={(loadHomeComponents) => this.toogleTermsAndConditionModal(loadHomeComponents)}
                    termsData={this.state.termsData}
                    />
                )
        }

       
        let app = null;
        app = (
            <div id="page-container">
                <div className="container-fluid RCM_wrapper" key='dvContainer' id="content-wrap">
                    <div className="row " key='fontsizing'>
                        <div className="RCM_fontsizing_wrapper" key='FontSizing' id='FontSizing'>
                            <FontSizing isFontScaleRequired={this.state.isFontScaleRequired} />
                        </div>
                    </div>

                    <div className="row RCM_headerWrapper" key='row'>
                        <div className={this.state.showFADetails ? "col-xs-12 col-sm-12 col-md-4 col-lg-3 RCM_logosettingwrapper RCM_withFADetails" : "col-xs-12 col-sm-12 col-md-12 col-lg-3 RCM_logosettingwrapper"} key='dvLogo' data-intro='Rockefeller'>
                            <div><Logo /></div>
                            <div>
                                <UserAccountSetting data={this.state.name} preferences={this.state.preferences} FAdata={this.state.FAdata} />
                            </div>
                            {/*<ProfileTabs />*/}
                        </div>
                        <div className={this.state.showFADetails ? "col-xs-12 col-sm-12 col-md-12 col-lg-6 RCM_headerbalance RCM_logosettingwrapper_balances RCM_withFADetails" : "col-xs-12 col-sm-12 col-md-12 col-lg-8 RCM_headerbalance RCM_withoutFADetails"} key='dv' data-intro='Rockefeller'>
                            <Messages messages={this.state && this.state.messages} containerClass="RCM_validation_error_container" />
                            <Balancessummary rcmcpEnv={this.props.rcmcpEnv} />
                        </div>
                        {this.state.showFADetails ? <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 RCM_FA_details_wrapper" key='dvwrp'>
                            <FADetails />
                        </div> : ''}

                        <div className="RCM_header_as_of_date" key='timestamp'>
                            <div className="RCM_headerTimestamp" key='timestampval'>{getDateTimeVal(new Date())}</div>
                        </div>
                    </div>
                    <div className="row RCM_navContainer">
                        <Sticky className="RCM_sticky">
                            <header>
                                <StickyNavbar navigate={this.state.navigate} className="RCM_navbar" />
                            </header>
                        </Sticky>
                    </div>
                    <div><TabView navigate={this.state.navigate} /></div>
                    <div className="row RCM_container" key='dvNavbar'>

                        <div key='dvRoute' className='col-xs-12 col-sm-12 col-md-12 col-lg-12 '>
                            <Switch>
                                {/*<Route key='root' exact path="/" render={() => <Redirect to="/Accounts" />} />
                            <Route key='root' exact path="/" component={() => ((this.props.navigate.map(x => (x.name === 'Accounts' ? <Accounts tiles={x.components} /> : ''))))} />*/}
                                <Route key='accounts' exact path={["/", "/Accounts", "/null"]} component={() => (this.state.navigate.map(x => (x.Name === 'Accounts' ? <Accounts tiles={x.components} /> : '')))} />
                                <Route key='topten' exact path="/Toptendetail" component={() => (<Toptendetail />)} />
                                <Route key='secholdingdetails' exact path="/SecurityHoldingdetails" component={() => (<Toptendetail />)} />
                                <Route key='acctholdingdetails' exact path="/AccountHoldingdetails" component={() => (<Toptendetail />)} />
                                <Route key='assetholdingdetails' exact path="/AssetHoldingdetails" component={() => (<Toptendetail />)} />
                                <Route key='estimatedincome' exact path="/EstimatedProjectedIncomeDetails" component={(props) => <EstimatedProjectedIncomeDetails {...props} />} />
                                <Route key='estimatedincomesummary' exact path="/EstimatedIncomeSummary" component={(props) => <EstimatedProjectedIncomeDetails {...props} />} />
                                <Route key='estimatedincomesecurity' exact path="/EstimatedIncomeSecurity" component={(props) => <EstimatedProjectedIncomeDetails {...props} />} />
                                <Route key='estimatedincomeaccount' exact path="/EstimatedIncomeAccount" component={(props) => <EstimatedProjectedIncomeDetails {...props} />} />
                                <Route key='projectedincomesummary' exact path="/ProjectedIncomeSummary" component={(props) => <EstimatedProjectedIncomeDetails {...props} />} />
                                <Route key='projectedincomesecurity' exact path="/ProjectedIncomeSecurity" component={(props) => (<EstimatedProjectedIncomeDetails {...props} />)} />
                                <Route key='projectedincomeaccount' exact path="/ProjectedIncomeAccount" component={(props) => <EstimatedProjectedIncomeDetails {...props} />} />
                                <Route key='realizedgainloss' exact path="/RealizedGainLossDetails" component={() => (<RealizedGainlossDetails />)} />
                                <Route key='realizedgainlosssecurity' exact path="/RealizedSecurityHoldingdetails" component={() => (<RealizedGainlossDetails />)} />
                                <Route key='realizedgainlossaccount' exact path="/RealizedAccountHoldingdetails" component={() => (<RealizedGainlossDetails />)} />
                                <Route key='assetaggregation' exact path="/AssetAggregation" component={() => (<AssetAggregationDetails />)} />
                                <Route key='manageasset' exact path="/ManageAssets" component={() => (<ManageAssets />)} />
                                <Route key='activity' exact path="/Activitydetail" component={() => (<Activitydetail />)} />
                                <Route key='balances' exact path="/Balancesdetail" component={(props) => (<Balancesdetail {...props} preferences={this.state.preferences} />)} />
                                <Route key='aa' exact path="/Assetallocationdetail" component={(props) => (<Assetallocationdetail {...props} preferences={this.state.preferences} />)} />
                                <Route key='aahistory' exact path="/Assetallocationhistory" component={(props) => (<Assetallocationdetail {...props} preferences={this.state.preferences} />)} />
                                <Route key='balanceshistory' exact path="/Balanceshistory" component={(props) => (<Balancesdetail {...props} preferences={this.state.preferences} />)} />
                                <Route key='liabilities' exact path="/Liabilities/" render={(props) => <Liabilities {...props} />} />
                                <Route key='showalertdetails' exact path="/AlertDetails" component={() => (<AlertDetails />)} />
                                <Route key='settings' exact path="/ProfileComponent" component={() => (<ProfileComponent
                                    token={this.props.token}
                                    baseUrl={this.props.baseurl}
                                />)} />

                                <Route key='profile' exact path="/Profile" component={() => (<ProfileComponent
                                    token={this.props.token}
                                    baseUrl={this.props.baseurl}
                                />)} />
                                <Route key='viewpreference' exact path="/ViewPreferences" component={() => (<ProfileComponent
                                    token={this.props.token}
                                    baseUrl={this.props.baseurl}
                                />)} />

                                <Route key='prefernces' exact path="/EDelivery" component={() => (<ProfileComponent />)} />
                                <Route key='accountPrefernces' exact path="/AccountsNickname" component={() => (<ProfileComponent />)} />
                                <Route key='alerts' exact path="/SubscribeAlerts" component={() => (<ProfileComponent />)} />
                                {/*<Route key='feedback' exact path="/Feedback" component={() => (<FeedbackForm />)} /> */}
                                <Route key='collaboration' exact path="/Collaboration" component={() => (this.state.navigate.map(x => (x.Name === 'Collaboration' ? <TeamworkContainer /> : '')))} />
                                <Route key='transfers' exact path="/Transfer" component={() => (this.state.navigate.map(x => (x.Name === 'Transfer' ? <MoneyMovement role={this.state.role} /> : '')))} />
                                <Route key='trnsfers' exact path="/Transfers" component={() => (this.state.navigate.map(x => (x.Name === 'Transfer' ? <MoneyMovement role={this.state.role} /> : '')))} />
                                <Route key='billpay' exact path="/BillPay" component={() => (this.state.navigate.map(x => (x.Name === 'Transfer' ? <MoneyMovement role={this.state.role} /> : '')))} />
                                <Route key='billpay' exact path="/ProspectBillPay" component={() => (this.state.navigate.map(x => (x.Name === 'Transfer' ? <MoneyMovement role={this.state.role} /> : '')))} />
                                <Route key='mgextacct' exact path="/manageExtAccount" component={() => (this.state.navigate.map(x => (x.Name === 'Transfer' ? <MoneyMovement role={this.state.role} /> : '')))} />
                                <Route key='accountInfo' exact path="/AccountInfo" component={() => (this.state.navigate.map(x => (x.Name === 'Transfer' ? <MoneyMovement role={this.state.role} /> : '')))} />
                                <Route key='documents' exact path="/Statements" component={() => (this.state.navigate.map(x => (x.Name === 'Statements' ? <DocumentCenter /> : '')))} />
                                <Route key='accountstatements' exact path="/AccountStatements" component={() => (this.state.navigate.map(x => (x.Name === 'Statements' ? <DocumentCenter /> : '')))} />
                                <Route key='correspondence' exact path="/Correspondence" component={() => (this.state.navigate.map(x => (x.Name === 'Statements' ? <DocumentCenter /> : '')))} />
                                <Route key='taxdocuments' exact path="/TaxDocuments" component={() => (this.state.navigate.map(x => (x.Name === 'Statements' ? <DocumentCenter /> : '')))} />
                                <Route key='tradeconfirms' exact path="/TradeConfirms" component={() => (this.state.navigate.map(x => (x.Name === 'Statements' ? <DocumentCenter /> : '')))} />
                                <Route key='reports' exact path="/Reports" component={() => (this.state.navigate.map(x => (x.Name === 'Statements' ? <DocumentCenter /> : '')))} />
                                <Route key='help' exact path="/Help" component={() => (this.state.navigate.map(x => (x.Name === 'Help' ? <HelpandSupport /> : '')))} />
                                <Route key='casemanager' exact path="/CaseManager" component={() => (this.state.navigate.map(x => (x.Name === 'Help' ? <HelpandSupport /> : '')))} />
                                <Route key='feedback' exact path="/Feedback" component={() => (this.state.navigate.map(x => (x.Name === 'Help' ? <HelpandSupport /> : '')))} />
                                <Route key='support' exact path="/Support" component={() => (this.state.navigate.map(x => (x.Name === 'Help' ? <HelpandSupport /> : '')))} />
                                <Route key='managegroups' exact path="/ManageGroups" component={() => (<ProfileComponent />)} />
                                <Route key="lifestyles" exact path="/LifeStyle" component={() => (<Lifestyles />)} />
                                <Route key="aviation" exact path="/aviation" component={() => (<Lifestyles />)} />
                                <Route key="philanthropy" exact path="/philanthropy" component={() => (<Lifestyles />)} />
                                <Route key="health" exact path="/health" component={() => (<Lifestyles />)} />
                                <Route key="eldercare" exact path="/eldercare" component={() => (<Lifestyles />)} />
                                <Route key="art" exact path="/art" component={() => (<Lifestyles />)} />
                                <Route key="nextgen" exact path="/nextgen" component={() => (<Lifestyles />)} />
                                <Route key='performance' exact path="/Performance" component={() => (<Performance />)} />
                                <Route key='accountPerformance' exact path="/AccountPerformance" component={() => (<Performance />)} />
                                <Route key='accountPerformance' exact path="/AssetPerformance" component={() => (<Performance />)} />
                                <Route key='accountPerformance' exact path="/Securityperformance" component={() => (<Performance />)} />
                                <Route key='performanceSummary' exact path="/PerformanceSummary" component={() => (<Performance />)} />
                                <Route key="immersivex" exact path='/immersivex' component={() => <Lifestyles />} />
                                <Route key="inspirato" exact path='/inspirato' component={() => <Lifestyles />} />
                                <Route key="cyberwa" exact path='/cyberwa' component={() => <Lifestyles/>} />
                                <Route key="proofloyalty" exact path='/proofloyalty' component={() => <Lifestyles />} />
                                <Route key="globalrescue" exact path='/globalrescue' component={() => <Lifestyles />} />
                                <Route key="personalsecurity" exact path='/personalsecurity' component={() => <Lifestyles />} />
                                <Route key="virtualspecialitycare" exact path='/virtualspecialitycare' component={() => <Lifestyles />} />
                                {this.isRequired('Services') ? (
                                    <Route key="servicess" exact path="/Services" component={() => (<Services />)} />
                               
                                ) : ''}
                                {this.isRequired('PlanManager') ? (
                                    
                                <Route key="planManager" exact path="/PlanManager" component={() => (<Services />)} />
                                ) : ''}
                                
                                <Route key='default' path="*" component={({ staticContext }) => { if (staticContext) staticContext.status = 404; return <h4>Work in progress....</h4>; }} />
                            </Switch>
                        </div>
                    </div>

                </div>

                <Footer crspdf={this.props.crspdf} />
                <ClientV2Toast sneekpeekpdf={this.props.sneekpeekpdf}/>
            </div>
        );


        if (typeof window === 'undefined') {
            return (
                <StaticRouter
                    context={this.props.context}
                    location={this.props.location}
                >
                    {app}
                </StaticRouter>
            );
        }
        if(this.state.showHomePageComponents) {
          return <BrowserRouter>{app}</BrowserRouter>;
        }

        return null;
    }
}

const mapStateToProps = () => ({
})
const mapDispatchToProps = {
    UpdateStoreWithRequiredAppData, UpdateAccount, AddToken
}
const homecomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Home' })
)
export default homecomp(HomeComponent);