import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { spinner, MsgForNoData, MsgForFailedData} from './../../../../../common/constants.jsx';
import { GetSecuritySummaryColumns, GetAccountSummaryColumns } from '../projectedincomecolumns';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Tooltip } from 'reactstrap';
import EstimatedIncomeChartDetails from './../../../../../common/estimatedIncomeChartDetails';
import Messages from '../../../../../common/messages';
import RCMToggleButton from './../../../../../common/togglebutton.jsx';
import Watermark from '../../../../../common/watermark';
export function ProjectedIncomeSummaryView(
    props
) {
    let ProjectedIncomeSummaryView = (
        <div className="RCM_Toast" key="secdetails1">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            {props && props.state && props.state.messages && props.state.messages.length > 0 ?
                <div className="row RCM_AccountActivityDetails_Message_Div" >
                    <div className="col-sm-7">
                        <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                    </div>
                </div> : ''}

            <div className="row RCM_text_Header_EstimatedIncome">
                <div className="col-sm-4 RCM_yearFilter_wrapper" id="RCM_projectedincomedetailsfilter">
                    <div className="RCM_text RCM_CM_form_label RCM_text_textdropdown RCM_displayflex">
                        <label>YEAR :</label>
                   
                        <Dropdown
                            isOpen={props.state.yearDropdownOpen}
                            toggle={props.toggleYear}
                            className="RCM_select_box RCM_mm_subselect_box dropdown"
                            key="addropdown1">
                            <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                {
                                    props.state.dropdownText
                                }
                            </DropdownToggle>
                            <DropdownMenu >
                                {props.yearoptions.map((item, index) =>
                                    <DropdownItem key={index} onClick={() => props.changeYear(item)} disabled={props.state.isProspectClient}>
                                        <div>{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-2" id="RCM_toggleview">
                    <div className="RCM_expandviewrightWrapper">
                        <div>
                            <button id="TooltipToptendetailToggle" onClick={props.toggleShowTable}
                                className={(props.showTable ? 'RCM_left_toggle' : 'RCM_left_bar_toggle RCM_EstiamtedIncomeSummary_toggle_button_width')}></button>
                            <Tooltip placement="left" isOpen={props.state.tooltipOpen1} target="TooltipToptendetailToggle" toggle={props.toggle1}
                                innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                {props.showTable ? 'Toggle to Chart' : 'Toggle to Table'}
                            </Tooltip>
                            <label className="RCM_togglelabeltext" onClick={props.toggleShowTable}>{props.showTable ? 'Chart' : 'Table'}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" id="RCM_projectedincomesummaryview">
                <div className="col-sm-12 col-sm-12 col-md-12 col-xs-12 col-lg-12">

                    { props.showTable
                        ?
                        (() =>
                        {
                          return( <div>
                              <div className="row RCM_text_Header_EstimatedIncome">
                                  <div className="col-sm-1">
                                    <div className="RCM_text RCM_CM_form_label RCM_text_textdropdown RCM_Inline_Div">
                                        <label>View By :</label>
                                      </div>
                                  </div>
                                  <div className="col-sm-2" id="RCM_SecurityAccount_toggle_btn">
                                      <RCMToggleButton
                                          selectedOption={props.state.viewToggleValue ? "Security" : "Account"}
                                          leftOption="Security"
                                          rightOption="Account"
                                          onToggle={() => props.toggleView()}
                                      />
                                  </div>
                              </div>
                             
                              {
                                props.state.viewToggleValue?
                                      props.state.requestFailedSecuritySummary ?
                                          <div className="row mt-1" id="RCM_ProjectedIncomeSummaryTable">
                                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_EstimatedIncomeSummary_Wrapper">
                                                  {props.state.isEstimatedPayDate ? <span className="RCM_PaydateNote">
                                                      Estimated pay dates are shown below the amount where applicable.</span> : ''}
                                              <BootstrapTable key='btsecurity'
                                                  keyField='UniqueIdx'
                                                  data={[]}
                                                      classes="RCM_two_level_table1"
                                                      columns={GetSecuritySummaryColumns(props.securityrowsTotal, props.state.isEstimatedPayDate)}
                                                  noDataIndication={MsgForFailedData}
                                                  expandRow={props.expandRowBySecurity}
                                                  bordered={false}
                                              /></div></div>

                                          : (props.state.isloadingSecuritySummary) ?
                                          <div className="RCM_spinner">Loading Projected Income Summary by Security{spinner()}</div>
                                          :
                                          props.securityrows && props.securityrows.length > 0 && !props.state.isloading ?
                                                  <div className="row mt-1" id="RCM_ProjectedIncomeSummaryTable">
                                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_EstimatedIncomeSummary_Wrapper">
                                                          {props.state.isEstimatedPayDate ? <span className="RCM_PaydateNote">
                                                              Estimated pay dates are shown below the amount where applicable.</span> : ''}
                                                          <BootstrapTable key='btsecurity'
                                                      keyField='UniqueIdx'
                                                      data={props.securityrows}
                                                          classes="RCM_two_level_table1"
                                                          columns={GetSecuritySummaryColumns(props.securityrowsTotal, props.rowsortevent, props.state.dropdownyearValue, props.state.isEstimatedPayDate)}
                                                      noDataIndication={MsgForNoData}
                                                      expandRow={props.expandRowBySecurity}
                                                      bordered={false}
                                                  /></div></div>
                                              :
                                                  <div className="row mt-1" id="RCM_ProjectedIncomeSummaryTable">
                                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_EstimatedIncomeSummary_Wrapper">
                                                          {props.state.isEstimatedPayDate ? <span className="RCM_PaydateNote">
                                                              Estimated pay dates are shown below the amount where applicable.</span> : ''}
                                                          <BootstrapTable key='btsecurity'
                                                      keyField='UniqueIdx'
                                                      data={props.securityrows}
                                                      classes="RCM_two_level_table1"
                                                          columns={GetSecuritySummaryColumns(props.securityrowsTotal, props.rowsortevent, props.state.dropdownyearValue, props.state.isEstimatedPayDate)}
                                                      noDataIndication={MsgForNoData}
                                                      expandRow={props.expandRowBySecurity}
                                                      bordered={false}
                                                  /></div></div>
                                      :
                                      props.state.requestFailedAccountSummary ?
                                          <div className="row mt-2" id="RCM_ProjectedIncomeSummaryTable">
                                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_EstimatedIncomeSummary_Wrapper">
                                                  <BootstrapTable key='btsecurity'
                                                      keyField='UniqueIdx'
                                                      data={[]}
                                                      classes="RCM_two_level_table1"
                                                      columns={GetAccountSummaryColumns(props.acountrowsTotal, props.accounts, props.rowsortevent, props.state.dropdownyearValue)}
                                                      noDataIndication={MsgForFailedData}
                                                      expandRow={props.expandRowBySecurity}
                                                      bordered={false}
                                                  /></div></div>

                                          : (props.state.isloadingAccountSummary) ?
                                              <div className="RCM_spinner">Loading Projected Income Summary by Account{spinner()}</div>
                                              :
                                              props.accountrows && props.accountrows.length > 0 && !props.state.isloading ?
                                                  <div className="row mt-2" id="RCM_ProjectedIncomeSummaryTable">
                                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_EstimatedIncomeSummary_Wrapper"><BootstrapTable key='btsecurity'
                                                          keyField='UniqueIdx'
                                                          data={props.accountrows}
                                                          classes="RCM_two_level_table1"
                                                          columns={GetAccountSummaryColumns(props.acountrowsTotal, props.accounts, props.rowsortevent, props.state.dropdownyearValue)}
                                                          noDataIndication={MsgForNoData}
                                                          expandRow={props.expandRowBySecurity}
                                                          bordered={false}
                                                      /></div></div>
                                                  :
                                                  <div className="row mt-2" id="RCM_ProjectedIncomeSummaryTable">
                                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Tables RCM_EstimatedIncomeSummary_Wrapper"> <BootstrapTable key='btsecurity'
                                                          keyField='UniqueIdx'
                                                          data={props.accountrows}
                                                          classes="RCM_two_level_table1"
                                                          columns={GetAccountSummaryColumns(props.acountrowsTotal, props.accounts, props.rowsortevent, props.state.dropdownyearValue)}
                                                          noDataIndication={MsgForNoData}
                                                          expandRow={props.expandRowBySecurity}
                                                          bordered={false}
                                                      /></div></div>}
                            </div>)
                        })() :
                        (props.state.isloading) ?
                            <div className="RCM_spinner">Loading Projected Income Summary Chart{spinner()}
                            </div> :
                            (props.state.requestFailed) ?
                              
                                <div>{MsgForFailedData} </div> :
                            <EstimatedIncomeChartDetails datachart={props.chartrows} chartTotal={props.chartTotal} source="Projected Income" />
                    }
                </div>
                </div>
        </div>
    )
    return ProjectedIncomeSummaryView;
}
