import { ADD_EXTERNAL_ACCT, FETCH_EXTERNAL_ACCT, REMOVE_RECENTEXTACCT } from  "../actions/actiontypes";


let initialState = {
    externalAcctList : [],
    recentExternalAcctId: ""
}

export function ManageExternalAccountReducer(state = initialState, action)
{

    switch(action.type)
    {
        case FETCH_EXTERNAL_ACCT:
        {
            return{
                ...state,
                externalAcctList: action.payload
            }
        }

        case ADD_EXTERNAL_ACCT:
        {
            return{
                ...state,
                externalAcctList: action.payload.list,
                recentExternalAcctId: action.payload.recentExtAcctId
            }
        }

        case REMOVE_RECENTEXTACCT:
        {
            return{
                ...state,
                recentExternalAcctId: ""
            }
        }

        default :
        return state;
    }
}