import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Disclaimer from './disclaimer';
import Messages from '../../../common/messages.jsx';
import { MsgForNoData, MessageClass, getAccountToolTip, spinner, IndLongShort} from '../../../common/constants.jsx';
import { ConfirmEmailChangePopup } from './confirmemailchangepopup.jsx';

export function PreferenceView(props) {
   
    let preferenceView = (
        <div className="RCM_edelvery_container" key="edelivery02">
           
           
                    {
                        (props && props.state && props.state.isPreferencesUpdated)
                            ? props && props.state && props.state.messages && props.state.messages.length > 0
                        ? <div className="row RCM_Feedback_Message_Margin" key="edelivery03">
                            <div className="col-sm-12 col-xs-12 col-md-11 col-lg-11" key="edelivery04">
                                <Messages messages={props && props.state && props.state.messages}
                                    containerClass={
                                        props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                            ? "RCM_validation_error_container"
                                            : ""
                                    } />
                            </div>

                        </div>
                                : ""
                            : ""
                    }

               
            <div className="row" key="edelivery04">
                <div className="col-sm-12 " key="edelivery05">
                    <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key="edelivery06">E-DELIVERY</label>
                </div>

            </div>
            {
                props && props.state && props.state.isEdeliveryLoading 
                    ? !props.state.isPreferencesUpdated
                        ? <div className="RCM_spinner" key="edelivery07">Loading E-Delivery{spinner()}</div>
                        : <div className="RCM_spinner" key="edelivery08">Updating E-Delivery{spinner()}</div>
                    :
                    <div className="RCM_Toast RCM_pref_toast_wrapper" key="edelivery09">
                        <div className="row" id="RCM_Edelivery_email" key="edelivery10">
                            <div className="col-sm-12 RCM_Edelivery_email_wrapper" key="edelivery11">
                                <div className="" key="edelivery12">
                                    <span className="labelSpan" key="edelivery13"><label className="RCM_label profile-font-color" key="edelivery14">E-Delivery Email Address</label></span><br />
                                    <span className="labelSpan RCM_label_note" key="edelivery15"><label className="RCM_label profile-font-color" key="edelivery16">*Email where all the documents will be sent</label></span>
                            </div>
                                <div className="RCM_Manage_email_wrapper" align="left" key="edelivery17">
                                    <div className="RCM_form_control_group" key="edelivery18">

                                        <Dropdown className="RCM_form_control RCM_select_box RCM_mm_select_box RCM_edelivery_dropdown" id="RCM_Edelivery_email_dropdown" key="edelivery19" isOpen={props.state.dropdownOpen} toggle={props.toggleDropdown}>
                                            <DropdownToggle caret key="edelivery20">
                                            {props.state.editEmail ? props.state.editEmail.toUpperCase() : ''}
                                        </DropdownToggle>
                                            <DropdownMenu key="edelivery21">
                                            {
                                                    <div key="edelivery22">
                                                    {props.state.emailList && props.state.emailList !== null && props.state.emailList.length > 0 && props.state.emailList.map((item, index) =>
                                                        <DropdownItem key={`edelivery23-${index}`} onClick={() => props.changeEmailDropDownValue(item.emailid ? item.emailid.toUpperCase() : '')} >
                                                            <div key="edelivery24">{item.emailid ? item.emailid.toUpperCase() : ''}</div>
                                                        </DropdownItem>
                                                    )}
                                                </div>
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                    {/* <div className="RCM_edelivery_add_edit_email_btn_wrapper" id="RCM_Edelivery_manage_email" key="edelivery25">
                                        <label className="RCM_label profile-font-color RCM_icon_hand" onClick={() => props.toggelAddModel()} key="edelivery26">Manage Email</label>
                                </div>*/}
                                </div>
                            </div>

                        </div>
                        <div className="row RCM_edelivery_table_text_wrapper" key="edelivery27">
                            <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8 RCM_label_note" key="edelivery28">
                                <i key="edelivery29">Your documents will always be available online, irrespective of the e-delivery options selected.</i>
                            </div>
                            {props.state.accountList.length !== 0 ?
                      
                                
                                <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4 RCM_edelivery_link_wrapper" id="RCM_edelivery_selectAll" key="edelivery30">
                                    <label key="edelivery31" className={"RCM_label profile-font-color RCM_icon_hand " + (props.state.isProspectClient ?'RCM_Prospect_Disabled' :"")} onClick={() => props.handleSelectAllDocuments()}>
                                        {props.state.selectAllDocuments ? 'Clear all Accounts' : 'Select all Accounts'}
                                    </label>
                                </div>

                                : ''
                            }
                        </div>
                        <div className="row" id="RCM_Edelivery_table" key="edelivery32">
                            <div className="col-sm-12" key="edelivery33">
                                <div className="react-bootstrap-table" key="edelivery34">
                                    <table className="table RCM_two_level_table1" key="edelivery35">
                                        <thead key="edelivery36">
                                            <tr key="edelivery37">
                                                <th onClick={() => props.onSort('sortOrderAccount', "displayName")} key="edelivery38">Account<span className={props.getSortClassName(props.state.sortOrderAccount)} key="edelivery39"></span></th>
                                                <th onClick={() => props.onSort('sortOrderEnrolled', "enrolled")} key="edelivery40">Enrolled<span className={props.getSortClassName(props.state.sortOrderEnrolled)} key="edelivery41"></span></th>
                                                <th key="edelivery42">
                                                    {props.state.accountList.length !== 0 ?
                                                        <span className="radio" key="edelivery43">
                                                            <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key="edelivery44">
                                                                <input type="checkbox" value="ed"
                                                                    checked={props.state.confirmsProspectusesSelectAll}
                                                                    onChange={() => props.handleSelectAllColumnDocuments("confirmsProspectuses")}
                                                                    disabled={props.state.isProspectClient}
                                                                    key="edelivery45"
                                                                />
                                                                <span className="profile-font-color RCM_preference_label" key="edelivery46">Confirms/Confirming Prospectuses</span>
                                                            </label>
                                                        </span>
                                                        : <span key="edelivery47">Confirms/Confirming Prospectuses</span>
                                                    }
                                                </th>
                                                <th key="edelivery48">
                                                    {props.state.accountList.length !== 0 ?
                                                        <span className="radio" key="edelivery49">
                                                            <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key="edelivery50">
                                                                <input type="checkbox" value="ed"
                                                                    checked={props.state.statementsAndRegulatoryInsertsSelectAll}
                                                                    onChange={() => props.handleSelectAllColumnDocuments("statementsAndRegulatoryInserts")}
                                                                    disabled={props.state.isProspectClient}
                                                                    key="edelivery51"
                                                                />
                                                                <span className="profile-font-color RCM_preference_label" key="edelivery52">Statements and Regulatory Inserts</span>
                                                            </label>
                                                        </span>
                                                        : <span key="edelivery53">Statements and Regulatory Inserts</span>
                                                    }
                                                </th>
                                                <th key="edelivery54">
                                                    {props.state.accountList.length !== 0 ?
                                                        <span className="radio" key="edelivery55">
                                                            <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key="edelivery56">
                                                            <input type="checkbox" value="ed"
                                                                checked={props.state.eligibleCustomerCorrespondenceSelectAll}
                                                                    onChange={() => props.handleSelectAllColumnDocuments("eligibleCustomerCorrespondence")}
                                                                    disabled={props.state.isProspectClient}
                                                                    key="edelivery57"
                                                            />
                                                                <span className="profile-font-color RCM_preference_label" key="edelivery58">Eligible Customer Correspondence</span>
                                                        </label>
                                                        </span>
                                                        : <span key="edelivery59">Eligible Customer Correspondence</span>
                                                    }
                                                </th>
                                                <th key="edelivery60">
                                                    {props.state.accountList.length !== 0 ?
                                                        <span className="radio" key="edelivery61">
                                                            <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key="edelivery62">
                                                            <input type="checkbox" value="ed"
                                                                checked={props.state.taxFormsAndRelatedDocumentsSelectAll}
                                                                    onChange={() => props.handleSelectAllColumnDocuments("taxFormsAndRelatedDocuments")}
                                                                    disabled={props.state.isProspectClient}
                                                                    key="edelivery63"
                                                            />
                                                                <span className="profile-font-color RCM_preference_label" key="edelivery64">Tax Forms and Related Documents</span>
                                                        </label>
                                                        </span>
                                                        : <span key="edelivery65">Tax Forms and Related Documents</span>
                                                    }
                                                </th>
                                                <th key="edelivery66">
                                                    {props.state.accountList.length !== 0 ?
                                                        <span className="radio" key="edelivery67">
                                                            <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key="edelivery68">
                                                                <input type="checkbox" value="ed"
                                                                    checked={props.state.shareholderReportsAndOtherDocumentsSelectAll}
                                                                    onChange={() => props.handleSelectAllColumnDocuments("shareholderReportsAndOtherDocuments")}
                                                                    disabled={props.state.isProspectClient}
                                                                    key="edelivery69"
                                                                />
                                                                <span className="profile-font-color RCM_preference_label" key="edelivery70">Shareholder Reports & Other Documents</span>
                                                            </label>
                                                        </span>
                                                        : <span key="edelivery70">Shareholder Reports & Other Documents</span>
                                                    }
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody key="edelivery71">{
                                            props.state.accountList && props.state.accountList.length === 0 ? <div className="RCM_no_data_msg_wrapper" key="edelivery72">{MsgForNoData}</div>:
                                            props.state.accountList && props.state.accountList.map((account, index) => {
                                                return (
                                                    <tr key={"edeliverytr73" + index}>
                                                        <td title={getAccountToolTip(account, IndLongShort.LONG)} key={"edelivery73" + index +"01"}>{account.isedeliveryupdated === 'false'
                                                            ? <span className="RCM_error_icon" key={"edelivery73" + index + "02"}>&#9888;&nbsp;</span> : ''}{account.displayName}</td>
                                                        <td key={"edelivery73" + index + "03"}>{account.enrolled}</td>
                                                        <td key={"edelivery73" + index + "04"}>
                                                            {(account.enrolled === "Pending" || account.enrolled === "Not Available" || account.enrolled === 'Not Account Holder' || account.enrolled === 'No email on File') ?
                                                                <span key={"edelivery73" + index + "05"} className="RCM_Edelivery_noData_cell">NA</span> :
                                                            <span className="radio" key={"edelivery73" + index + "06"}>
                                                                <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key={"edelivery73" + index + "07"}>
                                                                    <input type="checkbox" value="ed"
                                                                        checked={account.confirmsProspectuses}
                                                                        onChange={() => props.handleConfirmsProspectus(index)}
                                                                        disabled={props.state.isProspectClient}
                                                                        key={"edelivery73" + index + "08"}
                                                                    />
                                                                    <span className="profile-font-color RCM_preference_label" key={"edelivery73" + index + "09"}></span>
                                                                </label>
                                                            </span>
                                                        }
                                                        </td>
                                                        <td key={"edelivery73" + index + "10"}>
                                                            {(account.enrolled === "Pending" || account.enrolled === "Not Available" || account.enrolled === 'Not Account Holder' || account.enrolled === 'No email on File') ?
                                                                <span key={"edelivery73" + index + "11"} className="RCM_Edelivery_noData_cell">NA</span> :
                                                            <span className="radio" key={"edelivery73" + index + "12"}>
                                                                <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key={"edelivery73" + index + "13"}>
                                                                    <input type="checkbox" value="ed"
                                                                        checked={account.statementsAndRegulatoryInserts}
                                                                        onChange={() => props.handleStatementsAndRegulatory(index)}
                                                                        disabled={props.state.isProspectClient}
                                                                        key={"edelivery73" + index + "14"}
                                                                    />
                                                                    <span className="profile-font-color RCM_preference_label" key={"edelivery73" + index + "15"}></span>
                                                                </label>
                                                            </span>
                                                        }
                                                        </td>
                                                        <td key={"edelivery73" + index + "16"}>
                                                            {(account.enrolled === "Pending" || account.enrolled === "Not Available" || account.enrolled === 'Not Account Holder' || account.enrolled === 'No email on File') ?
                                                                <span key={"edelivery73" + index + "17"} className="RCM_Edelivery_noData_cell">NA</span> :
                                                            <span className="radio" key={"edelivery73" + index + "18"}>
                                                                <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key={"edelivery73" + index + "19"}>
                                                                    <input type="checkbox" value="ed"
                                                                        checked={account.eligibleCustomerCorrespondence}
                                                                        onChange={() => props.handleEligibleCustomerCorrespondence(index)}
                                                                        disabled={props.state.isProspectClient}
                                                                        key={"edelivery73" + index + "20"}
                                                                    />
                                                                    <span className="profile-font-color RCM_preference_label" key={"edelivery73" + index + "21"}></span>
                                                                </label>
                                                            </span>
                                                        }
                                                        </td>

                                                        <td key={"edelivery73" + index + "22"}>
                                                            {(account.enrolled === "Pending" || account.enrolled === "Not Available" || account.enrolled === 'Not Account Holder' || account.enrolled === 'No email on File') ?
                                                                <span key={"edelivery73" + index + "23"} className="RCM_Edelivery_noData_cell">NA</span> :
                                                            <span className="radio" key={"edelivery73" + index + "24"}>
                                                                <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key={"edelivery73" + index + "25"}>
                                                                    <input type="checkbox" value="ed"
                                                                        checked={account.taxFormsAndRelatedDocuments}
                                                                        onChange={() => props.handleTaxFormRelated(index)}
                                                                        disabled={props.state.isProspectClient}
                                                                        key={"edelivery73" + index + "26"}
                                                                    />
                                                                    <span className="profile-font-color RCM_preference_label" key={"edelivery73" + index + "27"}></span>
                                                                </label>
                                                            </span>
                                                        }
                                                        </td>
                                                        <td key={"edelivery73" + index + "28"}>
                                                            {(account.enrolled === "Pending" || account.enrolled === "Not Available" || account.enrolled === 'Not Account Holder' || account.enrolled === 'No email on File') ?
                                                            <span key={"edelivery73" + index + "29"} className="RCM_Edelivery_noData_cell">NA</span> :
                                                            <span className="radio" key={"edelivery73" + index + "30"}>
                                                                <label className={"RCM_selectLabel " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")} key={"edelivery73" + index + "31"}>
                                                                    <input type="checkbox" value="ed"
                                                                        checked={account.shareholderReportsAndOtherDocuments}
                                                                        onChange={() => props.handleShareHolderReport(index)}
                                                                        disabled={props.state.isProspectClient}
                                                                        key={"edelivery73" + index + "32"}
                                                                    />
                                                                    <span className="profile-font-color RCM_preference_label" key={"edelivery73" + index + "33"}></span>
                                                                </label>
                                                            </span>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2" key="edelivery74">
                            { !sessionStorage.getItem("simulation") && ( 
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_edelivery_save_btn" key="edelivery75">
                                    <input key="edelivery76" className="btn RCM_button_primary RCM_select_btn RCM_prefsave_btn" type="submit" value="SAVE" onClick={props.toggleDesModel} disabled={props.state.isProspectClient || !props.state.disabled} />
                                </div>
                            )}
                        </div>
                        <div>
                            <a href={props.eDeliveryDisclaimerPdf}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="RCM_link RCM_our_ideas"
                                >
                                    Click here to print or download a copy of the edelivery agreement
                            </a>
                        </div>    
                    </div>
            }
            <Disclaimer key="edelivery77" 
                isOpen={props.state.des_modal} 
                toggleDesModel={props.toggleDesModel} 
                clickOnAgree={props.clickOnAgree} 
                updatedAccounts={props.state.updatedAccounts} 
                eDeliveryDisclaimerPdf={props.eDeliveryDisclaimerPdf}/>

            <ConfirmEmailChangePopup key="edeliveryEmail77"
                isOpen={props.state.isConfirmEmailChangePopupOpen}
                toggleConfirmEmailChangePopup={props.toggleConfirmEmailChangePopup}
                changeEmail={props.changeEmail}
            />

            <Modal isOpen={props.state.addModal} toggle={props.toggelAddModel} backdrop={false} key="edelivery78"
                zIndex='9999'
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                centered>
                <ModalHeader toggle={props.toggelAddModel} charCode="X" key="edelivery79">
                    <label className="RCM_tile_heading" key="edelivery80">MANAGE EMAIL</label>
                </ModalHeader>
                <ModalBody>
                    {
                        props.state.emailError === "true"
                            ? <div className="RCM_validation_error_container RCM_validation_error_edelivery_popup" key="edelivery103">
                                <div className="RCM_validation_error_text" key="edelivery104">
                                    <div className="RCM_error_img" key="edelivery105"></div>
                                    <div className="RCM_msg_text" key="edelivery106">{props.state.emailErrorMsg}</div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        (props && props.state && props.state.messages && props.state.messages.length > 0
                                ? <div className="row RCM_Feedback_Message_Margin" key="edelivery03">
                                    <div className="col-sm-12 col-xs-12 col-md-11 col-lg-11" key="edelivery04">
                                        <Messages messages={props && props.state && props.state.messages}
                                            containerClass={
                                                props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                                    ? "RCM_validation_error_container"
                                                    : ""
                                            } />
                                    </div>

                                </div>
                                : "")
                    }
                    <div className="row RCM_email_table_wrapper" key="edelivery81">
                        <div className="col-sm-12" key="edelivery82">
                            <div className=" RCM_eDelivery_email_table" key="edelivery83">
                                <table className="table RCM_two_level_table1" key="edelivery84">
                                    <thead key="edelivery85">
                                        <tr key="edelivery86">
                                            <th key="edelivery87">Email</th>
                                            <th key="edelivery88">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody key="edelivery89">{
                                        props.state.emailList && props.state.emailList !== null && props.state.emailList.length > 0 && props.state.emailList.map((item, index) => {
                                            return (
                                                <tr key={"edelivery90" + index + "01"}>
                                                    <td key={"edelivery90" + index + "02"}>{item.emailid}</td>
                                                    {!item.isdeletable
                                                        ? <td className="RCM_manage_groups_delete_col RCM_common_table_column_number RCM_disabled_remove_icon_wrapper" key={"edelivery90" + index + "03"}>
                                                            <div className="RCM_manage_grp_delete_icon RCM_manageasset_icon_position RCM_disabled_remove_icon" key={"edelivery90" + index + "04"}></div>
                                                        </td>
                                                        : <td className="RCM_manage_groups_delete_col RCM_common_table_column_number" key={"edelivery90" + index + "05"}>
                                                            <div className={"RCM_manage_grp_delete_icon RCM_manageasset_icon_position " + (props.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                                                                title="Remove email" onClick={() => props.handleRemoveEmail(index, item.emailid)} key={"edelivery90" + index + "06"}></div>
                                                        </td>
                                                    }
                                                    </tr>)
                                        })
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row" key="edelivery91">
                        <div className="col-sm-12" key="edelivery92">
                            <p key="edelivery93">Enter your email address below. This email address will be added to options for electronic delivery.</p>
                        </div>
                    </div>
                    <div className="row" key="edelivery94">
                        <div className="col-sm-6" key="edelivery95">
                            <label className="RCM_form_control_placeholder" key="edelivery96">ENTER EMAIL ADDRESS</label>
                            <div className="RCM_form_control_group" key="edelivery97">
                                <input
                                    type="text"
                                    name="newEmail"
                                    className="RCM_form_control"
                                    value={props.state.newEmail}
                                    onChange={props.handleEmailOnChange}
                                    onBlur={props.handleEmailOnBlur}
                                    disabled={props.state.isProspectClient}
                                    key="edelivery98"
                                />
                            </div>
                        </div>
                        <div className="col-sm-6" key="edelivery95">
                            <div className="RCM_edelivery_add_email_btn_wrapper RCM_link_wrapper" id="RCM_Edelivery_manage_email" key="edelivery25">
                                <label className={"RCM_label profile-font-color RCM_icon_hand " + (props.state.isProspectClient || props.state.disableAddEmail ? 'RCM_disabled_link' : '')}
                                    onClick={() => props.addEmail()} key="edelivery26">Add Email</label>
                            </div>
                        </div>

                    </div>
                    
                    <div className="row RCM_Save_email_btn_wrapper" key="edelivery99">
                        <div className="col-sm-12 RCM_edelivery_save_btn" key="edelivery100">
                            <input className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size" type="reset" value="CLOSE" id="emailCancel" onClick={props.toggelAddModel} key="edelivery101"/>
                        
                           
                        </div>
                    </div>
                    
                   
                    <br key="edelivery109"/>
                </ModalBody>
                
            </Modal>
        </div>

    )
    return preferenceView;

}