import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import AccountStatements from './accountstatement/accountstatements.jsx'
import TradeConfirms from './tradeconfirm/tradeconfirms.jsx';
import Correspondence from './correspondence/correspondence.jsx'
import TaxDocuments from './taxdocument/taxdocuments.jsx'
import ErrorBoundary from '../../common/errorboundary.jsx';
import { tabSelection, tabSelectionOnLoad, GetPreferencePilotValue, GetPreferenceValue } from './../../common/constants.jsx';
import { _logPageView } from "../../common/_logging.js";
import { compose } from 'redux';
import { withPageView } from '../../hoc/withPageView.jsx';
import { connect } from 'react-redux';
import AzureReports from './azureReports/reports';
import Reports from './reports/reports';

class DocumentCenter extends Component {

    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);

        this.state = {
            tabsLinks: this.getTabsLinks(),
            preferences: this.props.preferences,
            isCustomView: this.getPreferenceValue(),
            
        };
    }

    getReportsPilotFlagValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isReportPreferenceRequired = GetPreferencePilotValue(obj, "Reports", "false") === "true" ? true : false;
        return isReportPreferenceRequired;
    }
    getNewReportsPilotFlagValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isNewReportRequired = GetPreferencePilotValue(obj, "NewReports", "false") === "true" ? true : false;
        return isNewReportRequired;
    }
    getTabsLinks() {

        let defaultView = [
          { tabUrl: '/AccountStatements', active: true, displayName: 'STATEMENTS', id: 'RCM_AccountStatementstab' },
                { tabUrl: '/Correspondence', active: false, displayName: 'CORRESPONDENCE', id: 'RCM_Correspondencetab' },
            { tabUrl: '/TaxDocuments', active: false, displayName: 'TAX DOCUMENTS', id: 'RCM_TaxDocumentstab' },
            { tabUrl: '/TradeConfirms', active: false, displayName: 'TRADE CONFIRMS', id: 'RCM_TradeConfirmstab' },
        ];

        if (this.getReportsPilotFlagValue()) {
            defaultView.push({ tabUrl: '/Reports', active: false, displayName: 'REPORTS', id: 'RCM_Reportstab' });
        }

        return defaultView;
    }
    handleSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }
    componentDidMount() {
        //Put Page view
        /*  let pageViewData = new PageView();
         pageViewData.name = "Statements and Documents";
         pageViewData.url = "/Statements";
         _logPageView(pageViewData); */
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/AccountStatements', '/Statements');
        this.setState({ tabsLinks: tabsLinks });
    }
    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }

    componentDidUpdate() {
        if (this.props.preferences && this.state.preferences) {
            if (this.props.preferences !== this.state.preferences) {
                let iscustomview = this.getPreferenceValue();
                this.setState({ isCustomView: iscustomview, preferences: this.props.preferences });
            }
        }


    }

    render() {
        const document = (
            <div key="dvDocument">
                <div className={this.state.isCustomView ? "RCM_Tab_container" : "RCM_NavTab_container"} >
                    <ul className="nav nav-tabs">
                        {this.state.tabsLinks && this.state.tabsLinks.map((tabLink) => {
                            return <li className="nav-item active" id={tabLink.id}>
                                <NavLink className="nav-link" exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                    onClick={this.handleSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                            </li>
                        })}
                    </ul>
                </div>
                <div>
                    <Switch>
                        <Route key='root' exact path="/Statements" render={() => <Redirect to="/AccountStatements" />} />
                        <Route key='accountstatements' exact path="/AccountStatements" component={AccountStatements} />
                        <Route key='correspondence' exact path='/Correspondence' component={Correspondence} />
                        <Route key='tradeconfirms' exact path='/TradeConfirms' component={TradeConfirms} />
                        <Route key='taxdocuments' exact path='/TaxDocuments' component={TaxDocuments} />
                        <Route key='reports' exact path='/Reports' component={this.getNewReportsPilotFlagValue() ? Reports : AzureReports} />
                    </Switch>
                </div>
            </div>
        );
        return (
            <div>
                <ErrorBoundary>
                    <BrowserRouter>{document}</ BrowserRouter>
                </ErrorBoundary>
            </div>
        );
    }
}

const enhancement = compose(
    withPageView({ pageName: 'Statements and Documents' })
)
const mapStateToProps = (store) => {
    return {
        preferences: store.acct.preferences
    };
}

//export default withRouter(DocumentCenter);

export default enhancement(withRouter(connect(mapStateToProps)(DocumentCenter)));