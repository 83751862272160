import React from "react";
import BootstrapTable from 'react-bootstrap-table-next'
import { MsgForNoData, formatMoneyForPE, getDateString } from './../../../../../common/constants'
export const defaultSorted = [{ dataField: 'valueasof', order: 'desc' }];
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
export function HistoryManualAsset (props){
   
    let columns = [];
   
        columns.push({
            dataField: 'valueasof', text: "Value as of", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return <span>{getDateString(cell)}</span>;
            },
        })
    columns.push({
        dataField: 'totalvalue', text: "Value", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: 'right', classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else return <span>{formatMoneyForPE(cell)}</span>;
        },
    })
        columns.push({
            dataField: 'type', text: "Type",
            sort: true, sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return <span>{cell}</span>;
            },
        })
        columns.push({
            dataField: 'sourceofvaluation', text: "Source of Valuation", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return <span>{cell}</span>;
            },
        })
        if (props.source === 'hedgefunds')
        columns.push({
            dataField: 'pricecontact', text: "Pricing Contact",
            sort: true, sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return <span>{cell}</span>;
            },
        })
        columns.push({
            dataField: '', text: "",
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return (
                    <div
                        className={(row.type === 'initial') || (props.canedit !== undefined && props.canedit !== null && props.canedit === 'N') ? "RCM_manage_grp_delete_icon RCM_Prospect_Disabled" : "RCM_manage_grp_delete_icon"}
                        title="Remove Value"
                        onClick={() => props.deleteHistory(row.valueasof)}
                    >
                </div >)
            },
        })

                

        return (
            <div>
                <BootstrapTable
                    keyField='id'
                    data={props && props.data && props.data !== null ? props.data:[]}
                    columns={columns}
                    headerClasses="row-bg"
                    bordered={false}
                    classes="RCM_two_level_table1"
                    noDataIndication={MsgForNoData}
                    defaultSorted={defaultSorted}
                />
            </div>
        );

    
}