import { ApplicationInsights } from  '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";


export class TelemetryService {
    constructor() {
        const instance = this.constructor.instance;
        if (instance) {
            return instance;
        }
        
        let sessionInstrumentationKey =   sessionStorage.getItem('instrumentationKey');
         if(sessionInstrumentationKey){
           this.instrumentationKey = sessionInstrumentationKey;
         }
        const browserHistory = createBrowserHistory({ basename: '' });
        this.reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: true,
                disableAjaxTracking: false,
                isStorageUseDisabled: true,
                disableCorrelationHeaders: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
            },
            extensions: [this.reactPlugin],
            extensionConfig: {
                [this.reactPlugin.identifier]: { history: browserHistory }
            }
        });
        this.appInsights.loadAppInsights();
        this.constructor.instance = this;
    }

}
//export let ai = new TelemetryService();