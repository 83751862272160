import download from 'downloadjs'
export function downloadDocument(data, documenttype, fileName, role) {   
    if (data) {
        var base64str = data;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        if (documenttype === 'csv') {
            // create the blob object with content-type "application/pdf"
            if (role === 'Prospect') {
                var blob = new Blob([view], { type: "text/xlsx" });
                fileName = fileName.split('/').join('') + '.xlsx';
                download(blob, fileName, "text/xlsx");
            } else {
                var blob = new Blob([view], { type: "text/csv" });
                fileName = fileName.split('/').join('') + '.csv';
                download(blob, fileName, "text/csv");
            }            
            return 'csv downloaded'

        } else {
            // create the blob object with content-type "application/pdf"
            var blob = new Blob([view], { type: "application/pdf" });
            
            fileName = fileName.split('/').join('') + '.pdf'
            download(blob, fileName, "application/pdf");
            return 'pdf downloaded'
        }        
        
    } else {
        console.log("didn't receive document from webapi")
        return null
    }
}