import DateRanges from "../../../../common/dateranges.jsx";
import groupBy from "lodash.groupby";
import uniq from "lodash.uniq";
import {
    getAccountDisplayName,
    IndLongShort
} from "../../../../common/constants";

export const formatMoney = (value, decimals = 2) =>
    value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });

export const generateAccountKey = accounts => {
    return (accounts || []).reduce((a, x) => {
        return a + (x || {}).key || "";
    }, "");
};

export const getDateRange = filter => {
    if (filter.range.value === DateRanges.CUSTOMRANGE.value) {
        return {
            fromDate: filter.fromDate,
            toDate: filter.toDate
        };
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = 1;

    let toDate = new Date(year, month, day);
    let fromDate = new Date(year, month, day);

    toDate.setMonth(month + 1);

    switch (filter.range.value) {
        default:
        case DateRanges.PRIOR6MONTHS.value:
            fromDate.setMonth(month - 6);
            break;
        case DateRanges.PRIOR12MONTHS.value:
            fromDate.setMonth(month - 12);
            break;
        case DateRanges.CURRENTYEAR.value:
            fromDate.setMonth(0);
            break;
        case DateRanges.PRIORYEAR.value:
            fromDate.setFullYear(year - 1);
            fromDate.setMonth(0);
            toDate.setFullYear(year - 1);
            toDate.setMonth(11);
            toDate.setDate(31);
            break;
    }

    return {
        fromDate,
        toDate
    };
};

export const showHistoricalAssetAllocation = pilotfeatures => {
    let res = false;
    pilotfeatures = pilotfeatures || [];

    if (Array.isArray(pilotfeatures)) {
        for (let i = 0; i < pilotfeatures.length; i++) {
            let comp = pilotfeatures[i];
            if (
                comp.componentname &&
                comp.componentname === "Historical Asset Allocation" &&
                comp.active &&
                comp.active === "true"
            ) {
                res = true;
                break;
            }
        }
    }

    return res;
};

export const getAssetGroups = (mappedData, accounts) => {
    const groupedByAsset = groupBy(mappedData, item => item.assetType);
    const rows1 = [].concat(
        ...Object.keys(groupedByAsset)
            .map(assetType => {
                const assetItems = groupedByAsset[assetType];
                const groupedByAccount = groupBy(
                    assetItems,
                    assetItem => assetItem.accountNumber
                );
                return Object.keys(groupedByAccount).map(accountNumber => {
                    const accountItems = groupedByAccount[accountNumber];
                    return {
                        key: `${assetType}${accountNumber}`,
                        assetType,
                        accountNumber: accountItems && accountItems[0] ? accountItems[0].accountNumber : '',
                        assetcatcd: accountItems && accountItems[0] ? accountItems[0].assetcatcd : '',
                        accountnickname: getAccountDisplayName(accounts, accountItems && accountItems[0] ? accountItems[0].accountNumber : '', IndLongShort.SINGLEVIEW),
                        ...accountItems.reduce((a, assetItem) => {
                            const marketValue = parseFloat(assetItem.marketValue || 0);
                            const existingValue = a[assetItem.time];
                            // TODO - figure out why there's dupe entries for the same thing
                            if (existingValue) {
                                a[assetItem.time] = existingValue + marketValue;
                            } else {
                                a[assetItem.time] = marketValue;
                            }
                            return a;
                        }, {})
                    };
                });
            })
            .sort((a, b) => (a.assetType > b.assetType ? 0 : 1))
    );
    return groupBy(rows1, x => x.assetType);
}

export const getTotalRowsforAssetGroup = (assetGroups, allTimes) => {
    const assetTotalRows = Object.keys(assetGroups).map(assetType => {
        const accountItems = assetGroups[assetType];
        return {
            key: `${assetType}`,
            // accountNumber,
            assetType,
            assetcatcd: accountItems && accountItems[0] ? accountItems[0].assetcatcd : '',
            meta: { expanded: false },


            ...allTimes.reduce((a, time) => {
                const value = accountItems
                    .map(x => x[time])
                    .reduce((a, x) => a + (x || 0), 0);

                a[time] = value;
                return a;
            }, {})
        };
    });
    return assetTotalRows;
}

export const getMappedData = (data) => {
    return data.map(x => ({
        accountNumber: x.accountNumber,
        marketValue: parseFloat(x.marketValue) || 0,
        assetType: x.assetType || "UNCATEGORIZED",
        time: new Date(x.calendarYear, x.month - 1).getTime(),
        monthYear: new Date(x.calendarYear, x.month - 1),
        asOfDate: new Date(x.asOfDate),
        assetcatcd: x.code
    }));
}

export const GetUniqueTimedata = (mappedData) => {
    return uniq(mappedData.map(x => x.time).filter(x => x)).sort(
        (a, b) => {
            return a - b;
        }
    );

}


