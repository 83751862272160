export const FETCH_PAST_TRANSFERS = "FETCH_PAST_TRANSFERS";
export const FETCH_SCHEDULED_TRANSFERS = "FETCH_SCHEDULED_TRANSFERS";
export const ADD_TRANSFER = "ADD_TRANSFER";
export const CANCEL_TRANSFER = "CANCEL_TRANSFER";
export const REMOVE_RECENTCONF = "REMOVE_RECENTCONF";

/* Actions for manage external accounts*/

export const ADD_EXTERNAL_ACCT ="ADD_EXTERNAL_ACCT";
export const FETCH_EXTERNAL_ACCT = "FETCH_EXTERNAL_ACCT";
export const REMOVE_RECENTEXTACCT = "REMOVE_RECENTEXTACCT";



/* Actions for personalisation */
export const GET_PERSONALISATION_DATA ="GET_PERSONALISATION_DATA";
export const TOGGLE_COMPONENT ="TOGGLE_COMPONENT";
export const SET_OTP_EXPIRY ="SET_OTP_EXPIRY";

/* Actions for aggregated accounts */
export const UPDATE_AGGREGATED_ACCT = "UPDATE_AGGREGATED_ACCT";

export const UPDATE_APPSTORE = "UPDATE_APPSTORE";
export const UPDATE_PREFERENCE = "UPDATE_PREFERENCE";
export const SET_DASHBOARD_PREFERENCE = "SET_DASHBOARD_PREFERENCE";


/* Actions for environment  */

export const  LOAD_ENV = 'LOAD_ENV';
export const  CLEAR_ENV = 'CLEAR_ENV';

/* Actions for account information  */

export const UPDATE_ACCOUNT_INFORMATION = 'UPDATE_ACCOUNT_INFORMATION';