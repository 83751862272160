import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CaseManager from './CaseManager/casemanager.jsx'
import ErrorBoundary from '../common/errorboundary.jsx';
import { tabSelection, tabSelectionOnLoad, GetPreferencePilotValue, GetPreferenceValue } from '../common/constants.jsx';
import Feedback from '../components/Feedback/feedback';
import Support from './ApplicationSupport/applicationSupport';
class HelpandSupport extends Component {

    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);

        this.state = {
            tabsLinks:
                [{ tabUrl: '/CaseManager', active: true, displayName: 'REPORT AN ISSUE', id: 'RCM_CaseManagertab' },
                    { tabUrl: '/Feedback', active: false, displayName: 'PROVIDE FEEDBACK', id: 'RCM_Feedbacktab' },
                    { tabUrl: '/Support', active: false, displayName: 'ADDITIONAL SUPPORT', id: 'RCM_Supporttab' } 
                ],

            isCustomView: this.getPreferenceValue()
        };
    }

    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }
    handleSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }
    componentDidMount() {
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/CaseManager', '/Help');
        this.setState({ tabsLinks: tabsLinks });
    }

    render() {
        const helpandsupport = (
            <div key="dvDocument">
                <div className={this.state.isCustomView ? "RCM_Tab_container" : "RCM_NavTab_container"}>
                    <ul className="nav nav-tabs">
                        {this.state.tabsLinks && this.state.tabsLinks.map((tabLink) => {
                            return <li className="nav-item active" id={tabLink.id}>
                                <NavLink className="nav-link" exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                    onClick={this.handleSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                            </li>
                        })}
                    </ul>
                </div>
                <div>
                    <Switch>
                        <Route key='root' exact path="/Help" render={() => <Redirect to="/CaseManager" />} />
                        <Route key='casemanager' exact path="/CaseManager" component={CaseManager} />
                        {<Route key='feedback' exact path='/Feedback' component={Feedback} />}
                        {<Route key='support' exact path='/Support' component={Support} />}
                        
                    </Switch>
                </div>
            </div>
        );
        return (
            <div>
                <ErrorBoundary>
                    <BrowserRouter>{helpandsupport}</ BrowserRouter>
                </ErrorBoundary>
            </div>
        );
    }
}
const mapStateToProps = (store) => {
    return {
        preferences: store.acct.preferences
    };
}
export default withRouter(connect(mapStateToProps)(HelpandSupport));
