import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import {
  _logException,
  _logInformation,
  _logEvent,
} from "../common/_logging.js";
import { decodeJwtToken } from "../common/constants.jsx";
import { getNestedObject } from "../components/MoneyMovement/common/utilty.js";

export default class ClientOnlineMsalProvider {
  msalInstance = null;
  msalConfig = null;

  getMsalInstance = (env) => {
    let is_safari = navigator.userAgent.indexOf("Safari") > -1;
    let config = {
      auth: {
        clientId: env.clientid,
        authority: env.authority,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        storeInMemory: true,
      },
      system: {
        iframeHashTimeout: is_safari ? 10000 : 60000,
        windowHashTimeout: 60000,
        loadFrameTimeout: 500,
        loggerOptions: {
          logLevel: LogLevel.Verbose,
          loggerCallback: this.msalLogger,
          piiLoggingEnabled: true,
        },
        telemetry: {
          applicationName: "Rockefeller Client Online"
          //applicationVersion: version,
        },
        tokenRenewalOffsetSeconds: 300,
      },
    };

    this.msalConfig = config;
    this.msalInstance = new PublicClientApplication(config);

    return this.msalInstance;
  };

  msalLogger = (level, message, containPii) => {
    switch (level) {
      case LogLevel.Error: {
        console.error(message);
        _logException(message);
        break;
      }

      case LogLevel.Warning: {
        console.warn(message);
        _logInformation(message);
        break;
      }

      default: {
        console.log(message);
        _logInformation(message);
        break;
      }
    }
  };

  interactionBasedLogin = (loginHint, scopes) => {
    let prompt = "login";
    _logEvent("1. User Logging in", loginHint);
    const tokenRequest = {
      ...scopes,
      prompt,
      loginHint,
      forceRefresh: true,
    };
    this.msalInstance.acquireTokenRedirect(tokenRequest);
  };

  getTokenSilent = (account, scopes) => {
    const tokenRequest = {
      account,
      ...scopes,
      forceRefresh: true,
    };
    return this.msalInstance.acquireTokenSilent(tokenRequest);
  };

  ssoLogin = (loginHint, scopes) => {
    const ssoRequest = {
      ...scopes,
      loginHint,
      forceRefresh: true,
    };
    return this.msalInstance.ssoSilent(ssoRequest);
  };
}

export const getLoggedInUser = (token) => {
  let user = { name: "", userName: "", preferred_username: "" };

  let parsedToken = decodeJwtToken(token);
  if (parsedToken !== null) {
    user.name = getNestedObject(parsedToken, ["name"]);
    user.userName = getNestedObject(parsedToken, ["email"]);
    user.preferred_username = getNestedObject(parsedToken, [
      "preferred_username",
    ]);
    user.oid = getNestedObject(parsedToken, ["oid"])
  }

  return user;
};

export const isTokenRefreshRequired = (token, intervalTime) => {
  let isRefreshRequired = false;

  if (token) {
    let parsedToken = decodeJwtToken(token);
    if (parsedToken !== null) {
      let issuedTime = getNestedObject(parsedToken, ["iat"]);
      let expirationTime = getNestedObject(parsedToken, ["exp"]);
      let currentTime = new Date().valueOf() / 1000;
      console.log(
        `Issued Time:: ${issuedTime} Expiration Time:: ${expirationTime} Current Time:: ${currentTime}`
      );

      if (expirationTime <= currentTime + intervalTime / 1000) {
        isRefreshRequired = true;
      }
    }
  }

  return isRefreshRequired;
};
