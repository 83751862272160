import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ImmersiveXConfirmationModal from "./immersiveXConfirmationModal";

const ImmersiveXContainer = () => {
  const immersiveXText =
    "No ''bucket list'' experience is out of the reach of Rockefeller clients with the help of ImmersiveX, whose concierge experts can fulfill even the most ambitious wish. Discover the possibilities of personal access to global stars, events and experiential adventures of all varieties around the world.  ";
  const immersiveXURL = useSelector((store) => store?.env?.immersiveXURL)
  const [isImmersiveXModalOpen, setImmersiveXModalOpen] = useState(false);

  const handleViewImmersiveXClick = useCallback(() => {
    setImmersiveXModalOpen(true);
  }, []);

  const handleImmersiveXConfirmClick = useCallback(() => {
    window.open(immersiveXURL)
    setImmersiveXModalOpen(false);
  }, [immersiveXURL]);

  const toogleImmersiveXModal = useCallback(() => {
    setImmersiveXModalOpen((prev) => !prev);
  }, []);

  return (
    <div className="container">
      <img
        id="immersivex_image"
        src="./../images/ImmersiveX.png"
        alt="ImmersiveX"
        width="100%"
        className="RCM_health_base_image"
      />
      <div>
        <div className="row RCM_health_container">
          <div className="col col-sm-12 mt-1">
            <div
              className="RCM_Toast RCM_health_toast"
              id="RCM_immersiveX_details"
            >
              <div className="RCM_lifestyle_homeThrive"></div>
              <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
                IMMERSIVEX
              </div>
              <div className="mt-2 RCM_aviation_toast_content_container">
                {immersiveXText}
              </div>
              <div className="mt-4 RCM_lifestyle_btns" align="center">
                <button
                  className="btn btn-primary RCM_button_aviation_page"
                  onClick={handleViewImmersiveXClick}
                >
                  CLICK HERE TO VIEW IMMERSIVEX
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isImmersiveXModalOpen && (
        <ImmersiveXConfirmationModal
          isOpen={isImmersiveXModalOpen}
          toogleModal={toogleImmersiveXModal}
          onContinueClick={handleImmersiveXConfirmClick}
        />
      )}
    </div>
  );
};

export default ImmersiveXContainer;
