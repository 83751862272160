import { AccountInfoColumns } from './accountInfoColumns'
import BootstrapTable from 'react-bootstrap-table-next';
import { MsgForNoData } from '../../../common/constants'
export function AccountInfoTableView(
    props
) {
    let accountInfoTableview = (
        <div>
            {
                (props && props.state && !props.state.isAccountInfoLoading)
                    ? props && props.state && props.state.messages && props.state.messages.length > 0
                        ?
                        <div className="row RCM_Feedback_Message_Margin" key="AF100">
                            <div className="col-sm-12 col-xs-12 col-md-11 col-lg-9" key="AF101">
                                <Messages messages={props && props.state && props.state.messages}
                                    containerClass={
                                        props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                            ? "RCM_validation_error_container"
                                            : ""
                                    } />
                            </div>
                        </div>
                        : ""
                    : ""
            }
            <div id="RCM_Account_Info_Table" className="RCM_ManageGroup_Tables RCM_CM_scroll" key="AF102">
                <BootstrapTable
                    keyField='accountkey'
                    data={props.state.accounts}
                    columns={AccountInfoColumns(props.showUnMaskedNumber, props.state.allAccount)}
                    headerClasses="row-bg"
                    bordered={false}
                    rowClasses={props.grpTableRowClasses}
                    classes="RCM_two_level_table1 RCM_accountInfo_table"
                    noDataIndication={MsgForNoData}
                    selectRow={props.selectRow}
                    rowEvents={props.rowEvents}
                />
            </div>

        </div>
    )
    return accountInfoTableview
}