import axios from "axios";
import { assetSchemaColorsConfigUrl } from './../common/constants';
import { Get } from './../common/servicecalls.jsx';
export function getSchemaCode(dropdownClassValue) {
    if (dropdownClassValue) {
        let res = assetClassDropDownItems.find(c => c.label === dropdownClassValue);
        if (res) {
            return res.code;
        }
    }
    else return -1;
}
export const assetClassDropDownItems = [
    {
        label: "Product Class",
        value: "NFS",
        code:-1
    },
    {
        label: "Asset Allocation",
        value: "L1",
        code:1
    },
    {
        label: "Asset Allocation details",
        value: "L3",
        code:3
    },

];
export const historicalAssetClassDropDownItems = [
    {
        label: "Product Class",
        value: "P",
        code: -1
    },
    {
        label: "Asset Allocation",
        value: "L1",
        code: 1
    },
    {
        label: "Asset Allocation details",
        value: "L3",
        code: 3
    },

];
export function getAssetColorConfig(assetCat, assetcatcd, assetcolorRes, dropdownClassValue) {
    let colorcd = "#817F76";
    let displayseq = "999";
    let res = null;

    if (assetcolorRes) {
        if (assetcolorRes && assetcolorRes.assetclassificationconfig) {
            const selectedSchema = getSchemaCode(dropdownClassValue);
            if (selectedSchema === -1) {
                if (assetcolorRes && assetcolorRes.assetclassificationconfig && assetcolorRes.assetclassificationconfig.productclass && assetcolorRes.assetclassificationconfig.productclass.length > 0) {
                    if (assetcatcd !== undefined && assetcatcd !== null && assetcatcd != '') {
                        res = assetcolorRes.assetclassificationconfig.productclass.find(c => c.code === assetcatcd);
                    }
                    else {
                        res = assetcolorRes.assetclassificationconfig.productclass.find(c => c.description === assetCat);
                    }

                }
            }
            else {
                if (assetcolorRes && assetcolorRes.assetclassificationconfig && assetcolorRes.assetclassificationconfig.assetclass && assetcolorRes.assetclassificationconfig.assetclass.length > 0) {
                    if (assetcatcd !== undefined && assetcatcd !== null && assetcatcd != '') {
                        res = assetcolorRes.assetclassificationconfig.assetclass.find(c => c.code === assetcatcd && c.level === selectedSchema);
                    }
                    else {
                        res = assetcolorRes.assetclassificationconfig.assetclass.find(c => c.description === assetCat && c.level === selectedSchema);
                    }

                }
            }
            if (res) {
                colorcd = res.color;
                displayseq = res.order;
            }
        }
        else {
            const selectedSchema = getSchemaValue(dropdownClassValue);
            if (selectedSchema === 'NFS') {
                if (assetcolorRes && assetcolorRes.productclass && assetcolorRes.productclass.length > 0) {
                    res = assetcolorRes.productclass.find(c => c.name === assetCat);
                }
            }
            else if (selectedSchema === 'L1') {
                if (assetcolorRes && assetcolorRes.assetclass && assetcolorRes.assetclass.length > 0) {
                    res = assetcolorRes.assetclass.find(c => c.name === assetCat);
                }
            }
            else {
                if (assetcolorRes && assetcolorRes.assetclassdetails && assetcolorRes.assetclassdetails.length > 0) {
                    res = assetcolorRes.assetclassdetails.find(c => c.name === assetCat);
                }
            }
            if (res) {
                colorcd = res.code;
                displayseq = res.displayseq;
            }

        }
    }

    let assetCatObj = {
        "colorcd": colorcd, "displayseq": displayseq
    }

    return assetCatObj;
}
export function getSchemaValue(dropdownClassValue) {
    if (dropdownClassValue) {
        let res = assetClassDropDownItems.find(c => c.label === dropdownClassValue);
        if (res) {
            return res.value;
        }
    }
    else return "NFS";
}
export function getHistoricalSchemaValue(dropdownClassValue) {
    if (dropdownClassValue) {
        let res = historicalAssetClassDropDownItems.find(c => c.label === dropdownClassValue);
        if (res) {
            return res.value;
        }
    }
    else return "P";
}

//export function executeAssetColor = (assetColorCancellationToken,) => {
//    //this.setState(() => ({ isLoadingAssetColor: true, requestFailedAssetColor: false }));

//    //let baseUrl = assetSchemaColorsConfigUrl;

//    if (this.assetColorCancellationToken) {
//        this.assetColorCancellationToken.cancel('Operation canceled due to new request.')
//    }
//    this.assetColorCancellationToken = axios.CancelToken.source();

//    Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.assetColorCancellationToken)
//        .then(result => {
//            this.setState(() => ({ assetcolorRes: result.data, isLoadingAssetColor: false, requestFailedAssetColor: false }))
//            this.props.AddAssetColor(result.data)
//        })
//        .catch(error => {
//            if (axios.isCancel(error)) {
//                console.log(error);
//            } else {
//                this.setState(() => ({ requestFailedAssetColor: true, error: error.message }))
//            }
//        })
//};

export var assetColorCancellationToken = null;

export const staticColorConfigService = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (assetColorCancellationToken !== null && assetColorCancellationToken !== undefined) {
        //Cancel the existing call
        assetColorCancellationToken.cancel('Cancelling the static color config request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    assetColorCancellationToken = cancelToken.source();

    let assetColorPromise = new Promise(
        function (resolve, reject) {
            let AssetSchemaConfigUrl = assetSchemaColorsConfigUrl;
            Get(AssetSchemaConfigUrl, token, contextJson, profileJson, assetColorCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Asset Allocation Static Config service: Asset Allocation ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Asset Allocation Static Config service: Asset Allocation :: ", error);
                        //_logException("Alerts service: Alerts Summary :: " + (error && error.response) ? error.response : "");
                        reject(error);
                    }
                });
        });
    return assetColorPromise;
}


