import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import OverlayProcessing from "./../../../components/MoneyMovement/common/overlayProcessing.jsx";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { spinner, MsgDocumentLoading, MsgForFailedData, MsgForNoData, MsgSelectDocument, MsgSelectRockoActs } from './../../../common/constants.jsx'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export function DownloadPrintallModal(childProps){
    const selectRowModal = {
        mode: 'checkbox',
        bgColor: 'var(--color-light-blue)',
        onSelect: childProps.props && childProps.props.state && childProps.props.selectRowfromModal,
        onSelectAll: childProps.props && childProps.props.state && childProps.props.selectAllDocument,
        selectionHeaderRenderer: ({ mode, ...rest }) => <input type={mode} {...rest} />,
        selectionRenderer: ({ mode, ...rest }) => (
            <input type={mode} {...rest} />
        ),
        clickToSelect: true
    };
    let actionType = childProps.props.state.modalType ==="download"?" Download":" Print"
    let headerName = childProps.screenName+actionType


    let downloadPrintModal = (
        <Modal isOpen={childProps.props.state.modal} toggle={childProps.props.toggleModel} backdrop="static"
        className="RCM_holding_details_modal_content"
        zIndex='9999'
        modalClassName="RCM_CM_modal_class"
        centered key='modaltas1'>
        <ModalHeader toggle={childProps.props.toggleModel} className="RCM_holding_details_modal" charCode="X">{headerName}</ModalHeader>
        <ModalBody>
            <div>
                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6 RCM_Stmt_Date_Div" id="RCM_accstmtDatefilter">
                        <label className="RCM_Security_lbl">Documents Type</label>
                        <div className="RCM_Inline_Div">
                            <Dropdown isOpen={childProps.props && childProps.props.state && childProps.props.state.documentTypeDropdoenOpen} toggle={childProps.props.documentTypeToggle} className="RCM_select_box RCM_mm_subselect_box RCM_mm_subselectModal_box RCM_Document_Dropdown">
                                <DropdownToggle caret>
                                    {
        
                                        childProps.props && childProps.props.state && childProps.props.state.documentType
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    {childProps.props && childProps.props.documentTypeDropDownItems && childProps.props.documentTypeDropDownItems.map((item, index) =>
                                        <DropdownItem key={index} onClick={childProps.props.selectType}>
                                            <div >{item.label}</div>
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
        
                        </div>
                    </div>
                    <div className="col-sm-12 col-xs-12 col-md-2 col-lg-2"></div>
                    <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4 pull-right RCM_right_text_allign">
                        <label onClick={childProps.props && childProps.props.state && childProps.props.state.primaryValue && childProps.props.state.primaryValue.length >= 1 ? childProps.props.printModalDocument : () => { }} className={childProps.props.state.primaryValue.length >= 1 ? "custom-file-upload RCM_downloadall_width " : "custom-file-upload label-disable RCM_downloadall_width "} >{childProps.props.state.modalType ==="download"?"DOWNLOAD SELECTED":"PRINT SELECTED"}</label>
                    </div>
                </div>
                <div>
                    {
                         childProps.props && childProps.props.state && childProps.props.state.documentsUploaded
                            ? <div className="RCM_spinner modal-text-color">{MsgDocumentLoading}</div>
                                : <><div>Preparing documents for download. This process could take several minutes, please wait.</div> 
                            <OverlayProcessing documents={true}/></>
                    
                    }
                </div>
                {childProps.props.state.errorMessage}
                <div className="RCM_CM_scroll">
                    {
                        childProps.props && childProps.props.state.filteredDocumentListModal.length > 0 ?
                            <div className="RCM_Tax_doc_table_wrapper">
                                <BootstrapTable
                                    keyField="id"
                                    data={childProps.props && childProps.props.state && childProps.props.state.filteredDocumentListModal}
                                    columns={childProps.columns}
                                    bordered={false}
                                    selectRow={selectRowModal}
                                    classes="RCM_two_level_table1 RCM_TaxDocument_Table"
                                >
                                </BootstrapTable>
                            </div> : <div></div>
        
                    }
        
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
        </Modal>
    )
    return downloadPrintModal
}


