import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DocumentListURL, DocumentURL, StichedPdfDocumentURL, CheckIfProspectClient } from './../../../common/constants.jsx';
import DateRanges from './../../../common/dateranges.jsx'
import { AddAccountStatements, AddCorrespondenceDocuments, AddTaxDocuments, AddTradeConfirmDocuments } from './../../../actions/documentactions.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { AccountStatementsView } from './accountstatementsview.jsx'
import { MessageType, MessageClass, GetAccountsList } from './../../../common/constants.jsx';
import axios from 'axios';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import { printDocument } from './../../../common/printdocument.jsx';
import { downloadDocument } from './../../../common/downloaddocument.jsx';
import getSearchDate from '../searchdates.js';
import { _logException, _logInformation } from "../../../common/_logging.js";
import { withPageView } from '../../../hoc/withPageView.jsx';
import actstmtmockresponse from './propsectsaccountstatementmockresponse.js'
import pdfData from './../mockpdfresponse.js'
import dateFormat from "dateformat";
class AccountStatements extends Component {

    constructor(props) {
        super(props);

        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            documentResponse: null,
            pdfData: null,
            objectIds: [],
            documentList: [],
            filteredDocumentList: [],
            filteredDocumentListModal: [],
            documentListColumns: this.documentListColumns,
            accounts: this.accounts,
            selectedAccount: '',
            dropdownOpen: false,
            dateRangeDropdownOpen: false,
            dropdownValue: '',
            dropdownDispValue: '',
            dateRangeDropdownValue: '',
            dateRangeValue: '',
            selectedDateRange: '',
            isCustomDateRangeSelected: false,
            areDocumentsRetrieved: false,
            selected: [],
            isDocumentSelected: false,
            startDate: new Date(),
            endDate: new Date(),
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
            isDocumentResponseWithNoData: false,
            isDocumentResponseWithError: false,
            render: this.props.render,
            messages: [],
            fileName: '',
            modal: false,
            primaryValue: [],
            documentsUploaded: true,
            documentTypeDropdoenOpen: false,
            documentType: "Select Type",
            modalType: "",
            errorMessage: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.changeAccount = this.changeAccount.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.dateRangeDropdownToggle = this.dateRangeDropdownToggle.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.SearchDocuments = this.SearchDocuments.bind(this);
        this.execute = this.execute.bind(this);
    }
    accountStatementToken = null;
    documentCanellationToken = null;
    correspondenceCancellationToken = null;
    taxDocumentCancellationToken = null;
    tradeConfirmCancellationToken = null;
    documentList = [];
    dateRangeDropDownItems = [
        {
            label: 'Select Range',
            value: 'Select Range'
        },
        {
            label: DateRanges.PRIORMONTH.label,
            value: DateRanges.PRIORMONTH.value
        },
        {
            label: DateRanges.PRIOR3MONTHS.label,
            value: DateRanges.PRIOR3MONTHS.value
        },
        {
            label: DateRanges.PRIOR6MONTHS.label,
            value: DateRanges.PRIOR6MONTHS.value
        },
        {
            label: DateRanges.PRIOR12MONTHS.label,
            value: DateRanges.PRIOR12MONTHS.value
        },
        {
            label: DateRanges.CURRENTYEAR.label,
            value: DateRanges.CURRENTYEAR.value
        },
        {
            label: DateRanges.PRIORYEAR.label,
            value: DateRanges.PRIORYEAR.value
        },
        {
            label: DateRanges.CUSTOMRANGE.label,
            value: DateRanges.CUSTOMRANGE.value
        }];

    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.accounts = [];
                this.accounts = GetAccountsList(this.props.selectedaccount);
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                if (accountNumbers.length > 0) {
                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_STATEMENTS',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': '',
                        'SearchDateEnd': '',
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody, false);
                    this.setState({
                        isNonRockActSelected: false,
                        dropdownValue: this.accounts[0].number,
                        dropdownDispValue: this.accounts[0].accountdisplayname,
                        dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                        areDocumentsRetrieved: false,
                        accounts: this.accounts,
                        pdfData: null,
                        isDocumentSelected: false,
                        isDocumentResponseWithNoData: false,
                        isDocumentResponseWithError: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: false
                    });

                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true,
                        documentList: [],
                        filteredDocumentList: [],
                        filteredDocumentListModal: [],
                        isDocumentListResponseWithNoData: true,
                        isDocumentListResponseWithError: false,
                        areDocumentsRetrieved: false
                    });
                }

            }
        }
    }

    execute = (requestBody, dateRangeSearch) => {
        let documentList = [];
        let url = DocumentListURL;
        let i = 1;
        if (this.accountStatementToken) {
            this.accountStatementToken.cancel('Operation canceled due to new request.')
        }
        this.accountStatementToken = axios.CancelToken.source();
        Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.accountStatementToken)
            .then(res => {
                if (res.status === 200) {
                    const documentResponse = res.data;
                    if (documentResponse) {
                        documentResponse && documentResponse.value[0].DocumentList.map(document => {
                            var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
                            var correspondenceDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                            documentList.push({
                                "id": i,
                                "primaryValue": document.PrimaryValue,
                                "objectId": document.ObjectId,
                                "description": document.DisplayName,
                                "date": correspondenceDate
                            });
                            i++;
                        });
                        let accountStatementList = documentList;
                        /* accountStatementList.push({"id": "mock",
                        "primaryValue": "RJL000001",
                        "objectId": "05NPC221    Q101300E    RAI1GVTV7JS4    00LJGC68    499979VZ1910GCKH60002   1GCE0000042F    1000F  3",
                        "description": "M/Q Statements HH Summary",
                        "date": "12/10/2019"}) */
                        if (accountStatementList && accountStatementList.length > 0) {
                            this.props.AddAccountStatements(documentList, documentList);
                            this.setState({
                                documentList: accountStatementList,
                                filteredDocumentList: accountStatementList,
                                filteredDocumentListModal: accountStatementList,
                                areDocumentsRetrieved: true,
                                isDocumentListResponseWithError: false,
                            });
                        } else {
                            this.props.AddAccountStatements([], null);
                            this.setState({
                                documentList: [],
                                filteredDocumentList: [],
                                filteredDocumentListModal: [],
                                isDocumentListResponseWithNoData: true,
                                isDocumentListResponseWithError: false,
                                areDocumentsRetrieved: false
                            });
                        }
                        _logInformation("Account Statement: Documentlist fetched")
                        console.log(this.state);
                    } else {
                        this.props.AddAccountStatements(null, null);
                        this.setState({
                            documentList: [],
                            filteredDocumentList: [],
                            filteredDocumentListModal: [],
                            isDocumentListResponseWithNoData: true,
                            areDocumentsRetrieved: false
                        });
                    }

                } else {
                    this.props.AddAccountStatements(null, null);
                    this.setState({
                        isDocumentListResponseWithNoData: true,
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithError: false,
                    });
                }

            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    _logException("Account Statement : Fetching documentlist: " + (error && error.response) ? error.response : "");
                    console.log(error);
                    this.setState({
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: true
                    });
                }
            });


    };

    componentWillUnmount() {
        if (this.accountStatementToken) {
            this.accountStatementToken.cancel('Operation canceled due to new request.')
        }
        if (this.correspondenceCancellationToken) {
            this.correspondenceCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.taxDocumentCancellationToken) {
            this.taxDocumentCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.tradeConfirmCancellationToken) {
            this.tradeConfirmCancellationToken.cancel('Operation canceled due to new request.')
        }
    }
    componentDidMount() {
        if (this.state.isProspectClient) {
            this.SetDummyResponseForProspectClient();
        }
        else {
            let dateRangeDropdownValue = this.dateRangeDropDownItems[0].label;
            if (this.props.accountStatementsList && this.props.accountStatementsList.length > 0) {
                this.setState({
                    documentList: this.props.accountStatementsList,
                    filteredDocumentList: this.props.accountStatementsList,
                    filteredDocumentListModal: this.props.accountStatementsList,
                    areDocumentsRetrieved: true
                });
            } else {    //if statements are not available, make a service call and store them in global store 
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }

                    }
                });
                if (accountNumbers.length > 0) {
                    var dt = getSearchDate(DateRanges.PRIOR3MONTHS.value);
                    var startDate = dt.startDate;
                    var endDate = dt.endDate;
                    var searchBeginDate = this.getFormattedDate(startDate);
                    var searchEndDate = this.getFormattedDate(endDate);
                    dateRangeDropdownValue = DateRanges.PRIOR3MONTHS.label;
                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_STATEMENTS',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': searchBeginDate,
                        'SearchDateEnd': searchEndDate,
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody, false);

                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true
                    });
                }

            }
            this.setState({
                dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
                dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
                dateRangeDropdownValue: dateRangeDropdownValue,
                selectedAccount: 'All Accounts',
                selectedDateRange: 'Select Range'
            });
        }
    }
    SearchDocuments() {
        this.setState({
            messages: []
        });
        if (this.state.dateRangeValue === DateRanges.CUSTOMRANGE.value) {
            let messages = [];
            if (new Date(dateFormat(this.state.startDate, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
                messages.push({
                    type: MessageType.ERROR,
                    text: 'End date cannot be less than Start date',
                    class: MessageClass.ERROR
                })
                this.setState({ messages: messages });
                return;
            } else {
                this.setState({
                    messages: messages
                });
            }
        }
        this.setState({
            pdfData: null,
            isDocumentSelected: false,
            selected: [],
            areDocumentsRetrieved: false,
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
            documentList: [],
            filteredDocumentList: [],
            filteredDocumentListModal: []
        });

        if (this.state.dateRangeDropdownValue !== 'Select Range') {
            let accountNumbers = [];
            this.state.accounts.map(acct => {
                if (acct.number !== 'All Accounts') {
                    if (acct.isInternal) {
                        accountNumbers.push(acct.number);
                    }
                }
            });
            let requestBody = JSON.stringify({
                'VirtualTable': 'NF_STATEMENTS',
                'PrimaryType': 'Account',
                'PrimaryValue': accountNumbers,
                'SearchDateComparison': 'R',
                'SearchDateBegin': dateFormat(this.state.startDate, "yyyy-mm-dd"),
                'SearchDateEnd': dateFormat(this.state.endDate, "yyyy-mm-dd"),
                'SearchDateEndSpecified': true,
                'SearchAllVersions': true,
                'IncludeRelationships': false,
                'RenditionFilter': '*',
                'DateRange': '',

            });
            this.execute(requestBody, true);
        }
    }
    getFormattedDate(date) {
        return dateFormat(date, "mm/dd/yyyy")
    }
    changeDateRange(selectedDateRange) {
        let dateRange = this.dateRangeDropDownItems.find(item => item.label === selectedDateRange.currentTarget.textContent.trim()).value;
        this.setState({
            dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
            dateRangeValue: dateRange
        });
        if (dateRange !== 'Select Range') {
            if (dateRange === DateRanges.CUSTOMRANGE.value) {
                this.setState({
                    isCustomDateRangeSelected: true,
                    dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                    dateRangeValue: dateRange,
                });
            } else {
                this.setState({
                    isCustomDateRangeSelected: false,
                    startDate: new Date(),
                    endDate: new Date(),
                    pdfData: null,
                    isDocumentSelected: false,
                    selected: [],
                    areDocumentsRetrieved: false,
                    isDocumentListResponseWithNoData: false,
                    isDocumentListResponseWithError: false,
                    dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                    dateRangeValue: dateRange
                });
                var dt = getSearchDate(dateRange);
                var startDate = dt.startDate;
                var endDate = dt.endDate
                var searchBeginDate = this.getFormattedDate(startDate);
                var searchEndDate = this.getFormattedDate(endDate);
                let accountNumbers = [];
                this.state.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                let requestBody = JSON.stringify({
                    'VirtualTable': 'NF_STATEMENTS',
                    'PrimaryType': 'Account',
                    'PrimaryValue': accountNumbers,
                    'SearchDateComparison': 'R',
                    'SearchDateBegin': searchBeginDate,
                    'SearchDateEnd': searchEndDate,
                    'SearchDateEndSpecified': true,
                    'SearchAllVersions': true,
                    'IncludeRelationships': false,
                    'RenditionFilter': '*',
                    'DateRange': '',

                });
                this.execute(requestBody, true);
            }
            console.log(dateRange);
        }
    }
    startDateChange(date) {
        let messages = [];
        this.setState({
            startDate: date
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("start Date: " + this.state.startDate);
    }
    endDateChange(date) {
        let messages = [];
        this.setState({
            endDate: date
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) < new Date(dateFormat(this.state.startDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("end Date: " + this.state.endDate);
    }
    changeAccount(selectedAccount) {
        this.setState({
            pdfData: null,
            dropdownValue: selectedAccount.number,
            dropdownDispValue: selectedAccount.accountdisplayname,
            isDocumentSelected: false,
            selected: []
        });
        let accountNumber = selectedAccount.number;
        let docList = this.state.documentList.filter(doc => doc.primaryValue === accountNumber);
        this.props.AddAccountStatements(this.props.accountStatementsList, docList);
        if (accountNumber !== 'All Accounts') {
            this.setState({
                filteredDocumentList: docList,
                filteredDocumentListModal: docList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        } else {
            this.setState({
                filteredDocumentList: this.state.documentList,
                filteredDocumentListModal: this.state.documentList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        }

    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    dateRangeDropdownToggle() {
        this.setState(prevState => ({
            dateRangeDropdownOpen: !prevState.dateRangeDropdownOpen
        }));
    }
    selectDocument(row, isSelect) {
        if (this.state.isProspectClient) {
            this.setState({
                pdfData: pdfData,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            })
        }
        else {
            let fileName = row.primaryValue.trim() + "_" + row.description.trim() + "_" + row.date.trim();
            this.setState({
                pdfData: null,
                isDocumentSelected: true,
                selected: [row.id],
                fileName: fileName,
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
            let requestBody = JSON.stringify({
                'VirtualTable': 'NF_STATEMENTS',
                'PrimaryType': 'Account',
                'PrimaryValue': row.primaryValue,
                'ObjectId': row.objectId,
            });
            let url = DocumentURL;
            if (this.documentCanellationToken) {
                this.documentCanellationToken.cancel('Operation canceled due to new request.')
            }
            this.documentCanellationToken = axios.CancelToken.source();
            Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.documentCanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        let selectedDocPdfData = res && res.data && res.data.value[0].ContentStream;
                        if (selectedDocPdfData !== "") {
                            this.setState({
                                pdfData: selectedDocPdfData
                            })
                        } else {
                            this.setState({
                                pdfData: null,
                                isDocumentResponseWithNoData: true
                            })
                        }
                    } else {
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithNoData: true
                        })
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        // _logException("Account Statement : Fetching document: " + (error && error.response) ? error.response : "");
                        console.log(error);
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithError: true
                        })
                    }
                });
        }
        
    }
    options = [];

    toggleModel = (event) => {
        let id = event.target.id
        this.setState(prevState => ({
            modal: !prevState.modal,
            documentType: "Select Type",
            modalType: id,
            primaryValue: [],
            errorMessage: "",
            filteredDocumentListModal: this.state.filteredDocumentList
        }))
    }

    printModalDocument = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + '/' + dd + '/' + yyyy;
        let errorMessage = "";
        let requestBody = JSON.stringify({
            'VirtualTable': 'NF_TAX_DOCUMENTS',
            'PrimaryType': 'Account',
            'PrimaryValue': this.state.primaryValue
        });

        let url = StichedPdfDocumentURL;
        let documentCanellationTokenModal = null;
        if (documentCanellationTokenModal) {
            documentCanellationTokenModal.cancel('Operation canceled due to new request.')
        }
        documentCanellationTokenModal = axios.CancelToken.source();
        this.setState({
            documentsUploaded: false,
            errorMessage: ""
        })
        Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, documentCanellationTokenModal)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData !== "") {
                    if (this.state.modalType === "download") {
                        downloadDocument(selectedDocPdfData, null, "StatementsDownload" + "_" + today)
                    } else if (this.state.modalType === "print") {
                        printDocument(selectedDocPdfData)
                    }
                } else {
                    if (this.state.primaryValue.length > 1) {
                        errorMessage = "Unable to process the request. Please try selecting fewer documents to process."

                    } else {
                        errorMessage = "Unable to process the request. Please retry your operation.";
                    }
                    console.log("didn't receive document from webapi")
                }
                this.setState({
                    documentsUploaded: true,
                    errorMessage: errorMessage
                })
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log(error);

                    let fewRecMsg = this.state.primaryValue && Array.isArray(this.state.primaryValue) && this.state.primaryValue.length > 100
                        ? ' If the problem continues, please try selecting fewer documents and retry your action.' : '';
                    let errMsg = "Unable to process the request. Please retry your operation." + fewRecMsg;
                    this.setState({
                        documentsUploaded: true,
                        errorMessage: errMsg
                    })
                }

            });

    }

    selectRowfromModal = (row, isSelect) => {
        let obj = {}
        obj[row.primaryValue] = row.objectId;
        let primaryValArry = this.state.primaryValue;

        if (isSelect) {
            primaryValArry.push(obj)
        } else {
            let index = primaryValArry.findIndex(x => x[row.primaryValue] === row.objectId)
            primaryValArry.pop(index)
        }

        this.setState({
            primaryValue: primaryValArry
        })

    }

    selectAllDocument = (isSelect, rows, e) => {
        rows.map((row) => {
            this.selectRowfromModal(row, isSelect)
        })

    }

    documentTypeToggle = () => {
        this.setState(prevState => ({
            documentTypeDropdoenOpen: !prevState.documentTypeDropdoenOpen
        }));
    }

    selectType = (selectedType) => {
        let filteredData = []
        selectedType.currentTarget.textContent === "Select Type"
            ? filteredData = this.state.filteredDocumentList
            : filteredData = this.state.filteredDocumentList.filter(doc => doc.description === selectedType.currentTarget.textContent)
        this.setState({
            documentType: selectedType.currentTarget.textContent,
            filteredDocumentListModal: filteredData
        });
    }
    SetDummyResponseForProspectClient() {
        let i = 1;
        let documentList = [];
        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        actstmtmockresponse && actstmtmockresponse.value[0].DocumentList.map(document => {
            var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
            var correspondenceDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
            documentList.push({
                "id": i,
                "primaryValue": document.PrimaryValue,
                "objectId": document.ObjectId,
                "description": document.DisplayName,
                "date": correspondenceDate
            });
            i++;
        });
        let actstmtdoclist = [];
        documentList.forEach(doc => {
            this.accounts.forEach(act => {
                if (act.number === doc.primaryValue) {
                    actstmtdoclist.push(doc);
                }
            })
        });
        this.props.AddAccountStatements(actstmtdoclist, actstmtdoclist);
        this.setState({
            documentList: actstmtdoclist,
            filteredDocumentList: actstmtdoclist,
            areDocumentsRetrieved: true,
            isDocumentListResponseWithError: false,
            dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
            dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
            dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
            selectedAccount: 'All Accounts',
            selectedDateRange: 'Select Range'
        });
    }

    render() {
        console.log("Inside render")
        let documents = [];
        this.state.accounts.map(acct => {
            documents = this.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number);
            this.options.push({
                custom: true,
                totalSize: documents.length < 11 ? 0 : documents.length
            })

        });
        let authprops = {
            token: this.props.token,
            contextjson: this.props.contextjson,
            profilejson: this.props.profilejson,
            portalid: this.props.portalid
        }
        let flags = [], output = [], account_length = this.props.accountStatementsList && this.props.accountStatementsList.length > 0 ? this.props.accountStatementsList.length : 0;
        let array = JSON.parse(JSON.stringify(this.props.accountStatementsList))
        for (var i = 0; i < account_length; i++) {
            if (flags[array[i].description])
                continue;
            flags[array[i].description] = true;
            output.push({ label: array[i].description, value: array[i].description });
        }
        let documentTypeDropDownItems = [{
            label: 'Select Type',
            value: 'Select Type'
        }, ...output]
        return (
            <ErrorBoundary>
                <AccountStatementsView state={this.state}
                    options={this.options}
                    dateRangeDropDownItems={this.dateRangeDropDownItems}
                    selectDocument={this.selectDocument}
                    toggle={this.toggle}
                    changeAccount={this.changeAccount}
                    dateRangeDropdownToggle={this.dateRangeDropdownToggle}
                    changeDateRange={this.changeDateRange}
                    SearchDocuments={this.SearchDocuments}
                    startDateChange={this.startDateChange}
                    endDateChange={this.endDateChange}
                    authprops={authprops}
                    selectedaccounts={this.props.selectedaccount}
                    role={this.props.role}
                    toggleModel={this.toggleModel}
                    printModalDocument={this.printModalDocument}
                    selectRowfromModal={this.selectRowfromModal}
                    selectAllDocument={this.selectAllDocument}
                    documentTypeToggle={this.documentTypeToggle}
                    documentTypeDropDownItems={documentTypeDropDownItems}
                    selectType={this.selectType}
                />
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        accountStatementsList: state.doc.accountStatementsList,
        filteredAccountStatementsList: state.doc.filteredAccountStatementsList,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        portalid: state.acct.portalid,
        role: state.acct.role
    };
};
const mapDispatchToProps = {
    AddAccountStatements,
    AddCorrespondenceDocuments,
    AddTaxDocuments,
    AddTradeConfirmDocuments
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Account Statement' })
)


export default enhancement(AccountStatements);