import React from "react";
//import DatePicker from "react-datepicker";
import { SELECTRANGE, dateFilterDateRange } from "./data/filterDateRange.js";
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";

class TransferHistoryFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dateRangeDropdownValue: SELECTRANGE,
            dateRangeDropdownOpen: false,

        }
        this.dateRangeDropdownToggle = this.dateRangeDropdownToggle.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
    }

    dateRangeDropdownToggle() {
        console.log("toggle function called");
        this.setState(prevState => ({
            dateRangeDropdownOpen: !prevState.dateRangeDropdownOpen
        }));
    }

    changeDateRange(newRange) {
        if (this.props.changeFilterHistory !== null
            && this.props.changeFilterHistory !== undefined) {
            this.setState(Object.assign({}, this.state, {
                dateRangeDropdownValue: newRange.label
            }));
            this.props.changeFilterHistory(newRange.value);
        }
    }


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="RCM_Toast_heading RCM_heading_light">TRANSFER HISTORY</div>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="row">
                        <div className="col-sm-7" id="RCM_transferhistoryDate">
                            <div className="RCM_CM_form_label" style={{ "display": "inline-block" }} align="left">
                                <label className="RCM_heading_light">FILTER TRANSFERS BY:</label>
                            </div>

                            <div style={{ "display": "inline-block" }}>
                                <Dropdown isOpen={this.state.dateRangeDropdownOpen} toggle={this.dateRangeDropdownToggle} 
                                    className="RCM_select_box RCM_mm_subselect_box" align="left">
                                    <DropdownToggle caret>
                                        {
                                            this.state.dateRangeDropdownValue
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {dateFilterDateRange.map((item, index) =>
                                            <DropdownItem key={index} onClick={() => this.changeDateRange(item)}>
                                                <div>{item.label}</div>
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-sm-5"></div>
                        
                        {/* <div className="col-sm-1">
                            <button type="button" className="btn RCM_CM_primary_btn">
                                Go
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default TransferHistoryFilter;
