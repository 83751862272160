import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Post } from './servicecalls.jsx';
import { GetPreferenceValue, PreferencesUrl } from './constants.jsx';
import { connect } from 'react-redux';
import axios from 'axios';
import ErrorBoundary from './errorboundary.jsx';
import { updatePreference } from "../actions/accountsactions.jsx";

class Logo extends Component {
    constructor(props) {
        super(props);
        this.handleAccountSelections = this.handleAccountSelections.bind(this);
        this.increaseFontSize = this.increaseFontSize.bind(this);
        this.decreaseFontSize = this.decreaseFontSize.bind(this);
        this.resetFontSize = this.resetFontSize.bind(this);
        this.state = {
            fontSize: 16
        }

    }
    componentDidMount() {
        var root = document.documentElement;
        var fontSize = GetPreferenceValue(this.props.acct, "FontScale", "16");
        this.state.fontSize = parseInt(fontSize);
        //this.setState({ fontSize: parseInt(fontSize) });
        fontSize = fontSize + "px";
        root.style.setProperty('font-size', fontSize);
    }
    handleAccountSelections() {
        
        document.dispatchEvent(new CustomEvent('removeSelectionUsersetting', { bubbles: true }));
        document.dispatchEvent(new CustomEvent('HandlenavbarSelection', { bubbles: true }));
    }
    increaseFontSize() {
        var fontSize = this.state.fontSize + 1;
        this.state.fontSize = fontSize;
        this.updatePreferenceCallBack();
        var root = document.documentElement;
        fontSize = fontSize + "px";
        root.style.setProperty('font-size', fontSize);

    }
    decreaseFontSize() {
        var fontSize = this.state.fontSize - 1;
        this.state.fontSize = fontSize;
        this.updatePreferenceCallBack();
        
        var root = document.documentElement;
        fontSize = fontSize + "px";
        root.style.setProperty('font-size', fontSize);
        
    }
    resetFontSize() {
        var fontSize = 16;
        this.state.fontSize = fontSize;
        this.updatePreferenceCallBack();

        var root = document.documentElement;
        fontSize = fontSize + "px";
        root.style.setProperty('font-size', fontSize);

    }
    fontScalecanellationToken = null;
    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.fontScalecanellationToken = axios.CancelToken.source();
        var fontsize = this.state.fontSize + "";
        let componentjson = [];
        componentjson.push({ "key": "FontScale", "value": fontsize})
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(this.props.acct.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            // objIndex = myArray.findIndex((obj => obj.id == 1));
            let fontpreferencevalue = reqObj1.component.filter(component => component.key === "FontScale");
            if (fontpreferencevalue && fontpreferencevalue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "FontScale")].value = fontsize;
            }
            else {
                reqObj1.component.push({ "key": "FontScale", "value": fontsize});
            }
        }

        if (this.props.role !== "Prospect") {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.fontScalecanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        this.props.updatePreference(reqObj1);
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                });
        }
    }
    componentWillUnmount() {
        if (this.fontScalecanellationToken !== null && this.fontScalecanellationToken !== undefined) {
            this.fontScalecanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }
    render() {
        return (
           
            this.props.role === "Prospect" ? <ErrorBoundary ><Link to='Collaboration' onClick={this.handleAccountSelections.bind()}><div key='dvLogo1' className={"RCM_header_logo_img "} id="RCM_header_logo_img"></div></Link></ErrorBoundary>
                
                : <ErrorBoundary ><div className="RCM_logo_container">
                    <div >
                        <Link to='Accounts' onClick={this.handleAccountSelections.bind()}><div key='dvLogo1' className={"RCM_header_logo_img "} id="RCM_header_logo_img"></div></Link>
                    </div>
                    {/*<div className="RCM_font_sizing_container">
                        <span className="RCM_font_sizing RCM_font_sizing_small" onClick={this.decreaseFontSize.bind()}>A-</span>
                        <span className="RCM_font_sizing RCM_font_sizing_normal" onClick={this.resetFontSize.bind()}>A</span>
                        <span className="RCM_font_sizing RCM_font_sizing_big" onClick={this.increaseFontSize.bind()}>A+</span>
                    </div>*/}
                  </div>

            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        token: state.acct.token,
        
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        preferences: state.acct.preferences,
        role: state.acct.role,
        acct: state.acct,
        profileId: state.acct.profileId,
    };
};

const mapDispatchToProps = {
    updatePreference
}

export default connect(mapStateToProps, mapDispatchToProps)(Logo);