import { getAcctInfosJson, getTransferJson, getAccountString, getCancelTransferReq } from "./models/AccountInfo.js";
import { Array } from 'core-js';
import {getNumericDate} from "./data/filterDateRange.js";
import {genericErrorMsg} from "./common/constants.js";
import {eligiblityPairServiceUrl, mmvalidateUrl, addTransferUrl, activityTransfersUrl, mmSummaryUrl} from  "../../common/constants.jsx";
import { Get, Post } from "../../common/servicecalls.jsx";
import axios from "axios";
import {_logException} from "../../common/_logging";
import { getNestedObject } from "./common/utilty.js";
//import { eligiblePairDummyResponse, mmvalidateDummyResponse } from "./data/transferdata.js";

export var getPastTransfersCancellationToken = null;
export var getScheduleTransfersCancellationToken = null;
export var getEligiblePairCancellationToken = null;
export var mmValidateTransferCancellationToken = null;
export var cancelScheduleTransferCancellationToken = null;
export var addTransferCancellationToken = null;
export var getHolidayListCancellationToken = null;
export var getWithHoldingInfoCancellationToken = null;

export const getEligiblePair = (accounts, isRetirementDisAllowed, requesterId, token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if(getEligiblePairCancellationToken !== null && getEligiblePairCancellationToken !== undefined)
    {
        //Cancel the existing call
        getEligiblePairCancellationToken.cancel('Cancelling the existing eligible pair request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getEligiblePairCancellationToken = cancelToken.source(); 

    let eligiblePairPromise = new Promise(
        function (resolve, reject) {

            let response = {
                eligiblePairs: [],
                disclouser: null
            }
            //var accountsJson = { "AcctInfos": [{ "acctNum": "RJL000011", "acctType": "I", "acctCategory": "INT" }, { "acctNum": "RJL000003", "acctType": "IRA", "acctCategory": "RTR" }, { "acctNum": "RJL000020", "acctType": "TIC", "acctCategory": "TRUST" }], "RequestorId": "6611000176" };

            if (accounts !== null
                && accounts !== undefined
                && requesterId !== null
                && requesterId !== undefined) {
                var accountsJson = getAcctInfosJson(accounts, requesterId);

                //Call eligible pair service 
                let url = isRetirementDisAllowed ? eligiblityPairServiceUrl+`?isPilot=Y` : eligiblityPairServiceUrl;
                Post(url, accountsJson, token, contextJson, profileJson, getEligiblePairCancellationToken).then(res => {
                    // get the response in case of successful call. 
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {
                        var eligiblePairResponse = res.data.value[0];
                        if (eligiblePairResponse.Status !== null
                            && eligiblePairResponse.Status !== undefined
                            && eligiblePairResponse.Status.Code !== null
                            && eligiblePairResponse.Status.Code !== undefined
                            && eligiblePairResponse.Status.Code === "000000"
                            && eligiblePairResponse.EligiblePairs !== null
                            && Array.isArray(eligiblePairResponse.EligiblePairs)) {
                            response.eligiblePairs = eligiblePairResponse.EligiblePairs;
                            response.disclouser = (eligiblePairResponse.Disclosure !== null
                                && eligiblePairResponse.Disclosure !== undefined ? eligiblePairResponse.Disclosure : "");
                            console.log("Money Movment Serice : Get Eligible Pair :: ", eligiblePairResponse);
                            resolve(response);
                        } else {
                            console.log("Money Movment Serice Non Zero : Get Eligible Pair :: ", eligiblePairResponse.Status);
                            _logException("Money Movment Serice Non Zero : Get Eligible Pair :: "+ getNestedObject(eligiblePairResponse, ["Status", "Text"]));
                            if(eligiblePairResponse.Status !== null 
                                && eligiblePairResponse.Status !==undefined
                                && eligiblePairResponse.Status.DisplayText !== null 
                                && eligiblePairResponse.Status.DisplayText !== undefined){
                                    reject(eligiblePairResponse.Status.DisplayText) 
                                }else{
                                    reject(genericErrorMsg);
                                }
                            
                            //For testing when api is down
                            // resolve({
                            //     eligiblePairs: eligiblePairDummyResponse.value[0].EligiblePairs,
                            //     disclouser: eligiblePairDummyResponse.value[0].Disclosure
                            // });
                        }

                    }

                })
                .catch((error) => {
                    if(axios.isCancel(error))
                    {
                        console.log(error);
                    }else{
                    //Log the error on console incase of failure.
                    console.error("Money Movment Serice : Get Eligible Pair :: ", error);
                    _logException("Money Movment Serice : Get Eligible Pair :: "+ (error && error.response) ? error.response : "");
                    reject(genericErrorMsg);
                    }
                });
            }
            else {
                console.error("Money Movement Service : input accounts are not valid ", accounts);
                _logException("Money Movement Service : input accounts are not valid");
                reject(genericErrorMsg);
            }

        }
    );

    return eligiblePairPromise;
}

export const mmvalidateTransfer = (transferDetails, eligibleAcctPair, token, contextJson, profileJson) => {
 
    //Check if cancellation token has value;
    if(mmValidateTransferCancellationToken !== null && mmValidateTransferCancellationToken !== undefined)
    {
        //Cancel the existing call
        mmValidateTransferCancellationToken.cancel('Cancelling the existing validate transfer request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    mmValidateTransferCancellationToken = cancelToken.source(); 


    let validateTransferPromise = new Promise(

        function (resolve, reject) {
            let accountDetails = getTransferJson(transferDetails, eligibleAcctPair, false);
            let url = mmvalidateUrl;
            Post(url, accountDetails, token, contextJson, profileJson,  mmValidateTransferCancellationToken)
            .then(res => {
                // get the response in case of successful call. 
                console.log("Money Movement Service : Validate Transfer ::", res);
                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {

                    //get the valid response 
                    let response = res.data.value[0];
                    if (response.status !== null
                        && response.status !== undefined
                        && response.status.Code !== null
                        && response.status.Code !== undefined) {
                            if (response.status.Code !== "0") {
                                _logException("MoneyMovement Service:: Validate Transfer :: "+ getNestedObject(response.status, ["Text"]));
                                if(response.status.Errors !== null 
                                    && response.status.Errors !==undefined 
                                    && Array.isArray(response.status.Errors) && response.status.Errors.length > 0)
                                {
                                    //format error messages list
                                    let errors = response.status.Errors;
                                    if(errors[0].ErrorMsg !== null && errors[0].ErrorMsg !== undefined && errors[0].ErrorMsg !== "")
                                    {
                                        reject(errors[0].ErrorMsg);
                                    }else{
                                        reject(genericErrorMsg);
                                    }
                                    
                                }else{
                                    reject(genericErrorMsg);
                                }
                            }else{
                                resolve(response);
                            }
                            
                        }
                        else {
                            reject(genericErrorMsg);
                        }
                    }
                    else {
                        reject(genericErrorMsg);
                    }

            })
            .catch((error) => {
                if(axios.isCancel(error))
                {
                    console.log(error);
                }else{
                    //Log the error on console incase of failure.
                    console.error("Money Movment Service : MM Validate Transfer :: ", error);
                    _logException("Money Movment Service : MM Validate Transfer :: "+ (error && error.response) ? error.response : "");
                    reject(genericErrorMsg);
                    //resolve(mmvalidateDummyResponse);
                }
                
            });

    });

    return validateTransferPromise;

}

export const addTransfer = (transferObj, eligibleAcctPair, token, contextJson, profileJson) => {
    
    //transferDetails = { "moneymovementid": "test123", "amount": 123.45, "iseft": true, "isjnl": false, "isira": false, "memo": "This is a test", "transactiondate": "2019-04-16", "fromaccount": { "accountnumber": "0101010", "accounttype": "", "isexternal": true, "abanumber": "021000021", "siid": null, "bankname": "JPMORGAN CHASE BANK" }, "toaccount": { "accountnumber": "RJL000013", "accounttype": "", "isexternal": false, "siid": "03300001932", "bankname": "" } };

    //Check if cancellation token has value;
    if(addTransferCancellationToken !== null && addTransferCancellationToken !== undefined)
    {
        //Cancel the existing call
        addTransferCancellationToken.cancel('Cancelling the existing add transfer request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addTransferCancellationToken = cancelToken.source(); 


    let addTransferPromise = new Promise(
        function (resolve, reject) {

            let transferDetails = getTransferJson(transferObj, eligibleAcctPair, false);
            let url = addTransferUrl;
            Post(url, transferDetails, token, contextJson, profileJson, addTransferCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Money Movement Service : Add Transfer ::", res);

                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {

                    //get the valid response 
                    let response = res.data.value[0];
                    if(response.status !== null
                        && response.status !== undefined
                        && response.status.Code !== null && response.status.Code === "0")
                        {
                            resolve(res.data.value[0]);
                        }else{
                            console.log("Money movement service ::  Add Transfer : response is not Success");
                            _logException("Money movement service ::  Add Transfer : response is not Success" + getNestedObject(response, ["status", "Text"]));
                            reject(genericErrorMsg);
                        }
                }

            })
            .catch((error) => {
                if(axios.isCancel(error))
                {
                    console.log(error);
                }else{
                //Log the error on console incase of failure. 
                console.log("Money Movment Serice : Add Transfer :: ", error);
                _logException("Money Movment Serice : Add Transfer :: "+ (error && error.response) ? error.response : "");
                reject(genericErrorMsg);
                //resolve(mmvalidateDummyResponse);
                }
            });


        });

    return addTransferPromise;
    
}

export const getPastTransfersList = (accountsList, dateRangeValue, token, contextJson, profileJson) =>
{
    //Check if cancellation token has value;
    if(getPastTransfersCancellationToken !== null && getPastTransfersCancellationToken !== undefined)
    {
        //Cancel the existing call
        getPastTransfersCancellationToken.cancel('Cancelling the existing past transfers request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getPastTransfersCancellationToken = cancelToken.source(); 

    let getPastTransferPromise = new Promise(
        function (resolve, reject) {
            let pastTransactionsList = [];
            if (accountsList !== null
                && accountsList !== undefined
                && Array.isArray(accountsList) && accountsList.length > 0) {
                let accountStr = getAccountString(accountsList);
                let dateRangeFilter = getNumericDate(dateRangeValue);
                
                let activityUrl = `${activityTransfersUrl}?AccountList=&DateRange=Last,${dateRangeFilter}&SearchBy=Cash,MM&PendingList=No`;
                
                Get(activityUrl, token, contextJson, profileJson, getPastTransfersCancellationToken).then(res => {
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {

                        //get the valid response 
                        console.log("Money Movement Service : Past Transfer List::", res.data.value[0]);
                        let response = res.data.value[0];
                        if (response.activities !== null
                            && response.activities !== undefined
                            && Array.isArray(response.activities)
                            && response.activities.length > 0
                        ) {
                            pastTransactionsList = response.activities;
                            resolve(pastTransactionsList);
                        } else {
                            resolve(pastTransactionsList);
                        }
                    } else {
                        reject(genericErrorMsg);
                    }

                })
                .catch(error => {
                    if(axios.isCancel(error))
                    {
                        console.log(error);
                    }else{
                        console.log("Money movement service :: getPastTransfer ", error);
                        _logException("Money movement service :: getPastTransfer "+ (error && error.response) ? error.response : " ");
                        reject(genericErrorMsg);
                    }
                    
                });


            } else {
                console.log("Money movement service :: input parameter is not valid");
                _logException("Money movement service :: input parameter is not valid");
                reject(genericErrorMsg);
            }
        });

    return getPastTransferPromise;
}

export const getScheduleTransfersList = (accountsList, dateRangeValue, token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if(getScheduleTransfersCancellationToken !== null && getScheduleTransfersCancellationToken !== undefined)
    {
        //Cancel the existing call
        getScheduleTransfersCancellationToken.cancel('Cancelling the existing schedule transfers request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getScheduleTransfersCancellationToken = cancelToken.source();


    let getScheduleTransferPromise = new Promise(
        function (resolve, reject) {
            let scheduleTransactionsList = [];
            if (accountsList !== null
                && accountsList !== undefined
                && Array.isArray(accountsList) && accountsList.length > 0) {
                let accountStr = getAccountString(accountsList);
                let dateRangeFilter = getNumericDate(dateRangeValue);
                let activityUrl = `${mmSummaryUrl}?AccountList=${accountStr}&DateRange=Last,${dateRangeFilter}&SearchBy=Cash,MM&PendingList=Yes`;
                //let activityUrl = "https://api.dev.rockco.com/mm/odata/MMSummary?AccountList=RJL000013,RJL000036&DateRange=Last,30&SearchBy=Cash,MM&PendingList=Yes";
                Get(activityUrl, token, contextJson, profileJson, getScheduleTransfersCancellationToken).then(res => {
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {

                        //get the valid response 
                        console.log("Money Movement Service : Schedule Transfer List::", res.data.value[0]);
                        let response = res.data.value[0];
                        if (response.activities !== null
                            && response.activities !== undefined
                            && Array.isArray(response.activities)
                            && response.activities.length > 0
                        ) {
                            scheduleTransactionsList = response.activities;
                            resolve(scheduleTransactionsList);
                        } else {
                            resolve(scheduleTransactionsList);
                        }
                    } else {
                        reject(genericErrorMsg);
                    }

                })
                    .catch(error => {
                        if (axios.isCancel(error)) {
                            console.log(error);
                        } else {
                            console.log("Money movement service :: getScheduleTransfer ", error);
                            _logException("Money movement service :: getScheduleTransfer "+ (error && error.response) ? error.response : "");
                            reject(genericErrorMsg);
                        }
                    });


            } else {
                console.log("Money movement service getScheduleTransfer:: input parameter is not valid");
                _logException("Money movement service getScheduleTransfer:: input parameter is not valid");
                reject(genericErrorMsg);
            }
        });

    return getScheduleTransferPromise;
}

export const cancelScheduleTransfer = (canceRequest, token, contextJson, profileJson) =>
{

    //Check if cancellation token has value;
    if(cancelScheduleTransferCancellationToken !== null && cancelScheduleTransferCancellationToken !== undefined)
    {
        //Cancel the existing call
        cancelScheduleTransferCancellationToken.cancel('Cancelling the existing cancel schedule transfer request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    cancelScheduleTransferCancellationToken = cancelToken.source(); 

    let cancelScheduleTransferPromise = new Promise(
        function (resolve, reject) {
            let url = addTransferUrl;
            if(canceRequest !== null && canceRequest !== undefined)
            {
                let cancelPayload = getCancelTransferReq(canceRequest);
                Post(url, cancelPayload, token, contextJson, profileJson, cancelScheduleTransferCancellationToken).then(res => {
                    console.log("Cancel Transfer response: ",res);
                    if(res.data !== null  && res.data !== undefined && res.data.value !== null && res.data.value !== undefined 
                        && Array.isArray(res.data.value) && res.data.value.length > 0 )
                    {
                         let response  = res.data.value[0];
                         if(response.status !== null
                            && response.status !== undefined
                            && response.status.Code !== null && response.status.Code == "0")
                            {
                                resolve(res.data.value[0]);
                            }else{
                                console.log("Money movement service ::  Cancel Transfer : response is not Success");
                                reject(genericErrorMsg);
                            }                       
                        
                    }else{
                        console.log("Money movement service ::  Cancel Transfer : response is not in proper format");
                        _logException("Money movement service ::  Cancel Transfer : response is not in proper format");
                        reject(genericErrorMsg);
                    }
                }).catch(err => {
                    if(axios.isCancel(err))
                    {
                        console.log(err);
                    }else{
                        console.log("Money movement service :: Cancel Transfer :", err);
                        _logException("Money movement service :: Cancel Transfer :"+ (err && err.response) ? err.response : "");
                        reject(genericErrorMsg);
                    }
                })
            }
        });

    return cancelScheduleTransferPromise;    
}

export const getHolidayList = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (getHolidayListCancellationToken !== null && getHolidayListCancellationToken !== undefined) {
        //Cancel the existing call
        getHolidayListCancellationToken.cancel('Cancelling the existing holiday list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getHolidayListCancellationToken = cancelToken.source();


    let getHolidayListPromise = new Promise(
        function (resolve, reject) {
            let holidayList = [];
            let holidayListUrl = `${mmSummaryUrl}?SearchBy=Holiday&DateRange=Years,1`;
            Get(holidayListUrl, token, contextJson, profileJson, getHolidayListCancellationToken).then(res => {
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {

                        //get the valid response 
                        console.log("Money Movement Service : Holiday List::", res.data.value[0]);
                        let response = res.data.value[0];
                        if (response.holidays !== null
                            && response.holidays !== undefined
                            && Array.isArray(response.holidays)
                            && response.holidays.length > 0
                        ) {
                            holidayList = response.holidays;
                            resolve(holidayList);
                        } else {
                            resolve(holidayList);
                        }
                    } else {
                        reject(genericErrorMsg);
                    }

                })
                    .catch(error => {
                        if (axios.isCancel(error)) {
                            console.log(error);
                        } else {
                            console.log("Money movement service :: getHolidayList ", error);
                            _logException("Money movement service :: getHolidayList " + (error && error.response) ? error.response : "");
                            reject(genericErrorMsg);
                        }
                    });


           
        });

    return getHolidayListPromise;
}

export const getWithHoldingInfo = (transferObj, eligibleAcctPair, token, contextJson, profileJson) => {
    //Check if cancellation token has value;
    if(getWithHoldingInfoCancellationToken !== null && getWithHoldingInfoCancellationToken !== undefined)
    {
        //Cancel the existing call
        getWithHoldingInfoCancellationToken.cancel('Cancelling the existing with holding request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getWithHoldingInfoCancellationToken = cancelToken.source(); 

    let getWithholdPromise = new Promise(
        function (resolve, reject) {
            let transferDetails = getTransferJson(transferObj, eligibleAcctPair, true);

            let url = mmvalidateUrl;
            Post(url, transferDetails, token, contextJson, profileJson, getWithHoldingInfoCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Money Movement Service : Get Withholdings ::", res);

                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {

                    //get the valid response 
                    let response = res.data.value[0];
                    if(response.status !== null
                        && response.status !== undefined
                        && response.status.Code !== null && response.status.Code === "0")
                        {
                            resolve(res.data.value[0]);
                        }else{
                            console.log("Money movement service ::  Get Withholdings : response is not Success");
                            _logException("Money movement service ::  Get Withholdings : response is not Success" + getNestedObject(response, ["status", "Text"]));
                            reject(genericErrorMsg);
                        }
                }

            })
            .catch((error) => {
                if(axios.isCancel(error))
                {
                    console.log(error);
                }else{
                    //Log the error on console incase of failure. 
                    console.log("Money Movment Serice : Get Withholdings :: ", error);
                    _logException("Money Movment Serice : Get Withholdings:: "+ (error && error.response) ? error.response : "");
                    reject(genericErrorMsg);
                }
            });
        }
    );

    return getWithholdPromise;

}

