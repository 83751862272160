import React from 'react'
import Watermark from '../../../common/watermark';
import ConfirmationModal from '../confirmationModal';

export default function NextGenAdvisoryView(props) {

    const projectYouSectionText = 'Personalized 1:1 advising with seasoned executives to help young professionals gain a competitive advantage through finding clarity and discernment on a career path that aligns with their purpose, building the necessary skills to secure their ideal job or internship and ultimately accelerate within that role.';
    const pinnacleCareSectionText = 'Access to mental health and wellness resources and referrals, providing solutions to health care challenges and global access to top specialists and hospitals.';

    return (
        <div className="container">
            {props.isProspectClient ? <Watermark /> : ''}
            <img id="health_image" src="./../images/nextGen_landing_img.png" alt="Health" width="100%" className="RCM_health_base_image" />
            <div>
                <div className="row RCM_health_container">
                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast RCM_health_toast" id="RCM_nextGenAdvisory_projectYou">
                            <div className="RCM_lifestyle_projectYou"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">PROJECT YOU</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">{projectYouSectionText}</div>
                            <div className="mt-4" align="center" className="RCM_lifestyle_btns">
                                <button className="btn btn-primary RCM_button_aviation_page"
                                    disabled={props.isProspectClient}
                                    onClick={() => props.openProjectYou()}>
                                    CLICK HERE TO VIEW PROJECT YOU
                                    </button>
                            </div>
                        </div>

                    </div>

                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast RCM_health_toast" id="RCM_nextGenAdvisory_pinnacleCare">
                            <div className="RCM_lifestyle_pinnacleCare"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">PINNACLE CARE</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">{pinnacleCareSectionText}</div>
                            <div className="mt-4" align="center" className="RCM_lifestyle_btns">
                                <button className="btn btn-primary RCM_button_aviation_page"
                                    disabled={props.isProspectClient}
                                    onClick={() => props.openPinnacleCare()}>
                                    CLICK HERE TO VIEW PINNACLE CARE
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ConfirmationModal
                confirmationModalToggle={props.confirmationModalToggle}
                confirmationModalState={props.confirmationModalState}
                openSelectedSectionWindow={props.openSelectedSectionWindow}
                selectedSection={props.selectedSection}
            />

        </div>

    )
}
