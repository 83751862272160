import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';
import { fetchPersonalisationData } from "../../../actions/personalisationAction";
import { Get, Post } from '../../../common/servicecalls';
import { ProFileView } from './profileView.jsx';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import { MessageType, MessageClass, GetPreferencePilotValue  } from '../../../common/constants.jsx';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { userinfo } from './../../../../App.js'
//Component used to update the RCM client profile in Self Service
export class ChangeProfile extends Component {


    constructor(props) {
        super(props)
        this.state = {
            userProfileLoaded: false,
            userProfileUpdateFailed: false,
            error: "",
            firstName: this.props.firstname,
            lastName: this.props.lastname,
            primaryPhone: this.props.phone,
            secondaryPhone: "",
            emailAddress: this.props.emailprimary,
            messages: [],
            emailError: 'none',
            primaryPhoneError: 'none',
            secondaryPhoneError: 'none',
            disabled: false,
            isProfileUpdated: false,
            showMFALink: true,
            showOtpComponent:this.taxDocumentsViewPreference(this.props),
            showAuthenticationMethods: this.showAuthenticationMethods(this.props)
        }

        this.updateProfile = this.updateProfile.bind(this);
        this.handleLastName = this.handleLastName.bind(this);
        this.handlePrimaryEmailChange = this.handlePrimaryEmailChange.bind(this);
        this.handleSecondaryEmailChange = this.handleSecondaryEmailChange.bind(this);
        this.handlePrimaryPhone = this.handlePrimaryPhone.bind(this);
        this.handleSecondaryPhone = this.handleSecondaryPhone.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearData = this.clearData.bind(this);


    }
    cancellationToken = null;
    componentDidMount() {
        console.log(JSON.stringify(this.props.profilejson));
        let userKey = userinfo;
        let user = [];
        if (userKey) {
            user = JSON.parse(userKey);
        }
        if (user) {
            var useremail = user.userName;
            console.log(useremail);
            if (useremail.toString().includes("gmail.com")) {
                this.setState({ showMFALink: false });
            } else {
                this.setState({ showMFALink: true });
            }

        }
        //this.getProfile();
    }

    getProfile = () => {
        const profileUrl = this.props.baseUrl + "profileapi/odata/profile";
        this.cancellationToken = axios.CancelToken.source();
        Get(`${profileUrl}` + "(\'" + this.props.userName + ",true" + "\')", this.props.token, this.props.contextjson, this.props.profilejson, this.cancellationToken)
            .then(
                data => {
                    this.props.fetchPersonalisationData(data.data[0].profile);
                    let profileData = data.data[0].profile;
                    this.setState({
                        firstName: profileData.firstname,
                        lastName: profileData.lastname,
                        emailAddress: profileData.emailprimary,
                        primaryPhone: profileData.phone
                    })
                })
            .catch(error => {
                console.log('making false - profile');
                console.log(error);
                this.setState({ error: error, isprofsuccess: false })
            });
    };


    updateProfile = () => {
        this.setState({ isProfileUpdated: false, messages: [], disabled: true }, () => {
            this.updateProfileCallBack();
        });
    }


    updateProfileCallBack = () => {
        const profileUrl = this.props.baseUrl + "profileapi/odata/profile";
        this.cancellationToken = axios.CancelToken.source();
        let reqObj = JSON.parse(JSON.stringify(this.props.profileReq));
        //let reqObj = this.props.profileReq;
        reqObj.phone = this.state.primaryPhone;
        reqObj.emailprimary = this.state.emailAddress;
        let messages = [];
        let requestBody = {
            profile: reqObj
        }
        Post(profileUrl, requestBody, this.props.token,
            this.props.contextjson, this.props.profilejson, this.cancellationToken)
            .then(res => {
                if (res.status === 200) {
                    //this.props.fetchPersonalisationData(reqObj);
                    messages.push({
                        type: MessageType.SUCCESS,
                        text: 'Profile is updated successfully.',
                        class: MessageClass.SUCCESS
                    })
                    this.setState({
                        messages: messages
                        , disabled: false
                    });
                } else {
                    messages.push({
                        type: MessageType.INFO,
                        text: 'Profile is not submitted.',
                        class: MessageClass.ERROR
                    })
                    this.setState({
                        messages: messages
                        , disabled: false
                    });
                }
            }).catch(error => {
                console.log('making false - profile');
                console.log(error);
                messages.push({
                    type: MessageType.INFO,
                    text: 'Profile is not submitted.',
                    class: MessageClass.ERROR
                })
                this.setState({
                    messages: messages
                    , disabled: false
                });
            });
        this.setState({ isProfileUpdated: true })
    }


    openMFALink(url){
        window.open(url);
    }




    handleChange(event) {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }


    clearData() {
        this.setState({
            primaryPhone: '',
            secondaryPhone: '',
            primaryPhoneError: 'none',
            secondaryPhoneError: 'none'
        });
    }
    taxDocumentsViewPreference(preference) {
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'TaxDownload', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }

    showAuthenticationMethods(preference) {
        let rcmcomp = false;
        let result = GetPreferencePilotValue(preference, 'MultifactorAuthMethods', false);
        rcmcomp = result && result === "true" ? true : false;
        return rcmcomp;
    }

    //Get Client Profile details
    //Display the User Profile information on UI
    render() {

        return (
            <ErrorBoundary>
                <ProFileView
                    state={this.state}
                    updateProfile={this.updateProfile}
                    clearData={this.clearData}
                    handleChange={this.handleChange}
                    handlePrimaryEmailChange={this.handlePrimaryEmailChange}
                    handleSecondaryPhone={this.handleSecondaryPhone}
                    handlePrimaryPhone={this.handlePrimaryPhone}
                    openMFALink={this.openMFALink}
                />
            </ErrorBoundary>

        );
    }

    //Handle input changes
    handleFirstName(event) {
        this.setState({ firstname: event.target.value });
    }

    handleLastName(event) {
        this.setState({ lastname: event.target.value });
    }

    handlePrimaryEmailChange(event) {
        const val = event.target.value;
        if (val != '') {
            if (this.validateEmailId(val)) {
                this.setState({
                    userProfileUpdateFailed: true,
                    primaryemailid: val,
                    emailError: ''
                });
            }
            else {
                this.setState({
                    userProfileUpdateFailed: false,
                    error: "",
                    primaryemailid: val,
                    emailError: 'none'

                });
            }
        }
        else {
            this.setState({
                emailError: 'none'
            });
        }
    }

    handleSecondaryEmailChange(event) {
        const val = event.target.value;
        if (this.validateEmailId(val)) {
            this.setState({
                userProfileUpdateFailed: true,
                error: "Given Alternate Email Address is not valid",
                alternateemailid: val
            });
        }
        else {
            this.setState({
                userProfileUpdateFailed: false,
                error: "",
                alternateemailid: val

            });
        }
    }

    handlePrimaryPhone(event) {

        const val = event.target.value;

        if (val != '') {
            if (!this.validatePhoneNumber(val)) {

                this.setState({
                    userProfileUpdateFailed: true,
                    primaryPhoneError: 'none',
                    // error: "Given Primary Phone Number is not valid",
                    primaryphonenumber: val
                });
            }
            else {
                this.setState({
                    userProfileUpdateFailed: false,
                    primaryPhoneError: '',
                    error: "",
                    primaryphonenumber: val

                });
            }
        }
        else {
            this.setState({
                primaryPhoneError: 'none',

            });

        }
    }

    handleSecondaryPhone(event) {

        const val = event.target.value;
        if (val != '') {
            if (!this.validatePhoneNumber(val)) {

                this.setState({
                    userProfileUpdateFailed: true,
                    secondaryPhoneError: 'none',
                    // error: "Given Secondary Phone Number is not valid",
                    secondaryphonenumber: val
                });
            }
            else {
                this.setState({
                    userProfileUpdateFailed: false,
                    secondaryPhoneError: '',
                    error: "",
                    secondaryphonenumber: val

                });
            }
        }
        else {
            this.setState({
                secondaryPhoneError: 'none',
            });

        }
    }

    validatePhoneNumber(phonenumber) {
        var isInvalid = false;
        var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (phoneRegex.test(phonenumber)) {
            isInvalid = false
        } else {
            isInvalid = true
        }
        return isInvalid;
    }

    validateEmailId(emailid) {
        var isInvalid = false;
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailid && pattern.test(emailid)) {
            isInvalid = false;
        }
        else {
            isInvalid = true;
        }
        return isInvalid;
    }

}
const mapStateToProps = (store) => {
    return {
        profileResp: store.acct.profileResp,
        firstname: store.personalisationData.personalisationData === undefined ? store.acct.profileResp.firstname : store.personalisationData.personalisationData.firstname,
        lastname: store.acct.profileResp === undefined ? "" : store.acct.profileResp.lastname,
        emailprimary: store.acct.profileResp === undefined ? "" : store.acct.profileResp.emailprimary,
        profileReq: store.acct.profileResp === undefined ? "" : store.acct.profileResp,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        phone: store.personalisationData.personalisationData === undefined ? store.acct.profileResp.phone : store.personalisationData.personalisationData.phone,
        preferences: store.acct.preferences
    };
}
const mapDispatchToProps = {
    fetchPersonalisationData
}
const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({pageName: 'Profile'})
)

export default enhancement(ChangeProfile);
//export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfile);