import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function HealthConfirmationModal(props) {

    const headerData = 'Private Healthcare Advisory provided by PinnacleCare';
    const contentData = 'By clicking on the “Continue” button below, you will be transferred to a third party website which is owned, operated and maintained by PinnacleCare. If you have any questions, please contact your Financial Advisor.';

    return (
        <div>
                <Modal isOpen={props.healthModalState} toggle={props.healthModalToggle} 
                     className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                     centered >
                <ModalHeader toggle={props.healthModalToggle}>{headerData}</ModalHeader>
                        <ModalBody>
                            <div>
                                 {contentData}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                    <Button color="btn btn-secondary RCM_button_secondary RCM_select_btn" onClick={props.healthModalToggle}>Close</Button>
                            <Button color="btn btn-primary RCM_button_primary RCM_select_btn" onClick={props.openSelectedSectionWindow}>Continue</Button>
                        </ModalFooter>
                    </Modal>
            </div>
    )
}
