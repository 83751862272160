import React, { Component } from 'react';
import { connect } from 'react-redux';
import ManageAssetsDetails from './manageassetsdetails.jsx';
import AddEditAssetDetails from './addeditassetdetails.jsx';
import { updateAccountSelector } from '../../../../actions/accountsactions.jsx';
import { getUserProfile } from '../../../../services/home/userServices.jsx';
import { getAssetListService } from './manageassetsservice.jsx';
import { MessageType, MessageClass} from '../../../../common/constants.jsx';
import Messages from '../../../../common/messages.jsx';

class ManageAssets extends Component {

    constructor(props) {
        super(props);

        this.closeAddEdittoggle = this.closeAddEdittoggle.bind(this);
        this.openAddEdittoggle = this.openAddEdittoggle.bind(this);
        this.closeAddEditAfterSave = this.closeAddEditAfterSave.bind(this);
        this.edit = this.edit.bind(this);
        this.updateProfileDataInStore = this.updateProfileDataInStore.bind(this);
        this.openLinkedAccountForEdit = this.openLinkedAccountForEdit.bind(this);
        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }
        this.profileId = "";
        if (this.props.profileId !== null && this.props.profileId !== undefined) {
            this.profileId = this.props.profileId;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            saveSuccess: false,
            showSaveStatusMsg: false,
            addEditEnabled: this.props.addEditEnabled ? this.props.addEditEnabled : false,
            mode: this.props.mode ? this.props.mode : 'add',
            accountDetailsForEdit: this.props.accountDetailsForEdit ? this.props.accountDetailsForEdit : {},
            hideLabel: this.props.hideLabel ? this.props.hideLabel : false,
            messages: []
        };
    }
    openAddEdittoggle() {
        this.setState({ addEditEnabled: true, mode: 'add', accountDetailsForEdit: {} })
    }
    closeAddEdittoggle() {
        //check if we came from liability 
        if(this.props.hideLabel){
             this.props.closeAddEdittoggle();
        }else{
            this.setState({ addEditEnabled: false })
        }
        
    }
    openLinkedAccountForEdit(key) {
        //this.setState({ addEditEnabled: false, linkedAccount: key })
        this.setState({  loadingManageAsset: true }, () => {
            let messages = [];
            getAssetListService(this.authToken, this.contextjson, this.profilejson, this.profileId, key)
                .then(manageAssetData => {
                    
                    if (manageAssetData
                        && manageAssetData.manualassetdetails
                        && manageAssetData.manualassetdetails.accounts
                        && manageAssetData.manualassetdetails.accounts.length >= 0
                        && manageAssetData.manualassetdetails.accounts[0]) {
                        this.setState({ addEditEnabled: false }, () => {
                            this.edit(manageAssetData.manualassetdetails.accounts[0].account);
                            this.setState({ loadingManageAsset: false, messages: messages });
                        });
                        
                    } else {
                        messages.push({
                            type: MessageType.ERROR,
                            text: 'Unable to open the linked account, please try again later.',
                            class: MessageClass.ERROR
                        })
                        this.setState({ loadingManageAsset: false, messages: messages, addEditEnabled: false });
                    }
                    
                })
                .catch(error => {
                    console.error(error);
                    messages.push({
                        type: MessageType.ERROR,
                        text: 'Unable to open the linked account, please try again later.',
                        class: MessageClass.ERROR
                    })
                    this.setState({ loadingManageAsset: false, messages: messages, addEditEnabled: false });
                })
        })
        
    }
    closeAddEditAfterSave(flag) {
        this.setState({ addEditEnabled: false, saveSuccess: flag, showSaveStatusMsg: true });

    }
    edit(account) {
        var accountDetailsForEdit = { account: account, type: '' };
        this.setState({ mode: 'edit', accountDetailsForEdit: accountDetailsForEdit, addEditEnabled: true })
    }
    updateProfileDataInStore(selectorUpdateReason, accountkey="") {
        getUserProfile(this.authToken).then(res => {
            if (res && res.data && Array.isArray(res.data) && res.data[0]) {
                if (selectorUpdateReason === "DELETE_ACCOUNT_IN_MANUAL_ASSET" || selectorUpdateReason === "ADD_ACCOUNTS_IN_MANUAL_ASSET")
                    this.props.updateAccountSelector(res.data[0].profile, selectorUpdateReason, res.headers.profilejson, accountkey);
                else
                    this.props.updateAccountSelector(res.data[0].profile, selectorUpdateReason, res.headers.profilejson);
            }
        }).catch(err => {
            console.log('Error While Fetching Profile Data', err);
        })
    }
    render() {
        return (
                <div  id="RCM_ManageGroups">
                <div className="row RCM_ManageGroup_heading_container">
                    <div className="RCM_Toast_heading col-sm-12">
                        <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" >
                                {this.state.hideLabel || 'MANAGE OTHER ACCOUNTS'}</label>
                        </div>
                    </div>
                {
                    this.state.messages && this.state.messages.length > 0
                            ? <div className="row RCM_Feedback_Message_Margin" key="edelivery03">
                                <div className="col-sm-12 col-xs-12 col-md-11 col-lg-11" key="edelivery04">
                                <Messages messages={this.state.messages}
                                        containerClass={
                                            this.state.messages && this.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                                ? "RCM_validation_error_container"
                                                : ""
                                        } />
                                </div>

                            </div>
                            : ""
                }
                    {(!this.state.addEditEnabled) ?

                        <ManageAssetsDetails
                        state={this.state}
                        openAddEdittoggle={this.openAddEdittoggle}
                        closeAddEdittoggle={this.closeAddEdittoggle}
                        edit={this.edit}
                        updateProfileDataInStore={this.updateProfileDataInStore}
                /> :
                    <AddEditAssetDetails
                        state={this.state}
                        openAddEdittoggle={this.openAddEdittoggle}
                        closeAddEdittoggle={this.closeAddEdittoggle}
                        closeAddEditAfterSave={this.closeAddEditAfterSave}
                        updateProfileDataInStore={this.updateProfileDataInStore}
                        openLinkedAccountForEdit={this.openLinkedAccountForEdit}
                    />
                    }
                    
                </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId
    };
};

const mapDispatchToProps = {
    updateAccountSelector
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAssets);