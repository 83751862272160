import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { spinner, MsgForNoData, MsgForFailedData, getYearList } from './../../../../../common/constants.jsx';
import { securityColumns, monthDropDownItems, yearDropDownItems } from '../realizedgainlosscolumns.jsx';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Messages from '../../../../../common/messages';
import Watermark from '../../../../../common/watermark';
export function SecurityHoldingsView(
    props
) {
    let securityHoldingview = (
        <div className="conatiner-fluid RCM_Toast" key="secdetails1">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row RCM_AccountActivityDetails_Message_Div" >
                <div className="col-sm-7">
                    <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                </div>
            </div>
            <div className="row RCM_text_hd">
                <div className="col-sm-2" id="RCM_realizedgainlossdetailsfilter">
                    <div className="RCM_text RCM_CM_form_label RCM_text_textdropdown RCM_Inline_Div">
                        <label>YEAR :</label>
                    </div>
                    <div className="RCM_Inline_Div">
                        <Dropdown
                            isOpen={props.state.yearDropdownOpen}
                            toggle={props.toggleYear}
                            className="RCM_select_box RCM_mm_subselect_box dropdown"
                            key="addropdown1">
                            <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                {
                                    props.state.dropdownyearValue
                                }
                            </DropdownToggle>
                            <DropdownMenu >
                                {getYearList('2018').map((item, index) =>
                                    <DropdownItem key={index} onClick={() => props.changeYear(item)} disabled={props.state.isProspectClient}>
                                        <div>{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-sm-2 RCM_AccountActivityDetail_Date_Div" id="RCM_activitydetailsdatefilter">
                
                </div>
                <div className="col-sm-8"></div>
            </div>
            {
                (() => {
                    if (props.state.requestFailed) {
                        return (
                            <div className="row" id="RCM_RealizedGainLossSecurityHoldingTable">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Realized_SecurityHolding_Wrapper RCM_Tables">
                            <BootstrapTable key='btsecurity'
                                keyField='UNQID'
                                data={[]}
                                classes="RCM_two_level_table1"
                                        columns={securityColumns('', '', '', '','','', [], props.state.isrowexpanded, props.rowsortevent)}
                                noDataIndication={MsgForFailedData}
                                expandRow={props.expandRowBySecurity}
                                bordered={false}
                            /></div></div>)
                       
                    }
                    else if (props.state.isloading) {
                        return <div className="RCM_spinner">Loading Realized Gain Loss By Security {spinner()}</div>
                    }
                    else { 
                        if (props.state.holdingsresponsebysecurity && props.state.holdingsresponsebysecurity.length > 0 && !props.state.isloading) {
                            return (
                                <div className="row" id="RCM_RealizedGainLossSecurityHoldingTable">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Realized_SecurityHolding_Wrapper RCM_Tables"><BootstrapTable key='btsecurity'
                                keyField='UNQID'
                                data={props.securityData}
                                classes="RCM_two_level_table1"
                                        columns={securityColumns(props.totalST, props.totalLT, props.totalProceeds, props.totalglamount, props.totalglpct, props.totalcostbasis, props.securityData, props.state.isrowexpanded, props.rowsortevent)}
                                noDataIndication={MsgForNoData}
                                expandRow={props.expandRowBySecurity}
                                bordered={false}
                                 /></div></div>)
                        }
                        else {
                            return (
                                <div className="row" id="RCM_RealizedGainLossSecurityHoldingTable">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Realized_SecurityHolding_Wrapper RCM_Tables"> <BootstrapTable key='btsecurity'
                                keyField='UNQID'
                                data={props.securityData}
                                classes="RCM_two_level_table1"
                                        columns={securityColumns( props.totalST, props.totalLT,props.totalProceeds, props.totalglamount, props.totalglpct, props.totalcostbasis, props.securityData, props.state.isrowexpanded, props.rowsortevent)}
                                 noDataIndication={MsgForNoData}
                                expandRow={props.expandRowBySecurity}
                                bordered={false}
                                    /></div></div>)
                          
                        }
                    }
                })()
            }
        </div>
    )
    return securityHoldingview
}
