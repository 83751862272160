import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ProofLoyaltyConfirmationModal from "./proofLoyaltyConfirmationModel";

const ProofLoyaltyContainer = () => {
  const proofLoyalityText =
    "A leading wine-appreciation platform offering a single source for Rockefeller clients interested in fine wines, insider offers and access to unique experiences. ProofLoyalty works directly with wineries, providing access to allocated, reserve, library and collector-level wines. A portion of profits through the Rockefeller Live Well Do Good Membership Program are dedicated to the World Ocean Foundation. ";
  
  const proofLoyaltyURL = useSelector((store) => store?.env?.proofLoyaltyURL)
  const [isProofLoyaltyModalOpen, setProofLoyaltyModalOpen] = useState(false);
  const handleViewProofLotaltyeClick = useCallback(() => {
    setProofLoyaltyModalOpen(true);
  }, []);

  const handleProofLoyaltyConfirmClick = useCallback(() => {
    window.open(proofLoyaltyURL)
    setProofLoyaltyModalOpen(false);
  }, [proofLoyaltyURL]);

  const toogleProofLoyaltyModal = useCallback(() => {
    setProofLoyaltyModalOpen((prev) => !prev);
  }, []);

  return (
    <div className="container">
      <img
        id="proofloyalty_image"
        src="./../images/Proofloyalty.png"
        alt="Proof Loyalty"
        width="100%"
        className="RCM_health_base_image"
      />
      <div>
        <div className="row RCM_health_container">
          <div className="col col-sm-12 mt-1">
            <div
              className="RCM_Toast RCM_health_toast"
              id="RCM_proofloyalty_details"
            >
              <div className="RCM_lifestyle_homeThrive"></div>
              <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
                PROOF LOYALTY
              </div>
              <div className="mt-2 RCM_aviation_toast_content_container">
                {proofLoyalityText}
              </div>
              <div className="mt-4 RCM_lifestyle_btns" align="center">
                <button
                  className="btn btn-primary RCM_button_aviation_page"
                  onClick={handleViewProofLotaltyeClick}
                >
                  CLICK HERE TO VIEW PROOF LOYALTY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isProofLoyaltyModalOpen && (
        <ProofLoyaltyConfirmationModal
          isOpen={isProofLoyaltyModalOpen}
          toogleModal={toogleProofLoyaltyModal}
          onContinueClick={handleProofLoyaltyConfirmClick}
        />
      )}
    </div>
  );
};

export default ProofLoyaltyContainer;
