import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function InspiratoConfirmationModal({
  isOpen,
  toogleModal,
  onContinueClick,
}) {
  const headerData = "Travel Concierge provided by Inspirato";
  const contentData =
    "By clicking on the “Continue” button below, you will be transferred to a third party website which is owned, operated and maintained by Inspirato. If you have any questions, please contact your Advisor.";

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toogleModal}
        className="RCM_CM_modal"
        wrapClassName="RCM_CM_modal_wrapper"
        modalClassName="RCM_CM_modal_class"
        contentClassName="RCM_CM_modal_content"
        centered
      >
        <ModalHeader toggle={toogleModal}>{headerData}</ModalHeader>
        <ModalBody>
          <div>{contentData}</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="btn btn-secondary RCM_button_secondary RCM_select_btn"
            onClick={toogleModal}
          >
            Close
          </Button>
          <Button
            color="btn btn-primary RCM_button_primary RCM_select_btn"
            onClick={onContinueClick}
          >
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
