import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DocumentListURL, DocumentURL, GetAccountsList, StichedPdfDocumentURL, CheckIfProspectClient } from './../../../common/constants.jsx';
import { AddTaxDocuments, AddAccountStatements, AddCorrespondenceDocuments, AddTradeConfirmDocuments } from './../../../actions/documentactions.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { TaxDocumentView } from './taxdocumentview.jsx'
import axios from 'axios';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import getSearchDate from '../searchdates.js';
import { printDocument } from './../../../common/printdocument.jsx';
import { downloadDocument } from './../../../common/downloaddocument.jsx';
import { _logException, _logInformation } from "../../../common/_logging.js";
import { withPageView } from '../../../hoc/withPageView.jsx';
import taxdocumentmockresponse from './prospecttaxdocumentmockresponse.js';
import pdfData from './../mockpdfresponse.js'
import dateFormat from "dateformat";
class TaxDocuments extends Component {

    constructor(props) {
        super(props);

        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        //this.accounts.push({ number: "RKZ000097", accountregistration: "", i: null });

        for (var i = 0; i < 7; i++) {
            //minimum year for tax document search is set as 2018
            if (new Date().getFullYear() - i >= 2018) {
                this.dateRangeDropDownItems.push({
                    label: (new Date().getFullYear() - i).toString(),
                    value: (new Date().getFullYear() - i).toString()
                })
            }
        }

        this.state = {
            documentResponse: null,
            pdfData: null,
            objectIds: [],
            documentList: [],
            filteredDocumentList: [],
            filteredDocumentListModal: [],
            documentListColumns: this.documentListColumns,
            accounts: this.accounts,
            selectedAccount: '',
            dropdownOpen: false,
            dateRangeDropdownOpen: false,
            dropdownValue: '',
            dropdownDispValue: '',
            dateRangeDropdownValue: '',
            selectedDateRange: '',
            dateRangeValue: '',
            isCustomDateRangeSelected: false,
            areDocumentsRetrieved: false,
            selected: [],
            modal: false,
            isDocumentSelected: false,
            documentsUploaded: true,
            startDate: new Date(),
            endDate: new Date(),
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
            isDocumentResponseWithNoData: false,
            isDocumentResponseWithError: false,
            render: this.props.render,
            messages: [],
            primaryValue: [],
            documentType: "Select Type",
            documentTypeDropdoenOpen: false,
            modalType: "",
            errorMessage: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.changeAccount = this.changeAccount.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.dateRangeDropdownToggle = this.dateRangeDropdownToggle.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
        this.execute = this.execute.bind(this);
    }
    documentList = [];
    documentCanellationToken = null;
    taxDocumentToken = null;
    accountStatementCancellationToken = null;
    correspondenceCancellationToken = null;
    tradeConfirmCancellationToken = null;

    documentCanellationTokenModal = null;
    dateRangeDropDownItems = [
        {
            label: 'Select Range',
            value: 'Select Range'
        }];


    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.accounts = [];
                this.accounts = GetAccountsList(this.props.selectedaccount);

                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                if (accountNumbers.length > 0) {
                    this.setState({
                        dropdownValue: this.accounts[0].number,
                        dropdownDispValue: this.accounts[0].accountdisplayname,
                        dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                        areDocumentsRetrieved: false,
                        accounts: this.accounts,
                        pdfData: null,
                        isDocumentSelected: false,
                        isDocumentResponseWithNoData: false,
                        isDocumentResponseWithError: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: false,
                        isNonRockActSelected: false
                    });
                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_TAX_DOCUMENTS',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': '',
                        'SearchDateEnd': '',
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody);
                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true,
                        documentList: [],
                        filteredDocumentList: [],
                        filteredDocumentListModal: [],
                        isDocumentListResponseWithNoData: true,
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithError: false,
                    });
                }
            }

        }
    }

    execute = (requestBody) => {
        let url = DocumentListURL;
        let i = 1;
        let documentList = [];
        if (this.taxDocumentToken) {
            this.taxDocumentToken.cancel('Operation canceled due to new request.')
        }
        this.taxDocumentToken = axios.CancelToken.source();
        Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.taxDocumentToken)
            .then(res => {
                if (res.status === 200) {
                    const documentResponse = res.data;
                    let corrected = ''
                    if (documentResponse) {
                        documentResponse && documentResponse.value[0].DocumentList.map(document => {
                            if (document.CorrectionInd === 'N')
                                corrected = 'Original'
                            else if (document.CorrectionInd === 'U')
                                corrected = 'Duplicate'
                            else if (document.CorrectionInd === 'Y')
                                corrected = 'Correction'
                            else
                                corrected = 'Something Wrong'
                            var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
                            var correspondenceDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                            documentList.push({
                                "id": i,
                                "primaryValue": document.PrimaryValue,
                                "objectId": document.ObjectId,
                                "description": document.DisplayName,
                                "corrected": corrected,
                                "date": correspondenceDate,
                                "streamid": document.DocumentRenditionList.length > 0 ?
                                    document.DocumentRenditionList[0].StreamId
                                    : "",
                                "mimetype": (document.DocumentRenditionList && Array.isArray(document.DocumentRenditionList) && document.DocumentRenditionList.length > 0) ?
                                    document.DocumentRenditionList[0].MimeType
                                    : ""
                            });
                            i++;
                        });
                        let taxDocumentList = documentList;
                        if (taxDocumentList && taxDocumentList.length > 0) {
                            this.props.AddTaxDocuments(taxDocumentList, taxDocumentList);
                            this.setState({
                                documentList: taxDocumentList,
                                filteredDocumentList: taxDocumentList,
                                filteredDocumentListModal: taxDocumentList,
                                areDocumentsRetrieved: true,
                                isDocumentListResponseWithError: false,
                            });
                            _logInformation("Tax Document: Documentlist fetched")
                        } else {
                            this.props.AddTaxDocuments([], null);
                            this.setState({
                                documentList: [],
                                filteredDocumentList: [],
                                filteredDocumentListModal: [],
                                isDocumentListResponseWithNoData: true,
                                areDocumentsRetrieved: false,
                                isDocumentListResponseWithError: false,
                            });
                        }
                    } else {
                        this.props.AddTaxDocuments(null, null);
                        this.setState({
                            documentList: [],
                            filteredDocumentList: [],
                            filteredDocumentListModal: [],
                            isDocumentListResponseWithNoData: true,
                            areDocumentsRetrieved: false,
                            isDocumentListResponseWithError: false,
                        });
                    }

                } else {
                    this.props.AddTaxDocuments(null, null);
                    this.setState({
                        isDocumentListResponseWithNoData: true,
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithError: false,
                    });
                }

            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    _logException("Tax Document : Fetching documentlist: " + (error && error.response) ? error.response : "");
                    console.log(error);
                    this.setState({
                        areDocumentsRetrieved: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: true
                    });
                }
            });
    };
   
    componentWillUnmount() {
        if (this.taxDocumentToken) {
            this.taxDocumentToken.cancel('Operation canceled due to new request.')
        }
        if (this.documentCanellationTokenModal) {
            this.documentCanellationTokenModal.cancel('Operation canceled due to new request.')
        }
        if (this.accountStatementCancellationToken) {
            this.accountStatementCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.tradeConfirmCancellationToken) {
            this.tradeConfirmCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.correspondenceCancellationToken) {
            this.correspondenceCancellationToken.cancel('Operation canceled due to new request.')
        }
    }
    componentDidMount() {
        //Put Page view
        /*   let pageViewData = new PageView();
          pageViewData.name = "Tax Documents";
          pageViewData.url = "/TaxDocuments";
          _logPageView(pageViewData); */

        //if statements are already available in global store, use them
        if (this.state.isProspectClient) {
            this.SetDummyResponseForProspectClient();
        }
        else {
            if (this.props.taxDocumentList) {
                this.setState({
                    documentList: this.props.taxDocumentList,
                    filteredDocumentList: this.props.taxDocumentList,
                    filteredDocumentListModal: this.props.taxDocumentList,
                    areDocumentsRetrieved: true,
                });
            } else {    //if statements are not available, make a service call and store them in global store
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                if (accountNumbers.length > 0) {
                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_TAX_DOCUMENTS',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': '',
                        'SearchDateEnd': '',
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody);
                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true
                    });
                }

            }
            this.setState({
                dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
                dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
                dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                selectedAccount: 'All Accounts',
                selectedDateRange: 'Select Range'
            });
        }
    }
    getFormattedDate(date) {
        return dateFormat(date, "mm/dd/yyyy")
    }
    changeDateRange(selectedDateRange) {
        let dateRange = this.dateRangeDropDownItems.find(item => item.label === selectedDateRange.currentTarget.textContent.trim()).value;
        this.setState({
            dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
            dateRangeValue: dateRange
        });
        if (dateRange !== 'Select Range') {
            var dt = getSearchDate(dateRange);
            var startDate = dt.startDate;
            var endDate = dt.endDate
            var searchBeginDate = this.getFormattedDate(startDate);
            var searchEndDate = this.getFormattedDate(endDate);
            this.setState({
                pdfData: null,
                isDocumentSelected: false,
                selected: [],
                areDocumentsRetrieved: false,
                isDocumentListResponseWithNoData: false,
                isDocumentListResponseWithError: false,
                dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                dateRangeValue: dateRange,
                startDate: startDate,
                endDate: endDate
            });
            let accountNumbers = [];
            this.state.accounts.map(acct => {
                if (acct.number !== 'All Accounts') {
                    if (acct.isInternal) {
                        accountNumbers.push(acct.number);
                    }
                }
            });
            let requestBody = JSON.stringify({
                'VirtualTable': 'NF_TAX_DOCUMENTS',
                'PrimaryType': 'Account',
                'PrimaryValue': accountNumbers,
                'SearchDateComparison': 'R',
                'SearchDateBegin': searchBeginDate,
                'SearchDateEnd': searchEndDate,
                'SearchDateEndSpecified': true,
                'SearchAllVersions': true,
                'IncludeRelationships': false,
                'RenditionFilter': '*',
                'DateRange': '',

            });
            this.execute(requestBody);
        }
    }
    changeAccount(selectedAccount) {
        this.setState({
            pdfData: null,
            dropdownValue: selectedAccount.number,
            isDocumentSelected: false,
            selected: []
        });
        let accountNumber = selectedAccount.number;
        let docList = this.state.documentList.filter(doc => doc.primaryValue === accountNumber);
        this.props.AddTaxDocuments(this.props.taxDocumentList, docList);
        if (accountNumber !== 'All Accounts') {
            this.setState({
                filteredDocumentList: docList,
                filteredDocumentListModal: docList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        } else {
            this.setState({
                filteredDocumentList: this.state.documentList,
                filteredDocumentListModal: this.state.documentList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        }
    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    dateRangeDropdownToggle() {
        this.setState(prevState => ({
            dateRangeDropdownOpen: !prevState.dateRangeDropdownOpen
        }));
    }
    selectRowfromModal = (row, isSelect) => {
        let obj = {}
        obj[row.primaryValue] = row.objectId;
        let primaryValArry = this.state.primaryValue;
        let index = primaryValArry.findIndex(x => x[row.primaryValue] === row.objectId)
        if (isSelect) {
            if (index < 0) {
                primaryValArry.push(obj)
            }
        } else {
            primaryValArry.splice(index, 1);
        }

        this.setState({
            primaryValue: primaryValArry
        })

    }

    selectAllDocument = (isSelect, rows, e) => {
        rows.map((row) => {
            this.selectRowfromModal(row, isSelect)
        })

    }
    selectDocument(row, isSelect) {
        if (this.state.isProspectClient) {
            this.setState({
                pdfData: pdfData,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
        }
        else {
            this.setState({
                pdfData: null,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
            let requestBody = JSON.stringify({
                'VirtualTable': 'NF_TAX_DOCUMENTS',
                'PrimaryType': 'Account',
                'PrimaryValue': row.primaryValue,
                'ObjectId': row.objectId,
            });

            let url = DocumentURL;
            if (this.documentCanellationToken) {
                this.documentCanellationToken.cancel('Operation canceled due to new request.')
            }
            this.documentCanellationToken = axios.CancelToken.source();
            Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.documentCanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        let selectedDocPdfData = res && res.data && res.data.value[0].ContentStream;
                        if (selectedDocPdfData !== "") {
                            this.setState({
                                pdfData: selectedDocPdfData
                            })
                        } else {
                            this.setState({
                                pdfData: null,
                                isDocumentResponseWithNoData: true
                            })
                        }
                    } else {
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithNoData: true
                        })
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //_logException("Tax Document : Fetching document: " + (error && error.response) ? error.response : "");
                        console.log(error);
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithError: true
                        })
                    }
                });
        }
    }
    options = [];
    toggleModel = (event) => {
        let id = event.target.id
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalType: id,
            errorMessage: "",
            documentType: "Select Type",
            primaryValue: [],
            filteredDocumentListModal: this.state.filteredDocumentList
        }))
    }
    printModalDocument = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + '/' + dd + '/' + yyyy;
        let errorMessage = "";
        let requestBody = JSON.stringify({
            'VirtualTable': 'NF_TAX_DOCUMENTS',
            'PrimaryType': 'Account',
            'PrimaryValue': this.state.primaryValue
        });

        let url = StichedPdfDocumentURL;
        let documentCanellationTokenModal = null;
        if (documentCanellationTokenModal) {
            documentCanellationTokenModal.cancel('Operation canceled due to new request.')
        }
        documentCanellationTokenModal = axios.CancelToken.source();
        this.setState({
            documentsUploaded: false,
            errorMessage: ""
        })
        Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, documentCanellationTokenModal)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData !== "") {
                    if (this.state.modalType === "download") {
                        downloadDocument(selectedDocPdfData, null, "TaxDocumentDownload" + "_" + today)
                    } else if (this.state.modalType === "print") {
                        printDocument(selectedDocPdfData)
                    }
                } else {
                    if (this.state.primaryValue.length > 1) {
                        errorMessage = "Unable to process the request. Please try selecting fewer documents to process."

                    } else {
                        errorMessage = "Unable to process the request. Please retry your operation.";
                    }
                    console.log("didn't receive document from webapi")
                }
                this.setState({
                    documentsUploaded: true,
                    errorMessage: errorMessage
                })
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log(error);
                    let fewRecMsg = this.state.primaryValue && Array.isArray(this.state.primaryValue) && this.state.primaryValue.length > 100
                        ? ' If the problem continues, please try selecting fewer documents and retry your action.' : '';
                    let errMsg = "Unable to process the request. Please retry your operation." + fewRecMsg;
                    this.setState({
                        documentsUploaded: true,
                        errorMessage: errMsg
                    })
                }

            });

    }

    selectType = (selectedType) => {
        let filteredData = []
        selectedType.currentTarget.textContent === "Select Type"
            ? filteredData = this.state.filteredDocumentList
            : filteredData = this.state.filteredDocumentList.filter(doc => doc.description === selectedType.currentTarget.textContent)
        this.setState({
            documentType: selectedType.currentTarget.textContent,
            filteredDocumentListModal: filteredData
        });
    }

    documentTypeToggle = () => {
        this.setState(prevState => ({
            documentTypeDropdoenOpen: !prevState.documentTypeDropdoenOpen
        }));
    }

    SetDummyResponseForProspectClient() {
        let i = 1;
        let documentList = [];
        let corrected = '';
        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        if (taxdocumentmockresponse) {
            taxdocumentmockresponse && taxdocumentmockresponse.value[0].DocumentList.map(document => {
                if (document.CorrectionInd === 'N')
                    corrected = 'Original'
                else if (document.CorrectionInd === 'U')
                    corrected = 'Duplicate'
                else if (document.CorrectionInd === 'Y')
                    corrected = 'Correction'
                else
                    corrected = 'Something Wrong'
                var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
                var correspondenceDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                documentList.push({
                    "id": i,
                    "primaryValue": document.PrimaryValue,
                    "objectId": document.ObjectId,
                    "description": document.DisplayName,
                    "corrected": corrected,
                    "date": correspondenceDate,
                    "streamid": document.DocumentRenditionList[0].StreamId
                });
                i++;
            });
            let taxDocumentList = documentList;
            if (taxDocumentList && taxDocumentList.length > 0) {
                this.props.AddTaxDocuments(taxDocumentList, taxDocumentList);
                this.setState({
                    documentList: taxDocumentList,
                    filteredDocumentList: taxDocumentList,
                    areDocumentsRetrieved: true,
                    isDocumentListResponseWithError: false,
                });
                _logInformation("Tax Document: Documentlist fetched")
            } else {
                this.setState({
                    documentList: [],
                    filteredDocumentList: [],
                    isDocumentListResponseWithNoData: true,
                    areDocumentsRetrieved: false,
                    isDocumentListResponseWithError: false,
                });
            }
            this.setState({
                dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
                dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
                dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                selectedAccount: 'All Accounts',
                selectedDateRange: 'Select Range'
            });
        }
    }

    render() {
        console.log("Inside render")
        let documents = [];
        this.state.accounts.map(acct => {
            documents = this.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number);
            this.options.push({
                custom: true,
                totalSize: documents.length < 11 ? 0 : documents.length
            })
        });
        let authprops = {
            token: this.props.token,
            contextjson: this.props.contextjson,
            profilejson: this.props.profilejson,
            portalid: this.props.portalid
        }
        let flags = [], output = [], account_length = this.props.taxDocumentList && this.props.taxDocumentList.length > 0 ? this.props.taxDocumentList.length : 0;
        let array = JSON.parse(JSON.stringify(this.props.taxDocumentList))
        for (var i = 0; i < account_length; i++) {
            if (flags[array[i].description])
                continue;
            flags[array[i].description] = true;
            output.push({ label: array[i].description, value: array[i].description });
        }
        let documentTypeDropDownItems = [{
            label: 'Select Type',
            value: 'Select Type'
        }, ...output]
        return (
            <ErrorBoundary>
                <TaxDocumentView
                    state={this.state}
                    options={this.options}
                    dateRangeDropDownItems={this.dateRangeDropDownItems}
                    selectDocument={this.selectDocument}
                    toggle={this.toggle}
                    changeAccount={this.changeAccount}
                    dateRangeDropdownToggle={this.dateRangeDropdownToggle}
                    changeDateRange={this.changeDateRange}
                    SearchDocuments={this.SearchDocuments}
                    authprops={authprops}
                    selectedaccounts={this.props.selectedaccount}
                    toggleModel={this.toggleModel}
                    selectRowfromModal={this.selectRowfromModal}
                    selectAllDocument={this.selectAllDocument}
                    printModalDocument={this.printModalDocument}
                    documentTypeDropDownItems={documentTypeDropDownItems}
                    selectType={this.selectType}
                    documentTypeToggle={this.documentTypeToggle}
                    role={this.props.role}
                />

            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        taxDocumentList: state.doc.taxDocumentList,
        filteredTaxDocumentList: state.doc.filteredTaxDocumentList,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        portalid: state.acct.portalid,
        preferences: state.acct.preferences,
        role: state.acct.role
    };
};
const mapDispatchToProps = {
    AddTaxDocuments,
    AddCorrespondenceDocuments,
    AddAccountStatements,
    AddTradeConfirmDocuments
}
//Tax Documents
const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Tax Documents' })
)
export default enhancement(TaxDocuments);
