import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AlertView } from './alertdetailsview.jsx'
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { _logException, _logInformation, _logPageView } from "../../../../common/_logging.js";
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { alertsDetailsService, alertReadService } from './alertdetailservices.jsx';
import { CheckIfProspectClient, MessageClass, MessageType } from '../../../../common/constants.jsx';
import { addAlertNotificationDetails } from './../../../../actions/accountsactions';
import { alertSummaryResponse, RRR_123456_alertSummaryResponse, RRR_23456_alertSummaryResponse } from './../../../accounts/accountsmockresponse';
import cloneDeep from 'lodash/cloneDeep';
import OverlayProcessing from '../../../MoneyMovement/common/overlayProcessing';

class AlertDetails extends Component {

    constructor(props) {
        super(props);
        this.accounts = [];
        this.state = {
            showOverlay: false,
            isAlertSelected: false,
            selected: [],
            isAlertResponseWithNoData: false,
            isAlertResponseWithError: false,
            alertList: [],
            alertresponse: 'Please select alert to see the message',
            dismissedAlerts: [],
            isLoading: true,
            isRequestFailed: false,
            alertDetailsResponse: null,
            isUnreadAlertFound: true,
            isProspectClient: CheckIfProspectClient(this.props.role),
            messages: [],
            rowdata: null
        }

        this.selectAlert = this.selectAlert.bind(this);
        this.execute = this.execute.bind(this);
        this.SetAllAlertsAsRead = this.SetAllAlertsAsRead.bind(this);
        this.setReadAlert = this.setReadAlert.bind(this);
        this.setReadAllAlert = this.setReadAllAlert.bind(this);
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
    }

    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.execute();
            }
        }
    }

    checkifUnreadAlertExist(data) {
        const res = data.filter(alert => alert.isread === false);
        if (res && res.length > 0) {
            return true;
        }
        else {
            return false;
        }

    }

    execute = () => {
        this.setState({ isLoading: true, isRequestFailed: false }, () => {
            alertsDetailsService(this.authToken, this.contextjson, this.profilejson)
                .then(response => {
                    if (response && response.data && response.data.alertdetails && response.data.alertdetails.length > 0) {
                        this.setState({
                            alertDetailsResponse: response.data,
                            isLoading: false,
                            isRequestFailed: false,
                            alertList: response.data && response.data.alertdetails ? response.data.alertdetails : [],
                            selected: response.data && response.data.alertdetails && response.data.alertdetails.length && response.data.alertdetails[0].isread === false ? [] : [response.data.alertdetails[0].alertid],
                            alertresponse: ((response.data && response.data.alertdetails && response.data.alertdetails.length > 0) ? (response.data.alertdetails[0].isread === false ? 'Please select alert to see the message' : response.data.alertdetails[0].alertdesc) : ''),
                            isAlertSelected: true,
                            isUnreadAlertFound: this.checkifUnreadAlertExist(response.data && response.data.alertdetails && response.data.alertdetails.length > 0 ? response.data.alertdetails : []),
                            rowdata: response && response.data.alertdetails && response.data.alertdetails.length && response.data.alertdetails[0].isread === false ? null : response.data.alertdetails[0]

                        }, () => {
                            this.props.addAlertNotificationDetails(response.data.alertdetails);
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            requestFailed: false,
                            rowdata: [],
                            isAlertSelected: false,
                            alertList: []
                        });
                    }

                })
                .catch(error => {
                    console.error(error);
                    this.setState({ isLoading: false, isRequestFailed: true });
                })
        })


    };

    componentWillUnmount() {

    }

    componentDidMount() {
        document.dispatchEvent(new CustomEvent('removeSelection', { bubbles: true }));
        document.dispatchEvent(new CustomEvent('removeSelectionUsersetting', { bubbles: true }));
        if (this.state.isProspectClient) {
            this.SetDummyResponseForProspectClient();
        }
        else {
            this.execute();
        }
    }

    selectAlert(row, isSelect) {
        if (row.isread === false) {
            let alertRequest = {
                "alertcontextid": this.state.alertDetailsResponse && this.state.alertDetailsResponse.alertcontextid ? this.state.alertDetailsResponse.alertcontextid : "",
                "markreadall": false,
                "alertdetails": [
                    {
                        "alertid": row.alertid ? row.alertid : ''
                    }
                ]
            }
            if (this.state.isProspectClient) {
                this.setState({
                    isLoading: false,
                    isRequestFailed: false,
                    showOverlay: false
                })
            }
            else {
                var indexOfAlert = -1;
                this.state.alertDetailsResponse && this.state.alertDetailsResponse.alertdetails && this.state.alertDetailsResponse.alertdetails.findIndex(function (obj, i) {
                    if (obj.alertid === row.alertid) {
                        indexOfAlert = i;
                    }
                });
                if (indexOfAlert > -1) {
                    this.setState({
                        showOverlay: true,
                        isAlertSelected: true,
                        rowdata: row

                    }, () => {
                        this.setReadAlert(alertRequest, indexOfAlert, row.alertid);

                    });
                }


            }
        }
        else {
            this.setState({
                isAlertSelected: true,
                selected: [row.alertid], alertresponse: row.alertdesc,
                rowdata: row
            });
        }
        //api to save read notification
    }

    setReadAlert(alertRequest, indexOfAlert, alertid) {
        const clonedresponse = cloneDeep(this.state.alertDetailsResponse);
        clonedresponse.alertdetails[indexOfAlert].isread = true;
        alertReadService(alertRequest, this.authToken, this.contextjson, this.profilejson)
            .then(response => {
                if (indexOfAlert >= 0) {
                    this.setState(prevState => ({
                        showOverlay: false,
                        selected: [alertid],
                        alertresponse: clonedresponse.alertdetails[indexOfAlert].alertdesc,
                        alertDetailsResponse: clonedresponse,
                        alertList: clonedresponse.alertdetails
                    }), () => {
                        document.dispatchEvent(new CustomEvent('descreaseCount', { bubbles: true }));
                        this.props.addAlertNotificationDetails(clonedresponse.alertdetails);
                    })
                }
                else {
                    this.setState({
                        isLoading: false,
                        isRequestFailed: false
                    })
                }

            })
            .catch(error => {
                console.error(error);
                let messages = [];
                messages.push({
                    type: MessageType.INFO,
                    text: "Message read operation failed.Please try again",
                    class: MessageClass.ERROR
                });

                this.setState({
                    showOverlay: false,
                    isAlertSelected: true,
                    alertresponse: "Please select alert to see the message",
                    messages: messages,
                    rowdata: null

                });

            })
    }

    SetAllAlertsAsRead() {
        let alertRequest = {
            "alertcontextid": this.state.alertDetailsResponse && this.state.alertDetailsResponse.alertcontextid ? this.state.alertDetailsResponse.alertcontextid : "",
            "markreadall": true,
            "alertdetails": null
        }
        if (this.state.isProspectClient) {
            this.setState({
                isLoading: false,
                isRequestFailed: false,
                showOverlay: false,
            })
        }
        else {
            this.setState({
                showOverlay: true,
                isAlertSelected: true,


            }, () => {
                this.setReadAllAlert(alertRequest);

            });

        }

    }
    getRowDataForMarkRadAll(alertid, clonedresponse) {
        var res = clonedresponse.alertdetails.filter(alert => alert.alertid === this.state.selected[0]);
        if (res && res.length) {
            return res[0];
        }
        return null;
    }
    setReadAllAlert(alertRequest) {
        const clonedresponse = cloneDeep(this.state.alertDetailsResponse);
        for (let i = 0; i < clonedresponse.alertdetails.length; i++) {
            clonedresponse.alertdetails[i]['isread'] = true;
        }
        alertReadService(alertRequest, this.authToken, this.contextjson, this.profilejson)
            .then(response => {
                this.setState(prevState => ({
                    showOverlay: false,
                    selected: this.state.selected.length > 0 ? [this.state.selected[0]] : [clonedresponse.alertdetails[0].alertid],
                    alertresponse: this.state.alertresponse !== "Please select alert to see the message" ? this.state.alertresponse : clonedresponse.alertdetails[0].alertdesc,
                    alertDetailsResponse: clonedresponse,
                    alertList: clonedresponse.alertdetails,
                    rowdata: this.state.selected.length > 0 ? this.getRowDataForMarkRadAll(this.state.selected[0], clonedresponse) : [clonedresponse.alertdetails[0].alertid],
                }), () => {
                    document.dispatchEvent(new CustomEvent('descreaseCount', { bubbles: true }));
                    this.props.addAlertNotificationDetails(clonedresponse.alertdetails);
                })
            })
            .catch(error => {
                console.error(error);
                let messages = [];
                messages.push({
                    type: MessageType.INFO,
                    text: "Message Read All operation failed.Please try again",
                    class: MessageClass.ERROR
                });

                this.setState({
                    showOverlay: false,
                    isAlertSelected: true,
                    selected: this.state.selected.length > 0 ? [this.state.selected[0]] : [],
                    rowdata: null,
                    alertresponse: this.state.alertresponse !== "Please select alert to see the message" ? this.state.alertresponse : "Please select alert to see the message",
                    messages: messages

                });

            })
    }

    SetDummyResponseForProspectClient() {
        let response = [];
        response = alertSummaryResponse;
        if (this.props.selectedaccount.length == 1) {
            if (this.props.selectedaccount[0].key === "RRR-123456") {
                response = RRR_123456_alertSummaryResponse;
            }
            else if (this.props.selectedaccount[0].key === "RRR-234567") {
                response = RRR_23456_alertSummaryResponse;
            }

        } else if (this.props.selectedaccount.length == 2) {
            response = alertSummaryResponse;
        }

        this.setState({
            alertDetailsResponse: response,
            isLoading: false,
            isRequestFailed: false,
            alertList: response && response.alertdetails ? response.alertdetails : [],
            selected: response && response.alertdetails && response.alertdetails.length && response.alertdetails[0].isread === false ? [] : [response.alertdetails[0].alertid],
            alertresponse: ((response && response.alertdetails && response.alertdetails.length > 0) ? (response.alertdetails[0].isread === false ? 'Please select alert to see the message' : response.alertdetails[0].alertdesc) : ''),
            isAlertSelected: true,
            isUnreadAlertFound: this.checkifUnreadAlertExist(response && response.alertdetails && response.alertdetails.length > 0 ? response.alertdetails : []),
            isLoading: false,
            requestFailed: false,
            rowdata: response && response.alertdetails && response.alertdetails.length && response.alertdetails[0].isread === false ? null : response.alertdetails[0]
        }, () => {
            this.props.addAlertNotificationDetails(response.alertdetails);
        });


    }
    render() {
        console.log("Inside render");
        const rowClasses = (row, rowIndex) => {
            let classes = null;

            if (row && row.isread === false) {
                classes = 'RCM_Bold';
            }

            return classes;
        };

        let authprops = {
            token: this.props.token,
            contextjson: this.props.contextjson,
            profilejson: this.props.profilejson,
            portalid: this.props.portalid
        }

        return (
            <ErrorBoundary>
                {this.state.showOverlay && (<OverlayProcessing />)}
                <AlertView state={this.state}
                    selectAlert={this.selectAlert}
                    authprops={authprops}
                    alertList={this.state.alertList}
                    alertresponse={this.state.alertresponse}
                    accounts={this.props.accounts}
                    SetAllAlertsAsRead={this.SetAllAlertsAsRead}
                />
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        portalid: state.acct.portalid,
        role: state.acct.role,
        alertList: state.acct.alertList,
        accounts: state.acct.accounts
    };
};
const mapDispatchToProps = {
    addAlertNotificationDetails
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'alerts' })
)


export default enhancement(AlertDetails);