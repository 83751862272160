import React from "react";

class JournalConfirmation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="RCM_CM_form">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="RCM_Toast_heading">TRANSFER FUNDS</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 RCM_Toast_sub_heading RCM_heading_light">
                        Transfer Request Submitted
                            </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        Please allow up to 48 hours for the requested transfer to complete.
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-12 RCM_CM_btn_long" align="center">
                        <button
                            className="btn RCM_button_primary RCM_select_btn"
                            onClick={this.props.backToLending}
                        >
                            START ANOTHER TRANSFER
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default JournalConfirmation;
