import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import EstimatedIncomeForAccount from './estimatedincomeaccount/estimatedincomeaccount.jsx';
import EstimatedIncomeForSecurity from './estimatedincomesecurity/estimatedincomesecurity.jsx';
import { tabSelection, tabSelectionOnLoad } from './../../../../common/constants.jsx';
import { _logPageView } from '../../../../common/_logging';
import { withPageView } from '../../../../hoc/withPageView.jsx';
import EstimatedIncomeSummary from './estimatedincomesummary/estimatedincomesummary.jsx';
import ProjectedIncomeForAccount from './../projectedincome/projectedincomeaccount/projectedincomeaccount.jsx';
import ProjectedIncomeForSecurity from './../projectedincome/projectedincomesecurity/projectedincomesecurity.jsx';
import ProjectedIncomeSummary from './../projectedincome/projectedincomesummary/projectedincomesummary.jsx';
class EstimatedIncomeDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);
       
        this.state = {
            tabsLinks:[]
        };
    }
    handleSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
    }

    GetCurrentRoute() {
        let currentroute = '/EstimatedIncomeSummary';
        if (this.props.location && this.props.location.pathname) {
            if (this.props.location.pathname === '/ProjectedIncomeSummary') {
                currentroute = '/EstimatedIncomeSummary';
            }
            else if(this.props.location.pathname === '/ProjectedIncomeSecurity') {
                currentroute = '/EstimatedIncomeSecurity';
            }
            else if (this.props.location.pathname === '/ProjectedIncomeAccount') {
                currentroute = '/EstimatedIncomeAccount';
            }
                
        }
        return currentroute; 
    }
    componentDidMount() {
      
           
        const currentRoute = this.props.location.pathname;

        if (this.props.togglevalue ||(currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity' || currentRoute === '/EstimatedIncomeAccount' || currentRoute === '/EstimatedProjectedIncomeDetails')) {
            let links = [{ tabUrl: '/EstimatedIncomeSummary', active: false, displayName: 'SUMMARY', id: 'RCM_EstimatedIncomeForSummary' },
            { tabUrl: '/EstimatedIncomeAccount', active: false, displayName: 'ACCOUNT', id: 'RCM_EstimatedIncomeForAccount' },
            { tabUrl: '/EstimatedIncomeSecurity', active: false, displayName: 'SECURITY', id: 'RCM_EstimatedIncomeForSecurity' }
            
            ]

            for (const [index, component] of links.entries()) {
                if (component.displayName === 'SUMMARY' && (currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedProjectedIncomeDetails')) {
                    component.active = true;
                }
                else if (component.displayName === 'ACCOUNT' && (currentRoute === '/EstimatedIncomeAccount')) {
                    component.active = true;
                } 
                else if (component.displayName === 'SECURITY' && (currentRoute === '/EstimatedIncomeSecurity')) {
                    component.active = true;
                }
                
            }
            this.setState({ tabsLinks: links });
        }
        else {
            if (this.props.togglevalue) this.props.toggleView();
            let links = [{ tabUrl: '/ProjectedIncomeSummary', active: false, displayName: 'SUMMARY', id: 'RCM_ProjectedIncomeForSummary' },
            { tabUrl: '/ProjectedIncomeAccount', active: false, displayName: 'ACCOUNT', id: 'RCM_ProjectedIncomeForAccount' },
            { tabUrl: '/ProjectedIncomeSecurity', active: false, displayName: 'SECURITY', id: 'RCM_ProjectedIncomeForSecurity' }
            ];
            for (const [index, component] of links.entries()) {
                if (component.displayName === 'SUMMARY' && (currentRoute === '/ProjectedIncomeSummary')) {
                    component.active = true;
                } 
                else if (component.displayName === 'ACCOUNT' && (currentRoute === '/ProjectedIncomeAccount')) {
                    component.active = true;
                }
                else if (component.displayName === 'SECURITY' && (currentRoute === '/ProjectedIncomeSecurity')) {
                    component.active = true;
                }
            }
            this.setState({ tabsLinks: links });
           

        }

    }
   
    render() {
        const estimatedincomedetailsview = (
            <div>
                <div key="dvholdingdetailsview" className="row" >
                    <div className="col-sm-12" key="lbholdingdetailviewby">
                        <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding" >

                            <ul className="nav nav-tabs">
                                {this.state.tabsLinks && this.state.tabsLinks.map((tabLink, index) => {
                                    return <li className="nav-item active" id={tabLink.id} key={index}>
                                        <NavLink exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                            onClick={this.handleSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                                    </li>
                                })}
                            </ul>
                        </div>
                        {
                            this.props.togglevalue ?
                                <div>
                                    <Switch>
                                        <Route key='root' exact path="/EstimatedProjectedIncomeDetails" render={() => <Redirect to="/EstimatedIncomeSummary" />} />
                                        <Route key='root1' exact path="/ProjectedIncomeSummary" render={() => <Redirect to="/EstimatedIncomeSummary" />} />
                                        <Route key='root2' exact path="/ProjectedIncomeAccount" render={() => <Redirect to="/EstimatedIncomeAccount" />} />
                                        <Route key='root3' exact path="/ProjectedIncomeSecurity" render={() => <Redirect to="/EstimatedIncomeSecurity" />} />
                                        <Route key='estimatedincomesummary' exact path="/EstimatedIncomeSummary" component={EstimatedIncomeSummary} />
                                        <Route key='estimatedincomeaccount' exact path="/EstimatedIncomeAccount" component={EstimatedIncomeForAccount} />
                                        <Route key='estimatedincomesecurity' exact path='/EstimatedIncomeSecurity' component={EstimatedIncomeForSecurity} />
                                       
                                    </Switch>
                                </div>
                                :
                                <div>
                                    <Switch>
                                        <Route key='root' exact path="/EstimatedProjectedIncomeDetails" render={() => <Redirect to="/ProjectedIncomeSummary" />} />
                                        <Route key='root1' exact path="/EstimatedIncomeSummary" render={() => <Redirect to="/ProjectedIncomeSummary" />} />
                                        <Route key='root2' exact path="/EstimatedIncomeAccount" render={() => <Redirect to="/ProjectedIncomeAccount" />} />
                                        <Route key='root3' exact path="/EstimatedIncomeSecurity" render={() => <Redirect to="/ProjectedIncomeSecurity" />} />
                                        <Route key='projectedincomesummary' exact path="/ProjectedIncomeSummary" component={ProjectedIncomeSummary} />
                                        <Route key='projectedincomeaccount' exact path="/ProjectedIncomeAccount" component={ProjectedIncomeForAccount} />
                                        <Route key='projectedincomesecurity' exact path='/ProjectedIncomeSecurity' component={ProjectedIncomeForSecurity} />
                                    </Switch>
                                </div>
                        }
                        

                    </div>
                </div>


            </div>);
        return (
            <BrowserRouter>{estimatedincomedetailsview}</ BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        acct: state.acct,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};

const enhancement = compose(
    withPageView({pageName: 'Security Holdings'})
)
export default enhancement(withRouter(connect(mapStateToProps)(EstimatedIncomeDetails)));