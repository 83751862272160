

export function AccountInformationView(
    props
) {
    let accountInformationview = (
      <div key="AF010">
        <div className="RCM_Toast" id="RCM_ach_info_section" key="AF011">
          <div className="row" key="AF012">
            <div className="col-sm-12" key="AF013">
              ACH Information
            </div>
          </div>

          {props.state.selectedAccount &&
          props.state.selectedAccount.accountkey &&
          props.state.accountInfo ? (
            <div className="row RCM_AI_Inner_container">
              {props.state.selectedAccount &&
              props.state.selectedAccount.abanumber ? (
                <div className="col-sm-12" key="AF0131">
                  ABA routing number:{" "}
                  <span>
                    {props.state.selectedAccount &&
                      props.state.selectedAccount.abanumber}
                  </span>
                </div>
              ) : (
                ""
              )}
              {props.state.selectedAccount &&
              props.state.selectedAccount.accountnumber ? (
                <div
                  className="col-sm-12 RCM_AI_Checking_AC_container"
                  key="AF0132"
                >
                  <div>Account number:&nbsp;</div>
                  {props.state.showUnmaskedCheckingAccountNo ? (
                    <div
                      className="RCM_icon_hand"
                      onClick={() => props.showUnMaskedCheckingNumber(false)}
                    >
                      {props.state.selectedAccount &&
                        props.state.selectedAccount.accountnumber}{" "}
                    </div>
                  ) : (
                    <div
                      className="RCM_icon_hand"
                      onClick={() => props.showUnMaskedCheckingNumber(true)}
                    >
                      {props.state.selectedAccount &&
                        props.state.selectedAccount.maskedCheckingAccount}{" "}
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-sm-12">
                  The selected account does not have checking account features.
                  If that is needed, please contact your Advisor team.
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="RCM_Toast" id="RCM_wire_info_section" key="AF014">
          <div className="row" key="AF015">
            <div className="col-sm-12" key="AF016">
              Incoming Wire Information
            </div>
          </div>
          {props.state.selectedAccount &&
          props.state.selectedAccount.accountkey &&
          props.state.accountInfo ? (
            <div className="row RCM_AI_Inner_container">
              <div className="col-sm-12" key="AF0135">
                ABA routing number:{" "}
                <span>{props.state.accountInfo.Wire.ABAroutingnumber}</span>
              </div>
              <div className="col-sm-12">
                For international USD, use SWIFT code:{" "}
                <span>{props.state.accountInfo.Wire.SwiftCode}</span>
              </div>
              <div className="col-sm-12" key="AF0133">
                Wire to:{" "}
                <span className="RCM_AI_Inner_text">
                  {props.state.accountInfo.Wire.wireto}
                </span>
              </div>
              <div className="col-sm-12" key="AF0134">
                Bank address:{" "}
                <span>{props.state.accountInfo.Wire.bankaddress}</span>
              </div>

              <div className="col-sm-12" key="AF0136">
                Bank account name:{" "}
                <span>{props.state.accountInfo.Wire.Forcreditto}</span>
              </div>
              <div className="col-sm-12" key="AF0137">
                Account number:{" "}
                <span>{props.state.accountInfo.Wire.Accountnumber}</span>
              </div>
              <div className="col-sm-12" key="AF0138">
                For further credit:{" "}
                <span>{props.state.accountInfo.Wire.Forbenefitof}</span>
                  {props.state.selectedAccount &&
                  props.state.selectedAccount.brokerageaccount &&
                    <span className="col-sm-12" key="AF0139">
                        ({props.state.selectedAccount.brokerageaccount})
                    </span>
                 }
              </div>

              <div className="mt-2 ml-3 RCM_CM_linkText_small">
                *For incoming foreign currency wire instructions, please contact
                your Financial Advisor.
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
    return accountInformationview
}

