import React, { Component } from 'react';
import Chart from './chart.jsx';
import { MsgForNoData, formatMoney } from './constants.jsx';
class EstimatedIncomeChartDetails extends Component {

    render() {

        return (
            <div >
                {this.props.datachart && this.props.datachart.length !== 0 ?
                    <div className="row">
                        <div className='col-sm-10'>
                            <Chart data="bar_ProjectedIncome" chartdata={this.props.datachart} />
                        </div>
                        <div className='col-sm-2'>
                            <ul className="RCM_legent_height RCM_CM_scroll">
                                <li key="li2" className={"RCM_aachartWrapper RCM_legend_income"}>
                                    <div className="RCM_assetName"> <span>Interest</span></div>
                                </li>
                                <li key="li1" className={"RCM_aachartWrapper RCM_legend_dividend"} >
                                    <div className="RCM_assetName"><span>Dividend</span></div>
                                </li>
                                {
                                    (this.props.source && this.props.source === 'Estimated Income') &&
                                        (
                                            <li key="li7" className={"RCM_aachartWrapper RCM_legend_capitalgain"} >
                                                <div className="RCM_assetName"><span>Cap Gains Dist.</span></div>
                                            </li>
                                        )
                                }   
                            </ul>

                            <ul className="RCM_legent_height RCM_CM_scroll RCM_Estimation_total_legend">
                                <li key="li3" className={"RCM_aachartWrapper"}>
                                    <div className="RCM_assetName" > <span>Total Interest: {formatMoney(this.props.chartTotal.interest)} </span></div>
                                </li>
                                <li key="li4" className={"RCM_aachartWrapper"}>
                                    <div className="RCM_assetName"><span>Total Dividend: {formatMoney(this.props.chartTotal.dividend)}</span></div>
                                </li>
                                {
                                    (this.props.source && this.props.source === 'Estimated Income') && 
                                        (
                                            <li key="li5" className={"RCM_aachartWrapper"}>
                                            <div className="RCM_assetName"><span>Total Cap Gains Dist.: {formatMoney(this.props.chartTotal.capGain)}</span></div>
                                            </li>
                                        )
                                }
                                
                                <li key="li6" className={"RCM_aachartWrapper"}>
                            <div className="RCM_assetName"><span>Total {(this.props.source && this.props.source === 'Estimated Income') ? 'Est.' : 'Projected'} Income: {formatMoney(this.props.chartTotal.total)}</span></div>
                                </li>
                                
                            </ul>
                        </div>
                        </div> : <div>{MsgForNoData}</div>}
            </div>
        );
    }
}
export default EstimatedIncomeChartDetails;