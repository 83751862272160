import React from 'react';
import { getAccountDisplayName, IndLongShort } from '../../../../common/constants.jsx';
import { formatMoney, roundvalue, toDate } from '../../../../common/constants';

export function LiabilitiesColumns(showManageAssetDetails, selectedAccount) {
    var columns = [];
    columns = [
        {
            dataField: 'asofdate',
            text: "As Of Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{toDate(cell)}</div>;
            }
        },
        {
            dataField: 'customername',
            text: "Customer Name",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{cell}</div>;
            }
        },
        {
            dataField: 'nickname', text: "Account", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div> {acctFormatter(selectedAccount, row.key)}</div>;
            }
        },
        {
            dataField: 'purposeFlag',
            text: "Loan Purpose",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{cell}</div>;
            }
        },
        {
            dataField: 'commitment',
            text: "Commitment",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'outstandingbalance', text: "Outstanding",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right', classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'availablecredit', text: "Available Credit", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'grosscollval', text: "Gross Collateral Value", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                // else return <span>{formatMoney(roundvalue(cell, 2))}</span>;

                else return <div title={row && row.collateralaccounts ? row.collateralaccounts : ''}> <span>{formatMoney(roundvalue(cell, 2))}</span></div>;
            }
        },
        {
            dataField: 'interestrate', text: "Interest Rate", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined || cell === '-') return <span>-</span>;
                if (cell === 0) return <span>0%</span>;
                else return <span>{roundvalue(cell, 2) + '%'}</span>;
            }
        },
        {
            dataField: 'rateindex',
            text: "Rate Index",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{cell}</div>;
            }
        },
        {
            dataField: 'spread', text: "Spread", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined || cell === '-') return <span>-</span>;
                if (cell === 0) return <span>0%</span>;
                else return <span>{roundvalue(cell, 2) + '%'}</span>;
            }
        },
        {
            dataField: 'billedamount', text: "Billed Amount", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'type', text: "Type",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div align="left"><span>{cell}</span></div>;
            }
        },
        {
            dataField: "isdelete",
            isDummyField: true,
            text: "",
            formatter: (cell, row, rowIndex) => actionFormatter(row, showManageAssetDetails)
        }
    ];

    return columns;
}

export function LiabilitiesNotesColumns(){
    var columns = [];
    columns = [
        {
            dataField: 'asofdate',
            text: "As Of Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{toDate(cell)}</div>;
            }
        },
        {
            dataField: 'customername',
            text: "Customer Name",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{cell}</div>;
            }
        },
        {
            dataField: 'loanaccount', text: "Loan Account", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div> {cell}</div>;
            }
        },
        {
            dataField: 'totalcredit',
            text: "Commitment",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'outstandingbalance', text: "Outstanding",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right', classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'availablecredit', text: "Available Credit", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'interestrate', text: "Interest Rate", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined || cell === '-') return <span>-</span>;
                if (cell === 0) return <span>0%</span>;
                else return <span>{roundvalue(cell, 2) + '%'}</span>;
            }
        },
        {
            dataField: 'nextpayment', text: "Payment Due", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                else if (cell === 0) return <div align="right"><span>$0</span></div>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            }
        },
        {
            dataField: 'nextpaymentdate',
            text: "Next Payment Due",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{toDate(cell)}</div>;
            }
        },
        {
            dataField: 'nextratechangedate',
            text: "Next Rate Change Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{toDate(cell)}</div>;
            }
        },
        {
            dataField: 'rateindex',
            text: "Rate Type",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <div align="left"><span>-</span></div>;
                else return <div>{cell}</div>;
            }
        }
    ];

    return columns;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function actionFormatter(row, showManageAssetDetails) {
    return (row && row.accounttype && row.accounttype === 'UserManualAdded') ?
        (<div title="Details" className="RCM_CM_more-icon" onClick={() => showManageAssetDetails(row)}></div>) : (<div></div>)
}

function acctFormatter(accountlist, key) {
    return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}</div>)
}