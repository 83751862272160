import React from 'react';
import { MsgForNoData } from '../../../../common/constants.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Label } from 'reactstrap';

export function AssetTypeCancelConfrimationPopup(props) {

    let assettypecancelconfrimationpopup = (
        <div>
            {props && props.state ?
                <Modal isOpen={props.state.openAssettypecancelconfrimationpopup} toggle={props.toggleAssetTypeCancelConfrimationPopup}
                    className="RCM_CM_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content"
                    zIndex='9999'
                    centered >
                    <ModalHeader toggle={props.toggleAssetTypeCancelConfrimationPopup} className="RCM_externalAssetModel_header RCM_manage_group_header">

                        <label className="RCM_tile_heading">CONFIRMATION</label>
                    </ModalHeader>
                    <ModalBody>
                        {
                            props.state.linkedAccount && props.state.linkedAccount !== null && props.state.linkedAccount !== ''
                                ? <div>
                                    <Label key='linkedAccountMsg'>Opening the linked account, will lose all your unsaved data. Are you sure you want to open?</Label>
                                </div>
                                : <div>
                                    <Label for="groupname">Canceling the asset type, will lose all your unsaved data. Are you sure you want to cancel?</Label>
                                </div>
                        }
                        

                    </ModalBody>
                    <ModalFooter className="RCM_manage_group_footer">
                        <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.toggleAssetTypeCancelConfrimationPopup}>NO</button>
                        <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.cancelAssetType}>YES</button>
                    </ModalFooter>
                </Modal>

                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return assettypecancelconfrimationpopup
}



