import {
    UPDATE_ACCOUNT, ADD_ACTIVITY, ADD_ACTIVITY_DETAILS, ADD_ACTIVITY_DETAILS_FOR_SELECTED_ACCOUNTS, ADD_BALANCES, ADD_TOPTEN, ADD_AA,ADD_BALANCEDETAILS, 
    ADD_ACTIVITYDETAILS, ADD_AADETAILS,  UPDATE_ACCOUNT_NICKNAME,  UPDATE_ACCOUNT_SELECTOR,
    ADD_TOKEN, ADD_HISTORICAL_AADETAILS, ADD_LIABILITIES, GROUPVIEW, ADD_HISTORICAL_SUMMARY_AADETAILS, UPDATE_SUBSCRIBE_ALERTS, ADD_ALERT_NOTIFICATION_DETAILS, ADD_MANUAL_ASSET,
    ADD_AGGREGATED_ACCOUNT, UPDATE_ASSET_SCHEMA_SUMMARY, UPDATE_ASSET_SCHEMA_DETAILS, UPDATE_HISTORICAL_ASSET_SCHEMA, UPDATE_PERFORMANCE_ACCOUNT_DETAILS, UPDATE_PERFORMANCE_SUMMARY_DETAILS, UPDATE_PERFORMANCE_SUMMARY_TABLE, UPDATE_PERFORMANCE_SUMMARY_CHART, ADD_ASSETCOLOR, ADD_HISTORICAL_BALANCE_DETAILS, SET_ACCOUNT_HOLDING
} from '../actions/accountsactions.jsx';

import { UPDATE_AGGREGATED_ACCT, UPDATE_PREFERENCE, UPDATE_APPSTORE } from "../actions/actiontypes";

export function AccountsReducer(state = [], action) {
    switch (action.type) {
        case ADD_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case UPDATE_APPSTORE:
            return {
                ...state,
                accounts: action.payload.accounts,
                groupedAccounts: action.payload.groupedaccounts,
                externalAccounts: action.payload.externalAccounts,
                profileResp: action.payload.profileResp,
                selectedaccount: action.payload.accounts,
                portalid: action.payload.portalid,
                profileId: action.payload.profileId,
                name: action.payload.name,
                fadata: action.payload.fadata,
                token: action.payload.token,
                role: action.payload.role,
                baseurl: action.payload.baseurl,
                contextjson: action.payload.contextjson,
                profilejson: action.payload.profilejson,
                aaduseroid: action.payload.aaduseroid,
                preferences: action.payload.preferences,
                isTokenRefreshed: action.payload.isTokenRefreshed,
                userName: action.payload && action.payload.user && action.payload.user.userName,
                accountList: action.payload.accountList,
                isphilanthropy: action.payload.isphilanthropy,
                simulationAccount: action.payload.simulationAccount
            }
        case UPDATE_ACCOUNT:
            return {
                ...state,
                render: action.render,
                selectedaccount: action.selectedaccount,
                balancessummary: action.balancessummary,
                activitysummary: action.activitysummary,
                activitydetails: action.activitydetails,
                toptensummary: action.toptensummary,
                assetallocationsummary: action.assetallocationsummary,
                holdingsbyaccount: action.holdingsbyaccount,
                holdingsbysecurity: action.holdingsbysecurity,
                balancedetails: action.balancedetails,
                assetallocationdetails: action.assetallocationdetails,
                isAccountContextChanged: action.isAccountContextChanged,
                liabilities: action.liabilities
            }
        case ADD_ACTIVITY:
            return {
                ...state,
                activitysummary: action.activitysummary                
            }
        case ADD_ACTIVITY_DETAILS:
            return {
                ...state,
                activitydetails: action.activitydetails
            }
        case ADD_ACTIVITY_DETAILS_FOR_SELECTED_ACCOUNTS:
            return {
                ...state,
                activitydetailsforselectedaccounts: action.activitydetailsforselectedaccounts
            }
        case ADD_BALANCES:
            return {
                ...state,
                balancessummary: action.balancessummary
            }
        case ADD_TOPTEN:
            return {
                ...state,
                toptensummary: action.toptensummary
            }
        case ADD_AA:
            return {
                ...state,
                assetallocationsummary: action.assetallocationsummary
            }        
        case ADD_AADETAILS:
            return {
                ...state,
                assetallocationdetails: action.assetallocationdetails
            }
        case ADD_HISTORICAL_AADETAILS:
            return {
                ...state,
                historicalAssetAllocationDetails: action.historicalAssetAllocationDetails
            }
        case ADD_HISTORICAL_SUMMARY_AADETAILS:
            return {
                ...state,
                historicalAssetAllocationSummaryDetails: action.historicalAssetAllocationSummaryDetails
            }
        case ADD_HISTORICAL_BALANCE_DETAILS:
            return {
                ...state,
                historicalBalanceDetails: action.historicalBalanceDetails
            }
        case ADD_BALANCEDETAILS:
            return {
                ...state,
                balancedetails: action.balancedetails
            }        
        case ADD_ACTIVITYDETAILS:
            return {
                ...state,
                activitydetails: action.activitydetails
            }        
        case UPDATE_PREFERENCE:
            return {
                ...state,
                preferences: action.preferences
            }

        case UPDATE_AGGREGATED_ACCT:
            return {
                ...state,
                accountList: action.payload.acctList,
                accounts: action.payload.accounts,
                groupedAccounts: action.payload.groupedaccounts,
                selectedaccount: action.payload.accounts,
                profileResp: action.payload.profileResp,
                aggregatedAcctModifiedList: action.payload.aggregatedAcctModifiedList,
                profilejson: action.payload.profilejson
            }
        case UPDATE_ACCOUNT_NICKNAME:
            return {
                ...state,
                accounts: action.accounts,
                updateNickNameFlag: action.updateNickNameFlag
            }

        case UPDATE_ACCOUNT_SELECTOR:
        return {
            ...state,
            profileResp: action.profileObj,
            acctSelectorChangeReason: action.acctSelectorChangeReason,
            accounts: action.accounts,
            deletedManualAccountKey: action.deletedManualAccountKey,
            profilejson: action.profilejson,
        }
        case ADD_LIABILITIES:
            return {
                ...state,
                liabilities: action.liabilities
            }
        case GROUPVIEW:
            return{
                ...state, 
                selectorview: action.payload
            }
        case UPDATE_SUBSCRIBE_ALERTS:
            return {
                ...state,
                subscribeAlerts: action.accountList
            }
        case ADD_ALERT_NOTIFICATION_DETAILS:
            return {
                ...state,
                alertList: action.alertList
            }
        case ADD_MANUAL_ASSET:
            return {
                ...state,
                manualAssetList: action.manualAssetList
            }
        case ADD_AGGREGATED_ACCOUNT:
            return {
                ...state,
                aggregatedAccountList: action.aggregatedAccountList
            }
        case UPDATE_ASSET_SCHEMA_SUMMARY: {
            return {
                ...state,
                assetSchema: action.assetSchema,
                assetallocationdetails: action.assetallocationdetails
            }
        }
        case UPDATE_ASSET_SCHEMA_DETAILS: {
            return {
                ...state,
                assetSchema: action.assetSchema,
                assetallocationsummary: action.assetallocationsummary
            }
        }
        case UPDATE_PERFORMANCE_ACCOUNT_DETAILS: {
            return {
                ...state,
                perfromanceAccounts: action.perfromanceAccounts
            }
        } 
        case UPDATE_PERFORMANCE_SUMMARY_DETAILS: {
            return {
                ...state,
                perfromanceSummaryDetails: action.perfromanceSummaryDetails
            }
        }
        case UPDATE_PERFORMANCE_SUMMARY_TABLE: {
            return {
                ...state,
                perfromanceSummaryTable: action.perfromanceSummaryTable
            }
        }
        case UPDATE_PERFORMANCE_SUMMARY_CHART: {
            return {
                ...state,
                perfromanceSummaryChart: action.perfromanceSummaryChart
            }
        }
        case UPDATE_HISTORICAL_ASSET_SCHEMA: {
            return {
                ...state,
                assetSchema: action.assetSchema,
                historicalAssetAllocationDetails: action.historicalAssetAllocationDetails
            }
        }
        
        case ADD_ASSETCOLOR:
            return {
                ...state,
                assetschemacolorsconfig: action.assetschemacolorsconfig
            }
        case SET_ACCOUNT_HOLDING:
            return {
                ...state,
                newHoldingAccount: action.newHoldingAccount
            }
        default:
            return state;
    }

}