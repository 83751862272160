import React from "react";
import { Spinner } from "reactstrap";
import JournalConfirmation from "./journalConfirmation.jsx";
import { addTransfer, addTransferCancellationToken } from "./moneymovementService.jsx";
import { connect } from 'react-redux';
import { getNewTransferActivity } from "./models/AccountInfo.js";
import { ScheduleTransfersActivity, Add_Transfer } from "../../actions/moneymovementactions";
import { UpdateAccount } from "../../actions/accountsactions"
import OverlayProcessing from "./common/overlayProcessing.jsx";
import RCM_Modal from "./common/modal.jsx";
import { transferWithholdingNoticePdf } from "../../common/constants.jsx";

class JournalPreConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.authToken = "";
    if (this.props.token !== null && this.props.token !== undefined) {
      this.authToken = this.props.token;
    }

    this.contextjson = null;
    if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
      this.contextjson = this.props.contextjson;
    }

    this.profilejson = null;
    if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
      this.profilejson = this.props.profilejson;
    }

    this.selectedAccounts = [];
    if (this.props.selectedaccount !== null && this.props.selectedaccount !== undefined) {
      this.selectedAccounts = this.props.selectedaccount;
    }


    this.state = {
      showJournalConfirm: false,
      isLoading: false,
      errorMsg: "",
      showOverlay: false,
      disclouserModal: false,
      disableSubmit: this.props.transferData.isRetirementDisbursment ? true : false,
      isAcceptWithholdingNotice: false
    };

    this.submitTransfer = this.submitTransfer.bind(this);
    this.toggleDisclouserModal = this.toggleDisclouserModal.bind(this);
  }

  toggleDisclouserModal() {
    this.setState(prevState => ({
      disclouserModal: !prevState.disclouserModal
    }));
  }

  submitTransfer() {
    this.setState(Object.assign({}, this.state, { showOverlay: true }), () => {
      addTransfer(this.props.transferData, this.props.eligiblePairAccts, this.authToken, this.contextjson, this.profilejson)
        .then(data => {
          this.setState(Object.assign({}, this.state, {
            //isLoading: false,
            showOverlay: false,
            errorMsg: "",
            showJournalConfirm: true
          }), () => {
            //sending the event back to lending so it will transmit to schedule transfers
            //this.props.newTransferEvent(data);

            let localArray = [...this.props.scheduleTransfers];
            console.log(localArray, typeof (localArray));
            localArray.unshift(getNewTransferActivity(data));
            //this.props.ScheduleTransfersActivity(localArray); 
            this.props.Add_Transfer(localArray, data.confirmno);
            if (this.props.render !== null && this.props.render !== undefined) {
              this.props.UpdateAccount(this.selectedAccounts, this.props.render, false);
            }
          });
        })
        .catch(error => {
          this.setState(Object.assign({}, this.state, {
            //isLoading: false,
            showOverlay: false,
            errorMsg: error
          }));
        });

    });
  }

  componentWillUnmount() {
    if (addTransferCancellationToken !== null && addTransferCancellationToken !== undefined) {
      addTransferCancellationToken.cancel("Cancelling add transfer request on unmount to prevent memory leak.");
    }
  }

  withholdNoticeAcceptChange() {
    this.setState({ isAcceptWithholdingNotice: !this.state.isAcceptWithholdingNotice, disableSubmit: this.state.isAcceptWithholdingNotice });
  }

  openWithholdPdf(){
    window.open(transferWithholdingNoticePdf);
  }

  render() {
    if (!this.state.showJournalConfirm) {
      return (
        <div>
          {
            this.state.showOverlay === true ? (
              <OverlayProcessing />
            ) : ""
          }

          {
            this.state.isLoading ? (
              <div><Spinner color="primary" /></div>
            ) : ""
          }

          {
            this.state.errorMsg !== "" ?
              (
                <div className="RCM_validation_error_container" >
                  <div className="RCM_validation_error_text">{this.state.errorMsg}</div>
                </div>) : ""
          }

          <div className="RCM_CM_form">
            <div className="row">
              <div className="col-sm-12 RCM_Toast_heading">
                CONFIRM ACCOUNT TRANSFER
            </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                Please confirm the account transfers details below
            </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">TRANSFER FROM</div>
                <div className="RCM_Info_text">{this.props.transferData.fromAcctDisplayName}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">TRANSFER TO</div>
                <div className="RCM_Info_text">{this.props.transferData.toAcctDisplayName}</div>
              </div>
            </div>
            {
              this.props.transferData.isRetirement ?
                (
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="RCM_Toast_sub_heading">CONTRIBUTION YEAR</div>
                      <div className="RCM_Info_text">{this.props.transferData.contributionYear}</div>
                    </div>
                  </div>
                ) : ""
            }

            <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">INITIATE TRANSFER ON</div>
                <div className="RCM_Info_text">{this.props.transferData.transferDate}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">AMOUNT TRANSFER</div>
                <div className="RCM_Info_text">{this.props.transferData.displayAmount}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">OCCURRENCE</div>
                <div className="RCM_Info_text">{this.props.transferData.transferType}</div>
              </div>
            </div>

            {
              this.props.transferData.preConfirmDisclouser === "" ? ""
                : (
                  <div className="row">
                    <div className="col-sm-12" align="center">
                      <button className="btn btn-link RCM_CM_linkText_small" onClick={this.toggleDisclouserModal}>Disclosures</button>
                    </div>
                  </div>
                )
            }


            {
              //Below fields are added as part of retiremet disburstment
              (this.props.transferData.isRetirementDisbursment) && (
                <>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="RCM_Toast_sub_heading">DISBURSEMENT REASON</div>
                      <div className="RCM_Info_text">{this.props.transferData.retdistreason && this.props.transferData.retdistreason.reasondesc ? this.props.transferData.retdistreason.reasondesc : ''}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="RCM_Toast_sub_heading">FED WITHHOLD AMOUNT</div>
                      <div className="RCM_Info_text">{parseFloat(this.props.transferData.fedwithholdamt).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="RCM_Toast_sub_heading">STATE WITHHOLD AMOUNT</div>
                      <div className="RCM_Info_text">{parseFloat(this.props.transferData.statewithholdamt).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="RCM_Toast_sub_heading">TOTAL AMOUNT WITH TAX</div>
                      <div className="RCM_Info_text">{parseFloat(this.props.transferData.totalamtincludetaxwithhold).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                        <span className="RCM_caseManager_modal_link RCM_Info_text" onClick={this.openWithholdPdf}>Review Notice of Withholding</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label className="RCM_selectLabel">
                        <input type="checkbox" checked={this.state.isAcceptWithholdingNotice} onChange={() => this.withholdNoticeAcceptChange()} />
                        <span className="profile-font-color RCM_preference_label">I have read and accept to the Notice of Withholding.</span>
                      </label>
                    </div>
                  </div>
                </>

              )
            }




            <div className="row" align="center">
              <div className="col-sm-12">

                <button
                  className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size"
                  onClick={this.props.transferData.isRetirementDisbursment ? this.props.backToWithholding : this.props.backToEdit}
                >
                  BACK
                </button>
                &nbsp; &nbsp;
              <button
                  className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size"
                  onClick={this.submitTransfer}
                  disabled={this.state.disableSubmit}
                >
                  SUBMIT
                </button>

              </div>
            </div>

            <div className="row" align="center">
              <div className="col-sm-12">

              </div>
            </div>
          </div>

          <RCM_Modal
            modalTitle="DISCLOSURES"
            modalState={this.state.disclouserModal}
            modalContent={this.props.transferData.preConfirmDisclouser}
            toggleModal={this.toggleDisclouserModal} />

        </div>
      );
    } else {
      return (
        <JournalConfirmation
          backToMain={this.backToMain}
          backToLending={this.props.backToLending}
        />
      );
    }
  }
}


const mapStateToProps = state => {
  return {
    token: state.acct.token,
    render: state.acct.render,
    scheduleTransfers: state.moneymovement.scheduledTransfersList,
    contextjson: state.acct.contextjson,
    profilejson: state.acct.profilejson,
    selectedaccount: state.acct.selectedaccount
  };
};

const mapDispatchToProps =
{
  ScheduleTransfersActivity,
  Add_Transfer,
  UpdateAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(JournalPreConfirm);
