import React, { Component } from 'react';
import { connect } from 'react-redux';
import Realizedgainlossdetails from './realizedgainlossservicecalls.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx'
import { realizedGainLossAndEstIncomeFooterMsg } from '../../../../common/constants.jsx';
class RealizedGainLoss extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        const toptendetails = (
            <div key='dvholding' className="RCM_detailsScreeen">
                <div className="row">
                    <div className="col-sm-12" key="lblholdingheadingrow">
                        <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblholdingheading'>REALIZED GAIN/LOSS</label>
                    </div>
                </div>
                <ErrorBoundary>
                    <Realizedgainlossdetails />
                </ErrorBoundary>
                <div className="mt-2 RCM_footer_text">
                    {realizedGainLossAndEstIncomeFooterMsg}
                </div>
            </div>
        );

        return (
            toptendetails
        );
    }
}
const mapStateToProps = state => {
    return {
        holdingsbyaccount: state.acct.holdingsbyaccount,
        holdingsbysecurity: state.acct.holdingsbysecurity,
        holdingsbycategory: state.acct.holdingsbycategory,
        render: state.acct.render,
        acct: state.acct,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};

export default connect(mapStateToProps)(RealizedGainLoss);