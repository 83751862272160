import React from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import ErrorBoundary from '../../../../common/errorboundary';
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { SubscribeAlertsView } from './subscribealertsview.jsx';
import { getAlertSubscribeSummary, subscribeAlertsService } from './subscribealertsservices.jsx';
import { MessageType, MessageClass, validateEmailId, CheckIfProspectClient } from './../../../../common/constants.jsx';
import { updateSubscribeAlerts } from './../../../../actions/accountsactions';
import MockData from './prospectSubscribeAlertMock.jsx';
import Watermark from './../../../../common/watermark';

export class SubscribeAlerts extends React.Component {
    constructor(props) {
        super(props);

        this.getAlertSubscribeSummary = this.getAlertSubscribeSummary.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleAlertChange = this.handleAlertChange.bind(this);
        this.toggleManageAlertsPopup = this.toggleManageAlertsPopup.bind(this);
        this.toggleManageAlerts = this.toggleManageAlerts.bind(this);
        this.saveSelectedAccount = this.saveSelectedAccount.bind(this);
        this.applyAllAccounts = this.applyAllAccounts.bind(this);
        this.toggleAlertsConfirmationPopup = this.toggleAlertsConfirmationPopup.bind(this);
        this.saveAllAccounts = this.saveAllAccounts.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.saveAlerts = this.saveAlerts.bind(this);
        this.saveNotificationOptions = this.saveNotificationOptions.bind(this);

        this.state = {
            accountList: [],
            messages: [],
            accountSelected: [],
            internalAccountsList: [],
            subscribeAlertSummary: {},
            selectedRows:[],
            isManageAlertsPopupOpen: false,
            selectedAccount: {},
            isAlertsConfirmationPopupOpen: false,
            isAllAccountsSelected:false,
            isSubscribeAlertsLoading: true,
            alertTypes: [],
            notificationOptions: [],
            initiallySelectedAccount: {},
            isSaveDisabled: true,
            email: this.props.profileResp.emailprimary ? this.props.profileResp.emailprimary : '',
            mobileNo: '',
            isMobileNoAdded: false,
            isEmailAdded: false,
            initialEmail: '',
            initialMobileNo:'',
            alertsubscribid: "",
            isProspectClient: false,
            showMobile: false
        }

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }

    }
    componentDidMount() {
        let accountList = this.getInternalAccountList();
        if (CheckIfProspectClient(this.props.role)) {
            this.setState(prevState => ({
                isProspectClient: true, internalAccountsList: accountList 
            }), () => { this.getMockAlertSubscribeSummary() });
        } else
            this.setState(prevState => ({
               internalAccountsList: accountList
            }), () => { this.getAlertSubscribeSummary() });
    }
    componentDidUpdate(prevProps, prevState) {
        
        if (this.props.render !== prevProps.render) {
            let accountList = this.getInternalAccountList();
            this.setState({ isSubscribeAlertsLoading: true, internalAccountsList: accountList }, () => {
                if (this.state.isProspectClient) {
                    this.getMockAlertSubscribeSummary();
                }else
                    this.getAlertSubscribeSummary();
            });

        }

    }
    getMockAlertSubscribeSummary = () => {
        this.createAlertList(MockData);
    }
    getAlertSubscribeSummary = () => {
        console.log("Start get api: " + new Date());
        getAlertSubscribeSummary(this.authToken, this.contextjson, this.profilejson)
            .then(data => {
                console.log("end get api: " + new Date());
                this.createAlertList(data);
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    accountList: [], isSubscribeAlertsLoading: false
                });
            })

    }
    getInternalAccountList = () => {
        let list = [];
        for (const [index, account] of this.props.accounts.entries()) {
            if (account.isInternal)
                list.push(account);
        }
        return list;
    }
    createAlertList = data => {
        console.log("Start get processing: " + new Date());
        var subscribeAlertSummary = data;
        var alertsubscribid = data.alertsubscribid;
        //var subscribedAccountList = subscribeAlertSummary.subscribedaccounts;
        var accountList = [];
        //To get the values for email and Mobile no.
        let email = this.state.email;
        let mobileNo = '';
        let isEmailAdded = true;
        let isMobileNoAdded = false;
        let showMobile = false;
        for (const [index, device] of subscribeAlertSummary.notificationoptions.entries()) {
            if (device.notificationtype === 'Email') {
                email = device.notificationid;
                isEmailAdded = true;
            } else if (device.notificationtype === 'SMS') {
                mobileNo = device.notificationid;
                let onlyNums = mobileNo.replace(/[^0-9]/g, '');
                mobileNo = onlyNums.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '($1) $2-$3'
                );
                isMobileNoAdded = true;
                showMobile = true;
            }
        }

        for (const [index, account] of this.state.internalAccountsList.entries()) {
            var subscribedAccount = { ...account };
            let alertList = "";
            //let alertType = {};
            let filteraccount = subscribeAlertSummary.subscribedaccounts.filter(p => p.key === account.key);
            if (filteraccount && filteraccount.length > 0) {
                let tempAccount = filteraccount[0];
                let alertTypes = [];
                for (const [index, alert] of subscribeAlertSummary.availablealerttypes.entries()) {
                    let tempAlert = tempAccount.alerttypes.filter(p => p.alerttype.alerttype === alert.alerttype);
                    let subscribedDevices = [];
                    if (tempAlert && tempAlert.length > 0 && tempAlert[0].alertsendtodevices.length > 0) {
                        alertList = alertList + ", " + tempAlert[0].alerttype.alertname;

                    }
                    for (const [index, device] of subscribeAlertSummary.notificationoptions.entries()) {
                        let subscribedDevice = {};
                        if (tempAlert && tempAlert.length > 0) {
                            let filteredDevice = tempAlert[0].alertsendtodevices && tempAlert[0].alertsendtodevices !== null ? tempAlert[0].alertsendtodevices.filter(p => p.devicetype === device.notificationtype) : [];
                            if (filteredDevice && filteredDevice.length > 0) {
                                subscribedDevice = { value: true, deviceType: device.notificationtype, deviceDisplayName: device.notificationname, deviceid: device.notificationid };
                            } else {
                                subscribedDevice = { value: false, deviceType: device.notificationtype, deviceDisplayName: device.notificationname, deviceid: device.notificationid };
                            }
                        } else {
                            subscribedDevice = { value: false, deviceType: device.notificationtype, deviceDisplayName: device.notificationname, deviceid: device.notificationid };
                        }
                        subscribedDevices.push(subscribedDevice);
                    }

                    let alertType = {};
                    alertType.subscribedDevices = [...subscribedDevices];
                    alertType.alertType = alert.alerttype;
                    alertType.alertname = alert.alertname;
                    alertType.alertDesc = alert.alertdesc;
                    alertTypes.push(alertType);
                }
                subscribedAccount.alertList = alertList.substring(1);
                subscribedAccount.alertTypes = alertTypes;
            } else {
                subscribedAccount.alertList = '';

                let alertTypes = [];
                for (const [index, alert] of subscribeAlertSummary.availablealerttypes.entries()) {
                    let subscribedDevices = [];
                    for (const [index, device] of subscribeAlertSummary.notificationoptions.entries()) {
                        let subscribedDevice = { value: false, deviceType: device.notificationtype, deviceDisplayName: device.notificationname, deviceid: device.notificationid };
                        subscribedDevices.push(subscribedDevice);
                    }
                    let alertType = {};
                    alertType.subscribedDevices = [...subscribedDevices];
                    alertType.alertType = alert.alerttype;
                    alertType.alertname = alert.alertname;
                    alertType.alertDesc = alert.alertdesc;
                    alertTypes.push(alertType);
                }
                subscribedAccount.alertTypes = alertTypes;
            }
            accountList.push(subscribedAccount);
        }

        this.setState({
            accountList: accountList, subscribeAlertSummary: subscribeAlertSummary, alertTypes: subscribeAlertSummary.availablealerttypes,
            notificationOptions: subscribeAlertSummary.notificationoptions, alertsubscribid: alertsubscribid, email: email, isEmailAdded: isEmailAdded,
            mobileNo: mobileNo, isMobileNoAdded: isMobileNoAdded, initialEmail: email, initialMobileNo: mobileNo, isSubscribeAlertsLoading: false, showMobile: showMobile
        }, () => {
                this.props.updateSubscribeAlerts(accountList);
            //console.log("end get processing: " + new Date());
        });
    }
    handleAlertChange = (alertType,selectedDevice) => {
        let clonedState = Object.assign({}, this.state);
        for (const [index, alert] of clonedState.selectedAccount.alertTypes.entries()) {
            if (alert.alertType === alertType) {
                for (const [index, device] of alert.subscribedDevices.entries()) {
                    if (device.deviceType === selectedDevice.deviceType) {
                        device.value = !device.value;
                    }
                }
            }
        }
        clonedState.isSaveDisabled = !(this.isSelectedAccountChanged(clonedState.selectedAccount));
        this.setState(clonedState);
    }
    isSelectedAccountChanged = (selectedAccount) => {
       
        let initiallySelectedAccount = this.state.initiallySelectedAccount;
       
        if (JSON.stringify(selectedAccount) != JSON.stringify(initiallySelectedAccount))
            return true;
        else
            return false;
    }
   
    toggleAlertsConfirmationPopup= () => {
        this.setState(prevState => ({
            isAlertsConfirmationPopupOpen: !prevState.isAlertsConfirmationPopupOpen, 
            isAllAccountsSelected: false,
        }));
    }
    applyAllAccounts = () => {
        this.setState(prevState => ({
            isManageAlertsPopupOpen: !prevState.isManageAlertsPopupOpen,
            isAllAccountsSelected: true,
            isAlertsConfirmationPopupOpen: !prevState.isAlertsConfirmationPopupOpen,  
        }));
    }
  
    saveAlerts = () => {
        this.setState(prevState => ({
            isAlertsConfirmationPopupOpen: !prevState.isAlertsConfirmationPopupOpen,
            isSaveDisabled: true,
            isSubscribeAlertsLoading: true,
        }), () => {
            this.saveAllAccounts()
        });
    }
    saveAllAccounts = () => {
        console.log("Start: " + new Date());
        var subscribedAlerts = [];
        let alerttypes = [];
        for (const [alertTypeIndex, alert] of this.state.selectedAccount.alertTypes.entries()) {
            var alertType = {
                alerttype: { alerttype: alert.alertType, alertname: alert.alertname, alertdesc: alert.alertDesc }, alertsendtodevices: []
            };
            for (const [index, subscribedDevice] of alert.subscribedDevices.entries()) {
                if (subscribedDevice.value) {
                    alertType.alertsendtodevices.push({
                        devicetype: subscribedDevice.deviceType,
                        devicename: subscribedDevice.deviceDisplayName,
                        deviceid: subscribedDevice.deviceid
                    });
                }
            }
            alerttypes.push(alertType);
        }
        for (const [index, account] of this.state.accountList.entries()) {
            var tempAccount = { key: account.key, alerttypes: alerttypes };
            delete tempAccount.alertList;
            subscribedAlerts.push(tempAccount);
        }
        this.save(subscribedAlerts, 'allAccounts');
    }
    saveSelectedAccount = () => {
        this.setState(prevState => ({
            isManageAlertsPopupOpen: !prevState.isManageAlertsPopupOpen,
            isSubscribeAlertsLoading: true,
            isSaveDisabled:true
        }), () => {
                var subscribedAlerts = [];
                var tempAccount = { key: this.state.selectedAccount.key, alerttypes: [] };

                for (const [alertTypeIndex, alert] of this.state.selectedAccount.alertTypes.entries()) {
                    var alertType = {
                        alerttype: { alerttype: alert.alertType, alertname: alert.alertname, alertdesc: alert.alertDesc}, alertsendtodevices: []
                    };
                    for (const [index, subscribedDevice] of alert.subscribedDevices.entries()) {
                        if (subscribedDevice.value) {
                            alertType.alertsendtodevices.push({
                                devicetype: subscribedDevice.deviceType,
                                devicename: subscribedDevice.deviceDisplayName,
                                deviceid: subscribedDevice.deviceid 
                            });
                        }
                    }
                    tempAccount.alerttypes.push(alertType);
                }
                delete tempAccount.alertList;
                subscribedAlerts.push(tempAccount);
                this.save(subscribedAlerts,'singleAccount');
        });      
    }
    saveNotificationOptions = (notificationID, notificationOptionType, notificationOptionName) => {
        var requestJson = {
            "alertsubscribid": this.state.alertsubscribid,
            "notificationoptions": [
                {
                    "notificationid": notificationID,
                    "notificationname": notificationOptionName,
                    "notificationtype": notificationOptionType
                }
            ]
        }
        subscribeAlertsService(requestJson, this.authToken, this.contextjson, this.profilejson)
            .then(response => {
                var successMsg = "Email saved successfully.";
                if (notificationOptionType === "SMS")
                    successMsg = "Mobile Number saved successfully.";
                var messages = [];
                messages.push({
                    type: MessageType.INFO,
                    text: successMsg,
                    class: MessageClass.SUCCESS
                })

                this.setState({ messages: messages }, () => { this.getAlertSubscribeSummary() });
            })
            .catch(error => {
                console.error(error);
                
                var errorMsg = "Email not saved.";
                if (notificationOptionType === "SMS")
                    errorMsg = "Mobile Number not saved.";
                var messages = [];
                messages.push({
                    type: MessageType.INFO,
                    text: errorMsg,
                    class: MessageClass.ERROR
                })
                this.setState({ messages: messages, isSubscribeAlertsLoading: false});
            })
    }
    save = (subscribedAccounts, source) => {
        console.log("End of processing: " + new Date());
        var requestJson = {
            'subscribedaccounts': subscribedAccounts,
            'isaddormodify': source === 'allAccounts' ? false : true,
            "alertsubscribid": this.state.alertsubscribid,
            'isremove': false,
            'wsportaluserid': this.props.portalid
        };

        subscribeAlertsService(requestJson, this.authToken, this.contextjson, this.profilejson)
            .then(response => {
                this.cancel();
                var successMsg = "Alerts subscribed successfully.";
                var messages = [];
                messages.push({
                    type: MessageType.INFO,
                    text: successMsg,
                    class: MessageClass.SUCCESS
                })
                console.log("End of api call: " + new Date());
                this.setState({ messages: messages, accountList: [], selectedAccount: {} }, () => { this.getAlertSubscribeSummary() });
            })
            .catch(error => {
                console.error(error);
                this.cancel();
                var errorMsg = "Alerts not subscribed.";
                var messages = [];
                messages.push({
                    type: MessageType.INFO,
                    text: errorMsg,
                    class: MessageClass.ERROR
                })
                this.setState({ messages: messages, isSubscribeAlertsLoading: false});
            })
    }
    
    
    cancel = () => {
        this.setState({ accountSelected:[] })
    }
    
    toggleManageAlertsPopup = (row) => {
        let initiallySelectedAccount = JSON.parse(JSON.stringify(row));
        this.setState(prevState => ({
            isManageAlertsPopupOpen: !prevState.isManageAlertsPopupOpen,
            selectedAccount: row,
            initiallySelectedAccount: initiallySelectedAccount,
            isSaveDisabled:true
        }));
    }
    toggleManageAlerts = () => {
        this.setState(prevState => ({
            isManageAlertsPopupOpen: !prevState.isManageAlertsPopupOpen
        }));
    }
    handleOnChange = evt => {
        const basicEmail = /^[a-zA-Z0-9 .@_-]+$/;
        const phoneNoFormat = /^[0-9 +().-\s]*$/;
        if (evt.target.name ==='email' && (evt.target.value === "" || basicEmail.test(evt.target.value))) {
            this.setState({ email: evt.target.value });
        } else if (evt.target.name === 'mobileNo') {
            const onlyNums = evt.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 10) {
                this.setState({ mobileNo: onlyNums });
            } else if (onlyNums.length === 10) {
                const number = onlyNums.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '($1) $2-$3'
                );
                this.setState({ mobileNo: number });
            }
            
        } 
    }
    handleOnBlur = evt => {
        var messages = this.state.messages;
        var mobileNoErrorMsg = 'Mobile Number not valid, please enter a valid mobile number.';
        var emailErrorMsg = "Email not valid, please enter a valid email address.";
        var newMessages = [];
        if (evt.target.name === 'email' && evt.target.value !== this.state.initialEmail) {
            // var errorMsg = "";
            var errormsgIndex = messages.findIndex(item => item.text === mobileNoErrorMsg);
            if (errormsgIndex > -1) {
                newMessages.push({
                    type: MessageType.INFO,
                    text: mobileNoErrorMsg,
                    class: MessageClass.ERROR
                })
            }
            if (validateEmailId(evt.target.value)) {
                this.setState({ messages: newMessages, isEmailAdded: true, isSubscribeAlertsLoading: true}, () => {
                    this.saveNotificationOptions(this.state.email, 'Email', 'Email')
                });
            } else {
                newMessages.push({
                    type: MessageType.INFO,
                    text: emailErrorMsg,
                    class: MessageClass.ERROR
                })
                this.setState({ messages: newMessages, isEmailAdded: false });
            }
        } else if (evt.target.name === "mobileNo") {
            let onlyNums = evt.target.value.replace(/[^0-9]/g, '');
            let initialMobileNo = this.state.initialMobileNo.replace(/[^0-9]/g, '');
            if (onlyNums !== initialMobileNo) {
                var errormsgIndex = messages.findIndex(item => item.text === emailErrorMsg);
                if (errormsgIndex > -1) {
                    newMessages.push({
                        type: MessageType.INFO,
                        text: emailErrorMsg,
                        class: MessageClass.ERROR
                    })
                }
                //let onlyNums = evt.target.value.replace(/[^0-9]/g, '');

                if (onlyNums.length === 10) {
                    let value = "+" + onlyNums;
                    this.setState({ messages: newMessages, isMobileNoAdded: true, isSubscribeAlertsLoading: true }, () => {
                        this.saveNotificationOptions(value, 'SMS', 'SMS')
                    });
                } else {
                    newMessages.push({
                        type: MessageType.INFO,
                        text: mobileNoErrorMsg,
                        class: MessageClass.ERROR
                    })
                    this.setState({ messages: newMessages, isMobileNoAdded: false });
                }
            }
            
        }
    }
    render() {
        return (
            <ErrorBoundary>
                {this.state.isProspectClient ?
                    <Watermark />
                    : ''
                }
                <SubscribeAlertsView
                    state={this.state}
                    //selectRow={selectRow}
                    save={this.save}
                    cancel={this.cancel}
                    handleAlertChange={this.handleAlertChange}
                    toggleManageAlertsPopup={this.toggleManageAlertsPopup}
                    toggleManageAlerts={this.toggleManageAlerts}
                    saveSelectedAccount={this.saveSelectedAccount}
                    applyAllAccounts={this.applyAllAccounts}
                    toggleAlertsConfirmationPopup={this.toggleAlertsConfirmationPopup}
                    saveAlerts={this.saveAlerts}
                    handleOnChange={this.handleOnChange}
                    handleOnBlur={this.handleOnBlur}
                />
            </ErrorBoundary>
        );
    }

}
const mapStateToProps = (store) => {
    return {
        profileResp: store.acct.profileResp,
        token: store.acct.token,
        portalid: store.acct.portalid,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        render: store.acct.render,
        role: store.acct.role,
        selectedaccount: store.acct.selectedaccount,
        accounts: store.acct.accounts
    };
}

const mapDispatchToProps = {
    updateSubscribeAlerts
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Subscribe Alerts' })
)

export default enhancement(SubscribeAlerts);
