import React from 'react';
import { roundvalue, formatMoney, getAccountDisplayName, IndLongShort } from './../../../../common/constants.jsx';
import orderBy from 'lodash/orderBy';

//columns for child table shown on expanding specific row 
export function GetSecurityColumns(totalordinarydividend, totaltaxableincome, totalincome, totalcapgain, rowcol, isrowexpanded, rowsortevent) {
    var columns = [];
    if (isrowexpanded || rowcol.length === 0) {
        columns = [

            {
                dataField: 'symbol', text: "Symbol", headerAlign: 'left', sort: true, 
                sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_text',
                formatter: (cell, row) => {
                    if (cell === null || cell === '' || cell === undefined) {
                        if(row.CUSIPNUM === null || row.CUSIPNUM === undefined || row.CUSIPNUM === ''){
                            return <span>-</span>
                        }else{
                            return <span>{row.CUSIPNUM}</span>
                        }
                    }
                    else return <span>{cell}</span>;
                }, 
                onSort: (field, order) => {
                    rowsortevent(field, order);
                },
                footer: "Total:", footerAlign: (column, colIndex) => 'left', 
                
            },
            {
                dataField: 'desc', text: "Description", headerAlign: 'left', sort: true,
                sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_text', onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: "", formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else return <span>{cell}</span>;
                },
            },
            {
                dataField: 'ordinarydividends', text: "Dividend", headerAlign: 'right', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }, formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: (cell) => {
                    if (totalordinarydividend === null || totalordinarydividend === '' || totalordinarydividend === undefined) return <span>-</span>;
                    else if (totalordinarydividend === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalordinarydividend, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },
            {
                dataField: 'interestincome', text: "Interest", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totaltaxableincome === null || totaltaxableincome === '' || totaltaxableincome === undefined) return <span>-</span>;
                    else if (totaltaxableincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totaltaxableincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },
            {
                dataField: 'capgain', text: "Capital Gains Distribution", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalcapgain === null || totalcapgain === '' || totalcapgain === undefined) return <span>-</span>;
                    else if (totalcapgain === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalcapgain, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },
            {
                dataField: 'netincome', text: "Net Income", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalincome === null || totalincome === '' || totalincome === undefined) return <span>-</span>;
                    else if (totalincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            }
           

        ]
    }
    else {
        columns.push({
            dataField: 'symbol', text: "Security", sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) {
                    if(row.CUSIPNUM === null || row.CUSIPNUM === undefined || row.CUSIPNUM === ''){
                        return <span>-</span>
                    }else{
                    return <span>{row.CUSIPNUM}</span>
                    }
                }
                else return <span>{cell}</span>;
            }, 
            classes: 'RCM_common_table_column_text', 
            footer: "Total:", onSort: (field, order) => {
                rowsortevent(field, order);
            }, footerAlign: (column, colIndex) => 'left', 
            
        })

        if (checkIfFieldEmpty(rowcol, 'desc')) {
            columns.push({ dataField: 'df1', text: "", isDummyField: true, footer: "" });
        }
        else {
            columns.push({
                dataField: 'desc', text: "Description", headerAlign: 'left', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_text', onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: "", formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else return <span>{cell}</span>;
                },
            })
        }


        if (checkIfFieldEmpty(rowcol, 'ordinarydividends')) {
            columns.push({ dataField: 'df2', text: "", isDummyField: true, footer: "" });
        }
        else {
            columns.push({
                dataField: 'ordinarydividends', text: "Dividend", headerAlign: 'right', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }, formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: (cell) => {
                    if (totalordinarydividend === null || totalordinarydividend === '' || totalordinarydividend === undefined) return <span>-</span>;
                    else if (totalordinarydividend === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalordinarydividend, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            })
        }

        if (checkIfFieldEmpty(rowcol, 'interestincome')) {
            columns.push({ dataField: 'df4', text: "", isDummyField: true, footer: "" });
        }
        else {
            columns.push({
                dataField: 'interestincome', text: "Interest", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totaltaxableincome === null || totaltaxableincome === '' || totaltaxableincome === undefined) return <span>-</span>;
                    else if (totaltaxableincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totaltaxableincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            })
        }
        if (checkIfFieldEmpty(rowcol, 'capgain')) {
            columns.push({ dataField: 'df5', text: "", isDummyField: true, footer: "" });
        }
        else {
            columns.push({
                dataField: 'capgain', text: "Capital Gains Distribution", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: (cell) => {
                    if (totalcapgain === null || totalcapgain === '' || totalcapgain === undefined) return <span>-</span>;
                    else if (totalcapgain === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalcapgain, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            })
        }
        if (checkIfFieldEmpty(rowcol, 'netincome')) {
            columns.push({ dataField: 'df5', text: "", isDummyField: true, footer: "" });
        }
        else {
            columns.push({
                dataField: 'netincome', text: "Net Income", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalincome === null || totalincome === '' || totalincome === undefined) return <span>-</span>;
                    else if (totalincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            })
        }
    
    }
    return columns;
}
export function securitychildColumns(accounts) {
    //child columns for third level drill down in account tab
    var childColumns = [{
        dataField: 'ACCOUNTNICKNAME', text: "", headerAlign: 'left', sort: true,
        sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_text',
        formatter: (cell, row) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else return acctFormatter(accounts, cell, row.ACCTKEY);
        }
    },
    {
        dataField: 'ACCTYPE', text: "", classes: 'RCM_common_table_column_text', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>&nbsp;</span>;
            else return <span>&nbsp;</span>;
        },//account type
    },
    {
        dataField: 'ordinarydividends', text: "", headerAlign: 'right', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
            return <div>{cell}</div>;
        }, formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },
    {
        dataField: 'interestincome', text: "", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
        {
            dataField: 'capgain', text: "", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }
        },
    {
        dataField: 'netincome', text: "", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
        }, { dataField: 'df5', text: "", isDummyField: true }
    
    ]
    return childColumns;
}

export function GetAccountColumns(totalordinarydividend, totaltaxableincome, totalincome, totalcapgain, rowcol, isrowexpanded, accounts, rowsortevent) {
    var headercolumnsbyaccount = []
    if (isrowexpanded === true || rowcol.length === 0) {
        headercolumnsbyaccount = [{
            dataField: 'ACCOUNTNICKNAME', text: "Account", headerAlign: 'left', sort: true, sortCaret: (order, column) => customCaretSort(order, column),
            classes: 'RCM_common_table_column_text', formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) { return <span>-</span>;
            }
                else{ return acctFormatter(accounts, cell, row.ACCTKEY); }
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: "Total:", footerAlign: (column, colIndex) => 'left', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return <span>{cell}</span>;
            },
        }, {
                dataField: 'df1', text: "", classes: 'RCM_common_table_column_text', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>&nbsp;</span>;
                    else return <span>&nbsp;</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: ""//account type
            },
            {
                dataField: 'ordinarydividends', text: "Dividend", headerAlign: 'right', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }, formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalordinarydividend === null || totalordinarydividend === '' || totalordinarydividend === undefined) return <span>-</span>;
                    else if (totalordinarydividend === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalordinarydividend, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },

            {
                dataField: 'interestincome', text: "Interest", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: (cell) => {
                    if (totaltaxableincome === null || totaltaxableincome === '' || totaltaxableincome === undefined) return <span>-</span>;
                    else if (totaltaxableincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totaltaxableincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },
           

            {
                dataField: 'capgain', text: "Capital Gains Distribution", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: (cell) => {
                    if (totalcapgain === null || totalcapgain === '' || totalcapgain === undefined) return <span>-</span>;
                    else if (totalcapgain === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalcapgain, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },
            {
                dataField: 'netincome', text: "Net Income", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalincome === null || totalincome === '' || totalincome === undefined) return <span>-</span>;
                    else if (totalincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            }
        ];
    }
    else {
        //acct num is mandatoty field so not adding check here
        headercolumnsbyaccount.push({
            dataField: 'ACCOUNTNICKNAME', text: "Account", headerAlign: 'left', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_text', formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) { return <span>-</span>; }
                else { return acctFormatter(accounts, cell, row.ACCTKEY); }
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: "Total:", footerAlign: (column, colIndex) => 'left'
        });
        headercolumnsbyaccount.push({ dataField: 'df1', isDummyField: true, text: "", footer: "" });//this field is for accttype as we explicitly making it blank adding dummy column here

        if (checkIfFieldEmpty(rowcol, 'ordinarydividends')) {
            headercolumnsbyaccount.push({ dataField: 'df2', isDummyField: true, text: "", footer: "" });
        }
        else {
            headercolumnsbyaccount.push({
                dataField: 'ordinarydividends', text: "Dividend", headerAlign: 'right', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }, formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalordinarydividend === null || totalordinarydividend === '' || totalordinarydividend === undefined) return <span>-</span>;
                    else if (totalordinarydividend === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalordinarydividend, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },)
        }

        if (checkIfFieldEmpty(rowcol, 'interestincome')) {
            headercolumnsbyaccount.push({ dataField: 'df4', isDummyField: true, text: "", footer: "" });
        }
        else {
            headercolumnsbyaccount.push({
                dataField: 'interestincome', text: "Interest", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totaltaxableincome === null || totaltaxableincome === '' || totaltaxableincome === undefined) return <span>-</span>;
                    else if (totaltaxableincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totaltaxableincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },)
        }
        if (checkIfFieldEmpty(rowcol, 'capgain')) {
            headercolumnsbyaccount.push({ dataField: 'df5', isDummyField: true, text: "", footer: "" });
        }
        else {
            headercolumnsbyaccount.push({
                dataField: 'capgain', text: "Capital Gains Distribution", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                },footer: (cell) => {
                    if (totalcapgain === null || totalcapgain === '' || totalcapgain === undefined) return <span>-</span>;
                    else if (totalcapgain === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalcapgain, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            })
        }
        if (checkIfFieldEmpty(rowcol, 'netincome')) {
            headercolumnsbyaccount.push({ dataField: 'df5', isDummyField: true, text: "", footer: "" });
        }
        else {
            headercolumnsbyaccount.push({
                dataField: 'netincome', text: "Net Income", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                    if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                    else if (cell === 0) return <span>$0</span>;
                    else return <span>{formatMoney(cell)}</span>;
                }, onSort: (field, order) => {
                    rowsortevent(field, order);
                }, footer: (cell) => {
                    if (totalincome === null || totalincome === '' || totalincome === undefined) return <span>-</span>;
                    else if (totalincome === 0) return <span>$0</span>;
                    else return <span>{formatMoney(roundvalue(totalincome, 2))}</span>;
                }, footerAlign: (column, colIndex) => 'right'
            },)
        }
    }
    return headercolumnsbyaccount;

}
//child columns for account expand view
export const accountchildcolumns = [
    {
        dataField: 'symbol', text: "Symbol", headerAlign: 'left', 
        sort: true, sortCaret: (order, column) => customCaretSort(order, column), 
        classes: 'RCM_common_table_column_text', 
        formatter: (cell, row) => {
            if (cell === null || cell === '' || cell === undefined) {
                if(row.CUSIPNUM === null || row.CUSIPNUM === undefined || row.CUSIPNUM === ''){
                    return <span>-</span>
                }else{
                return <span>{row.CUSIPNUM}</span>
                }
            }
            else return <span>{cell}</span>;
        }
    },
    {
        dataField: 'desc', text: "Description", headerAlign: 'left', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_text', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else return <span>{cell}</span>;
        }
    },
    {
        dataField: 'ordinarydividends', text: "Dividend", headerAlign: 'right', sort: true, sortCaret: (order, column) => customCaretSort(order, column), classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
            return <div>{cell}</div>;
        }, formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },
    {
        dataField: 'interestincome', text: "Interest", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },
    {
        dataField: 'capgain', text: "Capital Gains Distribution Distribution", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },
    {
        dataField: 'netincome', text: "Net Income", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },  { dataField: 'df5', text: "", isDummyField: true }
  

]

function columnHeaderStyle(column, colIndex)
{
  return { 'textAlign': 'left', 'whiteSpace': 'nowrap' };
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

export function GetSecuritySummaryColumns(finalTotal,rowsortevent,yearselected='') {
    var columns = [{
        dataField: 'SECNAME', text: "Symbol", headerAlign: 'left', sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        classes: 'RCM_common_table_column_text',  
        footer: "Total:", 
        onSort: (field, order) => {
            rowsortevent(field, order);
        },
        footerAlign: (column, colIndex) => 'left', 
        formatter: (cell, row) => {
            if (cell === null || cell === '' || cell === undefined) {
                //if symbol is not present , put the cusip value
                if(row.CUSIPNUM === null || row.CUSIPNUM === undefined || row.CUSIPNUM === ''){
                    return <span>-</span>
                }else{
                return <span>{row.CUSIPNUM}</span>
                }
            }
            else return <span>{cell}</span>;
        },
    }, {
        dataField: 'SECDESCRIPTION', text: "Description", headerAlign: 'left', sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        classes: 'RCM_common_table_column_text', 
        formatter: (cell, row) => {
            return <div className="RCM_symbol_text_bold">{cell}</div>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: "", formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else return <span>{cell}</span>;
        },
    },
    {
        dataField: 'JanuaryTotal', text: 'Jan ' + yearselected.toString(), headerAlign: 'right', sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column), 
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        classes: 'RCM_common_table_column_number', 
        formatter: (cell, row) => {
            return <div>{cell}</div>;
        }, formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.JanuaryTotal === null || finalTotal.JanuaryTotal === '' || finalTotal.JanuaryTotal === undefined) return <span>-</span>;
            else if (finalTotal.JanuaryTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.JanuaryTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'FebruaryTotal', text: "Feb " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.FebruaryTotal === null || finalTotal.FebruaryTotal === '' || finalTotal.FebruaryTotal === undefined) return <span>-</span>;
            else if (finalTotal.FebruaryTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.FebruaryTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'MarchTotal', text: "March " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.MarchTotal === null || finalTotal.MarchTotal === '' || finalTotal.MarchTotal === undefined) return <span>-</span>;
            else if (finalTotal.MarchTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.MarchTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'AprilTotal', text: "April " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column), 
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.AprilTotal === null || finalTotal.AprilTotal === '' || finalTotal.AprilTotal === undefined) return <span>-</span>;
            else if (finalTotal.AprilTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.AprilTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'MayTotal', text: "May " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.MayTotal === null || finalTotal.MayTotal === '' || finalTotal.MayTotal === undefined) return <span>-</span>;
            else if (finalTotal.MayTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.MayTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'JuneTotal', text: "June " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.JuneTotal === null || finalTotal.JuneTotal === '' || finalTotal.JuneTotal === undefined) return <span>-</span>;
            else if (finalTotal.JuneTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.JuneTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'JulyTotal', text: "July " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.JulyTotal === null || finalTotal.JulyTotal === '' || finalTotal.JulyTotal === undefined) return <span>-</span>;
            else if (finalTotal.JulyTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.JulyTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'AugustTotal', text: "August " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.AugustTotal === null || finalTotal.AugustTotal === '' || finalTotal.AugustTotal === undefined) return <span>-</span>;
            else if (finalTotal.AugustTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.AugustTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'SeptemberTotal', text: "Sept " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.SeptemberTotal === null || finalTotal.SeptemberTotal === '' || finalTotal.SeptemberTotal === undefined) return <span>-</span>;
            else if (finalTotal.SeptemberTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.SeptemberTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'OctoberTotal', text: "Oct " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.OctoberTotal === null || finalTotal.OctoberTotal === '' || finalTotal.OctoberTotal === undefined) return <span>-</span>;
            else if (finalTotal.OctoberTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.OctoberTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'NovemberTotal', text: "Nov " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.NovemberTotal === null || finalTotal.NovemberTotal === '' || finalTotal.NovemberTotal === undefined) return <span>-</span>;
            else if (finalTotal.NovemberTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.NovemberTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'DecemberTotal', text: "Dec " + yearselected.toString(), sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal.DecemberTotal === null || finalTotal.DecemberTotal === '' || finalTotal.DecemberTotal === undefined) return <span>-</span>;
            else if (finalTotal.DecemberTotal === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.DecemberTotal, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    },
    {
        dataField: 'Total', text: "Net Income", sort: true, 
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
        headerAlign: 'right', 
        classes: 'RCM_common_table_column_number', 
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }, onSort: (field, order) => {
            rowsortevent(field, order);
        }, footer: (cell) => {
            if (finalTotal === null || finalTotal === undefined || finalTotal.Total === null || finalTotal.Total === '' || finalTotal.Total === undefined) return <span>-</span>;
            else if (finalTotal.Total === 0) return <span>$0</span>;
            else return <span>{formatMoney(roundvalue(finalTotal.Total, 2))}</span>;
        }, footerAlign: (column, colIndex) => 'right'
    }
    ]

    return columns;
}

export function GetAccountSummaryColumns(finalTotal, accounts, rowsortevent, yearselected='') {
    var columns = [
        {
            dataField: 'ACCOUNTNICKNAME', text: "Account", headerAlign: 'left', sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),  
            classes: 'RCM_common_table_column_text',
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            footer: "Total:",
            footerAlign: (column, colIndex) => 'left',
            formatter: (cell, row) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return acctFormatter(accounts, cell, row.ACCT);
            },
        },
        {
            dataField: 'df1', text: "", headerAlign: 'left', sort: true, 
            onSort: (field, order) => {
                rowsortevent(field, order);
            },footer: (cell) => {
               return  <span></span>;
            }, 
            footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'JanuaryTotal', text: "Jan " + yearselected.toString(), headerAlign: 'right', onSort: (field, order) => {
                rowsortevent(field, order);
            }, sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),  
            classes: 'RCM_common_table_column_number', formatter: (cell, row) => {
                return <div>{cell}</div>;
            }, formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.JanuaryTotal === null || finalTotal.JanuaryTotal === '' || finalTotal.JanuaryTotal === undefined) return <span>-</span>;
                else if (finalTotal.JanuaryTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.JanuaryTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'FebruaryTotal', text: "Feb " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),  
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.FebruaryTotal === null ||  finalTotal.FebruaryTotal === '' || finalTotal.FebruaryTotal === undefined) return <span>-</span>;
                else if (finalTotal.FebruaryTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.FebruaryTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'MarchTotal', text: "March " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.MarchTotal === null || finalTotal.MarchTotal === '' || finalTotal.MarchTotal === undefined) return <span>-</span>;
                else if (finalTotal.MarchTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.MarchTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'AprilTotal', text: "April " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if ( finalTotal === null || finalTotal.AprilTotal === null || finalTotal.AprilTotal === undefined || finalTotal.AprilTotal === '') return <span>-</span>;
                else if (finalTotal.AprilTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.AprilTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'MayTotal', text: "May " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),  
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.MayTotal === null || finalTotal.MayTotal === '' || finalTotal.MayTotal === undefined) return <span>-</span>;
                else if (finalTotal.MayTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.MayTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'JuneTotal', text: "June " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),  
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.JuneTotal === null || finalTotal.JuneTotal === '' || finalTotal.JuneTotal === undefined) return <span>-</span>;
                else if (finalTotal.JuneTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.JuneTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'JulyTotal', text: "July " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.JulyTotal === null || finalTotal.JulyTotal === '' || finalTotal.JulyTotal === undefined) return <span>-</span>;
                else if (finalTotal.JulyTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.JulyTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'AugustTotal', text: "August " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.AugustTotal === null || finalTotal.AugustTotal === '' || finalTotal.AugustTotal === undefined) return <span>-</span>;
                else if (finalTotal.AugustTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.AugustTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'SeptemberTotal', text: "Sept " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.SeptemberTotal === null || finalTotal.SeptemberTotal === '' || finalTotal.SeptemberTotal === undefined) return <span>-</span>;
                else if (finalTotal.SeptemberTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.SeptemberTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'OctoberTotal', text: "Oct " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.OctoberTotal === null || finalTotal.OctoberTotal === '' || finalTotal.OctoberTotal === undefined) return <span>-</span>;
                else if (finalTotal.OctoberTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.OctoberTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'NovemberTotal', text: "Nov " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.NovemberTotal === null || finalTotal.NovemberTotal === '' || finalTotal.NovemberTotal === undefined) return <span>-</span>;
                else if (finalTotal.NovemberTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.NovemberTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'DecemberTotal', text: "Dec " + yearselected.toString(), sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal.DecemberTotal === null || finalTotal.DecemberTotal === '' || finalTotal.DecemberTotal === undefined) return <span>-</span>;
                else if (finalTotal.DecemberTotal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.DecemberTotal, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        },
        {
            dataField: 'Total', text: "Net Income", sort: true, 
            sortCaret: (order, column) => customCaretSort(order, column), 
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex), 
            headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                if (finalTotal === null || finalTotal === undefined || finalTotal.Total === null || finalTotal.Total === '' || finalTotal.Total === undefined) return <span>-</span>;
                else if (finalTotal.Total === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(finalTotal.Total, 2))}</span>;
            }, footerAlign: (column, colIndex) => 'right'
        }
    ]

    return columns;
}

//child columns for account expand view
export const accountSummaryChildColumns = [{
    dataField: 'SECNAME', text: "", headerAlign: 'left', sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    classes: 'RCM_common_table_column_text',
    
    formatter: (cell, row) => {
        if (cell === null || cell === '' || cell === undefined) {
            //if symbol is not present , put the cusip value
            if (row.CUSIPNUM === null || row.CUSIPNUM === undefined || row.CUSIPNUM === '') {
                return <span>-</span>
            } else {
                return <span>{row.CUSIPNUM}</span>
            }
        }
        else return <span>{cell}</span>;
    },
}, {
    dataField: 'SECDESCRIPTION', text: "", headerAlign: 'left', sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    classes: 'RCM_common_table_column_text',
    formatter: (cell, row) => {
        return <div className="RCM_symbol_text_bold">{cell}</div>;
    }, 
},
{
    dataField: 'JanuaryTotal', text: "Jan", headerAlign: 'right', sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    classes: 'RCM_common_table_column_number',
    formatter: (cell, row) => {
        return <div>{cell}</div>;
    }, formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }, 
},
{
    dataField: 'FebruaryTotal', text: "Feb", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }, 
},
{
    dataField: 'MarchTotal', text: "March", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'AprilTotal', text: "April", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'MayTotal', text: "May", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'JuneTotal', text: "June", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'JulyTotal', text: "July", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'AugustTotal', text: "August", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'SeptemberTotal', text: "Sept", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'OctoberTotal', text: "Oct", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'NovemberTotal', text: "Nov", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'DecemberTotal', text: "Dec", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
},
{
    dataField: 'Total', text: "Net Income", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    headerAlign: 'right',
    classes: 'RCM_common_table_column_number',
    formatter: (cell) => {
        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(cell)}</span>;
    }
    },
{
        dataField: 'df2', text: "", headerAlign: 'left', isDummyField: true
    },
]

//child columns for account expand view
export function securitySummaryChildColumns(accounts) {
    var columns = [{
        dataField: 'ACCOUNTNICKNAME', text: "Account", headerAlign: 'left', sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        classes: 'RCM_common_table_column_text', formatter: (cell, row) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else return acctFormatter(accounts, cell, row.ACCT);
        },
    }, {
        dataField: 'df2', text: "", headerAlign: 'left', isDummyField: true
    },
    {
        dataField: 'JanuaryTotal', text: "Jan", headerAlign: 'right', sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        classes: 'RCM_common_table_column_number',
        formatter: (cell, row) => {
            return <div>{cell}</div>;
        }, formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },
    {
        dataField: 'FebruaryTotal', text: "Feb", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        },
    },
    {
        dataField: 'MarchTotal', text: "March", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'AprilTotal', text: "April", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'MayTotal', text: "May", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'JuneTotal', text: "June", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'JulyTotal', text: "July", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'AugustTotal', text: "August", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'SeptemberTotal', text: "Sept", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'OctoberTotal', text: "Oct", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'NovemberTotal', text: "Nov", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'DecemberTotal', text: "Dec", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'Total', text: "Net Income", sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
        headerAlign: 'right',
        classes: 'RCM_common_table_column_number',
        formatter: (cell) => {
            if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
            else if (cell === 0) return <span>$0</span>;
            else return <span>{formatMoney(cell)}</span>;
        }
    },
    {
        dataField: 'df3', text: "", headerAlign: 'left', isDummyField: true
    },
    ]

    return columns;
}

//method to get child table rows on expand used for Account tab
export function renderRowsByAccount(rowdata, childColumnToSort, childColumnSortOrder) {
    let data = []
    let datarows = []
    if (childColumnToSort === 'ACCOUNTNICKNAME') childColumnToSort = 'symbol';
    data = rowdata.INVPOSLIST;
    if (data) {
        data.map((d, i) =>
            datarows.push({
                CUSIPNUM: d.CUSIPNUM ? d.CUSIPNUM : null,
                symbol: d.SECNAME ? d.SECNAME : null,
                desc: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
                ordinarydividends: d.ORDDIV ? d.ORDDIV : null,
                interestincome: d.INTINCOME,
                netincome: d.TOTAL,
                capgain: d.TOTCAPGAIN ,
                UNQID:(d.ACCT)+(d.SECNAME)
            }
            ));
        if (childColumnToSort !== '' && childColumnSortOrder !== '') {
            if (childColumnToSort === 'symbol') {
                datarows = orderBy(datarows, [row => row[childColumnToSort].toLowerCase()], [childColumnSortOrder]);
            }
            else {
                datarows = orderBy(datarows, [childColumnToSort], [childColumnSortOrder]);
            }
        }
           
        return datarows;
    }
    return datarows;
}

export function renderRowsForAccount(rowdata,accountlist) {
    let datarows = []
    if (rowdata) {
        rowdata.map((d, i) =>
            datarows.push({
                UNQID: d.CUSIPNUM ? d.CUSIPNUM : null,
                symbol: d.SECNAME ? d.SECNAME : null,
                account: d.ACCT ? d.ACCT : null,
                desc: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
                ordinarydividends: d.ORDDIV,
                interestincome: d.INTINCOME ,
                netincome: d.TOTAL,
                capgain: d.TOTCAPGAIN ,
                id: i,
                INVPOSLIST: d.ItemDetail,
                ACCTKEY: d.ACCT ? d.ACCT : null,
                ACCOUNTNICKNAME: getAccountDisplayName(accountlist, d.ACCT, IndLongShort.SINGLEVIEW)
            })
        );
    }
    return datarows;
}

//method to get child table rows on expand used for security and Account tab
export function renderRowsBySecurity(rowdata) {

    let datarows = []
    for (var i = 0; i < rowdata.length; i++) {
        let d = rowdata[i];
        datarows.push({
            UNQID: d.CUSIPNUM ? d.CUSIPNUM : null,
            symbol: d.SECNAME ? d.SECNAME : null,
            desc: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
            ordinarydividends: d.ORDDIV,
            interestincome: d.INTINCOME,
            netincome: d.TOTAL,
            capgain: d.TOTCAPGAIN,
            INVPOSLIST: d.ItemDetail,
            CUSIPNUM: d.CUSIPNUM ? d.CUSIPNUM : null
        });
    }
    return datarows;
}

//method to get child table rows on expand used for security and Account tab
export function renderRowsBySecurityChild(rowdata, accountlist,childColumnToSort,childColumnSortOrder) {
    let datarows = []
    if (childColumnToSort === 'symbol') childColumnToSort = 'ACCOUNTNICKNAME';
    if (rowdata.INVPOSLIST && rowdata.INVPOSLIST.length > 0) {
        for (var i = 0; i < rowdata.INVPOSLIST.length; i++) {
            let d = rowdata.INVPOSLIST[i];
            datarows.push({
                UNQID: d.CUSIPNUM ? d.CUSIPNUM : null,
                symbol: d.SECNAME ? d.SECNAME : null,
                account: d.ACCT ? d.ACCT : null,
                desc: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
                ordinarydividends: d.ORDDIV ,
                interestincome: d.INTINCOME,
                netincome: d.TOTAL ? d.TOTAL : null,
                capgain: d.TOTCAPGAIN ? d.TOTCAPGAIN : null,
                ACCTKEY: d.ACCT ? d.ACCT : null,
                id: i,
                ACCOUNTNICKNAME: getAccountDisplayName(accountlist, d.ACCT, IndLongShort.SINGLEVIEW)
            });
        }
        if (childColumnToSort !== '' && childColumnSortOrder !== '') {
            if (childColumnToSort === 'ACCOUNTNICKNAME') {
                datarows = orderBy(datarows, [row => row[childColumnToSort].toLowerCase()], [childColumnSortOrder]);
            }
            else {
                datarows = orderBy(datarows, [childColumnToSort], [childColumnSortOrder]);
            }
        }
    }
    return datarows;
}
export function renderRowsBySecuritySummaryChild(rowdata, accountlist, childColumnToSort, childColumnSortOrder) {
    if (childColumnToSort === 'SECNAME') childColumnToSort = 'ACCOUNTNICKNAME';
    for (var i = 0; i < rowdata.length; i++) {
        rowdata[i].ACCOUNTNICKNAME = getAccountDisplayName(accountlist, rowdata[i].ACCT, IndLongShort.SINGLEVIEW);
    }
    if (childColumnToSort !== '' && childColumnSortOrder !== '') {
        if (childColumnToSort === 'ACCOUNTNICKNAME') {
            rowdata = orderBy(rowdata, [row => row[childColumnToSort].toLowerCase()], [childColumnSortOrder]);
        }
        else {
            rowdata = orderBy(rowdata, [childColumnToSort], [childColumnSortOrder]);
        }
    }
        
    return rowdata;
}

export function renderRowsByAccountSummaryChild(rowdata, accountlist, childColumnToSort, childColumnSortOrder) {
    if (childColumnToSort === 'ACCOUNTNICKNAME') childColumnToSort = 'SECNAME';
    for (var i = 0; i < rowdata.length; i++) {
        rowdata[i].ACCOUNTNICKNAME = getAccountDisplayName(accountlist, rowdata[i].ACCT, IndLongShort.SINGLEVIEW);
    }
    if (childColumnToSort !== '' && childColumnSortOrder !== '') {
        if (childColumnToSort === 'SECNAME') {
            rowdata = orderBy(rowdata, [row => row[childColumnToSort].toLowerCase()], [childColumnSortOrder]);
        }
        else {
            rowdata = orderBy(rowdata, [childColumnToSort], [childColumnSortOrder]);
        }
    }
        
    return rowdata;
}

export function getDisplayName(account) {

    let accountNo = (account.number === undefined || account.number === null || account.number === '') ? '' : account.number;
    let nickname = (account.nickname === undefined || account.nickname === null || account.nickname === '') ? '' : account.nickname;
    let regtypecode = (account.regtypecode === undefined || account.regtypecode === null || account.regtypecode === '') ? '' : " (" + account.regtypecode + ") ";

    if (accountNo === "All Accounts") {
        return accountNo;
    }
    else { return accountNo + " " + nickname + " " + regtypecode; }

}

function GetClassName(value) {
    if (value === '' || value === null || value === undefined) {
        return "";
    }
    else if (value >= 0) {
        return "RCM_positiveval";
    }
    else if (value < 0) {
        return "RCM_negativeval";
    }
}


function acctFormatter(accountlist, cell, key) {
    return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}</div>)
}

function checkIfFieldEmpty(rowcol, fieldname) {
    let filterrows = rowcol.filter(acc => (acc[fieldname] === null || acc[fieldname] === '' || acc[fieldname] === undefined));
    if (filterrows && filterrows.length === rowcol.length) {
        return true;
    }
    return false;
}

export const monthDropDownItems = [
    {
        label: 'All',
        value: ''
    },
    {
        label: "January",
        value: "JAN"
    },
    {
        label: "February",
        value: "FEB"
    },
    {
        label: "March",
        value: "MAR"
    },
    {
        label: "April",
        value: "APR"
    },
    {
        label: "May",
        value: "MAY"
    },
    {
        label: "June",
        value: "JUN"
    },
    {
        label: "July",
        value: "JUL"
    },
    {
        label: "August",
        value: "AUG"
    },
    {
        label: "September",
        value: "SEP"
    },
    {
        label: "October",
        value: "OCT"
    },
    {
        label: "November",
        value: "NOV"
    },
    {
        label: "December",
        value: "DEC"
    }

];

export const yearDropDownItems = [
    {
        label: "2019",
        value: "2019"
    },
    {
        label: "2018",
        value: "2018"
    }
];