import {  Get } from "../../../common/servicecalls.jsx";
import { errorMsg, getReportsURL, downloadReportURL } from "../../../common/constants"
import axios from "axios";

export var getReportsCancellationToken = null;
export const getReportsService = (token, contextJson, profileJson, requesterId) => {
    //Check if cancellation token has value;
    if (getReportsCancellationToken !== null && getReportsCancellationToken !== undefined) {
        getReportsCancellationToken.cancel('Cancelling the get reports request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getReportsCancellationToken = cancelToken.source();

    let getReportsPromise = new Promise(
        function (resolve, reject) {
            let getReportsurl = getReportsURL + requesterId;
            Get(getReportsurl, token, contextJson, profileJson, getReportsCancellationToken).then(res => {
                // get the response in case of successful call.
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Get Reports Service : get Reports List :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return getReportsPromise;
}

export var downloadReportCancellationToken = null;
export const downloadReportService = (token, contextJson, profileJson, repotyId) => {
    //Check if cancellation token has value;
    if (downloadReportCancellationToken !== null && downloadReportCancellationToken !== undefined) {
        downloadReportCancellationToken.cancel('Cancelling the download report request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    downloadReportCancellationToken = cancelToken.source();

    let downloadReportPromise = new Promise(
        function (resolve, reject) {
            let downloadReporturl = downloadReportURL + repotyId;
            Get(downloadReporturl, token, contextJson, profileJson, downloadReportCancellationToken).then(res => {
                // get the response in case of successful call.
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve('File cannot be accessed.');
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Download Reports Service : Download Report :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return downloadReportPromise;
}