import React from 'react';
import { roundvalue, formatMoney, getAccountDisplayName, IndLongShort } from '../../../../common/constants.jsx';
import orderBy from 'lodash/orderBy';
export const getAssetGroupInstitutionViewParentColumns = (rowsortevent) => {
    return ([
        {
            dataField: 'finame',
            text:'Account',
            sort: true,
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            headerAlign: 'left',
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
            classes: 'RCM_AO_parent_groupName',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'fitotalvalue',
            text:'Total Value',
            sort: true,
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            headerAlign: 'right',
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
            classes: 'RCM_AO_parent_grouptotalvalue',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <div align="right">-</div>;
                else return <div align="right">{cell === 0 ? '$0' : formatMoney(roundvalue(cell))}</div>;
            }
        },
        {
            dataField: 'figroupvaluechange',
            text:"Today's Change",
            sort: true,
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            headerAlign: 'right',
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
            classes: 'RCM_AO_parent_totalgroupvaluechange',
            formatter: (cell, rows) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return (<div className="RCM_assetspantot">
                    <span className={"RCM_positiveval " + getClassName((cell))}>
                        {(cell === undefined || cell === null || cell == 0) ? '$0' : formatMoney(roundvalue(cell, 2))}</span>&nbsp;/&nbsp;
                    <span className={"RCM_positiveval " + getClassName((rows.totalgroupfivaluepercentchange))}>
                        {(rows.totalgroupfivaluepercentchange === undefined || rows.totalgroupfivaluepercentchange === null || rows.totalgroupfivaluepercentchange == 0) ? '0%' :
                            `${roundvalue(rows.totalgroupfivaluepercentchange, 2)}%`}
                    </span>
                </div>)

            }
        }
    ]);
}
export const renderChildRowsForInstitutionView = (rowdata, accountlist, childColumnToSort, childColumnSortOrder) => {
    if (childColumnToSort === 'finame') childColumnToSort = 'accountnickname';
    if (childColumnToSort === 'fitotalvalue') childColumnToSort = 'totalaccountvalue';
    if (childColumnToSort === 'figroupvaluechange') childColumnToSort = 'totalaccountvaluechange';
    for (var i = 0; i < rowdata.length; i++) {
        rowdata[i].accountnickname = getAccountDisplayName(accountlist, rowdata[i].accountaumber, IndLongShort.SINGLEVIEW)
    }
    if (childColumnToSort !== '' && childColumnSortOrder !== '')
        if (childColumnToSort === 'accountnickname')
            rowdata = orderBy(rowdata, [row => row[childColumnToSort].toLowerCase()], [childColumnSortOrder]);
        else
            rowdata = orderBy(rowdata, [childColumnToSort], [childColumnSortOrder]);
    return rowdata;

}

export const getAssetGroupInstitutionViewChildColumns = (accounts) => {
    return ([
        {
            dataField: 'accountnickname',
            text:'Account Number',
            sort: false,
            classes:"RCM_Asset_table_child_accountNumber_col",
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <div title={getAccountDisplayName(accounts, row.accountaumber, IndLongShort.LONG)}>{getAccountDisplayName(accounts, row.accountaumber, IndLongShort.SINGLEVIEW)}</div>;
            }
        },
        {
            dataField: 'totalaccountvalue',
            text:'Total Value',
            sort: false,
            classes: "RCM_Asset_table_child_totalaccountvalue",
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <div align="right">-</div>;
                else return <div align="right">{cell === 0 ? '$0' : formatMoney(roundvalue(cell))}</div>;
            }
        },
        {
            dataField: 'totalaccountvaluechange',
            text:"Today's Change",
            sort: false,
            classes: "RCM_Asset_table_child_totalaccountvaluechange",
            formatter: (cell, rows) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return (<div className="RCM_assetspantot">
                    <span className={"RCM_positiveval " + getClassName((cell))}>
                        {(cell === undefined || cell === null || cell == 0) ? '$0' : formatMoney(roundvalue(cell, 2))}</span>&nbsp;/&nbsp;
                    <span className={"RCM_positiveval " + getClassName((rows.totalaccountvaluepercentchange))}>
                        {(rows.totalaccountvaluepercentchange === undefined || rows.totalaccountvaluepercentchange === null || rows.totalaccountvaluepercentchange == 0) ? '0%' :
                            `${roundvalue(rows.totalaccountvaluepercentchange, 2)}%`}
                    </span>
                </div>)

            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            classes:"RCM_dummy_col"
        }
    ]);
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function columnHeaderStyle(column, colIndex)
{
  return { 'textAlign': 'left', 'whiteSpace': 'nowrap' };
}

function getClassName(val) {
    if (val == '') {
        return ''
    } else if (val >= 0) {
        return "RCM_positiveval";
    }
    else {
        return "RCM_negativeval";
    }
}