
import cloneDeep from 'lodash/cloneDeep';
import { AggregratedAccount } from '../accounts/detail/assetaggregation/models/assetAggregationInfo';

export function getLoginBackGroundColor(res){
    if(res && res.uiAssets && res.uiAssets.backgroundColor){
        return  res.uiAssets.backgroundColor.includes('#') ? res.uiAssets.backgroundColor : `#${res.uiAssets.backgroundColor}`;
    }else{
        return '#ddd';
    }
}

export function getFiImageUrl(imgProps){
    let url = '';
    if(imgProps !== null && imgProps !== undefined){       
        let backgroundColor= imgProps.backgroundColor ? imgProps.backgroundColor : '';
        if(imgProps.imagePath !== null && imgProps.imagePath !== undefined ){
                imgProps.imagePath.includes('https://') ? 
                url = imgProps.imagePath :
                url = `https://res.cloudinary.com/ninth-wave/image/upload/b_rgb:${backgroundColor},bo_10px_solid_rgb:${backgroundColor},c_lpad,h_90,w_90/NWUI_fiLogos/${imgProps.imagePath}`;
        }
    }else{
        url = `https://res.cloudinary.com/ninth-wave/image/upload/c_lpad,h_1,w_1/NWUI_fiLogos/EmptyLogo`;
    }
    

    return url;
}


export function getFiLogoImageUrl(imgProps){
    let url = '';
    if(imgProps !== null && imgProps !== undefined){       
        //let backgroundColor= imgProps.backgroundColor ? imgProps.backgroundColor : '';
        if(imgProps.imagePath !== null && imgProps.imagePath !== undefined && !imgProps.imagePath.includes('EmptyLogo') ){
                imgProps.imagePath.includes('https://') ? 
                url = imgProps.imagePath :
                url = `https://res.cloudinary.com/ninth-wave/image/upload/c_lpad,h_400,w_600/NWUI_fiLogos/${imgProps.imagePath}`;
        }
    }else{
        url = '';
    }
    

    return url;
}


export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

export function lightOrDark(color){
    let theme = 'light';
    let rgbColor = hexToRgb(color);
    if(rgbColor){
        let {r,g,b} = rgbColor;
        let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
        (hsp>127.5) ? theme = 'light' : theme = 'dark';
    }

    return theme;
}


export function getUserAggrAcctsList(accounts){
    
    let aggregatedAcctList = [];

     //get the financial institution name 
     let fiName = (accounts && accounts.fiName) ? accounts.fiName : "";
     let fiid = (accounts && accounts.fiDirId) ? accounts.fiDirId : "";

     //List of deposite accounts 
     if(accounts.depositAccount && Array.isArray(accounts.depositAccount) && accounts.depositAccount.length > 0)
     {
         accounts.depositAccount.map(depAcct => {
             aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(depAcct, fiName, fiid));
         })
     }

     //List of investement account
     if(accounts.investmentAccount && Array.isArray(accounts.investmentAccount) && accounts.investmentAccount.length > 0)
     {
         accounts.investmentAccount.map(invAcct => {
             aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(invAcct, fiName, fiid));
         })
     }

     //List of loc accounts 
     if(accounts.locAccount && Array.isArray(accounts.locAccount) && accounts.locAccount.length > 0)
     {
         accounts.locAccount.map(locAcct => {
             aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(locAcct, fiName, fiid));
         })
     }

     //List of loan accounts
     if(accounts.loanAccount && Array.isArray(accounts.loanAccount) && accounts.loanAccount.length > 0) {
         accounts.loanAccount.map(loanAcct => {
             aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(loanAcct, fiName, fiid))
         })
     }

     return aggregatedAcctList;

}

export function mapAggregatedAccountToRcmAcct(account, fiName, fiid)
{
    let RcmAcct = new AggregratedAccount();
    //Map values here...
    RcmAcct.number = (account && account.accountNumber) ? account.accountNumber : "";
    RcmAcct.key = (account && account.accountKey) ? account.accountKey : "";
    //RcmAcct.status = (account && account.accountStatus) ? account.accountStatus : "";
    RcmAcct.status = 'PENDING';
    RcmAcct.accttype = (account &&  account.accountType) ? account.accountType : "";
    RcmAcct.routingTransitNumber = (account && account.routingTransitNumber) ? account.routingTransitNumber : "";
    RcmAcct.nickname = (account && account.displayName) ? account.displayName : "";
    RcmAcct.finame = fiName;
    RcmAcct.fiid = fiid;
    RcmAcct.fullAccountNumber = (account && account.fullAccountNumber) ? account.fullAccountNumber : "";
    //we do not have role, stackholder and managed account
    //accountid and brokerid should be part of rcm account  

    return RcmAcct;
}


export function getSelectedAccountDetails(accounts, selectedAcctKeys){

    let selectedAccts = {};
    let depositAccountList = [];
    let investmentAccountList = [];
    let locAccountList = [];
    let loanAccountList = [];

     //List of deposite accounts 
     if(accounts && accounts.depositAccount && Array.isArray(accounts.depositAccount) && accounts.depositAccount.length > 0)
     {
         for(let i=0; i< accounts.depositAccount.length; i++){
             if(selectedAcctKeys.includes(accounts.depositAccount[i].accountKey)){
                // delete accounts.depositAccount[i].fullAccountNumber;
                // delete accounts.depositAccount[i].routingTransitNumber;
                delete accounts.depositAccount[i].enrolled
                depositAccountList.push(accounts.depositAccount[i]);
             }
         }
     }

     //List of investement account
     if(accounts && accounts.investmentAccount && Array.isArray(accounts.investmentAccount) && accounts.investmentAccount.length > 0)
     {
        for(let i=0; i< accounts.investmentAccount.length; i++){
            if(selectedAcctKeys.includes(accounts.investmentAccount[i].accountKey)){
                //delete accounts.investmentAccount[i].fullAccountNumber;
                delete accounts.investmentAccount[i].enrolled
                investmentAccountList.push(accounts.investmentAccount[i]);
            }
        }
     }

     //List of loc accounts 
     if(accounts && accounts.locAccount && Array.isArray(accounts.locAccount) && accounts.locAccount.length > 0)
     {
        for(let i=0; i< accounts.locAccount.length; i++){
            if(selectedAcctKeys.includes(accounts.locAccount[i].accountKey)){
                //delete accounts.locAccount[i].fullAccountNumber;
                delete accounts.locAccount[i].enrolled
                locAccountList.push(accounts.locAccount[i]);
            }
        }
     }

     //List of loan accounts 
     if(accounts && accounts.loanAccount && Array.isArray(accounts.loanAccount) && accounts.loanAccount.length > 0)
     {
        for(let i=0; i< accounts.loanAccount.length; i++){
            if(selectedAcctKeys.includes(accounts.loanAccount[i].accountKey)){
                //delete accounts.locAccount[i].fullAccountNumber;
                delete accounts.loanAccount[i].enrolled
                loanAccountList.push(accounts.loanAccount[i]);
            }
        }
     }

     if(depositAccountList.length > 0){
        selectedAccts['depositAccount'] = depositAccountList;
     }

     if(investmentAccountList.length > 0){
        selectedAccts['investmentAccount'] = investmentAccountList;
     }

     if(locAccountList.length > 0){
        selectedAccts['locAccount'] = locAccountList;
     }

     if(loanAccountList.length > 0 ) {
         selectedAccts['loanAccount'] = loanAccountList;
     }

     return selectedAccts;
}

function getDummyUserNamePassword(type){
    if(type === "username") return "dummyUser"
    else if(type === "password") return "dummyPass"
    else return ""
}

export function getEnrollmentRequestDetails(loginUserCred, selectedAcct, fiLogin, fiAccounts, userDetails, isCommercialFlow){
    if(isCommercialFlow){
        const enrollmentReq = {
            accounts: selectedAcct,
            fiDirId: fiLogin && fiLogin.fiDirId ? fiLogin.fiDirId : '',
            customer: {
                customerKey: (fiAccounts && fiAccounts.customerKey) ? fiAccounts.customerKey : '',
                name:{
                    firstName: userDetails && userDetails.firstName ? userDetails.firstName: '',
                    lastName:  userDetails && userDetails.lastName? userDetails.lastName: ''
                },
                email: userDetails && userDetails.emailId ? userDetails.emailId: ''
            },
            signOnInfoParams: fiLogin && fiLogin.signOnInfo && fiLogin.signOnInfo.signOnParams && Array.isArray(fiLogin.signOnInfo.signOnParams)
            ? fiLogin.signOnInfo.signOnParams.map(signInParam => ({
                signOnParamId: signInParam.signOnParamId,
                paramName: signInParam.paramName,
                paramValue: getDummyUserNamePassword(signInParam.parameterTypeName)
            }))
            : []
        }
        return enrollmentReq;
    } else {    
        let enrollRequest = cloneDeep(loginUserCred);
        enrollRequest.fiDirId = fiLogin && fiLogin.fiDirId ? fiLogin.fiDirId : '';
        enrollRequest.accounts = selectedAcct;
        enrollRequest.customer.customerKey = (fiAccounts && fiAccounts.customerKey) ? fiAccounts.customerKey : '';
        enrollRequest.customer.customerReferenceId = "";
        return enrollRequest;
    }
}

export const isSvgImg = (imgProps) => {
    let isSvg = false

    if (imgProps !== null && imgProps !== undefined) {
        if (imgProps.imagePath !== null && imgProps.imagePath !== undefined) {
            isSvg = imgProps.imagePath.includes('.SVG') || imgProps.imagePath.includes('.svg')
        }
    }

    return isSvg
}

export const getMaskedLoginRequest = (loginRequest) => {
    let response  = {}
     if(loginRequest) {
        try{
            let clonedLoginObject = cloneDeep(loginRequest)
            
            if(clonedLoginObject && clonedLoginObject.signOnInfoParams && Array.isArray(clonedLoginObject.signOnInfoParams)) {
                clonedLoginObject.signOnInfoParams.map(singInParams => {
                    if(singInParams && singInParams.paramValue){
                        singInParams.paramValue = `XXXX-MASKED`
                    }
                })
            }

            if(clonedLoginObject && clonedLoginObject.mfaChallengeAnswers && Array.isArray(clonedLoginObject.mfaChallengeAnswers)) {
                clonedLoginObject.mfaChallengeAnswers.map(challenges => {
                    if(challenges && challenges.mfaChallengeAnswer) {
                        challenges.mfaChallengeAnswer = `XXXX-MASKED`
                    }
                })
            }

            response = clonedLoginObject
      }
      catch(err) {
          console.error(err)
      }
    }
    return response
}