import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import ErrorBoundary from '../../common/errorboundary';
import Aviation from './aviation/aviation';
import ElderlyCare from "./elderlyCare/elderlyCare";
import NextGenAdvisory from "./nextGenAdvisory/nextGenAdvisory";
import Art from "./art/art";
import Health from './health/health';
import immersiveXContainer from './immersiveX/immersiveXContainer';
import inspiratoContainer from './inspirato/inspiratoContainer';
import cyberWaContainer from './cyberWA/cyberWaContainer';
import proofLoyaltyContainer from './proofLoyalty/proofLoyaltyContainer';
import LifestyleOptions from './lifestyleOptions';
import GlobalRescueContainer from './globalRescue/globalRescueContainer';
import PersonalSecurityContainer from './personalSecurity/personalSecurityContainer';
import VirtualSpecialityCare from './virtualSpecialityCare/virtualSpecialityCareContainer';
class Lifestyles extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <ErrorBoundary>
              <div className="mt-4">
                <Switch>
                  <Route
                    key="lifestyles"
                    exact
                    path="/LifeStyle"
                    component={LifestyleOptions}
                  />
                  <Route
                    key="aviation"
                    exact
                    path="/aviation"
                    component={Aviation}
                  />
                  <Route
                    key="eldercare"
                    exact
                    path="/eldercare"
                    component={ElderlyCare}
                  />
                  <Route key="art" exact path="/art" component={Art} />
                  <Route
                    key="nextgen"
                    exact
                    path="/nextgen"
                    component={NextGenAdvisory}
                  />
                  <Route key="health" exact path="/health" component={Health} />
                  <Route
                    key="immersivex"
                    exact
                    path="/immersivex"
                    component={immersiveXContainer}
                  />
                  <Route
                    key="inspirato"
                    exact
                    path="/inspirato"
                    component={inspiratoContainer}
                  />
                  <Route
                    key="cyberwa"
                    exact
                    path="/cyberwa"
                    component={cyberWaContainer}
                  />
                  <Route
                    key="proofloyalty"
                    exact
                    path="/proofloyalty"
                    component={proofLoyaltyContainer}
                  />
                  <Route
                    key="globalrescue"
                    exact
                    path="/globalrescue"
                    component={GlobalRescueContainer}
                  />
                  <Route
                    key="personalsecurity"
                    exact
                    path="/personalsecurity"
                    component={PersonalSecurityContainer}
                  />
                  <Route
                    key="virtualspecialitycare"
                    exact
                    path="/virtualspecialitycare"
                    component={VirtualSpecialityCare}
                  />
                </Switch>
              </div>
          </ErrorBoundary>
        );
    }
}
const mapStateToProps = (store) => {
    return {
        preferences: store.acct.preferences,
        isphilanthropy:true// as per discussion with Sajeev enabled it for all store.acct.isphilanthropy
    };
}

export default withRouter(connect(mapStateToProps)(Lifestyles));
