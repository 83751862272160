import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UpdateAccount, UpdateSelectorView } from '../actions/accountsactions.jsx';
import { UpdateTopten } from '../actions/toptenactions.jsx';
import { UpdateDocuments } from '../actions/documentactions.jsx';
import { AccountSelectorWithGroupView } from './accountselectorwithgroupview.jsx';
import { setContextInCache } from '../services/home/userServices.jsx';
import axios from 'axios';
import { Post } from '../common/servicecalls.jsx';
import { GetPreferenceValue, PreferencesUrl } from './constants';
class AccountSelectorWithGroup extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelections = this.handleSelections.bind(this);
        this.selectUnSelectAllOptions = this.selectUnSelectAllOptions.bind(this);
        this.apply = this.apply.bind(this);
        this.cancel = this.cancel.bind(this);
        this.disableApply = this.disableApply.bind(this);
        //this.filterList = this.filterList.bind(this);
        //this.updateSearchKeyword = this.updateSearchKeyword.bind(this);
        this.getDisplayName = this.getDisplayName.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.checkAllSubAccountSelected = this.checkAllSubAccountSelected.bind(this);
        this.checkAllAccountSelected = this.checkAllAccountSelected.bind(this);
        this.selectUnSelectAllSubAccounts = this.selectUnSelectAllSubAccounts.bind(this);
        this.createAccountList = this.createAccountList.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.toggleGroupView = this.toggleGroupView.bind(this);
        this.createGroupedAccountList = this.createGroupedAccountList.bind(this);
        this.isOnlyOneGroupSelected = this.isOnlyOneGroupSelected.bind(this);

        this.state = {
            dropdownOpen: false,
            allSelected: true,
            dropDownValue: "All Accounts",
            allAccount: { id: 0, value: "All Accounts", isChecked: true },
            accounts: [],
            options: [],
            initialSelected: [],
            initialAllAccountSelected: { id: 0, value: "All Accounts", isChecked: true },
            initialDropDownValue: "All Accounts",
            initialOptions: [],
            render: this.props.render,
            disableApply: "disabled",
            items: [],
            //searchKeyword: '',
            activeKey: "1",
            showExternalAccounts: this.props.showExternalAccounts,
            newAccounts: this.props.accountList,
            groupToggleValue: true,
            groupedAccounts: [],
            initailGroupedAccounts: [],
            instGroupedAccounts: [],
            initailInstGroupedAccounts: [],
            selectedGroupName: "",
            initialSelectedGroupName: ""
           // groupedAccountsNew: this.props.groupedAccounts
        };
    }
    componentDidMount() {
        let grouptogglevalue = GetPreferenceValue(this.props.acct, "AllAccountSelectorView", "firm") === "firm" ? true : false;
        grouptogglevalue ? this.props.UpdateSelectorView("firm") : this.props.UpdateSelectorView("group");
        this.setState(({ groupToggleValue: grouptogglevalue }), () => {
            if (this.props.simulationAccount && this.props.simulationAccount !== null && this.props.simulationAccount !== '')
            {
                this.createAccountList('init-with-simulated-account');
            } else if (this.state.groupToggleValue) {
                this.createAccountList('init');
            } else {
                this.createAccountLists();
            }
            
        });
    }
    createAccountLists = () => {
        let options = [];
        for (const [, group] of this.props.profileResp.groupedaccounts.entries()) {
            if (group.isdefault) {
                if (group.accounts !== undefined && group.accounts !== null && group.accounts.length !== 0) {
                    for (const [, account] of group.accounts.entries()) {
                        var indexOfAccount = -1;
                        options.findIndex(function (obj, i) {
                            if (obj.key === account.key) {
                                indexOfAccount = i;
                            }
                        })
                        if (indexOfAccount < 0) {
                            options.push(account);
                        }
                    }
                }
            }
        }
        this.setState({ options: options }, () => {
            if (options.length > 0) {
                this.createAccountList('init_with_default_group');
                
            } else {
                this.createAccountList('init');
            }
            
        })
        
    }
    createGroupedAccountList(source) {
        var groupedAccounts = [];
        groupedAccounts = JSON.parse(JSON.stringify(this.props.profileResp.groupedaccounts));
        
        var options = this.state.options;
        if (groupedAccounts !== undefined && groupedAccounts !== null && groupedAccounts.length !== 0) {
            for (const [index, group] of groupedAccounts.entries()) {
                var tempValue = "All sub Accounts " + group.groupid
                group.allAccount = { id: group.groupid, value: tempValue, isChecked: true };
                if (index === 0) {
                    group.isOpen = true;
                } else {
                    group.isOpen = false;
                }
                var externalAccountsIndexs = [];
                if (group.accounts !== undefined && group.accounts !== null && group.accounts.length !== 0) {
                    for (const [index, account] of group.accounts.entries()) {
                        var indexOfAccount = -1;
                        options.findIndex(function (obj, i) {
                                    if (obj.key === account.key) {
                                        indexOfAccount = i;
                                    }
                                })
                        if (source !== "showExternalAccounts") {
                            if (indexOfAccount >= 0) {
                                account.isChecked = true;

                            } else {
                                account.isChecked = false;
                            }
                            if ((!this.state.showExternalAccounts && !account.isInternal) || (source === "hideExternalAccounts" && !account.isInternal)) {
                                externalAccountsIndexs.push(index);
                            }
                        } else if (source === "showExternalAccounts") {
                            if (!account.isInternal) {
                                account.isChecked = true;
                            } else {
                                if (indexOfAccount >= 0) {
                                    account.isChecked = true;

                                } else {
                                    account.isChecked = false;
                                }
                            }
                        }
                        if (source === "init_with_default_group" || source === "add" || source === "delete" || source === "updateNickname" || source === "groupUpdated" || source === "nicknameUpdatedFromGroupOrManualAsset" ||
                            source === "manualAssetsAdded" || source === "manualAssetDeleted") {
                            if (indexOfAccount >= 0) {
                                account.isChecked = true;
                            } else {
                                account.isChecked = false;
                            }
                            if (source === "add") {
                                var modifiedAccounts = this.props.aggregatedAcctModifiedList.modifiedAccounts;
                                for (const [, addedAccount] of modifiedAccounts.entries()) {
                                    if (account.key === addedAccount.key) {
                                        account.isChecked = true;
                                    }
                                }
                            } else if (source === "manualAssetsAdded") {
                                var addedAccountKey = this.props.modifiedManualAccountKey;
                                if (account.key === addedAccountKey) {
                                    account.isChecked = true;
                                }
                            }
                        }
                    }
                    if (source !== "showExternalAccounts") {
                        for (var i = externalAccountsIndexs.length - 1; i >= 0; i--)
                            group.accounts.splice(externalAccountsIndexs[i], 1);
                    }
                }
            }
            this.state.groupedAccounts = groupedAccounts;
            if (!this.state.groupToggleValue) {
                this.state.accounts = groupedAccounts;
            }
            this.setState({ initailGroupedAccounts: groupedAccounts }, () => {
                if (source !== "init") {
                    this.setDropdownValue();
                    this.setContext();
                }
                
            });
        }
    }
    createAccountList(source) {
        var accordionList = [];
        var options = this.state.options;
        var newOptions = [];
        var allAccount = this.state.allAccount;
        let simulationAccount = this.props.simulationAccount;

        if (source === 'init_with_default_group' || source === "init-with-simulated-account") {
            allAccount.isChecked = false;
        }
        var newAccounts = {};
            newAccounts.accounts = JSON.parse(JSON.stringify(this.props.profileResp.accounts));
            newAccounts.externalAccounts = JSON.parse(JSON.stringify(this.props.profileResp.groupedexternalaccounts));
            
        this.setState({ newAccounts: newAccounts }, () => {
            if (this.state.newAccounts.accounts && this.state.newAccounts.accounts.length !== 0) {
                var internalAccounts = {};
                internalAccounts.groupname = "Rockefeller";
                internalAccounts.groupid = "rockcoid";
                internalAccounts.allAccount = { id: "rockcoid", value: "All sub Accounts RockId", isChecked: true };
                internalAccounts.isOpen = true;
                internalAccounts.accounts = this.state.newAccounts.accounts;
                for (const [, item] of internalAccounts.accounts.entries()) {
                    var indexOfAccount = -1;
                    item.isInternal = true;
                    options.findIndex(function (obj, i) {
                        if (obj.key === item.key) {
                            indexOfAccount = i;
                        }
                    })
                    if (source === "init-with-simulated-account") {
                        if (item.key === simulationAccount) {
                            item.isChecked = true;
                            options.push({ ...item });
                        } else {
                            item.isChecked = false;
                        }
                    }
                    else if (source === "init") {
                        item.isChecked = true;
                        options.push({ ...item });
                    } else if ((source === "init_with_default_group" || source === "add" || source === "delete" || source === "showExternalAccounts" || source === "hideExternalAccounts" || source === "updateNickname" || source === "groupUpdated" ||
                        source === "nicknameUpdatedFromGroupOrManualAsset" || source === "manualAssetsAdded" || source === "manualAssetDeleted") && indexOfAccount >= 0) {
                        item.isChecked = true;
                        if (source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset") {
                            newOptions.push({ ...item });
                        }
                    } else {
                        item.isChecked = false;
                    }
                }
                accordionList.push(internalAccounts);
            }
            if (this.state.showExternalAccounts && this.state.newAccounts.externalAccounts !== null && this.state.newAccounts.externalAccounts !== undefined && this.state.newAccounts.externalAccounts.length !== 0) {
                var modifiedAccounts = this.props.aggregatedAcctModifiedList.modifiedAccounts;
                for (const [, value] of this.state.newAccounts.externalAccounts.entries()) {
                    var account = {};
                    account.accounts = JSON.parse(JSON.stringify(value.groupby));
                    var allAccountText = "All sub Accounts " + value.groupid;

                    account.isOpen = false;

                    account.groupname = value.groupname;
                    account.groupid = value.groupid;
                    if (account.accounts && account.accounts !== undefined && account.accounts !== null) {
                        for (const [, item] of account.accounts.entries()) {
                            item.isChecked = false;
                            item.isInternal = false;
                            var indexOfAccount = -1;
                            options.findIndex(function (obj, i) {
                                if (obj.key === item.key) {
                                    indexOfAccount = i;
                                }
                            })

                            if (source === "init-with-simulated-account") {
                                if (item.key === simulationAccount) {
                                    item.isChecked = true;
                                    options.push({ ...item });
                                } else {
                                    item.isChecked = false;
                                }
                            }
                            else if (source === "init" || source === "showExternalAccounts") {
                                item.isChecked = true;
                                options.push({ ...item });
                            } else if (source === "init_with_default_group" || source === "add" || source === "delete" || source === "updateNickname" || source === "groupUpdated" || source === "nicknameUpdatedFromGroupOrManualAsset" ||
                                source === "manualAssetsAdded" || source === "manualAssetDeleted") {
                                if (indexOfAccount >= 0) {
                                    item.isChecked = true;
                                    if (source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset") {
                                        newOptions.push({ ...item });
                                    }
                                } else {
                                    item.isChecked = false;
                                }
                                if (source === "add") {
                                    for (const [, addedAccount] of modifiedAccounts.entries()) {
                                        if (item.key === addedAccount.key) {
                                            item.isChecked = true;
                                            options.push({ ...item });
                                        }
                                    }
                                }
                                else if (source === "manualAssetsAdded") {
                                    var addedAccountKey = this.props.modifiedManualAccountKey;
                                    if (item.key === addedAccountKey) {
                                        item.isChecked = true;
                                        options.push({ ...item });
                                    }
                                }
                            }

                        }
                    }
                    var isAllSubAccountsChecked = this.checkAllSubAccountSelected(account.accounts);
                    account.allAccount = { id: value.groupid, value: allAccountText, isChecked: isAllSubAccountsChecked };
                    accordionList.push(account);
                }

            }
            if (source === "hideExternalAccounts") {
                if (this.state.newAccounts && this.state.newAccounts.externalAccounts) {
                    for (const [, value] of this.state.newAccounts.externalAccounts.entries()) {
                        for (const [, item] of value.groupby.entries()) {
                            var indexOfAccount = -1;
                            options.findIndex(function (obj, i) {
                                if (obj.key === item.key) {
                                    indexOfAccount = i;
                                }
                            })
                            if (indexOfAccount >= 0) {
                                options.splice(indexOfAccount, 1);
                            }
                        }
                    }
                }
            } else if (source === "delete") {
                for (const [, deletedAccount] of modifiedAccounts.entries()) {
                    var indexOfAccount = -1;
                    options.findIndex(function (obj, i) {
                        if (obj.key === deletedAccount.key) {
                            indexOfAccount = i;
                        }
                    })
                    options.splice(indexOfAccount, 1);
                }
            } else if (source === "manualAssetDeleted" && this.props.modifiedManualAccountKey !== undefined && this.props.modifiedManualAccountKey !== null && this.props.modifiedManualAccountKey !== "") {
                var deletedAccount = this.props.modifiedManualAccountKey;
                var indexOfAccount = -1;
                options.findIndex(function (obj, i) {
                    if (obj.key === deletedAccount) {
                        indexOfAccount = i;
                    }
                })
                options.splice(indexOfAccount, 1);
            }
            if (this.state.groupToggleValue) {
                this.state.accounts = accordionList;
            }
            if (source === "updateNickname" || source === "nicknameUpdatedFromGroupOrManualAsset") {
                this.state.options = newOptions;
            } else {
                this.state.options = options;
            }
            this.setState({
                initialSelected: accordionList,
                instGroupedAccounts: accordionList,
                initailInstGroupedAccounts: accordionList,
                allAccount: allAccount
            }, () => {
                    this.createGroupedAccountList(source);
            });
        });
         }
    toggleAccordion(groupid, e) {
        var accounts = this.state.accounts;
        for (const [, value] of accounts.entries()) {
            if (value.groupid === groupid) {
                value.isOpen = !value.isOpen;
            } else {
                if (value.isOpen) {
                    value.isOpen = false;
                }
            }
        }
        this.setState({ accounts: accounts });
    }
    isOnlyOneGroupSelected() {
        var selectedGroupName = "";
        if (this.state.allAccount.isChecked) {
            return "";
        }
        var groupedAccounts = [];
        groupedAccounts = JSON.parse(JSON.stringify(this.state.accounts));
        if (groupedAccounts !== undefined && groupedAccounts !== null && groupedAccounts.length !== 0) {
            var groupCounter = 0;
            var halfSelected = 0;

            for (const [, group] of groupedAccounts.entries()) { 
                if (group.accounts !== undefined && group.accounts !== null && group.accounts.length !== 0) {
                    var groupSelected = true;
                    var counter = 0;
                    for (const [, account] of group.accounts.entries()) {
                        if (!account.isChecked) {
                            groupSelected = false;
                        } else {
                            counter = counter + 1;
                        }
                    }
                    if (counter !== 0 && !groupSelected) {
                        halfSelected = halfSelected + 1;
                        return "";
                    } else if (groupSelected) {
                        groupCounter = groupCounter + 1;
                        selectedGroupName = group.groupname;
                        if (groupCounter > 1) {
                            return "";
                        }
                    }
                }
            }
            if (groupCounter === 1 && halfSelected === 0) {
                return selectedGroupName;
            } else
                return "";
        }
    }
    setDropdownValue() {
        var dropDownValue = this.state.dropDownValue;
        var isAllAccountSelected = this.checkAllAccountSelected();
        let allAccount = this.state.allAccount;

        if (isAllAccountSelected) {
            dropDownValue = "All Accounts";
            allAccount.isChecked = true;
        } else if (!isAllAccountSelected && (this.state.options.length <= 3) && (this.state.options.length !== 0)) {
            for (const [index, value] of this.state.options.entries()) {
                if (index == 0) {
                    dropDownValue = value.nickname ? value.nickname : value.number;
                } else {
                    dropDownValue = dropDownValue + ", " + (value.nickname ? value.nickname : value.number);
                }
            }
            allAccount.isChecked = false;
        }
        if (!isAllAccountSelected && this.state.options.length > 3 || dropDownValue.length > 40) {
            dropDownValue = this.state.options.length + " Selected";
            allAccount.isChecked = false;
        } else if (this.state.options.length == 0) {
            dropDownValue = "Please select an account";
            allAccount.isChecked = false;
        }
        let selectedGroupName = this.isOnlyOneGroupSelected();   
        this.setState({ allAccount: allAccount, selectedGroupName: selectedGroupName, dropDownValue: dropDownValue}, () => { console.log("done"); });
    }
    componentDidUpdate(prevProps) {
        //Handling the account context changed from account overview, showing the account clicked from account overview as selected.
        if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) || (prevProps.aggregatedAcctModifiedList.isExternalAccountAdded !== this.props.aggregatedAcctModifiedList.isExternalAccountAdded)
            || (this.props.updateNickNameFlag !== prevProps.updateNickNameFlag) || (this.props.profileResp !== prevProps.profileResp)) {
            this.state.showExternalAccounts = this.props.showExternalAccounts;

            var source = "";
            if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) && !this.props.showExternalAccounts) {
                source = 'hideExternalAccounts';
            } else if ((prevProps.showExternalAccounts !== this.props.showExternalAccounts) && this.props.showExternalAccounts) {
                source = 'showExternalAccounts';
            } else if (this.props.updateNickNameFlag !== prevProps.updateNickNameFlag) {
                source = 'updateNickname';
            } else if (this.props.aggregatedAcctModifiedList !== prevProps.aggregatedAcctModifiedList){
                source = this.props.aggregatedAcctModifiedList.modifiedType;
            }else if (this.props.profileResp !== prevProps.profileResp) {
                if (this.props.acctSelectorChangeReason === "ADD_GROUP" || this.props.acctSelectorChangeReason === "UPDATE_GROUP_NAME"
                    || this.props.acctSelectorChangeReason === "ADD_ACCOUNTS_IN_GROUP" || this.props.acctSelectorChangeReason === "DELETE_GROUP"
                    || this.props.acctSelectorChangeReason === "DELETE_ACCOUNT_IN_GROUP" || this.props.acctSelectorChangeReason === "GROUP_SORT") {
                    source = 'groupUpdated';
                } else if (this.props.acctSelectorChangeReason === "ADD_ACCOUNTS_IN_MANUAL_ASSET") {
                    source = 'manualAssetsAdded';
                } else if (this.props.acctSelectorChangeReason === "DELETE_ACCOUNT_IN_MANUAL_ASSET") {
                    source = 'manualAssetDeleted';
                } else if (this.props.acctSelectorChangeReason === "NICKNAME_UPDATE" || this.props.acctSelectorChangeReason === "EDIT_ACCOUNTS_IN_MANUAL_ASSET") {
                    source = 'nicknameUpdatedFromGroupOrManualAsset';
                } else if (this.props.acctSelectorChangeReason === "UPDATE_DEFAULT_GROUP") {
                    source = 'defaultgroupUpdated';
                }

            }
            this.createAccountList(source);
            this.handleSelections();
        } else if (this.props.isAccountContextChanged) {
            var allAccount = this.state.allAccount;
            allAccount.isChecked = false;

            var instGroupedAccounts = this.state.instGroupedAccounts;
            for (const [, value] of instGroupedAccounts.entries()) {
                if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                    for (const [, account] of value.accounts.entries()) {
                        if (account.key === this.props.selectedaccount[0].key) {
                            account.isChecked = true;
                        } else {
                            account.isChecked = false;
                        }
                    }
                }
            }
            var groupedAccounts = this.state.groupedAccounts;
            for (const [, value] of groupedAccounts.entries()) {
                if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                    for (const [, account] of value.accounts.entries()) {
                        if (account.key === this.props.selectedaccount[0].key) {
                            account.isChecked = true;
                        } else {
                            account.isChecked = false;
                        }
                    }
                }
            }
            if (!this.state.groupToggleValue) {
                this.state.accounts = groupedAccounts;
                this.setState({ allAccount: allAccount, groupedAccounts: groupedAccounts, instGroupedAccounts: instGroupedAccounts });
            } else {
                this.state.accounts = instGroupedAccounts;
                this.setState({ allAccount:allAccount, groupedAccounts: groupedAccounts, instGroupedAccounts: instGroupedAccounts });
            }

            this.handleSelections();
            var dropdownValue = this.state.options[0].nickname ? this.state.options[0].nickname : this.state.options[0].number;
            var selectedGroupName = '';
            this.setState({ dropDownValue: dropdownValue, selectedGroupName: selectedGroupName });
            this.props.UpdateAccount(this.props.selectedaccount, this.props.render, false);
            this.props.UpdateTopten();
            this.props.UpdateDocuments();
        }
       
    }
    setContext = (source='other') => {
        setContextInCache(this.state.options, this.props.token, this.props.contextjson, this.props.profilejson)
            .then(res => {
                this.props.UpdateAccount(this.state.options, this.props.render);
                this.props.UpdateTopten();
                this.props.UpdateDocuments();
                /*if (source === 'apply') {
                    this.setState(prevState => ({
                        dropdownOpen: !prevState.dropdownOpen,
                    }));
                }*/
                
            })
            .catch(err => {
                console.log(err);
                this.cancel();
            })
    }
    toggle() {
        if (this.state.dropdownOpen) {
            var initialSelected = JSON.parse(JSON.stringify(this.state.initialSelected));
            var initialAllAccountSelected = JSON.parse(JSON.stringify(this.state.initialAllAccountSelected));
            var initialDropDownValue = this.state.initialDropDownValue;
            var initialSelectedGroupName = this.state.initialSelectedGroupName;
            var initialOptions = JSON.parse(JSON.stringify(this.state.initialOptions));

            var initailInstGroupedAccounts = JSON.parse(JSON.stringify(this.state.initailInstGroupedAccounts));
            var initailGroupedAccounts = JSON.parse(JSON.stringify(this.state.initailGroupedAccounts));

            this.setState({
                allAccount: initialAllAccountSelected, accounts: initialSelected, dropDownValue: initialDropDownValue, options: initialOptions,
                instGroupedAccounts: initailInstGroupedAccounts, groupedAccounts: initailGroupedAccounts, selectedGroupName: initialSelectedGroupName
            });

            this.disableApply();
        } else {
            this.handleSelectionsforToggle();
        }

        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));

    }
    clearAll() {

        this.selectUnSelectAllOptions(false);
        this.setState({ dropDownValue: "Please select an account", selectedGroupName:"" });
        this.disableApply();
    }
    handleSelections() {
        var selectedAccounts = JSON.parse(JSON.stringify(this.state.accounts));
        var selectAll = JSON.parse(JSON.stringify(this.state.allAccount));

        this.setState({ initialAllAccountSelected: selectAll, initialSelected: selectedAccounts });
        const options = this.state.options;
        var accounts = this.state.accounts;
        if (this.state.allAccount.isChecked === true) {
            this.selectUnSelectAllOptions(true);
        } else {
            for (const [, value] of accounts.entries()) {
                if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                    for (const [, account] of value.accounts.entries()) {
                        var indexOfAccount = -1;
                        options.findIndex(function (item, i) {
                            if (item.key === account.key) {
                                indexOfAccount = i;
                            }
                        })
                        if (account.isChecked && !(indexOfAccount >= 0)) {
                            options.push({ ...account });

                        } else if (!(account.isChecked) && indexOfAccount >= 0) {
                            options.splice(indexOfAccount, 1);
                        }
                    }

                }
                value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
            }
        }
        var dropDownValue = this.state.dropDownValue;
        var selectedGroupName = this.state.selectedGroupName;
        var initialOptions = JSON.parse(JSON.stringify(options));

        this.setState({ accounts: accounts, options: options, initialDropDownValue: dropDownValue, initialSelectedGroupName: selectedGroupName },
            () => {
                this.disableApply();
            });
        this.state.initialOptions = initialOptions;   
    }
    handleSelectionsforToggle = () => {
        var accounts = [];
        if (this.state.groupToggleValue) {
            //firm view
            accounts = JSON.parse(JSON.stringify(this.state.instGroupedAccounts));
        } else {
            //group view
            accounts = JSON.parse(JSON.stringify(this.state.groupedAccounts));
        }

        const options = this.state.options;
        if (this.state.allAccount.isChecked === true) {
            this.selectUnSelectAllOptions(true);
        } else {
            for (const [, value] of accounts.entries()) {
                if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                    for (const [, account] of value.accounts.entries()) {
                        var indexOfAccount = -1;
                        options.findIndex(function (item, i) {
                            if (item.key === account.key) {
                                indexOfAccount = i;
                            }
                        })
                        if (indexOfAccount >= 0) {
                            account.isChecked = true;

                        } else {
                            account.isChecked = false;
                        }
                    }

                }
                value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
            }
        }
        var dropDownValue = this.state.dropDownValue;
        var selectedGroupName = this.state.selectedGroupName;
        var initialOptions = JSON.parse(JSON.stringify(options));
        var selectAll = JSON.parse(JSON.stringify(this.state.allAccount));

        this.setState({
            accounts: accounts, options: options, initialDropDownValue: dropDownValue, initialSelectedGroupName: selectedGroupName, initialOptions: initialOptions,
            initialAllAccountSelected: selectAll, initialSelected: accounts, selectedGroupName: selectedGroupName},
            () => {
                if (!this.state.groupToggleValue) {
                    let selectedGroupName = this.isOnlyOneGroupSelected();
                    this.setState({
                        initialSelectedGroupName: selectedGroupName, selectedGroupName: selectedGroupName
                    },
                        () => { this.disableApply(); })
                } else {
                    this.disableApply();
                }
                
            });
    }

    checkAllSubAccountSelected(accounts) {
        if (accounts && accounts !== undefined && accounts !== null) {
            for (const [, account] of accounts.entries()) {
                if (!account.isChecked) {
                    return false;
                }
            }
        }
        return true;
    }
    checkAllAccountSelected() {
        for (const [, value] of this.state.accounts.entries()) {
            if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                for (const [, account] of value.accounts.entries()) {
                    if (!account.isChecked) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    selectUnSelectAllSubAccounts(id, flag) {
        var accounts = this.state.accounts;
        var options = this.state.options;
        var selectedGroupAccountList = [];

        if (!this.state.groupToggleValue) {
            var selectedGroup = this.state.accounts.filter(group => group.groupid === id);
            selectedGroupAccountList = selectedGroup[0].accounts;
        }
        for (const [, value] of accounts.entries()) {
            if (value.groupid === id && value.accounts && value.accounts !== undefined && value.accounts !== null) {
                for (const [, account] of value.accounts.entries()) {
                    account.isChecked = flag;
                    var indexOfAccount = -1;
                    options.findIndex(function (item, i) {
                        if (item.key === account.key) {
                            indexOfAccount = i;
                        }
                    })
                    if (flag && indexOfAccount < 0) {
                        options.push({ ...account });
                    } else if (!(flag) && indexOfAccount >= 0) {
                        options.splice(indexOfAccount, 1);
                    }
                }
                value.allAccount.isChecked = flag;
            } else if (!this.state.groupToggleValue && selectedGroupAccountList !== null && selectedGroupAccountList.length !== 0 && value.groupid !== id && value.accounts && value.accounts !== undefined && value.accounts !== null) {
                for (const [, account] of value.accounts.entries()) {
                    var indexOfAccount = -1;
                    selectedGroupAccountList.findIndex(function (item, i) {
                        if (item.key === account.key) {
                            indexOfAccount = i;
                        }
                    })
                    if (indexOfAccount >= 0) {
                        account.isChecked = flag;
                    }
                }
                value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
            }
        }
        this.setState({ accounts: accounts, options: options });
    }

    selectUnSelectAllOptions(flag) {
        const options = this.state.options;
        var allAccount = this.state.allAccount;
        allAccount.isChecked = flag;
        var accounts = this.state.accounts;
        for (const [, value] of accounts.entries()) {
            value.allAccount.isChecked = flag;
            if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                for (const [, account] of value.accounts.entries()) {
                    account.isChecked = flag;
                    var indexOfAccount = -1;
                    options.findIndex(function (item, i) {
                        if (item.key === account.key) {
                            indexOfAccount = i;
                        }
                    })
                    if (flag && !(indexOfAccount >= 0)) {
                        options.push({ ...account });
                    }
                }
            }
        }
        if (!flag) {
            options.length = 0;

        }
        this.setState({ allAccount: allAccount, options: options, accounts: accounts });
    }

    handleSelectChange(param, e) {
        var accounts = this.state.accounts;
        var options = this.state.options;

        if (e.target.value === "All Accounts" && e.target.checked) {
            this.selectUnSelectAllOptions(true);
        } else if (e.target.value === "All Accounts" && !e.target.checked) {
            this.selectUnSelectAllOptions(false);
        } else if (e.target.value.includes("All sub Accounts") && e.target.checked) {
            this.selectUnSelectAllSubAccounts(param.id, true);
        } else if (e.target.value.includes("All sub Accounts") && !e.target.checked) {
            this.selectUnSelectAllSubAccounts(param.id, false);
        } else {
            for (const [, value] of accounts.entries()) {
                if (value.accounts && value.accounts !== undefined && value.accounts !== null) {

                    for (const [, account] of value.accounts.entries()) {
                        if (account.key === e.target.value) {
                            var indexOfOptions = -1;
                            options.findIndex(function (item, i) {
                                if (item.key === e.target.value) {
                                    indexOfOptions = i;
                                }
                            })
                            account.isChecked = e.target.checked;
                            if (e.target.checked && indexOfOptions < 0) {
                                options.push({ ...account });
                            } else if (!(e.target.checked) && indexOfOptions >= 0) {
                                options.splice(indexOfOptions, 1);
                            } if (!(e.target.checked)) {
                                value.allAccount.isChecked = false;
                            }
                        }
                    }
                }
                if (e.target.checked) {
                    value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
                }
            }
        }

        this.setState({ options: options, accounts: accounts },
            () => {
                this.setDropdownValue();
                this.disableApply();
            });  
    }

    apply() {
        // Make Asyc call to profile set context
        if (this.props.clientRole === "Prospect") {
            try {
                this.setState(prevState => ({
                    dropdownOpen: !prevState.dropdownOpen,
                }), () => {
                        this.props.UpdateAccount(this.state.options, this.props.render);
                        this.props.UpdateTopten();
                        this.props.UpdateDocuments();
                });
            }
            catch (err) {
                console.log(err);
                this.cancel();
            }
        }
        else {
            this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
            }));
            this.setContext('apply');
        }
    }

    cancel() {
        var initialSelected = JSON.parse(JSON.stringify(this.state.initialSelected));
        var initialAllAccountSelected = JSON.parse(JSON.stringify(this.state.initialAllAccountSelected));

        var initialDropDownValue = this.state.initialDropDownValue;
        var initialSelectedGroupName = this.state.initialSelectedGroupName;
        var initialOptions = JSON.parse(JSON.stringify(this.state.initialOptions));

        this.setState({ accounts: initialSelected, dropDownValue: initialDropDownValue, allAccount: initialAllAccountSelected, options: initialOptions, selectedGroupName: initialSelectedGroupName });
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
        this.disableApply();
    }

    disableApply() {
        this.state.options.sort(function (a, b) {
            var x = a.number.toLowerCase();
            var y = b.number.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        this.state.initialOptions.sort(function (a, b) {
            var x = a.number.toLowerCase();
            var y = b.number.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        if ((this.state.options.length === 0) || JSON.stringify(this.state.options) == JSON.stringify(this.state.initialOptions.sort())) {
            this.state.disableApplyFlag = "disabled";
        } else {
            this.state.disableApplyFlag = "";
        }
    }

    //updateSearchKeyword(e) {
    //this.state.searchKeyword = e.target.value;
    //this.filterList();
    //}
    //filterList() {
    //var updatedList = this.state.accounts;
    //var searchKeyword = this.state.searchKeyword;
    //updatedList = updatedList.filter(function (item) {
    //    return item.number.toLowerCase().search(
    //        searchKeyword.toLowerCase()) !== -1;
    //});
    //this.setState({ items: updatedList });
    //}

    getDisplayName(account) {
        var actName = (account.nickname !== undefined && account.nickname !== null && account.nickname.length > 0 && account.displaynickname[0].dispname !== undefined && account.displaynickname[0].dispname !== null && account.displaynickname[0].dispname.length > 0) ? account.displaynickname[0].dispname: (account.displaynickname[0].displongname === undefined || account.displaynickname[0].displongname === null || account.displaynickname[0].displongname === '')
            ? ''
            : account.displaynickname[0].displongname;
        return actName;
    }

    aacanellationToken = null;
    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.aacanellationToken = axios.CancelToken.source();
        let componentjson = [];
        componentjson.push({ "key": "AllAccountSelectorView", "value": this.state.groupToggleValue ? "firm" : "group" })
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: preferencejson,
        }
        if (this.props.clientRole !== "Prospect") {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.aacanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                });
        }
    }

    componentWillUnmount() {
        if (this.aacanellationToken !== null && this.aacanellationToken !== undefined) {
            this.aacanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }

    toggleGroupView() {
        var accounts = [];
        if (this.state.groupToggleValue) {
            accounts = JSON.parse(JSON.stringify(this.state.groupedAccounts));
            this.props.UpdateSelectorView('group');

        } else {
            accounts = JSON.parse(JSON.stringify(this.state.instGroupedAccounts));
            this.props.UpdateSelectorView('firm');
        }

        for (const [, value] of accounts.entries()) {
            if (value.accounts && value.accounts !== undefined && value.accounts !== null) {
                for (const [, account] of value.accounts.entries()) {
                    var indexOfAccount = -1;
                    this.state.options.findIndex(function (item, i) {
                        if (item.key === account.key) {
                            indexOfAccount = i;
                        }
                    })
                    if (indexOfAccount >= 0) {
                        account.isChecked = true;

                    } else {
                        account.isChecked = false;
                    }
                }
            }
            value.allAccount.isChecked = this.checkAllSubAccountSelected(value.accounts);
        }
        
        this.setState(prevState => ({
            groupToggleValue: !prevState.groupToggleValue, accounts: accounts
            //, initialSelected: initialSelected
        }),
            () => {
                this.updatePreferenceCallBack();
                var selectedGroupName = "";
                if (!this.state.groupToggleValue) {
                    selectedGroupName = this.isOnlyOneGroupSelected();
                }
                this.setState({ selectedGroupName: selectedGroupName });
            }
        );

    }
    render() {
        return (
            <AccountSelectorWithGroupView
                state={this.state}
                toggle={this.toggle}
                handleSelectChange={this.handleSelectChange}
                getDisplayName={this.getDisplayName}
                apply={this.apply}
                cancel={this.cancel}
                clientRole={this.props.clientRole}
                toggleAccordion={this.toggleAccordion}
                clearAll={this.clearAll}
                toggleGroupView={this.toggleGroupView} />

        );
    }
}

function mapStateToProps(state) {
    return {
        accounts: state.acct.accounts,
        groupedAccounts: state.acct.groupedAccounts,
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        isAccountContextChanged: state.acct.isAccountContextChanged,
        showExternalAccounts: (state.acct && state.acct.preferences) ? JSON.parse(state.acct.preferences.showexternalaccount) : false,
        accountList: state.acct.accountList,
        aggregatedAcctModifiedList: state.acct.aggregatedAcctModifiedList,
        updateNickNameFlag: state.acct.updateNickNameFlag,
        clientRole: state.acct.role,
        acctSelectorChangeReason: state.acct.acctSelectorChangeReason,
        modifiedManualAccountKey: state.acct.deletedManualAccountKey,
        profileResp: state.acct.profileResp,
        profileId: state.acct.profileId,
        acct: state.acct,
        simulationAccount: state.acct.simulationAccount
    };
};

const mapDispatchToProps = {
    UpdateAccount, UpdateTopten, UpdateSelectorView, UpdateDocuments
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountSelectorWithGroup);