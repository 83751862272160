import React, { memo } from "react";

const Group = ({ details, key, selectedGroup, onGroupSelection }) => {
  return (
    <>
      <div
        key={key}
        className={
          selectedGroup && selectedGroup.id === details.id
            ? "RCM_Teamwork_Grouplist_Item RCM_Teamwork_Grouplist_Item_Selected"
            : "RCM_Teamwork_Grouplist_Item"
        }
        onClick={() => onGroupSelection(details)}
      >
        {details.displayName}
      </div>
    </>
  );
};

export default memo(Group);
