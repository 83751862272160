import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function AviationConfirmationModal(props) {

    const headerAviationProtfolioText = 'Aviation Protfolio';
    const headerFlyExclusiveText = 'FlyExclusive';
    const contentFlyExclusiveText = 'By clicking on the "Continue" button below, you will be transferred to a third-party website which is owned, operated, and maintained by flyExclusive. If you have any questions, please contact your advisor.';
    const contentAviationProtfolioText = 'By clicking on the "Continue" button below, you will be transferred to a third-party website which is owned, operated, and maintained by Aviation Portfolio. If you have any questions, please contact your Advisor.';

    return (
        <div>
                <Modal isOpen={props.aviationModalState} toggle={props.aviationModalToggle} 
                     className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                     centered >
                <ModalHeader toggle={props.aviationModalToggle}>
                    {props.selectedSection && props.selectedSection === 'contactFlyExclusive' ? headerFlyExclusiveText : headerAviationProtfolioText}
                </ModalHeader>
                        <ModalBody>
                            <div>
                                {
                                    props.selectedSection && props.selectedSection === 'contactFlyExclusive' ? contentFlyExclusiveText : contentAviationProtfolioText
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="btn btn-secondary RCM_button_secondary RCM_select_btn" onClick={props.aviationModalToggle}>Close</Button>
                            <Button color="btn btn-primary RCM_button_primary RCM_select_btn" onClick={props.openSelectedSectionWindow}>Continue</Button>
                        </ModalFooter>
                    </Modal>
            </div>
    )
}
