import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { HoldingsbysecurityURL, spinner, GetAccountsList, MsgForNoData, EstimatedIncomeSummaryURL, addIndexToList, getAccountDisplayName, IndLongShort, CheckIfProspectClient} from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddEstimatedIncomeSummaryAccount, AddEstimatedIncomeSummarySecurity, AddEstimatedIncomeSummaryChart, UpdateEstimateIncomeSummaryToggleView, ChangeYearEstimatedIncome, RefreshEstimatedIncomeAccountSecurity } from '../../../../../actions/toptenactions.jsx';
import { GetSecuritySummaryChildColumns, accountSummaryChildColumns, securitySummaryChildColumns, renderRowsBySecuritySummaryChild, renderRowsByAccountSummaryChild } from '../estimatedincomecolumns.jsx';
//import TASHoldingdetails from '../realizedtasholding.jsx';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from "axios";
import { EstimatedIncomeSummaryView } from './estimatedincomesummaryview';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { estimatedincomesummarychart, RRR_123456_estimatedincomesummarychart, RRR_234567_estimatedincomesummarychart, estimatedincomesummarysecurity, RRR_123456_estimatedincomesummarysecurity, estimatedincomesummaryaccount, RRR_123456_estimatedincomesummaryaccount, RRR_234567_estimatedincomesummaryaccount, RRR_234567_estimatedincomesummarysecurity } from './../../../accountsmockresponse'

class EstimatedIncomeSummary extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            
            chartData: this.props.estimatedIncomeSummaryChartData,
            securityViewData: this.props.estimatedIncomeSummarySecurityData,
            accountViewData: this.props.estimatedIncomeSummaryAccountData,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            requestFailed: false,
            isloadingSecuritySummary: true,
            requestFailedSecuritySummary: false,
            isloadingAccountSummary: true,
            requestFailedAccountSummary: false,
            render: this.props.render,
            expanded: [],
            //dropdownValue: '',
            //dropdownDispValue: '',
            //accountOverviewFlag: this.props.accountOverviewFlag,
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
           // monthDropdownValue: 'All',
            dropdownyearValue: this.props.estimatedIncomeYear ? this.props.estimatedIncomeYear: (new Date().getMonth() > 0) ? new Date().getFullYear() : new Date().getFullYear() - 1,
            showTable: false,
            tooltipOpen1: false,
            viewToggleValue: true,
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        //this.collapserow = this.collapserow.bind(this);
        //this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        //this.toggle = this.toggle.bind(this);
        this.changeYear = this.changeYear.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
        //this.toggle1 = this.toggle1.bind(this);
        this.toggleShowTable = this.toggleShowTable.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.fetchTableSecurityViewData = this.fetchTableSecurityViewData.bind(this);
        this.fetchTableAccountViewData = this.fetchTableAccountViewData.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
    }
    getEstimatedIncomeSummaryChartCancellationToken = null;
    getEstimatedIncomeSummarySecurityCancellationToken = null;
    getEstimatedIncomeSummaryAccountCancellationToken = null;

    //method to execute api calls
    execute = (indUpdateToggleView) => {

        this.setState(() => ({ isloading: true, requestFailed: false, chartData: [] }), () => {
            if (this.getEstimatedIncomeSummaryChartCancellationToken) {
                //Cancel the existing call
                this.getEstimatedIncomeSummaryChartCancellationToken.cancel('Cancelling the existing estimated income request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getEstimatedIncomeSummaryChartCancellationToken = cancelToken.source();
            let selectorparam = 'C';
            const baseUrlSummary = EstimatedIncomeSummaryURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
            Get(baseUrlSummary, this.props.token, this.props.contextjson, this.props.profilejson, this.getEstimatedIncomeSummaryChartCancellationToken)
                .then(result => {
                    const data = result && result.data && result.data != "" ? result.data : null;
                    this.setState(() => ({ chartData: data, isloading: false }), () => { 
                            this.props.AddEstimatedIncomeSummaryChart(data); 
                        if(indUpdateToggleView) 
                            this.props.UpdateEstimateIncomeSummaryToggleView('C');
                        this.props.ChangeYearEstimatedIncome(this.state.dropdownyearValue);
                    });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                    }
                })
        });
    };

    fetchTableSecurityViewData = (indUpdateToggleView) => {

        this.setState(() => ({ isloadingSecuritySummary: true, requestFailedSecuritySummary: false, securityViewData: [] }), () => {
            if (this.getEstimatedIncomeSummarySecurityCancellationToken) {
                //Cancel the existing call
                this.getEstimatedIncomeSummarySecurityCancellationToken.cancel('Cancelling the existing estimated income request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getEstimatedIncomeSummarySecurityCancellationToken = cancelToken.source();
            let selectorparam = 'S';
            const baseUrlSummary = EstimatedIncomeSummaryURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
            Get(baseUrlSummary, this.props.token, this.props.contextjson, this.props.profilejson, this.getEstimatedIncomeSummarySecurityCancellationToken)
                .then(result => {
                    const data = result && result.data && result.data != "" ? result.data : null;
                    this.setState(() => ({ securityViewData: data, isloadingSecuritySummary: false }), () => { 
                        this.props.AddEstimatedIncomeSummarySecurity(data); 
                        if(indUpdateToggleView)  
                            this.props.UpdateEstimateIncomeSummaryToggleView('S');
                        this.props.ChangeYearEstimatedIncome(this.state.dropdownyearValue);
                    });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailedSecuritySummary: true, error: error.message, isloadingSecuritySummary: false }));
                    }
                })
        });
    }

    fetchTableAccountViewData = (indUpdateToggleView) => {

        this.setState(() => ({ isloadingAccountSummary: true, requestFailedAccountSummary: false, accountViewData: [] }), () => {
            if (this.getEstimatedIncomeSummaryAccountCancellationToken) {
                //Cancel the existing call
                this.getEstimatedIncomeSummaryAccountCancellationToken.cancel('Cancelling the existing estimated income request to prevent memory leaks.');
            }
            //Create a new cancellation token
            const cancelToken = axios.CancelToken;
            this.getEstimatedIncomeSummaryAccountCancellationToken = cancelToken.source();
            let selectorparam = 'A';
            const baseUrlSummary = EstimatedIncomeSummaryURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
            Get(baseUrlSummary, this.props.token, this.props.contextjson, this.props.profilejson, this.getEstimatedIncomeSummaryAccountCancellationToken)
                .then(result => {
                    const data = result && result.data && result.data != "" ? result.data : null;
                    this.setState(() => ({ accountViewData: data, isloadingAccountSummary: false }), () => { 
                        this.props.AddEstimatedIncomeSummaryAccount(data); 
                        if(indUpdateToggleView) 
                            this.props.UpdateEstimateIncomeSummaryToggleView('A');
                        this.props.ChangeYearEstimatedIncome(this.state.dropdownyearValue);
                    });
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    }
                    else {
                        this.setState(() => ({ requestFailedAccountSummary: true, error: error.message, isloadingAccountSummary: false }));
                    }
                })
        });
    }

    componentDidMount() {
        //if props has data then take it or make api call
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.estimatedIncomeSummaryChartData === null)
                this.execute(true);
            else {
                this.setState({ isloading: false, requestFailed: false });
                this.props.UpdateEstimateIncomeSummaryToggleView('C');
            }

            if (this.props.estimatedIncomeSummarySecurityData === null)
                this.fetchTableSecurityViewData(false);
            else this.setState({ isloadingSecuritySummary: false, requestFailedSecuritySummary: false });

            if (this.props.estimatedIncomeSummaryAccountData === null)
                this.fetchTableAccountViewData(false);
            else this.setState({ isloadingAccountSummary: false, requestFailedAccountSummary: false });
        }
       
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.refreshScreens();
            }
        }
    }

    refreshScreens() {
        if (!this.state.showTable) {
            this.execute(true);
            this.fetchTableSecurityViewData(false);
            this.fetchTableAccountViewData(false);
        } else {
            if (this.state.viewToggleValue) {
                //Call security api
                this.fetchTableSecurityViewData(true);
                this.fetchTableAccountViewData(false);
                this.execute(false);
            } else {
                //call account api
                this.fetchTableAccountViewData(true);
                this.fetchTableSecurityViewData(false);
                this.execute(false);
            }
        }
    }

    componentWillUnmount() {
        if (this.getEstimatedIncomeSummaryChartCancellationToken !== null && this.getEstimatedIncomeSummaryChartCancellationToken !== undefined) {
            this.getEstimatedIncomeSummaryChartCancellationToken.cancel('Cancelling the Estimated Income Summary Chart during unmount to prevent memory leak.');
        }
        if (this.getEstimatedIncomeSummarySecurityCancellationToken !== null && this.getEstimatedIncomeSummarySecurityCancellationToken !== undefined) {
            this.getEstimatedIncomeSummarySecurityCancellationToken.cancel('Cancelling the Estimated Income Summary By Security during unmount to prevent memory leak.');
        }
        if (this.getEstimatedIncomeSummaryAccountCancellationToken !== null && this.getEstimatedIncomeSummaryAccountCancellationToken !== undefined) {
            this.getEstimatedIncomeSummaryAccountCancellationToken.cancel('Cancelling the Estimated Income Summary By Account during unmount to prevent memory leak.');
        }

    }

    

    //collapserow(row) {
    //    this.setState(() => ({
    //        expanded: []
    //    }));
    //}

    //toggle1() {
    //    this.setState({ tooltipOpen1: !this.state.tooltipOpen1 });
    //}

    toggleShowTable() {
        this.setState(prevState => ({ showTable: !prevState.showTable,expanded:[] }), () => {
            if (this.state.showTable) {
                if (this.props.estimatedIncomeSummarySecurityData == null) {
                    this.fetchTableSecurityViewData(true);
                }
                else {
                    this.props.UpdateEstimateIncomeSummaryToggleView('S');
                    this.setState(() => ({ isloadingSecuritySummary: false, requestFailedSecuritySummary: false }));
                }
               
            } else {
                if (this.props.estimatedIncomeSummaryChartData == null) {
                    this.execute(true);
                }
                else {
                    this.props.UpdateEstimateIncomeSummaryToggleView('C');
                    this.setState(() => ({ isloading: false, requestFailed: false }));
                }
            }
        });
    }

    //toggle(row) {
    //    this.setState(prevState => ({
    //        modal: !prevState.modal,

    //    }));
    //    this.state.rowdata = row;
    //}
    //toggleDropdown() {
    //    this.setState(prevState => ({
    //        dropdownOpen: !prevState.dropdownOpen
    //    }));
    //}

     handleOnExpand = (row, isExpand, rowIndex, e) => {
         if (isExpand) {
             this.setState(() => ({
                 isrowexpanded: true,
                 expanded: [...this.state.expanded, row.UniqueIdx]
             }));
         } else {
             this.setState(() => ({
                 expanded: this.state.expanded.filter(x => x !== row.UniqueIdx),
                 isrowexpanded: this.state.expanded.length > 1 ? true : false
             }));
         }
     }

    changeYear(selectedyear) {
        if (this.state.dropdownyearValue !== selectedyear.label)
            this.setState({ dropdownyearValue: selectedyear.label },
                () => this.refreshScreens(),
                this.props.RefreshEstimatedIncomeAccountSecurity());
    }


    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }

    toggleView() {
        this.setState({ viewToggleValue: !this.state.viewToggleValue, expanded: [] }, () => {
            if (this.state.viewToggleValue) {
                //Call security api
                if (this.props.estimatedIncomeSummarySecurityData == null) {
                    this.fetchTableSecurityViewData(true);
                }
                else {
                    this.props.UpdateEstimateIncomeSummaryToggleView('S');
                    this.setState(() => ({ isloadingSecuritySummary: false, requestFailedSecuritySummary: false }));
                }

            } else {
                //call account api
                if (this.props.estimatedIncomeSummaryAccountData == null) {
                    this.fetchTableAccountViewData(true);
                }
                else {
                    this.props.UpdateEstimateIncomeSummaryToggleView('A');
                    this.setState(() => ({ isloadingAccountSummary: false, requestFailedAccountSummary: false }));
                }

            }
        });
    }

    getChartInput(data) {
        let chartrows = [];
        if (data !== null && Array.isArray(data) && data.length > 0 && data[0] && Array.isArray(data[0].incomeSubTotal) && data[0].incomeSubTotal.length > 0) {
            let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            chartrows = cloneDeep(data[0].incomeSubTotal);
            chartrows = orderBy(chartrows, ['monseq'], ['asc']);
            for (var i = 0; i < chartrows.length; i++) {
                const month = chartrows[i].monseq;
                chartrows[i].month = ((textfieldarr[month-1]) + (this.state.dropdownyearValue ? (' '+ this.state.dropdownyearValue.toString()): ''));
            }
        }

        return chartrows;
    }

    getChartTotal(data) {

        let response = {};
        if (data !== null && Array.isArray(data) && data.length > 0 && data[0] && Array.isArray(data[0].incomeSubTotal) && data[0].incomeSubTotal.length > 0) {
            response = data[0];
        }

        return response;
    }

    getSecurityInput(data) {
        let securityrows = [];
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            securityrows = data.value[0].IncomeSubTotal ? addIndexToList(data.value[0].IncomeSubTotal) : [];
           
        }
        return securityrows;
    }

    getSecurityInputTotal(data) {

        let resp = {};
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            let localData = JSON.parse(JSON.stringify(data));
            delete localData.value[0].IncomeSubTotal;
            delete localData.value[0]['@odata.type'];

            resp = localData.value[0];
        }
        return resp;
    }

    getAccountInputTotal(data) {

        let resp = {};
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            let localData = JSON.parse(JSON.stringify(data));
            delete localData.value[0].IncomeSubTotal;
            delete localData.value[0]['@odata.type'];

            resp = localData.value[0];
        }
        return resp;
    }

    getAccountViewInput(data) {
        let accountrows = [];
        if (data && data.value && Array.isArray(data.value) && data.value[0]) {
            accountrows = data.value[0].IncomeSubTotal ? addIndexToList(data.value[0].IncomeSubTotal) : [];
            for (var i = 0; i < accountrows.length; i++) {
                accountrows[i].ACCOUNTNICKNAME = getAccountDisplayName(this.props.selectedaccount, accountrows[i].ACCT, IndLongShort.SINGLEVIEW);

            }
        }
        return accountrows;
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    SetDummayResponseForProspectClient() {
        if (this.props.selectedaccount.length == 1) {
            if (this.props.selectedaccount[0].key === "RRR-123456") {
                this.setState(() => ({
                    chartData: RRR_123456_estimatedincomesummarychart,
                    accountViewData: RRR_123456_estimatedincomesummaryaccount,
                    securityViewData: RRR_123456_estimatedincomesummarysecurity, isloading: false
                }), () => {
                    this.props.AddEstimatedIncomeSummaryChart(RRR_123456_estimatedincomesummarychart);
                    this.props.AddEstimatedIncomeSummaryAccount(RRR_123456_estimatedincomesummaryaccount);
                    this.props.AddEstimatedIncomeSummarySecurity(RRR_123456_estimatedincomesummarysecurity);
                });
            }
            else if (this.props.selectedaccount[0].key === "RRR-234567") {
                this.setState(() => ({
                    chartData: RRR_234567_estimatedincomesummarychart,
                    accountViewData: RRR_234567_estimatedincomesummaryaccount,
                    securityViewData: RRR_234567_estimatedincomesummarysecurity, isloading: false
                }), () => {
                    this.props.AddEstimatedIncomeSummaryChart(RRR_234567_estimatedincomesummarychart);
                    this.props.AddEstimatedIncomeSummaryAccount(RRR_234567_estimatedincomesummaryaccount);
                    this.props.AddEstimatedIncomeSummarySecurity(RRR_234567_estimatedincomesummarysecurity);
                });
            }
        }
        else if (this.props.selectedaccount.length == 2) {

            this.setState(() => ({
                chartData: estimatedincomesummarychart,
                accountViewData: estimatedincomesummaryaccount,
                securityViewData: estimatedincomesummarysecurity, isloading: false
            }), () => {
                this.props.AddEstimatedIncomeSummaryChart(estimatedincomesummarychart);
                this.props.AddEstimatedIncomeSummaryAccount(estimatedincomesummaryaccount);
                this.props.AddEstimatedIncomeSummarySecurity(estimatedincomesummarysecurity);
            });
        }
    }
   

    //render method
    render() {

        const expandRowBySecurity = {
            renderer: (row) => (
                <BootstrapTable
                    key="btsecchild1"
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={this.state.viewToggleValue ? renderRowsBySecuritySummaryChild(row.IncomeDetail ? row.IncomeDetail : [], this.props.selectedaccount, this.state.childColumnToSort, this.state.childColumnSortOrder) : renderRowsByAccountSummaryChild(row.IncomeDetail ? row.IncomeDetail : [], this.props.selectedaccount, this.state.childColumnToSort, this.state.childColumnSortOrder)}
                    columns={this.state.viewToggleValue ?  securitySummaryChildColumns(this.props.selectedaccount)  : accountSummaryChildColumns}
                    noDataIndication={MsgForNoData}
                    bordered={false}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.UniqueIdx);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };

        let chartrows = this.getChartInput(this.state.chartData);
        let chartTotal = this.getChartTotal(this.state.chartData);
        let securityrows = this.getSecurityInput(this.state.securityViewData);
        let securityrowsTotal = this.getSecurityInputTotal(this.state.securityViewData);
        let accountrows = this.getAccountViewInput(this.state.accountViewData);
        let acountrowsTotal = this.getAccountInputTotal(this.state.accountViewData);


        return (

            <ErrorBoundary>
                <EstimatedIncomeSummaryView
                    state={this.state}
                    showTable={this.state.showTable}
                    securityrows={securityrows}
                    securityrowsTotal={securityrowsTotal}
                    acountrowsTotal={acountrowsTotal}
                    accountrows={accountrows}
                    chartrows={chartrows}
                    chartTotal={chartTotal}
                    toggle={this.toggleDropdown}
                    changeAccount={this.changeAccount}
                    accounts={this.props.selectedaccount}
                    selectedAccount={this.props.selectedaccount}
                    expandRowBySecurity={expandRowBySecurity}
                    changeYear={this.changeYear}
                    toggleYear={this.toggleYear}
                    toggleShowTable={this.toggleShowTable}
                    toggle1={this.toggle1}
                    toggleView={this.toggleView}
                    rowsortevent={this.rowsortevent}
                />

            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        estimatedIncomeYear: state.topten.estimatedIncomeYear,
        estimatedIncomeSummaryChartData: state.topten.estimatedIncomeSummaryChartData,
        estimatedIncomeSummarySecurityData: state.topten.estimatedIncomeSummarySecurityData,
        estimatedIncomeSummaryAccountData: state.topten.estimatedIncomeSummaryAccountData,
        estimatedIncomeSummarytoggleview: state.topten.estimatedIncomeSummarytoggleview,
        role:state.acct.role
    };
};

const mapDispatchToProps = {
    AddEstimatedIncomeSummaryChart,
    AddEstimatedIncomeSummarySecurity,
    AddEstimatedIncomeSummaryAccount,
    UpdateEstimateIncomeSummaryToggleView,
    ChangeYearEstimatedIncome,
    RefreshEstimatedIncomeAccountSecurity
};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Estimated Income Summary View' })
)
export default securityholdingdetailscomp(EstimatedIncomeSummary);