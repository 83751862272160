import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as Highcharts from "highcharts";
import { formatMoney } from "../constants";

const HistoricalAssetAllocationBarHighChart = ({
  data,
  colors,
  categories,
}) => {
  console.log(data)
  const chartContainer = useRef();
  const seriesData = useMemo(() => {
    if (
      data &&
      Array.isArray(data) &&
      categories &&
      Array.isArray(categories)
    ) {
      return categories.map((x, i) => ({
        name: x,
        data: data.map((y) => ({y: y[x] || 0, barTotal: y.TOTAL })),
        color: colors[i],
      }));
    } else {
      return [];
    }
  }, [categories, colors, data]);

  const xAxisCategories = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x) => x.date);
    } else {
      return [];
    }
  }, [data]);

  const setColor = useCallback((index, colors) => {
    if (colors && colors.length > 0 && colors[index]) {
      return (
        "linear-gradient(to bottom, transparent 0%,transparent 10%," +
        colors[index] +
        " 11%, " +
        colors[index] +
        " 90%, transparent 91%, transparent 100%)"
      );
    } else {
      return "linear-gradient(to bottom, transparent 0%,transparent 10%,#817F76 11%,#817F76 90%, transparent 91%, transparent 100%)";
    }
  }, []);

  useEffect(() => {
    Highcharts.chart(chartContainer.current, {
      chart: {
        type: "column",
        backgroundColor: "transparent",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        categories: xAxisCategories,
        title: {
          text: "",
        },
        labels: {
            formatter: function() {
                return Highcharts.dateFormat('%b %Y', this.value)
            }
        },
      },
      yAxis: {   
        title: {
          text: "",
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return `<span style="color: #666; font-size: 0.75rem; font-weight: normal;">${formatMoney(
              this.total
            )}</span>`;
          },
          useHTML: true,
        },
        gridLineWidth: 0,
        plotLines: [{
            color: '#666',
            width: 1,
            value: 0,
            dashStyle: 'Dash'
        }]
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return `<b>${Highcharts.dateFormat('%b %y', this.x)}</b><br/>${this.series.name} <b>${formatMoney(
            this.point.y
          )}</b> <br/> Total: <b>${formatMoney(this.point.barTotal)}</b>`;
        },
        backgroundColor: '#000',
        style: {
          color: '#fff',
          fontFamily: 'Avenir'
        },
        outside: true
      },
      plotOptions: {
        column: {
            stacking: 'normal',
            borderWidth: 0
        }
      },
      series: seriesData,
    });
  }, [seriesData, xAxisCategories]);

  return (
    <div className="row">
      <div className="col-sm-10">
        <div ref={chartContainer}></div>
      </div>
      <div className="col-sm-2 RCM_legent_Wrapper">
        <ul className="RCM_legent_height RCM_CM_scroll">
          {categories.map((asset, index) => {
            return (
              <li
                key={index}
                style={{
                  borderImageSource: setColor(index, colors),
                }}
                className={"RCM_aachartWrapper RCM_aachart"}
              >
                <div className="RCM_assetName">
                  {" "}
                  <span>{asset}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default HistoricalAssetAllocationBarHighChart;
