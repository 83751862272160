import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import PdfViewer from './../pdfviewer'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DatePicker from 'react-datepicker'
import { spinner, MsgDocumentLoading, MsgForNoData, MsgForFailedData, MsgSelectDocument, MsgSelectRockoActs } from './../../../common/constants.jsx';
import { tradeconfirmcolumns, tradeconfirmsummarycolumns } from './tradeconfirmcolumns.jsx';
import Messages from './../../../common/messages.jsx';
import Watermark from './../../../common/watermark';
export function TradeConfirmView(
    props
) {
    const selectRow = {
        mode: 'radio',
        selected: props && props.state && props.state.selected,
        onSelect: props && props.state && props.selectDocument,
        bgColor: 'var(--color-light-blue)',
        clickToSelect: true
    };


    let tradeconfirmview = (
        <div className="container-fluid">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row RCM_Document_Message_Margin">
                <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                    <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                </div>
            </div>
            <div className="row RCM_Document_Fields">
                <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3 RCM_Documents_TradeConfirm_Search_Div" id="RCM_TradeConfirm_Search">
                    <label className="RCM_Accounts_lbl">SEARCH BY</label>
                    <div className="RCM_Inline_Div">
                        <Dropdown isOpen={props && props.state && props.state.searchDropdownOpen} toggle={props.searchDropdownToggle} className="RCM_select_box RCM_mm_subselect_box RCM_Document_Dropdown">
                            <DropdownToggle caret disabled={props.state.isProspectClient}>
                                {
                                    props && props.state && props.state.searchDropdownValue
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {props && props.searchDropDownItems && props.searchDropDownItems.map((item, index) =>
                                    <DropdownItem key={index} onClick={props.handleTradeConfrimOrSummary} disabled={props.state.isProspectClient}>
                                        <div >{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 col-md-3 col-lg-3 RCM_TradeConfirm_Date_Div" id="RCM_TradeConfirm_Date">
                    <label className="RCM_Date_lbl">DATE</label>
                    <div className="RCM_Document_Date_Inline_Div" >
                        <Dropdown isOpen={props && props.state && props.state.dateRangeDropdownOpen} toggle={props.dateRangeDropdownToggle} className="RCM_select_box RCM_mm_subselect_box RCM_Document_Dropdown">
                            <DropdownToggle caret disabled={props.state.isProspectClient}>
                                {
                                    props && props.state && props.state.dateRangeDropdownValue
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {props && props.dateRangeDropDownItems && props.dateRangeDropDownItems.map((item, index) =>
                                    <DropdownItem key={index} onClick={props.changeDateRange} disabled={props.state.isProspectClient}>
                                        <div >{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                {
                    props && props.state && props.state.isCustomDateRangeSelected
                        ?
                        <div className="row RCM_Trade_Date_Div" >
                            <div className=" col-sm-12 col-xs-12 col-md-5 col-lg-5" id="RCM_TradeConfirm_FromDate">
                                <label className="RCM_Accounts_lbl RCM_FromDate_Margin" >FROM DATE</label>
                                <DatePicker
                                    className="RCM_Document_Datepicker"
                                    selected={props && props.state && props.state.startDate}
                                    onChange={props.startDateChange}
                                    maxDate={new Date()}
                                    disabled={props.state.isProspectClient}
                                ></DatePicker>
                                <label className="RCM_bottom-label RCM_Document_Bottom_lbl">(mm/dd/yyyy)</label>
                            </div>

                            <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5 RCM_ToDate_Div" id="RCM_TradeConfirm_ToDate">
                                <label className="RCM_Accounts_lbl RCM_ToDate_Lbl">TO DATE</label>
                                <DatePicker
                                    className="RCM_Document_Datepicker"
                                    selected={props && props.state && props.state.endDate}
                                    onChange={props.endDateChange}
                                    maxDate={new Date()}
                                    disabled={props.state.isProspectClient}
                                />
                                <label className="RCM_bottom-label RCM_ToDate_Bottom_lbl">(mm/dd/yyyy)</label>
                            </div>
                            <div className={props.state.isProspectClient ? "col-sm-1 RCM_TradeConfirm_DateRange_Search_Btn_Margin RCM_Trade_Search_Div RCM_Prospect_Disable_Button" : "col-sm-1 RCM_TradeConfirm_DateRange_Search_Btn_Margin RCM_Trade_Search_Div"} id="RCM_TradeConfirm_Search_date">
                                <a className="RCM_Cursor_Pointer" onClick={props.SearchDocuments} title="Search" >
                                    <div className={"RCM_TradeConfirm_Search"}></div>
                                </a>
                            </div>
                        </div>
                        : <div ></div>
                }

            </div>
            <div className={
                props && props.state && props.state.filteredDocumentList && props.state.filteredDocumentList.length === 0
                    ? 'row RCM_Document_Details_Row'
                    : 'row'
            }>
                {
                    props && props.state && props.state.areDocumentsRetrieved
                        ?
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5" id="RCM_TradeConfirmview">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    props && props.state && props.state.dropdownValue !== 'All Accounts'
                                        ? !props.state.filteredDocumentList.find(doc => doc.primaryValue === props.state.dropdownValue)
                                            ? <p>{MsgForNoData}</p>
                                            :
                                            <div className={!props.state.isSearchByTradeConfirmSummary ? 'RCM_two_level_table1 RCM_TradeConfirm_Table RCM_CM_scroll' : ''}>
                                                <h6>{props && props.state && props.state.dropdownValue}</h6>

                                                <BootstrapTable
                                                    keyField="id"
                                                    data={props && props.state && props.state.filteredDocumentList.filter(doc => doc.primaryValue === props.state.dropdownValue)
                                                    }
                                                    columns={props && props.state && props.state.isSearchByTradeConfirmSummary ? tradeconfirmsummarycolumns(props && props.state, props.authprops, props.selectedaccounts, props.role) : tradeconfirmcolumns(props && props.state, props.authprops, props.selectedaccounts, props.role)}
                                                    bordered={false}
                                                    selectRow={selectRow}
                                                    classes="RCM_two_level_table1 RCM_TradeConfirm_Table"

                                                >
                                                </BootstrapTable>

                                            </div>
                                        :
                                        props && props.state && props.state.accounts.map((acct, index) =>
                                            (acct.number !== 'All Accounts' && props && props.state && props.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number).length > 0
                                                ? <div key={index}>
                                                    <h6>{acct.number}</h6>
                                                    <div className={!props.state.isSearchByTradeConfirmSummary ? 'RCM_two_level_table1 RCM_TradeConfirm_Table RCM_CM_scroll' : ''}>

                                                        <BootstrapTable
                                                            keyField="id"
                                                            data={props && props.state && props.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number)
                                                            }
                                                            columns={props && props.state && props.state.isSearchByTradeConfirmSummary ? tradeconfirmsummarycolumns(props && props.state, props.authprops, props.selectedaccounts, props.role) : tradeconfirmcolumns(props.state, props.authprops, props.selectedaccounts, props.role)}
                                                            bordered={false}
                                                            selectRow={selectRow}
                                                            classes="RCM_two_level_table1 RCM_TradeConfirm_Table"
                                                        >
                                                        </BootstrapTable>
                                                    </div>
                                                </div>
                                                : <div key={index}></div>
                                            )
                                        )
                                }
                            </div>
                        </div>
                        : <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5 " id="RCM_TradeConfirmview">
                            <div className="RCM_Toast RCM_Document_Toast">
                                {
                                    props && props.state && props.state.isNonRockActSelected
                                        ? <div>
                                            <p>{MsgSelectRockoActs}</p>
                                        </div>
                                        : props && props.state && props.state.isDocumentListResponseWithNoData
                                            ? <div>
                                                <p>{MsgForNoData}</p>
                                            </div>
                                            : <div>
                                                {
                                                    props && props.state && props.state.isDocumentListResponseWithError
                                                        ? <div>
                                                            <p> {MsgForFailedData}</p>
                                                        </div>
                                                        : <div className="RCM_spinner">{MsgDocumentLoading}{spinner()}</div>
                                                }
                                            </div>

                                }
                            </div>
                        </div>

                }
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7">
                    <div className="RCM_Toast RCM_Document_Toast" id="RCM_TradeConfirmpreview">
                        {
                            props && props.state && props.state.pdfData
                                ? <PdfViewer pdfData={props && props.state && props.state.pdfData} />
                                : props && props.state && props.state.isDocumentSelected
                                    ? props && props.state && props.state.isDocumentResponseWithNoData || props && props.state && props.state.isDocumentResponseWithError
                                        ? props && props.state && props.state.isDocumentResponseWithNoData
                                            ? <p>{MsgForNoData}</p>
                                            : <p> {MsgForFailedData} </p>
                                        : <div className="RCM_spinner">Loading Selected Document{spinner()}</div>
                                    : props && props.state && props.state.areDocumentsRetrieved
                                        ? props && props.state && props.state.dropdownValue === 'All Accounts' || props && props.state && props.state.filteredDocumentList.find(doc => doc.primaryValue === props.state.dropdownValue)
                                            ? <p>{MsgSelectDocument}</p>
                                            : <p></p>
                                        : <p></p>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
    return tradeconfirmview
}