import React from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import ErrorBoundary from '../../../common/errorboundary';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { spinner, getAccountDisplayName, IndLongShort, arrayFilterByValue, MessageType, MessageClass, } from '../../../common/constants.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { ManageGroupsView } from './managegroupsview.jsx';
import { updateAccountSelector } from '../../../actions/accountsactions';
import InputComponent from './inputComponent'
import { getGroupList, addGroupService, deleteGroupService, editGroupService, addDeleteAccountService, updateNickNameCancellationToken, updateNickNameService, sortGroup } from './managegroupservice.jsx';
import { getUserProfile } from "../../../services/home/userServices";
import { groupedaccountmockresponse } from './../../profilemockresponse.jsx';
import _ from 'lodash'
export class ManageGroups extends React.Component {
    constructor(props) {
        super(props);
        this.addGroup = this.addGroup.bind(this);
        this.handleOnGroupSelection = this.handleOnGroupSelection.bind(this);
        this.save = this.save.bind(this);
        this.setActiveGroup = this.setActiveGroup.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.openConfirmationPopup = this.openConfirmationPopup.bind(this);
        this.editToggleModal = this.editToggleModal.bind(this);
        this.editGroupName = this.editGroupName.bind(this);
        this.setGroupList = this.setGroupList.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.toggleNicknameModal = this.toggleNicknameModal.bind(this);

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }

        this.state = {
            profileId: props.profileId,
            groupsdata: [],
            expanded: [],
            accounts: props.accounts,
            selectedAccounts: [],
            activeGroup: {},
            modal: false,
            newGroupName: '',
            deleteModal: false,
            deleteRow: {},
            action: '',
            editRow: {},
            nicknameModal: false,
            nicknameUpdateRow: {},
            selectedAccountList:[],
            filteredAccountList: [],
            isProspectClient:this.props.role==='Prospect'?true:false
        }

    }
    isEmpty(row) {
        if (row.accounts === null || row.accounts === undefined) {
            return [];
        } else {
            for (const [index, account] of row.accounts.entries()) {
                account.groupid = row.groupid;
                account.groupname = row.groupname;
                account.isAccountRemove = true
            }
            return row.accounts;
        }
    }

    getuniqueAccounts(){
        const accts = this.props.accounts ? this.props.accounts : [];
        const externalaccts = this.props.externalaccts ? this.props.externalaccts : [];
        return _.uniqBy( [...accts, ...externalaccts], 'key')
    }

    componentDidMount() {
        this.setGroupList();

        // In case of show rockefeller account only account contains only
        // rockefeller account which was causing issue in group list
        // we have added external account also uniquely to solve this issue
        var accounts = this.getuniqueAccounts()
        for (const [index, account] of accounts.entries()) {
            account.isselected = false;
        }
        this.setState({ accounts: accounts, filteredAccountList: accounts});
    }

    setGroupList() {
        if (this.state.isProspectClient) {
            this.setState({ groupsdata: groupedaccountmockresponse.value }, () => {
                this.setActiveGroup(0);
            });
        }
        else {
            getGroupList(this.authToken, this.contextjson, this.profilejson, this.state.profileId)
                .then(data => {
                    if (data) {
                        if (data[0] && data[0]['@odata.type']) {
                            delete data[0]['@odata.type'];
                        }
                        console.log('Group Data:', data);
                        this.setState({ groupsdata: data }, () => {
                            this.setActiveGroup(0);
                        });

                    }
                })
                .catch(error => {
                    console.error('Unable to fetch user groups', error);
                })
        }
       
    }
   
    setActiveGroup(i) {
        if (this.state.groupsdata && this.state.groupsdata.length !== 0) {
            let expanded = this.state.expanded;
            let activeGroup = this.state.activeGroup;
            var selectedAccounts = [];
            var accounts = this.state.accounts;
            if (this.state.groupsdata[i] !== null && this.state.groupsdata[i] !== undefined) {
                expanded.push(this.state.groupsdata[i].groupid);
                
                if (accounts !== null && accounts !== undefined && Array.isArray(accounts)) {
                    for (const [index, item] of accounts.entries()) {
                        var counter = 0;
                        if (this.state.groupsdata[i].accounts !== null && this.state.groupsdata[i].accounts !== undefined) {
                            for (const [index, account] of this.state.groupsdata[i].accounts.entries()) {
                                if (item.key === account.key) {
                                    let key = item.key;
                                    selectedAccounts.push(key);
                                    counter = counter + 1;
                                }
                            }
                        }
                        if (counter > 0) {
                            item.isselected = true;
                        } else {
                            item.isselected = false;
                        }
                    }

                    activeGroup = this.state.groupsdata[i];
                }
            }
            this.setState({ selectedAccounts: selectedAccounts, expanded: expanded, activeGroup: activeGroup, accounts: accounts, isManageGroupsLoading: false });
        }
        else {
            //if this the last group then there should not be any selected account
            this.setState({ selectedAccounts: [], activeGroup: {}, isManageGroupsLoading: false });
        }
    }
    handleOnSelect = (row) => {
        var selectedAccounts = this.state.selectedAccounts;
        var accounts = this.state.accounts;
        for (const [index, account] of accounts.entries()) {
            if (account.key === row.key) {
                account.isselected = !account.isselected;
            }
        }
        var indexOfAccount = -1;
        selectedAccounts.findIndex(function (obj, i) {
            if (obj === row.key) {
                indexOfAccount = i;
            }
        })

        if (indexOfAccount !== -1) {
            selectedAccounts.splice(indexOfAccount, 1);
        } else {
            selectedAccounts.push(row.key);
        }
        this.setState({ selectedAccounts: selectedAccounts, accounts: accounts });
    }

    updateNickName(nickname, row) {
       
        if (nickname !== null && nickname !== undefined
            && row !== null && row !== undefined) {
            this.setState({ isManageGroupsLoading: true }, () => {
                let messages = [];
                //Call Update nickname api 
                let requestBody = {
                    'nickname': nickname,
                    'accountid': null,
                    'rockaccounttype': row.isInternal ? 'Internal' : 'External',
                    'profileid': this.state.profileId,
                    'accountkey': row.key
                }

                updateNickNameService(this.authToken, this.contextjson, this.profilejson, requestBody).then(res => {

                    //identify the account and update the nickname property
                    if (this.state.accounts && Array.isArray(this.state.accounts) && this.state.accounts.length > 0) {

                        let updatedAccountList = this.state.accounts.map(account => {
                            if (account && account.key && account.key === row.key) {
                                account.nickname = nickname;
                                return account;
                            } else {
                                return account;
                            }
                        })
                        var successMsg = "Nick name updated successfully.";
                        messages.push({
                            type: MessageType.INFO,
                            text: successMsg,
                            class: MessageClass.SUCCESS
                        });
                        this.setState({ nicknameModal: false, accounts: updatedAccountList, isManageGroupsLoading: false, messages: messages }, () => {
                            this.updateProfileDataInStore('NICKNAME_UPDATE');
                        })
                    }
                })
                    .catch(err => {
                        console.log(err);
                        var errorMsg = "Nick name not updated.";
                        messages.push({
                            type: MessageType.INFO,
                            text: errorMsg,
                            class: MessageClass.ERROR
                        });
                        this.setState({ isManageGroupsLoading: false, nicknameModal: false, messages: messages})
                        
                    })
            });
        } else {
            this.setState({ nicknameModal: false })
        }
    }
    handleOnSelectDefault(row) {
        this.setState({ isManageGroupsLoading: true }, () => {
            let messages = [];
            var requestBody = { "groupid": row.groupid, "isdefault": !row.isdefault};
            editGroupService(this.authToken, this.contextjson, this.profilejson, this.state.profileId, requestBody)
                .then(data => {
                    var successMsg = "Group info updated successfully.";
                    messages.push({
                        type: MessageType.INFO,
                        text: successMsg,
                        class: MessageClass.SUCCESS
                    });
                    this.setState(prevState => ({
                        groupsdata: data, editRow: {}, isManageGroupsLoading: false, messages: messages
                    }));

                })
                .catch(error => {
                    console.error(error);
                    var errorMsg = "Group info not updated.";
                    messages.push({
                        type: MessageType.INFO,
                        text: errorMsg,
                        class: MessageClass.ERROR
                    });
                    this.setState({ isManageGroupsLoading: false, messages: messages });
                })
        });
    }
    save() {
        if (this.state.activeGroup.groupid !== '') {
            this.setState({ isManageGroupsLoading: true }, () => {
                //var group = this.state.activeGroup;
                let group = {};
                group.groupid = this.state.activeGroup.groupid;
                group.profileid = this.state.activeGroup.profileid;
                group.accounts = [];
                for (const [index, selectedAccount] of this.state.selectedAccounts.entries()) {
                    let filteredAccounts = this.state.accounts.filter(account => account.key === selectedAccount);
                    let filteredAccount = filteredAccounts[0];
                    var accttype = "External";
                    if (filteredAccount.isInternal) {
                        accttype = "Internal";
                    }

                    var account = {
                        "number": window.btoa(filteredAccount.number),
                        "key": filteredAccount.key,
                        "accttype": accttype,
                        "accountid": null
                    };

                    group.accounts.push(account);
                }
                let messages = [];
                var requestBody = group;
                addDeleteAccountService(this.authToken, this.contextjson, this.profilejson, this.state.profileId, requestBody)
                    .then(data => {
                        var successMsg = "Group updated successfully.";
                        messages.push({
                            type: MessageType.INFO,
                            text: successMsg,
                            class: MessageClass.SUCCESS
                        });
                        this.setState({ groupsdata: data, isManageGroupsLoading: false, messages: messages }, () => {
                            //call profile api and send response 
                            this.updateProfileDataInStore('ADD_ACCOUNTS_IN_GROUP');
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        var errorMsg = "Group not updated.";
                        messages.push({
                            type: MessageType.INFO,
                            text: errorMsg,
                            class: MessageClass.ERROR
                        });
                        this.setState({ isManageGroupsLoading: false, messages: messages });
                    })
            });
        }
    }


    updateProfileDataInStore(selectorUpdateReason) {
        getUserProfile(this.authToken).then(res => {
            if (res && res.data && Array.isArray(res.data) && res.data[0]) {
                this.props.updateAccountSelector(res.data[0].profile, selectorUpdateReason, res.headers.profilejson);
            }
        }).catch(err => {
            console.log('Error While Fetching Profile Data', err);
        })
    }


    editGroupName() {
        this.setState({ isManageGroupsLoading: true }, () => {
            let messages = [];
            var requestBody = { "groupid": this.state.editRow.groupid, "groupname": this.state.newGroupName, "action": "EDIT" };
            editGroupService(this.authToken, this.contextjson, this.profilejson, this.state.profileId, requestBody)
                .then(data => {
                    var successMsg = "Group name updated successfully.";
                    messages.push({
                        type: MessageType.INFO,
                        text: successMsg,
                        class: MessageClass.SUCCESS
                    });
                    this.setState(prevState => ({
                        modal: !prevState.modal, groupsdata: data, editRow: {}, isManageGroupsLoading: false, messages: messages
                    }), () => {
                        this.updateProfileDataInStore('UPDATE_GROUP_NAME');
                    });

                })
                .catch(error => {
                    console.error(error);
                    var errorMsg = "Group name not updated.";
                    messages.push({
                        type: MessageType.INFO,
                        text: errorMsg,
                        class: MessageClass.ERROR
                    });
                    this.setState({ isManageGroupsLoading: false, messages: messages });
                })
        });
    }

    addGroup() {
        this.setState({ isManageGroupsLoading: true }, () => {
            let messages = [];
            var requestJson = { "groupid": null, "profileid": this.state.profileId, "groupkey": "", "grouptypecd": "test", "groupname": this.state.newGroupName, "isvisible": "1", "isdeleted": "0", "accounts": null };
            addGroupService(requestJson, this.authToken, this.contextjson, this.profilejson, this.state.profileId)
                .then(data => {
                    var successMsg = "Group added successfully.";
                    messages.push({
                        type: MessageType.INFO,
                        text: successMsg,
                        class: MessageClass.SUCCESS
                    });
                    this.setState(prevState => ({
                        modal: !prevState.modal, groupsdata: this.addUniqueValueToRow(data), isManageGroupsLoading: false, messages: messages
                    }), () => {
                        this.updateProfileDataInStore('ADD_GROUP');
                    });
                    //var length = data.length - 1;
                    this.setActiveGroup(0);
                })
                .catch(error => {
                    console.error(error);
                    var errorMsg = "Group not added.";
                    messages.push({
                        type: MessageType.INFO,
                        text: errorMsg,
                        class: MessageClass.ERROR
                    });
                    this.setState({ isManageGroupsLoading: false, messages: messages});
                })
        });
    }

    openConfirmationPopup(row) {
            this.setState(prevState => ({
                deleteModal: !prevState.deleteModal, deleteRow: row
            }));
    }


    deleteGroup() {
        this.setState({ isManageGroupsLoading: true }, () => {
            let messages = [];
            var requestBody = { "groupid": this.state.deleteRow.groupid, "action": "DELETE" };
            deleteGroupService(this.authToken, this.contextjson, this.profilejson, this.state.profileId, requestBody)
                .then(data => {
                    var successMsg = "Group deleted successfully.";
                    messages.push({
                        type: MessageType.INFO,
                        text: successMsg,
                        class: MessageClass.SUCCESS
                    });
                    var expanded = this.state.expanded;
                    expanded = expanded.filter(x => x !== this.state.deleteRow.groupid);
                    var selectedAccounts = this.state.selectedAccounts;
                    selectedAccounts = selectedAccounts.filter(x => x !== this.state.deleteRow.groupid);
                    this.setState(prevState => ({
                        deleteModal: !prevState.deleteModal, groupsdata: this.addUniqueValueToRow(data), deleteRow: {},
                        expanded: expanded, selectedAccounts: selectedAccounts, isManageGroupsLoading: false, messages: messages
                    }), () => {
                        this.updateProfileDataInStore('DELETE_GROUP');
                    });

                    this.setActiveGroup(0);
                })
                .catch(error => {
                    console.error(error);
                    var errorMsg = "Group not deleted.";
                    messages.push({
                        type: MessageType.INFO,
                        text: errorMsg,
                        class: MessageClass.ERROR
                    });
                    this.setState(prevState => ({
                        deleteModal: !prevState.deleteModal, deleteRow: {}, isManageGroupsLoading: false, messages: messages
                    }));
                })
        });
    }

    toggleNicknameModal(row) {
        this.setState(prevState => (
            {
                nicknameModal: !prevState.nicknameModal,
                nicknameUpdateRow: row
            }
        ));
    }

    deleteAccount() {
        this.setState({ isManageGroupsLoading: true }, () => {
            var row = this.state.deleteRow;
            let requestBody = {};
            //requestBody.groupid= this.state.activeGroup.groupid;
            requestBody.groupid = row.groupid;
            requestBody.profileid = this.state.profileId;
            requestBody.accounts = [];

            var groupsdata = this.state.groupsdata;
            for (const [index, group] of groupsdata.entries()) {
                if (group.groupid === row.groupid) {
                    if (group && group.accounts && Array.isArray(group.accounts)) {
                        group.accounts.forEach(obj => {
                            if (obj.key !== row.key) {
                                var accountType = obj.accttype;
                                if (obj.accttype === "Manual") {
                                    accountType = "External";
                                }
                                var account = {
                                    "number": window.btoa(obj.number),
                                    "key": obj.key,
                                    "accttype": accountType,
                                    "accountid": obj.accountid
                                };
                                requestBody.accounts.push(account);
                            }
                        });
                    }
                }
            }
            let messages = [];
            addDeleteAccountService(this.authToken, this.contextjson, this.profilejson, this.state.profileId, requestBody)
                .then(data => {
                    //get the selected accounts
                   
                    let selectedAccounts = [];
                    if (data && Array.isArray(data)) {
                        for (const [index, group] of data.entries()) {
                            if (group.groupid === this.state.activeGroup.groupid) {
                                if (group && group.accounts && Array.isArray(group.accounts)) {
                                    group.accounts.forEach(obj => {
                                        selectedAccounts.push(obj.key)
                                    });
                                }
                            }
                        }
                        var accounts = this.state.accounts;
                        for (const [index, account] of accounts.entries()) {
                            var indexOfAccount = -1;
                            selectedAccounts.findIndex(function (obj, i) {
                                if (obj === account.key) {
                                    indexOfAccount = i;
                                }
                            })

                            if (indexOfAccount !== -1) {
                                account.isselected = true;
                            } else {
                                account.isselected = false;
                            }
                        }
                        var successMsg = "Account deleted successfully.";
                        messages.push({
                            type: MessageType.INFO,
                            text: successMsg,
                            class: MessageClass.SUCCESS
                        });

                        this.setState(prevState => ({
                            deleteModal: !prevState.deleteModal,
                            groupsdata: data,
                            selectedAccounts: selectedAccounts,
                            accounts: accounts,
                            deleteRow: {},
                            isManageGroupsLoading: false,
                            messages: messages
                        }), () => {
                            this.updateProfileDataInStore('DELETE_ACCOUNT_IN_GROUP');
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    var errorMsg = "Account not deleted.";
                    messages.push({
                        type: MessageType.INFO,
                        text: errorMsg,
                        class: MessageClass.ERROR
                    });
                    this.setState(prevState => ({
                        deleteModal: !prevState.deleteModal,
                        deleteRow: {}, isManageGroupsLoading: false,
                        messages: messages
                    }));
                })
        });
    }

    handleOnGroupSelection = (row, e) => {

        //Check what value event is containing
        //console.log('Event Fired On Row Click', e.target.getAttribute("sortindicator"));
        //we need to first check , if we have clicked on sort icons then no need to do group selection 

        if (e.target.getAttribute("sortindicator") !== 'UP' && e.target.getAttribute("sortindicator") !== 'DOWN') {

            if (row) {
                var accounts = this.state.accounts;
                var expanded = this.state.expanded;
                var activeGroup = row;

                if (expanded && Array.isArray(expanded)) {
                    var isGroupExpended = expanded.includes(row.groupid);
                    if (!isGroupExpended) {
                        expanded.push(row.groupid);
                    }
                }
                
                var selectedAccounts = [];
                if (row.accounts !== null && row.accounts !== undefined) {
                    var groupAccounts = row.accounts;
                    
                    if (accounts !== null && accounts !== undefined && Array.isArray(accounts)) {
                        for (const [index, item] of accounts.entries()) {
                            var counter = 0;
                            for (const [index, account] of groupAccounts.entries()) {
                                if (item.key === account.key) {
                                    let key = item.key;
                                    selectedAccounts.push(key);
                                    counter = counter + 1;
                                }
                            }
                            if (counter > 0) {
                                item.isselected = true;
                            } else {
                                item.isselected = false;
                            }
                        }
                    }
                } else {
                    activeGroup.accounts = [];
                    if (accounts !== null && accounts !== undefined && Array.isArray(accounts)) {
                        for (const [index, item] of accounts.entries()) {
                            item.isselected = false;
                        }
                    }
                }
                this.setState({ selectedAccounts: selectedAccounts, activeGroup: activeGroup, expanded: expanded, accounts: accounts });
            }
        }
    }

    handleOnGroupExpand = (row, isExpand) => {
        var expanded = this.state.expanded;
        if (isExpand && expanded && Array.isArray(expanded)) {
            var isGroupExpended = expanded.includes(row.groupid);
            if (!isGroupExpended) {
                expanded.push(row.groupid);
            }
        } else {
            expanded = expanded.filter(x => x != row.groupid);
        }

        this.setState({ expanded: expanded });
    }


    toggleModal() {
        if (!this.state.isProspectClient)
            this.setState(prevState => ({
                modal: !prevState.modal, newGroupName: '', action: '', editRow: {}
            }));
    }
    editToggleModal(row) {
        this.setState(prevState => ({
            modal: !prevState.modal, newGroupName: row.groupname, action: 'edit', editRow: row
        }));
    }
    toggleDeleteModal() {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    }
    handleFormChanges(evt) {
        if (evt.target.name === "groupname") {
            var newGroupName = evt.target.value;
            newGroupName = newGroupName.replace(/[^a-zA-Z 0-9*_()&/-]+/g, '');
            this.setState({ newGroupName: newGroupName });
        }
    }

    getSwapLoginId(rowIndex, sortIndicator) {
        if (sortIndicator === 'UP') {
            return (this.state.groupsdata && Array.isArray(this.state.groupsdata) && typeof this.state.groupsdata[rowIndex - 1] !== undefined) ? this.state.groupsdata[rowIndex - 1].grouploginid : ''
        } else {
            return (this.state.groupsdata && Array.isArray(this.state.groupsdata) && typeof this.state.groupsdata[rowIndex + 1] !== undefined) ? this.state.groupsdata[rowIndex + 1].grouploginid : '';
        }
    }

    sortGroup(row, rowIndex, sortIndicator) {
        this.setState({ isManageGroupsLoading: true }, () => {
            let messages = [];
            var requestBody = {
                'currentitemid': row.grouploginid,
                'swapitemid': this.getSwapLoginId(rowIndex, sortIndicator),
                'type': 'Group'
            }

            sortGroup(this.authToken, this.contextjson, this.profilejson, requestBody).then(res => {
                console.log(res);
                
                getGroupList(this.authToken, this.contextjson, this.profilejson, this.state.profileId)
                    .then(data => {
                        if (data) {
                            if (data[0] && data[0]['@odata.type']) {
                                delete data[0]['@odata.type'];
                            }
                            console.log('Group Data:', data);
                            var successMsg = "Groups sorted successfully.";
                            messages.push({
                                type: MessageType.INFO,
                                text: successMsg,
                                class: MessageClass.SUCCESS
                            });
                            this.setState({ groupsdata: data, isManageGroupsLoading: false, messages: messages }, () => {
                                this.updateProfileDataInStore('GROUP_SORT');
                            });

                        }
                    })
                    .catch(error => {
                        console.error('Unable to fetch user groups', error);
                        var errorMsg = "Unable to fetch user groups.";
                        messages.push({
                            type: MessageType.INFO,
                            text: errorMsg,
                            class: MessageClass.ERROR
                        });
                        this.setState({ isManageGroupsLoading: false, messages: messages });
                    })
            }).catch(err => {
                console.log('Error While sorting group ', err);
                var errorMsg = "Groups not sorted.";
                messages.push({
                    type: MessageType.INFO,
                    text: errorMsg,
                    class: MessageClass.ERROR
                });
                this.setState({ isManageGroupsLoading: false, messages: messages });
            })
        });

    }

    //Call this function to add current timestamp each group row

    addUniqueValueToRow(data) {
        if (data !== null && data !== undefined && Array.isArray(data)) {
            // add extra property 
            for (const [index, item] of data.entries()) {
                item.uniqueValue = new Date();
            }
        }

        return data;
    }


    beforeSaveCell(oldValue, newValue, row, column, done) {
        console.log(row);
        row.nickname = newValue.replace(/[^a-zA-Z 0-9'/-]/g, "")
        if (oldValue !== newValue) {
            let requestBody = {
                'nickname': newValue.replace(/[^a-zA-Z 0-9'/-]/g, ""),
                'accountid': null,
                'rockaccounttype': row.isInternal ? 'Internal' : 'External',
                'profileid': this.state.profileId,
                'accountkey': row.key
            }

            updateNickNameService(this.authToken, this.contextjson, this.profilejson, requestBody).then(res => {
                console.log(res);
                done(true);
                this.updateProfileDataInStore('NICKNAME_UPDATE');
            })
                .catch(err => {
                    console.log(err);
                    done(false);
                })

        } else {
            done(false);
        }

        return { async: true };
    }

    customCaretSort(order, column)
    {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }

    handleAccountFilter(accounts, e){
        let filteredAcct =  arrayFilterByValue(accounts, e.target.value);
        this.setState({filteredAccountList: filteredAcct, filterKey: e.target.value});
    }

    resetFilter(accounts){
        this.setState({filteredAccountList: accounts, filterKey: ''});
    }

    render() {
        const grpTableRowClasses = (row, rowIndex) => {
            if (rowIndex % 2 == 0)
                return 'RCM_Table_row_odd  RCM_grp_table_row';
            else
                return 'RCM_Table_row_even  RCM_grp_table_row';
        };
        const rowClasses = (row, rowIndex) => {
            if (rowIndex % 2 == 0)
                return 'RCM_Table_row_odd  RCM_grp_table_row';
            else
                return 'RCM_Table_row_even  RCM_grp_table_row';
        };
        const defaultColumnMsg = "Groups that are selected here, will be selected upon login.";
        const columns = [{
            dataField: 'groupname',
            text: "Group Name",
            classes: 'RCM_manage_groups_name_col',
            formatter: (cell, row) => {
                return (
                    <div className="RCM_edit_cell_container">{cell}
                        <div className={"RCM_edit_icon RCM_group_edit_icon " + (this.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                            onClick={() => this.editToggleModal(row)}
                            title="Edit group name"
                            disabled={this.state.isProspectClient}></div>
                    </div>);
            },

        },
            {
                dataField: 'isdefault',
                text: "",
                classes: 'RCM_manage_groups_delete_col RCM_manage_group_default',
                headerFormatter: (cell, row) => {
                    return (<div className="RCM_manage_group_default_header"><span>Default&nbsp;<div className="RCM_Inline_Div RCM_icon_hand" title={defaultColumnMsg}>&#x1F6C8;</div></span></div>)
                },
                formatter: (cell, row, rowIndex) => {
                    return (
                        <label className="RCM_selectLabel">
                        <input type="checkbox" checked={cell}
                            className="RCM_select_checkbox" type="checkbox"
                            onChange={() => this.handleOnSelectDefault(row)}
                            disabled={this.state.isProspectClient} />
                        <span className="RCM_accounts_span"></span>
                        </label>
                    ) 
                }
            },

        {
            dataField: '',
            isDummyField: true,
            classes: 'RCM_manage_groups_delete_col',
            formatter: (cell, row, rowIndex) => {
                return (rowIndex !== 0) ? (<span 
                    className={"RCM_Sort_arrow_up_icon " + (this.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                    onClick={() => this.sortGroup(row, rowIndex, 'UP')}
                    disabled={this.state.isProspectClient}
                    sortindicator="UP" title="Move Up"></span>) : ""
            }
        },
        {
            dataField: '',
            isDummyField: true,
            classes: 'RCM_manage_groups_delete_col',
            formatter: (cell, row, rowIndex) => {
                return ((rowIndex !== (this.state.groupsdata.length - 1)) ?
                    (<span
                        className={"RCM_Sort_arrow_down_icon " + (this.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                        disabled={this.state.isProspectClient}
                        onClick={() => this.sortGroup(row, rowIndex, 'DOWN')}
                        sortindicator="DOWN" title="Move Down"></span>) : "")
            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            classes: 'RCM_manage_groups_delete_col',
            formatter: (cell,row) => {
                return <div
                    className={"RCM_manage_grp_delete_icon " + (this.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                    title="Remove group" disabled={this.state.isProspectClient} onClick={() => this.openConfirmationPopup(row)}></div>;
            },
            events: {
                
            }
        }
        ];

        const accountsGridColumns = [
            {
                dataField: "isselected",
                text: "Include",
                sort: false,
                editable: false,
                formatter: (cell, row) => {
                    return (
                        <label className="RCM_selectLabel">
                            <input type="checkbox" checked={cell}
                                className="RCM_select_checkbox" type="checkbox"
                                onChange={() => this.handleOnSelect(row)}
                                disabled={this.state.isProspectClient}/>
                            <span className="RCM_accounts_span"></span>
                        </label>
                        )
                }
            },
            {
                dataField: 'finame',
                text: 'Firm',
                editable: false,
                sort: true,
                formatter: (cell, row) => {
                    return row.isInternal ? 'Rockefeller' : row.finame;
                },
                sortCaret: (order, column) => this.customCaretSort(order, column),
            },
            {
                dataField: 'accttype',
                text: 'Type',
                sort: true,
                editable: false,
                formatter: (cell, row) => {
                    return row.isInternal ? row.accountregistration : row.accttype;
                },
                sortCaret: (order, column) => this.customCaretSort(order, column),
            },
            {
                dataField: 'number',
                text: 'Account',
                sort: true,
                editable: false,
                sortCaret: (order, column) => this.customCaretSort(order, column),
                
            },
            {
                dataField: 'nickname',
                text: 'Nickname',
                editable: true,
                sort: true,
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                    <InputComponent {...editorProps} value={value} />
                ),
                formatter: (cell, row) => {
                    return (
                        <div className="RCM_edit_cell_container">
                            {(cell) ? cell.replace(/[^a-zA-Z 0-9'/-]/g, "") : "-"}
                            <div className={"RCM_edit_icon RCM_nickname_edit_icon " + (this.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                                onClick={() => this.toggleNicknameModal(row)}
                                title="Edit Nick name"
                                disabled={this.state.isProspectClient} ></div>
                        </div>
                    )
                },
                sortCaret: (order, column) => this.customCaretSort(order, column),
            }
            ];
        const selectGroupRow = {
            mode: 'radio',
            clickToSelect: true,
            clickToExpand: false,
            hideSelectColumn: true,
            classes: 'RCM_grp_selection_row',
            selected: [this.state.activeGroup.groupid],
            onSelect: (row, isSelect, rowIndex, e) => this.handleOnGroupSelection(row, e)
        }

        const accountchildcolumns = [{
            dataField: 'number',
            text: '',
            classes: 'RCM_manage_groups_number_col',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                //alert('key::', row.key, getAccountDisplayName(this.props.accounts, row.key, IndLongShort.LONG));
                return (
                    <div title={getAccountDisplayName(formatExtraData.accounts, row.key, IndLongShort.LONG)}>
                        {getAccountDisplayName(formatExtraData.accounts, row.key, IndLongShort.SINGLEVIEW)}
                    </div>
                )
            },
            formatExtraData: {
                accounts: this.getuniqueAccounts()
            }
        },
        {
            dataField: '',
            classes: 'RCM_manage_groups_delete_col',
            text: '',
            isDummyField: true,
            formatter: (cell,row) => {
                return <div
                    className={"RCM_manage_grp_delete_icon "+ (this.state.isProspectClient ? 'RCM_Prospect_Disabled' : "")}
                    title="Remove account"
                    disabled={this.state.isProspectClient} onClick={() => this.openConfirmationPopup(row)}></div>;
            },
            events: {
               // onClick:this.openConfirmationPopup
            }
        },
        {
            dataField: '',
            text: '',
            isDummyField: true,
            classes: 'RCM_manage_groups_empty_col',
        },
        ];
        const expandRow = {
            onExpand: (row, isExpand) => this.handleOnGroupExpand(row, isExpand),
            renderer: row => (
                <div className="row">
                    <div className="col-sm-12">
                        <BootstrapTable
                            keyField='key'
                            classes="RCM_two_level_table2 RCM_Headerless_table RCM_grp_Expanded_Table"
                            data={this.isEmpty(row)}
                            columns={accountchildcolumns}
                            bordered={false}
                            noDataIndication='There are no accounts assigned to this group, please assign accounts from right panel.'
                        />
                    </div>
                </div>
            ),
            expanded: this.state.expanded,
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: () => {
                return (
                    <div align="right" onClick={this.toggleModal} id="RCM_group_add"
                        className="RCM_icon_hand  RCM_manage_grp_new_group">+ Add Group</div>)
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_manage_grp_arrow_down"></div>);
                }
                return (
                    <div className="RCM_manage_grp_arrow"></div>
                );
            }
        };

        return (
            <ErrorBoundary>
                {
                    this.props.accounts === null ? <div className="RCM_spinner">Loading Groups{spinner()}</div> :
                        <ManageGroupsView
                            state={this.state}
                            handleChange={this.handleChange}
                            save={this.save}
                            toggleModal={this.toggleModal}
                            handleFormChanges={this.handleFormChanges}
                            addGroup={this.addGroup}
                            editGroupName={this.editGroupName}
                            toggleDeleteModal={this.toggleDeleteModal}
                            deleteGroup={this.deleteGroup}
                            deleteAccount={this.deleteAccount}
                            columns={columns}
                            grpTableRowClasses={grpTableRowClasses}
                            expandRow={expandRow}
                            selectGroupRow={selectGroupRow}
                            accountsGridColumns={accountsGridColumns}
                            // selectRow={selectRow}
                            rowClasses={rowClasses}
                            toggleNicknameModal={this.toggleNicknameModal}
                            updateNickName={(nickname, row) => this.updateNickName(nickname, row)}
                            beforeSaveCell={(oldValue, newValue, row, column, done) => this.beforeSaveCell(oldValue, newValue, row, column, done)}
                            handleAccountFilter={(accounts, e) => this.handleAccountFilter(accounts, e)}
                            resetFilter={(accts => this.resetFilter(accts))}
                            filterKey={this.state.filterKey}
                        //afterSaveCell = {this.afterSaveCell}
                        />
                }
            </ErrorBoundary>
        );
    }

}
const mapStateToProps = (store) => {
    return {
        accounts: store.acct.accounts,
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
        role:store.acct.role,
        externalaccts: store.acct.externalAccounts
    };
}

const mapDispatchToProps = {
    updateAccountSelector
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Manage Groups' })
)

export default enhancement(ManageGroups);
