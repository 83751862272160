import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Accountlist from './accounts/summary/accountlist.jsx'
import { generateExcelReport } from '../common/excelreport.jsx';
import { generatePrintReport } from '../common/printreport.jsx';
import { UpdateAccount } from '../actions/accountsactions.jsx';
import { toggleComponent } from '../actions/personalisationAction';
import { GetPreferenceValue, GetPreferencePilotValue, planManagerUrl } from './../common/constants';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Badge
} from 'reactstrap';
import BillPayConfirmationModal from './MoneyMovement/common/billpayconfirmationModal.jsx';
import PlanManagerConfirmationModal from './services/planmanager/planManagerconfirmationModal';
import introJs from 'intro.js';
import { introData } from './../data/intro.jsx';
import * as $ from 'jquery';
class StickyNavbar extends React.Component {


    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.generateExcel = this.generateExcel.bind(this);
        this.printCustomWindow = this.printCustomWindow.bind(this);
        this.removedselectionEvent = this.removedselectionEvent.bind(this);
        this.removeotherSelectionNavbar = this.removeotherSelectionNavbar.bind(this);
        this.GetClassNamePrintExport = this.GetClassNamePrintExport.bind(this);
        this.togglePlanManagerModal = this.togglePlanManagerModal.bind(this);

        let dragableObj = this.props.preferences !== undefined ? this.props.preferences.pilotfeatures.filter(data => data.componentname === "OnlinePortalCustomization") : []
        this.state = {
            navigate: this.props.navigate,
            preferences: this.props.preferences,
            imgURL: './dist/images/',
            imgExtn: '.svg',
            imgHover: '_hover',
            imgSelected: '_selected',
            dropdownOpen: false,
            dropdownTransferOpen: false,
            dropdownServicesOpen: false,
            isAccounta: true,
            isOpen: false,
            modalState: false,
            allSteps: introData,
            toggleCustomizationIcon: (dragableObj[0] !== undefined) && (dragableObj[0].active === "true") ? true : false,
            options: { scrollTo: 'tooltip', disableInteraction: true, },
            isCustomView: this.getPreferenceValue(),
            planManagerModalState: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.getPreferenceValue = this.getPreferenceValue.bind(this);
    }
    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }

    isRequired(source) {
        let obj = {
            preferences: this.props.preferences
        }
        let isRequired = ((source === 'PlanManager' || source === 'Services') && planManagerUrl && planManagerUrl !== null && planManagerUrl !== '')
            ? (GetPreferencePilotValue(obj, source, "false") === "true" ? true : false)
            : (source !== 'PlanManager' && source !== 'Services') ? (GetPreferencePilotValue(obj, source, "false") === "true" ? true : false)
                :false;

        return isRequired;
    }
    GetClassNamePrintExport() {
        var currentRoute = window.location.pathname;
        if (currentRoute === "/Collaboration" || currentRoute === "/Help" || currentRoute === "/Feedback" || currentRoute === "/Support" || currentRoute === "/ProfileComponent" || currentRoute === "/Profile" || currentRoute === "/EDelivery" || currentRoute === "/AccountsNickname" || currentRoute === "/CaseManager" || currentRoute === "/ManageGroups" || currentRoute === "/ViewPreferences") {
            return "RCM_printexportWrapper_disabled";
        }
        else {
            return "RCM_printexportWrapper RCM_icon_hand";
        }
    }
    generateExcel() {
        generateExcelReport(this.props, window.location.pathname);
    }

    printCustomWindow() {
        generatePrintReport(this.props, window.location.pathname);
    }
    toggle() {
        //if (this.state.isOpen === false) {
        this.setState({
            isOpen: !this.state.isOpen
        });
        // }
    }

    toggleModal() {
        this.setState(prevState => ({
            modalState: !prevState.modalState
        }));
    }
    togglePlanManagerModal() {
       
        this.setState(prevState => ({
            planManagerModalState: !prevState.planManagerModalState
        }));
    }
    onMouseEnter(navigateName) {
        if (navigateName === "Accounts")
            this.setState({ dropdownOpen: true });

        if (navigateName === "Transfer")
            this.setState({ dropdownTransferOpen: true });
        if (navigateName === "Services")
            this.setState({ dropdownServicesOpen: true });
        
    }

    onMouseLeave(navigateName) {
        if (navigateName === "Accounts")
            this.setState({ dropdownOpen: false });

        if (navigateName === "Transfer")
            this.setState({ dropdownTransferOpen: false });
        if (navigateName === "Services")
            this.setState({ dropdownServicesOpen: false });

    }
    removedselectionEvent = (event) => {
        if (event !== null && event !== undefined) {
            const navigate = this.state.navigate;
            for (const [index, value] of this.state.navigate.entries()) {
                value.active = false;
            }
            this.setState({ navigate: navigate });
        }
        else {
            console.log("Invalid removedselectionEvent received");
        }
    };
    removeotherSelectionNavbar = (event) => {
        if (event !== null && event !== undefined) {
            const navigate = this.state.navigate;
            for (const [index, value] of this.state.navigate.entries()) {
                if (value.Name === 'Accounts') {
                    value.active = true;
                } else if (value.Name === 'Collaboration' && this.props.role === "Prospect") {
                    value.active = true;
                }
                else {
                    value.active = false;
                }
            }
            this.setState({ navigate: navigate });
        }
        else {
            console.log("Invalid removeotherSelectionNavbar received");
        }
    };
    componentDidUpdate() {
        if (this.props.preferences && this.state.preferences) {
            if (this.props.preferences !== this.state.preferences) {
                let iscustomview = this.getPreferenceValue();
                this.setState({ isCustomView: iscustomview, preferences: this.props.preferences });
            }
        }

    }

    componentDidMount() {
        window.addEventListener('removeSelection', this.removedselectionEvent);
        window.addEventListener('HandlenavbarSelection', this.removeotherSelectionNavbar);
        const navigate = this.state.navigate;
        const currentRoute = this.props.location.pathname;
        for (const [, value] of this.state.navigate.entries()) {
            var route = "/" + value.Name;
            if (route === currentRoute) {
                value.active = true;
            } else if (value.Name === 'Accounts' && (currentRoute === "/" || currentRoute === '/Accounts'
                || currentRoute === '/Toptendetail' || currentRoute === '/SecurityHoldingdetails' || currentRoute === '/AccountHoldingdetails'
                || currentRoute === '/Activitydetail' || currentRoute === '/Balancesdetail' || currentRoute === '/Assetallocationdetail'
                || currentRoute === '/AssetAggregation' || currentRoute === '/ManageAssets' || currentRoute === '/EstimatedProjectedIncomeDetails'
                || currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity'
                || currentRoute === '/EstimatedIncomeAccount' || currentRoute === '/EstimatedIncomeSummary'
                || currentRoute === '/RealizedSecurityHoldingdetails' || currentRoute === '/RealizedAccountHoldingdetails'
                || currentRoute === '/RealizedGainLossDetails' || currentRoute === '/Liabilities' || currentRoute === '/ProjectedIncomeSummary'
                || currentRoute === '/ProjectedIncomeSecurity' || currentRoute === '/ProjectedIncomeAccount' || currentRoute === '/ProjectedIncomeDetails'
                || currentRoute === '/HistoricalAssetAllocation' || currentRoute === '/Balanceshistory' || currentRoute === '/Assetallocationhistory'
                || currentRoute === ' /AssetHoldingdetails' || currentRoute === '/Performance' || currentRoute === '/AccountPerformance'
                || currentRoute === '/AssetPerformance' || currentRoute === '/SecurityPerformance' || currentRoute === '/PerformanceSummary')) {
                value.active = true;
            }
            else if (value.Name === 'Transfer' && (currentRoute === '/Transfer' || currentRoute === '/Transfers' || currentRoute === '/BillPay' || currentRoute === '/manageExtAccount' || currentRoute ==='/ProspectBillPay')) {
                value.active = true;
            }
            else if (value.Name === 'Statements' && (currentRoute === '/AccountStatements' || currentRoute === '/Documents' || currentRoute === '/TradeConfirms' || currentRoute === '/TaxDocuments' || currentRoute === '/Correspondence' || currentRoute === '/Reports')) {
                value.active = true;
            }
            else if (
              value.Name === "Help" &&
              (currentRoute === "/Help" ||
                currentRoute === "/CaseManager" ||
                currentRoute === "/Feedback" ||
                currentRoute === "/Support")
            ) {
              value.active = true;
            } 
            else if (
              (value.Name && value.Name.toLowerCase() === "lifestyle") &&
              [
                "/aviation",
                "/philanthropy",
                "/lifestyle",
                "/art",
                "/cyberwa",
                "/eldercare",
                "/health",
                "/immersivex",
                "/inspirato",
                "/nextgen",
                "/proofloyalty",
              ].includes(currentRoute)
            ) {
              value.active = true;
            } 
            else if (
              value.Name === "Services" &&
              (currentRoute === "/services" || currentRoute === "/planManager")
            ) {
              value.active = true;
            } else {
              value.active = false;
            }
        }
        this.setState({ navigate: navigate });

    }
    componentWillUnmount() {
        // de registering the event here
        window.removeEventListener('removeSelection', this.setStepsEvent);
        window.removeEventListener('HandlenavbarSelection', this.removeotherSelectionNavbar);
    }

    handleSelections(param, e) {
        document.dispatchEvent(new CustomEvent('removeSelectionUsersetting', { bubbles: true }));
        document.dispatchEvent(new CustomEvent('removePreferenceMesage', { bubbles: true }));
        const navigate = this.state.navigate;
        if (!param.active) {
            for (const [, value] of this.state.navigate.entries()) {
                value.active  = value.Name === param.Name ? true : false
            }
            if (this.state.isOpen === true) {
                this.setState({ navigate: navigate, isOpen: !this.state.isOpen });
            } else {
                this.setState({ navigate: navigate });
            }
        }

        if (param.Name && param.Name === 'Accounts') {
            document.dispatchEvent(new CustomEvent('AccountTabSelected', { bubbles: true }));
        }
        else {
            document.dispatchEvent(new CustomEvent('AccountTabDeselected', { bubbles: true }));
        }
    }
    refreshApplication() {
        this.props.UpdateAccount(this.props.acct.selectedaccount, this.props.acct.render, false);
    }
    setSteps() {
        var currentRoute = window.location.pathname;
        var steps = [];
        for (const [, value] of this.state.allSteps.data.entries()) {
            var route = "/" + value.step;
            var altRoute = "/" + value.altStep;
            if (currentRoute === '/' && route === '/Accounts') {
                steps = value.steps;
            }
            else if (route === currentRoute || altRoute === currentRoute) {
                steps = value.steps;
            }
        }

        var introProfile = introJs();
        introProfile.setOptions({
            tooltipPosition: 'top',
            steps: steps.filter(function (obj) {
                if ($(obj.element).is(":visible")) {
                    return $(obj.element).length;
                }
            }),
            scrollTo: 'tooltip',
            disableInteraction: true
        });
        introProfile.start();
    }
    toggleSteps = () => {
        this.setSteps();
    };

    updatePreferenceReducer = () => {
        let data = this.props.personalistaionData.toggleComponent;
        this.props.toggleComponent(!data);
    }
    getClassName(isCustomView, navLinkName) {
        if (isCustomView && navLinkName === "Accounts") {
            return 'RCM_Nav_wrapper_active';
        }
        else {
            return 'RCM_Nav_wrapper_active RCM_Disable_Link';
        }
    }

    render() {
        return (
            <div>
                <Navbar color="dark" dark expand="lg" sticky="top">
                    <NavbarBrand className="mr-auto RCM_Accounts_dropDown_container"><Accountlist /></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="REM_aaflex-list" navbar>
                            {
                                this.state.navigate && this.state.navigate.map(navigatelink => {
                                    return ((navigatelink.Name === 'Accounts' || navigatelink.Name === 'Transfer' || (navigatelink?.Name?.toLowerCase() === 'lifestyle') || (navigatelink.Name === 'Services' && this.isRequired(navigatelink.Name))) && (!this.state.isCustomView || navigatelink?.Name?.toLowerCase() === 'lifestyle')) ?
                                        <Dropdown key={"Nav8" + navigatelink.Name} className="d-inline-block" direction="bottom"
                                            onMouseOver={() => this.onMouseEnter(navigatelink.Name)}
                                            onMouseLeave={() => this.onMouseLeave(navigatelink.Name)}
                                            isOpen={(navigatelink.Name === "Accounts") ? this.state.dropdownOpen
                                                : (navigatelink.Name === 'Transfer') ? this.state.dropdownTransferOpen
                                                    : this.state.dropdownServicesOpen}
                                            toggle={() => this.toggle(navigatelink.Name)}>
                                            <Link key={"Nav20" + navigatelink.Name} to={navigatelink.Name} className={"RCM_Nav_wrapper RCM_menu_text " + (navigatelink.active ? 'RCM_menu_active ' : ' ')}>
                                                <DropdownToggle key={"Nav9" + navigatelink.Name} onClick={this.handleSelections.bind(this, navigatelink)}>

                                                    <NavItem>
                                                        <div key={"Nav21" + navigatelink.Name} className={"RCM_menu_img RCM_Nav_" + navigatelink.Name}></div>
                                                        <div className={"RCM_Nav_displayNone_" + navigatelink.Name}></div>
                                                        <div key={"Nav22" + navigatelink.Name} className="RCM_nav_text">{navigatelink.DisplayName}</div>
                                                    </NavItem>

                                                </DropdownToggle>
                                            </Link>
                                            <DropdownMenu key={"Nav14" + navigatelink.Name} className="RCM_navmenu">
                                                {
                                                    navigatelink.Components && navigatelink.Components.map(component => {
                                                        return component.ComponentName === 'Allocation' && component.Active === true ?
                                                            <Link key={"Nav15" + navigatelink.Name} to='Assetallocationdetail' onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Asset Allocation</DropdownItem></Link>
                                                            : component.ComponentName === 'TopTen' && component.Active === true ?
                                                                <Link key={"Nav16" + navigatelink.Name} to='Toptendetail' onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Holdings</DropdownItem></Link>
                                                                : component.ComponentName === 'Balances' && component.Active === true ?
                                                                    <Link key={"Nav17" + navigatelink.Name} to='Balancesdetail' onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Balances</DropdownItem></Link>
                                                                    : component.ComponentName === 'Activity' && component.Active === true ?
                                                                        <Link key={"Nav18" + navigatelink.Name} to='Activitydetail' onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Activity</DropdownItem></Link>
                                                                        : component.ComponentName === 'RealizedGainLoss' && component.Active === true ?
                                                                            <Link key={"Nav26" + navigatelink.Name} to='RealizedGainLossDetails' onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Realized Gain Loss</DropdownItem></Link>
                                                                            : component.ComponentName === 'Income' && component.Active === true ?
                                                                                <Link key={"Nav27" + navigatelink.Name} to={{
                                                                                    pathname: '/EstimatedProjectedIncomeDetails',
                                                                                    view: {
                                                                                        viewBy: 'E'
                                                                                    }
                                                                                }} onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Income</DropdownItem></Link>
                                                                                : component.ComponentName === 'Liabilities' && component.Active === true ?
                                                                                    <Link key={"Nav29" + navigatelink.Name} to={{
                                                                                        pathname: '/Liabilities',
                                                                                        view: {
                                                                                            viewBy: 'L'
                                                                                        }
                                                                                    }} onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Liabilities</DropdownItem></Link>
                                                                                    : component.ComponentName === 'Performance' && component.Active === true && this.isRequired(component.ComponentName) ?
                                                                                        <Link key={"Nav161" + navigatelink.Name} to='Performance' onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>Performance</DropdownItem></Link>
                                                                                        : component.ComponentName === "My Transfers" && component.Active === true ?
                                                                                            <Link key={"Nav19" + navigatelink.Name} to="Transfer" onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem>My Transfer</DropdownItem></Link>
                                                                                            : component.ComponentName === "Manage Accounts" && component.Active === true ?
                                                                                                <Link key={"Nav20" + navigatelink.Name} to="manageExtAccount" onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem >Manage Accounts</DropdownItem></Link>
                                                                                                : component.ComponentName === "Bill Payments" && component.Active === true && this.props.role === 'Prospect' ?
                                                                                                    <Link key={"Nav21" + navigatelink.Name} to="ProspectBillPay"><DropdownItem onClick={this.handleSelections.bind(this, navigatelink)}>Bill Pay</DropdownItem></Link>
                                                                                                    : component.ComponentName === "Bill Payments" && component.Active === true && this.props.role !== 'Prospect' ?
                                                                                                        <Link key={"Nav256" + navigatelink.Name}><DropdownItem onClick={this.toggleModal}>Bill Pay</DropdownItem></Link>
                                                                                                        : component.ComponentName === "Account Information" && component.Active === true && this.isRequired("IsAccountInfoRequired")?
                                                                                                            <Link key={"Nav20" + navigatelink.Name} to="AccountInfo" onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem >Account Information</DropdownItem></Link>
                                                                                                        : component.ComponentName === "Asset Aggregation" &&

                                                                                                            (component.Active === true)
                                                                                                            ?
                                                                                                            <Link key={"Nav221" + navigatelink.Name} to="AssetAggregation" onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem >Account Aggregation</DropdownItem></Link>
                                                                                                            : component.ComponentName === "Specialty Assets" && (component.Active === true)
                                                                                                                ? <Link key={"Nav2002" + navigatelink.Name} to="ManageAssets" onClick={this.handleSelections.bind(this, navigatelink)}><DropdownItem >Specialty Assets</DropdownItem></Link>
                                                                                                                : component.ComponentName === "Plan Manager" && this.isRequired("PlanManager")
                                                                                                                    && component.Active === true
                                                                                                                    && this.props.role !== 'Prospect'
                                                                                                                    ? <Link key={"Nav2006" + navigatelink.Name}><DropdownItem onClick={this.togglePlanManagerModal}>Plan Manager</DropdownItem></Link>
                                                                                                                    : ''
                                                    }
                                                    )}
                                            </DropdownMenu>
                                        </Dropdown>
                                        : (navigatelink.Name === 'Services' && !this.isRequired(navigatelink.Name))
                                            ? ''
                                            : <NavItem className={"RCM_Nav_wrapper RCM_Nav_wrapper_" + navigatelink.Name + ' ' + (navigatelink.active ? this.getClassName(this.state.isCustomView, navigatelink.Name) : ' ')} 
                                                        key={"navItem" + navigatelink.Name}>
                                                <Badge key='intro' onClick={this.toggleSteps} className="RCM_Badge_introJS">Overview*</Badge>
                                                <Link onClick={this.handleSelections.bind(this, navigatelink)} 
                                                    key={"Nav23" + navigatelink.Name} to={navigatelink.Name} 
                                                    className={" RCM_menu_text " + (navigatelink.active ? 'RCM_menu_active ' : ' ')}>
                                                        <div key={"Nav24" + navigatelink.Name} className={"RCM_menu_img RCM_Nav_" + navigatelink.Name}></div>
                                                        <div className={"RCM_Nav_displayNone_" + navigatelink.Name}></div>
                                                        <div key={"Nav25" + navigatelink.Name} className="RCM_nav_text">{navigatelink.DisplayName}</div>
                                                </Link>
                                            </NavItem>
                                       
                                }
                                )}
                        </Nav>
                    </Collapse>
                    {
                        this.props.role !== 'Prospect'
                            ?
                            <div className="RCM_nav_print RCM_right RCM_Home_PrintExport">

                                <span className="RCM_StickyNavBar_PrintExportWrapper  RCM_icon_hand" id="RCM_Refresh">
                                    <a className="RCM_Cursor_Pointer" onClick={this.refreshApplication.bind(this)} title="Refresh">
                                        <div className={"RCM_menu_img  RCM_userMenu_Refresh"}></div>
                                    </a>
                                </span>
                                <span className="RCM_StickyNavBar_PrintExportWrapper RCM_icon_hand">
                                    <a className="RCM_Cursor_Pointer" onClick={this.printCustomWindow} title="Print">
                                        <div className={"RCM_menu_img  RCM_userMenu_Print"}></div>
                                    </a>
                                </span>
                                <span className="RCM_StickyNavBar_PrintExportWrapper RCM_icon_hand">
                                    <a className="RCM_Cursor_Pointer" onClick={this.generateExcel} title="Export" >
                                        <div className={"RCM_menu_img  RCM_Download"}></div>
                                    </a>
                                </span>
                                {((this.props.location.pathname === "/" || this.props.location.pathname === "/Accounts") && this.state.toggleCustomizationIcon) ?
                                    <span className="RCM_StickyNavBar_PrintExportWrapper  RCM_icon_hand">
                                        <a className="RCM_Cursor_Pointer" onClick={this.updatePreferenceReducer} title="Customize">
                                            <div className={"RCM_menu_img  RCM_Customization_img"}></div>
                                        </a>
                                    </span> : ""}
                            </div>
                            :
                            <div className="RCM_nav_print RCM_right RCM_Prospect_Home_PrintExportBtns RCM_Home_PrintExport">
                                <span className="RCM_StickyNavBar_PrintExportWrapper  RCM_icon_hand" id="RCM_Refresh">
                                    <a className="RCM_Cursor_Pointer" onClick={() => { }} title="Refresh">
                                        <div className={"RCM_menu_img  RCM_userMenu_Refresh"}></div>
                                    </a>
                                </span>
                                <span className="RCM_StickyNavBar_PrintExportWrapper RCM_icon_hand">
                                    <a className="RCM_Cursor_Pointer" onClick={() => { }} title="Print">
                                        <div className={"RCM_menu_img  RCM_userMenu_Print"}></div>
                                    </a>
                                </span>
                                <span className="RCM_StickyNavBar_PrintExportWrapper RCM_icon_hand">
                                    <a className="RCM_Cursor_Pointer" onClick={() => { }} title="Export" >
                                        <div className={"RCM_menu_img  RCM_Download"}></div>
                                    </a>
                                </span>
                                {((this.props.location.pathname === "/" || this.props.location.pathname === "/Accounts") && this.state.toggleCustomizationIcon) ?
                                    <span className="RCM_StickyNavBar_PrintExportWrapper  RCM_icon_hand">
                                        <a className="RCM_Cursor_Pointer" onClick={() => { }} title="Customize">
                                            <div className={"RCM_menu_img  RCM_Customization_img"}></div>
                                        </a>
                                    </span> : ""}
                            </div>
                    }
                </Navbar>

                <BillPayConfirmationModal modalState={this.state.modalState} toggleModal={this.toggleModal}></BillPayConfirmationModal>
                <PlanManagerConfirmationModal planManagerModalState={this.state.planManagerModalState} togglePlanManagerModal={this.togglePlanManagerModal}></PlanManagerConfirmationModal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        acct: state.acct,
        personalistaionData: state.personalisationData,
        refreshTransfer: state.acct.refreshTransfer,
        topten: state.topten,
        doc: state.doc,
        preferences: state.acct.preferences,
        moneymovement: state.moneymovement,
        manageextacct: state.manageextacct,
        role: state.acct.role,
        selectedaccount: state.acct.selectedaccount
    };
};
const mapDispatchToProps = {
    StickyNavbar,
    UpdateAccount,
    toggleComponent
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StickyNavbar));