import React, { useState, useEffect } from "react";
import {
  getLoginBackGroundColor,
  getFiLogoImageUrl,
  lightOrDark,
} from "./ninthWaveUtility";
import NinthWaveLoginMFA from "./ninthWaveLoginMFA";
import cloneDeep from "lodash/cloneDeep";

function onLoginControlChange(event, params, loginCustomer) {
  if (params && params.signOnParamId) {
    //check if user has param id
    if (checkParamExists(loginCustomer, params.signOnParamId)) {
      //update the paramValue here
      for (let i = 0; i < loginCustomer.length; i++) {
        if (loginCustomer[i].signOnParamId === params.signOnParamId) {
          loginCustomer[i].paramValue = event.target.value;
          break;
        }
      }
    } else {
      //create a new object and push to object
      loginCustomer.push({
        signOnParamId: params.signOnParamId,
        paramName: params.paramName,
        paramValue: event.target.value,
      });
    }
  }
}

function checkParamExists(loginCustomer, id) {
  let isParamExist = false;

  if (
    loginCustomer &&
    Array.isArray(loginCustomer) &&
    loginCustomer.length > 0
  ) {
    for (let i = 0; i < loginCustomer.length; i++) {
      if (loginCustomer[i].signOnParamId === id) {
        isParamExist = true;
        break;
      }
    }
  }

  return isParamExist;
}

export default function NinthWaveLogin(props) {
  const logonStyle = {
    backgroundColor: getLoginBackGroundColor(props.fiLogin),
  };

  const fontStyle = {
    color:
      lightOrDark(getLoginBackGroundColor(props.fiLogin)) === "light"
        ? "#252f39"
        : "#fff",
  };

  let [selectedOption, setSelectedOption] = useState({
    signOnParamId: "",
    paramName: "",
    paramValue: "",
    selectedOptionText: "",
  });

  let loginCustomer = [];
  props.loginUserCred && props.loginUserCred.signOnInfoParams
    ? (loginCustomer = cloneDeep(props.loginUserCred.signOnInfoParams))
    : (loginCustomer = []);

  useEffect(() => {
    //Use effect with empty array will get called only once and will behave as componentdidmount instead of componentdidupdate.
    let firstOption = null;

    if (loginCustomer !== []) {
      //select the first option
      if (
        props.fiLogin &&
        props.fiLogin.signOnInfo &&
        props.fiLogin.signOnInfo.signOnParams &&
        Array.isArray(props.fiLogin.signOnInfo.signOnParams) &&
        props.fiLogin.signOnInfo.signOnParams.length > 0
      ) {
        let params = props.fiLogin.signOnInfo.signOnParams;
        for (let i = 0; i < params.length; i++) {
          if (
            params[i].isOptionGroup !== null &&
            params[i].isOptionGroup !== undefined &&
            params[i].isOptionGroup === true
          ) {
            firstOption = params[i];
            break;
          }
        }
      }
    }

    if (firstOption) {
      setSelectedOption({
        ...selectedOption,
        paramName: firstOption.paramName,
        selectedOptionText: firstOption.paramCaption,
        signOnParamId: firstOption.signOnParamId,
      });
    }
  }, []);

  const renderOptionsFields = (signonParams) => {
    let optionsCompArr = [];
    signonParams.map((params, index) => {
      if (
        params.isOptionGroup !== null &&
        params.isOptionGroup !== undefined &&
        params.isOptionGroup === true
      ) {
        optionsCompArr.push(
          <div className="mt-1" key={index}>
            <input
              type="radio"
              name={params.optionGroupName}
              id={params.paramName}
              value={params.paramCaption}
              className="RCM_Aggr_Login_Radio"
              checked={
                params.paramCaption === selectedOption.selectedOptionText
              }
              onChange={() => handleOptionSelection(params)}
            />
            <label htmlFor={params.paramName} style={fontStyle}>
              &nbsp;{params.paramCaption}
            </label>
          </div>
        );
      }
    });

    if (optionsCompArr.length > 0) {
      optionsCompArr.push(
        <div className="mt-1" key={optionsCompArr.length + 1}>
          <input
            type="text"
            className="RCM_form_control RCM_Aggr_Login_Controls"
            placeholder={selectedOption.selectedOptionText}
            value={selectedOption.paramValue}
            onChange={(e) => handleSelecedOptionValueChange(e)}
          />
        </div>
      );
    }

    return optionsCompArr;
  };

  const handleOptionSelection = (params) => {
    if (params) {
      setSelectedOption({
        ...selectedOption,
        signOnParamId: params.signOnParamId,
        paramName: params.paramName,
        selectedOptionText: params.paramCaption,
        paramValue: "",
      });
    }
  };

  const handleSelecedOptionValueChange = (event) => {
    setSelectedOption({ ...selectedOption, paramValue: event.target.value });
  };

  const goback = (props) => {
    loginCustomer = [];
    props.showFiList();
  };

  const renderLoginForm = (params, index) => {

    // return empty div if control is not required to be visible
    if(params.visible === false) {
       return <React.Fragment key={index}></React.Fragment>
    }

    if (
      params.isOptionGroup !== null &&
      params.isOptionGroup !== undefined &&
      params.isOptionGroup === true
    ) {
      //render select option and text box
      return <div key={index} style={{ display: "none" }}></div>;
    } else if (params.paramName === "OPTION" && params.select) {
      return (
        <div className="mt-1" key={index}>
          <select
            className="RCM_form_control RCM_Aggr_Login_Controls"
            onChange={(e) => onLoginControlChange(e, params, loginCustomer)}
          >
            <option
              value="default"
              className="RCM_Aggr_login_form_select"
              style={fontStyle}
              default
            >
              {
                params.paramCaption
              }
            </option>
            {params.options && params.options.map((option, index) => {
              return (
                <option
                  key={index}
                  value={option.optionValue}
                  style={fontStyle}
                >
                  {option.displayText}
                </option>
              );
            })}
          </select>
        </div>
      );
    } else if (params.paramName.toUpperCase().includes("PASSWORD")) {
      return (
        <div className="mt-1" key={index}>
          <input
            type="password"
            placeholder={params.paramCaption}
            className="RCM_form_control RCM_Aggr_Login_Controls"
            onChange={(e) => onLoginControlChange(e, params, loginCustomer)}
          />
        </div>
      );
    } else {
      return (
        <div className="mt-1" key={index}>
          <input
            type={getInputType(params)}
            placeholder={params.paramCaption}
            className="RCM_form_control RCM_Aggr_Login_Controls"
            onChange={(e) => onLoginControlChange(e, params, loginCustomer)}
          />
        </div>
      );
    }
  };

  const loginUserWithOption = () => {
    //Remove existing option values and new one
    removeExistingSelectedOption();

    if (selectedOption && selectedOption.signOnParamId !== "") {
      loginCustomer.push({
        signOnParamId: selectedOption.signOnParamId,
        paramName: selectedOption.paramName,
        paramValue: selectedOption.paramValue,
      });
    }

    props.loginUser(loginCustomer, props.fiLogin);
  };

  const removeExistingSelectedOption = () => {
    if (
      props.fiLogin &&
      props.fiLogin.signOnInfo &&
      props.fiLogin.signOnInfo.signOnParams &&
      Array.isArray(props.fiLogin.signOnInfo.signOnParams) &&
      props.fiLogin.signOnInfo.signOnParams.length > 0 &&
      loginCustomer !== null &&
      Array.isArray(loginCustomer) &&
      loginCustomer.length > 0
    ) {
      let params = props.fiLogin.signOnInfo.signOnParams;
      for (let i = 0; i < params.length; i++) {
        if (
          params[i].isOptionGroup !== null &&
          params[i].isOptionGroup !== undefined &&
          params[i].isOptionGroup === true
        ) {
          //delete this value if exist
          deleteFromLoginCustomer(params[i].signOnParamId, loginCustomer);
        }
      }
    }
  };

  const deleteFromLoginCustomer = (signOnParamId, loginCustomer) => {
    for (let i = 0; i < loginCustomer.length; i++) {
      if (
        signOnParamId &&
        loginCustomer[i] &&
        loginCustomer[i].signOnParamId &&
        loginCustomer[i].signOnParamId === signOnParamId
      ) {
        loginCustomer.splice(i, 1);
        break;
      }
    }
  };

  return (
    <div align="center" className="RCM_Aggr_Login_Container" style={logonStyle}>
      <div className="RCM_Aggr_Login_logo_wrap">
        {getFiLogoImageUrl(props.fiLogin.uiAssets) !== "" && (
          <img src={getFiLogoImageUrl(props.fiLogin.uiAssets)} alt=""/>
        )}
      </div>
      <h4 style={fontStyle}>
        {props && props.fiLogin && props.fiLogin.name ? props.fiLogin.name : ""}
      </h4>
      <h6 style={fontStyle}>PLEASE SIGN IN</h6>

      {props.errormsg && props.errormsg !== "" && (
        <div className="RCM_Aggr_error_container">{props.errormsg}</div>
      )}
      {props.showMfaScreen ? (
        <NinthWaveLoginMFA
          mfaChallanegs={props.mfaChallenges}
          fontStyle={fontStyle}
          handleExit={() => props.handleExit()}
          loginUserMfaChallenge={(mfaChallenges, mfaChallengesAnswers) =>
            props.loginUserMfaChallenge(
              mfaChallenges,
              mfaChallengesAnswers,
              props.fiLogin
            )
          }
          passwordResetFiid={props.passwordResetFiid}
        />
      ) : (
        <>
          <div className="mt-4" align="left">
            {props.fiLogin &&
              props.fiLogin.signOnInfo &&
              props.fiLogin.signOnInfo.signOnParams &&
              Array.isArray(props.fiLogin.signOnInfo.signOnParams) &&
              props.fiLogin.signOnInfo.signOnParams.length > 0 && (
                <>
                  {renderOptionsFields(props.fiLogin.signOnInfo.signOnParams)}

                  {props.fiLogin.signOnInfo.signOnParams.map(
                    (params, index) => {
                      return renderLoginForm(params, index);
                    }
                  )}
                </>
              )}

            <div className="mt-3" align="center">
              <button
                onClick={() => goback(props)}
                className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size RCM_overlapping_btn"
              >
                {
                  /*props.passwordResetFiid ? 'FI LIST' : */'GO BACK'
                } 
              </button>
              <button
                className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size RCM_overlapping_btn"
                onClick={() => loginUserWithOption()}
              >
                {
                  props.passwordResetFiid ? 'SUBMIT' : 'SIGN IN'
                }  
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function getInputType(params) {
  if (params && params.paramName) {
    const name = params.paramName;
    const caption = params.paramCaption;
    if (name.toUpperCase().includes("PASSWORD") || (caption && caption.toUpperCase() === 'PASSWORD')) {
      return "password";
    }

    switch (name.toUpperCase()) {
      case "LOGIN":
        return "text";
      case "USERPASS":
        return "password";
      case "PASSWORD":
        return "password";
        case "LDAPPWD":
          return "password";
      default:
        return "text";
    }
  } else {
    return "text";
  }
}
