import React, { Component } from 'react';
import { connect } from 'react-redux';
import Transfers from './Transfers.jsx'
import BillPay from '../billpay/BillPay.jsx';
import BillPayConfirmationModal from "./common/billpayconfirmationModal";
import ManageExternalAccounts from './ManageExternalAccounts/manageExternalAccounts.jsx';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import ErrorBoundary from '../../common/errorboundary.jsx';
import { tabSelection, tabSelectionOnLoad,GetPreferencePilotValue, GetPreferenceValue} from './../../common/constants.jsx';
import ProspectBillPay from './prospectBillPay.jsx'
import AccountInfo from './AccountInformation/accountInformation'
class MoneyMovement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalState: false,
            tabsLinks: this.getTabsLinks(),
               
            preferences: this.props.preferences,
            isCustomView: this.getPreferenceValue(),
        }
        this.handleSelections = this.handleSelections.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }
    getTabsLinks() {

        let defaultView = [
            { tabUrl: '/Transfers', active: true, displayName: 'MY TRANSFERS', id: 'RCM_transfertab' },
            { tabUrl: '/manageExtAccount', active: false, displayName: 'MANAGE ACCOUNTS', id: 'RCM_manageEXtAccounttab' },
            { tabUrl: this.props.role === "Prospect" ? '/ProspectBillPay' : '/BillPay', active: false, displayName: 'BILL PAYMENTS', id: 'RCM_billpaytab' }
        ]
        if (this.getPilotFlagValue('IsAccountInfoRequired')) {
            defaultView.push({ tabUrl: '/AccountInfo', active: false, displayName: 'ACCOUNT INFORMATION', id: 'RCM_AccountInfotab' });
        }
       
        return defaultView;
    }
    getPilotFlagValue(source) {
        let obj = {
            preferences: this.props.preferences
        }
        let isRequired = GetPreferencePilotValue(obj, source, "false") === "true" ? true : false;
        return isRequired;
    }
    handleSelections(param, e) {
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks });
        // if (param.displayName === 'BILL PAYMENTS') {
        //     this.toggleModal();
        // }
    }
    getPreferenceValue() {
        let obj = {
            preferences: this.props.preferences
        }
        let isViewPreferenceRequired = GetPreferencePilotValue(obj, "View Preference", "false") === "true" ? true : false;
        let val = GetPreferenceValue(obj, "ViewPreference", "Normal") === 'Normal' ? false : true;
        if (isViewPreferenceRequired && val)
            return true;
        else
            return false;
    }
    componentDidMount() {
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/Transfers', '/Transfer');
        this.setState({ tabsLinks: tabsLinks });
    }
    componentDidUpdate() {
        if (this.props.preferences && this.state.preferences) {
            if (this.props.preferences !== this.state.preferences) {
                let iscustomview = this.getPreferenceValue();
                this.setState({ isCustomView: iscustomview, preferences: this.props.preferences });
            }
        }


    }
    toggleModal() {
        this.setState(prevState => ({
            modalState: !prevState.modalState
        }));
    }

    render() {
        const transfersmenu = (
            <div>
                <div className={this.state.isCustomView ? "RCM_Tab_container" : "RCM_NavTab_container"}>
                    <ul className="nav nav-tabs">
                        {this.state.tabsLinks && this.state.tabsLinks.map((tabLink, index) => {
                            return (
                                this.props.role === 'prospect' ? (<li key={index} className="nav-item active" id={tabLink.id}>
                                    <NavLink className="nav-link" exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                        onClick={this.handleSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                                </li>) :
                                    (tabLink.tabUrl === "/BillPay") ?
                                        (<li key={index} className="nav-item" id={tabLink.id}><button className="btn btn-link nav-link RCM_Transfer_BillPay_btn" onClick={this.toggleModal}>{tabLink.displayName}</button></li>) :
                                        (<li key={index} className="nav-item active" id={tabLink.id}>
                                            <NavLink className="nav-link" exact to={tabLink.tabUrl} className={"nav-link " + (tabLink.active ? 'linkactive ' : ' ')}
                                                onClick={this.handleSelections.bind(this, tabLink)}>{tabLink.displayName}</NavLink>
                                        </li>))
                        })}
                    </ul>

                </div>
                <div>
                    <Switch>
                        <Route key='mmrt' exact path="/Transfer" render={() => <Redirect to="/Transfers" />} />
                        <Route key='mmtransfers' exact path="/Transfers" component={Transfers} />
                        <Route key='mmbillpay' exact path='/BillPay' component={BillPay} />
                        <Route key='mmbillpaytest' exact path='/BillPay' component={BillPay} />
                        <Route key="manageExtAcct" exact path='/manageExtAccount' component={ManageExternalAccounts} />
                        <Route key="prospectbillpay" exact path="/ProspectBillPay" component={ProspectBillPay} />
                        <Route key='mmaccountInfo' exact path='/AccountInfo' component={AccountInfo} />
                    </Switch>
                </div>
            </div>
        );

        return (
            <div className="RCM_sub_container">
                <ErrorBoundary>
                    <BillPayConfirmationModal modalState={this.state.modalState} toggleModal={this.toggleModal}></BillPayConfirmationModal>
                    <BrowserRouter>{transfersmenu}</BrowserRouter>
                </ErrorBoundary>
            </div>

        );
    }
}
const mapStateToProps = (store) => {
    return {
        preferences: store.acct.preferences
    };
}
export default withRouter(connect(mapStateToProps)(MoneyMovement));