import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import cloneDeep from 'lodash/cloneDeep';
import {
    formatMoneyForPE, MessageType, MessageClass, amountFormat, alphanumericnameFormat, yearFormat, basicAlphaNumeric, discriptionFormat, alphanumericFormat,
    getDateString, viewOnlyText
} from './../../../../../common/constants.jsx';
import orderBy from 'lodash/orderBy';
import Messages from '../../../../../common/messages.jsx';
import dateFormat from "dateformat";

class PrivateEquity extends Component {

    constructor(props) {
        super(props);

        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.dropdownChange = this.dropdownChange.bind(this);

        this.state = {
            activeTab: '1',
            displaytotalvalue: '',
            displayinvestmentcommitmentamount: '',
            displayinvestmentcapitalcontributionsamount: '',
            displayvaluationofinvestmentsamount: '',
            displaycontributionspendingvaluationsamount: '',
            displaydistributionsamount: '',
            displayrecallablecapitalreturnedamount: '',
            displaydistributionspendingvaluationsamount: '',
            intialAccount: {},
            messages: [],
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: "",
                    accountnumber: "",
                    nickname: "",
                    type: "Private Equity",
                    description: "",
                    name: '',
                    originator: null,
                    bankname: null,
                    issuername: null,
                    status: null,
                    volatility: "",
                    risk: "",
                    subassettype: ""
                },
                value: {
                    totalvalue: "",
                    valueasof: new Date(),
                    outstandingbalance: "",
                    sourceofvaluation: "",
                    pricecontact: "",
                    pricingcurrency: "",
                    pricingfrequency: "",
                    initialprice: "",
                    initialpricedate: new Date(),
                    sourceofinitialvaluation: "",
                    otherpricingfrequency: ''
                },
                privateequity: {
                    investmentvehiclename: '',
                    inceptionyear: '',
                    investmentcommitmentamount: '',
                    valueasof: new Date(),
                    investmentcapitalcontributions: '',
                    valuationofinvestments: '',
                    contributionspendingvaluations: '',
                    distributions: '',
                    recallablecapitalreturned: '',
                    distributionspendingvaluations: '',
                    transactions: []
                }
            },
            formStateChange: false,
            investmenttypes: [
                { displayname: 'Investment Capital Contribution', value: 'investmentcapitalcontributions' },
                { displayname: 'Contribution Pending Valuations', value: 'contributionspendingvaluations' },
                { displayname: 'Distribution', value: 'distributions' },
                { displayname: 'Distribution Pending Valuation', value: 'distributionspendingvaluations' },
                { displayname: 'Recallable Capital Returned', value: 'recallablecapitalreturned' },
                { displayname: 'Valuation of Investment', value: 'valuationofinvestments' },
                { displayname: 'Investment Commitment Amount', value: 'investmentcommitmentamount' },
            ],
            disabledFields: {
                investmentcapitalcontributions: false,
                contributionspendingvaluations: false,
                distributions: false,
                distributionspendingvaluations: false,
                recallablecapitalreturned: false,
                valuationofinvestments: false,
                investmentcommitmentamount: false
            },
            isPricingFrequencyOpen: false,
            typesOfPricingFrequency: ['Monthly', 'Quarterly', 'Semi – Annually', 'Annually', 'Other'],
            isVolatilityOpen: false,
            typesOfVolatilityRisk: ['High', 'Moderated', 'Low'],
            isRiskOpen: false,

            isTypesOfAssetsOpen: false,
            typesOfAssets: [' ', 'Private Equity / Direct Investments', 'Private Debt', 'Private Real Estate', 'Venture', 'Other']
        };

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
    }

    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var account = JSON.parse(JSON.stringify(this.props.account));
            let newTransaction = [];
            newTransaction = account.privateequity.transactions.map((transaction, transactionid) => {
                let displaytotalvalue = "";
                if (transaction.totalvalue !== undefined && transaction.totalvalue !== null && transaction.totalvalue !== "")
                    displaytotalvalue = parseFloat(transaction.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                return { ...transaction, iseditable: false, isDropdownOpen: false, displaytotalvalue: displaytotalvalue };
            });
            account.privateequity.transactions = newTransaction;
            var displaytotalvalue = "";
            var displayinvestmentcommitmentamount = '';
            var displayinvestmentcapitalcontributionsamount = '';
            var displayvaluationofinvestmentsamount = '';
            var displaycontributionspendingvaluationsamount = '';
            var displaydistributionsamount = '';
            var displayrecallablecapitalreturnedamount = '';
            var displaydistributionspendingvaluationsamount = '';
            if (account.value.totalvalue !== undefined && account.value.totalvalue !== null && account.value.totalvalue !== "")
                displaytotalvalue = parseFloat(account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            if (account.privateequity) {
                if (account.privateequity.investmentcommitmentamount !== undefined && account.privateequity.investmentcommitmentamount !== null && account.privateequity.investmentcommitmentamount !== "")
                    displayinvestmentcommitmentamount = parseFloat(account.privateequity.investmentcommitmentamount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (account.privateequity.investmentcapitalcontributions !== undefined && account.privateequity.investmentcapitalcontributions !== null && account.privateequity.investmentcapitalcontributions !== "")
                    displayinvestmentcapitalcontributionsamount = parseFloat(account.privateequity.investmentcapitalcontributions).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (account.privateequity.valuationofinvestments !== undefined && account.privateequity.valuationofinvestments !== null && account.privateequity.valuationofinvestments !== "")
                    displayvaluationofinvestmentsamount = parseFloat(account.privateequity.valuationofinvestments).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (account.privateequity.contributionspendingvaluations !== undefined && account.privateequity.contributionspendingvaluations !== null && account.privateequity.contributionspendingvaluations !== "")
                    displaycontributionspendingvaluationsamount = parseFloat(account.privateequity.contributionspendingvaluations).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (account.privateequity.distributions !== undefined && account.privateequity.distributions !== null && account.privateequity.distributions !== "")
                    displaydistributionsamount = parseFloat(account.privateequity.distributions).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (account.privateequity.recallablecapitalreturned !== undefined && account.privateequity.recallablecapitalreturned !== null && account.privateequity.recallablecapitalreturned !== "")
                    displayrecallablecapitalreturnedamount = parseFloat(account.privateequity.recallablecapitalreturned).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                if (account.privateequity.distributionspendingvaluations !== undefined && account.privateequity.distributionspendingvaluations !== null && account.privateequity.distributionspendingvaluations !== "")
                    displaydistributionspendingvaluationsamount = parseFloat(account.privateequity.distributionspendingvaluations).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            var initailAccount = JSON.parse(JSON.stringify(account));
            this.setState({
                account: account, intialAccount: initailAccount, displaytotalvalue: displaytotalvalue,
                displayinvestmentcommitmentamount: displayinvestmentcommitmentamount,
                displayinvestmentcapitalcontributionsamount: displayinvestmentcapitalcontributionsamount,
                displayvaluationofinvestmentsamount: displayvaluationofinvestmentsamount,
                displaycontributionspendingvaluationsamount: displaycontributionspendingvaluationsamount,
                displaydistributionsamount: displaydistributionsamount,
                displayrecallablecapitalreturnedamount: displayrecallablecapitalreturnedamount,
                displaydistributionspendingvaluationsamount: displaydistributionspendingvaluationsamount
            }, () => {
                this.setDisabledFields();
            });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        };

    }

    handleValueAsOfChange(evt, controlname) {
        let messages = [];
        let clonedState = Object.assign({}, this.state);
        clonedState.messages = messages;
        if (controlname === 'valueasof')
            clonedState.account.value.valueasof = evt;
        else if (controlname === 'pevalueasof') {
            if (clonedState.account.privateequity.transactions.length > 0) {
                let arr = clonedState.account.privateequity.transactions;
                arr = orderBy(arr, ['valueasof'], ['desc']);
                if (new Date(dateFormat(arr[0].valueasof, "mm/dd/yyyy")) > new Date(dateFormat(evt, "mm/dd/yyyy"))) {
                    messages.push({
                        type: MessageType.ERROR,
                        text: 'The Date you entered is prior to the last activity in Transactions',
                        class: MessageClass.ERROR
                    })
                    clonedState.messages = messages;
                } else {
                    clonedState.account.privateequity.valueasof = evt;
                }
            }
            else {
                clonedState.account.privateequity.valueasof = evt;
            }
        }

        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    handleFormChanges(evt) {

        let clonedState = Object.assign({}, this.state);
        clonedState.messages = [];
        //if (evt.target.name === "custodianname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
        //    clonedState.account.info.custodianname = evt.target.value;
        //}
        //else 
        if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        }
        else if (evt.target.name === "name" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.name = evt.target.value;
        }
        else if (evt.target.name === "accountnumber" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            clonedState.account.info.accountnumber = evt.target.value;
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaytotalvalue = evt.target.value;
        }
        else if (evt.target.name === "investmentcommitmentamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displayinvestmentcommitmentamount = evt.target.value;
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;
        }
        else if (evt.target.name === "investmentvehiclename") {
            if (evt.target.value === "" || alphanumericFormat.test(evt.target.value) && evt.target.value.length <= 50) {
                clonedState.account.privateequity.investmentvehiclename = evt.target.value;
            }
        }
        else if (evt.target.name === "investmentcapitalcontributionsamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displayinvestmentcapitalcontributionsamount = evt.target.value;
        }
        else if (evt.target.name === "valuationofinvestmentsamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displayvaluationofinvestmentsamount = evt.target.value;
        }
        else if (evt.target.name === "contributionspendingvaluationsamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaycontributionspendingvaluationsamount = evt.target.value;
        }
        else if (evt.target.name === "distributionsamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaydistributionsamount = evt.target.value;
        }
        else if (evt.target.name === "recallablecapitalreturnedamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displayrecallablecapitalreturnedamount = evt.target.value;
        }
        else if (evt.target.name === "distributionspendingvaluationsamount" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaydistributionspendingvaluationsamount = evt.target.value;
        }
        else if (evt.target.name === "inceptionyear" && (evt.target.value === "" || yearFormat.test(evt.target.value)) && evt.target.value.length <= 4) {
            clonedState.account.privateequity.inceptionyear = evt.target.value;
        } else if (evt.target.name === "pricecontact" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.pricecontact = evt.target.value;
        } else if (evt.target.name === "sourceofvaluation" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.sourceofvaluation = evt.target.value;
        } else if (evt.target.name === "pricingcurrency" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.pricingcurrency = evt.target.value;
        } else if (evt.target.name === "otherpricingfrequency" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.otherpricingfrequency = evt.target.value;
        }
        this.setState(clonedState);
        this.checkStateChange();
    }


    getUpdatedTransaction(clonedState, transType, value, transText) {

        let clonedTransactions = cloneDeep(clonedState.account.privateequity.transactions)
        let isFound = false;
        for (let i = 0; i < clonedTransactions.length; i++) {
            if (clonedTransactions[i].transactiontype === transType) {
                clonedTransactions[i].totalvalue = value;
                if (value === "") {
                    value = 0;
                }
                clonedTransactions[i].displaytotalvalue = parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                isFound = true;
                break;
            }
        }

        if (!isFound) {
            clonedTransactions.push({ transactiontype: transType, displayname: transText, totalvalue: value, valueasof: clonedState.account.privateequity.valueasof, iseditable: false, isDropdownOpen: false, isInitial: true })
        }

        return [...clonedTransactions]

    }


    handleAmountFormatting(evt) {
        const zeroFormat = /^[0]+$/;
        if (evt.target.value !== "" && !(zeroFormat.test(evt.target.value))) {
            let clonedState = Object.assign({}, this.state);
            clonedState.messages = [];
            if (evt.target.name === "totalvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "investmentcommitmentamount") {

                clonedState.account.privateequity.investmentcommitmentamount = evt.target.value;
                clonedState.displayinvestmentcommitmentamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'investmentcommitmentamount', evt.target.value, 'Investment Commitment Amount');
            }
            else if (evt.target.name === "investmentcapitalcontributionsamount") {
                clonedState.account.privateequity.investmentcapitalcontributions = evt.target.value;
                clonedState.displayinvestmentcapitalcontributionsamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'investmentcapitalcontributions', evt.target.value, 'Investment Capital Contribution');
            }
            else if (evt.target.name === "valuationofinvestmentsamount") {
                clonedState.account.privateequity.valuationofinvestments = evt.target.value;
                clonedState.displayvaluationofinvestmentsamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'valuationofinvestments', evt.target.value, 'Valuation of Investment');
            }
            else if (evt.target.name === "contributionspendingvaluationsamount") {
                clonedState.account.privateequity.contributionspendingvaluations = evt.target.value;
                clonedState.displaycontributionspendingvaluationsamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'contributionspendingvaluations', evt.target.value, 'Contribution pending valuations');
            }
            else if (evt.target.name === "distributionsamount") {
                clonedState.account.privateequity.distributions = evt.target.value;
                clonedState.displaydistributionsamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'distributions', evt.target.value, 'Distribution');
            }
            else if (evt.target.name === "recallablecapitalreturnedamount") {
                clonedState.account.privateequity.recallablecapitalreturned = evt.target.value;
                clonedState.displayrecallablecapitalreturnedamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'recallablecapitalreturned', evt.target.value, 'Recallable Capital returned');
            }
            else if (evt.target.name === "distributionspendingvaluationsamount") {
                clonedState.account.privateequity.distributionspendingvaluations = evt.target.value;
                clonedState.displaydistributionspendingvaluationsamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                clonedState.account.privateequity.transactions = this.getUpdatedTransaction(clonedState, 'distributionspendingvaluations', evt.target.value, 'Distribution pending Valuation');
            }
            this.setState(clonedState, () => {
                this.setDisabledFields();
            });
        } else {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "totalvalue") {
                clonedState.account.value.totalvalue = evt.target.value;
            } else if (evt.target.name === "investmentcommitmentamount") {
                clonedState.account.privateequity.investmentcommitmentamount = "";
                clonedState.displayinvestmentcommitmentamount = "";
            }
            else if (evt.target.name === "investmentcapitalcontributionsamount") {
                clonedState.account.privateequity.investmentcapitalcontributions = "";
                clonedState.displayinvestmentcapitalcontributionsamount = "";
            }
            else if (evt.target.name === "valuationofinvestmentsamount") {
                clonedState.account.privateequity.valuationofinvestments = "";
                clonedState.displayvaluationofinvestmentsamount = "";
            }
            else if (evt.target.name === "contributionspendingvaluationsamount") {
                clonedState.account.privateequity.contributionspendingvaluations = "";
                clonedState.displaycontributionspendingvaluationsamount = "";
            }
            else if (evt.target.name === "distributionsamount") {
                clonedState.account.privateequity.distributions = "";
                clonedState.displaydistributionsamount = "";
            }
            else if (evt.target.name === "recallablecapitalreturnedamount") {
                clonedState.account.privateequity.recallablecapitalreturned = "";
                clonedState.displayrecallablecapitalreturnedamount = "";
            }
            else if (evt.target.name === "distributionspendingvaluationsamount") {
                clonedState.account.privateequity.distributionspendingvaluations = "";
                clonedState.displaydistributionspendingvaluationsamount = "";
            }
            this.setState(clonedState, () => {
                this.setDisabledFields();
            });
        }
        this.checkStateChange();
    }
    setDisabledFields = () => {
        let clonedState = Object.assign({}, this.state);
        clonedState.disabledFields = {
            investmentcapitalcontributions: false,
            contributionspendingvaluations: false,
            distributions: false,
            distributionspendingvaluations: false,
            recallablecapitalreturned: false,
            valuationofinvestments: false,
            investmentcommitmentamount: false
        };
        let counters = {
            investmentcapitalcontributionsCounter: 0,
            investmentcommitmentamountCounter: 0,
            contributionspendingvaluationsCounter: 0,
            distributionsCounter: 0,
            distributionspendingvaluationsCounter: 0,
            recallablecapitalreturnedCounter: 0,
            valuationofinvestmentsCounter: 0,
        };
        for (const [index, transaction] of this.state.account.privateequity.transactions.entries()) {

            if (transaction.transactiontype === 'investmentcapitalcontributions') {
                counters.investmentcapitalcontributionsCounter = counters.investmentcapitalcontributionsCounter + 1;
            } else if (transaction.transactiontype === 'investmentcommitmentamount') {
                counters.investmentcommitmentamountCounter = counters.investmentcommitmentamountCounter + 1;
            } else if (transaction.transactiontype === 'contributionspendingvaluations') {
                counters.contributionspendingvaluationsCounter = counters.contributionspendingvaluationsCounter + 1;
            } else if (transaction.transactiontype === 'distributions') {
                counters.distributionsCounter = counters.distributionsCounter + 1;
            } else if (transaction.transactiontype === 'distributionspendingvaluations') {
                counters.distributionspendingvaluationsCounter = counters.distributionspendingvaluationsCounter + 1;
            } else if (transaction.transactiontype === 'recallablecapitalreturned') {
                counters.recallablecapitalreturnedCounter = counters.recallablecapitalreturnedCounter + 1;
            } else if (transaction.transactiontype === 'valuationofinvestments') {
                counters.valuationofinvestmentsCounter = counters.valuationofinvestmentsCounter + 1;
            }

        }
        if (counters.investmentcapitalcontributionsCounter > 1) {
            clonedState.disabledFields.investmentcapitalcontributions = true;
        }
        if (counters.investmentcommitmentamountCounter > 1) {
            clonedState.disabledFields.investmentcommitmentamount = true;
        }
        if (counters.contributionspendingvaluationsCounter > 1) {
            clonedState.disabledFields.contributionspendingvaluations = true;
        }
        if (counters.distributionsCounter > 1) {
            clonedState.disabledFields.distributions = true;
        }
        if (counters.distributionspendingvaluationsCounter > 1) {
            clonedState.disabledFields.distributionspendingvaluations = true;
        }
        if (counters.recallablecapitalreturnedCounter > 1) {
            clonedState.disabledFields.recallablecapitalreturned = true;
        }
        if (counters.valuationofinvestmentsCounter > 1) {
            clonedState.disabledFields.valuationofinvestments = true;
        }
        this.setState(clonedState);
    }

    handleAmmountOnFocus(evt) {
        const clonedState = Object.assign({}, this.state);
        clonedState.messages = [];
        if (evt.target.name === "totalvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displaytotalvalue = ""
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
            }
        } else if (evt.target.name === "investmentcommitmentamount") {
            if (clonedState.account.privateequity.investmentcommitmentamount === "") {
                clonedState.account.privateequity.investmentcommitmentamount = "";
                clonedState.displayinvestmentcommitmentamount = "";
            } else {
                clonedState.displayinvestmentcommitmentamount = clonedState.account.privateequity.investmentcommitmentamount;
            }

        }
        else if (evt.target.name === "investmentcapitalcontributionsamount") {
            if (clonedState.account.privateequity.investmentcapitalcontributions === "") {
                clonedState.account.privateequity.investmentcapitalcontributions = "";
                clonedState.displayinvestmentcapitalcontributionsamount = "";
            } else
                clonedState.displayinvestmentcapitalcontributionsamount = clonedState.account.privateequity.investmentcapitalcontributions;
        }
        else if (evt.target.name === "valuationofinvestmentsamount") {
            if (clonedState.account.privateequity.valuationofinvestments === "") {
                clonedState.account.privateequity.valuationofinvestments = "";
                clonedState.displayvaluationofinvestmentsamount = "";
            } else
                clonedState.displayvaluationofinvestmentsamount = clonedState.account.privateequity.valuationofinvestments;
        }
        else if (evt.target.name === "contributionspendingvaluationsamount") {
            if (clonedState.account.privateequity.contributionspendingvaluations === "") {
                clonedState.account.privateequity.contributionspendingvaluations = "";
                clonedState.displaycontributionspendingvaluationsamount = "";
            } else
                clonedState.displaycontributionspendingvaluationsamount = clonedState.account.privateequity.contributionspendingvaluations;
        }
        else if (evt.target.name === "distributionsamount") {
            if (clonedState.account.privateequity.distributions === "") {
                clonedState.account.privateequity.distributions = "";
                clonedState.displaydistributionsamount = "";
            } else
                clonedState.displaydistributionsamount = clonedState.account.privateequity.distributions
        }
        else if (evt.target.name === "recallablecapitalreturnedamount") {
            if (clonedState.account.privateequity.recallablecapitalreturned === "") {
                clonedState.account.privateequity.recallablecapitalreturned = "";
                clonedState.displayrecallablecapitalreturnedamount = "";
            } else
                clonedState.displayrecallablecapitalreturnedamount = clonedState.account.privateequity.recallablecapitalreturned;
        }
        else if (evt.target.name === "distributionspendingvaluationsamount") {
            if (clonedState.account.privateequity.distributionspendingvaluations === "") {
                clonedState.account.privateequity.distributionspendingvaluations = "";
                clonedState.displaydistributionspendingvaluationsamount = "";
            } else
                clonedState.displaydistributionspendingvaluationsamount = clonedState.account.privateequity.distributionspendingvaluations;
        }
        this.setState(clonedState);
    }

    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }

    handleTransactionChange(id, value, source) {
        let clonedState = Object.assign({}, this.state);
        clonedState.messages = [];
        let newTransaction = {};
        if (source === 'investmentDropdownChange') {
            newTransaction = clonedState.account.privateequity.transactions.map((transaction, transactionid) => {
                if (id !== transactionid) return transaction;
                return { ...transaction, transactiontype: value.value, displayname: value.displayname };
            });
        } else if (source === 'valueasofChange') {
            newTransaction = clonedState.account.privateequity.transactions.map((transaction, transactionid) => {
                if (id !== transactionid) return transaction;
                return { ...transaction, valueasof: value };
            });
        } else if (source === 'investmentDropdownToggle') {
            newTransaction = clonedState.account.privateequity.transactions.map((transaction, transactionid) => {
                if (id !== transactionid) return transaction;
                return { ...transaction, isDropdownOpen: !transaction.isDropdownOpen };
            });
        } else if (source === 'totalValueChange') {
            newTransaction = clonedState.account.privateequity.transactions.map((transaction, transactionid) => {
                const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/;
                if (id !== transactionid) return transaction;
                if (value.target.value === "" || amountFormat.test(value.target.value) && value.target.value.length <= 12) {
                    if (value.target.value === "")
                        return { ...transaction, displaytotalvalue: 0 };
                    return { ...transaction, displaytotalvalue: value.target.value };
                }
                return transaction;
            });
        } else if (source === 'totalValueChangeBlur') {
            newTransaction = clonedState.account.privateequity.transactions.map((transaction, transactionid) => {
                const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/;
                if (id !== transactionid) return transaction;
                if (value.target.value === "" || amountFormat.test(value.target.value) && value.target.value.length <= 12) {
                    if (value.target.value === "")
                        return {
                            ...transaction, totalvalue: 0,
                            displaytotalvalue: parseFloat(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                        };
                    return {
                        ...transaction, totalvalue: value.target.value,
                        displaytotalvalue: parseFloat(value.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                    };
                }
                return transaction;
            });

        } else if (source === 'totalValueChangeFocus') {
            newTransaction = clonedState.account.privateequity.transactions.map((transaction, transactionid) => {
                const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/;
                if (id !== transactionid) return transaction;
                if (transaction.totalvalue === "")
                    return {
                        ...transaction, totalvalue: "",
                        displaytotalvalue: ""
                    };
                else
                    return {
                        ...transaction, displaytotalvalue: transaction.totalvalue
                    };

            });
        }

        clonedState.account.privateequity.transactions = newTransaction;
        if (clonedState.account.privateequity.transactions.length > 0) {
            let arr = clonedState.account.privateequity.transactions;
            arr = orderBy(arr, ['valueasof'], ['desc']);
            if (new Date(dateFormat(arr[0].valueasof, "mm/dd/yyyy")) > new Date(dateFormat(clonedState.account.privateequity.valueasof, "mm/dd/yyyy"))) {
                clonedState.account.privateequity.valueasof = arr[0].valueasof;
            }
        }
        this.setState(clonedState, () => {
            if (source === 'investmentDropdownChange' || source === 'totalValueChangeBlur') {
                this.calculateTotalValues();
            }
            this.checkStateChange();
        });

    }
    calculateTotalValues = () => {

        let clonedState = Object.assign({}, this.state);
        clonedState.messages = [];
        let totalinvestmentcommitmentamount = 0;
        let totalInvestmentCapitalContributions = 0;
        let totalValuationOfInvestments = 0;
        let totalContributionsPendingValuations = 0;
        let totalDistributions = 0;
        let totalDistributionsPendingValuations = 0;
        let totalRecallableCapitalReturned = 0;

        for (const [index, transaction] of this.state.account.privateequity.transactions.entries()) {
            let value = transaction.totalvalue === '' ? 0 : parseFloat(transaction.totalvalue.replace(/[^0-9.+-]/g, ''));

            if (transaction.transactiontype === 'investmentcapitalcontributions') {
                totalInvestmentCapitalContributions = totalInvestmentCapitalContributions + value;
            } else if (transaction.transactiontype === 'investmentcommitmentamount') {
                totalinvestmentcommitmentamount = totalinvestmentcommitmentamount + value;
            } else if (transaction.transactiontype === 'contributionspendingvaluations') {
                totalContributionsPendingValuations = totalContributionsPendingValuations + value;
            } else if (transaction.transactiontype === 'distributions') {
                totalDistributions = totalDistributions + value;
            } else if (transaction.transactiontype === 'distributionspendingvaluations') {
                totalDistributionsPendingValuations = totalDistributionsPendingValuations + value;
            } else if (transaction.transactiontype === 'recallablecapitalreturned') {
                totalRecallableCapitalReturned = totalRecallableCapitalReturned + value;
            } else if (transaction.transactiontype === 'valuationofinvestments') {
                totalValuationOfInvestments = totalValuationOfInvestments + value;
            }

        }

        clonedState.account.privateequity.investmentcapitalcontributions = totalInvestmentCapitalContributions;
        clonedState.account.privateequity.investmentcommitmentamount = totalinvestmentcommitmentamount;
        clonedState.account.privateequity.valuationofinvestments = totalValuationOfInvestments;
        clonedState.account.privateequity.contributionspendingvaluations = totalContributionsPendingValuations;
        clonedState.account.privateequity.distributions = totalDistributions;
        clonedState.account.privateequity.distributionspendingvaluations = totalDistributionsPendingValuations;
        clonedState.account.privateequity.recallablecapitalreturned = totalRecallableCapitalReturned;

        //change display value on page 2
        clonedState.displayinvestmentcapitalcontributionsamount = formatMoneyForPE(totalInvestmentCapitalContributions);
        clonedState.displayinvestmentcommitmentamount = formatMoneyForPE(totalinvestmentcommitmentamount);
        clonedState.displayvaluationofinvestmentsamount = formatMoneyForPE(totalValuationOfInvestments);
        clonedState.displaycontributionspendingvaluationsamount = formatMoneyForPE(totalContributionsPendingValuations)
        clonedState.displaydistributionsamount = formatMoneyForPE(totalDistributions);
        clonedState.displaydistributionspendingvaluationsamount = formatMoneyForPE(totalDistributionsPendingValuations);
        clonedState.displayrecallablecapitalreturnedamount = formatMoneyForPE(totalRecallableCapitalReturned);

        this.setState(clonedState, () => {
            this.setDisabledFields();
        });
    }

    handleTransactionAddDelete = (source, id = 0) => {

        let arr = cloneDeep(this.state.account.privateequity.transactions);
        let clonedState = Object.assign({}, this.state);
        clonedState.messages = [];
        if (source === 'add') {
            arr.unshift({
                transactiontype: "", displayname: '', totalvalue: '', valueasof: new Date(), iseditable: true, isDropdownOpen: false, displaytotalvalue: '', isInitial: false
            });
            clonedState.account.privateequity.transactions = arr;
            this.setState(clonedState);
        } else if (source === 'delete') {

            let newarr = arr.filter((b, bid) => id !== bid);
            clonedState.account.privateequity.transactions = newarr;
            this.setState(clonedState, () => {
                this.calculateTotalValues();
            });
        }

    };


    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }
    dropdownToggle = source => {
        if (source === 'volatility') {
            this.setState(prevState => ({
                isVolatilityOpen: !prevState.isVolatilityOpen
            }))
        } else if (source === 'pricingfrequency') {
            this.setState(prevState => ({
                isPricingFrequencyOpen: !prevState.isPricingFrequencyOpen
            }))
        } else if (source === 'risk') {
            this.setState(prevState => ({
                isRiskOpen: !prevState.isRiskOpen
            }))
        } else if (source === 'typeofasset') {
            this.setState(prevState => ({
                isTypesOfAssetsOpen: !prevState.isTypesOfAssetsOpen
            }))
        }
    }
    dropdownChange(source, statevalue) {
        let clonedState = Object.assign({}, this.state);
        if (source === 'pricingfrequency') {
            if (clonedState.account.value.pricingfrequency === "Other" && statevalue !== "Other") {
                clonedState.account.value.otherpricingfrequency = "";
            }
            clonedState.account.value.pricingfrequency = statevalue;
        } else if (source === 'volatility') {
            clonedState.account.info.volatility = statevalue;
        } else if (source === 'risk') {
            clonedState.account.info.risk = statevalue;
        } else if (source === 'typeofasset') {
            clonedState.account.info.subassettype = statevalue;
        }
        this.setState(clonedState);
        this.checkStateChange();
    }
    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_PrivateEquity_form">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                        >
                            STEP 1 : BASIC INFORMATION
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('2'); }} id="RCM_investment_info"
                        >
                            STEP 2 : INVESTMENT INFORMATION
                     </NavLink>

                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' }, { linkactive: this.state.activeTab === '3' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('3'); }} id="RCM_transactions_info"
                        >
                            STEP 3 : TRANSACTIONS
                     </NavLink>

                    </NavItem>



                </Nav>
                <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                    <TabPane tabId="1">

                        <div>

                            <Form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_assetClass">
                                                <Label for="type">Asset Class</Label>
                                                <Dropdown name="type" required
                                                    isOpen={this.state.isTypesOfAssetsOpen}
                                                    toggle={() => this.dropdownToggle("typeofasset")}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.info.subassettype}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.typesOfAssets.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.dropdownChange('typeofasset', item)}>
                                                                        <div>{item}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridAccount" id="RCM_accountNumber">
                                                <Label for="accountnumber">Account Number</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="accountnumber"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.accountnumber}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_name">
                                                <Label for="name">Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="name"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.name}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                                <Label for="nickname">Nickname</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="nickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.nickname}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div >
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                                <Label for="totalvalue">Total Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="totalvalue"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displaytotalvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="valueasof" id="valueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    onChange={(evt) => { this.handleValueAsOfChange(evt, 'valueasof') }}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_sourceofvaluation">
                                                <Label for="sourceofvaluation">Source of Valuation</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="sourceofvaluation"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.sourceofvaluation}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="PriceContact">Price Contact</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="pricecontact"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.pricecontact}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div >
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="type">Pricing Frequency</Label>
                                                <Dropdown name="type" required
                                                    isOpen={this.state.isPricingFrequencyOpen}
                                                    toggle={() => this.dropdownToggle("pricingfrequency")}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.value.pricingfrequency}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.typesOfPricingFrequency.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.dropdownChange('pricingfrequency', item)}>
                                                                        <div>{item}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        {this.state.account.value.pricingfrequency === 'Other'
                                            ? <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="otherpricingfrequency">Specify Other Pricing Frequency</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="otherpricingfrequency"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.otherpricingfrequency}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            : ''
                                        }
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="pricingcurrency">Pricing Currency</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="pricingcurrency"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.value.pricingcurrency}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="volatility">Volatility </Label>
                                                <Dropdown name="volatility" required
                                                    isOpen={this.state.isVolatilityOpen}
                                                    toggle={() => this.dropdownToggle("volatility")}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.info.volatility}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.typesOfVolatilityRisk.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.dropdownChange('volatility', item)}>
                                                                        <div>{item}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="risk">Risk </Label>
                                                <Dropdown name="risk" required
                                                    isOpen={this.state.isRiskOpen}
                                                    toggle={() => this.dropdownToggle("risk")}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    <DropdownToggle caret>{this.state.account.info.risk}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            <div>
                                                                {this.state.typesOfVolatilityRisk.map((item, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.dropdownChange('risk', item)}>
                                                                        <div>{item}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="description"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.info.description}
                                                onChange={this.handleFormChanges}
                                                required
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                        >
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                    </div>
                                </div>

                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">

                        <div>
                            {
                                this.state.messages.length > 0 ?
                                    <div className="row" >
                                        <div className="col-sm-7">
                                            <Messages messages={this.state.messages} containerClass="RCM_validation_error_container" />
                                        </div>
                                    </div>
                                    : ''
                            }
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridInvestmentVehicleName">
                                                <Label for="investmentvehiclename">Investment Vehicle Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="investmentvehiclename"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.privateequity.investmentvehiclename}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3 col-xs-6 col-md-2 col-lg-2">
                                            <FormGroup controlId="formGridTotal Value">
                                                <div><Label for="inceptionyear">Inception Year</Label></div>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="yyyy"
                                                    name="inceptionyear"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.privateequity.inceptionyear}
                                                    onChange={this.handleFormChanges}

                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-3 col-xs-6 col-md-2 col-lg-2">
                                            <FormGroup controlId="formGridTotal Value">
                                                <div><Label for="valueasof">Value as of</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="pevalueasof" id="pevalueasof"
                                                    value={new Date(this.state.account.privateequity.valueasof)}
                                                    onChange={(evt) => { this.handleValueAsOfChange(evt, 'pevalueasof') }}
                                                    selected={(this.state.account.privateequity.valueasof !== undefined && this.state.account.privateequity.valueasof !== null
                                                        && this.state.account.privateequity.valueasof !== '') ? new Date(this.state.account.privateequity.valueasof) : ''}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <Label for="investmentcommitmentamount">Investment Commitment Amount
                                            {this.state.disabledFields.investmentcommitmentamount ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="investmentcommitmentamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displayinvestmentcommitmentamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.investmentcommitmentamount}
                                                />
                                            </FormGroup>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="forminvestmentcapitalcontributions Value">
                                                <Label for="investmentcapitalcontributions">Investment Capital Contributions
                                                {this.state.disabledFields.investmentcapitalcontributions ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="investmentcapitalcontributionsamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displayinvestmentcapitalcontributionsamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.investmentcapitalcontributions}

                                                />
                                            </FormGroup>

                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formvaluationofinvestments Value">
                                                <Label for="valuationofinvestments">Valuation of Investments
                                                {this.state.disabledFields.valuationofinvestments ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="valuationofinvestmentsamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displayvaluationofinvestmentsamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.valuationofinvestments}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formcontributionspendingvaluations Value">
                                                <Label for="contributionspendingvaluations">Contributions Pending Valuations
                                                {this.state.disabledFields.contributionspendingvaluations ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="contributionspendingvaluationsamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displaycontributionspendingvaluationsamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.contributionspendingvaluations}
                                                />
                                            </FormGroup>

                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formdistributions Value">
                                                <Label for="distributions">Distributions
                                                {this.state.disabledFields.distributions ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="distributionsamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displaydistributionsamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.distributions}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formrecallablecapitalreturned Value">
                                                <Label for="recallablecapitalreturned">Recallable Capital Returned
                                                {this.state.disabledFields.recallablecapitalreturned ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="recallablecapitalreturnedamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displayrecallablecapitalreturnedamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.recallablecapitalreturned}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formdistributionspendingvaluations Value">
                                                <Label for="distributionspendingvaluations">Distributions Pending Valuations
                                                {this.state.disabledFields.distributionspendingvaluations ? <span>&nbsp;*</span> : ''}</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="distributionspendingvaluationsamount"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.displaydistributionspendingvaluationsamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.disabledFields.distributionspendingvaluations}
                                                />
                                            </FormGroup>

                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>NEXT</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <label className="RCM_Customization_text RCM_Customization_text_small_font"><span>*&nbsp;&nbsp;</span> These values can be updated in Transactions tab.</label>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div>
                            <Form>

                                <div className="row">
                                    <div className="col-sm-8 col-md-8 col-lg-8">
                                        <table style={{ width: '100%' }} className="table RCM_two_level_table1">
                                            <thead>
                                                <tr>
                                                    <th>Investment Type</th>
                                                    <th>Amount</th>
                                                    <th>Value as of</th>
                                                    <th className="RCM_PE_addnew_link_wrapper">
                                                        <div align="right" className="RCM_icon_hand  RCM_manage_grp_new_group" id="RCM_addAsset_link"
                                                            onClick={() => this.handleTransactionAddDelete('add')}
                                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                            + Add New
                                                            </div>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.account.privateequity.transactions.map((transaction, id) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        transaction.iseditable ?
                                                                            (
                                                                                <Dropdown name="investmentType" required
                                                                                    isOpen={transaction.isDropdownOpen}
                                                                                    toggle={() => this.handleTransactionChange(id, '', 'investmentDropdownToggle')}
                                                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                                                    <DropdownToggle caret>{transaction.displayname}</DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        {this.state.investmenttypes.map((item, index) =>
                                                                                            <DropdownItem key={index} onClick={() => this.handleTransactionChange(id, item, 'investmentDropdownChange')}>
                                                                                                <div>{item.displayname}</div>
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                    </DropdownMenu>
                                                                                </Dropdown>
                                                                            )
                                                                            : transaction.displayname
                                                                    }
                                                                </td>
                                                                <td>{transaction.iseditable ?
                                                                    (<input type="text"
                                                                        className="RCM_form_control"
                                                                        value={transaction.displaytotalvalue}
                                                                        onChange={(evt) => this.handleTransactionChange(id, evt, 'totalValueChange')}
                                                                        placeholder="$"
                                                                        onBlur={(evt) => this.handleTransactionChange(id, evt, 'totalValueChangeBlur')}
                                                                        onFocus={(evt) => this.handleTransactionChange(id, evt, 'totalValueChangeFocus')}
                                                                    />)

                                                                    : formatMoneyForPE(transaction.totalvalue)}
                                                                </td>
                                                                <td>{transaction.iseditable ?
                                                                    (
                                                                        <DatePicker required
                                                                            className="RCM_externalasset_Datepicker RCM_form_control" required
                                                                            name="transDate" id="transDate"
                                                                            onChange={(value) => this.handleTransactionChange(id, value, 'valueasofChange')}
                                                                            value={new Date(transaction.valueasof)}
                                                                            selected={(transaction.valueasof !== undefined && transaction.valueasof !== null
                                                                                && transaction.valueasof !== '') ? new Date(transaction.valueasof) : ''}
                                                                            placeholderText="Click to select a date"
                                                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                                        />
                                                                    )
                                                                    : getDateString(transaction.valueasof)}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        <div className="RCM_manage_grp_delete_icon"
                                                                            title="Remove Transaction"
                                                                            onClick={() => this.handleTransactionAddDelete('delete', id)}
                                                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                                        >
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>

                                                        )

                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Investment Commitment Amount</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.investmentcommitmentamount)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Investment Capital Contributions</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.investmentcapitalcontributions)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Valuation of Investments</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.valuationofinvestments)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Recallable Capital Returned</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.recallablecapitalreturned)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Contributions Pending Valuations</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.contributionspendingvaluations)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Distributions</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.distributions)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label>Distributions Pending Valuations</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        className="RCM_form_control"
                                                        value={formatMoneyForPE(this.state.account.privateequity.distributionspendingvaluations)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>PREVIOUS</button>
                                    </div>
                                </div>

                            </Form>

                        </div>

                    </TabPane>
                    {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                        ?
                        < div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                {viewOnlyText}
                            </div>
                        </div>
                        : ""
                    }
                </TabContent>

            </div>)
    }
}
function mapStateToProps(store) {
    return {
        accounts: store.acct.accounts,
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateEquity);