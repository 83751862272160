import React from "react";
import {
  getFiLogoImageUrl,
  getLoginBackGroundColor,
  lightOrDark,
  getUserAggrAcctsList,
} from "./ninthWaveUtility";

export default function NinthWaveAcctList(props) {
  const logonStyle = {
    backgroundColor: getLoginBackGroundColor(props.fiLogin),
  };

  const fontStyle = {
    color:
      lightOrDark(getLoginBackGroundColor(props.fiLogin)) === "light"
        ? "#252f39"
        : "#fff",
  };

  return (
    <div align="center" className="RCM_Aggr_Login_Container" style={logonStyle}>
      <div className="RCM_Aggr_Login_logo_wrap">
        <img src={getFiLogoImageUrl(props.fiLogin.uiAssets)} alt=""/>
      </div>
      <h4 style={fontStyle}>
        {props && props.fiLogin && props.fiLogin.name ? props.fiLogin.name : ""}
      </h4>
      <h6 style={fontStyle}>PLEASE SELECT ONE OR MORE ACCOUNTS TO CONNECT.</h6>

      {props.errormsg && props.errormsg !== "" && (
        <div className="RCM_Aggr_error_container">{props.errormsg}</div>
      )}

      <div className="mt-4">
        {props.fiAccounts ? (
          <div>
            <div className="RCM_Aggr_Acct_Container">
              {getUserAggrAcctsList(props.fiAccounts).map((acct, index) => {
                return (
                  <div key={index} className="RCM_Aggr_Acct_List">
                    <label className="RCM_selectLabel">
                      <input
                        type="checkbox"
                        onChange={(e) => props.handleAccountSelection(e)}
                        value={acct && acct.key ? acct.key : ""}
                        style={{ left: 0, position: "relative" }}
                        checked={props.selectedAcctKeys ? props.selectedAcctKeys.includes(acct.key) : false}
                      />
                      <div>
                        <p style={fontStyle}>
                          {acct && acct.nickname ? acct.nickname : ""}
                        </p>
                      </div>
                      <div align="left">
                        <p
                          style={fontStyle}
                          title={
                            acct && acct.fullAccountNumber
                              ? acct.fullAccountNumber
                              : ""
                          }
                        >
                          {acct && acct.number ? acct.number : ""}
                        </p>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="mt-3" align="center">
              <button
                onClick={() => props.handleExit()}
                className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size RCM_overlapping_btn"
              >
                EXIT
              </button>
              <button
                className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size RCM_overlapping_btn"
                onClick={() => props.enrollUser()}
                disabled={props.selectedAcctKeys ? 
                  props.selectedAcctKeys.length > 0 ? false : true
                  : true}
              >
                ENROLL
              </button>
            </div>
          </div>
        ) : (
          <div>No account present</div>
        )}
      </div>
    </div>
  );
}
