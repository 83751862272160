import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { amountFormat, alphanumericnameFormat, basicAlphaNumeric, discriptionFormat, interestRateFormat, viewOnlyText } from '../../../../../common/constants.jsx';
import classnames from 'classnames';
class PromissoryNote extends Component {

    constructor(props) {
        super(props);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);


        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            displaytotalvalue: '',
            displayredemptionvalue: '',
            isInterestAccrualFrequencyOpen: false,
            typesOfInterestAccrualFrequency: ['No Interest', 'Interest at Maturity', 'Annually', 'Semi Annually', 'Triannually', 'Quarterly', 'Bimonthly', 'Monthly', 'Biweekly', 'Weekly'],
            isAccrualBasisOpen: false,
            typesOfAccrualBasis: ['30/360', '30/365', '30/ACT', 'ACT/360', 'ACT/365', 'ACT/ACT'],
            isAmortizationOpen: false,
            typesOfAmortization: ['Default', 'Customized'],
            activeTab: '1',
            intialAccount: {},
            formStateChange: false,
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    custodianname: "",
                    accountnumber: "",
                    nickname: "",
                    type: "Promissory Note",
                    description: "",
                    name: null,
                    originator: null,
                    bankname: null,
                    issuername: null,
                    status: null,
                    volatility: "",
                    risk: "",
                    subassettype: ""
                },
                value: {
                    totalvalue: "",
                    valueasof: new Date(),
                    outstandingbalance: "",
                    sourceofvaluation: "",
                    pricecontact: "",
                    pricingcurrency: "",
                    pricingfrequency: "",
                    initialprice: "",
                    initialpricedate: new Date(),
                    sourceofinitialvaluation: "",
                    otherpricingfrequency: ''
                },
                promissorynote: {
                    couponrate: '',
                    maturitydate: new Date(),
                    incomeaccruing: false,
                    firstcoupondate: new Date(),
                    lastcoupondate: new Date(),
                    nextcoupondate: new Date(),
                    commitmentamount: '',
                    industry: '',
                    assetclass: '',
                    liquidationterms: '',
                    redemptionvalue: '',
                    interestaccrualfrequency: '',
                    accrualbasis: '',
                    dateddate: new Date(),
                    amortization: '',
                }
            }

        };

    }
    componentDidMount() {
        if (this.props.mode === 'edit' && this.props.account) {
            var displayvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displayredemptionvalue = "";
            if (this.props.account.promissorynote.redemptionvalue !== undefined && this.props.account.promissorynote.redemptionvalue !== null && this.props.account.promissorynote.redemptionvalue !== "")
                displayredemptionvalue = parseFloat(this.props.account.promissorynote.redemptionvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({
                account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displayvalue,
                displayredemptionvalue: displayredemptionvalue
            });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    componentDidUpdate(prevProps) {

        if (prevProps.source !== this.props.source) {
            if (this.props.mode === 'edit' && this.props.account) {
                var displayvalue = "";
                if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                    displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var displayredemptionvalue = "";
                if (this.props.account.promissorynote.redemptionvalue !== undefined && this.props.account.promissorynote.redemptionvalue !== null && this.props.account.promissorynote.redemptionvalue !== "")
                    displayredemptionvalue = parseFloat(this.props.account.promissorynote.redemptionvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                var initailAccount = JSON.parse(JSON.stringify(this.props.account));
                this.setState({
                    account: this.props.account, intialAccount: initailAccount, displaytotalvalue: displayvalue,
                    displayredemptionvalue: displayredemptionvalue
                });
            } else if (this.props.mode === 'add') {
                var initailAccount = JSON.parse(JSON.stringify(this.state.account));
                this.setState({ intialAccount: initailAccount });
            }
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    dropdownToggle = source => {

        if (source === 'interestaccrualfrequency') {
            this.setState(prevState => ({
                isInterestAccrualFrequencyOpen: !prevState.isInterestAccrualFrequencyOpen
            }))
        } else if (source === 'accrualbasis') {
            this.setState(prevState => ({
                isAccrualBasisOpen: !prevState.isAccrualBasisOpen
            }))
        } else if (source === 'amortization') {
            this.setState(prevState => ({
                isAmortizationOpen: !prevState.isAmortizationOpen
            }))
        }

    }
    dropdownChange(source, statevalue) {
        let clonedState = Object.assign({}, this.state);

        if (source === 'interestaccrualfrequency') {
            clonedState.account.promissorynote.interestaccrualfrequency = statevalue;
        } else if (source === 'accrualbasis') {
            clonedState.account.promissorynote.accrualbasis = statevalue;
        } else if (source === 'amortization') {
            clonedState.account.promissorynote.amortization = statevalue;
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    handleFormChanges(evt) {
        let clonedState = Object.assign({}, this.state);

        if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.info.nickname = evt.target.value;
        }
        else if (evt.target.name === "accountnumber" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            clonedState.account.info.accountnumber = evt.target.value;
        }
        else if (evt.target.name === "totalvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displaytotalvalue = evt.target.value;
        }
        else if (evt.target.name === "description" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;

        } else if (evt.target.name === "industry" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.promissorynote.industry = evt.target.value;
        } else if (evt.target.name === "assetclass" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.promissorynote.assetclass = evt.target.value;
        } else if (evt.target.name === "pricecontact" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.value.pricecontact = evt.target.value;
        } else if (evt.target.name === "liquidationterms" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {
            clonedState.account.promissorynote.liquidationterms = evt.target.value;
        } else if (evt.target.name === "couponrate") {
            if (evt.target.value === "" || interestRateFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.promissorynote.couponrate = evt.target.value;
        } else if (evt.target.name === "redemptionvalue" && (evt.target.value === "" || amountFormat.test(evt.target.value)) && evt.target.value.length <= 12) {
            clonedState.displayredemptionvalue = evt.target.value;
        } else if (evt.target.name === "incomeaccruing") {
            if (evt.target.value === 'yes') {
                clonedState.account.promissorynote.incomeaccruing = true;
            } else {
                clonedState.account.promissorynote.incomeaccruing = false;
            }

        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    handleAmountFormatting(evt) {
        let clonedState = Object.assign({}, this.state);
        if (evt.target.name === "totalvalue") {
            if (evt.target.value !== "") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displaytotalvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                clonedState.account.value.totalvalue = evt.target.value;
            }
        } else if (evt.target.name === "redemptionvalue") {
            if (evt.target.value !== "") {
                clonedState.account.promissorynote.redemptionvalue = evt.target.value;
                clonedState.displayredemptionvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            } else {
                clonedState.account.promissorynote.redemptionvalue = evt.target.value;
            }
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }


    handleAmmountOnFocus(evt) {
        const clonedState = Object.assign({}, this.state);
        if (evt.target.name === "totalvalue") {
            if (clonedState.account.value.totalvalue === "") {
                clonedState.displaytotalvalue = "";
                clonedState.account.value.totalvalue = "";
            } else {
                clonedState.displaytotalvalue = clonedState.account.value.totalvalue;
            }
        } else if (evt.target.name === "redemptionvalue") {
            if (clonedState.account.promissorynote.redemptionvalue === "") {
                clonedState.displayredemptionvalue = "";
                clonedState.account.promissorynote.redemptionvalue = "";
            } else {
                clonedState.displayredemptionvalue = clonedState.account.promissorynote.redemptionvalue;
            }
        }
        this.setState(clonedState);
    }
    handleDateChange = (value, source) => {
        let clonedState = Object.assign({}, this.state);
        if (source === 'valueasof') {
            clonedState.account.value.valueasof = value;
        } else if (source === 'maturitydate') {
            clonedState.account.promissorynote.maturitydate = value;
        } else if (source === 'dateddate') {
            clonedState.account.promissorynote.dateddate = value;
        } else if (source === 'firstcoupondate') {
            clonedState.account.promissorynote.firstcoupondate = value;
        } else if (source === 'lastcoupondate') {
            clonedState.account.promissorynote.lastcoupondate = value;
        } else if (source === 'nextcoupondate') {
            clonedState.account.promissorynote.nextcoupondate = value;
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //call profile api and send response 
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.intialAccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }

    render() {
        return (
            this.props.mode && this.props.mode !== "view" ?

                <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_promissorynote_form">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                                onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                            >
                                BASIC INFORMATION
                         </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                                onClick={() => { this.toggle('2'); }} id="RCM_note_info"
                            >
                                NOTE INFORMATION
                            </NavLink>
                        </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">
                        <TabPane tabId="1">
                            <div>

                                <Form>
                                    <fieldset>
                                        <div className="row">

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridAccount" id="RCM_accountNumber">
                                                    <Label for="accountnumber">Account Number</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="accountnumber"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.accountnumber}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridNickname" id="RCM_nickname">
                                                    <Label for="nickname">Nickname</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="nickname"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.info.nickname}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_totalvalue">
                                                    <Label for="totalvalue">Total Value</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        name="totalvalue"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.displaytotalvalue}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                    <div><Label for="valueasof">Value as of (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="valueasof" id="valueasof"
                                                        value={new Date(this.state.account.value.valueasof)}
                                                        onChange={(value) => this.handleDateChange(value, 'valueasof')}
                                                        selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                            && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value" id="RCM_sourceofvaluation">
                                                    <Label for="custodianname">Pricing Source</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="pricecontact"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.value.pricecontact}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                                <div><Label for="desc" >Description</Label></div>
                                                <textarea
                                                    name="description"
                                                    placeholder=""
                                                    className="RCM_form_control RCM_Feedback_TextArea"
                                                    value={this.state.account.info.description}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="row">

                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                            <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                            <button type="button"
                                                className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                onClick={this.saveAsset}
                                            >
                                                SUBMIT
                                            </button>
                                            <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </TabPane>
                        <TabPane tabId="2">
                            <div>

                                <Form>
                                    <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                        <div className="row">

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="industry">Industry</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="industry"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.promissorynote.industry}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridAccount">
                                                    <Label for="assetclass">Asset Class</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="assetclass"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.promissorynote.assetclass}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridNickname">
                                                    <Label for="liquidationterms">Liquidation Terms</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="liquidationterms"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.promissorynote.liquidationterms}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridNickname">
                                                    <Label for="couponrate">Coupon Rate</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="%"
                                                        name="couponrate"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.account.promissorynote.couponrate}
                                                        onChange={this.handleFormChanges}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="redemptionvalue">Redemption Value</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="$"
                                                        name="redemptionvalue"
                                                        className="RCM_form_control"
                                                        required
                                                        value={this.state.displayredemptionvalue}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                    />
                                                </FormGroup>
                                            </div>


                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="valueasof">Maturity Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="maturitydate" id="maturitydate"
                                                        value={new Date(this.state.account.promissorynote.maturitydate)}
                                                        onChange={(value) => this.handleDateChange(value, 'maturitydate')}
                                                        selected={(this.state.account.promissorynote.maturitydate !== undefined && this.state.account.promissorynote.maturitydate !== null
                                                            && this.state.account.promissorynote.maturitydate !== '') ? new Date(this.state.account.promissorynote.maturitydate) : ''}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <div>
                                                        <label className="RCM_selectLabel">
                                                            Income Accruing
                                                    </label>
                                                    </div>
                                                    <div className="RCM_Taxable_radio_wrapper">
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio" value="yes" checked={this.state.account.promissorynote.incomeaccruing === true} name="incomeaccruing" onChange={this.handleFormChanges} />
                                                                <span className="RCM_radio_span_assets">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio" value="no" name="incomeaccruing" checked={this.state.account.promissorynote.incomeaccruing === false} onChange={this.handleFormChanges} />
                                                                <span className="RCM_radio_span_assets">No</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="interestaccrualfrequency">Interest Accrual Frequency</Label>
                                                    <Dropdown name="interestaccrualfrequency" required
                                                        isOpen={this.state.isInterestAccrualFrequencyOpen}
                                                        toggle={() => this.dropdownToggle("interestaccrualfrequency")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                        <DropdownToggle caret>{this.state.account.promissorynote.interestaccrualfrequency}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfInterestAccrualFrequency.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('interestaccrualfrequency', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="accrualbasis">Accrual Basis</Label>
                                                    <Dropdown name="accrualbasis" required
                                                        isOpen={this.state.isAccrualBasisOpen}
                                                        toggle={() => this.dropdownToggle("accrualbasis")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                        <DropdownToggle caret>{this.state.account.promissorynote.accrualbasis}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfAccrualBasis.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('accrualbasis', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="dateddate">Dated Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="dateddate" id="dateddate"
                                                        value={new Date(this.state.account.promissorynote.dateddate)}
                                                        onChange={(value) => this.handleDateChange(value, 'dateddate')}
                                                        selected={(this.state.account.promissorynote.dateddate !== undefined && this.state.account.promissorynote.dateddate !== null
                                                            && this.state.account.promissorynote.dateddate !== '') ? new Date(this.state.account.promissorynote.dateddate) : ''}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="firstcoupondate">First Coupon Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="firstcoupondate" id="firstcoupondate"
                                                        value={new Date(this.state.account.promissorynote.firstcoupondate)}
                                                        onChange={(value) => this.handleDateChange(value, 'firstcoupondate')}
                                                        selected={(this.state.account.promissorynote.firstcoupondate !== undefined && this.state.account.promissorynote.firstcoupondate !== null
                                                            && this.state.account.promissorynote.firstcoupondate !== '') ? new Date(this.state.account.promissorynote.firstcoupondate) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="lastcoupondate">Last Coupon Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="lastcoupondate" id="lastcoupondate"
                                                        value={new Date(this.state.account.promissorynote.lastcoupondate)}
                                                        onChange={(value) => this.handleDateChange(value, 'lastcoupondate')}
                                                        selected={(this.state.account.promissorynote.lastcoupondate !== undefined && this.state.account.promissorynote.lastcoupondate !== null
                                                            && this.state.account.promissorynote.lastcoupondate !== '') ? new Date(this.state.account.promissorynote.lastcoupondate) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <div><Label for="nextcoupondate">Next Coupon Date (mm/dd/yyyy)</Label></div>
                                                    <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                        name="nextcoupondate" id="nextcoupondate"
                                                        value={new Date(this.state.account.promissorynote.nextcoupondate)}
                                                        onChange={(value) => this.handleDateChange(value, 'nextcoupondate')}
                                                        selected={(this.state.account.promissorynote.nextcoupondate !== undefined && this.state.account.promissorynote.nextcoupondate !== null
                                                            && this.state.account.promissorynote.nextcoupondate !== '') ? new Date(this.state.account.promissorynote.nextcoupondate) : ''}
                                                        maxDate={new Date()}
                                                        placeholderText="Click to select a date"
                                                        disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="amortization">Amortization</Label>
                                                    <Dropdown name="amortization" required
                                                        isOpen={this.state.isAmortizationOpen}
                                                        toggle={() => this.dropdownToggle("amortization")}
                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                        <DropdownToggle caret>{this.state.account.promissorynote.amortization}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                <div>
                                                                    {this.state.typesOfAmortization.map((item, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.dropdownChange('amortization', item)}>
                                                                            <div>{item}</div>
                                                                        </DropdownItem>
                                                                    )}
                                                                </div>
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                            <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                            <button type="button"
                                                className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                onClick={this.saveAsset}
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                SUBMIT
                                            </button>
                                            <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </TabPane>
                        {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                            ?
                            < div className="row">
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                    {viewOnlyText}
                                </div>
                            </div>
                            : ""
                        }
                    </TabContent>
                </div>
                :
                <>
                </>
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(PromissoryNote);