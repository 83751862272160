import React from 'react';
import { MsgForNoData } from '../../../../common/constants.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormGroup } from 'reactstrap';

export function MADeleteModalPopup(props) {

    let madeletemodalpopup = (
        <div>
            {props && props.state ?
                <Modal isOpen={props.state.openDeletePopup} toggle={props.toggleDeleteModal} 
                    className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                        zIndex='9999'
                        centered >
                        <ModalHeader toggle={props.toggleDeleteModal} className="RCM_externalAssetModel_header RCM_manage_group_header">
                           
                                <label className="RCM_tile_heading">REMOVE ACCOUNT</label>
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                            <div><label >Are you sure you want to delete this account?</label></div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label >Account No:&nbsp;&nbsp;
                                    {props.state.assetToDelete && props.state.assetToDelete.account && props.state.assetToDelete.account.info && props.state.assetToDelete.account.info.accountnumber
                                            ? props.state.assetToDelete.account.info.accountnumber : ''}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label >Custodian Name:&nbsp;&nbsp;
                                    {props.state.assetToDelete && props.state.assetToDelete.account && props.state.assetToDelete.account.info && props.state.assetToDelete.account.info.custodianname
                                            ? props.state.assetToDelete.account.info.custodianname : ''}</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6"><label >Nickname:&nbsp;&nbsp;
                                {props.state.assetToDelete && props.state.assetToDelete.account && props.state.assetToDelete.account.info && props.state.assetToDelete.account.info.nickname
                                        ? props.state.assetToDelete.account.info.nickname:''}</label></div>
                                <div className="col-sm-6"><label >Type:&nbsp;&nbsp;
                                {props.state.assetToDelete && props.state.assetToDelete.account && props.state.assetToDelete.account.info && props.state.assetToDelete.account.info.type
                                        ? props.state.assetToDelete.account.info.type : ''}</label></div>
                            </div>
                            {
                                (props.state.assetToDelete && props.state.assetToDelete.account 
                                && props.state.assetToDelete.account.realestate && props.state.assetToDelete.account.realestate !== null
                                && props.state.assetToDelete.account.realestate.mortgageinformation && props.state.assetToDelete.account.realestate.mortgageinformation !== null
                                && props.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey 
                                && props.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey !== null
                                && props.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey !== '')
                                    ?
                                    <div className="row RCM_deleteLinkedAccount_option">
                                        <div className="col-sm-12">
                                            <label className="RCM_selectLabel">
                                                <input
                                                    type="checkbox"
                                                    checked={props.state.deleteLinkedAccount}
                                                    className="RCM_select_checkbox"
                                                    type="checkbox"
                                                    onChange={() => props.handleDeleteLinkedAccount()}
                                                />
                                                <span>There is a linked mortgage account to this asset, do you would to delete mortgage account as well?</span>
                                            </label>
                                        </div>
                                    </div>
                                :((props.state.assetToDelete && props.state.assetToDelete.account 
                                    && props.state.assetToDelete.account.mortgage && props.state.assetToDelete.account.mortgage !== null
                                    && props.state.assetToDelete.account.mortgage.realestateaccountkey && props.state.assetToDelete.account.mortgage.realestateaccountkey !== null
                                    && props.state.assetToDelete.account.mortgage.realestateaccountkey !== '')
                                        ? <div className="row RCM_deleteLinkedAccount_option">
                                            <div className="col-sm-12">
                                                <label className="RCM_selectLabel">
                                                    <input
                                                        type="checkbox"
                                                        checked={props.state.deleteLinkedAccount}
                                                        className="RCM_select_checkbox"
                                                        type="checkbox"
                                                        onChange={() => props.handleDeleteLinkedAccount()}
                                                    />
                                                    <span>There is a linked real estate asset to this account, do you would to delete asset as well?</span>
                                        </label>
                                    </div>
                                            </div>

                              : '')
                            }
                            
                        </FormGroup>
                        </ModalBody>
                    <ModalFooter className="RCM_manage_group_footer">
                        <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.toggleDeleteModal}>CANCEL</button> 
                        <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.deleteAsset}>DELETE</button> 
                        </ModalFooter>
                    </Modal>
            
                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return madeletemodalpopup
}



