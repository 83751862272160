import { genericErrorMsg, thirdPartyErrorMsg } from "../common/constants.js";
import { getAcctInfosJson } from "../models/AccountInfo.js";
import { validateABAUrl, getSIUrl, validateSIUrl, submitSI, uploadToBpm, getFirstPartyEligibleAcctUrl, getThirdPartyEligibleAcctUrl } from "../../../common/constants.jsx";
import { Get, Post, PostMultiPart } from "../../../common/servicecalls.jsx";
import { getValidateExternalAcct, cancelExternalAccountJson } from "../models/ExternalAcctInfo.js";
import axios from "axios";
import { _logException } from "../../../common/_logging.js";
import { getNestedObject } from "../common/utilty.js";

export var getStandingInstructionsCancellationToken = null;
export var cancelStandingInstructionCancellationToken = null;
export var validateStandingInstructionCancellationToken = null;
export var validateABACancellationToken = null;
export var submitStandingInstructionCancellationToken = null;
export var uploadFileToBPMCancellationToken = null;
export var getFirstPartyEligibleAcctCancellationToken = null;
export var getThirdPartyEligibleAcctCancellationToken = null;

export const validateABA = (abaNumber, token, contextJson, profileJson) => {

    if (validateABACancellationToken !== null && validateABACancellationToken !== undefined) {
        validateABACancellationToken.cancel("Cancel existing validate ABA request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    validateABACancellationToken = cancelToken.source();



    let validateABAPromise = new Promise(
        function (resolve, reject) {
            let url = validateABAUrl.replace(/ABANumber/g, abaNumber);
            Get(url, token, contextJson, profileJson, validateABACancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage external account :: validate ABA", res);
                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {
                    let abaResponse = res.data.value[0];
                    if (abaResponse.status !== null
                        && abaResponse.status !== undefined
                        && abaResponse.status.Code !== null
                        && abaResponse.status.Code !== undefined
                        && abaResponse.status.Code === "000000") {
                        resolve(res.data.value[0]);
                    } else {
                        reject(genericErrorMsg);
                    }

                } else {
                    console.log("Manage External Account Service Non Zero : Validate ABA :: ");
                    reject(genericErrorMsg);
                }

            })
                .catch((error) => {
                    if (axios.isCancel()) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure.
                        console.error("Manage External Account Service : Validate ABA :: ", error);
                        _logException("Manage External Account Service : Validate ABA :: "+ (error && error.response) ? error.response : "");
                        reject(genericErrorMsg);
                    }
                });

        });
    return validateABAPromise;
};

export const getStandingInstructions = (accounts, requesterId, token, contextJson, profileJson) => {

    if (getStandingInstructionsCancellationToken !== null && getStandingInstructionsCancellationToken !== undefined) {
        getStandingInstructionsCancellationToken.cancel("Cancel existing standing instruction request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getStandingInstructionsCancellationToken = cancelToken.source();


    let standingInstructionsPromise = new Promise(
        function (resolve, reject) {
            if (accounts !== null
                && accounts !== undefined
                && requesterId !== null
                && requesterId !== undefined) {
                //Call eligible pair service 
                let url = getSIUrl;
                let accountsJson = getAcctInfosJson(accounts, requesterId);
                let SI = [];
                Post(url, accountsJson, token, contextJson, profileJson, getStandingInstructionsCancellationToken).then(res => {
                    // get the response in case of successful call. 
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {
                        console.log("Standing Instructions Response ::", res);
                        let standingInstructionsRes = res.data.value[0];
                        //check the status here
                        if (standingInstructionsRes.StandingInstructions !== null
                            && standingInstructionsRes.StandingInstructions !== undefined
                            && Array.isArray(standingInstructionsRes.StandingInstructions)
                            && standingInstructionsRes.StandingInstructions.length > 0) {
                            SI = standingInstructionsRes.StandingInstructions

                        }
                        resolve(SI);

                    } else {
                        reject(genericErrorMsg);
                    }
                })
                    .catch((error) => {

                        if (axios.isCancel(error)) {
                            console.log(error);
                        } else {
                            //Log the error on console incase of failure.
                            console.error("Money Movment Serice : Get SI :: ", error);
                            _logException("Money Movment Serice : Get SI :: "+ (error && error.response) ? error.response : "" );
                            reject(genericErrorMsg);
                        }

                    });
            }
            else {
                console.log("External Account Service :: Get SI :: Invalid Input");
                _logException("External Account Service :: Get SI :: Invalid Input");
                reject(genericErrorMsg);
            }
        });

    return standingInstructionsPromise;
}

export const validateStandingInstruction = (objStandingInstruction, token, contextJson, profileJson) => {

    if (validateStandingInstructionCancellationToken !== null && validateStandingInstructionCancellationToken !== undefined) {
        validateStandingInstructionCancellationToken.cancel("Cancel existing cancel standing instruction request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    validateStandingInstructionCancellationToken = cancelToken.source();


    let validateSIPromise = new Promise(
        function (resolve, reject) {
            let url = validateSIUrl;
            let validateSIJson = getValidateExternalAcct(objStandingInstruction);
            Post(url, validateSIJson, token, contextJson, profileJson, validateStandingInstructionCancellationToken)
                .then(res => {
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {
                        let response = res.data.value[0];
                        if (response.status !== null
                            && response.status !== undefined
                            && response.status.Code !== null
                            && response.status.Code !== undefined) {
                                if(response.status.Code === "0")
                                {
                                    resolve(response);
                                }else{
                                    (getNestedObject(response, ['status', 'Errors', 0, 'ErrorMsg'])) ? 
                                    reject(getNestedObject(response, ['status', 'Errors', 0, 'ErrorMsg'])): 
                                    reject(genericErrorMsg);
                                }
                            
                        } else {
                            console.log("External account service: Validate Standing Instructions : No success response");
                            reject(genericErrorMsg);
                        }
                    }
                    else {
                        console.log("External account service: Validate Standing Instructions : ", res);
                        reject(genericErrorMsg);
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("External account service: Validate Standing Instructions : ", error)
                        _logException("External account service: Validate Standing Instructions : "+ (error && error.response) ? error.response : "");
                        reject(genericErrorMsg);
                    }
                })
        });

    return validateSIPromise;
}

export const submitStandingInstruction = (objStandingInstruction, token, contextJson, profileJson) => {

    if (submitStandingInstructionCancellationToken !== null && submitStandingInstructionCancellationToken !== undefined) {
        submitStandingInstructionCancellationToken.cancel("Cancel existing Submit SI request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    submitStandingInstructionCancellationToken = cancelToken.source();


    let submitSIPromise = new Promise(
        function (resolve, reject) {
            let url = submitSI;
            let submitSIJson = getValidateExternalAcct(objStandingInstruction);
            Post(url, submitSIJson, token, contextJson, profileJson, submitStandingInstructionCancellationToken)
                .then(res => {
                    if (res.data !== null
                        && res.data != undefined
                        && res.data.value !== null
                        && res.data.value !== undefined
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {
                        let response = res.data.value[0];
                        if (response.status !== null
                            && response.status !== undefined
                            && response.status.Code !== null
                            && response.status.Code !== undefined && response.status.Code === "0") {
                            resolve(response);
                        } else {
                            console.log("External account service: Submit Standing Instructions : No success response");
                            reject(genericErrorMsg);
                        }
                    }
                    else {
                        console.log("External account service: Submit Standing Instructions : ", res);
                        reject(genericErrorMsg);
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("External account service: Submit Standing Instructions : ", error);
                        _logException("External account service: Submit Standing Instructions : " + (error && error.response) ? error.response :"");
                        reject(genericErrorMsg);
                    }
                })
        });

    return submitSIPromise;
}

export const cancelStandingInstruction = (objStandingInstruction, token, contextJson, profileJson) => {
    if (cancelStandingInstructionCancellationToken !== null && cancelStandingInstructionCancellationToken !== undefined) {
        cancelStandingInstructionCancellationToken.cancel("Cancel existing cancel standing instruction request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    cancelStandingInstructionCancellationToken = cancelToken.source();

    let cancelSIPromise = new Promise(
        function (resolve, reject) {
            if (objStandingInstruction !== null && objStandingInstruction !== undefined) {
                let url = submitSI;
                let cancelPaylod = cancelExternalAccountJson(objStandingInstruction);

                Post(url, cancelPaylod, token, contextJson, profileJson, cancelStandingInstructionCancellationToken)
                    .then(res => {
                        console.log("External Account Service :: Cancel SI ", res);
                        if (res.data !== null
                            && res.data != undefined
                            && res.data.value !== null
                            && res.data.value !== undefined
                            && Array.isArray(res.data.value)
                            && res.data.value.length > 0) {
                            let response = res.data.value[0];
                            if (response.status !== null
                                && response.status !== undefined
                                && response.status.Code !== null
                                && response.status.Code !== undefined && response.status.Code === "0") {
                                resolve(response);
                            } else {
                                console.log("External account service: Cancel Standing Instructions : No success response");
                                reject(genericErrorMsg);
                            }
                        }
                        else {
                            //console.log("External account service: Cancel Standing Instructions : ", res);
                            reject(genericErrorMsg);
                        }
                    }).catch(error => {
                        if (axios.isCancel()) {
                            console.log(error);
                        } else {
                            console.error("External Account Service :: Cancel SI ::", error);
                            _logException("External Account Service :: Cancel SI ::"+ (error && error.response) ? error.response : "");
                            reject(genericErrorMsg);
                        }
                    })
            } else {
                console.log("External Account Service :: Cancel SI :: Invalid Input");
                _logException("External Account Service :: Cancel SI :: Invalid Input");
                reject(genericErrorMsg);
            }

        });

    return cancelSIPromise;
}

export const uploadFileToBPM = (sirequest, token, contextJson, profileJson) => {
    //upload file into binary format
    if (uploadFileToBPMCancellationToken !== null && uploadFileToBPMCancellationToken !== undefined) {
        uploadFileToBPMCancellationToken.cancel("Cancel existing upload document to BPM to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    uploadFileToBPMCancellationToken = cancelToken.source();

    let uploadDocumentPromise = new Promise(
        function (resolve, reject) {
            let url = uploadToBpm;
            //let objRequest = generateUploadToBPMRequest(sirequest);
            PostMultiPart(url, sirequest, token, contextJson, profileJson, uploadFileToBPMCancellationToken)
                .then(res => {
                    console.log(res);
                    resolve(res);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("External account service: Upload document to bpm: ", error);
                        _logException("External account service: Upload document to bpm: " + (error && error.response) ? error.response :"");
                        reject(genericErrorMsg);
                    }
                })
        });

    return uploadDocumentPromise;


}

export const getFirstPartyEligibleAcct = (requesterId, token, contextJson, profileJson) => {

    if (getFirstPartyEligibleAcctCancellationToken !== null && getFirstPartyEligibleAcctCancellationToken !== undefined) {
        getFirstPartyEligibleAcctCancellationToken.cancel("Cancel existing first party eligible account request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getFirstPartyEligibleAcctCancellationToken = cancelToken.source();


    let firstPartyAcctsPromise = new Promise(
        function (resolve, reject) {
                let url = `${getFirstPartyEligibleAcctUrl}`;
                let data = {
                    "AcctInfos": null,
                    "RequestorId": requesterId,
                    "IncludingBalance": null,
                    "By": null
                };
                Post(url, data, token, contextJson, profileJson, getFirstPartyEligibleAcctCancellationToken).then(res => {
                    // get the response in case of successful call. 
                    if (res.data 
                        && res.data.value
                        && Array.isArray(res.data.value)
                        && res.data.value.length > 0) {
                            let response = res.data.value[0];
                            if(response && response.ToList && Array.isArray(response.ToList)){

                                let formattedResponse = response.ToList.map(acct => {
                                    return {
                                        "AccountNo": acct.AcctNum,
                                        "DisplayName": acct.DisplayName
                                    }
                                })

                                formattedResponse && Array.isArray(formattedResponse) && formattedResponse.length > 0 ? resolve(formattedResponse) : reject(genericErrorMsg);
                            }
                            else{
                                reject(genericErrorMsg);
                            }

                    } else {
                        reject(genericErrorMsg);
                    }
                })
                .catch((error) => {

                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure.
                        console.error("Money Movment Serice : First Party Eligible Account :: ", error);
                        _logException("Money Movment Serice : First Party Eligible Account :: "+ (error && error.response) ? error.response : "" );
                        reject(genericErrorMsg);
                    }

                });
        });

    return firstPartyAcctsPromise;

}

export const getThirdPartyEligibleAcct = (mode, token, contextJson, profileJson) => {

    if (getThirdPartyEligibleAcctCancellationToken !== null && getThirdPartyEligibleAcctCancellationToken !== undefined) {
        getThirdPartyEligibleAcctCancellationToken.cancel("Cancel existing third party eligible account request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getThirdPartyEligibleAcctCancellationToken = cancelToken.source();


    let thirdPartyAcctsPromise = new Promise(
        function (resolve, reject) {
                let url = `${getThirdPartyEligibleAcctUrl}(${mode})`
                Get(url, token, contextJson, profileJson, getThirdPartyEligibleAcctCancellationToken).then(res => {
                    // get the response in case of successful call. 
                    if (res.data !== null
                        && res.data != undefined
                        && Array.isArray(res.data)
                        && res.data.length > 0) {
                            let response = res.data[0];
                            if(response && response.status && response.status.Code && response.status.Code === "000000" 
                                && response.AcctList && Array.isArray(response.AcctList)){

                                let formattedResponse = response.AcctList.map(acct => {
                                    return {
                                        "AccountNo": acct.AccountNo,
                                        "DisplayName": acct.DisplayName
                                    }
                                })

                                formattedResponse && Array.isArray(formattedResponse) && formattedResponse.length > 0 ? resolve(formattedResponse) : reject(genericErrorMsg);
                            }
                            else{
                                reject(thirdPartyErrorMsg);
                            }

                    } else {
                        reject(thirdPartyErrorMsg);
                    }
                })
                .catch((error) => {

                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure.
                        console.error("Money Movment Serice : Third Party Eligible Account :: ", error);
                        _logException("Money Movment Serice : Third Party Eligible Account :: "+ (error && error.response) ? error.response : "" );
                        reject(thirdPartyErrorMsg);
                    }

                });
        });

    return thirdPartyAcctsPromise;

}