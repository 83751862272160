import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function RCM_Modal(props) {
    return (
        <div>
        <Modal 
            isOpen={props.modalState} 
            toggle={props.toggleModal} 
            backdrop={false} 
            size='lg'
            zIndex= '9999'
            className="RCM_CM_modal"
            wrapClassName="RCM_CM_modal_wrapper"
            modalClassName="RCM_CM_modal_class"
            contentClassName="RCM_CM_modal_content"
            centered>
                <ModalHeader toggle={props.toggleModal} charCode="X" ></ModalHeader>
                <ModalBody>
                    <div>
                        <div className="RCM_Toast_heading" align="center">
                            {props.modalTitle}
                        </div>
                        <div className="fixed-area-overflow RCM_CM_scroll RCM_CM_modal_data">
                            {
                                (props.modalContent !== null && props.modalContent !== undefined) ?
                                    (
                                        props.modalContent.split('\n').map((item, i) => {
                                            return <p key={i}>{item}</p>;
                                        })
                                    ) : "Unable to show content"
                            }
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {/* <Button color="btn btn-secondary RCM_button_secondary RCM_select_btn" onClick={props.toggleModal}>Close</Button> */}
                    {
                        (props.approvedAction !== null && props.approvedAction !== undefined) ?
                            (
                                <Button color="btn btn-primary RCM_button_primary RCM_select_btn" onClick={props.approvedAction}>Approve</Button>
                            ) : ""
                    }
                </ModalFooter>
        </Modal>
      </div>
    )
}

