import React from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardImg, CardText, CardTitle } from "reactstrap";

const LifeStyleOptionCard = ({ imgSrc, imgAlt, cardTitle, cardText, path, cardText2 }) => {
  const history = useHistory();
  const handleCardClick = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );
  return (
    <Card style={{ cursor: "pointer" }} onClick={() => handleCardClick(path)}>
      <CardImg alt={imgAlt} src={imgSrc} top width="100%" />
      <CardBody>
        <CardTitle tag="h5" className="RCM_Lifestyle_Card_Title">
          {cardTitle}
        </CardTitle>
              <CardText className="RCM_Lifestyle_Card_Text">{cardText}</CardText>
              {
                  cardText2 && cardText2 !== '' ?
                  <CardText className="RCM_Lifestyle_Card_Text">{cardText2}</CardText>
                  : ''
              }
      </CardBody>
    </Card>
  );
};

const immersiveXText =
  "ImmersiveX’s experts work alongside you to curate your bucket-list experience, no matter how ambitious.";
const fineArtGroupSectionText =
  "A comprehensive, global services group for expert live and online auctions, art, jewelry, and other valuable collections through partnerships with The Fine Art Group and Christie’s.";
const inspiratoText =
  "A private booking desk just for Rockefeller clients, to assist you with booking and planning unforgettable Inspirato vacations.";
const proofLoyaltyText =
  "Proof Loyalty works directly with wineries and select wine consultants, enabling them to provide access to allocated, reserve and library wines. ProofLoyalty’s  premium hotel offers, curated tastings, private events, and lifestyle partnerships are hand-picked for Rockefeller clients.";
const cyberWaText = "CyberWA, Inc. is an exclusive boutique company with a proven history of providing elite cyber protective services to UHNW & HNW individuals, their families, and family offices who are frequently targets of cyber attacks."
const aviationText = "Aviation Portfolio is an independent private aviation advisory firm that develops customized strategies to optimize private aviation portfolios and utilize proprietary market intelligence to proactively manage the costs, risks, logistics, billing and safety of private air travel."
const healthcareText = "Concierge health support for you and your family, delivered to Rockefeller clients through PinnacleCare, the preeminent provider in the space. Designed to provide individuals and families with proactive health and wellness support, 24/7 travel & emergency contingency, and complete management of any health concern."
const elderCaretext = "Homethrive is a unique benefit that can help with everything related to aging, elder care or special needs. With Homethrive, families get peace of mind and dedicated help at their fingertips."
const nextGenText = "Career and life coaching for emerging professionals, as well as health and wellness resources for young adults, delivered to Rockefeller clients through ProjectYou."
const globalRescueText= "Global Rescue pioneered the concept of worldwide field rescue and has served more than 1 million members. Their team of military special operations veterans, paramedics, physicians, and nurses is available to assist members at a moment’s notice, 24/7. Global Rescue’s intelligence team keeps members apprised of important events and trends in 200+ nations and principalities."
const personalSecurityText ="Professional security for Rockefeller clients, provided by Clark International. Includes executive protection, travel protection, and threat management services, as well as security training and public information analysis."
const virtualSpecialityCareText="Summus, a virtual specialty care platform, leverages sophisticated technology to curate access to a network of leading doctors across 48 US hospitals for expert insights on specific health concerns. Rockefeller clients have access to a powerful specialist physician network and medical advisory team, available by phone or video within days and from anywhere in the world."
const aviationText2 = "FlyExclusive is a leading private aviation Firm providing Rockefeller clients with a special offer to gain access to its world-class fleet through its Platinum Jet Club."


const LifestyleOptions = () => {
  return (
    <div className="RCM_Lifestyle_Layout">
      <LifeStyleOptionCard
        imgSrc={"../images/ImmersiveX.png"}
        imgAlt="ImmersiveX"
        cardTitle={"Once in a Lifetime Experiences"}
        cardText={immersiveXText}
        path="/immersivex"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/Inspirato.png"}
        imgAlt="Inspirato"
        cardTitle={"Unique Travel Experiences"}
        cardText={inspiratoText}
        path="/inspirato"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/Proofloyalty.png"}
        imgAlt="Proof Loyalty"
        cardTitle={"Purpose-Driven Wine Platform"}
        cardText={proofLoyaltyText}
        path="/proofloyalty"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/Cyber_WA.png"}
        imgAlt="Cyber WA"
        cardTitle={"Cyber Security"}
        cardText={cyberWaText}
        path="/cyberwa"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/Clark_International.png"}
        imgAlt="Personal Security"
        cardTitle={"Personal Security"}
        cardText={personalSecurityText}
        path="/personalsecurity"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/health-bgimg.jpg"}
        imgAlt="Arts"
        cardTitle={"Private Health Advisory"}
        cardText={healthcareText}
        path="/health"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/summas_lending.png"}
        imgAlt="Virtual Specialty Care"
        cardTitle={"Virtual Specialty Care"}
        cardText={virtualSpecialityCareText}
        path="/virtualspecialitycare"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/elderly_landing_image.png"}
        imgAlt="ELDERLY CARE"
        cardTitle={"Elder Care"}
        cardText={elderCaretext}
        path="/eldercare"
      />  
      <LifeStyleOptionCard
        imgSrc={"../images/art_landing_image.png"}
        imgAlt="Arts"
        cardTitle={"Art Advisory"}
        cardText={fineArtGroupSectionText}
        path="/art"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/Global_Rescue.png"}
        imgAlt="Global Rescue"
        cardTitle={"Global Rescue"}
        cardText={globalRescueText}
        path="/globalrescue"
      />
      <LifeStyleOptionCard
        imgSrc={"../images/nextGen_landing_img.png"}
        imgAlt="NextGen"
        cardTitle={"Next Gen Advisory"}
        cardText={nextGenText}
        path="/nextgen"
      />
      <LifeStyleOptionCard
              imgSrc={"../images/aviation_portfolio.png"}
              imgAlt="Aviation"
              cardTitle={"Private Aviation"}
              cardText={aviationText}
              path="/aviation"
              cardText2={aviationText2}
      />
    </div>
  );
};

export default LifestyleOptions;
