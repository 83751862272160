import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
//import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { GetBalanceDetailsColumns } from './balancesdetailscolumns.jsx'
import { spinner, MsgForFailedData, MsgForNoData } from '../../../../common/constants.jsx';
import Watermark from '../../../../common/watermark';
export function Balancesdetailsview(props) {
    let balancesdetailview = (
        <div>
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="conatiner-fluid RCM_Toast" key="baldetails1">
                {
                    (() => {
                        if (props.state.requestFailed) {
                            return (<div className="row RCM_balanceDetails" id="RCM_balancedetailsTable">
                                <div className="col-sm-12 RCM_Tables">

                                    <BootstrapTable
                                        keyField='id'
                                        data={[]}
                                        columns={GetBalanceDetailsColumns('', '', '', '', '', props.selectedAccount, '', props.state.BalSummaryV2PilotFlag, '', '', props.redirecttoholdings)}
                                        headerClasses="row-bg"
                                        bordered={false}
                                        classes="RCM_two_level_table1"
                                        noDataIndication={MsgForFailedData} />

                                </div>
                            </div>)

                        }
                        else if (props.state.isloading) {
                            return <div className="RCM_spinner">Loading Account Details{spinner()}</div>
                        }
                        else {
                            if (props.state.areDataRetrieved && !props.state.isloading) {
                                return (
                                    <div className="row RCM_balanceDetails" id="RCM_balancedetailsTable">
                                        <div className="col-sm-12 RCM_Tables">

                                            <BootstrapTable
                                                keyField='id'
                                                data={props.rows}
                                                columns={GetBalanceDetailsColumns(props.pricedinvestments, props.cashmoneyaccounts, props.marginbalance,
                                                    props.pendingactivity, props.totalaccountvalue, props.selectedAccount,
                                                    props.totalfixedincomeaccruedinterest, props.totaloutstandingbalance,
                                                    props.insuranceandannuity,
                                                    props.state.BalSummaryV2PilotFlag,
                                                    props.totalcreditdebit,
                                                    props.totalmarginandoutstandingbalance,
                                                    props.redirecttoholdings
                                                )}
                                                headerClasses="row-bg"
                                                bordered={false}
                                                classes="RCM_two_level_table1"
                                                noDataIndication={MsgForNoData} />

                                        </div>
                                    </div>
                                )

                            }
                            else {
                                return (<div className="row RCM_balanceDetails" id="RCM_balancedetailsTable">
                                    <div className="col-sm-12 RCM_Tables">

                                        <BootstrapTable
                                            keyField='id'
                                            data={props.rows}
                                            columns={GetBalanceDetailsColumns('', '', '', '', '', props.selectedAccount, '', props.state.BalSummaryV2PilotFlag, '', '', props.redirecttoholdings)}
                                            headerClasses="row-bg"
                                            bordered={false}
                                            classes="RCM_two_level_table1"
                                            noDataIndication={MsgForNoData}  />

                                    </div>
                                </div>)
                            }
                        }
                    })()
                }
            </div >

        </div >
    )

    return balancesdetailview;
}