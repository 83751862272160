import React, { Component } from "react";
import Balancesdetails from "./balancesdetailsservicecalls.jsx";
import ErrorBoundary from "../../../../common/errorboundary.jsx";
import { balancesDetailsFooterMsg, balancesDetailsFooterMsg2, balanceActicityAggreationDisclaimer} from "../../../../common/constants.jsx";
import { connect } from "react-redux";
import HistoricalAssetAllocationContainer from "../historicalassetallocation/index.jsx";
import  Toptendetail from './../topten/toptendetails'
import { Switch, Route, BrowserRouter, NavLink } from "react-router-dom";

//import { showHistoricalAssetAllocation } from "../historicalassetallocation/HistoricalAssetAllocationService";

class Balancesdetail extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    const { match } = this.props;
    this.tabLinks = [
      {
        id: "balances-detail",
        active: false,
        displayName: "CURRENT",
        tabUrl: "/Balancesdetail"
      },
      {
        id: "balances-history",
        active: false,
        displayName: "HISTORY",
        tabUrl: "/Balanceshistory"
      }
    ].map(x => ({ ...x, active: x.tabUrl === match.path }));

    this.state = {
      tooltipOpen: false,
      activeTab: this.tabLinks.findIndex(x => x.active)
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  showBalancesFooter() {
    let res = false;

    if (
      this.props.preferences &&
      this.props.preferences.pilotfeatures &&
      Array.isArray(this.props.preferences.pilotfeatures)
    ) {
      for (let i = 0; i < this.props.preferences.pilotfeatures.length; i++) {
        let comp = this.props.preferences.pilotfeatures[i];
        if (
          comp.componentname &&
          comp.componentname === "Manage Assets" &&
          comp.active &&
          comp.active === "true"
        ) {
          res = true;
          break;
        }
      }
    }

    return res;
    }

    componentDidMount() {
        window.addEventListener('goToAccountHoldingDetails', this.goToAccountHoldingDetails);
    }
    componentWillUnmount() {
        // de registering the event here
        window.removeEventListener('goToAccountHoldingDetails', this.goToAccountHoldingDetails);

    }

    goToAccountHoldingDetails = () => {
        this.props.history.push("/AccountHoldingdetails");
    }
  render() {
    const { activeTab } = this.state;

    return (
      <div className="RCM_detailsScreeen">
        <div className="row">
          <div className="col-sm-12" key="finacialrow">
            <label
              className="RCM_tile_heading RCM_details_heading RCM_Toast_heading"
                        key="finacial" id="RCM_bal_details_heading"
            >
              ACCOUNT BALANCE DETAILS
            </label>
          </div>
        </div>
        <ErrorBoundary>
                    {
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding">
              <ul className="nav nav-tabs">
                {this.tabLinks.map((tabLink, i) => {
                  return (
                      <li key={tabLink.id} id={tabLink.id} className="nav-item active">
                      <NavLink
                        exact={true}
                        to={tabLink.tabUrl}
                        className={
                          "nav-link " + (i === activeTab ? "linkactive " : " ")
                        }
                        onClick={() => this.setState({ activeTab: i })}
                      >
                        {tabLink.displayName}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
                }
          <BrowserRouter>
            <Switch>
              <Route
                key="balancesdetail"
                exact
                path="/Balancesdetail"
                component={Balancesdetails}
              />
              <Route
                key="balanceshistory"
                exact
                path="/Balanceshistory"
                component={HistoricalAssetAllocationContainer}
                        />
                        
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>

        {this.showBalancesFooter() && (
                <div className="mt-2 RCM_footer_text">
                    <p>{balancesDetailsFooterMsg}</p>
                    <p>{balancesDetailsFooterMsg2}</p>
                    <p>{balanceActicityAggreationDisclaimer}</p>
                </div>
            )}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    preferences: state.acct.preferences
  };
};

export default connect(mapStateToProps)(Balancesdetail);
