import React, { Component } from 'react';
import { MessageClass } from './constants.jsx'
class Messages extends Component {
    render() {
        console.log('Rendering Message Coponent')
        return (
            <div
                className={
                    (this.props.messages !== null
                        && this.props.messages !== undefined
                        && Array.isArray(this.props.messages)
                        && this.props.messages.length > 0) ? this.props.containerClass : ""}>
                {
                    this.props.messages && this.props.messages.map((msg, index) =>
                        <div key={`msgDiv-${index}`}>
                            {
                                msg.class === MessageClass.ERROR
                                    ? <div key={index} className={msg.class}>
                                        < div className="RCM_error_img" ></div >
                                        <div className="RCM_msg_text">{msg.text}</div>
                                    </div>
                                    : ""
                            }
                            {
                                msg.class === MessageClass.SUCCESS
                                    ? <div key={index} className={msg.class+" RCM_Feedback_Success_Body"}>
                                        <div className="RCM_Feedback_Success"> {msg.text}</div>
                                    </div>
                                    : ""
                            }
                            {
                                msg.class === MessageClass.INFO
                                    ? <div key={index} className={msg.class+" RCM_Feedback_Success_Body"}>
                                        <div className="RCM_Feedback_Info">{msg.text}</div>
                                    </div>
                                    : ""
                            }
                            {
                                msg.class === MessageClass.WARNING
                                    ? <div key={index} className={msg.class + " RCM_Message_Warning_Body"}>
                                        <div className="RCM_Message_Warning">{msg.text}</div>
                                    </div>
                                    : ""
                            }
                            {
                                msg.class === MessageClass.LABEL
                                    ? <div key={index} className={msg.class}>
                                        <div className="">{msg.text}</div>
                                    </div>
                                    : ""
                            }
                            {
                                msg.class === MessageClass.PREFERENCE_WARNING
                                    ? <div key={index} className={msg.class}>
                                        <div className="RCM_msg_text">Failed to save eDelivery setting for accounts indicated by <span className="RCM_error_icon">&#9888;</span>.</div>
                                    </div>
                                    : ""
                                    
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}
export default React.memo(Messages)
