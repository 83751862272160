import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DocumentListURL, DocumentURL, GetAccountsList, CheckIfProspectClient } from './../../../common/constants.jsx';
import DateRanges from './../../../common/dateranges.jsx'
import { AddTradeConfirmDocuments, AddTradeConfirmSummaryDocuments, AddAccountStatements, AddCorrespondenceDocuments, AddTaxDocuments } from './../../../actions/documentactions.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { TradeConfirmView } from './tradeconfirmview.jsx';
import { MessageType, MessageClass } from './../../../common/constants.jsx';
import axios from 'axios';
import ErrorBoundary from '../../../common/errorboundary.jsx';
import { _logException, _logInformation } from "../../../common/_logging.js";
import { withPageView } from '../../../hoc/withPageView.jsx';
import tradeconfirmmockresponse from './prospecttradeconfirmresponse.js'
import pdfData from './../mockpdfresponse.js'
import dateFormat from "dateformat";
class TradeConfirm extends Component {

    constructor(props) {
        super(props);

        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            documentResponse: null,
            pdfData: null,
            objectIds: [],
            documentList: [],
            filteredDocumentList: [],
            tradeConfirmColumns: this.tradeConfirmColumns,
            accounts: this.accounts,
            selectedAccount: '',
            dropdownOpen: false,
            dateRangeDropdownOpen: false,
            searchDropdownOpen: false,
            dropdownValue: '',
            dropdownDispValue: '',
            dateRangeDropdownValue: '',
            selectedDateRange: '',
            searchDropdownValue: '',
            selectedSearchBy: '',
            dateRangeValue: '',
            searchValue: '',
            isCustomDateRangeSelected: false,
            areDocumentsRetrieved: false,
            selected: [],
            isDocumentSelected: false,
            startDate: new Date(),
            endDate: new Date(),
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
            isDocumentResponseWithNoData: false,
            isDocumentResponseWithError: false,
            isSearchByTradeConfirmSummary: false,
            render: this.props.render,
            messages: [],
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.changeAccount = this.changeAccount.bind(this);
        this.toggle = this.toggle.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
        this.dateRangeDropdownToggle = this.dateRangeDropdownToggle.bind(this);
        this.searchDropdownToggle = this.searchDropdownToggle.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.SearchDocuments = this.SearchDocuments.bind(this);
        this.handleTradeConfrimOrSummary = this.handleTradeConfrimOrSummary.bind(this);
        this.execute = this.execute.bind(this);
    }
    cancellationToken = null;
    documentCanellationToken = null;
    accountStatementCancellationToken = null;
    correspondenceCancellationToken = null;
    taxDocumentCancellationToken = null;
    documentList = [];
    dateRangeDropDownItems = [
        {
            label: 'Select Range',
            value: 'Select Range'
        },
        {
            label: DateRanges.YESTERDAY.label,
            value: DateRanges.YESTERDAY.value
        },
        {
            label: DateRanges.LAST7DAYS.label,
            value: DateRanges.LAST7DAYS.value
        },
        {
            label: DateRanges.LAST30DAYS.label,
            value: DateRanges.LAST30DAYS.value
        },
        {
            label: DateRanges.LAST90DAYS.label,
            value: DateRanges.LAST90DAYS.value
        },
        {
            label: DateRanges.CURRENTYEAR.label,
            value: DateRanges.CURRENTYEAR.value
        },
        {
            label: DateRanges.PRIORYEAR.label,
            value: DateRanges.PRIORYEAR.value
        },
        {
            label: DateRanges.CUSTOMRANGE.label,
            value: DateRanges.CUSTOMRANGE.value
        }
    ];
    searchDropDownItems = [
        {
            label: "Trade Confirm",
            value: "tradeConfirm"
        },
        {
            label: "Trade Summary",
            value: "tradeConfirmSummary"
        }
    ];
    handleTradeConfrimOrSummary(selectedItem) {
        let requestBody = {};
        let searchBy = this.searchDropDownItems.find(item => item.label === selectedItem.currentTarget.textContent.trim()).value
        this.setState({
            searchDropdownValue: selectedItem.currentTarget.textContent,
            searchValue: searchBy,
        });
        let isSearchByTradeConfirmSummary = false;
        let accountNumbers = [];
        this.state.accounts.map(acct => {
            if (acct.number !== 'All Accounts') {
                if (acct.isInternal) {
                    accountNumbers.push(acct.number);
                }
            }
        });
        if (searchBy === 'tradeConfirmSummary') {
            if (this.props.tradeConfirmSummaryDocumentList && this.props.tradeConfirmSummaryDocumentList.length > 0) {
                this.setState({
                    documentList: this.props.tradeConfirmSummaryDocumentList,
                    filteredDocumentList: this.props.tradeConfirmSummaryDocumentList,
                    areDocumentsRetrieved: true,
                    isSearchByTradeConfirmSummary: true
                });
            } else {
                isSearchByTradeConfirmSummary = true;
                this.setState({
                    documentList: [],
                    filteredDocumentList: [],
                    areDocumentsRetrieved: false,
                    isSearchByTradeConfirmSummary: true,
                    pdfData: null,
                    isDocumentSelected: false,
                    isDocumentListResponseWithNoData: false,
                    isDocumentListResponseWithError: false,
                    selected: []
                });
                requestBody = JSON.stringify({
                    'VirtualTable': 'NF_TRADE_CONFIRMS',
                    'PrimaryType': 'Account',
                    'PrimaryValue': accountNumbers,
                    'SearchDateComparison': 'R',
                    'SearchDateBegin': '',
                    'SearchDateEnd': '',
                    'SearchDateEndSpecified': true,
                    'SearchAllVersions': true,
                    'IncludeRelationships': false,
                    'RenditionFilter': '*',
                    'DateRange': '',

                });
                this.execute(requestBody, isSearchByTradeConfirmSummary, false);
            }

        } else {        //Search By Trade Confirm           
            if (this.props.tradeConfirmDocumentList && this.props.tradeConfirmDocumentList.length > 0) {
                this.setState({
                    documentList: this.props.tradeConfirmDocumentList,
                    filteredDocumentList: this.props.tradeConfirmDocumentList,
                    areDocumentsRetrieved: true,
                    isSearchByTradeConfirmSummary: false,
                });
            } else {
                this.setState({
                    documentList: [],
                    filteredDocumentList: [],
                    areDocumentsRetrieved: false,
                    isSearchByTradeConfirmSummary: false,
                    pdfData: null,
                    isDocumentSelected: false,
                    isDocumentListResponseWithNoData: false,
                    isDocumentListResponseWithError: false,
                    selected: []
                });
                requestBody = JSON.stringify({
                    'VirtualTable': 'NF_TRADES',
                    'PrimaryType': 'Account',
                    'PrimaryValue': accountNumbers,
                    'SearchDateComparison': 'R',
                    'SearchDateBegin': '',
                    'SearchDateEnd': '',
                    'SearchDateEndSpecified': true,
                    'SearchAllVersions': true,
                    'IncludeRelationships': false,
                    'RenditionFilter': '*',
                    'DateRange': '',
                });
                this.execute(requestBody, isSearchByTradeConfirmSummary, false);
            }
        }
    }
    componentWillUnmount() {
        if (this.cancellationToken) {
            this.cancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.documentCanellationToken) {
            this.documentCanellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.accountStatementCancellationToken) {
            this.accountStatementCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.correspondenceCancellationToken) {
            this.correspondenceCancellationToken.cancel('Operation canceled due to new request.')
        }
        if (this.taxDocumentCancellationToken) {
            this.taxDocumentCancellationToken.cancel('Operation canceled due to new request.')
        }
    }
    //Below life cycle hook is used to filter accounts based on top level account dropdown selection.
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectClient();
            }
            else {
                this.accounts = [];
                this.accounts = GetAccountsList(this.props.selectedaccount);
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                if (accountNumbers.length > 0) {
                    let requestBody = JSON.stringify({
                        'VirtualTable': this.state.isSearchByTradeConfirmSummary ? 'NF_TRADE_CONFIRMS' : 'NF_TRADES',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': '',
                        'SearchDateEnd': '',
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',
                    });
                    this.setState({
                        areDocumentsRetrieved: false,
                        accounts: this.accounts,
                        isDocumentResponseWithNoData: false,
                        isDocumentResponseWithError: false,
                        isDocumentListResponseWithNoData: false,
                        isDocumentListResponseWithError: false,
                        pdfData: null,
                        isDocumentSelected: false,
                        dropdownValue: this.accounts[0].number,
                        dropdownDispValue: this.accounts[0].accountdisplayname,
                        isNonRockActSelected: false
                    });
                    this.execute(requestBody, this.state.isSearchByTradeConfirmSummary, false);
                } else {    // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true,
                        documentList: [],
                        filteredDocumentList: [],
                        isDocumentListResponseWithNoData: true,
                        areDocumentsRetrieved: false
                    });
                }


            }
        }
    }
    execute = (requestBody, isSearchByTradeConfirmSummary, dateRangeSearch) => {
        let documentList = [];
        let url = DocumentListURL;
        if (this.cancellationToken) {
            this.cancellationToken.cancel('Operation canceled due to new request.')
        }
        this.cancellationToken = axios.CancelToken.source();
        if (!isSearchByTradeConfirmSummary) {
            let i = 1;
            Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.cancellationToken)
                .then(res => {
                    if (res.status === 200) {
                        const documentResponse = res.data;
                        if (documentResponse) {
                            documentResponse && documentResponse.value[0].DocumentList.map(document => {
                                var date = document.AccessKeyCustomData.substring(25, 35)
                                var tradeDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                                documentList.push({
                                    "id": i,
                                    "primaryValue": document.PrimaryValue,
                                    "objectId": document.ObjectId,
                                    "tradeDate": tradeDate,
                                    "description": document.AccessKeyCustomData.substring(125, 145),
                                    "securityType": document.AccessKeyCustomData.substring(84, 86),
                                    "action": document.AccessKeyCustomData.substring(9, 10),
                                    "symbol": document.AccessKeyCustomData.substring(45, 75),
                                    "cusip": document.AccessKeyCustomData.substring(75, 84),
                                    "quantity": document.AccessKeyCustomData.substring(87, 106),
                                    "price": document.AccessKeyCustomData.substring(106, 125),
                                });
                                i++;
                            });
                            let tradeConfirmList = documentList;
                            if (tradeConfirmList && tradeConfirmList.length > 0) {
                                this.props.AddTradeConfirmDocuments(tradeConfirmList, tradeConfirmList, isSearchByTradeConfirmSummary);
                                this.setState({
                                    documentList: tradeConfirmList,
                                    filteredDocumentList: tradeConfirmList,
                                    areDocumentsRetrieved: true
                                });
                                _logInformation("Trade Confirm: Documentlist fetched")
                            } else {
                                this.props.AddTradeConfirmDocuments([], null, isSearchByTradeConfirmSummary);
                                this.setState({
                                    isDocumentListResponseWithNoData: true,
                                    areDocumentsRetrieved: false
                                });
                            }
                        } else {
                            this.props.AddTradeConfirmDocuments(null, null, isSearchByTradeConfirmSummary);
                            this.setState({
                                documentList: [],
                                filteredDocumentList: [],
                                isDocumentListResponseWithNoData: true,
                                areDocumentsRetrieved: false
                            });
                        }
                    } else {
                        this.props.AddTradeConfirmDocuments(null, null, isSearchByTradeConfirmSummary);
                        this.setState({
                            isDocumentListResponseWithNoData: true,
                            areDocumentsRetrieved: false
                        });
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        _logException("Tax Document : Fetching documentlist: " + (error && error.response) ? error.response : "");
                        console.log(error);
                        this.setState({
                            areDocumentsRetrieved: false,
                            isDocumentListResponseWithNoData: false,
                            isDocumentListResponseWithError: true
                        });
                    }
                });

        } else {
            let i = 1;
            Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.cancellationToken)
                .then(res => {
                    if (res.status === 200) {
                        const documentResponse = res.data;
                        if (documentResponse) {
                            documentResponse && documentResponse.value[0].DocumentList.map(document => {
                                var date = new Date(document.CorrespondenceDate).toISOString("mm/dd/yyyy").substring(0, 10)
                                var summaryDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                                documentList.push({
                                    "id": i,
                                    "primaryValue": document.PrimaryValue,
                                    "objectId": document.ObjectId,
                                    "summaryDate": summaryDate,
                                    "description": document.DisplayName
                                });
                                i++;
                            });
                            let tradeConfirmSummaryList = documentList;
                            if (tradeConfirmSummaryList && tradeConfirmSummaryList.length > 0) {

                                this.props.AddTradeConfirmSummaryDocuments(tradeConfirmSummaryList, tradeConfirmSummaryList, isSearchByTradeConfirmSummary);

                                this.setState({
                                    documentList: tradeConfirmSummaryList,
                                    filteredDocumentList: tradeConfirmSummaryList,
                                    areDocumentsRetrieved: true
                                });
                                _logInformation("Trade Confirm Summary: Documentlist fetched")
                            } else {
                                this.props.AddTradeConfirmSummaryDocuments(null, null, isSearchByTradeConfirmSummary);
                                this.setState({
                                    isDocumentListResponseWithNoData: true,
                                    areDocumentsRetrieved: false
                                });
                            }
                        } else {
                            this.props.AddTradeConfirmSummaryDocuments(null, null, isSearchByTradeConfirmSummary);
                            this.setState({
                                documentList: [],
                                filteredDocumentList: [],
                                isDocumentListResponseWithNoData: true,
                                areDocumentsRetrieved: false
                            });
                        }

                    } else {
                        this.props.AddTradeConfirmSummaryDocuments(null, null, isSearchByTradeConfirmSummary);
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        _logException("Tax Document : Fetching documentlist: " + (error && error.response) ? error.response : "");
                        console.log(error);
                        this.setState({
                            areDocumentsRetrieved: false,
                            isDocumentListResponseWithNoData: false,
                            isDocumentListResponseWithError: true
                        });
                    }
                });
        }
    }
    componentDidMount() {
        //Put Page view
        /*  let pageViewData = new PageView();
         pageViewData.name = "Trade Confirms";
         pageViewData.url = "/TradeConfirms";
         _logPageView(pageViewData); */

        //if statements are already available in global store, use them
        if (this.state.isProspectClient) {
            this.SetDummyResponseForProspectClient();
        }
        else {
            if (this.props.tradeConfirmDocumentList) {
                this.setState({
                    documentList: this.props.tradeConfirmDocumentList,
                    filteredDocumentList: this.props.tradeConfirmDocumentList,
                    areDocumentsRetrieved: true
                });
            } else {    //if statements are not available, make a service call and store them in global store
                let accountNumbers = [];
                this.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                if (accountNumbers.length > 0) {
                    let requestBody = JSON.stringify({
                        'VirtualTable': 'NF_TRADES',
                        'PrimaryType': 'Account',
                        'PrimaryValue': accountNumbers,
                        'SearchDateComparison': 'R',
                        'SearchDateBegin': '',
                        'SearchDateEnd': '',
                        'SearchDateEndSpecified': true,
                        'SearchAllVersions': true,
                        'IncludeRelationships': false,
                        'RenditionFilter': '*',
                        'DateRange': '',

                    });
                    this.execute(requestBody, false, false);
                } else {        // For non rockco accounts
                    this.setState({
                        isNonRockActSelected: true
                    });

                }

            }
            this.setState({
                dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
                dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
                dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                searchDropdownValue: this.searchDropDownItems[0].label,
                selectedAccount: 'All Accounts',
                selectedDateRange: 'Select Range',
                selectedSearchBy: 'Trade Confirm'
            });
        }
    }
    SearchDocuments() {
        this.setState({
            messages: []
        });
        if (this.state.dateRangeValue === DateRanges.CUSTOMRANGE.value) {
            let messages = [];
            if (new Date(dateFormat(this.state.startDate, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
                messages.push({
                    type: MessageType.ERROR,
                    text: 'End date cannot be less than Start date',
                    class: MessageClass.ERROR
                })
                this.setState({ messages: messages });
                return;
            } else {
                this.setState({
                    messages: messages
                });
            }

        }
        this.setState({
            pdfData: null,
            isDocumentSelected: false,
            selected: [],
            areDocumentsRetrieved: false,
            isDocumentListResponseWithNoData: false,
            isDocumentListResponseWithError: false,
        });

        if (this.state.dateRangeDropdownValue !== 'Select Range') {
            this.setState({
                pdfData: null,
                isDocumentSelected: false,
                selected: [],
                areDocumentsRetrieved: false
            });
            let accountNumbers = [];
            this.state.accounts.map(acct => {
                if (acct.number !== 'All Accounts') {
                    if (acct.isInternal) {
                        accountNumbers.push(acct.number);
                    }
                }
            });
            let requestBody = JSON.stringify({
                'VirtualTable': this.state.isSearchByTradeConfirmSummary ? 'NF_TRADE_CONFIRMS' : 'NF_TRADES',
                'PrimaryType': 'Account',
                'PrimaryValue': accountNumbers,
                'SearchDateComparison': 'R',
                'SearchDateBegin': dateFormat(this.state.startDate, "yyyy-mm-dd"),
                'SearchDateEnd': dateFormat(this.state.endDate, "yyyy-mm-dd"),
                'SearchDateEndSpecified': true,
                'SearchAllVersions': true,
                'IncludeRelationships': false,
                'RenditionFilter': '*',
                'DateRange': '',

            });
            this.execute(requestBody, this.state.isSearchByTradeConfirmSummary, true);
            this.setState({ dropdownValue: this.state.accounts[0] && this.state.accounts[0].number });
        }
    }
    changeDateRange(selectedDateRange) {
        let dateRange = this.dateRangeDropDownItems.find(item => item.label === selectedDateRange.currentTarget.textContent.trim()).value
        this.setState({
            dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
            dateRangeValue: dateRange
        });
        if (dateRange !== 'Select Range') {
            if (dateRange === DateRanges.CUSTOMRANGE.value) {
                this.setState({
                    isCustomDateRangeSelected: true,
                    dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                    dateRangeValue: dateRange
                });
            } else {
                this.setState({
                    isCustomDateRangeSelected: false,
                    startDate: new Date(),
                    endDate: new Date(),
                    pdfData: null,
                    isDocumentSelected: false,
                    selected: [],
                    areDocumentsRetrieved: false,
                    isDocumentListResponseWithNoData: false,
                    isDocumentListResponseWithError: false,
                    dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                    dateRangeValue: dateRange
                });
                let accountNumbers = [];
                this.state.accounts.map(acct => {
                    if (acct.number !== 'All Accounts') {
                        if (acct.isInternal) {
                            accountNumbers.push(acct.number);
                        }
                    }
                });
                let requestBody = JSON.stringify({
                    'VirtualTable': this.state.isSearchByTradeConfirmSummary ? 'NF_TRADE_CONFIRMS' : 'NF_TRADES',
                    'PrimaryType': 'Account',
                    'PrimaryValue': accountNumbers,
                    'SearchDateComparison': 'R',
                    'SearchDateBegin': '',
                    'SearchDateEnd': '',
                    'SearchDateEndSpecified': true,
                    'SearchAllVersions': true,
                    'IncludeRelationships': false,
                    'RenditionFilter': '*',
                    'DateRange': dateRange,

                });
                this.execute(requestBody, this.state.isSearchByTradeConfirmSummary, false);
            }
        }
    }
    startDateChange(date) {
        let messages = [];
        this.setState({
            startDate: date
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("start Date: " + this.state.startDate);
    }
    endDateChange(date) {
        let messages = [];
        this.setState({
            endDate: date
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) < new Date(dateFormat(this.state.startDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'End date cannot be less than Start date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("end Date: " + this.state.endDate);
    }
    changeAccount(selectedAccount) {
        this.setState({
            pdfData: null,
            dropdownValue: selectedAccount.number,
            dropdownDispValue: selectedAccount.accountdisplayname,
            isDocumentSelected: false,
            selected: []
        });
        let accountNumber = selectedAccount.number;
        let docList = this.state.documentList.filter(doc => doc.primaryValue === accountNumber);
        if (!this.state.isSearchByTradeConfirmSummary) {
            this.props.AddTradeConfirmDocuments(this.props.tradeConfirmDocumentList, docList, this.state.isSearchByTradeConfirmSummary);
        } else {
            this.props.AddTradeConfirmSummaryDocuments(this.props.tradeConfirmSummaryDocumentList, docList, this.state.isSearchByTradeConfirmSummary);
        }
        if (accountNumber !== 'All Accounts') {
            this.setState({
                filteredDocumentList: docList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        } else {
            this.setState({
                filteredDocumentList: this.state.documentList,
                dropdownValue: accountNumber,
                dropdownDispValue: selectedAccount.accountdisplayname
            });
        }

    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    dateRangeDropdownToggle() {
        this.setState(prevState => ({
            dateRangeDropdownOpen: !prevState.dateRangeDropdownOpen
        }));
    }
    searchDropdownToggle() {
        this.setState(prevState => ({
            searchDropdownOpen: !prevState.searchDropdownOpen
        }));
    }
    selectDocument(row, isSelect) {
        if (this.state.isProspectClient) {
            this.setState({
                pdfData: pdfData,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
        }
        else {
            this.setState({
                pdfData: null,
                isDocumentSelected: true,
                selected: [row.id],
                isDocumentResponseWithNoData: false,
                isDocumentResponseWithError: false
            });
            let requestBody = JSON.stringify({
                'VirtualTable': this.state.isSearchByTradeConfirmSummary ? 'NF_TRADE_CONFIRMS' : 'NF_TRADES',
                'PrimaryType': 'Account',
                'PrimaryValue': row.primaryValue,
                'ObjectId': row.objectId,

            });

            let url = DocumentURL;
            if (this.documentCanellationToken) {
                this.documentCanellationToken.cancel('Operation canceled due to new request.')
            }
            this.documentCanellationToken = axios.CancelToken.source();
            Post(url, requestBody, this.props.token, this.props.contextjson, this.props.profilejson, this.documentCanellationToken)
                .then(res => {
                    if (res.status === 200) {
                        let selectedDocPdfData = res && res.data && res.data.value[0].ContentStream;
                        if (selectedDocPdfData !== "") {
                            this.setState({
                                pdfData: selectedDocPdfData
                            })
                        } else {
                            this.setState({
                                pdfData: null,
                                isDocumentResponseWithNoData: true
                            })
                        }
                    } else {
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithNoData: true
                        })
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //_logException("Trade Confirm : Fetching document: " + (error && error.response) ? error.response : "");
                        console.log(error);
                        this.setState({
                            pdfData: null,
                            isDocumentResponseWithError: true
                        })
                    }
                });
        }
    }
    SetDummyResponseForProspectClient() {
        let documentList = [];
        let i = 1;
        this.accounts = [];
        this.accounts = GetAccountsList(this.props.selectedaccount);
        if (tradeconfirmmockresponse) {
            tradeconfirmmockresponse && tradeconfirmmockresponse.value[0].DocumentList.map(document => {
                var date = document.AccessKeyCustomData.substring(25, 35)
                var tradeDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
                documentList.push({
                    "id": i,
                    "primaryValue": document.PrimaryValue,
                    "objectId": document.ObjectId,
                    "tradeDate": tradeDate,
                    "description": document.AccessKeyCustomData.substring(125, 145),
                    "securityType": document.AccessKeyCustomData.substring(84, 86),
                    "action": document.AccessKeyCustomData.substring(9, 10),
                    "symbol": document.AccessKeyCustomData.substring(45, 75),
                    "cusip": document.AccessKeyCustomData.substring(75, 84),
                    "quantity": document.AccessKeyCustomData.substring(87, 106),
                    "price": document.AccessKeyCustomData.substring(106, 125),
                });
                i++;
            });
            let tradeConfirmList = documentList;
            if (tradeConfirmList && tradeConfirmList.length > 0) {
                this.props.AddTradeConfirmDocuments(tradeConfirmList, tradeConfirmList, false);
                this.setState({
                    documentList: tradeConfirmList,
                    filteredDocumentList: tradeConfirmList,
                    areDocumentsRetrieved: true
                });
                _logInformation("Trade Confirm: Documentlist fetched")
            } else {
                this.setState({
                    isDocumentListResponseWithNoData: true,
                    areDocumentsRetrieved: false
                });
            }
        }


        this.setState({
            dropdownValue: this.state.accounts[0] && this.state.accounts[0].number,
            dropdownDispValue: this.state.accounts[0] && this.state.accounts[0].accountdisplayname,
            dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
            searchDropdownValue: this.searchDropDownItems[0].label,
            selectedAccount: 'All Accounts',
            selectedDateRange: 'Select Range',
            selectedSearchBy: 'Trade Confirm'
        });
    }

    options = [];
    render() {
        this.options = [];
        console.log("Inside render")
        let documents = [];
        this.state.accounts.map(acct => {
            documents = this.state.filteredDocumentList.filter(doc => doc.primaryValue === acct.number);
            this.options.push({
                custom: true,
                totalSize: documents.length < 11 ? 0 : documents.length
            })

        });
        console.log(this.options);
        let authprops = {
            token: this.props.token,
            contextjson: this.props.contextjson,
            profilejson: this.props.profilejson,
            portalid: this.props.portalid
        }
        return (
            <ErrorBoundary>
                <TradeConfirmView
                    state={this.state}
                    options={this.options}
                    dateRangeDropDownItems={this.dateRangeDropDownItems}
                    searchDropDownItems={this.searchDropDownItems}
                    selectDocument={this.selectDocument}
                    toggle={this.toggle}
                    changeAccount={this.changeAccount}
                    dateRangeDropdownToggle={this.dateRangeDropdownToggle}
                    searchDropdownToggle={this.searchDropdownToggle}
                    changeDateRange={this.changeDateRange}
                    SearchDocuments={this.SearchDocuments}
                    startDateChange={this.startDateChange}
                    endDateChange={this.endDateChange}
                    handleTradeConfrimOrSummary={this.handleTradeConfrimOrSummary}
                    authprops={authprops}
                    selectedaccounts={this.props.selectedaccount}
                    role={this.props.role}
                />
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => {
    return {
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        token: state.acct.token,
        tradeConfirmDocumentList: state.doc.tradeConfirmDocumentList,
        tradeConfirmSummaryDocumentList: state.doc.tradeConfirmSummaryDocumentList,
        isSearchByTradeConfirmSummary: state.doc.isSearchByTradeConfirmSummary,
        filteredTradeConfirmSummaryDocumentList: state.doc.filteredTradeConfirmSummaryDocumentList,
        filteredTradeConfirmDocumentList: state.doc.filteredTradeConfirmDocumentList,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        portalid: state.acct.portalid,
        role:state.acct.role
    };
};
const mapDispatchToProps = {
    AddTradeConfirmDocuments,
    AddTradeConfirmSummaryDocuments,
    AddTaxDocuments,
    AddCorrespondenceDocuments,
    AddAccountStatements,
}
const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Trade Confirms' })
)
export default enhancement(TradeConfirm);

