import {TelemetryService} from  "../../TelemetryService";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getNestedObject } from "../components/MoneyMovement/common/utilty";
import { userinfo, userTypedEmail } from '../../App.js';
import {relasevariables} from "../../index";
//var ai = null;

export function _logException(errorMessage)
{
    try {
        let ai =  new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();

        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }   
        ai.appInsights.trackException({ error: new Error(`${JSON.stringify(errorMessage)}`), severityLevel: SeverityLevel.Error }, addCustomData());
        //ai.appInsights.flush();
    } catch (err) {
        console.error(err)
    }
}

export function _logInformation(infoMessage)
{
    try{
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackTrace({ message: `${JSON.stringify(infoMessage)}`, severityLevel: SeverityLevel.Information }, addCustomData());
        //ai.appInsights.flush();
    }catch(err)
    {
        console.error(err)
    }
}

export function _logEvent(eventName, data)
{
    try{
        
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        ai.appInsights.setAuthenticatedUserContext(data);

        let telemetryInitializer = (envelope) => {
            envelope.tags['ai.user.id'] = data;
            envelope.tags['ai.user.authUserId'] = data;
          };
        ai.appInsights.addTelemetryInitializer(telemetryInitializer);      
        ai.appInsights.trackEvent({ name: eventName }, addCustomData());
        //ai.appInsights.flush();
    }catch(err){
        console.error(err)
    }
}

export function _logPageView(pageView){

    try{
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackPageView(pageView);
        //ai.appInsights.flush();
    }catch(err)
    {
        console.error(err)
    }
}

export function disableConsoleLogs()
{
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
}

function getTelemetryInitializer()
{
    let telemetryInitializer =null;
    //let user = (sessionStorage.getItem('user'));
    let user = userinfo;
     if(user)
     {
       let userObject = JSON.parse(user);
       let userName = getNestedObject(userObject, ['userName']) || getNestedObject(userObject, ['preferred_username']);

        telemetryInitializer = (envelope) => {
            envelope.tags['ai.user.id'] = userName;
            envelope.tags['ai.user.authUserId'] = userName;
        };
     }else{
        telemetryInitializer = (envelope) => {
            envelope.tags['ai.user.id'] = userTypedEmail ? userTypedEmail : sessionStorage.getItem('userTypeEmail');
            envelope.tags['ai.user.authUserId'] = userTypedEmail ? userTypedEmail : sessionStorage.getItem('userTypeEmail');
        };
     }

    return telemetryInitializer;
}

function addCustomData(){
    let customData = {};
    let user = userinfo;
     if(user)
     {
       let userObject = JSON.parse(user);
       customData.userName = getNestedObject(userObject, ['name']);
       customData.azureAdId = getNestedObject(userObject, ['oid']);
       customData.userEmail = getNestedObject(userObject, ['userName']) || getNestedObject(userObject, ['preferred_username']);
     }else{
         //Before getting user object from AD
         customData.userName =  userTypedEmail ? userTypedEmail : sessionStorage.getItem('userTypeEmail');
         customData.userEmail =  userTypedEmail ? userTypedEmail : sessionStorage.getItem('userTypeEmail');
     }

     customData.codeVersion = relasevariables ?  relasevariables.version : '';
     customData.environment = relasevariables ? relasevariables.env : '';
     return customData;
}

