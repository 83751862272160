import React from 'react';
import { PhoneErrorMsg, EmailErrorMsg } from '../../../common/constants.jsx'
import Messages from '../../../common/messages.jsx';
import { MessageType, MessageClass } from '../../../common/constants.jsx';
import { spinner } from '../../../common/constants.jsx';
import OtpComponent from '../otp/otp.jsx';
import AuthenticationMethodsList from '../multifactorAuthentication/AuthenticationMethodsList.jsx';

export function ProFileView(
    props
) {
    let userProfileUpdateFailed = props.state.userProfileUpdateFailed;
    let errorDiv;
    if (userProfileUpdateFailed === true) {
        errorDiv = <div><div >{props.state.error}</div><br /></div>
    }

    let profileview = (
        <div className="RCM_Profile">
            <div className="row">
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-9" key="lblholdingheadingrow">
                    <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblholdingheading'>SETTINGS</label>
                </div>
            </div>

            <div className="RCM_Toast RCM_subscribe_toast" id="RCM_ProfileSettings">
                {
                    (props && props.state && props.state.isProfileUpdated && props && props.state && props.state.messages && props.state.messages.length > 0)
                        ? props && props.state && props.state.messages && props.state.messages.length > 0
                            ?
                            <div className="row RCM_Feedback_Message_Margin">
                                <div className="col-sm-11">
                                    <Messages messages={props && props.state && props.state.messages}
                                        containerClass={
                                            props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                                ? "RCM_validation_error_container"
                                                : ""
                                        } />
                                </div>
                            </div>

                            : <div className="row RCM_Feedback_Message_Margin">
                                <div className="col-sm-11"><div className="RCM_spinner">Updating profile{spinner()}</div></div></div>
                        : ""
                }
                <div>
                    <div className="row mt-2">
                        <div className="col-sm-6">
                            <label className="RCM_CM_form_label">FIRST NAME</label>
                            <div>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder=""
                                    className="RCM_form_control"
                                    required
                                    readOnly
                                    value={props.state.firstName}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <label className="RCM_CM_form_label">LAST NAME</label>
                            <div>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder=""
                                    className="RCM_form_control"
                                    required
                                    readOnly
                                    value={props.state.lastName}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-6">
                            <label className="RCM_CM_form_label">PRIMARY PHONE</label>
                            <div>
                                <input
                                    type="text"
                                    name="primaryPhone"
                                    placeholder=""
                                    className="RCM_form_control"
                                    autoComplete="off"
                                    required
                                    readOnly
                                    value={props.state.primaryPhone}
                                    onChange={props.handleChange}
                                    onBlur={props.handlePrimaryPhone}
                                />
                            </div>
                            <div className="RCM_validation_error_container" style={{ display: props.state.primaryPhoneError }}>
                                <div className="RCM_validation_error_text">
                                    <div className="RCM_error_img"></div>
                                    <div className="RCM_msg_text">{PhoneErrorMsg}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <label className="RCM_CM_form_label">SECONDARY PHONE</label>
                            <div>
                                <input
                                    type="text"
                                    name="secondaryPhone"
                                    placeholder=""
                                    className="RCM_form_control"
                                    autoComplete="off"
                                    required
                                    readOnly
                                    value={props.state.secondaryPhone}
                                    onChange={props.handleChange}
                                    onBlur={props.handleSecondaryPhone}

                                />
                            </div>
                            <div className="RCM_validation_error_container" style={{ display: props.state.secondaryPhoneError }}>
                                <div className="RCM_validation_error_text">
                                    <div className="RCM_error_img"></div>
                                    <div className="RCM_msg_text">{PhoneErrorMsg}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-6">
                            <label className="RCM_CM_form_label">EMAIL ADDRESS</label>
                            <div>
                                <input
                                    type="text"
                                    name="emailAddress"
                                    placeholder=""
                                    className="RCM_form_control"
                                    onBlur={props.handlePrimaryEmailChange}
                                    required
                                    readOnly
                                    value={props.state.emailAddress}
                                    onChange={props.handleChange}
                                />
                            </div>
                            <div className="RCM_validation_error_container" style={{ display: props.state.emailError }}>
                                <div className="RCM_validation_error_text">
                                    <div className="RCM_error_img"></div>
                                    <div className="RCM_msg_text">{EmailErrorMsg}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mt-4">
                        <div className="col-sm-12">
                            {
                                props.state && props.state.showMFALink
                                    ? <div onClick={() => props.openMFALink("https://aka.ms/MFASetup")} className="profile-font-color" style={{ cursor: 'pointer' }} >Multifactor Authentication Registration Process</div>
                                    : <div onClick={() => props.openMFALink("https://myaccount.google.com/security")} className="profile-font-color" style={{ cursor: 'pointer' }}>Multifactor Authentication Registration Process</div>
                            }
                        </div>
                    </div> */}
                </div>
            </div>
            {
                !sessionStorage.getItem("simulation") &&  props && props.state.showAuthenticationMethods && (
                    <div className="RCM_toast">
                        <AuthenticationMethodsList />
                    </div>
                )
                     
            }
            {props && props.state.showOtpComponent ?
                <>
                 <div className="row">
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-9" key="lblholdingheadingrow">
                    <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblholdingheading'>ONE TIME PASSCODE</label>
                </div>
                </div>
                <div className="RCM_Toast RCM_subscribe_toast">
                    <OtpComponent />
                    </div> 
                </> : ''
            }
        </div>
    )
    return profileview
}
