import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { planManagerUrl } from "../../../common/constants";

export default class PlanManagerConfirmationModal extends Component {
    constructor()
    {
        super();

        this.state={
            headerValue: "Rockefeller Plan Manager provided by National Financial Services LLC",
            contentData: "By clicking on the “Continue” button below you will be transferred to a third party web site which is owned, operated and maintained by National Financial Services LLC. If you have any questions, please call your Financial Advisor."
        }
    }

    openPlanManagerWindow = (props) => {
        props.togglePlanManagerModal();
        let url = planManagerUrl;
        window.open(url, "PlanManager", "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=768").focus();
        
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.planManagerModalState} toggle={this.props.togglePlanManagerModal} 
                     className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                     centered >
                    <ModalHeader toggle={this.props.togglePlanManagerModal}>{this.state.headerValue}</ModalHeader>
                        <ModalBody>
                            <div>
                                 {this.state.contentData}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="btn btn-secondary RCM_button_secondary RCM_select_btn" onClick={this.props.togglePlanManagerModal}>Close</Button>
                        <Button color="btn btn-primary RCM_button_primary RCM_select_btn"
                            onClick={() => this.openPlanManagerWindow(this.props)}
                            disabled={planManagerUrl === undefined || planManagerUrl === null || planManagerUrl === ''}>Continue</Button>
                        </ModalFooter>
                    </Modal>
            </div>
        )
    }
}
