import React, { Component } from 'react';
import Activitysummary from './activityservice.jsx';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { CheckIfProspectClient } from '../../../../common/constants.jsx';
import { connect } from "react-redux";
class Activity extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            isProspectClient: CheckIfProspectClient(this.props.role)
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        let activity = (
            <div className="RCM_Toast" id="Account_Activity" key="activity02">
                <div className="row RCM_headingwrapper" key="activity03">
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10" key="activity04">
                        <label className="RCM_tile_heading" key="activity05">ACCOUNT ACTIVITY</label>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 RCM_expandviewright RCM_expandviewright_activity" key="activity06">
                        <div className="RCM_expandviewrightWrapper" key="activity07">
                            <Link to='Activitydetail' key="activity08"><button className="RCM_expandview" id="TooltipActivitydetail" key="activity09"></button></Link>
                        </div>
                        <Tooltip placement="left" isOpen={this.state.tooltipOpen} target="TooltipActivitydetail" toggle={this.toggle} innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow" key="activity10">
                            Account Activity Detail
                        </Tooltip>
                    </div>
                </div>
                <ErrorBoundary key="activity11">
                    <Activitysummary key="activity12" />
                </ErrorBoundary>
            </div>);

        return (<ErrorBoundary>
            <>{}
                {this.state.isProspectClient ? <div key="activity01" className='col-xs-12 col-sm-12 col-md-12 col-lg-12' data-intro='Activity Information'
                    id="activity">
                    {activity}
                </div>
                    : <div key="activity01" className='col-xs-12 col-sm-12 col-md-12 col-lg-12' data-intro='Activity Information' draggable={this.props.draggable}
                        id="activity"
                        onDragStart={this.props.onDragStart} onDragOver={this.props.onDragOver} onDrop={this.props.onDrop("activity")}
                    >
                        {activity}
                    </div>}

            </>
        </ErrorBoundary >)
    }
}
const mapStateToProps = state => {
    return {
        role: state.acct.role,
    };
};
export default connect(mapStateToProps)(Activity);