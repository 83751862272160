 const MockData = {
            "_id": "15",
            "partyid": "",
            "aaduseroid": "",
            "wsportaluserid": "6611000026",
     "emailprimary": "abc@rockco.com",
            "partytype": "Person",
            "role": "Client",
            "businesstrustname": "",
            "firstname": "Amber",
            "lastname": "Jha",
            "fullname": "Amber Jha",
            "description": "",
            "status": "active",
            "dob": "1991-09-28",
            "ssn": "******9048",
            "phone": "",
     "entitlements": "",
     "edeliveryemail": [{ "profileid": null, "emailid": "abc@rockco.com", "indicator": null, "isdeletable": false }],
     "accounts": [{
         "number": "RRR-123456",
         "key": "RRR-123456",
         "accountregistration": "Individual",
         "regtypecode": "I",
         "repcode": "123",
         "nickname": "",
         "role": "",
         "stakeholder": "",
         "managedaccount": "N",
         "eDelivery": [{ "cfrmsSuprnCd": "N", "custCorrSuprnCd": "N", "rptSuprnCd": "N", "stmtsSuprnCd": "N", "taxFormSuprnCd": "N", "email": null, "enrolled": false, "accountNumber": "RRR-123456", "custID": "B49169925ORDP", "edeliverystatus": "Not Enrolled" }],
         "displaynickname": [
             {
                 "displongname": "RRR-123456 (Individual)",
                 "dispshortname": "RRR-123456 (I)",
                 "dispname": "RRR-123456"
             }
         ]
     },
     {
         "number": "RRR-234567",
         "key": "RRR-234567",
         "accountregistration": "IRA",
         "regtypecode": "IRA",
         "repcode": "123",
         "nickname": "",
         "role": "",
         "stakeholder": "",
         "managedaccount": "N",
         "eDelivery": [{ "cfrmsSuprnCd": "Y", "custCorrSuprnCd": "N", "rptSuprnCd": "N", "stmtsSuprnCd": "N", "taxFormSuprnCd": "N", "email": "abc@rockco.com", "enrolled": true, "accountNumber": "RRR-234567", "custID": "B49262520SOPR", "edeliverystatus": "Partial Enrolled" }],
         "displaynickname": [
             {
                 "displongname": "RRR-234567 (IRA)",
                 "dispshortname": "RRR-234567 (IRA)",
                 "dispname": "RRR-234567"
             }
         ]
     },
         //{
         //    "number": "RJL000008",
         //    "key": "RJL000008",
         //    "accountregistration": "Individual",
         //    "regtypecode": "I",
         //    "repcode": "123",
         //    "nickname": "",
         //    "role": "",
         //    "stakeholder": "",
         //    "managedaccount": "N",
         //    "eDelivery": null,
         //    "displaynickname": [
         //        {
         //            "displongname": "RJL000008 (Individual)",
         //            "dispshortname": "RJL000008 (I)",
         //            "dispname": "RJL000008"
         //        }
         //    ]
         //},
         //{
         //    "number": "RJL000011",
         //    "key": "RJL000011",
         //    "accountregistration": "Individual",
         //    "regtypecode": "I",
         //    "repcode": "123",
         //    "nickname": "",
         //    "role": "",
         //    "stakeholder": "",
         //    "managedaccount": "N",
         //    "eDelivery": null,
         //    "displaynickname": [
         //        {
         //            "displongname": "RJL000011 (Individual)",
         //            "dispshortname": "RJL000011 (I)",
         //            "dispname": "RJL000011"
         //        }
         //    ]
         //}
     ],
            "groupedexternalaccounts": null,
            "externalaccounts": null,
            "address": [
                {
                    "addresstypecode": "CL",
                    "addresstypecode_display": "2XX - Legal address",
                    "line1": "",
                    "line2": "4094 TURNPIKE DRIVE",
                    "line3": "",
                    "city": "ANNISTON",
                    "stateorprovince": "AL",
                    "country": "USA",
                    "postofficebox": "",
                    "postalcode": "36201"
                },
                {
                    "addresstypecode": "CM",
                    "addresstypecode_display": "2XX - Mailing address",
                    "line1": "",
                    "line2": "4094 TURNPIKE DRIVE",
                    "line3": "",
                    "city": "ANNISTON",
                    "stateorprovince": "AL",
                    "country": "USA",
                    "postofficebox": "",
                    "postalcode": "36201"
                }
            ],
            "createdby": null,
            "createddate": "0001-01-01T00:00:00",
            "lastupdatedby": null,
            "lastupdateddate": "0001-01-01T00:00:00",
            "component_name": null,
            "statuscode": "Success",
            "errormessage": ""
        }

export default MockData