import React, { Component } from 'react';
import { Get } from '../../../../../common/servicecalls.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import { ProjectedIncomeURL, spinner, GetAccountsList, MsgForNoData, CheckIfProspectClient } from './../../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddProjectedIncomeBySecurity, ChangeYearProjectedIncome, RefreshProjectedSummaryAccount } from '../../../../../actions/toptenactions.jsx';
import { securityColumns, securitychildColumns, renderRowsBySecurity, renderRowsBySecurityChild, yearoptions,getYearOptionsText } from '../projectedincomecolumns.jsx';
import axios from "axios";
import { ProjectedIncomeSecurityView } from './projectedincomesecurityview.jsx';
import ErrorBoundary from '../../../../../common/errorboundary.jsx';
import { withPageView } from '../../../../../hoc/withPageView.jsx';
import { _logPageView } from '../../../../../common/_logging';
import { projectedincomesecurity, RRR_123456_projectedincomesecurity, RRR_234567_projectedincomesecurity } from './../../../accountsmockresponse';

class ProjectedIncomeForSecurity extends Component {

    constructor(props) {
        super(props)
        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            modal: false,
            requestFailed: false,
            projectedincomebysecurity: this.props.projectedincomebysecurity,
            accounts: this.accounts,
            portalid: this.props.portalid,
            isloading: true,
            render: this.props.render,
            expanded: [],
            //dropdownValue: '',
            //dropdownDispValue: '',
            //accountOverviewFlag: this.props.accountOverviewFlag,
            isrowexpanded: false,
            expanded: [],
            yearDropdownOpen: false,
            monthDropdownOpen: false,
            dropdownyearValue: this.props.projectedIncomeYear ? this.props.projectedIncomeYear : '2',
            dropdownText: this.props.projectedIncomeYear ? getYearOptionsText(this.props.projectedIncomeYear) : getYearOptionsText('2'),
            //showTable: false,
            //tooltipOpen1: false,
            childColumnToSort: "",
            childColumnSortOrder: "",
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        //this.collapserow = this.collapserow.bind(this);
        //this.toggleDropdown = this.toggleDropdown.bind(this);
        //this.changeAccount = this.changeAccount.bind(this);
        //this.toggle = this.toggle.bind(this);
        //this.toggle1 = this.toggle1.bind(this);
        //this.toggleShowTable = this.toggleShowTable.bind(this);
        this.rowsortevent = this.rowsortevent.bind(this);
        this.changeYear = this.changeYear.bind(this);
        this.toggleYear = this.toggleYear.bind(this);
    }
    
    getSecurityHoldingsCancellationToken = null;
    getSecurityHoldingsAccountChangeCancellationToken = null;

    //method to execute api calls
    execute = (accountlist) => {
        this.setState(() => ({ isloading: true, requestFailed: false }));
        var datarows = [];
        var accounts = '';
        if (this.state.accountOverviewFlag) {
            this.props.selectedaccount.map(x => {
                if (x.number !== 'All Accounts') {
                    accounts += x.number + ',';
                }

            })
            accounts = accounts.substr(0, accounts.length - 1);
            this.props.SetAccountOverviewFlag(false);
        }
        if (this.getSecurityHoldingsCancellationToken) {
            //Cancel the existing call
            this.getSecurityHoldingsCancellationToken.cancel('Cancelling the existing Security Holdings request to prevent memory leaks.');
        }
        //Create a new cancellation token
        const cancelToken = axios.CancelToken;
        this.getSecurityHoldingsCancellationToken = cancelToken.source();
        let selectorparam = 'S';
        const baseUrlSecurity = ProjectedIncomeURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getSecurityHoldingsCancellationToken)
            .then(result => {
                const data = result && result.data && result.data != "" ? result.data.value : datarows;
                this.props.AddProjectedIncomeBySecurity(data);
                this.props.ChangeYearProjectedIncome(this.state.dropdownyearValue);
                this.setState(() => ({ projectedincomebysecurity: data, isloading: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                }
                else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
                }
            })
    };

    componentDidMount() {
        //if props has data then take it or make api call
        //
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.projectedincomebysecurity === null)
                this.execute(this.state.accounts);
            else
                this.setState({ requestFailed: false, isloading: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute();
            }
        }
    }
    componentWillUnmount() {
        if (this.getSecurityHoldingsCancellationToken !== null && this.getSecurityHoldingsCancellationToken !== undefined) {
            this.getSecurityHoldingsCancellationToken.cancel('Cancelling the Security Holdings during unmount to prevent memory leak.');
        }
        if (this.getSecurityHoldingsAccountChangeCancellationToken !== null && this.getSecurityHoldingsAccountChangeCancellationToken !== undefined) {
            this.getSecurityHoldingsAccountChangeCancellationToken.cancel('Cancelling the Security Holdings Account Change during unmount to prevent memory leak.');
        }

    }
    //collapserow(row) {
    //    this.setState(() => ({
    //        expanded: []
    //    }));
    //}
    //changeAccount(selectedAccount) {
    //    let accountNumber;
    //    if (selectedAccount.number === "All Accounts") {
    //        accountNumber = 'All Accounts';
    //    }
    //    else { accountNumber = selectedAccount.number; }
    //    this.setState(() => ({ isloading: true, requestFailed: false, dropdownValue: accountNumber, dropdownDispValue: selectedAccount.accountdisplayname }));
    //    var accounts = '';
    //    if (accountNumber === 'All Accounts') {
    //        this.setState(() => ({ estimatedincomebysecurity: this.props.estimatedincomebysecurity, isloading: false, requestFailed: false }));
    //    }
    //    else {
    //        if (selectedAccount.isInternal === true) {
    //            accounts = accountNumber;
    //        }
    //        else {
    //            accounts = selectedAccount.key;
    //        }
    //        var datarows = [];
    //        if (this.getSecurityHoldingsAccountChangeCancellationToken) {
    //            //Cancel the existing call
    //            this.getSecurityHoldingsAccountChangeCancellationToken.cancel('Cancelling the existing Account Holdings request to prevent memory leaks.');
    //        }
    //        //Create a new cancellation token
    //        const cancelToken = axios.CancelToken;
    //        this.getSecurityHoldingsAccountChangeCancellationToken = cancelToken.source();
    //        let selectorparam = 'S';
    //        const baseUrlSecurity = ProjectedIncomeURL + '\'' + selectorparam + '\',' + this.state.dropdownyearValue + ')';
    //        Get(baseUrlSecurity, this.props.token, this.props.contextjson, this.props.profilejson, this.getSecurityHoldingsAccountChangeCancellationToken)
    //            .then(result => {
    //                const data = result && result.data && result.data != "" ? result.data.value : datarows;
    //                this.setState(() => ({ estimatedincomebysecurity: data, isloading: false }));
    //            })
    //            .catch(error => {
    //                if (axios.isCancel(error)) {
    //                    console.log(error);
    //                }
    //                else {
    //                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }));
    //                }

    //            })
    //    }

    //}
    //GetHeaderName() {
    //    if (this.state.rowdata) {
    //        let header = this.state.rowdata.SECNAME ? this.state.rowdata.SECNAME : ""
    //        header += this.state.rowdata.SECID ? (" - " + this.state.rowdata.SECID) : "";
    //        return header;
    //    }
    //    return "";
    //}
    //toggle1() {
    //    this.setState(prevState => ({
    //        tooltipOpen1: !prevState.tooltipOpen1
    //    }));
    //}
    //toggleShowTable() {
    //    this.setState(prevState => ({ showTable: !prevState.showTable }));
    //}

    //toggle(row) {
    //    this.setState(prevState => ({
    //        modal: !prevState.modal,

    //    }));
    //    this.state.rowdata = row;
    //}
    //toggleDropdown() {
    //    this.setState(prevState => ({
    //        dropdownOpen: !prevState.dropdownOpen
    //    }));
    //}
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                isrowexpanded: true,
                expanded: [...this.state.expanded, row.UNQID]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.UNQID),
                isrowexpanded: this.state.expanded.length > 1 ? true : false
            }));
        }
    }
    rowsortevent(field, order) {
        this.setState({
            childColumnToSort: field,
            childColumnSortOrder: order
        });
    }
    changeYear(selectedyear) {
        if (this.state.dropdownText !== selectedyear.label)
            this.setState({ dropdownText: selectedyear.label, dropdownyearValue: selectedyear.value, childColumnToSort: '', childColumnSortOrder: '' },
                () => this.execute(this.state.accounts),
                this.props.RefreshProjectedSummaryAccount());

    }
    toggleYear() {
        this.setState(prevState => ({
            yearDropdownOpen: !prevState.yearDropdownOpen
        }));
    }
    SetDummayResponseForProspectClient() {
        if (this.props.projectedincomebysecurity === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ projectedincomebysecurity: (RRR_123456_projectedincomesecurity && RRR_123456_projectedincomesecurity.value ? RRR_123456_projectedincomesecurity.value : []), isloading: false, areDataRetrieved: true, expanded: ["115065YH1", "FDIC66606"] }), () => { this.props.AddProjectedIncomeBySecurity((RRR_123456_projectedincomesecurity && RRR_123456_projectedincomesecurity.value ? RRR_123456_projectedincomesecurity.value : [])); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ projectedincomebysecurity: (RRR_234567_projectedincomesecurity && RRR_234567_projectedincomesecurity.value ? RRR_234567_projectedincomesecurity.value : []), isloading: false, areDataRetrieved: true, expanded: ["FDIC66606"] }), () => { this.props.AddProjectedIncomeBySecurity((RRR_234567_projectedincomesecurity && RRR_234567_projectedincomesecurity.value ? RRR_234567_projectedincomesecurity.value : [])); });
                }
            } else if (this.props.selectedaccount.length == 2) {

                this.setState(() => ({ projectedincomebysecurity: (projectedincomesecurity && projectedincomesecurity.value ? projectedincomesecurity.value : []), isloading: false, areDataRetrieved: true, expanded: ["115065YH1", "FDIC66606", "002535300"] }), () => { this.props.AddProjectedIncomeBySecurity((projectedincomesecurity && projectedincomesecurity.value ? projectedincomesecurity.value : [])); });
            }
        }
        else {
            this.setState({ isloading: false, dropdownValue: this.state.accounts[0].number, expanded: ["115065YH1", "FDIC66606", "002535300"], dropdownDispValue: this.state.accounts[0].accountdisplayname });
        }

    }
    //render method
    render() {
        var securityData = [];
        var totalordinarydividend;
        var totalqualifieddividend
        var totaltaxableinterest;
        var totalnontaxableinterest;
        var totalnetincome;
        var totalcapitalgainloss;
        if (this.state.projectedincomebysecurity && this.state.projectedincomebysecurity.length > 0) {
            if (this.state.projectedincomebysecurity[0].itemSummary) {
                securityData = renderRowsBySecurity(this.state.projectedincomebysecurity[0].itemSummary);
                totalordinarydividend = this.state.projectedincomebysecurity[0].ODNDividend;
                totalqualifieddividend = this.state.projectedincomebysecurity[0].QlfDividend;
                totaltaxableinterest = this.state.projectedincomebysecurity[0].TaxableInterest;
                totalnontaxableinterest = this.state.projectedincomebysecurity[0].NonTaxaableInterest;
                totalnetincome = this.state.projectedincomebysecurity[0].NetIncome;
                totalcapitalgainloss = this.state.projectedincomebysecurity[0].TOTCAPGAIN;
            }

        }
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.toggle(row);
            }
        };
        //expand row function for security table
        const expandRowBySecurity = {
            renderer: (row) => (
                <BootstrapTable
                    key="btsecchild1"
                    keyField='ACCTKEY'
                    classes="RCM_two_level_table2 RCM_Headerless_table"
                    data={renderRowsBySecurityChild(row, this.props.selectedaccount,this.state.childColumnToSort, this.state.childColumnSortOrder)}
                    columns={securitychildColumns(this.props.selectedaccount)}
                    noDataIndication={MsgForNoData}
                    rowEvents={rowEvents}
                    bordered={false}
                />
            ),
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return <b></b>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (<div className="RCM_icon_hand RCM_down_arrow">
                    </div>);
                }
                return (<div className="RCM_icon_hand RCM_right_arrow">
                </div>);
            },
            onExpandAll: (isExpandAll, results, e) => {

                if (isExpandAll) {
                    let expandedrows = [];
                    for (const [index, account] of results.entries()) {
                        expandedrows.push(account.UNQID);
                    }
                    this.setState(() => ({
                        isrowexpanded: true,
                        expanded: [],
                        expanded: expandedrows
                    }));
                } else {
                    this.setState(() => ({
                        isrowexpanded: false,
                        expanded: []
                    }));
                }
            }

        };

        return (

            <ErrorBoundary>
                <ProjectedIncomeSecurityView
                    state={this.state}
                    showTable={this.state.showTable}
                    securityData={securityData}
                    //toggle={this.toggleDropdown}
                    //changeAccount={this.changeAccount}
                    //accounts={this.props.selectedaccount}
                    totalordinarydividend={totalordinarydividend}
                    totalqualifieddividend={totalqualifieddividend}
                    totaltaxableinterest={totaltaxableinterest} 
                    totalnontaxableinterest={totalnontaxableinterest}
                    totalnetincome={totalnetincome}
                    totalcapitalgainloss={totalcapitalgainloss}
                    selectedAccount={this.props.selectedaccount}
                    expandRowBySecurity={expandRowBySecurity}
                    changeYear={this.changeYear}
                    toggleYear={this.toggleYear}
                    toggleShowTable={this.toggleShowTable}
                    //toggle1={this.toggle1}
                    accounts={this.props.selectedaccount}
                    rowsortevent={this.rowsortevent}
                    yearoptions={yearoptions}
                />
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        render: state.acct.render,
        selectedaccount: state.acct.selectedaccount,
        token: state.acct.token,
        portalid: state.acct.portalid,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        accountOverviewFlag: state.acct.accountOverviewFlag,
        projectedincomebysecurity: state.topten.projectedincomebysecurity,
        projectedIncomeYear: state.topten.projectedIncomeYear,
        role: state.acct.role
    };
};

const mapDispatchToProps = {
    AddProjectedIncomeBySecurity,
    ChangeYearProjectedIncome,
    RefreshProjectedSummaryAccount
};

const securityholdingdetailscomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Security Holdings' })
)
export default securityholdingdetailscomp(ProjectedIncomeForSecurity);