import React, { Component } from 'react';
import { Get } from '../../../../common/servicecalls.jsx';
import { ActivitysummaryURL, roundvalue, formatMoney, spinner, roundUnitValue, MsgForFailedData, CheckIfProspectClient } from '../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddActivity } from '../../../../actions/accountsactions.jsx';
import axios from 'axios';
import { AccountActivitySummaryView } from './activityview.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { activityDummyResponse, RRR_123456_activity_Response, RRR_234567_activity_Response } from './../../accountsmockresponse.js'
class Activitysummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false,
            accounts: this.props.selectedaccount,
            render: this.props.render,
            activityresponse: this.props.activitysummary,
            isloading: true,
            isProspectClient: CheckIfProspectClient(this.props && this.props.role)
        }
        this.execute = this.execute.bind(this);
    }
    activitycanellationToken = null;
    execute = () => {
        this.setState(() => ({ isloading: true, requestFailed: false }));

        const baseUrl = ActivitysummaryURL + '&DateRange=Last,5';
        if (this.activitycanellationToken) {
            this.activitycanellationToken.cancel('Operation canceled due to new request.')
        }
        this.activitycanellationToken = axios.CancelToken.source();
        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.activitycanellationToken)
            .then(result => {
                this.props.AddActivity(result.data);
                this.setState(() => ({ activityresponse: result.data, isloading: false, requestFailed: false }));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState(() => ({ requestFailed: true, error: error.message, isloading: false }))
                }
            })
    };


    componentDidUpdate(prevProps, prevState) {
        console.log('updated');
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.setDataForProspectClient()
            }
            else {
                this.execute(this.props.selectedaccount);
            }
        }
    }
    componentWillUnmount() {
        if (this.activitycanellationToken !== null && this.activitycanellationToken !== undefined) {
            this.activitycanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }
    componentDidMount() {
        if (this.state.isProspectClient) {
            this.setDataForProspectClient()
        }
        else {
            if (this.state.activityresponse === null)
                this.execute(this.state.accounts);
            else
                this.setState(() => ({ isloading: false }))
        }
    }
    customCaretSort(order, column) {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }
    setDataForProspectClient() {
        if (this.props.activitysummary === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ activityresponse: RRR_123456_activity_Response, isloading: false, areDataRetrieved: true }), () => { this.props.AddActivity(RRR_123456_activity_Response); });
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ activityresponse: RRR_234567_activity_Response, isloading: false, areDataRetrieved: true }), () => { this.props.AddActivity(RRR_234567_activity_Response); });
                }

            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ activityresponse: activityDummyResponse, isloading: false, areDataRetrieved: true }), () => { this.props.AddActivity(activityDummyResponse); });

            }
        }
        else
            this.setState(() => ({ isloading: false }))
    }
    render() {

        if (this.state.isloading) return <div className="RCM_spinner">Loading Account Activity{spinner()}</div>
        if (this.state.requestFailed) return <p className="RCM_data_error">{MsgForFailedData}</p>
        if (!this.state.activityresponse || !this.state.activityresponse.value || !this.state.activityresponse.value.length > 0)
            return <p className="RCM_data_error">No data available</p>

        const rowClasses = (row, rowIndex) => {
            if (rowIndex % 2 == 0)
                return 'RCM_Table_row_odd';
            else
                return 'RCM_Table_row_even';
        };
        var rows = [];
        this.state.activityresponse && this.state.activityresponse.value[0].activities.map((d, i) => rows.push(
            {
                dttrade: d.dttrade ? d.dttrade.split('T')[0] : '-',
                dtsettle: d.dtsettle ? d.dtsettle.split('T')[0] : '-',
                key: d.key,
                acct: d.acct,
                trndesc: d.trndesc,
                trntype: d.trntype,
                secid: d.secid,
                units: d.units,
                unitprice: d.unitprice,
                total: d.ispending ? d.total + '*' : d.total
            }
        )
        )
        //if (rows && rows.length > 0) {
        //    rows.sort(function (a, b) {
        //        var dateA = new Date(a.dtsettle);
        //        var dateB = new Date(b.dtsettle);
        //        return dateB - dateA;
        //    });
        //}
        if (rows && rows.length > 15) {
            rows = rows.slice(0, 14)
        }
        return (
            <ErrorBoundary key="accservice01">
                <AccountActivitySummaryView
                    rows={rows}
                    rowClasses={rowClasses}
                    selectedaccounts={this.props.selectedaccount}
                    key="accservice01"
                >
                </AccountActivitySummaryView>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        activitysummary: state.acct.activitysummary,
        render: state.acct.render,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        role:state.acct.role
    };
};

const mapDispatchToProps = {
    AddActivity
};
export default connect(mapStateToProps, mapDispatchToProps)(Activitysummary);
