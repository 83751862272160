import React from 'react';
import { printDocument } from './../../../common/printdocument.jsx';
import { downloadDocument } from './../../../common/downloaddocument.jsx';
import { Post } from './../../../common/servicecalls.jsx';
import { DocumentURL, formatMoney, roundUnitValue, getAccountDisplayName, IndLongShort  } from './../../../common/constants.jsx';
import axios from 'axios';
import pdfData from './../mockpdfresponse.js'
export function tradeconfirmsummarycolumns(
    state, authprops, selectedaccounts, role) {
    let columns = [
        {
            dataField: "objectId",
            text: "",
            sort: true,
            hidden: true
        },
        {
            dataField: "primaryValue",
            text: "Account Number",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell);
            }
        },
        {
            dataField: "summaryDate",
            text: "Summary Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
            align: (cell, row, rowIndex, colIndex) => (
                { width: '10px' }
            )
        },
        {

            dataField: "description",
            text: "Description",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),            
            headerAlign: 'left',
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {
            dataField: '',
            text: '',
            formatter: (cellContent, row) => (
                < div className="row " >
                    <a className="RCM_Cursor_Pointer" onClick={() => printDocuments(row, state, authprops, role)} title="Print">
                        <div className={"RCM_Documents_btn_img RCM_userMenu_Print"}></div>
                    </a>
                    <a className="RCM_Cursor_Pointer" onClick={() => downloadDocuments(row, state, authprops, role)} title="Download" >
                        <div className={"RCM_Documents_btn_img RCM_Download"}></div>
                    </a>                    
                </div >
            )
        }

    ];
    return columns;
}
export function tradeconfirmcolumns(state, authprops, selectedaccounts, role) {
    let columns = [
        {
            dataField: "objectId",
            text: "",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            hidden: true
        },
        {
            dataField: "primaryValue",
            text: "Account Number",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell);
            }
        },
        {
            dataField: "tradeDate",
            text: "Trade Date",
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },

        },
        {

            dataField: "description",
            text: "Description",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },

        },
        {

            dataField: "securityType",
            text: "SecurityType",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "symbol",
            text: "Symbol",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            headerAlign: 'left',            
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {

            dataField: "cusip",
            text: "Cusip",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell) return <span className="">{cell}</span>;                
                else return <span>-</span>;
            }
        },
        {

            dataField: "quantity",
            text: "Quantity",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell && cell !== '') {
                    let value = parseFloat(cell.trim())
                    return <span className="">{roundUnitValue(value, 2)}</span>;
                } else {
                    return <span>-</span>;
                }                
            }
        },        
        {

            dataField: "price",
            text: "Price",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            headerStyle: (colum, colIndex) => {
                return { 'white-space': 'nowrap' };
            },
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell < 0) return <span className="">{formatMoney(cell)}</span>;
                else if (cell > 0) return <span className="">{formatMoney(cell)}</span>;
                else return <span>-</span>;
            }
        },
        {
            dataField: 'Download/Print',
            text: 'Download/Print',
            formatter: (cellContent, row) => (
                < div className="row RCM_TradeConfirm_DownloadPrint_Column_Margin" >
                    <a className="RCM_Cursor_Pointer" onClick={() => printDocuments(row, state, authprops, role)} title="Print">
                        <div className={"RCM_Documents_btn_img RCM_userMenu_Print"}></div>
                    </a>
                    <a className="RCM_Cursor_Pointer" onClick={() => downloadDocuments(row, state, authprops, role)} title="Download" >
                        <div className={"RCM_Documents_btn_img RCM_Download"}></div>
                    </a>                    
                </div >
            )
        }

    ];
    return columns
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function printDocuments(row, state, authprops, role) {
    if (role === 'Prospect') {
        printDocument(pdfData)
    } else {
        let requestBody = JSON.stringify({
            'VirtualTable': state && state.isSearchByTradeConfirmSummary ? 'NF_TRADE_CONFIRMS' : 'NF_TRADES',
            'PrimaryType': 'Account',
            'PrimaryValue': row.primaryValue,
            'ObjectId': row.objectId,

        });

        let url = DocumentURL;
        let documentCanellationToken = null;
        if (documentCanellationToken) {
            documentCanellationToken.cancel('Operation canceled due to new request.')
        }
        documentCanellationToken = axios.CancelToken.source();
        Post(url, requestBody, authprops.token, authprops.contextjson, authprops.profilejson, documentCanellationToken)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData) {
                    printDocument(selectedDocPdfData)
                } else {
                    console.log("didn't receive document from webapi")
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }    
}

function downloadDocuments(row, state, authprops, role) {
    if (role === 'Prospect') {
        let fileName = '';
        if (state && state.isSearchByTradeConfirmSummary) {
            fileName = row.primaryValue.trim() + "_" + 'TradeConfirm' + "_" + row.summaryDate.trim();
        } else {
            fileName = row.primaryValue.trim() + "_" + 'TradeConfirm' + "_" + row.tradeDate.trim();
        }
        downloadDocument(pdfData, null, fileName);
    } else {
        let fileName = '';
        if (state && state.isSearchByTradeConfirmSummary) {
            fileName = row.primaryValue.trim() + "_" + 'TradeConfirm' + "_" + row.summaryDate.trim();
        } else {
            fileName = row.primaryValue.trim() + "_" + 'TradeConfirm' + "_" + row.tradeDate.trim();
        }
        let requestBody = JSON.stringify({
            'VirtualTable': state && state.isSearchByTradeConfirmSummary ? 'NF_TRADE_CONFIRMS' : 'NF_TRADES',
            'PrimaryType': 'Account',
            'PrimaryValue': row.primaryValue,
            'ObjectId': row.objectId,

        });

        let url = DocumentURL;
        let documentCanellationToken = null;
        if (documentCanellationToken) {
            documentCanellationToken.cancel('Operation canceled due to new request.')
        }
        documentCanellationToken = axios.CancelToken.source();
        Post(url, requestBody, authprops.token, authprops.contextjson, authprops.profilejson, documentCanellationToken)
            .then(res => {
                let selectedDocPdfData = res.data.value[0].ContentStream;
                if (selectedDocPdfData) {
                    downloadDocument(selectedDocPdfData, null, fileName);
                } else {
                    console.log("didn't receive document from webapi")
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }  

}


function acctFormatter(accounts, cell) {
    return (<div title={getAccountDisplayName(accounts, cell, IndLongShort.LONG)}>{getAccountDisplayName(accounts, cell, IndLongShort.SINGLEVIEW)}</div>)
}