import React from 'react';
import { Link } from 'react-router-dom';
import { MsgForNoData, spinner, MessageClass} from '../../../../common/constants.jsx';
import Messages from '../../../../common/messages.jsx';
import { AccountListColumns } from './subscribealertscolumns.jsx';
import BootstrapTable from 'react-bootstrap-table-next';
import ManageAlertsPopup from './managealertspopup.jsx';
import { AlertsConfirmationPopup } from './alertsconfirmationpopup.jsx';
 
export function SubscribeAlertsView(
    props
) {
    let subscribealertsview = (
        <div key="alerts01">
            {props && props.state ?
                <div id="RCM_SubscribeAlerts" className="RCM_tile_margin" key="alerts02">
                    {
                        (props && props.state && !props.state.isSubscribeAlertsLoading)
                            ? props && props.state && props.state.messages && props.state.messages.length > 0
                                ?
                                <div className="row RCM_Feedback_Message_Margin RCM_alert_wrapper" key="alerts03">
                                    <div className="col-sm-12 col-xs-12 col-md-11 col-lg-9" key="alerts04">
                                        <Messages messages={props && props.state && props.state.messages}
                                            containerClass={
                                                props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                                    ? "RCM_validation_error_container"
                                                    : ""
                                            } />
                                    </div>

                                </div>
                                : ""
                            : ""
                    }

                    <div className="row RCM_ManageGroup_heading_container RCM_alert_wrapper" key="alerts05">
                        <div className="RCM_Toast_heading col-sm-12 col-xs-12 col-md-12 col-lg-9" key="alerts06" id="RCM_Subscribe_Alerts">SUBSCRIBE ALERTS</div>
                    </div>
                    {props && props.state && props.state.isSubscribeAlertsLoading
                        ? <div className="RCM_spinner" key="alerts07">Loading Subscribe Alerts{spinner()}</div>
                        :

                        <div className="row RCM_alert_wrapper" key="alerts08">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-9" key="alerts09">
                                <div className="RCM_Toast RCM_subscribe_toast" key="alerts10">
                                    <div className="row RCM_Alerts_input_wrapper" key="alerts11">
                                        <div className="col-sm-6 RCM_Edelivery_email_wrapper" key="alerts12" id="RCM_Edelivery_email_wrapper">
                                            <div key="alerts13">
                                                <span className="labelSpan" key="alerts14"><label className="RCM_label profile-font-color" key="alerts15">Email Address</label></span><br />
                                                <span className="labelSpan RCM_label_note" key="alerts16"><label className="RCM_label profile-font-color" key="alerts17">*Enter Email address for alerts</label></span>
                                            </div>
                                            <div className="RCM_Manage_email_wrapper" align="left" key="alerts18">
                                                <div className="RCM_form_control_group" key="alerts19">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="RCM_form_control RCM_alert_inputBox"
                                                        value={props.state.email}
                                                        onChange={props.handleOnChange}
                                                        onBlur={props.handleOnBlur}
                                                        key="alerts20"
                                                        disabled
                                                      //  disabled={props.state.isProspectClient}
                                                    />
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        {props.state.showMobile
                                            ? <div className="col-sm-6 RCM_Edelivery_email_wrapper" key="alerts21" id="RCM_Edelivery_mobileNo_wrapper">
                                                <div key="alerts22">
                                                    <span className="labelSpan" key="alerts23"><label className="RCM_label profile-font-color" key="alerts24">Mobile</label></span><br />
                                                    <span className="labelSpan RCM_label_note" key="alerts25"><label className="RCM_label profile-font-color" key="alerts26">*Enter Mobile no for alerts.</label></span>
                                                </div>
                                                <div className="RCM_Manage_email_wrapper" align="left" key="alerts27">
                                                    <div className="RCM_form_control_group" key="alerts28">
                                                        <input
                                                            type="text"
                                                            name="mobileNo"
                                                            className="RCM_form_control RCM_alert_inputBox"
                                                            value={props.state.mobileNo}
                                                            onChange={props.handleOnChange}
                                                            onBlur={props.handleOnBlur}
                                                            key="alerts29"
                                                            disabled={props.state.isProspectClient}
                                                        />

                                                    </div>

                                                </div>
                                            </div>
                                            : <></>
                                        }
                                    </div>

                                    <div className="row row-margin" id="RMC_Subscribe_alert_account_list" key="alerts30">
                                        <div className="col-sm-12" key="alerts31">
                                            <BootstrapTable
                                                keyField='key'
                                                data={props.state.accountList}
                                                columns={AccountListColumns(props.toggleManageAlertsPopup, props.state.isEmailAdded, props.state.isMobileNoAdded, props.state.isProspectClient)}

                                                headerClasses="row-bg"
                                                bordered={false}
                                                classes="RCM_two_level_table1"
                                                noDataIndication="No data found"
                                            />

                                        </div>
                                    </div>
                                   
                                </div>
                            </div>

                        </div>
                    }
                    <ManageAlertsPopup
                        isManageAlertsPopupOpen={props.state.isManageAlertsPopupOpen}
                        state={props.state}
                        toggleManageAlerts={props.toggleManageAlerts}
                        handleAlertChange={props.handleAlertChange}
                        saveSelectedAccount={props.saveSelectedAccount}
                        applyAllAccounts={props.applyAllAccounts}
                            />
                    <AlertsConfirmationPopup
                        state={props.state}
                        toggleAlertsConfirmationPopup={props.toggleAlertsConfirmationPopup}
                        saveAlerts={props.saveAlerts}
                    />
                </div>
                : <div key="alerts32">{MsgForNoData}</div>
            }
        </div>
    )
    return subscribealertsview
}



