import React from 'react';
import { convertDttime, roundvalue, formatMoney, AssetallocationURL, spinner, roundUnitValue, GetAccountsList, MsgForNoData, MsgForFailedData, getAccountDisplayName, IndLongShort } from './../../../../common/constants.jsx';
import { getAssetColorConfig } from './../../../../common/assetschemautilities';
export function getHeaderColumnsByCategory(props, selectedaccounts, rowsortevent) {
    const headercolumnsbycategoty = [
        {
            dataField: 'assetcat',
            text: "Asset Class",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            width: 10,
            headerAlign: 'left',
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            footer: (cell) => {
                return <span>Total</span>;
            },
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: '123',
            text: "Account Number",
            width: 10,
            headerAlign: 'left',
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            footer: (cell) => {
                return <span></span>;
            },
            formatter: (cell,row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell, row.acctkey);;
            }
        },
        {
            dataField: 'secname',
            text: "Description",
            sort: false,
            sortCaret: (order, column) => customCaretSort(order, column),
            width: 10,
            headerAlign: 'left',
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            footer: (cell) => {
                return <span></span>;
            }
        },
        {
            dataField: 'shrqnts',
            text: "Quantity",
            sort: false,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            footer: (cell) => {
                return <span></span>;
            },
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>
                else if (cell === 0) return <span>0</span>;
                else return <span>{roundUnitValue(cell)}</span>;
            }
        },
        {
            dataField: 'pricing',
            text: "Price",
            sort: false,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }, onSort: (field, order) => {
                rowsortevent(field, order);
            }, footer: (cell) => {
                return <span></span>;
            }
        },
        {
            dataField: 'mktvalpct',
            text: 'Percentage',
            classes: 'RCM_common_table_column_number',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>0</span>;
                else return <span >{roundvalue(cell, 2)}%</span >;
            },
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            footer: (cell) => {
                if (props.totalPct === undefined || props.totalPct === null || props.totalPct === '') return <span>-</span>;
                else if (props.totalPct === 0) return <span>0%</span>;
                else return <span>{roundvalue(props.totalPct, 2)}%</span>;
            },
            footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
        },
        {
            dataField: 'mktval', text: 'Value',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            classes: 'RCM_common_table_column_number',
            headerAlign: 'right',
            onSort: (field, order) => {
                rowsortevent(field, order);
            },
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>0</span>;
                else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
            },
            footer: (cell) => {
                if (props.totalmkVal === undefined || props.totalmkVal === null || props.totalmkVal === '') return <span>-</span>;
                else if (props.totalmkVal === 0) return <span>$0</span>;
                else return <span>{formatMoney(roundvalue(props.totalmkVal, 2))}</span>;
            },
            footerClasses: (column, colIndex) => {
                return 'RCM_common_table_column_number';
            }
        }
    ];
    return headercolumnsbycategoty;
}

export function getColumnsForExpandedRow(props, selectedaccounts) {
    const columns = [
        {
            dataField: 'ticker',
            text: "Symbol",
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text RCM_tbl_aad',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerFormatter: (cell) => {
                return <span>Symbol</span>;
            },
            formatter: (cell, row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <div className="RCM_symbol_text_bold">{row.ticker}</div>;
            }
        },
        {
            dataField: 'acctnum',
            text: "Account Number",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            width: 10,
            headerAlign: 'left',
            formatter: (cell,row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return acctFormatter(selectedaccounts, cell, row.acctkey);
            }
        },
        {
            dataField: 'secname',
            text: "Description",
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text RCM_tbl_aad',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerFormatter: (cell) => {
                return <span>Description</span>;
            },
            formatter: (cell, row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'shrqnts',
            text: "Quantity",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>0</span>;
                else return <span>{roundUnitValue(cell)}</span>;
            }
        },
        {
            dataField: 'pricing',
            text: "Price",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }
        },
        {
            dataField: 'assettotalpct',
            text: 'Percentage',
            classes: 'RCM_common_table_column_number',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>0%</span>;
                else return <span >{roundvalue(cell,2)}%</span >;
            }
        },
        {
            dataField: 'mktvals',
            text: "Value",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                if (cell == 0) return <span>$0</span>;
                else return <span>{formatMoney(cell)}</span>;
            }
        }

    ]
    return columns;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function acctFormatter(accountlist, cell, key) {
    return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}</div>)
}
export function renderTemplateRows(rowasset, assetcolorRes, dropdownClassValue) {

    var assetallocationData = [];
    var i;
    for (i = 0; i < rowasset.value.length; i++) {
        if (rowasset.value[i].assetcat !== null) {
            let assetObj = getAssetColorConfig(rowasset.value[i].assetcat, rowasset.value[i].assetcatcd, assetcolorRes, dropdownClassValue)
            assetallocationData.push(
                {
                    assetcat: rowasset.value[i].assetcat,
                    secname: rowasset.value[i].secname,
                    shrqnts: rowasset.value[i].shrqnts,
                    pricing: rowasset.value[i].pricing,
                    mktvalpct: rowasset.value[i].mktvalpct,
                    mktval: rowasset.value[i].mktval,
                    invpossummary: rowasset.value[i].invpossummary,
                    colorCd: assetObj.colorcd,
                    displaySeq: assetObj.displayseq

                }
            )
        }

    }
    if (assetallocationData && assetallocationData.length > 0) {
        assetallocationData.sort(function (a, b) { return a.displaySeq - b.displaySeq; });
    }
    return assetallocationData;
}
