import React from 'react';
import { getAccountDisplayName, IndLongShort, getDateTimeVal } from './../../../../common/constants.jsx';
export function getAlertColumns(accounts) {
    const accountstatementscolumns = [
        {

            dataField: "accountnumber",
            text: "Account Number",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell,row) => {
                if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
                else return acctFormatter(accounts, cell, row.accountnumber, row.isread);
            },
        },
        {

            dataField: "alertdate",
            text: "Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell,row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span className={getClassName(row.isread)}>{getDateTimeVal(cell)}</span>;
            },
        },
        {

            dataField: "alerttype",
            text: "Type",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell,row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span className={getClassName(row.isread)}>{cell}</span>;
            },
        }, {

            dataField: "alertname",
            text: "Alert",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell,row) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span className={getClassName(row.isread)}>{cell}</span>;
            },
        }, 

    ];
    return accountstatementscolumns;
}
function getClassName(value) {
    if (value!==null && !value) {
        return 'RCM_Bold';
    }
    else {
        return '';
    }

}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function acctFormatter(accountlist, cell, key, isread) {
    return (<div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)} className={getClassName(isread)}>
        {!isread ? <span className="RCM_Alert_dot RCM_Bold"></span> : <span className="RCM_Alert_Padding_Left RCM_Bold"></span>}
        {getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}
    </div>)
}



