import React from 'react';
import Messages from '../../common/messages.jsx';
import { MessageClass } from '../../common/constants.jsx';
import { spinner } from '../../common/constants.jsx';

export function CaseManagerView(
    props
) {
    let casemanagerview = (
        <div className={"RCM_Toast " + (props.state.source === '' ? '' : ' RCM_modal_toast')}>
            <div className="row">
                {props.state.source === '' ? <div className="col-md-3 col-lg-3"></div>:<div></div>}
                
                <div className={(props.state.source === '' ? 'col-sm-12 col-md-6 col-lg-6' : ' col-sm-12 col-md-12 col-lg-12')} id="RCM_Case_manager">
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="RCM_helpsupport_label">Name</label>
                            <label className="RCM_helpsupport_label RCM_CaseMgr_Name_lbl">{props && props.state && props.state.name}</label>
                        </div>
                        <br />
                        <div className="col-sm-12">
                            <label className="RCM_helpsupport_label">Email</label>
                            <label className="RCM_helpsupport_label RCM_CaseMgr_Email_lbl">{props && props.state && props.state.emailid}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="RCM_helpsupport_label">Which section(s) of the website are you experiencing an issue with?</label>
                        </div>
                        <br />
                    </div>
                    <div className="row mt-2" id="RCM_issue_section">
                        <div className="col-sm-6">
                            <div className="react-bootstrap-table">
                                <table className="table RCM_two_level_table1">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="login" checked={props.state.isloginchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Login</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="logout" checked={props.state.islogoutchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Logout</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="accounts" checked={props.state.isaccountschecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Accounts</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="transfers" checked={props.state.istransferschecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Transfers</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="documents" checked={props.state.isdocumentschecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Statements and Documents</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="general" checked={props.state.isgeneralchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">General</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="react-bootstrap-table">
                                <table className="table RCM_two_level_table1">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="collaboration" checked={props.state.iscollaborationchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Collaboration</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="helpandsupport" checked={props.state.ishelpandsupportchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Help & Support</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="billpay" checked={props.state.isbillpaychecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Bill Pay</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label className="RCM_selectLabel">
                                                        <input type="checkbox" value="preferences" checked={props.state.ispreferenceschecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Preferences</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label>
                                                        <input type="checkbox" value="printandexport" checked={props.state.isprintandexportchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Print & Export</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="radio">
                                                    <label>
                                                        <input type="checkbox" value="assetaggregation" checked={props.state.isassetaggregationchecked}
                                                            onChange={props.selectSection} />
                                                        <span className="profile-font-color RCM_preference_label">Account Aggregation</span>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div className={"row RCM_Feedback_Message_Margin " + (props.state.source === '' ? '' : ' RCM_CM_modal_message_hidden')}>
                        <div className="col-sm-12">
                            {
                                (props && props.state && props.state.isCaseSubmitted) && (props && props.state && props.state.validateFeedForm)
                                    ? props && props.state && props.state.messages && props.state.messages.length > 0
                                        ? <Messages messages={props && props.state && props.state.messages}
                                            containerClass={
                                                props && props.state && props.state.messages && props.state.messages.find(msg => msg.class === MessageClass.ERROR)
                                                    ? "RCM_validation_error_container"
                                                    : ""
                                            } />

                                        : <div className="RCM_spinner">Submitting the case{spinner()}</div>
                                    : (((props.state.validateFeedForm === false) ||
                                        (props.state.sectionsWithIssue === '')) && props.state.isCaseSubmitted === false)
                                        ? <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                                        : ""
                            }

                        </div>
                    </div>
                    <div className="row RCM_Feedback_FeedbackDetails RCM_FeedbackDetails_modal">
                        <div className="col-sm-12">
                            <div className="RCM_form_control_group" id="RCM_Feedback_TextArea">
                                <label className="RCM_helpsupport_label">Please provide a brief summary of the issue(s) you are experiencing:</label>
                                <textarea
                                    name="feedback"
                                    placeholder=""
                                    className="RCM_form_control RCM_Feedback_TextArea"
                                    value={props && props.state && props.state.feedbackDetails}
                                    onChange={props.changeFeedBack}
                                    required
                                />

                            </div>
                        </div>
                    </div>
                    {props.state.source === 'AssetAggregation' && props.state.additionalInfo !== "" ? 
                        <div className="row RCM_Feedback_FeedbackDetails RCM_FeedbackDetails_modal">
                            <div className="col-sm-12">
                                <div className="RCM_form_control_group" id="RCM_Feedback_AdditionalInfo">
                                    <label className="RCM_helpsupport_label">Additional Info:</label>
                                    
                                    <div><label className="RCM_helpsupport_sublabel">{props.state.additionalInfo}</label></div>
                                </div>
                            </div>
                        </div>: <div></div>}
                    <div className="row RCM_Feedback_FeedbackDetails">
                        <div className="col-sm-12" id="RCM_feedback_file_upload">

                            <label htmlFor="file-upload" className="custom-file-upload">
                                Upload
                            </label>
                            <input type="file" className="form-control RCM_fileUpload" onChange={props.selectfile} id="file-upload" />
                            <label className="RCM_form_uploadbtn RCM_form_uploadbtn_lable">
                                {props.state.selectedfile && props.state.selectedfile.Name ? props.state.selectedfile.Name : 'No file chosen'}
                            </label>

                        </div>
                    </div>
                    <div className="row row-margin ">
                        <div className={"col-sm-12 RCM_Feedback_Submit " + (props.state.source === '' ? '' : ' RCM_caseManager_modal_btn_container')} id="RCM_Feedback_Submit">
                            <input className="btn RCM_button_primary RCM_select_btn" type="submit" value="Submit" onClick={props.submit} />
                            {props.state.source === '' ? <div></div> : <button type="button" className="btn btn-secondary RCM_button_secondary RCM_caseManager_modal_cancel_btn" onClick={props.toggleHelpAndSupportModal}>CANCEL</button>}
                        </div>
                    </div>
                </div>
                {props.state.source === '' ? <div className="col-md-3 col-lg-3"></div> : <div></div>}
            </div>
        </div>
    )
    return casemanagerview
}



