import React from "react";

class AddExternalAccountConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.processingTime = '7 business days';
    }
    render() {
        return (
            <div className="RCM_CM_form">
                <div className="row">
                    <div className="col-sm-12 RCM_Toast_heading" align="center">
                        ACCOUNT REQUEST SUBMITTED</div>
                </div>

                <div className="row">
                    <div className="col-sm-12 RCM_Text_large">
                        Please allow up to {this.processingTime} for the account verification process to
                        complete.
                            </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 RCM_CM_btn_long" align="center">
                        <button
                            className="btn RCM_button_primary RCM_select_btn"
                            onClick={this.props.backAsFresh}
                        >
                            ADD ANOTHER ACCOUNT
            </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddExternalAccountConfirm;
