import React, { useMemo, useEffect, useRef } from "react";
import { GetNumericValue, roundvalue, formatMoney } from "../constants";
import * as Highcharts from 'highcharts'

const PhilanthropyDonorHighChart = ({ data }) => {
  const chartContainerReference = useRef();
  const colors = useMemo(() => {
    return [
      "#13A5B0",
      "#B3E3F4",
      "#7BC46C",
      "#C4E1B2",
      "#B71F3A",
      "#EF4264",
      "#653C93",
      "#B02C91",
      "#F89938",
      "#F4B93F",
      "#45bda9",
    ];
  }, []);

  const seriesData = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x) => ({
        name: x.name,
        y: x.percentage,
        color: colors % colors.length,
        value: formatMoney(x.value),
      }));
    } else {
      return [];
    }
  }, [colors, data]);


  useEffect(() => {
    Highcharts.chart(chartContainerReference.current, {
      title: {
        text: "",
      },
      chart: {
        type: "pie",
        backgroundColor: "transparent",
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        pointFormat: `<b>{point.value} ({point.percentage:.2f}%)</b>`,
        backgroundColor: '#000',
        style: {
          color: '#fff',
          fontFamily: 'Avenir'
        },
        outside: false
      },
      series: [
        {
          name: "Asset Allocations",
          colorByPoint: true,
          data: seriesData,
          cursor: "pointer",
        },
      ],
    });
  }, [seriesData]);

  return (
    <div className="row">
      <div className="col-sm-8">
        <div ref={chartContainerReference}></div>
      </div>
      <div className="col-sm-4 RCM_legent_Wrapper">
        <ul className="RCM_legent_height RCM_CM_scroll">
          {data.map((asset, index) => {
            return (
              <li
                key={index}
                className={"RCM_aachartWrapper RCM_aachart_" + index}
              >
                <div className="RCM_aseetpct">
                  {asset.percentage && asset.percentage > 0
                    ? roundvalue(asset.percentage, 2) + "%"
                    : GetNumericValue(asset.percentage, true)}
                </div>
                <div className="RCM_assetName">
                  {" "}
                  <span>{asset.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(
  PhilanthropyDonorHighChart,
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps || {}) === JSON.stringify(nextProps || {})
      ? true
      : false;
  }
);
