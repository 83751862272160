var CryptoJS = require("crypto-js");

export function encrypt(text, salt) {
  const encrypted = CryptoJS.AES.encrypt(text, salt);
  return encrypted.toString();
}

export function decrypt(text, salt) {
  if (text) {
    const bytes = CryptoJS.AES.decrypt(text, salt);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
  return text;
}
