import React from 'react';
import { roundvalue, formatMoney, getDateString } from './../../../../common/constants.jsx';
export function ManageAssetColumns(addEditEnableddtoggleevt, openAssetDeletePopup,edit) {
    var columns = [];
    columns = [
        {
            dataField: 'account.info.custodianname', text: "Custodian/Name", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {

                if ((cell === null || cell === '') && (row.account.info.name === null || row.account.info.name === '')) return '-';
                else if (cell === null || cell === '') return <div>{row.account.info.name}</div>;
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'account.info.type', text: "Type", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'account.info.subassettype', text: "Asset Class", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'account.info.accountnumber', text: "Account", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'account.info.nickname', text: "Nickname",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'account.value.totalvalue', text: "Value",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'right', classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                let type = row.account.info.type;
                let assetClass = type === "Mortgage" || type === "Credit Card" || type === "Loan" || type === "Promissory Note Loan" ? "Liability" : "Asset";
                if (assetClass === 'Asset') {
                    if (cell === null || cell === '' || cell === undefined) return <div align="right"><span>-</span></div>;
                    else if (cell === 0) return <div align="right"><span>$0</span></div>;
                    else return <div align="right"><span>{formatMoney(roundvalue(cell, 2))}</span></div>;
                } else {
                    let outstandingbalance = row.account.value.outstandingbalance;
                    if (outstandingbalance === null || outstandingbalance === '' || outstandingbalance === undefined) return <div align="right"><span>-</span></div>;
                    else if (outstandingbalance === 0) return <div align="right"><span>$0</span></div>;
                    else {
                        let outstandingbalancevalue = "-" + outstandingbalance;
                        return <div align="right"><span>{formatMoney(roundvalue(outstandingbalancevalue, 2))}</span></div>;
                    }
                }
            }
            
        },
        {
            dataField: 'account.value.valueasof',
            text: "Value as of",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left', classes: 'RCM_common_table_column_text', 
            formatter: (cell, row) => {
                return <div>{getDateString(cell)}</div>;
            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            classes: 'RCM_manage_groups_delete_col',
            formatter: (cell, row) => {
                return <div className="RCM_edit_icon" title="Edit asset" onClick={() => edit(row)}></div>;
            },
            events: {
                // onClick: this.openConfirmationPopup
            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            classes: 'RCM_manage_groups_delete_col RCM_common_table_column_number',
            
            headerFormatter: (cell, row) => {
                return (<div align="right" className="RCM_icon_hand  RCM_manage_grp_new_group" onClick={addEditEnableddtoggleevt} id="RCM_addAsset_link">+ Add New</div>)
            },
            formatter: (cell, row) => {
                return <div className="RCM_manage_grp_delete_icon RCM_manageasset_icon_position RCM_sp_details" title="Remove asset" onClick={() => openAssetDeletePopup(row)}></div>;
            },
            events: {
               // onClick: this.openConfirmationPopup
            }
        }
    ];

    return columns;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}