import { UPDATE_AGGREGATED_ACCT,UPDATE_PREFERENCE } from "./actiontypes.jsx";
import { getUpdatedAccountList } from "../common/constants.jsx";
//import assetallocationdetails from "../components/accounts/detail/assetallocation/assetallocationdetails.jsx";

export const UPDATE_ACCOUNT = "Account:updateAccount";
export const ADD_ACTIVITY = "Activity:addActivity";
export const ADD_ACTIVITY_DETAILS = "Activity:addActivityDetails";
export const ADD_ACTIVITY_DETAILS_FOR_SELECTED_ACCOUNTS = "Activity:addActivityDetailsForSelectedAccounts";
export const ADD_BALANCES = "Balances:addBalances";
export const ADD_TOPTEN = "Topten:addTopten";
export const ADD_AA = "AA:addAA";
export const ADD_STATEMENTS = "Statement:addStatementList";
export const ADD_ACCOUNTSTATEMENTS = "AccountStatement:addAccountStatementList";
export const ADD_CORRESPONDENCEDOCUMENTS = "Correspondence:addCorrespondenceDocumentList";
export const ADD_TRADECONFIRMS = "TradeConfirms:addTradeConfirmDocumentList";
export const ADD_TRADECONFIRMSUMMARY = "TradeConfirmSummary:addTradeConfirmSummaryDocumentList";
export const ADD_TAXDOCUMENTS = "TaxDocuments:addTaxDocumentList";
export const ADD_AADETAILS = "AssetAllocation:Details";
export const ADD_HISTORICAL_AADETAILS = "HistoricalAssetAllocation:Details";
export const ADD_HISTORICAL_SUMMARY_AADETAILS = "HistoricalAssetAllocation:Summary";
export const ADD_HISTORICAL_BALANCE_DETAILS = "HistoricalBalance:details";
export const ADD_BALANCEDETAILS = "Balance:Details";
export const ADD_HOLDINGSBYSECURITY = "Holdings:Security"
export const ADD_HOLDINGSBYACCOUNT = "Holdings:Account"
export const ADD_HOLDINGSBYCATEGORY = "Holdings:Category"
export const ADD_ACTIVITYDETAILS = "Activity:Details"
export const SET_ACCOUNTOVERVIEWFLAG = "Security:Flag"
export const UPDATE_ACCOUNT_NICKNAME = "updateNickName"
export const UPDATE_ACCOUNT_NICKNAME_FLAG = "updateNickNameFlag"
export const UPDATE_PROFILE_RESPONSE = "profileResp"
export const UPDATE_ACCOUNT_SELECTOR = "updateAccountSelector"
export const UPDATE_APPSTORE = "UPDATE_APPSTORE";
export const ADD_TOKEN = "ADD_TOKEN";
export const ADD_LIABILITIES = "liabilities";
export const GROUPVIEW = 'GROUPVIEW';
export const UPDATE_SUBSCRIBE_ALERTS = 'SubscribeAlerts';
export const ADD_ALERT_NOTIFICATION_DETAILS = "AlertNotification";
export const ADD_MANUAL_ASSET = "ManualAsset:add";
export const ADD_AGGREGATED_ACCOUNT="AssetAggregation:add"
export const UPDATE_ASSET_SCHEMA_SUMMARY = "update:assetschemaSummary";
export const UPDATE_ASSET_SCHEMA_DETAILS = "update:assetschemaDetails";

export const UPDATE_PERFORMANCE_ACCOUNT_DETAILS = "update:performanceAccountDetails";
export const UPDATE_PERFORMANCE_SUMMARY_DETAILS = "update:performanceSummaryDetails";
export const UPDATE_PERFORMANCE_SUMMARY_TABLE = "update:performanceSummaryTable";
export const UPDATE_PERFORMANCE_SUMMARY_CHART = "update:performanceSummaryChart";

export const ADD_ASSETCOLOR = "add:AddAssetColor"
export const SET_ACCOUNT_HOLDING="balanceDetails:setholdingaccount"
export const UPDATE_HISTORICAL_ASSET_SCHEMA="update:historicalassetschema"




export function AddToken(token){
    return {
        type: ADD_TOKEN,
        payload: token
    }
}

export function UpdateStoreWithRequiredAppData(data) {
    return {
        type: UPDATE_APPSTORE,
        payload: data
    }
}
export function UpdateAccount(newAccount, brender, isAccountContextChanged) {
    return {
        type: UPDATE_ACCOUNT,
        render: !brender,
        selectedaccount: newAccount,
        balancessummary: null,
        activitysummary: null,
        activitydetails: null,
        toptensummary: null,
        assetallocationsummary: null,
        balancedetails: null,
        assetallocationdetails: null,
        holdingsbyaccount: null,
        holdingsbysecurity: null,
        holdingsbycategory: null,
        isAccountContextChanged: isAccountContextChanged,
        newHoldingAccount:null
    }
}
export function AddActivity(newActivitysummary) {
    return {
        type: ADD_ACTIVITY,
        activitysummary: newActivitysummary
    }
}
export function AddActivityDetails(newActivityDetails) {
    return {
        type: ADD_ACTIVITY_DETAILS,
        activitydetails: newActivityDetails
    }
}
export function AddActivitiesForSelectedAccounts(newActivityDetailsForSelectedAccounts) {
    return {
        type: ADD_ACTIVITY_DETAILS_FOR_SELECTED_ACCOUNTS,
        activitydetailsforselectedaccounts: newActivityDetailsForSelectedAccounts
    }
}
export function AddBalances(newBalancessummary) {
    return {
        type: ADD_BALANCES,
        balancessummary: newBalancessummary
    }
}
export function AddTopten(newToptensummary) {
    return {
        type: ADD_TOPTEN,
        toptensummary: newToptensummary
    }
}
export function AddAA(newAAsummary) {
    return {
        type: ADD_AA,
        assetallocationsummary: newAAsummary
    }
}
export function AddAssetAllocationDetails(newAssetAllocationDetails) {
    return {
        type: ADD_AADETAILS,
        assetallocationdetails: newAssetAllocationDetails
    }
}

export function AddHistoricalAssetAllocationDetails(newHistoricalAssetAllocationDetails) {
    return {
        type: ADD_HISTORICAL_AADETAILS,
        historicalAssetAllocationDetails: newHistoricalAssetAllocationDetails
    }
}

export function AddHistoricalAssetAllocationSummaryDetails(newHistoricalAssetAllocationDetails) {
    return {
        type: ADD_HISTORICAL_SUMMARY_AADETAILS,
        historicalAssetAllocationSummaryDetails: newHistoricalAssetAllocationDetails
    }
}
export function AddHistoricalBalanceDetails(newHistoricalBalanceDetails) {
    return {
        type: ADD_HISTORICAL_BALANCE_DETAILS,
        historicalBalanceDetails: newHistoricalBalanceDetails
    }
}

export function AddBalanceDetails(newBalanceDetailsDetails) {
    return {
        type: ADD_BALANCEDETAILS,
        balancedetails: newBalanceDetailsDetails
    }
}
export function SetAccountOverviewFlag(newAccountOverviewFlag) {
    return {
        type: SET_ACCOUNTOVERVIEWFLAG,
        accountOverviewFlag: newAccountOverviewFlag
    }
}
export function SetHoldingAccount(newAccount) {
    return {
        type: SET_ACCOUNT_HOLDING,
        newHoldingAccount: newAccount
    }
}
export function updateAggregatedAccounts(data) {
    return {
        type: UPDATE_AGGREGATED_ACCT,
        payload: data
    }
}

export function updatePreference(preferencesObj) {
    return {
        type: UPDATE_PREFERENCE,
        preferences: preferencesObj
    }
}

export function updateAccountNickName(accounts,flag){
    return{
        type: UPDATE_ACCOUNT_NICKNAME,
        accounts :accounts,
        updateNickNameFlag :flag
    }
    
}


export function updateAccountSelector(profileObj, flag, profilejson, accountkey=""){
    return{
        type: UPDATE_ACCOUNT_SELECTOR,
        profileObj: profileObj,
        acctSelectorChangeReason: flag,
        deletedManualAccountKey: accountkey,
        accounts: getUpdatedAccountList(profileObj),
        profilejson: profilejson
    }
}

export function AddLiabilites(liabilities) {
    return {
        type: ADD_LIABILITIES,
        liabilities: liabilities
    }
}

export function UpdateSelectorView(view){
    return{
        type: GROUPVIEW,
        payload: view
    }
}
export function updateSubscribeAlerts(accountList) {
    return {
        type: UPDATE_SUBSCRIBE_ALERTS,
        accountList: accountList
    }
}
export function addAlertNotificationDetails(alertList) {
    return {
        type: ADD_ALERT_NOTIFICATION_DETAILS,
        alertList: alertList
    }
}

export function addManualAsset(manualAssetList) {
    return {
        type: ADD_MANUAL_ASSET,
        manualAssetList: manualAssetList
    }
}

export function addAggregatedAccounts(aggregatedAccountList) {
    return {
        type: ADD_AGGREGATED_ACCOUNT,
        aggregatedAccountList: aggregatedAccountList
    }
}
export function UpdateAssetSchemaAssetSummary(assetschema) {
    return {
        type: UPDATE_ASSET_SCHEMA_SUMMARY,
        assetSchema: assetschema,
        assetallocationdetails: null
    }
}
export function UpdateAssetSchemaAssetDetails(assetschema) {
    return {
        type: UPDATE_ASSET_SCHEMA_DETAILS,
        assetSchema: assetschema,
        assetallocationsummary: null
    }
}
export function UpdatePerformanceAccountsDetails(accounts) {
    return {
        type: UPDATE_PERFORMANCE_ACCOUNT_DETAILS,
        perfromanceAccounts: accounts   
    }
}
export function UpdatePerformanceSummaryDetails(accounts) {
    return {
        type: UPDATE_PERFORMANCE_SUMMARY_DETAILS,
        perfromanceSummaryDetails: accounts
    }
}
export function UpdatePerformanceSummaryTable(accounts, columns) {
    return {
        type: UPDATE_PERFORMANCE_SUMMARY_TABLE,
        perfromanceSummaryTable: {
            accounts: accounts, columns: columns
        }
    }
}
export function UpdatePerformanceSummaryChart(accounts) {
    return {
        type: UPDATE_PERFORMANCE_SUMMARY_CHART,
        perfromanceSummaryChart: accounts
    }
}

export function UpdateHistoricalAssetSchema(assetschema) {
    return {
        type: UPDATE_HISTORICAL_ASSET_SCHEMA,
        assetSchema: assetschema,
        historicalAssetAllocationDetails: null
    }
}


export function AddAssetColor(newAssetColor) {
    return {
        type: ADD_ASSETCOLOR,
        assetschemacolorsconfig: newAssetColor
    }
}