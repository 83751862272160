import React from 'react'
import HealthConfirmationModal from './healthModal';
import Watermark from '../../../common/watermark';
import ConfirmationModal from '../confirmationModal';
export default function Healthview(props) {

    const learnMoreSectionText = 'Click the button below to access a comprehensive overview of PinnacleCare information for Rockefeller Clients.';
    const alreadyWheelupMemberText = 'If you are currently a PinnacleCare Member and activated your Electronic Medical Records, please click the button below to log in to your Member Portal.';
    const haveQuestionText = 'Speak directly with a PinnacleCare Executive, who is exclusively dedicated to Rockefeller Clients.';

    return (
        <div className="container">
            {props.isProspectClient ? <Watermark /> : ''}
            <img id="health_image" src="./../images/health-bgimg.jpg" alt="Health" width="100%" className="RCM_health_base_image" />
            <div className="row">
                <div className={"col mt-1 RCM_health_wrapper col-sm-12"}>
                    <div className={"row"}>
                        <div className="col col-sm-4 mt-1">
                            <div className="RCM_Toast RCM_health_toast" id="health_learnmore">
                                <div className="RCM_health_moreinfo"></div>
                                <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">LEARN MORE</div>
                                <div className="mt-2 RCM_aviation_toast_content_container">{learnMoreSectionText}</div>
                                <div align="center" className="mt-4 RCM_lifestyle_btns"><button className="btn btn-primary RCM_button_aviation_page" onClick={props.openPinnacleCareLearnMore} disabled={props.isProspectClient} >VIEW DETAILS</button></div>
                            </div>

                        </div>

                        <div className="col col-sm-4 mt-1">
                            <div className="RCM_Toast RCM_health_toast" id="health_member">
                                <div className="RCM_health_memberportal"></div>
                                <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">ALREADY A PINNACLECARE MEMBER?</div>
                                <div className="mt-2 RCM_aviation_toast_content_container">{alreadyWheelupMemberText}</div>
                                <div align="center" className="mt-4 RCM_lifestyle_btns"><button className="btn btn-primary RCM_button_aviation_page" onClick={props.alreadyMemberClick} disabled={props.isProspectClient} >ACCESS MEMBER ACCOUNT</button></div>
                            </div>
                        </div>
                        <div className="col col-sm-4 mt-1">
                            <div className="RCM_Toast RCM_health_toast" id="health_contact">
                                <div className="RCM_health_contact"></div>
                                <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">DO YOU HAVE QUESTIONS?</div>
                                <div className="mt-2 RCM_aviation_toast_content_container">{haveQuestionText}</div>
                                <div align="center" className="mt-4 RCM_lifestyle_btns"><button className="btn btn-primary RCM_button_aviation_page" onClick={props.contactPinnacleCare} disabled={props.isProspectClient} >CONTACT PINNACLECARE</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HealthConfirmationModal  
                healthModalToggle={props.healthModalToggle} 
                healthModalState={props.healthModalState}
                openSelectedSectionWindow = {props.openSelectedSectionWindow}
            />
            <ConfirmationModal
                confirmationModalToggle={props.confirmationModalToggle}
                confirmationModalState={props.confirmationModalState}
                openSelectedSectionWindow={props.openSelectedSectionWindowNew}
                selectedSection={props.selectedSection}
            />
        </div>
    )
}
