import React, { Component } from 'react';
import { Get, Guid } from '../../../../common/servicecalls.jsx';
import { roundvalue, formatMoney, BalancessummaryURL, BalancesdetailURL, spinner, MsgForFailedData, GetNumericValue, CheckIfProspectClient } from '../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { AddBalances, AddBalanceDetails } from '../../../../actions/accountsactions.jsx';
import { BalanceSummaryView } from './balanceview.jsx'
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import axios from 'axios';
import { balancesummaryDummyResponse, balancedetailsDummyResponse, RRR_123456_balance_details, RRR_234567_balance_details } from '../../accountsmockresponse.js';
class Balancessummary extends Component {
    constructor(props) {
        super(props)
        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            requestFailed: false,
            index: 0,
            direction: null,
            messages: [],
            isProspectClient: CheckIfProspectClient(this.props.clientRole)
        }
    }
   
    handleSelect(selectedIndex, e) {
        this.setState({
            index: selectedIndex,
            direction: e.direction,
        });
    }
    balDetailscanellationToken = null;
    balSummarycanellationToken = null;
    execute = () => {
        this.setState({ isloading: true, requestFailed: false });
        //get data for balanced summary 
        if (this.props.balancessummary === null) {
            var balancesummaryUrl = BalancessummaryURL + "&k1=" + Guid();
            this.getBalanceData(balancesummaryUrl, this.balSummarycanellationToken, this.props.AddBalances)
        }
        //get data for balanced details 
        if (this.props.balancedetails === null) {
            var balancedetailsUrl = BalancesdetailURL + "&k1=" + Guid();
            this.getBalanceData(balancedetailsUrl, this.balDetailscanellationToken, this.props.AddBalanceDetails)
        }

    }
    getBalanceData(baseURL, canellationToken, propsData) {
        const baseUrl = baseURL + "";
        if (canellationToken) {
            canellationToken.cancel('Operation cancelled due to new request.')
        }
        canellationToken = axios.CancelToken.source();
        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, canellationToken)
            .then(result => {
                propsData(result.data);
                this.setState({ isloading: false, requestFailed: false })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    this.setState({ requestFailed: true, error: error.message })
                }
            })
    }

    componentWillUnmount() {
        if (this.balDetailscanellationToken !== null && this.balDetailscanellationToken !== undefined) {
            this.balDetailscanellationToken.cancel('Cancelling the Account Details during unmount to prevent memory leak.');
        }
        if (this.balSummarycanellationToken !== null && this.balSummarycanellationToken !== undefined) {
            this.balSummarycanellationToken.cancel('Cancelling the Bal Summary Token during unmount to prevent memory leak.');
        }
    }
    componentDidUpdate(prevProps) {


        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummyResponseForProspectCLient();
            }
            else {
                this.execute(this.props.selectedaccount);
            }
            if (this.props.isTokenRefreshed !== undefined && this.props.isTokenRefreshed !== prevProps.isTokenRefreshed) {
                if (this.state.isProspectClient) {
                    this.SetDummyResponseForProspectCLient();
                }
                else {
                    this.execute(this.props.selectedaccount);
                }
            }
        }
    }
    componentDidMount() {
        if (this.state.isProspectClient) {
            this.SetDummyResponseForProspectCLient();
        } else {
            if (this.props.balancessummary === null || this.props.balancedetails === null)
                this.execute(this.props.selectedaccount);
        }
    }
    getClassName(val) {
        if (val === '' || val === null || val === undefined) {
            return '';
        } else if (val >= 0) {
            return "RCM_positiveval";
        }
        else {
            return "RCM_negativeval";
        }
    }
    SetDummyResponseForProspectCLient() {
        if (this.props.balancessummary === null) {
            this.setState(() => ({ isloading: false, requestFailed: false }), () => {
                let response = balancesummaryDummyResponse;
                this.props.AddBalances(response);
            });
        }
        //get data for balanced details 
        if (this.props.balancedetails === null) {
            this.setState(() => ({ isloading: false, requestFailed: false }), () => {
                if (this.props.selectedaccount.length === 1) {
                    if (this.props.selectedaccount[0].key === "RRR_123456_balance_details") {
                        this.props.AddBalanceDetails(RRR_123456_balance_details);
                    }
                    else if (this.props.selectedaccount[0].key === "RRR_234567_balance_details") {
                        this.props.AddBalanceDetails(RRR_234567_balance_details);
                    }
                } else if (this.props.selectedaccount.length === 2) {
                    this.props.AddBalanceDetails(balancedetailsDummyResponse);
                }
            });
        }
    }

    render() {
        if (this.state.requestFailed) {
            return <p className="RCM_data_error">{MsgForFailedData}</p>
        }
        if (!this.props.balancessummary && this.props.clientRole !== "Prospect") {
            return <div className="RCM_spinner">Loading Balances{spinner()}</div>
        }

        var rows = [];
        var rows4 = [];
        var rows5 = [];
        //  console.log(this.props.balancessummary);
        if (this.props.balancessummary && this.props.balancessummary.value && this.props.balancessummary.value !== 0 ) {
            this.props.balancessummary.value.map((d) => {

                rows.push(
                    {
                        name: 'Priced Investments', value: ((d.pricedinvestments === undefined || d.pricedinvestments === null || d.pricedinvestments === 0) ? '$0'
                            : formatMoney(roundvalue(d.pricedinvestments, 2))), change: roundvalue(d.pricedinvestmentschange, 2)
                    },
                    {
                        name: 'Cash/Money Accounts', value: ((d.cashmoneyaccounts === undefined || d.cashmoneyaccounts === null || d.cashmoneyaccounts === 0) ? '$0'
                            : formatMoney(roundvalue(d.cashmoneyaccounts, 2))), change: roundvalue(d.cashmoneyaccountschange, 2)
                    },
                    {
                        name: 'Money Account Value', value: ((d.moneyaccountvalue === undefined || d.moneyaccountvalue === null || d.moneyaccountvalue === 0) ? '$0'
                            : formatMoney(roundvalue(d.moneyaccountvalue, 2))), change: roundvalue(d.moneyaccountvaluechange, 2)
                    },
                    {
                        name: 'Cash Balance', value: ((d.cashbalance === undefined || d.cashbalance === null || d.cashbalance === 0) ? '$0'
                            : formatMoney(roundvalue(d.cashbalance, 2))), change: roundvalue(d.cashbalancechange, 2)
                    }
                )
            });

        }

        let pricedinvestments = "-";
        let cashmoneyaccounts = "-";
        let totalaccountvalue = "-";
        let totalaccountvaluechangepct;
        let pricedinvestmentschange;
        let pricedinvestmentspercentchange;
        let annuity = '-';
        let pendingactivity = '-';
        let othercreditdebit = '-';
        let marginandoutstandingbalance = '-'

        if (this.props.balancedetails && this.props.balancedetails.value && this.props.balancedetails.value.length > 0 && this.props.balancedetails.value[0] && this.props.balancedetails.value[0].accountbalances) {
            pricedinvestments = this.props.balancedetails.value[0].pricedinvestments ? formatMoney(this.props.balancedetails.value[0].pricedinvestments) : GetNumericValue(this.props.balancedetails.value[0].pricedinvestments);
            cashmoneyaccounts = this.props.balancedetails.value[0].cashmoneyaccounts ? formatMoney(this.props.balancedetails.value[0].cashmoneyaccounts) : GetNumericValue(this.props.balancedetails.value[0].cashmoneyaccounts);
            totalaccountvalue = this.props.balancedetails.value[0].totalaccountvalue ? formatMoney(this.props.balancedetails.value[0].totalaccountvalue) : GetNumericValue(this.props.balancedetails.value[0].totalaccountvalue);
            totalaccountvaluechangepct = this.props.balancedetails.value[0].totalaccountvaluepercentchange; //?// roundvalue(this.props.balancedetails.value[0].totalaccountvaluepercentchange) : GetNumericValue(this.props.balancedetails.value[0].totalaccountvaluepercentchange,true);
            pricedinvestmentschange = this.props.balancedetails.value[0].pricedinvestmentschange;
            pricedinvestmentspercentchange = this.props.balancedetails.value[0].pricedinvestmentspercentchange;
            annuity = this.props.balancedetails.value[0].annuity ? formatMoney(this.props.balancedetails.value[0].annuity) : GetNumericValue(this.props.balancedetails.value[0].annuity);
            pendingactivity =  this.props.balancedetails.value[0].pendingactivity ? formatMoney(this.props.balancedetails.value[0].pendingactivity) : GetNumericValue(this.props.balancedetails.value[0].pendingactivity);
            othercreditdebit =  this.props.balancedetails.value[0].othercreditdebit ? formatMoney(this.props.balancedetails.value[0].othercreditdebit) : GetNumericValue(this.props.balancedetails.value[0].othercreditdebit);
            marginandoutstandingbalance =  this.props.balancedetails.value[0].marginandoutstandingbalance ? formatMoney(this.props.balancedetails.value[0].marginandoutstandingbalance) : GetNumericValue(this.props.balancedetails.value[0].marginandoutstandingbalance);
        }

        rows4.push(
            {
                name: 'Total Account Value', value: totalaccountvalue
            },
            {
                name: 'Priced Investments', value: pricedinvestments
            },
            {
                name: 'Cash/Money Accounts', value: cashmoneyaccounts
            },
            {
                name: 'Insurance & Annuities', value: annuity
            },
            {
                name: 'Other Credit/Debit', value: othercreditdebit
            }
        );
        rows5.push(
            {
                name: "Today's Change", value: pricedinvestmentschange
            },
            {
                name: 'Pending Activity', value: pendingactivity
            },
            {
                name: 'Margin/Outstanding Balance', value: marginandoutstandingbalance
            }

        );

        return (
            <ErrorBoundary>
                <BalanceSummaryView
                    state={this.state}
                    rows={rows}
                    rows4={rows4}
                    rows5={rows5}
                    handleSelect={this.handleSelect}
                    index={this.state.index}
                    direction={this.state.direction}
                    getClassName={this.getClassName}
                    totalaccountvaluechangepct={totalaccountvaluechangepct}
                    pricedinvestmentspercentchange={pricedinvestmentspercentchange}
                />
            </ErrorBoundary>

        )
    }
}

const mapStateToProps = state => {
    return {
        balancessummary: state.acct.balancessummary,
        balancedetails: state.acct.balancedetails,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        render: state.acct.render,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        clientRole: state.acct.role,
        isTokenRefreshed: state.acct.isTokenRefreshed,
        preferences: state.acct.preferences
    };
};

const mapDispatchToProps = {
    AddBalances,
    AddBalanceDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Balancessummary);
