import React, {useState, useEffect} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormGroup } from 'reactstrap';

 const UpdateNicknameModalPopup = (props) => {

    let nickNameInput;
    const [nickname, setNickname] = useState('');
     const alphanumericFormat = /^[a-zA-Z 0-9';/-]+$/;

    useEffect(
        () => {
            let acctNickName = (props && props.state && props.state.nicknameUpdateRow && props.state.nicknameUpdateRow.nickname) ? props.state.nicknameUpdateRow.nickname : '';
            setNickname(acctNickName);
        }, [props]
    )

    const updateNickName = () => {
        if(props && props.state && props.state.nicknameUpdateRow){
            props.updateNickName(nickname, props.state.nicknameUpdateRow);
        }
    }

    return (
        <div>
            <Modal isOpen={props.state.nicknameModal} toggle={props.toggleNicknameModal} onOpened={() => onModalWindowOpened(nickNameInput)}
                    zIndex='9999'
                    className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                    centered >
                    <ModalHeader toggle={props.toggleNicknameModal} className="RCM_externalAssetModel_header RCM_manage_group_header">
                        <label className="RCM_tile_heading">UPDATE NICKNAME</label>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder=""
                                name="nickname"
                                className="RCM_form_control"
                                maxLength="50"
                                required
                                value={nickname}
                                onChange={(event) => setNickname((event.target.value === "" || alphanumericFormat.test(event.target.value))?event.target.value:nickname)}
                                ref={ele => { nickNameInput = ele }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="RCM_manage_group_footer">
                        <button key="Dropdown15" type="button" className="btn btn-secondary RCM_button_secondary" 
                            onClick={props.toggleNicknameModal}>CANCEL</button>{' '}
                         <button key="Dropdown16" type="button" className="btn btn-primary RCM_button_primary" 
                            onClick={updateNickName}
                         >UPDATE NICKNAME</button>                       
                    </ModalFooter>
                </Modal>
        </div>
    )
}

const onModalWindowOpened = (nickNameInput) => {
    if(nickNameInput){
      nickNameInput.focus();
    }
  }

export default UpdateNicknameModalPopup;



