import React, { useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import PerformanceView  from './performanceview';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { getNestedObject } from '../../../MoneyMovement/common/utilty';
import { GetPreferenceValue } from '../../../../common/constants';
import { setPreferenceService, setPreferenceCancellationToken } from './../../../../services/preference/preferenceservices';
import { updatePreference } from "../../../../actions/accountsactions";

const Performance = () => {
    
    const [tooltipTableChartOpen, setTooltipTableChartOpen] = useState(false);
    const [showTable, setShowTable] = useState(true); 
    const [tooltipDetailsOpen, setTooltipDetailsOpen] = useState(false);
    const dispatch = useDispatch();
    const contextJson = useSelector(state => getNestedObject(state, ['acct', 'contextjson']));
    const authToken = useSelector(state => getNestedObject(state, ['acct', 'token']));
    const profileJson = useSelector(state => getNestedObject(state, ['acct', 'profilejson']));
    const profileId = useSelector(state => getNestedObject(state, ['acct', 'profileId']));
    const acct = useSelector(state => getNestedObject(state, ['acct']));
    const render = useSelector(state => getNestedObject(state, ['acct', 'render']));

    useEffect(() => {
        //getPreferenceData();
        //getAccountPerformanceData();
    }, [render]);

    useEffect(() => {
        return () => {
            if (setPreferenceCancellationToken !== null && setPreferenceCancellationToken !== undefined) {
                setPreferenceCancellationToken.cancel("Cancelling set preference request on unmount to prevent memory leak.");
            }

        }
    }, []);
    const updatePreferences = useCallback(
        (perfObject) => dispatch(updatePreference(perfObject)),
        [dispatch]
    );

    const getPreferenceData = () => {
        let showtable = GetPreferenceValue(acct, "Performance", "chart") === "table" ? true : false;
        setShowTable(showtable);
    }
   
    const updatePreferenceCallBack = () => {
        let componentjson = [];
        let changedValue = !showTable;
        componentjson.push({ "key": "Performance", "value": changedValue ? "table" : "chart" });
        let preferencejson = {};
        preferencejson.component = componentjson;
        let requestBody = {
            profileid: parseInt(profileId),
            preferencejson: preferencejson,
        }
        let reqObj1 = JSON.parse(JSON.stringify(acct.preferences));//specifically for updating store
        if (reqObj1 && reqObj1.component) {
            let performancePreferenceValue = reqObj1.component.filter(component => component.key === "Performance");
            if (performancePreferenceValue && performancePreferenceValue.length > 0) {
                reqObj1.component[reqObj1.component.findIndex(component => component.key === "Performance")].value = changedValue ? "table" : "chart";
            } else {
                reqObj1.component.push({ "key": "Performance", "value": changedValue ? "table" : "chart" });
            }

        }
        setPreferenceService(requestBody, authToken, contextJson, profileJson)
            .then(res => {
                if (res.status === 200) {
                    updatePreferences(reqObj1);
                    console.log("updated successfully");
                }
                setShowTable(prevShowtable => !prevShowtable);
            })
            .catch(error => {
                console.error(error);
                setShowTable(prevShowtable => !prevShowtable);
            })
        }
    
    return (
        
        <>   
            <ErrorBoundary>
                <PerformanceView tooltipTableChartOpen={tooltipTableChartOpen}
                    setTooltipTableChartOpen={setTooltipTableChartOpen}
                    showTable={showTable}
                    updatePreferenceCallBack={updatePreferenceCallBack}
                    tooltipDetailsOpen={tooltipDetailsOpen}
                    setTooltipDetailsOpen={setTooltipDetailsOpen} />
            </ErrorBoundary>    

        </>
    )

};

export default Performance;