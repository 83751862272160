import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import InspiratoConfirmationModal from "./inspiratoConfirmationModal";

const InspiratoContainer = () => {
  const inspiratoText =
    "An exclusive planning and booking desk just for Rockefeller clients seeking unforgettable vacations. Clients have access at member-only rates to unique Inspirato Club settings, from luxury vacation homes, top hotels and resorts and unique travel excursions. Complimentary Family Sharing privileges allow clients' children and parents to enjoy Inspirato trips of their own. ";
  
  const inspiratoURL = useSelector((store) => store?.env?.inspiratoURL)
  const [isInspiratoModalOpen, setInspiratoModalOpen] = useState(false);
  const handleViewInspiratoClick = useCallback(() => {
    setInspiratoModalOpen(true);
  }, []);

  const handleInspiratoConfirmClick = useCallback(() => {
    window.open(inspiratoURL)
    setInspiratoModalOpen(false);
  }, [inspiratoURL]);

  const toogleInspiratoModal = useCallback(() => {
    setInspiratoModalOpen((prev) => !prev);
  }, []);

  return (
    <div className="container">
      <img
        id="inspirato_image"
        src="./../images/Inspirato.png"
        alt="Inspirato"
        width="100%"
        className="RCM_health_base_image"
      />
      <div>
        <div className="row RCM_health_container">
          <div className="col col-sm-12 mt-1">
            <div
              className="RCM_Toast RCM_health_toast"
              id="RCM_inspirato_details"
            >
              <div className="RCM_lifestyle_homeThrive"></div>
              <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
                INSPIRATO
              </div>
              <div className="mt-2 RCM_aviation_toast_content_container">
                {inspiratoText}
              </div>
              <div className="mt-4 RCM_lifestyle_btns" align="center">
                <button className="btn btn-primary RCM_button_aviation_page" onClick={handleViewInspiratoClick}>
                  CLICK HERE TO VIEW INSPIRATO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isInspiratoModalOpen && (
        <InspiratoConfirmationModal
          isOpen={isInspiratoModalOpen}
          toogleModal={toogleInspiratoModal}
          onContinueClick={handleInspiratoConfirmClick}
        />
      )}
    </div>
  );
};

export default InspiratoContainer;
