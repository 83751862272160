import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
    // ButtonGroup
} from "reactstrap";
import DateRanges from "../../../../common/dateranges";
import { historicalAssetClassDropDownItems} from './../../../../common/assetschemautilities'

export class HistoricalAssetAllocationFiltersComponent extends PureComponent {
    chartTypes;
    assetGroupings;
    constructor(props) {
        super(props);

        const { dateRangeOptions, initialFilters, isProspectClient, assetSchema, chartType} = props;

        this.chartTypes = [
            { type: "area", display: "Area" },
            { type: "bar", display: "Bar" }
        ];
        this.assetGroupings = [
            { type: "assetType", display: "Type" },
            { type: "assetSubType", display: "Sub Type" }
        ];
        this.state = {
            dateRangeDropdownOpen: false,
            filters: initialFilters || {
                range: dateRangeOptions[0].value
            },
            dropdownClassValue: assetSchema,
            classDropdownOpen: false,
            chartType: chartType
        };
    }
    toggleClass=()=> {
        this.setState(prevState => ({
            classDropdownOpen: !prevState.classDropdownOpen
        }));
    }
    changeClass=selectedClass =>{
        
        this.setState({
            dropdownClassValue: selectedClass.currentTarget.textContent.trim(),
           
        }, () => {
                this.props.onChange(this.state.filters, this.state.dropdownClassValue);
                this.props.UpdateAssetSchemaAssetDetails(this.state.dropdownClassValue);
                this.props.UpdateAssetSchemaAssetSummary(this.state.dropdownClassValue);
                this.props.UpdateAssetSchema();

        });
    }
    onFromDateChanged = fromDate => {
        this.setState({ filters: { ...this.state.filters, fromDate } });
    };

    onToDateChanged = toDate => {
        this.setState({ filters: { ...this.state.filters, toDate } });
    };

    onDateRangeDropdownToggle = () => {
        const { dateRangeDropdownOpen } = this.state;
        this.setState({
            dateRangeDropdownOpen: !dateRangeDropdownOpen
        });
    };

    onDateRangeSelected = event => {
        const { dateRangeOptions } = this.props;
        const range = dateRangeOptions.filter(
            x => x.value === event.currentTarget.getAttribute("value")
        )[0];
        this.setState({
            filters: { ...this.state.filters, range },
            dateRangeDropdownOpen: false
        });

        if (range.value !== DateRanges.CUSTOMRANGE.value) {
            this.props.onChange({ range }, this.state.dropdownClassValue);
        }
    };

    onCustomDateRangeApplied = () => {
        const { fromDate, toDate } = this.state.filters;
        this.props.onChange({
            range: DateRanges.CUSTOMRANGE,
            fromDate,
            toDate
        }, this.state.dropdownClassValue);
    };

    render() {
        const {
            dateRangeOptions,
            isProspectClient,
            chartType
            // assetGrouping,
            // onAssetGroupingChanged,
            // onChartTypeChanged
        } = this.props;
        const { dateRangeDropdownOpen, filters, classDropdownOpen, dropdownClassValue} = this.state;

        const now = new Date();

        const selectedDateRangeText = (dateRangeOptions || []).filter(
            x => x.value === filters.range.value
        )[0].label;

        const groupStyles = { flexShrink: 0, padding: "0 5px" };

        return (
            <form>
                {/* <div className="form-group">
          <label className="RCM_Date_lbl text-left w-100">CHART TYPE</label>
          <ButtonGroup>
            {this.chartTypes.map(x => (
              <Button
                key={x.type}
                color="secondary"
                size="sm"
                active={chartType === x.type}
                onClick={() => onChartTypeChanged(x.type)}
              >
                {x.display}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <div className="form-group">
          <label className="RCM_Date_lbl text-left w-100">ASSET GROUPING</label>
          <ButtonGroup>
            {this.assetGroupings.map(x => (
              <Button
                key={x.type}
                color="secondary"
                size="sm"
                active={assetGrouping === x.type}
                onClick={() => onAssetGroupingChanged(x.type)}
              >
                {x.display}
              </Button>
            ))}
          </ButtonGroup>
        </div> */}
                <div className="d-flex flex-row align-content-start" id="RCM_Historical_dateRange_filter">
                    <div className="form-group RCM_displayflex RCM_form_group_inline" style={groupStyles}>
                        <label className="RCM_Date_lbl text-left w-auto d-block">
                            DATE RANGE
            </label>
                        <Dropdown 
                            isOpen={dateRangeDropdownOpen}
                            toggle={this.onDateRangeDropdownToggle}
                            className="RCM_select_box RCM_mm_subselect_box"
                            
                        >
                            <DropdownToggle className="pr-2 text-left pl-0" className={isProspectClient ? "RCM_Prospect_Disabled" : ''} caret
                                disabled={isProspectClient}>
                                {selectedDateRangeText}
                            </DropdownToggle>
                            <DropdownMenu>
                                {dateRangeOptions.map((item, index) => (
                                    <DropdownItem
                                        key={index}
                                        onClick={this.onDateRangeSelected}
                                        value={item.value}
                                        disabled={isProspectClient}
                                        className={isProspectClient ? "RCM_Prospect_Disabled" : ''}
                                    >
                                        <div>{item.label}</div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    {filters.range.value === DateRanges.CUSTOMRANGE.value ? (
                        <>
                            <div className="form-group" style={groupStyles}>
                                <label className="RCM_Date_lbl text-left w-auto d-block">
                                    FROM DATE
                </label>
                                <DatePicker
                                    className="RCM_Document_Datepicker"
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    style={{ width: "100px" }}
                                    selected={filters.fromDate}
                                    maxDate={filters.toDate}
                                    placeholderText="MMMM yyyy"
                                    onChange={this.onFromDateChanged}
                                    

                                ></DatePicker>
                            </div>
                            <div className="form-group" style={groupStyles}>
                                <label className="RCM_Date_lbl text-left w-auto d-block">
                                    TO DATE
                </label>
                                <DatePicker
                                    className="RCM_Document_Datepicker"
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={filters.toDate}
                                    maxDate={now}
                                    minDate={filters.fromDate}
                                    placeholderText="MMMM yyyy"
                                    onChange={this.onToDateChanged}
                                />
                            </div>
                            <div className="form-group" style={groupStyles}>
                                <Button
                                    color="btn btn-primary RCM_button_primary RCM_select_btn RCM_reduce_btn_size"
                                    onClick={this.onCustomDateRangeApplied}
                                >
                                    APPLY
                </Button>
                            </div>
                        </>
                    ) : null}
                    {chartType==='Bar'? 
                    <div className="form-group RCM_displayflex RCM_form_group_inline" style={groupStyles}>
                        <label className="RCM_Date_lbl text-left w-auto d-block">
                            TYPE
            </label>
                            <Dropdown
                            isOpen={classDropdownOpen}
                                toggle={this.toggleClass}
                            className="RCM_select_box RCM_mm_subselect_box"
                            key="addropdown1">
                            <DropdownToggle className="pr-2 text-left pl-0" key="addropdown2" className={isProspectClient ? "RCM_Prospect_Disabled" : ''} caret
                                disabled={isProspectClient}>
                                    {
                                        dropdownClassValue
                                    }
                                </DropdownToggle>
                                <DropdownMenu >
                                {historicalAssetClassDropDownItems && historicalAssetClassDropDownItems.map((item, index) =>
                                        <DropdownItem key={index} onClick={this.changeClass} disabled={isProspectClient}>
                                            <div>{item.label}</div>
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div> : ''}
                       
                   
                </div>
            </form>
        );
    }
}
