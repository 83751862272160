import React from "react";
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import JournalPreConfirm from "./journalPreConfirm.jsx";
import { getEligiblePair, mmvalidateTransfer, getEligiblePairCancellationToken, mmValidateTransferCancellationToken, getHolidayList, getHolidayListCancellationToken } from "./moneymovementService.jsx";
import {
    getFromAccounts, getEligibleToAccounts, getTransferLimitAndDate,
    getContributionYears, validateTransferInput,
    identifyRetirementContribution, identifyLimits, getInternalExternalAccts, calculateCutOffVarbiage, 
    identifyAchTransfer, identifyJournalHolidays, identifyAchHolidays, identifyRetirementDisbursment, identifyRetirementDisbursmentPilot
} from "./models/AccountInfo.js";
import { spinner } from "../../common/constants.jsx";
import RCM_Modal from "./common/modal.jsx";
import OverlayProcessing from "./common/overlayProcessing.jsx";
import { TransfersErrors, errorObjTransfers } from "./models/transfersError.js";
import Messages from "../../common/messages.jsx";
import { noEligiblePairExist } from "./common/constants.js";
import { filterAggregatedAccounts } from "./common/utilty.js";
import DatePicker from 'react-datepicker';
import { getDay, addYears, isToday, addDays  } from 'date-fns';
import TransferWithhold from "./transferWithhold.jsx";

export class JournalEntry extends React.Component {
    constructor(props) {
        super(props);
        //console.log("Printing properties ", props);

        this.topLevelAccounts = [];
        if (this.props.accounts !== null
            && this.props.accounts !== undefined
            && Array.isArray(this.props.accounts)
            && this.props.accounts.length > 0) {
            this.topLevelAccounts =  filterAggregatedAccounts(this.props.accounts);
        }

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if(this.props.contextjson !== null && this.props.contextjson !== undefined)
        {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if(this.props.profilejson !== null && this.props.profilejson !== undefined)
        {
            this.profilejson = this.props.profilejson;
        }

        //Add the pilot check for retirement distribution
        this.retirementDisAllowed = identifyRetirementDisbursmentPilot(this.props.preferences);
        

        this.holidayList = [];
        
        this.state = {
            eligiblePairAccts: [],
            fromAcctList: [],
            fromAcctInternalList: [],
            fromAcctExternalList: [],
            toAcctList: [],
            toAcctInternalList: [],
            toAcctExternalList: [],
            contributionYearList: [],
            excludeTransferDates: [],
            transfers: {
                fromAccount: "",
                toAccount: "",
                fromAcctDisplayName: "",
                toAcctDisplayName: "",
                transferType: "One-Time",
                transferDate: "",
                transferAmount: "",
                displayAmount: "",
                //transferMemo: "",
                contributionType: "",
                contributionYear: "",
                accountType: "",
                transferLimit: "",
                preConfirmDisclouser: "",
                isRetirement: false,
                cutoff: "",
                isfuturedated: false,
                isRetirementDisbursment: false
            },

            transfersDescription: "",
            showNextScreen: false,
            errorMsg: "",
            isLoading: false,
            showOverlay: false,
            disableBtnClear: true,
            disableBtnSubmit: true,
            disclouserModal: false,
            apiTransferCutoff: "",

            fromAccountDropdownOpen: false,
            toAccountDropdownOpen: false,
            contributionYrDropdownOpen: false,

            //Error Messages
            transfersErrors: new TransfersErrors()

        };

        this.submitTransfer = this.submitTransfer.bind(this);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.backToEdit = this.backToEdit.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.backToLending = this.backToLending.bind(this);
        this.toggleDisclouserModal = this.toggleDisclouserModal.bind(this);
        this.fromAccountDropdownToggle = this.fromAccountDropdownToggle.bind(this);
        this.toAccountDropdownToggle = this.toAccountDropdownToggle.bind(this);
        this.contributionYrDropdownToggle = this.contributionYrDropdownToggle.bind(this);
        this.onFromAccountChange = this.onFromAccountChange.bind(this);
        this.onToAccountChange = this.onToAccountChange.bind(this);
        this.onContributionYrChange = this.onContributionYrChange.bind(this);
        this.setEligiblePairData = this.setEligiblePairData.bind(this);
        this.refreshJournalEntry = this.refreshJournalEntry.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.isWeekday = this.isWeekday.bind(this);
        this.OpenJournalEntry = this.OpenJournalEntry.bind(this);
        
    }

    onFromAccountChange(fromAcct) {
        let clonedState = Object.assign({}, this.state);
        clonedState.transfers.fromAccount = fromAcct.accountNumber;
        clonedState.transfers.fromAcctDisplayName = fromAcct.displayName;
        clonedState.transfers.toAccount = "";
        clonedState.transfers.toAcctDisplayName = "";
        clonedState.transfers.transferLimit = "";
        clonedState.transfers.cutoff = "";
        clonedState.apiTransferCutoff = "";
        clonedState.toAcctList = getEligibleToAccounts(fromAcct.accountNumber, this.state.eligiblePairAccts, this.topLevelAccounts);
        clonedState.toAcctInternalList = getInternalExternalAccts(clonedState.toAcctList, false);
        clonedState.toAcctExternalList = getInternalExternalAccts(clonedState.toAcctList, true);
        if(fromAcct.accountNumber !== "")
            clonedState.transfersErrors.fromAccountErrorMsg = "";
        this.setState(clonedState);
    }

    onToAccountChange(toAcct) {
        let clonedState = Object.assign({}, this.state);
        let isRetirementContribution = identifyRetirementContribution(toAcct.accountNumber, this.state.toAcctList);
        let limits = identifyLimits(toAcct.accountNumber, this.state.toAcctList);

        let isAchTransfer = identifyAchTransfer(toAcct, this.state.transfers.fromAccount, this.state.eligiblePairAccts);

        isAchTransfer ? 
            clonedState.excludeTransferDates =  identifyAchHolidays(this.holidayList, toAcct.cutoff) :  
            clonedState.excludeTransferDates = identifyJournalHolidays(this.holidayList, toAcct.cutoff)

        if (!isRetirementContribution) {
            let transferDateLimit = getTransferLimitAndDate(this.state.transfers.fromAccount, toAcct.accountNumber, this.state.eligiblePairAccts, false);
            clonedState.transfers.transferLimit = transferDateLimit.limit;
            clonedState.transfers.transferDate = transferDateLimit.transferDate;
            clonedState.transfers.isRetirement = isRetirementContribution;

            if(clonedState.transfers.transferDate !== "")
              clonedState.transfersErrors.transferDateErrorMsg = "";

            clonedState.transfers.isRetirementDisbursment = identifyRetirementDisbursment(toAcct);

        } else {
            clonedState.transfers.transferLimit = "";
            clonedState.transfers.transferDate = "";
            clonedState.transfers.isRetirement = isRetirementContribution;
            clonedState.transfers.contributionType = "";
            clonedState.transfers.contributionYear = "";
            clonedState.contributionYearList = getContributionYears(limits);
            clonedState.transfers.isRetirementDisbursment = false;
        }
        clonedState.transfers.toAccount = toAcct.accountNumber;
        clonedState.transfers.toAcctDisplayName = toAcct.displayName;
        clonedState.transfers.cutoff = toAcct.cutoff;
        clonedState.apiTransferCutoff = toAcct.cutoff;
        if(toAcct.accountNumber !== "")
            clonedState.transfersErrors.toAccountErrorMsg= "";
        this.setState(clonedState);
    }

    onContributionYrChange(contribution) {

        let transferDateLimit = getTransferLimitAndDate(
            this.state.transfers.fromAccount,
            this.state.transfers.toAccount,
            this.state.eligiblePairAccts,
            true, contribution.value);

        let clonedState = Object.assign({}, this.state);
        clonedState.transfers.contributionType = contribution.value;
        clonedState.transfers.contributionYear = contribution.label;
        clonedState.transfers.transferLimit = transferDateLimit.limit;
        clonedState.transfers.transferDate = transferDateLimit.transferDate;
        if(contribution.value !== "")
            clonedState.transfersErrors.contributionYearErrorMsg = "";

        if(clonedState.transfers.transferDate !== "")
            clonedState.transfersErrors.transferDateErrorMsg = "";
        this.setState(clonedState);
    }

    fromAccountDropdownToggle() {
        this.setState(prevState => ({
            fromAccountDropdownOpen: !prevState.fromAccountDropdownOpen
        }));
    }

    toAccountDropdownToggle() {
        if (this.state.transfers.fromAccount !== "") {
            this.setState(prevState => ({
                toAccountDropdownOpen: !prevState.toAccountDropdownOpen
            }));
        }
    }

    contributionYrDropdownToggle() {
        this.setState(prevState => ({
            contributionYrDropdownOpen: !prevState.contributionYrDropdownOpen
        }));
    }

    componentDidMount() {
        /* Call the eligible accounts service if we have selected one valid account*/
        window.addEventListener("refreshJournalEntry", this.refreshJournalEntry);
        this.setEligiblePairData();
        this.setHolidayList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render && this.props.isAccountContextChanged !== undefined && this.props.isAccountContextChanged !== true ) {
            this.setState({
                errorMsg: "",
                transfers: {
                    fromAccount: "",
                    toAccount: "",
                    fromAcctDisplayName: "",
                    toAcctDisplayName: "",
                    transferType: "One-Time",
                    transferDate: "",
                    transferAmount: "",
                    displayAmount: "",
                    //transferMemo: "",
                    contributionType: "",
                    contributionYear: "",
                    accountType: "",
                    transferLimit: "",
                    preConfirmDisclouser: "",
                    isRetirement: false,
                    cutoff: "",
                    isfuturedated: false,
                    isRetirementDisbursment: false
                },
                showNextScreen: false,
            });
            this.setEligiblePairData();
            this.setHolidayList();
        }
    }

    refreshJournalEntry(event)
    {
        //console.log("Refreshing journal entry", event);
        this.setState({showNextScreen: false}, () => {
            this.backToLending();
        })
    }

    setHolidayList() {
        getHolidayList(this.authToken, this.contextjson, this.profilejson)
            .then(data => {
                if (data) {

                    let recievedHolidayList = data;
                    let holidayList = [];
                    for (const [index, item] of recievedHolidayList.entries()) {
                        let holiday = addDays(new Date(item.date), 1);
                        holidayList.push( 
                            {date: holiday, 
                            isjournalallowed: item.isjournalallowed, 
                            isachallowed: item.isachallowed});
                    }

                    this.holidayList = holidayList;
                }   
            })
            .catch(error => {
                console.error(error);
            })
    }

    setEligiblePairData()
    {
        if (this.topLevelAccounts.length > 0 && this.requesterId !== "") {
            this.setState({showOverlay: true}, () => {
                getEligiblePair(this.topLevelAccounts, this.retirementDisAllowed, this.requesterId, this.authToken, this.contextjson, this.profilejson)
                .then(data => {
                    if(data && data.eligiblePairs && Array.isArray(data.eligiblePairs) && data.eligiblePairs.length === 0){
                        const clonedState = Object.assign({}, this.state);
                        clonedState.errorMsg = noEligiblePairExist;
                        clonedState.isLoading = false;
                        clonedState.showOverlay = false;
                        clonedState.transfersDescription = "";
                        clonedState.disableBtnClear = true;
                        clonedState.disableBtnSubmit = true;
                        this.setState(clonedState);
                    }else{
                        const clonedState = Object.assign({}, this.state);
                        clonedState.eligiblePairAccts = data.eligiblePairs;
                        clonedState.fromAcctList = getFromAccounts(data.eligiblePairs,this.topLevelAccounts);
                        clonedState.fromAcctInternalList = getInternalExternalAccts(clonedState.fromAcctList, false);
                        clonedState.fromAcctExternalList = getInternalExternalAccts(clonedState.fromAcctList, true);
                        clonedState.errorMsg = "";
                        clonedState.transfersDescription = data.disclouser;
                        clonedState.isLoading = false;
                        clonedState.showOverlay = false;
                        clonedState.disableBtnClear = false;
                        clonedState.disableBtnSubmit = false;
                        this.setState(clonedState);
                    }
                })
                .catch(error => {
                    console.error(error);
                    const clonedState = Object.assign({}, this.state);
                    clonedState.errorMsg = error;
                    clonedState.isLoading = false;
                    clonedState.showOverlay = false;
                    clonedState.transfersDescription = "";
                    clonedState.disableBtnClear = true;
                    clonedState.disableBtnSubmit = true;
                    this.setState(clonedState);
                });
            })
            
        }
    }

    componentWillUnmount()
    {
        window.removeEventListener("refreshJournalEntry", this.refreshJournalEntry);

        if(getEligiblePairCancellationToken !== null && getEligiblePairCancellationToken !== undefined)
        {
            getEligiblePairCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
        }

        if(mmValidateTransferCancellationToken !== null && mmValidateTransferCancellationToken !== undefined)
        {
            mmValidateTransferCancellationToken.cancel("Cancelling validate transfers request on unmount to prevent memory leak.");
        }

        if (getHolidayListCancellationToken !== null && getHolidayListCancellationToken !== undefined) {
            getHolidayListCancellationToken.cancel("Cancelling Holiday List request on unmount to prevent memory leak.");
        }
        
    } 
    
    toggleDisclouserModal() {
        this.setState(prevState => ({
            disclouserModal: !prevState.disclouserModal
        }));
    }



    handleFormChanges(evt) {
        const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/;
        if (evt.target.name === "transferAmt") {
            if (
                (evt.target.value === "" || amountFormat.test(evt.target.value)) &&
                evt.target.value.length <= 12
            ) {
                let clonedState = Object.assign({}, this.state);
                clonedState.transfers.displayAmount = evt.target.value;
                this.setState(clonedState);
            }
        }
    }

    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);
            clonedState.transfers.transferAmount = evt.target.value;
            clonedState.transfers.displayAmount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            if(clonedState.transfers.transferAmount !== "")
                clonedState.transfersErrors.transferAmountErrorMsg = "";
            
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            clonedState.transfers.transferAmount = evt.target.value;
            this.setState(clonedState);
        }
    }

    handleAmmountOnFocus(event) {
        const clonedState = Object.assign({}, this.state);
        if (clonedState.transfers.displayAmount === "") {
            clonedState.transfers.displayAmount = "";
            clonedState.transfers.transferAmount = "";
        } else {
            clonedState.transfers.displayAmount = clonedState.transfers.transferAmount;
        }
        this.setState(clonedState);
    }


    submitTransfer(evt) {
        this.setState(Object.assign({}, this.state, { isLoading: false, errorMsg: "", showOverlay: true, transfersErrors: new TransfersErrors }));
        let validationResponse = validateTransferInput(this.state.transfers);
        if (!validationResponse.isError){
            if(this.state.transfers.isRetirementDisbursment){
                this.OpenWithHolding();
            }else{
                this.OpenJournalEntry();
            }
        }else {
            let clonedState = Object.assign({}, this.state);
            clonedState.showNextScreen = false;
            clonedState.showOverlay = false;
            clonedState.transfersErrors = validationResponse.transfersError;
            clonedState.errorMsg = "";
            this.setState(clonedState);
        }
    }

    OpenWithHolding(){
        this.setState({showOverlay: false, showNextScreen: true});
    }

    OpenJournalEntry(){      
        mmvalidateTransfer(this.state.transfers, this.state.eligiblePairAccts, this.authToken, this.contextjson, this.profilejson)
            .then(data => {
                let disclouser = "";
                if (data.disclosure !== null && data.disclosure !== undefined)
                    disclouser = data.disclosure;

                let clonedState = Object.assign({}, this.state);
                clonedState.showNextScreen = true;
                clonedState.showOverlay = false;
                clonedState.errorMsg = "";
                clonedState.transfers.preConfirmDisclouser = disclouser;
                this.setState(clonedState);
            })
            .catch(error => {
                let clonedState = Object.assign({}, this.state);
                clonedState.showNextScreen = false;
                clonedState.showOverlay = false;
                clonedState.errorMsg = error;
                clonedState.transfers.preConfirmDisclouser = "";
                this.setState(clonedState);
            });
    }

    backToEdit() {
        this.setState(Object.assign({}, this.state, { showNextScreen: false }));
    }

    handleDateChange(date) {

        if(date && Date.parse(date)){
            let transfers = this.state.transfers;
            if (isToday(date)) {
                transfers.isfuturedated = false;
            } else {
                transfers.isfuturedated = true;
            }
            transfers.transferDate =  date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
            
            //Check condition if selected date is future dated then date else nothing 
            if(transfers.isfuturedated){
                transfers.cutoff = date;
            }else{
                transfers.cutoff = this.state.apiTransferCutoff;
            }

            
            this.setState({
                transfers: transfers, 
            });
        }
    }

    isWeekday = date => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };
    backToLending() {
        let clonedState = Object.assign({}, this.state);
        clonedState.transfers = {
            fromAccount: "",
            toAccount: "",
            fromAcctDisplayName: "",
            toAcctDisplayName: "",
            transferType: "One-Time",
            transferDate: "",
            transferAmount: "",
            displayAmount: "",
            //transferMemo: "",
            contributionType: "",
            contributionYear: "",
            accountType: "",
            transferLimit: "",
            preConfirmDisclouser: "",
            isRetirement: false,
            cutoff: "",
            isfuturedated: false
        };
        clonedState.errorMsg = "";
        clonedState.transfersErrors = new TransfersErrors();
        clonedState.showNextScreen = false;
        this.setState(clonedState, () => {
            //one the data has been refreshed.
            this.setEligiblePairData();
        });
    }

    render() {
        if (!this.state.showNextScreen) {
            return (
                <div>
                    {
                        this.state.showOverlay === true ? (
                            <OverlayProcessing />
                        ) : ""
                    }

                    {
                        this.state.isLoading ? (
                            <div>
                                {spinner()}
                            </div>
                        ) : ""
                    }

                    {
                        this.state.errorMsg !== "" ?
                            (<div className="RCM_validation_error_container" >
                                <div className="RCM_msg_text">{this.state.errorMsg}</div>
                            </div>) : ""
                    }

                    <div className="">
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="RCM_Toast_heading">TRANSFER FUNDS</div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <div className="RCM_CM_form_label">
                                            <label>TRANSFER FROM</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <Messages
                                            messages={(this.state.transfersErrors.fromAccountErrorMsg !== "") ? [errorObjTransfers.fromAccount] : []}
                                            containerClass="" />
                                    </div>
                                </div>
                                <Dropdown name="fromaccount" required
                                    isOpen={this.state.fromAccountDropdownOpen}
                                    toggle={this.fromAccountDropdownToggle}
                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                    <DropdownToggle caret>{this.state.transfers.fromAcctDisplayName}</DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            (this.state.fromAcctInternalList !== null
                                                && this.state.fromAcctInternalList !== undefined
                                                && Array.isArray(this.state.fromAcctInternalList)
                                                && this.state.fromAcctInternalList.length > 0) ? (
                                                    <div>
                                                        <DropdownItem header>Accounts at Rockefeller</DropdownItem>
                                                        {/* <DropdownItem divider></DropdownItem> */}
                                                        {this.state.fromAcctInternalList.map((account, index) =>
                                                            <DropdownItem key={index} onClick={() => this.onFromAccountChange(account)}>
                                                                <div title={account.accountNumber}>{account.displayName}</div>
                                                            </DropdownItem>
                                                        )}
                                                    </div>
                                                ) : ""
                                        }

                                        {
                                            (this.state.fromAcctExternalList !== null
                                                && this.state.fromAcctExternalList !== undefined
                                                && Array.isArray(this.state.fromAcctExternalList)
                                                && this.state.fromAcctExternalList.length > 0) ? (
                                                    <div>
                                                        <DropdownItem header>External Accounts</DropdownItem>
                                                        {/* <DropdownItem divider></DropdownItem> */}
                                                        {this.state.fromAcctExternalList.map((account, index) =>
                                                            <DropdownItem key={index} onClick={() => this.onFromAccountChange(account)}>
                                                                <div title={account.accountNumber}>{account.displayName}</div>
                                                            </DropdownItem>
                                                        )}
                                                    </div>
                                                ) : ""
                                        }

                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12" >
                                <div className="row">
                                    <div className="col-sm-5">
                                        <div className="RCM_CM_form_label">
                                            <label>TRANSFER TO</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <Messages
                                            messages={(this.state.transfersErrors.toAccountErrorMsg !== "") ? [errorObjTransfers.toAccount] : []}
                                            containerClass="" />
                                    </div>
                                </div>
                                <Dropdown name="toaccount" required
                                    isOpen={this.state.toAccountDropdownOpen}
                                    toggle={this.toAccountDropdownToggle}
                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                    <DropdownToggle caret>{this.state.transfers.toAcctDisplayName}</DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            (this.state.toAcctInternalList !== null
                                                && this.state.toAcctInternalList !== undefined
                                                && Array.isArray(this.state.toAcctInternalList)
                                                && this.state.toAcctInternalList.length > 0) ? (
                                                    <div>
                                                        <DropdownItem header>Accounts at Rockefeller</DropdownItem>
                                                        {/* <DropdownItem divider></DropdownItem> */}
                                                        {this.state.toAcctInternalList.map((account, index) =>
                                                            <DropdownItem key={index} onClick={() => this.onToAccountChange(account)}>
                                                                <div title={account.accountNumber}>{account.displayName}</div>
                                                            </DropdownItem>
                                                        )}
                                                    </div>
                                                ) : ""
                                        }

                                        {
                                            (this.state.toAcctExternalList !== null
                                                && this.state.toAcctExternalList !== undefined
                                                && Array.isArray(this.state.toAcctExternalList)
                                                && this.state.toAcctExternalList.length > 0) ? (
                                                    <div>
                                                        <DropdownItem header>External Accounts</DropdownItem>
                                                        {/* <DropdownItem divider></DropdownItem> */}
                                                        {this.state.toAcctExternalList.map((account, index) =>
                                                            <DropdownItem key={index} onClick={() => this.onToAccountChange(account)}>
                                                                <div>{account.displayName}</div>
                                                            </DropdownItem>
                                                        )}
                                                    </div>
                                                ) : ""
                                        }

                                    </DropdownMenu>
                                </Dropdown>
                                
                            </div>
                            
                        </div>
                        {
                            this.state.transfers.isRetirement === false ? "" : (
                                <div className="row RCM_CM_form_row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="RCM_CM_form_label">
                                                    <label>CONTRIBUTION YEAR</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <Messages
                                                    messages={(this.state.transfersErrors.contributionYearErrorMsg !== "") ? [errorObjTransfers.contributionYear] : []}
                                                    containerClass="" />
                                            </div>
                                        </div>
                                        
                                        <Dropdown name="contributionYr" required
                                            isOpen={this.state.contributionYrDropdownOpen}
                                            toggle={this.contributionYrDropdownToggle}
                                            className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                            <DropdownToggle caret>{this.state.transfers.contributionYear}</DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    (this.state.contributionYearList !== null
                                                        && this.state.contributionYearList !== undefined
                                                        && Array.isArray(this.state.contributionYearList)
                                                        && this.state.contributionYearList.length > 0) ? (
                                                            <div>
                                                                {this.state.contributionYearList.map((contribution, index) =>
                                                                    <DropdownItem key={index} onClick={() => this.onContributionYrChange(contribution)}>
                                                                        <div>{contribution.label}</div>
                                                                    </DropdownItem>
                                                                )}
                                                            </div>
                                                        ) : ""
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    
                                </div>
                            )
                        }

                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <div className="RCM_CM_form_label">
                                            <label>AMOUNT</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <Messages
                                            messages={(this.state.transfersErrors.transferAmountErrorMsg !== "") ? [errorObjTransfers.transferAmount] : []}
                                            containerClass="" />
                                    </div>
                                </div>
                                <div className="RCM_form_control_group RCM_CM_extAcct_from_control">
                                    <input
                                        type="text"
                                        name="transferAmt"
                                        autoComplete="off"
                                        placeholder="$"
                                        className="RCM_form_control"
                                        required
                                        value={this.state.transfers.displayAmount}
                                        onChange={this.handleFormChanges}
                                        onBlur={this.handleAmountFormatting}
                                        onFocus={this.handleAmmountOnFocus}
                                    />

                                </div>
                                {
                                    this.state.transfers.transferLimit !== "" ?
                                        (<div className="RCM_CM_form_warning">
                                            *Your limit for this transaction is $
                                                {this.state.transfers.transferLimit.toLocaleString('en-US')}
                                        </div>) : ""
                                }

                            </div>
                        </div>

                        {
                            this.state.transfers.transferDate === "" ? ""
                                : (
                                    <div className="row RCM_CM_form_row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-5">
                                                    <div className="RCM_CM_form_label">
                                                        <label>TRANSFER DATE</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-7">
                                                    <Messages
                                                        messages={(this.state.transfersErrors.transferDateErrorMsg !== "") ? [errorObjTransfers.transferDate] : []}
                                                        containerClass="" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 RCM_transfers_datepicker">
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="transferDate" id="transferDate"
                                                    selected={new Date(this.state.transfers.transferDate)}
                                                    onChange={this.handleDateChange}
                                                    minDate={new Date()}
                                                    filterDate={this.isWeekday}
                                                    maxDate={addYears(new Date(), 1)}
                                                    excludeDates={this.state.excludeTransferDates}
                                                    />
                                                    </div>
                                                {/*<label className="RCM_form_control">{this.state.transfers.transferDate}</label>*/}
                                            </div>
                                            {
                                                this.state.transfers.cutoff !== "" ?
                                                    (<div className="RCM_CM_form_warning">
                                                        {`${calculateCutOffVarbiage(this.state.transfers.cutoff)}`}
                                                    </div>) : ""
                                            }
                                        </div>
                                    </div>
                                )
                        }

                        
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12">
                                <div className="RCM_CM_form_label">
                                    <label>OCCURRENCE</label>
                                </div>
                                <div>
                                    <label className="RCM_form_control" required> {this.state.transfers.transferType} </label>
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.transfersDescription === ""
                                || this.state.transfersDescription === null
                                || this.state.transfersDescription === undefined) ? ""
                                : (
                                    <div className="row">
                                        <div className="col-sm-12" align="center">
                                          <button className="btn btn-link RCM_CM_linkText_small" onClick={this.toggleDisclouserModal}>Disclosures</button>
                                        </div>
                                    </div>
                                )
                        }

                        
                        
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-12" align="center">

                                <button type="button"
                                    className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size RCM_overlapping_btn"
                                    onClick={this.backToLending} disabled={this.state.disableBtnClear}>
                                    CLEAR</button>

                                <button
                                    type="button"
                                    className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size RCM_overlapping_btn"
                                    onClick={this.submitTransfer}
                                    disabled={this.state.disableBtnSubmit}
                                >
                                    SUBMIT</button>
                            </div>
                        </div>

                    </div>

                    <RCM_Modal
                            modalTitle="DISCLOSURES"
                            modalState={this.state.disclouserModal}
                            modalContent={this.state.transfersDescription}
                            toggleModal={this.toggleDisclouserModal} />

                </div>
            );
        } else {
            return (
                (this.state.transfers.isRetirementDisbursment) ? 
                    <TransferWithhold 
                        transferData={this.state.transfers}
                        backToEdit={this.backToEdit}
                        eligiblePairAccts={this.state.eligiblePairAccts} 
                        backToLending={this.backToLending} 
                        newTransferEvent={this.props.newTransferEvent} />
                    :
                    <JournalPreConfirm
                        transferData={this.state.transfers}
                        eligiblePairAccts={this.state.eligiblePairAccts}
                        backToEdit={this.backToEdit}
                        backToLending={this.backToLending}
                        newTransferEvent={this.props.newTransferEvent}
                    />
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        accounts: state.acct.accounts,
        portalid: state.acct.portalid,
        token: state.acct.token,
        render: state.acct.render,
        isAccountContextChanged: state.acct.isAccountContextChanged,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        preferences: state.acct.preferences
    };
};
export default connect(mapStateToProps)(JournalEntry);
