import { errorMsg, PreferencesUrl} from "../../common/constants";
import { Post } from "../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../common/_logging";

export var setPreferenceCancellationToken = null;

export const setPreferenceService = (requestBody, token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (setPreferenceCancellationToken !== null && setPreferenceCancellationToken !== undefined) {
        //Cancel the existing call
        setPreferenceCancellationToken.cancel('Cancelling the set Preference request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    setPreferenceCancellationToken = cancelToken.source();

    let setPreferencePromise = new Promise(
        function (resolve, reject) {

            Post(PreferencesUrl, requestBody, token, contextJson, profileJson, setPreferenceCancellationToken)
                .then(res => {
                // get the response in case of successful call.
                    console.log("Preference Service :Set Preference ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Preference Service :Set Preference :: ", error);
                        
                        reject(errorMsg);
                    }
                });
        });
    return setPreferencePromise;
}