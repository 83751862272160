import React from "react";
import XLSX from "xlsx";
import { getAccountDisplayName, IndLongShort, GetNumericValue, formatMoney, GetHeaderHtmlPrint} from "../../../common/constants";

export const defaultSorted = [{ dataField: 'AccountName', order: 'asc' }];

export function externalAcctColums(accountList){ 
  return [
  {
    dataField: "displayname",
    text: "Account Name",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
  },
  {
    dataField: "abanumber", text: "ABA Number", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
  },
  {
    dataField: "bankname", text: "Bank Name", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
  },
  {
    dataField: "bankaccountnumber", text: "Account Number", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
  },
  {
    dataField: "bankaccounttype",
    text: "Account Type",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    formatter: bankAccountTypeFormatter
  },
  {
    dataField: "accountnumber",
    text: "Linked To",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex),
    formatter: (cell) => {
      return <div>{cell ? cell : '-'}</div>
    }
  },
  {
    dataField: "status", text: "Status", sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerStyle: (column, colIndex) => columnHeaderStyle(column, colIndex)
  },
  { dataField: "isdelete", text: "", formatter: actionFormatter }
]}


function acctFormatter(accountlist, cell)
    {
        return (<div title={getAccountDisplayName(accountlist, cell, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, cell, IndLongShort.SINGLEVIEW)}</div>)
    }


function actionFormatter(cell, row, rowIndex)
{
  return(<div title="Delete Account" className="RCM_CM_delete-icon" onClick={() =>cancelExternalAccount(row)}></div>);
}

function cancelExternalAccount(row)
{
    document.dispatchEvent(new CustomEvent('deleteExternalAccount', {bubbles: true, 'detail': row}));
}

function bankAccountTypeFormatter(cell, row, rowIndex)
{
  if(cell !== null && cell !==undefined )
  {
    switch(cell)
    {
      case "C":
       return "Checking Account";

       case "S":
        return "Saving Account";

        default:
        return "-";

    }
  }
}

function customCaretSort(order, column)
{
  if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
  else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
  else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
  return null;
}

function columnHeaderStyle(column, colIndex)
{
  return { 'textAlign': 'left', 'whiteSpace': 'nowrap' };
}


export function generateExtAcctExcelWs(externalAcctList) {
    let xlsHeader = ["Account Name", "Routing Number", "Bank Name", "Account Number", "Account Type",  "Status"];
    let xlsRows = []
    if (externalAcctList !== null && externalAcctList !== undefined && Array.isArray(externalAcctList)) {
        externalAcctList.forEach(extAcct => {
            var xlRow = {
                "Account Name": extAcct.displayname, "Routing Number": extAcct.abanumber,
                "Bank Name": extAcct.bankname, "Account Number": extAcct.bankaccountnumber,
                "Account Type": bankAccountTypeFormatter(extAcct.bankaccounttype), "Status": extAcct.status
            }
            xlsRows.push(xlRow);
        })
    }
    let ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });

    ws['!cols'] = [{ 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 120 }];

    return ws;
}

export function printExternalManageAccount(props) {
       
        let printWindow = window.open('', 'RCM Print', '');
        let table = GetHeaderHtmlPrint(props);
        table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
        //prepare table header columns 

        table += "<tr><th colspan='6'>External Account Details</th></tr>"
        table += "<tr>"
        table += "<td style='text-align:left; border: 1px solid black; width:15%;'>Account Name </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> ABA Number </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Bank Name </td>"
        table += "<td style='text-align:right; border: 1px solid black; width:10%;'> Account Number </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Account Type </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Status </td>"
        table += "</tr>"
    if (props.manageextacct.externalAcctList !== null && props.manageextacct.externalAcctList !== undefined && Array.isArray(props.manageextacct.externalAcctList)) {
        props.manageextacct.externalAcctList.forEach(extAcct => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:15%;'>" + extAcct.displayname + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + extAcct.abanumber + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:15%;'>" + extAcct.bankname + "</td>"
                table += "<td style='text-align:right; border: 1px solid black; width:10%;'>" + extAcct.bankaccountnumber + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:15%;'>" + bankAccountTypeFormatter(extAcct.bankaccounttype) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:15%;'>" + extAcct.status + "</td>"
            })
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        }
        table += "</table>"
        printWindow.document.write(table);
        printWindow.print()
        printWindow.close();
    }