import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Post } from './servicecalls';
import { fetchPersonalisationData } from "../actions/personalisationAction";
import { PreferencesUrl } from "./constants"
import ToggleButton from 'react-toggle-button';
import axios from 'axios';
import { getNestedObject } from '../components/MoneyMovement/common/utilty';
import {relasevariables} from "../../index";


class ThemeToggle extends Component {

    constructor(props) {
        super(props);

        this.darkCss = (process.env.NODE_ENV === 'development') ? "RCM.dark.style.css" : "RCM.dark.style.min.css";
        this.lightCss = (process.env.NODE_ENV === 'development') ? "RCM.light.style.css" : "RCM.light.style.min.css";

        this.darkCss = `${this.darkCss}?ver=${relasevariables ? relasevariables.version :''}`;
        this.lightCss = `${this.lightCss}?ver=${relasevariables ? relasevariables.version : ''}`;

        let theme = sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : getNestedObject(props, ["preferences", "theme"]);
        //let theme = sessionStorage.getItem('theme'); 
        if (!(theme !== null && theme !== undefined && theme !== "")) {
            theme = 'Dark';
            sessionStorage.setItem('theme', theme);
        } else {
            sessionStorage.setItem('theme', theme);
            let themeStyle = document.getElementById('themecss');
            themeStyle.href = theme === "Dark" ? this.darkCss : this.lightCss;
        }
        this.state = {
            themeToggleValue: theme === "Dark" ? true : false
        }
           
    }
    cancellationToken = null;

    toggleTheme() {
        this.setState(prevState => ({
            themeToggleValue: !prevState.themeToggleValue
        }), () => {
            let theme = sessionStorage.getItem('theme');
            let themeStyle = document.getElementById('themecss');
            if (theme === "Light") {
                themeStyle.href = this.darkCss;
                sessionStorage.setItem('theme', "Dark")
            } else {
                themeStyle.href = this.lightCss;
                sessionStorage.setItem('theme', "Light")
            }
            document.dispatchEvent(new CustomEvent('themeToggle', {bubbles: true}));
            this.updatePreferenceCallBack();
        })

    }

    componentDidMount() {
        //register event here
        window.addEventListener('toggleTheme', () => this.toggleTheme());
    }

    componentWillUnmount() {
        // de registering the event here
        window.removeEventListener('toggleTheme', () => this.toggleTheme());
    }

    updatePreferenceCallBack = () => {
        const preferenceUrl = PreferencesUrl;
        this.cancellationToken = axios.CancelToken.source();
        let reqObj = {}
        reqObj.theme = sessionStorage.getItem('theme');
        let requestBody = {
            profileid: parseInt(this.props.profileId),
            preferencejson: reqObj,
        }
        if (this.props.role !== "Prospect") {
            Post(preferenceUrl, requestBody, this.props.token,
                this.props.contextjson, this.props.profilejson, this.cancellationToken)
                .then(res => {
                    if (res.status === 200) {
                        // this.props.fetchPersonalisationData(reqObj);
                        console.log("updated successfully");
                    }
                }).catch(error => {
                    console.log('making false - profile');
                    console.log(error);
                });
        }
    }

    render() {
        const thumbStyle = { width: 16, height: 16 }
        const trackStyle = { height: 16 }

        return (
            <ToggleButton

                inactiveLabel={this.props.inActive}
                activeLabel={this.props.active}
                value={this.state.themeToggleValue}
                onToggle={() => this.toggleTheme()}
                colors={{
                    inactive: {
                        base: '#000',
                    },
                    active: {
                        base: '#E2E2E2',
                    },
                }}
                thumbAnimateRange={[0, 40]}
                thumbStyle={thumbStyle}
                trackStyle={trackStyle}
                activeLabelStyle={{
                    color: '#000',
                    height: '10px'
                }}
                inactiveLabelStyle={{
                    color: '#fff',
                    height: '10px'
                }}
            />
        );
    }
}
const mapStateToProps = (store) => {
    return {
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileReq: store.acct.profileResp,
        baseUrl: store.acct.baseurl,
        profileId: store.acct.profileId,
        role: store.acct.role
    };
}
const mapDispatchToProps = {
    fetchPersonalisationData
}

//export default ThemeToggle;
export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggle);

