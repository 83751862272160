import { errorMsg, manageAssetURL } from "../../../../common/constants.jsx";
import { Post, Get } from "../../../../common/servicecalls.jsx";
import axios from "axios";
import { _logException } from "../../../../common/_logging";

export var addAssetCancellationToken = null;
export var editAssetCancellationToken = null;
export var getAssetListCancellationToken = null;
export var deleteAssetCancellationToken = null;
export var getAssetTypeListCancellationToken = null;


export const addAssetService = (assetDetails, token, contextJson) => {

    //Check if cancellation token has value;
    if (addAssetCancellationToken !== null && addAssetCancellationToken !== undefined) {
        //Cancel the existing call
        addAssetCancellationToken.cancel('Cancelling the add asset request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addAssetCancellationToken = cancelToken.source();

    let addAssetPromise = new Promise(
        function (resolve, reject) {
            let addAsseturl = manageAssetURL;
            Post(addAsseturl, assetDetails, token, contextJson, addAssetCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Asset Service : Add Asset ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Asset Service : Add Asset :: ", error);
                        _logException("Manage Asset Service : Add Asset :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return addAssetPromise;
}
export const editAssetService = (assetDetails, token, contextJson) => {

    //Check if cancellation token has value;
    if (editAssetCancellationToken !== null && editAssetCancellationToken !== undefined) {
        //Cancel the existing call
        editAssetCancellationToken.cancel('Cancelling the edit asset request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    editAssetCancellationToken = cancelToken.source();

    let editAssetPromise = new Promise(
        function (resolve, reject) {
            let editAsseturl = manageAssetURL + "/UpdateManualAsset";
            Post(editAsseturl, assetDetails, token, contextJson, editAssetCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Asset Service : Edit Asset ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Asset Service : Edit Asset :: ", error);
                        _logException("Manage Asset Service : Edit Asset :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return editAssetPromise;
}
export const getAssetListService = (token, contextJson, profileJson, profileId, key='') => {

    //Check if cancellation token has value;
    if (getAssetListCancellationToken !== null && getAssetListCancellationToken !== undefined) {
        //Cancel the existing call
        getAssetListCancellationToken.cancel('Cancelling the get asset list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAssetListCancellationToken = cancelToken.source();

    let getAssetListPromise = new Promise(
        function (resolve, reject) {
            let getAssetListurl = manageAssetURL + '?profid=' + profileId + (key !== '' ? ('&accountkey=' + key) : ''); 
            Get(getAssetListurl, token, contextJson, profileJson, getAssetListCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Asset Service : get Asset List ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Asset Service : get Asset List :: ", error);
                        _logException("Manage Asset Service : get Asset List :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return getAssetListPromise;
}
export const deleteAssetService = (assetDetails, token, contextJson, deleteLinkedAccount = false) => {

    //Check if cancellation token has value;
    if (deleteAssetCancellationToken !== null && deleteAssetCancellationToken !== undefined) {
        //Cancel the existing call
        deleteAssetCancellationToken.cancel('Cancelling the delete asset request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    deleteAssetCancellationToken = cancelToken.source();

    let deleteAssetPromise = new Promise(
        function (resolve, reject) {
            let deleteAsseturl = manageAssetURL + "/DeleteManualAssetAccount";
            Post(deleteAsseturl, assetDetails, token, contextJson, deleteAssetCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Asset Service : delete Asset ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Asset Service : delete Asset :: ", error);
                        _logException("Manage Asset Service : delete Asset :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return deleteAssetPromise;
}
export const getAssetTypeListService = (token, contextJson, profileJson, profileId) => {

    //Check if cancellation token has value;
    if (getAssetTypeListCancellationToken !== null && getAssetTypeListCancellationToken !== undefined) {
        //Cancel the existing call
        getAssetTypeListCancellationToken.cancel('Cancelling the get asset list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAssetTypeListCancellationToken = cancelToken.source();

    let getAssetTypeListPromise = new Promise(
        function (resolve, reject) {
            let getAssetTypeListurl = manageAssetURL + '?profid=' + profileId;
            Get(getAssetTypeListurl, token, contextJson, profileJson, getAssetTypeListCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Asset Service : get Asset Type List ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Asset Service : get Asset Type List :: ", error);
                        _logException("Manage Asset Service : get Asset Type List :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return getAssetTypeListPromise;
}