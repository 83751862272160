import React, { Component } from 'react'
import Aviationview from './aviationview';
import { getWheelsUpApiUrl } from './aviationService';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPageView } from '../../../hoc/withPageView.jsx';
import {wheelsUPApiCancellationToken} from  './aviationService';
import { contactFlyexclusiveUrl, avationPortfolioUrl, CheckIfProspectClient } from '../../../common/constants';
import OverlayProcessing from '../../MoneyMovement/common/overlayProcessing';
import { _logInformation } from '../../../common/_logging';

class Aviation extends Component {

    constructor(props){
        super(props);

        this.state = {
            wheelsUpUrl : '',
            disableSearchFlightLink: true,
            aviationModalState: false,
            selectedSectionurl: '',
            showOverlay: false,
            isProspectClient: CheckIfProspectClient(this.props.role),
            selectedSection: ''
        }

        this.aviationModalToggle = this.aviationModalToggle.bind(this);

    }

    aviationModalToggle(){
        this.setState(prevState => ({aviationModalState: !prevState.aviationModalState}));
    }

    componentDidMount(){
        getWheelsUpApiUrl(this.props.token, this.props.contextJson, this.props.profileJson).then(res => {
            if(res && res.data && res.data.vendorurl){
                this.setState({wheelsUpUrl: res.data.vendorurl, disableSearchFlightLink: false});
            }
        }).catch(err => {
            console.log('Error While Fetching wheels up api', err);
        })

        
    }

    componentWillUnmount(){
        //unmount api call
        if(wheelsUPApiCancellationToken !== null && wheelsUPApiCancellationToken !== undefined)
        {
            wheelsUPApiCancellationToken.cancel("Cancelling wheels up getapi url on unmount to prevent memory leak.");
        }
    }

    openWheelsUpLearnMore(){
        _logInformation('Learn More Clicked');
        window.open(this.props.env?.wheelsUpCdnPdfURL)
    }

    getPdfUrl(data) {
        //below one will be the actual data received from webapi
        if (data) {
            var base64str = data;
            // decode base64 string, remove space for IE compatibility
            var binary = atob(base64str.replace(/\s/g, ""));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }            
            // create the blob object with content-type "application/pdf"
            var blob = new Blob([view], { type: "application/pdf" });           
            var url = URL.createObjectURL(blob);
            console.log('url: ' + url);
            return url;
        }
    }

    avationPortfolio = () =>{
        _logInformation('Avation Portfolio Clicked');
        this.setState({ selectedSectionurl: avationPortfolioUrl, selectedSection: 'avationPortfolio'}, this.aviationModalToggle);
    }

    contactFlyExclusive = () => {
        _logInformation('Contact FlyExclusives Clicked');
        this.setState({ selectedSectionurl: contactFlyexclusiveUrl, selectedSection: 'contactFlyExclusive'}, this.aviationModalToggle);
    }

    searchFlightsClick = () => {
        _logInformation('Search Flight Clicked');
        getWheelsUpApiUrl(this.props.token, this.props.contextJson, this.props.profileJson).then(res => {
            if(res && res.data && res.data.vendorurl){
                this.setState({wheelsUpUrl: res.data.vendorurl, disableSearchFlightLink: false, selectedSectionurl: res.data.vendorurl}, this.aviationModalToggle);
            }
        }).catch(err => {
            console.log('Error While Fetching wheels up api', err);
            this.setState({wheelsUpUrl: null, selectedSectionurl: '', disableSearchFlightLink: true }, this.aviationModalToggle);
        })
    }

    openSelectedSectionWindow = () => {
        _logInformation(this.state.selectedSectionurl);
        window.open(this.state.selectedSectionurl);
        this.setState({selectedSectionurl: ''}, this.aviationModalToggle);
    }

    render() {
        return (
            <>
                <Aviationview 
                    wheelsUpVendorUrl={this.state.wheelsUpUrl}
                    openWheelsUpLearnMore={() => this.openWheelsUpLearnMore()}
                    avationPortfolio={() => this.avationPortfolio()}
                    contactFlyExclusive={() => this.contactFlyExclusive()}
                    searchFlightsClick={() => this.searchFlightsClick()}
                    openSelectedSectionWindow={() => { this.openSelectedSectionWindow() }}
                    disableSearchFlightLink={this.state.disableSearchFlightLink}
                    aviationModalToggle={this.aviationModalToggle}
                    aviationModalState={this.state.aviationModalState}
                    isProspectClient={this.state.isProspectClient}
                    selectedSection={this.state.selectedSection}
                />
                { this.state.showOverlay && (<OverlayProcessing />) }
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        token: store.acct.token,
        contextJson: store.acct.contextjson,
        profileJson: store.acct.profilejson,
        role:store.acct.role,
        env: store.env
    };
}

const mapDispatchToProps = {
    
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Wheels UP' })
)

export default enhancement(Aviation);
