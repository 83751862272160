import React, { useCallback, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { Post } from "../../../../common/servicecalls";
import axios from "axios";
import { useSelector } from "react-redux";

const VerifyPhoneMethod = ({ phoneSubmitResult, handleCloseAddAuthenticationPopup }) => {
  const { token } = useSelector((store) => store.acct) || {};
  const { baseurl } = useSelector((store) => store.env) || {};
  const [otp, setOtp] = useState();
  const [submitOtpState, setSubmitOtpState] = useState({
    isOtpSubmitInProgress: false,
    otpSubmitError: undefined,
  });

  const handleOnOtpChange = useCallback((event) => {
    const value = event.target ? event.target.value : "";
    const pattern = /^\d*$/;
    if (value === "" || pattern.test(value)) {
      setOtp(value);
    }
  }, []);

  const handleOTPSubmit = useCallback(() => {
    setSubmitOtpState((prevState) => ({
      ...prevState,
      isOtpSubmitInProgress: true,
      otpSubmitError: undefined,
    }));

    const cancellationToken = axios.CancelToken.source();
    const apiurl = `${baseurl}multifactorauthapi/api/PhoneMethods/verify`;
    const verifyPhoneRequest = {
      key: `${phoneSubmitResult}`,
      otp: otp,
    };
    Post(apiurl, verifyPhoneRequest, token, null, null, cancellationToken)
      .then(() => {
        setSubmitOtpState((prevState) => ({
          ...prevState,
          isOtpSubmitInProgress: false,
        }));
        handleCloseAddAuthenticationPopup(true)
      })
      .catch(() => {
        setSubmitOtpState((prevState) => ({
          ...prevState,
          isOtpSubmitInProgress: false,
          otpSubmitError:
            "We are unable to verify phone number, Please try after sometime.",
        }));
      });
  }, [baseurl, handleCloseAddAuthenticationPopup, otp, phoneSubmitResult, token]);

  const handleOnOtpKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleOTPSubmit();
    }
  };

  return (
    <div>
      {submitOtpState.otpSubmitError && (
        <div className="RCM_Teamwork_error">
          {submitOtpState.otpSubmitError}
        </div>
      )}
      <div>
        Please enter the verification code sent to your mobile phone (expires in 5 minutes).
      </div>
      <div className="RCM_CM_form_label">
        <label>VERIFICATION CODE</label>
      </div>
      <div className="row">
        <div className="col col-sm-12">
          <input
            type="text"
            className="RCM_form_control"
            onChange={(event) => handleOnOtpChange(event)}
            onKeyUp={(event) => handleOnOtpKeyUp(event)}
            value={otp}
            autoFocus
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col col-sm-12"
          style={{
            display: "flex",
            gap: "0.75rem",
            marginTop: "1.5rem",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            className="btn RCM_button_primary RCM_select_btn"
            onClick={handleOTPSubmit}
            disabled={submitOtpState.isOtpSubmitInProgress}
          >
            <>
              {submitOtpState.isOtpSubmitInProgress && (
                <Spinner size="sm" style={{ marginRight: "0.5rem" }} />
              )}{" "}
              CONFIRM
            </>
          </Button>
          <input
            className="btn RCM_button_secondary RCM_select_btn"
            type="submit"
            value="CANCEL"
            onClick={() => handleCloseAddAuthenticationPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyPhoneMethod;
