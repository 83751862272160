import { ADD_TOPTEN, ADD_HOLDINGSBYSECURITY, ADD_HOLDINGSBYACCOUNT, ADD_HOLDINGSBYCATEGORY, 
    ADD_REALIZEDGAINLOSSBYACCOUNT, ADD_REALIZEDGAINLOSSBYSECURITY, UPDATE_TOPTEN, SET_ACCOUNTOVERVIEWFLAG, 
    ADD_ESTIMATEDINCOMEBYACCOUNT, ADD_ESTIMATEDINCOMEBYSECURITY,
    ADD_ESTIMATEDINCOMESUMMARY_SECURITY, ADD_ESTIMATEDINCOMESUMMARY_ACCOUNT, ADD_ESTIMATEDINCOMESUMMARY_CHART,
    UPDATE_ESTIMATEDINCOMESUMMARY_TOGGLEVIEW, ADD_PROJECTEDINCOMEBYACCOUNT, ADD_PROJECTEDINCOMEBYSECURITY,
    ADD_PROJECTEDINCOMESUMMARY_ACCOUNT, ADD_PROJECTEDINCOMESUMMARY_CHART, ADD_PROJECTEDINCOMESUMMARY_SECURITY,
    UPDATE_PROJECTEDINCOMESUMMARY_TOGGLEVIEW, CHANGEYEAR_ESTIMATEDINCOME, CHANGEYEAR_PROJECTEDINCOME, REFRESH_ESTIMATEDINCOME_ACCOUNTSECURITY, REFRESH_ESTIMATEDINCOME_SUMMARYSECURITY, REFRESH_ESTIMATEDINCOME_SUMMARYACCOUNT,
    REFRESH_PROJECTEDINCOME_SUMMARYSECURITY, REFRESH_PROJECTEDINCOME_ACCOUNTSECURITY, REFRESH_PROJECTEDINCOME_SUMMARYACCOUNT, UPDATE_ASSET_SCHEMA
} from '../actions/toptenactions.jsx';


export function TopTenReducer(state = [], action) {
    switch (action.type) {
        
        case ADD_TOPTEN:
            return {
                ...state,
                toptensummary: action.toptensummary
            }
        case ADD_HOLDINGSBYACCOUNT:
            return {
                ...state,
                holdingsbyaccount: action.holdingsbyaccount
            }
        case ADD_HOLDINGSBYSECURITY:
            return {
                ...state,
                holdingsbysecurity: action.holdingsbysecurity
            }
        case ADD_HOLDINGSBYCATEGORY:
            return {
                ...state,
                holdingsbycategory: action.holdingsbycategory
            }
        case SET_ACCOUNTOVERVIEWFLAG:
            return {
                ...state,
                accountOverviewFlag: action.accountOverviewFlag
            }  
        case UPDATE_TOPTEN:
            return {
                ...state,                
                toptensummary: action.toptensummary,
                holdingsbyaccount: action.holdingsbyaccount,
                holdingsbysecurity: action.holdingsbysecurity,
                holdingsbycategory: action.holdingsbycategory,
                realizedgainlossbyaccount: action.realizedgainlossbyaccount,
                realizedgainlossbysecurity: action.realizedgainlossbysecurity,
                estimatedincomebyaccount: action.estimatedincomebyaccount,
                estimatedincomebysecurity: action.estimatedincomebysecurity,
                estimatedIncomeSummarytoggleview: action.estimatedIncomeSummarytoggleview,
                estimatedIncomeSummaryChartData: action.estimatedIncomeSummaryChartData,
                estimatedIncomeSummarySecurityData: action.estimatedIncomeSummarySecurityData,
                estimatedIncomeSummaryAccountData: action.estimatedIncomeSummaryAccountData,
                projectedincomebyaccount: action.projectedincomebyaccount,
                projectedincomebysecurity: action.projectedincomebysecurity,
                projectedIncomeSummarytoggleview: action.projectedIncomeSummarytoggleview,
                estimatedIncomeYear: action.estimatedIncomeYear,
                projectedIncomeYear: action.projectedIncomeYear,
                projectedIncomeSummaryChartData: action.projectedIncomeSummaryChartData,
                projectedIncomeSummarySecurityData: action.projectedIncomeSummarySecurityData,
                projectedIncomeSummaryAccountData: action.projectedIncomeSummaryAccountData,
            }
        case ADD_REALIZEDGAINLOSSBYACCOUNT:
            return {
                ...state,
                realizedgainlossbyaccount: action.realizedgainlossbyaccount
            }
        case ADD_REALIZEDGAINLOSSBYSECURITY:
            return {
                ...state,
                realizedgainlossbysecurity: action.realizedgainlossbysecurity
            }
        case ADD_ESTIMATEDINCOMEBYACCOUNT:
            return {
                ...state,
                estimatedincomebyaccount: action.estimatedincomebyaccount
            }
        case ADD_ESTIMATEDINCOMEBYSECURITY:
            return {
                ...state,
                estimatedincomebysecurity: action.estimatedincomebysecurity
            }
        case ADD_ESTIMATEDINCOMESUMMARY_CHART:
            return {
                ...state,
                estimatedIncomeSummaryChartData: action.estimatedIncomeSummaryChartData
            }
        case ADD_ESTIMATEDINCOMESUMMARY_SECURITY:
            return {
                ...state,
                estimatedIncomeSummarySecurityData: action.estimatedIncomeSummarySecurityData
            } 
        case ADD_ESTIMATEDINCOMESUMMARY_ACCOUNT:
            return {
                ...state,
                estimatedIncomeSummaryAccountData: action.estimatedIncomeSummaryAccountData
            } 
        case UPDATE_ESTIMATEDINCOMESUMMARY_TOGGLEVIEW:
            return {
                ...state,
                estimatedIncomeSummarytoggleview: action.estimatedIncomeSummarytoggleview
            } 
        case ADD_PROJECTEDINCOMEBYACCOUNT:
            return {
                ...state,
                projectedincomebyaccount: action.projectedincomebyaccount
            }
        case ADD_PROJECTEDINCOMEBYSECURITY:
            return {
                ...state,
                projectedincomebysecurity: action.projectedincomebysecurity
            }
        case ADD_PROJECTEDINCOMESUMMARY_CHART:
            return {
                ...state,
                projectedIncomeSummaryChartData: action.projectedIncomeSummaryChartData
            }
        case ADD_PROJECTEDINCOMESUMMARY_SECURITY:
            return {
                ...state,
                projectedIncomeSummarySecurityData: action.projectedIncomeSummarySecurityData
            }
        case ADD_PROJECTEDINCOMESUMMARY_ACCOUNT:
            return {
                ...state,
                projectedIncomeSummaryAccountData: action.projectedIncomeSummaryAccountData
            }
        case UPDATE_PROJECTEDINCOMESUMMARY_TOGGLEVIEW:
            return {
                ...state,
                projectedIncomeSummarytoggleview: action.projectedIncomeSummarytoggleview
            }
        case CHANGEYEAR_ESTIMATEDINCOME: {
            return {
                ...state,
                estimatedIncomeYear: action.estimatedIncomeYear
            }
        }
        case REFRESH_ESTIMATEDINCOME_ACCOUNTSECURITY: {
            return {
                ...state,
                estimatedincomebyaccount: action.estimatedincomebyaccount,
                estimatedincomebysecurity: action.estimatedincomebysecurity,
            }
        }
        case REFRESH_ESTIMATEDINCOME_SUMMARYACCOUNT: {
            return {
                ...state,
                estimatedincomebyaccount: action.estimatedincomebyaccount,
                estimatedIncomeSummaryChartData: action.estimatedIncomeSummaryChartData,
                estimatedIncomeSummarySecurityData: action.estimatedIncomeSummarySecurityData,
                estimatedIncomeSummaryAccountData: action.estimatedIncomeSummaryAccountData
            }
        }
        case REFRESH_ESTIMATEDINCOME_SUMMARYSECURITY: {
            return {
                ...state,
                estimatedincomebysecurity: action.estimatedincomebysecurity,
                estimatedIncomeSummaryChartData: action.estimatedIncomeSummaryChartData,
                estimatedIncomeSummarySecurityData: action.estimatedIncomeSummarySecurityData,
                estimatedIncomeSummaryAccountData: action.estimatedIncomeSummaryAccountData
            }
        }
       
        case CHANGEYEAR_PROJECTEDINCOME: {
            return {
                ...state,
                projectedIncomeYear: action.projectedIncomeYear
            }
        }
        case REFRESH_PROJECTEDINCOME_ACCOUNTSECURITY: {
            return {
                ...state,
                projectedincomebyaccount: action.projectedincomebyaccount,
                projectedincomebysecurity: action.projectedincomebysecurity,
            }
        }
        case REFRESH_PROJECTEDINCOME_SUMMARYACCOUNT: {
            return {
                ...state,
                projectedincomebyaccount: action.projectedincomebyaccount,
                projectedIncomeSummaryChartData: action.projectedIncomeSummaryChartData,
                projectedIncomeSummarySecurityData: action.projectedIncomeSummarySecurityData,
                projectedIncomeSummaryAccountData: action.projectedIncomeSummaryAccountData
            }
        }
        case REFRESH_PROJECTEDINCOME_SUMMARYSECURITY: {
            return {
                ...state,
                projectedincomebysecurity: action.projectedincomebysecurity,
                projectedIncomeSummaryChartData: action.projectedIncomeSummaryChartData,
                projectedIncomeSummarySecurityData: action.projectedIncomeSummarySecurityData,
                projectedIncomeSummaryAccountData: action.projectedIncomeSummaryAccountData
            }
        }
        case UPDATE_ASSET_SCHEMA: {
            return {
                ...state,
                holdingsbycategory: action.holdingsbycategory
            }
        }
        default:
            return state;
    }

}