import React, { Component } from 'react';
import { MailURL, GetPreferencePilotValue } from '../common/constants.jsx';
import { Post } from '../common/servicecalls.jsx';
import axios from 'axios';
import { FooterView } from './footerview.jsx';
import ErrorBoundary from '../common/errorboundary.jsx';
import { connect } from 'react-redux';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.sendMail = this.sendMail.bind(this);
        this.state = {
            
        };

        this.crspdfPilotFlag = GetPreferencePilotValue(this.props, 'RCMCRSPDF', 'false');

    }
    cancellationToken = null;

    componentWillUnmount() {
        if (this.cancellationToken) {
            this.cancellationToken.cancel('Operation canceled due to new request.')
        }
    }
    sendMail() {
        var body = "Test Mail";

        let requestBody = {
            'Subject': 'Test Mail',
            'Body': body,
            'ToRecipients': 'dataprotectionofficer@rockco.com'
        }
        if (this.cancellationToken) {
            this.cancellationToken.cancel('Operation canceled due to new request.')
        }
        this.cancellationToken = axios.CancelToken.source();
        let url = MailURL;

        var body = "Unable to Encrypt the message";
        try {
            body = this.toUTF8Array(JSON.stringify(requestBody));
        }
        catch (e) {
            body = "Unable to Encrypt the message";
            body = "Unable to Encrypt the message";
        }
        // Encrypt the message
        var bodyFormData = new FormData();
        bodyFormData.set('EmailMessageEncoded', body);
        // Done Encrypting
        Post(url, bodyFormData, this.props.token, this.props.contextjson, this.props.profilejson, this.cancellationToken)
            .then(res => {
                if (res.status === 200) {
                    console.log("Mail sent");
                } else {
                    console.log("Mail sent Failed.");
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log(error);
                }
            });
    }
    // convert to base 64

    toUTF8Array = (unicodeString) => {
        return btoa(unicodeString);
    }
    render() {
        return (
            <ErrorBoundary>
                <FooterView
                    state={this.state}
                    sendMail={this.sendMail}
                    formCrsPdf={this.props.crspdf}
                    crspdfPilotFlag = {this.crspdfPilotFlag}
                />
            </ErrorBoundary>
            
        );
    }
}
const mapStateToProps = state => {
    return {
        token: state && state.acct && state.acct.token,
        contextjson: state && state.acct && state.acct.contextjson,
        profilejson: state && state.acct && state.acct.profilejson,
        preferences: state.acct.preferences
    };
};
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

