import React, { useEffect, useState, useCallback } from "react";
import { authProvider } from "../../../App";
import { _logException, _logInformation } from "../../common/_logging";
import GroupListContainer from "./DirectoryList/GroupListContainer";
import GroupItemList from "./DirectoryList/GroupItemList";
import FilePreviewContainer from "./Preview/FilePreviewContainer";
import { spinner } from "../../common/constants";

export const TeamworkContext = React.createContext();

const TeamworkContainer = () => {
    const [token, setToken] = useState();
    const [selectedGroup, setSelectedGroup] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState();

    useEffect(() => {
        // fetch access token for new scope
        if (authProvider) {
            setError();
            setLoading();
            try {
                const accounts = authProvider.getAllAccounts();
                if (accounts && Array.isArray(accounts) && accounts.length > 0) {
                    //fetching silent token for collaboration scope
                    const tokenRequest = {
                        account: accounts[0],
                        scopes: ["Files.ReadWrite.All", "user.read"],
                        forceRefresh: true,
                    };
                    setLoading(true);
                    authProvider
                        .acquireTokenSilent(tokenRequest)
                        .then((res) => {
                            setLoading(false);
                            _logInformation(`Team work token received for scopes = 
                            ${
                                res && res.scopes && Array.isArray(res.scopes)
                                    ? res.scopes.map((s) => s).join(",")
                                    : "Scope not found"
                                }`);
                            setToken(res.accessToken);
                        })
                        .catch((err) => {
                            _logException(err);
                            setLoading(false);
                            setError(
                                "We are unable to authenticate you, please try after sometime."
                            );
                        });
                } else {
                    _logException("Account not found");
                    setLoading(false);
                    setError("We could not find logged in user account");
                }
            } catch (e) {
                setError("We are unable to load documents, please try after sometime.");
                setLoading(false);
                _logException(e);
            }
        }
    }, []);

    const onGroupSelection = useCallback((group) => {
        setPreviewUrl();
        setSelectedGroup(group);
    }, []);

    const onPreviewClick = useCallback((drive) => {
        setPreviewUrl(
            `https://graph.microsoft.com/v1.0/drives/${drive.parentReference.driveId}/items/${drive.id}/preview`
        );
    }, []);

    if (loading) {
        return (
            <div
                className="RCM_Teamwork_error"
                style={{
                    color: "inherit",
                    background: "none",
                }}
            >
                Loading Collaboration Details {spinner()}
            </div>
        );
    }

    return (
        <>
            <div className="row" id="grpContainer1">
                <div className="col col-sm-12 col-md-12 col-lg-12">
                    <label
                        className="RCM_tile_heading RCM_details_heading RCM_Toast_heading"
                        style={{ paddingLeft: "0.1rem" }}
                    >
                        COLLABORATION
          </label>
                </div>
            </div>
            {error && <div className="RCM_Teamwork_error">{error}</div>}
            {token && (
                <TeamworkContext.Provider value={{ token, setError }}>
                    <div id="grpContainer">
                        <GroupListContainer
                            onGroupSelection={onGroupSelection}
                            selectedGroup={selectedGroup}
                        />
                    </div>
                    <div className="row">
                        <div id="grpList" className="col col-sm-12 col-md-8 col-lg-8 mt-1">
                            {selectedGroup && (
                                <GroupItemList
                                    group={selectedGroup}
                                    onPreviewClick={onPreviewClick}
                                />
                            )}
                        </div>
                        <div id="previewDocument"
                            className="col col-sm-12 col-md-4 col-lg-4 mt-1"
                            style={{ paddingLeft: 0 }}
                        >
                            {previewUrl && <FilePreviewContainer  previewUrl={previewUrl} />}
                        </div>
                    </div>
                </TeamworkContext.Provider>
            )}
        </>
    );
};

export default TeamworkContainer;
