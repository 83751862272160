import React from 'react';
import { MsgForNoData } from './../../../../../common/constants';
import { AccountPerformanceColumns } from './accountperformancecolumns';
import BootstrapTable from 'react-bootstrap-table-next';
const AccountPerformanceView = (props) => {
    return (
        <>
            <div className="RCM_Toast" key="accountPerf03">
                <div className="row" id="RCM_AccountPerformanceTable" key="accountPerf04">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_Tables RCM_AccountPerformance_table" key="accountPerf05" id="RCM_AccountPerformance_table">
                        <BootstrapTable key="accountPerf06"
                            keyField='key'
                            data={props.accounts}
                            classes="RCM_two_level_table1"
                            columns={AccountPerformanceColumns(props.accountlist)}
                            noDataIndication={MsgForNoData}
                            bordered={false}
                        />
                    </div>
                </div>
            </div>    
        </>
    )

};

export default AccountPerformanceView;