import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { setCookie, getCookie } from "../common/constants";

function ClientV2Toast(props) {
  const sneekPeekShow = getCookie("SneekPeekDontShow") === "true" ? false : true;
  const [show, setShow] = useState(sneekPeekShow);

  const handleToogle = () => {
    setShow(!show);
  };

  const handleDontShowAgain = () => {
    setCookie("SneekPeekDontShow", "true", 365)
    setShow(false)
  };

  const handleShowSneekPeekPdf = () => {
    if(props?.sneekpeekpdf){
    window.open(props.sneekpeekpdf, 'Sneekpeek')
    setShow(false)
    }
  }

  return (
    <Modal
      isOpen={show}
      toggle={handleToogle}
      backdrop={false}
      size="md"
      zIndex="9999"
      className="RCM_CM_modal"
      wrapClassName="RCM_CM_modal_wrapper"
      modalClassName="RCM_CM_modal_class"
      contentClassName="RCM_CM_modal_content"
      centered
    >
      <ModalHeader toggle={handleToogle} charCode="X">
        <label className="RCM_tile_heading">NOTIFICATION</label>
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
          Our new and improved website is coming soon; 
          </div>
          <div className="RCM_caseManager_modal_link" style={{marginTop: '5px'}} onClick={handleShowSneekPeekPdf}> 
            click here for a sneak peek.
          </div>
        </div>

        <div className="row row-margin" style={{marginTop: '30px'}}>
          <div className="col-sm-12 RCM_edelivery_popup_buttons" style={{gap: '10px'}}>
            <input
              className="btn RCM_button_secondary RCM_select_btn"
              type="submit"
              value="CLOSE"
              onClick={handleToogle}
            />
            <input
              className="btn RCM_button_primary RCM_select_btn"
              type="submit"
              value="DON'T SHOW AGAIN"
              onClick={handleDontShowAgain}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ClientV2Toast;
