import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class ShowFilePopOver extends React.PureComponent {

    constructor(props){
        super(props)
        this.state = {
            fileUrl: '',
            filePreviewError: '',
            fileType:''
        }

        this.readURL = this.readURL.bind(this);
    }

    componentDidMount(){
        //this.readURL(this.props.file);
    }

    componentDidUpdate(prevProps){
        if(this.props.filePopOverOpen !== prevProps.filePopOverOpen && this.props.filePopOverOpen === true){
                let fileExtn = this.props.file && this.props.file.name ? this.props.file.name.split('.').pop() : '';
                this.setState({fileUrl: '', filePreviewError: '', fileType: fileExtn}, () => {
                    this.readURL(this.props.file).then(res => {
                        this.setState({fileUrl: res});
                    }).catch(err => {
                        console.error(err);
                        this.setState({filePreviewError: 'Unable to preview file at this time.'});
                    })
                });
        }
    }

    

   readURL(file) {
     let filePreviewPromise = new Promise((resolve, reject) => {
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file); 
            reader.onload = function(e) {
              resolve(reader.result);
            }
        }else{
            reject('File not found');
        }
     })

     return filePreviewPromise;
    }

    renderConditionalUI(){
        if(this.state.filePreviewError){
            return <div align="center" style={{color: 'red'}}>{this.state.filePreviewError}</div>
        }else if(this.state.fileUrl === ''){
            return <div align="center" style={{color: 'black'}}>Loading your check...</div>
        }else if(this.state.fileType && this.state.fileType.toLowerCase() === 'tiff'){
            return <img src={this.state.fileUrl} style={{height: '500px', width: '100%'}}/>
        }else{
            return  <iframe src={this.state.fileUrl} style={{ width: '100%', height: '500px' }}></iframe>
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.filePopOverOpen} toggle={this.props.filePopOverToggle}
                    className="RCM_CM_modal RCM_casemanage_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content RCM_casemanage_modalcontent"
                    zIndex='9999'
                    size='lg'
                    centered >
                    <ModalHeader toggle={this.props.filePopOverToggle} className="RCM_externalAssetModel_header RCM_manage_group_header">
                        <label className="RCM_tile_heading">CHECK PREVIEW</label>
                    </ModalHeader>
                    <ModalBody className="RCM_casemanage_modalBody">
                        {
                           this.renderConditionalUI()
                        }
                    </ModalBody>
                </Modal>
        )
    }
}

export default ShowFilePopOver;
