import { PureComponent } from "react";
import { Post } from "../../../../common/servicecalls.jsx";
import axios from "axios";
import { HistoricalAssetAllocationURL } from "../../../../common/constants.jsx";
import DateRanges from "../../../../common/dateranges.jsx";
import * as HistoricalAssetAllocationService from "./HistoricalAssetAllocationService";
import { RRR_12456_HistoricalAllocation, RRR_234567_HistoricalAllocation, Historicalallocationresponse, assetAllocationColorRes } from "../../accountsmockresponse.js";
import { getHistoricalSchemaValue, staticColorConfigService } from './../../../../common/assetschemautilities'
export class HistoricalAssetAllocationContainerBase extends PureComponent {
    aadcanellationToken;
    accountKey;
    dateRangeOptions;

    constructor(props) {
        super(props);

        this.aadcanellationToken = null;
        this.accountKey = null;
        this.dateRangeOptions = [
            DateRanges.PRIOR6MONTHS,
            DateRanges.PRIOR12MONTHS,
            DateRanges.CURRENTYEAR,
            DateRanges.PRIORYEAR,
            DateRanges.CUSTOMRANGE
        ];

        this.state = {
            isLoading: false,
            error: undefined,
            isLoadingAssetColor: false,
            errorAssetColor:undefined
        };
    }

    shouldUpdateData(prevProps) {
        if (prevProps && this.props.render !== prevProps.render) {
            return true;
        }

        if (!this.props.selectedaccount || !this.props.selectedaccount.length) {
            return false;
        }

        if (prevProps && this.props.assetSchema !== prevProps.assetSchema) {
            return true;
        }
        const currentAccountKey = this.generateAccountKey(
            this.props.selectedaccount
        );

        if (this.accountKey === currentAccountKey) {
            return false;
        }

        return true;
    }

    componentDidMount() {
        this.accountKey = ((this.props.chartType === 'Bar' ? this.props.historicalAssetAllocationDetails : this.props.historicalBalanceDetails) || {}).key;
        if (this.shouldUpdateData()) {
            const { filters } = (this.props.chartType === 'Bar' ? this.props.historicalAssetAllocationDetails : this.props.historicalBalanceDetails) || {};
            if (this.props.role === 'Prospect') {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute({
                    range: DateRanges.PRIOR12MONTHS,
                    ...filters
                }, this.props.assetSchema);
                this.executeAssetColor();
            }

        }

        this.accountKey = this.generateAccountKey(this.props.selectedaccount);
    }

    SetDummayResponseForProspectClient(isSummary=false) {
        let data = [];
        const { filters } = (this.props.chartType === 'Bar' ? this.props.historicalAssetAllocationDetails : this.props.historicalBalanceDetails) || {};
        if (this.props.selectedaccount.length == 1) {
            if (this.props.selectedaccount[0].key === "RRR-123456") {
                data = RRR_12456_HistoricalAllocation;
            }
            else if (this.props.selectedaccount[0].key === "RRR-234567") {
                data = RRR_234567_HistoricalAllocation;
            }
        } else if (this.props.selectedaccount.length == 2) {
            data = Historicalallocationresponse;
        }

        this.setState({ isLoading: false }, () => {
            if (this.props.chartType === 'Bar') {
                this.props.AddHistoricalAssetAllocationDetails({
                    key: this.accountKey,
                    data: data,
                    filters
                });
            }
            else {
                if (isSummary) {
                    this.props.AddHistoricalAssetAllocationSummaryDetails({
                        key: this.accountKey,
                        data: data,
                        filters
                    });
                }
                else {
                    this.props.AddHistoricalBalanceDetails({
                        key: this.accountKey,
                        data: data,
                        filters
                    });
                }
               
            }
            
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('resizeCharts', { bubbles: true }));
            }, 100);
        });

        this.setState({
            isLoadingAssetColor: false
        }, () => {
            this.props.AddAssetColor(assetAllocationColorRes)
        });      
       
    }

    componentDidUpdate(prevProps) {
        if (this.shouldUpdateData(prevProps)) {
            const { filters } = (this.props.chartType === 'Bar' ? this.props.historicalAssetAllocationDetails : this.props.historicalBalanceDetails) || {};
            if (this.props.role === 'Prospect') {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.execute({
                    range: DateRanges.PRIOR12MONTHS,
                    ...filters
                }, this.props.assetSchema);
            }
        }

        this.accountKey = this.generateAccountKey(this.props.selectedaccount);
    }

    generateAccountKey = HistoricalAssetAllocationService.generateAccountKey;

    fetchData(request) {
        // const useStub = true;
        // if (useStub) {
        //   const stub = require("./stub.json");
        //   return new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve({ data: stub });
        //     }, 100);
        //   });
        // }

        const baseUrl = HistoricalAssetAllocationURL;

        return Post(
            baseUrl,
            request,
            this.props.token,
            this.props.contextjson,
            this.props.profilejson,
            this.aadcanellationToken
        );
    }

    execute = async (filters, classValue,isSummary=false) => {
        this.setState(() => ({
            isLoading: true,
            error: undefined
        }));

        const dateRange = this.getDateRange(filters);

        const cancelMessage = "Operation canceled due to new request."
        if (this.aadcanellationToken) {
            this.aadcanellationToken.cancel(cancelMessage);
        }

        this.aadcanellationToken = axios.CancelToken.source();

        const accountKey = this.generateAccountKey(this.props.selectedaccount);

        try {
            if (this.props.chartType === 'Bar') {
                const result = await this.fetchData({
                    fromdate: dateRange.fromDate.toISOString(),
                    todate: dateRange.toDate.toISOString(),
                    grain: "Month",
                    aggregationType: getHistoricalSchemaValue(classValue)
                });

                this.props.AddHistoricalAssetAllocationDetails({
                    key: accountKey,
                    data: result.data,
                    filters
                });
            }
            else {
                const result = await this.fetchData({
                    fromdate: dateRange.fromDate.toISOString(),
                    todate: dateRange.toDate.toISOString(),
                    grain: "Month"
                    
                });
                if (isSummary) {
                    this.props.AddHistoricalAssetAllocationSummaryDetails({
                        key: accountKey,
                        data: result.data,
                        filters
                    });
                }
                else {

                    this.props.AddHistoricalBalanceDetails({
                        key: accountKey,
                        data: result.data,
                        filters
                    });
                }
               
            }
            this.setState({ isLoading: false });
            this.aadcanellationToken = null;
        } catch (error) {
            if (error.message !== cancelMessage) {
                this.setState({ error, isLoading: false });
                this.aadcanellationToken = null;
            }
        }
    };

    executeAssetColor = () => {
        this.setState({ isLoadingAssetColor: true, error: undefined }, () => {
            staticColorConfigService(this.props.token, this.props.contextjson, this.props.profilejson)
                .then(response => {
                    this.props.AddAssetColor(response.data)
                })
           .catch (error => {
                console.error(error);
               this.setState({ errorAssetColor:error,isLoadingAssetColor: false});
            });
        })
    }
            

    getDateRange = HistoricalAssetAllocationService.getDateRange;

    componentWillUnmount() {
        if (
            this.aadcanellationToken !== null &&
            this.aadcanellationToken !== undefined
        ) {
            this.aadcanellationToken.cancel(
                "Cancelling the token during unmount to prevent memory leak."
            );
        }
    }
}
