import { getNestedObject } from "../../../../MoneyMovement/common/utilty";

export class AggregratedAccount
{
   number;
   key;
   status;
   accttype;
   routingTransitNumber;
   nickname;
   role;
   stakeholder;
   managedaccount;
   finame;
   fiid;
}

export class AggregratedAccountRequest
{
    profileid;
    aggregatedAccounts
}


export function getAggregatedAccountInputJson(aggregatedAccountResponse, profileId)
{
    const aggregatedAcctList = getNwAggregatedAccounts(aggregatedAccountResponse);
    const aggregatedAcctReq =  new AggregratedAccountRequest();
    aggregatedAcctReq.profileid = profileId;
    aggregatedAcctReq.aggregatedAccounts =  aggregatedAcctList;
    return JSON.stringify(aggregatedAcctReq);
}

export function getNwAggregatedAccounts(aggregatedAccountResponse)
{
    let aggregatedAcctList = [];

    if(aggregatedAccountResponse && aggregatedAccountResponse.details && aggregatedAccountResponse.details.accounts )
    {
        //Check different types of accounts 
        let accounts  =  aggregatedAccountResponse.details.accounts;
        
        //get the financial institution name 
        let fiName = (accounts && accounts.fiName) ? accounts.fiName : "";
        let fiid = (accounts && accounts.fiDirId) ? accounts.fiDirId : "";

        //List of deposite accounts 
        if(accounts.depositAccount && Array.isArray(accounts.depositAccount) && accounts.depositAccount.length > 0)
        {
            accounts.depositAccount.map(depAcct => {
                aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(depAcct, fiName, fiid));
            })
        }

        //List of investement account
        if(accounts.investmentAccount && Array.isArray(accounts.investmentAccount) && accounts.investmentAccount.length > 0)
        {
            accounts.investmentAccount.map(invAcct => {
                aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(invAcct, fiName, fiid));
            })
        }

        //List of loc accounts 
        if(accounts.locAccount && Array.isArray(accounts.locAccount) && accounts.locAccount.length > 0)
        {
            accounts.locAccount.map(locAcct => {
                aggregatedAcctList.push(mapAggregatedAccountToRcmAcct(locAcct, fiName, fiid));
            })
        }
    }

    return aggregatedAcctList;
}

function mapAggregatedAccountToRcmAcct(account, fiName, fiid)
{
    let RcmAcct = new AggregratedAccount();
    //Map values here...
    RcmAcct.number = (account && account.accountNumber) ? window.btoa(account.accountNumber) : "";
    RcmAcct.key = (account && account.accountKey) ? account.accountKey : "";
    //RcmAcct.status = (account && account.accountStatus) ? account.accountStatus : "";
    RcmAcct.status = 'PENDING';
    RcmAcct.accttype = (account &&  account.accountType) ? account.accountType : "";
    RcmAcct.routingTransitNumber = (account && account.routingTransitNumber) ? account.routingTransitNumber : "";
    RcmAcct.nickname = (account && account.displayName) ? window.btoa(account.displayName) : "";
    RcmAcct.finame = fiName;
    RcmAcct.fiid = fiid;
    //we do not have role, stackholder and managed account
    //accountid and brokerid should be part of rcm account  

    return RcmAcct;
}

export function deleteExternalAccountJson(deleteAccount) {
    if (deleteAccount.deletelinkedaccount) {
        return {
            "extassetaggregationid": "f9b43c74-3d16-416b-906c-0895e24947a0",
            "accountkey": (deleteAccount) ? deleteAccount.key : null,
            "isremove": true,
            "deletelinkedaccount": deleteAccount.deletelinkedaccount,
            "deletelinkedaccountkey": deleteAccount.deletelinkedaccountkey
        }
    } else {
        return {
            "extassetaggregationid": "f9b43c74-3d16-416b-906c-0895e24947a0",
            "accountkey": (deleteAccount) ? deleteAccount.key : null,
            "isremove": true
        }
    }
}


