import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { MsgForNoData } from './../../../../common/constants.jsx';
import { getTopTenSummaryColumns } from './toptencolumns.jsx'
import TopTenChartDetails from './../../../../common/toptenchartdetails.jsx';
import Watermark from '../../../../common/watermark.jsx'
export function TopTenSummaryView(
    props
) {
    let toptensummaryview = (
        <div>
            <div className="row RCM_headingwrapper">
                <div className="col-sm-8">
                    <label className="RCM_tile_heading">TOP 10 HOLDINGS</label>
                </div>
                {props && props.state.isProspectClient ? <Watermark /> : ''}
                <div className="col-sm-4 RCM_expandviewright">
                    <div className="RCM_expandviewrightWrapper">
                        <div>
                            <button id="TooltipToptendetailToggle" onClick={props.toggleShowTable}
                                className={(props.showTable ? 'RCM_left_toggle' : 'RCM_left_bar_toggle')}></button>
                            <Tooltip placement="left" isOpen={props.state.tooltipOpen1} target="TooltipToptendetailToggle" toggle={props.toggle1}
                                innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                {props.showTable ? 'Toggle to Chart' : 'Toggle to Table'}
                            </Tooltip>
                            <label className="RCM_togglelabeltext" onClick={props.toggleShowTable}>{props.showTable ? 'Chart' : 'Table'}</label>
                        </div>
                        <div>
                            <Link to='Toptendetail' className="RCM_expandview" id="TooltipToptendetail"></Link>
                            <Tooltip placement="left" isOpen={props.state.tooltipOpen} target="TooltipToptendetail" toggle={props.toggle}
                                innerClassName="RCM_toolTip" arrowClassName="RCM_toolTip_arrow">
                                Holding Details
                                </Tooltip>
                            <Link to='Toptendetail'> <label className="RCM_togglelabeltext RCM_detailsLabel">Detail</label></Link>
                        </div>
                    </div>


                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-sm-12 col-md-12 col-xs-12 col-lg-12 RCM_toptentable_wrapper">
                    {props.showTable
                        ?
                        <div className="RCM_Dashboard_Tables RCM_CM_scroll">
                            <BootstrapTable
                                keyField='id'
                                data={props.datarows}
                                columns={getTopTenSummaryColumns(props.datarows)}
                                headerClasses="row-bg"
                                bordered={false}
                                rowClasses={props.rowClasses}
                                classes="RCM_two_level_table1"
                                noDataIndication={MsgForNoData} />
                        </div>
                        :
                        <TopTenChartDetails data={props.chartrows} datachart={props.datatth} />
                    }
                </div>
            </div>
        </div>);
    return toptensummaryview;
};