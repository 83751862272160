import React, { useEffect, useState } from "react";
import { Get, Post } from "../../common/servicecalls";
import { ProfileURL } from "../../common/constants";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DisclaimerText from "../personalisationandsettings/preference/disclaimerText";

const TermsAndConditionsModal = ({ isOpen, toogleModal, termsData }) => {
  const { token, contextjson, profilejson } =
    useSelector((store) => store.acct) || {};
  const { baseurl, eDeliveryDisclaimerPdf } =
    useSelector((store) => store.env) || {};
  const fetchProfileCancellationToken = axios.CancelToken.source();
  const [accountsState, setAccountsState] = useState({
    inProgress: false,
    error: undefined,
    accounts: [],
  });

  const [acceptProcessState, setAcceptProcessState] = useState({
    acceptInProgress: false,
    acceptError: undefined,
  });
  const [newContextKeys, setNewContextKeys] = useState({
    profileJson: "",
    contextJson: "",
  });

  const handleOnAccept = () => {
    setAcceptProcessState((prevState) => ({
      ...prevState,
      acceptError: undefined,
      acceptInProgress: true,
    }));

    Post(
      `${baseurl}profileapi/odata/edeliverystatus`,
      null,
      token,
      newContextKeys.contextJson,
      newContextKeys.profileJson
    )
      .then(() => {
        setAcceptProcessState((prevState) => ({
          ...prevState,
          acceptError: undefined,
          acceptInProgress: false,
        }));
        toogleModal(true);
      })
      .catch((err) => {
        setAcceptProcessState((prevState) => ({
          ...prevState,
          acceptError: err,
          acceptInProgress: false,
        }));
      });
  };

  useEffect(() => {
    //Check if we need to show Terms and conditions for Edelivery
    setAccountsState((prevState) => ({
      ...prevState,
      inProgress: true,
      error: undefined,
      accounts: [],
    }));
    Get(
      `${ProfileURL} ,true')`,
      token,
      contextjson,
      profilejson,
      fetchProfileCancellationToken
    )
      .then((profileResponse) => {
        var eDeliveriesData = [];
        //save new profilejson and contextjson
        if (profileResponse.headers) {
          setNewContextKeys(() => ({
            profileJson: profileResponse.headers["profilejson"],
            contextJson: profileResponse.headers["contextjson"],
          }));
        }

        if (
          profileResponse.data &&
          profileResponse.data[0] &&
          profileResponse.data[0].profile
        ) {
          const profileAccounts = profileResponse.data[0].profile.accounts;
          if (Array.isArray(profileAccounts)) {
            profileAccounts.map((account) => {
              eDeliveriesData.push(...(account.eDelivery || []));
            });
          }
        }
        setAccountsState((prevState) => ({
          ...prevState,
          inProgress: false,
          error: undefined,
          accounts: eDeliveriesData,
        }));
      })
      .catch((err) => {
        setAccountsState((prevState) => ({
          ...prevState,
          inProgress: false,
          error: err,
          accounts: [],
        }));
      });
  }, []);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toogleModal}
        backdrop={false}
        size="lg"
        zIndex="9999"
        className="RCM_CM_modal"
        wrapClassName="RCM_CM_modal_wrapper"
        modalClassName="RCM_CM_modal_class"
        contentClassName="RCM_CM_modal_content"
        centered
      >
        <ModalHeader toggle={() => toogleModal(true)} charCode="X">
          <label className="RCM_tile_heading">TERMS AND CONDITIONS</label>
        </ModalHeader>
        <ModalBody>
          <>
            {acceptProcessState.acceptError && (
              <div className="RCM_Teamwork_error">
                We are unable to save your acceptance, Please try after
                sometime.
              </div>
            )}

            {accountsState.inProgress && (
              <div
                className="RCM_spinner"
                align="center"
                style={{ marginTop: "0.5rem" }}
              >
                Loading EDelivery Information
                <div>
                  <Spinner type="grow" color="primary" />
                  <Spinner type="grow" color="primary" />
                  <Spinner type="grow" color="primary" />
                </div>
              </div>
            )}
            {accountsState.error && (
              <div className="RCM_Teamwork_error">
                We are unable to fetch opted eDelivery information, Please try
                again after sometime.
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  termsData && termsData[0] && termsData[0].notes
                    ? termsData[0].notes
                    : "",
              }}
            ></div>
            {!accountsState.inProgress && (
              <div className="RCM_eDelivery_summary_table RCM_table_scorllable_body mt-1">
                <table className="table RCM_two_level_table1">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Confirms/ Confirming Prospectuses</th>
                      <th>Statements and Regulatory Inserts</th>
                      <th>Eligible Customer Correspondence</th>
                      <th>Tax Forms and Related Documents</th>
                      <th>{`Shareholder Reports & Other Documents`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountsState &&
                      accountsState.accounts &&
                      accountsState.accounts.map((account, index) => {
                        return (
                          <tr key={"eDeliveryAccount" + index}>
                            <td style={{ wordWrap: "break-word" }}>
                              {account.accountNumber}
                              <br />
                              <div style={{fontSize: '0.60rem'}}>
                                {`${account.name ? account.name : ""} 
                             ${
                               account.email ? account.email.toUpperCase() : ""
                             }`}
                              </div>
                            </td>
                            <td>
                              {account.enrolled === "Pending" ||
                              account.enrolled === "Not Available" ? (
                                <span>{account.enrolled}</span>
                              ) : (
                                <span>
                                  {account.cfrmsSuprnCd || 'N'}
                                </span>
                              )}
                            </td>
                            <td>
                              {account.enrolled === "Pending" ||
                              account.enrolled === "Not Available" ? (
                                <span>{account.enrolled}</span>
                              ) : (
                                <span>
                                  {account.stmtsSuprnCd || 'N'}
                                </span>
                              )}
                            </td>
                            <td>
                              {account.enrolled === "Pending" ||
                              account.enrolled === "Not Available" ? (
                                <span>{account.enrolled}</span>
                              ) : (
                                <span>
                                  {account.custCorrSuprnCd || 'N'}
                                </span>
                              )}
                            </td>
                            <td>
                              {account.enrolled === "Pending" ||
                              account.enrolled === "Not Available" ? (
                                <span>{account.enrolled}</span>
                              ) : (
                                <span>
                                  {account.taxFormSuprnCd || 'N'}
                                </span>
                              )}
                            </td>
                            <td>
                              {account.enrolled === "Pending" ||
                              account.enrolled === "Not Available" ? (
                                <span>{account.enrolled}</span>
                              ) : (
                                <span>
                                  {account.rptSuprnCd || 'N'}
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div
                  className="RCM_CM_scroll RCM_profile_editmail"
                  style={{ marginBottom: "0.3rem" }}
                >
                  <DisclaimerText />
                </div>
                <div className="row RCM_deleteLinkedAccount_option">
                  <div className="col-sm-12">
                    <span>
                      <a
                        href={eDeliveryDisclaimerPdf}
                        target="_blank"
                        rel="noreferrer"
                        className="RCM_link RCM_our_ideas"
                      >
                        Click here to print or download a copy of the agreement
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary RCM_button_secondary"
            onClick={() => toogleModal(true)}
          >
            SKIP
          </button>
          <button
            type="button"
            className="btn btn-secondary RCM_button_primary"
            onClick={handleOnAccept}
            disabled={
              !(
                accountsState &&
                accountsState.accounts &&
                accountsState.accounts.length > 0
              )
            }
          >
            I AGREE
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TermsAndConditionsModal;
