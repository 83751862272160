import { FETCH_EXTERNAL_ACCT, ADD_EXTERNAL_ACCT, REMOVE_RECENTEXTACCT } from "./actiontypes.jsx";

export function fetchExternalAccounts(data)
{
    return {
        type: FETCH_EXTERNAL_ACCT,
        payload: data
    }
}

export function addExternalAccountAction(data, newExtAcctId)
{
    return{
        type:ADD_EXTERNAL_ACCT,
        payload:{
            list: data,
            recentExtAcctId: newExtAcctId
        }
    }
}

export function Remove_LatestExtAcct()
{
    return {
        type: REMOVE_RECENTEXTACCT
    }
}