import axios from 'axios';
import { _logException, _logInformation } from './_logging';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    _logInformation(`Api call:: ${config && config.url ? config.url: 'No url found'}`);
    return config;
  }, function (error) {
    // Do something with request error
    _logException("Error occured in request to call api");
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    logErrors(error);
    return Promise.reject(error);
  });


function logErrors(error) {
    if (axios.isCancel(error)) {
        console.log(error);
    } else {
        //Log the error on console incase of failure.
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            _logException({"data": error.response.data, "status": error.response.status});
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js;
            _logException(`Error present in request, mostly due to apim configuration`);
        } else {
            // Something happened in setting up the request that triggered an Error
            _logException({'Error': error.message});
        }
        //console.log(error.config);
        //_logException(error);
    }
}

 export default axios;