import React, { useEffect } from "react";

const NinthWaveCommercialWidget = ({ url, handleExit, handleFinicityCompleted }) => {
  useEffect(() => {
    //load finicity SDK
    const scriptElement = window.document.createElement("script");
    scriptElement.src =
      "https://connect2.finicity.com/assets/sdk/finicity-connect.min.js";
    scriptElement.async = true;
    scriptElement.onload = () => {
      //SDK implemetation for finicity
      window.finicityConnect.launch(url, {
        selector: "#finicityIFrameContainer",
        overlay: 'rgba(255,255,255, 0)',
        success: () => {
          handleFinicityCompleted()
        },
        cancel: () => {
          handleExit();
        },
        error: () => {
          handleExit();
        },
        loaded: () => {
          console.log(
            "This gets called only once after the iframe has finished loading "
          );
        },
      });
    };
    window.document.body.appendChild(scriptElement);
    return () => {
      window.document.body.removeChild(scriptElement);
      window.finicityConnect.destroy();
    };
  }, [handleExit, handleFinicityCompleted, url]);

  return (
      <div id="finicityIFrameContainer"  />
  );
};

export default NinthWaveCommercialWidget;
