import React from 'react'
import {
    getFiLogoImageUrl,
    getLoginBackGroundColor,
    lightOrDark
  } from "./ninthWaveUtility";

function ResetPasswordSuccess(props) {
    const logonStyle = {
        backgroundColor: getLoginBackGroundColor(props.fiLogin),
      };
    
      const fontStyle = {
        color:
          lightOrDark(getLoginBackGroundColor(props.fiLogin)) === "light"
            ? "#252f39"
            : "#fff",
      };
    return (

        <div align="center" className="RCM_Aggr_Login_Container" style={logonStyle}>
            <div className="RCM_Aggr_Login_logo_wrap">
                <img src={getFiLogoImageUrl(props.fiLogin.uiAssets)} alt=""/>
            </div>
            <h4 style={fontStyle}>
                {props && props.fiLogin && props.fiLogin.name ? props.fiLogin.name : ""}
            </h4>
            <h6 style={fontStyle}>PASSWORD RESET SUCCESSFULLY.</h6>
            <div className="mt-3" align="center">
                <button
                    onClick={() => props.handleExit()}
                    className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size RCM_overlapping_btn"
                    >
                    EXIT
                </button>
            </div>
        </div>
        
    )
}

export default ResetPasswordSuccess
