import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlayProcessing from './common/overlayProcessing';
import { getWithHoldingInfo, mmvalidateTransfer } from './moneymovementService';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import Messages from "../../common/messages.jsx";
import { Object } from 'core-js';
import JournalPreConfirm from './journalPreConfirm';

class TransferWithhold extends Component {

    constructor(props){
        super(props);

        this.state = {
            showOverlay: false,
            errorMsg: '',
            disableBtnBack: true,
            disableBtnSubmit: true,
            withholddata: {},
            withholdRsnDropdownOpen: false,
            withholdRsn: {},
            disableStateHoldings: true,
            disableFedHoldings: true,
            fedWithholdOption: 'WTHD_NA',
            stateWithholdOption: 'WTHD_NA',
            statewithholdpct: 0,
            fedwithholdpct: 0,
            includewithholdingAmt: false,
            showPreConfirmScreen: false,
            fedwithholdrateChange: 0,
            statewithholdratechange : 0
        }

        this.withholdRsnDropdownToggle = this.withholdRsnDropdownToggle.bind(this);
        this.validateTransfer = this.validateTransfer.bind(this);
    }

    componentDidMount(){
        //call MMContext with mode1
        this.setState({showOverlay: true}, () => {
            getWithHoldingInfo(this.props.transferData, this.props.eligiblePairAccts, 
                this.props.token, this.props.contextjson, this.props.profilejson).then(res => {
                    console.log('With Holdings::', res);
                    this.setState({showOverlay: false, withholddata: res, disableBtnBack: false, disableBtnSubmit: false, disableFedHoldings: false, disableStateHoldings: false, fedWithholdOption: 'WTHD_NO', stateWithholdOption: 'WTHD_NO'});
    
            }).catch(err => {
                this.setState({showOverlay: false, disableBtnBack: false, disableBtnSubmit: true});
            })
        })
        

    }

    withholdRsnDropdownToggle(){
        this.setState({withholdRsnDropdownOpen: !this.state.withholdRsnDropdownOpen})
    }

    onWithHoldRsnChange(objReason){
        let stateChangeObj = {};

        if(objReason && objReason.canfedwithhold && objReason.canfedwithhold === 'Y'){
            stateChangeObj.disableFedHoldings = false;
            stateChangeObj.fedWithholdOption =  (this.state.fedWithholdOption && this.state.fedWithholdOption !== 'WTHD_NA' ) ? this.state.fedWithholdOption : 'WTHD_NO';
            stateChangeObj.fedwithholdpct = (this.state.fedWithholdOption && this.state.fedWithholdOption !== 'WTHD_NA' )  ? this.state.fedwithholdpct : 0;
        }else{
            stateChangeObj.disableFedHoldings = true;
            stateChangeObj.fedWithholdOption = 'WTHD_NA';
            stateChangeObj.fedwithholdpct = 0;
        }

        if(objReason && objReason.canstatewithhold && objReason.canstatewithhold === 'Y'){
            stateChangeObj.disableStateHoldings = false;
            stateChangeObj.stateWithholdOption = (this.state.stateWithholdOption && this.state.stateWithholdOption !== 'WTHD_NA' ) ? this.state.stateWithholdOption : 'WTHD_NO';
            stateChangeObj.statewithholdpct = (this.state.stateWithholdOption && this.state.stateWithholdOption !== 'WTHD_NA' ) ?  this.state.statewithholdpct : 0;
        }else{
            stateChangeObj.disableStateHoldings = true;
            stateChangeObj.stateWithholdOption = 'WTHD_NA';
            stateChangeObj.statewithholdpct = 0;
        }

        this.setState({...stateChangeObj, withholdRsn: objReason})
    }

    onChangeFedHolding(selectedOption){

        let fedholdpercentage = 0;
        if(selectedOption === 'WTHD_NA' || selectedOption === 'WTHD_NO'){
            fedholdpercentage = 0;
        }
        
        if(selectedOption === 'WTHD_RT'){
            //take the value from inut field. Withhold should be greter than minimum and less than maximun
            fedholdpercentage = this.state.fedwithholdrateChange;
        }

        this.setState({fedWithholdOption: selectedOption, fedwithholdpct: fedholdpercentage, fedwithholdrateChange: 0});
    }

    onChangeStateHolding(selectedOption){

        let stateholdpercentage = 0;
        if(selectedOption === 'WTHD_NA' || selectedOption === 'WTHD_NO'){
            stateholdpercentage = 0;
        }
        
        if(selectedOption === 'WTHD_RT'){
            //take the value from inut field. Withhold should be greter than minimum and less than maximun
            stateholdpercentage = this.state.statewithholdratechange;
        }

        if(selectedOption === 'WTHD_MN'){
            //assign minimum withhold percentage 
            stateholdpercentage = this.state.withholddata.statewithholdminpct;
        }

        this.setState({stateWithholdOption: selectedOption, statewithholdpct: stateholdpercentage, statewithholdratechange: 0});
    }

    handleIncludeWithholdingAmtChange(){
        this.setState({includewithholdingAmt: !this.state.includewithholdingAmt});
    }

    validateTransfer(){
        let transferData = this.props.transferData;
        transferData.statewithholdpct = parseInt(this.state.statewithholdpct);
        transferData.fedwithholdpct = parseInt(this.state.fedwithholdpct);
        transferData.istrnamtincludetaxwithhold =  !this.state.includewithholdingAmt;
        transferData.retdistreason = this.state.withholdRsn;

        console.log(transferData);

        this.setState({showOverlay: true, errorMsg: ''}, () => {
            mmvalidateTransfer(transferData, this.props.eligiblePairAccts, 
                this.props.token, this.props.contextjson, this.props.profilejson).then(res => {
                    transferData.totalamtincludetaxwithhold =  res.totalamtincludetaxwithhold;
                    transferData.fedwithholdamt = res.fedwithholdamt;
                    transferData.statewithholdamt = res.statewithholdamt;
                    this.setState({showOverlay: false, showPreConfirmScreen: true});
    
            }).catch(err => {
                this.setState({showOverlay: false, errorMsg: err});
            })
        });

    }

    handleFedWithholdRateChange(event){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({fedwithholdrateChange: event.target.value, fedwithholdpct: event.target.value, fedWithholdOption: 'WTHD_RT'});
        }
    }

    handleStateWithholdRateChange(event){

        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({statewithholdratechange: event.target.value, statewithholdpct: event.target.value, stateWithholdOption: 'WTHD_RT'});
        }
    }

    backToWithholding(){
        this.setState({showPreConfirmScreen: false});
    }

    render() {

        if(!this.state.showPreConfirmScreen){
            return (
                <div>
                    {
                        this.state.showOverlay === true ? (
                            <OverlayProcessing />
                        ) : ""
                    }

                    {
                        this.state.errorMsg !== "" ?
                            (<div className="RCM_validation_error_container" >
                                <div className="RCM_msg_text">{this.state.errorMsg}</div>
                            </div>) : ""
                    }

                    <div className="mt-4 RCM_CM_form_radio RCM_CM_withhold_toast">
                        <label className="RCM_Toast_heading">TRANSFER SUMMARY</label>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-6">
                                <div className="RCM_CM_form_label">
                                    <label>TRANSFER FROM</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div>
                                    <label className="RCM_Info_text"> {this.props.transferData.fromAcctDisplayName} </label>
                                </div>
                            </div>
                        </div>
                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-6">
                                <div className="RCM_CM_form_label">
                                    <label>TRANSFER TO</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div>
                                    <label className="RCM_Info_text" > {this.props.transferData.toAcctDisplayName} </label>
                                </div>
                            </div>
                        </div>

                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-6">
                                <div className="RCM_CM_form_label">
                                    <label>INITIATE TRANSFER ON</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                    <label className="RCM_Info_text"> {this.props.transferData.transferDate} </label>
                                </div>
                        </div>

                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-6">
                                <div className="RCM_CM_form_label">
                                    <label>TRANSFER AMOUNT</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                    <label className="RCM_Info_text" > {this.props.transferData.displayAmount} </label>
                                </div>
                        </div>

                        <div className="row RCM_CM_form_row">
                            <div className="col-sm-6">
                                <div className="RCM_CM_form_label">
                                    <label>OCCURRENCE</label>
                                </div>
                                
                            </div>
                            <div className="col-sm-6">
                                    <label className="RCM_Info_text" > {this.props.transferData.transferType} </label>
                                </div>
                        </div>
                    </div>

                    <div className="row RCM_CM_form_row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="RCM_CM_form_label">
                                        <label>DISBURSEMENT REASON</label>
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <Messages
                                        messages=""
                                        containerClass="" />
                                </div>
                            </div>
                            <Dropdown name="withholdrsn" required
                                isOpen={this.state.withholdRsnDropdownOpen}
                                toggle={this.withholdRsnDropdownToggle}
                                className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                <DropdownToggle caret>{this.state.withholdRsn.reasondesc}</DropdownToggle>
                                <DropdownMenu>
                                    {
                                        (this.state.withholddata !== null
                                            && this.state.withholddata !== undefined && this.state.withholddata.retdistreason !== null
                                            && this.state.withholddata.retdistreason !== undefined
                                            && Array.isArray(this.state.withholddata.retdistreason)
                                            && this.state.withholddata.retdistreason.length > 0) && (
                                                <div>
                                                    {this.state.withholddata.retdistreason.map((withholdrsn, index) =>
                                                        <DropdownItem key={index} onClick={() => this.onWithHoldRsnChange(withholdrsn)}>
                                                            <div>{withholdrsn.reasondesc}</div>
                                                        </DropdownItem>
                                                    )}
                                                </div>
                                            ) 
                                    }

                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="mt-4 RCM_CM_form_radio RCM_CM_withhold_toast">
                        <label className="RCM_Toast_heading">FEDRAL TAX WITHHOLDING</label>
                        {
                            (!this.state.disableFedHoldings) ? (
                                <div className="mt-2">
                                    <label>
                                        <input type="radio" name="fedHolding" disabled={this.state.disableFedHoldings} value="WTHD_NO" checked={this.state.fedWithholdOption === 'WTHD_NO'} onChange={() => this.onChangeFedHolding('WTHD_NO')}></input>
                                        <span>Do not withhold</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="fedHolding" disabled={this.state.disableFedHoldings} value="WTHD_RT" checked={this.state.fedWithholdOption === 'WTHD_RT'} onChange={() => this.onChangeFedHolding('WTHD_RT')}></input>
                                        <span>Withhold at the rate of &nbsp;
                                            <input type="text" className="RCM_form_control RCM_CM_Withhold_radio_text" maxLength="3" onChange={(e) => this.handleFedWithholdRateChange(e)} value={this.state.fedwithholdrateChange} />
                                            &nbsp;{(this.state.withholddata && this.state.withholddata.fedwithholdminpct && this.state.withholddata.fedwithholdmaxpct) && (<div className="RCM_Info_text">({this.state.withholddata.fedwithholdminpct} - {this.state.withholddata.fedwithholdmaxpct})</div>)}&nbsp;%</span>
                                    </label>
                                </div>
                            ) : (
                                    <div className="mt-2">
                                        <label>
                                            <input type="radio" name="fedHolding" disabled={!this.state.disableFedHoldings} value="WTHD_NA" checked={this.state.fedWithholdOption === 'WTHD_NA'} onChange={() => this.onChangeFedHolding('WTHD_NA')}></input>
                                            <span>Not applicable</span>
                                        </label>
                                    </div>
                                )
                        }
                        
                    </div>

                    <div className="mt-4 RCM_CM_withhold_toast">
                        <label className="RCM_Toast_heading">{(this.state.withholddata && this.state.withholddata.legalstate) && (this.state.withholddata.legalstate)} STATE TAX WITHHOLDING</label>
                        {
                            (!this.state.disableStateHoldings) ? (
                                <div className="mt-2">
                                    <label>
                                        <input type="radio" name="stateHolding" disabled={this.state.disableStateHoldings} value="WTHD_NO" checked={this.state.stateWithholdOption === 'WTHD_NO'} onChange={() => this.onChangeStateHolding('WTHD_NO')}></input>
                                        <span>Do not withhold</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="stateHolding" disabled={this.state.disableStateHoldings} value="WTHD_RT" checked={this.state.stateWithholdOption === 'WTHD_RT'} onChange={() => this.onChangeStateHolding('WTHD_RT')}></input>
                                        <span>Withhold at the rate of &nbsp; <input type="text" className="RCM_form_control RCM_CM_Withhold_radio_text" maxLength="3" onChange={(e) => this.handleStateWithholdRateChange(e)} value={this.state.statewithholdratechange} /> &nbsp; {(this.state.withholddata && this.state.withholddata.statewithholdminpct && this.state.withholddata.statewithholdmaxpct) && (<div className="RCM_Info_text">({this.state.withholddata.statewithholdminpct} - {this.state.withholddata.statewithholdmaxpct})</div>)}&nbsp;%</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="stateHolding" value="WTHD_MN" disabled={this.state.disableStateHoldings} checked={this.state.stateWithholdOption === 'WTHD_MN'} onChange={() => this.onChangeStateHolding('WTHD_MN')}></input>
                                        <span>Withhold minimum</span>
                                    </label>
                                </div>
                            ) :
                                (
                                    <div className="mt-2">
                                        <label>
                                            <input type="radio" name="stateHolding" value="WTHD_NA" disabled={!this.state.disableStateHoldings} checked={this.state.stateWithholdOption === 'WTHD_NA'} onChange={() => this.onChangeStateHolding('WTHD_NA')}></input>
                                            <span>Not applicable</span>
                                        </label>
                                    </div>
                                )
                        }
                        
                    </div>
                    
                    <div className="mt-2">
                        <label className="RCM_selectLabel">
                            <input type="checkbox" checked={this.state.includewithholdingAmt} onChange={() => this.handleIncludeWithholdingAmtChange()}/>
                            <span className="profile-font-color RCM_preference_label">Don't include withholding on transaction amount</span>
                        </label>
                    </div>

                    <div className="row RCM_CM_form_row">
                                <div className="col-sm-12" align="center">

                                <button type="button"
                                        className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size"
                                        onClick={this.props.backToEdit} disabled={this.state.disableBtnBack}>
                                        BACK</button>
                                &nbsp; &nbsp;
                                    <button
                                        type="button"
                                        className="btn RCM_button_primary RCM_select_btn RCM_CM_btn_spacing RCM_reduce_btn_size"
                                        onClick={this.validateTransfer}
                                        disabled={this.state.disableBtnSubmit}
                                    >
                                        SUBMIT</button>
                                        
                                </div>
                    </div>
                </div>
            )}
        else {
            return (
                    <JournalPreConfirm
                        transferData={this.props.transferData}
                        eligiblePairAccts={this.props.eligiblePairAccts}
                        backToEdit={this.props.backToEdit}
                        backToWithholding =  {() => this.backToWithholding()}
                        backToLending={this.props.backToLending}
                        newTransferEvent={this.props.newTransferEvent}
                    />
            );
        }
    }
}


const mapStateToProps = state => {
    return {
        token: state.acct.token,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson
    };
};

export default connect(mapStateToProps)(TransferWithhold);;
