import React, { Component } from 'react'
import { getNestedObject } from '../MoneyMovement/common/utilty';
import { connect } from 'react-redux';
import { getAALoginToken, updateExternalAccountsList, updatePasswordStatusService } from '../accounts/detail/assetaggregation/assetaggregationservice';
import { compose } from 'redux';
import { withPageView } from '../../hoc/withPageView';
import { fetchFiList, fetchFiProfile, fetchCustomerAccounts, provideCustomerConsent, enrollCustomerAccounts, resetPasswordService, fetchAllFiList, fetchCommercialWidgetDetails } from '../../services/ninthwaves/ninthwaveservices';
import NinthWaveFiList from './ninthWaveFiList';
import NinthWaveLogin from './ninthWaveLogin';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy'
import NinthWaveAcctList from './ninthWaveAcctList';
import { getSelectedAccountDetails, getEnrollmentRequestDetails, getMaskedLoginRequest } from './ninthWaveUtility';
import { getUserProfile } from '../../services/home/userServices';
import { getUpdatedAccountList } from '../../common/constants';
import { updateAggregatedAccounts } from '../../actions/accountsactions';
import { getNwAggregatedAccounts } from '../accounts/detail/assetaggregation/models/assetAggregationInfo';
import OverlayProcessing from '../MoneyMovement/common/overlayProcessing';
import { animateFinancialInstitutionList } from './ninthWaveAnimations';
import { _logInformation, _logException } from '../../common/_logging';
import ResetPasswordSuccess from './resetPasswordSuccess';
import {getUserAggrAcctsList} from './ninthWaveUtility'
import NinthWaveCommercialWidget from './ninthWaveCommercialWidget';


class NinthWaveContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            errormsg: '',
            fiList: [],
            filteredFiList: [],
            appId: '',
            nwToken: '',
            userDetails: {
                firstName: getNestedObject(props.profileResp, ["firstname"]),
                lastName: getNestedObject(props.profileResp, ["lastname"]),
                aadUserId: (props.aaduseroid) ? props.aaduseroid : "",
                emailId: (props.userEmailId) ? props.userEmailId : ""
            },
            fiLogin: {},
            fiAccounts: {},
            loginUserCred: {},
            loginUserMFAChallenged: {},
            selectedAcctKeys: [],
            searchKey:'',

            showFiList: true,
            showFiLogin: false,
            showAccountList: false,

            showOverlay: false,
            showFiAsList: true,
            showMfaScreen: false,
            showPasswordResetSuccess: false,
            passwordResetFiid: props.passwordResetFiid,
            timer: null,
            showCommercialWidget: false,
            isCommercialWidgetFlow: false,
            commercialWidget: {
                url: undefined,
                error: undefined,
                customerKey: undefined,
                sessionKey: undefined
            }

        }
    }

    componentDidMount(){
        //Call Asset aggregation api to get the latest token
        //once we have valid token and app id then call ninthwaves api
         this.state.passwordResetFiid ? this.handleFiSelection({"fiDirId": this.state.passwordResetFiid}) : this.loadFiData();
        _logInformation('RCMNinthWaves Financial Institution List Page');
    }

    componentDidUpdate(prevProps){
        if (this.props.render !== prevProps.render) {
            this.setState({
                fiList: [],
                filteredFiList: [],
                searchKey: '',
                loginUserCred: {},
                showFiList: true,
                showFiLogin: false,
                showAccountList: false,
                showCommercialWidget: false,
                selectedAcctKeys: [],
                passwordResetFiid: undefined
            }, () => {
                this.loadFiData();
            });
           
        }

        if(this.props.passwordResetFiid !== prevProps.passwordResetFiid){
            this.setState({passwordResetFiid: this.props.passwordResetFiid}, () => {
                this.handleFiSelection({fiDirId: this.props.passwordResetFiid})
            })
        }
    }

    loadFiData(){
        this.setState({showOverlay: true},  () => {
            getAALoginToken(this.state.userDetails.aadUserId, this.props.token, this.props.contextjson, this.props.profilejson)
            .then(res => { 
                _logInformation('RCMNinthWaves:: RCM Ninth wave Fetched Login token')
                this.setState({
                    nwToken: getNestedObject(res, ['nwtoken']),
                    appId: getNestedObject(res, ['applicationid'])
                }, () => {
                    //Call filist api here...
                    fetchFiList(this.state.nwToken, this.state.appId, this.props.token).then(fiListRes => {
                        if(fiListRes && fiListRes.data && fiListRes.data.featuredFIList && Array.isArray(fiListRes.data.featuredFIList) && fiListRes.data.featuredFIList.length > 0 ){
                            const sortedFiList = (sortBy(fiListRes.data.featuredFIList, ['name']))
                            this.setState({fiList: sortedFiList, filteredFiList: sortedFiList, showOverlay: false}, () => {
                                //Animate the list of the financial institution 
                                _logInformation('RCMNinthWaves:: RCM Ninth Wave Financial Institution List Loaded.');
                                animateFinancialInstitutionList(this.state.showFiAsList);
                            });
                        }
                        
                    })
                    .catch(err => {
                        console.error(err);
                        this.setState({showOverlay: false});
                    })
                })
            })
            .catch(err => { 
                console.error(err);
                this.setState({showOverlay: false});
            });  
        })
    }

    fetchFiProfileDetails(fi) {
        fetchFiProfile(fi.fiDirId, this.state.nwToken, this.state.appId, this.props.token)
            .then(res => {
                if(res && res.data){
                    //check here if profile response contains 
                    _logInformation(`RCMNinthWaves:: Fiid ${fi.fiDirId}, FiName ${fi.name}  profile fetched`);
                    _logInformation(res.data)
                    if(res.data.signOnInfo && res.data.signOnInfo.signonType === 'COMMERCIAL') {
                        const data = {
                            "customer":{
                                "name":{
                                    "firstName": this.state.userDetails.firstName,
                                    "lastName": this.state.userDetails.lastName
                                },
                                "email":[this.state.userDetails.emailId]}
                        }
                        fetchCommercialWidgetDetails(data, fi.fiDirId, this.state.nwToken, this.state.appId, this.props.token)
                        .then(widgetResponse => {
                            if(widgetResponse && widgetResponse.data && widgetResponse.data.payload && widgetResponse.data.payload.code === 0) {
                                const widgetUrl = widgetResponse.data.payload.link
                                this.setState((prevState) => ({
                                  ...prevState,
                                  showFiList: false,
                                  showOverlay: false,
                                  showCommercialWidget: true,
                                  fiLogin: res.data,
                                  isCommercialWidgetFlow: true,
                                  commercialWidget: {
                                    url: widgetUrl,
                                    error: undefined,
                                    customerKey: widgetResponse.data.customerKey,
                                    sessionKey: widgetResponse.data.sessionKey,
                                  },
                                }));
                            } else {
                                this.setState((prevState) => ({
                                  ...prevState,
                                  showFiList: false,
                                  showOverlay: false,
                                  showCommercialWidget: true,
                                  fiLogin: res.data,
                                  isCommercialWidgetFlow: true,
                                  commercialWidget: {
                                    url: undefined,
                                    error: new Error("Failed to load widget"),
                                    customerKey: undefined,
                                    sessionKey: undefined
                                  },
                                }));
                            }
                        })
                        .catch(err => {
                            this.setState((prevState) => ({
                              ...prevState,
                              showOverlay: false,
                              showFiList: false,
                              showCommercialWidget: true,
                              fiLogin: res.data,
                              isCommercialWidgetFlow: true,
                              commercialWidget: {
                                url: undefined,
                                error: err,
                                customerKey: undefined,
                                sessionKey: undefined,
                              },
                            }));
                        })
                    }
                    else {
                        this.setState({
                          fiLogin: res.data,
                          showFiLogin: true,
                          showFiList: false,
                          showAccountList: false,
                          showOverlay: false,
                          showCommercialWidget: false,
                          isCommercialWidgetFlow: false,
                          commercialWidget: {
                            url: undefined, 
                            error: undefined,
                            customerKey: undefined,
                            sessionKey: undefined
                          }
                        });
                    }
                }
            })
            .catch(err => {
                this.setState({showOverlay: false});
                console.error(err);
                _logException(`RCMNinthWaves:: Fiid ${fi.fiDirId} profile failed`);
            })
    }


    handleFiSelection(fi){
        if(fi && fi.fiDirId){
            this.setState({ showOverlay: true, showFiLogin: false, loginUserCred: {}, errormsg:"" }, () => {
                _logInformation(`RCMNinthWaves:: Fiid ${fi.fiDirId} , FiName ${fi.name} selected`);
                //check if token is not present 
                if(this.state.nwToken && this.state.appId){
                    this.fetchFiProfileDetails(fi)
                } else {
                    getAALoginToken(this.state.userDetails.aadUserId, this.props.token, this.props.contextjson, this.props.profilejson)
                    .then(res => {
                        _logInformation('RCMNinthWaves:: RCM Ninth wave Fetched Login token')
                        this.setState({
                            nwToken: getNestedObject(res, ['nwtoken']),
                            appId: getNestedObject(res, ['applicationid'])
                        }, () => {
                            this.fetchFiProfileDetails(fi)
                        })
                    })
                }
            
            })
        }
    }

    fetchFilteredFiList() {
        if(this.state.searchKey){
        this.setState({showOverlay: true},  () => {
            fetchAllFiList(this.state.searchKey, this.state.nwToken, this.state.appId, this.props.token).then(fiListRes => {
                if(fiListRes && fiListRes.data && fiListRes.data.fiList && Array.isArray(fiListRes.data.fiList) && fiListRes.data.fiList.length > 0 ){
                    const sortedFiList = (sortBy(fiListRes.data.fiList, ['name']))
                    this.setState({filteredFiList: sortedFiList, showOverlay: false}, () => {
                        //Animate the list of the financial institution 
                        _logInformation('RCMNinthWaves:: RCM Ninth Wave Financial Institution List Loaded After Search.');
                        animateFinancialInstitutionList(this.state.showFiAsList);
                    });
                } else {
                    this.setState({showOverlay: false, filteredFiList: []});
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({showOverlay: false});
            })
        })
      }
    }

    handleSearchFinancialInstitution(event){
        if(event.target.value !== ''){
            //Filter financial institution and set state 
            if (this.state.timer) {
                clearTimeout(this.state.timer)
            }
            this.setState({searchKey: event.target.value, 
                timer: setTimeout(() => {this.fetchFilteredFiList(event.target.value)}, 1500)})   
        }else{
            //Set state with all financial institution
            this.resetSearchList();
        }
    }

    resetSearchList(){
        this.setState({filteredFiList: this.state.fiList, searchKey: '', errormsg:''},  () => {
            animateFinancialInstitutionList(this.state.showFiAsList);
        });
    }

    showFiList(){
        this.setState({showFiList: true, showFiLogin: false, showAccountList: false, fiLogin: {}, loginUserCred: {}, errormsg:'', passwordResetFiid: undefined }, () => {
            animateFinancialInstitutionList(this.state.showFiAsList);
        });
    }

    toogleFiGridListView(value){
        this.setState({showFiAsList: !value}, () => {
            _logInformation(`RCMNinthWaves:: ${this.state.showFiAsList ? 'Toggle to List' : 'Toggle to gridview' }`);
            animateFinancialInstitutionList(this.state.showFiAsList);
        });
    }

    fetchCustomerAccountDetails(accountReq, fi, version){
        //Call api to fetch user accounts 
        let fiid = (fi && fi.fiDirId) ? fi.fiDirId : '';
        _logInformation(`RCMNinthWaves:: Login to fiid ${fi.fiDirId}`);
        _logInformation(`RCMNinthWaves:: Login Request Masked ${JSON.stringify(getMaskedLoginRequest(accountReq))}`)
        this.setState({errormsg: '', showOverlay: true, loginUserCred: accountReq}, () => {
            fetchCustomerAccounts(accountReq, fiid, this.state.nwToken, this.state.appId, this.props.token, version)
            .then(res => {

                if(res && res.data && res.data.code !== undefined && res.data.code !== null && res.data.code !== '0'){
                    //if code is 303 which means MFA has been challenged then move to MFA page
                    if(res.data.code === '303'){
                        _logInformation(`RCMNinthWaves:: MFA Required for fiid ${fiid}`);
                        _logInformation(`RCMNinthWaves:: MFA Challanges:: ${res.data.mfaChallengeQuestions ? JSON.stringify(res.data.mfaChallengeQuestions) : 'No mfa challanges found in api response'}`)
                        this.setState({showMfaScreen: true, showOverlay: false, loginUserMFAChallenged: res.data});
                    }else{
                        res.data.message ? 
                        this.setState({errormsg: res.data.message, showOverlay: false}) : 
                        this.setState({errormsg: 'We are unable to process your request, please verify your credentials.'});
                    }
                }else{
                    const accountKeys = getUserAggrAcctsList(res.data).map(acct => acct.key || '')
                    this.setState({
                        showFiList: false, 
                        showFiLogin: false, 
                        showAccountList: true, 
                        fiAccounts: res.data, 
                        showOverlay: false,
                        selectedAcctKeys: accountKeys || []
                    },  () => {

                        _logInformation(`RCMNinthWaves:: Accounts fetched for fiid ${fi.fiDirId}`);
                        _logInformation(`RCMNinthWaves:: Account List ${JSON.stringify(res.data)}`);
                        provideCustomerConsent(res.data, this.state.nwToken, this.state.appId, this.props.token).then(() => {
                            _logInformation(`RCMNinthWaves:: Consent Provided for fiid ${fiid}`);
                        })
                        .catch(err => {
                            console.error('Consent Failed:', err);
                        });
                    });
                }
            })
            .catch(err => {
                _logException(`RCMNinthWaves:: Fetching customer account failed for fiid ${fiid}`)
                if(err && err.response && err.response.data && err.response.data.causedBy ){
                    this.setState({errormsg: err.response.data.causedBy, showOverlay: false});
                }else{
                    this.setState({errormsg: 'We are unable to process your request, please verify your credentials.', showOverlay: false});
                }
            })
        });
        
    }

    resetPassword(accountReq, fi) {
        let fiid = (fi && fi.fiDirId) ? fi.fiDirId : '';
        _logInformation(`RCMNinthWaves:: Login to fiid ${fi.fiDirId}`);
        _logInformation(`RCMNinthWaves:: Login Request Masked ${JSON.stringify(getMaskedLoginRequest(accountReq))}`)
        this.setState({errormsg: '', showOverlay: true, loginUserCred: accountReq}, () => {
            resetPasswordService(accountReq, fiid, this.state.nwToken, this.state.appId, this.props.token)
            .then(res => {
                if(res && res.data && res.data.code !== undefined && res.data.code !== null && res.data.code !== '0'){
                    //if code is 303 which means MFA has been challenged then move to MFA page
                    if(res.data.code === '303'){
                        _logInformation(`RCMNinthWaves:: MFA Required for fiid ${fiid}`);
                        _logInformation(`RCMNinthWaves:: MFA Challanges:: ${res.data.mfaChallengeQuestions ? JSON.stringify(res.data.mfaChallengeQuestions) : 'No mfa challanges found in api response'}`)
                        this.setState({showMfaScreen: true, showOverlay: false, loginUserMFAChallenged: res.data});
                    }else{
                        res.data.message ? 
                        this.setState({errormsg: res.data.message, showOverlay: false}) : 
                        this.setState({errormsg: 'We are unable to process your request, please verify your credentials.'});
                        _logException(`RCMNinthWaves:: Reset Password fiid ${fiid} error: ${res.data.message}`);
                    }
                }else{

                    //Update reset password status in RCM db
                    _logInformation(`RCMNinthWaves:: Update password status called`)
                    const data = {
                        extassetaggregationid: "11234567",
                        accountkey: this.props.selectedAcctkey,
                        isupdatecredential: true,
                        newcredentialneeded: "N"
                    }
                    updatePasswordStatusService(data, this.props.token)
                    .then(() => {
                        this.setState({showFiList: false, showFiLogin: false, showAccountList: false, 
                            showPasswordResetSuccess: true, fiAccounts: null, showOverlay: false},  () => {
                            _logInformation(`RCMNinthWaves:: Password Reset for fiid ${fi.fiDirId} successfully`);                        
                        });
                        document.dispatchEvent(new CustomEvent('refreshAggregatedAcctList', { bubbles: true, detail: false }));
                    })
                    .catch(() => {
                        _logException(`RCMNinthWaves:: Resetting password failed for fiid ${fiid} because we are not able to update password status`)
                        this.setState({errormsg: 'We are unable to process your request. ', showOverlay: false});
                    })                    
                }
            })
            .catch(err => {
                _logException(`RCMNinthWaves:: Resetting password failed for fiid ${fiid}`)
                if(err && err.response && err.response.data && err.response.data.causedBy ){
                    this.setState({errormsg: err.response.data.causedBy, showOverlay: false});
                }else{
                    this.setState({errormsg: 'We are unable to process your request, please verify your credentials.', showOverlay: false});
                }
            })
        })
    }


    loginUser(signInParams, fi){
       let accountReq = { 
            accountKey: this.props.selectedAcctkey,
            signOnInfoParams: signInParams, 
            customer: (this.props.selectedAcctkey && this.state.passwordResetFiid) ? undefined : {
                name: {
                    firstName: this.state.userDetails.firstName,
                    lastName: this.state.userDetails.lastName
                },
                email: this.state.userDetails.emailId
            }
        }
        this.state.passwordResetFiid ? this.resetPassword(accountReq, fi) :
        this.fetchCustomerAccountDetails(accountReq, fi, '')
    }

    handleFinicityCompleted() {
        let accountReq = {
            sessionKey: this.state.commercialWidget.sessionKey,
            customer:{
                customerId:this.state.commercialWidget.customerKey
            }
        }
        this.setState({showCommercialWidget: false }, () => {
            this.fetchCustomerAccountDetails(accountReq, this.state.fiLogin, 'v2')
        })
        
    }

    handleExit(){
        this.setState({
            showFiList: true, 
            showFiLogin: false, 
            showAccountList: false,
            showMfaScreen: false,
            showPasswordResetSuccess: false, 
            showCommercialWidget: false,
            fiLogin: {}, 
            fiAccounts: {}, 
            loginUserCred: {}, 
            selectedAcctKeys: [],
            loginUserMFAChallenged: {}, 
            errormsg: '', 
            searchKey: '',
            passwordResetFiid: '', 
            isCommercialWidgetFlow: false,
            commercialWidget: {
                url: undefined,
                error: undefined,
                customerKey: undefined,
                sessionKey: undefined
            },
            filteredFiList: this.state.fiList }, () => {
                this.state.filteredFiList && this.state.filteredFiList.length > 0 ?
                 animateFinancialInstitutionList(this.state.showFiAsList)
                 : this.loadFiData()
            }); 
    }

    handleAccountSelection(event){

        let keyValue = event.target.value;
        let acctKeys = cloneDeep(this.state.selectedAcctKeys);
        //if selectedAcctKeys Does not contain then add if checked is true else do nothing
        if(event.target.checked && Array.isArray(acctKeys) && !acctKeys.includes(keyValue)){
            acctKeys.push(keyValue);
        }else{
            //if checked is false then remove from selected account list
            for( let i = 0; i < acctKeys.length; i++){ 
                if ( acctKeys[i] === keyValue) {
                    acctKeys.splice(i, 1); 
                    i--;
                }
             }
        }
        this.setState({selectedAcctKeys: acctKeys});
    }

    enrollUser(){
        let selectedAcct = getSelectedAccountDetails(this.state.fiAccounts, this.state.selectedAcctKeys);
        let enrollmentRequest = getEnrollmentRequestDetails(this.state.loginUserCred, selectedAcct, this.state.fiLogin, this.state.fiAccounts, this.state.userDetails, this.state.isCommercialWidgetFlow);
        
        this.setState({errormsg: '', showOverlay: true}, () => {
            _logInformation(`RCMNinthWaves:: Enrollment Submitted for fiid ${this.state.fiLogin && this.state.fiLogin.fiDirId ? this.state.fiLogin.fiDirId : ''}`);
            _logInformation(`RCMNinthWaves:: Enrollment Request Masked Login ${JSON.stringify(getMaskedLoginRequest(enrollmentRequest))}`)
            document.dispatchEvent(new CustomEvent('showNewAcctMessage', { bubbles: true, detail: false }));
            enrollCustomerAccounts(enrollmentRequest, this.state.nwToken, this.state.appId, this.props.token)
            .then(res => {
                _logInformation(`RCMNinthWaves:: Enrollment Successful`);
                if(res && res.data && res.data.code === '0'){
                    //call rockefeller api here...
                    //Call api to insert into the database
                    let data = {
                        details: {
                            accounts:{
                                fiDirId: this.state.fiLogin && this.state.fiLogin.fiDirId ? this.state.fiLogin.fiDirId : '',
                                fiName: this.state.fiLogin && this.state.fiLogin.name ? this.state.fiLogin.name : '',
                                ...enrollmentRequest.accounts
                            }
                        }
                    };
                    updateExternalAccountsList(data, getNestedObject(this.props.profileResp, ["_id"]), this.props.token, this.props.contextjson, this.props.profilejson)
                        .then(() => {
                            //Process after adding the external account
                            this.refreshAccountSelector(data, 'add');
                            this.handleExit();
                            document.dispatchEvent(new CustomEvent('showNewAcctMessage', { bubbles: true, detail: true }));
                        })
                        .catch(() => {
                            //Show error message and close the ninth waves 
                            this.setState({showOverlay: false, errormsg: 'We are unable to process your request, please try after sometime.'});
                        })

                }else{
                    res && res.data && res.data.message ? 
                    this.setState({errormsg: res.data.message, showOverlay: false}) : 
                    this.setState({errormsg: 'We are unable to process your request, please try after sometime.', showOverlay: false});
                    _logInformation('RCMNinthWaves:: Enrollment Failed');
                }
            })
            .catch(err => {
                if(err && err.response && err.response.data && err.response.data.causedBy ){
                    this.setState({errormsg: err.response.data.causedBy, showOverlay: false});
                }else{
                    this.setState({errormsg: 'We are unable to process your request, please try after sometime.', showOverlay: false});
                }
            })
        });
    }


    refreshAccountSelector(data, modifiedType){
        //Call profile api to get the group external accounts 
        getUserProfile(this.props.token).then(profileRes => {
            const profileData = (profileRes.data && Array.isArray(profileRes.data)
                && profileRes.data[0] && profileRes.data[0].profile) ? profileRes.data[0].profile : null;
            let groupedaccounts = [];
            if (profileData !== null && profileData.groupedexternalaccounts && profileData.groupedaccounts
                && this.props.accountList) {
                let externalAccountsList = profileData.groupedexternalaccounts;
                if (profileData.groupedaccounts && profileData.groupedaccounts!==null) {
                    groupedaccounts = profileData.groupedaccounts;
                }
                if (externalAccountsList !== null && externalAccountsList !== undefined && Array.isArray(externalAccountsList)) {
                    
                    let acctList = this.props.accountList;
                    acctList.externalAccounts = profileData.groupedexternalaccounts;
                    acctList.accounts = profileData.accounts;
                    this.props.updateAggregatedAccounts(
                        {
                            acctList: acctList,
                            profileResp: profileData,
                            accounts: getUpdatedAccountList(profileData),
                            groupedaccounts: groupedaccounts,
                            aggregatedAcctModifiedList: this.getModifiedAggregatedAcctList(modifiedType, data),
                            profilejson: profileRes.headers.profilejson
                        });
                }
            }
            this.setState({showOverlay: false});
        }).catch(err => {
            //log the error message here...
            console.error(err);
            this.setState({showOverlay: false});
        })
    }

    getModifiedAggregatedAcctList(modifiedType, data){
        if(modifiedType === "add"){
            return {
                isExternalAccountAdded: (this.props.aggregatedAcctModifiedList) ? !(this.props.aggregatedAcctModifiedList.isExternalAccountAdded) : false,
                modifiedType: modifiedType,
                modifiedAccounts: getNwAggregatedAccounts(data)
            }
        }
    }

    loginUserWithMfaChallengeAnswers(accountReq, fi) {
        let fiid = (fi && fi.fiDirId) ? fi.fiDirId : '';
        this.setState({errormsg: '', showOverlay: true}, () => {
            _logInformation(`RCMNinthWaves:: MFA Challanged Responded for fiid ${fiid}`);
            _logInformation(`RCMNinthWaves:: MFA Challanged Masked Request :: ${JSON.stringify(getMaskedLoginRequest(accountReq))}`)
            fetchCustomerAccounts(accountReq, fiid, this.state.nwToken, this.state.appId, this.props.token, '')
            .then(res => {
                if(res && res.data && res.data.code !== undefined && res.data.code !== null && res.data.code !== '0'){
                        //if code is 303 which means MFA has been challenged then move to MFA page
                    if(res.data.code === '303'){
                        _logInformation(`RCMNinthWaves:: MFA Required`);
                        this.setState({showMfaScreen: true, showOverlay: false, loginUserMFAChallenged: res.data});
                    }else{
                        res.data.message ? 
                        this.setState({errormsg: res.data.message, showOverlay: false}) : 
                        this.setState({errormsg: 'We are unable to process your request, please verify your credentials.'});
                    }
                }else{
                    const accountKeys = getUserAggrAcctsList(res.data).map(acct => acct.key || '')
                    this.setState({
                        showFiList: false, 
                        showFiLogin: false, 
                        showAccountList: true, 
                        fiAccounts: res.data, 
                        showOverlay: false,
                        selectedAcctKeys: accountKeys || []  //Default select accounts for enrollment
                    },  () => {
                        _logInformation(`RCMNinthWaves:: Client Accounts Fetched for fiid ${fiid}`);
                        provideCustomerConsent(res.data, this.state.nwToken, this.state.appId, this.props.token).then(() => {
                            _logInformation(`RCMNinthWaves:: Consent Provided`);
                            console.log('Consent Provided Successfully');
                        })
                        .catch(err => {
                            console.error('Consent Failed:', err);
                        });
                    });
                }
            })
            .catch(err => {
                if(err && err.response && err.response.data && err.response.data.causedBy ){
                    this.setState({errormsg: err.response.data.causedBy, showOverlay: false});
                }else{
                    this.setState({errormsg: 'We are unable to process your request, please verify your answers.', showOverlay: false});
                }
            })
        });

    }

    loginUserMfaChallenge(mfaChallenges, mfaChallengesAnswers, fi){

        let accountReq = {
            customerKey: mfaChallenges.customerKey,
            mfaChallengeAnswers: [...mfaChallengesAnswers]
        }

        this.state.passwordResetFiid ? 
            this.resetPassword(accountReq, fi) : 
            this.loginUserWithMfaChallengeAnswers(accountReq, fi)

    }


    renderPages(){
        if(this.state.showFiList){
            return (
                <div key="nwfilist">
                    <NinthWaveFiList 
                        fiList={this.state.filteredFiList} 
                        handleFiSelection={(fi) => this.handleFiSelection(fi)} 
                        handleSearchFinancialInstitution={(evt) => this.handleSearchFinancialInstitution(evt)}
                        resetSearchList={() => this.resetSearchList()}
                        toogleFiGridListView={(showListVal) => this.toogleFiGridListView(showListVal)}
                        searchKey={this.state.searchKey}
                        errormsg={this.state.errormsg}
                        showOverlay={this.state.showOverlay}
                        showFiAsList={this.state.showFiAsList}
                        showLogo={this.props.env && this.props.env.ninthwaveShowLogo && this.props.env.ninthwaveShowLogo === 'true' ? true : false}
                        />
                </div>
            )
        }

        if(this.state.showFiLogin){
            return (
                <div key="nwlogin">
                    <NinthWaveLogin 
                        fiLogin={this.state.fiLogin} 
                        loginUserCred={this.state.loginUserCred}
                        showMfaScreen={this.state.showMfaScreen}
                        mfaChallenges={this.state.loginUserMFAChallenged}
                        showFiList={() => this.showFiList()}
                        loginUser={(signinInfo, fi) => this.loginUser(signinInfo, fi)}
                        handleExit = {() => this.handleExit()}
                        loginUserMfaChallenge = {(mfaChallenges, mfaChallengesAnswers, fi) => this.loginUserMfaChallenge(mfaChallenges, mfaChallengesAnswers, fi)}
                        errormsg={this.state.errormsg}
                        passwordResetFiid={this.state.passwordResetFiid}
                    />
                </div>
            )
        }

        if(this.state.showAccountList){
            return (
                <div key="nwacctlist">
                    <NinthWaveAcctList
                       errormsg={this.state.errormsg}
                       fiLogin={this.state.fiLogin}
                       fiAccounts = {this.state.fiAccounts}
                       handleExit = {() => this.handleExit()}
                       handleAccountSelection = {(e) => this.handleAccountSelection(e)}
                       enrollUser = {() => this.enrollUser()} 
                       selectedAcctKeys={this.state.selectedAcctKeys}
                    />
                </div>
            )
        }

        if(this.state.showPasswordResetSuccess) {
            return (
                <div key="nwPasswordResetSuccess"> 
                    <ResetPasswordSuccess handleExit = {() => this.handleExit()}  fiLogin={this.state.fiLogin}/>
                </div>
            )
        }

        if(this.state.showCommercialWidget) {
            return (
                <NinthWaveCommercialWidget url={this.state.commercialWidget.url} 
                handleExit={() => this.handleExit()} 
                handleFinicityCompleted={() => this.handleFinicityCompleted()}/>
            )
        }
    }


    render() {

        let renderTemplate = this.renderPages();

        return (
            <div>
                {
                    this.state.showOverlay === true && (
                        <OverlayProcessing />
                    ) 
                }

                {
                    renderTemplate
                }
            </div>
            
        )
    }
}



const mapStateToProps = state => {
    return {
        render: state.acct.render,
        aaduseroid: state.acct.aaduseroid,
        token: state.acct.token,
        profileResp: state.acct.profileResp,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        userEmailId: state.acct.userName,
        aggregatedAcctModifiedList: state.acct.aggregatedAcctModifiedList,
        accountList: state.acct.accountList,
        env: state.env
    };
};

const mapDispatchToProps = {
    updateAggregatedAccounts
};
const NinthWaveContainerComp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Asset Aggregation' })
)

export default NinthWaveContainerComp(NinthWaveContainer);