import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Get } from "../../../common/servicecalls";
import axios from "axios";
import ConfirmDeleteAuthenticationMethod from "./ConfirmDeleteAuthenticationMethod";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import AddAuthenticationMethod from "./AddAuthenticationMethod";
import {MaskPhoneNumber} from './common/constants'

const AuthenticationMethodsList = () => {
  const [authmethodsListState, setAuthMethodsList] = useState({
    inProgress: false,
    list: [],
    error: undefined,
  });
  const [isDeleteAuthMethodPopupOpen, setDeleteAuthMethodPopUpOpen] =
    useState(false);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState();
  const [isAddAuthMethodModalOpen, setAddAuthMethodModalOpen] = useState(false);
 
  const { render, token } = useSelector((store) => store.acct) || {};
  const { baseurl } = useSelector((store) => store.env) || {};

  const showAddPhone = useMemo(() => {
    var allowAddPhone = false;
    if (
      authmethodsListState.list &&
      Array.isArray(authmethodsListState.list)
    ) {
      const mobileMethods = authmethodsListState.list.filter((method) => {
        return method.phoneType === "mobile";
      });
      allowAddPhone = mobileMethods && mobileMethods.length > 0 ? false : true;
    }
    return allowAddPhone;
  }, [authmethodsListState.list]);

  const fetchAuthenticationMethodsList = useCallback(() => {
    setAuthMethodsList((prevState) => ({
      ...prevState,
      inProgress: true,
      error: undefined,
    }));
    if (token) {
      const apiUrl = `${baseurl}multifactorauthapi/api/Authentication/methods`;
      const cancellationToken = axios.CancelToken.source();
      Get(apiUrl, token, undefined, undefined, cancellationToken)
        .then((graphResponse) => {
          //for now just take authenticator app and phone type
          const allowedTypes = [
            "#microsoft.graph.phoneAuthenticationMethod",
            "#microsoft.graph.microsoftAuthenticatorAuthenticationMethod",
          ];
          if (
            graphResponse &&
            graphResponse.data &&
            Array.isArray(graphResponse.data.value)
          ) {
            const res = graphResponse.data.value
              .filter((x) => {
                return allowedTypes.includes(x["@odata.type"]);
              })
              .map((y) => ({
                ...y,
                canDelete: true,
                canEdit:
                  y["@odata.type"] ===
                  "#microsoft.graph.phoneAuthenticationMethod"
                    ? false
                    : false,
              }));

            setAuthMethodsList((prevState) => ({
              ...prevState,
              inProgress: false,
              list: res || [],
            }));
          }
        })
        .catch((err) => {
          setAuthMethodsList((prevState) => ({
            ...prevState,
            inProgress: false,
            list: undefined,
            error: err,
          }));
        });
    }
  }, []);

  const handleDeleteClick = useCallback((authMethod) => {
    setSelectedAuthMethod(authMethod);
    setDeleteAuthMethodPopUpOpen(true);
  }, []);

  const toggleConfirmDeleteModalPopup = useCallback((reload) => {
    setDeleteAuthMethodPopUpOpen((prevValue) => !prevValue);
    if(reload) {
      fetchAuthenticationMethodsList();
    }
  }, []);

  
  const handleEditClick = (id) => {
    console.log(id);
  };

  const openAuthenticationMethodModal = useCallback(() => {
    setAddAuthMethodModalOpen((prevValue) => !prevValue);
    //window.open("https://aka.ms/MFASetup", "MfaWindow");
  }, []);


  const handleCloseAddAuthenticationPopup = useCallback((reload) => {
    setAddAuthMethodModalOpen(false);
    if (reload) {
      fetchAuthenticationMethodsList();
    }
  }, []);

  useEffect(() => {
    //aquire token for graph api call.
    fetchAuthenticationMethodsList();
  }, [render]);

  if (authmethodsListState && authmethodsListState.inProgress) {
    return (
      <div
        className="RCM_spinner"
        align="center"
        style={{ marginTop: "0.5rem" }}
      >
        Loading Multi-factor authentication methods
        <div>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="primary" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-9">
          <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading">
            CURRENT MULTIFACTOR AUTHENTICATION REGISTRATION SETTINGS
          </label>
        </div>
      </div>

      {authmethodsListState.error && (
        <div className="RCM_Teamwork_error">
          We are unable to fetch opted multifactor authentication list, Please
          try again after sometime.
        </div>
      )}
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "flex-end",
            marginLeft: "auto",
            paddingBottom: "0.5rem",
          }}
        >
          {showAddPhone && (
            <div
              className="RCM_Teamwork_link RCM_Teamwork_DriveItem_Center"
              onClick={openAuthenticationMethodModal}
            >
              {`Add Authentication Method`}
            </div>
          )}
        </div>
        <div className="row RCM_Teamwork_DriveItem">
          <div className="RCM_Teamwork_DriveItem_Center col col-sm-2 RCM_Teamwork_Item_wordbreak">
            METHOD
          </div>
          <div className="RCM_Teamwork_DriveItem_Center col col-sm-2 RCM_Teamwork_Item_wordbreak">
            TYPE
          </div>
          <div className="RCM_Teamwork_DriveItem_Center col col-sm-2 RCM_Teamwork_Item_wordbreak">
            DETAILS
          </div>
        </div>
        {authmethodsListState &&
          authmethodsListState.list &&
          authmethodsListState.list.map((authMethods) => {
            return (
              <div key={authMethods.id} className="row RCM_Teamwork_DriveItem">
                <div className="RCM_Teamwork_DriveItem_Center col col-sm-2 RCM_Teamwork_Item_wordbreak">
                  {authMethods["@odata.type"] ===
                  "#microsoft.graph.phoneAuthenticationMethod"
                    ? "Phone"
                    : "Authenticator App"}
                </div>
                <div className="RCM_Teamwork_DriveItem_Center col col-sm-2 RCM_Teamwork_Item_wordbreak">
                  {authMethods["@odata.type"] ===
                  "#microsoft.graph.phoneAuthenticationMethod"
                    ? authMethods.phoneType
                    : authMethods.clientAppName}
                </div>
                <div className="RCM_Teamwork_DriveItem_Center col col-sm-2 RCM_Teamwork_Item_wordbreak">
                  {authMethods["@odata.type"] ===
                  "#microsoft.graph.phoneAuthenticationMethod"
                    ? MaskPhoneNumber(authMethods.phoneNumber)
                    : authMethods.displayName}
                </div>
                <div
                  className="col col-sm-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.75rem",
                  }}
                >
                  {authMethods.canEdit && (
                    <div
                      className="RCM_edit_icon RCM_icon_hand"
                      title="Edit"
                      onClick={() => handleEditClick(authMethods)}
                    ></div>
                  )}
                  {authMethods.canDelete && (
                    <div
                      className="RCM_manage_grp_delete_icon RCM_icon_hand"
                      title="Delete"
                      onClick={() => handleDeleteClick(authMethods)}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {((authmethodsListState &&
        authmethodsListState.list &&
        authmethodsListState.list.length === 0) ||
        !(authmethodsListState && authmethodsListState.list)) && (
        <div className="RCM_Teamwork_NoItem">
          No authentication method exist
        </div>
      )}
      {isDeleteAuthMethodPopupOpen && (
        <ConfirmDeleteAuthenticationMethod
          isOpen={isDeleteAuthMethodPopupOpen}
          selectedAuthMethod={selectedAuthMethod}
          toggleConfirmDeleteModalPopup={toggleConfirmDeleteModalPopup}
        />
      )}
      {isAddAuthMethodModalOpen && (
        <AddAuthenticationMethod
          isOpen={isAddAuthMethodModalOpen}
          handleCloseAddAuthenticationPopup={handleCloseAddAuthenticationPopup}
        />
      )}
    </div>
  );
};

export default AuthenticationMethodsList;
