import React, { useEffect, useMemo, useRef } from "react";
import * as Highcharts from "highcharts";
import { formatMoneyToKMB } from "./ChartUtility";
import { formatMoney } from "../constants";

const HistoricalAssetAllocationAreaHighChart = ({ data, colors }) => {
  const chartContainer = useRef();
  const seriesData = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((x) => ({
        y: x.TOTAL,
        x: x.date,
        asOfDate: x.asOfDate,
      }));
    } else {
      return [];
    }
  }, [data]);


  useEffect(() => {
    Highcharts.chart(chartContainer.current, {
      chart: {
        type: "area",
        backgroundColor: "transparent",
        height: '300px'
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%b %Y}",
        },
        title: {
          text: "",
        },
      },
      yAxis: {
        labels: {
          formatter: function () {
            return formatMoneyToKMB(this.value);
          },
        },
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: "",
        formatter: function () {
          return `<b>${formatMoney(
            this.point.y
          )}</b><br/> As of: <b>${Highcharts.dateFormat(
            "%b/%e/%Y",
            this.point.asOfDate
          )}</b>`;
        },
        backgroundColor: '#000',
        style: {
          color: '#fff',
          fontFamily: 'Avenir'
        },
        outside: false
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 3,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
          label: {},
        },
      },
      series: [
        {
          type: "area",
          data: seriesData,
          color: colors[0],
        },
      ],
    });
  }, [colors, seriesData]);

  return <div ref={chartContainer}></div>;
};

export default React.memo(HistoricalAssetAllocationAreaHighChart);
