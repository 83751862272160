import { genericErrorMsg } from "../../../MoneyMovement/common/constants.js";
import { getAALoginTokenUrl, updateAssetAggrAcct, getAssetAggrAcctList, deleteAggrAcctUrl, updatePasswordStatusUrl } from "../../../../common/constants";
import { Get, Post } from "../../../../common/servicecalls.jsx";
import axios from "axios";
import { getNestedObject } from "../../../MoneyMovement/common/utilty";
import { getAggregatedAccountInputJson, deleteExternalAccountJson } from "./models/assetAggregationInfo.js";


export var getAALoginCancellationToken = null;
export var updateExternalAccountsListCancellationToken = null;
export var getExternalAccountsListCancellationToken = null;
export var deleteExternalAccountCancellationToken = null;
export var updatePasswordStatusCancellationToken = null;

export const getAALoginToken = (aauid, token, contextJson, profileJson) => {


    if (getAALoginCancellationToken !== null && getAALoginCancellationToken !== undefined) {
        getAALoginCancellationToken.cancel("Cancel existing validate Assest aggregation login request to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAALoginCancellationToken = cancelToken.source();

    let getAALoginTokenPromise = new Promise(
        function (resolve, reject) {
            
            let url = `${getAALoginTokenUrl}?AAUid=${aauid}`;
            Get(url, token, contextJson, profileJson).then(res => {

                if(getNestedObject(res, ['data', 'value', 0, 'status', 'Code']) === '0')
                    resolve(getNestedObject(res, ['data', 'value', 0]));
                else
                    reject(genericErrorMsg);
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log(err);
                } else {
                    console.log("Error Occured Asset aggregation getlogintoken ", err);
                    reject(genericErrorMsg);
                }
            })

        });

    return getAALoginTokenPromise;

}

export const updateExternalAccountsList = (aggregatedAcctResponse, profileId, token, contextJson, profileJson) =>
{
    if (updateExternalAccountsListCancellationToken !== null && updateExternalAccountsListCancellationToken !== undefined) {
        updateExternalAccountsListCancellationToken.cancel("Cancel existing update aggregated account list call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    updateExternalAccountsListCancellationToken = cancelToken.source();

    let updateExternalAccountsListPromise = new Promise(function (resolve, reject){
        let url =  updateAssetAggrAcct;
        let accountsListsJson = getAggregatedAccountInputJson(aggregatedAcctResponse, profileId);
        Post(url, accountsListsJson, token, contextJson, profileJson).then(data => {
            resolve(data);
        })
        .catch(err =>{
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured Asset aggregation update accounts list", err);
                reject(genericErrorMsg);
            }
        })
    });


    return updateExternalAccountsListPromise;

}


export const getExternalAccountsList = (token, contextJson, profileJson) =>
{
    if (getExternalAccountsListCancellationToken !== null && getExternalAccountsListCancellationToken !== undefined) {
        getExternalAccountsListCancellationToken.cancel("Cancel existing get aggregated account request to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getExternalAccountsListCancellationToken = cancelToken.source();

    let getExternalAccountsListPromise = new Promise(function (resolve, reject){
        let url =  getAssetAggrAcctList;
        Get(url, token, contextJson, profileJson).then(data => {
            resolve(data);
        })
        .catch(err =>{
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured Asset aggregation update accounts list", err);
                reject(genericErrorMsg);
            }
        })
    });


    return getExternalAccountsListPromise;

}

export const deleteExternalAccount = (deleteAccount, token, contextJson, profileJson) => {

    if (deleteExternalAccountCancellationToken !== null && deleteExternalAccountCancellationToken !== undefined) {
        deleteExternalAccountCancellationToken.cancel("Cancel existing get aggregated account request to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    deleteExternalAccountCancellationToken = cancelToken.source();

    let deleteExternalAccountPromise = new Promise(function (resolve, reject){
        let url =  deleteAggrAcctUrl;
        let data = deleteExternalAccountJson(deleteAccount);
        Post(url, data, token, contextJson, profileJson, deleteExternalAccountCancellationToken).then(data => {
            resolve(data);
        })
        .catch(err =>{
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured Asset aggregation during delete external account", err);
                reject(genericErrorMsg);
            }
        })
    });


    return deleteExternalAccountPromise;


}


export const updatePasswordStatusService = (data, token) =>  {

    if (updatePasswordStatusCancellationToken !== null && updatePasswordStatusCancellationToken !== undefined) {
        updatePasswordStatusCancellationToken.cancel("Cancel existing update password status call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    updatePasswordStatusCancellationToken = cancelToken.source();

    let updatePasswordStatusPromise = new Promise(function (resolve, reject){
        Post(updatePasswordStatusUrl, data, token, updatePasswordStatusCancellationToken).then(
            enrollRes => {
                resolve(enrollRes);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                reject(err);
            }
        })
    });

    return updatePasswordStatusPromise
}