import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ConfirmationModal(props) {

    return (
        <div>
            <Modal isOpen={props.confirmationModalState} toggle={props.confirmationModalToggle}
                     className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                     centered >
                <ModalHeader toggle={props.confirmationModalToggle}>{props.selectedSection.headerData}</ModalHeader>
                        <ModalBody>
                            <div>
                        {props.selectedSection.contentData}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                    <Button color="btn btn-secondary RCM_button_secondary RCM_select_btn" onClick={props.confirmationModalToggle}>Close</Button>
                            <Button color="btn btn-primary RCM_button_primary RCM_select_btn" onClick={props.openSelectedSectionWindow}>Continue</Button>
                        </ModalFooter>
                    </Modal>
            </div>
    )
}
