import React from "react";

export default class inputComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    componentDidMount() {
        this.nameInput.focus();
    }

    handleChange = (event) => {
        const alphanumericFormat = /^[a-zA-Z 0-9'/-]+$/;
        if ((event.target.value === "" || alphanumericFormat.test(event.target.value))) {
            this.setState({
                value: event.target.value
            })
        }
    }
    render() {
        const { value, onUpdate, ...rest } = this.props;
        return (
            <input
                {...rest}
                ref={(input) => { this.nameInput = input; }}
                type="text"
                autofocus="true"
                placeholder=""
                className="RCM_form_control"
                value={this.state.value}
                onChange={this.handleChange}
                onBlur={() => onUpdate(this.state.value)}
                maxLength="50"
            />
        )

    }
}