export const accountresponse1 = [
    {

        "number": "RKZ000071",
        "key": "RKZ000071",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "123",
        "nickname": "",
        "role": "TTE ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000071(Trust - Under Agreement)",
                "dispshortname": "RKZ000071(TRUA)",
                "dispname": "RKZ000071"
            }
        ]

    },
    {

        "number": "RKZ000072",
        "key": "RKZ000072",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "123",
        "nickname": "",
        "role": "TTE ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000072(Trust - Under Agreement)",
                "dispshortname": "RKZ000072(TRUA)",
                "dispname": "RKZ000072"
            }
        ]

    },
    {

        "number": "RKZ000073",
        "key": "RKZ000073",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "123",
        "nickname": "",
        "role": "TTE ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000073(Trust - Under Agreement)",
                "dispshortname": "RKZ000073(TRUA)",
                "dispname": "RKZ000073"
            }
        ]

    },
    {
        "number": "RJL000001",
        "key": "RJL000001",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000001 (Individual)",
                "dispshortname": "RJL000001 (I)",
                "dispname": "RJL000001"
            }
        ]
    },
    {
        "number": "RRR-123456",
        "key": "RRR-123456",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000002 (Individual)",
                "dispshortname": "RJL000002 (I)",
                "dispname": "RJL000002"
            }
        ]
    },
    {
        "number": "RRR-234567",
        "key": "RRR-234567",
        "accountregistration": "IRA",
        "regtypecode": "IRA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000003 (IRA)",
                "dispshortname": "RJL000003 (IRA)",
                "dispname": "RJL000003"
            }
        ]
    },
    {
        "number": "RRR-345678",
        "key": "RRR-345678",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000004 (Individual)",
                "dispshortname": "RJL000004 (I)",
                "dispname": "RJL000004"
            }
        ]
    },
    {
        "number": "RJL000005",
        "key": "RJL000005",
        "accountregistration": "Joint - with Right of Survivorship",
        "regtypecode": "J",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000005 (Joint - with Right of Survivorship)",
                "dispshortname": "RJL000005 (J)",
                "dispname": "RJL000005"
            }
        ]
    },
    {
        "number": "RJL000006",
        "key": "RJL000006",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000006 (Individual)",
                "dispshortname": "RJL000006 (I)",
                "dispname": "RJL000006"
            }
        ]
    },
    {
        "number": "RJL000007",
        "key": "RJL000007",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000007 (Individual)",
                "dispshortname": "RJL000007 (I)",
                "dispname": "RJL000007"
            }
        ]
    },
    {
        "number": "RJL000008",
        "key": "RJL000008",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000008 (Individual)",
                "dispshortname": "RJL000008 (I)",
                "dispname": "RJL000008"
            }
        ]
    },
    {
        "number": "RJL000010",
        "key": "RJL000010",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000010 (Individual)",
                "dispshortname": "RJL000010 (I)",
                "dispname": "RJL000010"
            }
        ]
    },
    {
        "number": "RJL000011",
        "key": "RJL000011",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000011 (Individual)",
                "dispshortname": "RJL000011 (I)",
                "dispname": "RJL000011"
            }
        ]
    },
    {
        "number": "RJL000012",
        "key": "RJL000012",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "GRC",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000012 (Individual)",
                "dispshortname": "RJL000012 (I)",
                "dispname": "RJL000012"
            }
        ]
    },
    {
        "number": "RJL000013",
        "key": "RJL000013",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000013 (Individual)",
                "dispshortname": "RJL000013 (I)",
                "dispname": "RJL000013"
            }
        ]
    },
    {
        "number": "RJL000014",
        "key": "RJL000014",
        "accountregistration": "IRA",
        "regtypecode": "IRA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000014 (IRA)",
                "dispshortname": "RJL000014 (IRA)",
                "dispname": "RJL000014"
            }
        ]
    },
    {
        "number": "RJL000019",
        "key": "RJL000019",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000019 (Individual)",
                "dispshortname": "RJL000019 (I)",
                "dispname": "RJL000019"
            }
        ]
    },
    {
        "number": "RJL000020",
        "key": "RJL000020",
        "accountregistration": "Joint - with Right of Survivorship",
        "regtypecode": "J",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000020 (Joint - with Right of Survivorship)",
                "dispshortname": "RJL000020 (J)",
                "dispname": "RJL000020"
            }
        ]
    },
    {
        "number": "RJL000022",
        "key": "RJL000022",
        "accountregistration": "Transfer on Death - Individual",
        "regtypecode": "TODI",
        "repcode": "GRC",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000022 (Transfer on Death - Individual)",
                "dispshortname": "RJL000022 (TODI)",
                "dispname": "RJL000022"
            }
        ]
    },
    {
        "number": "RJL000023",
        "key": "RJL000023",
        "accountregistration": "Corporation",
        "regtypecode": "CP",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000023 (Corporation)",
                "dispshortname": "RJL000023 (CP)",
                "dispname": "RJL000023"
            }
        ]
    },
    {
        "number": "RJL000024",
        "key": "RJL000024",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000024 (Individual)",
                "dispshortname": "RJL000024 (I)",
                "dispname": "RJL000024"
            }
        ]
    },
    {
        "number": "RJL000025",
        "key": "RJL000025",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000025 (Trust - Under Agreement)",
                "dispshortname": "RJL000025 (TRUA)",
                "dispname": "RJL000025"
            }
        ]
    },
    {
        "number": "RJL000026",
        "key": "RJL000026",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000026 (Trust - Under Agreement)",
                "dispshortname": "RJL000026 (TRUA)",
                "dispname": "RJL000026"
            }
        ]
    },
    {
        "number": "RJL000029",
        "key": "RJL000029",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000029 (Individual)",
                "dispshortname": "RJL000029 (I)",
                "dispname": "RJL000029"
            }
        ]
    },
    {
        "number": "RJL000030",
        "key": "RJL000030",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000030 (Individual)",
                "dispshortname": "RJL000030 (I)",
                "dispname": "RJL000030"
            }
        ]
    },
    {
        "number": "RJL000031",
        "key": "RJL000031",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000031 (Individual)",
                "dispshortname": "RJL000031 (I)",
                "dispname": "RJL000031"
            }
        ]
    },
    {
        "number": "RJL000036",
        "key": "RJL000036",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "987",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000036 (Individual)",
                "dispshortname": "RJL000036 (I)",
                "dispname": "RJL000036"
            }
        ]
    },
    {
        "number": "RJL000041",
        "key": "RJL000041",
        "accountregistration": "IRA",
        "regtypecode": "IRA",
        "repcode": "987",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000041 (IRA)",
                "dispshortname": "RJL000041 (IRA)",
                "dispname": "RJL000041"
            }
        ]
    },
    {
        "number": "RJL000055",
        "key": "RJL000055",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000055 (Individual)",
                "dispshortname": "RJL000055 (I)",
                "dispname": "RJL000055"
            }
        ]
    },
    {
        "number": "RJL000056",
        "key": "RJL000056",
        "accountregistration": "IRA - Rollover",
        "regtypecode": "IRRL",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "Y",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000056 (IRA - Rollover)",
                "dispshortname": "RJL000056 (IRRL)",
                "dispname": "RJL000056"
            }
        ]
    },
    {
        "number": "RJL000060",
        "key": "RJL000060",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000060 (Trust - Under Agreement)",
                "dispshortname": "RJL000060 (TRUA)",
                "dispname": "RJL000060"
            }
        ]
    },
    {
        "number": "RJL000061",
        "key": "RJL000061",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "XYZ",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000061 (Individual)",
                "dispshortname": "RJL000061 (I)",
                "dispname": "RJL000061"
            }
        ]
    },
    {
        "number": "RJL000064",
        "key": "RJL000064",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000064 (Individual)",
                "dispshortname": "RJL000064 (I)",
                "dispname": "RJL000064"
            }
        ]
    },
    {
        "number": "RJL000065",
        "key": "RJL000065",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000065 (Individual)",
                "dispshortname": "RJL000065 (I)",
                "dispname": "RJL000065"
            }
        ]
    },
    {
        "number": "RJL000066",
        "key": "RJL000066",
        "accountregistration": "Transfer on Death - Individual",
        "regtypecode": "TODI",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000066 (Transfer on Death - Individual)",
                "dispshortname": "RJL000066 (TODI)",
                "dispname": "RJL000066"
            }
        ]
    },
    {
        "number": "RJL000067",
        "key": "RJL000067",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000067 (Individual)",
                "dispshortname": "RJL000067 (I)",
                "dispname": "RJL000067"
            }
        ]
    },
    {
        "number": "RJL000068",
        "key": "RJL000068",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000068 (Individual)",
                "dispshortname": "RJL000068 (I)",
                "dispname": "RJL000068"
            }
        ]
    },
    {
        "number": "RJL000069",
        "key": "RJL000069",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000069 (Individual)",
                "dispshortname": "RJL000069 (I)",
                "dispname": "RJL000069"
            }
        ]
    },
    {
        "number": "RJL000070",
        "key": "RJL000070",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000070 (Individual)",
                "dispshortname": "RJL000070 (I)",
                "dispname": "RJL000070"
            }
        ]
    },
    {
        "number": "RJL000071",
        "key": "RJL000071",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000071 (Individual)",
                "dispshortname": "RJL000071 (I)",
                "dispname": "RJL000071"
            }
        ]
    },
    {
        "number": "RJL000072",
        "key": "RJL000072",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000072 (Individual)",
                "dispshortname": "RJL000072 (I)",
                "dispname": "RJL000072"
            }
        ]
    },
    {
        "number": "RJL000073",
        "key": "RJL000073",
        "accountregistration": "Joint - with Right of Survivorship",
        "regtypecode": "J",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000073 (Joint - with Right of Survivorship)",
                "dispshortname": "RJL000073 (J)",
                "dispname": "RJL000073"
            }
        ]
    },
    {
        "number": "RJL000075",
        "key": "RJL000075",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000075 (Individual)",
                "dispshortname": "RJL000075 (I)",
                "dispname": "RJL000075"
            }
        ]
    },
    {
        "number": "RJL000078",
        "key": "RJL000078",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000078 (Individual)",
                "dispshortname": "RJL000078 (I)",
                "dispname": "RJL000078"
            }
        ]
    },
    {
        "number": "RJL000083",
        "key": "RJL000083",
        "accountregistration": "Trust - Under Agreement",
        "regtypecode": "TRUA",
        "repcode": "000",
        "nickname": "",
        "role": "TTE ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000083 (Trust - Under Agreement)",
                "dispshortname": "RJL000083 (TRUA)",
                "dispname": "RJL000083"
            }
        ]
    },
    {
        "number": "RJL000087",
        "key": "RJL000087",
        "accountregistration": "Transfer on Death - Individual",
        "regtypecode": "TODI",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000087 (Transfer on Death - Individual)",
                "dispshortname": "RJL000087 (TODI)",
                "dispname": "RJL000087"
            }
        ]
    },
    {
        "number": "RJL000088",
        "key": "RJL000088",
        "accountregistration": "Custodial Uniform Transfer to Minors Act",
        "regtypecode": "UTMA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000088 (Custodial Uniform Transfer to Minors Act)",
                "dispshortname": "RJL000088 (UTMA)",
                "dispname": "RJL000088"
            }
        ]
    },
    {
        "number": "RJL000089",
        "key": "RJL000089",
        "accountregistration": "Joint - with Right of Survivorship",
        "regtypecode": "J",
        "repcode": "000",
        "nickname": "",
        "role": "JWR ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000089 (Joint - with Right of Survivorship)",
                "dispshortname": "RJL000089 (J)",
                "dispname": "RJL000089"
            }
        ]
    },
    {
        "number": "RJL000091",
        "key": "RJL000091",
        "accountregistration": "Joint - Tenants in Common",
        "regtypecode": "TIC",
        "repcode": "000",
        "nickname": "",
        "role": "JTC ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJL000091 (Joint - Tenants in Common)",
                "dispshortname": "RJL000091 (TIC)",
                "dispname": "RJL000091"
            }
        ]
    },
    {
        "number": "RJM000001",
        "key": "RJM000001",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "000",
        "nickname": "",
        "role": "INDV",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJM000001 (Individual)",
                "dispshortname": "RJM000001 (I)",
                "dispname": "RJM000001"
            }
        ]
    },
    {
        "number": "RJM000002",
        "key": "RJM000002",
        "accountregistration": "Joint - with Right of Survivorship",
        "regtypecode": "J",
        "repcode": "000",
        "nickname": "",
        "role": "JWR ",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RJM000002 (Joint - with Right of Survivorship)",
                "dispshortname": "RJM000002 (J)",
                "dispname": "RJM000002"
            }
        ]
    },
    {
        "number": "RKZ000001",
        "key": "RKZ000001",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "XYZ",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000001 (Individual)",
                "dispshortname": "RKZ000001 (I)",
                "dispname": "RKZ000001"
            }
        ]
    },
    {
        "number": "RKZ000006",
        "key": "RKZ000006",
        "accountregistration": "IRA",
        "regtypecode": "IRA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000006 (IRA)",
                "dispshortname": "RKZ000006 (IRA)",
                "dispname": "RKZ000006"
            }
        ]
    },
    {
        "number": "RKZ000008",
        "key": "RKZ000008",
        "accountregistration": "IRA - Rollover",
        "regtypecode": "IRRL",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000008 (IRA - Rollover)",
                "dispshortname": "RKZ000008 (IRRL)",
                "dispname": "RKZ000008"
            }
        ]
    },
    {
        "number": "RKZ000014",
        "key": "RKZ000014",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "XYZ",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000014 (Individual)",
                "dispshortname": "RKZ000014 (I)",
                "dispname": "RKZ000014"
            }
        ]
    },
    {
        "number": "RKZ000018",
        "key": "RKZ000018",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "XYZ",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000018 (Individual)",
                "dispshortname": "RKZ000018 (I)",
                "dispname": "RKZ000018"
            }
        ]
    },
    {
        "number": "RKZ000038",
        "key": "RKZ000038",
        "accountregistration": "IRA - Rollover",
        "regtypecode": "IRRL",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000038 (IRA - Rollover)",
                "dispshortname": "RKZ000038 (IRRL)",
                "dispname": "RKZ000038"
            }
        ]
    },
    {
        "number": "RKZ000044",
        "key": "RKZ000044",
        "accountregistration": "IRA - Rollover",
        "regtypecode": "IRRL",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000044 (IRA - Rollover)",
                "dispshortname": "RKZ000044 (IRRL)",
                "dispname": "RKZ000044"
            }
        ]
    },
    {
        "number": "RKZ000052",
        "key": "RKZ000052",
        "accountregistration": "Roth IRA",
        "regtypecode": "ROTH",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000052 (Roth IRA)",
                "dispshortname": "RKZ000052 (ROTH)",
                "dispname": "RKZ000052"
            }
        ]
    },
    {
        "number": "RKZ000075",
        "key": "RKZ000075",
        "accountregistration": "Transfer on Death - Individual",
        "regtypecode": "TODI",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000075 (Transfer on Death - Individual)",
                "dispshortname": "RKZ000075 (TODI)",
                "dispname": "RKZ000075"
            }
        ]
    },
    {
        "number": "RKZ000085",
        "key": "RKZ000085",
        "accountregistration": "Transfer on Death - Joint WRS",
        "regtypecode": "TODJ",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000085 (Transfer on Death - Joint WRS)",
                "dispshortname": "RKZ000085 (TODJ)",
                "dispname": "RKZ000085"
            }
        ]
    },
    {
        "number": "RKZ000086",
        "key": "RKZ000086",
        "accountregistration": "Transfer on Death - Joint WRS",
        "regtypecode": "TODJ",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000086 (Transfer on Death - Joint WRS)",
                "dispshortname": "RKZ000086 (TODJ)",
                "dispname": "RKZ000086"
            }
        ]
    },
    {
        "number": "RKZ000101",
        "key": "RKZ000101",
        "accountregistration": "IRA - BDA",
        "regtypecode": "IRAB",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000101 (IRA - BDA)",
                "dispshortname": "RKZ000101 (IRAB)",
                "dispname": "RKZ000101"
            }
        ]
    },
    {
        "number": "RKZ000102",
        "key": "RKZ000102",
        "accountregistration": "IRA - BDA",
        "regtypecode": "IRAB",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RKZ000102 (IRA - BDA)",
                "dispshortname": "RKZ000102 (IRAB)",
                "dispname": "RKZ000102"
            }
        ]
    }
];
export const accountresponse =
    [{
        "number": "RRR-123456",
        "key": "RRR-123456",
        "accountregistration": "Individual",
        "regtypecode": "I",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RRR-123456 (Individual)",
                "dispshortname": "RRR-123456 (I)",
                "dispname": "RRR-123456"
            }
        ]
    },
    {
        "number": "RRR-234567",
        "key": "RRR-234567",
        "accountregistration": "IRA",
        "regtypecode": "IRA",
        "repcode": "123",
        "nickname": "",
        "role": "",
        "stakeholder": "",
        "managedaccount": "N",
        "eDelivery": null,
        "displaynickname": [
            {
                "displongname": "RRR-234567 (IRA)",
                "dispshortname": "RRR-234567 (IRA)",
                "dispname": "RRR-234567"
            }
        ]
    }
    ];

export const famockresponse = { "faname": "The RCM Group", "contactnumber": ["+1-212-555-5555", ""], "email": "TheRCMGroup@rockco.com" };

export const groupedaccountmockresponse = { "contentType": null, "serializerSettings": null, "statusCode": null, "value": [{ "groupid": "93", "grouploginid": "91", "profileid": "9", "groupkey": "0de5e1ec-d687-49e5-ae99-87e486a9994f", "grouptypecd": "test", "groupname": "Long Investment", "isvisible": "1", "isdeleted": "0", "action": null, "accounts": [{ "number": "RRR-123456", "key": "RRR-123456", "status": null, "accttype": "Internal", "routingTransitNumber": null, "nickname": "RRR-123456 (Individual)", "role": null, "stakeholder": null, "managedaccount": null, "finame": null, "fiid": null, "accountsourcetypecd": "Rockefeller", "documentstatus": null, "accountid": "41", "accountinstitutionid": null, "rockaccounttype": null, "isInternal": false, "displaynickname": null, "accountregistration": null, "accountregtypecode": null, "repcode": null, "manualaccountype": null, "accountclassification": null }, { "number": "RRR-234567", "key": "RRR-234567", "status": null, "accttype": "INTERNAL", "routingTransitNumber": null, "nickname": "RRR-234567 (IRA)", "role": null, "stakeholder": null, "managedaccount": null, "finame": null, "fiid": null, "accountsourcetypecd": "Rockefeller", "documentstatus": null, "accountid": "5315", "accountinstitutionid": "1", "rockaccounttype": null, "isInternal": false, "displaynickname": null, "accountregistration": null, "accountregtypecode": null, "repcode": null, "manualaccountype": null, "accountclassification": null }] }] }

export const groupedaccountselectormockresponse = [{ "groupid": "93", "grouploginid": "91", "profileid": "9", "groupkey": "0de5e1ec-d687-49e5-ae99-87e486a9994f", "grouptypecd": "test", "groupname": "Long Investment", "isvisible": "1", "isdeleted": "0", "action": null, "accounts": [{ "number": "RRR-123456", "key": "RRR-123456", "status": null, "accttype": null, "routingTransitNumber": null, "nickname": "", "role": "", "stakeholder": "", "managedaccount": "N", "finame": null, "fiid": null, "accountsourcetypecd": "Rockefeller", "documentstatus": null, "accountid": "41", "accountinstitutionid": null, "rockaccounttype": "Internal", "isInternal": true, "displaynickname": [{ "displongname": "RRR-123456 (Individual)", "dispshortname": "RRR-123456 (I)", "dispname": "RRR-123456" }], "accountregistration": "Individual", "accountregtypecode": "I", "repcode": "123", "manualaccountype": null, "accountclassification": "ASSET" }, { "number": "RRR-234567", "key": "RRR-234567", "status": null, "accttype": null, "routingTransitNumber": null, "nickname": "", "role": "INDV", "stakeholder": "", "managedaccount": "Y", "finame": null, "fiid": null, "accountsourcetypecd": "Rockefeller", "documentstatus": null, "accountid": "5315", "accountinstitutionid": null, "rockaccounttype": "Internal", "isInternal": true, "displaynickname": [{ "displongname": "RRR-234567 (IRA)", "dispshortname": "RRR-234567 (IRA)", "dispname": "RRR-234567" }], "accountregistration": "IRA", "accountregtypecode": "IRA", "repcode": "000", "manualaccountype": null, "accountclassification": "ASSET" }] }];

export const groupedexternalacctmockresponse = [{ "groupid":"1138", "groupname":"LIABILITY GROUP", "groupby": [{ "number":"246546546464", "key":"78c8f7fa-0fa2-4f2e-b40a-7e724fbdd795", "status":"Pending", "accttype":"Mortgage", "routingTransitNumber":"", "nickname":"liab123", "role":"", "stakeholder":"", "managedaccount":"", "finame":"LIABILITY", "fiid":"1138", "displaynickname": [{ "displongname":"liab123*6464 dhjfdshf", "dispshortname":"liab123-6464", "dispname":"liab123"}], "updatedon":"", "accountsourcetypecode":"UserManualAdded", "accountclassification":"LIABILITY"}] }, { "groupid":"30", "groupname":"SBL", "groupby": [{ "number":"********6819", "key":"********6819", "status":"Pending", "accttype":"", "routingTransitNumber":"", "nickname":"SBL-6819", "role":"", "stakeholder":"", "managedaccount":"", "finame":"SBL", "fiid":"30", "displaynickname": [{ "displongname":"SBL-6819*6819 SBL", "dispshortname":"SBL-6819-6819", "dispname":"SBL-6819"}], "updatedon":"01/28/2020", "accountsourcetypecode":"SBL", "accountclassification":"LIABILITY"}, { "number":"********6900", "key":"********6900", "status":"Pending", "accttype":"", "routingTransitNumber":"", "nickname":"", "role":"", "stakeholder":"", "managedaccount":"", "finame":"SBL", "fiid":"30", "displaynickname": [{ "displongname":"SBL*6900", "dispshortname":"SBL*6900", "dispname":"SBL*6900"}], "updatedon":"", "accountsourcetypecode":"SBL", "accountclassification":"LIABILITY"}, { "number":"********9232", "key":"********9232", "status":"Pending", "accttype":"", "routingTransitNumber":"", "nickname":"", "role":"", "stakeholder":"", "managedaccount":"", "finame":"SBL", "fiid":"30", "displaynickname": [{ "displongname":"SBL*9232", "dispshortname":"SBL*9232", "dispname":"SBL*9232"}], "updatedon":"", "accountsourcetypecode":"SBL", "accountclassification":"LIABILITY"}] }];
//export default accountresponse;
