import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import { clientOnlineTutorialUrl, billPayTutorialUrl, mobileTutorialUrl } from './../../common/constants'
import { getSupportdocColumns } from './supportcolumns.jsx'
export default function Help() {

    const [pdfUrl, setPdfUrl] = useState(null);
    const [tutorialData, setTutorialData] = useState(null);
    const [selectedTutorial, setSelectedTutorial] = useState([])
   const previewDocument=(row)=> {
       if (selectedTutorial && selectedTutorial.length > 0) {
           if (selectedTutorial[0] !== row.id) {
               setSelectedTutorial([row.id]);
               setPdfUrl(row.url);
            }
        }
       else {
           setSelectedTutorial([row.id]);
           setPdfUrl(row.url);
        }
    }
    
    const selectRow = {
        mode: 'radio',
        selected: selectedTutorial,
        onSelect: previewDocument,
        clickToSelect: true
    };
    const navigateToSite = (url) => {
        if (url)
            window.open(url);
    }
   
    const getDataRows = () => {
        let data = [];
        data.push({id:1, name: 'Client Online', url: clientOnlineTutorialUrl },
            { id:2,name: 'Bill Pay', url: billPayTutorialUrl }, 
            {id:3, name: 'Mobile App', url: mobileTutorialUrl});
        return data;
    }

    const rowClassForSelected = (row) => {
        console.log(row, selectRow)
        let cssClass = '';
        if (row !== null && row !== undefined && row.id !== null
            && row.id !== undefined && selectRow 
            && selectRow.selected && selectRow.selected.length > 0
            && selectRow.selected.includes(row.id)) {
            cssClass = "RCM_CM_new_transfer";
        }

        return cssClass;
    }

    useEffect(() => {
        let data = getDataRows();
        setTutorialData(data);
        setPdfUrl(null);
    }, []);


    return (
        <div className="container-fluid">
            <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblholdingheading'>
                TUTORIALS
            </label>
            <div className='row'>
                <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4" id="RCM_stmtaccdetails">
                    <div className="RCM_Toast RCM_Document_Toast">
                        {tutorialData && tutorialData.length > 0 ?
                            <div key="lblholdingheadingrow">
                                <BootstrapTable
                                    keyField="id"
                                    data={tutorialData}
                                    columns={getSupportdocColumns(navigateToSite)}
                                    bordered={false}
                                    classes="RCM_two_level_table1"
                                    selectRow={selectRow}
                                    rowClasses={(row) => rowClassForSelected(row)}
                                >
                                </BootstrapTable>
                            </div>
                            : ''}

                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8">
                    <div className="RCM_Toast RCM_Document_Toast" id="RCM_accstmtpreview">
                        {
                            tutorialData && tutorialData.length > 0 && pdfUrl ?

                                <div className="RCM_PdfViewer_Border">
                                    <iframe
                                        type="application/pdf"
                                        src={pdfUrl}
                                        width="100%"
                                        height="700px"
                                    />
                                </div> : tutorialData && tutorialData.length > 0 ? <p>Please Select an item to preview the tutorial</p> : ''
                        }
                    </div>
                </div>
            </div>

        </div>




    );
}