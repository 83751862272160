import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountDetailsInfoService } from "../accountInfoservice";
import AccountHolders from "./accountHolders";
import Beneficiaries from "./beneficiaries";

const AccountDetailsContainer = ({ account }) => {
  const [accountInfoState, setAccountInfoState] = useState({
    isLoading: false,
    error: undefined,
    accountInfo: undefined,
  });

  const { token, contextjson, profilejson } = useSelector(
    (state) => state.acct
  ) || {};

  useEffect(() => {
    const accountkey =  account.accountkey
    if (token && accountkey && contextjson && profilejson) {
      setAccountInfoState({
        isLoading: true,
        error: undefined,
        accountInfo: undefined,
      });
      
      getAccountDetailsInfoService(accountkey, token, contextjson, profilejson)
        .then((res) => {
          setAccountInfoState({
            isLoading: false,
            error: undefined,
            accountInfo: res,
          });
        })
        .catch((err) => {
          setAccountInfoState({
            isLoading: false,
            error: err,
            accountInfo: undefined,
          });
        });
    }
  }, [account, contextjson, profilejson, token]);

  return (
    <div>
      <div className="RCM_Toast">
        <div>Account holders</div>
        <AccountHolders accountInfoState={accountInfoState} />
      </div>
      <div className="RCM_Toast">
        <div>Beneficiaries</div>
        <Beneficiaries accountInfoState={accountInfoState} />
      </div>
    </div>
  );
};

export default React.memo(AccountDetailsContainer);
