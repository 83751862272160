import React, { useState, useCallback, memo, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const NewFolderModal = ({ isOpen, toogle, onAddNewFolderClick }) => {
  const [folderName, setFolderName] = useState();
  const folderNameInputRef = useRef(null);
  const onChangeFolderName = useCallback((event) => {
    setFolderName(event.target.value);
  }, []);
  const handleOnModalOpen = useCallback(() => {
    setFolderName('');
    if (folderNameInputRef) {
      folderNameInputRef.current.focus();
    }
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toogle}
      className="RCM_CM_modal"
      wrapClassName="RCM_CM_modal_wrapper"
      modalClassName="RCM_CM_modal_class"
      contentClassName="RCM_CM_modal_content"
      zIndex="9999"
      centered
      onOpened={handleOnModalOpen}
    >
      <ModalHeader
        toggle={toogle}
        className="RCM_externalAssetModel_header RCM_manage_group_header"
      >
        ADD FOLDER
      </ModalHeader>
      <ModalBody>
        <div style={{ padding: "1rem" }}>
          <input
            type="text"
            className="RCM_form_control"
            autoComplete="off"
            maxLength={100}
            placeholder="Enter folder name"
            value={folderName}
            onChange={onChangeFolderName}
            ref={folderNameInputRef}
          />
        </div>
      </ModalBody>
      <ModalFooter className="RCM_manage_group_footer">
        <button
          type="button"
          className="btn btn-secondary RCM_button_secondary"
          onClick={toogle}
        >
          CANCEL
        </button>
        <button
          type="button"
          className="btn btn-primary RCM_button_primary"
          onClick={() => onAddNewFolderClick(folderName)}
        >
          ADD FOLDER
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(NewFolderModal);
