import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import { connect } from 'react-redux';
import AssetAccount from './manageassetforms/assetaccount.jsx';
import OtherLoan from './manageassetforms/otherloan.jsx';
import RealEstateForm from './manageassetforms/realestate.jsx';
import Mortgage from './manageassetforms/mortgage.jsx';
import CreditCard from './manageassetforms/creditcard.jsx';
import ExchangeFund from './manageassetforms/exchangefund.jsx';
import PrivateEquity from './manageassetforms/privateequity.jsx';
import HedgeFunds from './manageassetforms/hedgefunds.jsx';
import Tangibles from './manageassetforms/tangibles.jsx';
import Transportation from './manageassetforms/transportation.jsx';
import PromissoryNote from './manageassetforms/promissorynote';
import PromissoryNoteLoan from './manageassetforms/promissorynoteloan';
import { AssetTypeChangeConfrimationPopup } from './assettypechangeconfrimationpopup.jsx';
import { AssetTypeCancelConfrimationPopup } from './assetTypeCancelConfirmationpopup.jsx';
//import { getAssetTypeListService } from './manageassetsservice.jsx';

class AddEditAssetDetails extends Component {

    constructor(props) {
        super(props);

        this.typeOfAssetToggle = this.typeOfAssetToggle.bind(this);
        this.onFromAssetTypeChange = this.onFromAssetTypeChange.bind(this);
        this.getAssetFormComponent = this.getAssetFormComponent.bind(this);
        this.addModaltoggle = this.addModaltoggle.bind(this);
        this.cancel = this.cancel.bind(this);
        this.setFormStateChange = this.setFormStateChange.bind(this);
        this.toggleAssetTypeChangeConfrimationPopup = this.toggleAssetTypeChangeConfrimationPopup.bind(this);
        this.changeAssetType = this.changeAssetType.bind(this);
        this.closeAddEditAfterSave = this.closeAddEditAfterSave.bind(this);
        this.toggleAssetTypeCancelConfrimationPopup = this.toggleAssetTypeCancelConfrimationPopup.bind(this);
        this.cancelAssetType = this.cancelAssetType.bind(this);
        this.openLinkedAccount = this.openLinkedAccount.bind(this);

        this.state = {
            typeOfAssetList: [
                {
                    "assetclass": "Asset",
                    "assettypes": [
                        {
                            "assettype": "Asset Account",
                            "assetsubtypes": [
                                {
                                    "assettype": "Cash Account"
                                },
                                {
                                    "assettype": "Investment Account"
                                },
                                {
                                    "assettype": "Retirement Account"
                                },
                                {
                                    "assettype": "Education and Savings Account"
                                },
                                {
                                    "assettype": "Trust Account"
                                }
                            ]
                        },
                        {
                            "assettype": "Real Estate"
                        },
                        {
                            "assettype": "Hedge Funds"
                        },
                        {
                            "assettype": "Fund of Hedge Funds"
                        },
                        {
                            "assettype": "Private Equity"
                        },
                        {
                            "assettype": "Exchange Fund"
                        },
                        {
                            "assettype": "Tangibles",
                            "assetsubtypes": [
                                {
                                    "assettype": "Gold"
                                },
                                {
                                    "assettype": "Wine"
                                },
                                {
                                    "assettype": "Horses"
                                },
                                {
                                    "assettype": "Watches"
                                },
                                {
                                    "assettype": "Art"
                                },
                                {
                                    "assettype": "Jewelry"
                                },
                                {
                                    "assettype": "Collectibles"
                                },
                                {
                                    "assettype": "Other"
                                }
                            ]
                        },
                        {
                            "assettype": "Transportation"
                        },
                        {
                            "assettype": "Other Asset"
                        },
                        {
                            "assettype": "Promissory Note"
                        },
                    ]
                },
                {
                    "assetclass": "Liability",
                    "assettypes": [
                        {
                            "assettype": "Mortgage"
                        },
                        {
                            "assettype": "Credit Card"
                        },
                        {
                            "assettype": "Loan"
                        },
                        {
                            "assettype": "Promissory Note Loan"
                        },
                    ]
                }
            ],
            typeOfAssetOpen: false,
            typeOfAsset: "",
            formComponent: "",
            isAddModalOpen: false,
            formStateChanged: false,
            openAssetTypeChangeConfrimationPopup: false,
            openAssettypecancelconfrimationpopup: false,
            selectedAsset: "",
            assetClass: '', 
            selectedAccount: [],
            linkedAccount: ''
        };
    }
    //getAssetTypeList() {

    //    getAssetTypeListService(this.authToken, this.contextjson, this.profilejson, this.state.profileId)
    //        .then(data => {
    //            //if (manageAssetData) {
    //            //    this.setState({ manageAssetData: manageAssetData, manageAssetList: manageAssetData.manualassetlist }, () => {
    //            //    });
    //            //}
    //        })
    //        .catch(error => {
    //            console.error(error);
    //        })
    //}
    componentDidMount() {
        //this.getAssetTypeList();
        if (this.props.state.mode === 'edit') {
            let account = this.getCheckedAccount();
            this.setState({ selectedAccount: account }, () => {
                var formComponent = this.getAssetFormComponent("", "edit", this.state.selectedAccount);
                //if (formComponent.props.children === undefined || formComponent.props.children === null) {
                //    formComponent = <div> Not supported asset type</div>
                //}
                let liabilities = this.state.typeOfAssetList.filter(assetType => assetType.assetclass === "Liability");
                let assetClass = 'Asset';
                let filterKey = this.state.selectedAccount && this.state.selectedAccount.info ? this.state.selectedAccount.info.type : '';
                if (liabilities.length === 1) {
                    let assetArray = liabilities[0].assettypes;
                    let asset = assetArray.filter(item => item.assettype === filterKey);
                    if (asset.length > 0)
                        assetClass = 'Liability';
                }
                //let type = this.props.state.accountDetailsForEdit && this.props.state.accountDetailsForEdit.account && this.props.state.accountDetailsForEdit.account.info ? this.props.state.accountDetailsForEdit.account.info.type : '';
                this.setState({ typeOfAsset: filterKey, assetClass: assetClass, formComponent: formComponent });
            })
           
        }
    }
    getCheckedAccount = () => {
        let account = { ...this.props.state.accountDetailsForEdit.account };
        if (account) {
            if (account.info === undefined || account.info === null) {
                account.info = {};
            }
            if (account.value === undefined || account.value === null) {
                account.value = { valueasof: null, initialpricedate: null};
            }
            if (account.history === undefined || account.history === null) {
                account.history = [];
            }
            switch (account.info.type) {
                case 'Hedge Funds': {
                    if (account.hedgefunds === undefined || account.hedgefunds === null) {
                        account.hedgefunds = {};
                    }
                    return account;
                };
                case 'Real Estate': {
                    if (account.realestate === undefined || account.realestate === null) {
                        account.realestate = {};
                    } 
                    if (account.realestate.propertyaddress === undefined || account.realestate.propertyaddress === null) {
                        account.realestate.propertyaddress = {};
                    }
                    if (account.realestate.propertydetails === undefined || account.realestate.propertydetails === null) {
                        account.realestate.propertydetails = {};
                    }   
                    if (account.realestate.mortgageinformation === undefined || account.realestate.mortgageinformation === null) {
                        account.realestate.mortgageinformation = {};
                    }
                    return account;
                };
                case 'Loan': {
                    if (account.loan === undefined || account.loan === null) {
                        account.loan = {};
                    } 
                    if (account.loan.collateral === undefined || account.loan.collateral === null) {
                        account.loan.collateral = {};
                    }
                    if (account.loan.loaninfo === undefined || account.loan.loaninfo === null) {
                        account.loan.loaninfo = {};
                    }
                    return account;
                };
                case 'Mortgage': {
                    if (account.mortgage === undefined || account.mortgage === null) {
                        account.mortgage = {};
                    }
                    if (account.mortgage.propertyaddress === undefined || account.mortgage.propertyaddress === null) {
                        account.mortgage.propertyaddress = {};
                    }
                    if (account.mortgage.propertydetails === undefined || account.mortgage.propertydetails === null) {
                        account.mortgage.propertydetails = {};
                    }
                    if (account.mortgage.loaninfo === undefined || account.mortgage.loaninfo === null) {
                        account.mortgage.loaninfo = { maturitydate: null, nextpaymentdue: null};
                    }
                    return account;
                };
                case 'Credit Card': {
                    if (account.creditcard === undefined || account.creditcard === null) {
                        account.creditcard = { nextpaymentdate: null};
                    } 
                    if (account.creditcard.loaninfo === undefined || account.creditcard.loaninfo === null) {
                        account.creditcard.loaninfo = {};
                    }
                    return account;
                }; case 'Exchange Fund': {
                    if (account.exchangefund === undefined || account.exchangefund === null) {
                        account.exchangefund = { inceptiondate: null};
                    }
                    return account;
                }; 
                case 'Private Equity': {
                    if (account.privateequity === undefined || account.privateequity === null) {
                        account.privateequity = { valueasof: null};
                    }
                    if (account.privateequity.transactions === undefined || account.privateequity.transactions === null) {
                        account.privateequity.transactions = [];
                    }
                    return account;
                };
                case 'Promissory Note Loan': {
                    if (account.promissorynoteloan === undefined || account.promissorynoteloan === null) {
                        account.promissorynoteloan = {
                            maturitydate: null,
                            firstcoupondate: null,
                            lastcoupondate: null,
                            nextcoupondate: null
                        };
                    } 
                    return account;
                };
                case 'Promissory Note': {
                    if (account.promissorynote === undefined || account.promissorynote === null) {
                        account.promissorynote = {
                            maturitydate: null,
                            firstcoupondate: null,
                            lastcoupondate: null,
                            nextcoupondate: null,
                            dateddate: null};
                    }
                    return account;
                }; 
                default: {
                    return account;
                }
            }
        }
       
        
    }
    toggleAssetTypeChangeConfrimationPopup() {
        this.setState(prevState => ({
            openAssetTypeChangeConfrimationPopup: !prevState.openAssetTypeChangeConfrimationPopup
        }))
    }
    toggleAssetTypeCancelConfrimationPopup() {
        this.setState(prevState => ({
            openAssettypecancelconfrimationpopup: !prevState.openAssettypecancelconfrimationpopup,
            linkedAccount: ''
        }))

    }
    addModaltoggle() {
        this.setState(prevState => ({
            isAddModalOpen: !prevState.isAddModalOpen
        }))
    }
    typeOfAssetToggle() {
        this.setState(prevState => ({
            typeOfAssetOpen: !prevState.typeOfAssetOpen
        }))
    }
    onFromAssetTypeChange(asset) {
        if (!this.state.formStateChanged) {

            var formComponent = this.getAssetFormComponent(asset, "add");
            this.setState({ typeOfAsset: asset, formComponent: formComponent });
        } else {
            this.setState({ selectedAsset: asset });
            this.toggleAssetTypeChangeConfrimationPopup();
        }
    }
    changeAssetType() {

        var formComponent = this.getAssetFormComponent(this.state.selectedAsset, "add");
        this.setState(prevState => ({
            typeOfAsset: this.state.selectedAsset, formComponent: formComponent, formStateChanged: false,
            openAssetTypeChangeConfrimationPopup: !prevState.openAssetTypeChangeConfrimationPopup
        }))
    }
    setFormStateChange(flag) {
        this.setState({ formStateChanged: flag });
    }
    cancel() {
        if (this.state.formStateChanged) {
            this.setState(prevState => ({
                openAssettypecancelconfrimationpopup: !prevState.openAssettypecancelconfrimationpopup
            }))
        } else if (this.state.linkedAccount && this.state.linkedAccount !== null && this.state.linkedAccount !== '') {
            this.props.openLinkedAccountForEdit(this.state.linkedAccount);
        } else {
            this.props.closeAddEdittoggle();
        }
    }
    openLinkedAccount = (key) => {
        this.setState({ linkedAccount: key }, () => {
            this.cancel();
        });
    }
    closeAddEditAfterSave(flag) {
        this.setState({ formStateChanged: false });
        this.props.closeAddEditAfterSave(flag);
    }

    cancelAssetType() {
        this.setState(prevState => ({
            openAssettypecancelconfrimationpopup: !prevState.openAssettypecancelconfrimationpopup
        }))
        if (this.state.linkedAccount && this.state.linkedAccount !== null && this.state.linkedAccount !== '') {
           
            this.props.openLinkedAccountForEdit(this.state.linkedAccount);
        } else {
            this.props.closeAddEdittoggle();
        }
        
    }

    getAssetFormComponent(type, mode, account) {
        let typeOfAsset = type;
        if(mode === 'edit')
            typeOfAsset = account.info.type;
        return (
            <div>
                {{
                    'Asset Account': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Asset Account" />,
                    'Cash Account': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Cash Account" />,
                    'Investment Account': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Investment Account" />,
                    'Retirement Account': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Retirement Account" />,
                    'Education and Savings Account': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Education and Savings Account" />,
                    'Trust Account': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Trust Account" />,
                    'Other Asset': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Other Asset" />,
                    'Hedge Funds': <HedgeFunds cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Fund of Hedge Funds': <AssetAccount cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} source="Fund of Hedge Funds" />,
                    'Real Estate': <RealEstateForm cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} openLinkedAccount={this.openLinkedAccount}/>,
                    'Loan': <OtherLoan cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Mortgage': <Mortgage cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} openLinkedAccount={this.openLinkedAccount}/>,
                    'Credit Card': <CreditCard cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Exchange Fund': <ExchangeFund cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Private Equity': <PrivateEquity cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Tangibles': <Tangibles cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Transportation': <Transportation cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account} />,
                    'Promissory Note Loan': <PromissoryNoteLoan cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account}/>,
                    'Promissory Note': <PromissoryNote cancel={this.cancel} setFormStateChange={this.setFormStateChange} closeAddEditAfterSave={this.closeAddEditAfterSave} updateProfileDataInStore={this.props.updateProfileDataInStore} mode={mode} account={account}/>,
                }[typeOfAsset]}
            </div>
        );
    }
    render() {
        return (
            <div key="MA01">
                <div className="RCM_Toast RCM_Document_Toast" key="MA02">
                    <div className="row RCM_backLink_container" key="MA03">
                        <div className="col-sm-1 RCM_backLink" key="MA04">
                            <div id="RCM_addmanageAsset_back_link" className="RCM_icon_hand  RCM_manage_grp_new_group" onClick={this.cancel} key="MA05">Back</div>
                        </div>
                    </div>
                    {this.props.state.mode === 'edit'
                        ? <div key="MA06">
                        <label key="MA07">{this.state.assetClass} Type: {this.state.typeOfAsset}</label>
                        </div>
                        :<div className="row" key="MA08">
                            <div className="col-sm-8 col-xs-12 col-md-6 col-lg-4 RCM_select_asset_container" id="RCM_manageAsset_select_type" key="MA09">
                                <div className="RCM_CM_form_label" key="MA10">
                                    <label key="MA011">Select type</label>&nbsp;&nbsp;&nbsp;
                                </div>
                                <Dropdown name="typeOfAsset" required
                                    isOpen={this.state.typeOfAssetOpen}
                                    toggle={this.typeOfAssetToggle}
                                    className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                    key="MA012">
                                    <DropdownToggle caret> {this.state.typeOfAsset}</DropdownToggle>
                                    <DropdownMenu key="MA013">
                                        {
                                            (this.state.typeOfAssetList !== null
                                                && this.state.typeOfAssetList !== undefined
                                                && Array.isArray(this.state.typeOfAssetList)
                                                && this.state.typeOfAssetList.length > 0) ? (
                                                    <div key="MA018">
                                                        {this.state.typeOfAssetList.map((assetClass, index) =>
                                                            <div key={"MA01" + index}>
                                                                <DropdownItem header key={"MA011" + index}>{assetClass.assetclass}</DropdownItem>
                                                                <div key={"MA0111" + index}>
                                                                    {assetClass.assettypes !== null ? assetClass.assettypes.map((asset, index) =>
                                                                        <DropdownItem key={index} onClick={() => this.onFromAssetTypeChange(asset.assettype)}>
                                                                            <div key={"MA01111" + index}>{asset.assettype}</div>
                                                                              
                                                                        </DropdownItem>
                                                                    )
                                                                        : ""}

                                                                </div>
                                                            </div>
                                                        )
                                                        }
                                                    </div>



                                                ) : ""
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className="col-sm-8" key="MA014">
                            </div>
                        </div>
                    }
                    <div className="row" key="MA015">
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" key="MA016">
                            {this.state.formComponent}
                        </div>
                    </div>
                </div>
                <AssetTypeChangeConfrimationPopup
                    state={this.state}
                    changeAssetType={this.changeAssetType}
                    toggleAssetTypeChangeConfrimationPopup={this.toggleAssetTypeChangeConfrimationPopup} />

                <AssetTypeCancelConfrimationPopup
                    state={this.state}
                    cancelAssetType={this.cancelAssetType}
                    toggleAssetTypeCancelConfrimationPopup={this.toggleAssetTypeCancelConfrimationPopup} />
            </div>

        );
    }
}
const mapDispatchToProps = {

}
function mapStateToProps(store) {
    return {


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAssetDetails);