import React, { Component } from 'react'
import ArtView from './artview';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withPageView } from '../../../hoc/withPageView.jsx';
import { fineArtGroupUrl, christiesUrl, CheckIfProspectClient } from '../../../common/constants';
import OverlayProcessing from '../../MoneyMovement/common/overlayProcessing';
import { _logInformation } from '../../../common/_logging';

class Art extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmationModalState: false,
            showOverlay: false,
            selectedSection: {
                url: '',
                headerData: '',
                contentData: ''
            },
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.confirmationModalToggle = this.confirmationModalToggle.bind(this);
        this.openChristies = this.openChristies.bind(this);
        this.openFineArtGroup = this.openFineArtGroup.bind(this);
    }
    confirmationModalToggle() {
        this.setState(prevState => ({ confirmationModalState: !prevState.confirmationModalState }));
    }
    openFineArtGroup() {
        _logInformation('Fine Art Group Clicked');
        const selectedSection = {
            url: fineArtGroupUrl,
            headerData: 'Art Advisory provided by The Fine Art Group',
            contentData: 'By clicking on the \"Continue\" button below, you will be transferred to a third party website which is owned, operated and maintained by The Fine Art Group. If you have any questions, please contact your Financial Advisor.'
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    openChristies() {
        _logInformation('Christie\'s Clicked');
        const selectedSection = {
            url: christiesUrl,
            headerData: 'Art Advisory provided by Christie\'s',
            contentData: 'By clicking on the \"Continue\" button below, you will be transferred to a third party website which is owned, operated and maintained by Christie\'s. If you have any questions, please contact your Financial Advisor.'
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    openSelectedSectionWindow = () => {
        _logInformation(this.state.selectedSection.url);
        window.open(this.state.selectedSection.url);
        const selectedSection= {
            url: '',
            headerData: '',
            contentData: ''
        }
        this.setState({ selectedSection: selectedSection }, this.confirmationModalToggle);
    }
    render() {
        return (
            <>
                <ArtView
                    confirmationModalState={this.state.confirmationModalState}
                    confirmationModalToggle={this.confirmationModalToggle}
                    openFineArtGroup={this.openFineArtGroup}
                    openChristies={this.openChristies}
                    openSelectedSectionWindow={this.openSelectedSectionWindow}
                    selectedSection={this.state.selectedSection}
                />
                {this.state.showOverlay && (<OverlayProcessing />)}
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
       
    };
}

const mapDispatchToProps = {

}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Art' })
)

export default enhancement(Art);
