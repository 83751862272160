import XLSX from "xlsx";
import { formatMoney,getAccountDisplayName, IndLongShort  } from './../../common/constants.jsx';
import { getFormattedDate } from './common/utilty.js';

export const defaultSorted = [{ dataField: 'dttrade', order: 'desc' }];

export function generateScheduleTransfersExcelWs(props,scheduleTransfersList) {
    let xlsHeader = ["Transaction Date", "Account", "Amount", "Type", "Description"];
    let xlsRows = []
    if (scheduleTransfersList !== null && scheduleTransfersList !== undefined && Array.isArray(scheduleTransfersList)) {
        scheduleTransfersList.forEach(transfer => {
            var xlRow = {
                "Transaction Date": transfer.dttrade, "Account": getAccountDisplayName( props.selectedaccount, transfer.acct, IndLongShort.SINGLEVIEW),
                "Amount": transfer.total, "Type": transfer.trntype,
                 "Description": transfer.trndesc
            }
            xlsRows.push(xlRow);
        })
    }
    let ws = XLSX.utils.json_to_sheet(xlsRows, {
        header: xlsHeader,
        skipHeader: false
    });

    ws['!cols'] = [{ 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 80 }, { 'wpx': 120 }];

    return ws;
}


export function printScheduleTransferReport(scheduleTransfers) {
    let printWindow = window.open('', 'RCM Print', '');
    let table = "<table cellspacing='0'><tr><td><div><b>ROCKEFELLER</b></div><div>CAPITAL MANAGEMENT</div></td >";
    table += scheduleTrasfersPrintTable(scheduleTransfers);
    
    printWindow.document.write(table);
    printWindow.print()
    printWindow.close();
}

export function scheduleTrasfersPrintTable(props,scheduleTransfers)
{
    
    let table = "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='6'>Pending Transfers Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'>Date </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Account </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Type </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:30%;'> Description </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:10%;'> Amount </td>"
  
    table += "</tr>"
    if (scheduleTransfers !== null && scheduleTransfers !== undefined && Array.isArray(scheduleTransfers)) {
        scheduleTransfers.forEach(transfer => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:15%;'>" + getFormattedDate(transfer.dttrade) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" +getAccountDisplayName( props.selectedaccount, transfer.acct, IndLongShort.SINGLEVIEW) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:15%;'>" + transfer.trntype + "</td>"
            table += "<td style='text-align:right; border: 1px solid black;width:30%;'>" + transfer.trndesc + "</td>"
            table += "<td style='text-align:right; border: 1px solid black; width:10%;'>" + formatMoney(transfer.total) + "</td>"
           
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }
    table += "</table>"

    return table;
}


