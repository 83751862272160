import React, { Component } from 'react';
import { billPayProspectMsg } from './../../common/constants.jsx';
import { MessageType, MessageClass } from './../../common/constants.jsx';
import Messages from './../../common/messages.jsx';

export default class BillPay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []

        }
    }

    componentDidMount() {
        let messages = [];
        messages.push({
            type: MessageType.INFO,
            text: billPayProspectMsg,
            class: MessageClass.INFO
        })
        this.setState({ messages: messages });
    }
    render() {
       
        return (<div className="row">
            <div className="col-sm-11 col-xs-12 col-md-11 col-lg-11">
                {
                    (this.state && this.state.messages)
                        ? <Messages messages={this.state.messages}
                            containerClass={"RCM_validation_error_container"} />
                        : ""
                }
            </div>
        </div>);
       
    }
}
