import React from 'react'
import Watermark from '../../../common/watermark';
import ConfirmationModal from '../confirmationModal';

export default function ElderlyCareView(props) {
    const elderlyCareSectionText = 'Homethrive provides peace of mind for families with a variety of needs: from aging adults who want to maintain their health and independence to families who are overwhelmed by an aging loved one\'s needs. Homethrive supports caregivers and older adults through expert guidance and services designed to improve daily living, from health and home safety to all things Medicare. Click the button below to access a comprehensive overview of Homethrive for Rockefeller Clients.';
    
    return (
        <div className="container">
            {props.isProspectClient ? <Watermark /> : ''}
            <img id="health_image" src="./../images/elderly_landing_image.png" alt="Health" width="100%" className="RCM_health_base_image" />
            <div>
                <div className="row RCM_health_container">
                    <div className="col col-sm-3 mt-1">
                        

                    </div>
                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast RCM_health_toast" id="RCM_elderlyCare">
                            <div className="RCM_lifestyle_homeThrive"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">ELDER CARE</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">{elderlyCareSectionText}</div>
                            <div className="mt-4 RCM_lifestyle_btns" align="center">
                                <button className="btn btn-primary RCM_button_aviation_page"
                                    onClick={() => props.openElderlyCare()}
                                    disabled={props.isProspectClient} >
                                    CLICK HERE TO VIEW HOMETHRIVE
                                    </button>
                            </div>
                        </div>

                    </div>
                    <div className="col col-sm-3 mt-1">


                    </div>
                </div>

            </div>
            <ConfirmationModal
                confirmationModalToggle={props.confirmationModalToggle}
                confirmationModalState={props.confirmationModalState}
                openSelectedSectionWindow={props.openSelectedSectionWindow}
                selectedSection={props.selectedSection}
            />


           
        </div>
    )
}
