import React, { Component } from 'react';
import { connect } from 'react-redux';
import LiabilitiesDetails from './liabilitiesdetails.jsx'
class Liabilities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view:"L"
        }
    }
    componentDidMount() {
        if (this.props.location && this.props.location.view) {
            const viewstate = this.props.location.view;
            this.setState({ view: viewstate.viewBy ? viewstate.viewBy : "L" });
            console.log(viewstate);
        }
    }

    componentDidUpdate(prevprops) {
        if (this.props.location && this.props.location.pathname === "/Liabilities" && prevprops.location && prevprops.location.pathname === "/Liabilities") {
          const  currentview = this.props.location.view && this.props.location.view.viewBy ? this.props.location.view.viewBy : "L"
          const  prevview = prevprops.location.view && prevprops.location.view.viewBy ? prevprops.location.view.viewBy:"L"
            if (currentview !== prevview) {
                const viewstate = this.props.location.view;
                this.setState({ view: viewstate.viewBy ? viewstate.viewBy : "L" });
            }
        }

    }
    render() {
            return (
                <div id="RCM_ManageGroups">
                    <div className="row RCM_ManageGroup_heading_container">
                        <div className="RCM_Toast_heading col-sm-12">
                            <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" >
                                {
                                    this.state.view === "AL" ? 'ACCOUNTS' :
                                        this.state.view === "A" ? 'ASSETS' : 'LIABILITIES'
                                }
                            </label>
                        </div>
                    </div>
                    <LiabilitiesDetails viewBy={this.state.view}/>
                </div>
            );
        
    }
}

function mapStateToProps(store) {
    return {
        token: store.acct.token,
    };
};

const mapDispatchToProps = {
   
};
export default connect(mapStateToProps, mapDispatchToProps)(Liabilities);