import React, { Component } from 'react';

export function CustomizationView(
    props
) {
    let customizationview = (
        <div>
            <div id="RCM_top_tile_selection">
            <div className="row mt-1" >
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                    <label className="RCM_Customization_text">Top Section <span className="RCM_Customization_text_small_font">(Select upto 3)</span></label>
                </div>
            </div>
            <div className="row">
                
                <div className="col-sm-3 col-xs-3 col-md-3 col-lg-3">
                    <div className="radio">
                        <label className="RCM_selectLabel">
                            <input type="checkbox"
                                name="assetallocation"
                                checked={props && props.state?props.state.assetallocation:''}
                                onChange={props.handleInputChange}/>
                            <span className="profile-font-color RCM_preference_label">Asset Allocation</span>
                        </label>
                    </div>
                </div>
                <div className="col-sm-3 col-xs-3 col-md-3 col-lg-3">
                    <div className="radio">
                        <label className="RCM_selectLabel">
                            <input type="checkbox"
                                name="asset"
                                checked={props && props.state ? props.state.asset:''}
                                onChange={props.handleInputChange} />
                            <span className="profile-font-color RCM_preference_label">Account Overview</span>
                        </label>
                    </div>
                </div>
                <div className="col-sm-3 col-xs-3 col-md-3 col-lg-3">
                    <div className="radio">
                        <label className="RCM_selectLabel">
                            <input type="checkbox"
                                name="topten"
                                checked={props && props.state ? props.state.topten:''}
                                onChange={props.handleInputChange} />
                            <span className="profile-font-color RCM_preference_label">Top 10 Holdings</span>
                        </label>
                    </div>
                </div>
               
                    {/* props.state.isPilotForHistoricalAssetAllocation ?*/}
                <div className="col-sm-3 col-xs-3 col-md-3 col-lg-3">
                        <div className="radio">
                            <label className="RCM_selectLabel">
                                <input type="checkbox"
                                    name="historicalasset"
                                    checked={props && props.state ? props.state.historicalasset:''}
                                    onChange={props.handleInputChange} />
                                <span className="profile-font-color RCM_preference_label">Historical Value</span>
                            </label>
                        </div></div>

                    {/*  : ''*/}
                    {
                        props && props.state && props.state.isPerformance
                            ? <div className="col-sm-3 col-xs-3 col-md-3 col-lg-3">
                                <div className="radio">
                                    <label className="RCM_selectLabel">
                                        <input type="checkbox"
                                            name="performance"
                                            checked={props && props.state ? props.state.performance : ''}
                                            onChange={props.handleInputChange} />
                                        <span className="profile-font-color RCM_preference_label">Performance</span>
                                    </label>
                                </div>
                            </div>
                            :<></>
                    }
                    
            </div>
        </div>

            <div className="row mt-4"></div>
            <div id="RCM_bottom_tile_selection">
            <div className="row mt-2">
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                    <label className="RCM_Customization_text">Bottom Section</label>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 col-xs-4 col-md-4 col-lg-4">
                    <div className="radio">
                        <label className="RCM_selectLabel">
                            <input type="checkbox"
                                name="activity"
                                checked={props && props.state ?props.state.activity:''}
                                onChange={props.handleInputChange}/>
                            <span className="profile-font-color RCM_preference_label">Account Activity</span>
                        </label>
                    </div>
                </div>
                </div>
            </div>

        </div>
    )

    return customizationview
}
