import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import { getNestedObject } from '../../../MoneyMovement/common/utilty';
import { MsgForNoData, formatMoney, GetNumericValue, roundvalue, getAccountDisplayName, IndLongShort, spinner} from '../../../../common/constants';
import { getPerformanceSummaryService, getPerformanceSummaryCancellationToken } from './../../detail/performance/performanceservice';
import { UpdatePerformanceSummaryTable } from '../../../../actions/accountsactions';
import { PerformanceSummaryColumns } from './performancesummarycolumns';
import { getPerformanceDataService, getPerformanceDataServiceCancellationToken } from '../../detail/performance/performanceservice';

const PerformanceSummaryTable = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [totalColumns, setTotalColumns] = useState([{
        dataField: "number",
        text: "Account Number",
        formatter: (value) => {
            return (
                <div >{value}</div>
            );
        }
    }]);

    const contextJson = useSelector(state => getNestedObject(state, ['acct', 'contextjson']));
    const authToken = useSelector(state => getNestedObject(state, ['acct', 'token']));
    const profilejson = useSelector(state => getNestedObject(state, ['acct', 'profilejson']));
    const render = useSelector(state => getNestedObject(state, ['acct', 'render']));
    const accountlist = useSelector(state => getNestedObject(state, ['acct', 'accounts']));
    const columns = PerformanceSummaryColumns(accountlist);
    const dispatch = useDispatch();
    useEffect(() => {

        return () => {
            if (getPerformanceSummaryCancellationToken !== null && getPerformanceSummaryCancellationToken !== undefined) {
                getPerformanceSummaryCancellationToken.cancel("Cancelling get Performance Data request on unmount to prevent memory leak.");
            }
            if (getPerformanceDataServiceCancellationToken !== null && getPerformanceDataServiceCancellationToken !== undefined) {
                getPerformanceDataServiceCancellationToken.cancel("Cancelling get performance data request on unmount to prevent memory leak.");
            }
     
        }
    }, []);
    useEffect(() => {
        setShowOverlay(prevShowOverlay => true);
        getAccountPerformanceData();
        //getPerformanceSummaryTableData();
    }, [render]);
    const setAccountData = data => {
        if (data && data.length > 0) {
            let mappedData = data.map(x => ({
                ...x,
                displayname: getAccountDisplayName(accountlist, x.key, IndLongShort.SINGLEVIEW)

            }));
            //updatePerformanceAccount(mappedData);
            setAccounts(mappedData);
            updatePerformanceSummary(data, columns);
        } else {
           // updatePerformanceAccount([]);
            setAccounts([]);
            updatePerformanceSummary(data, columns);
        }
    }
    const getAccountPerformanceData = () => {

        getPerformanceDataService(authToken, contextJson, profilejson, 'account')
            .then(performanceData => {
                if (performanceData) {
                    let data = performanceData.accountlist && performanceData.accountlist !== null ? performanceData.accountlist : [];
                    setAccountData(data);
                    //setAccounts(data);

                    setShowOverlay(prevShowOverlay => false);
                    updatePerformanceSummary(data, columns);
                } else {
                    setAccounts([]);
                    updatePerformanceSummary([], columns);
                    setShowOverlay(prevShowOverlay => false);
                }

            })
            .catch(error => {
                console.error(error);
                setAccounts([]);
                updatePerformanceSummary([], columns);
                setShowOverlay(prevShowOverlay => false);
            })

    }
    const updatePerformanceSummary = useCallback(
        (tableData, columns) => dispatch(UpdatePerformanceSummaryTable(tableData, columns)),
        [dispatch]
    );

    const constructTableData = data => {
        let tableData = [];
        //let tableColumns = [];
        let tableColumnDate = [];
        /*to get column names*/
        for (const [, value] of data.entries()) {
            let account = {};

            account.key = value && value.key ? value.key : '';
            account.number = value && value.number ? value.number : '';
            if (value.accountsummary && value.accountsummary.length > 0) {
                for (const [, item] of value.accountsummary.entries()) {
                    let keyString = new Date(item.year, item.month - 1).toLocaleString("default", {
                        month: "short",
                        year: "2-digit"
                    })
                    let columnDate = new Date(item.year, item.month - 1);
                    account[keyString] = parseInt(item.amount);
                    tableColumnDate.push(columnDate);
                }
            }
            tableData.push(account);
        }
        let sortedTableColDate = tableColumnDate.sort((a, b) => a - b);
       
        let finalColumn = [];
        for (const [, item] of sortedTableColDate.entries()) {
            let dateString = new Date(item).toLocaleString("default", {
                month: "short",
                year: "2-digit"
            })
            if (finalColumn.indexOf(dateString) === -1) {
                finalColumn.push(dateString);
            }
           
        }

        var columns = finalColumn.slice(finalColumn.length - 4, finalColumn.length);
        let dateColumns = columns.map(dateItem => {
            return {
                dataField: "" + dateItem,
                text: "" + dateItem,
                headerAlign: "right",
                classes: "RCM_common_table_column_number RCM_common_table_column_long",
                formatter: (cell) => {
                    let value = cell !== undefined && cell !== null ? (cell !== 0 ? formatMoney(roundvalue(cell, 2)) : GetNumericValue(cell)) : '-';
                    return (
                        <div >{value}</div>
                    );
                }
            };
        });

        let headerColumn = {

            dataField: "key",
            text: "Account Number",
            formatter: (cell) => {
                return <div title={getAccountDisplayName(accountlist, cell, IndLongShort.LONG)}>{getAccountDisplayName(accountlist, cell, IndLongShort.SINGLEVIEW)}</div>;
            },
        };
        let totalColumns = [
            {
                ...headerColumn,
            },
            ...dateColumns.map(x => ({
                ...x
            }))
        ];
        setTotalColumns(totalColumns);
        setAccounts(tableData);
        setShowOverlay(prevShowOverlay => false);
        updatePerformanceSummary(tableData, columns);
    }
    const getPerformanceSummaryTableData = () => {
        getPerformanceSummaryService(authToken, contextJson, profilejson)
        .then(performanceData => {
            if (performanceData) {
                let data = performanceData.performancesummarytable && performanceData.performancesummarytable !== null ? performanceData.performancesummarytable:[];
                constructTableData(data);
                
            } else {
                setAccounts([]);
                setShowOverlay(prevShowOverlay => false);
            }

        })
        .catch(error => {
            console.error(error);
            setAccounts([]);
            setShowOverlay(prevShowOverlay => false);
        })
        
       
    }
   
    return (
        <>
            
            {showOverlay
                ? <div className="RCM_spinner mt-4" key="PerformanceSummarytable01">Loading Performance Summary{spinner()}</div>
                :
                <div key="PerformanceSummarytable02" className="RCM_Dashboard_Tables RCM_CM_scroll RCM_Performance_summary_table">
                    <BootstrapTable
                        keyField="key"
                        classes="RCM_two_level_table1"
                        data={accounts}
                        columns={columns}
                        bordered={false}
                        noDataIndication={MsgForNoData}
                    />
                </div>
            }

        </>
    )

};

export default PerformanceSummaryTable;