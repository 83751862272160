import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { spinner, MsgForNoData, MsgForFailedData} from './../../../../../common/constants.jsx';
import { securityColumns } from '../holdingdetailscolumns.jsx';
import Watermark from '../../../../../common/watermark';
export function SecurityHoldingsView(
    props
) {
    let securityHoldingview = (
        <div>
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            
            <div className="conatiner-fluid RCM_Toast" key="secdetails1">
                {
                    !props.state.isloading && props.state.balSummaryV2PilotFlag ?
                        <div className="row RCM_Holding_Note">
                            <label className="RCM_Customization_text RCM_Customization_text_small_font">*&nbsp; This section excludes any cash fund positions and intra-day cash movements from Rockefeller accounts.  Please see the <span className="RCM_balance_link" onClick={props.gotobalance}>balances</span> view for pending and unsettled activities.</label>
                        </div>
                        : ''
                }
               
            {
                (() => {
                    if (props.state.requestFailed) {
                        return (
                            <div className="row" id="RCM_SecurityHoldingTable">
                                <div
                                    className={"col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_SecurityHolding_Wrapper"
                                        + (props.state.viewYield ? ' RCM_Security_holdingChild_withYield' : ' RCM_Security_holdingChild_withoutYield')}>
                            <BootstrapTable key='btsecurity'
                                keyField='UNQID'
                                data={[]}
                                classes="RCM_two_level_table1"
                                        columns={securityColumns('', '', '', '', [], props.state.isrowexpanded, '', '', props.state.viewYield)}
                                noDataIndication={MsgForFailedData}
                                expandRow={props.expandRowBySecurity}
                                bordered={false}
                            /></div></div>)
                       
                    }
                    else if (props.state.isloading) {
                        return <div className="RCM_spinner">Loading Security Holdings{spinner()}</div>
                    }
                    else {
                        if (props.state.holdingsresponsebysecurity && props.state.holdingsresponsebysecurity.length > 0 && !props.state.isloading) {
                            return (
                                <div className="row" id="RCM_SecurityHoldingTable">
                                    <div
                                        className={"col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_SecurityHolding_Wrapper"
                                            + (props.state.viewYield ? ' RCM_Security_holdingChild_withYield' : ' RCM_Security_holdingChild_withoutYield')}>
                                        <BootstrapTable key='btsecurity'
                                keyField='UNQID'
                                data={props.securityData}
                                classes="RCM_two_level_table1"
                                        columns={securityColumns(props.totalvalue, props.totalglamount, props.totalglpct, props.totalcostbasis, props.securityData, props.state.isrowexpanded, props.totaltodayschange, props.totaltodayschangepct, props.rowsortevent, props.state.viewYield)}
                                noDataIndication={MsgForNoData}
                                expandRow={props.expandRowBySecurity}
                                bordered={false}
                                 /></div></div>)
                        }
                        else {
                            return (
                                <div className="row" id="RCM_SecurityHoldingTable">
                                    <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_SecurityHolding_Wrapper"
                                        + (props.state.viewYield ? ' RCM_Security_holdingChild_withYield' : ' RCM_Security_holdingChild_withoutYield')}>
                                        <BootstrapTable key='btsecurity'
                                keyField='UNQID'
                                data={props.securityData}
                                classes="RCM_two_level_table1"
                                        columns={securityColumns(props.totalvalue, props.totalglamount, props.totalglpct, props.totalcostbasis, props.securityData, props.state.isrowexpanded, props.totaltodayschange, props.totaltodayschangepct, props.rowsortevent, props.state.viewYield)}
                                 noDataIndication={MsgForNoData}
                                expandRow={props.expandRowBySecurity}
                                bordered={false}
                                    /></div></div>)
                          
                        }
                    }
                })()
                }
            </div>
            </div>
    )
    return securityHoldingview
}
