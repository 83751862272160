import { errorMsg, accountInfoURL, assetSchemaColorsConfigUrl, accountInfoDetailServiceurl } from "../../../common/constants"
import { Get } from "../../../common/servicecalls.jsx";
import axios from "axios";

export var getAccountInfoCancellationToken = null;
export var getAccountInfoDetailsCancellationToken =null;


export const getAccountInfoService = (token, contextJson, profileJson, searchBy) => {

    //Check if cancellation token has value;
    if (getAccountInfoCancellationToken !== null && getAccountInfoCancellationToken !== undefined) {
        getAccountInfoCancellationToken.cancel('Cancelling the get account info request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAccountInfoCancellationToken = cancelToken.source();

    let getAccountInfoPromise = new Promise(
        function (resolve, reject) {
            let getAccountInfourl = accountInfoURL + 'SearchBy=' + searchBy;
            Get(getAccountInfourl, token, contextJson, profileJson, getAccountInfoCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage Asset Service : get Asset List ::", res);
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Manage Asset Service : get Asset List :: ", error);
                        //_logException("Manage Asset Service : get Asset List :: " + (error && error.response) ? error.response : "");
                        reject(errorMsg);
                    }
                });
        });
    return getAccountInfoPromise;
}

export var getWireAndAchInfoCancellationToken = null;


export const getWireAndAchInfoService = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (getWireAndAchInfoCancellationToken !== null && getWireAndAchInfoCancellationToken !== undefined) {
        getWireAndAchInfoCancellationToken.cancel('Cancelling the get Wire and ACH info request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getWireAndAchInfoCancellationToken = cancelToken.source();

    let getWireAndAchInfoPromise = new Promise(
        function (resolve, reject) {
            let baseUrl = assetSchemaColorsConfigUrl;
            Get(baseUrl, token, contextJson, profileJson, getWireAndAchInfoCancellationToken).then(res => {
                // get the response in case of successful call.
                
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Get Wire and ACH Service : Get Wire and ACH Info :: ", error);
                        
                        reject(errorMsg);
                    }
                });
        });
    return getWireAndAchInfoPromise;
}


export const getAccountDetailsInfoService = (account, token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (getAccountInfoDetailsCancellationToken !== null && getAccountInfoDetailsCancellationToken !== undefined) {
        getAccountInfoDetailsCancellationToken.cancel('Cancelling the get account info details service request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAccountInfoDetailsCancellationToken = cancelToken.source();
    let getAccountInfoPromise = new Promise(
        function (resolve, reject) {
            let url = `${accountInfoDetailServiceurl}${account}`;
            Get(url, token, contextJson, profileJson, getAccountInfoDetailsCancellationToken).then(res => {
                // get the response in case of successful call.
                if (res && res !== null
                    && res != undefined) {
                    let response = res.data;
                    resolve(response);
                } else {
                    resolve({});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.log("Get Account Info Detail Service Error:: ", error);
                    reject(errorMsg);
                }
            });
        });
    return getAccountInfoPromise;
}