import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { getAlertColumns } from './alertdetailscolumns'
import { spinner, MsgForFailedData, MsgForNoData, getAccountDisplayName, IndLongShort, getDateTimeVal } from './../../../../common/constants.jsx';
import Watermark from '../../../../common/watermark';
import Messages from '../../../../common/messages.jsx';
export function AlertView(
    props
) {
    console.log("selected row" + props && props.state && props.state.isAlertSelected)
    const selectRow = {
        mode: 'radio',
        selected: props && props.state && props.state.selected,
        onSelect: props && props.selectAlert,
        classes: 'RCM_grp_selection_row',
        clickToSelect: true
    };
    const formateAlertMessageView = (text) => {
        let returntext = text.split('\\n').map(str => <p className="RCM_alertdetail_msg">{str}</p>);
        return returntext;
    }
    let alertview = (

        <div className="container-fluid">
            {props.state && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row">
                <div className="col-sm-12" key="lblholdingheadingrow">
                    <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblalertsheading' id="RCM_alert_notification">ALERT DETAILS</label>
                </div>
            </div>
            {
                props && props.state && props.state.messages && props.state.messages.length > 0 ?
                    <div className="row RCM_AccountActivityDetails_Message_Div" >
                        <div className="col-sm-12">
                            <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                        </div>
                    </div> : ''}
            {
                props && props.state && props.state.isLoading ? <div className="RCM_spinner">Loading Alert Details{spinner()}</div> :

                    props && props.state && props.state.isRequestFailed ?
                        <div>{MsgForFailedData}</div>
                        :
                        <div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5" id="RCM_stmtaccdetails">
                                <div className="RCM_Toast" id="RCM_alert_table">
                                    {
                                        props && props.alertList && props.alertList.length === 0
                                            ?
                                            <p>{MsgForNoData}</p>
                                            :
                                            <div>
                                                {props && props.state && props.state.isUnreadAlertFound ?
                                                    <div className="RCM_caseManager_modal_link" align="right" onClick={props.SetAllAlertsAsRead}><a id="lnkmarkallasread" disabled={props.state.isProspectClient} className={props.state.isProspectClient ? "isDisabled" : ''}>Mark all as read</a></div>
                                                    : <div className="RCM_caseManager_modal_link" align="right" onClick={() => { }}><a id="lnkmarkallasread" className="isDisabled">Mark all as read</a></div>}
                                                <div className="row mt-2 RCM_ManageGroup_Tables RCM_CM_scroll">
                                                    <div className="col-sm-12">
                                                        <BootstrapTable
                                                            keyField="alertid"
                                                            data={props.alertList && props.alertList.length > 0 ? props.alertList:[]}
                                                            columns={getAlertColumns(props.accounts)}
                                                            bordered={false}
                                                            selectRow={selectRow}
                                                            classes="RCM_two_level_table1"

                                                        >
                                                        </BootstrapTable>
                                                    </div>
                                                </div>
                                            </div>

                                    }

                                </div>
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                                <div className="RCM_Toast" id="RCM_alert_preview">
                                    <div className="RCM_Text_Center">
                                        <label className="RCM_tile_heading" >ALERT MESSAGE</label>
                                    </div>

                                    {props && props.state && props.state.rowdata && props.alertList && props.alertList.length > 0?
                                        <div>
                                        <div className="row mt-4" >
                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-5"><label >Account Number : {props.state.rowdata && props.state.rowdata && props.state.rowdata.accountnumber ? getAccountDisplayName(props.accounts, props.state.rowdata.accountnumber, IndLongShort.SINGLEVIEW):'-'}</label></div>
                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-4"> <label>Date : {props.state.rowdata && props.state.rowdata.alertdate&& props.state.rowdata.alertdate ? getDateTimeVal(props.state.rowdata.alertdate):'-'}</label>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-3"><label >Alert Name : {props.state.rowdata && props.state.rowdata.alertname ? props.state.rowdata.alertname : '-'}</label></div>
                                        </div>
                                        <div className="row mt-2">
                                                {/*<div className="col-sm-12 col-xs-12 col-md-12 col-lg-12"><label>Account Message</label></div>*/}
                                              {props && props.state && props.state.isAlertSelected ?
                                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_ManageGroup_Tables RCM_CM_scroll" key="disclaimer18" id="alert_text">
                                                        {formateAlertMessageView(props.alertresponse)}</div>

                                                : ''}

                                            </div></div> :
                                         props && props.state && props.state.isAlertSelected ?
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_ManageGroup_Tables RCM_CM_scroll" key="disclaimer18" >
                                                {props.alertresponse}</div>

                                            : ''}
                                </div>
                            </div>



                        </div>
            }

        </div>



    )
    return alertview
}

