import React from 'react';
import Messages from '../../../common/messages.jsx';
const OtpView = (props) => {
    return (
        <div id="RCM_OneTimePasscode">
            
            {props.messages && props.messages.length > 0 ?
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <Messages messages={props.messages} containerClass="" />
                </div>
                : ''
            }
            <div className="row">
                <div className="col-sm-12 RCM_caseManager_modal_link_container">
                     <span onClick={props.getOtpToken} className="RCM_caseManager_modal_link" id="RCM_click_here_token">
                            Click here to generate a One Time Passcode</span>
                </div>
            </div>
            {
                props.showOverlay
                    ? <div align="center" className="mt-2" key="Approver02">Fetching One Time Passcode
                    
                </div >
                    : ''
            }
            <div className="row mt-2">
                <div className="col-sm-12 RCM_caseManager_modal_link_container">
                    {props.token}
                    </div>
            </div>
            { props.expTime && props.expTime!=''?
            <div className="row mt-2">
                <div className="col-sm-12 RCM_caseManager_modal_link_container">
                        <label id="expTime"></label>
                    </div>
            </div>:''}
        </div>
    )
}

export default OtpView;