import React, { useCallback, useRef, useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { spinner } from "../../../../common/constants";
import Messages from "../../../../common/messages";
import { useClickOutside } from "./useClickOutside";

const AccountHolders = ({ accountInfoState }) => {
  const divRefForClickOutside = useRef();
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const { accountInfo, isLoading, error } = accountInfoState || {};

  const onClickOutside = useCallback(() => {
    setIsToolTipOpen(false);
  }, []);

  useClickOutside(divRefForClickOutside, onClickOutside);

  const handleToolTipToogle = useCallback(() => {
    setIsToolTipOpen((prev) => !prev);
  }, []);

  return (
    <div ref={divRefForClickOutside}>
      {isLoading && (
        <div className="RCM_spinner">Loading Account Holders{spinner()}</div>
      )}
      {error && (
        <Messages
          messages={["Unable to fetch account holders information."]}
          containerClass="RCM_validation_error_container"
        />
      )}
      <div className="mt-4">
        {accountInfo &&
          accountInfo.accountHolders &&
          accountInfo.accountHolders.map((holder, index) => {
            return (
              <div key={index} style={{ display: "flex", gap: 5 }}>
                <div>{holder.name}</div>
                {holder.trustedContacts && (
                  <>
                    <div
                      className="RCM_menu_img RCM_Nav_Statements"
                      style={{ cursor: "pointer" }}
                      id="trustedcontacticon"
                    ></div>
                    <Popover
                      target="trustedcontacticon"
                      isOpen={isToolTipOpen}
                      toggle={handleToolTipToogle}
                      className="RCMTrustedContactPopover"
                      innerClassName="RCMTrustedContactPopoverInner"
                    >
                      <PopoverHeader>TRUSTED CONTACTS</PopoverHeader>
                      <PopoverBody>
                        {holder.trustedContacts.map((trustedContact, index) => {
                          return (
                            <div
                              key={index}
                              style={{ display: "flex", gap: 5 }}
                            >
                              <div>{trustedContact.name}</div>
                              {trustedContact.isPrimary && <div>(Primary)</div>}
                            </div>
                          );
                        })}
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AccountHolders;
