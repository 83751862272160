import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import DatePicker from 'react-datepicker'
import classnames from 'classnames';
import { addAssetService, editAssetService } from '../manageassetsservice.jsx';
import { roundUnitValue, amountFormat, zipFormat, alphanumericnameFormat, alphabitFormat, basicAlphaNumeric, discriptionFormat, numericFormat, interestRateFormat, viewOnlyText } from './../../../../../common/constants.jsx';

class Mortgage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            displayvalue: '',
            displayoutstandingbalance: '',
            displayoriginalamount: '',
            displaypurchaseprice: '',
            displaydownpayment: '',
            displayliveablearea: '',
            displaynextpaymentamount: '',
            intialAccount: {},
            account: {
                profid: props.profileId,
                accountkey: '',
                info: {
                    originator: '',
                    custodianname: '',
                    accountnumber: '',
                    nickname: '',
                    type: 'Mortgage',
                    description: ''
                },
                value: {
                    totalvalue: '',
                    valueasof: new Date()
                },
                mortgage: {
                    propertyaddress: {
                        line1: "",
                        line2: "",
                        city: "",
                        state: "",
                        zip: "",
                        county: "",
                        country: ""
                    },
                    propertydetails:
                    {
                        liveablearea: "",
                        floors: '',
                        bedrooms: "",
                        bathrooms: "",
                        yearbuilt: "",
                        type: "",
                        isprimaryresidence: "",
                        isinvestment: "",
                        yearpurchased: '',
                        sizeofland: "",
                    },
                    loaninfo: {
                        purchaseprice: "",
                        downpayment: "",
                        outstandingbalance: "",
                        interestrate: "",
                        interesttype: "",
                        paymentfrequency: "",
                        maturitydate: new Date(),
                        loanterm: '',
                        nextpaymentdue: new Date(),
                        nextpaymentamount: ''
                    },
                    realestateaccountkey: ''
                }
            },

            typeoptions: ["Single Family", "Multiple Family", "Condo", "TownHome", "Land", "Commercial"],
            interesttypeoptions: ["Interest Only", "Adjustable Rate", "Fixed", "Variable"],
            paymentfrequencyoptions: ["Bi-Weekly", "Monthly", "Quarterly", "Yearly"],
            istypeopen: false,
            isinteresttypeopen: false,
            ispaymentfrequencyopen: false,
            totalval: 0,
            formStateChange: false

        };
        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }

        this.interesttypetoggle = this.interesttypetoggle.bind(this);
        this.paymentfrequencytoggle = this.paymentfrequencytoggle.bind(this);
        this.typetoggle = this.typetoggle.bind(this);
        // this.handleValueAsOfChange = this.handleValueAsOfChange.bind(this);
        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.typechange = this.typechange.bind(this);
        this.interesttypechange = this.interesttypechange.bind(this);
        this.paymentfrequencychange = this.paymentfrequencychange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleAmountFormatting = this.handleAmountFormatting.bind(this);
        this.handleAmmountOnFocus = this.handleAmmountOnFocus.bind(this);
        this.checkStateChange = this.checkStateChange.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
    }
    componentDidMount() {

        if (this.props.mode === 'edit' && this.props.account) {
            var displayvalue = "";
            if (this.props.account.value.totalvalue !== undefined && this.props.account.value.totalvalue !== null && this.props.account.value.totalvalue !== "")
                displayvalue = parseFloat(this.props.account.value.totalvalue).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displayoutstandingbalance = "";
            if (this.props.account.mortgage.loaninfo.outstandingbalance !== undefined && this.props.account.mortgage.loaninfo.outstandingbalance !== null && this.props.account.mortgage.loaninfo.outstandingbalance !== "")
                displayoutstandingbalance = parseFloat(this.props.account.mortgage.loaninfo.outstandingbalance).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displaynextpaymentamount = "";
            if (this.props.account.mortgage.loaninfo.nextpaymentamount !== undefined && this.props.account.mortgage.loaninfo.nextpaymentamount !== null && this.props.account.mortgage.loaninfo.nextpaymentamount !== "")
                displaynextpaymentamount = parseFloat(this.props.account.mortgage.loaninfo.nextpaymentamount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displaypurchaseprice = "";
            if (this.props.account.mortgage.loaninfo.purchaseprice !== undefined && this.props.account.mortgage.loaninfo.purchaseprice !== null && this.props.account.mortgage.loaninfo.purchaseprice !== "")
                displaypurchaseprice = parseFloat(this.props.account.mortgage.loaninfo.purchaseprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

            var displaydownpayment = "";
            if (this.props.account.mortgage.loaninfo.downpayment !== undefined && this.props.account.mortgage.loaninfo.downpayment !== null && this.props.account.mortgage.loaninfo.downpayment !== "")
                displaydownpayment = parseFloat(this.props.account.mortgage.loaninfo.downpayment).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            var displayliveablearea = "";
            if (this.props.account.mortgage.propertydetails.liveablearea !== undefined && this.props.account.mortgage.propertydetails.liveablearea !== null && this.props.account.mortgage.propertydetails.liveablearea !== "")
                displayliveablearea = roundUnitValue(parseFloat(this.props.account.mortgage.propertydetails.liveablearea), false, true);

            var initailAccount = JSON.parse(JSON.stringify(this.props.account));
            this.setState({
                account: this.props.account, intialAccount: initailAccount, displayvalue: displayvalue, displayoutstandingbalance: displayoutstandingbalance,
                displaypurchaseprice: displaypurchaseprice, displaydownpayment: displaydownpayment, displayliveablearea: displayliveablearea, displaynextpaymentamount: displaynextpaymentamount
            });
        } else if (this.props.mode === 'add') {
            var initailAccount = JSON.parse(JSON.stringify(this.state.account));
            this.setState({ intialAccount: initailAccount });
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab)
            this.setState({ activeTab: tab });
    }
    checkStateChange() {
        if (JSON.stringify(this.state.account) !== JSON.stringify(this.state.initialaccount)) {
            if (this.state.formStateChange === false) {
                this.setState(({
                    formStateChange: true
                }), () => {
                    this.props.setFormStateChange(true);
                })
            }
        } else {
            if (this.state.formStateChange === true) {
                this.setState(({
                    formStateChange: false
                }), () => {
                    this.props.setFormStateChange(false);
                })
            }

        }
    }
    saveAsset() {
        var requestJson = {
            "account": this.state.account
        };
        if (this.props.mode === 'add') {
            addAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    //this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET');
                    this.props.updateProfileDataInStore('ADD_ACCOUNTS_IN_MANUAL_ASSET', data.data);
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        } else if (this.props.mode === 'edit') {
            editAssetService(requestJson, this.authToken, this.contextjson)
                .then(data => {
                    this.props.closeAddEditAfterSave(true);
                    this.props.updateProfileDataInStore('EDIT_ACCOUNTS_IN_MANUAL_ASSET');
                })
                .catch(error => {
                    console.error(error);
                    this.props.closeAddEditAfterSave(false);
                })
        }
    }

    interesttypechange(interesttypeval) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.mortgage.loaninfo.interesttype = interesttypeval;
        this.setState(clonedState);
        this.checkStateChange();
    }
    paymentfrequencychange(paymentfrequencyval) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.mortgage.loaninfo.paymentfrequency = paymentfrequencyval;
        this.setState(clonedState);
        this.checkStateChange();
    }

    typechange(statevalue) {
        let clonedState = Object.assign({}, this.state);
        clonedState.account.mortgage.propertydetails.type = statevalue;
        this.setState(clonedState);
        this.checkStateChange();
    }

    typetoggle() {
        this.setState(prevState => ({
            istypeopen: !prevState.istypeopen
        }))
    }

    interesttypetoggle() {
        this.setState(prevState => ({
            isinteresttypeopen: !prevState.isinteresttypeopen
        }))
    }
    paymentfrequencytoggle() {
        this.setState(prevState => ({
            ispaymentfrequencyopen: !prevState.ispaymentfrequencyopen
        }))
    }

    //handleValueAsOfChange(evt) {
    //    let clonedState = Object.assign({}, this.state);
    //    clonedState.account.value.valueasof = evt;
    //    this.setState(clonedState);
    //    this.checkStateChange();
    //}
    handleDateChange = (value, source) => {
        let clonedState = Object.assign({}, this.state);
        if (source === 'valueasof') {
            clonedState.account.value.valueasof = value;
        } else if (source === 'maturitydate') {
            clonedState.account.mortgage.loaninfo.maturitydate = value;
        } else if (source === 'nextpaymentdue') {
            clonedState.account.mortgage.loaninfo.nextpaymentdue = value;
        }
        this.setState(clonedState, () => {
            this.checkStateChange();
        });

    }

    handleFormChanges(evt) {

        let clonedState = Object.assign({}, this.state);

        if (evt.target.name === "loannumber") {
            if (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value) && evt.target.value.length <= 20)
                clonedState.account.info.accountnumber = evt.target.value;
        }

        else if (evt.target.name === "desc" && (evt.target.value === "" || discriptionFormat.test(evt.target.value))) {
            clonedState.account.info.description = evt.target.value;
        }
        else if (evt.target.name === "address1") {

            clonedState.account.mortgage.propertyaddress.line1 = evt.target.value;
        }
        else if (evt.target.name === "address2") {
            clonedState.account.mortgage.propertyaddress.line2 = evt.target.value;
        }
        else if (evt.target.name === "originator") {
            if (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.info.originator = evt.target.value;
            clonedState.account.info.custodianname = evt.target.value;
        }
        else if (evt.target.name === "city") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.mortgage.propertyaddress.city = evt.target.value;
        }
        else if (evt.target.name === "state") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.mortgage.propertyaddress.state = evt.target.value;
        }
        else if (evt.target.name === "servicer") {
            clonedState.mortgage.servicer = evt.target.value;
        }
        else if (evt.target.name === "country") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.mortgage.propertyaddress.country = evt.target.value;
        }
        else if (evt.target.name === "county") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.mortgage.propertyaddress.county = evt.target.value;
        }
        else if (evt.target.name === "zip") {
            if (evt.target.value === "" || zipFormat.test(evt.target.value) && evt.target.value.length <= 10)
                clonedState.account.mortgage.propertyaddress.zip = evt.target.value;
        } else if (evt.target.name === "bedrooms") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 2)
                clonedState.account.mortgage.propertydetails.bedrooms = evt.target.value;
        } else if (evt.target.name === "bathrooms") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 2)
                clonedState.account.mortgage.propertydetails.bathrooms = evt.target.value;
        }
        else if (evt.target.name === "liveablearea") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayliveablearea = evt.target.value;
        }
        else if (evt.target.name === "value") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayvalue = evt.target.value;
        }
        else if (evt.target.name === "isprimaryresidence") {
            clonedState.account.mortgage.propertydetails.isprimaryresidence = evt.target.value;
        }
        else if (evt.target.name === "isinvestment") {
            clonedState.account.mortgage.propertydetails.isinvestment = evt.target.value;
        }
        else if (evt.target.name === "yearbuilt") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 4)
                clonedState.account.mortgage.propertydetails.yearbuilt = evt.target.value;
        }
        else if (evt.target.name === "yearpurchased") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 4)
                clonedState.account.mortgage.propertydetails.yearpurchased = evt.target.value;
        }
        else if (evt.target.name === "floors") {
            if (evt.target.value === "" || numericFormat.test(evt.target.value) && evt.target.value.length <= 3)
                clonedState.account.mortgage.propertydetails.floors = evt.target.value;
        }
        else if (evt.target.name === "purchaseprice") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displaypurchaseprice = evt.target.value;
        }
        else if (evt.target.name === "downpayment") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displaydownpayment = evt.target.value;
        }
        else if (evt.target.name === "outstandingbalance") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displayoutstandingbalance = evt.target.value;
        }
        else if (evt.target.name === "nextpaymentamount") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displaynextpaymentamount = evt.target.value;
        }
        else if (evt.target.name === "interestrate") {
            if (evt.target.value === "" || interestRateFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.mortgage.loaninfo.interestrate = evt.target.value;
        }
        else if (evt.target.name === "periodicprincipalpayment") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.mortgage.loaninfo.periodicprincipalpayment = evt.target.value;
        }
        else if (evt.target.name === "periodicinterestpayment") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.account.mortgage.loaninfo.periodicinterestpayment = evt.target.value;
        } else if (evt.target.name === "nickname" && evt.target.value.length <= 50 && (evt.target.value === "" || alphanumericnameFormat.test(evt.target.value))) {

            clonedState.account.info.nickname = evt.target.value;
        } else if (evt.target.name === "sizeofland" && evt.target.value.length <= 10 && (evt.target.value === "" || numericFormat.test(evt.target.value))) {
            clonedState.account.mortgage.propertydetails.sizeofland = evt.target.value;

        } else if (evt.target.name === "loanterm") {
            if (evt.target.value === "" || alphabitFormat.test(evt.target.value) && evt.target.value.length <= 50)
                clonedState.account.mortgage.loaninfo.loanterm = evt.target.value;
        } else if (evt.target.name === "nextpaymentamount") {
            if (evt.target.value === "" || amountFormat.test(evt.target.value) && evt.target.value.length <= 12)
                clonedState.displaynextpaymentamount = evt.target.value;
        }
        this.setState(clonedState);
        this.checkStateChange();
    }

    handleAmountFormatting(evt) {
        if (evt.target.value !== "") {
            let clonedState = Object.assign({}, this.state);

            if (evt.target.name === "value") {
                clonedState.account.value.totalvalue = evt.target.value;
                clonedState.displayvalue = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "purchaseprice") {
                clonedState.account.mortgage.loaninfo.purchaseprice = evt.target.value;
                clonedState.displaypurchaseprice = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "downpayment") {

                clonedState.account.mortgage.loaninfo.downpayment = evt.target.value;
                clonedState.displaydownpayment = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "outstandingbalance") {

                clonedState.account.mortgage.loaninfo.outstandingbalance = evt.target.value;
                clonedState.displayoutstandingbalance = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "nextpaymentamount") {

                clonedState.account.mortgage.loaninfo.nextpaymentamount = evt.target.value;
                clonedState.displaynextpaymentamount = parseFloat(evt.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            else if (evt.target.name === "liveablearea") {

                clonedState.account.mortgage.propertydetails.liveablearea = evt.target.value;
                clonedState.displayliveablearea = roundUnitValue(parseFloat(evt.target.value), false, true);
            }
            this.setState(clonedState);
        } else {
            let clonedState = Object.assign({}, this.state);
            if (evt.target.name === "value") {
                clonedState.account.value.totalvalue = evt.target.value;

            }
            else if (evt.target.name === "purchaseprice") {
                clonedState.account.mortgage.loaninfo.purchaseprice = evt.target.value;

            }
            else if (evt.target.name === "downpayment") {

                clonedState.account.mortgage.loaninfo.downpayment = evt.target.value;

            }
            else if (evt.target.name === "outstandingbalance") {

                clonedState.account.mortgage.loaninfo.outstandingbalance = evt.target.value;

            } else if (evt.target.name === "nextpaymentamount") {

                clonedState.account.mortgage.loaninfo.nextpaymentamount = evt.target.value;

            }
            else if (evt.target.name === "liveablearea") {

                clonedState.account.mortgage.propertydetails.liveablearea = evt.target.value;
            }
            this.setState(clonedState);
            this.checkStateChange();
        }
    }

    handleAmmountOnFocus(evt) {

        let clonedState = Object.assign({}, this.state);
        if (evt.target.name === "value") {
            if (clonedState.displayvalue === "") {
                clonedState.account.value.totalvalue = "";
                clonedState.displayvalue = "";
            } else {
                clonedState.displayvalue = clonedState.account.value.totalvalue
            }
        }
        else if (evt.target.name === "purchaseprice") {
            if (clonedState.displaypurchaseprice === "") {
                clonedState.account.mortgage.loaninfo.purchaseprice = "";
                clonedState.displaypurchaseprice = "";
            } else {
                clonedState.displaypurchaseprice = clonedState.account.mortgage.loaninfo.purchaseprice;
            }
        }
        else if (evt.target.name === "downpayment") {
            if (clonedState.displaydownpayment === "") {
                clonedState.account.mortgage.loaninfo.downpayment = "";
                clonedState.displaydownpayment = "";
            } else {
                clonedState.displaydownpayment = clonedState.account.mortgage.loaninfo.downpayment;
            }
        }
        else if (evt.target.name === "outstandingbalance") {
            if (clonedState.displayoutstandingbalance === "") {
                clonedState.account.mortgage.loaninfo.outstandingbalance = "";
                clonedState.displayoutstandingbalance = "";
            } else {
                clonedState.displayoutstandingbalance = clonedState.account.mortgage.loaninfo.outstandingbalance;
            }
        }
        else if (evt.target.name === "nextpaymentamount") {
            if (clonedState.displaynextpaymentamount === "") {
                clonedState.account.mortgage.loaninfo.nextpaymentamount = "";
                clonedState.displaynextpaymentamount = "";
            } else {
                clonedState.displaynextpaymentamount = clonedState.account.mortgage.loaninfo.nextpaymentamount;
            }
        }
        else if (evt.target.name === "liveablearea") {
            if (clonedState.displayliveablearea === "") {
                clonedState.account.mortgage.propertydetails.liveablearea = "";
                clonedState.displayliveablearea = "";
            } else {
                clonedState.displayliveablearea = clonedState.account.mortgage.propertydetails.liveablearea;
            }

        }

        this.setState(clonedState);
    }
    render() {
        return (
            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container" id="RCM_mortgage_form">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' }, { linkactive: this.state.activeTab === '1' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('1'); }} id="RCM_basic_info"
                        >
                            STEP 1 : BASIC INFORMATION
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' }, { linkactive: this.state.activeTab === '2' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('2'); }} id="RCM_propertyAddress_info"
                        >
                            STEP 2 : PROPERTY ADDRESS
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' }, { linkactive: this.state.activeTab === '3' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('3'); }} id="RCM_propertydetails_info"
                        >
                            STEP 3 : PROPERTY DETAILS
                     </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '4' }, { linkactive: this.state.activeTab === '4' }) + " RCM_manage_asset_navlink"}
                            onClick={() => { this.toggle('4'); }} id="RCM_loan_info"
                        >
                            STEP 4 : LOAN INFORMATION
                     </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab} className="RCM_asset_form_container">

                    <TabPane tabId="1">


                        <div>
                            <Form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridloannumber" id="RCM_loannumber">
                                                <Label for="loannumber">Loan No.</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="loannumber"
                                                    className="RCM_form_control"
                                                    required
                                                    maxLength="20"
                                                    value={this.state.account.info.accountnumber}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_nickname">
                                                <Label for="nickname">Nickname</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="nickname"
                                                    className="RCM_form_control"
                                                    required
                                                    value={this.state.account.info.nickname}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_bankname">
                                                <Label for="bankname">Bank Name</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="originator"
                                                    className="RCM_form_control"
                                                    required
                                                    maxLength="50"
                                                    value={this.state.account.info.originator}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity" id="RCM_outstandingbalance">
                                                <Label>Outstanding Balance</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="outstandingbalance"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displayoutstandingbalance}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity" id="RCM_totalvalue">
                                                <Label>Property Value</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="value"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.displayvalue}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                    disabled={(this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== '')
                                                        || (this.state.account.canedit !== undefined
                                                            && this.state.account.canedit !== null
                                                            && this.state.account.canedit === 'N')}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value" id="RCM_valueasof">
                                                <div>  <Label for="valueasof" >Value as of (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="valueasof" id="valueasof"
                                                    value={new Date(this.state.account.value.valueasof)}
                                                    onChange={(value) => this.handleDateChange(value, 'valueasof')}
                                                    selected={(this.state.account.value.valueasof !== undefined && this.state.account.value.valueasof !== null
                                                        && this.state.account.value.valueasof !== '') ? new Date(this.state.account.value.valueasof) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={(this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== '') ||
                                                        (this.state.account.canedit !== undefined
                                                            && this.state.account.canedit !== null
                                                            && this.state.account.canedit === 'N')}
                                                />
                                            </FormGroup>

                                        </div>

                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" id="RCM_decs">
                                            <div><Label for="desc" >Description</Label></div>
                                            <textarea
                                                name="desc"
                                                placeholder=""
                                                className="RCM_form_control RCM_Feedback_TextArea"
                                                value={this.state.account.info.description}
                                                onChange={this.handleFormChanges}
                                                required
                                                disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                            />
                                        </div>
                                    </div>

                                    {
                                        this.state.account.mortgage.realestateaccountkey
                                            && this.state.account.mortgage.realestateaccountkey !== null
                                            && this.state.account.mortgage.realestateaccountkey !== '' ?
                                            <div className="row">
                                                <div className="RCM_icon_hand  RCM_manage_grp_new_group"
                                                    onClick={() => { this.props.openLinkedAccount(this.state.account.mortgage.realestateaccountkey) }}
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                                    Click here to edit the linked real estate asset.
                                            </div>
                                            </div>
                                            : ''
                                    }
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                        >
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>NEXT</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </TabPane>
                    <TabPane tabId="2">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                            <FormGroup controlId="formGridAddress1">
                                                <Label for="address1">Address Line 1</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="address1"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.line1}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                            <FormGroup controlId="formGridAddress2">
                                                <Label>Address Line 2</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="address2"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.line2}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>City</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="city"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.city}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                />

                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>State</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="state"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.state}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                // ref={ele => { groupNameInput = ele }}
                                                />

                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Zip</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="zip"
                                                    className="RCM_form_control"
                                                    maxLength="10"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.zip}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                //ref={ele => { groupNameInput = ele }}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>County</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="county"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.county}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                // ref={ele => { groupNameInput = ele }}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>Country</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="country"
                                                    className="RCM_form_control"
                                                    maxLength="50"
                                                    required
                                                    value={this.state.account.mortgage.propertyaddress.country}
                                                    onChange={this.handleFormChanges}
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                // ref={ele => { groupNameInput = ele }}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('1'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>NEXT</button>
                                    </div>
                                </div>
                                {this.state.account.mortgage.realestateaccountkey
                                    && this.state.account.mortgage.realestateaccountkey !== null
                                    && this.state.account.mortgage.realestateaccountkey !== ''
                                    ? <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                            <label className="RCM_Customization_text RCM_Customization_text_small_font"><span></span> These values can be updated from linked real estate asset.</label>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div>
                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>Property Type</Label>
                                                <Dropdown name="typedropdown" required
                                                    isOpen={this.state.istypeopen}
                                                    toggle={this.typetoggle}
                                                    className={"RCM_form_control RCM_select_box RCM_mm_select_box" +
                                                        (this.state.account.mortgage.realestateaccountkey
                                                            && this.state.account.mortgage.realestateaccountkey !== null
                                                            && this.state.account.mortgage.realestateaccountkey !== ''
                                                            ? ' RCM_form_control_disabled' : ' ')
                                                    }
                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                        && this.state.account.mortgage.realestateaccountkey !== ''}>
                                                    <DropdownToggle caret>{this.state.account.mortgage.propertydetails.type}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.typeoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.typechange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    {this.state.account.mortgage.propertydetails.type !== 'Land' && this.state.account.mortgage.propertydetails.type !== '' ?
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridCity">
                                                    <Label>Square footage</Label>

                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="liveablearea"
                                                        className="RCM_form_control"
                                                        maxLength="12"
                                                        required
                                                        value={this.state.displayliveablearea}
                                                        onChange={this.handleFormChanges}
                                                        onBlur={this.handleAmountFormatting}
                                                        onFocus={this.handleAmmountOnFocus}
                                                        disabled={this.state.account.mortgage.realestateaccountkey
                                                            && this.state.account.mortgage.realestateaccountkey !== null
                                                            && this.state.account.mortgage.realestateaccountkey !== ''}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <Label>No. Of Floors</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="floors"
                                                        className="RCM_form_control"
                                                        maxLength="3"
                                                        required
                                                        value={this.state.account.mortgage.propertydetails.floors}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.mortgage.realestateaccountkey
                                                            && this.state.account.mortgage.realestateaccountkey !== null
                                                            && this.state.account.mortgage.realestateaccountkey !== ''}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {this.state.account.mortgage.propertydetails.type !== 'Commercial' ?
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridZip">
                                                        <Label>No. Of Bedrooms</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="bedrooms"
                                                            className="RCM_form_control"
                                                            maxLength="2"
                                                            required
                                                            value={this.state.account.mortgage.propertydetails.bedrooms}
                                                            onChange={this.handleFormChanges}
                                                            disabled={this.state.account.mortgage.realestateaccountkey
                                                                && this.state.account.mortgage.realestateaccountkey !== null
                                                                && this.state.account.mortgage.realestateaccountkey !== ''}
                                                        />

                                                    </FormGroup>
                                                </div>
                                                : <div></div>}
                                            {this.state.account.mortgage.propertydetails.type !== 'Commercial' ?
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridCity">
                                                        <Label>No. Of Bathrooms</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="bathrooms"
                                                            className="RCM_form_control"
                                                            maxLength="2"
                                                            required
                                                            value={this.state.account.mortgage.propertydetails.bathrooms}
                                                            onChange={this.handleFormChanges}
                                                            disabled={this.state.account.mortgage.realestateaccountkey
                                                                && this.state.account.mortgage.realestateaccountkey !== null
                                                                && this.state.account.mortgage.realestateaccountkey !== ''}
                                                        />

                                                    </FormGroup>
                                                </div>
                                                : <div></div>}
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <Label>Year Built</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="yearbuilt"
                                                        className="RCM_form_control"
                                                        maxLength="4"
                                                        required
                                                        value={this.state.account.mortgage.propertydetails.yearbuilt}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.mortgage.realestateaccountkey
                                                            && this.state.account.mortgage.realestateaccountkey !== null
                                                            && this.state.account.mortgage.realestateaccountkey !== ''}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <Label>Year Purchased</Label>
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        name="yearpurchased"
                                                        className="RCM_form_control"
                                                        maxLength="4"
                                                        required
                                                        value={this.state.account.mortgage.propertydetails.yearpurchased}
                                                        onChange={this.handleFormChanges}
                                                        disabled={this.state.account.mortgage.realestateaccountkey
                                                            && this.state.account.mortgage.realestateaccountkey !== null
                                                            && this.state.account.mortgage.realestateaccountkey !== ''}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {this.state.account.mortgage.propertydetails.type !== 'Commercial' ?
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridState">
                                                        <div><label className="RCM_selectLabel">
                                                            Is Primary Residence
                                    </label></div>
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio"
                                                                    value="yes"
                                                                    checked={this.state.account.mortgage.propertydetails.isprimaryresidence === 'yes'}
                                                                    name="isprimaryresidence"
                                                                    onChange={this.handleFormChanges}
                                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                                    className={this.state.account.mortgage.realestateaccountkey
                                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                                        && this.state.account.mortgage.realestateaccountkey !== ''
                                                                        ? ' RCM_form_control_disabled' : ' '
                                                                    }
                                                                />
                                                                <span className="RCM_radio_span">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label className="RCM_icon_hand">
                                                                <input type="radio"
                                                                    value="no"
                                                                    name="isprimaryresidence"
                                                                    checked={this.state.account.mortgage.propertydetails.isprimaryresidence === 'no'}
                                                                    onChange={this.handleFormChanges}
                                                                    disabled={this.state.account.mortgage.realestateaccountkey
                                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                                        && this.state.account.mortgage.realestateaccountkey !== ''}
                                                                    className={this.state.account.mortgage.realestateaccountkey
                                                                        && this.state.account.mortgage.realestateaccountkey !== null
                                                                        && this.state.account.mortgage.realestateaccountkey !== ''
                                                                        ? ' RCM_form_control_disabled' : ' '
                                                                    } />
                                                                <span className="RCM_radio_span">No</span>
                                                            </label>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                                : <div></div>}
                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridState">
                                                    <div><label className="RCM_selectLabel">
                                                        Is Investment
                                    </label></div>
                                                    <div>
                                                        <label className="RCM_icon_hand">
                                                            <input
                                                                type="radio"
                                                                value="yes"
                                                                name="isinvestment"
                                                                checked={this.state.account.mortgage.propertydetails.isinvestment === 'yes'}
                                                                onChange={this.handleFormChanges}
                                                                disabled={this.state.account.mortgage.realestateaccountkey
                                                                    && this.state.account.mortgage.realestateaccountkey !== null
                                                                    && this.state.account.mortgage.realestateaccountkey !== ''}
                                                                className={this.state.account.mortgage.realestateaccountkey
                                                                    && this.state.account.mortgage.realestateaccountkey !== null
                                                                    && this.state.account.mortgage.realestateaccountkey !== ''
                                                                    ? ' RCM_form_control_disabled' : ' '
                                                                } />
                                                            <span className="RCM_radio_span">Yes</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="RCM_icon_hand">
                                                            <input
                                                                type="radio"
                                                                value="no"
                                                                name="isinvestment"
                                                                checked={this.state.account.mortgage.propertydetails.isinvestment === 'no'}
                                                                onChange={this.handleFormChanges}
                                                                disabled={this.state.account.mortgage.realestateaccountkey
                                                                    && this.state.account.mortgage.realestateaccountkey !== null
                                                                    && this.state.account.mortgage.realestateaccountkey !== ''}
                                                                className={this.state.account.mortgage.realestateaccountkey
                                                                    && this.state.account.mortgage.realestateaccountkey !== null
                                                                    && this.state.account.mortgage.realestateaccountkey !== ''
                                                                    ? ' RCM_form_control_disabled' : ' '
                                                                } />
                                                            <span className="RCM_radio_span">No</span>
                                                        </label>
                                                    </div>
                                                </FormGroup>
                                            </div>

                                        </div> :
                                        this.state.account.mortgage.propertydetails.type === 'Land' ?
                                            <div className="row">
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridState">
                                                        <Label>Size of Land</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="sizeofland"
                                                            className="RCM_form_control"
                                                            maxLength="10"
                                                            required
                                                            value={this.state.account.mortgage.propertydetails.sizeofland}
                                                            onChange={this.handleFormChanges}
                                                            disabled={this.state.account.mortgage.realestateaccountkey
                                                                && this.state.account.mortgage.realestateaccountkey !== null
                                                                && this.state.account.mortgage.realestateaccountkey !== ''}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                                    <FormGroup controlId="formGridState">
                                                        <Label>Year Purchased</Label>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            name="yearpurchased"
                                                            className="RCM_form_control"
                                                            maxLength="4"
                                                            required
                                                            value={this.state.account.mortgage.propertydetails.yearpurchased}
                                                            onChange={this.handleFormChanges}
                                                            disabled={this.state.account.mortgage.realestateaccountkey
                                                                && this.state.account.mortgage.realestateaccountkey !== null
                                                                && this.state.account.mortgage.realestateaccountkey !== ''}
                                                        // ref={ele => { groupNameInput = ele }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                            </div> : <div></div>
                                    }
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('2'); }}>PREVIOUS</button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('4'); }}>NEXT</button>
                                    </div>
                                </div>
                                {this.state.account.mortgage.realestateaccountkey
                                    && this.state.account.mortgage.realestateaccountkey !== null
                                    && this.state.account.mortgage.realestateaccountkey !== ''
                                    ? <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                            <label className="RCM_Customization_text RCM_Customization_text_small_font"><span></span>These values can be updated from linked real estate asset.</label>
                                        </div>
                                    </div>
                                    : ''
                                }

                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div>

                            <Form>
                                <fieldset disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>Purchase Price</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="purchaseprice"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displaypurchaseprice}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>Down Payment</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="downpayment"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displaydownpayment}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridState">
                                                <Label>Interest Rate</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="interestrate"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.account.mortgage.loaninfo.interestrate}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Interest Type</Label>
                                                <Dropdown name="interesttypedropdown" required
                                                    isOpen={this.state.isinteresttypeopen}
                                                    toggle={this.interesttypetoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.mortgage.loaninfo.interesttype}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.interesttypeoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.interesttypechange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridZip">
                                                <Label>Payment Frequency</Label>
                                                <Dropdown name="paymentfrequencydropdown" required
                                                    isOpen={this.state.ispaymentfrequencyopen}
                                                    toggle={this.paymentfrequencytoggle}
                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                    <DropdownToggle caret>{this.state.account.mortgage.loaninfo.paymentfrequency}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <div>
                                                            {this.state.paymentfrequencyoptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => this.paymentfrequencychange(item)}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <div>  <Label for="maturitydate" >Maturity Date (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="maturitydate" id="maturitydate"
                                                    value={new Date(this.state.account.mortgage.loaninfo.maturitydate)}
                                                    onChange={(value) => this.handleDateChange(value, 'maturitydate')}
                                                    selected={(this.state.account.mortgage.loaninfo.maturitydate !== undefined && this.state.account.mortgage.loaninfo.maturitydate !== null
                                                        && this.state.account.mortgage.loaninfo.maturitydate !== '') ? new Date(this.state.account.mortgage.loaninfo.maturitydate) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="loanterm">
                                                <Label>Loan Term</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="loanterm"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.account.mortgage.loaninfo.loanterm}
                                                    onChange={this.handleFormChanges}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">
                                                <div>  <Label for="nextpaymentdue" >Next Payment Due (mm/dd/yyyy)</Label></div>
                                                <DatePicker required className="RCM_externalasset_Datepicker RCM_form_control" required
                                                    name="nextpaymentdue" id="nextpaymentdue"
                                                    value={new Date(this.state.account.mortgage.loaninfo.nextpaymentdue)}
                                                    onChange={(value) => this.handleDateChange(value, 'nextpaymentdue')}
                                                    selected={(this.state.account.mortgage.loaninfo.nextpaymentdue !== undefined && this.state.account.mortgage.loaninfo.nextpaymentdue !== null
                                                        && this.state.account.mortgage.loaninfo.nextpaymentdue !== '') ? new Date(this.state.account.mortgage.loaninfo.nextpaymentdue) : ''}
                                                    maxDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                    disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridCity">
                                                <Label>Next Payment Amount</Label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="$"
                                                    name="nextpaymentamount"
                                                    className="RCM_form_control"
                                                    maxLength="12"
                                                    required
                                                    value={this.state.displaynextpaymentamount}
                                                    onChange={this.handleFormChanges}
                                                    onBlur={this.handleAmountFormatting}
                                                    onFocus={this.handleAmmountOnFocus}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                        <button type="button" className="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={this.props.cancel}>CANCEL</button>
                                        <button type="button"
                                            className="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                            onClick={this.saveAsset}
                                            disabled={this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'}>
                                            SUBMIT
                                        </button>
                                        <button type="button" className="btn btn-primary RCM_button_primary RCM_manageAsset_btn" onClick={() => { this.toggle('3'); }}>PREVIOUS</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    {this.state.account.canedit !== undefined && this.state.account.canedit !== null && this.state.account.canedit === 'N'
                        ?
                        < div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_view_only_Text">
                                {viewOnlyText}
                            </div>
                        </div>
                        : ""
                    }
                </TabContent>
            </div>

        );

    }
}
function mapStateToProps(store) {
    return {
        accounts: store.acct.accounts,
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId
    };
};

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Mortgage);




