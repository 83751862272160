
export const AuthenticationMethodTypes = {
    mobile: {
        displayName: 'Phone'
    },
    alternateMobile: {
        displayName: 'Alternate Phone'
    },
    office: {
        displayName: 'Office'
    },

}

export const MaskPhoneNumber = (phoneNumber) => {
    if(phoneNumber && phoneNumber.length > 4) {
        return `XXX-XXX-${phoneNumber.substring((phoneNumber.length-4), phoneNumber.length)}`
    }
    return phoneNumber
}

export const rectifyPhoneNumber = (phoneNumber) => {
    var rectifiedPhone = ''
    if(phoneNumber && phoneNumber.length === 10) {
      rectifiedPhone = `+1${phoneNumber}`
    } else if(phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith('011')) {
        rectifiedPhone =  `+${phoneNumber.slice(3)}`
    }
    return rectifiedPhone;
  }