import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { spinner, MsgForNoData, MsgForFailedData, getYearList } from './../../../../../common/constants.jsx';
import { securityColumns, monthDropDownItems, yearDropDownItems, GetSecurityColumns } from '../estimatedincomecolumns';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Tooltip } from 'reactstrap';
import ProjectedIncomeChartDetails from './../../../../../common/estimatedIncomeChartDetails';
import Messages from '../../../../../common/messages';
import Watermark from '../../../../../common/watermark';
export function ProjectedIncomeSecurityView(
    props
) {
    let ProjectedIncomeSecurityView = (
        <div className="conatiner-fluid RCM_Toast" key="secdetails1">
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="row RCM_AccountActivityDetails_Message_Div" >
                <div className="col-sm-7">
                    <Messages messages={props && props.state && props.state.messages} containerClass="RCM_validation_error_container" />
                </div>
            </div>
            <div className="row RCM_text_hd">
                <div className="col-sm-4" id="RCM_estimatedincomedetailsfilter">
                    <div className="RCM_text RCM_CM_form_label RCM_text_textdropdown RCM_Inline_Div">
                        <label>YEAR :</label>
                    </div>
                    <div className="RCM_Inline_Div">
                        <Dropdown
                            isOpen={props.state.yearDropdownOpen}
                            toggle={props.toggleYear}
                            className="RCM_select_box RCM_mm_subselect_box dropdown"
                            key="addropdown1">
                            <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                {
                                    props.state.dropdownyearValue
                                }
                            </DropdownToggle>
                            <DropdownMenu >
                                {getYearList('2018').map((item, index) =>
                                    <DropdownItem key={index} onClick={() => props.changeYear(item)} disabled={props.state.isProspectClient}>
                                        <div>{item.label}</div>
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-sm-8"></div>
            </div>
            <div className="row" id="RCM_estimatedincomesecurityview">
                <div className="col-sm-12 col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    {
                        (() => {
                            if (props.state.requestFailed) {
                                return (
                                    <div className="row" id="RCM_SecurityHoldingTable">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Realized_SecurityHolding_Wrapper RCM_Tables">
                                            <BootstrapTable key='btsecurity'
                                                keyField='UNQID'
                                                data={[]}
                                                classes="RCM_two_level_table1"
                                                columns={GetSecurityColumns()}
                                                noDataIndication={MsgForFailedData}
                                                expandRow={props.expandRowBySecurity}
                                                bordered={false}
                                            /></div></div>)

                            }
                            else if (props.state.isloading) {
                                return <div className="RCM_spinner">Loading Historical Income by Security{spinner()}</div>
                            }
                            else {
                                if (props.state.holdingsresponsebysecurity && props.state.holdingsresponsebysecurity.length > 0 && !props.state.isloading) {
                                    return (
                                        <div className="row" id="RCM_SecurityHoldingTable">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Realized_SecurityHolding_Wrapper RCM_Tables"><BootstrapTable key='btsecurity'
                                                keyField='UNQID'
                                                data={props.securityData}
                                                classes="RCM_two_level_table1"
                                                columns={GetSecurityColumns(props.totalordinarydividend, props.totaltaxableinterest, props.totalnetincome, props.totalcapitalgainloss, props.securityData, props.state.isrowexpanded, props.rowsortevent)}
                                                noDataIndication={MsgForNoData}
                                                expandRow={props.expandRowBySecurity}
                                                bordered={false}
                                            /></div></div>)
                                }
                                else {
                                    return (
                                        <div className="row" id="RCM_SecurityHoldingTable">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_Realized_SecurityHolding_Wrapper RCM_Tables"> <BootstrapTable key='btsecurity'
                                                keyField='UNQID'
                                                data={props.securityData}
                                                classes="RCM_two_level_table1"
                                                columns={GetSecurityColumns(props.totalordinarydividend, props.totaltaxableinterest, props.totalnetincome, props.totalcapitalgainloss, props.securityData, props.state.isrowexpanded, props.rowsortevent)}
                                                noDataIndication={MsgForNoData}
                                                expandRow={props.expandRowBySecurity}
                                                bordered={false}
                                            /></div></div>)

                                }
                            }
                        })() 
                    }
                </div>
                </div>
        </div>
    )
    return ProjectedIncomeSecurityView;
}
