import React from 'react';
export function getReportsColumns(downloadReport) {
    const reportcolumns = [
        {
            dataField: "id",
            text: "",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            hidden: true
        },
        {
            dataField: "name",
            text: "Report Name",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },

        {

            dataField: "date",
            text: "Date",
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell) => {
                if (cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
        },
        {
            dataField: '',
            text: '',
            classes: 'RCM_stmt_btn_container',
            formatter: (cell, row) => (
                <div className="row">
                    <a className="RCM_Cursor_Pointer" title="Print report" onClick={(ev) => { ev.stopPropagation(); downloadReport(row, 'print') }}>
                        <div className={"RCM_Documents_btn_img RCM_userMenu_Print"} ></div>
                    </a>
                    <a className="RCM_Cursor_Pointer" onClick={(ev) => { ev.stopPropagation(); downloadReport(row, 'download') }} title="Download" >
                        <div className={"RCM_Documents_btn_img RCM_Download"}></div>
                    </a>

                </div>
            )
        }

    ];
    return reportcolumns;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}



