import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { ManageAssetColumns } from './manageassetcolumns.jsx';
import { MsgForNoData } from '../../../../common/constants.jsx';
import { MADeleteModalPopup } from './madeletemodalpopup.jsx';
import { Link } from 'react-router-dom';

export function ManageAssetsDetailsView(
    props
) {
   
    let manageassetsdetailsview = (
        <div>
            {props ?
               
                     <div className="row">
                    <div className="col-sm-12">
                        <div className="RCM_Toast RCM_Document_Toast">
                            <div className="row RCM_backLink_container">
                                <div className="col-sm-1 RCM_backLink">
                                    <Link to='AssetAggregation'>
                                        <div className="RCM_icon_hand  RCM_manage_grp_new_group" id="RCM_manageAsset_back_link">Back</div>
                                        
                                    </Link>
                                </div>
                            </div>
                            <div id="RCM_ManageAsset_Table" className="RCM_ManageGroup_Tables RCM_CM_scroll RCM_ManageAsset_Table">
                                    <BootstrapTable
                                        keyField='AssetType'
                                        data={props.data}
                                        columns={ManageAssetColumns(props.openAddEdittoggle, props.openAssetDeletePopup, props.edit)}
                                        headerClasses="row-bg"
                                        bordered={false}
                                        classes="RCM_two_level_table1"
                                        noDataIndication={MsgForNoData}
                                    />
                                </div>
                        </div>
                        </div>
                        </div>
                : <div></div>
            }
            <MADeleteModalPopup
                state={props.state}
                toggleDeleteModal={props.toggleDeletePopup}
                deleteAsset={props.deleteAsset}
                handleDeleteLinkedAccount={props.handleDeleteLinkedAccount}
            />
        </div>
    )
    return manageassetsdetailsview
}



