const DateRanges = {

    PRIORMONTH: {
        label: 'Prior Month',
        value: 'PriorMonth'
    },
    PRIOR3MONTHS: {
        label: 'Prior 3 Months',
        value: 'Prior3Months'
    },
    PRIOR6MONTHS: {
        label: 'Prior 6 Months',
        value: 'Prior6Months'
    },
    PRIOR12MONTHS: {
        label: 'Prior 12 Months',
        value: 'Prior12Months'
    },
    CURRENTYEAR: {
        label: 'Current Year',
        value: 'CurrentYear'
    },
    PRIORYEAR: {
        label: 'Prior Year',
        value: 'PriorYear'
    },
    CUSTOMRANGE: {
        label: 'Custom Range',
        value: 'CustomRange'
    },
    YESTERDAY: {
        label: 'Yesterday',
        value: 'Yesterday'
    },
    LAST5DAYS: {
        label: 'Last 5 Days',
        value: 'Last5Days'
    },
    LAST7DAYS: {
        label: 'Last 7 Days',
        value: 'Last7Days'
    },
    LAST30DAYS: {
        label: 'Last 30 Days',
        value: 'Last30Days'
    },
    LAST90DAYS: {
        label: 'Last 90 Days',
        value: 'Last90Days'
    },
    YEARTODATE: {
        label: 'Year to Date',
        value: 'YeartoDate'
    },


};
export default DateRanges