import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ManageAssetsDetailsView } from './manageassetsdetailsview.jsx';
import { getAssetListService } from './manageassetsservice.jsx';
import { deleteExternalAccount } from '../assetaggregation/assetaggregationservice.jsx';
import { spinner } from '../../../../common/constants.jsx';
import { addManualAsset } from './../../../../actions/accountsactions';
class ManageAssetsDetails extends Component {

    constructor(props) {
        super(props);
        this.toggleDeletePopup = this.toggleDeletePopup.bind(this);
        this.getAssetList = this.getAssetList.bind(this);
        this.edit = this.edit.bind(this);
        this.deleteAsset = this.deleteAsset.bind(this);
        this.openAssetDeletePopup = this.openAssetDeletePopup.bind(this);

        this.requesterId = "";
        if (this.props.portalid !== null && this.props.portalid !== undefined) {
            this.requesterId = this.props.portalid;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if (this.props.profilejson !== null && this.props.profilejson !== undefined) {
            this.profilejson = this.props.profilejson;
        }
        this.state = {
            typeOfAsset: {},
            formComponent: "",
            isAddModalOpen: false,
            viewformcomponent: "",
            selectedAsset: [],
            openDeletePopup: false,
            selectedRow: {},
            profileId: props.profileId,
            manageAssetList: [],
            assetToDelete: {},
            loadingManageAsset: true,
            deleteLinkedAccount: false

        };
    }
    componentDidMount() {
        this.getAssetList();
    }
    edit(row) {
        this.props.edit(row.account);
    }
    deleteAsset() {
        if (this.state.assetToDelete !== {})   
        {
            let deleteAccountKey = '';
            if (this.state.deleteLinkedAccount) {
                deleteAccountKey = this.state.assetToDelete.account.mortgage && this.state.assetToDelete.account.mortgage !== null
                    && this.state.assetToDelete.account.mortgage.realestateaccountkey && this.state.assetToDelete.account.mortgage.realestateaccountkey !== null
                    && this.state.assetToDelete.account.mortgage.realestateaccountkey !== ''
                    ? this.state.assetToDelete.account.mortgage.realestateaccountkey
                    : (this.state.assetToDelete.account.realestate && this.state.assetToDelete.account.realestate !== null
                    && this.state.assetToDelete.account.realestate.mortgageinformation && this.state.assetToDelete.account.realestate.mortgageinformation !== null
                    && this.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey
                    && this.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey !== null
                        && this.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey !== '')
                        ? this.state.assetToDelete.account.realestate.mortgageinformation.mortgageaccountkey
                        :''
            }
            let requestJson = {
                key: this.state.assetToDelete.account.accountkey,
                deletelinkedaccount: this.state.deleteLinkedAccount,
                deletelinkedaccountkey: deleteAccountKey
            };
            //call jiajin's api to make sure its removing the account from account table
            deleteExternalAccount(requestJson, this.authToken, this.contextjson, this.profilejson, )
                .then(data => {
                this.props.updateProfileDataInStore('DELETE_ACCOUNT_IN_MANUAL_ASSET', this.state.assetToDelete.account.accountkey);
                this.setState(prevState => ({
                    openDeletePopup: !prevState.openDeletePopup, assetToDelete: {}, deleteLinkedAccount: false
                }))
                this.getAssetList();
            })
            .catch(error => {
                console.error(error);
                this.setState(prevState => ({
                    openDeletePopup: !prevState.openDeletePopup, assetToDelete: {}, deleteLinkedAccount: false
                }))
            }) 
        }
    }
    getAssetList() {
       
        getAssetListService(this.authToken, this.contextjson, this.profilejson, this.state.profileId )
            .then(manageAssetData => {
                if (manageAssetData) {
                    this.props.addManualAsset(manageAssetData.manualassetdetails && manageAssetData.manualassetdetails.accounts);
                    this.setState({ manageAssetList: manageAssetData.manualassetdetails.accounts, loadingManageAsset: false });
                } else {
                    this.props.addManualAsset([]);
                    this.setState({ manageAssetList: [], loadingManageAsset: false });
                }
                    
            })
            .catch(error => {
                console.error(error);
                this.setState({ manageAssetList: [], loadingManageAsset: false });
            })
    }

    toggleDeletePopup() {
        this.setState(prevState => ({
            openDeletePopup: !prevState.openDeletePopup
        }))
    }
    openAssetDeletePopup(row) {
        this.setState(prevState => ({
            openDeletePopup: !prevState.openDeletePopup, assetToDelete: row
        }))
    }
    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            this.setState({ manageAssetList: [], loadingManageAsset: true }, () => this.getAssetList());
        }
        else if (this.props.state.linkedAccount !== prevProps.state.linkedAccount && this.props.state.linkedAccount !== '') {
            this.findLinkedAccountForEdit(this.props.state.linkedAccount);
        }
    }
    findLinkedAccountForEdit = (key) => {
        let account = this.state.manageAssetList.find(item => item.key === key);
        this.props.edit(account);
    }
    handleDeleteLinkedAccount = () => {
        this.setState(prevState => ({
            deleteLinkedAccount: !prevState.deleteLinkedAccount
        }))
    }
    render() {
       
        var rowdata = [];
        if (this.state.manageAssetList && this.state.manageAssetList !== null && this.state.manageAssetList.length > 0) {
            rowdata = this.state.manageAssetList;
        }
        
        return (

            this.state.loadingManageAsset ? ( 
                <div align="center">
                    <div className="RCM_spinner">Loading Manual Asset{spinner()}</div>
                </div>
            ): 
            (
                <ManageAssetsDetailsView
                        state={this.state}
                        typeOfAssetToggle={this.typeOfAssetToggle}
                        onFromAssetTypeChange={this.onFromAssetTypeChange}
                        addModaltoggle={this.addModaltoggle}
                        data={rowdata}
                        openAddEdittoggle={this.props.openAddEdittoggle}
                        toggleDeletePopup={this.toggleDeletePopup}
                        edit={this.edit}
                        deleteAsset={this.deleteAsset}
                        openAssetDeletePopup={this.openAssetDeletePopup}
                        handleDeleteLinkedAccount={this.handleDeleteLinkedAccount}
                />
            )
        );
    }
}

function mapStateToProps(store) {
    return {
        portalid: store.acct.portalid,
        token: store.acct.token,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        profileId: store.acct.profileId,
        render: store.acct.render
    };
};

const mapDispatchToProps = {
    addManualAsset
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAssetsDetails);