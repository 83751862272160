import React from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PreferenceView } from './preferenceView.jsx'
import axios from 'axios';
import { Post, Get } from '../../../common/servicecalls';
import ErrorBoundary from '../../../common/errorboundary';
import { MessageType, MessageClass, ProfileUpdateUrl, getDisplayName, validateEmailId, toUTF8Array, CheckIfProspectClient} from '../../../common/constants.jsx';
import { fetchPersonalisationData } from "../../../actions/personalisationAction";
import { updatePreference } from "../../../actions/accountsactions"
import { withPageView } from '../../../hoc/withPageView.jsx';
import { getLoginId } from "../../../services/home/userServices.jsx";
import { addRemoveEmailServices } from './edeliveryservices.jsx';
import * as _ from 'lodash';
import Watermark from '../../../common/watermark';
import MockData from './prospectProfileMock.js'


export class Preference extends React.Component {

    constructor(props) {
        super(props);
        this.requesterId = "";
        if (this.props.profileId !== null && this.props.profileId !== undefined) {
            this.requesterId = this.props.profileId;
        }

        this.authToken = "";
        if (this.props.token !== null && this.props.token !== undefined) {
            this.authToken = this.props.token;
        }

        this.contextjson = null;
        if (this.props.contextjson !== null && this.props.contextjson !== undefined) {
            this.contextjson = this.props.contextjson;
        }
        this.state = {
            accountList: [],
            initialAccountList:[],
            dropdownOpen: false,
            error: '',
            modal: false,
            des_modal: false,
            editEmail: '',
            emailError: 'false',
            messages: [],
            isPreferencesUpdated: false,
            disabled: false,
            addModal: false,
            newEmail:'',
            disableAddEmail: true,
            emailList: [],
            accounts: [],
            isEdeliveryLoading: true,
            selectAllDocuments: false,
            confirmsProspectusesSelectAll: false,
            statementsAndRegulatoryInsertsSelectAll:false,
            eligibleCustomerCorrespondenceSelectAll:false,
            taxFormsAndRelatedDocumentsSelectAll:false,
            shareholderReportsAndOtherDocumentsSelectAll: false,
            updatedAccounts: [],
            sortOrderAccount: '',
            sortOrderEnrolled: '',
            isProspectClient: false,
            //isEmailUpdated: false,
            primaryEmail: '',
            selectedEmail: "",
            isConfirmEmailChangePopupOpen:false,
            contextjson: this.props.contextjson,
            profilejson: this.props.profilejson
        };
        this.handleConfirmsProspectus = this.handleConfirmsProspectus.bind(this);
        this.handleStatementsAndRegulatory = this.handleStatementsAndRegulatory.bind(this);
        this.handleEligibleCustomerCorrespondence = this.handleEligibleCustomerCorrespondence.bind(this);
        this.handleTaxFormRelated = this.handleTaxFormRelated.bind(this);
        this.handleShareHolderReport = this.handleShareHolderReport.bind(this);
        this.toggleDesModel = this.toggleDesModel.bind(this);
        this.changeEmailDropDownValue = this.changeEmailDropDownValue.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.isEdeliveryChanged = this.isEdeliveryChanged.bind(this);
        this.toggelAddModel = this.toggelAddModel.bind(this);
        this.handleEmailOnChange = this.handleEmailOnChange.bind(this);
        this.handleEmailOnBlur = this.handleEmailOnBlur.bind(this);
        this.addEmail = this.addEmail.bind(this);
        this.handleRemoveEmail = this.handleRemoveEmail.bind(this);
        this.handleSelectAllDocuments = this.handleSelectAllDocuments.bind(this);
        this.handleSelectAllColumnDocuments = this.handleSelectAllColumnDocuments.bind(this);
        this.checkAllSubDocumentsSelected = this.checkAllSubDocumentsSelected.bind(this);
        this.checkAllDocumentsSelected = this.checkAllDocumentsSelected.bind(this);
        this.onSort = this.onSort.bind(this);
        this.getSortClassName = this.getSortClassName.bind(this);
        this.toggleConfirmEmailChangePopup = this.toggleConfirmEmailChangePopup.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
    }

    componentDidMount() {
        if (CheckIfProspectClient(this.props.role)) {
            this.setState(prevState => ({
                ...prevState,
                isProspectClient: true
            }), () => { this.getMockProfile() });
        } else
            this.getProfile('init',null);
    }
    componentDidUpdate(prevProps) {
        //this.setState({ isEdeliveryLoading: true });
        if (this.props.render !== prevProps.render) {
            this.setState({ isEdeliveryLoading: true, messages:[] }, () => {
                if (this.state.isProspectClient)
                    this.getMockProfile();
                else
                    this.getProfile('init', null)
            });
           
        }

    }

    deleteDuplicateEmail(arr) {

        let result= [];
        if(arr && Array.isArray(arr) && arr.length > 0) {
           result= _.map(arr, (e) => {
                if(e){
                    return {...e, emailid: e.emailid ? e.emailid.toUpperCase() : ''}
                }
            })
          result = _.uniqBy(result, 'emailid')
        }
        return result;
    }
    cancellationToken = null;
    getProfile = (source, updatedAccounts) => {
        const profileUrl = ProfileUpdateUrl;
        
        if (this.cancellationToken !== undefined && this.cancellationToken !== null) {
            this.cancellationToken.cancel('Cancelling the existing get call request to prevent memory leaks.');
        }
        this.cancellationToken = axios.CancelToken.source();

        let userName = getLoginId();
        Get(`${profileUrl}('${userName},true')`, this.props.token, this.props.contextjson, this.props.profilejson, this.cancellationToken)
            .then(
                data => {
                    this.setState(prevState => ({...prevState, contextjson: data.headers.contextjson, profilejson: data.headers.profilejson}))
                    this.props.fetchPersonalisationData(data.data[0].profile);
                    if (data.data && data.data.length >= 1 && data.data[0] && data.data[0].profile) {
                        let profileData = data.data[0].profile;
                        let emailList = [];
                        if (profileData.edeliveryemail && profileData.edeliveryemail != null && profileData.edeliveryemail.length > 0) {
                            emailList = this.deleteDuplicateEmail([...profileData.edeliveryemail]);
                        }
                        
                        let accounts = JSON.parse(JSON.stringify(profileData.accounts));
                        let editEmail = this.state.editEmail;
                        let primaryEmail = this.state.primaryEmail;
                       
                        if (source !== 'updateCallBack' && profileData.emailprimary && profileData.emailprimary !== null && profileData.emailprimary !== "") {
                            if (profileData.edeliveryemail && profileData.edeliveryemail != null && profileData.edeliveryemail.length > 0) {
                                var indexOfEmail = -1;
                                emailList.findIndex(function (obj, i) {
                                    if (obj.emailid === profileData.emailprimary) {
                                        indexOfEmail = i;
                                    }
                                })
                                if (indexOfEmail > -1) {
                                    editEmail = emailList[indexOfEmail].emailid;
                                } else {
                                    editEmail = emailList[0].emailid;
                                }
                            }
                            else {
                                editEmail = '';
                            }
                            primaryEmail = profileData.emailprimary;
                        }
   
                        this.setState({ emailList: emailList, editEmail: editEmail, accounts: accounts, primaryEmail: primaryEmail }, () => {
                            if (source === 'updateCallBack')
                                this.getAccountList(updatedAccounts, source);
                            else
                                this.getAccountList(null, source)
                        });
                        
                    }
                })
            .catch(error => {
                console.log('making false - profile');
                console.log(error);
                this.setState({ error: error})
            });
    };
    getMockProfile = (updatedAccounts, source) => {
        //this.props.fetchPersonalisationData(data.data[0].profile);
        let profileData = MockData;
        let emailList = [];
        if (profileData.edeliveryemail && profileData.edeliveryemail != null && profileData.edeliveryemail.length > 0) {
            emailList = [...profileData.edeliveryemail];
        }
        let accounts = JSON.parse(JSON.stringify(profileData.accounts));
        this.setState({ emailList: emailList, editEmail: profileData.emailprimary, accounts: accounts }, () => {
            if (source === 'updateCallBack')
                this.getAccountList(updatedAccounts, source);
            else
                this.getAccountList(null, source)
        });
    }
    getAccountList = (updatedAccounts, source) =>{
        var accountList = [];
        for (const [index, data] of this.state.accounts ? this.state.accounts.entries() : []) {
            if (data.eDelivery !== null) {
                let eDeliveryList = data.eDelivery.filter(p => {
                    if (p.email !== null) {
                        return p;
                    }                                  
                });

                var indexOfEdelivery = -1;
                let stateEditEmail = this.state.editEmail ? this.state.editEmail.toUpperCase() : ''
                data.eDelivery.findIndex(function (p, i) {
                    let eDeliveryMail = p.email ? p.email.toUpperCase() : '';
                    
                    if (eDeliveryMail === stateEditEmail)  {
                        indexOfEdelivery = i;
                    }
                })
                    accountList.push(
                        {
                            id: index,
                            key: data.key,
                            
                            displayName: getDisplayName(data),
                            email: indexOfEdelivery !== -1 ? data.eDelivery[indexOfEdelivery].email : '',
                            custID: indexOfEdelivery !== -1 ? data.eDelivery[indexOfEdelivery].custID : '',

                            enrolled: eDeliveryList.length === 0
                                ? "No email on File"
                                : indexOfEdelivery === -1
                                    ? "Not Account Holder"
                                    : data.eDelivery[indexOfEdelivery].edeliverystatus !== null
                                        ? data.eDelivery[indexOfEdelivery].edeliverystatus
                                        : "Not Available",

                            confirmsProspectuses: indexOfEdelivery !== -1 && data.eDelivery[indexOfEdelivery].cfrmsSuprnCd !== null
                                ? (data.eDelivery[indexOfEdelivery].cfrmsSuprnCd === 'Y'
                                    ? true
                                    : false)
                                : false,
                            statementsAndRegulatoryInserts: indexOfEdelivery !== -1 && data.eDelivery[indexOfEdelivery].stmtsSuprnCd !== null ? (data.eDelivery[indexOfEdelivery].stmtsSuprnCd === 'Y' ? true : false) : false,
                            eligibleCustomerCorrespondence: indexOfEdelivery !== -1 && data.eDelivery[indexOfEdelivery].custCorrSuprnCd !== null ? (data.eDelivery[indexOfEdelivery].custCorrSuprnCd === 'Y' ? true : false) : false,
                            taxFormsAndRelatedDocuments: indexOfEdelivery !== -1 && data.eDelivery[indexOfEdelivery].taxFormSuprnCd !== null ? (data.eDelivery[indexOfEdelivery].taxFormSuprnCd === 'Y' ? true : false) : false,
                            shareholderReportsAndOtherDocuments: indexOfEdelivery !== -1 && data.eDelivery[indexOfEdelivery].rptSuprnCd !== null ? (data.eDelivery[indexOfEdelivery].rptSuprnCd === 'Y' ? true : false) : false,
                            isedeliveryupdated: (source === 'updateCallBack' && updatedAccounts.filter(updatedAccount => updatedAccount.key === data.key).length > 0) ?
                                updatedAccounts.filter(updatedAccount => updatedAccount.key === data.key)[0].isedeliveryupdated : 'true',
                            displaynickname: data.displaynickname
                        }
                    )
            }
        }
        var initialAccountList = JSON.parse(JSON.stringify(accountList));
        this.setState({ accountList: accountList, initialAccountList: initialAccountList, isEdeliveryLoading: false }, () => {
            if (accountList.length !== 0) {
                let clonedState = Object.assign({}, this.state);
                clonedState.confirmsProspectusesSelectAll = this.checkAllSubDocumentsSelected("confirmsProspectuses");
                clonedState.statementsAndRegulatoryInsertsSelectAll = this.checkAllSubDocumentsSelected("statementsAndRegulatoryInserts");
                clonedState.eligibleCustomerCorrespondenceSelectAll = this.checkAllSubDocumentsSelected("eligibleCustomerCorrespondence");
                clonedState.taxFormsAndRelatedDocumentsSelectAll = this.checkAllSubDocumentsSelected("taxFormsAndRelatedDocuments");
                clonedState.shareholderReportsAndOtherDocumentsSelectAll = this.checkAllSubDocumentsSelected("shareholderReportsAndOtherDocuments");
                if (clonedState.confirmsProspectusesSelectAll && clonedState.statementsAndRegulatoryInsertsSelectAll && clonedState.eligibleCustomerCorrespondenceSelectAll
                    && clonedState.taxFormsAndRelatedDocumentsSelectAll && clonedState.shareholderReportsAndOtherDocumentsSelectAll)
                    clonedState.selectAllDocuments = true;
                else
                    clonedState.selectAllDocuments = false;
                this.setState(clonedState);
            }
        } 

        );
    }
    handleConfirmsProspectus = index => {
        let accountdata = this.state.accountList;
        if (index !== -1) {
            accountdata[index].confirmsProspectuses = !accountdata[index].confirmsProspectuses;
        }
        this.setState({ accountList: accountdata }, () => {
            let flag = this.checkAllSubDocumentsSelected("confirmsProspectuses");
            let allDocumentFlag = false;
            if (flag) {
                allDocumentFlag = this.checkAllDocumentsSelected();
            }
            this.setState({ confirmsProspectusesSelectAll: flag, selectAllDocuments: allDocumentFlag, disabled: true});
        });
    }
    
    handleStatementsAndRegulatory = index => {
        let accountdata = this.state.accountList;
        if (index !== -1) {
            accountdata[index].statementsAndRegulatoryInserts = !accountdata[index].statementsAndRegulatoryInserts;
        }
        this.setState({ accountList: accountdata }, () => {
            let flag = this.checkAllSubDocumentsSelected("statementsAndRegulatoryInserts");
            let allDocumentFlag = false;
            if (flag) {
                allDocumentFlag = this.checkAllDocumentsSelected();
            }
            this.setState({ statementsAndRegulatoryInsertsSelectAll: flag, selectAllDocuments: allDocumentFlag, disabled: true});
        });
    }
    handleEligibleCustomerCorrespondence = index => {
        let accountdata = this.state.accountList;
        if (index !== -1) {
            accountdata[index].eligibleCustomerCorrespondence = !accountdata[index].eligibleCustomerCorrespondence;
        }
        this.setState({ accountList: accountdata }, () => {
            let flag = this.checkAllSubDocumentsSelected("eligibleCustomerCorrespondence");
            let allDocumentFlag = false;
            if (flag) {
                allDocumentFlag = this.checkAllDocumentsSelected();
            }
            this.setState({ eligibleCustomerCorrespondenceSelectAll: flag, selectAllDocuments: allDocumentFlag, disabled: true });
        });
    }
    handleTaxFormRelated = index => {
        let accountdata = this.state.accountList;
        if (index !== -1) {
            accountdata[index].taxFormsAndRelatedDocuments = !accountdata[index].taxFormsAndRelatedDocuments;
        }
        this.setState({ accountList: accountdata }, () => {
            let flag = this.checkAllSubDocumentsSelected("taxFormsAndRelatedDocuments");
            let allDocumentFlag = false;
            if (flag) {
                allDocumentFlag = this.checkAllDocumentsSelected();
            }
            this.setState({ taxFormsAndRelatedDocumentsSelectAll: flag, selectAllDocuments: allDocumentFlag, disabled: true });
        });
    }
    handleShareHolderReport = index => {
        let accountdata = this.state.accountList;
        if (index !== -1) {
            accountdata[index].shareholderReportsAndOtherDocuments = !accountdata[index].shareholderReportsAndOtherDocuments;
        }
        this.setState({ accountList: accountdata },() => {
            let flag = this.checkAllSubDocumentsSelected("shareholderReportsAndOtherDocuments");
            let allDocumentFlag = false;
            if (flag) {
                allDocumentFlag = this.checkAllDocumentsSelected();
            }
            this.setState({ shareholderReportsAndOtherDocumentsSelectAll: flag, selectAllDocuments: allDocumentFlag, disabled: true });
        });
    }
    handleSelectAllDocuments = () => {
        let accountdata = [...this.state.accountList];
        let flag = !this.state.selectAllDocuments;
        for (const [, account] of accountdata.entries()) {
            if (account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File') {
                account.confirmsProspectuses = flag;
                account.statementsAndRegulatoryInserts = flag;
                account.eligibleCustomerCorrespondence = flag;
                account.taxFormsAndRelatedDocuments = flag;
                account.shareholderReportsAndOtherDocuments = flag;
            }
        }
        this.setState({
            accountList: accountdata, selectAllDocuments: flag, confirmsProspectusesSelectAll: flag, statementsAndRegulatoryInsertsSelectAll: flag,
            eligibleCustomerCorrespondenceSelectAll: flag, taxFormsAndRelatedDocumentsSelectAll: flag, shareholderReportsAndOtherDocumentsSelectAll: flag,
            disabled:true
        });
    }
    handleSelectAllColumnDocuments = (source) => {
        let clonedState = Object.assign({}, this.state);
        let flag = false;
        switch (source) {
            case "confirmsProspectuses":
                flag = !clonedState.confirmsProspectusesSelectAll;
                for (const [, account] of clonedState.accountList.entries()) {
                    if (account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File')
                        account.confirmsProspectuses = flag;
                }
                clonedState.confirmsProspectusesSelectAll = flag;
                break;
            case "statementsAndRegulatoryInserts":
                flag = !clonedState.statementsAndRegulatoryInsertsSelectAll;
                for (const [, account] of clonedState.accountList.entries()) {
                    if (account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File')
                        account.statementsAndRegulatoryInserts = flag;
                }
                clonedState.statementsAndRegulatoryInsertsSelectAll = flag;
                break;
            case "eligibleCustomerCorrespondence":
                flag = !clonedState.eligibleCustomerCorrespondenceSelectAll;
                for (const [, account] of clonedState.accountList.entries()) {
                    if (account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File')
                        account.eligibleCustomerCorrespondence = flag;
                }
                clonedState.eligibleCustomerCorrespondenceSelectAll = flag;
                break;
            case "taxFormsAndRelatedDocuments":
                flag = !clonedState.taxFormsAndRelatedDocumentsSelectAll;
                for (const [, account] of clonedState.accountList.entries()) {
                    if (account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File')
                        account.taxFormsAndRelatedDocuments = flag;
                }
                clonedState.taxFormsAndRelatedDocumentsSelectAll = flag;
                break;
            case "shareholderReportsAndOtherDocuments":
                flag = !clonedState.shareholderReportsAndOtherDocumentsSelectAll;
                for (const [, account] of clonedState.accountList.entries()) {
                    if (account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File')
                        account.shareholderReportsAndOtherDocuments = flag;
                }
                clonedState.shareholderReportsAndOtherDocumentsSelectAll = flag;
                break;
        }
        
        this.setState(clonedState , () => {
            let selectAllDocuments = this.checkAllDocumentsSelected();
            this.setState({ selectAllDocuments: selectAllDocuments, disabled: true});
        });
    }
    checkAllSubDocumentsSelected(documentType) {
        switch (documentType) {
            case "confirmsProspectuses":
                for (const [, account] of this.state.accountList.entries()) {
                    if ((account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File') && !account.confirmsProspectuses) {
                        return false;
                    }
                }
                return true;
                break;
            case "statementsAndRegulatoryInserts":
                for (const [, account] of this.state.accountList.entries()) {
                    if ((account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File') && !account.statementsAndRegulatoryInserts) {
                        return false;
                    }
                }
                return true;
                break;
            case "eligibleCustomerCorrespondence":
                for (const [, account] of this.state.accountList.entries()) {
                    if ((account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File') && !account.eligibleCustomerCorrespondence) {
                        return false;
                    }
                }
                return true;
                break;
            case "taxFormsAndRelatedDocuments":
                for (const [, account] of this.state.accountList.entries()) {
                    if ((account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File') && !account.taxFormsAndRelatedDocuments) {
                        return false;
                    }
                }
                return true;
                break;
            case "shareholderReportsAndOtherDocuments":
                for (const [, account] of this.state.accountList.entries()) {
                    if ((account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File') && !account.shareholderReportsAndOtherDocuments) {
                        return false;
                    }
                }
                return true;
                break;
        }
    }
    checkAllDocumentsSelected() {
        let accountdata = [...this.state.accountList];
        for (const [, account] of accountdata.entries()) {
            if ((account.enrolled !== 'Pending' && account.enrolled !== 'Not Available' && account.enrolled !== 'Not Account Holder' && account.enrolled !== 'No email on File')
                && (!account.confirmsProspectuses || !account.statementsAndRegulatoryInserts || !account.eligibleCustomerCorrespondence
                || !account.taxFormsAndRelatedDocuments || !account.shareholderReportsAndOtherDocuments)) {
                return false;
            }
        }
        return true;
    }
    scrollStep = () => {
        window.scroll(0, 0);
    }


    scrollToTop = () => {
        window.scroll(0, 0);
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    toggelAddModel = () => {
        this.setState(prevState => ({
            addModal: !prevState.addModal,
            emailError: 'false',
            disableAddEmail: true,
            newEmail: '',
           // isEmailUpdated: false,
            messages:[]
        }));
    }

    toggleDesModel() {
        let isEdeliveryChanged = this.isEdeliveryChanged();
        if (!this.state.des_modal && !isEdeliveryChanged) {
            let messages = this.state.messages;
            messages.push({
                type: MessageType.INFO,
                text: 'There were no changes made to update.',
                class: MessageClass.ERROR
            })
            this.setState({
                messages: messages,
                disabled: true,
                isPreferencesUpdated: true
            });
        } else if (!this.state.des_modal && this.state.editEmail === "") {
            let messages = this.state.messages;
            messages.push({
                type: MessageType.INFO,
                text: 'E-delivery email address is required.',
                class: MessageClass.ERROR
            })
            this.setState({
                messages: messages,
                disabled: true,
                isPreferencesUpdated: true
            });
        }else if(!this.state.des_modal && isEdeliveryChanged){
            let updatedAccounts = [];
            for (const [, account] of this.state.accountList.entries()) {
                var initialAccount = this.state.initialAccountList.filter(item => item.key === account.key);
                if (initialAccount != null && initialAccount.length >= 1 && initialAccount[0] != null && JSON.stringify(account) != JSON.stringify(initialAccount[0])) {
                    updatedAccounts.push({ ...account });
                }
            }
            this.setState(prevState => ({
                des_modal: !prevState.des_modal,
                modal: !prevState.modal,
                disabled: !prevState.disabled,
                updatedAccounts: updatedAccounts
            }));
        }else {
            this.setState(prevState => ({
                des_modal: !prevState.des_modal,
                modal: !prevState.modal,
                disabled: !prevState.disabled,
            }));
        }
        
    }

    clickOnAgree = () => {
        this.setState(prevState => ({
            des_modal: !prevState.des_modal,
            modal: false
        }), () => { this.updateProfile()});
    }

    handleEmailOnChange = evt => {
        const basicEmail = /^[a-zA-Z0-9 .@_-]+$/;
        if (evt.target.value === "" || basicEmail.test(evt.target.value)) {
            let newEmail = evt.target.value;
            let disableAddEmail = true;
            if (validateEmailId(evt.target.value)) {
                disableAddEmail = false;
            }
            this.setState({ newEmail: newEmail, disableAddEmail: disableAddEmail });
        }
    }
    handleEmailOnBlur = evt => {
        if (evt.target.value === '') {
            this.setState({ emailError: "false", emailErrorMsg: '' });
        } else if (validateEmailId(evt.target.value)) {
            this.setState({ emailError: "false", disableAddEmail: false, emailErrorMsg:''});
        } else {
            this.setState({ emailError: "true", disableAddEmail: true, emailErrorMsg: 'Email not valid, please enter a valid email address.', messages:[] });
        }
    }
    addEmail = () => {
        let emailList = [...this.state.emailList];
        if (!this.state.emailList.filter(item => item.emailid.toLowerCase() === this.state.newEmail.toLowerCase()).length > 0) {
                var requestJson = {
                    "profileid": this.requesterId,
                    "emailid": this.state.newEmail,
                    "indicator": "I"
                };

                addRemoveEmailServices(requestJson, this.authToken, this.contextjson)
                    .then(response => {
                        if (response.status === 200) {
                            let email = { emailid: this.state.newEmail, isdeletable: true };
                            emailList.push({ ...email });
                            let messages = [];
                            messages.push({
                                type: MessageType.SUCCESS,
                                text: 'Email added successfully.',
                                class: MessageClass.SUCCESS
                            });
                            this.setState(prevState => ({
                                ...prevState,
                                emailError: 'false',
                                disableAddEmail: true,
                                newEmail: '',
                                emailList: emailList,
                                emailErrorMsg: '',
                                messages: messages,
                                editEmail: this.state.newEmail
                            }));
                        }
                        else {
                            let messages = [];
                            messages.push({
                                type: MessageType.INFO,
                                text: "Email not added.",
                                class: MessageClass.ERROR
                            })
                            this.setState(prevState => ({
                                addModal: !prevState.addModal,
                                emailError: 'false',
                                disableAddEmail: true,
                                newEmail: '',
                                emailErrorMsg: '',
                                messages: messages
                            }));
                           
                        }
                        })

                    .catch(error => {
                        console.error(error);
                        let messages = [];
                        messages.push({
                            type: MessageType.INFO,
                            text: "Email not added.",
                            class: MessageClass.ERROR
                        })
                        this.setState(prevState => ({
                            addModal: !prevState.addModal,
                            emailError: 'false',
                            disableAddEmail: true,
                            newEmail: '',
                            emailErrorMsg: '',
                            messages: messages,
                           // isEmailUpdated:true,
                        }));
                        //this.setState({ emailError: "true", emailErrorMsg: "Email not added."});
                    })
        } else {
            this.setState({ emailError: "true", emailErrorMsg: 'Email already exists, please enter a different email address.', messages:[] });
        }
        
    }

    handleRemoveEmail = (index, email) => {
        let emailList = [...this.state.emailList];
        
        var requestJson = {
            "profileid": this.requesterId,
            "emailid": email,
            "indicator": "D"
        };

        addRemoveEmailServices(requestJson, this.authToken, this.contextjson)
            .then(response => {
                if (response.status === 200) {
                    let editEmail = this.state.editEmail;
                    if (index <= emailList.length)
                        emailList.splice(index, 1);
                    if (email === this.state.editEmail) {
                        editEmail = this.state.primaryEmail;
                    }
                    this.setState({ emailList: emailList, emailError: "false", emailErrorMsg: '', editEmail: editEmail });
                    let messages = [];
                    messages.push({
                        type: MessageType.SUCCESS,
                        text: 'Email deleted successfully.',
                        class: MessageClass.SUCCESS
                    });
                    this.setState(prevState => ({
                        ...prevState,
                        emailList: emailList, emailError: "false", emailErrorMsg: '', editEmail: editEmail,
                        messages: messages,
                    }));
                }
                else
                    this.setState({ emailError: "true", emailErrorMsg: "Email not deleted.", messages:[] });
            })
            .catch(error => {
                console.error(error);
                this.setState({ emailError: "true", emailErrorMsg: "Email not deleted.", messages:[] });
            })
        
    }

    changeEmailDropDownValue(email) {
        if (this.isEdeliveryChanged()) {
            this.setState({
                selectedEmail: email,
                isConfirmEmailChangePopupOpen: true
            })
        } else {
            this.setState({
                editEmail: email
            }, () => {
                this.getAccountList(null, "emailUpdated")
            })
        }
    }

    updateProfile = () => {
        this.setState({ isPreferencesUpdated: false, messages: [], isEdeliveryLoading: true }, () => {
            this.updateProfileCallback();
        });
    }

    isEdeliveryChanged = () => {
        let accountList = JSON.parse(JSON.stringify(this.state.accountList));
        let initialAccountList = JSON.parse(JSON.stringify(this.state.initialAccountList));
        accountList.sort(function (a, b) {
            var x = a.key.toLowerCase();
            var y = b.key.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        initialAccountList.sort(function (a, b) {
            var x = a.key.toLowerCase();
            var y = b.key.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
        if (JSON.stringify(accountList) != JSON.stringify(initialAccountList))
            return true;
        else
            return false;
    }
    updateProfileCallback = () => {
        this.scrollToTop();
        const profileUrl = ProfileUpdateUrl
        this.cancellationToken = axios.CancelToken.source();
        let messages = [];
        let updatedAccounts = [];
        for (const [, account] of this.state.updatedAccounts.entries()) {
           
                var tempAccount = this.state.accounts.filter(item => item.key === account.key);
                if (tempAccount && tempAccount != null && tempAccount.length === 1) {
                    var updatedAccount = { ...tempAccount[0] };
                    let custID = "";
                    
                    var indexOfEdelivery = -1;
                    let stateEditEmail = this.state.editEmail ? this.state.editEmail.toUpperCase() : ''
                    updatedAccount.eDelivery.findIndex(function (p, i) {
                        let eDeliveryMail = p.email ? p.email.toUpperCase() : '';

                        if (eDeliveryMail === stateEditEmail) {
                            indexOfEdelivery = i;
                        }
                    })
                    if (indexOfEdelivery >= 0) {
                        custID = updatedAccount.eDelivery[indexOfEdelivery].custID;
                    }
                    if (updatedAccount.eDelivery !== null && indexOfEdelivery >= 0) {
                        updatedAccount.eDelivery[indexOfEdelivery].custID = custID;
                        updatedAccount.eDelivery[indexOfEdelivery].cfrmsSuprnCd = account.confirmsProspectuses ? 'Y' : 'N';
                        updatedAccount.eDelivery[indexOfEdelivery].stmtsSuprnCd = account.statementsAndRegulatoryInserts ? 'Y' : 'N';
                        updatedAccount.eDelivery[indexOfEdelivery].custCorrSuprnCd = account.eligibleCustomerCorrespondence ? 'Y' : 'N';
                        updatedAccount.eDelivery[indexOfEdelivery].taxFormSuprnCd = account.taxFormsAndRelatedDocuments ? 'Y' : 'N';
                        updatedAccount.eDelivery[indexOfEdelivery].rptSuprnCd = account.shareholderReportsAndOtherDocuments ? 'Y' : 'N';
                        updatedAccount.eDelivery[indexOfEdelivery].email = this.state.editEmail !== "" ? this.state.editEmail : "";
                        updatedAccount.eDelivery[indexOfEdelivery].enrolled = (updatedAccount.eDelivery[indexOfEdelivery].cfrmsSuprnCd === "Y" || updatedAccount.eDelivery[indexOfEdelivery].custCorrSuprnCd === "Y"
                            || updatedAccount.eDelivery[indexOfEdelivery].stmtsSuprnCd === "Y" || updatedAccount.eDelivery[indexOfEdelivery].taxFormSuprnCd === "Y"
                            || updatedAccount.eDelivery[indexOfEdelivery].rptSuprnCd === "Y") ? true : false;
                        updatedAccounts.push({ ...updatedAccount });
                    }
                }
        }
        var reqObj = {
            profile: {
                wsportaluserid: this.props.profileReq.wsportaluserid,
                documentname: this.props.env.eDeliveryDisclaimerPdf,
                accounts: updatedAccounts
            }
        }
        let byteArr = toUTF8Array(JSON.stringify(reqObj));
        Post(profileUrl, byteArr, this.props.token,
            this.state.contextjson, this.state.profilejson, this.cancellationToken)
            .then(
                res => {
                    let allAcountupdated = true;
                    if (res.status === 200) {
                        res.data.profile.accounts.map((account) => {
                            if (account.isedeliveryupdated === "false")
                                allAcountupdated = false;

                        })
                        this.getProfile('updateCallBack', res.data.profile.accounts);
                        messages.push({
                            type: allAcountupdated ? MessageType.SUCCESS : MessageType.PREFERENCE_WARNING,
                            text: allAcountupdated ? 'Accounts updated successfully' : ' ',
                            class: allAcountupdated ? MessageClass.SUCCESS : MessageClass.PREFERENCE_WARNING
                        })
                        this.setState({
                            messages: messages,
                            disabled: true
                        });
                    } else {

                        messages.push({
                            type: MessageType.INFO,
                            text: 'Not Updated.',
                            class: MessageClass.ERROR
                        })
                        this.setState({
                            messages: messages,
                            disabled: true,
                            isEdeliveryLoading: false
                        });
                    }
                }
            ).catch(error => {
                console.log('making false - profile');
                console.log(error);
                messages.push({
                    type: MessageType.INFO,
                    text: 'Not Updated.',
                    class: MessageClass.ERROR
                })
                this.setState({
                    messages: messages,
                    disabled: true,
                    isEdeliveryLoading: false
                });
            });
        this.setState({
            isPreferencesUpdated: true
        });
    }
    onSort(sortOrderColumnName, ColumnName) {
        const data = this.state.accountList;
        if (this.state[sortOrderColumnName] === '') {
            data.sort(function (a, b) {
                if (a[ColumnName]) return b[ColumnName] ? a[ColumnName].localeCompare(b[ColumnName]) : -1;
                else if (b[ColumnName]) return a[ColumnName] ? b[ColumnName].localeCompare(a[ColumnName]) : 1;
            });
            this.setState({ accountList: data, [sortOrderColumnName]: 'asc' })
        }
        else if (this.state[sortOrderColumnName] === 'asc') {
            data.sort(function (a, b) {
                if (b[ColumnName]) return a[ColumnName] ? b[ColumnName].localeCompare(a[ColumnName]) : 1;
                else if (a[ColumnName]) return (b[ColumnName]) ? a[ColumnName].localeCompare(b[ColumnName]) : -1;
            });
            this.setState({ accountList: data, [sortOrderColumnName]: 'desc' })
        }
        else if (this.state[sortOrderColumnName] === 'desc') {
            data.sort(function (a, b) {
                if (a[ColumnName]) return b[ColumnName] ? a[ColumnName].localeCompare(b[ColumnName]) : -1;
                else if (b[ColumnName]) return a[ColumnName] ? b[ColumnName].localeCompare(a[ColumnName]) : 1;
            });
            this.setState({ accountList: data, [sortOrderColumnName]: 'asc' })
        }
    }
    getSortClassName(sortOrder) {
        if (sortOrder === '') {
            return "RCM_Sort_arrow_icon"
        }
        else if (sortOrder === "asc") {
            return "RCM_Sort_arrow_down_icon";
        }
        else {
            return "RCM_Sort_arrow_up_icon";
        }
    }
    toggleConfirmEmailChangePopup = () => {
        this.setState(prevState => ({
            isConfirmEmailChangePopupOpen: !prevState.isConfirmEmailChangePopupOpen,
        }));
        //this.setState(prevState => { isConfirmEmailChangePopupOpen: !prevState.isConfirmEmailChangePopupOpen })
    }
    changeEmail = () => {
        this.setState(prevState =>({
            editEmail: this.state.selectedEmail,
            isConfirmEmailChangePopupOpen: !prevState.isConfirmEmailChangePopupOpen
        }), () => {
            this.getAccountList(null, "emailUpdated")
        })
    }
    render() {
        return (
            <div className="container-fluid" key="edelivery01">
                <ErrorBoundary>
                    {this.state.isProspectClient ?
                        <Watermark />
                        : ''
                    }
                        <PreferenceView
                        state={this.state}
                        handleConfirmsProspectus={this.handleConfirmsProspectus}
                        handleStatementsAndRegulatory={this.handleStatementsAndRegulatory}
                        handleEligibleCustomerCorrespondence={this.handleEligibleCustomerCorrespondence}
                        handleTaxFormRelated={this.handleTaxFormRelated}
                        handleShareHolderReport={this.handleShareHolderReport}
                        handleEmailConfirm={this.handleEmailConfirm}
                        toggelAddModel={this.toggelAddModel}
                        toggleDesModel={this.toggleDesModel}
                        clickOnAgree={this.clickOnAgree}
                        changeEmailDropDownValue={this.changeEmailDropDownValue}
                        toggleDropdown={this.toggleDropdown}
                        handleEmailOnChange={this.handleEmailOnChange}
                        handleEmailOnBlur={this.handleEmailOnBlur}
                        addEmail={this.addEmail}
                        handleRemoveEmail={this.handleRemoveEmail}
                        handleSelectAllDocuments={this.handleSelectAllDocuments}
                        handleSelectAllColumnDocuments={this.handleSelectAllColumnDocuments}
                        onSort={this.onSort}
                        getSortClassName={this.getSortClassName}
                        toggleConfirmEmailChangePopup={this.toggleConfirmEmailChangePopup}
                        changeEmail={this.changeEmail}
                        eDeliveryDisclaimerPdf={this.props.env ? this.props.env.eDeliveryDisclaimerPdf : ''}
                        //getDisplayName={this.getDisplayName}
                        />
                    </ErrorBoundary>
                
            </div>
        );
    }

}
const mapStateToProps = (store) => {
    return {
        accounts: store.personalisationData.personalisationData === undefined ? [] : store.personalisationData.personalisationData.accounts,
        contextjson: store.acct.contextjson,
        profilejson: store.acct.profilejson,
        preferenceReq: store.acct.preferences,
        name: store.acct.name,
        profileId: store.acct.profileId,
        profileReq: store.personalisationData.personalisationData === undefined ? "" : store.personalisationData.personalisationData,
        token: store.acct.token,
        render: store.acct.render,
        role: store.acct.role,
        env: store.env
    };
}
const mapDispatchToProps = {
    fetchPersonalisationData,
    updatePreference
}
const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Preferences' })
)

export default enhancement(Preference);