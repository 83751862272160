import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import CyberWaConfirmationModal from "./cyberWaConfirmationModal";

const CyberWaContainer = () => {
  const cyberwaText =
    "An exclusive provider of elite cyber-protection services for UHNW & HNW individuals, their families and family offices associated with Rockefeller. Offerings range from a digital security assessment and recommendations up to retainer packages with 24/7 monitoring of all digital assets. ";
  const cyberwaUrl = useSelector((state) => state?.env?.cyberWaURL);
  const [isCyberwaModalOpen, setCyberwaModalOpen] = useState(false);

  const handleViewCyberWaClick = useCallback(() => {
    setCyberwaModalOpen(true);
  }, []);

  const handleCyberWaConfirmClick = useCallback(() => {
    window.open(cyberwaUrl);
    setCyberwaModalOpen(false);
  }, [cyberwaUrl]);

  const toogleCyberWaModal = useCallback(() => {
    setCyberwaModalOpen((prev) => !prev);
  }, []);

  return (
    <div className="container">
      <img
        id="immersicx_image"
        src="./../images/Cyber_WA.png"
        alt="Cyber WA"
        width="100%"
        className="RCM_health_base_image"
      />
      <div>
        <div className="row RCM_health_container">
          <div className="col col-sm-12 mt-1">
            <div
              className="RCM_Toast RCM_health_toast"
              id="RCM_cyberwa_details"
            >
              <div className="RCM_lifestyle_homeThrive"></div>
              <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">
                CYBER WA
              </div>
              <div className="mt-2 RCM_aviation_toast_content_container">
                {cyberwaText}
              </div>
              <div className="mt-4 RCM_lifestyle_btns" align="center">
                <button
                  className="btn btn-primary RCM_button_aviation_page"
                  onClick={handleViewCyberWaClick}
                >
                  CLICK HERE TO VIEW CYBER WA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCyberwaModalOpen && (
        <CyberWaConfirmationModal
          isOpen={isCyberwaModalOpen}
          toogleModal={toogleCyberWaModal}
          onContinueClick={handleCyberWaConfirmClick}
        />
      )}
    </div>
  );
};

export default CyberWaContainer;
