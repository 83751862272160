import React, { Component } from 'react';
import AssetAllocationChartDetails from './assetallocationchartdetails.jsx';
import { MsgForNoData } from './constants.jsx';
import ToptenHoldingsHighChart from './HighCharts/ToptenHoldingsHighChart.jsx';
import EstimatedIncomeHighChart from './HighCharts/EstimatedIncomeHighChart.jsx';
import PhilanthropyDonorHighChart from './HighCharts/PhilanthropyDonorHighChart.jsx';
import PhilanthropyGrantsHighChart from './HighCharts/PhilanthropyGrantsHighChart.jsx'

class Chart extends Component {

    render() {
        const data_style = { width: 300, height: 400, innerRadius: 60, outerRadius: 100 };

        if (this.props.data === 'pie') {
            if (this.props.chartdata && this.props.chartdata.length !== 0) {
                return (
                     <div>
                        <AssetAllocationChartDetails ations data={this.props.chartdata} styleObj={data_style} />
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <p className="RCM_data_error">{MsgForNoData}</p>
                    </div>
                );
            }
        }
        else if (this.props.data === 'bar') {
            if (this.props.chartdata && this.props.chartdata.length !== 0) {
                return (
                    <div>
                        <ToptenHoldingsHighChart data={this.props.chartdata} />
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <p className="RCM_data_error">{MsgForNoData}</p>
                    </div>
                );
            }
        }
        else if (this.props.data === 'bar_ProjectedIncome') {
            if (this.props.chartdata && this.props.chartdata.length !== 0) {
                return (
                    <div>
                        <EstimatedIncomeHighChart data={this.props.chartdata}/>
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <p className="RCM_data_error">{MsgForNoData}</p>
                    </div>
                );
            }
        }
        else if (this.props.data === 'philanthropy_donorpie') {
            if (this.props.chartdata && this.props.chartdata.length !== 0) {
                return (
                    <div>
                        <PhilanthropyDonorHighChart data={this.props.chartdata} />
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <p className="RCM_data_error">{MsgForNoData}</p>
                    </div>
                );
            }
        }
        else if (this.props.data === 'philanthropy_grantpie') {
            if (this.props.chartdata && this.props.chartdata.length !== 0) {
                return (
                    <div>
                        <PhilanthropyGrantsHighChart data={this.props.chartdata} />
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <p className="RCM_data_error">{MsgForNoData}</p>
                    </div>
                );
            }
        }
        else {
            return (
                <div>
                    No Such chart exist
                </div>
            )
        }
    }
}
export default Chart;