import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { roundvalue, formatMoney, MsgForNoData, GetNumericValue } from '../../../../common/constants.jsx';
import Watermark from '../../../../common/watermark.jsx';
export function BalanceSummaryView(
    props
) {
    let balancesummaryview = (
        <div className="row RCM_Balance_margin" key="bs1" id="RCM_headerbal">
            {props.state && props.state.isProspectClient ? <Watermark component="balancesummary" /> : ''}
            <div className="col-sm-12 RCM_Dashboard_Tables" key="bs2">
                    <Carousel
                        activeIndex={props.index}
                        direction={props.direction}
                        onSelect={props.handleSelect}
                        indicators={false}
                        controls={false}
                        interval={null}
                        prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" hidden={true} />}
                        nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" hidden={true} />}
                        slide={false}
                    >
                        <Carousel.Item>
                            <div className="RCM_silderfirstitem">
                                <div className="col-sm-6 col-md-6 col-xs-6 col-lg-6" key="bs4">
                                    <div className="RCM_Dashboard_Tables RCM_CM_scroll" key="bs5">
                                        {props.rows4 && props.rows4.length !== 0 ?
                                            <div className="RCM_tabledata slide3" key="bs6">
                                                {
                                                    props.rows4 && props.rows4.map((rows, index) =>
                                                        <div className="RCM_rowdata" key={"bs_" + index}>
                                                            <div className="RCM_fo_name" key={"bsname_" + index}>
                                                                <span>{rows.name}</span>
                                                            </div>
                                                            <div className="RCM_fo_value" key={"bsvalue_" + index}>{rows.value}</div>
                                                        </div>
                                                    )}
                                            </div> : <div>{MsgForNoData}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-xs-6 col-lg-6" key="bs7">
                                    <div className="RCM_Dashboard_Tables RCM_CM_scroll" key="bs8">
                                        <div style={{marginBottom: '0.4rem'}}>&nbsp;</div>
                                        {props.rows5 && props.rows5.length !== 0 ?
                                            <div className="RCM_tabledata slide2" key="bs9">
                                                {
                                                    props.rows5 && props.rows5.map((rows, index) =>
                                                        rows.name === "Today's Change" ?
                                                            <div className="RCM_rowdata" key={"bs_" + index}>
                                                                <div className="RCM_fo_name" key={"bsname_" + index}>
                                                                    <span>{rows.name}</span>
                                                                </div>
                                                                <div className="RCM_fo_value" key={"bsvalue_" + index}>
                                                                    <span className={props.getClassName((rows.value))}>
                                                                        {(rows.value) ? formatMoney(rows.value, 2) : GetNumericValue(rows.value)
                                                                        }</span>/
                                                                <span className={props.getClassName((props.pricedinvestmentspercentchange))}>
                                                                        {(props.pricedinvestmentspercentchange) ? `${roundvalue(props.pricedinvestmentspercentchange, 2)}%` : GetNumericValue(props.pricedinvestmentspercentchange, true)
                                                                        }</span>
                                                                </div>
                                                            </div> :
                                                            <div className="RCM_rowdata" key={"bs_" + index}>
                                                                <div className="RCM_fo_name" key={"bsname_" + index}>
                                                                    <span>{rows.name}</span>
                                                                </div>
                                                                <div className="RCM_fo_value" key={"bsvalue_" + index}>{rows.value}</div>
                                                            </div>
                                                    )}
                                                
                                            </div> : <div></div>}
                                    </div>
                                </div></div>
                        </Carousel.Item>
                    </Carousel>
            </div>
        </div>
    )
    return balancesummaryview;
}