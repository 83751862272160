export const accountPerformanceMockData = {
    "accountlist": [
        {
            "key": "RRR-123456",
            "number": "RRR-123456",
            "lastmarketclose": {
                "percent": 10,
                "value": 80000
            },
            "monthtodate":  5.2,
            "quartertodate": 7,
            "yeartodate": 3.1,
            "securitylist": [
                {
                    "number": "RRR-123456",
                    "key": "RRR-123456",
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "UNQID": "315994103",
                        "ASSETCAT": " Mutual Fund",
                        "secid": "FCASH",
                        "TICKER": "FCASH",
                        "secname": "CASH"
                    }
                },
                {
                    "number": "RRR-123456",
                    "key": "RRR-123456",
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "UNQID": "316062108",
                        "ASSETCAT": " Mutual Fund",
                        "secid": "FAGIX",
                        "TICKER": "FAGIX",
                        "secname": "FIDELITY CAPITAL & INCOME"
                    }
                }
            ],
            "STATUS": {
                "SEVERITY": "I",
                "ERRORCODE": "000000",
                "ERRORTEXT": "OK"
            }
        },
        {
            "key": "RRR-234567",
            "number": "RRR-234567",
            "lastmarketclose": {
                "percent": 10,
                "value": 65800.00
            },
            "monthtodate": 5.36,
            "quartertodate": 9.9,
            "yeartodate": 17.00,
            "securitylist": [
                {
                    "key": "RRR-234567",
                    "number": "RRR-234567",
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "UNQID": "461070AF1",
                        "ASSETCAT": "Corporate Debt",
                        "secid": "461070AF1",
                        "TICKER": null,
                        "secname": "INTERSTATE PWR &LT CO BOND 7.25000% 10/01/2018 CALL MAKE WHOLE"
                    }
                }
            ],
            "STATUS": {
                "SEVERITY": "I",
                "ERRORCODE": "000000",
                "ERRORTEXT": "OK"
            }
        }
    ]
};

export const securityPerformanceMockData = {
    "securityList": [
        {
            "lastmarketclose": {
                "percent": 10,
                "value": 658,
                "increase": false
            },
            "lastmonthend": {
                "percent": 2,
                "value": 650,
                "increase": true
            },
            "lastquarterend": {
                "percent": 10,
                "value": 858,
                "increase": false
            },
            "monthtodate": {
                "percent": 5,
                "increase": false
            },
            "quartertodate": {
                "percent": 7,
                "increase": true
            },
            "yeartodate": {
                "percent": 3,
                "increase": true
            },
            "last3years": {
                "percent": 10,
                "increase": false
            },
            "last5years": {
                "percent": 10,
                "increase": false
            },
            "secinfo": {
                "UNQID": "315994103",
                "ASSETCAT": " Mutual Fund",
                "secid": "FCASH",
                "TICKER": "FCASH",
                "secname": "CASH"
            },
            "accountList": [
                {
                    "number": "RRR-123456",
                    "key": "RRR-123456",
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    }
                }
            ]
        },
        {
            "lastmarketclose": {
                "percent": 10,
                "value": 658,
                "increase": false
            },
            "lastmonthend": {
                "percent": 2,
                "value": 650,
                "increase": true
            },
            "lastquarterend": {
                "percent": 10,
                "value": 858,
                "increase": false
            },
            "monthtodate": {
                "percent": 5,
                "increase": false
            },
            "quartertodate": {
                "percent": 7,
                "increase": true
            },
            "yeartodate": {
                "percent": 3,
                "increase": true
            },
            "last3years": {
                "percent": 10,
                "increase": false
            },
            "last5years": {
                "percent": 10,
                "increase": false
            },
            "secinfo": {
                "UNQID": "316062108",
                "ASSETCAT": " Mutual Fund",
                "secid": "FAGIX",
                "TICKER": "FAGIX",
                "secname": "FIDELITY CAPITAL & INCOME"
            },
            "accountList": [
                {
                    "number": "RRR-123456",
                    "key": "RRR-123456",
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    }
                }
            ]
        },
        {
            "lastmarketclose": {
                "percent": 10,
                "value": 658,
                "increase": false
            },
            "lastmonthend": {
                "percent": 2,
                "value": 650,
                "increase": true
            },
            "lastquarterend": {
                "percent": 10,
                "value": 858,
                "increase": false
            },
            "monthtodate": {
                "percent": 5,
                "increase": false
            },
            "quartertodate": {
                "percent": 7,
                "increase": true
            },
            "yeartodate": {
                "percent": 3,
                "increase": true
            },
            "last3years": {
                "percent": 10,
                "increase": false
            },
            "last5years": {
                "percent": 10,
                "increase": false
            },
            "secinfo": {
                "UNQID": "461070AF1",
                "ASSETCAT": "Corporate Debt",
                "secid": "461070AF1",
                "TICKER": null,
                "secname": "INTERSTATE PWR &LT CO BOND 7.25000% 10/01/2018 CALL MAKE WHOLE"
            },
            "accountList": [
                {
                    "number": "RRR-234567",
                    "key": "RRR-234567",
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    }
                }
            ]
        }
    ]
}; 

export const assetPerformanceMockData = {
    "assetList": [
        {
            "assetcat": "Common Stock",
            "assetid": "CommonStock",
            "lastmarketclose": {
                "percent": 10,
                "value": 658,
                "increase": false
            },
            "lastmonthend": {
                "percent": 2,
                "value": 650,
                "increase": true
            },
            "lastquarterend": {
                "percent": 10,
                "value": 858,
                "increase": false
            },
            "monthtodate": {
                "percent": 5,
                "increase": false
            },
            "quartertodate": {
                "percent": 7,
                "increase": true
            },
            "yeartodate": {
                "percent": 3,
                "increase": true
            },
            "last3years": {
                "percent": 10,
                "increase": false
            },
            "last5years": {
                "percent": 10,
                "increase": false
            },
            "securityList": [
                {
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "unqid": "98986T108",
                        "assetcat": "Common Stock",
                        "secid": "ZNGA",
                        "ticker": "ZNGA",
                        "secname": "ZYNGA INC COM USD0.00000625 CLASS A"
                    },
                    "accountlist": [
                        {
                            "acctnum": "RJL000001",
                            "acctkey": "RJL000001",
                            "acctype": "Margin",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        },
                        {
                            "acctnum": "RJL000013",
                            "acctkey": "RJL000013",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        }
                    ]
                },
                {
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "unqid": "00206R102",
                        "assetcat": "Common Stock",
                        "secid": "T",
                        "ticker": "T",
                        "secname": "AT&amp;T INC COM USD1"
                    },
                    "accountlist": [
                        {
                            "acctnum": "RJL000001",
                            "acctkey": "RJL000001",
                            "acctype": "Margin",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        },
                        {
                            "acctnum": "RJL000013",
                            "acctkey": "RJL000013",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        }
                    ]
                }
            ]
        },
        {
            "assetcat": "Cash Fund",
            "assetid": "CashFund",
            "lastmarketclose": {
                "percent": 10,
                "value": 658,
                "increase": false
            },
            "lastmonthend": {
                "percent": 2,
                "value": 650,
                "increase": true
            },
            "lastquarterend": {
                "percent": 10,
                "value": 858,
                "increase": false
            },
            "monthtodate": {
                "percent": 5,
                "increase": false
            },
            "quartertodate": {
                "percent": 7,
                "increase": true
            },
            "yeartodate": {
                "percent": 3,
                "increase": true
            },
            "last3years": {
                "percent": 10,
                "increase": false
            },
            "last5years": {
                "percent": 10,
                "increase": false
            },
            "securityList": [
                {
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "unqid": "98986T108",
                        "assetcat": "Common Stock",
                        "secid": "ZNGA",
                        "ticker": "ZNGA",
                        "secname": "ZYNGA INC COM USD0.00000625 CLASS A"
                    },
                    "accountlist": [
                        {
                            "acctnum": "RJL000001",
                            "acctkey": "RJL000001",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        },
                        {
                            "acctnum": "RJL000013",
                            "acctkey": "RJL000013",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        }
                    ]
                },
                {
                    "lastmarketclose": {
                        "percent": 10,
                        "value": 658,
                        "increase": false
                    },
                    "lastmonthend": {
                        "percent": 2,
                        "value": 650,
                        "increase": true
                    },
                    "lastquarterend": {
                        "percent": 10,
                        "value": 858,
                        "increase": false
                    },
                    "monthtodate": {
                        "percent": 5,
                        "increase": false
                    },
                    "quartertodate": {
                        "percent": 7,
                        "increase": true
                    },
                    "yeartodate": {
                        "percent": 3,
                        "increase": true
                    },
                    "last3years": {
                        "percent": 10,
                        "increase": false
                    },
                    "last5years": {
                        "percent": 10,
                        "increase": false
                    },
                    "secinfo": {
                        "unqid": "00206R102",
                        "assetcat": "Common Stock",
                        "secid": "T",
                        "ticker": "T",
                        "secname": "AT&amp;T INC COM USD1"
                    },
                    "accountlist": [
                        {
                            "acctnum": "RJL000001",
                            "acctkey": "RJL000001",
                            "acctype": "Margin",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        },
                        {
                            "acctnum": "RJL000013",
                            "acctkey": "RJL000013",
                            "lastmarketclose": {
                                "percent": 10,
                                "value": 658,
                                "increase": false
                            },
                            "lastmonthend": {
                                "percent": 2,
                                "value": 650,
                                "increase": true
                            },
                            "lastquarterend": {
                                "percent": 10,
                                "value": 858,
                                "increase": false
                            },
                            "monthtodate": {
                                "percent": 5,
                                "increase": false
                            },
                            "quartertodate": {
                                "percent": 7,
                                "increase": true
                            },
                            "yeartodate": {
                                "percent": 3,
                                "increase": true
                            },
                            "last3years": {
                                "percent": 10,
                                "increase": false
                            },
                            "last5years": {
                                "percent": 10,
                                "increase": false
                            }
                        }
                    ]
                }
            ]
        }
    ]
};

export const performanceSummaryMockData = {
    "accountSummary": [
        {
            "month": "6",
            "year": "2020",
            "amount": '3900000.00'
        },
        {
            "month": "7",
            "year": "2020",
            "amount": '3000000'
        },
        {
            "month": "8",
            "year": "2020",
            "amount": '5000000'
        },
        {
            "month": "9",
            "year": "2020",
            "amount": '2000000'
        },
        {
            "month": "10",
            "year": "2020",
            "amount": '5200000'
        },
        {
            "month": "11",
            "year": "2020",
            "amount": '8550000'
        },
        {
            "month": "12",
            "year": "2020",
            "amount": '5500000'
        }
    ]
};