import React from 'react';
export function AccountInfoColumns(showUnMaskedNumber) {
    var columns = [];
    columns = [
        {
            dataField: 'accountnickname', text: "Account #", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div>{acctFormatter(row)}</div>;
            }
        },
        {
            dataField: 'abanumber', text: "ABA Number", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'accountnumber', text: "Checking Account Number", sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            formatter: (cell, row) => {
                if (row.showUnmaskedNumber) {
                    return <div onClick={() => showUnMaskedNumber(row, false)} >{row.accountnumber}</div>;
                }
                else
                    return <div onClick={() => showUnMaskedNumber(row, true)} > {row.maskedCheckingAccount }</div >;
            }
        }
    ];

    return columns;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

function acctFormatter(row) {
    //let displayNames = getAccountNames(accountlist, key, IndLongShort.LONG, IndLongShort.SINGLEVIEW);
    return (<div title={row.titleDisplayName}>{row.accountnickname}</div>)
}