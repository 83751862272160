import React from 'react';
import { formatMoney, roundvalue } from '../../../../common/constants.jsx';
import { getAssetColorConfig } from '../../../../common/assetschemautilities';
export function getAssetAllocationSummaryColumns() {
    const columns = [
        {
            dataField: 'name',
            text: 'Asset Class',
            headerAlign: 'left',
            classes: 'RCM_common_table_column_text',
            sort: false,
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>0</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'percentage',
            text: 'Allocation',
            sort: false,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>0%</span>;
                else return <span >{roundvalue(cell, 2)}%</span >;
            }

        },
        {
            dataField: 'value',
            text: 'Value',
            sort: false,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>$0</span>;
                if (cell) return <span>{formatMoney(cell)}</span>;
            }
        }
    ]
    return columns
}

export function renderRowsByCategory(assetallocationresponse, assetcolorRes, dropdownClassValue) {
    let i;
    let rows = [];
    for (i = 0; i < assetallocationresponse.length; i++) {
        if (assetallocationresponse[i].assetcat !== null) {
            let assetObj = getAssetColorConfig(assetallocationresponse[i].assetcat, assetallocationresponse[i].assetcatcd, assetcolorRes, dropdownClassValue)
            rows.push(
                {
                    name: assetallocationresponse[i].assetcat,
                    percentage: assetallocationresponse[i].mktvalpct,
                    value: assetallocationresponse[i].mktval,
                    colorCd: assetObj.colorcd,
                    displaySeq: assetObj.displayseq

                }
            )
        }
    }
    if (rows && rows.length > 0) {
        rows.sort(function (a, b) { return a.displaySeq - b.displaySeq; });
    }
    return rows;
}