import { ADD_TRANSFER, CANCEL_TRANSFER, FETCH_SCHEDULED_TRANSFERS, FETCH_PAST_TRANSFERS, REMOVE_RECENTCONF, UPDATE_ACCOUNT_INFORMATION   } from  "../actions/actiontypes.jsx";

const initialState = {
    pastTransfersList: [],
    scheduledTransfersList: [],
    recentTransferConf: "",
    accountList: []
}

export function MoneyMovementReducer(state=initialState, action)
{
    switch(action.type)
    {
        case FETCH_PAST_TRANSFERS:
        {
            return {
                ...state, 
                pastTransfersList : action.payload
            }
        }

        case FETCH_SCHEDULED_TRANSFERS:
        {
            return{
                ...state, 
                scheduledTransfersList: action.payload
            }
        }

        case ADD_TRANSFER:
        {
            return{
                ...state,
                scheduledTransfersList: action.payload.list,
                recentTransferConf: action.payload.recentConf
            }
        }

        case REMOVE_RECENTCONF:
        {
            return{
                ...state,
                recentTransferConf: ""
            }
        }
        case UPDATE_ACCOUNT_INFORMATION:
            {
                return {
                    ...state,
                    accountList: action.payload
                }
            }
        default:
        return state;
    }
}