import React from "react";
import {
  roundvalue,
  formatMoney,
  roundUnitValue,
  GetNumericValue,
  getAccountDisplayName,
  IndLongShort,
  toDate,
} from "./../../../../common/constants.jsx";
import {
  printHoldingDetailsReport,
  printTasHoldingExcelReport,
} from "../../../../common/printreport.jsx";
import { generateTasHoldingExcelReport } from "../../../../common/excelreport.jsx";
import orderBy from "lodash/orderBy";
//columns for child table shown on expanding specific row
export function securityColumns(
  totalST,
  totalLT,
  totalProceeds,
  totalgainloss,
  totalglpct,
  totalcostbasis,
  rowcol,
  isrowexpanded,
  rowsortevent
) {
  var columns = [];
  if (isrowexpanded || rowcol.length === 0) {
    columns = [
      {
        dataField: "SECID",
        text: "Symbol",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        formatter: (cell, row) => {
          return <div className="RCM_symbol_text_bold">{cell}</div>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "Total:",
        footerAlign: (column, colIndex) => "left",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      },
      {
        dataField: "SECNAME",
        text: "Description",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        formatter: (cell, row) => {
          return <div>{cell}</div>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      },
      {
        dataField: "UNIT",
        text: "Quantity",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },

      {
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      },
      {
        dataField: "PROCEEDS",
        text: "Net Proceeds",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalProceeds === null ||
            totalProceeds === "" ||
            totalProceeds === undefined
          )
            return <span>-</span>;
          else if (totalProceeds === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalProceeds, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      }, 
      {
        dataField: "SHORTTERMGAINLOSS",
        text: "Short Term",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: {wordBreak: 'break-word'},
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalST === null ||
            totalST === "" ||
            totalST === undefined
          )
            return <span>-</span>;
          else if (totalST === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalST, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      },
      {
        dataField: "LONGTERMGAINLOSS",
        text: "Long Term",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: {wordBreak: 'break-word'},
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalLT === null ||
            totalLT === "" ||
            totalLT === undefined
          )
            return <span>-</span>;
          else if (totalLT === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalLT, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      },
       
      {
        dataField: "GAINLOSS",
        text: "Net Gain/loss",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "center",
        // headerStyle: { paddingLeft: "7%" },
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.GAINLOSS !== null &&
            row.GAINLOSS !== undefined &&
            row.GAINLOSS !== 0
              ? formatMoney(roundvalue(row.GAINLOSS, 2))
              : GetNumericValue(row.GAINLOSS);
          var marketValuePerc =
            row.GAINLOSSPCT !== null &&
            row.GAINLOSSPCT !== undefined &&
            row.GAINLOSSPCT !== 0
              ? roundvalue(row.GAINLOSSPCT, 2) + "%"
              : GetNumericValue(row.GAINLOSSPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.GAINLOSS)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.GAINLOSSPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== 0
              ? formatMoney(totalgainloss)
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== 0
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: (column, colIndex) => "right",
      },
    ];
  } else {
    columns.push({
      dataField: "SECID",
      text: "Symbol",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      formatter: (cell, row) => {
        return <div className="RCM_symbol_text_bold">{cell}</div>;
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: "Total:",
      footerAlign: (column, colIndex) => "left",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    });

    if (checkIfFieldEmpty(rowcol, "SECNAME")) {
      columns.push({
        dataField: "df1",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "SECNAME",
        text: "Description",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        formatter: (cell, row) => {
          return <div>{cell}</div>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return <span>{cell}</span>;
        },
      });
    }

    if (checkIfFieldEmpty(rowcol, "UNIT")) {
      columns.push({
        dataField: "df2",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "UNIT",
        text: "Quantity",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }

    if (checkIfFieldEmpty(rowcol, "COSTBASIS")) {
      columns.push({
        dataField: "df4",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      });
    }

    if (checkIfFieldEmpty(rowcol, "PROCEEDS")) {
      columns.push({
        dataField: "df5",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "PROCEEDS",
        text: "Net Proceeds",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalProceeds === null ||
            totalProceeds === "" ||
            totalProceeds === undefined
          )
            return <span>-</span>;
          else if (totalProceeds === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalProceeds, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
    if (checkIfFieldEmpty(rowcol, "SHORTTERMGAINLOSS")) {
      columns.push({
        dataField: "df6_",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "SHORTTERMGAINLOSS",
        text: "Short Term",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: {wordBreak: 'break-word'},
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalST === null ||
            totalST === "" ||
            totalST === undefined
          )
            return <span>-</span>;
          else if (totalST === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalST, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
    if (checkIfFieldEmpty(rowcol, "LONGTERMGAINLOSS")) {
      columns.push({
        dataField: "df7_",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "LONGTERMGAINLOSS",
        text: "Long Term",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        headerStyle: {wordBreak: 'break-word'},
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalLT === null ||
            totalLT === "" ||
            totalLT === undefined
          )
            return <span>-</span>;
          else if (totalLT === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalLT, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
   

    if (
      checkIfFieldEmpty(rowcol, "GAINLOSS") &&
      checkIfFieldEmpty(rowcol, "GAINLOSSPCT")
    ) {
      columns.push({
        dataField: "df8",
        text: "",
        isDummyField: true,
        footer: "",
      });
    } else {
      columns.push({
        dataField: "GAINLOSS",
        text: "Net Gain/loss",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "center",
        // headerStyle: { paddingLeft: "7%" },
        classes: "RCM_common_table_column_number",
        formatter: (cell, row) => {
          var marketValue =
            row.GAINLOSS !== null &&
            row.GAINLOSS !== undefined &&
            row.GAINLOSS !== 0
              ? formatMoney(roundvalue(row.GAINLOSS, 2))
              : GetNumericValue(row.GAINLOSS);
          var marketValuePerc =
            row.GAINLOSSPCT !== null &&
            row.GAINLOSSPCT !== undefined &&
            row.GAINLOSSPCT !== 0
              ? roundvalue(row.GAINLOSSPCT, 2) + "%"
              : GetNumericValue(row.GAINLOSSPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.GAINLOSS)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.GAINLOSSPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== 0
              ? formatMoney(totalgainloss)
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== 0
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
  }
  return columns;
}

//method to get child table rows on expand used for security and Account tab
export function renderRowsBySecurity(rowdata) {
  let datarows = [];
  for (var i = 0; i < rowdata.length; i++) {
    let d = rowdata[i];
    datarows.push({
      UNQID: d.CUSIPNUM,
      SECID: d.SECNAME ? d.SECNAME : null,
      SECNAME: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
      UNIT: d.NUMSHRS ? d.NUMSHRS : null,
      COSTBASIS: d.COSTBASIS ? d.COSTBASIS : null,
      PROCEEDS: d.STKBNDAMT ? d.STKBNDAMT : null,
      GAINLOSS: d.PROFIT ? d.PROFIT : null,
      GAINLOSSPCT: d.PROFITPCT ? d.PROFITPCT : null,
      INVPOSLIST: d.DETAILSUMMARY,
      SHORTTERMGAINLOSS: d.SHRTGAINLOSS ? d.SHRTGAINLOSS : null,
      LONGTERMGAINLOSS: d.LNGGAINLOSS ? d.LNGGAINLOSS : null,
      tasdisallowed: d.WASHSALELOSSDISALLOWEDAMT
        ? d.WASHSALELOSSDISALLOWEDAMT
        : null,
      WashSaleST: d.WashSaleST ? d.WashSaleST : null,
      WashSaleLT: d.WashSaleLT ? d.WashSaleLT : null,
    });
  }
  return datarows;
}

//method to get child table rows on expand used for security and Account tab
export function renderRowsBySecurityChild(
  rowdata,
  childColumnToSort,
  childColumnSortOrder,
  accountlist
) {
  let datarows = [];
  if (childColumnToSort === "SECID") childColumnToSort = "ACCOUNTNICKNAME";
  if (rowdata.INVPOSLIST && rowdata.INVPOSLIST.length > 0) {
    for (var i = 0; i < rowdata.INVPOSLIST.length; i++) {
      let d = rowdata.INVPOSLIST[i];
      datarows.push({
        CUSIP: d.CUSIPNUM,
        ACCTNUM: d.ACCT ? d.ACCT : null,
        GAINLOSS: d.PROFIT ? d.PROFIT : null,
        GAINLOSSPCT: d.PROFITPCT ? d.PROFITPCT : null,
        UNIT: d.NUMSHRS ? d.NUMSHRS : null,
        COSTBASIS: d.COSTBASIS ? d.COSTBASIS : null,
        PROCEEDS: d.STKBNDAMT ? d.STKBNDAMT : null,
        LOTS: d.DETAIL ? d.DETAIL : [],
        SECID: d.SECNAME ? d.SECNAME : null,
        SECNAME: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
        ACCTKEY: d.ACCTKEY,
        SHORTTERMGAINLOSS: d.SHRTGAINLOSS ? d.SHRTGAINLOSS : null,
        LONGTERMGAINLOSS: d.LNGGAINLOSS ? d.LNGGAINLOSS : null,
        WashSaleST: d.WashSaleST ? d.WashSaleST : null,
        WashSaleLT: d.WashSaleLT ? d.WashSaleLT : null,
        ACCOUNTNICKNAME: getAccountDisplayName(
          accountlist,
          d.ACCTKEY,
          IndLongShort.SINGLEVIEW
        ),
      });
    }
    if (childColumnToSort !== "" && childColumnSortOrder !== "") {
      if (
        childColumnToSort === "ACCOUNTNICKNAME" ||
        childColumnToSort === "ACCTYPE"
      ) {
        datarows = orderBy(
          datarows,
          [(row) => row[childColumnToSort].toLowerCase()],
          [childColumnSortOrder]
        );
      } else {
        datarows = orderBy(
          datarows,
          [childColumnToSort],
          [childColumnSortOrder]
        );
      }
    }

    return datarows;
  }
  return datarows;
}
export function accountColumns(
  totalproceeds,
  totalgainloss,
  totalglpct,
  accounts,
  rowcol,
  isrowexpanded,
  totalcostbasis,
  rowsortevent,
  totalShortTermGL,
  totalLongTermGL
) {
  var headercolumnsbyaccount = [];
  if (isrowexpanded === true || rowcol.length === 0) {
    headercolumnsbyaccount = [
      {
        dataField: "ACCOUNTNICKNAME",
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        text: "Account",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "Total:",
        footerAlign: (column, colIndex) => "left",
        formatter: (cell, row) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else return acctFormatter(accounts, cell, row.ACCTKEY);
        },
      },
      {
        dataField: "ACCTYPE",
        headerAlign: "left",
        classes: "RCM_common_table_column_text",
        text: "Description",
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
        footerAlign: (column, colIndex) => "left",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>&nbsp;</span>;
          else return <span>&nbsp;</span>;
        },
      },
      {
        dataField: "UNIT",
        text: "Quantity",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      },
      {
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      },
      {
        dataField: "PROCEEDS",
        text: "Net Proceeds",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalproceeds === null ||
            totalproceeds === "" ||
            totalproceeds === undefined
          )
            return <span>-</span>;
          else if (totalproceeds === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalproceeds, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      },
      {
        dataField: "SHORTTERMGAINLOSS",
        text: "Short Term",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else
            return (
              <span className={GetClassNameForSTLT(cell)}>
                {formatMoney(roundvalue(cell, 2))}
              </span>
            );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalShortTermGL === null ||
            totalShortTermGL === "" ||
            totalShortTermGL === undefined
          )
            return <span>-</span>;
          else if (totalShortTermGL === 0) return <span>$0</span>;
          else
            return (
              <span className={GetClassNameForSTLT(totalShortTermGL)}>
                {formatMoney(roundvalue(totalShortTermGL, 2))}
              </span>
            );
        },
        footerAlign: (column, colIndex) => "right",
      },
      {
        dataField: "LONGTERMGAINLOSS",
        text: "Long Term",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else
            return (
              <span className={GetClassNameForSTLT(cell)}>
                {formatMoney(roundvalue(cell, 2))}
              </span>
            );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalLongTermGL === null ||
            totalLongTermGL === "" ||
            totalLongTermGL === undefined
          )
            return <span>-</span>;
          else if (totalLongTermGL === 0) return <span>$0</span>;
          else
            return (
              <span className={GetClassNameForSTLT(totalLongTermGL)}>
                {formatMoney(roundvalue(totalLongTermGL, 2))}
              </span>
            );
        },
        footerAlign: (column, colIndex) => "right",
      },
      {
        dataField: "GAINLOSS",
        text: "Net Gain/Loss",
        headerAlign: "center",
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        formatter: (cell, row) => {
          var marketValue =
            row.GAINLOSS !== null &&
            row.GAINLOSS !== undefined &&
            row.GAINLOSS !== 0
              ? formatMoney(roundvalue(row.GAINLOSS, 2))
              : GetNumericValue(row.GAINLOSS);
          var marketValuePerc =
            row.GAINLOSSPCT !== null &&
            row.GAINLOSSPCT !== undefined &&
            row.UNGLPCT !== 0
              ? roundvalue(row.GAINLOSSPCT, 2) + "%"
              : GetNumericValue(row.GAINLOSSPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.GAINLOSS)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.GAINLOSSPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== ""
              ? formatMoney(roundvalue(totalgainloss, 2))
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== ""
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: (column, colIndex) => "right",
      },
    ];
  } else {
    //acct num is mandatoty field so not adding check here
    headercolumnsbyaccount.push({
      dataField: "ACCOUNTNICKNAME",
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      text: "Account",
      sort: true,
      hidden: checkIfFieldEmpty(rowcol, "ACCTNUM") && isrowexpanded === false,
      sortCaret: (order, column) => customCaretSort(order, column),
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: "Total:",
      footerAlign: (column, colIndex) => "left",
      formatter: (cell, row) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return acctFormatter(accounts, cell, row.ACCTKEY);
      },
    });
    headercolumnsbyaccount.push({
      dataField: "df1",
      isDummyField: true,
      text: "",
      footer: "",
    });
    if (checkIfFieldEmpty(rowcol, "UNIT")) {
      headercolumnsbyaccount.push({
        dataField: "df2",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "UNIT",
        text: "Quantity",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>0</span>;
          else return <span>{roundUnitValue(cell)}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: "",
      });
    }
    if (checkIfFieldEmpty(rowcol, "COSTBASIS")) {
      headercolumnsbyaccount.push({
        dataField: "df4",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "COSTBASIS",
        text: "Cost Basis",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalcostbasis === null ||
            totalcostbasis === "" ||
            totalcostbasis === undefined
          )
            return <span>-</span>;
          else if (totalcostbasis === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalcostbasis, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
    if (checkIfFieldEmpty(rowcol, "PROCEEDS")) {
      headercolumnsbyaccount.push({
        dataField: "df5",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "PROCEEDS",
        text: "Net Proceeds",
        headerAlign: "right",
        classes: "RCM_common_table_column_number",
        formatter: (cell) => {
          if (cell === null || cell === "" || cell === undefined)
            return <span>-</span>;
          else if (cell === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          if (
            totalproceeds === null ||
            totalproceeds === "" ||
            totalproceeds === undefined
          )
            return <span>-</span>;
          else if (totalproceeds === 0) return <span>$0</span>;
          else return <span>{formatMoney(roundvalue(totalproceeds, 2))}</span>;
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
    headercolumnsbyaccount.push({
      dataField: "SHORTTERMGAINLOSS",
      text: "Short Term",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else
          return (
            <span className={GetClassNameForSTLT(cell)}>
              {formatMoney(roundvalue(cell, 2))}
            </span>
          );
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: (cell) => {
        if (
          totalShortTermGL === null ||
          totalShortTermGL === "" ||
          totalShortTermGL === undefined
        )
          return <span>-</span>;
        else if (totalShortTermGL === 0) return <span>$0</span>;
        else
          return (
            <span className={GetClassNameForSTLT(totalShortTermGL)}>
              {formatMoney(roundvalue(totalShortTermGL, 2))}
            </span>
          );
      },
      footerAlign: (column, colIndex) => "right",
    });
    headercolumnsbyaccount.push({
      dataField: "LONGTERMGAINLOSS",
      text: "Long Term",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else
          return (
            <span className={GetClassNameForSTLT(cell)}>
              {formatMoney(roundvalue(cell, 2))}
            </span>
          );
      },
      onSort: (field, order) => {
        rowsortevent(field, order);
      },
      footer: (cell) => {
        if (
          totalLongTermGL === null ||
          totalLongTermGL === "" ||
          totalLongTermGL === undefined
        )
          return <span>-</span>;
        else if (totalLongTermGL === 0) return <span>$0</span>;
        else
          return (
            <span className={GetClassNameForSTLT(totalLongTermGL)}>
              {formatMoney(roundvalue(totalLongTermGL, 2))}
            </span>
          );
      },
      footerAlign: (column, colIndex) => "right",
    });
    if (
      checkIfFieldEmpty(rowcol, "GAINLOSS") &&
      checkIfFieldEmpty(rowcol, "GAINLOSSPCT")
    ) {
      headercolumnsbyaccount.push({
        dataField: "df8",
        isDummyField: true,
        text: "",
        footer: "",
      });
    } else {
      headercolumnsbyaccount.push({
        dataField: "GAINLOSS",
        text: "Net Gain/Loss",
        headerAlign: "center",
        // headerStyle: { paddingLeft: "7%" },
        classes: "RCM_common_table_column_number",
        sort: true,
        sortCaret: (order, column) => customCaretSort(order, column),
        formatter: (cell, row) => {
          var marketValue =
            row.GAINLOSS !== null &&
            row.GAINLOSS !== undefined &&
            row.GAINLOSS !== 0
              ? formatMoney(roundvalue(row.GAINLOSS, 2))
              : GetNumericValue(row.GAINLOSS);
          var marketValuePerc =
            row.GAINLOSSPCT !== null &&
            row.GAINLOSSPCT !== undefined &&
            row.GAINLOSSPCT !== 0
              ? roundvalue(row.GAINLOSSPCT, 2) + "%"
              : GetNumericValue(row.GAINLOSSPCT, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(row.GAINLOSS)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidth">
                <span className={GetClassName(row.GAINLOSSPCT)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        onSort: (field, order) => {
          rowsortevent(field, order);
        },
        footer: (cell) => {
          var marketValue =
            totalgainloss !== null &&
            totalgainloss !== undefined &&
            totalgainloss !== ""
              ? formatMoney(roundvalue(totalgainloss, 2))
              : GetNumericValue(totalgainloss);
          var marketValuePerc =
            totalglpct !== null && totalglpct !== undefined && totalglpct !== ""
              ? roundvalue(totalglpct, 2) + "%"
              : GetNumericValue(totalglpct, true);
          return (
            <div className="RCM_displayflex">
              <div className="RCM_Spanwidthone">
                <span className={GetClassName(totalgainloss)}>
                  {marketValue}
                </span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="RCM_Spanwidthfooter">
                <span className={GetClassName(totalglpct)}>
                  {marketValuePerc}
                </span>
              </div>
            </div>
          );
        },
        footerAlign: (column, colIndex) => "right",
      });
    }
  }
  return headercolumnsbyaccount;
}

//child columns for account expand view
export const accountchildcolumns = [
  {
    dataField: "SECID",
    text: "",
    headerAlign: "left",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    classes: "RCM_common_table_column_text",
    formatter: (cell, row) => {
      return <div className="RCM_symbol_text_bold">{cell}</div>;
    },
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else return <span>{cell}</span>;
    },
  },
  {
    dataField: "SECNAME",
    text: "",
    headerAlign: "left",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    classes: "RCM_common_table_column_text",
    formatter: (cell, row) => {
      return <div>{cell}</div>;
    },
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else return <span>{cell}</span>;
    },
  },
  {
    dataField: "UNIT",
    text: "",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerAlign: "right",
    classes: "RCM_common_table_column_number",
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else if (cell === 0) return <span>0</span>;
      else return <span>{roundUnitValue(cell)}</span>;
    },
  },

  {
    dataField: "COSTBASIS",
    text: "",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerAlign: "right",
    classes: "RCM_common_table_column_number",
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else if (cell === 0) return <span>$0</span>;
      else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
    },
  },
  {
    dataField: "PROCEEDS",
    text: "",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerAlign: "right",
    classes: "RCM_common_table_column_number",
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else if (cell === 0) return <span>$0</span>;
      else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
    },
  },
  {
    dataField: "SHORTTERMGAINLOSS",
    text: "",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerAlign: "right",
    classes: "RCM_common_table_column_number",
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else if (cell === 0) return <span>$0</span>;
      else
        return (
          <span className={GetClassNameForSTLT(cell)}>
            {formatMoney(roundvalue(cell, 2))}
          </span>
        );
    },
  },
  {
    dataField: "LONGTERMGAINLOSS",
    text: "",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerAlign: "right",
    classes: "RCM_common_table_column_number",
    formatter: (cell) => {
      if (cell === null || cell === "" || cell === undefined)
        return <span>-</span>;
      else if (cell === 0) return <span>$0</span>;
      else
        return (
          <span className={GetClassNameForSTLT(cell)}>
            {formatMoney(roundvalue(cell, 2))}
          </span>
        );
    },
  },
 
  {
    dataField: "GAINLOSS",
    text: "",
    sort: true,
    sortCaret: (order, column) => customCaretSort(order, column),
    headerAlign: "right",
    classes: "RCM_common_table_column_number",
    formatter: (cell, row) => {
      var marketValue =
        row.GAINLOSS !== null &&
        row.GAINLOSS !== undefined &&
        row.GAINLOSS !== 0
          ? formatMoney(roundvalue(row.GAINLOSS, 2))
          : GetNumericValue(row.GAINLOSS);
      var marketValuePerc =
        row.GAINLOSSPCT !== null &&
        row.GAINLOSSPCT !== undefined &&
        row.GAINLOSSPCT !== 0
          ? roundvalue(row.GAINLOSSPCT, 2) + "%"
          : GetNumericValue(row.GAINLOSSPCT, true);
      return (
        <div className="RCM_displayflex">
          <div className="RCM_Spanwidthone">
            <span className={GetClassName(row.GAINLOSS)}>{marketValue}</span>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="RCM_Spanwidth">
            <span className={GetClassName(row.GAINLOSSPCT)}>
              {marketValuePerc}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    dataField: "dummy",
    isDummyField: true,
    text: "...",
    classes: "RCM_common_table_column_number RCM_noWrap",
    formatter: (cell) => {
      return (
        <span>
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
          <span className="RCM_holding_dot"></span>
        </span>
      );
    },
  },
];

function customCaretSort(order, column) {
  if (!order) return <span className="RCM_Sort_arrow_icon"></span>;
  else if (order === "asc")
    return <span className="RCM_Sort_arrow_down_icon"></span>;
  else if (order === "desc")
    return <span className="RCM_Sort_arrow_up_icon"></span>;
  return null;
}

export function securitychildColumns(accounts) {
  //child columns for third level drill down in account tab
  var childColumns = [
    {
      dataField: "ACCOUNTNICKNAME",
      text: "",
      classes: "RCM_common_table_column_text",
      formatter: (cell, row) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return acctFormatter(accounts, cell, row.ACCTKEY);
      },
    },
    {
      dataField: "ACCTYPE",
      text: "",
      classes: "RCM_common_table_column_text",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>&nbsp;</span>;
        else return <span>&nbsp;</span>;
      }, //account type
    },
    {
      dataField: "UNIT",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValue(cell)}</span>;
      },
    },
    {
      dataField: "COSTBASIS",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "PROCEEDS",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "SHORTTERMGAINLOSS",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },
    {
      dataField: "LONGTERMGAINLOSS",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
    },   

    {
      dataField: "GAINLOSS",
      text: "",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.GAINLOSS !== null &&
          row.GAINLOSS !== undefined &&
          row.GAINLOSS !== 0
            ? formatMoney(roundvalue(row.GAINLOSS, 2))
            : GetNumericValue(row.GAINLOSS);
        var marketValuePerc =
          row.GAINLOSSPCT !== null &&
          row.GAINLOSSPCT !== undefined &&
          row.GAINLOSSPCT !== 0
            ? roundvalue(row.GAINLOSSPCT, 2) + "%"
            : GetNumericValue(row.GAINLOSSPCT, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.GAINLOSS)}>{marketValue}</span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.GAINLOSSPCT)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "dummy",
      isDummyField: true,
      text: "...",
      classes: "RCM_common_table_column_number RCM_noWrap ",
      formatter: (cell) => {
        return (
          <span>
            <span class="RCM_holding_dot"></span>
            <span class="RCM_holding_dot"></span>
            <span class="RCM_holding_dot"></span>
          </span>
        );
      },
    },
  ];
  return childColumns;
}

export function taschildcolumns(totalgainloss, totalglpct) {
  //child columns for third level drill down in account tab
  const childColumns = [
    {
      dataField: "AcquisitionDate",
      text: "Acquisition Date",
      sort: true,
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      sortCaret: (order, column) => customCaretSort(order, column),
      footer: "",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "SaleDate",
      text: "Sale Date",
      sort: true,
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      sortCaret: (order, column) => customCaretSort(order, column),
      footer: "",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "HoldingPeriod",
      text: "Holding Period",
      sort: true,
      headerAlign: "left",
      classes: "RCM_common_table_column_text",
      sortCaret: (order, column) => customCaretSort(order, column),
      footer: "",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else return <span>{cell}</span>;
      },
    },
    {
      dataField: "Quantity",
      text: "Quantity",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>0</span>;
        else return <span>{roundUnitValue(cell, 2)}</span>;
      },
      footer: "",
    },
    //{
    //    dataField: 'AvgUnitCost', text: "Unit Cost", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
    //        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
    //        else if (cell === 0) return <span>$0</span>;
    //        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
    //    }, footer: ""
    //},
    {
      dataField: "tasCostBasis",
      text: "Cost Basis",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
      footer: "",
    },
    {
      dataField: "tasProceeds",
      text: "Net Proceeds",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === "" || cell === undefined)
          return <span>-</span>;
        else if (cell === 0) return <span>$0</span>;
        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
      },
      footer: "",
    },
    {
      dataField: "tasdisallowed",
      text: "Wash sale disallowed",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === undefined) return <span>-</span>;
        else return <span>{cell}</span>;
      },
      footer: "",
    },
    {
      dataField: "tasWashSalePeriod",
      text: "Wash Sale Period",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell) => {
        if (cell === null || cell === undefined) return <span>-</span>;
        else return <span>{toDate(cell)}</span>;
      },
      footer: "",
    },
    //{
    //    dataField: 'RecentMktVal', text: "Value", sort: true, sortCaret: (order, column) => customCaretSort(order, column), headerAlign: 'right', classes: 'RCM_common_table_column_number', formatter: (cell) => {
    //        if (cell === null || cell === '' || cell === undefined) return <span>-</span>;
    //        else if (cell === 0) return <span>$0</span>;
    //        else return <span>{formatMoney(roundvalue(cell, 2))}</span>;
    //    }, footer: ""
    //},
    {
      dataField: "abc",
      text: "",
      isDummyField: true,
      footer: "Total:",
      footerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "GainLossAmt",
      text: "Net Gain/Loss",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      headerAlign: "right",
      classes: "RCM_common_table_column_number",
      formatter: (cell, row) => {
        var marketValue =
          row.GainLossAmt !== null &&
          row.GainLossAmt !== undefined &&
          row.GainLossAmt !== "" &&
          row.GainLossAmt !== 0
            ? formatMoney(row.GainLossAmt)
            : GetNumericValue(row.GainLossAmt);
        var marketValuePerc =
          row.tasGainLossPct !== null &&
          row.tasGainLossPct !== undefined &&
          row.tasGainLossPct !== "" &&
          row.tasGainLossPct !== 0
            ? roundvalue(row.tasGainLossPct, 2) + "%"
            : GetNumericValue(row.tasGainLossPct, true);

        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(row.GainLossAmt)}>
                {marketValue}
              </span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidth">
              <span className={GetClassName(row.tasGainLossPct)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
      footer: (cell) => {
        var marketValue =
          totalgainloss !== null &&
          totalgainloss !== undefined &&
          totalgainloss !== "" &&
          totalgainloss !== 0
            ? formatMoney(roundvalue(totalgainloss, 2))
            : GetNumericValue(totalgainloss);
        var marketValuePerc =
          totalglpct !== null &&
          totalglpct !== undefined &&
          totalglpct !== "" &&
          totalglpct !== 0
            ? roundvalue(totalglpct, 2) + "%"
            : GetNumericValue(totalglpct, true);
        return (
          <div className="RCM_displayflex">
            <div className="RCM_Spanwidthone">
              <span className={GetClassName(totalgainloss)}>{marketValue}</span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="RCM_Spanwidthfooter">
              <span className={GetClassName(totalglpct)}>
                {marketValuePerc}
              </span>
            </div>
          </div>
        );
      },
      footerAlign: (column, colIndex) => "right",
    },
  ];

  return childColumns;
}

//method to get child table rows on expand used for Account tab
export function renderRowsByAccount(
  rowdata,
  childColumnToSort,
  childColumnSortOrder
) {
  let datarows = [];
  if (childColumnToSort === "ACCOUNTNICKNAME") childColumnToSort = "SECID";
  if (childColumnToSort === "ACCTYPE") {
    childColumnToSort = "SECNAME";
  }
  if (rowdata.INVPOSLIST && rowdata.INVPOSLIST.length > 0) {
    for (var i = 0; i < rowdata.INVPOSLIST.length; i++) {
      let d = rowdata.INVPOSLIST[i];
      datarows.push({
        CUSIP: d.CUSIP,
        ACCTNUM: d.ACCT ? d.ACCT : null,
        GAINLOSS: d.PROFIT ? d.PROFIT : null,
        GAINLOSSPCT: d.PROFITPCT ? d.PROFITPCT : null,
        UNIT: d.NUMSHRS ? d.NUMSHRS : null,
        COSTBASIS: d.COSTBASIS ? d.COSTBASIS : null,
        PROCEEDS: d.STKBNDAMT ? d.STKBNDAMT : null,
        SECID: d.SECNAME ? d.SECNAME : null,
        SECNAME: d.SECDESCRIPTION ? d.SECDESCRIPTION : null,
        SEQID: d.SEQID ? d.SEQID : null,
        LOTS: d.DETAIL ? d.DETAIL : [],
        LONGTERMGAINLOSS: d.LONGAMOUNT ? d.LONGAMOUNT : null,
        SHORTTERMGAINLOSS: d.SHORTAMOUNT ? d.SHORTAMOUNT : null,
        WASHSALELOSSDISALLOWEDAMT: d.WASHSALELOSSDISALLOWEDAMT
          ? d.WASHSALELOSSDISALLOWEDAMT
          : null,
        WashSaleST: d.WashSaleST ? d.WashSaleST : null,
        WashSaleLT: d.WashSaleLT ? d.WashSaleLT : null,
      });
    }
    if (childColumnToSort !== "" && childColumnSortOrder !== "")
      if (childColumnToSort === "SECID" || childColumnToSort === "SECNAME") {
        datarows = orderBy(
          datarows,
          [(row) => row[childColumnToSort].toLowerCase()],
          [childColumnSortOrder]
        );
      } else {
        datarows = orderBy(
          datarows,
          [childColumnToSort],
          [childColumnSortOrder]
        );
      }

    return datarows;
  }
  return datarows;
}

export function renderRowsForAccount(rowdata, accountlist) {
  let datarows = [];
  if (rowdata) {
    rowdata.map((d, i) =>
      datarows.push({
        UNQID: d.GUID,
        ACCTNUM: d.ACCT ? d.ACCT : null,
        ACCTKEY: d.ACCTKEY ? d.ACCTKEY : null,
        UNIT: d.NUMSHRS ? d.NUMSHRS : null,
        COSTBASIS: d.COSTBASIS ? d.COSTBASIS : null,
        PROCEEDS: d.STKBNDAMT ? d.STKBNDAMT : null,
        GAINLOSS: d.PROFIT ? d.PROFIT : null,
        GAINLOSSPCT: d.PROFITPCT ? d.PROFITPCT : null,
        INVPOSLIST: d.DETAILSUMMARY,
        ACCOUNTNICKNAME: getAccountDisplayName(
          accountlist,
          d.ACCTKEY,
          IndLongShort.SINGLEVIEW
        ),
        SHORTTERMGAINLOSS: d.SHORTAMOUNT ? d.SHORTAMOUNT : null,
        LONGTERMGAINLOSS: d.LONGAMOUNT ? d.LONGAMOUNT : null,
        WASHSALELOSSDISALLOWEDAMT: d.WASHSALELOSSDISALLOWEDAMT
          ? d.WASHSALELOSSDISALLOWEDAMT
          : null,
        WashSaleST: d.WashSaleST ? d.WashSaleST : null,
        WashSaleLT: d.WashSaleLT ? d.WashSaleLT : null,
      })
    );
  }
  return datarows;
}

export function getDisplayName(account) {
  let accountNo =
    account.number === undefined ||
    account.number === null ||
    account.number === ""
      ? ""
      : account.number;
  let nickname =
    account.nickname === undefined ||
    account.nickname === null ||
    account.nickname === ""
      ? ""
      : account.nickname;
  let regtypecode =
    account.regtypecode === undefined ||
    account.regtypecode === null ||
    account.regtypecode === ""
      ? ""
      : " (" + account.regtypecode + ") ";

  if (accountNo === "All Accounts") {
    return accountNo;
  } else {
    return accountNo + " " + nickname + " " + regtypecode;
  }
}
export function generateExcel(props, totalgainlossamt, totalgainlosspct) {
  generateTasHoldingExcelReport(props, totalgainlossamt, totalgainlosspct);
}

//method to print data
export function printCustomWindow(table, props) {
  printTasHoldingExcelReport(table, props);
}
function GetClassName(value) {
  if (value === "" || value === null || value === undefined) {
    return "";
  } else if (value >= 0) {
    return "RCM_positiveval";
  } else if (value < 0) {
    return "RCM_negativeval";
  }
}
function GetClassNameForSTLT(value) {
  if (value === undefined || value === null || value === "" || value >= 0) {
    return "";
  } else if (value < 0) {
    return "RCM_negativeval";
  }
}

function acctFormatter(accountlist, cell, key) {
  return (
    <div title={getAccountDisplayName(accountlist, key, IndLongShort.LONG)}>
      {getAccountDisplayName(accountlist, key, IndLongShort.SINGLEVIEW)}
    </div>
  );
}

function checkIfFieldEmpty(rowcol, fieldname) {
  let filterrows = rowcol.filter(
    (acc) =>
      acc[fieldname] === null ||
      acc[fieldname] === "" ||
      acc[fieldname] === undefined
  );

  if (filterrows && filterrows.length === rowcol.length) {
    return true;
  }
  return false;
}

export const monthDropDownItems = [
  {
    label: "All",
    value: "",
  },
  {
    label: "January",
    value: "JAN",
  },
  {
    label: "February",
    value: "FEB",
  },
  {
    label: "March",
    value: "MAR",
  },
  {
    label: "April",
    value: "APR",
  },
  {
    label: "May",
    value: "MAY",
  },
  {
    label: "June",
    value: "JUN",
  },
  {
    label: "July",
    value: "JUL",
  },
  {
    label: "August",
    value: "AUG",
  },
  {
    label: "September",
    value: "SEP",
  },
  {
    label: "October",
    value: "OCT",
  },
  {
    label: "November",
    value: "NOV",
  },
  {
    label: "December",
    value: "DEC",
  },
];

export const yearDropDownItems = [
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2018",
    value: "2018",
  },
];
