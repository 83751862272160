import React, { Component } from 'react';
import { connect } from 'react-redux';
import EstimatedIncomeDetails from './estimatedincomeservicecalls.jsx';
import ErrorBoundary from '../../../../common/errorboundary.jsx'
import {  estimatedIncomeFooterMsgP1, estimatedIncomeFooterMsgP2 } from '../../../../common/constants.jsx';
import RCMToggleButton from '../../../../common/togglebutton.jsx';

class EstimatedProjectedIncomeDetails extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.getToggleValue = this.getToggleValue.bind(this);

        this.state = {
            tooltipOpen: false,
            viewToggleValue: this.getToggleValue()
        };
    }

    getToggleValue() {
        var currentRoute = this.props.location.pathname;
        if (currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity' || currentRoute === '/EstimatedIncomeAccount' || currentRoute === '/EstimatedProjectedIncomeDetails')
            return true;
        else
            return false;
    }
    componentDidMount() {
        if (this.props.location && this.props.location.view) {
            const viewstate = this.props.location.view;
            this.setState({ viewToggleValue: viewstate.viewBy === 'E' ? true : false });
            console.log(viewstate);
        }
        else {
            var currentRoute = this.props.location.pathname;
            if(currentRoute === '/EstimatedIncomeSummary' || currentRoute === '/EstimatedIncomeSecurity' || currentRoute === '/EstimatedIncomeAccount' || currentRoute === '/EstimatedProjectedIncomeDetails')
                this.setState({ viewToggleValue: true });
            else
                this.setState({ viewToggleValue: false });
        }
    }

    
    toggleView() {
        this.setState(prevState => ({
            viewToggleValue: !prevState.viewToggleValue
        }));
    }
    
    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    showToggleButton(){
        let res = false;

        if(this.props.preferences && this.props.preferences.pilotfeatures && Array.isArray(this.props.preferences.pilotfeatures)){
            for(let i=0 ; i< this.props.preferences.pilotfeatures.length; i++){
                let comp =  this.props.preferences.pilotfeatures[i];
                if(comp.componentname && comp.componentname === 'ProjectedIncome' && comp.active && comp.active === 'true')
                {   
                    res=  true;
                    break;
                }
            }
        }

        return res;
    }

    render() {
        let projectedIncome = (
            <div key='dvholding' className="RCM_detailsScreeen">
                <div className="row">
                    <div className="col-sm-12" key="lblholdingheadingrow">
                        <label className="RCM_tile_heading RCM_details_heading RCM_Toast_heading" key='lblholdingheading'>{
                            this.state.viewToggleValue ?
                                'HISTORICAL INCOME' : 'PROJECTED INCOME'
                        }
                        </label>
                        { this.showToggleButton() && (
                            <div className="RCM_text_hd RCM_Inline_Div" id="RCM_projectedIncome_toggle">
                                <RCMToggleButton
                                    selectedOption={this.state.viewToggleValue ? "Historical" : "Projected"}
                                    leftOption="Historical"
                                    rightOption="Projected"
                                    onToggle={() => this.toggleView()}
                                />
                            </div>
                                
                        )}
                    </div>
                </div>
                
                <ErrorBoundary>
                    <EstimatedIncomeDetails togglevalue={this.state.viewToggleValue} toggleView={this.toggleView}/>
                </ErrorBoundary>
                <div className="mt-2 RCM_footer_text">
                    <p>{estimatedIncomeFooterMsgP1}</p>
                    <p>{estimatedIncomeFooterMsgP2}</p>
                </div>
            </div>
        );

        return (
            projectedIncome
        );
    }
}
const mapStateToProps = state => {
    return {
        holdingsbyaccount: state.acct.holdingsbyaccount,
        holdingsbysecurity: state.acct.holdingsbysecurity,
        holdingsbycategory: state.acct.holdingsbycategory,
        render: state.acct.render,
        acct: state.acct,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        preferences: state.acct.preferences
    };
};

export default connect(mapStateToProps)(EstimatedProjectedIncomeDetails);