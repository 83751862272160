import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  billPayUIUrl,
  mmSummaryUrl,
  assetSchemaColorsConfigUrl,
  GetPreferencePilotValue,
} from "../../../common/constants";
import { connect } from "react-redux";
import { Get } from "../../../common/servicecalls";
import { _logException } from "../../../common/_logging";
import { AddAssetColor } from "../../../actions/accountsactions";

class BillPayConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hedaerValue:
        "Rockefeller Bill Payments provided by National Financial Services LLC",
      contentData:
        "By clicking on the “Continue” button below you will be transferred to a third party web site which is owned, operated and maintained by National Financial Services LLC. If you have any questions, please call your Financial Advisor.",
      isAccountsLoading: false,
      isAccountsFetchError: false,
      accounts: [],
      selectedAccount: "",
      isContinueDisable: false,
      isGetBillpayAccountSuccess: false,
      isBillPay2PilotFeature:
        GetPreferencePilotValue(
          { preferences: props.preferences },
          "BillPay2",
          "false"
        ) === "true"
          ? true
          : false,
    };
  }

  openBillPayWindow(props) {
    let url = "";
    if (this.state.isBillPay2PilotFeature) {
      let relayUrl = "";
      if (
        props.staticConfig &&
        props.staticConfig.bp &&
        props.staticConfig.bp.relay
      ) {
        relayUrl = props.staticConfig.bp.relay.replace(
          "{0}",
          this.state.selectedAccount
        );
      }
      const relayUri = this.state.selectedAccount
        ? `&RelayState=${encodeURIComponent(relayUrl)}`
        : "";
      url = `${billPayUIUrl}${relayUri}`;
    } else {
      url = billPayUIUrl;
    }
    window
      .open(
        url,
        "BillPay",
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=768"
      )
      .focus();
    props.toggleModal();
  }

  handleAccountSelectionChange(account) {
    this.setState((prev) => ({ ...prev, selectedAccount: account }));
  }

  checkStaticConfig() {
    if (!this.props.staticConfig) {
      Get(
        assetSchemaColorsConfigUrl,
        this.props.token,
        this.props.contextjson,
        this.props.profilejson,
        this.assetColorCancellationToken
      ).then((result) => {
        this.props.AddAssetColor(result.data);
        if(result.data &&
            result.data.bp &&
            result.data.bp.isdisbled === 1
        ){this.setState((prev) => ({ ...prev, isContinueDisable: true }));}
      });
    } 
    else {
      if (
        this.props.staticConfig.bp &&
        this.props.staticConfig.bp.isdisbled === 1
      ) {
        this.setState((prev) => ({ ...prev, isContinueDisable: true }));
      }
    }
  }

  handleOnOpened() {
    this.checkStaticConfig();
    if (this.state.isBillPay2PilotFeature) {
      this.setState((prev) => ({
        ...prev,
        isAccountsLoading: true,
        isAccountsFetchError: false,
        selectedAccount: "",
        isGetBillpayAccountSuccess: false
      }));
      const url = `${mmSummaryUrl}?SearchBy=BillPay`;
      Get(
        url,
        this.props.token,
        this.props.contextjson,
        this.props.profilejson,
        null
      )
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.value &&
            Array.isArray(res.data.value) &&
            res.data.value.length > 0 &&
            res.data.value[0].activities &&
            Array.isArray(res.data.value[0].accounts) &&
            res.data.value[0].accounts.length > 0
          ) {
            const accounts = res.data.value[0].accounts;
            this.setState((prev) => ({
              ...prev,
              accounts,
              isAccountsLoading: false,
              isAccountsFetchError: false,
              isGetBillpayAccountSuccess: true,
              selectedAccount:
                accounts && accounts.length > 0
                  ? accounts[0].accountnumber
                  : "",
            }));
          } else {
            this.setState((prev) => ({
              ...prev,
              accouns: [],
              isAccountsLoading: false,
              isAccountsFetchError: false,
              isGetBillpayAccountSuccess: false,
            }));
          }
        })
        .catch(() => {
          _logException("Error while fetching accounts for billpay");
          this.setState((prev) => ({
            ...prev,
            accouns: [],
            isAccountsLoading: false,
            isAccountsFetchError: true,
            isGetBillpayAccountSuccess: false,
          }));
        });
    }
  }

  getNickName(key) {
    if (key && this.props.accounts) {
      const account = this.props.accounts.filter((x) => x.key === key)?.[0];
      return account && account.nickname ? `( ${account.nickname} )` : "";
    }
    return "";
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalState}
          toggle={this.props.toggleModal}
          onOpened={() => this.handleOnOpened()}
          className="RCM_CM_modal"
          wrapClassName="RCM_CM_modal_wrapper"
          modalClassName="RCM_CM_modal_class"
          contentClassName="RCM_CM_modal_content"
          centered
          size="lg"
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.state.hedaerValue}
          </ModalHeader>
          <ModalBody>
            {this.props.staticConfig &&
              this.props.staticConfig.bp &&
              this.props.staticConfig.bp.message && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.staticConfig.bp.message,
                  }}
                ></div>
              )}
            {this.state.isAccountsLoading && (
              <div
                className="RCM_CM_Overlay_spinner"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              ></div>
            )}
            <>
              {!this.state.isAccountsLoading &&
                !this.state.isAccountsFetchError &&
                this.state.accounts &&
                this.state.accounts.length > 1 &&
                this.state.isBillPay2PilotFeature && (
                  <>
                    <div style={{ marginTop: "15px" }}>
                      Please select an account to pay bills from
                    </div>
                    <div className="RCM_BillPay_AccountSelection">
                      {this.state.accounts.map((account, index) => (
                        <div
                          onClick={() =>
                            this.handleAccountSelectionChange(
                              account.accountnumber
                            )
                          }
                          key={index}
                        >
                          <label className="RCM_icon_hand">
                            <input
                              type="radio"
                              value={account.accountnumber}
                              name="account-selection"
                              checked={
                                account.accountnumber ===
                                this.state.selectedAccount
                              }
                            />
                            <span
                              className="RCM_radio_span_assets"
                              style={{ width: "25rem" }}
                            >
                              {account.accountnumber}{" "}
                              {this.getNickName(account.accountkey)}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              <div style={{ marginTop: "15px" }}>{this.state.contentData}</div>
            </>
            {this.state.isAccountsFetchError && (
              <div>
                Billpay is not available at the moment, Please try after some
                time.
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="btn btn-secondary RCM_button_secondary RCM_select_btn"
              onClick={this.props.toggleModal}
            >
              Close
            </Button>
            <Button
                        color="btn btn-primary RCM_button_primary RCM_select_btn"
                        onClick={() => this.openBillPayWindow(this.props)}
                        disabled={this.state.isContinueDisable || !this.state.isGetBillpayAccountSuccess}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.acct.token,
    staticConfig: state.acct.assetschemacolorsconfig,
    contextjson: state.acct.contextjson,
    profilejson: state.acct.profilejson,
    preferences: state.acct.preferences,
    accounts: state.acct.accounts,
  };
};

const mapDispatchToProps = {
  AddAssetColor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillPayConfirmationModal);
