const MockData = {
    "alertsubscribid": "2e2375d2-cc4b-4763-9601-aed09793c485",
    "profileid": "0d518a68-7008-4ec5-9c9e-6d406ddbfdc0",
    "wsportaluserid": "",
    "availablealerttypes": [
        {
            "alerttype": "Trade",
            "alertname": "Trade",
            "alertdesc": "Trade Order & Execution"
        },
        {
            "alerttype": "Profile",
            "alertname": "Profile",
            "alertdesc": "New Account Profile"
        },
        {
            "alerttype": "Activity",
            "alertname": "Activity",
            "alertdesc": "Cash Activity"
        },
        {
            "alerttype": "AssetTransfers",
            "alertname": "Asset Transfers",
            "alertdesc": "Transfer Of Account"
        }
    ],
    "notificationoptions": [
        {
            "notificationid": "diksha_bhargava@test1.com",
            "notificationname": "Email",
            "notificationtype": "Email"
        },
        {
            "notificationid": "+8888888888",
            "notificationname": "SMS",
            "notificationtype": "SMS"
        }
    ],
    "registereddevices": null,
    "subscribedaccounts": [
        {
            "alerttypes": [
                {
                    "alerttype": {
                        "alerttype": "Activity",
                        "alertname": "Activity",
                        "alertdesc": "Cash Activity"
                    },
                    "alertsendtodevices": [

                    ]
                },
                {
                    "alerttype": {
                        "alerttype": "AssetTransfers",
                        "alertname": "Asset Transfers",
                        "alertdesc": "Transfer Of Account"
                    },
                    "alertsendtodevices": [
                        {
                            "deviceid": "diksha_bhargava@test1.com",
                            "devicename": "Email",
                            "devicetype": "Email",
                            "senddate": "0001-01-01T00:00:00"
                        }
                    ]
                },
                {
                    "alerttype": {
                        "alerttype": "Profile",
                        "alertname": "Profile",
                        "alertdesc": "New Account Profile"
                    },
                    "alertsendtodevices": [
                        {
                            "deviceid": "+8888888888",
                            "devicename": "SMS",
                            "devicetype": "SMS",
                            "senddate": "0001-01-01T00:00:00"
                        }
                    ]
                },
                {
                    "alerttype": {
                        "alerttype": "Trade",
                        "alertname": "Trade",
                        "alertdesc": "Trade Order & Execution"
                    },
                    "alertsendtodevices": [
                        {
                            "deviceid": "diksha_bhargava@test1.com",
                            "devicename": "Email",
                            "devicetype": "Email",
                            "senddate": "0001-01-01T00:00:00"
                        }
                    ]
                }
            ],
            "datesubscribed": "2020-03-24T08:01:49",
            "number": null,
            "accountregistration": null,
            "regtypecode": null,
            "repcode": null,
            "nickname": null,
            "role": null,
            "stakeholder": null,
            "managedaccount": null,
            "displaynickname": null,
            "key": "RRR-123456",
            "status": null,
            "type": null,
            "routingTransitNumber": null,
            "accountsourcetypecode": null,
            "accountsourcetypecd": null
        },
        {
            "alerttypes": [
                {
                    "alerttype": {
                        "alerttype": "Activity",
                        "alertname": "Activity",
                        "alertdesc": "Cash Activity"
                    },
                    "alertsendtodevices": [
                        {
                            "deviceid": "diksha_bhargava@test1.com",
                            "devicename": "Email",
                            "devicetype": "Email",
                            "senddate": "0001-01-01T00:00:00"
                        },
                        {
                            "deviceid": "+8888888888",
                            "devicename": "SMS",
                            "devicetype": "SMS",
                            "senddate": "0001-01-01T00:00:00"
                        }
                    ]
                },
                {
                    "alerttype": {
                        "alerttype": "Profile",
                        "alertname": "Profile",
                        "alertdesc": "New Account Profile"
                    },
                    "alertsendtodevices": [
                        {
                            "deviceid": "diksha_bhargava@test1.com",
                            "devicename": "Email",
                            "devicetype": "Email",
                            "senddate": "0001-01-01T00:00:00"
                        }
                    ]
                },
                {
                    "alerttype": {
                        "alerttype": "Trade",
                        "alertname": "Trade",
                        "alertdesc": "Trade Order & Execution"
                    },
                    "alertsendtodevices": [

                    ]
                }
            ],
            "datesubscribed": "2020-03-24T07:47:53",
            "number": null,
            "accountregistration": null,
            "regtypecode": null,
            "repcode": null,
            "nickname": null,
            "role": null,
            "stakeholder": null,
            "managedaccount": null,
            "displaynickname": null,
            "key": "RRR-234567",
            "status": null,
            "type": null,
            "routingTransitNumber": null,
            "accountsourcetypecode": null,
            "accountsourcetypecd": null
        }
    ]
}
export default MockData