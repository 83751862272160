import React from 'react'
import AviationConfirmationModal from './aviationModal';
import Watermark from '../../../common/watermark';

export default function Aviationview(props) {

    const flyExclusiveText = 'FlyExclusive is a premier owner/operator of private jet experiences that surpass expectations for quality, convenience, and safety.'
    const avationPortfolioText = 'An independent private aviation advisory firm that develops customized strategies to optimize private aviation portfolios and utilize proprietary market intelligence to proactively manage the costs, risks, logistics, billing and safety of private air travel.';

    return (
        <div className="container">
            {props.isProspectClient ? <Watermark/>:''}
            <img id="lifestyle_image" src="./../images/aviation_portfolio.png" alt= "flyExclusive" width="100%" />
            <div>
                <div className="row">
                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast" id="aviation_mail">
                            <div className="RCM_aviation_plane"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">FLYEXCLUSIVE</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">{flyExclusiveText}</div>
                            <div align="center" className="mt-4 RCM_lifestyle_btns">
                                <button className="btn btn-primary RCM_button_aviation_page" onClick={props.contactFlyExclusive} disabled={props.isProspectClient}>VIEW THE DETAILS</button>
                            </div>
                        </div>
                    </div>
                    <div className="col col-sm-6 mt-1">
                        <div className="RCM_Toast" id="aviation_plane">
                            <div className="RCM_aviation_plane"></div>
                            <div className="RCM_Toast_heading RCM_aviation_toast_heading_container mt-4">AVATION PORTFOLIO</div>
                            <div className="mt-2 RCM_aviation_toast_content_container">
                                <span>{avationPortfolioText}</span>
                            </div>
                            <div align="center" className="mt-4 RCM_lifestyle_btns">
                                <button className="btn btn-primary RCM_button_aviation_page" onClick={props.avationPortfolio} disabled={props.isProspectClient}>VIEW THE DETAILS</button>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </div>

            <AviationConfirmationModal  
                aviationModalToggle={props.aviationModalToggle}
                aviationModalState={props.aviationModalState}
                openSelectedSectionWindow={props.openSelectedSectionWindow}
                selectedSection={props.selectedSection}
            />
        </div>
    )
}
