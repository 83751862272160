import React, {Component} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class AssetAggregationModal extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return(
            <div>
                {
                        <Modal
                            isOpen={this.props.modalState}
                            toggle={this.props.toggleModal}
                            backdrop={false}
                            zIndex='9999'
                            className="RCM_CM_modal"
                            wrapClassName="RCM_CM_modal_wrapper"
                            modalClassName="RCM_CM_modal_class"
                            contentClassName="RCM_CM_modal_content"
                            scrollable={true}
                            centered
                            >
                            <ModalHeader toggle={this.props.toggleModal} charCode="X" ></ModalHeader>
                            <ModalBody>
                                <div className="row ml-3">
                                    <div className="col-sm-12">
                                        DELETE ACCOUNT
                                    </div>
                                </div>
                                <div className="row ml-3 mt-4">
                                    <div className="col-sm-12">
                                        Do you want to delete your account&nbsp;
                                            {this.props.aboutToDeleteAccount ? this.props.aboutToDeleteAccount.number : ''}&nbsp;
                                            from institution {this.props.aboutToDeleteAccount ? this.props.aboutToDeleteAccount.finame : ''} ?
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                    <Button className="btn btn-secondary RCM_button_secondary" onClick={this.props.toggleModal}>CANCEL</Button> {' '}
                                    <Button color="btn btn-primary RCM_button_primary RCM_select_btn" onClick={this.props.deleteAccept}>ACCEPT</Button>
                            </ModalFooter>
                        </Modal>
                }
            </div>
                
        );
    }
}

export default AssetAggregationModal;