import React, { useCallback } from "react";
import DriveItemIcon from "./DriveItemIcon";

const GetModifiedBy = (dd) => {
  if (
    dd &&
    dd.lastModifiedBy &&
    dd.lastModifiedBy.user &&
    dd.lastModifiedBy.user.displayName
  ) {
    return dd.lastModifiedBy.user.displayName;
  }
  return "";
};

const formatBytes = (bytes) => {
  if (bytes < 1024) return bytes + " bytes";
  else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KB";
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MB";
  else return (bytes / 1073741824).toFixed(3) + " GB";
};

const GetFileSize = (dd) => {
  if (dd) {
    return dd.folder ? dd.folder.childCount + " items" : formatBytes(dd.size);
  }
};

const DriveItem = ({ drive, selectDriveItem, onPreviewClick }) => {
  const modifiedBy = GetModifiedBy(drive);
  const fileSize = GetFileSize(drive);
  const handleDriveItemClick = useCallback(() => {
    window.open(drive.webUrl);
  }, [drive]);

  const handleOnDriveItemClick = useCallback(
    (drive) => {
      if (drive) {
        drive.folder ? selectDriveItem(drive) : onPreviewClick(drive);
      }
    },
    [onPreviewClick, selectDriveItem]
  );

  return (
    <>
      {drive && (
        <div className="RCM_Teamwork_DriveItem RCM_icon_hand">
          <div onClick={() => handleOnDriveItemClick(drive)}>
            <DriveItemIcon drive={drive} />
          </div>
          <div
            className="col col-sm-3 RCM_Teamwork_DriveItem_Center RCM_icon_hand RCM_Teamwork_Item_wordbreak"
            onClick={() => handleOnDriveItemClick(drive)}
          >
            {drive.name}
          </div>
          <div
            className="col col-sm-2 RCM_Teamwork_DriveItem_Center RCM_icon_hand RCM_Teamwork_Item_wordbreak"
            onClick={() => handleOnDriveItemClick(drive)}
          >
            {modifiedBy}
          </div>
          <div
            className="col col-sm-2 RCM_Teamwork_DriveItem_Center RCM_icon_hand RCM_Teamwork_Item_wordbreak"
            onClick={() => handleOnDriveItemClick(drive)}
          >
            {drive.lastModifiedDateTime
              ? drive.lastModifiedDateTime.split("T")[0]
              : ""}
          </div>
          <div className="col col-sm-1 RCM_Teamwork_DriveItem_Center RCM_Teamwork_Item_wordbreak">
            {fileSize}
          </div>
          <div
            className="col col-sm-2"
            style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
          >
            <div
              className="RCM_icon_hand RCM_Teamwork_sharepoint"
              title="View in sharepoint"
              onClick={handleDriveItemClick}
            ></div>
            {!drive.folder && (
              <div
                className="RCM_icon_hand RCM_Teamwork_preview"
                title="Preview file"
                onClick={() => onPreviewClick(drive)}
              ></div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DriveItem;
