import { GET_PERSONALISATION_DATA, TOGGLE_COMPONENT,SET_OTP_EXPIRY, SET_DASHBOARD_PREFERENCE} from "./actiontypes.jsx";

export function fetchPersonalisationData(data)
{
    return {
        type: GET_PERSONALISATION_DATA,
        payload: data
    }
}

export function toggleComponent(data)
{
    return {
        type: TOGGLE_COMPONENT,
        payload: data
    }
}

export function setDashboardPreference(data) {
    return {
        type: SET_DASHBOARD_PREFERENCE,
        payload: data
    }
}

export function setOtpExpiry(data) {
    return {
        type: SET_OTP_EXPIRY,
        payload: data
    }
}
