import React from "react";
import { connect } from "react-redux";
import ErrorBoundary from "../../../common/errorboundary.jsx";
import { AccountInfoTableView } from "./accountInfoTableView";
import { AccountInformationView } from "./accountInformationView";
import {
  getAccountInfoService,
  getWireAndAchInfoService,
} from "./accountInfoservice";
import Watermark from "../../../common/watermark";
import {
  spinner,
  getAccountNames,
  maskAccountTimeout,
} from "../../../common/constants";
import { UpdateAccountInformation } from "../../../actions/moneymovementactions";
import AccountDetailsContainer from "./AccountDetails/accountDetailsContainer.jsx";

class AccountInformation extends React.Component {
  constructor(props) {
    super(props);
    this.requesterId = "";
    if (this.props.portalid !== null && this.props.portalid !== undefined) {
      this.requesterId = this.props.portalid;
    }

    this.authToken = "";
    if (this.props.token !== null && this.props.token !== undefined) {
      this.authToken = this.props.token;
    }

    this.contextjson = null;
    if (
      this.props.contextjson !== null &&
      this.props.contextjson !== undefined
    ) {
      this.contextjson = this.props.contextjson;
    }

    this.profilejson = null;
    if (
      this.props.profilejson !== null &&
      this.props.profilejson !== undefined
    ) {
      this.profilejson = this.props.profilejson;
    }
    this.getAccountValues = this.getAccountValues.bind(this);
    this.showUnMaskedCheckingNumber =
      this.showUnMaskedCheckingNumber.bind(this);
    this.state = {
      isAccountInfoLoading: false,
      messages: [],
      isProspectClient: false,
      accounts: [],
      selectedAccount: { accountkey: "" },
      render: this.props.render,
      allAccounts: this.props.allAccount,
      accountInfo: {},
      showUnmaskedCheckingAccountNo: false,
    };
  }
  componentDidMount() {
    this.setState({ isAccountInfoLoading: true }, () => {
      this.execute();
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      this.setState({ isAccountInfoLoading: true }, () => {
        this.execute();
      });
    }
  }
  viewAccount = (row) => {
    this.setState({
      selectedAccount: row,
      showUnmaskedCheckingAccountNo: false,
    });
  };
  execute = () => {
    let searchby = "ChkEft";
    getAccountInfoService(
      this.authToken,
      this.contextjson,
      this.profilejson,
      searchby
    )
      .then((accountInfoData) => {
        if (accountInfoData) {
          let accounts =
            accountInfoData &&
            accountInfoData.value &&
            accountInfoData.value.length === 1
              ? accountInfoData.value[0].accounts
              : [];
          this.getAccountValues(accounts);
        } else {
          this.setState({ accounts: [], isAccountInfoLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ accounts: [], isAccountInfoLoading: false });
      });
    getWireAndAchInfoService(this.authToken, this.contextjson, this.profilejson)
      .then((accountData) => {
        if (accountData) {
          let accountInfo =
            accountData && accountData.accountinformation
              ? accountData.accountinformation
              : {};
          this.setState({ accountInfo: accountInfo });
        } else {
          this.setState({ accountInfo: {} });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ accountInfo: {} });
      });
  };

  getAccountValues = (data) => {
    let accountsArray = [];
    for (const [, x] of data.entries()) {
      let displayNames = getAccountNames(
        this.state.allAccounts,
        x.accountkey,
        true,
        true,
        true
      );
      accountsArray.push({
        ...x,
        maskedCheckingAccount:
          x.accountnumber && x.accountnumber !== null && x.accountnumber !== ""
            ? x.accountnumber
                .substr(0, x.accountnumber.length - 4)
                .replace(/[0-9a-zA-Z ]/g, "*") +
              x.accountnumber.substr(x.accountnumber.length - 4)
            : "",
        showUnmaskedNumber: false,
        accountnickname:
          displayNames && displayNames.shortName ? displayNames.shortName : "",
        titleDisplayName:
          displayNames && displayNames.longName ? displayNames.longName : "",
        singleViewDisplayName:
          displayNames && displayNames.singleView
            ? displayNames.singleView
            : "",
      });
    }
    this.setState({ accounts: accountsArray, isAccountInfoLoading: false });
    this.props.UpdateAccountInformation(accountsArray);
  };

  showUnMaskedNumber = (row, value) => {
    if (this.state.maskRow_timer) {
      clearTimeout(this.state.maskRow_timer);
    }
    let accounts = this.state.accounts.map((x) => ({
      ...x,
      showUnmaskedNumber: x.accountkey === row.accountkey ? value : false,
    }));
    this.setState({ accounts: accounts });
    if (value === true) {
      let timer = setTimeout(() => {
        let accounts = this.state.accounts.map((x) => ({
          ...x,
          showUnmaskedNumber: false,
        }));
        this.setState({ accounts: accounts });
      }, maskAccountTimeout);
      this.setState({ maskRow_timer: timer, accounts: accounts });
    }
  };
  showUnMaskedCheckingNumber = (value) => {
    this.setState({ showUnmaskedCheckingAccountNo: value });
    if (this.state.mask_timer) {
      clearTimeout(this.state.mask_timer);
    }
    if (value === true) {
      let timer = setTimeout(() => {
        this.setState({ showUnmaskedCheckingAccountNo: false });
      }, maskAccountTimeout);
      this.setState({
        showUnmaskedCheckingAccountNo: true,
        mask_timer: timer,
      });
    }
  };
  render() {
    const rowEvents = {
      onClick: (e, row) => {
        this.viewAccount(row);
      },
    };
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      clickToExpand: false,
      hideSelectColumn: true,
      classes: "RCM_grp_selection_row",
      selected: [this.state.selectedAccount.accountkey],
      onSelect: () => rowEvents,
    };
    return (
      <div className="container-fluid" key="AF001">
        <div
          className="row RCM_ManageGroup_heading_container RCM_alert_wrapper"
          key="AF002"
        >
          <div
            className="RCM_Toast_heading col-sm-12 col-xs-12 col-md-12 col-lg-12"
            key="AF003"
          >
            Account Information
          </div>
        </div>
        {this && this.state.isProspectClient ? <Watermark /> : ""}
        {this && this.state && this.state.isAccountInfoLoading ? (
          <div className="RCM_spinner" key="AF004">
            Loading Account Information{spinner()}
          </div>
        ) : (
          <div className="row" key="AF005">
            <div className="col-sm-6" key="AF006">
              <div
                className="RCM_Toast"
                id="RCM_manageExtAddAccount"
                key="AF007"
              >
                <ErrorBoundary>
                  <AccountInfoTableView
                    state={this.state}
                    rowEvents={rowEvents}
                    showUnMaskedNumber={this.showUnMaskedNumber}
                    selectRow={selectRow}
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div className="col-sm-6" key="AF008">
              <ErrorBoundary>
                <AccountInformationView
                  state={this.state}
                  showUnMaskedCheckingNumber={this.showUnMaskedCheckingNumber}
                />
              </ErrorBoundary>
              {this.props.profileResp &&
                this.props.profileResp.role &&
                ["Client", "3rdPartyMM", "ClientWithPlanM"].includes(
                  this.props.profileResp.role
                ) && (
                  <ErrorBoundary>
                    {this.state.selectedAccount && (
                      <AccountDetailsContainer
                        account={this.state.selectedAccount}
                      />
                    )}
                  </ErrorBoundary>
                )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    portalid: store.acct.portalid,
    token: store.acct.token,
    contextjson: store.acct.contextjson,
    profilejson: store.acct.profilejson,
    profileId: store.acct.profileId,
    profileResp: store.acct.profileResp,
    render: store.acct.render,
    allAccount: store.acct.accounts,
  };
}

const mapDispatchToProps = {
  UpdateAccountInformation,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
