import React from 'react';
import { MsgForNoData } from '../../../common/constants.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';

export function AddEditGroupModalPopup(props) {

    let groupNameInput;
    let addeditgroupmodalpopup = (
        <div>
            {props && props.state ?
                <Modal isOpen={props.state.modal} toggle={props.toggleModal} onOpened={() => onModalWindowOpened(groupNameInput)}
                    zIndex='9999'
                    className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                    centered >
                    <ModalHeader toggle={props.toggleModal} className="RCM_externalAssetModel_header RCM_manage_group_header">
                        {(props.state.action === 'edit' ? <label className="RCM_tile_heading" key='externalAssetModelheader'>EDIT GROUP NAME</label>
                            : <label className="RCM_tile_heading" key='externalAssetModelheader'>ADD GROUP</label>)}
                    </ModalHeader>
                    <ModalBody>

                        <FormGroup>
                            <Label for="groupname">Enter Group Name</Label>
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder=""
                                name="groupname"
                                className="RCM_form_control"
                                maxLength="50"
                                required
                                value={props.state.newGroupName}
                                onChange={props.handleFormChanges}
                                ref={ele => { groupNameInput = ele }}
                            />
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter className="RCM_manage_group_footer">
                        <button key="Dropdown15" type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.toggleModal}>CANCEL</button>{' '}
                        {(props.state.action === 'edit' ? <button key="Dropdown16" type="button" className="btn btn-primary RCM_button_primary" onClick={props.editGroupName}
                            disabled={props.state.newGroupName === ""} >SAVE</button>
                            : <button key="Dropdown16" type="button" className="btn btn-primary RCM_button_primary" onClick={props.addGroup}
                                disabled={props.state.newGroupName === ""} >ADD GROUP</button>)}
                        
                    </ModalFooter>
                </Modal>
            
                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return addeditgroupmodalpopup
}

function onModalWindowOpened(groupNameInput){
  if(groupNameInput){
    groupNameInput.focus();
  }
}



