import React from 'react';
import { MsgForNoData } from '../../../../common/constants.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';

export function AssetTypeChangeConfrimationPopup(props) {

    let assettypechangeconfrimationpopup = (
        <div>
            {props && props.state ?
                <Modal isOpen={props.state.openAssetTypeChangeConfrimationPopup} toggle={props.toggleAssetTypeChangeConfrimationPopup} 
                    className="RCM_CM_modal"
                     wrapClassName="RCM_CM_modal_wrapper"
                     modalClassName="RCM_CM_modal_class"
                     contentClassName="RCM_CM_modal_content"
                        zIndex='9999'
                        centered >
                    <ModalHeader toggle={props.toggleAssetTypeChangeConfrimationPopup} className="RCM_externalAssetModel_header RCM_manage_group_header">
                           
                                <label className="RCM_tile_heading">CONFIRMATION</label>
                        </ModalHeader>
                        <ModalBody>
 
                            <div><Label for="groupname">Changing the asset type, will lose all your unsaved data. Are you sure you want to change?</Label></div>
                            
                        </ModalBody>
                    <ModalFooter className="RCM_manage_group_footer">
                        <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={props.toggleAssetTypeChangeConfrimationPopup}>CANCEL</button> 
                        <button type="button" className="btn btn-primary RCM_button_primary" onClick={props.changeAssetType}>CHANGE</button> 
                        </ModalFooter>
                    </Modal>
            
                : <div>{MsgForNoData}</div>
            }
        </div>
    )
    return assettypechangeconfrimationpopup
}



