import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AccountHoldingdetails from './accountholdings/accountholding.jsx';
import SecurityHoldingdetails from './securityholdings/securityholding.jsx';
import AssetHoldingsdetails from './assetholdings/assetholding.jsx';
import { tabSelection, tabSelectionOnLoad, GetPreferencePilotValue } from './../../../../common/constants.jsx';
import { _logPageView } from '../../../../common/_logging';
import { withPageView } from '../../../../hoc/withPageView.jsx';
class Toptendetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelections = this.handleSelections.bind(this);

        this.state = {
            tabsLinks: this.getTabLinks(),
            pageTitle: 'HOLDINGS (Priced Investments)'
        };
    }

    getTabLinks() {
        let tablinks = [];
        tablinks.push({ tabUrl: '/AccountHoldingdetails', active: false, displayName: 'ACCOUNT', id: 'RCM_AccountHoldingdetails' });
        tablinks.push({ tabUrl: '/SecurityHoldingdetails', active: true, displayName: 'SECURITY', id: 'RCM_SecurityHoldingdetails' });
        let obj = {
            preferences: this.props.preferences
        }
        let isAssetHoldingsrequired = GetPreferencePilotValue(obj, "AssetHoldings", false) === "true" ? true : false;
        if (isAssetHoldingsrequired) {
            tablinks.push({ tabUrl: '/AssetHoldingdetails', active: true, displayName: 'ASSET TYPE', id: 'RCM_AssetHoldingdetails' });
        }
        return tablinks;
    }

    getTitle(path){
        let title = ''
        let obj = {
            preferences: this.props.preferences
        }
        let isBalSummaryV2Pilot = GetPreferencePilotValue(obj, 'BalSummaryV2', false) === "true" ? true : false;
        if (!isBalSummaryV2Pilot) {
            title = 'HOLDINGS'
        }
        else {
            if (path && path === "/AssetHoldingdetails") {
                title = 'HOLDINGS'
            } else {
                title = 'HOLDINGS (Priced Investments)'
            }
        }

        return title
    }

    handleSelections(param, e) {
        let title = this.getTitle(param.tabUrl)
        let tabsLinks = tabSelection(this.state.tabsLinks, param);
        this.setState({ tabsLinks: tabsLinks, pageTitle: title });
    }

    componentDidMount() {  
        let title= this.getTitle(this.props.location.pathname)
        let tabsLinks = tabSelectionOnLoad(this.state.tabsLinks, this.props.location.pathname, '/AccountHoldingdetails', '/Toptendetail');
        this.setState({ tabsLinks: tabsLinks, pageTitle: title });
        window.addEventListener('goToBalanceDetails', this.goToBalanceDetails);
    }
    componentWillUnmount() {
        // de registering the event here
        window.removeEventListener('goToBalanceDetails', this.goToBalanceDetails);

    }
    goToBalanceDetails = () => {
        this.props.history.push("/Balancesdetail");
    }
    render() {
        const toptendetailsview = (
          <div>
            <div className="row">
              <div className="col-sm-12" key="lblholdingheadingrow">
                <label
                  className="RCM_tile_heading RCM_details_heading RCM_Toast_heading"
                  key="lblholdingheading"
                >
                  {this.state.pageTitle}
                </label>
              </div>
            </div>
            <div key="dvholdingdetailsview" className="row">
              <div className="col-sm-12" key="lbholdingdetailviewby">
                <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding">
                  <ul className="nav nav-tabs">
                    {this.state.tabsLinks &&
                      this.state.tabsLinks.map((tabLink) => {
                        return (
                          <li className="nav-item active" id={tabLink.id}>
                            <NavLink
                              className="nav-link"
                              exact
                              to={tabLink.tabUrl}
                              className={
                                "nav-link " +
                                (tabLink.active ? "linkactive " : " ")
                              }
                              onClick={this.handleSelections.bind(
                                this,
                                tabLink
                              )}
                            >
                              {tabLink.displayName}
                            </NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                <div>
                  <Switch>
                    <Route
                      key="root"
                      exact
                      path="/Toptendetail"
                      render={() => <Redirect to="/AccountHoldingdetails" />}
                    />
                    <Route
                      key="accountholdings"
                      exact
                      path="/AccountHoldingdetails"
                      component={AccountHoldingdetails}
                    />
                    <Route
                      key="securityholdings"
                      exact
                      path="/SecurityHoldingdetails"
                      component={SecurityHoldingdetails}
                    />
                    <Route
                      key="assetholdings"
                      exact
                      path="/AssetHoldingdetails"
                      component={AssetHoldingsdetails}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        );
        return (
            <BrowserRouter>{toptendetailsview}</ BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        holdingsbyaccount: state.topten.holdingsbyaccount,
        holdingsbysecurity: state.topten.holdingsbysecurity,
        holdingsbycategory: state.topten.holdingsbycategory,
        render: state.acct.render,
        acct: state.acct,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        preferences: state.acct.preferences
    };
};

const enhancement = compose(
    withPageView({pageName: 'Security Holdings'})
)
export default enhancement(withRouter(connect(mapStateToProps)(Toptendetails)));