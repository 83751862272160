import React from 'react';
import { getAccountToolTip, IndLongShort, getDisplayName } from '../../../../common/constants.jsx';
export function AccountListColumns(toggleManageAlertsPopup, isEmailAdded, isMobileNoAdded, isProspectClient) {
    var columns = [];
    if (isEmailAdded || isMobileNoAdded) {
        columns = [
            {
                dataField: 'key', text: "Account", sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                headerAlign: 'left',
                headerClasses: 'RCM_noWrap',
                classes: 'RCM_common_table_column_text',
                formatter: (cell, row) => {
                    return <div title={getAccountToolTip(row, IndLongShort.LONG)}>{getDisplayName(row)}</div>;
                }
            },
            {
                dataField: 'alertList', text: "Subscribed Alerts",
                headerAlign: 'left',
                classes: 'RCM_common_table_column_text',
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: '', text: "",
                headerAlign: 'right',
                classes: 'RCM_common_table_column_number',
                formatter: (cell, row) => {
                    return <span className="RCM_link_wrapper ">
                        {
                            isProspectClient ?
                            <label className='manage_alert_btn RCM_Prospect_Disabled'>Manage Alerts</label>
                            : <label onClick={() => toggleManageAlertsPopup(row)} className='manage_alert_btn'>Manage Alerts</label>
                        }
                    </span>;
                }
            }
        ];
    } else {
        columns = [
            {
                dataField: 'key', text: "Account", sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                headerAlign: 'left',
                headerClasses: 'RCM_noWrap',
                classes: 'RCM_common_table_column_text',
                formatter: (cell, row) => {
                    return <div title={getAccountToolTip(row, IndLongShort.LONG)}>{getDisplayName(row)}</div>;
                }
            },
            {
                dataField: 'alertList', text: "Subscribed Alerts",
                headerAlign: 'left',
                classes: 'RCM_common_table_column_text',
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: 'key', text: "",
                headerAlign: 'right',
                classes: 'RCM_common_table_column_number RCM_disabled',
                formatter: (cell, row) => {
                    return <span className="RCM_link_wrapper ">
                        <label className='manage_alert_btn RCM_manage_alert_btn_disabled'>
                            Manage Alerts</label>
                    </span>;
                }
            }
        ];
    }
    return columns;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}