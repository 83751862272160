import {
    roundvalue, formatMoney, GetNumericValue, GetTextValue, roundUnitValue, GetHeaderHtmlPrint, getAccountDisplayName, IndLongShort, getDisplayName,
    getDateTimeVal, getDateString, getManualAssetBalance, GetPreferenceValue, GetPreferencePilotValue
} from './../common/constants.jsx';
import { printExternalManageAccount } from "../components/MoneyMovement/ManageExternalAccounts/externalAccountColumns.js";
import { pastTransfersPrintTable } from "../components/MoneyMovement/pastTransfersColumns.js";
import { scheduleTrasfersPrintTable } from "../components/MoneyMovement/scheduleTransfersColumn.js";
import { toDate } from './constants.jsx';
import { getMappedData, GetUniqueTimedata, getAssetGroups } from './../components/accounts/detail/historicalassetallocation/HistoricalAssetAllocationService';
import groupBy from "lodash.groupby";
import uniq from "lodash.uniq";
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import { getAssetColorConfig } from './assetschemautilities';
import { renderRowsByAsset } from './../components/accounts/detail/topten/holdingdetailscolumns';
import { renderTemplateRows } from './../components/accounts/detail/assetallocation/assetallocationdetailscolumns';
import { renderRowsByCategory } from './../components/accounts/summary/assetallocation/assetallocationcolumns';
export function generatePrintReport(props, path) {
    switch (path) {
        case "/Balancesdetail": {
            if (props && props.acct && props.acct)
                printFinancialDetailsReport(props.acct)
            break;
        }
        case "/Assetallocationdetail": {
            if (props && props.acct)
                printAssetAllocationDetailsReport(props.acct);
            break;
        }
        case "/AccountHoldingdetails": {
            if (props && props.acct) {
                let obj = {
                    preferences: props.preferences
                }
                const viewYield = GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
                printHoldingDetailsReport(props.acct, props.topten, true, false, false, viewYield)
            }

            break;
        }
        case "/RealizedAccountHoldingdetails": {
            if (props && props.acct && props.topten) {
                printRealizedGainLossDetailsReport(props.acct, props.topten, true, false)
            }
            break;
        }
        case "/AssetHoldingdetails": {
            if (props && props.acct && props) {
                let obj = {
                    preferences: props.preferences
                }
                const viewYield = GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
                printHoldingDetailsReport(props.acct, props.topten, false, false, true, viewYield)
            }

            break;
        }
        case "/SecurityHoldingdetails": {
            if (props && props.acct) {
                let obj = {
                    preferences: props.preferences
                }
                const viewYield = GetPreferencePilotValue(obj, 'ViewYield', 'false') === 'true' ? true : false;
                printHoldingDetailsReport(props.acct, props.topten, false, true, false, viewYield)
            }
            break;
        }

        case "/RealizedSecurityHoldingdetails": {
            if (props && props.acct && props.topten) {
                printRealizedGainLossDetailsReport(props.acct, props.topten, false, true)
            }
            break;
        }
        case "/Activitydetail": {
            if (props && props.acct)
                printActivityDetailsReport(props.acct)
            break;
        }
        case "/Transfers": {
            printTransfersDetails(props);
            break;
        }
        case "/Documents": {
            printDocumentDetails(props);
            break;
        }
        case "/AccountStatements": {
            printAccountStatementsDetails(props);
            break;
        }
        case "/Correspondence": {
            printCorrespondenceDetails(props);
            break;
        }
        case "/TradeConfirms": {
            printTradeConfirmDetails(props);
            break;
        }
        case "/TaxDocuments": {
            printTaxDocumentDetails(props);
            break;
        }
        case "/manageExtAccount": {
            printExternalManageAccount(props);
            break;
        }
        case "/AssetAggregation": {
            printAssetAggregation(props);
            break;
        }
        case "/EstimatedIncomeSecurity": {
            printEstimatedIncomeSecurityReport(props.acct, props);
            break;
        }
        case "/EstimatedIncomeAccount": {
            printEstimatedIncomeAccountReport(props.acct, props);
            break;
        }
        case "/EstimatedIncomeSummary": {
            printEstimatedIncomeSummaryReport(props.acct, props);
            break;
        }
        case "/ProjectedIncomeSecurity": {
            printProjectedIncomeSecurityReport(props.acct, props);
            break;
        }
        case "/ProjectedIncomeAccount": {
            printProjectedIncomeAccountReport(props.acct, props);
            break;
        }
        case "/ProjectedIncomeSummary": {
            printProjectedIncomeSummaryReport(props.acct, props);
            break;
        }
        case "/Liabilities":
            {
                printLiabilitiesReport(props.acct, props);
                break;
            }
        case "/EDelivery":
            {
                printEDeliveryReport(props);
                break;
            }
        case "/Balanceshistory":
            {
                printHistoricalAccountDetailsExcelReport(props);
                break;
            }
        case "/SubscribeAlerts":
            {
                printSubscribeAlertsReport(props);
                break;
            }
        case "/AlertDetails":
            {
                printAlertNotificationReport(props);
                break;
            }
        case "/Assetallocationhistory": {
            if (props && props.acct && props)
                printAssetallocationHistoryExcelReport(props);
            break;
        }
        case "/ManageAssets": {
            if (props && props.acct && props)
                printManualAssetsReport(props);
            break;
        }
        case "/AccountPerformance": {
            if (props && props.acct)
                printAccountPerformanceReport(props);
            break;
        }
        case "/PerformanceSummary": {
            if (props && props.acct)
                printPerformanceSummaryReport(props);
            break;
        }
        case "/AccountInfo": {
            if (props && props.acct && props.moneymovement)
                printAccountInformationReport(props);
            break;
        }
        case "/Collaboration":
        case "/Help":
        case "/Feedback":
        case "/Support":
        case "/ProfileComponent":
        case "/Profile":
        case "/Preferences":
        case "/AccountsNickname":
        case "/ManageGroups":
        case "/aviation":
        case "/philanthropy":
        case "/CaseManager":
        case "/Reports":
        case "/PlanManager":
        case "/Services": {
            break;
        }
        default: {
            if (props && props.acct)
                printDashboardReport(props)
        }


    }
}
export function printDashboardReport(props) {
    let isAssetAllocation = false;
    let isAsset = false;
    let isTopten = false;
    let isActivity = false;
    let isHistoricalAsset = false;
    let isPerformance = false;
    if (props.personalistaionData.dashboardPreference) {
        let dashboardPreferences = props.personalistaionData.dashboardPreference;
        dashboardPreferences.forEach(dashboardPreference => {
            if (dashboardPreference === 'assetallocation') {
                isAssetAllocation = true;
            } else if (dashboardPreference === 'asset') {
                isAsset = true;
            } else if (dashboardPreference === 'topten') {
                isTopten = true;
            } else if (dashboardPreference === 'activity') {
                isActivity = true;
            } else if (dashboardPreference === 'historicalasset') {
                isHistoricalAsset = true;
            } else if (dashboardPreference === 'performance') {
                isPerformance = true;
            }
        })
    }
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 
    table += "<tr>"

    if (isAssetAllocation) {
        if (isHistoricalAsset) {
            table += "<td valign='top' width='20%'>"
        }
        else {
            table += "<td valign='top' width='30%'>"
        }


        table += "<table style = 'border: 1px solid black' cellspacing = '0'><tr>"
        //prepare table header columns 

        table += "<th style='text-align:center; border: 1px solid black;' colspan='4'> Asset Allocation</th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Asset Name </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Asset% </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Value </th>"
        if (props && props.acct && props.acct.assetallocationsummary && props.acct.assetallocationsummary[0].value) {
            const rows = renderRowsByCategory(props.acct.assetallocationsummary[0].value, props.acct.assetschemacolorsconfig, props.acct.assetSchema);
            rows.forEach(asset => {
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + asset.name + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (asset.percentage ? roundvalue(asset.percentage, 2) : GetNumericValue(asset.percentage, true)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (asset.value ? formatMoney(asset.value) : GetNumericValue(asset.value)) + "</td></tr>"
            });

        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black' >&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black' >&nbsp;</td></tr>"
        }
        table += "</table></td>"
    }
    if (isAsset) {
        if (isHistoricalAsset)
            table += "<td valign='top'  width='20%'><table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='9'> Account Overview </th>"
        else
            table += "<td valign='top'  width='35%'><table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='9'> Account Overview </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Total Value </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Todays Change </th>"
        table += "</tr>"
        if (props && props.acct && props.acct.balancedetails && props.acct.balancedetails.value && props.acct.balancedetails.value.length > 0 && props.acct.balancedetails.value[0] && props.acct.balancedetails.value[0].accountbalances) {
            props.acct.balancedetails.value[0].accountbalances.forEach(account => {
                var totalval = (account.totalaccountvaluechange && (account.totalaccountvaluechange > 0 || account.totalaccountvaluechange < 0)) ? formatMoney(account.totalaccountvaluechange) : GetNumericValue(account.totalaccountvaluechange);
                var totalpct = (account.totalaccountvaluepercentchange && (account.totalaccountvaluepercentchange > 0 || account.totalaccountvaluepercentchange < 0)) ? roundvalue(account.totalaccountvaluepercentchange, 2) + "%" : GetNumericValue(account.totalaccountvaluepercentchange, true);
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props.selectedaccount, account.accountaumber, IndLongShort.SINGLEVIEW)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (account.totalaccountvalue ? formatMoney(account.totalaccountvalue) : GetNumericValue(account.totalaccountvalue)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (totalval + "/" + totalpct) + "</td></tr>"
            });

        }
        else {

            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table></td>"
    }
    if (isTopten) {
        if (isHistoricalAsset)
            table += "<td valign='top'  width='20%'><table style = 'border: 1px solid black' cellspacing = '0' > <tr><th style='text-align:center; border: 1px solid black;' colspan='4'> Top Ten Holdings </th>"
        else table += "<td valign='top'  width='35%'><table style = 'border: 1px solid black' cellspacing = '0' > <tr><th style='text-align:center; border: 1px solid black;' colspan='4'> Top Ten Holdings </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol (shares @ price ) </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Value </th>"
        table += "<th style='text-align:right; border: 1px solid black'> $ Gain/Loss </th>"
        table += "<th style='text-align:right; border: 1px solid black'> % Gain/Loss </th>"
        table += "</tr>"
        if (props && props.acct && props.acct.toptensummary && props.acct.toptensummary.value[0] && props.acct.toptensummary.value[0].holdingdetails &&
            props.acct.toptensummary.value[0].holdingdetails.length > 0) {
            props.acct.toptensummary.value[0].holdingdetails.forEach(holding => {
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + holding.symbol + "(" + (holding.unit ? roundUnitValue(holding.unit) : GetNumericValue(holding.unit)) + "@" + (holding.price ? formatMoney(holding.price) : GetNumericValue(holding.price)) + ")</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (holding.recentmktval ? formatMoney(holding.recentmktval) : GetNumericValue(holding.recentmktval)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (holding.gainlossamt ? formatMoney(holding.gainlossamt) : GetNumericValue(holding.gainlossamt)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (holding.gainlosspct ? roundvalue(holding.gainlosspct, 2) : GetNumericValue(holding.gainlosspct, false, true)) + "</td></tr>"
            });

        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table></td>"
    }

    if (isHistoricalAsset) {
        table += "<td valign='top'  width='60%'>"
        if (props && props.acct && props.acct.historicalAssetAllocationSummaryDetails && props.acct.historicalAssetAllocationSummaryDetails) {
            table = GenerateHistoricalAssetallocationSummaryTable(props.acct.historicalAssetAllocationSummaryDetails, table, props.selectedaccount);
            table += "</table></td>"
        }
        else {
            table += "<table style='border: 1px solid black' cellspacing='0' > <tr><th style='text-align:center; border: 1px solid black;' colspan='12'> Historical Value </th></tr>"
            table += "</table></td>"
        }
        
    }
    if (isPerformance) {
        if (isHistoricalAsset)
            table += "<td valign='top'  width='20%'><table style = 'border: 1px solid black' cellspacing = '0' > <tr><th style='text-align:center; border: 1px solid black;' colspan='5'> Performance Summary </th>"
        else table += "<td valign='top'  width='35%'><table style = 'border: 1px solid black' cellspacing = '0' > <tr><th style='text-align:center; border: 1px solid black;' colspan='5'> Performance Summary </th>"
        table += "</tr>"
        let showtablechart = GetPreferenceValue(props.acct, "Performance", "chart");
        if (showtablechart === 'table') {
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
            if (props && props.acct && props.acct.perfromanceSummaryTable && props.acct.perfromanceSummaryTable.columns && props.acct.perfromanceSummaryTable.columns.length > 0) {
                props.acct.perfromanceSummaryTable.columns.forEach(column => {
                    table += "<th style='text-align:left; border: 1px solid black'> "+ column +" </th>"
                   
                });

            }
            table += "</tr>"
            if (props && props.acct && props.acct.perfromanceSummaryTable && props.acct.perfromanceSummaryTable.accounts && props.acct.perfromanceSummaryTable.accounts.length > 0) {
                props.acct.perfromanceSummaryTable.accounts.forEach(account => {
                    table += "<tr><td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.acct.accounts, account.key, IndLongShort.SINGLEVIEW) + "</td>"

                    if (props && props.acct && props.acct.perfromanceSummaryTable && props.acct.perfromanceSummaryTable.columns && props.acct.perfromanceSummaryTable.columns.length > 0) {
                        props.acct.perfromanceSummaryTable.columns.forEach(column => {
                            let value = account[column] !== undefined && account[column] !== null ? (account[column] !== 0 ? formatMoney(roundvalue(account[column], 2)) : GetNumericValue(account[column])) : '-';
                            table += "<td style='text-align:left; border: 1px solid black'>" + value + " </th>"
                        });

                    }
                });

            }
            else {
                table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
                table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
                table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
                table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
            }
        } else {
            //table for chart
            table += "<tr>"
            if (props && props.acct && props.acct.perfromanceSummaryChart && props.acct.perfromanceSummaryChart.length > 0) {
                props.acct.perfromanceSummaryChart.forEach(row => {
                    let keyString = new Date(row.month).toLocaleString("default", {
                        month: "short",
                        year: "2-digit"
                    })
                    table += "<th style='text-align:left; border: 1px solid black'> " + keyString + "</th>"
                });
            }
            table += "</tr>";
            table += "<tr>";
            if (props && props.acct && props.acct.perfromanceSummaryChart && props.acct.perfromanceSummaryChart.length > 0) {
                props.acct.perfromanceSummaryChart.forEach(row => {
                    let value = row.value !== undefined && row.value !== null && row.value !== 0 ? formatMoney(roundvalue(row.value, 2)) : GetNumericValue(row.value);

                    table += "<th style='text-align:left; border: 1px solid black'>" + value + "</th>";

                });
            }
            table += "</tr>";
        }
        
        table += "</table></td>"
    }
    table += "</tr ></table>"

    table += "<br/>"

    if (isActivity) {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='9'> Activity </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> TradeDate </th>"
        table += "<th style='text-align:left; border: 1px solid black'> SettlemenetDate </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:left; border: 1px solid black'> TransDesc </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Type </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol/CUSIP </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Quantity </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Price </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Amount </th>"
        table += "</tr>"
        if (props && props.acct && props.acct.activitysummary && props.acct.activitysummary.value && props.acct.activitysummary.value[0] && props.acct.activitysummary.value[0].activities) {
            let activities = props.acct.activitysummary.value[0].activities;
            if (activities && activities.length > 15) {
                activities = activities.slice(0, 14)
            }

            activities.sort(function (a, b) {
                if (b['dtsettle']) return a['dtsettle'] ? b['dtsettle'].localeCompare(a['dtsettle']) : 1;
                else if (a['dtsettle']) return (b['dtsettle']) ? a['dtsettle'].localeCompare(b['dtsettle']) : -1;
            });
            activities.forEach(activity => {
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + (activity.dttrade ? activity.dttrade.split('T')[0] : GetTextValue(activity.dttrade)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.dtsettle ? activity.dtsettle.split('T')[0] : GetTextValue(activity.dtsettle)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.acct ? getAccountDisplayName(props.selectedaccount, activity.acct, IndLongShort.SINGLEVIEW) : GetTextValue(activity.acct)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.trndesc ? activity.trndesc : GetTextValue(activity.trndesc)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.trntype ? activity.trntype : GetTextValue(activity.trntype)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.secid ? activity.secid : GetTextValue(activity.secid)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (activity.units ? roundUnitValue(activity.units) : GetNumericValue(activity.units, false, true)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (activity.unitprice ? formatMoney(activity.unitprice) : GetNumericValue(activity.unitprice)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (activity.total ? formatMoney(activity.total) : GetNumericValue(activity.total)) + "</td></tr>"
            });
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>"
    }

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();

}
export function printTasHoldingExcelReport(dttable, props) {

    var printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props.acct);
    table += "<table cellspacing='0'><tr><td valign='top'  width='50%'><table style='border: 1px solid black' cellspacing='0' ><tr>"
    //prepare table for Account

    table += "<th style='text-align:center; border: 1px solid black;' colspan='8'> TAS Holdings </th>"
    table += "</tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Acquisition Date </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Holding Period </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Quantity </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Unit Cost </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Cost Basis </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Price </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Value </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Unrealized Gain/loss </th>"

    table += "</tr>"
    if (dttable) {
        dttable.forEach(account => {
            var unglVal = (account.UnrealizedGainLossAmt && (account.UnrealizedGainLossAmt > 0 || account.UnrealizedGainLossAmt < 0)) ? formatMoney(account.UnrealizedGainLossAmt) : GetNumericValue(account.UnrealizedGainLossAmt);
            var unglPct = (account.UnrealizedGainLossPct && (account.UnrealizedGainLossPct > 0 || account.UnrealizedGainLossPct < 0)) ? roundvalue(account.UnrealizedGainLossPct, 2) + "%" : GetNumericValue(account.UnrealizedGainLossPct, true);
            table += "<tr><td style='text-align:left; border: 1px solid black'>" + (account.AcquisitionDate.split('T')[0]) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black'>" + (account.LongShort === "L" ? "Long Term" : "Short Term") + "</td>"
            table += "<td style='text-align:right; border: 1px solid black'>" + (account.Quantity && account.Quantity > 0 ? roundUnitValue(account.Quantity) : GetNumericValue(account.Quantity, true, false)) + "</td>"
            table += "<td style='text-align:right; border: 1px solid black'>" + (account.AvgUnitCost && account.AvgUnitCost > 0 ? formatMoney(account.AvgUnitCost) : GetNumericValue(account.AvgUnitCost)) + "</td>"
            table += "<td style='text-align:right; border: 1px solid black'>" + (account.CostBasis && account.CostBasis > 0 ? formatMoney(account.CostBasis) : GetNumericValue(account.CostBasis)) + "</td>"
            table += "<td style='text-align:right; border: 1px solid black'>" + (account.RecentPrice && account.RecentPrice > 0 ? formatMoney(account.RecentPrice) : GetNumericValue(account.RecentPrice)) + "</td>"
            table += "<td style='text-align:right; border: 1px solid black'>" + (account.RecentMktVal && account.RecentMktVal > 0 ? formatMoney(account.RecentMktVal) : GetNumericValue(account.RecentMktVal)) + "</td>"
            table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td></tr>"
        });
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }

    table += "</table>"

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();

}
export function printHoldingDetailsReport(props, topTen, isAccount, isSecurity, iscategory, viewYield) {

    var printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table cellspacing='0'><tr><td valign='top'  width='50%'><table style='border: 1px solid black' cellspacing='0' ><tr>"
    //prepare table for Account
    if (isAccount) {
        table += "<th style='text-align:center; border: 1px solid black;' colspan='9'> Account Holdings </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Quantity </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Unit Cost </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Cost Basis </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Price </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Value </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Today's Change </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Unrealized Gain/loss </th>"
        if (viewYield) {
            table += "<th style='text-align:right; border: 1px solid black'> Yield % </th>"
        }

        table += "</tr>"
        if (topTen && topTen.holdingsbyaccount && topTen.holdingsbyaccount[0] && topTen.holdingsbyaccount[0].AcctPosLst) {
            topTen.holdingsbyaccount[0].AcctPosLst.forEach(account => {
                var unglVal = (account.UNGLVAL && (account.UNGLVAL > 0 || account.UNGLVAL < 0)) ? formatMoney(account.UNGLVAL) : GetNumericValue(account.UNGLVAL);
                var unglPct = (account.UNGLPCT && (account.UNGLPCT > 0 || account.UNGLPCT < 0)) ? roundvalue(account.UNGLPCT, 2) + "%" : GetNumericValue(account.UNGLPCT, true);
                var mktVal = (account.MKTVALCHANGE && (account.MKTVALCHANGE > 0 || account.MKTVALCHANGE < 0)) ? formatMoney(account.MKTVALCHANGE) : GetNumericValue(account.MKTVALCHANGE);
                var mktPct = (account.MKTVALCHANGEPCT && (account.MKTVALCHANGEPCT > 0 || account.MKTVALCHANGEPCT < 0)) ? roundvalue(account.MKTVALCHANGEPCT, 2) + "%" : GetNumericValue(account.MKTVALCHANGEPCT, true);
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, account.ACCTNUM, IndLongShort.SINGLEVIEW) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'></td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(account.ACCTYPE) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (account.UNIT && account.UNIT > 0 ? roundUnitValue(account.UNIT) : GetNumericValue(account.UNIT, true, false)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (account.AVGCOSTPERSHARE && account.AVGCOSTPERSHARE > 0 ? formatMoney(roundvalue(account.AVGCOSTPERSHARE, 4), 4) : GetNumericValue(account.AVGCOSTPERSHARE, false, false, true)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (account.COSTBASIS && account.COSTBASIS > 0 ? formatMoney(account.COSTBASIS) : GetNumericValue(account.COSTBASIS)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (account.RCNTPRICE && account.RCNTPRICE > 0 ? formatMoney(account.RCNTPRICE) : GetNumericValue(account.RCNTPRICE)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (account.MKTVALS && account.MKTVALS.RCNTMKTVAL && account.MKTVALS.RCNTMKTVAL > 0 ? formatMoney(account.MKTVALS.RCNTMKTVAL) : GetNumericValue(account.MKTVALS.RCNTMKTVAL)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (mktVal + "  /  " + mktPct) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td>"
                if (viewYield) {
                    table += "<td style='text-align:right; border: 1px solid black'>" + "  " + "</td>"
                }

                table += "</tr>"
                if (account.INVPOSLIST && account.INVPOSLIST.length > 0) {
                    account.INVPOSLIST.forEach(invitem => {
                        var unglVal = (invitem.UnrealizedValue && invitem.UnrealizedValue > 0 || invitem.UnrealizedValue && invitem.UnrealizedValue < 0) ? formatMoney(invitem.UnrealizedValue) : GetNumericValue(invitem.UnrealizedValue);
                        var unglPct = (invitem.UnrealizedPct && invitem.UnrealizedPct > 0 || invitem.UnrealizedPct && invitem.UnrealizedPct < 0) ? roundvalue(invitem.UnrealizedPct, 2) + "%" : GetNumericValue(invitem.UnrealizedPct, true, false);
                        var mktVal = (invitem.MKTVALS.MKTVALCHANGE && (invitem.MKTVALS.MKTVALCHANGE > 0 || invitem.MKTVALS.MKTVALCHANGE < 0)) ? formatMoney(invitem.MKTVALS.MKTVALCHANGE) : GetNumericValue(invitem.MKTVALS.MKTVALCHANGE);
                        var mktPct = (invitem.MKTVALS.MKTVALCHANGEPCT && (invitem.MKTVALS.MKTVALCHANGEPCT > 0 || invitem.MKTVALS.MKTVALCHANGEPCT < 0)) ? roundvalue(invitem.MKTVALS.MKTVALCHANGEPCT, 2) + "%" : GetNumericValue(invitem.MKTVALS.MKTVALCHANGEPCT, true);
                        var yieldPct = (invitem.SECINFO.YieldPct && invitem.SECINFO.YieldPct > 0 || invitem.SECINFO.YieldPct && invitem.SECINFO.YieldPct < 0) ? roundvalue(invitem.SECINFO.YieldPct, 2) + "%" : GetNumericValue(invitem.SECINFO.YieldPct, true, false);
                        table += "<tr><td style='text-align:left; border: 1px solid black'></td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(invitem.SECINFO.SECID) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(invitem.SECINFO.SECNAME) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.SHRQNTS && invitem.SHRQNTS.UNIT && invitem.SHRQNTS.UNIT > 0 ? roundUnitValue(invitem.SHRQNTS.UNIT) : GetNumericValue(invitem.SHRQNTS.UNIT, false, true)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.COSTBASIS && invitem.COSTBASIS.AVGCOSTPERSHARE && invitem.COSTBASIS.AVGCOSTPERSHARE > 0 ? formatMoney(roundvalue(invitem.COSTBASIS.AVGCOSTPERSHARE, 4), 4) : GetNumericValue(invitem.COSTBASIS.AVGCOSTPERSHARE, false, false, true)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.COSTBASIS && invitem.COSTBASIS.COSTBASIS && invitem.COSTBASIS.COSTBASIS > 0 ? formatMoney(invitem.COSTBASIS.COSTBASIS) : GetNumericValue(invitem.COSTBASIS.COSTBASIS)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.PRICING && invitem.PRICING.RCNTPRICE && invitem.PRICING.RCNTPRICE > 0 ? formatMoney(invitem.PRICING.RCNTPRICE) : GetNumericValue(invitem.PRICING.RCNTPRICE)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.MKTVALS && invitem.MKTVALS.RCNTMKTVAL && invitem.MKTVALS.RCNTMKTVAL > 0 ? formatMoney(invitem.MKTVALS.RCNTMKTVAL) : GetNumericValue(invitem.MKTVALS.RCNTMKTVAL)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + mktVal + "  /  " + mktPct + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + unglVal + "  /  " + unglPct + "</td>"
                        if (viewYield) {
                            table += "<td style='text-align:right; border: 1px solid black'>" + yieldPct + "</td>"
                        }
                        table += "</tr>"
                    }
                    )

                }

            });
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            if (viewYield) {
                table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            }
            table += "</tr>"
        }

        table += "</table>"
    }

    //prepare table for security
    if( isSecurity) {
        table += "<th style='text-align:center; border: 1px solid black;' colspan='10'> Security Holdings </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Quantity </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Unit Cost </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Cost Basis </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Price </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Value </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Today's Change </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Unrealized Gain/loss </th>"
        if (viewYield) {
            table += "<th style='text-align:right; border: 1px solid black'> Yield % </th>"
        }

        table += "</tr>"
        if (topTen && topTen.holdingsbysecurity) {
            var data = topTen.holdingsbysecurity && topTen.holdingsbysecurity.length > 0 && topTen.holdingsbysecurity[0].DetailPOSLIST ? topTen.holdingsbysecurity[0].DetailPOSLIST : []
            data.forEach(security => {
                var unglVal = (security.UnrealizedValue && security.UnrealizedValue > 0 || security.UnrealizedValue && security.UnrealizedValue < 0) ? formatMoney(security.UnrealizedValue) : GetNumericValue(security.UnrealizedValue);
                var unglPct = (security.UnrealizedPct && security.UnrealizedPct > 0 || security.UnrealizedPct && security.UnrealizedPct < 0) ? roundvalue(security.UnrealizedPct, 2) + "%" : GetNumericValue(security.UnrealizedPct, true, false);
                var mktVal = (security.MKTVALS.MKTVALCHANGE && security.MKTVALS.MKTVALCHANGE > 0 || security.MKTVALS.MKTVALCHANGE && security.MKTVALS.MKTVALCHANGE < 0) ? formatMoney(security.MKTVALS.MKTVALCHANGE) : GetNumericValue(security.MKTVALS.MKTVALCHANGE);
                var mktPct = (security.MKTVALS.MKTVALCHANGEPCT && security.MKTVALS.MKTVALCHANGEPCT > 0 || security.MKTVALS.MKTVALCHANGEPCT && security.MKTVALS.MKTVALCHANGEPCT < 0) ? roundvalue(security.MKTVALS.MKTVALCHANGEPCT, 2) + "%" : GetNumericValue(security.MKTVALS.MKTVALCHANGEPCT, true, false);
                var yieldPct = (security.SECINFO.YieldPct && security.SECINFO.YieldPct > 0 || security.SECINFO.YieldPct && security.SECINFO.YieldPct < 0) ? roundvalue(security.SECINFO.YieldPct, 2) + "%" : GetNumericValue(security.SECINFO.YieldPct, true, false);
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + GetTextValue(security.SECINFO.SECID) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(security.SECINFO.SECNAME) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'></td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (security.SHRQNTS && security.SHRQNTS.UNIT && security.SHRQNTS.UNIT > 0 ? roundUnitValue(security.SHRQNTS.UNIT) : GetNumericValue(security.SHRQNTS.UNIT, false, true)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (security.COSTBASIS && security.COSTBASIS.AVGCOSTPERSHARE && security.COSTBASIS.AVGCOSTPERSHARE > 0 ? formatMoney(roundvalue(security.COSTBASIS.AVGCOSTPERSHARE, 4), 4) : GetNumericValue(security.COSTBASIS.AVGCOSTPERSHARE, false, false, true)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (security.COSTBASIS && security.COSTBASIS.COSTBASIS && security.COSTBASIS.COSTBASIS > 0 ? formatMoney(security.COSTBASIS.COSTBASIS) : GetNumericValue(security.COSTBASIS.COSTBASIS)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (security.PRICING && security.PRICING.RCNTPRICE && security.PRICING.RCNTPRICE > 0 ? formatMoney(security.PRICING.RCNTPRICE) : GetNumericValue(security.PRICING.RCNTPRICE)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (security.MKTVALS && security.MKTVALS.RCNTMKTVAL && security.MKTVALS.RCNTMKTVAL > 0 ? formatMoney(security.MKTVALS.RCNTMKTVAL) : GetNumericValue(security.MKTVALS.RCNTMKTVAL)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (mktVal + "  /  " + mktPct) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td>"
                if (viewYield) {
                    table += "<td style='text-align:left; border: 1px solid black'>" + (yieldPct) + "</td>"
                }

                table += "</tr>"
                if (security.INVPOSLIST && security.INVPOSLIST.length > 0) {
                    security.INVPOSLIST.forEach(invitem => {
                        var unglVal = (invitem.UnrealizedValue && invitem.UnrealizedValue > 0 || invitem.UnrealizedValue && invitem.UnrealizedValue < 0) ? formatMoney(invitem.UnrealizedValue) : GetNumericValue(invitem.UnrealizedValue);
                        var unglPct = (invitem.UnrealizedPct && invitem.UnrealizedPct > 0 || invitem.UnrealizedPct && invitem.UnrealizedPct < 0) ? roundvalue(invitem.UnrealizedPct, 2) + "%" : GetNumericValue(invitem.UnrealizedPct, true, false);
                        var mktVal = (invitem.MKTVALS.MKTVALCHANGE && invitem.MKTVALS.MKTVALCHANGE > 0 || invitem.MKTVALS.MKTVALCHANGE && invitem.MKTVALS.MKTVALCHANGE < 0) ? formatMoney(invitem.MKTVALS.MKTVALCHANGE) : GetNumericValue(invitem.MKTVALS.MKTVALCHANGE);
                        var mktPct = (invitem.MKTVALS.MKTVALCHANGEPCT && invitem.MKTVALS.MKTVALCHANGEPCT > 0 || invitem.MKTVALS.MKTVALCHANGEPCT && invitem.MKTVALS.MKTVALCHANGEPCT < 0) ? roundvalue(invitem.MKTVALS.MKTVALCHANGEPCT, 2) + "%" : GetNumericValue(invitem.MKTVALS.MKTVALCHANGEPCT, true, false);
                        var yieldPct = (security.SECINFO.YieldPct && security.SECINFO.YieldPct > 0 || security.SECINFO.YieldPct && security.SECINFO.YieldPct < 0) ? roundvalue(security.SECINFO.YieldPct, 2) + "%" : GetNumericValue(security.SECINFO.YieldPct, true, false);
                        table += "<tr><td style='text-align:left; border: 1px solid black'>" + GetTextValue(invitem.SECINFO.SECID) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(security.SECINFO.SECNAME) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, invitem.ACCTNUM, IndLongShort.SINGLEVIEW) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.SHRQNTS && invitem.SHRQNTS.UNIT && invitem.SHRQNTS.UNIT > 0 ? roundUnitValue(invitem.SHRQNTS.UNIT) : GetNumericValue(invitem.SHRQNTS.UNIT, false, true)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.COSTBASIS && invitem.COSTBASIS.AVGCOSTPERSHARE && invitem.COSTBASIS.AVGCOSTPERSHARE > 0 ? formatMoney(roundvalue(invitem.COSTBASIS.AVGCOSTPERSHARE, 4), 4) : GetNumericValue(invitem.COSTBASIS.AVGCOSTPERSHARE, false, false, true)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.COSTBASIS && invitem.COSTBASIS.COSTBASIS && invitem.COSTBASIS.COSTBASIS > 0 ? formatMoney(invitem.COSTBASIS.COSTBASIS) : GetNumericValue(invitem.COSTBASIS.COSTBASIS)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.PRICING && invitem.PRICING.RCNTPRICE && invitem.PRICING.RCNTPRICE > 0 ? formatMoney(invitem.PRICING.RCNTPRICE) : GetNumericValue(invitem.PRICING.RCNTPRICE)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (invitem.MKTVALS && invitem.MKTVALS.RCNTMKTVAL && invitem.MKTVALS.RCNTMKTVAL > 0 ? formatMoney(invitem.MKTVALS.RCNTMKTVAL) : GetNumericValue(invitem.MKTVALS.RCNTMKTVAL)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (mktVal + "  /  " + mktPct) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td>"
                        if (viewYield) {
                            table += "<td style='text-align:left; border: 1px solid black'>" + (yieldPct) + "</td>"
                        }
                        table += "</tr>"
                    }
                    )
                }
            });
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            if (viewYield) {
                table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            }
            table += "</tr>"
        }

        table += "</table>"
    }

    if (iscategory) {
        table += "<th style='text-align:center; border: 1px solid black;' colspan='12'> Asset Category Holdings </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Asset class </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Quantity </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Unit Cost </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Cost Basis </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Price </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Value </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Today's Change </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Unrealized Gain/loss </th>"
        table += "<th style='text-align:right; border: 1px solid black'> % of Portfolio </th>"
        if (viewYield) {
            table += "<th style='text-align:right; border: 1px solid black'> Yield % </th>"
        }
        table += "</tr>"
        if (topTen && topTen.holdingsbycategory) {

            var data = topTen.holdingsbycategory && topTen.holdingsbycategory.length > 0 && topTen.holdingsbycategory[0].value ? topTen.holdingsbycategory[0].value : []
            const assetData = renderRowsByAsset(data, props.assetschemacolorsconfig, props.assetSchema);
            assetData.forEach(asset => {
                var unglVal = (asset.MKTVALCHANGE && asset.MKTVALCHANGE > 0 || asset.MKTVALCHANGE && asset.MKTVALCHANGE < 0) ? formatMoney(asset.MKTVALCHANGE) : MKTVALCHANGE(asset.MKTVALCHANGE);
                var unglPct = (asset.MKTVALCHANGEPCT && asset.MKTVALCHANGEPCT > 0 || asset.MKTVALCHANGEPCT && asset.MKTVALCHANGEPCT < 0) ? roundvalue(asset.MKTVALCHANGEPCT, 2) + "%" : GetNumericValue(asset.MKTVALCHANGEPCT, true, false);
                var mktVal = (asset.TODAYSCHANGE && asset.TODAYSCHANGE > 0 || asset.TODAYSCHANGE && asset.TODAYSCHANGE < 0) ? formatMoney(asset.TODAYSCHANGE) : GetNumericValue(asset.TODAYSCHANGE);
                var mktPct = (asset.TODAYSCHANGEPCT && asset.TODAYSCHANGEPCT > 0 || asset.TODAYSCHANGEPCT && asset.TODAYSCHANGEPCT < 0) ? roundvalue(asset.TODAYSCHANGEPCT, 2) + "%" : GetNumericValue(asset.TODAYSCHANGEPCT, true, false);

                table += "<tr><td style='text-align:left; border: 1px solid black'>" + GetTextValue(asset.ASSET) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'></td>"
                table += "<td style='text-align:left; border: 1px solid black'></td>"
                table += "<td style='text-align:left; border: 1px solid black'></td>"
                table += "<td style='text-align:right; border: 1px solid black'></td>"
                table += "<td style='text-align:right; border: 1px solid black'></td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (asset.COSTBASIS && asset.COSTBASIS > 0 ? formatMoney(asset.COSTBASIS) : GetNumericValue(asset.COSTBASIS)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'></td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (asset.RCNTMKTVAL && asset.RCNTMKTVAL > 0 ? formatMoney(asset.RCNTMKTVAL) : GetNumericValue(asset.RCNTMKTVAL)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (mktVal + "  /  " + mktPct) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (asset.ASSETTOTALPCT && asset.ASSETTOTALPCT > 0 ? roundvalue(asset.ASSETTOTALPCT, 2) + "%" : GetNumericValue(asset.ASSETTOTALPCT, true, false)) + "</td>"
                if (viewYield) {
                    table += "<td style='text-align:right; border: 1px solid black'>" + " " + "</td>"
                }
                table += "</tr>"
                if (asset.INVPOSLIST && asset.INVPOSLIST.length > 0) {
                    asset.INVPOSLIST.forEach(security => {
                        var unglVal = (security.unrealizedValue && security.unrealizedValue > 0 || security.unrealizedValue && security.unrealizedValue < 0) ? formatMoney(security.unrealizedValue) : GetNumericValue(security.unrealizedValue);
                        var unglPct = (security.unrealizedValue && security.unrealizedPct > 0 || security.unrealizedPct && security.unrealizedPct < 0) ? roundvalue(security.unrealizedPct, 2) + "%" : GetNumericValue(security.unrealizedPct, true, false);
                        var mktVal = (security.mktvals.mktvalchange && security.mktvals.mktvalchange > 0 || security.mktvals.mktvalchange && security.mktvals.mktvalchange < 0) ? formatMoney(security.mktvals.mktvalchange) : GetNumericValue(security.mktvals.mktvalchange);
                        var mktPct = (security.mktvals.mktvalchangepct && security.mktvals.mktvalchangepct > 0 || security.mktvals.mktvalchangepct && security.mktvals.mktvalchangepct < 0) ? roundvalue(security.mktvals.mktvalchangepct, 2) + "%" : GetNumericValue(security.mktvals.mktvalchangepct, true, false);
                        var yieldPct = (security.secinfo.yieldPct && security.secinfo.yieldPct > 0 || security.secinfo.yieldPct && security.secinfo.yieldPct < 0) ? roundvalue(security.secinfo.yieldPct, 2) + "%" : GetNumericValue(security.secinfo.yieldPct, true, false);
                        table += "<tr><td style='text-align:left; border: 1px solid black'></td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(security.secinfo.secid) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + GetTextValue(security.secinfo.secname) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'></td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (security.shrqnts && security.shrqnts > 0 ? roundUnitValue(security.shrqnts) : GetNumericValue(security.shrqnts, false, true)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'></td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (security.costbasis && security.costbasis > 0 ? formatMoney(security.costbasis) : GetNumericValue(security.costbasis)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (security.pricing && security.pricing.rcntprice && security.pricing.rcntprice > 0 ? formatMoney(security.pricing.rcntprice) : GetNumericValue(security.pricing.rcntprice)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (security.mktvals && security.mktvals.rcntmktval && security.mktvals.rcntmktval > 0 ? formatMoney(security.mktvals.rcntmktval) : GetNumericValue(security.mktvals.rcntmktval)) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (mktVal + "  /  " + mktPct) + "</td>"
                        table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td>";
                        table += "<td style='text-align:right; border: 1px solid black'>" + (security.assettotalpct && security.assettotalpct > 0 ? roundvalue(security.assettotalpct, 2) + "%" : GetNumericValue(security.assettotalpct, true, false)) + "</td>"
                        if (viewYield) {
                            table += "<td style='text-align:right; border: 1px solid black'>" + yieldPct + "</td>"
                        }

                        table += "</tr>"
                        if (security.invposlist && security.invposlist.length > 0) {
                            security.invposlist.forEach(account => {
                                var unglVal = (account.unrealizedValue && account.unrealizedValue > 0 || account.unrealizedValue && account.unrealizedValue < 0) ? formatMoney(account.unrealizedValue) : GetNumericValue(account.unrealizedValue);
                                var unglPct = (account.unrealizedValue && account.unrealizedPct > 0 || account.unrealizedPct && account.unrealizedPct < 0) ? roundvalue(account.unrealizedPct, 2) + "%" : GetNumericValue(account.unrealizedPct, true, false);
                                var mktVal = (account.mktvals.mktvalchange && account.mktvals.mktvalchange > 0 || account.mktvals.mktvalchange && account.mktvals.mktvalchange < 0) ? formatMoney(account.mktvals.mktvalchange) : GetNumericValue(account.mktvals.mktvalchange);
                                var mktPct = (account.mktvals.mktvalchangepct && account.mktvals.mktvalchangepct > 0 || account.mktvals.mktvalchangepct && account.mktvals.mktvalchangepct < 0) ? roundvalue(account.mktvals.mktvalchangepct, 2) + "%" : GetNumericValue(account.mktvals.mktvalchangepct, true, false);
                                table += "<tr><td style='text-align:left; border: 1px solid black'></td>"
                                table += "<td style='text-align:left; border: 1px solid black'></td>"
                                table += "<td style='text-align:left; border: 1px solid black'></td>"
                                table += "<td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, account.acctnum, IndLongShort.SINGLEVIEW) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (account.shrqnts && account.shrqnts.recentUNIT && account.shrqnts.recentUNIT > 0 ? roundUnitValue(account.shrqnts.recentUNIT) : GetNumericValue(account.shrqnts.recentUNIT, false, true)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (account.costbasis && account.costbasis.avgcostpershare && account.costbasis.avgcostpershare > 0 ? formatMoney(roundvalue(account.costbasis.avgcostpershare, 4), 4) : GetNumericValue(account.costbasis.avgcostpershare, false, false, true)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (account.costbasis && account.costbasis.costbasis && account.costbasis.costbasis > 0 ? formatMoney(account.costbasis.costbasis) : GetNumericValue(account.costbasis.costbasis)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (account.pricing && account.pricing.rcntprice && account.pricing.rcntprice > 0 ? formatMoney(account.pricing.rcntprice) : GetNumericValue(account.pricing.rcntprice)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (account.mktvals && account.mktvals.rcntmktval && account.mktvals.rcntmktval > 0 ? formatMoney(account.mktvals.rcntmktval) : GetNumericValue(account.mktvals.rcntmktval)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (mktVal + "  /  " + mktPct) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (unglVal + "  /  " + unglPct) + "</td>";
                                table += "<td style='text-align:right; border: 1px solid black'>" + (account.assettotalpct && account.assettotalpct > 0 ? roundvalue(account.assettotalpct, 2) + "%" : GetNumericValue(account.assettotalpct, true, false)) + "</td>"
                                if (viewYield) {
                                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                                }

                                table += "</tr>"
                            })
                        }

                    }
                    )
                }
            });
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            if (viewYield) {
                table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            }

            table += "</tr>"
        }

        table += "</table>"
    }

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();

}

export function printRealizedGainLossDetailsReport(props, topTen, isAccount, isSecurity) {

    var printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table cellspacing='0'><tr><td valign='top'  width='50%'><table style='border: 0.1px solid black' cellspacing='0' ><tr>"
    //prepare table for Account
    if (isAccount) {
        table += "<th style='text-align:center; border: 1px solid black;' colspan='12'> Realized Gain Loss - Account </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Account </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Symbol </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Description </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Acquisition Date </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Sale Date </th>"
        table += "<th style='text-align:left;border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Holding Period </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Quantity </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Cost Basis </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Net Proceeds </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Wash Sale </th>"
        table += "<th style='text-align:right;border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> $ Net Gain/Loss </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> % Net Gain/Loss </th>"

        table += "</tr>"
        if (topTen && topTen.realizedgainlossbyaccount && topTen.realizedgainlossbyaccount.length > 0 && topTen.realizedgainlossbyaccount[0].ITEMSUMMARY) {
            topTen.realizedgainlossbyaccount[0].ITEMSUMMARY.forEach(account => {
                var unglVal = (account.PROFIT && (account.PROFIT > 0 || account.PROFIT < 0)) ? formatMoney(account.PROFIT) : GetNumericValue(account.PROFIT);
                var unglPct = (account.PROFITPCT && (account.PROFITPCT > 0 || account.PROFITPCT < 0)) ? roundvalue(account.PROFITPCT, 2) + "%" : GetNumericValue(account.PROFITPCT, true);
                var colorglval = account.PROFIT && account.PROFIT > 0 ? '#00A647' : account.PROFIT && account.PROFIT < 0 ? '#FB0000' : "";
                var colorglvalpct = account.PROFITPCT && account.PROFITPCT > 0 ? '#00A647' : account.PROFITPCT && account.PROFITPCT < 0 ? '#FB0000' : "";

                //table += "<tr><td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, account.AccountNo, IndLongShort.SINGLEVIEW) + "</td>"
                table += "<tr><td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + getAccountDisplayName(props.selectedaccount, account.ACCT, IndLongShort.SINGLEVIEW) + "</td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + GetTextValue(account.ACCTYPE) + "</td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (account.NUMSHRS && account.NUMSHRS > 0 ? roundUnitValue(account.NUMSHRS) : GetNumericValue(account.NUMSHRS, true, false)) + "</td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (account.COSTBASIS && account.COSTBASIS > 0 ? formatMoney(account.COSTBASIS) : GetNumericValue(account.COSTBASIS)) + "</td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (account.STKBNDAMT && account.STKBNDAMT > 0 ? formatMoney(account.STKBNDAMT) : GetNumericValue(account.STKBNDAMT)) + "</td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglval + "'>" + unglVal + "</td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglvalpct + "'>" + unglPct + "</td></tr>"

                if (account.DETAILSUMMARY && account.DETAILSUMMARY.length > 0) {
                    account.DETAILSUMMARY.forEach(invitem => {
                        var unglVal = (invitem.PROFIT && (invitem.PROFIT > 0 || invitem.PROFIT < 0)) ? formatMoney(invitem.PROFIT) : GetNumericValue(invitem.PROFIT);
                        var unglPct = (invitem.PROFITPCT && (invitem.PROFITPCT > 0 || invitem.PROFITPCT < 0)) ? roundvalue(invitem.PROFITPCT, 2) + "%" : GetNumericValue(invitem.PROFITPCT, true);
                        var colorglval = invitem.PROFIT && invitem.PROFIT > 0 ? '#00A647' : invitem.PROFIT && invitem.PROFIT < 0 ? '#FB0000' : "";
                        var colorglvalpct = invitem.PROFITPCT && invitem.PROFITPCT > 0 ? '#00A647' : invitem.PROFITPCT && invitem.PROFITPCT < 0 ? '#FB0000' : "";
                        table += "<tr><td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + GetTextValue(invitem.SECNAME) + "</td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + GetTextValue(invitem.SECDESCRIPTION) + "</td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (invitem.NUMSHRS && invitem.NUMSHRS > 0 ? roundUnitValue(invitem.NUMSHRS) : GetNumericValue(invitem.NUMSHRS, false, true)) + "</td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (invitem.COSTBASIS && invitem.COSTBASIS > 0 ? formatMoney(invitem.COSTBASIS) : GetNumericValue(invitem.COSTBASIS)) + "</td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (invitem.STKBNDAMT && invitem.STKBNDAMT && invitem.STKBNDAMT > 0 ? formatMoney(invitem.STKBNDAMT) : GetNumericValue(invitem.STKBNDAMT)) + "</td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglval + "'>" + unglVal + "</td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglvalpct + "'>" + unglPct + "</td></tr>"
                        if (invitem.DETAIL && invitem.DETAIL.length > 0) {
                            invitem.DETAIL.forEach(tasdetail => {
                                var unglVal = (tasdetail.PROFIT && (tasdetail.PROFIT > 0 || tasdetail.PROFIT < 0)) ? formatMoney(tasdetail.PROFIT) : GetNumericValue(tasdetail.PROFIT);
                                var unglPct = (tasdetail.PROFITPCT && (tasdetail.PROFITPCT > 0 || tasdetail.PROFITPCT < 0)) ? roundvalue(tasdetail.PROFITPCT, 2) + "%" : GetNumericValue(tasdetail.PROFITPCT, true);
                                var colorglval = tasdetail.PROFIT && tasdetail.PROFIT > 0 ? '#00A647' : tasdetail.PROFIT && tasdetail.PROFIT < 0 ? '#FB0000' : "";
                                var colorglvalpct = tasdetail.PROFITPCT && tasdetail.PROFITPCT > 0 ? '#00A647' : tasdetail.PROFITPCT && tasdetail.PROFITPCT < 0 ? '#FB0000' : "";
                                table += "<tr><td style='text-align:left; border-right: 0.1px solid black'></td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.DTAQD ? tasdetail.DTAQD.split('T')[0] : "-") + "</td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.DTSALE ? tasdetail.DTSALE.split('T')[0] : "-") + "</td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + tasdetail.LONGSHORT + "</td>"
                                //table += "<td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, invitem.AccountNo, IndLongShort.SINGLEVIEW)+ "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.NUMSHRS && tasdetail.NUMSHRS > 0 ? roundUnitValue(tasdetail.NUMSHRS) : GetNumericValue(tasdetail.NUMSHRS, false, true)) + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.COSTBASIS && tasdetail.COSTBASIS > 0 ? formatMoney(tasdetail.COSTBASIS) : GetNumericValue(tasdetail.COSTBASIS)) + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.STKBNDAMT && tasdetail.STKBNDAMT && tasdetail.STKBNDAMT > 0 ? formatMoney(tasdetail.STKBNDAMT) : GetNumericValue(tasdetail.STKBNDAMT)) + "</td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + tasdetail.WASHSALE + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglval + "'>" + unglVal + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglvalpct + "'>" + unglPct + "</td></tr>"
                            });

                        }
                    }
                    )

                }

            });
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        }

        table += "</table>"
    }

    //prepare table for security

    if(isSecurity) {
        table += "<th style='text-align:center; border: 0.1px solid black;font-size: 12px;font-family: Avenir;' colspan='12'>Realized Gain Loss - Security Holdings </th>"
        table += "</tr>"
        table += "<tr>"
        table += "<th style='text-align:left;border-bottom: 0.1px solid black;border-right: 0.1px solid black; font-size: 10px;'> Symbol </th>"
        table += "<th style='text-align:left;border-bottom: 0.1px solid black;border-right: 0.1px solid black; font-size: 10px;'> Description </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Account </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Acquisition Date </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Sale Date </th>"
        table += "<th style='text-align:left;border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Holding Period </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Quantity </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Cost Basis </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Net Proceeds </th>"
        table += "<th style='text-align:left; border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> Wash Sale </th>"

        table += "<th style='text-align:right;border-bottom: 0.1px solid black;border-right: 0.1px solid black;font-size: 10px;'> $ Net Gain/Loss </th>"
        table += "<th style='text-align:right; border-bottom: 0.1px solid black;font-size: 10px;'> % Net Gain/Loss </th>"
        table += "</tr>"
        if (topTen && topTen.realizedgainlossbysecurity && topTen.realizedgainlossbysecurity.length > 0 && topTen.realizedgainlossbysecurity[0].ITEMSUMMARY) {

            topTen.realizedgainlossbysecurity[0].ITEMSUMMARY.forEach(security => {
                var unglVal = (security.PROFIT && (security.PROFIT > 0 || security.PROFIT < 0)) ? formatMoney(security.PROFIT) : GetNumericValue(security.PROFIT);
                var unglPct = (security.PROFITPCT && (security.PROFITPCT > 0 || security.PROFITPCT < 0)) ? roundvalue(security.PROFITPCT, 2) + "%" : GetNumericValue(security.PROFITPCT, true);
                var colorglval = security.PROFIT && security.PROFIT > 0 ? '#00A647' : security.PROFIT && security.PROFIT < 0 ? '#FB0000' : "";
                var colorglvalpct = security.PROFITPCT && security.PROFITPCT > 0 ? '#00A647' : security.PROFITPCT && security.PROFITPCT < 0 ? '#FB0000' : "";
                table += "<tr><td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + GetTextValue(security.SECNAME) + "</td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + GetTextValue(security.SECDESCRIPTION) + "</td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (security.NUMSHRS && security.NUMSHRS > 0 ? roundUnitValue(security.NUMSHRS) : GetNumericValue(security.NUMSHRS, false, true)) + "</td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (security.COSTBASIS && security.COSTBASIS > 0 ? formatMoney(security.COSTBASIS) : GetNumericValue(security.COSTBASIS)) + "</td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (security.STKBNDAMT && security.STKBNDAMT > 0 ? formatMoney(security.STKBNDAMT) : GetNumericValue(security.STKBNDAMT)) + "</td>"
                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'></td>"

                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglval + "'>" + unglVal + "</td>"
                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglvalpct + "'>" + unglPct + "</td></tr>"
                if (security.DETAILSUMMARY && security.DETAILSUMMARY.length > 0) {
                    security.DETAILSUMMARY.forEach(invitem => {
                        var unglVal = (invitem.PROFIT && (invitem.PROFIT > 0 || invitem.PROFIT < 0)) ? formatMoney(invitem.PROFIT) : GetNumericValue(invitem.PROFIT);
                        var unglPct = (invitem.PROFITPCT && (invitem.PROFITPCT > 0 || invitem.PROFITPCT < 0)) ? roundvalue(invitem.PROFITPCT, 2) + "%" : GetNumericValue(invitem.PROFITPCT, true);
                        var colorglval = invitem.PROFIT && invitem.PROFIT > 0 ? '#00A647' : invitem.PROFIT && invitem.PROFIT < 0 ? '#FB0000' : "";
                        var colorglvalpct = invitem.PROFITPCT && invitem.PROFITPCT > 0 ? '#00A647' : invitem.PROFITPCT && invitem.PROFITPCT < 0 ? '#FB0000' : "";
                        table += "<tr><td style='text-align:left; border-right: 0.1px solid black'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + getAccountDisplayName(props.selectedaccount, invitem.ACCT, IndLongShort.SINGLEVIEW) + "</td>"
                        //table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + invitem.ACCT + "</td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                        table += "<td style='text-align:right;border-right: 0.1px solid black;font-size:8px'>" + (invitem.NUMSHRS && invitem.NUMSHRS > 0 ? roundUnitValue(invitem.NUMSHRS) : GetNumericValue(invitem.NUMSHRS, false, true)) + "</td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (invitem.COSTBASIS && invitem.COSTBASIS > 0 ? formatMoney(invitem.COSTBASIS) : GetNumericValue(invitem.COSTBASIS)) + "</td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (invitem.STKBNDAMT && invitem.STKBNDAMT && invitem.STKBNDAMT > 0 ? formatMoney(invitem.STKBNDAMT) : GetNumericValue(invitem.STKBNDAMT)) + "</td>"
                        table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"

                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglval + "'>" + unglVal + "</td>"
                        table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglvalpct + "'>" + unglPct + "</td></tr>"
                        if (invitem.DETAIL && invitem.DETAIL.length > 0) {
                            invitem.DETAIL.forEach(tasdetail => {
                                var unglVal = (tasdetail.PROFIT && (tasdetail.PROFIT > 0 || tasdetail.PROFIT < 0)) ? formatMoney(tasdetail.PROFIT) : GetNumericValue(tasdetail.PROFIT);
                                var unglPct = (tasdetail.PROFITPCT && (tasdetail.PROFITPCT > 0 || tasdetail.PROFITPCT < 0)) ? roundvalue(tasdetail.PROFITPCT, 2) + "%" : GetNumericValue(tasdetail.PROFITPCT, true);
                                var colorglval = tasdetail.PROFIT && tasdetail.PROFIT > 0 ? '#00A647' : tasdetail.PROFIT && tasdetail.PROFIT < 0 ? '#FB0000' : "";
                                var colorglvalpct = tasdetail.PROFITPCT && tasdetail.PROFITPCT > 0 ? '#00A647' : tasdetail.PROFITPCT && tasdetail.PROFITPCT < 0 ? '#FB0000' : "";
                                table += "<tr><td style='text-align:left; border-right: 0.1px solid black'></td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black'></td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.DTAQD ? tasdetail.DTAQD.split('T')[0] : "-") + "</td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.DTSALE ? tasdetail.DTSALE.split('T')[0] : "-") + "</td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + tasdetail.LONGSHORT + "</td>"
                                //table += "<td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, invitem.AccountNo, IndLongShort.SINGLEVIEW)+ "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.NUMSHRS && tasdetail.NUMSHRS > 0 ? roundUnitValue(tasdetail.NUMSHRS) : GetNumericValue(tasdetail.NUMSHRS, false, true)) + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.COSTBASIS && tasdetail.COSTBASIS > 0 ? formatMoney(tasdetail.COSTBASIS) : GetNumericValue(tasdetail.COSTBASIS)) + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.STKBNDAMT && tasdetail.STKBNDAMT && tasdetail.STKBNDAMT > 0 ? formatMoney(tasdetail.STKBNDAMT) : GetNumericValue(tasdetail.STKBNDAMT)) + "</td>"
                                table += "<td style='text-align:left; border-right: 0.1px solid black;font-size:8px'>" + (tasdetail.WASHSALE) + "</td>"

                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglval + "'>" + (unglVal) + "</td>"
                                table += "<td style='text-align:right; border-right: 0.1px solid black;font-size:8px;color:" + colorglvalpct + "'>" + (unglPct) + "</td></tr>"
                            });

                        }


                    })
                }
            });
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }

        table += "</table>"
    }

    console.log(table);
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();

}
export function printAssetAllocationDetailsReport(props) {

    var printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    //table += " Asset Allocation Details"
    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='8'>Asset Allocation Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'>Asset Class </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Symbol </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Description </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Account</td>"
    table += "<td style='text-align:right; border: 1px solid black; width:10%;'> Quantity </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:15%;'> Price </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:15%;'> Percentage </td>"
    table += "<td style='text-align:right; border: 1px solid black; width:20%;'> Value </td>"
    table += "</tr>"
    if (props !== null && props && props.assetallocationdetails[0].value) {
        const assetallocationData = renderTemplateRows(props.assetallocationdetails[0], props.assetschemacolorsconfig, props.assetSchema);
        assetallocationData.forEach(asset => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:15%;'>" + (GetTextValue(asset.assetcat)) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:10%;'></td>"
            table += "<td style='text-align:left; border: 1px solid black; width:15%;'></td>"
            table += "<td style='text-align:left; border: 1px solid black; width:15%;'></td>"
            table += "<td style='text-align:right; border: 1px solid black; width:10%;'></td>"
            table += "<td style='text-align:right; border: 1px solid black;width:15%;'></td>"
            table += "<td style='text-align:right; border: 1px solid black;width:15%;'>" + (asset.mktvalpct && asset.mktvalpct > 0 ? roundvalue(asset.mktvalpct, 2) + "%" : GetNumericValue(asset.mktvalpct, true)) + "</td>"
            table += "<td style='text-align:right; border: 1px solid black;width:20%;'>" + (asset.mktval && asset.mktval > 0 ? formatMoney(asset.mktval) : GetNumericValue(asset.mktval)) + "</td></tr>"
            if (asset.invpossummary) {
                asset.invpossummary.forEach(invpossummary => {
                    if (invpossummary.invposlist) {
                        invpossummary.invposlist.forEach(invposlist => {
                            let unit = ''
                            let rcntprice = ''
                            let rcntmktval = ''
                            if (invposlist.shrqnts && invposlist.shrqnts.unit) {
                                unit = roundUnitValue(invposlist.shrqnts.unit)
                            } else {
                                unit = GetNumericValue(invposlist.shrqnts.unit)
                            }
                            if (invposlist.pricing && invposlist.pricing.rcntprice) {
                                rcntprice = formatMoney(invposlist.pricing.rcntprice)
                            } else {
                                rcntprice = GetNumericValue(invposlist.pricing.rcntprice)
                            }
                            if (invposlist.mktvals && invposlist.mktvals.rcntmktval) {
                                rcntmktval = formatMoney(invposlist.mktvals.rcntmktval)
                            } else {
                                rcntmktval = GetNumericValue(invposlist.mktvals.rcntmktval)
                            }

                            table += "<tr><td style='text-align:left; border: 1px solid black; width:15%;'>" + GetTextValue(invposlist.secinfo.assetcat) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + GetTextValue(invposlist.secinfo.ticker ? invposlist.secinfo.ticker : invposlist.secinfo.unqid) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black; width:15%;'>" + GetTextValue(invposlist.secinfo.secname) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black; width:15%;'>" + getAccountDisplayName(props.selectedaccount, invposlist.acctnum, IndLongShort.SINGLEVIEW) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black; width:10%;'>" + unit + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black;width:15%;'>" + rcntprice + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black;width:15%;'>" + (invposlist.assettotalpct && invposlist.assettotalpct > 0 ? roundvalue(invposlist.assettotalpct, 2) + "%" : GetNumericValue(invposlist.assettotalpct, true)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black;width:20%;'>" + rcntmktval + "</td></tr>"
                        })
                    }
                })
            }
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }
    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}
export function printFinancialDetailsReport(props) {

    var printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    let obj = {
        preferences: props.preferences
    }
    let balancesummaryV2PilotFlag = GetPreferencePilotValue(obj, 'BalSummaryV2', 'false') === 'true' ? true : false;
    //table += " Asset Allocation Details"

    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 
    table += "<tr><th><table style='border: 1px solid black' cellspacing='0' ><tr><th style='text-align:center; border: 1px solid black;' colspan='6'> Financial Details </th>"
    table += "</tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:5%;'>Account </td>"
    table += "<td style='text-align:left; border: 1px solid black'> Priced Investments </td>"
    table += "<td style='text-align:left; border: 1px solid black' width:10%;> Cash/Money Accounts </td>"
    balancesummaryV2PilotFlag ?
        table += "<td style='text-align:left; border: 1px solid black' width:2%;> Other Credit/Debit </td>"
        :
        table += "<td style='text-align:left; border: 1px solid black' width:2%;> Margin Balance </td>"
    table += "<td style='text-align:left; border: 1px solid black' width:2%;> Accrued Interest </td>"
    table += "<td style='text-align:left; border: 1px solid black'> Pending Activity(Cash/Securities) </td>"
    table += "<td style='text-align:left; border: 1px solid black' width:2%;> Outstanding Balance </td>"
    table += "<td style='text-align:left; border: 1px solid black' width:2%;> Insurance/Annuity </td>"
    table += "<td style='text-align:left; border: 1px solid black'> Value </td>"
    table += "</tr>"

    if (props.balancedetails.value[0]) {
        props.balancedetails.value[0].accountbalances.forEach(balance => {
            if (balance) {
                let pricedinvestments = ''
                let cashmoneyaccounts = ''
                let marginbalance = ''
                let pendingactivity = ''
                let totalaccountvalue = ''
                let fixedincomeaccruedinterest = ''
                let outstandingbalance = ''
                let annuity = ''

                if (balance.pricedinvestments) {
                    pricedinvestments = formatMoney(balance.pricedinvestments)
                } else {
                    pricedinvestments = GetNumericValue(balance.pricedinvestments)
                }
                if (balance.cashmoneyaccounts) {
                    cashmoneyaccounts = formatMoney(balance.cashmoneyaccounts)
                } else {
                    cashmoneyaccounts = GetNumericValue(balance.cashmoneyaccounts)
                }
                if (balancesummaryV2PilotFlag) {
                    if (balance.othercreditdebit) {
                        marginbalance = formatMoney(balance.othercreditdebit)
                    } else {
                        marginbalance = GetNumericValue(balance.othercreditdebit)
                    }
                }
                else {
                    if (balance.marginbalance) {
                        marginbalance = formatMoney(balance.marginbalance)
                    } else {
                        marginbalance = GetNumericValue(balance.marginbalance)
                    }
                }

                if (balance.pendingactivity) {
                    pendingactivity = formatMoney(balance.pendingactivity)
                } else {
                    pendingactivity = GetNumericValue(balance.pendingactivity)
                }
                if (balance.totalaccountvalue) {
                    totalaccountvalue = formatMoney(balance.totalaccountvalue)
                } else {
                    totalaccountvalue = GetNumericValue(balance.totalaccountvalue)
                }
                if (balance.fixedincomeaccruedinterest) {
                    fixedincomeaccruedinterest = formatMoney(balance.fixedincomeaccruedinterest)
                } else {
                    fixedincomeaccruedinterest = GetNumericValue(balance.fixedincomeaccruedinterest)
                }
                if (balance.outstandingbalance) {
                    outstandingbalance = formatMoney(balance.outstandingbalance)
                } else {
                    outstandingbalance = GetNumericValue(balance.outstandingbalance)
                }
                if (balance.annuity) {
                    annuity = formatMoney(balance.annuity)
                } else {
                    annuity = GetNumericValue(balance.annuity)
                }

                table += "<tr><td style='text-align:left; border: 1px solid black'>" + getAccountDisplayName(props.selectedaccount, balance.accountaumber, IndLongShort.SINGLEVIEW) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + pricedinvestments + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + cashmoneyaccounts + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + marginbalance + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + fixedincomeaccruedinterest + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + pendingactivity + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + outstandingbalance + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + annuity + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + totalaccountvalue + "</td>"
            }

        });
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }
    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}
export function printTransfersDetails(props) {
    let printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    let pastTransfersList = props.moneymovement.pastTransfersList;
    pastTransfersList.sort(function (a, b) {
        if (b['dttrade']) return a['dttrade'] ? b['dttrade'].localeCompare(a['dttrade']) : 1;
        else if (a['dttrade']) return (b['dttrade']) ? a['dttrade'].localeCompare(b['dttrade']) : -1;
    });
    let scheduledTransfersList = props.moneymovement.scheduledTransfersList;
    scheduledTransfersList.sort(function (a, b) {
        if (b['dttrade']) return a['dttrade'] ? b['dttrade'].localeCompare(a['dttrade']) : 1;
        else if (a['dttrade']) return (b['dttrade']) ? a['dttrade'].localeCompare(b['dttrade']) : -1;
    });

    table += scheduleTrasfersPrintTable(props, scheduledTransfersList);
    table += "<br/>"
    table += pastTransfersPrintTable(props, pastTransfersList);
    printWindow.document.write(table);
    printWindow.print()
    printWindow.close();
}

export function printDocumentDetails(props) {
    let printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='6'>Document Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Date </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Document Type </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Account</td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Description</td>"
    table += "</tr>"

    if (props && props.acct && props.acct.filteredStatementsList) {
        props.acct.filteredStatementsList.forEach(doc => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:10%;'>" + GetTextValue(doc.date) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + GetTextValue(doc.documentType) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + getAccountDisplayName(props.selectedaccount, doc.primaryValue, IndLongShort.SINGLEVIEW) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + GetTextValue(doc.description) + "</td>"
            table += "</tr>"
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "</tr>"
    }
    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printAccountStatementsDetails(props) {

    let printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='6'>Document Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Account</td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Document Name </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Date</td>"
    table += "</tr>"

    if (props && props.doc && props.doc.filteredAccountStatementsList) {
        props.doc.filteredAccountStatementsList.forEach(doc => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:10%;'>" + getAccountDisplayName(props.selectedaccount, doc.primaryValue, IndLongShort.SINGLEVIEW) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + doc.description + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + (doc.date) + "</td>"
            table += "</tr>"
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "</tr>"
    }
    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printCorrespondenceDetails(props) {

    let printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='6'>Correspondence Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Account </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:30%;'> Document Name </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Date </td>"
    table += "</tr>"

    if (props && props.doc && props.doc.filteredCorrespondenceDocumentList) {
        props.doc.filteredCorrespondenceDocumentList.forEach(doc => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:10%;'>" + getAccountDisplayName(props.selectedaccount, doc.primaryValue, IndLongShort.SINGLEVIEW) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:30%;'>" + doc.description + "</td>"
            table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + doc.date + "</td>"
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }
    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printTaxDocumentDetails(props) {

    let printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 

    table += "<tr><th colspan='6'>Tax Document Details</th></tr>"
    table += "<tr>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Account </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:20%;'> Document Type </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:20%;'> Corrected </td>"
    table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Date </td>"
    table += "</tr>"

    if (props && props.doc && props.doc.filteredTaxDocumentList) {
        props.doc.filteredTaxDocumentList.forEach(doc => {
            table += "<tr><td style='text-align:left; border: 1px solid black; width:10%;'>" + getAccountDisplayName(props.selectedaccount, doc.primaryValue, IndLongShort.SINGLEVIEW) + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:20%;'>" + doc.description + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:20%;'>" + doc.corrected + "</td>"
            table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + doc.date + "</td>"
        })
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
    }
    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printTradeConfirmDetails(props) {
    let printWindow = window.open('', 'RCM Print', '');
    let table = GetHeaderHtmlPrint(props);
    table += "<table style='border: 1px solid black; width:100%;' cellspacing='0' >"
    //prepare table header columns 


    if (props.doc.isSearchByTradeConfirmSummary) {
        table += "<tr><th colspan='6'>Trade Confirm Details</th></tr>"
        table += "<tr>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Account</td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Summary Date </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:30%;'> Description </td>"

        if (props && props.doc && props.doc.filteredTradeConfirmSummaryDocumentList) {
            props.doc.filteredTradeConfirmSummaryDocumentList.forEach(doc => {
                table += "<tr><td style='text-align:left; border: 1px solid black; width:10%;'>" + getAccountDisplayName(props.selectedaccount, doc.primaryValue, IndLongShort.SINGLEVIEW) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + doc.summaryDate + "</td>"
                table += "<td style='text-align:left; border: 1px solid black;width:30%;'>" + doc.description + "</td>"
            })
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        }

    } else {
        table += "<tr><th colspan='12'>Trade Confirm Details</th></tr>"
        table += "<tr>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'>Account</td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Trade Date </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Description </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Security Type </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Action </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Symbol </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:15%;'> Cusip </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Quantity </td>"
        table += "<td style='text-align:left; border: 1px solid black; width:10%;'> Price </td>"

        if (props && props.doc && props.doc.filteredTradeConfirmDocumentList) {
            props.doc.filteredTradeConfirmDocumentList.forEach(doc => {

                let cusip = ''
                let quantity = ''
                let total = ''
                if (doc.cusip) {
                    cusip = doc.cusip.trim()
                } else {
                    cusip = '-'
                }
                if (doc.quantity) {
                    quantity = roundvalue(parseFloat(doc.quantity), 2)
                } else {
                    quantity = '-'
                }
                if (doc.price) {
                    total = formatMoney(doc.price)
                } else {
                    total = '-'
                }
                table += "<tr><td style='text-align:left; border: 1px solid black; width:10%;'>" + getAccountDisplayName(props.selectedaccount, doc.primaryValue, IndLongShort.SINGLEVIEW) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + GetTextValue(doc.tradeDate) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black;width:10%;'>" + GetTextValue(doc.description) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + GetTextValue(doc.securityType) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + GetTextValue(doc.action) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + GetTextValue(doc.symbol) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:15%;'>" + cusip + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + quantity + "</td>"
                table += "<td style='text-align:left; border: 1px solid black; width:10%;'>" + total + "</td>"
            })
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"

        }

    }

    table += "</tr>"


    table += "</table>"
    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printActivityDetailsReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props);

    //prepare table header columns 


    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='9'> Activity </th>"
    table += "</tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Trade Date </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Settlement Date </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Type </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Symbol/CUSIP </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Quantity </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Price </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Amount </th>"
    table += "</tr>"
    if (props && props.activitydetails && props.activitydetails.value[0] && props.activitydetails.value[0].activities) {
        const data = props.activitydetails.value[0].activities;
        data.sort(function (a, b) {
            if (b['dtsettle']) return a['dtsettle'] ? b['dtsettle'].localeCompare(a['dtsettle']) : 1;
            else if (a['dtsettle']) return (b['dtsettle']) ? a['dtsettle'].localeCompare(b['dtsettle']) : -1;
        });
        data.forEach(activity => {
            if (activity) {
                let units = ''
                let unitprice = ''
                let total = ''
                if (activity.units) {
                    units = roundUnitValue(activity.units)
                } else {
                    units = GetNumericValue(activity.units)
                }
                if (activity.unitprice) {
                    unitprice = formatMoney(activity.unitprice)
                } else {
                    unitprice = GetNumericValue(activity.unitprice)
                }
                if (activity.total) {
                    total = formatMoney(activity.total)
                } else {
                    total = GetNumericValue(activity.total)
                }
                table += "<tr><td style='text-align:left; border: 1px solid black'>" + (activity.dttrade ? activity.dttrade.split('T')[0] : GetTextValue(activity.dttrade)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.dtsettle ? activity.dtsettle.split('T')[0] : GetTextValue(activity.dtsettle)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.acct ? getAccountDisplayName(props.selectedaccount, activity.acct, IndLongShort.SINGLEVIEW) : GetTextValue(activity.acct)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.trndesc ? activity.trndesc : GetTextValue(activity.trndesc)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.trntype ? activity.trntype : GetTextValue(activity.trntype)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (activity.secid ? activity.secid : GetTextValue(activity.secid)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + units + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + unitprice + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + total + "</td></tr>"
            }
        });
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td></tr>"
    }
    table += "</table>"

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();

}

export function printAssetAggregation(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='6'> AGGREGATED ACCOUNTS </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> BankName </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Nickname</th>"
    table += "<th style='text-align:left; border: 1px solid black'> AccountType </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Status</th>"
    table += "<th style='text-align:left; border: 1px solid black'> Last Updated Date</th>"
    table += "</tr>"
    if (props && props.acct && props.acct.aggregatedAccountList && props.acct.aggregatedAccountList.length > 0) {
        props.acct.aggregatedAccountList.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.finame ? row.finame : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.number ? row.number : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.nickname ? row.nickname : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.accttype ? row.accttype : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.status ? row.status : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.updatedon ? row.updatedon : '') + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printEstimatedIncomeSecurityReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(propsacct);

    //prepare table header columns 


    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> HISTORICAL INCOME BY SECURITY </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Dividend </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Interest </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Net Income </th>"
    table += "</tr>"
    if (props && props.topten && props.topten.estimatedincomebysecurity &&
        props.topten.estimatedincomebysecurity[0] && props.topten.estimatedincomebysecurity[0].itemSummary &&
        Array.isArray(props.topten.estimatedincomebysecurity[0].itemSummary)) {
        props.topten.estimatedincomebysecurity[0].itemSummary.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-")) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECDESCRIPTION ? row.SECDESCRIPTION : "-") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.ORDDIV ? formatMoney(row.ORDDIV) : GetNumericValue(row.ORDDIV)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.INTINCOME ? formatMoney(row.INTINCOME) : GetNumericValue(row.INTINCOME)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.TOTAL ? formatMoney(row.TOTAL) : GetNumericValue(row.TOTAL)) + "</td>"
                table += "<tr/>"

                //Add sub level data here....
                if (row.ItemDetail && Array.isArray(row.ItemDetail)) {
                    row.ItemDetail.forEach(subrow => {
                        if (subrow) {
                            table += "<tr>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.ORDDIV ? formatMoney(subrow.ORDDIV) : GetNumericValue(subrow.ORDDIV)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.INTINCOME ? formatMoney(subrow.INTINCOME) : GetNumericValue(subrow.INTINCOME)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.TOTAL ? formatMoney(subrow.TOTAL) : GetNumericValue(subrow.TOTAL)) + "</td>"
                            table += "<tr/>"
                        }
                    })
                }
            }
        });

        //put the footer value here....
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedincomebysecurity[0].ODNDividend ? formatMoney(props.topten.estimatedincomebysecurity[0].ODNDividend) : GetNumericValue(props.topten.estimatedincomebysecurity[0].ODNDividend)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedincomebysecurity[0].TotalInt ? formatMoney(props.topten.estimatedincomebysecurity[0].TotalInt) : GetNumericValue(props.topten.estimatedincomebysecurity[0].TotalInt)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedincomebysecurity[0].NetIncome ? formatMoney(props.topten.estimatedincomebysecurity[0].NetIncome) : GetNumericValue(props.topten.estimatedincomebysecurity[0].NetIncome)) + "</th>"
        table += "<th/>"
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}


export function printEstimatedIncomeAccountReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(propsacct);

    //prepare table header columns 


    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> HISTORICAL INCOME BY ACCOUNT </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Dividend </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Interest </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Net Income </th>"
    table += "</tr>"
    if (props && props.topten && props.topten.estimatedincomebyaccount &&
        props.topten.estimatedincomebyaccount[0] && props.topten.estimatedincomebyaccount[0].itemSummary &&
        Array.isArray(props.topten.estimatedincomebyaccount[0].itemSummary)) {
        props.topten.estimatedincomebyaccount[0].itemSummary.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.ORDDIV ? formatMoney(row.ORDDIV) : GetNumericValue(row.ORDDIV)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.INTINCOME ? formatMoney(row.INTINCOME) : GetNumericValue(row.INTINCOME)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.TOTAL ? formatMoney(row.TOTAL) : GetNumericValue(row.TOTAL)) + "</td>"
                table += "<tr/>"
            }

            //Add sub level data here....
            if (row.ItemDetail && Array.isArray(row.ItemDetail)) {
                row.ItemDetail.forEach(subrow => {
                    if (subrow) {
                        table += "<tr>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECNAME ? subrow.SECNAME : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-")) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-") + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.ORDDIV ? formatMoney(subrow.ORDDIV) : GetNumericValue(subrow.ORDDIV)) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.INTINCOME ? formatMoney(subrow.INTINCOME) : GetNumericValue(subrow.INTINCOME)) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.TOTAL ? formatMoney(subrow.TOTAL) : GetNumericValue(subrow.TOTAL)) + "</td>"
                        table += "<tr/>"
                    }
                })
            }

        });

        //put the footer value here....
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedincomebyaccount[0].ODNDividend ? formatMoney(props.topten.estimatedincomebyaccount[0].ODNDividend) : GetNumericValue(props.topten.estimatedincomebyaccount[0].ODNDividend)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedincomebyaccount[0].TotalInt ? formatMoney(props.topten.estimatedincomebyaccount[0].TotalInt) : GetNumericValue(props.topten.estimatedincomebyaccount[0].TotalInt)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedincomebyaccount[0].NetIncome ? formatMoney(props.topten.estimatedincomebyaccount[0].NetIncome) : GetNumericValue(props.topten.estimatedincomebyaccount[0].NetIncome)) + "</th>"
        table += "<th/>"
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printEstimatedIncomeSummaryReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(propsacct);
    let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //prepare table header columns 
    if (props && props.topten.estimatedIncomeSummarytoggleview === 'C') {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='5'> HISTORICAL INCOME SUMMARY </th></tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Month </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Interest </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Dividend </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Capital Gain </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Total </th>"

        table += "</tr>"
        if (props && props.topten && props.topten.estimatedIncomeSummaryChartData &&
            props.topten.estimatedIncomeSummaryChartData[0] && props.topten.estimatedIncomeSummaryChartData[0].incomeSubTotal &&
            Array.isArray(props.topten.estimatedIncomeSummaryChartData[0].incomeSubTotal)) {
            props.topten.estimatedIncomeSummaryChartData[0].incomeSubTotal.forEach(row => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.monseq ? (textfieldarr[row.monseq - 1] + ' ' + props.topten.estimatedIncomeYear.toString().substr(-2)) : '-') + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.interest ? formatMoney(row.interest) : GetNumericValue(row.interest)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.dividend ? formatMoney(row.dividend) : GetNumericValue(row.dividend)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.capGain ? formatMoney(row.capGain) : GetNumericValue(row.capGain)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.totalIncome ? formatMoney(row.totalIncome) : GetNumericValue(row.totalIncome)) + "</td>"
                    table += "<tr/>"
                }
            });

            //put the footer value here....
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryChartData[0].interest ? formatMoney(props.topten.estimatedIncomeSummaryChartData[0].interest) : GetNumericValue(props.topten.estimatedIncomeSummaryChartData[0].interest)) + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryChartData[0].dividend ? formatMoney(props.topten.estimatedIncomeSummaryChartData[0].dividend) : GetNumericValue(props.topten.estimatedIncomeSummaryChartData[0].dividend)) + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryChartData[0].capGain ? formatMoney(props.topten.estimatedIncomeSummaryChartData[0].capGain) : GetNumericValue(props.topten.estimatedIncomeSummaryChartData[0].capGain)) + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryChartData[0].total ? formatMoney(props.topten.estimatedIncomeSummaryChartData[0].total) : GetNumericValue(props.topten.estimatedIncomeSummaryChartData[0].total)) + "</th>"
            table += "<th/>"
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>";
        table += "</table>";
    }
    else if (props && props.topten.estimatedIncomeSummarytoggleview === 'A') {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='16'> HISTORICAL INCOME SUMMARY BY ACCOUNT </th></tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Jan" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + " </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Feb" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> March" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> April" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> May" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> June" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> July" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> August " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Sept" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Oct" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Nov" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Dec" + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "  </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Net Income </th>"
        table += "</tr>"
        if (props && props.topten && props.topten.estimatedIncomeSummaryAccountData &&
            props.topten.estimatedIncomeSummaryAccountData.value && props.topten.estimatedIncomeSummaryAccountData.value[0] && props.topten.estimatedIncomeSummaryAccountData.value[0].IncomeSubTotal &&
            Array.isArray(props.topten.estimatedIncomeSummaryAccountData.value[0].IncomeSubTotal)) {
            props.topten.estimatedIncomeSummaryAccountData.value[0].IncomeSubTotal.forEach(row => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.JanuaryTotal ? formatMoney(row.JanuaryTotal) : GetNumericValue(row.JanuaryTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.FebruaryTotal ? formatMoney(row.FebruaryTotal) : GetNumericValue(row.FebruaryTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.MarchTotal ? formatMoney(row.MarchTotal) : GetNumericValue(row.MarchTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.AprilTotal ? formatMoney(row.AprilTotal) : GetNumericValue(row.AprilTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.MayTotal ? formatMoney(row.MayTotal) : GetNumericValue(row.MayTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.JuneTotal ? formatMoney(row.JuneTotal) : GetNumericValue(row.JuneTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.JulyTotal ? formatMoney(row.JulyTotal) : GetNumericValue(row.JulyTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.AugustTotal ? formatMoney(row.AugustTotal) : GetNumericValue(row.AugustTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.SeptemberTotal ? formatMoney(row.SeptemberTotal) : GetNumericValue(row.SeptemberTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.OctoberTotal ? formatMoney(row.OctoberTotal) : GetNumericValue(row.OctoberTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.NovemberTotal ? formatMoney(row.NovemberTotal) : GetNumericValue(row.NovemberTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.DecemberTotal ? formatMoney(row.DecemberTotal) : GetNumericValue(row.DecemberTotal)) + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.Total ? formatMoney(row.Total) : GetNumericValue(row.Total)) + "</td>"
                    table += "<tr/>"

                    //Add sub level data here....
                    if (row.IncomeDetail && Array.isArray(row.IncomeDetail)) {
                        row.IncomeDetail.forEach(subrow => {
                            if (subrow) {
                                table += "<tr>"
                                table += "<td style='text-align:left; border: 1px solid black'></td>"
                                table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECNAME ? subrow.SECNAME : "-") + "</td>"
                                table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-") + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.JanuaryTotal ? formatMoney(subrow.JanuaryTotal) : GetNumericValue(subrow.JanuaryTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.FebruaryTotal ? formatMoney(subrow.FebruaryTotal) : GetNumericValue(subrow.FebruaryTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.MarchTotal ? formatMoney(subrow.MarchTotal) : GetNumericValue(subrow.MarchTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.AprilTotal ? formatMoney(subrow.AprilTotal) : GetNumericValue(subrow.AprilTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.MayTotal ? formatMoney(subrow.MayTotal) : GetNumericValue(subrow.MayTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.JuneTotal ? formatMoney(subrow.JuneTotal) : GetNumericValue(subrow.JuneTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.JulyTotal ? formatMoney(subrow.JulyTotal) : GetNumericValue(subrow.JulyTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.AugustTotal ? formatMoney(subrow.AugustTotal) : GetNumericValue(subrow.AugustTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.SeptemberTotal ? formatMoney(subrow.SeptemberTotal) : GetNumericValue(subrow.SeptemberTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.OctoberTotal ? formatMoney(subrow.OctoberTotal) : GetNumericValue(subrow.OctoberTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.NovemberTotal ? formatMoney(subrow.NovemberTotal) : GetNumericValue(subrow.NovemberTotal)) + "</td>"
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.DecemberTotal ? formatMoney(subrow.DecemberTotal) : GetNumericValue(subrow.DecemberTotal)) + "</td>"
                                table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.Total ? formatMoney(subrow.Total) : GetNumericValue(subrow.Total)) + "</td>"
                                table += "<tr/>"
                            }


                        })

                    }

                }
            })

            //put the footer value here....
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].JanuaryTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].JanuaryTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].JanuaryTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].FebruaryTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].FebruaryTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].FebruaryTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].MarchTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].MarchTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].MarchTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].AprilTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].AprilTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].AprilTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].MayTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].MayTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].MayTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].JuneTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].JuneTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].JuneTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].JulyTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].JulyTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].JulyTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].AugustTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].AugustTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].AugustTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].SeptemberTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].SeptemberTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].SeptemberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].OctoberTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].OctoberTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].OctoberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].NovemberTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].NovemberTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].NovemberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].DecemberTotal ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].DecemberTotal) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].DecemberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummaryAccountData.value[0].Total ? formatMoney(props.topten.estimatedIncomeSummaryAccountData.value[0].Total) : GetNumericValue(props.topten.estimatedIncomeSummaryAccountData.value[0].Total)) + "</th>"
            table += "<th/>"
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>";
        table += "</table>";

    }
    else if (props && props.topten.estimatedIncomeSummarytoggleview === 'S') {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='16'> HISTORICAL INCOME SUMMARY BY SECURITY </th></tr>"
        table += "<tr>"

        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Jan " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Feb " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> March " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> April " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> May " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> June " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> July " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> August " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Sept " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Oct " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Nov " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Dec " + (props.topten.estimatedIncomeYear ? ' ' + props.topten.estimatedIncomeYear.toString().substr(-2) : '') + "</th>"
        table += "<th style='text-align:right; border: 1px solid black'> Net Income </th>"
        table += "</tr>"
        if (props && props.topten && props.topten.estimatedIncomeSummarySecurityData &&
            props.topten.estimatedIncomeSummarySecurityData.value && props.topten.estimatedIncomeSummarySecurityData.value[0] && props.topten.estimatedIncomeSummarySecurityData.value[0].IncomeSubTotal &&
            Array.isArray(props.topten.estimatedIncomeSummarySecurityData.value[0].IncomeSubTotal)) {
            props.topten.estimatedIncomeSummarySecurityData.value[0].IncomeSubTotal.forEach(row => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECNAME ? row.SECNAME : "-") + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECDESCRIPTION ? row.SECDESCRIPTION : "-") + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.JanuaryTotal ? formatMoney(row.JanuaryTotal) : GetNumericValue(row.JanuaryTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.FebruaryTotal ? formatMoney(row.FebruaryTotal) : GetNumericValue(row.FebruaryTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.MarchTotal ? formatMoney(row.MarchTotal) : GetNumericValue(row.MarchTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.AprilTotal ? formatMoney(row.AprilTotal) : GetNumericValue(row.AprilTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.MayTotal ? formatMoney(row.MayTotal) : GetNumericValue(row.MayTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.JuneTotal ? formatMoney(row.JuneTotal) : GetNumericValue(row.JuneTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.JulyTotal ? formatMoney(row.JulyTotal) : GetNumericValue(row.JulyTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.AugustTotal ? formatMoney(row.AugustTotal) : GetNumericValue(row.AugustTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.SeptemberTotal ? formatMoney(row.SeptemberTotal) : GetNumericValue(row.SeptemberTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.OctoberTotal ? formatMoney(row.OctoberTotal) : GetNumericValue(row.OctoberTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.NovemberTotal ? formatMoney(row.NovemberTotal) : GetNumericValue(row.NovemberTotal)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.DecemberTotal ? formatMoney(row.DecemberTotal) : GetNumericValue(row.DecemberTotal)) + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.Total ? formatMoney(row.Total) : GetNumericValue(row.Total)) + "</td>"
                    table += "<tr/>"
                }

                //Add sub level data here....
                if (row.IncomeDetail && Array.isArray(row.IncomeDetail)) {
                    row.IncomeDetail.forEach(subrow => {
                        if (subrow) {
                            table += "<tr>"
                            table += "<td style='text-align:left; border: 1px solid black'></td>"
                            table += "<td style='text-align:left; border: 1px solid black'></td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.JanuaryTotal ? formatMoney(subrow.JanuaryTotal) : GetNumericValue(subrow.JanuaryTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.FebruaryTotal ? formatMoney(subrow.FebruaryTotal) : GetNumericValue(subrow.FebruaryTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.MarchTotal ? formatMoney(subrow.MarchTotal) : GetNumericValue(subrow.MarchTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.AprilTotal ? formatMoney(subrow.AprilTotal) : GetNumericValue(subrow.AprilTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.MayTotal ? formatMoney(subrow.MayTotal) : GetNumericValue(subrow.MayTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.JuneTotal ? formatMoney(subrow.JuneTotal) : GetNumericValue(subrow.JuneTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.JulyTotal ? formatMoney(subrow.JulyTotal) : GetNumericValue(subrow.JulyTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.AugustTotal ? formatMoney(subrow.AugustTotal) : GetNumericValue(subrow.AugustTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.SeptemberTotal ? formatMoney(subrow.SeptemberTotal) : GetNumericValue(subrow.SeptemberTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.OctoberTotal ? formatMoney(subrow.OctoberTotal) : GetNumericValue(subrow.OctoberTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.NovemberTotal ? formatMoney(subrow.NovemberTotal) : GetNumericValue(subrow.NovemberTotal)) + "</td>"
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.DecemberTotal ? formatMoney(subrow.DecemberTotal) : GetNumericValue(subrow.DecemberTotal)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.Total ? formatMoney(subrow.Total) : GetNumericValue(subrow.Total)) + "</td>"
                            table += "<tr/>"
                        }
                    })
                }

            });

            //put the footer value here....
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].JanuaryTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].JanuaryTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].JanuaryTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].FebruaryTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].FebruaryTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].FebruaryTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].MarchTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].MarchTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].MarchTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].AprilTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].AprilTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].AprilTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].MayTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].MayTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].MayTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].JuneTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].JuneTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].JuneTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].JulyTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].JulyTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].JulyTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].AugustTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].AugustTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].AugustTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].SeptemberTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].SeptemberTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].SeptemberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].OctoberTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].OctoberTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].OctoberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].NovemberTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].NovemberTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].NovemberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].DecemberTotal ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].DecemberTotal) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].DecemberTotal)) + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.estimatedIncomeSummarySecurityData.value[0].Total ? formatMoney(props.topten.estimatedIncomeSummarySecurityData.value[0].Total) : GetNumericValue(props.topten.estimatedIncomeSummarySecurityData.value[0].Total)) + "</th>"
            table += "<th/>"
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>";
        table += "</table>";
    }

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}


export function printProjectedIncomeSecurityReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(propsacct);

    //prepare table header columns 


    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> PROJECTED INCOME BY SECURITY </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Dividend </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Interest </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Net Income </th>"
    table += "</tr>"
    if (props && props.topten && props.topten.projectedincomebysecurity &&
        props.topten.projectedincomebysecurity[0] && props.topten.projectedincomebysecurity[0].itemSummary &&
        Array.isArray(props.topten.projectedincomebysecurity[0].itemSummary)) {
        props.topten.projectedincomebysecurity[0].itemSummary.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECNAME ? row.SECNAME : (row.CUSIPNUM ? row.CUSIPNUM : "-")) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECDESCRIPTION ? row.SECDESCRIPTION : "-") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.ORDDIV ? formatMoney(row.ORDDIV) : GetNumericValue(row.ORDDIV)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.INTINCOME ? formatMoney(row.INTINCOME) : GetNumericValue(row.INTINCOME)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.TOTAL ? formatMoney(row.TOTAL) : GetNumericValue(row.TOTAL)) + "</td>"
                table += "<tr/>"

                //Add sub level data here....
                if (row.ItemDetail && Array.isArray(row.ItemDetail)) {
                    row.ItemDetail.forEach(subrow => {
                        if (subrow) {
                            table += "<tr>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.ORDDIV ? formatMoney(subrow.ORDDIV) : GetNumericValue(subrow.ORDDIV)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.INTINCOME ? formatMoney(subrow.INTINCOME) : GetNumericValue(subrow.INTINCOME)) + "</td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.TOTAL ? formatMoney(subrow.TOTAL) : GetNumericValue(subrow.TOTAL)) + "</td>"
                            table += "<tr/>"
                        }
                    })
                }
            }
        });

        //put the footer value here....
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.projectedincomebysecurity[0].ODNDividend ? formatMoney(props.topten.projectedincomebysecurity[0].ODNDividend) : GetNumericValue(props.topten.projectedincomebysecurity[0].ODNDividend)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.projectedincomebysecurity[0].TotalInt ? formatMoney(props.topten.projectedincomebysecurity[0].TotalInt) : GetNumericValue(props.topten.projectedincomebysecurity[0].TotalInt)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.projectedincomebysecurity[0].NetIncome ? formatMoney(props.topten.projectedincomebysecurity[0].NetIncome) : GetNumericValue(props.topten.projectedincomebysecurity[0].NetIncome)) + "</th>"
        table += "<th/>"
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}


export function printProjectedIncomeAccountReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(propsacct);

    //prepare table header columns 


    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> PROJECTED INCOME BY ACCOUNT </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Dividend </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Interest </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Net Income </th>"
    table += "</tr>"
    if (props && props.topten && props.topten.projectedincomebyaccount &&
        props.topten.projectedincomebyaccount[0] && props.topten.projectedincomebyaccount[0].itemSummary &&
        Array.isArray(props.topten.projectedincomebyaccount[0].itemSummary)) {
        props.topten.projectedincomebyaccount[0].itemSummary.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.ORDDIV ? formatMoney(row.ORDDIV) : GetNumericValue(row.ORDDIV)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.INTINCOME ? formatMoney(row.INTINCOME) : GetNumericValue(row.INTINCOME)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.TOTAL ? formatMoney(row.TOTAL) : GetNumericValue(row.TOTAL)) + "</td>"
                table += "<tr/>"
            }

            //Add sub level data here....
            if (row.ItemDetail && Array.isArray(row.ItemDetail)) {
                row.ItemDetail.forEach(subrow => {
                    if (subrow) {
                        table += "<tr>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + "" + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECNAME ? subrow.SECNAME : (subrow.CUSIPNUM ? subrow.CUSIPNUM : "-")) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-") + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.ORDDIV ? formatMoney(subrow.ORDDIV) : GetNumericValue(subrow.ORDDIV)) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.INTINCOME ? formatMoney(subrow.INTINCOME) : GetNumericValue(subrow.INTINCOME)) + "</td>"
                        table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.TOTAL ? formatMoney(subrow.TOTAL) : GetNumericValue(subrow.TOTAL)) + "</td>"
                        table += "<tr/>"
                    }
                })
            }

        });

        //put the footer value here....
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + "" + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.projectedincomebyaccount[0].ODNDividend ? formatMoney(props.topten.projectedincomebyaccount[0].ODNDividend) : GetNumericValue(props.topten.projectedincomebyaccount[0].ODNDividend)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.projectedincomebyaccount[0].TotalInt ? formatMoney(props.topten.projectedincomebyaccount[0].TotalInt) : GetNumericValue(props.topten.projectedincomebyaccount[0].TotalInt)) + "</th>"
        table += "<th style='text-align:left; border: 1px solid black'>" + (props.topten.projectedincomebyaccount[0].NetIncome ? formatMoney(props.topten.projectedincomebyaccount[0].NetIncome) : GetNumericValue(props.topten.projectedincomebyaccount[0].NetIncome)) + "</th>"
        table += "<th/>"
    }
    else {
        table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
        table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}
function GetColumnsForInputMonth(start, end, table, iscurrentyear, isSecurity = false, isEstimatedPayDate = false) {
    
    let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    for (var i = start; i < end; i++) {
        table += "<th style='text-align:right; border: 1px solid black'> " + (textfieldarr[i]) + ' ' + (iscurrentyear ? new Date().getFullYear().toString().substr(-2) : (new Date().getFullYear() + 1).toString().substr(-2)) + " </th>";
        
        if (isEstimatedPayDate && isSecurity) table += "<th style='text-align:left; border: 1px solid black'> Est. pay date </th>";
    }
    return table;
}
function GetColumnValuesForInputMonth(start, end, table, row, isSecurity = false, isEstimatedPayDate = false) {
   
    let datafieldarr = ["JanuaryTotal", "FebruaryTotal", "MarchTotal", "AprilTotal", "MayTotal", "JuneTotal", "JulyTotal", "AugustTotal", "SeptemberTotal", "OctoberTotal", "NovemberTotal", "DecemberTotal"]
    let datefieldarr = ["JanuaryPayDate", "FebruaryPayDate", "MarchPayDate", "AprilPayDate", "MayPayDate", "JunePayDate", "JulyPayDate", "AugustPayDate", "SeptemberPayDate", "OctoberPayDate", "NovemberPayDate", "DecemberPayDate"]
    
    for (var i = start; i < end; i++) {
        const valofcol = row[datafieldarr[i]];
        table += "<td style='text-align:right; border: 1px solid black'>" + (valofcol ? formatMoney(valofcol) : GetNumericValue(valofcol)) + "</td>"
        const paydate = row[datefieldarr[i]]
        
        if (isEstimatedPayDate && isSecurity) {
            table += "<td style='text-align:right; border: 1px solid black'>" + (paydate ? row[datefieldarr[i]].split('T')[0] : '') + "</td>"
        }
           
    }
    return table;

}
export function printProjectedIncomeSummaryReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');
    let obj = {
        preferences: props.preferences
    }
    const isEstimatedPayDate = GetPreferencePilotValue(obj, 'EstimatedPayDate', 'false') === 'true' ? true : false;
    let table = GetHeaderHtmlPrint(propsacct);
    let textfieldarr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //prepare table header columns 
    if (props && props.topten.projectedIncomeSummarytoggleview === 'C') {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='5'> PROJECTED INCOME SUMMARY </th></tr>"
        table += "<tr>"

        table += "<th style='text-align:left; border: 1px solid black'> Month </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Interest </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Dividend </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Capital Gain </th>"
        table += "<th style='text-align:right; border: 1px solid black'> Total </th>"

        table += "</tr>"
        if (props && props.topten && props.topten.projectedIncomeSummaryChartData &&
            props.topten.projectedIncomeSummaryChartData[0] && props.topten.projectedIncomeSummaryChartData[0].incomeSubTotal &&
            Array.isArray(props.topten.projectedIncomeSummaryChartData[0].incomeSubTotal)) {
            const data = props.topten.projectedIncomeSummaryChartData;
            let currentmonth = new Date().getMonth() + 1;
            let futuremonths = data[0].incomeSubTotal.filter(c => c.monseq < currentmonth);
            futuremonths = orderBy(futuremonths, ['monseq'], ['asc'])
            for (var i = 0; i < futuremonths.length; i++) {
                futuremonths[i].monseq = 13 + i;
            }
            let chartrows = [];
            chartrows = cloneDeep(data[0].incomeSubTotal);
            chartrows = orderBy(chartrows, ['monseq'], ['asc'])
            chartrows.forEach((row) => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (textfieldarr[(row.monseq > 12 ? (row.monseq - 13) : (row.monseq - 1))] + ' ' + (row.monseq > 12 ? (new Date().getFullYear() + 1).toString().substr(-2) : (new Date().getFullYear()).toString().substr(-2))) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.interest ? formatMoney(row.interest) : GetNumericValue(row.interest)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.dividend ? formatMoney(row.dividend) : GetNumericValue(row.dividend)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.capGain ? formatMoney(row.capGain) : GetNumericValue(row.capGain)) + "</td>"
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.totalIncome ? formatMoney(row.totalIncome) : GetNumericValue(row.totalIncome)) + "</td>"
                    table += "<tr/>"
                }
            });

            //put the footer value here....
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.projectedIncomeSummaryChartData[0].interest ? formatMoney(props.topten.projectedIncomeSummaryChartData[0].interest) : GetNumericValue(props.topten.projectedIncomeSummaryChartData[0].interest)) + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.projectedIncomeSummaryChartData[0].dividend ? formatMoney(props.topten.projectedIncomeSummaryChartData[0].dividend) : GetNumericValue(props.topten.projectedIncomeSummaryChartData[0].dividend)) + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.projectedIncomeSummaryChartData[0].capGain ? formatMoney(props.topten.projectedIncomeSummaryChartData[0].capGain) : GetNumericValue(props.topten.projectedIncomeSummaryChartData[0].capGain)) + "</th>"
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.projectedIncomeSummaryChartData[0].total ? formatMoney(props.topten.projectedIncomeSummaryChartData[0].total) : GetNumericValue(props.topten.projectedIncomeSummaryChartData[0].total)) + "</th>"
            table += "<th/>"
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:right; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>";
        table += "</table>";
    }
    else if (props && props.topten.projectedIncomeSummarytoggleview === 'A') {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='16'> PROJECTED INCOME SUMMARY BY ACCOUNT </th></tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        let currentmoth = new Date().getMonth();
        table = GetColumnsForInputMonth(currentmoth, 12, table, true);
        if (props && props.topten && props.topten.projectedIncomeYear === '2')
            table = GetColumnsForInputMonth(0, currentmoth, table, false);
        table += "<th style='text-align:right; border: 1px solid black'> Net Income </th>"
        table += "</tr>"
        if (props && props.topten && props.topten.projectedIncomeSummaryAccountData &&
            props.topten.projectedIncomeSummaryAccountData.value && props.topten.projectedIncomeSummaryAccountData.value[0] && props.topten.projectedIncomeSummaryAccountData.value[0].IncomeSubTotal &&
            Array.isArray(props.topten.projectedIncomeSummaryAccountData.value[0].IncomeSubTotal)) {
            props.topten.projectedIncomeSummaryAccountData.value[0].IncomeSubTotal.forEach(row => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (row.ACCT ? row.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                    table = GetColumnValuesForInputMonth(currentmoth, 12, table, row);
                    if (props && props.topten && props.topten.projectedIncomeYear === '2')
                        table = GetColumnValuesForInputMonth(0, currentmoth, table, row);
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.Total ? formatMoney(row.Total) : GetNumericValue(row.Total)) + "</td>"
                    table += "<tr/>"

                    //Add sub level data here....
                    if (row.IncomeDetail && Array.isArray(row.IncomeDetail)) {
                        row.IncomeDetail.forEach(subrow => {
                            if (subrow) {
                                table += "<tr>"
                                table += "<td style='text-align:left; border: 1px solid black'></td>"
                                table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECNAME ? subrow.SECNAME : "-") + "</td>"
                                table += "<td style='text-align:left; border: 1px solid black'>" + (subrow.SECDESCRIPTION ? subrow.SECDESCRIPTION : "-") + "</td>"
                                table = GetColumnValuesForInputMonth(currentmoth, 12, table, subrow);
                                if (props && props.topten && props.topten.projectedIncomeYear === '2')
                                    table = GetColumnValuesForInputMonth(0, currentmoth, table, subrow);
                                table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.Total ? formatMoney(subrow.Total) : GetNumericValue(subrow.Total)) + "</td>"
                                table += "<tr/>"
                            }


                        })

                    }

                }
            })

            //put the footer value here....
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table = GetColumnValuesForInputMonth(currentmoth, 12, table, props.topten.projectedIncomeSummaryAccountData.value[0]);
            if (props && props.topten && props.topten.projectedIncomeYear === '2')
                table = GetColumnValuesForInputMonth(0, currentmoth, table, props.topten.projectedIncomeSummaryAccountData.value[0]);
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.projectedIncomeSummaryAccountData.value[0].Total ? formatMoney(props.topten.projectedIncomeSummaryAccountData.value[0].Total) : GetNumericValue(props.topten.projectedIncomeSummaryAccountData.value[0].Total)) + "</th>"
            table += "<th/>"
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>";
        table += "</table>";

    }
    else if (props && props.topten.projectedIncomeSummarytoggleview === 'S') {
        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='28'> PROJECTED INCOME SUMMARY BY SECURITY </th></tr>"
        table += "<tr>"

        table += "<th style='text-align:left; border: 1px solid black'> Symbol </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Description </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"

        let currentmoth = new Date().getMonth();
        table = GetColumnsForInputMonth(currentmoth, 12, table, true, true, isEstimatedPayDate);
        if (props && props.topten && props.topten.projectedIncomeYear === '2')
            table = GetColumnsForInputMonth(0, currentmoth, table, false, true, isEstimatedPayDate);
        table += "<th style='text-align:right; border: 1px solid black'> Net Income </th>"
        table += "</tr>"
        if (props && props.topten && props.topten.projectedIncomeSummarySecurityData &&
            props.topten.projectedIncomeSummarySecurityData.value && props.topten.projectedIncomeSummarySecurityData.value[0] && props.topten.projectedIncomeSummarySecurityData.value[0].IncomeSubTotal &&
            Array.isArray(props.topten.projectedIncomeSummarySecurityData.value[0].IncomeSubTotal)) {
            props.topten.projectedIncomeSummarySecurityData.value[0].IncomeSubTotal.forEach(row => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECNAME ? row.SECNAME : "-") + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row.SECDESCRIPTION ? row.SECDESCRIPTION : "-") + "</td>"
                    table += "<td style='text-align:left; border: 1px solid black'></td>"
                    table = GetColumnValuesForInputMonth(currentmoth, 12, table, row, true, isEstimatedPayDate);
                    if (props && props.topten && props.topten.projectedIncomeYear === '2')
                        table = GetColumnValuesForInputMonth(0, currentmoth, table, row, true, isEstimatedPayDate);
                    table += "<td style='text-align:right; border: 1px solid black'>" + (row.Total ? formatMoney(row.Total) : GetNumericValue(row.Total)) + "</td>"
                    table += "<tr/>"
                }

                //Add sub level data here....
                if (row.IncomeDetail && Array.isArray(row.IncomeDetail)) {
                    row.IncomeDetail.forEach(subrow => {
                        if (subrow) {
                            table += "<tr>"
                            table += "<td style='text-align:left; border: 1px solid black'></td>"
                            table += "<td style='text-align:left; border: 1px solid black'></td>"
                            table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props && props.acct && props.acct.selectedaccount, (subrow.ACCT ? subrow.ACCT : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                            table = GetColumnValuesForInputMonth(currentmoth, 12, table, subrow, true, isEstimatedPayDate);
                            if (props && props.topten && props.topten.projectedIncomeYear === '2')
                                table = GetColumnValuesForInputMonth(0, currentmoth, table, subrow, true, isEstimatedPayDate);
                            table += "<td style='text-align:right; border: 1px solid black'>" + (subrow.Total ? formatMoney(subrow.Total) : GetNumericValue(subrow.Total)) + "</td>"
                            table += "<tr/>"
                        }
                    })
                }

            });

            //put the footer value here....
            table += "<tr>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "Total" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table += "<th style='text-align:left; border: 1px solid black'>" + "</th>"
            table = GetColumnValuesForInputMonth(currentmoth, 12, table, props.topten.projectedIncomeSummarySecurityData.value[0], true, isEstimatedPayDate);
            if (props && props.topten && props.topten.projectedIncomeYear === '2')
                table = GetColumnValuesForInputMonth(0, currentmoth, table, props.topten.projectedIncomeSummarySecurityData.value[0], true, isEstimatedPayDate);
            table += "<th style='text-align:right; border: 1px solid black'>" + (props.topten.projectedIncomeSummarySecurityData.value[0].Total ? formatMoney(props.topten.projectedIncomeSummarySecurityData.value[0].Total) : GetNumericValue(props.topten.projectedIncomeSummarySecurityData.value[0].Total)) + "</th>"
            table += "<th/>"
        }
        else {
            table += "<tr><td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td>"
            table += "<td style='text-align:left; border: 1px solid black'>&nbsp;</td></tr>"
        }
        table += "</table>";
        table += "</table>";
    }

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printLiabilitiesReport(propsacct, props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(propsacct);

    //prepare table header columns 


    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='13'> LIABILITIES </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> As Of Date </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Customer Name </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Loan Purpose </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Commitment </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Outstanding Balance </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Available Credit </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Gross Collateral Value </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Interest Rate </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Rate Index </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Spread </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Billed Amount </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Type </th>"
    table += "</tr>"
    if (propsacct && propsacct.liabilities && propsacct.liabilities.length > 0) {
        propsacct.liabilities.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.asofdate ? toDate(row.asofdate) : '-') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.customername ? row.customername : '-') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props.selectedaccount, (row.accountnumber ? row.accountnumber : '-'), IndLongShort.SINGLEVIEW)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.purposeFlag ? row.purposeFlag : '-') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.commitment ? row.commitment : '-') + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (row.outstandingbalance ? formatMoney(row.outstandingbalance) : GetNumericValue(row.outstandingbalance)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (row.availablecredit ? formatMoney(row.availablecredit) : GetNumericValue(row.outstandingbalance)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (row.grosscollval ? formatMoney(row.grosscollval) : GetNumericValue(row.grosscollval)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (row.interestrate ? roundvalue(row.interestrate, 2) + '%' : GetNumericValue(row.interestrate, true, false)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.rateindex ? row.rateindex : '-') + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (row.spread ? roundvalue(row.spread, 2) + '%' : GetNumericValue(row.spread, true, false)) + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (row.billedamount ? formatMoney(row.billedamount) : GetNumericValue(row.billedamount)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + row.type + "</td>"
                table += "<tr/>"
            }
        });
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}
export function printEDeliveryReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> E-DELIVERY </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Enrolled </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Confirms/Confirming Prospectuses </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Statements and Regulatory Inserts </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Eligible Customer Correspondence </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Tax Forms and Related Documents </th>"
    table += "<th style='text-align:right; border: 1px solid black'> Shareholder Reports & Other Documents </th>"
    table += "</tr>"
    if (props && props.personalistaionData && props.personalistaionData.personalisationData && props.personalistaionData.personalisationData.accounts
        && props.personalistaionData.personalisationData.accounts.length > 0) {
        props.personalistaionData.personalisationData.accounts.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.number) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].edeliverystatus : "") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].cfrmsSuprnCd : "") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].stmtsSuprnCd : "") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].custCorrSuprnCd : "") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].taxFormSuprnCd : "") + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.eDelivery && row.eDelivery.length > 0 && row.eDelivery[0] ? row.eDelivery[0].rptSuprnCd : "") + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}


export function printHistoricalAccountDetailsExcelReport(props) {

    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 


    if (props && props.acct && props.acct.historicalBalanceDetails) {
        table = GenerateHistoricalAssetallocationDetailsTable(props.acct.historicalBalanceDetails, table, props.selectedaccount);
        //wb.Sheets["Historical Account Value"] = ws;
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
    //var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //var timestamp = getFormattedTime();
    //download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Historical Account Value-" + timestamp + ".xlsx", "application/octet-stream");
    //return;

}

function GenerateHistoricalAssetallocationDetailsTable(props, table, accountlist) {
    if (props && props.data
        && props.data.length > 0) {
        const mappedData = props.data.map(x => ({
            accountNumber: x.accountNumber,
            marketValue: parseFloat(x.marketValue) || 0,
            assetType: x.assetType || "UNCATEGORIZED",
            time: new Date(x.calendarYear, x.month - 1).getTime(),
            monthYear: new Date(x.calendarYear, x.month - 1),
            asOfDate: new Date(x.asOfDate),
            // monthYearCol: shortmonths[new Date(x.calendarYear, x.month - 1).getMonth()] + new Date(x.calendarYear, x.month - 1).getFullYear().toString().substring(2)
        }));

        const allTimes = uniq(mappedData.map(x => x.time).filter(x => x)).sort(
            (a, b) => {
                return a - b;
            }
        );

        const groupedByAccount = groupBy(mappedData, item => item.accountNumber);
        const rows = [].concat(
            ...Object.keys(groupedByAccount)
                .map(accountNumber => {
                    const accountItems = groupedByAccount[accountNumber];
                    const groupedByAssetType = groupBy(
                        accountItems,
                        accountItem => accountItem.assetType
                    );
                    return Object.keys(groupedByAssetType).map(assetType => {
                        const assetItems = groupedByAssetType[assetType];
                        return {
                            //  key: `${accountNumber}${assetType}`,
                            Account: accountNumber,
                            // assetType: assetItems[0].assetType,
                            ...assetItems.reduce((a, assetItem) => {
                                const marketValue = parseFloat(assetItem.marketValue || 0);
                                const existingValue = a[assetItem.time];
                                // TODO - figure out why there's dupe entries for the same thing
                                if (existingValue) {
                                    a[assetItem.time] = existingValue + marketValue;
                                } else {
                                    a[assetItem.time] = marketValue;
                                }
                                return a;
                            }, {})
                        };
                    });
                })
                .sort((a, b) => (a.assetType > b.assetType ? 0 : 1))
        );

        const accountGroups = groupBy(rows, x => x.Account);
        const accountTotalRows = Object.keys(accountGroups).map(accountNumber => {
            const assetItems = accountGroups[accountNumber];
            return {
                //key: `${accountNumber}`,
                Account: accountNumber,
                //meta: { expanded: false },
                //assetType: "",

                ...allTimes.reduce((a, time) => {
                    const value = assetItems
                        .map(x => x[time])
                        .reduce((a, x) => a + (x || 0), 0);

                    a[time] = value ? formatMoney(value) : GetNumericValue(value);
                    return a;
                }, {})
            };
        });

        const dateColumns = allTimes.map(dateItem => {
            return {
                text: new Date(dateItem).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                }),
                value: dateItem
            };
        });

        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='" + (dateColumns.length + 1) + "'> Historical Value </th></tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        for (var i = 0; i < dateColumns.length; i++) {
            table += "<th style='text-align:right; border: 1px solid black'> " + dateColumns[i].text + "</th>"
        }
        accountTotalRows.forEach(row => {
            var xlRow = {};
            var arr = [];
            var key;
            if (row) {
                table += "<tr>"
                for (key in row) {
                    if (row.hasOwnProperty(key)) {
                        //console.log(key + " = " + row[key]);
                        if (key === "Account")
                            table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(accountlist, row[key], IndLongShort.SINGLEVIEW)) + "</td>"
                        else
                            table += "<td style='text-align:right; border: 1px solid black'>" + (row[key]) + "</td>"
                    }
                }
                table += "<tr>"

            }

        })
    }

    return table;
}

function GenerateHistoricalAssetallocationSummaryTable(props, table, accountlist) {
    if (props && props.data
        && props.data.length > 0) {
        const mappedData = props.data.map(x => ({
            accountNumber: x.accountNumber,
            marketValue: parseFloat(x.marketValue) || 0,
            assetType: x.assetType || "UNCATEGORIZED",
            time: new Date(x.calendarYear, x.month - 1).getTime(),
            monthYear: new Date(x.calendarYear, x.month - 1),
            asOfDate: new Date(x.asOfDate),
        }));

        const allTimes = uniq(mappedData.map(x => x.time).filter(x => x)).sort(
            (a, b) => {
                return a - b;
            }
        );

        const groupedByAccount = groupBy(mappedData, item => item.accountNumber);
        const rows = [].concat(
            ...Object.keys(groupedByAccount)
                .map(accountNumber => {
                    const accountItems = groupedByAccount[accountNumber];
                    const groupedByAssetType = groupBy(
                        accountItems,
                        accountItem => accountItem.assetType
                    );
                    return Object.keys(groupedByAssetType).map(assetType => {
                        const assetItems = groupedByAssetType[assetType];
                        return {
                            //  key: `${accountNumber}${assetType}`,
                            Account: accountNumber,
                            // assetType: assetItems[0].assetType,
                            ...assetItems.reduce((a, assetItem) => {
                                const marketValue = parseFloat(assetItem.marketValue || 0);
                                const existingValue = a[assetItem.time];
                                // TODO - figure out why there's dupe entries for the same thing
                                if (existingValue) {
                                    a[assetItem.time] = existingValue + marketValue;
                                } else {
                                    a[assetItem.time] = marketValue;
                                }
                                return a;
                            }, {})
                        };
                    });
                })
                .sort((a, b) => (a.assetType > b.assetType ? 0 : 1))
        );

        const accountGroups = groupBy(rows, x => x.Account);
        const accountTotalRows = Object.keys(accountGroups).map(accountNumber => {
            const assetItems = accountGroups[accountNumber];
            return {
                //key: `${accountNumber}`,
                Account: accountNumber,
                //meta: { expanded: false },
                //assetType: "",

                ...allTimes.reduce((a, time) => {
                    const value = assetItems
                        .map(x => x[time])
                        .reduce((a, x) => a + (x || 0), 0);

                    a[time] = value ? formatMoney(value) : GetNumericValue(value);
                    return a;
                }, {})
            };
        });

        const dateColumns = allTimes.map(dateItem => {
            return {
                text: new Date(dateItem).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                }),
                value: dateItem
            };
        });

        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='" + (dateColumns.length + 1) + "'> Historical Value </th></tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        for (var i = 0; i < dateColumns.length; i++) {
            table += "<th style='text-align:right; border: 1px solid black'> " + dateColumns[i].text + "</th>"
        }
        accountTotalRows.forEach(row => {
            var key;
            if (row) {
                table += "<tr>"
                for (key in row) {
                    if (row.hasOwnProperty(key)) {
                        //console.log(key + " = " + row[key]);
                        if (key === "Account")
                            table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(accountlist, row[key], IndLongShort.SINGLEVIEW)) + "</td>"
                        else
                            table += "<td style='text-align:right; border: 1px solid black'>" + (row[key]) + "</td>"
                    }
                }
                table += "<tr>"

            }

        })
    }
    return table;
}


export function printSubscribeAlertsReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='2'> SUBSCRIBE ALERTS </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Subscribed Alerts </th>"
    table += "</tr>"
    if (props && props.acct && props.acct.subscribeAlerts && props.acct.subscribeAlerts.length > 0) {
        props.acct.subscribeAlerts.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (getDisplayName(row)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.alertList) + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printAlertNotificationReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='5'> ALERT NOTIFICATION DETAILS </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Date </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Type </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Alert </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Alert desc</th>"
    table += "</tr>"
    if (props && props.acct && props.acct.alertList && props.acct.alertList.length > 0) {
        props.acct.alertList.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (getAccountDisplayName(props.selectedaccount, row.accountnumber, IndLongShort.SINGLEVIEW)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + getDateTimeVal(row.alertdate) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.alerttype) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.alertname) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.isread ? row.alertdesc : '') + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printAssetallocationHistoryExcelReport(props) {

    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 


    if (props && props.acct && props.acct.historicalAssetAllocationDetails) {
        table = GenerateAssetallocationHistoryTable(props.acct.historicalAssetAllocationDetails, table, props.selectedaccount, props.acct.assetschemacolorsconfig, props.acct.assetSchema);
        //wb.Sheets["Historical Account Value"] = ws;
    }
    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
    //var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    //var timestamp = getFormattedTime();
    //download(new Blob([getDataArrayBuffer(wbout)], { type: "application/octet-stream" }), "Historical Account Value-" + timestamp + ".xlsx", "application/octet-stream");
    //return;

}

function GenerateAssetallocationHistoryTable(props, table, accountlist, assetschemacolorsconfig, assetSchema) {
    if (props && props.data
        && props.data.length > 0) {
        const mappedData = getMappedData(props.data)
        for (var i = 0; i < mappedData.length; i++) {
            if (mappedData[i].category !== null) {
                let assetObj = getAssetColorConfig(mappedData[i].assetType, mappedData[i].assetcatcd, assetschemacolorsconfig, assetSchema)
                mappedData[i].colorCd = assetObj.colorcd;
                mappedData[i].displaySeq = assetObj.displayseq;
            }

        }
        if (mappedData && mappedData.length > 0) {
            mappedData.sort(function (a, b) { return a.displaySeq - b.displaySeq; });
        }
        const allTimes = GetUniqueTimedata(mappedData);

        const dateColumns = allTimes.map(dateItem => {
            return {
                text: new Date(dateItem).toLocaleString("default", {
                    month: "short",
                    year: "2-digit"
                }),
                value: dateItem
            };
        });

        table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='" + (dateColumns.length + 2) + "'> Historical Value </th></tr>"
        table += "<tr>"
        table += "<th style='text-align:left; border: 1px solid black'> AssetType </th>"
        table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
        for (var i = 0; i < dateColumns.length; i++) {
            table += "<th style='text-align:right; border: 1px solid black'> " + dateColumns[i].text + "</th>"
        }
        const assetGroups = getAssetGroups(mappedData, accountlist);
        Object.keys(assetGroups).forEach(function (key) {
            assetGroups[key].forEach(row => {
                if (row) {
                    table += "<tr>"
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row["assetType"]) + "</td>";
                    table += "<td style='text-align:left; border: 1px solid black'>" + (row["accountnickname"]) + "</td>";
                    for (var i = 0; i < dateColumns.length; i++) {
                        if (row.hasOwnProperty(dateColumns[i].value)) {
                            let val = row[dateColumns[i].value];
                            table += "<td style='text-align:right; border: 1px solid black'>" + (val ? formatMoney(val, 2) : '-') + "</td>";
                        }
                        else {
                            table += "<td style='text-align:right; border: 1px solid black'>" + ('-') + "</td>";
                        }

                    }
                    table += "<tr>"
                }

            })

        });

    }

    return table;
}

export function printManualAssetsReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> MANAGE OTHER ACCOUNTS </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Custodian/Name </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Type </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Asset Class </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Nickname</th>"
    table += "<th style='text-align:right; border: 1px solid black'> Value</th>"
    table += "<th style='text-align:left; border: 1px solid black'> Value as of</th>"
    table += "</tr>"
    if (props && props.acct && props.acct.manualAssetList && props.acct.manualAssetList.length > 0) {
        props.acct.manualAssetList.forEach(row => {
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.account.info.custodianname ? row.account.info.custodianname : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.account.info.type ? row.account.info.type : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.account.info.subassettype ? row.account.info.subassettype : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.account.info.accountnumber ? row.account.info.accountnumber : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.account.info.nickname ? row.account.info.nickname : '') + "</td>"
                table += "<td style='text-align:right; border: 1px solid black'>" + (getManualAssetBalance(row)) + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.account.value.valueasof ? getDateString(row.account.value.valueasof) : '') + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printAccountPerformanceReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> PERFORMANCE ACCOUNTS </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Last Market Close </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Month To Date (%) </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Quarter To Date (%) </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Year To Date (%) </th>"
    table += "</tr>"
    if (props && props.acct && props.acct.perfromanceAccounts && props.acct.perfromanceAccounts.length > 0) {
        props.acct.perfromanceAccounts.forEach(row => {
            let displayName = row.key;
            if (props.acct.accounts && props.acct.accounts !== null) {
                displayName = getAccountDisplayName(props.acct.accounts, row.key, IndLongShort.SINGLEVIEW);
            }
            let value = '';
            let valuePerc = '';
            if (row.lastmarketclose && row.lastmarketclose !== null) {
                value = row.lastmarketclose.value !== undefined && row.lastmarketclose.value !== null && row.lastmarketclose.value !== 0 ? formatMoney(roundvalue(row.lastmarketclose.value, 2)) : GetNumericValue(row.lastmarketclose.value);
                valuePerc = row.lastmarketclose.percent !== undefined && row.lastmarketclose.percent !== null && row.lastmarketclose.percent !== 0 ? roundvalue(row.lastmarketclose.percent, 2) + '%' : GetNumericValue(row.lastmarketclose.percent, true);
            } 
            
            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + displayName + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + value + ' / ' + valuePerc + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.monthtodate && row.monthtodate!==  null ? (row.monthtodate !== 0 ? roundvalue(row.monthtodate, 2) + '%' : GetNumericValue(row.monthtodate, true)):'') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.quartertodate && row.quartertodate !== null ? (row.quartertodate !== 0 ? roundvalue(row.quartertodate, 2) + '%' : GetNumericValue(row.quartertodate, true)) : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.yeartodate && row.yeartodate !== null ? (row.yeartodate !== 0 ? roundvalue(row.yeartodate, 2) + '%' : GetNumericValue(row.yeartodate, true)) : '') + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}
export function printPerformanceSummaryReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='7'> PERFORMANCE SUMMARY </th></tr>"
    table += "<tr>"
    if (props && props.acct && props.acct.perfromanceSummaryDetails && props.acct.perfromanceSummaryDetails.length > 0) {
        props.acct.perfromanceSummaryDetails.forEach(row => {
            let keyString = new Date(row.month).toLocaleString("default", {
                month: "short",
                year: "2-digit"
            })
            table += "<th style='text-align:left; border: 1px solid black'> " + keyString +  "</th>"
        });
    }
    table += "</tr>";
    table += "<tr>";
    if (props && props.acct && props.acct.perfromanceSummaryDetails && props.acct.perfromanceSummaryDetails.length > 0) {
        props.acct.perfromanceSummaryDetails.forEach(row => {
           let value = row.value !== undefined && row.value !== null && row.value !== 0 ? formatMoney(roundvalue(row.value, 2)) : GetNumericValue(row.value);
           
            table += "<th style='text-align:left; border: 1px solid black'>" + value + "</th>";
            
        });
    }
    table += "</tr>";

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function printAccountInformationReport(props) {
    var printWindow = window.open('', 'RCM Print', '');

    let table = GetHeaderHtmlPrint(props.acct);

    //prepare table header columns 

    table += "<table cellspacing='0'><tr><th style='text-align:center; border: 1px solid black;' colspan='3'> ACCOUNTS INFORMATION </th></tr>"
    table += "<tr>"
    table += "<th style='text-align:left; border: 1px solid black'> Account #</th>"
    table += "<th style='text-align:left; border: 1px solid black'> ABA Number </th>"
    table += "<th style='text-align:left; border: 1px solid black'> Checking Account Number </th>"
    table += "</tr>"
    if (props && props.moneymovement && props.moneymovement.accountList && props.moneymovement.accountList.length > 0) {
        props.moneymovement.accountList.forEach(row => {

            if (row) {
                table += "<tr>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.singleViewDisplayName && row.singleViewDisplayName !== null ? row.singleViewDisplayName : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.abanumber && row.abanumber !== null ? row.abanumber : '') + "</td>"
                table += "<td style='text-align:left; border: 1px solid black'>" + (row.maskedCheckingAccount && row.maskedCheckingAccount !== null ? row.maskedCheckingAccount : '') + "</td>"
                table += "<tr/>"
            }
        });
    }

    table += "</table>";
    table += "</table>";

    printWindow.document.write(table);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
}