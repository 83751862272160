import {
    ADD_ACCOUNTSTATEMENTS, ADD_CORRESPONDENCEDOCUMENTS, ADD_TRADECONFIRMS, ADD_TRADECONFIRMSUMMARY, ADD_TAXDOCUMENTS, UPDATE_DOCUMENTS
} from '../actions/documentactions.jsx';

export function DocumentReducer(state = [], action) {
    switch (action.type) {
        
        case ADD_ACCOUNTSTATEMENTS:
            return {
                ...state,
                accountStatementsList: action.accountStatementsList,
                filteredAccountStatementsList: action.filteredAccountStatementsList
            }
        case ADD_CORRESPONDENCEDOCUMENTS:
            return {
                ...state,
                correspondenceDocumentList: action.correspondenceDocumentList,
                filteredCorrespondenceDocumentList: action.filteredCorrespondenceDocumentList,
                correspondenceDocStartDate: action.startDate,
                correspondenceDocEndDate: action.endDate
            }
        case ADD_TRADECONFIRMS:
            return {
                ...state,
                tradeConfirmDocumentList: action.tradeConfirmDocumentList,
                filteredTradeConfirmDocumentList: action.filteredTradeConfirmDocumentList,
                isSearchByTradeConfirmSummary: action.isSearchByTradeConfirmSummary
            }
        case ADD_TRADECONFIRMSUMMARY:
            return {
                ...state,
                tradeConfirmSummaryDocumentList: action.tradeConfirmSummaryDocumentList,
                filteredTradeConfirmSummaryDocumentList: action.filteredTradeConfirmSummaryDocumentList,
                isSearchByTradeConfirmSummary: action.isSearchByTradeConfirmSummary
            }
        case ADD_TAXDOCUMENTS:
            return {
                ...state,
                taxDocumentList: action.taxDocumentList,
                filteredTaxDocumentList: action.filteredTaxDocumentList
            }   
        case UPDATE_DOCUMENTS:
            return {
                ...state,
                accountStatementsList: action.accountStatementsList,
                filteredAccountStatementsList: action.filteredAccountStatementsList,
                correspondenceDocumentList: action.correspondenceDocumentList,
                filteredCorrespondenceDocumentList: action.filteredCorrespondenceDocumentList,
                tradeConfirmDocumentList: action.tradeConfirmDocumentList,
                filteredTradeConfirmDocumentList: action.filteredTradeConfirmDocumentList,
                tradeConfirmSummaryDocumentList: action.tradeConfirmSummaryDocumentList,
                filteredTradeConfirmSummaryDocumentList: action.filteredTradeConfirmSummaryDocumentList,
                taxDocumentList: action.taxDocumentList,
                filteredTaxDocumentList: action.filteredTaxDocumentList
            }
        default:
            return state;
    }

}