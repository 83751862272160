import React from "react";
import ErrorBoundary from "../../../../common/errorboundary.jsx";
import { AddHistoricalAssetAllocationSummaryDetails, AddAssetColor, AddHistoricalAssetAllocationDetails , AddHistoricalBalanceDetails } from "../../../../actions/accountsactions.jsx";

import { connect } from "react-redux";
import { spinner, CheckIfProspectClient } from "../../../../common/constants.jsx";
import DateRanges from "../../../../common/dateranges.jsx";
import { HistoricalAssetAllocationChartComponent } from "./HistoricalAssetAllocationChart";
import { HistoricalAssetAllocationContainerBase } from "./HistoricalAssetAllocationContainerBase";
import { Link } from "react-router-dom";
import Watermark from "../../../../common/watermark.jsx";
class HistoricalAssetAllocationSummaryContainer extends HistoricalAssetAllocationContainerBase {
    constructor(props) {
        super(props);

        this.state = {
            ...super.state,
            isProspectClient: CheckIfProspectClient(this.props.role)
        };
    }
  componentDidMount() {
      this.accountKey = (this.props.historicalAssetAllocationSummaryDetails || {}).key;
      if (this.shouldUpdateData()) {
          if (this.state.isProspectClient) {
              this.SetDummayResponseForProspectClient(true);
          }
          else {
              this.execute({
                  range: DateRanges.PRIOR12MONTHS
              }, this.props.assetSchema,true);
          }
    
    }

    this.accountKey = this.generateAccountKey(this.props.selectedaccount);
  }

  componentDidUpdate(prevProps) {
      if (this.shouldUpdateData(prevProps)) {
          if (this.state.isProspectClient) {
              this.SetDummayResponseForProspectClient();
          }
          else {
              this.execute({
                  range: DateRanges.PRIOR12MONTHS

              }, this.props.assetSchema,true);
          }
    }

    this.accountKey = this.generateAccountKey(this.props.selectedaccount);
  }

  render() {
      const { historicalAssetAllocationSummaryDetails } = this.props;
      const { isLoading, error, isProspectClient } = this.state;

    const hasData =
        historicalAssetAllocationSummaryDetails &&
        historicalAssetAllocationSummaryDetails.data &&
        historicalAssetAllocationSummaryDetails.data.length;

      const data = hasData ? historicalAssetAllocationSummaryDetails.data : [];

    const isLoadingOrHasError = isLoading || !!error;

    const LoadingOrError = () => {
      if (!isLoadingOrHasError) {
        return null;
      }

      return (
          <>
          {isProspectClient ? <Watermark /> : ''}
          {isLoading && (
            <div className="RCM_spinner">
              Loading Historical Value {spinner()}
            </div>
          )}
          {error && (
            <p className="RCM_data_error">Unable to load Historical Value</p>
          )}
        </>
      );
    };

    return (
        <ErrorBoundary>
            {isProspectClient ? <Watermark /> : ''}
        <div className="d-flex flex-column h-100">
          <div className="d-flex justify-content-between">
            <label className="RCM_tile_heading">HISTORICAL VALUE</label>

            <div>
              <Link
                to="Balanceshistory"
                className="RCM_expandview"
                id="TooltipToptendetail"
              ></Link>
              <Link to="Balanceshistory">
                <label className="RCM_togglelabeltext RCM_detailsLabel">
                  Detail
                </label>
              </Link>
            </div>
          </div>

          {isLoadingOrHasError ? (
            <LoadingOrError />
          ) : (
            <HistoricalAssetAllocationChartComponent
              groupByAssetSubType={false}
              style={{ height: "100%" }}
              chartType="area"
              data={data}
            />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => {
  return {
    render: state.acct.render,
    portalid: state.acct.portalid,
    token: state.acct.token,
    selectedaccount: state.acct.selectedaccount,
    name: state.acct.name,
      historicalAssetAllocationSummaryDetails:
          state.acct.historicalAssetAllocationSummaryDetails,
    contextjson: state.acct.contextjson,
    profilejson: state.acct.profilejson,
    role: state.acct.role,
    assetSchema: state.acct.assetSchema
     
  };
};
const mapDispatchToProps = {
    AddHistoricalBalanceDetails,
    AddHistoricalAssetAllocationSummaryDetails,
    AddHistoricalAssetAllocationDetails,
    AddAssetColor
    
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalAssetAllocationSummaryContainer);
