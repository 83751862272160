import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { spinner, MsgForNoData, MsgForFailedData } from './../../../../../common/constants.jsx';
import { securityColumns, AssetColumns } from '../holdingdetailscolumns.jsx';
import Watermark from '../../../../../common/watermark';
export function AssetHoldingsView(
    props
) {

    let assetHoldingview = (
        <div>
            {props && props.state.isProspectClient ? <Watermark /> : ''}
            <div className="conatiner-fluid RCM_Toast" key="secdetails1">
                {
                    !props.state.isloading && props.state.balSummaryV2PilotFlag ?
                        <div className="row RCM_Holding_Note">
                            <label className="RCM_Customization_text RCM_Customization_text_small_font">*&nbsp; This section includes additional cash/money account values that exclude pending and unsettled activities. Please see the <span className="RCM_balance_link" onClick={props.gotobalance}>balances</span> view for pending and unsettled activities.</label>
                        </div>
                        : ''
                }
                {props && props.state.showAssetClassOption ?
                    <form>
                        <div className="d-flex flex-row align-content-start" id="RCM_asset_schema_filter">
                            <div className="form-group RCM_displayflex RCM_form_group_inline">

                                <Dropdown
                                    isOpen={props.state.classDropdownOpen}
                                    toggle={props.toggleClass}
                                    className="RCM_as_dropdown RCM_select_box RCM_mm_subselect_box dropdown"
                                    key="addropdown1">
                                    <DropdownToggle caret key="addropdown2" disabled={props.state.isProspectClient}>
                                        {
                                            props.state.dropdownClassValue
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu >
                                        {props && props.classDropDownItems && props.classDropDownItems.map((item, index) =>
                                            <DropdownItem key={index} onClick={props.changeClass} disabled={props.state.isProspectClient}>
                                                <div>{item.label}</div>
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </form> : ''}
                {
                    (() => {
                        if (props.state.requestFailed) {
                            return (
                                <div className="row" id="RCM_AssetHoldingTable">
                                    <div
                                        className={"col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_SecurityHolding_Wrapper"
                                            + (props.state.viewYield ? ' RCM_AssetHoldingChild_withYield' : ' RCM_AssetHoldingChild_withoutYield')}>
                                        <BootstrapTable key='btsecurity'
                                            keyField='UNQID'
                                            data={[]}
                                            classes="RCM_two_level_table1"
                                            columns={AssetColumns('', '', '', '', [], props.state.isrowexpanded, '', '', props.rowsortevent, '',
                                                props.headerColumnStyles, props.otherColumnStyles, props.smallColumnStyles, props.state.viewYield)}

                                            noDataIndication={MsgForFailedData}
                                            expandRow={props.expandRowByAsset}
                                            bordered={false}
                                        /></div></div>)

                        }
                        else if (props.state.isloading || props.state.isLoadingAssetColor) {
                            return <div className="RCM_spinner">Loading Asset Holdings{spinner()}</div>
                        }
                        else if (props.state.requestFailedAssetColor) { return <p className="RCM_data_error">Unable to fetch Asset schema configuration</p> }
                        else {
                            if (props.state.holdingsbycategory && props.state.holdingsbycategory.length > 0 && !props.state.isloading) {
                                return (
                                    <div className="row" id="RCM_AssetHoldingTable">
                                        <div
                                            className={"col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_SecurityHolding_Wrapper"
                                                + (props.state.viewYield ? ' RCM_AssetHoldingChild_withYield' : ' RCM_AssetHoldingChild_withoutYield')}>
                                           <BootstrapTable key='btsecurity'
                                            keyField='UNQID'
                                            data={props.assetData}
                                            classes="RCM_two_level_table1"
                                                columns={AssetColumns(props.totalvalue, props.totalglamount, props.totalglpct, props.totalcostbasis, props.assetData, props.state.isrowexpanded, props.totaltodayschange, props.totaltodayschangepct, props.rowsortevent, props.totalassetpct, props.headerColumnStyles, props.otherColumnStyles, props.smallColumnStyles, props.state.viewYield)}
                                            noDataIndication={MsgForNoData}
                                            expandRow={props.expandRowByAsset}
                                            bordered={false}
                                        /></div></div>)
                            }
                            else {
                                return (
                                    <div className="row" id="RCM_AssetHoldingTable">
                                        <div
                                            className={"col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_Tables RCM_SecurityHolding_Wrapper"
                                                + (props.state.viewYield ? ' RCM_AssetHoldingChild_withYield' : ' RCM_AssetHoldingChild_withoutYield')}>
                                            <BootstrapTable key='btsecurity'
                                            keyField='UNQID'
                                            data={props.assetData}
                                            classes="RCM_two_level_table1"
                                            columns={AssetColumns(props.totalvalue, props.totalglamount, props.totalglpct, props.totalcostbasis, props.assetData, props.state.isrowexpanded, props.totaltodayschange, props.totaltodayschangepct, props.rowsortevent, props.totalassetpct, props.headerColumnStyles, props.otherColumnStyles, props.smallColumnStyles, props.state.viewYield)}
                                            noDataIndication={MsgForNoData}
                                            expandRow={props.expandRowByAsset}
                                            bordered={false}
                                        /></div></div>)

                            }
                        }
                    })()
                }
            </div>
        </div>
    )
    return assetHoldingview
}
