import DateRanges from './../../common/dateranges.jsx'

export default function getSearchDate(dateRange) {
    return (getSearchDateWOffset(dateRange, 1));
}
export function getSearchDateWOffset(dateRange, dayOffset) {
    var years = [];
    for (var i = 0; i < 7; i++) {
        years.push({
            label: (new Date().getFullYear() - i).toString(),
            value: (new Date().getFullYear() - i).toString()
        })
    }

    var dt = new Date();
    var month = null;
    var year = null;
    var startDate = null;
    var endDate = null;
    if (dateRange === DateRanges.PRIORMONTH.value) {
        dt.setMonth(dt.getMonth() - 1);
        month = dt.getMonth();
        year = dt.getFullYear();
        startDate = new Date(year, month, 1);
        endDate = new Date(year, month + 1, 0);

        return { startDate: startDate, endDate: endDate }
    }
    else if (dateRange === DateRanges.PRIOR3MONTHS.value) {
        dt.setMonth(dt.getMonth() - 3);
        month = dt.getMonth();
        year = dt.getFullYear();
        startDate = new Date(year, month, 1);
        dt = new Date();
        dt.setMonth(dt.getMonth() - 1)
        month = dt.getMonth();
        year = dt.getFullYear();
        endDate = new Date(year, month + 1, 0);
        return { startDate: startDate, endDate: endDate }
    } else if (dateRange === DateRanges.PRIOR6MONTHS.value) {

        dt.setMonth(dt.getMonth() - 6);
        month = dt.getMonth();
        year = dt.getFullYear();
        startDate = new Date(year, month, 1);
        dt = new Date();
        dt.setMonth(dt.getMonth() - 1)
        month = dt.getMonth();
        year = dt.getFullYear();
        endDate = new Date(year, month + 1, 0);
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.PRIOR12MONTHS.value) {
        dt.setMonth(dt.getMonth() - 12);
        month = dt.getMonth();
        year = dt.getFullYear();
        startDate = new Date(year, month, 1);
        dt = new Date();
        dt.setMonth(dt.getMonth() - 1)
        month = dt.getMonth();
        year = dt.getFullYear();
        endDate = new Date(year, month + 1, 0);
        return { startDate: startDate, endDate: endDate }
    } else if (dateRange === DateRanges.CURRENTYEAR.value) {

        year = dt.getFullYear();
        startDate = new Date(year, 0, 1);
        endDate = new Date();
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.PRIORYEAR.value) {

        year = dt.getFullYear() - 1;
        startDate = new Date(year, 0, 1);
        endDate = new Date(year, 11, 31);
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.YESTERDAY.value) {
        dt.setDate(dt.getDate() - 1);
        startDate = dt;
        endDate = dt;
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.LAST5DAYS.value) {
        dt.setDate(dt.getDate() - 5);
        startDate = dt;
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - dayOffset);
        endDate = yesterday;
        return { startDate: startDate, endDate: endDate }

    }else if (dateRange === DateRanges.LAST7DAYS.value) {
        dt.setDate(dt.getDate() - 7);
        startDate = dt;
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - dayOffset);
        endDate = yesterday;
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.LAST30DAYS.value) {
        dt.setDate(dt.getDate() - 30);
        startDate = dt;
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - dayOffset);
        endDate = yesterday;
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.LAST90DAYS.value) {
        dt.setDate(dt.getDate() - 90);
        startDate = dt;
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - dayOffset);
        endDate = yesterday;
        return { startDate: startDate, endDate: endDate }

    } else if (dateRange === DateRanges.YEARTODATE.value) {
        year = dt.getFullYear();
        startDate = new Date(year, 0, 1);
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - dayOffset);
        endDate = yesterday;
        return { startDate: startDate, endDate: endDate }

    }else if (years.find(yr => yr.value === dateRange)) {
        var yr = years.filter(yr => yr.value === dateRange);
        startDate = new Date(yr[0].value, 0, 1);
        endDate = new Date(yr[0].value, 11, 31);
        return { startDate: startDate, endDate: endDate }
    } else {
        return null;
    } 
}