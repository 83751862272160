import React from "react";

const UserPhoneList = ({ PhoneList, detailsForm, onUserPhoneChange }) => {
  return (
    <div>
      <div style={{ color: "gray" }}>Select an Existing Phone Number</div>
      <div style={{maxHeight: "95px", overflowY: "auto", overflowX: "hidden"}}>
        {PhoneList &&
          PhoneList.map((phone, index) => {
            return (
              <div
                key={index}
                style={{
                  padding: "3px",
                  display: "flex",
                  borderBottom: "0.1px solid lightgray"
                }}
              >
                <span style={{ color: "gray" }}>{phone.displayPhone}</span>
                <span style={{ marginLeft: "auto" }}>
                  <input
                    type="radio"
                    name="phone_number"
                    style={{ position: "relative", left: 0 }}
                    onChange={() => onUserPhoneChange(phone)}
                    checked={phone.value === detailsForm.phoneno}
                  />
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserPhoneList;
