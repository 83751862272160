import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { useCallback, useState } from "react";
import { authProvider } from "../../../../App";
import { DeleteGraph } from "../../../common/servicecalls";
import axios from "axios";
import { useSelector } from "react-redux";
import {MaskPhoneNumber} from './common/constants'

const ConfirmDeleteAuthenticationMethod = ({
  isOpen,
  toggleConfirmDeleteModalPopup,
  selectedAuthMethod,
}) => {
  const [deleteProgrssState, setDeleteProgressState] = useState({
    inProgress: false,
    error: undefined,
  });
  const { baseurl, scopes: apiScope } = useSelector((store) => store.env) || {};

  const getApiAccessToken = useCallback(async (scopes) => {
    if (authProvider) {
      const accounts = authProvider.getAllAccounts();
      if (Array.isArray(accounts) && accounts.length > 0) {
        const tokenResponse = await authProvider.acquireTokenSilent({
          scopes: scopes,
          account: accounts[0],
        });
        return tokenResponse.accessToken;
      }
    }
  }, []);

  const deleteAuthenticationMethod = useCallback((token, authMethod) => {
    setDeleteProgressState((prevState) => ({
      ...prevState,
      inProgress: true,
      error: undefined,
    }));
    const methodType =
      authMethod["@odata.type"] === "#microsoft.graph.phoneAuthenticationMethod"
        ? "PhoneMethods"
        : "MicrosoftAuthenticatorMethods";
    const apiUrl = `${baseurl}multifactorauthapi/api/${methodType}/${authMethod.id}`;
    const cancellationToken = axios.CancelToken.source();
    DeleteGraph(apiUrl, token, cancellationToken)
      .then(() => {
        setDeleteProgressState((prevState) => ({
          ...prevState,
          inProgress: true,
          error: undefined,
        }));
        toggleConfirmDeleteModalPopup(true);
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          //checking error code because we are not getting www-authenticate header for requested claim
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.code === "actionRequiresMfaClaim"
          ) {
            const accounts = authProvider.getAllAccounts();
            authProvider.loginPopup({
              scopes: [apiScope],
              claims: atob(err.response.data.error.requiredClaim),
              account: accounts[0],
            });
          } else {
            setDeleteProgressState((prevState) => ({
              ...prevState,
              inProgress: false,
              error: err,
            }));
          }
        } else {
          setDeleteProgressState((prevState) => ({
            ...prevState,
            inProgress: false,
            error: err,
          }));
        }
      });
  }, []);

  const deleteAuthenticationMethodConfirmation = useCallback((authMethod) => {
    getApiAccessToken([apiScope]).then((apiToken) => {
      deleteAuthenticationMethod(apiToken, authMethod);
    });
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleConfirmDeleteModalPopup()}
      backdrop={false}
      size="md"
      zIndex="9999"
      className="RCM_CM_modal"
      wrapClassName="RCM_CM_modal_wrapper"
      modalClassName="RCM_CM_modal_class"
      contentClassName="RCM_CM_modal_content"
      centered
    >
      <div key="confirmAlerts02">
        <ModalHeader
          toggle={() => toggleConfirmDeleteModalPopup()}
          charCode="X"
        >
          <label className="RCM_tile_heading">DELETE CONFIRMATION</label>
        </ModalHeader>
        <ModalBody className="RCM_Manage_alert_popup">
          <>
            {deleteProgrssState.error && (
              <div className="RCM_Teamwork_error">
                We are unable to delete authentication, Please try after
                sometime.
              </div>
            )}
            <div className="RCM_manage_alert_popup_subheading">
              Are you sure, you want to remove{" "}
              {selectedAuthMethod["@odata.type"] ===
              "#microsoft.graph.phoneAuthenticationMethod"
                ? `${selectedAuthMethod.phoneType} (${MaskPhoneNumber(selectedAuthMethod.phoneNumber)})`
                : "Authenticator App"}{" "}
              multifactor authentication method?
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          <div className="row row-margin">
            <div
              className="col-sm-12 RCM_alert_cnf_popup_buttons"
              style={{ display: "flex", gap: "0.75rem" }}
            >
              <input
                className="btn RCM_button_secondary RCM_select_btn"
                type="submit"
                value="CANCEL"
                onClick={() => toggleConfirmDeleteModalPopup()}
              />
              <Button
                className="btn RCM_button_primary RCM_select_btn"
                onClick={() =>
                  deleteAuthenticationMethodConfirmation(selectedAuthMethod)
                }
              >
                <>
                  {deleteProgrssState.inProgress && (
                    <Spinner size="sm" style={{ marginRight: "0.5rem" }} />
                  )}{" "}
                  CONFIRM
                </>
              </Button>
            </div>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteAuthenticationMethod;
