import React, { Component } from 'react';
import { ActivitysummaryURL, GetAccountsList, MessageType, MessageClass, getAccountDisplayName, IndLongShort, CheckIfProspectClient, getDateString} from '../../../../common/constants.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Get } from '../../../../common/servicecalls.jsx';
import DateRanges from '../../../../common/dateranges.jsx'
import { AddActivity, AddActivityDetails, AddActivitiesForSelectedAccounts } from '../../../../actions/accountsactions.jsx';
import { AccountActivityDetailsView } from './accountactivitydetailsview.jsx'
import axios from 'axios';
import ErrorBoundary from '../../../../common/errorboundary.jsx';
import { getSearchDateWOffset } from '../../../documents/searchdates.js';
import { _logException, _logInformation } from "../../../../common/_logging.js";
import { withPageView } from '../../../../hoc/withPageView.jsx';
import { activityDummyResponse, RRR_123456_activity_Response, RRR_234567_activity_Response } from '../../accountsmockresponse.js';
import dateFormat from "dateformat";
class Activitydetails extends Component {

    constructor(props) {
        super(props)

        this.accounts = GetAccountsList(this.props.selectedaccount);
        this.state = {
            requestFailed: false,
            error: null,
            accounts: this.accounts,
            render: this.props.render,
            activityresponse: this.props.activitydetailsforselectedaccounts,
            isloading: true,
            //dropdownValue: '',
            //dropdownDispValue: '',
            name: this.props.name,
            dropdownOpen: false,
            dateRangeDropdownOpen: false,
            typeDropdownOpen: false,
            dateRangeDropdownValue: '',
            dateRangeValue: 'Select Range',
            dropdownTypeValue: 'All',
            isCustomDateRangeSelected: false,
            startDate: new Date(),
            endDate: new Date(),
            searchDates: '',
            typeValue: '',
            showCheckDepModal: false,
            checkDepImgData: {
                acct: '',
                dttrade: '',
                amount: ''
            },
            isProspectClient: CheckIfProspectClient(this.props.role)
        }
        this.execute = this.execute.bind(this);
        //this.toggle = this.toggle.bind(this);
        //this.changeAccount = this.changeAccount.bind(this);
        this.dateRangeDropdownToggle = this.dateRangeDropdownToggle.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.search = this.search.bind(this);
        this.changeType = this.changeType.bind(this);
        this.toggleType = this.toggleType.bind(this);
        this.toggleCheckDepModal=  this.toggleCheckDepModal.bind(this);
        this.checkDepImageClick = this.checkDepImageClick.bind(this);
    }
    activitydetailscanellationToken = null;
    dateRangeDropDownItems = [
        {
            label: 'Select Range',
            value: 'Select Range'
        },
        {
            label: DateRanges.LAST5DAYS.label,
            value: DateRanges.LAST5DAYS.value
        },
        {
            label: DateRanges.LAST30DAYS.label,
            value: DateRanges.LAST30DAYS.value
        },
        {
            label: DateRanges.LAST90DAYS.label,
            value: DateRanges.LAST90DAYS.value
        },
        {
            label: DateRanges.YEARTODATE.label,
            value: DateRanges.YEARTODATE.value
        },
        {
            label: 'Previous Year',
            value: DateRanges.PRIORYEAR.value
        },
        {
            label: DateRanges.CUSTOMRANGE.label,
            value: DateRanges.CUSTOMRANGE.value
        }];

    typeDropDownItems = [
        {
            label: 'All',
            value: ''
        },
        {
            label: "Buy",
            value: "BY"
        },
        {
            label: "Corporate Action",
            value: "CA"
        },
        {
            label: "Deposits / Contribution",
            value: "DP"
        },
        {
            label: "Dividend",
            value: "DV"
        },
        {
            label: "Exchanges / Transfer",
            value: "EX"
        },
        {
            label: "Fee",
            value: "FE"
        },
        {
            label: "Interest",
            value: "IN"
        },
        {
            label: "Journal",
            value: "JN"
        },
        {
            label: "Sell",
            value: "SL"
        },
        {
            label: "Withdrawal",
            value: "WD"
        },
        {
            label: "Other Transaction",
            value: "ZZ"
        },
        {
            label: "Trade",
            value: "Trade"
        },
        {
            label: "Cash",
            value: "Cash"
        },
    ];
    execute = (daterange, trancode) => {
        var accounts = '';
        //Below is commmented out account filter dropdown is commented out in view
        //if (this.state.dropdownValue === 'All Accounts') {
        //    accounts = ''
        //}
        //else {
        //    accounts = this.state.dropdownValue
        //}
        let baseUrl = "";
        baseUrl = ActivitysummaryURL + accounts;
        if (daterange && daterange !== "") {
            baseUrl = baseUrl + "&DateRange=" + daterange;
        }
        if (trancode && trancode !== "") {
            if (trancode === 'Cash') {
                baseUrl = baseUrl + "&SearchBy=Cash,All"
            } else if (trancode === 'Trade') {
                baseUrl = baseUrl + "&SearchBy=TranCode,BY,SL"
            } else {
                baseUrl = baseUrl + "&SearchBy=TranCode," + trancode
            }
                
        }
        if (this.activitydetailscanellationToken) {
            this.activitydetailscanellationToken.cancel('Operation canceled due to new request.')
        }
        this.activitydetailscanellationToken = axios.CancelToken.source();
        Get(baseUrl, this.props.token, this.props.contextjson, this.props.profilejson, this.activitydetailscanellationToken)
            .then(result => {
                this.setState(() => ({ activityresponse: result.data, isloading: false }))
                if (daterange === '' && trancode === '') {
                    this.props.AddActivitiesForSelectedAccounts(result.data);
                }
                this.props.AddActivityDetails(result.data);
                _logInformation("Account Activity Details: Activity details fetched")
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    _logException("Account Activity Details : Fetching account activities: " + (error && error.response) ? error.response : "");
                    this.setState(() => ({ requestFailed: true, error: error.message }))
                }
            })
    };

    componentDidMount() {
        if (this.state.isProspectClient) {
            this.SetDummayResponseForProspectClient();
        }
        else {
            if (this.props.activitydetails === null) {
                this.execute('', '');
                this.setState({
                    dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                });
            }
            else {
                this.props.AddActivityDetails(this.props.activitysummary);
                this.setState({
                    isloading: false,
                    dateRangeDropdownValue: this.dateRangeDropDownItems[0].label,
                });
            }
        }
       
    }
    componentWillUnmount() {
        if (this.activitydetailscanellationToken !== null && this.activitydetailscanellationToken !== undefined) {
            this.activitydetailscanellationToken.cancel('Cancelling the token during unmount to prevent memory leak.');
        }
    }

    toggleCheckDepModal(){
        this.setState({showCheckDepModal: !this.state.showCheckDepModal});
    }

    checkDepImageClick(row){
        this.setState({
            showCheckDepModal: true, 
            checkDepImgData: {
                acct: row.acct,
                dttrade: row.dttrade,
                amount: row.total,
                checknumber: row.checknumber
            } 
        });
    }

    ApplyFilter() {
        if (this.state.dateRangeValue !== 'Select Range') {
            if (this.state.dateRangeValue !== DateRanges.CUSTOMRANGE.value) {
                var dt = getSearchDateWOffset(this.state.dateRangeValue, 0);   // getSearchDate() is removing current date from the end date causing current activities to not return
                var startDate = dt.startDate;
                var endDate = dt.endDate;
                var searchBeginDate = this.getFormattedDate(startDate);
                var searchEndDate = this.getFormattedDate(endDate);
                var dates = "FromTo," + searchBeginDate + "," + searchEndDate;
                this.execute(dates, this.state.typeValue);
            }
            else {
                var searchBeginDate = this.getFormattedDate(this.state.startDate);
                var searchEndDate = this.getFormattedDate(this.state.endDate);
                var dates = "FromTo," + searchBeginDate + "," + searchEndDate;
                this.execute(dates, this.state.typeValue);
            }
        }
        else {
            this.execute(this.state.searchDates, this.state.typeValue);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.render !== prevProps.render) {
            if (this.state.isProspectClient) {
                this.SetDummayResponseForProspectClient();
            }
            else {
                this.accounts = [];
                this.accounts = GetAccountsList(this.props.selectedaccount);
                this.setState(() => ({
                    accounts: this.accounts,
                    isloading: true,
                   
                }), () => {
                    this.ApplyFilter();
                });
            }
           
        }
    }

    changeType(selectedType) {
        let type = this.typeDropDownItems.find(item => item.label === selectedType.currentTarget.textContent.trim()).value;
        this.setState({
            dropdownTypeValue: selectedType.currentTarget.textContent.trim(),
            typeValue: type,
            activityresponse: null,
            isloading: true,
            messages: []
        });
       /* this.setState({
           
        }); */
       // let type = type === 'TR' ? 'SL,BY' : type === 'TR'
        this.execute(this.state.searchDates, type);
    }

    changeDateRange(selectedDateRange) {
        let dateRange = this.dateRangeDropDownItems.find(item => item.label === selectedDateRange.currentTarget.textContent.trim()).value;
        this.setState({
            dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
            dateRangeValue: dateRange
        });
        if (dateRange !== 'Select Range') {
            if (dateRange === DateRanges.CUSTOMRANGE.value) {
                this.setState({
                    isCustomDateRangeSelected: true,
                    dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                    dateRangeValue: dateRange,
                });
            } else {
                this.setState({
                    isCustomDateRangeSelected: false,
                    startDate: new Date(),
                    endDate: new Date(),
                    dateRangeDropdownValue: selectedDateRange.currentTarget.textContent,
                    dateRangeValue: dateRange,
                    messages: []
                });
                var dt = getSearchDateWOffset(dateRange, 0);      // getSearchDate() is removing current date from the end date causing current activities to not return
                var startDate = dt.startDate;
                var endDate = dt.endDate
                var searchBeginDate = this.getFormattedDate(startDate);
                var searchEndDate = this.getFormattedDate(endDate);
                var dates = "FromTo," + searchBeginDate + "," + searchEndDate;
                this.execute(dates, this.state.typeValue);
                this.setState({
                    activityresponse: null,
                    isloading: true,
                    searchDates: dates
                })
            }
            console.log(dateRange);
        } else {
            this.setState({ searchDates: '' })
        }
    }
    getFormattedDate(date) {
        var formattedDate = new Date(date).toISOString("mm/dd/yyyy").substring(0, 10)
        //var formattedDate = date.substring(5, 7) + "/" + date.substring(8) + "/" + date.substring(0, 4);
        return formattedDate;
    }
    startDateChange(date) {
        let messages = [];
        var searchBeginDate = this.getFormattedDate(date);
        var searchEndDate = this.getFormattedDate(this.state.endDate);
        var dates = "FromTo," + searchBeginDate + "," + searchEndDate;

        this.setState({
            startDate: date,
            searchDates: dates
        });
        if (new Date(dateFormat(date, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'From Date cannot be greater than To Date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("start Date: " + this.state.startDate);
    }
    endDateChange(date) {
        let messages = [];
        var searchBeginDate = this.getFormattedDate(this.state.startDate);
        var searchEndDate = this.getFormattedDate(date);
        var dates = "FromTo," + searchBeginDate + "," + searchEndDate;

        this.setState({
            endDate: date,
            searchDates: dates
        });

        if (new Date(dateFormat(date, "mm/dd/yyyy")) < new Date(dateFormat(this.state.startDate, "mm/dd/yyyy"))) {
            messages.push({
                type: MessageType.ERROR,
                text: 'To Date cannot be less than From date',
                class: MessageClass.ERROR
            })
            this.setState({ messages: messages });
        } else {
            this.setState({
                messages: messages
            });
        }
        console.log("end Date: " + this.state.endDate);
    }

    dateRangeDropdownToggle() {
        this.setState(prevState => ({
            dateRangeDropdownOpen: !prevState.dateRangeDropdownOpen
        }));
    }

    toggleType() {
        this.setState(prevState => ({
            typeDropdownOpen: !prevState.typeDropdownOpen
        }));
    }
    customCaretSort(order) {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }
    search() {
        this.setState({
            messages: []
        });
        if (this.state.dateRangeValue === DateRanges.CUSTOMRANGE.value) {
            let messages = [];
            if (new Date(dateFormat(this.state.startDate, "mm/dd/yyyy")) > new Date(dateFormat(this.state.endDate, "mm/dd/yyyy"))) {
                messages.push({
                    type: MessageType.ERROR,
                    text: 'To Date cannot be less than From date',
                    class: MessageClass.ERROR
                })
                this.setState({ messages: messages });
                return;
            } else {
                this.setState({
                    messages: messages
                });
            }
        }
        var searchBeginDate = getDateString(this.state.startDate, "yyyy-mm-dd");
        var searchEndDate = getDateString(this.state.endDate, "yyyy-mm-dd");
        
        var dateRange = "FromTo," + searchBeginDate + "," + searchEndDate;
        
        this.execute(dateRange, this.state.typeValue);
        this.setState({
            activityresponse: null,
            isloading: true
        })
    }
    SetDummayResponseForProspectClient() {
        if (this.props.activitydetails === null) {
            if (this.props.selectedaccount.length == 1) {
                if (this.props.selectedaccount[0].key === "RRR-123456") {
                    this.setState(() => ({ isloading: false, areDataRetrieved: true, activityresponse: RRR_123456_activity_Response, dateRangeDropdownValue: this.dateRangeDropDownItems[0].label, }), () => {
                        this.props.AddActivityDetails(RRR_123456_activity_Response);
                        this.props.AddActivitiesForSelectedAccounts(RRR_123456_activity_Response)});
                }
                else if (this.props.selectedaccount[0].key === "RRR-234567") {
                    this.setState(() => ({ isloading: false, areDataRetrieved: true, activityresponse: RRR_234567_activity_Response, dateRangeDropdownValue: this.dateRangeDropDownItems[0].label, }),
                        () => { this.props.AddActivityDetails(RRR_234567_activity_Response); this.props.AddActivitiesForSelectedAccounts(RRR_234567_activity_Response) });
                }
            } else if (this.props.selectedaccount.length == 2) {
                this.setState(() => ({ isloading: false, areDataRetrieved: true, activityresponse: activityDummyResponse, dateRangeDropdownValue: this.dateRangeDropDownItems[0].label, }),
                    () => {
                        this.props.AddActivityDetails(activityDummyResponse); this.props.AddActivitiesForSelectedAccounts(activityDummyResponse)
                 });
            }
        }
        else {
            this.props.AddActivityDetails(this.props.activitysummary);
            this.setState({
                isloading: false,
                dateRangeDropdownValue: this.dateRangeDropDownItems[0].label
            });
        }
    }
    


    render() {
        var dt = new Date();
        dt.setMonth(dt.getMonth() - 12);
        var month = dt.getMonth();
        var year = dt.getFullYear();
        //var minDate = new Date(year, month, 1);

        var rows = [];

        if (this.state.activityresponse && this.state.activityresponse.value && this.state.activityresponse.value.length > 0
            && this.state.activityresponse.value[0] && this.state.activityresponse.value[0].activities) {
            this.state.activityresponse.value[0].activities.map((d) => rows.push(
                {
                    dttrade: d.dttrade ? d.dttrade.split('T')[0] : '-',
                    dtsettle: d.dtsettle ? d.dtsettle.split('T')[0] : '-',
                    acct: d.acct,
                    key: d.key,
                    trndesc: d.trndesc,
                    trntype: d.trntype,
                    secid: d.secid,
                    units: d.units,
                    unitprice: d.unitprice,
                    ispending: d.ispending,
                    total: d.total,
                    accountnickname: getAccountDisplayName(this.props.selectedaccount, d.key, IndLongShort.SINGLEVIEW),
                    tranmnemonic: d.tranmnemonic,
                    checknumber:d.checknumber,
                    hasimage: d.hasimage
                }
            )
            )            
        }
        return (
            <ErrorBoundary>
                <AccountActivityDetailsView
                    state={this.state}
                    rows={rows}
                    dateRangeDropDownItems={this.dateRangeDropDownItems}
                    typeDropDownItems={this.typeDropDownItems}
                    toggleType={this.toggleType}
                    dateRangeDropdownToggle={this.dateRangeDropdownToggle}
                    changeDateRange={this.changeDateRange}
                    changeType={this.changeType}
                    search={this.search}
                    startDateChange={this.startDateChange}
                    endDateChange={this.endDateChange}
                    accounts={this.props.selectedaccount}
                    role={this.props.role}
                    showCheckDepModal={this.state.showCheckDepModal}
                    toggleCheckDepModal={this.toggleCheckDepModal}
                    checkDepImageClick = {this.checkDepImageClick}
                    checkDepImgData={this.state.checkDepImgData}
                    token = {this.props.token} 
                    contextjson = {this.props.contextjson}
                    profilejson = {this.props.profilejson}
                />
            </ErrorBoundary>

        )
    }
}

const mapStateToProps = state => {
    return {
        activitysummary: state.acct.activitysummary,
        activitydetails: state.acct.activitydetails,
        activitydetailsforselectedaccounts: state.acct.activitydetailsforselectedaccounts,
        render: state.acct.render,
        token: state.acct.token,
        selectedaccount: state.acct.selectedaccount,
        contextjson: state.acct.contextjson,
        profilejson: state.acct.profilejson,
        name: state.acct.name,
        role: state.acct.role
    };
};
const mapDispatchToProps = {
    AddActivity, AddActivityDetails, AddActivitiesForSelectedAccounts
};

const Activitydetailcomp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({ pageName: 'Activity Details' })
)

export default Activitydetailcomp(Activitydetails);

