export function getValidateExternalAcct(objSI)
{
    return {
        "seqid": "1",
        "standinginstructionid": null,
        "accountnumber": (objSI.fromaccount !== null && objSI.fromaccount !== undefined) ? objSI.fromaccount : "",
        "iseft": true,
        "isjnl": false,
        "isthirdparty": (objSI.thirdparty !== null && objSI.thirdparty !== undefined && objSI.thirdparty === "self")?  false : true,
        "abanumber": (objSI.abaNumber !== null && objSI.abaNumber !== undefined) ? objSI.abaNumber : "",
        "bankname": (objSI.bankName !== null && objSI.bankName !== undefined)? objSI.bankName : "",
        "bankaccountnumber": (objSI.acctNumber !==null && objSI.acctNumber !== undefined ) ? objSI.acctNumber :"",
        "displayname": (objSI.acctNickName !== null && objSI.acctNickName !== undefined) ? objSI.acctNickName : "",
        "bankaccountname": (objSI.thirdparty !== null && objSI.thirdparty !== undefined && objSI.thirdparty === "self") ? null : (objSI.bankAcctName !== null && objSI.bankAcctName !== undefined) ? objSI.bankAcctName: "",
        "bankaccounttype": objSI.acctType,
        "memo": (objSI.memo !== null && objSI.memo !== undefined) ? objSI.memo : "",
        "sidocid": (objSI.thirdparty !== null && objSI.thirdparty !== undefined && objSI.thirdparty === "self") ? objSI.sidocid : undefined
    }
}

export function getRowFormattedExternalAccount(data)
{
    return{
        "abanumber": (data.abanumber !== null && data.abanumber !== undefined) ? data.abanumber : "",
        "accountnumber": (data.accountnumber !== null && data.accountnumber !== undefined) ? data.accountnumber : "",
        "accounttype": null,
        "bankaccountnumber": (data.bankaccountnumber !==null && data.bankaccountnumber !== undefined)? data.bankaccountnumber : "",
        "bankaccounttype": (data.bankaccounttype !== null  && data.bankaccounttype !== undefined) ? data.bankaccounttype : "",
        "bankaddress": "",
        "bankname": (data.bankname !== null && data.bankname !== undefined) ? data.bankname :"",
        "errormsg": "",
        "iseft": true,
        "isjnl": false,
        "isthirdparty": false,
        "memo": "",
        "seqid": 1,
        "standinginstructionid": (data.standinginstructionid !== null && data.standinginstructionid !== undefined)? data.standinginstructionid : "",
        "standinginstructionstatus": null,
        "standinginstructiontype": (data.standinginstructiontype !== null && data.standinginstructiontype !== undefined) ? data.standinginstructiontype : "",
        "status": "Pending",
        //Bank account name yet to be added in the response 
        "displayname": (data.displayname !== null && data.displayname !== undefined) ? data.displayname : ""
        //"bankaccountname": (data.bankaccountname !== null && data.bankaccountname !== undefined) ? data.bankaccountname : ""
    }
}


export function cancelExternalAccountJson(row)
{
    return{
            "seqid": "1",
            "standinginstructionid": (row.standinginstructionid !== null && row.standinginstructionid !== undefined) ? row.standinginstructionid : "",
            "standinginstructiontype": (row.standinginstructiontype !== null && row.standinginstructiontype !== undefined) ? row.standinginstructiontype : "",
            "accountnumber": (row.accountnumber !== null && row.accountnumber !== undefined) ? row.accountnumber : "",
            "iseft": true,
           // "isjnl": false,
           // "isthirdparty": false,
           // "isincoming": true,
            "isdelete": true,
            //"memo": "This is a test"
        } 
}


export function isFirstPartyAllowed(featureName, preferences){

    let value = false;
    if(preferences && preferences.pilotfeatures && Array.isArray(preferences.pilotfeatures) && preferences.pilotfeatures.length > 0){
        for(let i =0; i< preferences.pilotfeatures.length; i++){
            if(preferences.pilotfeatures[i]['componentname'] && preferences.pilotfeatures[i]['componentname'] === featureName){
                preferences.pilotfeatures[i]['active'] === 'true' ? value = true : value = false; 
                break;
            }
        }
    }
    return value;
}

