import React, { useCallback, useState } from "react";
import { Spinner } from "reactstrap";
import { _logException, _logInformation } from "../../common/_logging";
import { updateFirstTimeUserDetails } from "../../services/home/userServices";

const VerifyUser = ({
  logout,
  token,
  otpkey,
  userDetailsForm,
  afterVerification,
}) => {
  const [otp, setOtp] = useState("");
  const [verifyProgressState, setVerifyProgressState] = useState({
    inProgress: false,
    error: undefined,
  });

  const handleOtpChange = (event) => {
    event.persist();
    const value = event.target ? event.target.value : "";
    const pattern = /^\d*$/;
    if (value === "" || pattern.test(value)) {
      setOtp(event.target.value);
    }
  };

  const handleVerifyUser = useCallback(() => {
    setVerifyProgressState((prevState) => ({
      ...prevState,
      inProgress: true,
      error: undefined,
    }));
    updateFirstTimeUserDetails(userDetailsForm, otpkey, otp, token)
      .then(() => {
        if (userDetailsForm) {
          _logInformation(`Verification mode: ${userDetailsForm.mode}`);
        }
        afterVerification();
      })
      .catch((error) => {
        setVerifyProgressState((prevState) => ({
          ...prevState,
          inProgress: false,
          error:
            "We are unable to verify your details, Please contact your advisor.",
        }));
        _logException(error);
      });
  }, [userDetailsForm, otpkey, otp, token, afterVerification]);

  const handleOnOtpKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleVerifyUser();
    }
  };

  return (
    <>
      <div>
        <div className="RCM_background"></div>
        <div className="RCM_background_overlay"></div>
      </div>
      <div className="RCM_login_outer">
        <div className="RCM_login_middle">
          <div className="RCM_login_inner" style={{ minHeight: "480px" }}>
            <img
              className="RCM_Logon_Form_Logo"
              role="presentation"
              src="../images/logo.svg"
              alt="Rockefeller logo"
            />
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  marginTop: "2.5rem",
                }}
              >
                <div style={{ color: "black", fontSize: "0.80rem" }}>
                  Please enter the verification code sent to your mobile phone
                  (expires in 5 minutes).
                </div>
                <input
                  type="text"
                  placeholder="Enter Your Verification Code"
                  className="RCM_Form_textfield"
                  required
                  spellCheck={false}
                  value={otp}
                  onChange={handleOtpChange}
                  onKeyUp={handleOnOtpKeyUp}
                  autoFocus
                />
              </div>
              {verifyProgressState.error && (
                <div className="RCM_Teamwork_error">
                  {verifyProgressState.error}
                </div>
              )}
              <div className="mt-3" align="right">
                <button
                  className="RCM_Form_Sec_Button"
                  onClick={() => logout()}
                >
                  Cancel
                </button>{" "}
                <button
                  className="RCM_Form_Primary_Button"
                  onClick={handleVerifyUser}
                >
                  {verifyProgressState.inProgress && (
                    <Spinner size="sm" style={{ marginRight: "0.5rem" }} />
                  )}{" "}
                  Verify
                </button>
              </div>
              <div
                style={{ color: "black", fontSize: "0.8rem", marginTop: "2px" }}
              >
                If you have any questions, please contact your advisor team.
              </div>
            </div>

            <div className="RCM_Logon_Form_Footer">
              <div>
                Welcome to Rockefeller Capital Management.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyUser;
