import React from "react";
import {connect} from "react-redux";
import AddExternalAccountConfirm from "./addExternalAccountConfirm.jsx";
import { submitStandingInstruction, submitStandingInstructionCancellationToken, uploadFileToBPM } from "./manageExternalAccountService.jsx";
import OverlayProcessing from "../common/overlayProcessing.jsx";
import {addExternalAccountAction} from "../../../actions/manageexternalacctactions";
import {getRowFormattedExternalAccount} from "../models/ExternalAcctInfo";
import { v4 as uuid } from "uuid";
import { genericErrorMsg } from "../common/constants.js";
import * as CloneDeep from  'lodash/cloneDeep';
import ShowFilePopOver from "../../../common/showFilePopOver.jsx";


class AddExternalAccountPreConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.token= "";
    if(this.props.token !== null && this.props.token !== undefined)
    {
      this.token = this.props.token;
    }

    this.contextjson = null;
        if(this.props.contextjson !== null && this.props.contextjson !== undefined)
        {
            this.contextjson = this.props.contextjson;
        }

        this.profilejson = null;
        if(this.props.profilejson !== null && this.props.profilejson !== undefined)
        {
            this.profilejson = this.props.profilejson;
        }

    this.state = {
      showAddExtAcctConfirm: false,
      isLoading: false,
      errorMsg: "",
      filePopOverOpen: false
    };

    this.handleAddExtAcctPreConfirm = this.handleAddExtAcctPreConfirm.bind(this);
    this.submitSI = this.submitSI.bind(this);
    this.filePopOverToggle = this.filePopOverToggle.bind(this);
  }

  filePopOverToggle(){
    this.setState({filePopOverOpen: !this.state.filePopOverOpen});
  }

  handleAddExtAcctPreConfirm(evt) {
    evt.preventDefault();
    let clonedState = Object.assign({}, this.state);
    clonedState.isLoading= true;
    clonedState.errorMsg = "";
    this.setState(clonedState, () => {
        console.log(this.props.newAcctData)
        if(this.props.newAcctData.thirdparty === 'self'){
          let fromacct = this.props.newAcctData.fromaccount;
          let formData = new FormData();
          formData.append("bpmid", uuid());  
            formData.append("accountnumber", fromacct);
            formData.append("sidoctype", this.props.newAcctData.fileSelected && this.props.newAcctData.fileSelected.name ? this.props.newAcctData.fileSelected.name.split('.').pop() : 'pdf')
          formData.append("isforsi", true);
          formData.append("fileContent", this.props.newAcctData.fileSelected);

          uploadFileToBPM(formData, this.token, this.contextjson, this.profilejson).then(res => {
            if(res && res.data && res.data.sidocid){
              this.submitSI(res.data.sidocid);
            }else{
              this.setState({ showAddExtAcctConfirm: false, isLoading: false, errorMsg: genericErrorMsg});
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({ showAddExtAcctConfirm: false, isLoading: false, errorMsg: err});
          });
        }else{
          this.submitSI();
        }

        
    })
  }


  submitSI(sidocid){

    let request = CloneDeep(this.props.newAcctData);
    if(this.props.newAcctData && this.props.newAcctData.thirdparty === 'self'){
      request.sidocid =  sidocid;
    }

    submitStandingInstruction(request, this.token, this.contextjson, this.profilejson)
      .then(data => {
        //call reducer here to add the record in store
        if(data !== null && data !== undefined)
        {
          //format as per grid and send to reducer to unshift the array
          let newExtAcctData = getRowFormattedExternalAccount(data);
          let newExternalAcctList = [...this.props.externalAcctList];
          newExternalAcctList.unshift(newExtAcctData);
          this.props.addExternalAccountAction(newExternalAcctList, newExtAcctData.standinginstructionid);
        }

        const newState = Object.assign({}, this.state, {
          showAddExtAcctConfirm: true,
          isLoading: false,
          errorMsg: ""
        });
        this.setState(newState);

      }).catch(error => {
        const newState = Object.assign({}, this.state, {
          showAddExtAcctConfirm: false,
          isLoading: false,
          errorMsg: error
        });
        this.setState(newState);
      });
  }

  showFilePreview(){
    this.setState({filePopOverOpen: true});
}

  hideFilePreview(){
      this.setState({filePopOverOpen: false});
  }

  componentWillUnmount()
  {
    if(submitStandingInstructionCancellationToken !==null && submitStandingInstructionCancellationToken !== undefined)
    {
      submitStandingInstructionCancellationToken.cancel("Cancelling submit SI request on unmount to prevent memory leak.");
    }
  } 

  render() {
    let addExternalAcctPreConfirm;
    if (!this.state.showAddExtAcctConfirm) {
      addExternalAcctPreConfirm = (
        <div>
          {
            this.state.errorMsg !== "" ?
              (
                <div className="RCM_validation_error_container" >
                  <div className="RCM_validation_error_text">{this.state.errorMsg}</div>
                </div>
              ) : ""
          }

          <div className="RCM_CM_form">
          <div className="row">
            <div className="col-sm-12 RCM_Toast_sub_heading">
              CONFIRM ACCOUNT ADDITION
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              Please confirm the new account details.
            </div>
          </div>       
          <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">ACCOUNT OWNERSHIP</div>
                <div className="RCM_Info_text">{this.props.newAcctData.displayThirdParty}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <div className="RCM_Toast_sub_heading">ACCOUNT NICKNAME</div>
                <div className="RCM_Info_text">{this.props.newAcctData.acctNickName}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <div className="RCM_Toast_sub_heading">BANK NAME</div>
                <div className="RCM_Info_text">{this.props.newAcctData.bankName}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <div className="RCM_Toast_sub_heading">ACCOUNT NUMBER</div>
                <div className="RCM_Info_text">{this.props.newAcctData.acctNumber}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <div className="RCM_Toast_sub_heading">ABA ROUTING NUMBER</div>
                <div className="RCM_Info_text">{this.props.newAcctData.abaNumber}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <div className="RCM_Toast_sub_heading">ACCOUNT TYPE</div>
                <div className="RCM_Info_text">{this.props.newAcctData.displayAcctType}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="RCM_Toast_sub_heading">{this.props.newAcctData.thirdparty === 'self'? 'TRANSFER FROM/TO' : 'TRANSFER FROM' }</div>
                <div className="RCM_Info_text">{this.props.newAcctData.displayAcctName}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <div className="RCM_Toast_sub_heading">MEMO (OPTIONAL)</div>
                <div className="RCM_Info_text">{this.props.newAcctData.memo}</div>
              </div>
            </div>
            {
              (this.props.newAcctData && this.props.newAcctData.fileSelected) && (
                <div className="row">
                  <div className="col-sm-12">
                  <div className="RCM_Toast_sub_heading">VOID CHECK IMAGE</div>
                    <div className="RCM_Info_text RCM_icon_hand" id='RCM_SI_file_popover'
                    onClick={() => this.showFilePreview()}>{this.props.newAcctData.fileSelected.name}</div>
                  </div>
                  <ShowFilePopOver 
                      target='RCM_SI_file_popover'
                      file={this.props.newAcctData.fileSelected}
                      filePopOverOpen={this.state.filePopOverOpen} 
                      filePopOverToggle={this.filePopOverToggle}/>
                </div>
              )
            }
            
            <div className="row">
              <div className="col-sm-12" align="center">
              <button
                  className="btn btn-secondary RCM_button_secondary RCM_select_btn RCM_reduce_btn_size"
                  onClick={this.props.backAsEdit}
                >
                  EDIT
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn RCM_button_primary RCM_select_btn RCM_reduce_btn_size"
                  onClick={this.handleAddExtAcctPreConfirm}
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12" align="center">
              
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      addExternalAcctPreConfirm = (
        <AddExternalAccountConfirm backAsFresh={this.props.backAsFresh} newAcctData={this.props.newAcctData} />
      );
    }

    return (<div>
      {
        this.state.isLoading === true ? (
          <OverlayProcessing />
        ) : ""
      }
      {addExternalAcctPreConfirm}
    </div>);
  }
}

const mapStateToProps = store =>{
  return{
    externalAcctList: store.manageextacct.externalAcctList,
    token: store.acct.token,
    contextjson: store.acct.contextjson,
    profilejson: store.acct.profilejson

  }
}

const mapDispatchToProps ={
  addExternalAccountAction
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExternalAccountPreConfirm);
